import React, { useState, useEffect } from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';

import { clarity } from 'react-microsoft-clarity';

import { BrowserRouter as Router, Switch, Route, Redirect, Link } from 'react-router-dom';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

import Navigation from './Navigation';
import ScrollToTop from './ScrollToTop';
import EtaxCompanies from './etax/companies/Companies';
import ReceiptTaxInvoiceNew from './etax/receipts-taxinvoices/ReceiptTaxInvoice';
import ReceiptTaxInvoiceNewConfirm from './etax/receipts-taxinvoices/ReceiptTaxInvoiceConfirm';
import ReceiptTaxInvoiceAbbNew from './etax/receipts-taxinvoices-abb/ReceiptTaxInvoiceAbb';
import ReceiptTaxInvoiceAbbNewConfirm from './etax/receipts-taxinvoices-abb/ReceiptTaxInvoiceAbbConfirm';
import ReceiptNew from './etax/receipts/Receipt';
import ReceiptConfirm from './etax/receipts/ReceiptConfirm';
import InvoiceTaxInvoiceNew from './etax/invoices-taxinvoices/InvoiceTaxInvoice';
import InvoiceTaxInvoiceNewConfirm from './etax/invoices-taxinvoices/InvoiceTaxInvoiceConfirm';
import DeliveryOrderTaxInvoiceNew from './etax/deliveryorders-taxinvoices/DeliveryOrderTaxInvoice';
import DeliveryOrderTaxInvoiceNewConfirm from './etax/deliveryorders-taxinvoices/DeliveryOrderTaxInvoiceConfirm';
import TaxInvoiceNew from './etax/taxinvoices/TaxInvoice';
import TaxInvoiceNewConfirm from './etax/taxinvoices/TaxInvoiceConfirm';
import CreditNote from './etax/credit-notes/CreditNote';
import CreditNoteConfirm from './etax/credit-notes/CreditNoteConfirm';
import DebitNote from './etax/debit-notes/DebitNote';
import DebitNoteConfirm from './etax/debit-notes/DebitNoteConfirm';
import QuotationNew from './documents/quotations/Quotation';
import QuotationConfirm from './documents/quotations/QuotationConfirm';
import InvoiceNew from './documents/invoices/Invoice';
import InvoiceConfirm from './documents/invoices/InvoiceConfirm';
import BillingNoteNew from './documents/billing-notes/BillingNote';
import BillingNoteConfirm from './documents/billing-notes/BillingNoteConfirm';
import WithholdingTaxNew from './documents/withholding-tax/WithholdingTax';
import WithholdingTaxConfirm from './documents/withholding-tax/WithholdingTaxConfirm';
import Signatures from './signatures/Signatures';
import Certificates from './etax/certificates/Certificates';
import CertificateAdd from './etax/certificates/add/CertificateAdd';
import P12 from './etax/certificates/add/p12/P12';
import LogOut from './logout/LogOut';
import Header from './Header';
import Pdf from './etax/documents/pdf/Pdf';
import Xml from './etax/documents/xml/Xml';
import ViewPdf from './etax/documents/pdf/view/ViewPdf';
import ViewXml from './etax/documents/xml/view/ViewXml';
import Billings from './etax/billings/Billings';
import Checkout from './etax/billings/subscriptions/checkout/Checkout';
import SmsCheckout from './etax/billings/sms/checkout/SmsCheckout';
import Complete from './etax/billings/complete/Complete';
import DocumentsCheckout from './etax/billings/documents/checkout/DocumentsCheckout';
import PdfTemplate from './etax/templates/pdf/PdfTemplate';
import TemplatePreview from './etax/templates/pdf/preview/TemplatePreview';
import Maintenance from './maintenance/Maintenance';
import Companies from './ca/companies/Companies';
import Address from './ca/address/Address';
import Documents from './ca/documents/Documents';
import Contacts from './ca/contacts/Contacts';
import Confirm from './ca/confirm/Confirm';
import ProfileUpdateComplete from './profiles/update/complete/ProfileUpdateComplete';
import ProfileUpdate from './profiles/update/ProfileUpdate';
import TermsConditions from './ca/terms-conditions/TermsConditions';
import Loading from './loading/Loading';
import XmlDeliver from './etax/xml/deliver/XmlDeliver';
import Excel from './external-sources/excel/excel';
import LazadaConnect from './external-sources/lazada/LazadaConnect';
import Lazada from './external-sources/lazada/Lazada';
// import LazadaPreview from './external-sources/lazada/LazadaPreview';
import ShopeeConnect from './external-sources/shopee/ShopeeConnect';
import Shopee from './external-sources/shopee/Shopee';
// import ShopeePreview from './external-sources/shopee/ShopeePreview';
import JdCentral from './external-sources/jdcentral/JdCentral';
import JdCentralConnect from './external-sources/jdcentral/JdCentralConnect';
import ShipnityConnect from './external-sources/shipnity/ShipnityConnect';
import Shipnity from './external-sources/shipnity/Shipnity';
import XeroConnect from './external-sources/xero/XeroConnect';
import Xero from './external-sources/xero/Xero';
import TRCloud from './external-sources/trcloud/TRCloud';
import TRCloudConnect from './external-sources/trcloud/TRCloudConnect';
import OutputTax from './reports/output-tax/OutputTax';
import OutputTaxConfirm from './reports/output-tax/OutputTaxConfirm';
import PP30 from './pnd/pp30/PP30';
import PP30Summary from './pnd/pp30/PP30Summary';
import PP30Confirm from './pnd/pp30/PP30Confirm';
import XmlUpload from './reports/xml-upload/XmlUpload';
import XmlUploadConfirm from './reports/xml-upload/XmlUploadConfirm';
import Peak from './external-sources/peak/Peak';
import PeakConnect from './external-sources/peak/PeakConnect';
import RdVat from './rd/vat/RdVat';
import RdEtax from './rd/etax/RdEtax';
import RdTaxId from './rd/taxId/TaxId';

import ApiKeys from './etax/apikeys/ApiKeys';

// import { initializeFileTypeIcons } from '@uifabric/file-type-icons';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { initializeIcons, Stack, Icon } from '@fluentui/react';
// import { Stack, Icon } from '@fluentui/react';
// import { initializeIcons } from '@fluentui/react/lib/Icons';

// MSAL imports
import { MsalProvider, useMsal } from '@azure/msal-react';
import { MsalAuthenticationTemplate, useAccount } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { silentRequest } from '../authConfig';

import { loginRequest, signupRequest } from '../authConfig';

import axios from 'axios';
import AllDocuments from './etax/documents/all/AllDocuments';
import ExpenseNote from './expenses/expense-notes/ExpenseNote';
import ExpenseNoteConfirm from './expenses/expense-notes/ExpenseNoteConfirm';
import AddContacts from './contacts/AddContacts';
import ContactList from './contacts/Contacts';
import AllExpenses from './expenses/ExpenseDocuments';
import Inbox from './inbox/Inbox';
import Sent from './sent/Sent';
import Upload from './sent/Upload';
import Emails from './settings/emails/Emails';
import ViewPdfInbox from './etax/documents/pdf/view/ViewPdfInbox';
import ViewPdfSent from './etax/documents/pdf/view/ViewPdfSent';
import SentReport from './sent/SentReport';

// import { ThemeProvider } from '@fluentui/react-theme-provider';
import PaypalConnect from './external-sources/paypal/PaypalConnect';
import Paypal from './external-sources/paypal/Paypal';
import SellSummary from './reports/sell-summary/SellSummary';
import SellSummaryConfirm from './reports/sell-summary/SellSummaryConfirm';
import QuickBooksConnect from './external-sources/quickbooks/QuickBooksConnect';
import QuickBooks from './external-sources/quickbooks/QuickBooks';
import ZohoConnect from './external-sources/zoho/ZohoConnect';
import Zoho from './external-sources/zoho/Zoho';
import Sources from './external-sources/sources/Sources';
import Feedback from './supports/Feedback';
import ViewPdfExpense from './etax/documents/pdf/view/ViewPdfExpenseNote';
import PND53 from './pnd/pnd53/PND53';
import PND2 from './pnd/pnd2/PND2';
import PND3 from './pnd/pnd3/PND3';
import WithholdingTaxDocuments from './documents/withholding-tax/withholdingTaxDocument/WithholdingTaxDocuments';
import WithholdingTaxDropzone from './documents/withholding-tax/withholdingTaxDocument/WithholdingTaxDropzone';
import WithholdingTaxResult from './documents/withholding-tax/withholdingTaxDocument/WithholdingTaxResult';
import WithholdingTaxUploadList from './documents/withholding-tax/withholdingTaxDocument/WithholdingTaxUploadList';
import DynamicsBusinessCentral from './external-sources/dynamicsBusinessCentral/DynamicsBusinessCentral';
import DynamicsBusinessCentralConnect from './external-sources/dynamicsBusinessCentral/DynamicsBusinessCentralConnect';
import DynamicsCrmConnect from './external-sources/dynamicsCrm/DynamicsCrmConnect';
import DynamicsCrm from './external-sources/dynamicsCrm/DynamicsCrm';
import SAPS4HANAConnect from './external-sources/sapS4HANA/SAPS4HANAConnect';
import SAPS4HANA from './external-sources/sapS4HANA/SAPS4HANA';
import SAPBusinessOne from './external-sources/sapBusinessOne/SAPBusinessOne';
import SAPBusinessOneConnect from './external-sources/sapBusinessOne/SAPBusinessOneConnect';
import BatchReceipt from './etax/batch-receipts/BatchReceipt';
import BatchReceiptConfirm from './etax/batch-receipts/BatchReceiptConfirm';
import ContactsDropzone from './contacts/contactsDropzone/ContactsDropzone';
import ContactsUploadList from './contacts/contactsDropzone/ContactsUploadList';
import ContactsResult from './contacts/contactsDropzone/ContactsResult';
import DropzoneDragDrop from './external-sources/excel/dragdrop/dropzone';
import ExcelDragDropCheck from './external-sources/excel/dragdrop/ExcelDragDropCheck';
import DragDropFail from './external-sources/excel/dragdrop/DragDropFail';
import DragDropSuccess from './external-sources/excel/dragdrop/DragDropSuccess';
import InputTax from './reports/input-tax/InputTax';
import InputTaxConfirm from './reports/input-tax/InputTaxConfirm';
import StripeConnect from './external-sources/stripe/StripeConnect';
import Stripe from './external-sources/stripe/Stripe';
import DeliveryOrder from './documents/deliveryorders/DeliveryOrder';
import DeliveryOrderConfirm from './documents/deliveryorders/DeliveryOrderConfirm';
import ExcelMenu from './external-sources/excel/ExcelMenu';
import PersonList from './contacts/Persons';
import AddPerson from './contacts/AddPerson';
import AccountReceivable from './accounts-receivable/AccountReceivable';
import LineShoppingConnect from './external-sources/lineShopping/LineShoppingConnect';
import LineShopping from './external-sources/lineShopping/LineShopping';
import ShopeeAutomation from './external-sources/shopee/ShopeeAutomation';
import LazadaAutomation from './external-sources/lazada/LazadaAutomation';
import Products from './products/Products';
import AddProducts from './products/AddProducts';
import TiktokShopConnect from './external-sources/tiktokShop/TiktokShopConnect';
import TiktokShop from './external-sources/tiktokShop/TiktokShop';
import TiktokShopAutomation from './external-sources/tiktokShop/TiktokShopAutomation';
// import POS from './pos/POS';
import DynamicsFinanceOperationsConnect from './external-sources/dynamicsFinanceOperations/DynamicsFinanceOperationsConnect';
import DynamicsFinanceOperations from './external-sources/dynamicsFinanceOperations/DynamicsFinanceOperations';
import WooCommerceConnect from './external-sources/wooCommerce/WooCommerceConnect';
import WooCommerce from './external-sources/wooCommerce/WooCommerce';
import LazadaFinance from './external-sources/lazada/LazadaFinance';
import LazadaPayout from './external-sources/lazada/LazadaPayout';
import EmailDeliveries from './reports/email-deliveries/EmailDeliveries';
import SmsDeliveries from './reports/sms-deliveries/SmsDeliveries';
import ExcelSuccess from './external-sources/excel/ExcelSuccess';
import ExcelFail from './external-sources/excel/ExcelFail';
import StoragesConnect from './settings/storages/StoragesConnect';
import Strorages from './storages/Storages';
// import Roles from './permission/roles/Roles';
// Register icons and pull the fonts from the default SharePoint cdn.
initializeFileTypeIcons();
initializeIcons();

const API_URL = process.env.REACT_APP_API_URL;
const LECEIPT_VERSION = process.env.REACT_APP_LECEIPT_VERSION;
const MICROSOFT_CLARITY_ID = process.env.REACT_APP_MICROSOFT_CLARITY_ID;

// Styles definition
const stackStyles = {
  root: {
    background: '#FAF9F8',
    height: '100vh',
    margin: 0,
    padding: 0,
    minWidth: '1400px',
    minHeight: '100%',
  },
};

// Start seeing data on the Clarity dashboard with your id
clarity.init(MICROSOFT_CLARITY_ID);

// Identify the user
clarity.identify('USER_ID', { userProperty: 'value' });

// Cookie consent
clarity.consent();

// Setup a custom tag
clarity.setTag('key', 'value');

// Upgrade session
clarity.upgrade('upgradeReason');

// Check if Clarity has been initialized before calling its methods
if (clarity.hasStarted()) {
  clarity.identify('USER_ID', { userProperty: 'value' });
}

const App = ({ pca }) => {
    //Temporary fix Safari not reload
    /* 
    window.addEventListener('hashchange', () => {
        if (window.location.hash.startsWith('#code')) window.location.reload();
    });
 */

  const urlPath = window.location.pathname;

  let authRequest = {
    ...loginRequest,
  };

  if (urlPath === '/') {
    authRequest = {
      ...loginRequest,
    };
  } else if (urlPath === '/signup') {
    authRequest = {
      ...signupRequest,
    };
  } else {
    authRequest = {
      ...silentRequest,
    };
  }

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={LogOut}
        loadingComponent={Loading}
      >
{/* 
        <ThemeProvider
          theme={{
            components: {
              h2: {
                styles: {
                  root: {
                    color: '#000000',
                  },
                },
              },
              span: {
                styles: { root: { color: '#000000' } },
              },
            },
          }}
        >
 */}
          <FluentProvider theme={webLightTheme}>
            <Body />
          </FluentProvider>

        {/* </ThemeProvider> */}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

const Pages = () => {
  return (
    <Switch>
      <Route exact path="/">
        <ScrollToTop />
        <AllDocuments />
      </Route>
      <Route exact path="/etax">
        <ScrollToTop />
        <AllDocuments />
      </Route>
      <Route exact path="/etax/documents/all">
        <ScrollToTop />
        <AllDocuments />
      </Route>
      <Route exact path="/etax/documents/accounts-receivable/all">
        <ScrollToTop />
        <AccountReceivable />
      </Route>
      <Route exact path="/etax/documents/pdf">
        <ScrollToTop />
        <Pdf />
      </Route>
      <Route exact path="/etax/documents/xml">
        <ScrollToTop />
        <Xml />
      </Route>
      <Route exact path="/etax/documents/pdf/view">
        <ScrollToTop />
        <ViewPdf />
      </Route>
      <Route exact path="/expenses/pdf/view">
        <ScrollToTop />
        <ViewPdfExpense />
      </Route>
      <Route exact path="/etax/documents/xml/view">
        <ScrollToTop />
        <ViewXml />
      </Route>
      <Route exact path="/inbox/documents/pdf/view">
        <ScrollToTop />
        <ViewPdfInbox />
      </Route>
      <Route exact path="/sent/documents/pdf/view">
        <ScrollToTop />
        <ViewPdfSent />
      </Route>
      <Route exact path="/inbox">
        <ScrollToTop />
        <Inbox />
      </Route>
      <Route exact path="/sent">
        <ScrollToTop />
        <Sent />
      </Route>
      <Route exact path="/sent/upload">
        <ScrollToTop />
        <Upload />
      </Route>
      <Route exact path="/sent/upload/report">
        <ScrollToTop />
        <SentReport />
      </Route>
      <Route exact path="/etax/documents/batch-receipts">
        <ScrollToTop />
        <BatchReceipt />
      </Route>
      <Route exact path="/etax/documents/batch-receipts/confirm">
        <ScrollToTop />
        <BatchReceiptConfirm />
      </Route>
      <Route exact path="/etax/documents/receipts-taxinvoices">
        <ScrollToTop />
        <ReceiptTaxInvoiceNew />
      </Route>
      <Route exact path="/etax/documents/receipts-taxinvoices/confirm">
        <ScrollToTop />
        <ReceiptTaxInvoiceNewConfirm />
      </Route>
      <Route exact path="/etax/documents/receipts-taxinvoices-abb">
        <ScrollToTop />
        <ReceiptTaxInvoiceAbbNew />
      </Route>
      <Route exact path="/etax/documents/receipts-taxinvoices-abb/confirm">
        <ScrollToTop />
        <ReceiptTaxInvoiceAbbNewConfirm />
      </Route>
      <Route exact path="/etax/documents/receipts">
        <ScrollToTop />
        <ReceiptNew />
      </Route>
      <Route exact path="/etax/documents/receipts/confirm">
        <ScrollToTop />
        <ReceiptConfirm />
      </Route>
      <Route exact path="/etax/documents/invoices-taxinvoices">
        <ScrollToTop />
        <InvoiceTaxInvoiceNew />
      </Route>
      <Route exact path="/etax/documents/invoices-taxinvoices/confirm">
        <ScrollToTop />
        <InvoiceTaxInvoiceNewConfirm />
      </Route>
      <Route exact path="/etax/documents/deliveryorders-taxinvoices">
        <ScrollToTop />
        <DeliveryOrderTaxInvoiceNew />
      </Route>
      <Route exact path="/etax/documents/deliveryorders-taxinvoices/confirm">
        <ScrollToTop />
        <DeliveryOrderTaxInvoiceNewConfirm />
      </Route>
      <Route exact path="/etax/documents/taxinvoices">
        <ScrollToTop />
        <TaxInvoiceNew />
      </Route>
      <Route exact path="/etax/documents/taxinvoices/confirm">
        <ScrollToTop />
        <TaxInvoiceNewConfirm />
      </Route>
      <Route exact path="/supports/feedback">
        <ScrollToTop />
        <Feedback />
      </Route>
      <Route exact path="/expenses/all">
        <ScrollToTop />
        <AllExpenses />
      </Route>
      <Route exact path="/expenses/expense-notes">
        <ScrollToTop />
        <ExpenseNote />
      </Route>
      <Route exact path="/expenses/expense-notes/confirm">
        <ScrollToTop />
        <ExpenseNoteConfirm />
      </Route>
      <Route exact path="/rdprep/pnd2">
        <ScrollToTop />
        <PND2 />
      </Route>
      <Route exact path="/rdprep/pnd3">
        <ScrollToTop />
        <PND3 />
      </Route>
      <Route exact path="/rdprep/pnd53">
        <ScrollToTop />
        <PND53 />
      </Route>
      <Route exact path="/etax/documents/credit-notes">
        <ScrollToTop />
        <CreditNote />
      </Route>
      <Route exact path="/etax/documents/credit-notes/confirm">
        <ScrollToTop />
        <CreditNoteConfirm />
      </Route>
      <Route exact path="/etax/documents/debit-notes">
        <ScrollToTop />
        <DebitNote />
      </Route>
      <Route exact path="/etax/documents/debit-notes/confirm">
        <ScrollToTop />
        <DebitNoteConfirm />
      </Route>
      <Route exact path="/etax/companies/profiles">
        <EtaxCompanies />
      </Route>
      <Route exact path="/contacts/add">
        <ScrollToTop />
        <AddContacts />
      </Route>
      <Route exact path="/persons/add">
        <ScrollToTop />
        <AddPerson />
      </Route>
      <Route exact path="/contacts">
        <ScrollToTop />
        <ContactList />
      </Route>
      <Route exact path="/persons">
        <ScrollToTop />
        <PersonList />
      </Route>
      {/* <Route exact path="/pos">
        <ScrollToTop />
        <POS />
      </Route> */}
      <Route exact path="/products">
        <ScrollToTop />
        <Products />
      </Route>
      <Route exact path="/products/add">
        <ScrollToTop />
        <AddProducts />
      </Route>
      <Route exact path="/contacts/excel/upload">
        <ScrollToTop />
        <ContactsDropzone />
      </Route>
      <Route exact path="/contacts/excel/check/result">
        <ScrollToTop />
        <ContactsResult />
      </Route>
      <Route exact path="/contacts/excel/list">
        <ScrollToTop />
        <ContactsUploadList />
      </Route>
      <Route exact path="/etax/signatures">
        <ScrollToTop />
        <Signatures />
      </Route>
      <Route exact path="/etax/certificates">
        <ScrollToTop />
        <Certificates />
      </Route>
      <Route exact path="/etax/certificates/add">
        <ScrollToTop />
        <CertificateAdd />
      </Route>
      <Route exact path="/etax/certificates/add/p12">
        <ScrollToTop />
        <P12 />
      </Route>
      <Route exact path="/etax/apikeys">
        <ScrollToTop />
        <ApiKeys />
      </Route>
      <Route exact path="/billings">
        <ScrollToTop />
        <Billings />
      </Route>
      <Route exact path="/billings/subscriptions/checkout">
        <ScrollToTop />
        <Checkout />
      </Route>
      <Route exact path="/billings/documents/checkout">
        <ScrollToTop />
        <DocumentsCheckout />
      </Route>
      <Route exact path="/billings/sms/checkout">
        <ScrollToTop />
        <SmsCheckout />
      </Route>
      <Route exact path="/billings/:orderId/complete">
        <ScrollToTop />
        <Complete />
      </Route>
      <Route exact path="/etax/templates/pdf">
        <ScrollToTop />
        <PdfTemplate />
      </Route>
      <Route exact path="/etax/templates/pdf/preview">
        <ScrollToTop />
        <TemplatePreview />
      </Route>
      <Route exact path="/etax/xml/deliver">
        <ScrollToTop />
        <XmlDeliver />
      </Route>
      <Route exact path="/etax/sources">
        <ScrollToTop />
        <Sources />
      </Route>
      <Route exact path="/etax/sources/excel/dragdrop">
        <ScrollToTop />
        <DropzoneDragDrop />
      </Route>
      <Route exact path="/etax/sources/excel/dragdrop/check">
        <ScrollToTop />
        <ExcelDragDropCheck />
      </Route>
      <Route exact path="/etax/sources/excel/dragdrop/list">
        <ScrollToTop />
        <DragDropSuccess />
      </Route>
      <Route exact path="/etax/sources/excel/dragdrop/check/result">
        <ScrollToTop />
        <DragDropFail />
      </Route>
      <Route exact path="/etax/sources/excel">
        <ScrollToTop />
        <Excel />
      </Route>
      <Route exact path="/etax/sources/excel/success">
        <ScrollToTop />
        <ExcelSuccess />
      </Route>
      <Route exact path="/etax/sources/excel/fail">
        <ScrollToTop />
        <ExcelFail />
      </Route>
      <Route exact path="/etax/sources/excel/menu">
        <ScrollToTop />
        <ExcelMenu />
      </Route>
      <Route exact path="/etax/sources/lazada/connect">
        <ScrollToTop />
        <LazadaConnect />
      </Route>
      <Route exact path="/etax/sources/lazada">
        <ScrollToTop />
        <Lazada />
      </Route>
      <Route exact path="/etax/sources/lazada/finance">
        <ScrollToTop />
        <LazadaFinance />
      </Route>
      <Route exact path="/etax/sources/lazada/payout">
        <ScrollToTop />
        <LazadaPayout />
      </Route>
{/* 
      <Route exact path="/etax/sources/lazada-preview">
        <ScrollToTop />
        <LazadaPreview />
      </Route>
       */}
      <Route exact path="/etax/sources/lazada/automation">
        <ScrollToTop />
        <LazadaAutomation />
      </Route>
      <Route exact path="/etax/sources/line-shopping/connect">
        <ScrollToTop />
        <LineShoppingConnect />
      </Route>
      <Route exact path="/etax/sources/line-shopping">
        <ScrollToTop />
        <LineShopping />
      </Route>
      <Route exact path="/etax/sources/shopee/connect">
        <ScrollToTop />
        <ShopeeConnect />
      </Route>
      <Route exact path="/etax/sources/shopee">
        <ScrollToTop />
        <Shopee />
      </Route>
      <Route exact path="/etax/sources/tiktok-shop/connect">
        <ScrollToTop />
        <TiktokShopConnect />
      </Route>
      <Route exact path="/etax/sources/tiktok-shop">
        <ScrollToTop />
        <TiktokShop />
      </Route>
      <Route exact path="/etax/sources/tiktok-shop/automation">
        <ScrollToTop />
        <TiktokShopAutomation />
      </Route>
{/* 
      <Route exact path="/etax/sources/shopee-preview">
        <ScrollToTop />
        <ShopeePreview />
      </Route>
       */}
      <Route exact path="/etax/sources/shopee/automation">
        <ScrollToTop />
        <ShopeeAutomation />
      </Route>
      <Route exact path="/etax/sources/xero/connect">
        <ScrollToTop />
        <XeroConnect />
      </Route>
      <Route exact path="/etax/sources/xero">
        <ScrollToTop />
        <Xero />
      </Route>
      <Route exact path="/etax/sources/trcloud/connect">
        <ScrollToTop />
        <TRCloudConnect />
      </Route>
      <Route exact path="/etax/sources/trcloud">
        <ScrollToTop />
        <TRCloud />
      </Route>
      <Route exact path="/etax/sources/woo-commerce/connect">
        <ScrollToTop />
        <WooCommerceConnect />
      </Route>
      <Route exact path="/etax/sources/woo-commerce">
        <ScrollToTop />
        <WooCommerce />
      </Route>
      <Route exact path="/etax/sources/peak/connect">
        <ScrollToTop />
        <PeakConnect />
      </Route>
      <Route exact path="/etax/sources/peak">
        <ScrollToTop />
        <Peak />
      </Route>
      <Route exact path="/etax/sources/jdcentral">
        <ScrollToTop />
        <JdCentral />
      </Route>
      <Route exact path="/etax/sources/jdcentral/connect">
        <ScrollToTop />
        <JdCentralConnect />
      </Route>
      <Route exact path="/etax/sources/shipnity/connect">
        <ScrollToTop />
        <ShipnityConnect />
      </Route>
      <Route exact path="/etax/sources/shipnity">
        <ScrollToTop />
        <Shipnity />
      </Route>
      <Route exact path="/etax/sources/paypal/connect">
        <ScrollToTop />
        <PaypalConnect />
      </Route>
      <Route exact path="/etax/sources/paypal">
        <ScrollToTop />
        <Paypal />
      </Route>
      <Route exact path="/etax/sources/stripe/connect">
        <ScrollToTop />
        <StripeConnect />
      </Route>
      <Route exact path="/etax/sources/stripe">
        <ScrollToTop />
        <Stripe />
      </Route>
      <Route exact path="/etax/sources/quickbooks/connect">
        <ScrollToTop />
        <QuickBooksConnect />
      </Route>
      <Route exact path="/etax/sources/quickbooks">
        <ScrollToTop />
        <QuickBooks />
      </Route>
      <Route exact path="/etax/sources/dynamics/crm/connect">
        <ScrollToTop />
        <DynamicsCrmConnect />
      </Route>
      <Route exact path="/etax/sources/dynamics/crm">
        <ScrollToTop />
        <DynamicsCrm />
      </Route>
      <Route exact path="/etax/sources/dynamics/business-central/connect">
        <ScrollToTop />
        <DynamicsBusinessCentralConnect />
      </Route>
      <Route exact path="/etax/sources/dynamics/business-central">
        <ScrollToTop />
        <DynamicsBusinessCentral />
      </Route>
      <Route exact path="/etax/sources/dynamics/finance-operations/connect">
        <ScrollToTop />
        <DynamicsFinanceOperationsConnect />
      </Route>
      <Route exact path="/etax/sources/dynamics/finance-operations">
        <ScrollToTop />
        <DynamicsFinanceOperations />
      </Route>
      <Route exact path="/etax/sources/sap/s4-hana/connect">
        <ScrollToTop />
        <SAPS4HANAConnect />
      </Route>
      <Route exact path="/etax/sources/sap/s4-hana">
        <ScrollToTop />
        <SAPS4HANA />
      </Route>
      <Route exact path="/etax/sources/sap/business-one/connect">
        <ScrollToTop />
        <SAPBusinessOneConnect />
      </Route>
      <Route exact path="/etax/sources/sap/business-one">
        <ScrollToTop />
        <SAPBusinessOne />
      </Route>
      <Route exact path="/etax/sources/zoho/connect">
        <ScrollToTop />
        <ZohoConnect />
      </Route>
      <Route exact path="/etax/sources/zoho">
        <ScrollToTop />
        <Zoho />
      </Route>
      <Route exact path="/documents/quotations">
        <ScrollToTop />
        <QuotationNew />
      </Route>
      <Route exact path="/documents/quotations/confirm">
        <ScrollToTop />
        <QuotationConfirm />
      </Route>
      <Route exact path="/documents/invoices">
        <ScrollToTop />
        <InvoiceNew />
      </Route>
      <Route exact path="/documents/invoices/confirm">
        <ScrollToTop />
        <InvoiceConfirm />
      </Route>
      <Route exact path="/documents/billing-notes">
        <ScrollToTop />
        <BillingNoteNew />
      </Route>
      <Route exact path="/documents/billing-notes/confirm">
        <ScrollToTop />
        <BillingNoteConfirm />
      </Route>
      <Route exact path="/documents/deliveryorders">
        <ScrollToTop />
        <DeliveryOrder />
      </Route>
      <Route exact path="/documents/deliveryorders/confirm">
        <ScrollToTop />
        <DeliveryOrderConfirm />
      </Route>
      <Route exact path="/withholding-taxs/all">
        <ScrollToTop />
        <WithholdingTaxDocuments />
      </Route>
      <Route exact path="/withholding-taxs/excel/upload">
        <ScrollToTop />
        <WithholdingTaxDropzone />
      </Route>
      <Route exact path="/withholding-taxs/excel/check/result">
        <ScrollToTop />
        <WithholdingTaxResult />
      </Route>
      <Route exact path="/withholding-taxs/excel/list">
        <ScrollToTop />
        <WithholdingTaxUploadList />
      </Route>
      <Route exact path="/documents/withholding-taxs">
        <ScrollToTop />
        <WithholdingTaxNew />
      </Route>
      <Route exact path="/documents/withholding-taxs/confirm">
        <ScrollToTop />
        <WithholdingTaxConfirm />
      </Route>
      <Route exact path="/ca/terms-conditions">
        <ScrollToTop />
        <TermsConditions />
      </Route>
      <Route exact path="/ca/companies">
        <ScrollToTop />
        <Companies />
      </Route>
      <Route exact path="/ca/address">
        <ScrollToTop />
        <Address />
      </Route>
      <Route exact path="/ca/contacts">
        <ScrollToTop />
        <Contacts />
      </Route>
      <Route exact path="/ca/confirm">
        <ScrollToTop />
        <Confirm />
      </Route>
      <Route exact path="/ca/documents">
        <ScrollToTop />
        <Documents />
      </Route>
      <Route exact path="/profiles/update">
        <ScrollToTop />
        <ProfileUpdate />
      </Route>
      <Route exact path="/profiles/update/complete">
        <ScrollToTop />
        <ProfileUpdateComplete />
      </Route>
      <Route exact path="/reports/output-taxs">
        <ScrollToTop />
        <OutputTax />
      </Route>
      <Route exact path="/reports/output-taxs/confirm">
        <ScrollToTop />
        <OutputTaxConfirm />
      </Route>
      <Route exact path="/reports/sell-summary">
        <ScrollToTop />
        <SellSummary />
      </Route>
      <Route exact path="/reports/sell-summary/confirm">
        <ScrollToTop />
        <SellSummaryConfirm />
      </Route>
      <Route exact path="/reports/input-taxs">
        <ScrollToTop />
        <InputTax />
      </Route>
      <Route exact path="/reports/input-taxs/confirm">
        <ScrollToTop />
        <InputTaxConfirm />
      </Route>
      <Route exact path="/reports/deliveries/email">
        <ScrollToTop />
        <EmailDeliveries />
      </Route>
      <Route exact path="/reports/deliveries/sms">
        <ScrollToTop />
        <SmsDeliveries />
      </Route>
      <Route exact path="/rdprep/pp30">
        <ScrollToTop />
        <PP30 />
      </Route>
      <Route exact path="/rdprep/pp30/summary">
        <ScrollToTop />
        <PP30Summary />
      </Route>
      <Route exact path="/rdprep/pp30/confirm">
        <ScrollToTop />
        <PP30Confirm />
      </Route>
      <Route exact path="/reports/xml-deliveries">
        <ScrollToTop />
        <XmlUpload />
      </Route>
      <Route exact path="/reports/xml-deliveries/confirm">
        <ScrollToTop />
        <XmlUploadConfirm />
      </Route>
      <Route exact path="/rd/vat">
        <ScrollToTop />
        <RdVat />
      </Route>
      <Route exact path="/rd/taxId">
        <ScrollToTop />
        <RdTaxId />
      </Route>
      <Route exact path="/rd/etax">
        <ScrollToTop />
        <RdEtax />
      </Route>
      <Route exact path="/settings/emails">
        <ScrollToTop />
        <Emails />
      </Route>
      <Route exact path="/settings/storages">
        <ScrollToTop />
        <StoragesConnect />
      </Route>
      <Route exact path="/storages">
        <ScrollToTop />
        <Strorages />
      </Route>
      {/* <Route exact path="/permission/roles">
        <ScrollToTop />
        <Roles />
      </Route> */}
      <Route exact path="/maintenance">
        <ScrollToTop />
        <Maintenance />
      </Route>
      <Redirect to="/etax/documents/all" />
    </Switch>
  );
};

const LinkToFeedback = () => {
  const { t } = useTranslation();

  return (
    // <a
    //   onClick={() => history.push({ pathname: '/supports/feedback', state: {} })}
    //   style={{ color: '#106EBE', cursor: 'pointer', textDecoration: 'underline' }}
    // >
    //   {t('navigation.suggestService')}
    // </a>
    <Link
      to="/supports/feedback"
      style={{ color: '#106EBE', cursor: 'pointer', textDecoration: 'underline' }}
    >
      {t('navigation.suggestService')}
    </Link>
  );
};

const Body = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t } = useTranslation();

  const [companySetting, setCompanySetting] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [isLoadAccountsComplete, setIsLoadAccountsComplete] = useState(false);
  const [accountData, setAccountData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [companyDetailData, setCompanyDetailData] = useState({});

  useEffect(() => {
    console.log('userEffect retrieve company and accounts data!');

    const urlPath = window.location.pathname;
    console.log('Body urlPath: ', urlPath);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        console.log('tokenResponse: ', tokenResponse);

        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data.length === 0) {
                setCompanySetting(true);
                setIsLoading(false);
              } else {
                setCompanySetting(false);

                let _cid = ""
                if (sessionStorage.getItem("companyId") && response.data.find(_company => _company.id === sessionStorage.getItem("companyId"))) {
                  setCompanyData(response.data.find(_company => _company.id === sessionStorage.getItem("companyId")));
                  // sessionStorage.setItem("companyId", response.data[0].id);
                  _cid = sessionStorage.getItem("companyId")
                } else {
                  setCompanyData(response.data[0]);
                  sessionStorage.setItem("companyId", response.data[0].id);

                  _cid = response.data[0].id
                }

                axios
                    // .get(API_URL + '/companies-details/' + response.data[0].id, {
                    .get(API_URL + '/companies-details', {
                        headers: {
                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                            'cid': _cid
                        },
                    })
                    .then((companiesDetailsResponse) => {
                        console.log('companiesDetailsResponse: ', companiesDetailsResponse);
                        if (companiesDetailsResponse.data) {
                            setCompanyDetailData(companiesDetailsResponse.data)
                        }

                        // setIsLoadAccountsComplete(true);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                 }

                //  setIsLoading(false);
            },
            (error) => {
              console.log(error);
            }
          );

        if (urlPath === '/profiles/update') {
          console.log('Update user profile!');

          axios
            .patch(
              API_URL + '/accounts/profiles',
              {},
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              }
            )
            .then(
              (response) => {
                console.log(response);

                if (response.data) {
                  setAccountData(response.data);
                }

                setIsLoadAccountsComplete(true);
              },
              (error) => {
                console.log(error);
              }
            );
        } else {
          axios
            .get(API_URL + '/accounts', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            })
            .then(
              (response) => {
                console.log(response);

                if (response.data) {
                  setAccountData(response.data);
                }

                setIsLoadAccountsComplete(true);
              },
              (error) => {
                console.log(error);
              }
            );
        }
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      {isLoading || !isLoadAccountsComplete ? (
        <div>
          <Loading />
        </div>
      ) : companySetting ? (
        <Stack styles={stackStyles} disableShrink={false} wrap={false}>
          <Stack horizontal>
            <Header
              {...{
                accountData: accountData,
                companyData: { name: 'กรุณาตั้งค่าข้อมูลบริษัท...' },
                companyDetailData: companyDetailData
              }}
            />
          </Stack>
          <Stack horizontal horizontalAlign="center" style={{ background: '#FAF9F8' }}>
            <EtaxCompanies />
          </Stack>
        </Stack>
      ) : isLoadAccountsComplete ? (
        <Stack styles={stackStyles} disableShrink={false} wrap={false}>
          <Stack horizontal>
            <Header {...{ accountData: accountData, companyData: companyData, companyDetailData: companyDetailData }} />
          </Stack>
          <Stack horizontal>
            <Stack
              vertical
              style={{
                background: 'white',
                height: '100%',
                minHeight: 'calc(100vh - 70px)',
              }}
              verticalAlign="space-between"
            >
              <Navigation {...{ accountData: accountData, companyData: companyData, companyDetailData: companyDetailData }} />

              <span style={{ paddingLeft: '25px' }}>
                <span>
                  <b>
                    <Icon iconName="Telemarketer" style={{ fontSize: '20px' }} /> {t('navigation.serviceZone')}
                  </b>
                </span>
                <br />
                <span>
                  <a href="http://nav.cx/3p1xabz" target="_blank" rel="noreferrer">
                    LINE OA: @Leceipt
                  </a>
                </span>
                <br />
                <span>{t('navigation.tel')}</span>
                <br />
                <span>{t('navigation.workdays')}</span>
                <br />
                <span>
                  {t('navigation.version')} {LECEIPT_VERSION}
                </span>
                <br />
                <span>
                  <LinkToFeedback />
                </span>
                <br />
                <br />
              </span>
            </Stack>
            <Stack vertical style={{ background: '#FAF9F8', width: '100%' }}>
              <Stack horizontal horizontalAlign="center" wrap={false}>
                <Pages />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <div>
          <Loading />
        </div>
      )}
    </Router>
  );
};

export default App;
