import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { parse } from 'papaparse';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../../authConfig';

import { Stack, Spinner, Link, Dialog, Icon, DefaultButton, DialogFooter, DialogType } from '@fluentui/react';

var moment = require('moment'); // require
moment().format();

const xlsx = require('xlsx');

const API_URL = process.env.REACT_APP_API_URL;

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== '') return false;
  }
  return true;
}

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const headersPND53 = [
  'เลขที่เอกสาร',
  'เลขประจำตัวผู้เสียภาษีอากร',
  'สาขาที่',
  'คำนำหน้าชื่อ',
  'ชื่อ',
  'ชื่ออาคาร',
  'ห้องเลขที่',
  'ชั้นที่',
  'หมู่บ้าน',
  'เลขที่',
  'หมู่ที่',
  'ตรอก/ซอย',
  'แยก',
  'ถนน',
  'ตำบล/แขวง',
  'อำเภอ/เขต',
  'จังหวัด',
  'รหัสไปรษณีย์',
  'วันเดือนปีที่จ่าย',
  'ประเภทเงินได้',
  'อัตราภาษี',
  'จำนวนเงินได้ที่จ่าย',
  'จำนวนภาษีที่หัก',
  'เงื่อนไขการหักภาษี',
];

const headersPND3 = [
  'เลขที่เอกสาร',
  'เลขประจำตัวผู้เสียภาษีอากร',
  'สาขาที่',
  'คำนำหน้าชื่อ',
  'ชื่อ',
  'ชื่อกลาง',
  'ชื่อสกุล',
  'ชื่ออาคาร',
  'ห้องเลขที่',
  'ชั้นที่',
  'หมู่บ้าน',
  'เลขที่',
  'หมู่ที่',
  'ตรอก/ซอย',
  'แยก',
  'ถนน',
  'ตำบล/แขวง',
  'อำเภอ/เขต',
  'จังหวัด',
  'รหัสไปรษณีย์',
  'วันเดือนปีที่จ่าย',
  'ประเภทเงินได้',
  'อัตราภาษี',
  'จำนวนเงินได้ที่จ่าย',
  'จำนวนภาษีที่หัก',
  'เงื่อนไขการหักภาษี',
];

const headersPND2 = [
  'เลขที่เอกสาร',
  'เงินได้ตามมาตรา',
  'เลขประจำตัวผู้เสียภาษีอากร',
  'คำนำหน้าชื่อ',
  'ชื่อ',
  'ชื่อกลาง',
  'ชื่อสกุล',
  'เลขที่บัญชีเงินฝาก',
  'วันเดือนปีที่จ่าย',
  'อัตราภาษี',
  'จำนวนเงินที่จ่าย',
  'จำนวนเงินภาษีที่หัก',
  'เงื่อนไขการหัก',
];

export const validateDataPND53 = (row) => {
  let error = [];

  if (row['เลขที่เอกสาร'] === '' || row['เลขที่เอกสาร'] === null || row['เลขที่เอกสาร'] === undefined) error.push('เลขที่เอกสาร => ห้ามเว้นว่าง');
  if (row['เลขประจำตัวผู้เสียภาษีอากร'] === '' || row['เลขประจำตัวผู้เสียภาษีอากร'] === null || row['เลขประจำตัวผู้เสียภาษีอากร'] === undefined)
    error.push('เลขประจำตัวผู้เสียภาษีอากร => ห้ามเว้นว่าง');

  if (String(row['เลขประจำตัวผู้เสียภาษีอากร']).length !== 13) error.push('เลขประจำตัวผู้เสียภาษีอากร => มีตัวเลขไม่ถึง 13 หลัก');

  if (isNaN(row['เลขประจำตัวผู้เสียภาษีอากร'])) error.push('เลขประจำตัวผู้เสียภาษีอากร => ต้องมีแต่ตัวเลขเท่านั้น');

  if (row['สาขาที่'] && isNaN(row['สาขาที่'])) error.push('สาขาที่ => ต้องมีแต่ตัวเลขเท่านั้น');

  if (row['ชื่อ'] === '' || row['ชื่อ'] === null || row['ชื่อ'] === undefined) error.push('ชื่อ => ห้ามเว้นว่าง');

  if (row['เลขที่'] === '' || row['เลขที่'] === null || row['เลขที่'] === undefined) error.push('เลขที่ => ห้ามเว้นว่าง');

  if (row['ตำบล/แขวง'] === '' || row['ตำบล/แขวง'] === null || row['ตำบล/แขวง'] === undefined) error.push('ตำบล/แขวง => ห้ามเว้นว่าง');
  if (row['อำเภอ/เขต'] === '' || row['อำเภอ/เขต'] === null || row['อำเภอ/เขต'] === undefined) error.push('อำเภอ/เขต => ห้ามเว้นว่าง');
  if (row['จังหวัด'] === '' || row['จังหวัด'] === null || row['จังหวัด'] === undefined) error.push('จังหวัด => ห้ามเว้นว่าง');

  if (row['รหัสไปรษณีย์'] === '' || row['รหัสไปรษณีย์'] === null || row['รหัสไปรษณีย์'] === undefined) error.push('รหัสไปรษณีย์ => ห้ามเว้นว่าง');
  if (String(row['รหัสไปรษณีย์']).length !== 5) error.push('รหัสไปรษณีย์ => มีตัวเลขไม่ถึง 5 หลัก');
  if (isNaN(row['รหัสไปรษณีย์'])) error.push('รหัสไปรษณีย์ => ต้องมีแต่ตัวเลขเท่านั้น');

  row.incomes.forEach((income) => {
    const dateSplit = income['วันเดือนปีที่จ่าย'].split('/');
    if (!isValidDate(new Date(dateSplit[1] + '/' + dateSplit[0] + '/' + dateSplit[2])))
      error.push(`วันเดือนปีที่จ่าย บรรทัดที่ ${income['บรรทัดที่']} => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี เช่น 13/2/2565`);

    if (income['ประเภทเงินได้'] === '' || income['ประเภทเงินได้'] === null || income['ประเภทเงินได้'] === undefined)
      error.push(`ประเภทเงินได้ บรรทัดที่ ${income['บรรทัดที่']} => ห้ามเว้นว่าง`);

    if (isNaN(income['อัตราภาษี'])) error.push(`อัตราภาษี บรรทัดที่ ${income['บรรทัดที่']} => ต้องมีแต่ตัวเลขเท่านั้น`);
    if (isNaN(income['จำนวนเงินที่จ่าย'])) error.push(`จำนวนเงินที่จ่าย บรรทัดที่ ${income['บรรทัดที่']} => ต้องมีแต่ตัวเลขเท่านั้น`);
    if (isNaN(income['จำนวนเงินภาษีที่หัก'])) error.push(`จำนวนเงินภาษีที่หัก บรรทัดที่ ${income['บรรทัดที่']} => ต้องมีแต่ตัวเลขเท่านั้น`);

    if (isNaN(income['เงื่อนไขการหัก'])) error.push(`เงื่อนไขการหัก บรรทัดที่ ${income['บรรทัดที่']} => ต้องมีแต่ตัวเลขเท่านั้น`);
    if (Number(income['เงื่อนไขการหัก']) !== 1 && Number(income['เงื่อนไขการหัก']) !== 2)
      error.push(`เงื่อนไขการหัก บรรทัดที่ ${income['บรรทัดที่']} => ให้ระบุแค่ 1 หรือ 2 เท่านั้น`);
  });

  return error;
};

export const validateDataPND53EN = (row) => {
  let error = [];

  if (row['เลขที่เอกสาร'] === '' || row['เลขที่เอกสาร'] === null || row['เลขที่เอกสาร'] === undefined)
    error.push('เลขที่เอกสาร => Must not be empty');
  if (row['เลขประจำตัวผู้เสียภาษีอากร'] === '' || row['เลขประจำตัวผู้เสียภาษีอากร'] === null || row['เลขประจำตัวผู้เสียภาษีอากร'] === undefined)
    error.push('เลขประจำตัวผู้เสียภาษีอากร => Must not be empty');

  if (String(row['เลขประจำตัวผู้เสียภาษีอากร']).length !== 13) error.push('เลขประจำตัวผู้เสียภาษีอากร => Tax ID must contain 13 digit numbers');

  if (isNaN(row['เลขประจำตัวผู้เสียภาษีอากร'])) error.push('เลขประจำตัวผู้เสียภาษีอากร => The value must be number only');

  if (row['สาขาที่'] && isNaN(row['สาขาที่'])) error.push('สาขาที่ => The value must be number only');

  if (row['ชื่อ'] === '' || row['ชื่อ'] === null || row['ชื่อ'] === undefined) error.push('ชื่อ => Must not be empty');

  if (row['เลขที่'] === '' || row['เลขที่'] === null || row['เลขที่'] === undefined) error.push('เลขที่ => Must not be empty');

  if (row['ตำบล/แขวง'] === '' || row['ตำบล/แขวง'] === null || row['ตำบล/แขวง'] === undefined) error.push('ตำบล/แขวง => Must not be empty');
  if (row['อำเภอ/เขต'] === '' || row['อำเภอ/เขต'] === null || row['อำเภอ/เขต'] === undefined) error.push('อำเภอ/เขต => Must not be empty');
  if (row['จังหวัด'] === '' || row['จังหวัด'] === null || row['จังหวัด'] === undefined) error.push('จังหวัด => Must not be empty');

  if (row['รหัสไปรษณีย์'] === '' || row['รหัสไปรษณีย์'] === null || row['รหัสไปรษณีย์'] === undefined)
    error.push('รหัสไปรษณีย์ => Must not be empty');
  if (String(row['รหัสไปรษณีย์']).length !== 5) error.push('รหัสไปรษณีย์ => Postcode must contain 5 digit numbers');
  if (isNaN(row['รหัสไปรษณีย์'])) error.push('รหัสไปรษณีย์ => The value must be number only');

  row.incomes.forEach((income) => {
    const dateSplit = income['วันเดือนปีที่จ่าย'].split('/');
    if (!isValidDate(new Date(dateSplit[1] + '/' + dateSplit[0] + '/' + dateSplit[2])))
      error.push(`วันเดือนปีที่จ่าย in row: ${income['บรรทัดที่']} => The date format must be dd/mm/yyyy only. ex: 13/2/2565`);

    if (income['ประเภทเงินได้'] === '' || income['ประเภทเงินได้'] === null || income['ประเภทเงินได้'] === undefined)
      error.push(`ประเภทเงินได้ in row: ${income['บรรทัดที่']} => Must not be empty`);

    if (isNaN(income['อัตราภาษี'])) error.push(`อัตราภาษี in row: ${income['บรรทัดที่']} => The value must be number only`);
    if (isNaN(income['จำนวนเงินที่จ่าย'])) error.push(`จำนวนเงินที่จ่าย in row: ${income['บรรทัดที่']} => The value must be number only`);
    if (isNaN(income['จำนวนเงินภาษีที่หัก'])) error.push(`จำนวนเงินภาษีที่หัก in row: ${income['บรรทัดที่']} => The value must be number only`);

    if (isNaN(income['เงื่อนไขการหัก'])) error.push(`เงื่อนไขการหัก in row: ${income['บรรทัดที่']} => The value must be number only`);
    if (Number(income['เงื่อนไขการหัก']) !== 1 && Number(income['เงื่อนไขการหัก']) !== 2)
      error.push(`เงื่อนไขการหัก in row: ${income['บรรทัดที่']} => The value can be only 1, 2`);
  });

  return error;
};

export const validateDataPND3 = (row) => {
  let error = [];

  if (row['เลขที่เอกสาร'] === '' || row['เลขที่เอกสาร'] === null || row['เลขที่เอกสาร'] === undefined) error.push('เลขที่เอกสาร => ห้ามเว้นว่าง');
  if (row['เลขประจำตัวผู้เสียภาษีอากร'] === '' || row['เลขประจำตัวผู้เสียภาษีอากร'] === null || row['เลขประจำตัวผู้เสียภาษีอากร'] === undefined)
    error.push('เลขประจำตัวผู้เสียภาษีอากร => ห้ามเว้นว่าง');

  if (String(row['เลขประจำตัวผู้เสียภาษีอากร']).length !== 13) error.push('เลขประจำตัวผู้เสียภาษีอากร => มีตัวเลขไม่ถึง 13 หลัก');

  if (isNaN(row['เลขประจำตัวผู้เสียภาษีอากร'])) error.push('เลขประจำตัวผู้เสียภาษีอากร => ต้องมีแต่ตัวเลขเท่านั้น');

  if (row['สาขาที่'] && isNaN(row['สาขาที่'])) error.push('สาขาที่ => ต้องมีแต่ตัวเลขเท่านั้น');

  if (row['ชื่อ'] === '' || row['ชื่อ'] === null || row['ชื่อ'] === undefined) error.push('ชื่อ => ห้ามเว้นว่าง');

  if (row['เลขที่'] === '' || row['เลขที่'] === null || row['เลขที่'] === undefined) error.push('เลขที่ => ห้ามเว้นว่าง');

  if (row['ตำบล/แขวง'] === '' || row['ตำบล/แขวง'] === null || row['ตำบล/แขวง'] === undefined) error.push('ตำบล/แขวง => ห้ามเว้นว่าง');
  if (row['อำเภอ/เขต'] === '' || row['อำเภอ/เขต'] === null || row['อำเภอ/เขต'] === undefined) error.push('อำเภอ/เขต => ห้ามเว้นว่าง');
  if (row['จังหวัด'] === '' || row['จังหวัด'] === null || row['จังหวัด'] === undefined) error.push('จังหวัด => ห้ามเว้นว่าง');

  if (row['รหัสไปรษณีย์'] === '' || row['รหัสไปรษณีย์'] === null || row['รหัสไปรษณีย์'] === undefined) error.push('รหัสไปรษณีย์ => ห้ามเว้นว่าง');
  if (String(row['รหัสไปรษณีย์']).length !== 5) error.push('รหัสไปรษณีย์ => มีตัวเลขไม่ถึง 5 หลัก');
  if (isNaN(row['รหัสไปรษณีย์'])) error.push('รหัสไปรษณีย์ => ต้องมีแต่ตัวเลขเท่านั้น');

  row.incomes.forEach((income) => {
    const dateSplit = income['วันเดือนปีที่จ่าย'].split('/');
    if (!isValidDate(new Date(dateSplit[1] + '/' + dateSplit[0] + '/' + dateSplit[2])))
      error.push(`วันเดือนปีที่จ่าย บรรทัดที่ ${income['บรรทัดที่']} => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี เช่น 13/2/2565`);

    if (income['ประเภทเงินได้'] === '' || income['ประเภทเงินได้'] === null || income['ประเภทเงินได้'] === undefined)
      error.push(`ประเภทเงินได้ บรรทัดที่ ${income['บรรทัดที่']} => ห้ามเว้นว่าง`);

    if (isNaN(income['อัตราภาษี'])) error.push(`อัตราภาษี บรรทัดที่ ${income['บรรทัดที่']} => ต้องมีแต่ตัวเลขเท่านั้น`);
    if (isNaN(income['จำนวนเงินที่จ่าย'])) error.push(`จำนวนเงินที่จ่าย บรรทัดที่ ${income['บรรทัดที่']} => ต้องมีแต่ตัวเลขเท่านั้น`);
    if (isNaN(income['จำนวนเงินภาษีที่หัก'])) error.push(`จำนวนเงินภาษีที่หัก บรรทัดที่ ${income['บรรทัดที่']} => ต้องมีแต่ตัวเลขเท่านั้น`);

    if (isNaN(income['เงื่อนไขการหัก'])) error.push(`เงื่อนไขการหัก บรรทัดที่ ${income['บรรทัดที่']} => ต้องมีแต่ตัวเลขเท่านั้น`);
    if (Number(income['เงื่อนไขการหัก']) !== 1 && Number(income['เงื่อนไขการหัก']) !== 2 && Number(income['เงื่อนไขการหัก']) !== 3)
      error.push(`เงื่อนไขการหัก บรรทัดที่ ${income['บรรทัดที่']} => ให้ระบุแค่ 1, 2, 3 เท่านั้น`);
  });

  return error;
};

export const validateDataPND3EN = (row) => {
  let error = [];

  if (row['เลขที่เอกสาร'] === '' || row['เลขที่เอกสาร'] === null || row['เลขที่เอกสาร'] === undefined)
    error.push('เลขที่เอกสาร => Must not be empty');
  if (row['เลขประจำตัวผู้เสียภาษีอากร'] === '' || row['เลขประจำตัวผู้เสียภาษีอากร'] === null || row['เลขประจำตัวผู้เสียภาษีอากร'] === undefined)
    error.push('เลขประจำตัวผู้เสียภาษีอากร => Must not be empty');

  if (String(row['เลขประจำตัวผู้เสียภาษีอากร']).length !== 13) error.push('เลขประจำตัวผู้เสียภาษีอากร => Tax ID must contain 13 digit numbers');

  if (isNaN(row['เลขประจำตัวผู้เสียภาษีอากร'])) error.push('เลขประจำตัวผู้เสียภาษีอากร => The value must be number only');

  if (row['สาขาที่'] && isNaN(row['สาขาที่'])) error.push('สาขาที่ => The value must be number only');

  if (row['ชื่อ'] === '' || row['ชื่อ'] === null || row['ชื่อ'] === undefined) error.push('ชื่อ => Must not be empty');

  if (row['เลขที่'] === '' || row['เลขที่'] === null || row['เลขที่'] === undefined) error.push('เลขที่ => Must not be empty');

  if (row['ตำบล/แขวง'] === '' || row['ตำบล/แขวง'] === null || row['ตำบล/แขวง'] === undefined) error.push('ตำบล/แขวง => Must not be empty');
  if (row['อำเภอ/เขต'] === '' || row['อำเภอ/เขต'] === null || row['อำเภอ/เขต'] === undefined) error.push('อำเภอ/เขต => Must not be empty');
  if (row['จังหวัด'] === '' || row['จังหวัด'] === null || row['จังหวัด'] === undefined) error.push('จังหวัด => Must not be empty');

  if (row['รหัสไปรษณีย์'] === '' || row['รหัสไปรษณีย์'] === null || row['รหัสไปรษณีย์'] === undefined)
    error.push('รหัสไปรษณีย์ => Must not be empty');
  if (String(row['รหัสไปรษณีย์']).length !== 5) error.push('รหัสไปรษณีย์ => Postcode must contain 5 digit numbers');
  if (isNaN(row['รหัสไปรษณีย์'])) error.push('รหัสไปรษณีย์ => The value must be number only');

  row.incomes.forEach((income) => {
    const dateSplit = income['วันเดือนปีที่จ่าย'].split('/');
    if (!isValidDate(new Date(dateSplit[1] + '/' + dateSplit[0] + '/' + dateSplit[2])))
      error.push(`วันเดือนปีที่จ่าย in row: ${income['บรรทัดที่']} => The date format must be dd/mm/yyyy only. ex: 13/2/2565`);

    if (income['ประเภทเงินได้'] === '' || income['ประเภทเงินได้'] === null || income['ประเภทเงินได้'] === undefined)
      error.push(`ประเภทเงินได้ in row: ${income['บรรทัดที่']} => Must not be empty`);

    if (isNaN(income['อัตราภาษี'])) error.push(`อัตราภาษี in row: ${income['บรรทัดที่']} => The value must be number only`);
    if (isNaN(income['จำนวนเงินที่จ่าย'])) error.push(`จำนวนเงินที่จ่าย in row: ${income['บรรทัดที่']} => The value must be number only`);
    if (isNaN(income['จำนวนเงินภาษีที่หัก'])) error.push(`จำนวนเงินภาษีที่หัก in row: ${income['บรรทัดที่']} => The value must be number only`);

    if (isNaN(income['เงื่อนไขการหัก'])) error.push(`เงื่อนไขการหัก in row: ${income['บรรทัดที่']} => The value must be number only`);
    if (Number(income['เงื่อนไขการหัก']) !== 1 && Number(income['เงื่อนไขการหัก']) !== 2 && Number(income['เงื่อนไขการหัก']) !== 3)
      error.push(`เงื่อนไขการหัก in row: ${income['บรรทัดที่']} => The value can be only 1, 2, 3`);
  });

  return error;
};

export const validateDataPND2 = (row) => {
  let error = [];

  if (row['เลขที่เอกสาร'] === '' || row['เลขที่เอกสาร'] === null || row['เลขที่เอกสาร'] === undefined) error.push('เลขที่เอกสาร => ห้ามเว้นว่าง');

  if (row['เงินได้ตามมาตรา'] === '' || row['เงินได้ตามมาตรา'] === null || row['เงินได้ตามมาตรา'] === undefined)
    error.push('เงินได้ตามมาตรา => ห้ามเว้นว่าง');

  if (row['เลขประจำตัวผู้เสียภาษีอากร'] === '' || row['เลขประจำตัวผู้เสียภาษีอากร'] === null || row['เลขประจำตัวผู้เสียภาษีอากร'] === undefined)
    error.push('เลขประจำตัวผู้เสียภาษีอากร => ห้ามเว้นว่าง');

  if (String(row['เลขประจำตัวผู้เสียภาษีอากร']).length !== 13) error.push('เลขประจำตัวผู้เสียภาษีอากร => มีตัวเลขไม่ถึง 13 หลัก');

  if (isNaN(row['เลขประจำตัวผู้เสียภาษีอากร'])) error.push('เลขประจำตัวผู้เสียภาษีอากร => ต้องมีแต่ตัวเลขเท่านั้น');

  if (row['ชื่อ'] === '' || row['ชื่อ'] === null || row['ชื่อ'] === undefined) error.push('ชื่อ => ห้ามเว้นว่าง');

  row.incomes.forEach((income) => {
    const dateSplit = income['วันเดือนปีที่จ่าย'].split('/');
    if (!isValidDate(new Date(dateSplit[1] + '/' + dateSplit[0] + '/' + dateSplit[2])))
      error.push(`วันเดือนปีที่จ่าย บรรทัดที่ ${income['บรรทัดที่']} => รูปแบบวันที่ต้องเป็น วัน/เดือน/ปี เช่น 13/2/2565`);

    if (isNaN(income['อัตราภาษี'])) error.push(`อัตราภาษี บรรทัดที่ ${income['บรรทัดที่']} => ต้องมีแต่ตัวเลขเท่านั้น`);
    if (isNaN(income['จำนวนเงินที่จ่าย'])) error.push(`จำนวนเงินที่จ่าย บรรทัดที่ ${income['บรรทัดที่']} => ต้องมีแต่ตัวเลขเท่านั้น`);
    if (isNaN(income['จำนวนเงินภาษีที่หัก'])) error.push(`จำนวนเงินภาษีที่หัก บรรทัดที่ ${income['บรรทัดที่']} => ต้องมีแต่ตัวเลขเท่านั้น`);

    if (isNaN(income['เงื่อนไขการหัก'])) error.push(`เงื่อนไขการหัก บรรทัดที่ ${income['บรรทัดที่']} => ต้องมีแต่ตัวเลขเท่านั้น`);
    if (Number(income['เงื่อนไขการหัก']) !== 1 && Number(income['เงื่อนไขการหัก']) !== 1)
      error.push(`เงื่อนไขการหัก บรรทัดที่ ${income['บรรทัดที่']} => ให้ระบุแค่ 1 หรือ 2 เท่านั้น`);
  });

  return error;
};

export const validateDataPND2EN = (row) => {
  let error = [];

  if (row['เลขที่เอกสาร'] === '' || row['เลขที่เอกสาร'] === null || row['เลขที่เอกสาร'] === undefined)
    error.push('เลขที่เอกสาร => Must not be empty');

  if (row['เงินได้ตามมาตรา'] === '' || row['เงินได้ตามมาตรา'] === null || row['เงินได้ตามมาตรา'] === undefined)
    error.push('เงินได้ตามมาตรา => Must not be empty');

  if (row['เลขประจำตัวผู้เสียภาษีอากร'] === '' || row['เลขประจำตัวผู้เสียภาษีอากร'] === null || row['เลขประจำตัวผู้เสียภาษีอากร'] === undefined)
    error.push('เลขประจำตัวผู้เสียภาษีอากร => Must not be empty');

  if (String(row['เลขประจำตัวผู้เสียภาษีอากร']).length !== 13) error.push('เลขประจำตัวผู้เสียภาษีอากร => Tax ID must contain 13 digit numbers');

  if (isNaN(row['เลขประจำตัวผู้เสียภาษีอากร'])) error.push('เลขประจำตัวผู้เสียภาษีอากร => The value must be number only');

  if (row['ชื่อ'] === '' || row['ชื่อ'] === null || row['ชื่อ'] === undefined) error.push('ชื่อ => Must not be empty');

  row.incomes.forEach((income) => {
    const dateSplit = income['วันเดือนปีที่จ่าย'].split('/');
    if (!isValidDate(new Date(dateSplit[1] + '/' + dateSplit[0] + '/' + dateSplit[2])))
      error.push(`วันเดือนปีที่จ่าย in row: ${income['บรรทัดที่']} => The date format must be dd/mm/yyyy only. ex: 13/2/2565`);

    if (isNaN(income['อัตราภาษี'])) error.push(`อัตราภาษี in row: ${income['บรรทัดที่']} => The value must be number only`);
    if (isNaN(income['จำนวนเงินที่จ่าย'])) error.push(`จำนวนเงินที่จ่าย in row: ${income['บรรทัดที่']} => The value must be number only`);
    if (isNaN(income['จำนวนเงินภาษีที่หัก'])) error.push(`จำนวนเงินภาษีที่หัก in row: ${income['บรรทัดที่']} => The value must be number only`);

    if (isNaN(income['เงื่อนไขการหัก'])) error.push(`เงื่อนไขการหัก in row: ${income['บรรทัดที่']} => The value must be number only`);
    if (Number(income['เงื่อนไขการหัก']) !== 1 && Number(income['เงื่อนไขการหัก']) !== 1)
      error.push(`เงื่อนไขการหัก in row: ${income['บรรทัดที่']} => The value can be only 1, 2`);
  });

  return error;
};

export default function WithholdingTaxDropzone(props) {
  const history = useHistory();

  const { t } = useTranslation();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const companyId = sessionStorage.getItem('companyId') || '';

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: '.csv, .xlsx, .xls',
    multiple: false,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const [acceptedFilesList, setAcceptedFilesList] = useState([]);

  const [company, setCompany] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [dialogAlert, setDialogAlert] = useState({
    isClick: false,
    message: '',
  });

  useEffect(() => {
    let newList = [...acceptedFilesList, ...acceptedFiles];
    setAcceptedFilesList(newList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const openFile = (event, item) => {
    history.push({
      pathname: event === 'pass' ? '/withholding-taxs/excel/list' : '/withholding-taxs/excel/check/result',
      state: {
        items: item,
      },
    });
  };

  const openFileFail = (item, mergeArray, pnd) => {
    history.push({
      pathname: '/withholding-taxs/excel/check/result',
      state: {
        items: item,
        mergeArray: mergeArray,
        pnd: pnd,
      },
    });
  };

  useEffect(() => {
    acceptedFilesList.map(async (file) => {
      if (file.name.includes('.csv')) {
        const text = await file.text();
        const result = parse(text, { header: true });

        let filterData = result.data.filter((obj) => !isEmpty(obj));

        let dataWithoutEmptyKey = [];
        filterData.forEach((obj) => {
          delete obj[''];
          dataWithoutEmptyKey.push(obj);
        });

        if (
          !dataWithoutEmptyKey[0] ||
          (headersPND53.sort().join(',') !== Object.keys(dataWithoutEmptyKey[0]).sort().join(',') &&
            headersPND3.sort().join(',') !== Object.keys(dataWithoutEmptyKey[0]).sort().join(',') &&
            headersPND2.sort().join(',') !== Object.keys(dataWithoutEmptyKey[0]).sort().join(','))
        ) {
          setDialogAlert((prev) => ({
            ...prev,
            isClick: true,
            message: t('whtDropzone.dropzoneAlertCSV'),
          }));

          return setAcceptedFilesList([]);
        }

        let mergeArray;

        let failData = [];
        let _pnd = '';

        if (headersPND53.sort().join(',') === Object.keys(dataWithoutEmptyKey[0]).sort().join(',')) {
          mergeArray = mergePND53(dataWithoutEmptyKey);

          _pnd = 'PND53';

          mergeArray.forEach((row, index) => {
            if (validateDataPND53(row).length > 0) {
              failData.push({
                number: row['เลขที่เอกสาร'],
                taxId: row['เลขประจำตัวผู้เสียภาษีอากร'],
                dateBE: row['วันเดือนปีที่จ่าย'],
                error: validateDataPND53(row),
              });
            } else {
              row.company = company;
              row.company.taxIdForm = {
                zero: company.taxId[0],
                one: company.taxId[1],
                two: company.taxId[2],
                three: company.taxId[3],
                four: company.taxId[4],
                five: company.taxId[5],
                six: company.taxId[6],
                seven: company.taxId[7],
                eight: company.taxId[8],
                nine: company.taxId[9],
                ten: company.taxId[10],
                eleven: company.taxId[11],
                twelve: company.taxId[12],
              };
              row.pnd = 'PND53';
              row.key = index + 1;
            }
          });
        } else if (headersPND3.sort().join(',') === Object.keys(dataWithoutEmptyKey[0]).sort().join(',')) {
          mergeArray = mergePND3(dataWithoutEmptyKey);

          _pnd = 'PND3';

          mergeArray.forEach((row, index) => {
            if (validateDataPND3(row).length > 0) {
              failData.push({
                number: row['เลขที่เอกสาร'],
                taxId: row['เลขประจำตัวผู้เสียภาษีอากร'],
                dateBE: row['วันเดือนปีที่จ่าย'],
                error: validateDataPND3(row),
              });
            } else {
              row.company = company;
              row.company.taxIdForm = {
                zero: company.taxId[0],
                one: company.taxId[1],
                two: company.taxId[2],
                three: company.taxId[3],
                four: company.taxId[4],
                five: company.taxId[5],
                six: company.taxId[6],
                seven: company.taxId[7],
                eight: company.taxId[8],
                nine: company.taxId[9],
                ten: company.taxId[10],
                eleven: company.taxId[11],
                twelve: company.taxId[12],
              };
              row.pnd = 'PND3';
              row.key = index + 1;
            }
          });
        } else if (headersPND2.sort().join(',') === Object.keys(dataWithoutEmptyKey[0]).sort().join(',')) {
          mergeArray = mergePND2(dataWithoutEmptyKey);

          _pnd = 'PND2';

          mergeArray.forEach((row, index) => {
            if (validateDataPND2(row).length > 0) {
              failData.push({
                number: row['เลขที่เอกสาร'],
                taxId: row['เลขประจำตัวผู้เสียภาษีอากร'],
                dateBE: row['วันเดือนปีที่จ่าย'],
                error: validateDataPND2(row),
              });
            } else {
              row.company = company;
              row.company.taxIdForm = {
                zero: company.taxId[0],
                one: company.taxId[1],
                two: company.taxId[2],
                three: company.taxId[3],
                four: company.taxId[4],
                five: company.taxId[5],
                six: company.taxId[6],
                seven: company.taxId[7],
                eight: company.taxId[8],
                nine: company.taxId[9],
                ten: company.taxId[10],
                eleven: company.taxId[11],
                twelve: company.taxId[12],
              };
              row.pnd = 'PND2';
              row.key = index + 1;
            }
          });
        }

        if (failData.length > 0) {
          setAcceptedFilesList([]);
          // openFile('fail', failData);
          openFileFail(failData, mergeArray, _pnd);
        } else {
          openFile('pass', mergeArray);
        }
      } else {
        readExcel(file);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFilesList]);

  const readExcel = (file) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      const bufferArray = e.target.result;

      const workbook = xlsx.read(bufferArray, { type: 'buffer' });

      const worksheetName = workbook.SheetNames[0];

      const worksheet = workbook.Sheets[worksheetName];

      const columnsArray = xlsx.utils.sheet_to_json(worksheet, {
        header: 1,
      })[0];

      if (
        !columnsArray ||
        (headersPND53.sort().join(',') !== columnsArray.sort().join(',') &&
          headersPND3.sort().join(',') !== columnsArray.sort().join(',') &&
          headersPND2.sort().join(',') !== columnsArray.sort().join(','))
      ) {
        setDialogAlert((prev) => ({
          ...prev,
          isClick: true,
          message: t('whtDropzone.dropzoneAlertExcel'),
        }));

        return setAcceptedFilesList([]);
      }

      const data = xlsx.utils.sheet_to_json(worksheet);

      let mergeArray;

      let failData = [];
      let _pnd = '';

      if (headersPND53.sort().join(',') === columnsArray.sort().join(',')) {
        mergeArray = mergePND53(data);

        _pnd = 'PND53';

        mergeArray.forEach((row, index) => {
          if (validateDataPND53(row).length > 0) {
            failData.push({
              number: row['เลขที่เอกสาร'],
              taxId: row['เลขประจำตัวผู้เสียภาษีอากร'],
              dateBE: row['วันเดือนปีที่จ่าย'],
              error: validateDataPND53(row),
            });
          } else {
            row.company = company;
            row.company.taxIdForm = {
              zero: company.taxId[0],
              one: company.taxId[1],
              two: company.taxId[2],
              three: company.taxId[3],
              four: company.taxId[4],
              five: company.taxId[5],
              six: company.taxId[6],
              seven: company.taxId[7],
              eight: company.taxId[8],
              nine: company.taxId[9],
              ten: company.taxId[10],
              eleven: company.taxId[11],
              twelve: company.taxId[12],
            };
            row.pnd = 'PND53';
            row.key = index + 1;
          }
        });
      } else if (headersPND3.sort().join(',') === columnsArray.sort().join(',')) {
        mergeArray = mergePND3(data);

        _pnd = 'PND3';

        mergeArray.forEach((row, index) => {
          if (validateDataPND3(row).length > 0) {
            failData.push({
              number: row['เลขที่เอกสาร'],
              taxId: row['เลขประจำตัวผู้เสียภาษีอากร'],
              dateBE: row['วันเดือนปีที่จ่าย'],
              error: validateDataPND3(row),
            });
          } else {
            row.company = company;
            row.company.taxIdForm = {
              zero: company.taxId[0],
              one: company.taxId[1],
              two: company.taxId[2],
              three: company.taxId[3],
              four: company.taxId[4],
              five: company.taxId[5],
              six: company.taxId[6],
              seven: company.taxId[7],
              eight: company.taxId[8],
              nine: company.taxId[9],
              ten: company.taxId[10],
              eleven: company.taxId[11],
              twelve: company.taxId[12],
            };
            row.pnd = 'PND3';
            row.key = index + 1;
          }
        });
      } else if (headersPND2.sort().join(',') === columnsArray.sort().join(',')) {
        mergeArray = mergePND2(data);

        _pnd = 'PND2';

        mergeArray.forEach((row, index) => {
          if (validateDataPND2(row).length > 0) {
            failData.push({
              number: row['เลขที่เอกสาร'],
              taxId: row['เลขประจำตัวผู้เสียภาษีอากร'],
              dateBE: row['วันเดือนปีที่จ่าย'],
              error: validateDataPND2(row),
            });
          } else {
            row.company = company;
            row.company.taxIdForm = {
              zero: company.taxId[0],
              one: company.taxId[1],
              two: company.taxId[2],
              three: company.taxId[3],
              four: company.taxId[4],
              five: company.taxId[5],
              six: company.taxId[6],
              seven: company.taxId[7],
              eight: company.taxId[8],
              nine: company.taxId[9],
              ten: company.taxId[10],
              eleven: company.taxId[11],
              twelve: company.taxId[12],
            };
            row.pnd = 'PND2';
            row.key = index + 1;
          }
        });
      }

      if (mergeArray.length > 501) {
        setDialogAlert((prev) => ({
          ...prev,
          isClick: true,
          message: t('whtDropzone.dropzoneAlert500'),
        }));

        setAcceptedFilesList([]);
      } else {
        if (failData.length > 0) {
          setAcceptedFilesList([]);
          // openFile('fail', failData);
          openFileFail(failData, mergeArray, _pnd);
        } else {
          openFile('pass', mergeArray);
        }
      }
    };
  };

  const mergePND53 = (data) => {
    let mergeArray = [];

    for (let i = 0; i < data.length; i++) {
      if (mergeArray[mergeArray.length - 1] !== undefined && mergeArray[mergeArray.length - 1]['เลขที่เอกสาร'] === data[i]['เลขที่เอกสาร']) {
        mergeArray[mergeArray.length - 1].incomes.push({
          บรรทัดที่: i + 2,
          ลำดับ: mergeArray[mergeArray.length - 1].incomes.length + 1,
          วันเดือนปีที่จ่าย: data[i]['วันเดือนปีที่จ่าย'],
          ประเภทเงินได้: data[i]['ประเภทเงินได้'],
          อัตราภาษี: data[i]['อัตราภาษี'],
          จำนวนเงินที่จ่าย: data[i]['จำนวนเงินได้ที่จ่าย'],
          จำนวนเงินภาษีที่หัก: data[i]['จำนวนภาษีที่หัก'],
          เงื่อนไขการหัก: data[i]['เงื่อนไขการหักภาษี'] || 1,
        });
      } else {
        let newDataObject = { ...data[i] };

        console.log(newDataObject);

        newDataObject.incomes = [
          {
            บรรทัดที่: i + 2,
            ลำดับ: 1,
            วันเดือนปีที่จ่าย: data[i]['วันเดือนปีที่จ่าย'],
            ประเภทเงินได้: data[i]['ประเภทเงินได้'],
            อัตราภาษี: data[i]['อัตราภาษี'],
            จำนวนเงินที่จ่าย: data[i]['จำนวนเงินได้ที่จ่าย'],
            จำนวนเงินภาษีที่หัก: data[i]['จำนวนภาษีที่หัก'],
            เงื่อนไขการหัก: data[i]['เงื่อนไขการหักภาษี'] || 1,
          },
        ];

        mergeArray.push(newDataObject);
      }
    }

    return mergeArray;
  };

  const mergePND3 = (data) => {
    let mergeArray = [];

    for (let i = 0; i < data.length; i++) {
      if (mergeArray[mergeArray.length - 1] !== undefined && mergeArray[mergeArray.length - 1]['เลขที่เอกสาร'] === data[i]['เลขที่เอกสาร']) {
        mergeArray[mergeArray.length - 1].incomes.push({
          บรรทัดที่: i + 2,
          ลำดับ: mergeArray[mergeArray.length - 1].incomes.length + 1,
          วันเดือนปีที่จ่าย: data[i]['วันเดือนปีที่จ่าย'],
          ประเภทเงินได้: data[i]['ประเภทเงินได้'],
          อัตราภาษี: data[i]['อัตราภาษี'],
          จำนวนเงินที่จ่าย: data[i]['จำนวนเงินได้ที่จ่าย'],
          จำนวนเงินภาษีที่หัก: data[i]['จำนวนภาษีที่หัก'],
          เงื่อนไขการหัก: data[i]['เงื่อนไขการหักภาษี'] || 1,
        });
      } else {
        let newDataObject = { ...data[i] };

        newDataObject.incomes = [
          {
            บรรทัดที่: i + 2,
            ลำดับ: 1,
            วันเดือนปีที่จ่าย: data[i]['วันเดือนปีที่จ่าย'],
            ประเภทเงินได้: data[i]['ประเภทเงินได้'],
            อัตราภาษี: data[i]['อัตราภาษี'],
            จำนวนเงินที่จ่าย: data[i]['จำนวนเงินได้ที่จ่าย'],
            จำนวนเงินภาษีที่หัก: data[i]['จำนวนภาษีที่หัก'],
            เงื่อนไขการหัก: data[i]['เงื่อนไขการหักภาษี'] || 1,
          },
        ];

        mergeArray.push(newDataObject);
      }
    }

    return mergeArray;
  };

  const mergePND2 = (data) => {
    let mergeArray = [];

    for (let i = 0; i < data.length; i++) {
      if (mergeArray[mergeArray.length - 1] !== undefined && mergeArray[mergeArray.length - 1]['เลขที่เอกสาร'] === data[i]['เลขที่เอกสาร']) {
        mergeArray[mergeArray.length - 1].incomes.push({
          บรรทัดที่: i + 2,
          ลำดับ: mergeArray[mergeArray.length - 1].incomes.length + 1,
          วันเดือนปีที่จ่าย: data[i]['วันเดือนปีที่จ่าย'],
          เงินได้ตามมาตรา: data[i]['เงินได้ตามมาตรา'],
          อัตราภาษี: data[i]['อัตราภาษี'],
          จำนวนเงินที่จ่าย: data[i]['จำนวนเงินที่จ่าย'],
          จำนวนเงินภาษีที่หัก: data[i]['จำนวนเงินภาษีที่หัก'],
          เงื่อนไขการหัก: data[i]['เงื่อนไขการหัก'] || 1,
        });
      } else {
        let newDataObject = { ...data[i] };

        newDataObject.incomes = [
          {
            บรรทัดที่: i + 2,
            ลำดับ: 1,
            วันเดือนปีที่จ่าย: data[i]['วันเดือนปีที่จ่าย'],
            เงินได้ตามมาตรา: data[i]['เงินได้ตามมาตรา'],
            อัตราภาษี: data[i]['อัตราภาษี'],
            จำนวนเงินที่จ่าย: data[i]['จำนวนเงินที่จ่าย'],
            จำนวนเงินภาษีที่หัก: data[i]['จำนวนเงินภาษีที่หัก'],
            เงื่อนไขการหัก: data[i]['เงื่อนไขการหัก'] || 1,
          },
        ];

        mergeArray.push(newDataObject);
      }
    }

    return mergeArray;
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        //Check documents spending limit
        await axios
          .get(API_URL + '/subscriptions', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log('Subscriptions data: ', response);

              if (response.data) {
                if (response.data && response.data.documents) {
                  console.log('Subscription etax documents: ', response.data.documents);

                  let _subscriptionDocuments = response.data.documents;

                  if (_subscriptionDocuments.spent + 1 > _subscriptionDocuments.limit + _subscriptionDocuments.topup) {
                    history.push({
                      pathname: '/billings',
                      state: {},
                    });
                  }
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );

        await axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                  
                  setCompany(_company);
                  setIsLoading(false);
                } else {
                  setCompany(response.data[0]);
                  setIsLoading(false);
                }
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {isLoading ? (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              <Spinner label={t('whtDropzone.processing')} labelPosition="down" styles={spinnerStyles} />
            </div>
            <br />
          </Stack>
        </Stack>
      ) : (
        <>
          <div>
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1>{t('whtDropzone.header')}</h1>
            </Stack>
            <br />
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <div className="center">
                <button type="button" style={{ padding: '10px 20px', fontSize: '16px' }} onClick={open}>
                  {t('whtDropzone.upload')}
                </button>
                <p>{t('whtDropzone.uploadDescription')}</p>
              </div>
            </div>
          </div>
          <br />
          <br />
          <Stack vertical horizontalAlign="center">
            <Link href="https://www.leceipt.com/docs/etax/สร้าง50ทวิ-หลายเอกสาร" target="_blank" underline>
              {t('whtDropzone.article')}
            </Link>
            <br />
            <Link href="/excel-templates/PND-2-Template.csv" target="_blank" underline>
              {t('whtDropzone.pnd2')}
            </Link>
            <br />
            <Link href="/excel-templates/PND-3-Template.csv" target="_blank" underline>
              {t('whtDropzone.pnd3')}
            </Link>
            <br />
            <Link href="/excel-templates/PND-53-Template.csv" target="_blank" underline>
              {t('whtDropzone.pnd53')}
            </Link>
          </Stack>
        </>
      )}
      <Dialog
        hidden={!dialogAlert.isClick}
        onDismiss={() =>
          setDialogAlert((prev) => ({
            ...prev,
            isClick: false,
          }))
        }
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: <Icon iconName="Error" />,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24, fontSize: 81, paddingBottom: 0 }, subText: { textAlign: 'center' } },
          subText: dialogAlert.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center', marginRight: 0 } }}>
          <DefaultButton
            onClick={() =>
              setDialogAlert((prev) => ({
                ...prev,
                isClick: false,
              }))
            }
            text={t('documents.close')}
          />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
}

<WithholdingTaxDropzone />;
