import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';
import { useTranslation } from 'react-i18next';

import {
  TextField,
  PrimaryButton,
  DefaultButton,
  Stack,
  getTheme,
  Icon,
  Spinner,
  Separator,
  FocusZone,
  Link,
  Dialog,
  DialogFooter,
  DialogType,
} from '@fluentui/react';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

//Style
const centerItem = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

const stackTokens = { childrenGap: 10 };

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const ApiKeys = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [isLoadComplete, setIsLoadComplete] = useState(false);

  const [generateClick, setGenerateClick] = useState(false);
  const [confirmClick, setConfirmClick] = useState(false);
  const [deleteClick, setDeleteClick] = useState(false);
  const [name, setName] = useState('');
  const [apiKeyName, setApiKeyName] = useState('');
  const [apiKey, setApiKey] = useState('*************************************');
  const [createdTime, setCreatedTime] = useState('');

  const companyId = sessionStorage.getItem('companyId') || '';

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const deleteKey = () => {
    console.log('Delete Key!');

    setDeleteClick(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .delete(API_URL + '/apikeys/', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log('Delete key response: ', response);

              if (response.data) {
                console.log('Delete key: ', response.data);

                setApiKeyName('');
                setCreatedTime('');
                setName('');

                setGenerateClick(false);
              }

              setDeleteClick(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('Submit!');
    console.log('Confirm generate API Key: ', name);

    setConfirmClick(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            API_URL + '/apikeys/',
            {
              name: name,
              product: 'etax',
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            }
          )
          .then(
            (response) => {
              console.log('Create api key response: ', response);

              if (response.data) {
                console.log('Create api key: ', response.data);

                let createdDate = new Date(new Date(response.data.createdTime)).toLocaleString('th-TH', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  timeZone: 'Asia/Bangkok',
                });

                setApiKeyName(response.data.name);
                setApiKey(response.data.apiKey);
                setCreatedTime(createdDate);
              }

              setConfirmClick(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    console.log('userEffect initialize Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                console.log(response.data[0]);
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );

        axios
          .get(API_URL + '/apikeys/', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log('Api name response: ', response);

              if (response.data) {
                console.log('Api name: ', response.data);

                let createdDate = new Date(new Date(response.data.createdTime)).toLocaleString('th-TH', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  timeZone: 'Asia/Bangkok',
                });

                setApiKeyName(response.data.name);
                setCreatedTime(createdDate);
              }

              setIsLoadComplete(true);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Stack>
        <h2 style={{ marginLeft: '0px', marginTop: '27px' }}>
          <Icon iconName="ServerProcesses" style={{ color: '#106ebe' }} /> {t('apiKeys.header')}
        </h2>

        <div
          style={{
            width: '620px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 30,
            background: '#ffffff',
            margin: '0',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <h2 style={{ margin: '0%', marginTop: '-1%' }}>{t('apiKeys.privateKey')}</h2>
          <Separator />
          {(generateClick || apiKeyName) && (
            <span>
              {t('apiKeys.createKeyToConnect')}{' '}
              <Link href="https://developers.leceipt.com/etax" target="_blank" underline>
                Leceipt API
              </Link>
            </span>
          )}
          <br />

          {apiKeyName && !deleteClick && (
            <div>
              <FocusZone
                as="ul"
                style={{
                  display: 'inline-block',
                  border: '1px solid #EDEBE9',
                  padding: 20,
                  lineHeight: 3,
                  overflow: 'hidden',
                  width: '575px',
                }}
              >
                <b>{t('apiKeys.name')}</b>&nbsp;&nbsp; {apiKeyName}
                <br />
                <b>{t('apiKeys.key')}</b>&nbsp;&nbsp; {apiKey}
                <br />
                <b>{t('apiKeys.createdDate')}</b>&nbsp;&nbsp; {createdTime}
                <br />
              </FocusZone>
              <br />
              <small>{t('apiKeys.keyDescription')}</small>
              <br />
              <br />
              <br />
              <PrimaryButton
                name="delete"
                text={t('apiKeys.delete')}
                onClick={() => {
                  console.log('Click delete!');

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('apiKeys.delete'),
                    message: t('apiKeys.deleteConfirm'),
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteKey();
                    },
                    onCancel: () =>
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      })),
                  }));
                }}
              />
            </div>
          )}

          {(confirmClick || deleteClick) && (
            <div>
              <br />
              <br />
              <Spinner label={t('apiKeys.processing')} labelPosition="down" styles={spinnerStyles} />
              <br />
            </div>
          )}

          {!isLoadComplete && (
            <div>
              <br />
              <br />
              <Spinner label={t('apiKeys.loading')} labelPosition="down" styles={spinnerStyles} />
              <br />
            </div>
          )}

          {!apiKeyName && generateClick && !confirmClick && (
            <div>
              <br />
              <Stack horizontal tokens={stackTokens} style={centerItem}>
                <TextField
                  name="name"
                  label={t('apiKeys.keyName')}
                  styles={{ root: { width: 250 } }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
              </Stack>
              <br />
              <br />
              <Stack horizontal tokens={stackTokens} style={centerItem}>
                <PrimaryButton name="confirm" text={t('apiKeys.confirm')} type="submit" />
                <DefaultButton
                  text={t('apiKeys.cancel')}
                  style={{ marginLeft: '15px' }}
                  onClick={() => {
                    setGenerateClick(false);
                  }}
                />
              </Stack>
            </div>
          )}

          {isLoadComplete && !generateClick && !apiKeyName && (
            <center>
              <img src="/images/api-key.png" width="150" style={{ padding: 0, marginLeft: 0 }} align="center" alt="apikey" />
              <br />
              <br />

              <h3>{t('apiKeys.privateKey')}</h3>
              <span>{t('apiKeys.keyDescription2')}</span>
              <br />
              <br />
              <span>
                {t('apiKeys.createKeyToConnect2')}{' '}
                <Link href="https://developers.leceipt.com/etax" target="_blank" underline>
                  Leceipt API
                </Link>
              </span>
              <br />
              <br />
              <span>{t('apiKeys.keyDescription3')}</span>
              <br />
              <br />
              <br />

              <PrimaryButton
                name="generate"
                text={t('apiKeys.generate')}
                onClick={() => {
                  console.log('Generate API Key');

                  setGenerateClick(true);
                }}
              />
            </center>
          )}
          <br />
          <br />
        </div>

        <br />

        <br />
        <br />
      </Stack>
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </form>
  );
};

export default ApiKeys;
