import React from 'react';
import XMLViewer from 'react-xml-viewer';

export default function XmlAllViewer(props) {
  const { xml } = props;

  return (
    <div style={{ backgroundColor: 'white', width: '950px', padding: '25px' }}>
      <XMLViewer xml={xml} collapsible={true} theme={{ overflowBreak: true }} />
    </div>
  );
}
