import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

const ProfileUpdate = () => {
  console.log('Re render');

  const history = useHistory();

  useEffect(() => {
    console.log('userEffect initial call!');

    history.push({
      pathname: '/',
      state: {},
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default ProfileUpdate;
