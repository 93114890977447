import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { parse } from 'papaparse';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { Stack, Spinner, Link, Dialog, Icon, DefaultButton, DialogFooter, DialogType } from '@fluentui/react';
import { validateData } from './validate';

var moment = require('moment'); // require
moment().format();

const xlsx = require('xlsx');

const API_URL = process.env.REACT_APP_API_URL;

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== '') return false;
  }
  return true;
}

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const headers = [
  'taxId',
  'taxNumberType',
  'branchNumber',
  'language',
  'name',
  'addressLineOne',
  'addressLineTwo',
  'countryName',
  'postcode',
  'email',
  'phone',
  'website',
  'fax',
  'note',
  'contactName',
  'contactEmail',
  'contactPhone',
  'contactDepartment',
  'contactPosition',
];

export default function ContactsDropzone(props) {
  const history = useHistory();

  const { t } = useTranslation();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: '.csv, .xlsx, .xls',
    multiple: false,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const [acceptedFilesList, setAcceptedFilesList] = useState([]);

  const [contactList, setContactList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const companyId = sessionStorage.getItem('companyId') || '';

  const [dialogAlert, setDialogAlert] = useState({
    isClick: false,
    message: '',
  });

  useEffect(() => {
    let newList = [...acceptedFilesList, ...acceptedFiles];
    setAcceptedFilesList(newList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const openFile = (event, item) => {
    history.push({
      pathname: event === 'pass' ? '/contacts/excel/list' : '/contacts/excel/check/result',
      state: {
        items: item,
      },
    });
  };

  const openFileFail = (item, mergeArray) => {
    history.push({
      pathname: '/contacts/excel/check/result',
      state: {
        items: item,
        mergeArray: mergeArray,
        contactList: contactList,
      },
    });
  };

  useEffect(() => {
    acceptedFilesList.map(async (file) => {
      if (file.name.includes('.csv')) {
        const text = await file.text();
        const result = parse(text, { header: true });

        let filterData = result.data.filter((obj) => !isEmpty(obj));

        let dataWithoutEmptyKey = [];
        filterData.forEach((obj) => {
          delete obj[''];
          dataWithoutEmptyKey.push(obj);
        });

        if (!dataWithoutEmptyKey[0] || headers.sort().join(',') !== Object.keys(dataWithoutEmptyKey[0]).sort().join(',')) {
          setDialogAlert((prev) => ({
            ...prev,
            isClick: true,
            message: t('contactDropzone.dropzoneAlertCSV'),
          }));

          return setAcceptedFilesList([]);
        }

        let mergeArray = dataWithoutEmptyKey;

        let failData = [];

        if (headers.sort().join(',') === Object.keys(dataWithoutEmptyKey[0]).sort().join(',')) {
          mergeArray.forEach((row, index) => {
            if (validateData(row, contactList).length > 0) {
              failData.push({
                name: row['name'],
                taxId: row['taxId'],
                branchNumber: row['branchNumber'],
                error: validateData(row, contactList),
              });
            } else {
              row.key = index + 1;
            }
          });
        }

        if (failData.length > 0) {
          setAcceptedFilesList([]);
          // openFile('fail', failData);
          openFileFail(failData, mergeArray);
        } else {
          openFile('pass', mergeArray);
        }
      } else {
        readExcel(file);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFilesList]);

  const readExcel = (file) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      const bufferArray = e.target.result;

      const workbook = xlsx.read(bufferArray, { type: 'buffer' });

      const worksheetName = workbook.SheetNames[0];

      const worksheet = workbook.Sheets[worksheetName];

      const columnsArray = xlsx.utils.sheet_to_json(worksheet, {
        header: 1,
      })[0];

      if (!columnsArray || headers.sort().join(',') !== columnsArray.sort().join(',')) {
        setDialogAlert((prev) => ({
          ...prev,
          isClick: true,
          message: t('contactDropzone.dropzoneAlertExcel'),
        }));

        return setAcceptedFilesList([]);
      }

      const data = xlsx.utils.sheet_to_json(worksheet);

      let mergeArray = data;

      console.log('mergeArray', mergeArray);

      if (headers.sort().join(',') !== columnsArray.sort().join(',')) {
        setDialogAlert((prev) => ({
          ...prev,
          isClick: true,
          message: t('excelDropzone.dropzoneAlertExcel'),
        }));

        return setAcceptedFilesList([]);
      }

      if (mergeArray.length > 501) {
        setDialogAlert((prev) => ({
          ...prev,
          isClick: true,
          message: t('contactDropzone.dropzoneAlert500'),
        }));

        setAcceptedFilesList([]);
      } else {
        let failData = [];

        mergeArray.forEach((row, index) => {
          if (validateData(row, contactList).length > 0) {
            failData.push({
              name: row['name'],
              taxId: row['taxId'],
              branchNumber: row['branchNumber'],
              error: validateData(row, contactList),
            });
          }
        });

        // console.log('failData', failData);

        if (failData.length > 0) {
          setAcceptedFilesList([]);
          // openFile('fail', failData);
          openFileFail(failData, mergeArray);
        } else {
          mergeArray.forEach((row, index) => (row.key = index + 1));
          openFile('pass', mergeArray);
        }
      }
    };
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        //Check documents spending limit
        await axios
          .get(API_URL + '/subscriptions', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log('Subscriptions data: ', response);

              if (response.data) {
                if (response.data && response.data.documents) {
                  console.log('Subscription etax documents: ', response.data.documents);

                  let _subscriptionDocuments = response.data.documents;

                  if (_subscriptionDocuments.spent + 1 > _subscriptionDocuments.limit + _subscriptionDocuments.topup) {
                    history.push({
                      pathname: '/billings',
                      state: {},
                    });
                  }
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );

        const [response, responseContacts] = await Promise.all([
          axios
            .get(API_URL + '/etax/companies', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            })
            .catch((error) => console.log(error)),

          axios
            .get(API_URL + '/contacts', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            })
            .catch((error) => console.log(error)),
        ]);

        if (response.data[0]) {
          if (companyId && response.data.find(_company => _company.id === companyId)) {          
            setContactList(responseContacts.data);
  
            setIsLoading(false);
          } else {
            setContactList(responseContacts.data);
  
            setIsLoading(false);

          }
        } else {
          history.push({
            pathname: '/etax/companies/profiles',
            state: {},
          });
        }
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {isLoading ? (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              <Spinner label={t('contactDropzone.processing')} labelPosition="down" styles={spinnerStyles} />
            </div>
            <br />
          </Stack>
        </Stack>
      ) : (
        <>
          <div>
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1>{t('contactDropzone.header')}</h1>
            </Stack>
            <br />
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <div className="center">
                <button type="button" style={{ padding: '10px 20px', fontSize: '16px' }} onClick={open}>
                  {t('contactDropzone.upload')}
                </button>
                <p>{t('contactDropzone.uploadDescription')}</p>
              </div>
            </div>
          </div>
          <br />
          <br />
          <Stack vertical horizontalAlign="center">
            <Link href="https://www.leceipt.com/docs/etax/add-customers-from-excel" target="_blank" underline>
              {t('excelDropzone.article')}
            </Link>
            <br />
            <Link href="/excel-templates/Contact-Template.xlsx" target="_blank" underline>
              {t('contactDropzone.excelTemplate')}
            </Link>
          </Stack>
        </>
      )}
      <Dialog
        hidden={!dialogAlert.isClick}
        onDismiss={() =>
          setDialogAlert((prev) => ({
            ...prev,
            isClick: false,
          }))
        }
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: <Icon iconName="Error" />,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24, fontSize: 81, paddingBottom: 0 }, subText: { textAlign: 'center' } },
          subText: dialogAlert.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center', marginRight: 0 } }}>
          <DefaultButton
            onClick={() =>
              setDialogAlert((prev) => ({
                ...prev,
                isClick: false,
              }))
            }
            text={t('documents.close')}
          />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
}

<ContactsDropzone />;
