import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";

import { CSVLink } from "react-csv";

import paginate from "jw-paginate";

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  SelectionMode,
  Calendar,
  DateRangeType,
  Spinner,
  SpinnerSize,
  CommandBar,
  SearchBox,
  Stack,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  TextField,
  Text,
  Checkbox,
  DatePicker,
} from "@fluentui/react";

// import { Pagination } from "@uifabric/experiments/lib/Pagination";
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: "100%",
  background: "#FAF9F8",
};

const toThaiDateString = (isoDateTime) => {
  let date = "";
  date = new Date(isoDateTime);
  let year = date.getFullYear() + 543;
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let numOfDay = date.getDate().toString().padStart(2, "0");

  let hour = date.getHours().toString().padStart(2, "0");
  let minutes = date.getMinutes().toString().padStart(2, "0");

  return `${numOfDay}/${month}/${year} ${hour}:${minutes} น.`;
};

function formatDateTime(date) {
  let d = new Date(date.toLocaleString("en-GB", { timeZone: "Asia/Bangkok" })),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    minute = "" + d.getMinutes(),
    second = "" + d.getSeconds();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (minute.length < 2) minute = "0" + minute;
  if (second.length < 2) second = "0" + second;

  return [year, month, day, hour, minute, second].join("");
}

const onFormatDate = (date, format) => {
  if (format === "CE") {
    return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + (date.getFullYear() + 543);
  }
};

const EmailDeliveries = () => {
  console.log("Home render!!!");

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const csvLink = useRef();

  const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [html] = useState("");
  const [companyTaxId, setCompanyTaxId] = useState("");
  const [companyBranchText, setCompanyBranchText] = useState("");
  const [companyAllAddress, setCompanyAllAddress] = useState("");

  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const [totalProcessingDocuments] = useState(0);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem("emailDeliveries-pageSize") * 1 || 10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem("emailDeliveries-currentPage") * 1 || 1);

  const companyId = sessionStorage.getItem("companyId") || "";

  console.log("companyId", companyId)
  const [date, setDate] = useState(sessionStorage.getItem("emailDeliveries-date") ? new Date(sessionStorage.getItem("emailDeliveries-date")) : new Date());

  const [isClickEmail, setIsClickEmail] = useState(false);
  const [customerEmail, setCustomerEmail] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");

  const [isClickSms, setIsClickSms] = useState(false);
  const [customerPhone, setCustomerPhone] = useState("");
  const [errorMessageSms, setErrorMessageSms] = useState("");

  const [search, setSearch] = useState("");

  const [sortDirection, setSortDirection] = useState("DESC")
  const [sortBy, setSortBy] = useState("createdTime")
  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem("emailDeliveries-primarySort") || "createdTime");
  const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
    if (sessionStorage.getItem("emailDeliveries-sort-createdTime")) {
      if (sessionStorage.getItem("emailDeliveries-sort-createdTime") === "false") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem("emailDeliveries-sort-number") && sessionStorage.getItem("emailDeliveries-sort-number") === "true") {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem("emailDeliveries-sort-customerName") && sessionStorage.getItem("emailDeliveries-sort-customerName") === "true") {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingTo, setIsSortedDescendingTo] = useState(() => {
    if (sessionStorage.getItem("emailDeliveries-sort-to") && sessionStorage.getItem("emailDeliveries-sort-to") === "true") {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingDocumentTypeName, setIsSortedDescendingDocumentTypeName] = useState(() => {
    if (sessionStorage.getItem("emailDeliveries-sort-documentTypeName") && sessionStorage.getItem("emailDeliveries-sort-documentTypeName") === "true") {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingProvider, setIsSortedDescendingProvider] = useState(() => {
    if (sessionStorage.getItem("emailDeliveries-sort-provider") && sessionStorage.getItem("emailDeliveries-sort-provider") === "true") {
      return true;
    } else {
      return false;
    }
  });

  const [isDownloading, setIsDownloading] = useState(false);

  const DayPickerStrings = {
    months: [
      t("emailDeliveries.months01"),
      t("emailDeliveries.months02"),
      t("emailDeliveries.months03"),
      t("emailDeliveries.months04"),
      t("emailDeliveries.months05"),
      t("emailDeliveries.months06"),
      t("emailDeliveries.months07"),
      t("emailDeliveries.months08"),
      t("emailDeliveries.months09"),
      t("emailDeliveries.months10"),
      t("emailDeliveries.months11"),
      t("emailDeliveries.months12"),
    ],

    shortMonths: [
      t("emailDeliveries.shortMonths01"),
      t("emailDeliveries.shortMonths02"),
      t("emailDeliveries.shortMonths03"),
      t("emailDeliveries.shortMonths04"),
      t("emailDeliveries.shortMonths05"),
      t("emailDeliveries.shortMonths06"),
      t("emailDeliveries.shortMonths07"),
      t("emailDeliveries.shortMonths08"),
      t("emailDeliveries.shortMonths09"),
      t("emailDeliveries.shortMonths10"),
      t("emailDeliveries.shortMonths11"),
      t("emailDeliveries.shortMonths12"),
    ],

    days: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"],

    shortDays: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],

    goToToday: "ไปวันที่ปัจจุบัน",
    prevMonthAriaLabel: "ไปเดือนที่แล้ว",
    nextMonthAriaLabel: "ไปเดือนถัดไป",
    prevYearAriaLabel: "ไปปีที่แล้ว",
    nextYearAriaLabel: "ไปปีถัดไป",
    closeButtonAriaLabel: "ปิด",
    monthPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนปี",
    yearPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนเดือน",

    isRequiredErrorMessage: t("emailDeliveries.isRequiredErrorMessage"),

    invalidInputErrorMessage: "รูปแบบวันที่ไม่ถูกต้อง",
  };

  function getDateText(monthObject) {
    if (monthObject.type === "dateRange") {
      if (i18n.language === "th") {
        return onFormatDate(fromDate, "BE") + " - " + onFormatDate(toDate, "BE");
      }
      return onFormatDate(fromDate, "CE") + " - " + onFormatDate(toDate, "CE");
    }

    if (monthObject.text === "เดือนนี้" || monthObject.text === "This Month") return t("emailDeliveries.thisMonth");

    let monthNames = [
      t("emailDeliveries.months01"),
      t("emailDeliveries.months02"),
      t("emailDeliveries.months03"),
      t("emailDeliveries.months04"),
      t("emailDeliveries.months05"),
      t("emailDeliveries.months06"),
      t("emailDeliveries.months07"),
      t("emailDeliveries.months08"),
      t("emailDeliveries.months09"),
      t("emailDeliveries.months10"),
      t("emailDeliveries.months11"),
      t("emailDeliveries.months12"),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === "th") {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + " " + _year;
  }

  const headerCSV = [
    { key: "r1", label: "" },
    { key: "r2", label: "" },
    { key: "r3", label: "" },
    { key: "r4", label: "" },
    { key: "r5", label: "" },
    { key: "r6", label: "" },
  ];

  function getCSVData() {
    let month = ""

    if (queryMonth.type === "dateRange") {
      if (i18n.language === "th") {
        return onFormatDate(fromDate, "BE") + " - " + onFormatDate(toDate, "BE");
      }
      return onFormatDate(fromDate, "CE") + " - " + onFormatDate(toDate, "CE");
    }

    let monthNames = [
      t("emailDeliveries.months01"),
      t("emailDeliveries.months02"),
      t("emailDeliveries.months03"),
      t("emailDeliveries.months04"),
      t("emailDeliveries.months05"),
      t("emailDeliveries.months06"),
      t("emailDeliveries.months07"),
      t("emailDeliveries.months08"),
      t("emailDeliveries.months09"),
      t("emailDeliveries.months10"),
      t("emailDeliveries.months11"),
      t("emailDeliveries.months12"),
    ];

    let _monthIndex = Number(queryMonth.key.substring(4, 6)) - 1;
    let _year = Number(queryMonth.key.substring(0, 4));
    if (i18n.language === "th") {
      _year = _year + 543;
    }

    month = monthNames[_monthIndex] + " " + _year;

    let csvData = [
      {
        r1: t("emailDeliveries.header"),
        r2: "",
        r3: "",
        r4: "",
        r5: "",
        r6: "",
      },
      {
        r1: t("emailDeliveries.month") + month,
        r2: "",
        r3: "",
        r4: "",
        r5: "",
        r6: "",
      },
      {
        r1: t("emailDeliveries.company") + companyName + (companyBranchText ? " " + companyBranchText : ""),
        r2: "",
        r3: "",
        r4: "",
        r5: "",
        r6: "",
      },
      {
        r1: t("emailDeliveries.companyTaxId") + companyTaxId,
        r2: "",
        r3: "",
        r4: "",
        r5: "",
        r6: "",
      },
      {
        r1: t("emailDeliveries.address") + companyAllAddress,
        r2: "",
        r3: "",
        r4: "",
        r5: "",
        r6: "",
      },
      {},
      {
        r1: t("emailDeliveries.createdDate"),
        r2: t("emailDeliveries.number"),
        r3: t("emailDeliveries.customerName"),
        r4: t("emailDeliveries.to"),
        r5: t("emailDeliveries.documentTypeName"),
        r6: t("emailDeliveries.provider"),
      },
    ];

    documents.forEach((doc) => {
      const { 
        createdTime, 
        data, 
        provider, 
        to, 
      } = doc

      const { number, name: documentTypeName, customer } = data.data
      const { name: customerName } = customer

      let row = {
        r1: toThaiDateString(createdTime),
        r2: '=""' + number + '""',
        r3: '=""' + customerName + '""',
        r4: to.replaceAll(";", ""),
        r5: '=""' + documentTypeName + '""',
        r6: '=""' + provider + '""',
      };

      csvData.push(row);
    });

    return csvData;
  }

  const getfileName = () => {
    const time = formatDateTime(new Date().toISOString());
    let fileName = time + "-History-Email-Log.csv";
    return fileName;
  };

  const [fileName] = useState(getfileName);

  const getMonth = (goBack) => {
    let monthNames = [
      t("emailDeliveries.months01"),
      t("emailDeliveries.months02"),
      t("emailDeliveries.months03"),
      t("emailDeliveries.months04"),
      t("emailDeliveries.months05"),
      t("emailDeliveries.months06"),
      t("emailDeliveries.months07"),
      t("emailDeliveries.months08"),
      t("emailDeliveries.months09"),
      t("emailDeliveries.months10"),
      t("emailDeliveries.months11"),
      t("emailDeliveries.months12"),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + " " + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem("emailDeliveries-queryMonth-key") && {
      key: sessionStorage.getItem("emailDeliveries-queryMonth-key"),
      text: sessionStorage.getItem("emailDeliveries-queryMonth-text"),
      ...(sessionStorage.getItem("emailDeliveries-queryMonth-type") ? { type: sessionStorage.getItem("emailDeliveries-queryMonth-type") } : {}),
  }) || { key: getMonth(0).year + "" + getMonth(0).key, text: t("emailDeliveries.thisMonth") }
  );

  const [timeRangeDate, setTimeRangeDate] = useState(sessionStorage.getItem("emailDeliveries-queryMonth-type") ? true : false);
  const [fromDate, setFromDate] = useState(sessionStorage.getItem("emailDeliveries-fromDate") ? new Date(sessionStorage.getItem("emailDeliveries-fromDate")) : null);
  const [toDate, setToDate] = useState(sessionStorage.getItem("emailDeliveries-toDate") ? new Date(sessionStorage.getItem("emailDeliveries-toDate")) : null);
  const [timeRangeError, setTimeRangeError] = useState(false);

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log("First day: ", formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log("Last day: ", formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const getFromDMY = (day, month, year) => {
    let _firstDay = new Date(year, month, day);
    console.log("First day: ", formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getToDMY = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);
    console.log("Last day: ", formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const [from, setFrom] = useState(sessionStorage.getItem("emailDeliveries-from") || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem("emailDeliveries-to") || getTo(getMonth(0).month, getMonth(0).year));

  const [pageDropdown, setPageDropdown] = useState([]);

  const commandBarItems = [
    // {
    //   key: "pdf",
    //   text: t("emailDeliveries.report"),
    //   iconProps: { iconName: "TextDocument" },
    //   disabled: !loadDataComplete,
    //   onClick: () => {
    //     console.log("pdf");

    //     setSearch("");
    //     onSubmitHTMLcreate();
    //   },
    // },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: "Calendar" },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: "queryMonth",
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Stack styles={{ root: { padding: "12px 12px 0px 12px" } }}>
                <Checkbox
                  label={t("emailDeliveries.timeRange")}
                  checked={timeRangeDate}
                  onChange={(e) => {
                    setTimeRangeDate((prev) => !prev);
                  }}
                />
              </Stack>
              {timeRangeDate ? (
                <Stack>
                <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                  <Stack styles={{ root: { width: "20%", justifyContent: "center", alignItems: "center" } }}>
                    <Text>{t("emailDeliveries.fromRange")}</Text>
                  </Stack>
                  <Stack styles={{ root: { width: "80%", justifyContent: "center" /* alignItems: "center" */ } }}>
                    <DatePicker
                      styles={{ root: { width: "90%" } }}
                      placeholder={t("deliveryOrderTaxInvoice.pleaseSelect")}
                      strings={DayPickerStrings}
                      value={fromDate}
                      maxDate={toDate}
                      formatDate={onFormatDate}
                      onSelectDate={(_date) => {
                        setFromDate(_date);

                        setTimeRangeError(false);
                        if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                  <Stack styles={{ root: { width: "20%", justifyContent: "center", alignItems: "center" } }}>
                    <Text>{t("emailDeliveries.toRange")}</Text>
                  </Stack>
                  <Stack styles={{ root: { width: "80%" /* alignItems: "center" */ } }}>
                    <DatePicker
                      styles={{ root: { width: "90%" } }}
                      placeholder={t("deliveryOrderTaxInvoice.pleaseSelect")}
                      strings={DayPickerStrings}
                      value={toDate}
                      minDate={fromDate}
                      formatDate={onFormatDate}
                      onSelectDate={(_date) => {
                        setToDate(_date);

                        setTimeRangeError(false);
                        if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                      }}
                    />
                  </Stack>
                </Stack>
                {timeRangeError ? (
                  <Stack styles={{ root: { padding: "12px 0px 0px 12px" } }}>
                    <Text styles={{ root: { fontSize: 12, color: "#A4262C" } }}>{t("emailDeliveries.timeRangeError")}</Text>
                  </Stack>
                ) : null}
                <Stack horizontal horizontalAlign="end" styles={{ root: { width: "92%", height: 52, padding: "10px 0px 10px 0px" } }}>
                  <PrimaryButton
                    text={t("emailDeliveries.search")}
                    onClick={() => {
                      if (!timeRangeError && fromDate && toDate) {
                        setQueryMonth({
                          type: "dateRange",
                          key: formatDate(fromDate) + ":" + formatDate(toDate),
                          text: formatDate(fromDate) + " - " + formatDate(toDate),
                        });

                        sessionStorage.setItem("emailDeliveries-queryMonth-type", "dateRange");
                        sessionStorage.setItem("emailDeliveries-queryMonth-key", formatDate(fromDate) + ":" + formatDate(toDate));
                        sessionStorage.setItem("emailDeliveries-queryMonth-text", formatDate(fromDate) + " - " + formatDate(toDate));

                        setFrom(getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        setTo(getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        sessionStorage.setItem("emailDeliveries-from", getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        sessionStorage.setItem("emailDeliveries-to", getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        sessionStorage.setItem("emailDeliveries-fromDate", fromDate.toISOString());
                        sessionStorage.setItem("emailDeliveries-toDate", toDate.toISOString());

                        setLoadDataComplete(false);
                        setDocuments([]);
                        setItems([]);
                        setGetNewDocuments(true);
                        setCurrentPage(1)

                        setSortDirection("DESC")
                        setSortBy("createdTime")
                        setPrimarySort("createdTime");
                        setIsSortedDescendingCreatedTime(false);
                        setIsSortedDescendingNumber(false);
                        setIsSortedDescendingCustomerName(false);
                        setIsSortedDescendingTo(false);
                        setIsSortedDescendingDocumentTypeName(false);
                        setIsSortedDescendingProvider(false);

                        sessionStorage.setItem("emailDeliveries-primarySort", "createdTime");
                        sessionStorage.setItem("emailDeliveries-sort-createdTime", "false");
                        sessionStorage.setItem("emailDeliveries-sort-number", "false");
                        sessionStorage.setItem("emailDeliveries-sort-customerName", "false");
                        sessionStorage.setItem("emailDeliveries-sort-to", "false");
                        sessionStorage.setItem("emailDeliveries-sort-documentTypeName", "false");
                        sessionStorage.setItem("emailDeliveries-sort-provider", "false");
                      }
                    }}
                    style={{ width: "125px", letterSpacing: "1px" }}
                  />
                </Stack>
              </Stack>
              ) : (
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t("emailDeliveries.months01"),
                    t("emailDeliveries.months02"),
                    t("emailDeliveries.months03"),
                    t("emailDeliveries.months04"),
                    t("emailDeliveries.months05"),
                    t("emailDeliveries.months06"),
                    t("emailDeliveries.months07"),
                    t("emailDeliveries.months08"),
                    t("emailDeliveries.months09"),
                    t("emailDeliveries.months10"),
                    t("emailDeliveries.months11"),
                    t("emailDeliveries.months12"),
                  ];

                  setDate(_date);

                  sessionStorage.setItem("emailDeliveries-date", _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + "" + String(adjustDate.getMonth() + 1).padStart(2, "0"),
                    text: monthNames[adjustDate.getMonth()] + " " + year,
                  });

                  sessionStorage.setItem(
                    "emailDeliveries-queryMonth-key",
                    adjustDate.getFullYear() + "" + String(adjustDate.getMonth() + 1).padStart(2, "0")
                  );
                  sessionStorage.setItem("emailDeliveries-queryMonth-text", monthNames[adjustDate.getMonth()] + " " + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem("emailDeliveries-from", getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem("emailDeliveries-to", getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setDocuments([]);
                  setItems([]);
                  setGetNewDocuments(true);
                  setCurrentPage(1)

                  setSortDirection("DESC")
                  setSortBy("createdTime")
                  setPrimarySort("createdTime");
                  setIsSortedDescendingCreatedTime(false);
                  setIsSortedDescendingNumber(false);
                  setIsSortedDescendingCustomerName(false);
                  setIsSortedDescendingTo(false);
                  setIsSortedDescendingDocumentTypeName(false);
                  setIsSortedDescendingProvider(false);

                  sessionStorage.setItem("emailDeliveries-primarySort", "createdTime");
                  sessionStorage.setItem("emailDeliveries-sort-createdTime", "false");
                  sessionStorage.setItem("emailDeliveries-sort-number", "false");
                  sessionStorage.setItem("emailDeliveries-sort-customerName", "false");
                  sessionStorage.setItem("emailDeliveries-sort-to", "false");
                  sessionStorage.setItem("emailDeliveries-sort-documentTypeName", "false");
                  sessionStorage.setItem("emailDeliveries-sort-provider", "false");
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
              )}
            </div>
          );
        },
      },
    },
    {
      key: "pageSize " + pageSize,
      text: pageSize + " " + t("emailDeliveries.documentPerPage"),
      cacheKey: "myCacheKey", // changing this key will invalidate this item"s cache
      iconProps: { iconName: "NumberedList" },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: "pageSize " + 10,
            text: "10 " + t("emailDeliveries.documentPerPage"),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              console.log("10 ออเดอร์/หน้า");
              setPageSize(10);
              sessionStorage.setItem("emailDeliveries-pageSize", 10);

              setLoadDataComplete(false);
              setDocuments([]);
              setItems([]);
              setGetNewDocuments(true);
              setCurrentPage(1)
            },
          },
          {
            key: "pageSize " + 20,
            text: "20 " + t("emailDeliveries.documentPerPage"),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              console.log("20 ออเดอร์/หน้า");
              setPageSize(20);
              sessionStorage.setItem("emailDeliveries-pageSize", 20);

              setLoadDataComplete(false);
              setDocuments([]);
              setItems([]);
              setGetNewDocuments(true);
              setCurrentPage(1)
            },
          },
          {
            key: "pageSize " + 30,
            text: "30 " + t("emailDeliveries.documentPerPage"),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              console.log("30 ออเดอร์/หน้า");
              setPageSize(30);
              sessionStorage.setItem("emailDeliveries-pageSize", 30);

              setLoadDataComplete(false);
              setDocuments([]);
              setItems([]);
              setGetNewDocuments(true);
              setCurrentPage(1)
            },
          },
          {
            key: "pageSize " + 40,
            text: "40 " + t("emailDeliveries.documentPerPage"),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              console.log("40 ออเดอร์/หน้า");
              setPageSize(40);
              sessionStorage.setItem("emailDeliveries-pageSize", 40);

              setLoadDataComplete(false);
              setDocuments([]);
              setItems([]);
              setGetNewDocuments(true);
              setCurrentPage(1)
            },
          },
          {
            key: "pageSize " + 50,
            text: "50 " + t("emailDeliveries.documentPerPage"),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              console.log("50 ออเดอร์/หน้า");
              setPageSize(50);
              sessionStorage.setItem("emailDeliveries-pageSize", 50);

              setLoadDataComplete(false);
              setDocuments([]);
              setItems([]);
              setGetNewDocuments(true);
              setCurrentPage(1)
            },
          },
        ],
      },
    },
    {
      key: currentPage,
      text: t("emailDeliveries.page") + " " + currentPage,
      iconProps: { iconName: "Page" },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: "download",
      text: t("emailDeliveries.download"),
      cacheKey: "myCacheKey", // changing this key will invalidate this item"s cache
      iconProps: { iconName: "Download" },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: "download-csv",
            text: t("emailDeliveries.downloadCsv"),
            iconProps: { iconName: "ExcelDocument" },
            onClick: () => {
              console.log("Download CSV");
              handleDownloadCSV();
            },
          },
        ],
      },
    },
    {
      key: "refresh",
      text: t("emailDeliveries.refresh"),
      iconProps: { iconName: "Refresh" },
      disabled: !loadDataComplete,
      onClick: () => {
        console.log("Refresh");

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setCurrentPage(1)
      },
    },
  ];

  const sendEmail = (fileId, email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email !== "" && email !== null && email !== undefined && re.test(String(email).toLowerCase())) {
      setIsClickEmail(false);
      setErrorMessageEmail("");

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + "/emails/send",
              {
                fileId: fileId,
                email: email,
              },
              {
                headers: {
                  Authorization: "Bearer " + tokenResponse.accessToken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then(
              (response) => {
                console.log(response);
                setIsClickEmail(false);
                window.scrollTo(0, 0);
              },
              (error) => {
                console.log(error);
                setIsClickEmail(false);
                window.scrollTo(0, 0);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      setErrorMessageEmail("กรุณากรอกอีเมลที่ถูกต้อง");
    }
  };

  const sendSms = (fileId, mobile) => {
    const re = /^0[1-9]{1}[0-9]{8}/;

    if (mobile !== "" && mobile !== null && mobile !== undefined && re.test(String(mobile))) {
      setIsClickSms(false);
      setErrorMessageSms("");

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + "/etax/sms/",
              {
                fileId: fileId,
                mobile: mobile,
              },
              {
                headers: {
                  Authorization: "Bearer " + tokenResponse.accessToken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then(
              (response) => {
                console.log(response);
                setIsClickSms(false);
                window.scrollTo(0, 0);
              },
              (error) => {
                console.log(error);
                setIsClickSms(false);
                window.scrollTo(0, 0);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      setErrorMessageSms("กรุณากรอกเบอร์มือถือที่ถูกต้อง");
    }
  };

  const handleDownloadCSV = () => {
    setIsDownloading(true);
    csvLink.current.link.click();
    setIsDownloading(false);
  };

  useEffect(() => {
    console.log("userEffect initial call!");

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + "/etax/companies", {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                  
                  setCompany(_company);
  
                  setCompanyName(_company.name);
                  setCompanyTaxId(_company.taxId);
                  setCompanyBranchText(_company.branchText);
                } else {
                  setCompany(response.data[0]);
  
                  setCompanyName(response.data[0].name);
                  setCompanyTaxId(response.data[0].taxId);
                  setCompanyBranchText(response.data[0].branchText);

                }

              } else {
                history.push({
                  pathname: "/etax/companies/profiles",
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("Company state: ", company);

    if (company.taxId) {
      console.log("Company useEffect call!");
      console.log(company);
      console.log(company.id);

      setCompanyAllAddress(
        company.buildingNumber +
          " " +
          (!/^-*$/.test(company.address) && company.address.trim() && !/^-*$/.test(company.address.trim()) ? company.address + " " : "") +
          (!/^-*$/.test(company.streetName) && company.streetName.trim() && !/^-*$/.test(company.streetName.trim())
            ? company.streetPrefix + company.streetName + " "
            : "") +
          company.subDistrictPrefix +
          company.subDistrictName +
          " " +
          company.districtPrefix +
          company.districtName +
          " " +
          company.provincePrefix +
          company.provinceName +
          " " +
          company.postcode
      );
    }
  }, [company]);

  useEffect(() => {
    console.log("userEffect getNewDocuments Call!");

    if (getNewDocuments) {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          // Do something with the tokenResponse
          console.log("Token response: ", "Bearer " + tokenResponse.accessToken);

          axios
            .get(
              search ? `https://ws.leceipt.com/deliveries?from=${from}&to=${to}&channel=email&offset=${(currentPage - 1) * pageSize}&sortDirection=${sortDirection}&sortBy=${sortBy}&status=sent&search=${search}` :
              `https://ws.leceipt.com/deliveries?from=${from}&to=${to}&channel=email&offset=${(currentPage - 1) * pageSize}&sortDirection=${sortDirection}&sortBy=${sortBy}&status=sent`, 
            {
              headers: {
                Authorization: "Bearer " + tokenResponse.accessToken,
                "cid": companyId,
                // "Access-Control-Allow-Origin": "*",
              },
            })
            .then(
              (response) => {
                console.log("response: ", response, true);

                const { countTotal, deliveries } = response.data.data

                setDocuments(deliveries)

                // //Page dropdown
                let _pageDropdown = [];

                for (let i = 1; i <= Math.ceil(countTotal / pageSize); i++) {
                  _pageDropdown.push({
                    key: i,
                    text: "หน้า " + i,
                    iconProps: { iconName: "Page" },
                    onClick: () => {
                      console.log("Selected page: " + i);
                      setCurrentPage(i);
                    },
                  });
                }

                setPageDropdown(_pageDropdown);

                if (currentPage > Math.ceil(countTotal / pageSize)) {
                  setCurrentPage(1);
                }

                // //set page
                // console.log("Page data: ", paginate(filter_documents.length, currentPage, pageSize, 10));
                setPageData(paginate(countTotal, currentPage, pageSize, 10));

                let _docs = [];

                if (deliveries.length > 0) {
                  for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, deliveries.length); i++) {
                    const { 
                      createdTime, 
                      data, 
                      id,
                      provider, 
                      to, 
                    } = deliveries[i]

                    const { number, name: documentTypeName, customer } = data.data
                    const { name: customerName } = customer

                    let _provider = ""

                    if (provider === "microsoft") {
                      _provider = "Microsoft"
                    } else if (provider === "google") {
                      _provider = "Google"
                    } else if (provider === "smtp") {
                      _provider = "SMTP"
                    } else if (provider === "sendgrid") {
                      _provider = "SendGrid"
                    }

                    _docs.push({
                      key: id,
                      createdTime: createdTime,
                      number: number,
                      to: to.replaceAll(";", ""),
                      provider: _provider,
                      name: documentTypeName,
                      customerName: customerName
                    })
                  }
                }

                setItems(_docs);
                // setItems([]);

                setLoadDataComplete(true);
                setGetNewDocuments(false);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    if (documents.length > 0) {
      console.log("documents data", documents);

      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
        const { 
          createdTime, 
          data, 
          id,
          provider, 
          to, 
        } = documents[i]

        const { number, name: documentTypeName, customer } = data.data
        const { name: customerName } = customer

        let _provider = ""

        if (provider === "microsoft") {
          _provider = "Microsoft"
        } else if (provider === "google") {
          _provider = "Google"
        } else if (provider === "smtp") {
          _provider = "SMTP"
        } else if (provider === "sendgrid") {
          _provider = "SendGrid"
        }

        _docs.push({
          key: id,
          createdTime: createdTime,
          number: number,
          to: to.replaceAll(";", ""),
          provider: _provider,
          name: documentTypeName,
          customerName: customerName
        })
      }
      console.log("Docs data", _docs);

      setItems(_docs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const onPageChange = (selectedPageIndex) => {
    console.log("selectedPageIndex: ", selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const columns = [
    {
      key: "column1",
      name: t("emailDeliveries.createdDate"),
      fieldName: "createdDate",
      minWidth: 140,
      maxWidth: 140,
      isResizable: true,
      isSorted: primarySort === "createdTime" ? true : false,
      isSortedDescending: isSortedDescendingCreatedTime,
      isSortedAscending: !isSortedDescendingCreatedTime,
      onColumnClick: () => {
        console.log("Sort document date!");
        setPrimarySort("createdTime");
        setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

        setSortDirection(isSortedDescendingCreatedTime ? "DESC" : "ASC")
        setSortBy("createdTime")

        sessionStorage.setItem("emailDeliveries-primarySort", "createdTime");
        sessionStorage.setItem("emailDeliveries-sort-createdTime", !isSortedDescendingCreatedTime === true ? "true" : "false");

        sessionStorage.setItem("emailDeliveries-sort-number", "false");
        sessionStorage.setItem("emailDeliveries-sort-customerName", "false");
        sessionStorage.setItem("emailDeliveries-sort-to", "false");
        sessionStorage.setItem("emailDeliveries-sort-documentTypeName", "false");
        sessionStorage.setItem("emailDeliveries-sort-provider", "false");

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setCurrentPage(1)
      },
      data: "number",
      onRender: (item) => {
        return <span>{toThaiDateString(item.createdTime)}</span>;
      },
      isPadded: true,
    },
    {
      key: "column2",
      name: t("emailDeliveries.number"),
      fieldName: "number",
      minWidth: 140,
      maxWidth: 140,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "number" ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log("Sort document number!");
        setPrimarySort("number");
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        setSortDirection(isSortedDescendingNumber ? "DESC" : "ASC")
        setSortBy("data.data.number")

        sessionStorage.setItem("emailDeliveries-primarySort", "number");
        sessionStorage.setItem("emailDeliveries-sort-number", !isSortedDescendingNumber === true ? "true" : "false");

        sessionStorage.setItem("emailDeliveries-sort-createdTime", "false");
        sessionStorage.setItem("emailDeliveries-sort-customerName", "false");
        sessionStorage.setItem("emailDeliveries-sort-to", "false");
        sessionStorage.setItem("emailDeliveries-sort-documentTypeName", "false");
        sessionStorage.setItem("emailDeliveries-sort-provider", "false");

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setCurrentPage(1)
      },
      data: "string",
      onRender: (item) => {
        return (
          <span>{item.number}</span>
        );
      },
      isPadded: true,
    },
    {
      key: "column3",
      name: t("emailDeliveries.customerName"),
      fieldName: "customerName",
      minWidth: 140,
      maxWidth: 140,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "customerName" ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log("Sort customerName!");
        setPrimarySort("customerName");
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        setSortDirection(isSortedDescendingCustomerName ? "DESC" : "ASC")
        setSortBy("data.data.customer.name")

        sessionStorage.setItem("emailDeliveries-primarySort", "customerName");
        sessionStorage.setItem("emailDeliveries-sort-customerName", !isSortedDescendingCustomerName === true ? "true" : "false");

        sessionStorage.setItem("emailDeliveries-sort-createdTime", "false");
        sessionStorage.setItem("emailDeliveries-sort-number", "false");
        sessionStorage.setItem("emailDeliveries-sort-to", "false");
        sessionStorage.setItem("emailDeliveries-sort-documentTypeName", "false");
        sessionStorage.setItem("emailDeliveries-sort-provider", "false");

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setCurrentPage(1)
      },
      data: "string",
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      isPadded: true,
    },
    {
      key: "column4",
      name: t("emailDeliveries.to"),
      fieldName: "to",
      minWidth: 140,
      maxWidth: 140,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "to" ? true : false,
      isSortedDescending: isSortedDescendingTo,
      isSortedAscending: !isSortedDescendingTo,
      onColumnClick: () => {
        console.log("Sort taxId!");
        setPrimarySort("to");
        setIsSortedDescendingTo(!isSortedDescendingTo);

        setSortDirection(isSortedDescendingTo ? "DESC" : "ASC")
        setSortBy("to")

        sessionStorage.setItem("emailDeliveries-primarySort", "to");
        sessionStorage.setItem("emailDeliveries-sort-to", !isSortedDescendingTo === true ? "true" : "false");

        sessionStorage.setItem("emailDeliveries-sort-createdTime", "false");
        sessionStorage.setItem("emailDeliveries-sort-number", "false");
        sessionStorage.setItem("emailDeliveries-sort-customerName", "false");
        sessionStorage.setItem("emailDeliveries-sort-documentTypeName", "false");
        sessionStorage.setItem("emailDeliveries-sort-provider", "false");

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setCurrentPage(1)
      },
      data: "string",
      onRender: (item) => {
        return <span>{item.to}</span>
      },
      isPadded: true,
    },
    {
      key: "column6",
      name: t("emailDeliveries.documentTypeName"),
      fieldName: "documentTypeName",
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "documentTypeName" ? true : false,
      isSortedDescending: isSortedDescendingDocumentTypeName,
      isSortedAscending: !isSortedDescendingDocumentTypeName,
      onColumnClick: () => {
        console.log("Sort amountTotal!");
        setPrimarySort("documentTypeName");
        setIsSortedDescendingDocumentTypeName(!isSortedDescendingDocumentTypeName);

        setSortDirection(isSortedDescendingDocumentTypeName ? "DESC" : "ASC")
        setSortBy("data.data.name")

        sessionStorage.setItem("emailDeliveries-primarySort", "documentTypeName");
        sessionStorage.setItem("emailDeliveries-sort-documentTypeName", !isSortedDescendingDocumentTypeName === true ? "true" : "false");

        sessionStorage.setItem("emailDeliveries-sort-createdTime", "false");
        sessionStorage.setItem("emailDeliveries-sort-number", "false");
        sessionStorage.setItem("emailDeliveries-sort-customerName", "false");
        sessionStorage.setItem("emailDeliveries-sort-to", "false");
        sessionStorage.setItem("emailDeliveries-sort-provider", "false");

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setCurrentPage(1)
      },
      data: "string",
      onRender: (item) => {
        return (
          <span>
            {item.name}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: "column7",
      name: t("emailDeliveries.provider"),
      fieldName: "provider",
      minWidth: 60,
      maxWidth: 60,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "provider" ? true : false,
      isSortedDescending: isSortedDescendingProvider,
      isSortedAscending: !isSortedDescendingProvider,
      onColumnClick: () => {
        console.log("Sort provider!");
        setPrimarySort("provider");
        setIsSortedDescendingProvider(!isSortedDescendingProvider);

        setSortDirection(isSortedDescendingProvider ? "DESC" : "ASC")
        setSortBy("provider")

        sessionStorage.setItem("emailDeliveries-primarySort", "provider");
        sessionStorage.setItem("emailDeliveries-sort-provider", !isSortedDescendingProvider === true ? "true" : "false");

        sessionStorage.setItem("emailDeliveries-sort-createdTime", "false");
        sessionStorage.setItem("emailDeliveries-sort-number", "false");
        sessionStorage.setItem("emailDeliveries-sort-customerName", "false");
        sessionStorage.setItem("emailDeliveries-sort-to", "false");
        sessionStorage.setItem("emailDeliveries-sort-documentTypeName", "false");
        
        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setCurrentPage(1)
      },
      data: "number",
      onRender: (item) => {
        return (
          <span>
            {item.provider}
          </span>
        );
      },
      isPadded: true,
    },
  ];

  const isCompactMode = false;

  return (
    <div style={homeStyles}>
      <Stack>
        <center>
          <h2 style={{ marginLeft: "0px", marginTop: "25px" }}>{t("emailDeliveries.header")}</h2>
        </center>
      </Stack>

      {totalProcessingDocuments > 0 && (
        <div>
          <br />
          <Spinner label={t("emailDeliveries.signProcessing")} size={SpinnerSize.large} />
        </div>
      )}

      {isDownloading && (
        <div>
          <br />
          <Spinner label={t("emailDeliveries.loading")} labelPosition="right" />
        </div>
      )}
      <br />
      <Stack horizontal horizontalAlign="center">
        <Stack
          vertical
          style={{
            width: "98%",
            maxWidth: "1350px",
          }}
        >
          <Stack 
            horizontal 
            styles={{
              root: {
                backgroundColor: 'white',
              },
            }} 
            horizontalAlign="space-between"
          >
            <CommandBar
              items={commandBarItems}
              styles={{
                root: {
                  width: 750,
                },
              }}
            />
            <Stack
              horizontal
              styles={{
                root: {
                  height: 44,
                },
              }}
              tokens={{ childrenGap: "10px" }}
            >
              <SearchBox
                className="ms-borderColor-themePrimary"
                styles={{
                  root: {
                    marginTop: 6,
                    marginLeft: 6,
                    width: 200,
                    fontSize: 13,
                    fontWeight: 0,
                  },
                }}
                disabled={!loadDataComplete}
                placeholder={t("emailDeliveries.searchPlaceholder")}
                onChange={(e) => setSearch(e ? e.target.value.replace(/\s/g, "") : "")}
                onClear={() => {
                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);
                  setCurrentPage(1);
                }}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);
                    setCurrentPage(1);
                  }
                }}
                onSearch={() => {
                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);
                  setCurrentPage(1);
                }}
                value={search}
              />
              <PrimaryButton
                disabled={!loadDataComplete || !search}
                styles={{
                  root: {
                    marginTop: 6,
                    marginRight: 8,
                    width: 90,
                  },
                }}
                text={t("emailDeliveries.search")}
                onClick={() => {
                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);
                  setCurrentPage(1);
                }}
              />
            </Stack>
          </Stack>
          {items.length ? (
            <ShimmeredDetailsList
              items={items || []}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
            />
          ) : (
            <ShimmeredDetailsList
              items={[{}]}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderRow={() => {
                return (
                  <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: "#FFFFFF", height: 55 } }}>
                    <Text>{t("documents.noItemFound")}</Text>
                  </Stack>
                );
              }}
            />
          )}
        </Stack>
      </Stack>
      <br />
      <center>
        {loadDataComplete && (
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={pageData.totalPages}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{
              iconName: "DoubleChevronLeft",
            }}
            previousPageIconProps={{ iconName: "ChevronLeft" }}
            nextPageIconProps={{ iconName: "ChevronRight" }}
            lastPageIconProps={{
              iconName: "DoubleChevronRight",
            }}
          />
        )}
        <br />
        <br />
      </center>

      <Dialog
        hidden={!isClickEmail}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: t("emailDeliveries.sendFileByEmail"),
          subText: '',
        }}
      >
        <TextField
          label={t("emailDeliveries.email")}
          value={customerEmail}
          required
          onChange={(e) => {
            setCustomerEmail(e.target.value);
            setErrorMessageEmail("");
          }}
          errorMessage={errorMessageEmail}
        />
        <br />
        <br />
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              sendEmail('', customerEmail);
            }}
            text={t("emailDeliveries.sendEmail")}
          />
          <DefaultButton onClick={() => setIsClickEmail(false)} text={t("emailDeliveries.cancel")} />
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={!isClickSms}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: t("emailDeliveries.sendFileBySms"),
          subText: '',
        }}
      >
        <TextField
          label={t("emailDeliveries.mobileNumer")}
          value={customerPhone}
          required
          onChange={(e) => {
            setCustomerPhone(e.target.value);
            setErrorMessageSms("");
          }}
          errorMessage={errorMessageSms}
          description={t("emailDeliveries.exampleNumber")}
        />
        <br />
        <br />
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              sendSms('', customerPhone);
            }}
            text={t("emailDeliveries.sendSms")}
          />
          <DefaultButton onClick={() => setIsClickSms(false)} text={t("emailDeliveries.cancel")} />
        </DialogFooter>
      </Dialog>

      {html !== "" ? <div id="printableArea" style={{ display: "none" }} dangerouslySetInnerHTML={{ __html: html }} /> : null}

      <CSVLink style={{ display: "none" }} ref={csvLink} filename={fileName} data={getCSVData()} headers={headerCSV}>
        Download csv
      </CSVLink>
    </div>
  );
};

export default EmailDeliveries;
