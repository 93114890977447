import React from 'react';

import { useHistory } from 'react-router-dom';

import { Stack } from '@fluentui/react';

const stackTokens = { childrenGap: 15 };

function arrayTo2DArray2(list, howMany) {
  let idx = 0;
  let result = [];

  while (idx < list.length) {
    if (idx % howMany === 0) result.push([]);
    result[result.length - 1].push(list[idx++]);
  }

  return result;
}

const Sources = () => {
  const history = useHistory();

  const platformExternalSources = [
    {
      key: 'shopee',
      url: '/etax/sources/shopee/connect',
      img: '/images/Shopee-logo.jpg',
    },
    {
      key: 'lazada',
      url: '/etax/sources/lazada/connect',
      img: '/images/lazada-logo.jpg',
    },
    {
      key: 'jdcentral',
      url: '/etax/sources/jdcentral/connect',
      img: '/images/JDcentral.jpg',
    },
  ];
  const accountExternalSources = [
    {
      key: 'xero',
      url: '/etax/sources/xero/connect',
      img: '/images/xero.png',
    },
    {
      key: 'shipnity',
      url: '/etax/sources/shipnity/connect',
      img: '/images/shipnity-logo.png',
    },
    {
      key: 'peak',
      url: '/etax/sources/peak/connect',
      img: '/images/peak.jpg',
    },
    {
      key: 'trcloud',
      url: '/etax/sources/trcloud/connect',
      img: '/images/trcloud.png',
    },
    {
      key: 'paypal',
      url: '/etax/sources/paypal/connect',
      img: '/images/paypal_vertical.png',
    },
    {
      key: 'quickbooks',
      url: '/etax/sources/quickbooks/connect',
      img: '/images/quickbooks.jpeg',
    },
    {
      key: 'zoho',
      url: '/etax/sources/zoho/connect',
      img: '/images/Zoho-logo.png',
    },
  ];

  function StackPlatform(props) {
    const externalSources = props.externalSources;

    let platforms = arrayTo2DArray2(externalSources, 3);

    let jsx = platforms.map((chunk) => (
      <Stack horizontal tokens={stackTokens} style={{ marginTop: 15 }}>
        {chunk.map((platform) => (
          <Stack
            style={{
              border: '1px solid rgb(134, 134, 134)',
              width: 300,
              height: 275,
              backgroundColor: '#FFFFFF',
            }}
          >
            <br />
            <br />
            <Stack
              horizontal
              horizontalAlign="center"
              verticalAlign="center"
              onClick={() =>
                history.push({
                  pathname: platform.url,
                })
              }
            >
              <Stack verticalAlign="center">
                <img src={platform.img} width="200px" alt="platformimg" />
              </Stack>
            </Stack>
            <br />
            <br />
          </Stack>
        ))}
      </Stack>
    ));

    return jsx;
  }

  return (
    <Stack>
      <Stack horizontal horizontalAlign="center">
        <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>ข้อมูลภายนอก</h2>
      </Stack>
      <Stack vertical>
        <h3 style={{ marginLeft: '0px' }}>แพลตฟอร์มซื้อขายออนไลน์ (Marketplace)</h3>
        <StackPlatform externalSources={platformExternalSources} />
      </Stack>
      <Stack vertical>
        <h3 style={{ marginLeft: '0px', marginTop: '25px' }}>โปรแกรมบัญชีทั้งหมด</h3>
        <StackPlatform externalSources={accountExternalSources} />
      </Stack>
    </Stack>
  );
};

export default Sources;
