import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, Icon, PrimaryButton, FocusTrapZone, Spinner } from '@fluentui/react';

// const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
const API_URL = process.env.REACT_APP_API_URL;
const APP_KEY = process.env.REACT_APP_TIKTOK_SHOP_APP_KEY;
const AUTH_HOST = process.env.REACT_APP_TIKTOK_SHOP_AUTH_HOST;

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

// const authorizationUrl = `https://auth-sandbox.tiktok-shops.com/oauth/authorize?app_key=687eeqa459un5&state=leceipt`

const TiktokShopConnect = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const history = useHistory();

  const { t } = useTranslation();

  const password = '';

  const [isLoading, setIsLoading] = useState(true);

  const [status, setStatus] = useState('');

//   const authorizationUrl = `https://auth-sandbox.tiktok-shops.com/oauth/authorize?app_key=${APP_KEY}&state=leceipt`;
  const authorizationUrl = `${AUTH_HOST}/oauth/authorize?app_key=${APP_KEY}&state=leceipt`;

  const companyId = sessionStorage.getItem('companyId') || '';

/* 
  useEffect(() => {
    // console.log(window.location.href.split("&")[0].split("=")[1])
    if (window.location.href.split("&")[0].split("=")[1] !== undefined) {
      console.log('userEffect retrieve access_Token!');

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get("http://localhost:7071/api/TiktokShop_getAccessToken", {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
              params: {
                code: window.location.href.split("&")[0].split("=")[1]
              }
            })
            .then(
              (response) => {
                
                const { access_token, refresh_token } = response.data.data
                
                console.log("access_token", access_token);
                if (access_token) {
                  history.push({
                    pathname: '/etax/sources/tiktok-shop',
                    state: {},
                  });
                }
  
                setIsLoading(false);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      setIsLoading(false)
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 */
  useEffect(() => {
    console.log('userEffect retrieve users data!');

    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');

    console.log('code: ', code);

    if (code) {
      setIsLoading(true);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + '/sources/tiktok-shop',
              {
                code: code,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              }
            )
            .then(
              (response) => {
                console.log(response);

                if (response.data) {
                  if (response.data.canLogin) {
                    history.push({
                      pathname: '/etax/sources/tiktok-shop',
                      state: {},
                    });
                  } else {
                    setStatus('fail');
                  }
                }

                // setPassword('');
                setIsLoading(false);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/sources/tiktok-shop', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            })
            .then(
              (response) => {
                console.log('TikTok Shop sources: ', response);

                if (response.data) {
                  if (!response.data.disabled && response.data.canLogin) {
                    history.push({
                      pathname: '/etax/sources/tiktok-shop',
                      state: {},
                    });
                  }
                }

                setIsLoading(false);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {!isLoading && (
        <Stack vertical>
          <br />

          <h1 style={{ marginLeft: '0px', marginTop: '27px' }}>
            <Icon iconName="CloudImportExport" style={{ color: '#106ebe' }} /> {t('tiktokShopConnect.header')}
          </h1>
          <h3 style={{ marginTop: '0px', marginBottom: '10px' }}>{t('tiktokShopConnect.subHeader1')}</h3>

          <br />
          <br />
        </Stack>
      )}
      {status === '' && !isLoading && (
        <FocusTrapZone disabled={true}>
          <Stack horizontalAlign="center">
            <Stack style={{ border: '1px solid rgb(134, 134, 134)', width: '350px', backgroundColor: '#FFFFFF' }}>
              <br />

              <Stack horizontal horizontalAlign="center">
                {/* <img src="/images/Shopee-logo.jpg" width="200px" alt="shopeelogo" /> */}
                <img src="/images/tiktok-shop-logo.png" width="255px" alt="shopeelogo" />
              </Stack>

              <br />
              {/* <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Shop ID"
                    required
                    onChange={(e, newValue) => 
                      setShopId(newValue)
                    }
                    value={shopId}
                    style={{ width: '250px' }}
                  />
                </Stack> */}

              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
                <PrimaryButton style={{ fontSize: '20px', height: '50px', width: '255px' }} type="submit" onClick={() => window.location.replace(authorizationUrl)}>
                  {t('tiktokShopConnect.connect')}
                </PrimaryButton>
              </Stack>
              <br />
              <br />
            </Stack>
          </Stack>
        </FocusTrapZone>
      )}

      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              {password !== '' ? (
                <Spinner label={t('tiktokShopConnect.processing')} labelPosition="down" styles={spinnerStyles} />
              ) : (
                <Spinner label={t('tiktokShopConnect.loading')} labelPosition="down" styles={spinnerStyles} />
              )}
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'success' && (
        <Stack horizontal horizontalAlign="center">
          <Stack style={{ border: '1px solid rgb(134, 134, 134)', width: '300px', backgroundColor: '#FFFFFF' }}>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#0078D4' }}>{t('tiktokShopConnect.successMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/success03.gif" alt="success03gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  history.push({
                    pathname: '/etax/sources/shipnity',
                    state: {},
                  });
                }}
              >
                {t('tiktokShopConnect.successButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'fail' && (
        <Stack horizontal horizontalAlign="center">
          <Stack style={{ border: '1px solid rgb(134, 134, 134)', width: '300px', backgroundColor: '#FFFFFF' }}>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#F25139' }}>{t('tiktokShopConnect.failMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/fail01.gif" alt="fail01gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  setStatus('');
                }}
              >
                {t('tiktokShopConnect.failButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      <br />
      <br />
      {status === '' && !isLoading && (
        <center>
          <br />
          <br />
          <span style={{ color: 'gray' }}>{t('tiktokShopConnect.footer1')}</span>
          <br />
          <br />
          <span style={{ color: 'gray', fontWeight: 'bold' }}>{t('tiktokShopConnect.footer2')}</span>
        </center>
      )}
    </Stack>
  );
};

export default TiktokShopConnect;
