export const ROW_NAMES = {
  order: 'order',
  documentType: 'documentType',
  number: 'number',
  dateBE: 'dateBE',
  dueDateBE: 'dueDateBE',
  reference: 'reference',
  includeVat: 'includeVat',
  calculationMethod: 'calculationMethod',
  discount: 'discount',
  language: 'language',
  customerName: 'customerName',
  customerAddressLineOne: 'customerAddressLineOne',
  customerAddressLineTwo: 'customerAddressLineTwo',
  customerPostcode: 'customerPostcode',
  customerCountryName: 'customerCountryName',
  customerTaxNumberType: 'customerTaxNumberType',
  customerTaxId: 'customerTaxId',
  customerBranchNumber: 'customerBranchNumber',
  customerPhone: 'customerPhone',
  customerEmail: 'customerEmail',
  note: 'note',
  createdBy: 'createdBy',
  receivedBy: 'receivedBy',
  reIssue: 'reIssue',
  referTypeCode: 'referTypeCode',
  referNumber: 'referNumber',
  referDateBE: 'referDateBE',
  referAmountTotal: 'referAmountTotal',
  referReasonCode: 'referReasonCode',
  referSpecificReason: 'referSpecificReason',

  itemDescription1: 'itemDescription1',
  itemQuantity1: 'itemQuantity1',
  itemUnitName1: 'itemUnitName1',
  itemPrice1: 'itemPrice1',
  itemDiscount1: 'itemDiscount1',
  itemPercentVat1: 'itemPercentVat1',

  itemDescription2: 'itemDescription2',
  itemQuantity2: 'itemQuantity2',
  itemUnitName2: 'itemUnitName2',
  itemPrice2: 'itemPrice2',
  itemDiscount2: 'itemDiscount2',
  itemPercentVat2: 'itemPercentVat2',

  itemDescription3: 'itemDescription3',
  itemQuantity3: 'itemQuantity3',
  itemUnitName3: 'itemUnitName3',
  itemPrice3: 'itemPrice3',
  itemDiscount3: 'itemDiscount3',
  itemPercentVat3: 'itemPercentVat3',

  itemDescription4: 'itemDescription4',
  itemQuantity4: 'itemQuantity4',
  itemUnitName4: 'itemUnitName4',
  itemPrice4: 'itemPrice4',
  itemDiscount4: 'itemDiscount4',
  itemPercentVat4: 'itemPercentVat4',

  itemDescription5: 'itemDescription5',
  itemQuantity5: 'itemQuantity5',
  itemUnitName5: 'itemUnitName5',
  itemPrice5: 'itemPrice5',
  itemDiscount5: 'itemDiscount5',
  itemPercentVat5: 'itemPercentVat5',

  noRow: 'noRow',
};

export const ROW_NAMES_REQUIRE = ['number', 'dateBE', 'customerName', 'customerAddressLineOne', 'customerPostcode', 'itemDescription1', 'itemPrice1'];
