import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  DatePicker,
  DayOfWeek,
  mergeStyleSets,
  PrimaryButton,
  DefaultButton,
  IconButton,
  Checkbox,
  Dropdown,
  Stack,
  getTheme,
  Shimmer,
  Toggle,
  Separator,
  Spinner,
} from '@fluentui/react';

import { useLocation, useHistory } from 'react-router-dom';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { getProvinceList, getDistrictList, getSubDistrictList, getProvince, getDistrict, getSubDistrict } from '../../../providers/AreaData';
import { getUnit, getUnitList } from '../../../providers/UnitData';
import { getCountry, getCountryList } from '../../../providers/CountryData';
import { getReasonList, reasonsTaxinvoice } from '../../../providers/ReasonData';
import { numberToTextEn, numberToTextTh } from '../../../helpers/BahtWord';
import TextFieldWithDropDown from '../../../helpers/customComponents/TextFieldWithDropDown';

const API_URL = process.env.REACT_APP_API_URL;

const contactListStyle = {
  background: 'white',
  border: '1px solid #eee',
  boxShadow: '0 2px 3px #ccc',
  boxSizing: 'border-box',
  maxHeight: 200,
  width: 310,
  position: 'absolute',
  overflow: 'auto',
  zIndex: 100000000,
};

const contactOptionStyle = {
  padding: '0px 8px',
  paddingTop: '8px',
  height: 26,
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

const theme = getTheme();

const onFormatDate = (date, format) => {
  if (format === 'CE') {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
  }
};

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '300px',
  },
  hover: {
    selectors: {
      '&:hover': { background: theme.palette.neutralLight },
    },
  },
});

const dropdownStyles = {
  dropdown: { width: 150 },
};

const InvoiceTaxInvoice = () => {
  const location = useLocation();
  const history = useHistory();

  const { t, i18n } = useTranslation();

  const [data] = useState(location.state);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  console.log('Render');

  const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyTaxId, setCompanyTaxId] = useState('');
  const [companyBranchText, setCompanyBranchText] = useState('');
  const [companyAllAddress, setCompanyAllAddress] = useState('');

  //Customer Detail
  const [name, setName] = useState('');
  const [buildingNumber, setBuildingNumber] = useState('');
  const [address, setAddress] = useState('');
  const [streetPrefix, setStreetPrefix] = useState('');
  const [streetName, setStreetName] = useState('');
  const [provincePrefix, setProvincePrefix] = useState('');
  const [province, setProvince] = useState({ key: '', text: '' });
  const [subDistrictPrefix, setSubDistrictPrefix] = useState('');
  const [subDistrict, setSubDistrict] = useState({ key: '', text: '' });
  const [subDistrictList, setSubDistrictList] = useState([]);
  const [districtPrefix, setDistrictPrefix] = useState('');
  const [district, setDistrict] = useState({ key: '', text: '' });
  const [districtList, setDistrictList] = useState([]);
  const [postcode, setPostCode] = useState('');
  const [phone, setPhone] = useState('');
  const [taxNumberType, setTaxNumberType] = useState('');
  const [taxId, setTaxId] = useState('');
  const [branchText, setBranchText] = useState('');
  const [branchDisplay, setBranchDisplay] = useState([
    { key: '00000', text: 'สำนักงานใหญ่' },
    { key: 'branchNumber', text: 'สาขาเลขที่' },
  ]);

  const [branch, setBranch] = useState('00000');
  const [branchNumber, setBranchNumber] = useState('00000');
  const [email, setEmail] = useState('');

  const [number, setNumber] = useState('');
  const [date, setDate] = useState(new Date().toISOString());
  const [dateBE, setDateBE] = useState(onFormatDate(new Date(), 'BE'));
  const [dateCE, setDateCE] = useState(onFormatDate(new Date(), 'CE'));
  const [note, setNote] = useState('');
  const [createdBy, setCreatedBy] = useState('');

  const [showTaxId, setShowTaxId] = useState(true);
  const [showBranch, setShowBranch] = useState(false);
  const [showBranchNumber, setShowBranchNumber] = useState(false);

  const [amountTotal, setAmountTotal] = useState(0);

  // VAT type
  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  const nonVatText = '-';

  const [vatPriceTotal, setVatPriceTotal] = useState(0);
  const [zeroVatPriceTotal, setZeroVatPriceTotal] = useState(0);
  const [nonVatPriceTotal, setNonVatPriceTotal] = useState(0);

  const [discountDisable, setDiscountDisable] = useState(false);

  const [vatTotal, setVatTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [grandTotalEN, setGrandTotalEN] = useState('');
  const [grandTotalTH, setGrandTotalTH] = useState('');

  const [language, setLanguage] = useState(sessionStorage.getItem('address-language') || 'th');
  const [structure, setStructure] = useState(true);
  const [lineOne, setLineOne] = useState('');
  const [lineTwo, setLineTwo] = useState('');

  const provinceList = getProvinceList(language);
  const unitList = getUnitList(language);
  const countryList = getCountryList(language);
  const reasonList = getReasonList(reasonsTaxinvoice, language);

  const [firstDayOfWeek] = React.useState(DayOfWeek.Sunday);

  const [items, setItems] = useState([
    {
      number: '',
      description: '',
      quantity: '',
      unitCode: '',
      unitName: '-',
      price: '',
      discount: 0,
      percentVat: percentVat,
      percentVatText: percentVatText,
      total: 0,
      sku: ''
    },
  ]);

  //Cancel and Replace Doc
  const [reIssue, setReIssue] = useState(false);
  const [referNumber, setReferNumber] = useState('');
  const [referDate, setReferDate] = useState('');
  const [referDateBE, setReferDateBE] = useState('');
  const [referDateCE, setReferDateCE] = useState('');
  const [reason, setReason] = useState({ key: '', text: '' });
  const [specificReason, setSpecificReason] = useState('');

  const [includeVat, setIncludeVat] = useState(false);
  const [discount, setDiscount] = useState(0);

  const [backUrl, setBackUrl] = useState('/etax/documents/all');

  //Style
  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  //Error Message
  const [provinceErrMsg, setProvinceErrMsg] = useState('');
  const [districtErrMsg, setDistrictErrMsg] = useState('');
  const [subDistrictErrMsg, setSubDistrictErrMsg] = useState('');

  const [taxNumberTypeErrMsg, setTaxNumberTypeErrMsg] = useState('');

  const [reasonErrMsg, setReasonErrMsg] = useState('');

  const [contactSuggestion, setContactSuggestion] = useState();
  const [contactList, setContactList] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showSuggestionsTaxId, setShowSuggestionsTaxId] = useState(false);

  const dateRef = useRef();
  const referDateRef = useRef();

  const DropdownErrMsg = t('invoiceTaxInvoice.dropdownErrMsg');

  const phoneRegEx = /(^0[1-9][0-9]{1,13}$|^$)/g;
  let phoneRef = useRef(null);

  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [reference, setReference] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [dueDateBE, setDueDateBE] = useState('');
  const [dueDateCE, setDueDateCE] = useState('');
  const dueDateRef = useRef();

  const [countryCode, setCountryCode] = useState('TH');
  const [countryName, setCountryName] = useState('ไทย');

  const companyId = sessionStorage.getItem('companyId') || '';

  const DayPickerStrings = {
    months: [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ],

    shortMonths: [
      t('documents.shortMonths01'),
      t('documents.shortMonths02'),
      t('documents.shortMonths03'),
      t('documents.shortMonths04'),
      t('documents.shortMonths05'),
      t('documents.shortMonths06'),
      t('documents.shortMonths07'),
      t('documents.shortMonths08'),
      t('documents.shortMonths09'),
      t('documents.shortMonths10'),
      t('documents.shortMonths11'),
      t('documents.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('invoiceTaxInvoice.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('invoiceTaxInvoice.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        //Check documents spending limit
        axios
          .get(API_URL + '/companies-details', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
          })
          .then(
            (response) => {
              console.log('Companies details data: ', response);

              if (response.data) {
                if (
                  response.data &&
                  response.data.balances &&
                  response.data.balances.documents &&
                  response.data.balances.documents.credits !== undefined &&
                  response.data.balances.documents.credits !== null
                ) {
                  console.log('Credit documents: ', response.data.balances.documents.credits);

                  let _documents = response.data.balances.documents.credits;

                  if (_documents <= 0) {
                    history.push({
                      pathname: '/billings',
                      state: {},
                    });
                  }
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );

        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                  
                  if (_company.includeVat && !data) {
                    setIncludeVat(_company.includeVat);
                  } else {
                    _company.includeVat = false;
                  }
  
                  setCompany(_company);
                  setCompanyName(_company.name);
                  setCompanyPhone(_company.phone);
                  setCompanyEmail(_company.email);
                  setCompanyTaxId(_company.taxId);
                  setCompanyBranchText(_company.branchText);
                } else {
                  if (response.data[0].includeVat && !data) {
                    setIncludeVat(response.data[0].includeVat);
                  } else {
                    response.data[0].includeVat = false;
                  }
  
                  setCompany(response.data[0]);
                  setCompanyName(response.data[0].name);
                  setCompanyPhone(response.data[0].phone);
                  setCompanyEmail(response.data[0].email);
                  setCompanyTaxId(response.data[0].taxId);
                  setCompanyBranchText(response.data[0].branchText);
                }
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );

        axios
          .get(API_URL + '/functions/wakeup', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then((response) => {
            console.log('Wake up function: ', response);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('userEffect retrieve contact data!');

    async function getContact() {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/contacts', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            })
            .then((response) => {
              console.log(response.data);
              setContactList(response.data);
            });
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    getContact();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Company state: ', company);

    if (company.taxId) {
      console.log('Company useEffect call!');
      console.log(company);
      console.log(company.id);

      setCompanyAllAddress(
        company.buildingNumber +
          ' ' +
          (!/^-*$/.test(company.address) && company.address.trim() && !/^-*$/.test(company.address.trim()) ? company.address : '') +
          ' ' +
          (!/^-*$/.test(company.streetName) && company.streetName.trim() && !/^-*$/.test(company.streetName.trim())
            ? company.streetPrefix + company.streetName
            : '') +
          ' ' +
          company.subDistrictPrefix +
          company.subDistrictName +
          ' ' +
          company.districtPrefix +
          company.districtName +
          ' ' +
          company.provincePrefix +
          company.provinceName +
          ' ' +
          company.postcode
      );
    }
  }, [company]);

  useEffect(() => {
    console.log('Language Effect: ', language);

    console.log('Province: ', province);

    if (language != null) {
      setProvince(getProvince(province.key, language));
      setDistrict(getDistrict(district.key, language));
      setDistrictList(getDistrictList(province.key, language));
      setSubDistrict(getSubDistrict(subDistrict.key, language));
      setSubDistrictList(getSubDistrictList(district.key, language));
      setCountryName(getCountry(countryCode, language).text);

      handleBranch(branchNumber, language);

      setRoadPrefix(streetName);
      setAreaPrefix(province.key);

      const values = [...items];

      values.forEach((value) => {
        value.unitCode = getUnit(value.unitCode, language).key;
        value.unitName = getUnit(value.unitCode, language).text;
      });

      setItems(values);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    console.log('useEffect data call!: ', data);

    if (data !== undefined) {
      if (data.command === 'Contact') {
        setName(data.customer.name);

        setPostCode(data.customer.postcode);
        setPhone(data.customer.phone);
        setTaxNumberType(data.customer.taxNumberType);
        setTaxId(data.customer.taxId);
        setBranchText(data.customer.branchText);
        setBranchNumber(data.customer.branchNumber);

        let _language = '';

        if (data.customer.language != null) {
          setLanguage(data.customer.language);
          _language = data.customer.language;
        } else {
          _language = language;
        }

        if (data.customer.branchNumber === '00000' && !(data.customer.branchText.includes('สาขา') || data.customer.branchText.includes('Branch'))) {
          setBranch('00000');
          setShowBranchNumber(false);
        } else if (
          data.customer.branchNumber.length === 5 &&
          (data.customer.branchText.includes('สาขา') || data.customer.branchText.includes('Branch'))
        ) {
          setBranch('branchNumber');
          setShowBranchNumber(true);
        }

        setEmail(data.customer.email);
        setContactSuggestion(data.contactSuggestion);

        if (data.customer.taxNumberType === 'TXID') {
          setShowBranch(true);
        } else {
          setShowBranch(false);
        }

        if (data.customer.taxNumberType === 'OTHR') {
          setShowTaxId(false);
        } else {
          setShowTaxId(true);
        }

        setStructure(!data.customer.unstructure);

        if (data.customer.unstructure) {
          setLineOne(data.customer.addressLineOne);
          setLineTwo(data.customer.addressLineTwo);

          setCountryCode(data.customer.countryCode);
          setCountryName(data.customer.countryName);
        } else {
          setBuildingNumber(data.customer.buildingNumber);
          setAddress(data.customer.address);
          setStreetPrefix(data.customer.streetPrefix);
          setStreetName(data.customer.streetName);
          setProvincePrefix(data.customer.provincePrefix);
          setProvince({
            key: data.customer.provinceCode,
            text: data.customer.provinceName,
          });

          setSubDistrictList(getSubDistrictList(data.customer.districtCode, _language));
          setSubDistrictPrefix(data.customer.subDistrictPrefix);
          setSubDistrict({
            key: data.customer.subDistrictCode,
            text: data.customer.subDistrictName,
          });

          setDistrictList(getDistrictList(data.customer.provinceCode, _language));
          setDistrictPrefix(data.customer.districtPrefix);
          setDistrict({
            key: data.customer.districtCode,
            text: data.customer.districtName,
          });
        }

        setBackUrl(data.backUrl);
      } else {
        if (data.command === 'reIssue') {
          setReIssue(true);

          setReferNumber(data.number);
          setReferDate(data.date);
          setReferDateBE(data.dateBE);
          setReferDateCE(data.dateCE);
          setReason({ key: '', text: '' });
          setSpecificReason('');
        } else {
          setNumber(data.number);

          if (data.date) {
            setDate(data.date);
            setDateBE(data.dateBE);
            setDateCE(data.dateCE);
          }

          setReIssue(data.reIssue);
          if (data.reIssue) {
            setReferNumber(data.refer.number);
            setReferDate(data.refer.date);
            setReferDateBE(data.refer.dateBE);
            setReferDateCE(data.refer.dateCE);
            setReason({
              key: data.refer.reasonCode,
              text: data.refer.reasonName,
            });
            setSpecificReason(data.refer.specificReason);
          }
        }
        //Company
        setCompany(data.company);
        setCompanyName(data.company.name);
        setCompanyBranchText(data.company.branchText);
        setCompanyPhone(data.company.phone);
        setCompanyEmail(data.company.email);
        setCompanyTaxId(data.company.taxId);

        //Customer
        setName(data.customer.name);

        setPostCode(data.customer.postcode);
        setPhone(data.customer.phone);
        setTaxNumberType(data.customer.taxNumberType);
        setTaxId(data.customer.taxId);
        setBranchText(data.customer.branchText);
        setBranchNumber(data.customer.branchNumber);

        setCountryCode(data.customer.countryCode || 'TH');
        setCountryName(data.customer.countryName || 'ไทย');

        if (data.customer.branchNumber === '00000' && !(data.customer.branchText.includes('สาขา') || data.customer.branchText.includes('Branch'))) {
          setBranch('00000');
          setShowBranchNumber(false);
        } else if (
          data.customer.branchNumber.length === 5 &&
          (data.customer.branchText.includes('สาขา') || data.customer.branchText.includes('Branch'))
        ) {
          setBranch('branchNumber');
          setShowBranchNumber(true);
        }

        setEmail(data.customer.email);

        if (data.contactSuggestion) {
          setContactSuggestion(data.contactSuggestion);
        } else {
          async function getContactSuggestion() {
            instance
              .acquireTokenSilent({
                ...silentRequest,
                account: account,
              })
              .then((tokenResponse) => {
                axios
                  .get(API_URL + '/contacts', {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId
                    },
                  })
                  .then((response) => {
                    let suggestion = response.data.filter((contact) => contact.taxId === data.customer.taxId);

                    setContactSuggestion(suggestion[0]);
                  });
              })
              .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
              });
          }

          getContactSuggestion();
        }

        if (data.customer.taxNumberType === 'TXID') {
          setShowBranch(true);
        } else {
          setShowBranch(false);
        }

        if (data.customer.taxNumberType === 'OTHR') {
          setShowTaxId(false);
        } else {
          setShowTaxId(true);
        }

        setLanguage(data.customer.language);
        setStructure(!data.customer.unstructure);

        if (data.customer.unstructure) {
          setLineOne(data.customer.addressLineOne);
          setLineTwo(data.customer.addressLineTwo);
        } else {
          setBuildingNumber(data.customer.buildingNumber);
          setAddress(data.customer.address);
          setStreetPrefix(data.customer.streetPrefix);
          setStreetName(data.customer.streetName);
          setProvincePrefix(data.customer.provincePrefix);
          setProvince({
            key: data.customer.provinceCode,
            text: data.customer.provinceName,
          });

          setSubDistrictList(getSubDistrictList(data.customer.districtCode, data.customer.language));
          setSubDistrictPrefix(data.customer.subDistrictPrefix);
          setSubDistrict({
            key: data.customer.subDistrictCode,
            text: data.customer.subDistrictName,
          });

          setDistrictList(getDistrictList(data.customer.provinceCode, data.customer.language));
          setDistrictPrefix(data.customer.districtPrefix);
          setDistrict({
            key: data.customer.districtCode,
            text: data.customer.districtName,
          });
        }

        setDiscount(data.discount);

        let _items = [];
        let nonVatPrice = 0;
        let zeroVatPrice = 0;
        let vatPrice = 0;

        data.items.forEach((item) => {
          if (item.percentVat === nonVat) {
            nonVatPrice += item.total;
          } else if (item.percentVat === zeroVat) {
            zeroVatPrice += item.total;
          } else {
            vatPrice += item.total;
          }

          let _percentVat = '';
          let _percentVatText = '';

          if (item.percentVat === zeroVat) {
            _percentVat = item.percentVat;
            _percentVatText = zeroVatText;
          } else if (item.percentVat === nonVat) {
            _percentVat = item.percentVat;
            _percentVatText = nonVatText;
          } else {
            _percentVat = percentVat;
            _percentVatText = percentVatText;
          }

          _items.push({
            number: item.number,
            description: item.description,
            quantity: item.quantity,
            unitCode: item.unitCode,
            unitName: item.unitName,
            price: item.price,
            discount: item.discount,
            percentVat: _percentVat,
            percentVatText: _percentVatText,
            total: item.total,
            sku: item.sku ? item.sku : ''
          });
        });

        setVatPriceTotal(vatPrice);
        setZeroVatPriceTotal(zeroVatPrice);
        setNonVatPriceTotal(nonVatPrice);

        setItems(_items);

        setAmountTotal(data.amountTotal);
        setVatTotal(data.vatTotal);
        setGrandTotal(data.grandTotal);

        setGrandTotalEN(data.grandTotalEN);
        setGrandTotalTH(data.grandTotalTH);

        setNote(data.note);
        setCreatedBy(data.createdBy);

        if (data.customer.language === 'en') {
          setBranchDisplay([
            { key: '00000', text: 'Head office' },
            { key: 'branchNumber', text: 'Branch' },
          ]);
        } else {
          setBranchDisplay([
            { key: '00000', text: 'สำนักงานใหญ่' },
            { key: 'branchNumber', text: 'สาขาเลขที่' },
          ]);
        }

        if (data.backUrl) {
          setBackUrl(data.backUrl);
        }

        setIncludeVat(data.includeVat);

        setDueDate(data.dueDate);
        setDueDateBE(data.dueDateBE);
        setDueDateCE(data.dueDateCE);
        setReference(data.reference);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    console.log('useEffect items changed!: ', items);

    let vatTypeArray = [];

    items.forEach((item) => {
      if (!vatTypeArray.includes(item.percentVat)) {
        vatTypeArray.push(item.percentVat);
      }
      if (vatTypeArray.length > 1) {
        setDiscountDisable(true);
        setDiscount(0);
      } else {
        setDiscountDisable(false);
      }
    });

    calAmountTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    console.log('useEffect items changed!: ', items);
    calAmountTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeVat]);

  useEffect(() => {
    console.log('useEffect special discount changed!: ', discount);
    calAmountTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount]);

  useEffect(() => {
    let suggestions = contactList.filter((contact) => contact.name.includes(name));

    setSuggestionList(suggestions);
  }, [name, contactList]);

  useEffect(() => {
    let suggestions = contactList.filter((contact) => contact.taxId.includes(taxId));

    setSuggestionList(suggestions);
  }, [taxId, contactList]);

  const handleTaxType = (selectedOption) => {
    console.log('Change tax type:', selectedOption.key);

    setTaxNumberType(selectedOption.key);

    if (selectedOption.key === 'TXID') {
      setShowBranch(true);
      setBranchNumber('00000');

      if (language === 'en') {
        setBranchText('Head office');
      } else {
        setBranchText('สำนักงานใหญ่');
      }
    } else {
      setShowBranch(false);
      setBranchText('');
      setBranchNumber('');
    }

    if (selectedOption.key === 'OTHR') {
      setShowTaxId(false);
      setTaxId('N/A');
    } else {
      setTaxId('');
      setShowTaxId(true);
    }
  };

  const handleBranch = (branchNum, lang) => {
    console.log('Change branch:', branchNum);
    if (branchNum === '00000') {
      setShowBranchNumber(false);
      setBranch('00000');
      setBranchNumber('00000');

      if (lang === 'en') {
        setBranchText('Head office');
      } else {
        setBranchText('สำนักงานใหญ่');
      }
    } else {
      if (branchNum !== '') {
        setBranch('branchNumber');
        if (branchNum === 'branchNumber') {
          setBranchNumber('00000');

          if (lang === 'en') {
            setBranchText('Branch 00000');
          } else {
            setBranchText('สาขา 00000');
          }
        } else {
          setBranchNumber(branchNum);

          if (lang === 'en') {
            setBranchText('Branch ' + branchNum);
          } else {
            setBranchText('สาขา ' + branchNum);
          }
        }
        setShowBranchNumber(true);
      }
    }

    if (lang === 'en') {
      setBranchDisplay([
        { key: '00000', text: 'Head office' },
        { key: 'branchNumber', text: 'Branch' },
      ]);
    } else {
      setBranchDisplay([
        { key: '00000', text: 'สำนักงานใหญ่' },
        { key: 'branchNumber', text: 'สาขาเลขที่' },
      ]);
    }
  };

  const handleChangeItem = (index, event, selectedOption) => {
    console.log('Change index:', index);
    const values = [...items];
    if (selectedOption) {
      if (selectedOption.id === 'percentVat') {
        values[index]['percentVat'] = selectedOption.key;
        values[index]['percentVatText'] = selectedOption.text;
      } else {
        values[index]['unitCode'] = selectedOption.key;
        values[index]['unitName'] = selectedOption.text;
      }
    } else {
      if (event.target.name === 'quantity' || event.target.name === 'price' || event.target.name === 'discount') {
        if (Number(event.target.value) >= 9999999999.99) {
          values[index][event.target.name] = 9999999999.99;
        } else {
          values[index][event.target.name] = event.target.value;
        }
      } else {
        values[index][event.target.name] = event.target.value;

        if (event.target.name === 'description') {
          values[index]['sku'] = ''
        }
      }
    }

    setItems(values);
  };

  const handleBlurItem = (index, event) => {
    const values = [...items];
    values[index][event.target.name] = Math.round(values[index][event.target.name] * 100) / 100;
    setItems(values);
    calLineTotal(index);
  };

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        number: '',
        description: '',
        quantity: '',
        unitCode: '',
        unitName: '-',
        price: 0,
        discount: 0,
        percentVat: percentVat,
        percentVatText: percentVatText,
        total: 0,
        sku: ''
      },
    ]);
  };

  const handleRemoveItem = (index) => {
    console.log('Delete index:', index);
    const values = [...items];
    values.splice(index, 1);
    setItems(values);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let isCorrect = true;

    if (structure) {
      if (province.key == undefined || province.key == null || province.key == '') {
        isCorrect = false;
        setProvinceErrMsg(DropdownErrMsg);
      }

      if (district.key == undefined || district.key == null || district.key == '') {
        isCorrect = false;
        setDistrictErrMsg(DropdownErrMsg);
      }

      if (subDistrict.key == undefined || subDistrict.key == null || subDistrict.key == '') {
        isCorrect = false;
        setSubDistrictErrMsg(DropdownErrMsg);
      }
    }

    if (taxNumberType == undefined || taxNumberType == null || taxNumberType == '') {
      isCorrect = false;
      setTaxNumberTypeErrMsg(DropdownErrMsg);
    }

    if (date == undefined || date == null || date == '') {
      isCorrect = false;
      dateRef.current.focus();
    }

    if (reIssue) {
      if (referDate == undefined || referDate == null || referDate == '') {
        isCorrect = false;
        referDateRef.current.focus();
      }

      if (reason.key == undefined || reason.key == null || reason.key == '') {
        isCorrect = false;
        setReasonErrMsg(DropdownErrMsg);
      }
    }

    if (taxNumberType === 'TXID') {
      if (taxId.length !== 13 || branchNumber.length !== 5 || isNaN(branchNumber)) {
        isCorrect = false;
      }
    } else if (taxNumberType === 'NIDN') {
      if (taxId.length !== 13) {
        isCorrect = false;
      }
    } else if (taxNumberType === 'CCPT') {
      if (taxId.length === 0) {
        isCorrect = false;
      }
    }

    if (taxId === '0000000000000' || Number(taxId) === 0) {
      isCorrect = false;
    }

    if (postcode) {
      if (countryCode === 'TH') {
        if (isNaN(postcode)) isCorrect = false;
        if (postcode.length !== 5) isCorrect = false;
      }
    }

    if (phoneNumberError) {
      isCorrect = false;
      phoneRef.focus();
    }

    items.forEach((item) => {
      if (item.total < 0) {
        isCorrect = false;
      }
    });

    console.log(
      'Name:',
      name,
      '\nbuildingNumber:',
      buildingNumber,
      '\naddress:',
      address,
      '\nstreetName:',
      streetName,
      '\nprovincePrefix:',
      provincePrefix,
      '\nprovince:',
      province,
      '\ndistrictPrefix:',
      districtPrefix,
      '\ndistrict:',
      district,
      '\nsubDistrictPrefix:',
      subDistrictPrefix,
      '\nsubDistrict:',
      subDistrict,
      '\npostcode:',
      postcode,
      '\nphone:',
      phone,
      '\ntaxId:',
      taxId,
      '\nbranchNumber:',
      branchNumber,
      '\nemail:',
      email
    );

    console.log(
      'number:',
      number,
      '\ndate:',
      date,
      '\nnote:',
      note,
      '\namountTotal:',
      amountTotal,
      '\npercentVat:',
      percentVat,
      '\nvatTotal:',
      vatTotal,
      '\ngrandTotal:',
      grandTotal
    );
    console.log('Items', items);

    if (isCorrect) {
      let _customer = {};

      if (structure) {
        _customer = {
          address: address,
          branchNumber: branchNumber,
          branchText: branchText,
          buildingNumber: buildingNumber,
          streetPrefix: streetPrefix,
          streetName: streetName,
          districtPrefix: districtPrefix,
          districtCode: district.key,
          districtName: district.text,
          email: email,
          name: name,
          phone: phone,
          phoneCountryCode: '+66',
          postcode: postcode,
          provincePrefix: provincePrefix,
          provinceCode: province.key,
          provinceName: province.text,
          subDistrictPrefix: subDistrictPrefix,
          subDistrictCode: subDistrict.key,
          subDistrictName: subDistrict.text,
          taxId: taxId,
          taxNumberType: taxNumberType,
          language: language,
          countryCode: countryCode,
          countryName: countryName,
          unstructure: false,
        };
      } else {
        _customer = {
          addressLineOne: lineOne,
          addressLineTwo: lineTwo,
          branchNumber: branchNumber,
          branchText: branchText,
          email: email,
          name: name,
          phone: phone,
          phoneCountryCode: '+66',
          postcode: postcode,
          taxId: taxId,
          taxNumberType: taxNumberType,
          language: language,
          countryCode: countryCode,
          countryName: countryName,
          unstructure: true,
        };
      }

      if (!reIssue) {
        history.push({
          pathname: '/etax/documents/invoices-taxinvoices/confirm',
          state: {
            // location state
            amountTotal: amountTotal >= 0 ? amountTotal : 0,
            company: company,
            customer: _customer,
            date: date,
            dateBE: dateBE,
            dateCE: dateCE,
            discount: discount,
            grandTotal: grandTotal >= 0 ? grandTotal : 0,
            grandTotalTH: grandTotalTH,
            grandTotalEN: grandTotalEN,
            nonVatPriceTotal: nonVatPriceTotal >= 0 ? nonVatPriceTotal : 0,
            zeroVatPriceTotal: zeroVatPriceTotal >= 0 ? zeroVatPriceTotal : 0,
            vatPriceTotal: vatPriceTotal >= 0 ? vatPriceTotal : 0,
            items: items,
            note: note,
            number: number,
            percentVat: percentVat,
            createdBy: createdBy,
            vatTotal: vatTotal >= 0 ? vatTotal : 0,
            reIssue: reIssue,
            backUrl: backUrl,
            includeVat: includeVat,
            contactSuggestion: contactSuggestion,
            dueDate: dueDate,
            dueDateBE: dueDateBE,
            dueDateCE: dueDateCE,
            reference: reference,
          },
        });
      } else {
        history.push({
          pathname: '/etax/documents/invoices-taxinvoices/confirm',
          state: {
            // location state
            amountTotal: amountTotal >= 0 ? amountTotal : 0,
            company: company,
            customer: _customer,
            date: date,
            dateBE: dateBE,
            dateCE: dateCE,
            discount: discount,
            grandTotal: grandTotal >= 0 ? grandTotal : 0,
            grandTotalTH: grandTotalTH,
            grandTotalEN: grandTotalEN,
            nonVatPriceTotal: nonVatPriceTotal >= 0 ? nonVatPriceTotal : 0,
            zeroVatPriceTotal: zeroVatPriceTotal >= 0 ? zeroVatPriceTotal : 0,
            vatPriceTotal: vatPriceTotal >= 0 ? vatPriceTotal : 0,
            items: items,
            note: note,
            number: number,
            percentVat: percentVat,
            createdBy: createdBy,
            vatTotal: vatTotal >= 0 ? vatTotal : 0,

            //Cancel and Replace Doc
            reIssue: reIssue,
            refer: {
              number: referNumber,
              date: referDate,
              dateBE: referDateBE,
              dateCE: referDateCE,
              reasonCode: reason.key,
              reasonName: reason.text,
              specificReason: specificReason,
            },
            backUrl: backUrl,
            includeVat: includeVat,
            contactSuggestion: contactSuggestion,
            dueDate: dueDate,
            dueDateBE: dueDateBE,
            dueDateCE: dueDateCE,
            reference: reference,
          },
        });
      }
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: backUrl,
      state: {},
    });
  };

  const calLineTotal = (index) => {
    const values = [...items];
    values[index]['total'] = Math.round(values[index]['quantity'] * (values[index]['price'] - values[index]['discount']) * 100) / 100;
    setItems(values);
    calAmountTotal();
  };

  const calAmountTotal = () => {
    let sumVatTotal = 0;
    let sumGrandTotal = 0;

    let sumAmountTotal = 0;
    let nonVatPrice = 0;
    let zeroVatPrice = 0;
    let vatPrice = 0;

    if (includeVat) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].percentVat === nonVat) {
          nonVatPrice += items[i]['total'];
          sumGrandTotal += items[i]['total'];
        } else if (items[i].percentVat === zeroVat) {
          zeroVatPrice += items[i]['total'];
          sumGrandTotal += items[i]['total'];
        } else {
          vatPrice += items[i]['total'];
          sumGrandTotal += items[i]['total'];
        }
      }

      if (!discountDisable) {
        sumGrandTotal -= discount;
        if (vatPrice !== 0) {
          vatPrice -= discount;
        } else if (zeroVatPrice !== 0) {
          zeroVatPrice -= discount;
        } else {
          nonVatPrice -= discount;
        }
      }

      sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;
      vatPrice = Math.round(vatPrice * 100) / 100;
      nonVatPrice = Math.round(nonVatPrice * 100) / 100;
      zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;

      sumVatTotal =
        Math.round(((vatPrice * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
      sumAmountTotal = sumGrandTotal - sumVatTotal;

      setNonVatPriceTotal(nonVatPrice);
      setZeroVatPriceTotal(zeroVatPrice - Math.round(((zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100);
      setVatPriceTotal(vatPrice - Math.round(((vatPrice * percentVat) / (100 + percentVat)) * 100) / 100);
    } else {
      for (let i = 0; i < items.length; i++) {
        if (items[i].percentVat === nonVat) {
          nonVatPrice += items[i]['total'];
          sumAmountTotal += items[i]['total'];
        } else if (items[i].percentVat === zeroVat) {
          zeroVatPrice += items[i]['total'];
          sumAmountTotal += items[i]['total'];
        } else {
          vatPrice += items[i]['total'];
          sumAmountTotal += items[i]['total'];
        }
      }

      if (!discountDisable) {
        sumAmountTotal -= discount;
        if (vatPrice !== 0) {
          vatPrice -= discount;
        } else if (zeroVatPrice !== 0) {
          zeroVatPrice -= discount;
        } else {
          nonVatPrice -= discount;
        }
      }

      sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;
      vatPrice = Math.round(vatPrice * 100) / 100;
      nonVatPrice = Math.round(nonVatPrice * 100) / 100;
      zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;

      sumVatTotal = Math.round(((vatPrice * percentVat) / 100) * 100) / 100 + Math.round(((zeroVatPrice * zeroVat) / 100) * 100) / 100;
      sumGrandTotal = sumAmountTotal + sumVatTotal;

      setNonVatPriceTotal(nonVatPrice);
      setZeroVatPriceTotal(zeroVatPrice);
      setVatPriceTotal(vatPrice);
    }

    setAmountTotal(sumAmountTotal);
    setVatTotal(sumVatTotal);
    setGrandTotal(sumGrandTotal);

    setGrandTotalEN(numberToTextEn(sumGrandTotal >= 0 ? sumGrandTotal : 0));
    setGrandTotalTH(numberToTextTh(sumGrandTotal >= 0 ? sumGrandTotal : 0));
  };

  const handleReIssue = () => {
    setReIssue(!reIssue);
    console.log('reIssue:', reIssue);

    if (!reIssue) {
      setReferNumber('');
      setReferDate('');
      setReferDate('');
      setReferDateBE('');
      setReferDateCE('');
      setReason({ key: '', text: '' });
      setSpecificReason('');
    }
  };

  const handleReason = (selectedOption) => {
    console.log('Change reason:', selectedOption.key);
    if (selectedOption.key !== 'TIVC99') {
      setSpecificReason('');
      setReason(selectedOption);
      setReasonErrMsg('');
    } else {
      setReason(selectedOption);
      setReasonErrMsg('');
    }
  };

  const handleStructure = (checked) => {
    console.log('Address structure:', checked);

    setStructure(checked);
    if (checked) {
      setBuildingNumber('');
      setAddress('');
      setStreetPrefix('');
      setStreetName('');
      setProvincePrefix('');
      setProvince({ key: '', text: '' });
      setDistrictPrefix('');
      setDistrict({ key: '', text: '' });
      setSubDistrict('');
      setSubDistrict({ key: '', text: '' });

      setCountryCode('TH');
      setCountryName('ไทย');
    } else {
      setLineOne('');
      setLineTwo('');
    }
  };

  function handleSelectSuggestions(e, suggestion) {
    setContactSuggestion(suggestion);

    setName(suggestion.name);

    if (suggestion.addressLineOne) {
      setStructure(false);
      setLineOne(suggestion.addressLineOne);
      setLineTwo(suggestion.addressLineTwo);

      setCountryCode(suggestion.countryCode);
      setCountryName(suggestion.countryName);
    }

    if (suggestion.buildingNumber) {
      setStructure(true);

      setBuildingNumber(suggestion.buildingNumber);
      setAddress(suggestion.address);

      setStreetName(suggestion.streetName);

      if (suggestion.language != null) {
        setLanguage(suggestion.language);
      }

      setProvincePrefix(suggestion.provincePrefix);
      setProvince({
        key: suggestion.provinceCode,
        text: suggestion.provinceName,
      });

      setDistrictList(getDistrictList(suggestion.provinceCode, suggestion.language));
      setDistrictPrefix(suggestion.districtPrefix);
      setDistrict({
        key: suggestion.districtCode,
        text: suggestion.districtName,
      });

      setSubDistrictList(getSubDistrictList(suggestion.districtCode, suggestion.language));
      setSubDistrictPrefix(suggestion.subDistrictPrefix);
      setSubDistrict({
        key: suggestion.subDistrictCode,
        text: suggestion.subDistrictName,
      });

      if (suggestion.language === 'en') {
        setStreetPrefix('');
      } else {
        setStreetPrefix(suggestion.streetPrefix);
      }

      setAreaPrefix(suggestion.provinceCode);
    }

    setPostCode(suggestion.postcode);

    if (suggestion.taxNumberType === 'TXID') {
      setTaxNumberType(suggestion.taxNumberType);
      setShowTaxId(true);
      setShowBranch(true);

      setBranchNumber(suggestion.branchNumber);

      if (suggestion.language != null) {
        if (suggestion.branchNumber === '00000') {
          if (suggestion.language === 'en') {
            setBranchText('Head office');
          } else {
            setBranchText('สำนักงานใหญ่');
          }
        } else {
          if (suggestion.language === 'en') {
            setBranchText('Branch ' + suggestion.branchNumber);
          } else {
            setBranchText('สาขา ' + suggestion.branchNumber);
          }
        }
      } else {
        if (suggestion.branchNumber === '00000') {
          if (language === 'en') {
            setBranchText('Head office');
          } else {
            setBranchText('สำนักงานใหญ่');
          }
        } else {
          if (language === 'en') {
            setBranchText('Branch ' + suggestion.branchNumber);
          } else {
            setBranchText('สาขา ' + suggestion.branchNumber);
          }
        }
      }

      if (suggestion.branchNumber === '00000' && !(suggestion.branchText.includes('สาขา') || suggestion.branchText.includes('Branch'))) {
        setBranch('00000');
        setShowBranchNumber(false);
      } else if (suggestion.branchNumber.length === 5 && (suggestion.branchText.includes('สาขา') || suggestion.branchText.includes('Branch'))) {
        setBranch('branchNumber');
        setShowBranchNumber(true);
      }
    } else {
      if (suggestion.taxNumberType === 'OTHR') {
        setShowTaxId(false);
      } else {
        setShowTaxId(true);
      }
      setTaxNumberType(suggestion.taxNumberType);
      setShowBranch(false);
      setShowBranchNumber(false);
      setBranchText('');
      setBranchNumber('');
    }

    setTaxId(suggestion.taxId);

    setPhone(suggestion.phone);
    setEmail(suggestion.email);
  }

  const getErrorMessage = (value) => {
    return value && value !== '0' ? '' : t('invoiceTaxInvoice.getErrorMessage');
  };

  const getPostCodeErrorMessage = (value) => {
    if (countryCode === 'TH') {
      if (isNaN(value)) return t('invoiceTaxInvoice.getPostCodeErrorMessage1');
      if (!value || value.length !== 5) return t('invoiceTaxInvoice.getPostCodeErrorMessage2');
    }
    return '';
  };

  let postCodeRef = useRef(false);

  useLayoutEffect(() => {
    if (postCodeRef.current) {
      postCodeRef.focus();
      postCodeRef.blur();
    } else {
      postCodeRef.current = true;
    }
  }, [countryCode]);

  let itemsNumberRef = useRef(items.slice(0, items.length));
  let itemsDescRef = useRef(items.slice(0, items.length));
  let itemsQtyRef = useRef(items.slice(0, items.length));
  let itemsPriceRef = useRef(items.slice(0, items.length));

  let referReasonRef = useRef(false);

  let refArray = useRef({
    customerName: '',
    lineOneRef: '',
    buildingNumber: '',
    taxIdRef: '',
    branchNumberRef: '',
    numberRef: '',
    referNumber: '',
    referSpecificReason: '',
  });

  useLayoutEffect(() => {
    itemsNumberRef.current.forEach((_ref) => {
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsDescRef.current.forEach((_ref) => {
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsQtyRef.current.forEach((_ref) => {
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsPriceRef.current.forEach((_ref) => {
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });
  }, [i18n.language]);

  useLayoutEffect(() => {
    console.log('referReasonRef', referReasonRef);
    Object.keys(refArray.current).forEach((key) => {
      let _ref = refArray.current[key];
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });

    if (phoneRef._errorMessage) {
      phoneRef.focus();
      phoneRef.blur();
    }

    if (postCodeRef._errorMessage) {
      postCodeRef.focus();
      postCodeRef.blur();
    }

    if (referDateRef.current?.state?.errorMessage) {
      referDateRef.current?._onTextFieldBlur();
    }

    if (referReasonRef.current?.props?.errorMessage) {
      referReasonRef.current?._onDropdownBlur();
    }
  }, [i18n.language]);

  const getTaxIdErrorMessage = (value) => {
    if ((taxNumberType == 'TXID' || taxNumberType == 'NIDN') && taxId.length !== 13) {
      return t('invoiceTaxInvoice.getTaxIdErrorMessage');
    } else if (value === '' || value === null || value === undefined) {
      return t('invoiceTaxInvoice.getErrorMessage');
    } else if (value === '0000000000000') {
      return t('documents.getErrorMessageTaxIdZeroOnly');
    } else {
      return '';
    }
  };

  const getBranchNumberErrorMessage = (value) => {
    if (branchNumber.length !== 5) {
      return t('invoiceTaxInvoice.getBranchNumberErrorMessage2');
    } else if (isNaN(value)) {
      return t('invoiceTaxInvoice.getBranchNumberErrorMessage1');
    } else if (value === '' || value === null || value === undefined) {
      return t('invoiceTaxInvoice.getErrorMessage');
    } else {
      return '';
    }
  };

  const getPhoneNumberErrorMessage = (value) => {
    setPhoneNumberError(false);
    if (value === null || value === undefined) {
      setPhoneNumberError(true);
      return t('invoiceTaxInvoice.getErrorMessage');
    } else if (phoneRegEx.test(value) !== true) {
      setPhoneNumberError(true);
      return t('invoiceTaxInvoice.getPhoneNumberErrorMessage');
    }
  };

  const setRoadPrefix = (_streetName) => {
    if (_streetName === '') {
      setStreetPrefix('');
    } else {
      if (language === 'th') {
        setStreetPrefix('ถ.');
      } else {
        setStreetPrefix('');
      }
    }
  };

  const setAreaPrefix = (_provinceCode) => {
    if (_provinceCode === '10') {
      if (language === 'th') {
        setSubDistrictPrefix('แขวง');
        setDistrictPrefix('เขต');
        setProvincePrefix('');
      } else {
        setSubDistrictPrefix('');
        setDistrictPrefix('');
        setProvincePrefix('');
      }
    } else {
      if (language === 'en') {
        setSubDistrictPrefix('');
        setDistrictPrefix('');
        setProvincePrefix('');
      } else {
        setSubDistrictPrefix('ต.');
        setDistrictPrefix('อ.');
        setProvincePrefix('จ.');
      }
    }
  };

  const updateCompany = (replace, value) => {
    console.log('userEffect updateCompany call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        //Check documents spending limit
        axios
          .patch(
            API_URL + '/etax/companies/' + company.id,
            {
              replace: replace,
              value: value,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            }
          )
          .then(
            (response) => {
              console.log('Company data: ', response);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  return (
    <form onSubmit={handleSubmit} onKeyDown={(e) => {
      if (e.key == "Enter") {
        e.preventDefault();
      }
    }}>
      <Stack>
        <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('invoiceTaxInvoice.header')}</h2>
        <div
          style={{
            width: '1000px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 30,
            background: '#ffffff',
            marginLeft: '0px',
            marginTop: '0px',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <Stack horizontal horizontalAlign="space-between" styles={{ root: { height: 110 } }}>
            {companyName ? (
              <div className="ms-Grid-col ms-lg7">
                <h4
                  style={{
                    marginBottom: 2,
                    marginTop: 5,
                    fontSize: '16px',
                  }}
                >
                  {companyName} ({companyBranchText})
                </h4>
                <span>{companyAllAddress}</span>
                <br />
                {t('invoiceTaxInvoice.companyTel')} {companyPhone} {t('invoiceTaxInvoice.companyEmail')} {companyEmail}
                <br />
                {t('invoiceTaxInvoice.companyTaxId')} {companyTaxId}
              </div>
            ) : (
              <div className="ms-Grid-col ms-lg7">
                <Shimmer height="1%" width="50%" />
                <br />
                <Shimmer width="65%" />
                <br />
                <Shimmer width="65%" />
              </div>
            )}

            <div className="ms-Grid-col ms-sm12 ms-lg5" style={{ width: 385 }}>
              <center>
                <h2
                  style={{
                    color: '#0C479D',
                    marginBottom: 5,
                    marginTop: 5,
                    fontSize: '25px',
                  }}
                >
                  {t('invoiceTaxInvoice.documentHeader')}
                </h2>
                <span
                  style={{
                    color: '#696969',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  {t('invoiceTaxInvoice.documentSubHeader')}
                </span>
                <h3>{t('invoiceTaxInvoice.original')}</h3>
              </center>
            </div>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <Stack vertical>
              <Stack horizontal tokens={{ childrenGap: 15 }}>
                <div>
                  <TextField
                    name="name"
                    label={t('invoiceTaxInvoice.customerName')}
                    componentRef={(input) => (refArray.current.customerName = input)}
                    autoComplete="true"
                    styles={{ root: { width: 310 } }}
                    maxLength={140}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (e.target.value) {
                        setShowSuggestions(true);
                      } else {
                        setShowSuggestions(false);
                      }
                    }}
                    value={name}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                    autoFocus
                    onBlur={() => {
                      setShowSuggestions(false);
                      setShowSuggestionsTaxId(false);
                    }}
                  />
                  {showSuggestions && suggestionList[0] !== undefined && (
                    <div style={contactListStyle}>
                      {suggestionList.map((suggestion, index) => {
                        return (
                          <div
                            key={index}
                            id={suggestion.name}
                            className={controlClass.hover}
                            style={contactOptionStyle}
                            onMouseDown={(e) => handleSelectSuggestions(e, suggestion)}
                          >
                            {suggestion.name} {suggestion.taxId}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <Dropdown
                  placeholder={t('invoiceTaxInvoice.pleaseSelect')}
                  label={t('invoiceTaxInvoice.country')}
                  options={countryList}
                  selectedKey={countryCode}
                  styles={{ root: { width: 190 } }}
                  disabled={structure}
                  onChange={(e, selectedOption) => {
                    setCountryCode(selectedOption.key);
                    setCountryName(selectedOption.text);
                  }}
                />
                <Toggle
                  label={t('invoiceTaxInvoice.addressStructure')}
                  onText={t('invoiceTaxInvoice.on')}
                  offText={t('invoiceTaxInvoice.off')}
                  checked={structure}
                  onChange={() => {
                    handleStructure(!structure);
                  }}
                />
              </Stack>

              {!structure && (
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                  <TextField
                    name="lineOne"
                    label={t('invoiceTaxInvoice.lineOne')}
                    componentRef={(input) => (refArray.current.lineOneRef = input)}
                    styles={{ root: { width: 615 } }}
                    maxLength={256}
                    onChange={(e) => {
                      setLineOne(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        setLineOne('');
                      } else {
                        setLineOne(e.target.value.trim());
                      }
                    }}
                    value={lineOne}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                </Stack>
              )}

              {structure && (
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                  <TextField
                    name="buildingNumber"
                    label={t('invoiceTaxInvoice.buildingNumber')}
                    componentRef={(input) => (refArray.current.buildingNumber = input)}
                    styles={{ root: { width: 100 } }}
                    maxLength={16}
                    onChange={(e) => {
                      setBuildingNumber(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        setBuildingNumber('');
                      } else {
                        setBuildingNumber(e.target.value.trim());
                      }
                    }}
                    value={buildingNumber}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <TextField
                    name="address"
                    label={t('invoiceTaxInvoice.address')}
                    styles={{ root: { width: 350 } }}
                    maxLength={256}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        setAddress('');
                      } else {
                        setAddress(e.target.value.trim());
                      }
                    }}
                    value={address}
                  />
                  <TextField
                    name="streetName"
                    label={t('invoiceTaxInvoice.streetName')}
                    styles={{ root: { width: 135 } }}
                    maxLength={70}
                    onChange={(e) => {
                      setStreetName(e.target.value);

                      setRoadPrefix(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        setStreetName('');

                        setRoadPrefix('');
                      } else {
                        setStreetName(e.target.value.trim());
                        setRoadPrefix(e.target.value.trim());
                      }
                    }}
                    value={streetName}
                  />
                </Stack>
              )}

              <Stack horizontal tokens={{ childrenGap: 15 }}>
                {structure ? (
                  <Stack horizontal tokens={{ childrenGap: 15 }}>
                    <Dropdown
                      placeholder={t('invoiceTaxInvoice.pleaseSelect')}
                      label={t('invoiceTaxInvoice.province')}
                      options={provinceList}
                      styles={dropdownStyles}
                      selectedKey={province.key}
                      onChange={(e, selectedOption) => {
                        setProvince(selectedOption);
                        setDistrict({
                          key: '',
                          text: '',
                        });
                        setSubDistrict({
                          key: '',
                          text: '',
                        });
                        setDistrictList(getDistrictList(selectedOption.key, language));
                        setSubDistrictList([]);

                        setAreaPrefix(selectedOption.key);

                        console.log('Province changed', districtList);

                        setProvinceErrMsg('');
                        setDistrictErrMsg('');
                        setSubDistrictErrMsg('');
                      }}
                      onBlur={(e) => {
                        console.log(province.key);
                        if (province.key === '') {
                          setProvinceErrMsg(DropdownErrMsg);
                        } else {
                          setProvinceErrMsg('');
                        }
                      }}
                      errorMessage={provinceErrMsg}
                      required
                    />
                    <Dropdown
                      placeholder={t('invoiceTaxInvoice.pleaseSelect')}
                      label={t('invoiceTaxInvoice.district')}
                      options={districtList}
                      selectedKey={district.key}
                      styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        setDistrict(selectedOption);
                        setSubDistrict({
                          key: '',
                          text: '',
                        });
                        setSubDistrictList(getSubDistrictList(selectedOption.key, language));
                        console.log('District changed', subDistrictList);

                        setDistrictErrMsg('');
                        setSubDistrictErrMsg('');
                      }}
                      onBlur={(e) => {
                        console.log(district.key);
                        if (province.key !== '' && district.key === '') {
                          setDistrictErrMsg(DropdownErrMsg);
                        } else {
                          setDistrictErrMsg('');
                        }
                      }}
                      errorMessage={districtErrMsg}
                      required
                    />
                    <Dropdown
                      placeholder={t('invoiceTaxInvoice.pleaseSelect')}
                      label={t('invoiceTaxInvoice.subDistrict')}
                      options={subDistrictList}
                      selectedKey={subDistrict.key}
                      styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        setSubDistrict(selectedOption);

                        setSubDistrictErrMsg('');
                      }}
                      onBlur={(e) => {
                        console.log(subDistrict.key);
                        if (district.key !== '' && subDistrict.key === '') {
                          setSubDistrictErrMsg(DropdownErrMsg);
                        } else {
                          setSubDistrictErrMsg('');
                        }
                      }}
                      errorMessage={subDistrictErrMsg}
                      required
                    />
                  </Stack>
                ) : (
                  <TextField
                    name="lineTwo"
                    label={t('invoiceTaxInvoice.lineTwo')}
                    styles={{ root: { width: 480 } }}
                    maxLength={256}
                    onChange={(e) => {
                      setLineTwo(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (!e.target.value.trim()) {
                        setLineTwo('');
                      } else {
                        setLineTwo(e.target.value.trim());
                      }
                    }}
                    value={lineTwo}
                  />
                )}
                <TextField
                  name="postcode"
                  label={t('invoiceTaxInvoice.postcode')}
                  componentRef={(input) => (postCodeRef = input)}
                  styles={{ root: { width: 120 } }}
                  maxLength={16}
                  onChange={(e) => {
                    setPostCode(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (!e.target.value.trim()) {
                      setPostCode('');
                    } else {
                      setPostCode(e.target.value.trim());
                    }
                  }}
                  value={postcode}
                  onGetErrorMessage={getPostCodeErrorMessage}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
                <Toggle
                  label={t('invoiceTaxInvoice.english')}
                  onText={t('invoiceTaxInvoice.on')}
                  offText={t('invoiceTaxInvoice.off')}
                  checked={language === 'en' ? true : false}
                  onChange={() => {
                    if (language === 'en') {
                      setLanguage('th');
                      sessionStorage.setItem('address-language', 'th');
                    } else {
                      setLanguage('en');
                      sessionStorage.setItem('address-language', 'en');
                    }
                  }}
                />
              </Stack>

              <Stack horizontal tokens={{ childrenGap: 15 }}>
                <Dropdown
                  placeholder={t('invoiceTaxInvoice.pleaseSelect')}
                  label={t('invoiceTaxInvoice.taxNumberType')}
                  options={[
                    {
                      key: 'TXID',
                      text: t('invoiceTaxInvoice.txid'),
                    },
                    {
                      key: 'NIDN',
                      text: t('invoiceTaxInvoice.nidn'),
                    },
                    {
                      key: 'CCPT',
                      text: t('invoiceTaxInvoice.ccpt'),
                    },
                    { key: 'OTHR', text: t('invoiceTaxInvoice.othr') },
                  ]}
                  styles={{ root: { width: 180 } }}
                  selectedKey={taxNumberType}
                  onChange={(e, selectedOption) => {
                    handleTaxType(selectedOption);

                    setTaxNumberTypeErrMsg('');
                  }}
                  onBlur={(e) => {
                    console.log(taxNumberType);
                    if (taxNumberType === '' || taxNumberType === undefined) {
                      setTaxNumberTypeErrMsg(DropdownErrMsg);
                    } else {
                      setTaxNumberTypeErrMsg('');
                    }
                  }}
                  errorMessage={taxNumberTypeErrMsg}
                  required
                />
                {showTaxId && (
                  <div>
                    <TextField
                      name="taxId"
                      label={t('invoiceTaxInvoice.number')}
                      componentRef={(input) => (refArray.current.taxIdRef = input)}
                      styles={{ root: { width: 120 } }}
                      autoComplete="true"
                      value={taxId}
                      maxLength={35}
                      onChange={(e) => {
                        setTaxId(e.target.value);
                        if (e.target.value) {
                          setShowSuggestionsTaxId(true);
                        } else {
                          setShowSuggestionsTaxId(false);
                        }
                      }}
                      onGetErrorMessage={getTaxIdErrorMessage}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                      onBlur={() => {
                        setShowSuggestions(false);
                        setShowSuggestionsTaxId(false);
                      }}
                    />
                    {showSuggestionsTaxId && suggestionList[0] !== undefined && (
                      <div style={contactListStyle}>
                        {suggestionList.map((suggestion, index) => {
                          return (
                            <div
                              key={index}
                              id={suggestion.name}
                              className={controlClass.hover}
                              style={contactOptionStyle}
                              onMouseDown={(e) => handleSelectSuggestions(e, suggestion)}
                            >
                              {suggestion.taxId} {suggestion.name}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}

                {showBranch && (
                  <Stack horizontal tokens={{ childrenGap: 15 }}>
                    <Dropdown
                      placeholder={t('invoiceTaxInvoice.pleaseSelect')}
                      label={t('invoiceTaxInvoice.branchDisplay')}
                      options={branchDisplay}
                      selectedKey={branch}
                      styles={{ root: { width: 160 } }}
                      onChange={(e, selectedOption) => handleBranch(selectedOption.key, language)}
                    />
                    {showBranchNumber && (
                      <TextField
                        name="branchNumber"
                        label={t('invoiceTaxInvoice.number')}
                        description={t('invoiceTaxInvoice.branchNumberDesc')}
                        componentRef={(input) => (refArray.current.branchNumberRef = input)}
                        styles={{
                          root: { width: 110 },
                        }}
                        value={branchNumber}
                        required={showBranchNumber}
                        maxLength={5}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            setBranchNumber(e.target.value);

                            if (language === 'en') {
                              setBranchText('Branch ' + e.target.value);
                            } else {
                              setBranchText('สาขา ' + e.target.value);
                            }
                          }
                        }}
                        onBlur={(e) => {
                          if (!e.target.value.trim()) {
                            setBranchNumber('');
                          } else {
                            setBranchNumber(e.target.value.trim());

                            if (language === 'en') {
                              setBranchText('Branch ' + e.target.value.trim());
                            } else {
                              setBranchText('สาขา ' + e.target.value.trim());
                            }
                          }
                        }}
                        onGetErrorMessage={getBranchNumberErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                      />
                    )}
                  </Stack>
                )}
              </Stack>

              <Stack horizontal tokens={{ childrenGap: 15 }}>
                <TextField
                  componentRef={(input) => (phoneRef = input)}
                  name="phone"
                  label={t('invoiceTaxInvoice.tel')}
                  styles={{ root: { width: 200 } }}
                  onGetErrorMessage={getPhoneNumberErrorMessage}
                  maxLength={35}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                />
                <TextField
                  name="email"
                  label={t('invoiceTaxInvoice.email')}
                  styles={{ root: { width: 300 } }}
                  maxLength={256}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                />

                <Toggle
                  label={t('invoiceTaxInvoice.priceIncludeVat')}
                  onText={t('invoiceTaxInvoice.on')}
                  offText={t('invoiceTaxInvoice.off')}
                  checked={includeVat}
                  onChange={() => {
                    setIncludeVat(!includeVat);

                    setCompany({
                      ...company,
                      includeVat: !includeVat,
                    });

                    updateCompany('includeVat', !includeVat);
                  }}
                  style={{ alignItems: 'right' }}
                />
              </Stack>
            </Stack>

            <Stack vertical></Stack>

            <Stack vertical>
              <Stack horizontal>
                <TextField
                  name="number"
                  label={t('invoiceTaxInvoice.number')}
                  componentRef={(input) => (refArray.current.numberRef = input)}
                  styles={{ root: { width: 150 } }}
                  maxLength={35}
                  onChange={(e) => {
                    setNumber(e.target.value);
                  }}
                  value={number}
                  onGetErrorMessage={getErrorMessage}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
              </Stack>
              <Stack horizontal>
                <DatePicker
                  name="date"
                  label={t('invoiceTaxInvoice.date')}
                  allowTextInput={false}
                  className={controlClass.control}
                  firstDayOfWeek={firstDayOfWeek}
                  strings={DayPickerStrings}
                  formatDate={onFormatDate}
                  placeholder={t('invoiceTaxInvoice.pleaseSelectDate')}
                  styles={{ root: { width: 150 } }}
                  onSelectDate={(date) => {
                    setDateBE(
                      `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543)
                    );
                    setDateCE(`${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear());

                    setDate(date.toISOString());
                  }}
                  value={new Date(date)}
                  isRequired={true}
                  componentRef={dateRef}
                />
              </Stack>
              <Stack horizontal style={{ marginTop: '30px' }}>
                <DatePicker
                  name="dueDate"
                  label={t('invoiceTaxInvoice.dueDate')}
                  allowTextInput={false}
                  className={controlClass.control}
                  firstDayOfWeek={firstDayOfWeek}
                  strings={DayPickerStrings}
                  formatDate={onFormatDate}
                  placeholder={t('invoiceTaxInvoice.pleaseSelectDate')}
                  styles={{ root: { width: 150 } }}
                  onSelectDate={(dueDate) => {
                    setDueDateBE(
                      `${dueDate.getDate()}`.padStart(2, '0') +
                        '/' +
                        `${dueDate.getMonth() + 1}`.padStart(2, '0') +
                        '/' +
                        (dueDate.getFullYear() + 543)
                    );
                    setDueDateCE(
                      `${dueDate.getDate()}`.padStart(2, '0') + '/' + `${dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + dueDate.getFullYear()
                    );

                    setDueDate(dueDate.toISOString());
                  }}
                  value={dueDate ? new Date(dueDate) : ''}
                  componentRef={dueDateRef}
                />
              </Stack>
              <Stack horizontal>
                <TextField
                  name="reference"
                  label={t('invoiceTaxInvoice.reference')}
                  styles={{ root: { width: 150 } }}
                  maxLength={35}
                  onChange={(e) => {
                    setReference(e.target.value);
                  }}
                  value={reference}
                />
              </Stack>
            </Stack>
          </Stack>

          <div>
            <br />
            <Separator />
            <br />
          </div>
          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <span align="center" style={{ width: '50px', fontSize: '16px' }}>
              {t('invoiceTaxInvoice.itemNumber')}
            </span>
            <span align="center" style={{ width: '325px', fontSize: '16px' }}>
              {t('invoiceTaxInvoice.item')}
            </span>
            <span align="center" style={{ width: '70px', fontSize: '16px' }}>
              {t('invoiceTaxInvoice.quantity')}
            </span>
            <span align="center" style={{ width: '80px', fontSize: '16px' }}>
              {t('invoiceTaxInvoice.unit')}
            </span>
            <span align="center" style={{ width: '100px', fontSize: '16px' }}>
              {t('invoiceTaxInvoice.unitPrice')}
            </span>
            <span align="center" style={{ width: '80px', fontSize: '16px' }}>
              {t('invoiceTaxInvoice.discount')}
            </span>
            <span align="center" style={{ width: '65px', fontSize: '16px' }}>
              {t('invoiceTaxInvoice.itemVat')}
            </span>
            <span align="center" style={{ width: '140px', fontSize: '16px' }}>
              {includeVat ? <span>{t('invoiceTaxInvoice.totalIncludeVat')}</span> : <span>{t('invoiceTaxInvoice.totalBeforeVat')}</span>}
            </span>
          </Stack>
          <br />

          {items.map((item, index) => {
            return (
              <div key={index}>
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                  <TextField
                    name="number"
                    styles={{ root: { width: 50 } }}
                    componentRef={(input) => (itemsNumberRef.current[index] = input)}
                    value={items[index]['number']}
                    maxLength={35}
                    onChange={(event) => handleChangeItem(index, event)}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <TextFieldWithDropDown 
                    name="description" 
                    styles={{
                      root: {
                        width: 325,
                      },
                    }} 
                    index={index} 
                    onChangeFunction={handleChangeItem} 
                    value={items[index]['description']}
                    description={item.sku ? item.sku : ''}
                    items={items}
                    setState={setItems} 
                  />
                  {/* <TextField
                    name="description"
                    styles={{ root: { width: 325 } }}
                    componentRef={(input) => (itemsDescRef.current[index] = input)}
                    value={items[index]['description']}
                    maxLength={256}
                    onChange={(event) => handleChangeItem(index, event)}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  /> */}
                  <TextField
                    name="quantity"
                    styles={{ root: { width: 70 } }}
                    componentRef={(input) => (itemsQtyRef.current[index] = input)}
                    type="number"
                    min="0"
                    step="1"
                    max={9999999999.99}
                    value={items[index]['quantity']}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        handleChangeItem(index, event);
                      }
                    }}
                    onBlur={(event) => handleBlurItem(index, event)}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <Dropdown
                    name="unitCode"
                    options={unitList}
                    selectedKey={items[index]['unitCode']}
                    styles={{ root: { width: 80 } }}
                    onChange={(event, selectedOption) => {
                      console.log(selectedOption);
                      handleChangeItem(index, event, selectedOption);
                    }}
                  />
                  <TextField
                    name="price"
                    styles={{
                      root: { width: 100 },
                    }}
                    componentRef={(input) => (itemsPriceRef.current[index] = input)}
                    type="number"
                    min="0"
                    step=".01"
                    max={9999999999.99}
                    value={items[index]['price']}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        handleChangeItem(index, event);
                      }
                    }}
                    onBlur={(event) => handleBlurItem(index, event)}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <TextField
                    name="discount"
                    styles={{
                      root: { width: 80 },
                    }}
                    type="number"
                    min="0"
                    step=".01"
                    max={9999999999.99}
                    value={items[index]['discount']}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        handleChangeItem(index, event);
                      }
                    }}
                    onBlur={(event) => handleBlurItem(index, event)}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <Dropdown
                    name="percentVat"
                    options={[
                      {
                        key: nonVat,
                        text: nonVatText,
                        id: 'percentVat',
                      },
                      {
                        key: zeroVat,
                        text: zeroVatText,
                        id: 'percentVat',
                      },
                      {
                        key: percentVat,
                        text: percentVatText,
                        id: 'percentVat',
                      },
                    ]}
                    selectedKey={items[index]['percentVat']}
                    styles={{ root: { width: 65 } }}
                    onChange={(event, selectedOption) => {
                      console.log(selectedOption);
                      handleChangeItem(index, event, selectedOption);
                    }}
                  />
                  <TextField
                    name="total"
                    styles={{ root: { width: 110 } }}
                    disabled
                    readOnly
                    value={items[index]['total'].toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                    onGetErrorMessage={() => {
                      if (items[index]['total'] < 0) {
                        return t('invoiceTaxInvoice.totalErrorMessage1');
                      }
                    }}
                  />

                  <IconButton iconProps={{ iconName: 'Delete' }} title={t('invoiceTaxInvoice.delete')} onClick={() => handleRemoveItem(index)} />
                </Stack>

                <br />
              </div>
            );
          })}

          <Stack horizontal style={centerItem}>
            <PrimaryButton
              iconProps={{ iconName: 'Add' }}
              style={{ letterSpacing: '1px' }}
              name="addItem"
              text={t('invoiceTaxInvoice.addItem')}
              onClick={() => handleAddItem()}
            />
          </Stack>
          <br />
          <Stack horizontal horizontalAlign="space-between">
            <Stack vertical>
              <Stack horizontal>
                <TextField
                  name="note"
                  label={t('invoiceTaxInvoice.note')}
                  styles={{ root: { width: 450 } }}
                  maxLength={256}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                  value={note}
                />
              </Stack>
              <Stack horizontal>
                <br />
              </Stack>
              <Stack horizontal>
                <br />
                <TextField
                  name="createdBy"
                  label={t('invoiceTaxInvoice.createdBy')}
                  styles={{ root: { width: 450 } }}
                  maxLength={140}
                  onChange={(e) => {
                    setCreatedBy(e.target.value);
                  }}
                  value={createdBy}
                />
              </Stack>
              <br />
              <br />
              <Stack horizontal>
                <br />
                <Checkbox
                  label={t('invoiceTaxInvoice.reIssueMessage1')}
                  checked={reIssue}
                  onChange={(e) => {
                    handleReIssue();
                  }}
                />
              </Stack>
            </Stack>
            <Stack horizontalAlign="end">
              <br />
              <Stack horizontal>
                <span style={{ width: 178, fontSize: '18px' }}>{t('invoiceTaxInvoice.discount')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>{' '}
                {
                  <TextField
                    name="specialDiscount"
                    styles={{
                      root: { width: 150 },
                    }}
                    type="number"
                    min="0"
                    step=".01"
                    max={9999999999.99}
                    value={discount}
                    onChange={(event) => {
                      if (Number(event.target.value) >= 9999999999.99) {
                        setDiscount(9999999999.99);
                      } else {
                        if (Number(event.target.value) <= 0) {
                          setDiscount(0);
                        } else {
                          setDiscount(event.target.value);
                        }
                      }
                    }}
                    onBlur={(event) => setDiscount(Math.round(event.target.value * 100) / 100)}
                    disabled={discountDisable}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                }
                <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('invoiceTaxInvoice.thb')}</span>
              </Stack>
              <br />
              {includeVat ? (
                <Stack horizontal>
                  <span style={{ width: 178, fontSize: '18px' }}>{t('invoiceTaxInvoice.amountTotalBeforeVat')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={
                        amountTotal >= 0
                          ? amountTotal.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })
                          : '0.00'
                      }
                    />
                  }
                  <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('invoiceTaxInvoice.thb')}</span>
                </Stack>
              ) : (
                <Stack horizontal>
                  <span style={{ width: 178, fontSize: '18px' }}>{t('invoiceTaxInvoice.amountTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={
                        amountTotal >= 0
                          ? amountTotal.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })
                          : '0.00'
                      }
                    />
                  }
                  <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('invoiceTaxInvoice.thb')}</span>
                </Stack>
              )}

              <br />
              <Stack horizontal>
                <span style={{ width: 178, fontSize: '18px' }}>{t('invoiceTaxInvoice.vat')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {
                  <TextField
                    styles={{ root: { width: 150 } }}
                    disabled
                    readOnly
                    value={
                      vatTotal >= 0
                        ? vatTotal.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                        : '0.00'
                    }
                  />
                }
                <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('invoiceTaxInvoice.thb')}</span>
              </Stack>
              <br />
              <Stack horizontal>
                <span style={{ width: 178, fontSize: '18px' }}>{t('invoiceTaxInvoice.grandTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {
                  <TextField
                    styles={{ root: { width: 150 } }}
                    disabled
                    readOnly
                    value={
                      grandTotal >= 0
                        ? grandTotal.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                        : '0.00'
                    }
                  />
                }
                <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('invoiceTaxInvoice.thb')}</span>
              </Stack>
            </Stack>
          </Stack>
          <br />
          <Stack horizontal horizontalAlign="space-between">
            <Stack vertical>
              {reIssue && (
                <div>
                  <TextField
                    name="referNumber"
                    label={t('invoiceTaxInvoice.referNumber')}
                    componentRef={(input) => (refArray.current.referNumber = input)}
                    styles={{ root: { width: 220 } }}
                    maxLength={35}
                    onChange={(e) => {
                      setReferNumber(e.target.value);
                    }}
                    value={referNumber}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <DatePicker
                    name="referDate"
                    label={t('invoiceTaxInvoice.referDate')}
                    allowTextInput={false}
                    className={controlClass.control}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    formatDate={onFormatDate}
                    placeholder={t('invoiceTaxInvoice.pleaseSelect')}
                    styles={{ root: { width: 220 } }}
                    onSelectDate={(date) => {
                      setReferDateBE(
                        `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543)
                      );
                      setReferDateCE(
                        `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                      );

                      setReferDate(date.toISOString());
                    }}
                    value={referDate && new Date(referDate)}
                    isRequired={true}
                    componentRef={referDateRef}
                  />
                  <Dropdown
                    name="reason"
                    label={t('invoiceTaxInvoice.reason')}
                    placeholder={t('invoiceTaxInvoice.pleaseSelect')}
                    componentRef={referReasonRef}
                    options={reasonList}
                    selectedKey={reason.key}
                    styles={{ root: { width: 250 } }}
                    onChange={(event, selectedOption) => {
                      handleReason(selectedOption);

                      setReasonErrMsg('');
                    }}
                    onBlur={(e) => {
                      console.log(reason.key);
                      if (reason.key === '') {
                        setReasonErrMsg(DropdownErrMsg);
                      } else {
                        setReasonErrMsg('');
                      }
                    }}
                    errorMessage={reasonErrMsg}
                    required
                  />
                  {reason.key === 'TIVC99' && (
                    <TextField
                      name="specificReason"
                      label={t('invoiceTaxInvoice.specificReason')}
                      componentRef={(input) => (refArray.current.referSpecificReason = input)}
                      styles={{ root: { width: 300 } }}
                      maxLength={256}
                      onChange={(e) => {
                        setSpecificReason(e.target.value);
                      }}
                      value={specificReason}
                      onGetErrorMessage={getErrorMessage}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                    />
                  )}
                </div>
              )}
            </Stack>

            <Stack horizontalAlign="end">
              <br />
              <br />
              <Stack horizontal>
                <span style={{ width: 178 }}>{t('invoiceTaxInvoice.nonVatPriceTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {
                  <TextField
                    styles={{ root: { width: 150 } }}
                    disabled
                    readOnly
                    value={
                      nonVatPriceTotal >= 0
                        ? nonVatPriceTotal.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                        : '0.00'
                    }
                  />
                }
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{t('invoiceTaxInvoice.thb')}</span>
              </Stack>
              <br />
              <Stack horizontal>
                <span style={{ width: 178 }}>{t('invoiceTaxInvoice.zeroVatPriceTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {
                  <TextField
                    styles={{ root: { width: 150 } }}
                    disabled
                    readOnly
                    value={
                      zeroVatPriceTotal >= 0
                        ? zeroVatPriceTotal.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                        : '0.00'
                    }
                  />
                }
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{t('invoiceTaxInvoice.thb')}</span>
              </Stack>
              <br />
              <Stack horizontal>
                <span style={{ width: 178 }}>{t('invoiceTaxInvoice.vatPriceTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {
                  <TextField
                    styles={{ root: { width: 150 } }}
                    disabled
                    readOnly
                    value={
                      vatPriceTotal >= 0
                        ? vatPriceTotal.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                        : '0.00'
                    }
                  />
                }
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{t('invoiceTaxInvoice.thb')}</span>
              </Stack>
            </Stack>
          </Stack>
          <br />
          <br />
          {companyName && (
            <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
              <PrimaryButton style={{ width: '125px', letterSpacing: '1px' }} text={t('invoiceTaxInvoice.next')} type="submit" value="next" />
              <DefaultButton text={t('invoiceTaxInvoice.cancel')} onClick={handleCancel} />
            </Stack>
          )}
          {!companyName && <Spinner label={t('invoiceTaxInvoice.loading')} labelPosition="right" />}

          <br />
          <br />
        </div>
        <br />
        <br />
      </Stack>
    </form>
  );
};

export default InvoiceTaxInvoice;
