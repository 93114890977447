import { useLocation, useHistory } from 'react-router-dom';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import {
  validateDataPND2,
  validateDataPND2EN,
  validateDataPND3,
  validateDataPND3EN,
  validateDataPND53,
  validateDataPND53EN,
} from './WithholdingTaxDropzone';

var moment = require('moment'); // require
moment().format();

export default function WithholdingTaxResult(props) {
  const history = useHistory();
  const location = useLocation();

  const { t, i18n } = useTranslation();

  const [items, setItems] = useState(location.state.items);
  const mergeArray = location.state.mergeArray;
  const pnd = location.state.pnd;

  const isCompactMode = false;

  const columns = [
    {
      key: 'column1',
      name: t('whtDropzoneResult.number'),
      fieldName: 'number',
      minWidth: 130,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'column2',
      name: t('whtDropzoneResult.taxId'),
      fieldName: 'taxId',
      minWidth: 70,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'column3',
      name: t('whtDropzoneResult.dateBE'),
      fieldName: 'dateBE',
      minWidth: 70,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'column4',
      name: t('whtDropzoneResult.error'),
      fieldName: 'error',
      minWidth: 300,
      isResizable: true,
      data: 'string',
      isMultiline: true,
      onRender: (value) => {
        return (
          <div className={mergeStyles({ color: 'red' })}>
            {value.error.map((text, index) => {
              return <div key={`err-${index}`}>{text}</div>;
            })}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    let failData = [];

    if (pnd === 'PND53') {
      mergeArray.forEach((row) => {
        if (validateDataPND53(row).length > 0)
          failData.push({
            number: row['เลขที่เอกสาร'],
            taxId: row['เลขประจำตัวผู้เสียภาษีอากร'],
            dateBE: row['วันเดือนปีที่จ่าย'],
            error: i18n.language === 'th' ? validateDataPND53(row) : validateDataPND53EN(row),
          });
      });
    } else if (pnd === 'PND3') {
      mergeArray.forEach((row) => {
        if (validateDataPND3(row).length > 0) {
          failData.push({
            number: row['เลขที่เอกสาร'],
            taxId: row['เลขประจำตัวผู้เสียภาษีอากร'],
            dateBE: row['วันเดือนปีที่จ่าย'],
            error: i18n.language === 'th' ? validateDataPND3(row) : validateDataPND3EN(row),
          });
        }
      });
    } else if (pnd === 'PND2') {
      mergeArray.forEach((row) => {
        if (validateDataPND2(row).length > 0) {
          failData.push({
            number: row['เลขที่เอกสาร'],
            taxId: row['เลขประจำตัวผู้เสียภาษีอากร'],
            dateBE: row['วันเดือนปีที่จ่าย'],
            error: i18n.language === 'th' ? validateDataPND2(row) : validateDataPND2EN(row),
          });
        }
      });
    }

    setItems(failData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div>
      <Stack
        horizontal
        horizontalAlign="center"
        tokens={{ childrenGap: 25 }}
        style={{
          paddingTop: '10px',
          paddingBottom: '10px',
          background: '#4D4D4D',
          width: 'calc(100vw - 303px)',
          minWidth: '1113px',
        }}
      >
        <button style={{ width: '90px', height: '30px' }} onClick={() => history.push({ pathname: '/withholding-taxs/excel/upload', state: {} })}>
          {t('whtDropzoneResult.back')}
        </button>
      </Stack>
      <Stack horizontal horizontalAlign="center">
        <h1>{t('whtDropzoneResult.header')}</h1>
      </Stack>

      <Stack
        vertical
        style={{
          width: '98%',
          minWidth: '1000px',
          marginLeft: '10px',
          maxWidth: '1350px',
        }}
      >
        <DetailsList
          items={items}
          compact={isCompactMode}
          columns={columns}
          selectionMode={SelectionMode.none}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
        />
      </Stack>
    </div>
  );
}

<WithholdingTaxResult />;
