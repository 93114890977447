import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../authConfig';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  SelectionMode,
  Stack,
  PrimaryButton,
  DefaultButton,
  MessageBar,
  MessageBarType,
  ContextualMenu,
  SearchBox,
  Text,
  Dialog,
  DialogFooter,
  DialogType,
  DetailsRow,
  TextField,
  Icon,
  Pivot,
  PivotItem,
  Image,
  CheckboxVisibility,
  Dropdown,
  TeachingBubble,
  DirectionalHint,
  mergeStyleSets,
  getTheme,
  Toggle,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import { useDidMountEffect } from '../../helpers/customHooks';
import { useId } from '@fluentui/react-hooks';
import { categoryList } from '../../providers/CategoryData';

const API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const theme = getTheme();

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '300px',
  },
  hover: {
    selectors: {
      '&:hover': { background: theme.palette.neutralLight },
    },
  },
});

const toThaiDateString = (isoDateTime) => {
  let date = '';
  date = new Date(isoDateTime);
  let year = date.getFullYear() + 543;
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let numOfDay = date.getDate().toString().padStart(2, '0');

  let hour = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');

  return `${numOfDay}/${month}/${year} ${hour}:${minutes} น.`;
};

const _categoryList = categoryList

const Products = () => {
  console.log('Home render!!!');

  const { t, i18n } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const data = location.state;

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const [items, setItems] = useState([]);
  const [expandableList, setExpandableList] = useState([])
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const [pageData, setPageData] = useState({});

  const [currentPage, setCurrentPage] = useState(1);

  const [productStatus, setProductStatus] = useState('All')

  const [selectedCategory, setSelectedCategory] = useState({
    categoryCode: "",
    categoryName: "",
    cateRdCode: ""
  },)

  const percentVat = 7;

  const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);

  const [search, setSearch] = useState('');
  const [priceFrom, setPriceFrom] = useState("")
  const [priceTo, setPriceTo] = useState("")

  const sortDirection = 'DESC'
  const sortBy = 'createdTime'

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
  });

  const pageSize = 10
  const companyId = sessionStorage.getItem('companyId') || '';

  useEffect(() => {
    if (data != null && data.addComplete) {
      setShowMessageBarSuccess(true);
    }
  }, [data]);

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');

    if (getNewDocuments) {
      instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        let _status = "all"

        if (productStatus === 'Active') {
          _status = "active"
        } else if (productStatus === 'Inactive') {
          _status = "inactive"
        } else if (productStatus === 'Suspended') {
          _status = "suspended"
        } else if (productStatus === 'Draft') {
          _status = "draft"
        } else if (productStatus === 'Deleted') {
          _status = "deleted"
        }
        axios
          .get(
            // API_URL + '/products?' +
            // // "sort=" + sort +
            // (search ? ("&search=" + search) : "") +
            // (priceFrom ? ("priceFrom=" + priceFrom) : "") +
            // (priceTo ? ("&priceTo=" + priceTo) : "") +
            // (selectedCategory.categoryCode ? "&category=" + selectedCategory.categoryCode : ""),
            // (productStatus ? "&status=" + "active" : ""),
            search ? `${API_URL}/products?offset=${(currentPage - 1) * pageSize}&limit=${pageSize}&sortDirection=${sortDirection}&sortBy=${sortBy}&status=${_status}&priceFrom=${priceFrom}&priceTo=${priceTo}&category=${selectedCategory.categoryCode}&search=${search}` :
              `${API_URL}/products?offset=${(currentPage - 1) * pageSize}&limit=${pageSize}&sortDirection=${sortDirection}&sortBy=${sortBy}&status=${_status}&priceFrom=${priceFrom}&priceTo=${priceTo}&category=${selectedCategory.categoryCode}`, 
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
                'cid': companyId
              },
            }
          ).then(response => {
            console.log(response)

            const { products } = response.data.data

            const _filterProduct = products.filter(_product => 
              productStatus === 'All' ? _product :
              productStatus === 'Active' ? _product.data.status === "active" :
              productStatus === 'Inactive' ? _product.data.status === "inactive" :
              productStatus === 'Suspended' ? _product.data.status === "suspended" :
              productStatus === 'Draft' ? _product.data.status === "draft" :
              productStatus === 'Deleted' ? _product.data.status === "deleted" : _product
            )

            // const _filterProduct = products.filter(_product => {
            //   if (productStatus === 'All') { 
            //     return _product
            //   } else {
            //     if (productStatus === 'Active') {
            //       return _product.data.status === "active"
            //     } else if (productStatus === 'Inactive') {
            //       return _product.data.status === "inactive"
            //     } else if (productStatus === 'Suspended') {
            //       return _product.data.status === "suspended"
            //     } else if (productStatus === 'Draft') {
            //       return _product.data.status === "draft"
            //     } else if (productStatus === 'Deleted') {
            //       return _product.data.status === "deleted"
            //     }
            //   }
            // })

            if (currentPage > Math.ceil(_filterProduct.length / pageSize)) {
              setCurrentPage(1);
            }

            setPageData(paginate(_filterProduct.length, currentPage, pageSize, 10));

            const _docs = [];
            const _expandableList = []

            _filterProduct.forEach((_product, index) => { 
              _docs.push({
                ..._product.data,
                id: _product.id,
                totalQty: _product.data.skus.reduce((accumulator, _variant) => accumulator + Number(_variant.available), 0),   
                lowestPrice: _product.data.skus.reduce(
                  (acc, loc) =>
                  Number(acc.price) < Number(loc.price)
                      ? acc
                      : loc
                ).price,
                highestPrice: _product.data.skus.reduce(
                  (acc, loc) =>
                  Number(acc.price) > Number(loc.price)
                      ? acc
                      : loc
                ).price,
                data: _product.data,
                updatedTime: _product.updatedTime,
                image: _product.data.images?.length ? _product.data.images[0] : ''
              })

              _expandableList.push({
                id: _product.id,
                open: false,
              })
            })

            console.log(_docs)
            
            setItems(_docs)
            setExpandableList(_expandableList)

            setLoadDataComplete(true);
            setGetNewDocuments(false);

          })
      }).catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  
  useDidMountEffect(() => {
    console.log('useDidmountEffect')
    setGetNewDocuments(true)
    setLoadDataComplete(false)
  }, [productStatus])

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  function changeProductStatus(productId, status) {
    instance
    .acquireTokenSilent({ ...silentRequest, account: account })
    .then((tokenResponse) => {
      axios
        .patch(
          API_URL + '/products/' + productId,
          {
            replace: '/data/status',
            value: status,
          },
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'application/json',
              'cid': companyId
            },
          }
        ).then(response => {
          console.log(response)

          const updateItemIndex = items.findIndex((item) => item.id === productId);

          if (updateItemIndex > -1) {
            const _updateItems = [...items];

            _updateItems[updateItemIndex].status = status

            setItems(_updateItems);
          }

        })
    }).catch((error) => {
      //Acquire token silent failure, and send an interactive request
      console.log(error);
      instance.acquireTokenRedirect(silentRequest);
    });
  }

  function EditableColumns(props) {
    const { productId, variant, data, column, text } = props

    const buttonId = useId('targetButton');
    const [teachingBubbleVisible, setTeachingBubbleVisible] = useState(false);
    
    const [value, setValue] = useState(text)

    const [isChange, setIsChange] = useState(false)

    function getTextfieldLabel(columnName) {
      let result = ""
      if (columnName === "price") {
        result = t('product.price') + " " + t('product.thb')
      }

      if (columnName === "available") {
        result = t('product.quantity')
      }

      return result
    }

    function handleSave() {
      if (isChange) {
        const newSkus = [...data.skus]
  
        newSkus[0][column] = Number(value)
  
        handleUpdateVariant(productId, data, newSkus)

        setIsChange(false)
      }
      // setOpenExpand(false)
    }

    return <>
      <Stack horizontal horizontalAlign='space-between'>
      <Stack id={buttonId}>
        {text}
      </Stack>
      <Stack>
        <Icon iconName='Edit' styles={{ root: { color: "#0078d4", cursor: "pointer"}}} onClick={() => {
          if (variant) {
            const newExpandableList = [...expandableList]
            const _index = newExpandableList.findIndex(_item => _item.id === productId)
            newExpandableList[_index].open = !newExpandableList[_index].open

            setExpandableList(newExpandableList)

          } else {
            setTeachingBubbleVisible(prev => !prev)
          }
          }}/>
        {/* <IconButton iconProps={{ iconName: "Edit"}} onClick={() => {
          if (variant) {
            const newExpandableList = [...expandableList]
            const _index = newExpandableList.findIndex(_item => _item.id === productId)
            newExpandableList[_index].open = !newExpandableList[_index].open

            setExpandableList(newExpandableList)

          } else {
            setTeachingBubbleVisible(prev => !prev)
          }
          }} /> */}
      </Stack>
    </Stack>
    {teachingBubbleVisible && (
        <TeachingBubble
          styles={{ bodyContent: { backgroundColor: "white"}}}
          calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
          target={`#${buttonId}`}
          // primaryButtonProps={examplePrimaryButtonProps}
          // secondaryButtonProps={exampleSecondaryButtonProps}
          onDismiss={() => setTeachingBubbleVisible(prev => !prev)}
          // headline="Discover what’s trending around you"
          primaryButtonProps={{ children: 'Cancel', onClick: () => setTeachingBubbleVisible(false) }}
          secondaryButtonProps={{ children: 'Save', onClick: () => handleSave() }}
        >
          <TextField label={getTextfieldLabel(column)} value={value} onChange={(e, newValue )=> { 
            setIsChange(true)
            setValue(newValue)}} />
        </TeachingBubble>
      )}
    </>
  }

  const columns = [
    {
      key: 'column1',
      name: t('product.productName'),
      fieldName: 'name',
      minWidth: 150,
      maxWidth: 410,
      isResizable: true,
      isSorted: true,
      isSortedDescending: true,
      onColumnClick: '',
      data: 'number',
      onRender: (item) => {
        return <Stack horizontal tokens={{ childrenGap: 10}}>
          <Image src={item.image ? REACT_APP_IMAGE_URL + '/products/' + item.image : ''} height={42} width={42} />
          <Stack>
            {item.name}

          </Stack>
          </Stack>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('product.price') + " " + t('product.thb'),
      fieldName: 'price',
      minWidth: 80,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      isMultiline: true,
      data: 'string',
      onRender: (item) => {
        return <EditableColumns 
                  productId={item.id} 
                  variant={item.variations.length ? true : false} 
                  data={item.data} 
                  column="price" 
                  text={item.highestPrice !== item.lowestPrice ? 
                    item.lowestPrice.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }) + 
                    " - " + 
                    item.highestPrice.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }) : 
                    item.highestPrice.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })} 
                />;
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('product.quantity'),
      fieldName: 'available',
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      onColumnClick: '',
      data: 'number',
      onRender: (item) => {
        return <EditableColumns 
                  productId={item.id} 
                  variant={item.variations.length ? true : false} 
                  data={item.data} 
                  column="available" 
                  text={item.totalQty.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })} 
                />;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('product.updateTime'),
      fieldName: 'updateTime',
      minWidth: 80,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      isMultiline: true,
      onRender: (item) => {
        return <span>{toThaiDateString(item.updatedTime)}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('product.status'),
      fieldName: 'status',
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        let _styleProp = {};
        let _status = ""
        if (item.status === "active") {
          _styleProp = { backgroundColor: "#EDFFE0", color: "#52C41A" };
          _status = "Active"
        } else if (item.status === "inactive") {
          _styleProp = { backgroundColor: "#FFF1F0", color: "#F52222" };
          _status = "Inactive"
        } else if (item.status === "suspended") {
          _styleProp = { backgroundColor: "#FFF1F0", color: "#F52222" };
          _status = "Suspended"
        } else if (item.status === "draft") {
          _styleProp = { backgroundColor: "#F6F6FB", color: "#69718C" };
          _status = "Draft"
        } else if (item.status === "deleted") {
          _styleProp = { backgroundColor: "#F6F6FB", color: "#69718C" };
          _status = "Deleted"
        }

        return <span style={{ padding: 4, borderRadius: 4, ..._styleProp }}>{_status}</span>;
      },
      isPadded: true,
    },
    {
      key: "column6",
      name: "",
      fieldName: "command",
      minWidth: 110,
      maxWidth: 120,
      isResizable: true,
      data: "string",
      onColumnClick: "",
      onRender: (item) => {
        if (item.status === "deleted") {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('product.select')}
                // iconProps={{ iconName: "Add", style: { color: "#106ebe" } }}
                menuProps={{
                  items: [
                    {
                      key: "recover",
                      text: t('product.recover'),
                      iconProps: { iconName: 'Refresh' },
                      onClick: () => {
                        changeProductStatus(item.id, 'inactive')
                        // reCreateWithholdingTax(item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        } else if (item.status === "inactive") {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('product.select')}
                // iconProps={{ iconName: "Add", style: { color: "#106ebe" } }}
                menuProps={{
                  items: [
                    {
                      key: "activate",
                      text: t('product.activate'),
                      iconProps: { iconName: 'ActivateOrders' },
                      onClick: () => {
                        changeProductStatus(item.id, 'active')
                        // reCreateWithholdingTax(item);
                      },
                    },
                    {
                      key: "edit",
                      text: t('product.edit'),
                      iconProps: { iconName: 'Edit' },
                      onClick: () => {
                        history.push({
                          pathname: '/products/add',
                          state: { productId: item.id, command: 'Edit' },
                        });
                      },
                    },
                    {
                      key: "copy",
                      text: t('product.copy'),
                      iconProps: { iconName: 'Copy' },
                      onClick: () => {
                        history.push({
                          pathname: '/products/add',
                          state: { productId: item.id, command: 'Copy' },
                        });
                      },
                    },
                    {
                      key: "delete",
                      text: t('product.delete'),
                      iconProps: { iconName: 'Delete' },
                      onClick: () => {
                        changeProductStatus(item.id, 'deleted')
                        // reCreateWithholdingTax(item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('product.select')}
                // iconProps={{ iconName: "Add", style: { color: "#106ebe" } }}
                menuProps={{
                  items: [
                    {
                      key: "edit",
                      text: t('product.edit'),
                      iconProps: { iconName: 'Edit' },
                      onClick: () => {
                        history.push({
                          pathname: '/products/add',
                          state: { productId: item.id, command: 'Edit' },
                        });
                      },
                    },
                    {
                      key: "deactivate",
                      text: t('product.deactivate'),
                      iconProps: { iconName: 'DeactivateOrders' },
                      onClick: () => {
                        changeProductStatus(item.id, 'inactive')
                        // reCreateWithholdingTax(item);
                      },
                    },
                    {
                      key: "copy",
                      text: t('product.copy'),
                      iconProps: { iconName: 'Copy' },
                      onClick: () => {
                        history.push({
                          pathname: '/products/add',
                          state: { productId: item.id, command: 'Copy' },
                        });
                      },
                    },
                    {
                      key: "delete",
                      text: t('product.delete'),
                      iconProps: { iconName: 'Delete' },
                      onClick: () => {
                        changeProductStatus(item.id, 'deleted')
                        // reCreateWithholdingTax(item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );

        }
        
      },
    },
  ];

  const isCompactMode = false;

  function handleUpdateVariant(productId, productData, skus) {
    const _updateTime = new Date().toISOString()

        const body = {
          id: productId,
          name: productData.name,
          status: productData.status,
          description: productData.description,
          brand: productData.brand,
          category: productData.category,
          images: [],
          variations: productData.variations,
          skus: skus,
          percentVat: percentVat,
        }

        console.log('body', body)

        instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .patch(
              API_URL + '/products/' + productId,
              {
                replace: '/data',
                value: body,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'application/json',
                  'cid': companyId
                },
              }
            ).then(response => {
              console.log(response)

              const updateItemIndex = items.findIndex((item) => item.id === productId);

              if (updateItemIndex > -1) {
                const _updateItems = [...items];

                _updateItems[updateItemIndex].totalQty = skus.reduce((accumulator, _variant) => accumulator + Number(_variant.available), 0);
                _updateItems[updateItemIndex].lowestPrice = skus.reduce(
                  (acc, loc) =>
                    Number(acc.price) < Number(loc.price)
                      ? acc
                      : loc
                ).price;
                _updateItems[updateItemIndex].highestPrice = skus.reduce(
                  (acc, loc) =>
                  Number(acc.price) > Number(loc.price)
                      ? acc
                      : loc
                ).price;
                _updateItems[updateItemIndex].updateTime = _updateTime

                setItems(_updateItems);
              }

            })
        }).catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
  }

  function ExpandDetailRow(prop) {
    const { props } = prop
    
    const [isChange, setIsChange] = useState(false)

    const { skus, id: productId, data } = props.item
    const [newVariant, setNewVariant] = useState(skus)

    function handleEdit(e, index, checked) {
      const { value, id } = e.target
      setIsChange(true)

      const _newVariants = [...newVariant]

      if (id === "status") {
        if (checked) {
          _newVariants[index][id] = "active"
        } else {
          _newVariants[index][id] = "inactive"
        }
      } else {
        _newVariants[index][id] = Number(value)
      }

      setNewVariant(_newVariants)
    } 

    function handleCancel() {
      setNewVariant(skus)
      // setOpenExpand(false)
      setIsChange(false)
    }

    function handleSave() {
      handleUpdateVariant(productId, data, newVariant)
      // setOpenExpand(false)
      setIsChange(false)
    }
    
    return <Stack>
    <DetailsRow {...props}  />
    {expandableList.some(_item => _item.id === productId && _item.open) ? (
      <Stack styles={{ root: { backgroundColor: '#FFFFFF', borderBottom: '1px solid rgb(237, 235, 233)' }}}>
        {isChange ? (
          <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 8}} styles={{ root: { marginBottom: 5, paddingLeft: 15, paddingRight: 15}}} >
            {/* <h4 style={{ border: '1px solid black', padding: 3, borderRadius: 5}} onClick={() => handleCancel()}>Cancel</h4> */}
            {/* <h4 style={{ border: '1px solid black', padding: 3, borderRadius: 5}} onClick={() => handleSave()}>Save</h4> */}
            <DefaultButton text={t("product.cancel")} styles={{ root: { marginTop: 10 }}} onClick={() => handleCancel()}/>
            <PrimaryButton text={t("product.save")} styles={{ root: { marginTop: 10 }}} onClick={() => handleSave()}/>
          </Stack>
        ) : (
          <Stack horizontal horizontalAlign='end' onClick={() => {
            const newExpandableList = [...expandableList]
            const _index = newExpandableList.findIndex(_item => _item.id === productId)
            newExpandableList[_index].open = !newExpandableList[_index].open

            setExpandableList(newExpandableList)
          }} className={controlClass.hover} styles={{ root: { paddingLeft: 15, paddingRight: 15}}} >
            <h4>{t("product.collapse")} <Icon iconName='ChevronUp' styles={{ root: { paddingLeft: 5}}} /></h4>
          </Stack>

        )}
        <Stack horizontalAlign='center' styles={{ root: { padding: 5}}}>
          {newVariant.map((_variant, index) => (
            <Stack horizontal key={'variant' + index} tokens={{ childrenGap: 15}}>
              {_variant.variation.length === 2 ? (
                <h2 style={{ width: 100}}>{_variant.variation[0].value} - {_variant.variation[1].value}</h2>
              ) : (
                <h2 style={{ width: 100}}>{_variant.variation[0].value}</h2>
              )}
              <Stack verticalAlign='center'>
                <TextField id='price' value={_variant.price} onChange={(e) => handleEdit(e, index)} />
              </Stack>
              <Stack verticalAlign='center'>
                <TextField id='available' value={_variant.available} onChange={(e) => handleEdit(e, index)} />
              </Stack>
              <Stack verticalAlign='center'>
                <Toggle
                  id="status"
                  styles={{ root: { margin: "4px 0px", width: 120}}}
                  inlineLabel
                  onText="Active"
                  offText="Inactive"
                  checked={_variant.status === "active"}
                  onChange={(e, checked) => handleEdit(e, index, checked)} 
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
        {/* <Stack horizontalAlign='center' styles={{ root: { padding: 5}}}>
          {newVariant.map((_variant, index) => (
            <Stack horizontal key={'variant' + index} tokens={{ childrenGap: 15}}>
              {_variant.options.length === 2 ? (
                <h2 style={{ width: 100}}>{_variant.options[0].value} - {_variant.options[1].value}</h2>
              ) : (
                <h2 style={{ width: 100}}>{_variant.options[0].value}</h2>
              )}
              <Stack verticalAlign='center'>
                <TextField id='price' value={_variant.price} onChange={(e) => handleEdit(e, index)} />
              </Stack>
              <Stack verticalAlign='center'>
                <TextField id='available' value={_variant.available} onChange={(e) => handleEdit(e, index)} />
              </Stack>
            </Stack>
          ))}
        </Stack> */}

      </Stack>
    ) : <Stack className={controlClass.hover} styles={{ root: { backgroundColor: '#FFFFFF', borderBottom: '1px solid rgb(237, 235, 233)', paddingLeft: 63, paddingRight: 15 }}}>
          <Stack horizontal horizontalAlign='space-between'  onClick={() => {
            const newExpandableList = [...expandableList]
            const _index = newExpandableList.findIndex(_item => _item.id === productId)
            newExpandableList[_index].open = !newExpandableList[_index].open

            setExpandableList(newExpandableList)
          }}>
            <h4>{newVariant.length} {t("product.variations")}</h4>
            <h4>{t("product.expand")} <Icon iconName='ChevronDown' styles={{ root: { paddingLeft: 5}}} /></h4>
          </Stack>
        </Stack> }
      
  </Stack>
  }

  function PriceRangeDropdown(props) {
    return <Stack styles={{ root: { padding: 10}}}>
      <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
        <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
          <Text>{t('product.min')}</Text>
        </Stack>
        <Stack styles={{ root: { width: '80%', justifyContent: 'center' /* alignItems: 'center' */ } }}>
          <TextField suffix={t('product.thb')} value={priceFrom} onChange={(e, newValue) => setPriceFrom(newValue)}/>
        </Stack>
      </Stack>
      <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
        <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
          <Text>{t('product.max')}</Text>
        </Stack>
        <Stack styles={{ root: { width: '80%', justifyContent: 'center' /* alignItems: 'center' */ } }}>
          <TextField suffix={t('product.thb')} value={priceTo} onChange={(e, newValue) => setPriceTo(newValue)}/>
        </Stack>
      </Stack>
    </Stack>
  }

  function handleReset() {
    setPriceFrom(0)
    setPriceTo(0)
    setSearch("")
    setSelectedCategory({
      categoryCode: "",
      categoryName: "",
      cateRdCode: ""
    })

    setGetNewDocuments(true)
    setLoadDataComplete(false)
  }

  function handleSearch() {
    setGetNewDocuments(true)
    setLoadDataComplete(false)
  }

  return (
    <div style={homeStyles}>
      {showMessageBarSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSuccess(false)}
          dismissButtonAriaLabel={t('contact.close')}
          style={{
            fontSize: 14,
          }}
        >
          {t('contact.updateSuccess')}
        </MessageBar>
      )}

      
      <Stack horizontal horizontalAlign="center">
        <Stack
          vertical
          style={{
            width: '98%',
            minWidth: '800px',
            maxWidth: '1350px',
          }}
        >
          <Stack horizontal horizontalAlign='space-between'>
            <h2 style={{ marginLeft: '10px', marginTop: '25px' }}>
              <Icon iconName="ProductList" style={{ color: '#106ebe' }} /> {t('product.header')}
            </h2>
            <h2 style={{ marginRight: '10px', marginTop: '25px' }}>
              <PrimaryButton
                iconProps={{ iconName: 'Add' }}
                text={t('product.addProduct')}
                onClick={() => {
                  history.push('/products/add');
                }}
              />
            </h2>
          </Stack>
          
          {/* <Stack horizontal className="ms-bgColor-white" horizontalAlign="space-between">
              <Stack
                styles={{
                  root: {
                    width: 'calc(100vw - 700px)',
                    minWidth: '700px',
                    maxWidth: '900px',
                  },
                }}
              >
                <CommandBar items={commandBarItems} styles={{ root: { width: 200 } }} />
              </Stack>
          
          </Stack> */}
          <Stack horizontal horizontalAlign='space-between'>
            <Pivot onClickCapture={e => setProductStatus(e.target.innerText)}>
              <PivotItem headerText="All" itemKey='All'>
              </PivotItem>
              <PivotItem headerText="Active">
              </PivotItem>
              <PivotItem headerText="Inactive">
              </PivotItem>
              <PivotItem headerText="Suspended">
              </PivotItem>
              {/* <PivotItem headerText="Draft">
              </PivotItem> */}
              <PivotItem headerText="Deleted">
              </PivotItem>

            </Pivot>
            {/* <div style={{ height: 44, paddingLeft: 6 }}>
              <SearchBox
                className="ms-borderColor-themePrimary"
                styles={{
                  root: { margin: 6, width: 350, fontSize: 13, fontWeight: 0 },
                }}
                placeholder="ค้นหา"
                onChange={(e) => setSearch(e ? e.target.value : '')}
                value={search}
              />
            </div> */}
           
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 10}}>
            <SearchBox
              className="ms-borderColor-themePrimary"
              styles={{
                root: { margin: 6, width: 300, fontSize: 13, fontWeight: 0 },
              }}
              placeholder={t('product.search')}
              onChange={(e) => setSearch(e ? e.target.value : '')}
              value={search}
              onClear={() => {
                setGetNewDocuments(true)
                setLoadDataComplete(false)
              }}
            />
            <Dropdown 
              styles={{ root: { width: 200, margin: 6}}}
              options={[{ key: 'key'}]}
              placeholder={t('product.price')}
              onRenderPlaceholder={props => {
                let result = <Text>{t('product.price')}</Text>
                if (priceFrom && priceTo) {
                  result = <Text>{priceFrom} - {priceTo}</Text>
                } else if (priceFrom) {
                  result = <Text>{priceFrom}</Text>
                } else if (priceTo) {
                  result = <Text>{priceTo}</Text>
                }
                return result
              }}
              onRenderItem={props => (
                <PriceRangeDropdown props={props} />
              )}
            />
            <Dropdown
              id="category"
              // required
              placeholder={t('product.categoryPlaceHolder')}
              options={_categoryList.map(_category => ({ key: _category.categoryCode, text: i18n.language === "th" ? _category.categoryNameTh : _category.categoryNameEn}))}
              selectedKey={selectedCategory.categoryCode}
              styles={{ root: { width: 178, margin: 6 } }}
            //   styles={{ root: { minWidth: 178, maxWidth: 400, margin: 6 } }}
              onChange={(e, selectedOption) => setSelectedCategory(prev => ({...prev, categoryCode: selectedOption.key, categoryName: selectedOption.text}))}
            />
            <Dropdown 
              styles={{ root: { width: 178, margin: 6}}}
              options={[
                { key: 10, text: '10 ' + t('product.productPerPage')},
                { key: 20, text: '20 ' + t('product.productPerPage')},
                { key: 30, text: '30 ' + t('product.productPerPage')},
                { key: 40, text: '40 ' + t('product.productPerPage')},
                { key: 50, text: '50 ' + t('product.productPerPage')},
              ]}
              placeholder={t('product.productPerPage')}
              selectedKey={pageSize}
              // onRenderItem={props => (
              //   <PriceRangeDropdown props={props} />
              // )}
            />
            <PrimaryButton text={t('product.search')} styles={{ root: { margin: 6}}} onClick={handleSearch}/>
            <DefaultButton text={t('product.reset')} styles={{ root: { margin: 6}}} onClick={handleReset} />
          </Stack>
          <br />
          {items.length ? (
            <ShimmeredDetailsList
              items={items || []}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.multiple}
              layoutMode={DetailsListLayoutMode.justified}
              checkboxVisibility={CheckboxVisibility.always}
              isHeaderVisible={true}
              onRenderRow={(props) => {
                if (props.item.variations.length) {
                // if (props.item.variantOptions.length) {
                  return <ExpandDetailRow props={props} />
                } else {
                  return <Stack><DetailsRow {...props}  /></Stack>

                }
            }}
            />
          ) : (
            <ShimmeredDetailsList
              items={[{}]}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderRow={() => {
                return (
                  <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: '#FFFFFF', height: 55 } }}>
                    <Text>{t('product.noItemFound')}</Text>
                  </Stack>
                );
              }}
            />
          )}
        </Stack>
      </Stack>
      <br />
      <center>
        {loadDataComplete && (
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={pageData.totalPages}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
          />
        )}
        <br />
        <br />
      </center>
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={() =>
          setDialogConfirm((prev) => ({
            ...prev,
            isClick: false,
          }))
        }
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton
            onClick={() =>
              setDialogConfirm((prev) => ({
                ...prev,
                isClick: false,
              }))
            }
            text={t('documents.cancel')}
          />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default Products;
