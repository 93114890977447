import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Stack, Icon, PrimaryButton, TextField, FocusTrapZone, Spinner } from '@fluentui/react';

const API_URL = process.env.REACT_APP_API_URL;

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const SAPBusinessOneConnect = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t } = useTranslation();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const [status, setStatus] = useState('');

  const [server, setServer] = useState('');
  const [port, setPort] = useState('');
  const [companyDB, setCompanyDB] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .post(
          API_URL + '/sources/sap/b1',
          {
            server: server,
            port: port,
            CompanyDB: companyDB,
            UserName: userName,
            Password: password,
          },
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          }
        )
        .then((response) => {
          console.log('response', response);

          if (response.data) {
            history.push({
              pathname: '/etax/sources/sap/business-one',
              state: {},
            });
          }
          setIsLoading(false);
        });
    });
  };

  useEffect(() => {
    console.log('userEffect retrieve SAP Business One connection data!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/sources/sap-b1', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log('SAP Business One sources: ', response);

              if (response.data) {
                if (!response.data.disabled && response.data.canLogin) {
                  history.push({
                    pathname: '/etax/sources/sap/business-one',
                    state: {},
                  });
                }
              }

              setIsLoading(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {!isLoading && (
        <Stack>
          <br />

          <h1 style={{ marginLeft: '0px', marginTop: '27px' }}>
            <Icon iconName="CloudImportExport" style={{ color: '#106ebe' }} /> {t('sapB1Connect.header')}
          </h1>
          <h3 style={{ marginTop: '0px', marginBottom: '10px', alignSelf: 'center' }}>{t('sapB1Connect.subHeader1')}</h3>
          <h3 style={{ marginTop: '0px', marginBottom: '10px', alignSelf: 'center' }}>{t('sapB1Connect.subHeader2')}</h3>

          <br />
          <br />
        </Stack>
      )}
      {status === '' && !isLoading && (
        <FocusTrapZone disabled={true}>
          <Stack horizontalAlign="center">
            <Stack
              style={{
                border: '1px solid rgb(134, 134, 134)',
                width: '350px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <br />
              <Stack horizontal horizontalAlign="center">
                <img src="/images/sap-logo.png" width="280px" style={{ margin: 20 }} alt="saplogo" />
              </Stack>
              <form onSubmit={handleSubmit}>
                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Server"
                    required
                    onChange={(e) => {
                      setServer(e.target.value);
                    }}
                    value={server}
                    style={{ width: '280px' }}
                  />
                </Stack>
                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Port"
                    required
                    onChange={(e) => {
                      setPort(e.target.value);
                    }}
                    value={port}
                    style={{ width: '280px' }}
                  />
                </Stack>
                <br />
                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="CompanyDB"
                    required
                    onChange={(e) => {
                      setCompanyDB(e.target.value);
                    }}
                    value={companyDB}
                    style={{ width: '280px' }}
                  />
                </Stack>
                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="UserName"
                    required
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                    value={userName}
                    style={{ width: '280px' }}
                  />
                </Stack>

                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Password"
                    type="password"
                    canRevealPassword
                    required
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                    style={{ width: '250px' }}
                  />
                </Stack>
                <br />
                <br />
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
                  <PrimaryButton
                    style={{
                      fontSize: '20px',
                      height: '50px',
                      width: '280px',
                    }}
                    type="submit"
                  >
                    {t('sapB1Connect.connect')}
                  </PrimaryButton>
                </Stack>
              </form>
              <br />
              <br />
            </Stack>
          </Stack>
        </FocusTrapZone>
      )}
      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              {false ? (
                <Spinner label={t('sapB1Connect.processing')} labelPosition="down" styles={spinnerStyles} />
              ) : (
                <Spinner label={t('sapB1Connect.loading')} labelPosition="down" styles={spinnerStyles} />
              )}
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'success' && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: '1px solid rgb(134, 134, 134)',
              width: '300px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#0078D4' }}>{t('sapB1Connect.successMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/success03.gif" alt="success03gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton style={{ fontSize: '20px', height: '50px' }} onClick={() => {}}>
                {t('sapB1Connect.successButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'fail' && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: '1px solid rgb(134, 134, 134)',
              width: '300px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#F25139' }}>{t('sapB1Connect.failMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/fail01.gif" alt="fail01gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  setStatus('');
                }}
              >
                {t('sapB1Connect.failButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      <br />
      <br />
      {status === '' && !isLoading && (
        <center>
          <br />
          <br />
          <span style={{ color: 'gray' }}>{t('sapB1Connect.footer1')}</span>
          <br />
          <br />
          <span style={{ color: 'gray', fontWeight: 'bold' }}>{t('sapB1Connect.footer2')}</span>
        </center>
      )}
    </Stack>
  );
};

export default SAPBusinessOneConnect;
