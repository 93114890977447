import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, PrimaryButton, Dropdown, Stack, Spinner } from '@fluentui/react';
import axios from 'axios';
import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../authConfig';
import { useTranslation } from 'react-i18next';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_SUPPORT_EMAIL_API_KEY;

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

export default function Feedback() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const history = useHistory();

  const { t } = useTranslation();

  const [feedback, setFeedback] = useState({
    title: '',
    category: '',
    description: '',
  });

  const [status, setStatus] = useState('');

  const submitting = status === 'Submitting';
  const submitComplete = status === 'SubmitComplete';

  function handleChange(e, selectedOption) {
    if (e.target.id === 'category') {
      setFeedback((prev) => ({ ...prev, [e.target.id]: selectedOption }));
    } else {
      setFeedback((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    setStatus('Submitting');

    let email = account.username;

    let message = `
    <p>หัวข้อ: ${feedback.title}<p>
    <br />
    <p>หมวดหมู่: ${feedback.category.text}<p>
    <br />
    <p>ข้อความ: ${feedback.description}<p>`;

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async () => {
        const response = await axios.post(
          API_URL + '/external/emails/support',
          {
            subject: '[Leceipt-Feedback] ' + email,
            message: message,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'API-KEY': API_KEY,
            },
          }
        );

        setStatus('SubmitComplete');
        setTimeout(() => {
          history.push('/etax/documents/all');
        }, 2000);
        console.log('response emails support', response);
      })
      .catch((error) => {
        console.log('error emails support', error);
        instance.acquireTokenRedirect(silentRequest);
      });
  }

  return (
    <>
      <Stack horizontalAlign="center">
        <h2>{t('feedback.header')}</h2>
        {submitting && (
          <Stack horizontalAlign="center">
            <br />
            <Spinner label={t('feedback.sending')} labelPosition="down" styles={spinnerStyles} />
          </Stack>
        )}
        {submitComplete && (
          <Stack horizontalAlign="center">
            <br />
            <img height="220" src="../images/success01.gif" alt="success01gif" />
            <br />
            <h3>{t('feedback.sent')}</h3>
          </Stack>
        )}
        {!submitting && !submitComplete && (
          <form onSubmit={handleSubmit}>
            <Stack horizontalAlign="center" tokens={{ childrenGap: 15 }}>
              <Stack horizontalAlign="space-between" horizontal styles={{ root: { width: 900 } }}>
                <Dropdown
                  id="category"
                  placeholder={t('feedback.pleaseSelect')}
                  label={t('feedback.category')}
                  options={[
                    { key: 'REPORT', text: t('feedback.REPORT') },
                    { key: 'FUNCTION', text: t('feedback.FUNCTION') },
                    { key: 'SERVICE', text: t('feedback.SERVICE') },
                    { key: 'PAYMENT', text: t('feedback.PAYMENT') },
                  ]}
                  styles={{
                    root: {
                      width: 222,
                    },
                  }}
                  selectedKey={feedback.category.key}
                  onChange={(e, selectedOption) => handleChange(e, selectedOption)}
                  required
                />
                <TextField
                  id="title"
                  name="title"
                  label={t('feedback.title')}
                  placeholder={t('feedback.titleDescription')}
                  styles={{
                    root: {
                      width: 650,
                    },
                    field: {
                      selectors: {
                        '::placeholder': {
                          color: '#605E5C',
                          fontFamily: 'CSChatThaiUI, sans-serif !important;',
                          fontSize: 14,
                        },
                      },
                    },
                  }}
                  onChange={handleChange}
                  value={feedback.title}
                  required
                />
              </Stack>
              <Stack>
                <TextField
                  id="description"
                  name="description"
                  label={t('feedback.description')}
                  placeholder={t('feedback.descriptionDetail')}
                  multiline
                  styles={{
                    root: {
                      width: 900,
                    },
                    field: {
                      selectors: {
                        '::placeholder': {
                          color: '#605E5C',
                          fontFamily: 'CSChatThaiUI, sans-serif !important;',
                          fontSize: 14,
                        },
                      },
                    },
                  }}
                  style={{ height: 200 }}
                  onChange={handleChange}
                  value={feedback.description}
                  required
                />
              </Stack>
              <br />
              <PrimaryButton text={t('feedback.send')} style={{ width: '125px', letterSpacing: '1px' }} type="submit" />
            </Stack>
          </form>
        )}
      </Stack>
    </>
  );
}
