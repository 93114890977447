import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { useHistory, useLocation } from 'react-router-dom';

import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import {
  PrimaryButton,
  DefaultButton,
  getTheme,
  Spinner,
  Label,
  FocusZone,
  Dialog,
  DialogType,
  Icon,
  DialogFooter,
  mergeStyleSets,
  ActionButton,
} from '@fluentui/react';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

//Dropzone Style
const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-top: 5px;
`;

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

//Download button style
const classNames = mergeStyleSets({
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '50px',
    maxWidth: '250px',
  },
});

const Documents = () => {
  console.log('Re render');

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const history = useHistory();
  const location = useLocation();

  const [data] = useState(location.state);

  const [certificatesRequest, setCertificatesRequest] = useState({});

  const [isUploadComplete01, setIsUploadComplete01] = useState(false);
  const [isUploadComplete02, setIsUploadComplete02] = useState(false);
  const [isUploadComplete03, setIsUploadComplete03] = useState(false);

  const [isUploading01, setIsUploading01] = useState(false);
  const [isUploading02, setIsUploading02] = useState(false);
  const [isUploading03, setIsUploading03] = useState(false);
  const [isUploading04, setIsUploading04] = useState(false);
  const [isUploading05, setIsUploading05] = useState(false);

  const [files01, setFiles01] = useState([]);
  const [files02, setFiles02] = useState([]);
  const [files03, setFiles03] = useState([]);
  const [files04, setFiles04] = useState([]);
  const [files05, setFiles05] = useState([]);

  const [submitClick, setSubmitClick] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  const uploadFiles = (requestId, docId, files) => {
    if (docId === 1) {
      setIsUploading01(true);
    } else if (docId === 2) {
      setIsUploading02(true);
    } else if (docId === 3) {
      setIsUploading03(true);
    } else if (docId === 4) {
      setIsUploading04(true);
    } else if (docId === 5) {
      setIsUploading05(true);
    }

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        let promises = [];

        files.forEach((file) => {
          console.log(file.path);
          let formData = new FormData();
          formData.append('requestId', requestId);
          formData.append('file', file);

          promises.push(
            axios.post('https://ws.leceipt.com/certificate-requests/documents', formData, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
              },
            })
          );
        });

        Promise.all(promises).then(function (results) {
          if (docId === 1) {
            setIsUploadComplete01(true);
          } else if (docId === 2) {
            setIsUploadComplete02(true);
          } else if (docId === 3) {
            setIsUploadComplete03(true);
          }

          results.forEach(function (response) {
            if (response.data.fileSize > 0) {
              console.log('Response upload: ', response);
            } else {
              if (docId === 1) {
                setIsUploadComplete01(false);
                setFiles01([]);
              } else if (docId === 2) {
                setIsUploadComplete02(false);
                setFiles02([]);
              } else if (docId === 3) {
                setIsUploadComplete03(false);
                setFiles03([]);
              } else if (docId === 4) {
                setFiles04([]);
              } else if (docId === 5) {
                setFiles05([]);
              }
            }
          });

          if (docId === 1) {
            setIsUploading01(false);
          } else if (docId === 2) {
            setIsUploading02(false);
          } else if (docId === 3) {
            setIsUploading03(false);
          } else if (docId === 4) {
            setIsUploading04(false);
          } else if (docId === 5) {
            setIsUploading05(false);
          }
        });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const FileDropzone = (props) => {
    //Dropzone
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } = useDropzone({
      accept: 'image/jpeg, image/jpg, image/png, application/pdf',
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      multiple: true,
      minSize: 0,
      maxSize: 20971520,
      onDrop: (files) => {
        console.log(files);

        let _file = [];

        files.forEach((file) => {
          _file.push(file.name);
        });

        if (props.id === 1) {
          setFiles01(_file);
        } else if (props.id === 2) {
          setFiles02(_file);
        } else if (props.id === 3) {
          setFiles03(_file);
        } else if (props.id === 4) {
          setFiles04(_file);
        } else if (props.id === 5) {
          setFiles05(_file);
        }

        uploadFiles(certificatesRequest.id, props.id, files);
      },
    });

    return (
      <div>
        {props.id === 1 && (
          <ul style={{ color: '#106EBE' }}>
            {files01.map((file) => (
              <li key={file}>{file}</li>
            ))}
          </ul>
        )}
        {props.id === 2 && (
          <ul style={{ color: '#106EBE' }}>
            {files02.map((file) => (
              <li key={file}>{file}</li>
            ))}
          </ul>
        )}
        {props.id === 3 && (
          <ul style={{ color: '#106EBE' }}>
            {files03.map((file) => (
              <li key={file}>{file}</li>
            ))}
          </ul>
        )}
        {props.id === 4 && (
          <ul style={{ color: '#106EBE' }}>
            {files04.map((file) => (
              <li key={file}>{file}</li>
            ))}
          </ul>
        )}
        {props.id === 5 && (
          <ul style={{ color: '#106EBE' }}>
            {files05.map((file) => (
              <li key={file}>{file}</li>
            ))}
          </ul>
        )}
        <div className="container" style={{ width: 500 }}>
          <Container
            {...getRootProps({
              isDragActive,
              isDragAccept,
              isDragReject,
              onDrop: (file) => {
                console.log(file);
              },
            })}
          >
            <input {...getInputProps()} />
            <p>ลากไฟล์มาวางที่นี่ หรือคลิกปุ่มอัพโหลด</p>
            <DefaultButton text="อัพโหลด" onClick={open} />
          </Container>
        </div>
      </div>
    );
  };

  const download = (event, requestId) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/certificate-requests/' + requestId, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              console.log(response.headers);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'certificate-request.pdf'); //or any other extension
              document.body.appendChild(link);
              link.click();
              link.remove();
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  function handleSubmit(event) {
    console.log('handleSubmit!');
    event.preventDefault();

    setSubmitClick(true);

    if (isUploadComplete01 && isUploadComplete02 && isUploadComplete03) {
      setIsUpdating(true);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          //Update certificates request
          axios
            .put(
              API_URL + '/certificate-requests/' + certificatesRequest.id,
              {
                period: certificatesRequest.period,
                taxId: certificatesRequest.taxId,
                type: certificatesRequest.type,
                nameTH: certificatesRequest.nameTH,
                nameEN: certificatesRequest.nameEN,
                email: certificatesRequest.email,

                identity: certificatesRequest.identity,
                address: certificatesRequest.address,
                postcode: certificatesRequest.postcode,
                district: certificatesRequest.district,
                province: certificatesRequest.province,
                phone: certificatesRequest.phone,
                mobile: certificatesRequest.mobile,

                firstName: certificatesRequest.firstName,
                lastName: certificatesRequest.lastName,
                contactPhone: certificatesRequest.contactPhone,
                contactMobile: certificatesRequest.contactMobile,
                contactEmail: certificatesRequest.contactEmail,

                ca: certificatesRequest.ca,
                createdTime: certificatesRequest.createdTime,
                updatedTime: certificatesRequest.updatedTime,
                status: 'uploaded',

                document1: files01,
                document2: files02,
                document3: files03,
                document4: files04,
                document5: files05,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              }
            )
            .then(
              (response) => {
                console.log('Certificate requests update : ', response);

                setIsUpdating(false);
                setIsSuccess(true);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
          setIsUpdating(false);
        });
    }
  }

  const handleEdit = (e) => {
    e.preventDefault();

    history.push({
      pathname: '/etax/signatures',
      state: {},
    });
  };

  useEffect(() => {
    console.log('useEffect data call!: ', data);

    if (data !== undefined) {
      setCertificatesRequest(data);
    } else {
      history.push({
        pathname: '/etax/signatures',
        state: {},
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h2 style={{ margin: '2%' }}>
          <Icon iconName="DocumentApproval" style={{ color: '#106ebe' }} /> อัพโหลดเอกสารเพื่อขอใบรับรองอิเล็กทรอนิกส์
        </h2>
        <div
          className="ms-Grid"
          dir="ltr"
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 30,
            background: '#ffffff',
            margin: '2%',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <div className="ms-Grid-row">
            <FocusZone
              as="ul"
              style={{
                display: 'inline-block',
                border: '1px solid ' + theme.palette.neutralTertiary,
                padding: 15,
                lineHeight: 2,
                overflow: 'hidden',
              }}
            >
              <ul style={{ color: '#106EBE' }}>
                <li>สำเนาเอกสารทั้งหมดต้องลงลายมือชื่อโดยกรรมการผู้มีอำนาจลงนาม พร้อมรับรองสำเนาถูกต้อง และประทับตราสำคัญของบริษัท</li>
                <li>หากเอกสารแต่ละประเภทมีหลายไฟล์ ให้ลากทุกไฟล์มาวางพร้อมกัน</li>
                <li>กรุณาอัพโหลดไฟล์ jpg, png หรือ pdf ขนาดไฟล์ไม่เกิน 20 MB</li>
              </ul>
            </FocusZone>
            <br />
            <Label required>ใบคำขอใบรับรองอิเล็กทรอนิกส์</Label>
            <ActionButton
              iconProps={{
                iconName: 'DownloadDocument',

                styles: {
                  root: {
                    fontSize: 25,
                    color: '#106ebe',
                  },
                },
              }}
              title="ดาวน์โหลด"
              className={classNames.fileIconImg}
              onClick={(event) => {
                download(event, certificatesRequest.id);
              }}
            >
              ดาวน์โหลดเอกสารที่นี่
            </ActionButton>
            {submitClick && !isUploadComplete01 && <small style={{ color: 'red' }}>กรุณาอัพโหลดเอกสาร</small>}
            <FileDropzone id={1} />
            <br />
            <Label required>หนังสือรับรองบริษัท (อายุไม่เกิน 3 เดือน)</Label>
            {submitClick && !isUploadComplete02 && <small style={{ color: 'red' }}>กรุณาอัพโหลดเอกสาร</small>}
            <FileDropzone id={2} />
            <br />

            <Label required>บัตรประจำตัวประชาชนหรือพาสปอร์ต กรรมการผู้มีอำนาจลงนาม</Label>
            {submitClick && !isUploadComplete03 && <small style={{ color: 'red' }}>กรุณาอัพโหลดเอกสาร</small>}
            <FileDropzone id={3} />

            <br />

            <Label>ภ.พ. 20 (ถ้ามี)</Label>
            <FileDropzone id={4} />
            <br />
          </div>
        </div>

        <br />
        <center>
          {isUploading01 || isUploading02 || isUploading03 || isUploading04 || isUploading05 ? (
            <Spinner label="กำลังอัพโหลด..." labelPosition="right" />
          ) : (
            <div>
              <PrimaryButton name="next" text="ส่งเอกสาร" type="submit" />
              <DefaultButton text="ย้อนกลับ" style={{ marginLeft: '15px' }} onClick={handleEdit} />
            </div>
          )}
        </center>
        <br />
        <br />
        <br />
        <Dialog
          hidden={!isUpdating}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'กำลังดำเนินการ...',
          }}
        >
          <Spinner label="ส่งเอกสาร..." labelPosition="down" styles={spinnerStyles} />
        </Dialog>

        <Dialog
          hidden={!isSuccess}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'ส่งเอกสารสำเร็จ!',
            subText: 'เจ้าหน้าที่จะตรวจเอกสารประมาณ 1-2 วันทำการ แล้วจะโทรติดต่อท่านตามเบอร์ที่ให้ไว้',
          }}
        >
          <img height="220" src="/images/success.gif" alt="successGif" />
          <br />
          <br />
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                history.push({
                  pathname: '/etax/signatures',
                  state: {},
                });
              }}
              text="ตกลง"
            />
          </DialogFooter>
        </Dialog>
      </form>
    </div>
  );
};

export default Documents;
