import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Stack, Icon, PrimaryButton, TextField, FocusTrapZone, Spinner, Dropdown, DefaultButton, Separator } from '@fluentui/react';

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const centerItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const StoragesConnect = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t } = useTranslation();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const [status, setStatus] = useState('');

  const [accessKeyId, setAccessKeyId] = useState('');
  const [secretAccessKey, setSecretAccessKey] = useState('');

  const [storage, setStorage] = useState('amazon')
  const [region, setRegion] = useState('ap-southeast-1')

  const [buckets, setBuckets] = useState([])
  const [selectedBucket, setSelectedBucket] = useState(null)

  const [backup, setBackup] = useState('true')

  // useEffect(() => { 
  //   // setAccessKeyId('6YI3LXDC756TKFTB09QL')
  //   // setSecretAccessKey('fno3BBrM7frperhysTN34goiLLl137g7ZaSQ3rYB')
  //   // setStorage('wasabi')
    
  //   // setAccessKeyId('AKIATJSVGSYV65D7XJGE')
  //   // setSecretAccessKey('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')
  //   // setStorage('amazon')
  //   // setRegion('ap-southeast-2')

  //   setAccessKeyId('jxr7uir6nxqcwiow226b6vf3drxa')
  //   setSecretAccessKey('jytnnngkjtsthnek7fy2ahod5sqx2a6c6fnzjlt7wembwfwf7apcm')
  //   // setAccessKeyId('jw3ltrqsbqkgowk3tjsd3cumqzzq')
  //   // setSecretAccessKey('jzyn4qfxxbla6mu2yvzqk3t3vauoshhvo4qwkimhfqcq52nnc2prg')
  //   setStorage('storj')
  // }, [])
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .get(
          'http://localhost:7071/api/AWS_connect',
          {
            params: {
              accessKeyId: accessKeyId,
              secretAccessKey: secretAccessKey,
              storage: storage,
              region: region
            }
          },
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          }
        )
        .then((response) => {
          
          console.log('response', response);

          if (response.data.success) {
            if (response.data.Buckets.length) {
              setStatus('buckets')
              setBuckets(response.data.Buckets)
              setSelectedBucket(response.data.Buckets[0].Name)
            }
            // setStatus("success");
          } else {
            setStatus("fail");
          }

          setIsLoading(false);
        });
    });
  };

  const handleSelectBucket = async (e, bucket) => {
    e.preventDefault();

    setIsLoading(true);

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .get(
          // 'http://localhost:7071/api/AWS_getObjectInBucket',
          // 'http://localhost:7071/api/AWS_uploadObject',
          'http://localhost:7071/api/AWS_listObjects',
          {
            params: {
              accessKeyId: accessKeyId,
              secretAccessKey: secretAccessKey,
              storage: storage,
              region: region,
              bucket: bucket,

              // ContinuationToken: moreContent.ContinuationToken,
              // StartAfter: moreContent.StartAfter
            }
          },
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          }
        )
        .then((response) => {
          
          console.log('response', response);

          // const {
          //   $metadata,
          //   Contents,
          //   Delimiter,
          //   IsTruncated,
          //   KeyCount,
          //   MaxKeys,
          //   Name,
          //   NextContinuationToken,
          //   Prefix
          // } = response.data

          // setMoreContent(prev => ({...prev, IsTruncated: IsTruncated, ContinuationToken: NextContinuationToken}))

          if (response.data.success) {
            // if (response.data.Buckets.length) {
            //   setStatus('buckets')
            //   setBuckets(response.data.Buckets)
            // }
            setStatus("success");

            history.push({
              pathname: '/storages',
              state: {
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey,
                storage: storage,
                region: region,
                bucket: bucket
              },
            });
          } else {
            setStatus("fail");
          }

          setIsLoading(false);
        });
    });
  };

  useEffect(() => {
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {!isLoading && (
        <Stack>
          <br />

          <h1 style={{ marginLeft: '0px', marginTop: '27px' }}>
            <Icon iconName="CloudImportExport" style={{ color: '#106ebe' }} /> {t('storagesConnect.header')}
          </h1>

          {!(status === 'buckets' && !isLoading) ? (
            <>
              <br />
            </>
          ) : null}
          
        </Stack>
      )}
      {status === '' && !isLoading && (
        <FocusTrapZone disabled={true}>
          <Stack horizontalAlign="center">
            <Stack
              style={{
                border: '1px solid rgb(134, 134, 134)',
                width: '350px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <br />
              <Stack horizontal horizontalAlign="center">
                <img src="/images/aws-s3-logo-400x300.png" width="280px" style={{ margin: 20 }} alt="awsS3logo" />
              </Stack>
              <form onSubmit={handleSubmit}>
                <Stack horizontal horizontalAlign="center">
                  <Dropdown 
                    style={{ width: '280px' }}
                    label='Storage'
                    options={[
                      { key: "amazon", text: "Amazon S3" },
                      { key: "wasabi", text: "Wasabi" },
                      { key: "storj", text: "Storj" },
                    ]}
                    selectedKey={storage}
                    onChange={(e, option) => setStorage(option.key)}
                  />
                </Stack>
                <Stack horizontal horizontalAlign="center">
                  <Dropdown 
                    style={{ width: '280px' }}
                    label='Region'
                    options={[
                      { key: "ap-southeast-1", text: "ap-southeast-1" },
                      { key: "ap-southeast-2", text: "ap-southeast-2" },
                      { key: "ap-southeast-3", text: "ap-southeast-3" },
                      { key: "ap-southeast-4", text: "ap-southeast-4" },
                      { key: "ap-northeast-1", text: "ap-northeast-1" },
                      { key: "ap-northeast-2", text: "ap-northeast-2" },
                      { key: "ap-northeast-3", text: "ap-northeast-3" },
                      { key: "ap-south-1", text: "ap-south-1" },
                      { key: "ap-south-2", text: "ap-south-2" },
                      { key: "ap-east-1", text: "ap-east-1" },
                      { key: "us-east-2", text: "us-east-2" },
                      { key: "us-east-1", text: "us-east-1" },
                      { key: "us-west-1", text: "us-west-1" },
                      { key: "us-west-2", text: "us-west-2" },
                      { key: "us-gov-east-1", text: "us-gov-east-1" },
                      { key: "us-gov-west-1", text: "us-gov-west-1" },
                      { key: "af-south-1", text: "af-south-1" },
                      { key: "ca-central-1", text: "ca-central-1" },
                      { key: "cn-north-1", text: "cn-north-1" },
                      { key: "cn-northwest-1", text: "cn-northwest-1" },
                      { key: "eu-central-1", text: "eu-central-1" },
                      { key: "eu-west-1", text: "eu-west-1" },
                      { key: "eu-west-2", text: "eu-west-2" },
                      { key: "eu-south-1", text: "eu-south-1" },
                      { key: "eu-west-3", text: "eu-west-3" },
                      { key: "eu-north-1", text: "eu-north-1" },
                      { key: "eu-south-2", text: "eu-south-2" },
                      { key: "eu-central-2", text: "eu-central-2" },
                      { key: "sa-east-1", text: "sa-east-1" },
                      { key: "me-south-1", text: "me-south-1" },
                      { key: "me-central-1", text: "me-central-1" },
                    ]}
                    selectedKey={region}
                    onChange={(e, option) => setRegion(option.key)}
                  />
                </Stack>
                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Access Key Id"
                    required
                    onChange={(e) => {
                      setAccessKeyId(e.target.value);
                    }}
                    value={accessKeyId}
                    style={{ width: '280px' }}
                  />
                </Stack>
                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Secret Access Key"
                    required
                    onChange={(e) => {
                      setSecretAccessKey(e.target.value);
                    }}
                    value={secretAccessKey}
                    style={{ width: '280px' }}
                  />
                </Stack>
                <Stack style={{ margin: '35px 35px 10px 35px'}}>
                    <Separator>{t('storagesConnect.backupLong')}</Separator>
                </Stack>
                <Stack horizontal horizontalAlign="center">
                  <Dropdown 
                    style={{ width: '280px' }}
                    label='Backup to Cloud Storage'
                    required
                    options={[
                      { key: "true", text: t('storagesConnect.backupTrue') },
                      { key: "false", text: t('storagesConnect.backupFalse') },
                    ]}
                    selectedKey={backup}
                    onChange={(e, option) => setStorage(option.key)}
                  />
                </Stack>
                <br />
                <br />
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
                  <PrimaryButton
                    style={{
                      fontSize: '20px',
                      height: '50px',
                      width: '280px',
                    }}
                    type="submit"
                  >
                    {t('sapB1Connect.connect')}
                  </PrimaryButton>
                </Stack>
              </form>
              <br />
              <br />
            </Stack>
          </Stack>
        </FocusTrapZone>
      )}
      {status === 'buckets' && !isLoading && (
        <Stack>
          {/* <h2>{t('storagesConnect.selectBucket')}</h2> */}
          <Dropdown
            id="selectBucket"
            placeholder={t('expenseNote.pleaseSelect')}
            label={t('storagesConnect.selectBucket')}
            options={buckets.map((_bucket, index) => {
              return { key: _bucket.Name, text: _bucket.Name}
            })}
            // styles={{
            //   root: {
            //     width: '20%',
            //   },
            // }}
            selectedKey={selectedBucket}
            onChange={(e, selectedOption) => {
              setSelectedBucket(selectedOption.key)
            }}
          />
          {/* {buckets.map((_bucket, index) => (
            <React.Fragment key={`bucket-${index}`}>
              <Stack horizontal horizontalAlign="start">
                <DefaultButton
                  style={{ fontSize: '20px', height: '70px', width: '400px', textAlign: 'left' }}
                  onClick={(e) => handleSelectBucket(e, _bucket.Name)}
                >
                  {_bucket.Name}
                </DefaultButton>
              </Stack>
              <br />
            </React.Fragment>
          ))} */}
          <br />
          <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
              <PrimaryButton text={t('creditNoteTaxInvoice.next')} style={{ width: '125px', letterSpacing: '1px' }} onClick={(e) => handleSelectBucket(e, selectedBucket)} />
              <DefaultButton text={t('creditNoteTaxInvoice.cancel')} onClick={() => setStatus('')} />
            </Stack>
          {/* <Stack horizontal horizontalAlign="start">
            <DefaultButton
              style={{ fontSize: '20px', height: '50px' }}
              onClick={() => setStatus('')}
            >
              {t('storagesConnect.back')}
            </DefaultButton>
          </Stack> */}
        </Stack>
      )}
      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              {false ? (
                <Spinner label={t('sapB1Connect.processing')} labelPosition="down" styles={spinnerStyles} />
              ) : (
                <Spinner label={t('sapB1Connect.loading')} labelPosition="down" styles={spinnerStyles} />
              )}
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {status === "success" && (
        <Stack horizontal horizontalAlign="center">
            <Stack style={{ border: "1px solid rgb(134, 134, 134)", width: "300px", backgroundColor: "#FFFFFF" }}>
                <br />
                <br />
                <Stack horizontal horizontalAlign="center">
                    <h1 style={{ margin: "0px", color: "#0078D4" }}>{t("xmlDeliver.successMessage")}</h1>
                </Stack>
                <br />
                <Stack horizontal horizontalAlign="center">
                    <img height="120" src="/images/success03.gif" alt="success03gif" />
                </Stack>
                <br />
                <br />
                <Stack horizontal horizontalAlign="center">
                    <PrimaryButton
                        style={{ fontSize: "20px", height: "50px" }}
                        onClick={() => {
                            setStatus("connect");
                        }}
                    >
                        {t("xmlDeliver.details")}
                    </PrimaryButton>
                </Stack>
                <br />
                <br />
            </Stack>
        </Stack>
      )}

      {status === 'fail' && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: '1px solid rgb(134, 134, 134)',
              width: '300px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#F25139' }}>{t('sapB1Connect.failMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/fail01.gif" alt="fail01gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  setStatus('');
                }}
              >
                {t('sapB1Connect.failButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      <br />
      <br />
      {/* {status === '' && !isLoading && (
        <center>
          <br />
          <br />
          <span style={{ color: 'gray' }}>{t('sapB1Connect.footer1')}</span>
          <br />
          <br />
          <span style={{ color: 'gray', fontWeight: 'bold' }}>{t('sapB1Connect.footer2')}</span>
        </center>
      )} */}
    </Stack>
  );
};

export default StoragesConnect;