import React, { useState, useEffect } from 'react';
import { TextField, DatePicker, DayOfWeek, mergeStyleSets, PrimaryButton, DefaultButton, Checkbox, Stack, getTheme, Spinner } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';
import { numberToTextTh } from '../../../helpers/BahtWord';

var moment = require('moment'); // require
moment().format();

const API_URL = process.env.REACT_APP_API_URL;

const contactListStyle = {
  background: 'white',
  border: '1px solid #eee',
  boxShadow: '0 2px 3px #ccc',
  boxSizing: 'border-box',
  maxHeight: 200,
  width: 310,
  position: 'absolute',
  overflow: 'auto',
  zIndex: 100000000,
};

const contactOptionStyle = {
  padding: '0px 8px',
  paddingTop: '8px',
  height: 26,
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

const theme = getTheme();

const onFormatDate = (date, format) => {
  if (new Date(date) === 'Invalid Date') return '';
  if (format === 'CE') {
    return date !== undefined
      ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
      : '';
  } else {
    return date !== undefined
      ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543)
      : '';
  }
};

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '300px',
  },
});

const WitholdingTax = () => {
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation();

  const [data] = useState(location.state);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyTaxId, setCompanyTaxId] = useState('');
  const [companyBranchText, setCompanyBranchText] = useState('');
  const [companyAllAddress, setCompanyAllAddress] = useState('');

  const [firstDayOfWeek] = React.useState(DayOfWeek.Sunday);

  const [referExpenseNote, setReferExpenseNote] = useState({});

  //Style
  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const inputId_taxpayer_13 = useState([]);

  const checkBox = [];
  const checkBox_payment = [];

  const inputFiles = useState([]);

  const input_paymentType = useState([]);

  const [bookNumber, setBookNumber] = useState('');
  const [numberForm, setNumberForm] = useState('');

  const [withholdingTaxer, setWithholdingTaxer] = useState('');
  const [withholdingAddress, setWithholdingAddress] = useState('');
  const [withholdingTaxId, setWithholdingTaxId] = useState({
    zero: '',
    one: '',
    two: '',
    three: '',
    four: '',
    five: '',
    six: '',
    seven: '',
    eight: '',
    nine: '',
    ten: '',
    eleven: '',
    twelve: '',
  });
  const [withholdingTaxId_10, setWithholdingTaxId_10] = useState({
    zero: '',
    one: '',
    two: '',
    three: '',
    four: '',
    five: '',
    six: '',
    seven: '',
    eight: '',
    nine: '',
  });
  const [orderNumber, setOrderNumber] = useState('');
  const [formType_1, setFormType_1] = useState(false);
  const [formType_2, setFormType_2] = useState(false);
  const [formType_3, setFormType_3] = useState(false);
  const [formType_4, setFormType_4] = useState(false);
  const [formType_5, setFormType_5] = useState(false);
  const [formType_6, setFormType_6] = useState(false);
  const [formType_7, setFormType_7] = useState(false);

  const [paymentType_1, setPaymentType_1] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    amountTotal: '',
    vatTotal: '',
  });
  const [paymentType_2, setPaymentType_2] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    amountTotal: '',
    vatTotal: '',
  });
  const [paymentType_3, setPaymentType_3] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    amountTotal: '',
    vatTotal: '',
  });
  const [paymentType_4_A, setPaymentType_4_A] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    amountTotal: '',
    vatTotal: '',
  });
  const [paymentType_4_B_1_1, setPaymentType_4_B_1_1] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    amountTotal: '',
    vatTotal: '',
  });
  const [paymentType_4_B_1_2, setPaymentType_4_B_1_2] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    amountTotal: '',
    vatTotal: '',
  });
  const [paymentType_4_B_1_3, setPaymentType_4_B_1_3] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    amountTotal: '',
    vatTotal: '',
  });
  const [paymentType_4_B_1_4, setPaymentType_4_B_1_4] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    percentVat: '',
    amountTotal: '',
    vatTotal: '',
  });

  const [paymentType_4_B_2_1, setPaymentType_4_B_2_1] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    amountTotal: '',
    vatTotal: '',
  });
  const [paymentType_4_B_2_2, setPaymentType_4_B_2_2] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    amountTotal: '',
    vatTotal: '',
  });
  const [paymentType_4_B_2_3, setPaymentType_4_B_2_3] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    amountTotal: '',
    vatTotal: '',
  });
  const [paymentType_4_B_2_4, setPaymentType_4_B_2_4] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    amountTotal: '',
    vatTotal: '',
  });
  const [paymentType_4_B_2_5, setPaymentType_4_B_2_5] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    remark: '',
    amountTotal: '',
    vatTotal: '',
  });

  const [paymentType_5, setPaymentType_5] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    amountTotal: '',
    vatTotal: '',
  });
  const [paymentType_6, setPaymentType_6] = useState({
    date: '',
    dateBE: '',
    dateCE: '',
    remark: '',
    amountTotal: '',
    vatTotal: '',
  });

  const [amountTotal, setAmountTotal] = useState(0);
  const [vatTotal, setVatTotal] = useState(0);
  const [vatTotalText, setVatTotalText] = useState('');

  const [paymentAmount, setPaymentAmount] = useState({
    privateTeacherAidFund: '',
    providentFund: '',
    socialFund: '',
  });

  const [payment_type_1, setPayment_type_1] = useState(true);
  const [payment_type_2, setPayment_type_2] = useState(false);
  const [payment_type_3, setPayment_type_3] = useState(false);
  const [payment_type_4, setPayment_type_4] = useState(false);
  const [otherPayment, setOtherPayment] = useState('');

  const [date, setDate] = useState(new Date().toISOString());
  const [dateBE, setDateBE] = useState(onFormatDate(new Date(), 'BE'));
  const [dateCE, setDateCE] = useState(onFormatDate(new Date(), 'CE'));

  const [email, setEmail] = useState('');

  const [file, setFile] = useState();
  const [signature_base64, setSignature_base64] = useState();
  const [seal_file, setSeal_file] = useState();
  const [seal_base64, setSeal_base64] = useState();

  const [disable, setDisable] = useState(true);

  const [withholdingTaxNumberError, setWithholdingTaxNumberError] = useState(false);
  const [paymentTypeError, setPaymentTypeError] = useState(false);
  const [formTypeError, setFormTypeError] = useState(false);
  const [payment_TypeError, setPayment_TypeError] = useState(false);

  const [contactList, setContactList] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showSuggestionsTaxId, setShowSuggestionsTaxId] = useState(false);

  const [backUrl, setBackUrl] = useState('/etax/documents/all');

  const companyId = sessionStorage.getItem('companyId') || '';

  const DayPickerStrings = {
    months: [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ],

    shortMonths: [
      t('documents.shortMonths01'),
      t('documents.shortMonths02'),
      t('documents.shortMonths03'),
      t('documents.shortMonths04'),
      t('documents.shortMonths05'),
      t('documents.shortMonths06'),
      t('documents.shortMonths07'),
      t('documents.shortMonths08'),
      t('documents.shortMonths09'),
      t('documents.shortMonths10'),
      t('documents.shortMonths11'),
      t('documents.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('quotation.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('quotation.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  useEffect(() => {
    let suggestions = contactList.filter((contact) => contact.name.includes(withholdingTaxer));

    setSuggestionList(suggestions);
  }, [withholdingTaxer, contactList]);

  useEffect(() => {
    let taxId =
      withholdingTaxId.zero +
      withholdingTaxId.one +
      withholdingTaxId.two +
      withholdingTaxId.three +
      withholdingTaxId.four +
      withholdingTaxId.five +
      withholdingTaxId.six +
      withholdingTaxId.seven +
      withholdingTaxId.eight +
      withholdingTaxId.nine +
      withholdingTaxId.ten +
      withholdingTaxId.eleven +
      withholdingTaxId.twelve;

    let suggestions = contactList.filter((contact) => contact.taxId.includes(taxId));

    setSuggestionList(suggestions);
  }, [withholdingTaxId, contactList]);

  useEffect(() => {
    async function getContact() {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/contacts', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            })
            .then((response) => {
              setContactList(response.data);
            });
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    getContact();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        //Check documents spending limit
/* 
        axios
          .get(API_URL + '/companies-details', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
          })
          .then(
            (response) => {
              console.log('Companies details data: ', response);

              if (response.data) {
                if (
                  response.data &&
                  response.data.balances &&
                  response.data.balances.documents &&
                  response.data.balances.documents.credits !== undefined &&
                  response.data.balances.documents.credits !== null
                ) {
                  console.log('Credit documents: ', response.data.balances.documents.credits);

                  let _documents = response.data.balances.documents.credits;

                  if (_documents <= 0) {
                    history.push({
                      pathname: '/billings',
                      state: {},
                    });
                  }
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );
 */

        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  setCompany(_company);
                  setCompanyName(_company.name);
                  setCompanyTaxId(_company.taxId);
                  setCompanyBranchText(_company.branchText);
                } else {
                  setCompany(response.data[0]);
                  setCompanyName(response.data[0].name);
                  setCompanyTaxId(response.data[0].taxId);
                  setCompanyBranchText(response.data[0].branchText);
                }
                
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (company.taxId) {
      setCompanyAllAddress(
        company.buildingNumber +
          ' ' +
          (!/^-*$/.test(company.address) && company.address.trim() && !/^-*$/.test(company.address.trim()) ? company.address + ' ' : '') +
          (!/^-*$/.test(company.streetName) && company.streetName.trim() && !/^-*$/.test(company.streetName.trim())
            ? company.streetPrefix + company.streetName + ' '
            : '') +
          company.subDistrictPrefix +
          company.subDistrictName +
          ' ' +
          company.districtPrefix +
          company.districtName +
          ' ' +
          company.provincePrefix +
          company.provinceName +
          ' ' +
          company.postcode
      );
    }
  }, [company]);

  useEffect(() => {
    console.log('useEffect data call!: ', data);
    if (data !== undefined) {
      if (data.command === 'Contact') {
        setWithholdingTaxer(data.customer.name);

        if (data.customer.unstructure) {
          let _customerAddress = '';

          if (data.customer.addressLineOne) _customerAddress = _customerAddress + data.customer.addressLineOne;
          if (data.customer.addressLineTwo) _customerAddress = _customerAddress + ' ' + data.customer.addressLineTwo;
          if (data.customer.postcode) _customerAddress = _customerAddress + ' ' + data.customer.postcode;
          if (data.customer.countryCode !== 'TH') _customerAddress = _customerAddress + ' ' + data.customer.countryName;

          setWithholdingAddress(_customerAddress);
        } else {
          let _customerAddress = '';

          if (data.customer.buildingNumber) _customerAddress = _customerAddress + data.customer.buildingNumber;
          if (data.customer.address) _customerAddress = _customerAddress + ' ' + data.customer.address;
          if (data.customer.streetName) _customerAddress = _customerAddress + ' ' + data.customer.streetPrefix + data.customer.streetName;
          if (data.customer.subDistrictName)
            _customerAddress =
              _customerAddress +
              ' ' +
              (data.customer.subDistrictPrefix === 'เขต' ? 'เขต ' : data.customer.subDistrictPrefix) +
              data.customer.subDistrictName;
          if (data.customer.districtName)
            _customerAddress =
              _customerAddress +
              ' ' +
              (data.customer.districtPrefix === 'แขวง' ? 'แขวง ' : data.customer.districtPrefix) +
              data.customer.districtName;
          if (data.customer.provinceName)
            _customerAddress =
              _customerAddress + ' ' + (data.customer.provincePrefix ? data.customer.provincePrefix : '') + data.customer.provinceName;
          if (data.customer.postcode) _customerAddress = _customerAddress + ' ' + data.customer.postcode;

          setWithholdingAddress(_customerAddress);
        }

        setEmail(data.customer.email);

        setWithholdingTaxId((previousState) => ({
          ...previousState,
          zero: data.customer.taxId[0],
          one: data.customer.taxId[1],
          two: data.customer.taxId[2],
          three: data.customer.taxId[3],
          four: data.customer.taxId[4],
          five: data.customer.taxId[5],
          six: data.customer.taxId[6],
          seven: data.customer.taxId[7],
          eight: data.customer.taxId[8],
          nine: data.customer.taxId[9],
          ten: data.customer.taxId[10],
          eleven: data.customer.taxId[11],
          twelve: data.customer.taxId[12],
        }));

        if (data.backUrl) {
          setBackUrl(data.backUrl);
        }
      } else if (data.command === 'ExpenseDocuments') {
        setReferExpenseNote(data);

        setWithholdingTaxer(data.customer.name);

        if (data.customer.unstructure) {
          let _customerAddress = '';

          if (data.customer.addressLineOne) _customerAddress = _customerAddress + data.customer.addressLineOne;
          if (data.customer.addressLineTwo) _customerAddress = _customerAddress + ' ' + data.customer.addressLineTwo;
          if (data.customer.postcode) _customerAddress = _customerAddress + ' ' + data.customer.postcode;
          if (data.customer.countryCode !== 'TH') _customerAddress = _customerAddress + ' ' + data.customer.countryName;

          setWithholdingAddress(_customerAddress);
        } else {
          let _customerAddress = '';

          if (data.customer.buildingNumber) _customerAddress = _customerAddress + data.customer.buildingNumber;
          if (data.customer.address) _customerAddress = _customerAddress + ' ' + data.customer.address;
          if (data.customer.streetName) _customerAddress = _customerAddress + ' ' + data.customer.streetPrefix + data.customer.streetName;
          if (data.customer.subDistrictName)
            _customerAddress =
              _customerAddress +
              ' ' +
              (data.customer.subDistrictPrefix === 'เขต' ? 'เขต ' : data.customer.subDistrictPrefix) +
              data.customer.subDistrictName;
          if (data.customer.districtName)
            _customerAddress =
              _customerAddress +
              ' ' +
              (data.customer.districtPrefix === 'แขวง' ? 'แขวง ' : data.customer.districtPrefix) +
              data.customer.districtName;
          if (data.customer.provinceName)
            _customerAddress =
              _customerAddress + ' ' + (data.customer.provincePrefix ? data.customer.provincePrefix : '') + data.customer.provinceName;
          if (data.customer.postcode) _customerAddress = _customerAddress + ' ' + data.customer.postcode;

          setWithholdingAddress(_customerAddress);
        }

        setEmail(data.customer.email);

        setWithholdingTaxId((previousState) => ({
          ...previousState,
          zero: data.customer.taxId[0],
          one: data.customer.taxId[1],
          two: data.customer.taxId[2],
          three: data.customer.taxId[3],
          four: data.customer.taxId[4],
          five: data.customer.taxId[5],
          six: data.customer.taxId[6],
          seven: data.customer.taxId[7],
          eight: data.customer.taxId[8],
          nine: data.customer.taxId[9],
          ten: data.customer.taxId[10],
          eleven: data.customer.taxId[11],
          twelve: data.customer.taxId[12],
        }));

        if (data.backUrl) {
          setBackUrl(data.backUrl);
        }

        if (data.customer.taxNumberType === 'TXID') {
          setFormType_7(true);
        } else if (data.customer.taxNumberType === 'NIDN') {
          const itemTypeList = data.items.find((item) => item.whtIncomeCode === '397' || item.whtIncomeCode === '398');

          if (itemTypeList) {
            setFormType_3(true);
          } else {
            setFormType_4(true);
          }
        }

        let _date = data.date;

        if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/.test(data.date)) {
          _date = new Date(data.date).toISOString();
        }

        data.items.forEach((item) => {
          if (item.whtTotal > 0) {
            if (item.whtIncomeCode === '397') {
              setPaymentType_4_A((prev) => ({
                date: _date,
                dateBE: data.dateBE,
                dateCE: data.dateCE,
                amountTotal: Number(prev.amountTotal) + item.total,
                vatTotal: Number(prev.vatTotal) + item.whtTotal,
              }));
            } else if (item.whtIncomeCode === '398') {
              setPaymentType_4_B_1_4((prev) => ({
                date: _date,
                dateBE: data.dateBE,
                dateCE: data.dateCE,
                percentVat: item.percentWht,
                amountTotal: Number(prev.amountTotal) + item.total,
                vatTotal: Number(prev.vatTotal) + item.whtTotal,
              }));
            } else if (
              item.whtIncomeCode === '390' ||
              item.whtIncomeCode === '391' ||
              item.whtIncomeCode === '392' ||
              item.whtIncomeCode === '393' ||
              item.whtIncomeCode === '394' ||
              item.whtIncomeCode === '395'
            ) {
              setPaymentType_1((prev) => ({
                date: _date,
                dateBE: data.dateBE,
                dateCE: data.dateCE,
                amountTotal: Number(prev.amountTotal) + item.total,
                vatTotal: Number(prev.vatTotal) + item.whtTotal,
              }));
            } else if (item.whtIncomeCode === '396') {
              setPaymentType_2((prev) => ({
                date: _date,
                dateBE: data.dateBE,
                dateCE: data.dateCE,
                amountTotal: Number(prev.amountTotal) + item.total,
                vatTotal: Number(prev.vatTotal) + item.whtTotal,
              }));
            } else if (
              item.whtIncomeCode === '408' ||
              item.whtIncomeCode === '407' ||
              item.whtIncomeCode === '406' ||
              item.whtIncomeCode === '405' ||
              item.whtIncomeCode === '404' ||
              item.whtIncomeCode === '403' ||
              item.whtIncomeCode === '402' ||
              item.whtIncomeCode === '401' ||
              item.whtIncomeCode === '400' ||
              item.whtIncomeCode === '399'
            ) {
              setPaymentType_5((prev) => ({
                date: _date,
                dateBE: data.dateBE,
                dateCE: data.dateCE,
                amountTotal: Number(prev.amountTotal) + item.total,
                vatTotal: Number(prev.vatTotal) + item.whtTotal,
              }));
            } else if (item.whtIncomeCode === '999') {
              setPaymentType_6((prev) => ({
                date: _date,
                dateBE: data.dateBE,
                dateCE: data.dateCE,
                remark: item.description,
                amountTotal: Number(prev.amountTotal) + item.total,
                vatTotal: Number(prev.vatTotal) + item.whtTotal,
              }));
            }
          }
        });

        setDate(data.date);
        setDateBE(data.dateBE);
        setDateCE(data.dateCE);
      } else {
        setReferExpenseNote(data.referExpenseNote);

        setCompany(data.company);
        setBookNumber(data.bookNumber);
        setNumberForm(data.number);
        setCompanyTaxId(data.company.taxIdForm);
        setCompanyName(data.company.name);
        setCompanyAllAddress(data.company.addressLineOne);

        setWithholdingTaxId((previousState) => ({
          ...previousState,
          zero: data.customer.taxIdForm.zero,
          one: data.customer.taxIdForm.one,
          two: data.customer.taxIdForm.two,
          three: data.customer.taxIdForm.three,
          four: data.customer.taxIdForm.four,
          five: data.customer.taxIdForm.five,
          six: data.customer.taxIdForm.six,
          seven: data.customer.taxIdForm.seven,
          eight: data.customer.taxIdForm.eight,
          nine: data.customer.taxIdForm.nine,
          ten: data.customer.taxIdForm.ten,
          eleven: data.customer.taxIdForm.eleven,
          twelve: data.customer.taxIdForm.twelve,
        }));

        if (data.customer.taxId10Form) {
          setWithholdingTaxId_10((previousState) => ({
            ...previousState,
            zero: data.customer.taxId10Form.zero,
            one: data.customer.taxId10Form.one,
            two: data.customer.taxId10Form.two,
            three: data.customer.taxId10Form.three,
            four: data.customer.taxId10Form.four,
            five: data.customer.taxId10Form.five,
            six: data.customer.taxId10Form.six,
            seven: data.customer.taxId10Form.seven,
            eight: data.customer.taxId10Form.eight,
            nine: data.customer.taxId10Form.nine,
          }));
        }
        
        setWithholdingTaxer(data.customer.name);

        setWithholdingAddress(data.customer.addressLineOne);
        setEmail(data.customer.email);

        setOrderNumber(data.orderNumber);
        setFormType_1(data.formType_1);
        setFormType_2(data.formType_2);
        setFormType_3(data.formType_3);
        setFormType_4(data.formType_4);
        setFormType_5(data.formType_5);
        setFormType_6(data.formType_6);
        setFormType_7(data.formType_7);
        setPaymentType_1((previousState) => ({
          ...previousState,
          date: data.paymentType_1.date,
          dateBE: data.paymentType_1.dateBE,
          dateCE: data.paymentType_1.dateCE,
          amountTotal: data.paymentType_1.amountTotal,
          vatTotal: data.paymentType_1.vatTotal,
        }));
        setPaymentType_2((previousState) => ({
          ...previousState,
          date: data.paymentType_2.date,
          dateBE: data.paymentType_2.dateBE,
          dateCE: data.paymentType_2.dateCE,
          amountTotal: data.paymentType_2.amountTotal,
          vatTotal: data.paymentType_2.vatTotal,
        }));
        setPaymentType_3((previousState) => ({
          ...previousState,
          date: data.paymentType_3.date,
          dateBE: data.paymentType_3.dateBE,
          dateCE: data.paymentType_3.dateCE,
          amountTotal: data.paymentType_3.amountTotal,
          vatTotal: data.paymentType_3.vatTotal,
        }));
        setPaymentType_4_A((previousState) => ({
          ...previousState,
          date: data.paymentType_4_A.date,
          dateBE: data.paymentType_4_A.dateBE,
          dateCE: data.paymentType_4_A.dateCE,
          amountTotal: data.paymentType_4_A.amountTotal,
          vatTotal: data.paymentType_4_A.vatTotal,
        }));
        setPaymentType_4_B_1_1((previousState) => ({
          ...previousState,
          date: data.paymentType_4_B_1_1.date,
          dateBE: data.paymentType_4_B_1_1.dateBE,
          dateCE: data.paymentType_4_B_1_1.dateCE,
          amountTotal: data.paymentType_4_B_1_1.amountTotal,
          vatTotal: data.paymentType_4_B_1_1.vatTotal,
        }));
        setPaymentType_4_B_1_2((previousState) => ({
          ...previousState,
          date: data.paymentType_4_B_1_2.date,
          dateBE: data.paymentType_4_B_1_2.dateBE,
          dateCE: data.paymentType_4_B_1_2.dateCE,
          amountTotal: data.paymentType_4_B_1_2.amountTotal,
          vatTotal: data.paymentType_4_B_1_2.vatTotal,
        }));
        setPaymentType_4_B_1_3((previousState) => ({
          ...previousState,
          date: data.paymentType_4_B_1_3.date,
          dateBE: data.paymentType_4_B_1_3.dateBE,
          dateCE: data.paymentType_4_B_1_3.dateCE,
          amountTotal: data.paymentType_4_B_1_3.amountTotal,
          vatTotal: data.paymentType_4_B_1_3.vatTotal,
        }));
        setPaymentType_4_B_1_4((previousState) => ({
          ...previousState,
          date: data.paymentType_4_B_1_4.date,
          dateBE: data.paymentType_4_B_1_4.dateBE,
          dateCE: data.paymentType_4_B_1_4.dateCE,
          amountTotal: data.paymentType_4_B_1_4.amountTotal,
          vatTotal: data.paymentType_4_B_1_4.vatTotal,
          percentVat: data.paymentType_4_B_1_4.percentVat,
        }));
        setPaymentType_4_B_2_1((previousState) => ({
          ...previousState,
          date: data.paymentType_4_B_2_1.date,
          dateBE: data.paymentType_4_B_2_1.dateBE,
          dateCE: data.paymentType_4_B_2_1.dateCE,
          amountTotal: data.paymentType_4_B_2_1.amountTotal,
          vatTotal: data.paymentType_4_B_2_1.vatTotal,
        }));
        setPaymentType_4_B_2_2((previousState) => ({
          ...previousState,
          date: data.paymentType_4_B_2_2.date,
          dateBE: data.paymentType_4_B_2_2.dateBE,
          dateCE: data.paymentType_4_B_2_2.dateCE,
          amountTotal: data.paymentType_4_B_2_2.amountTotal,
          vatTotal: data.paymentType_4_B_2_2.vatTotal,
        }));
        setPaymentType_4_B_2_3((previousState) => ({
          ...previousState,
          date: data.paymentType_4_B_2_3.date,
          dateBE: data.paymentType_4_B_2_3.dateBE,
          dateCE: data.paymentType_4_B_2_3.dateCE,
          amountTotal: data.paymentType_4_B_2_3.amountTotal,
          vatTotal: data.paymentType_4_B_2_3.vatTotal,
        }));
        setPaymentType_4_B_2_4((previousState) => ({
          ...previousState,
          date: data.paymentType_4_B_2_4.date,
          dateBE: data.paymentType_4_B_2_4.dateBE,
          dateCE: data.paymentType_4_B_2_4.dateCE,
          amountTotal: data.paymentType_4_B_2_4.amountTotal,
          vatTotal: data.paymentType_4_B_2_4.vatTotal,
        }));
        setPaymentType_4_B_2_5((previousState) => ({
          ...previousState,
          date: data.paymentType_4_B_2_5.date,
          dateBE: data.paymentType_4_B_2_5.dateBE,
          dateCE: data.paymentType_4_B_2_5.dateCE,
          amountTotal: data.paymentType_4_B_2_5.amountTotal,
          vatTotal: data.paymentType_4_B_2_5.vatTotal,
          remark: data.paymentType_4_B_2_5.remark,
        }));
        setPaymentType_5((previousState) => ({
          ...previousState,
          date: data.paymentType_5.date,
          dateBE: data.paymentType_5.dateBE,
          dateCE: data.paymentType_5.dateCE,
          amountTotal: data.paymentType_5.amountTotal,
          vatTotal: data.paymentType_5.vatTotal,
        }));
        setPaymentType_6((previousState) => ({
          ...previousState,
          date: data.paymentType_6.date,
          dateBE: data.paymentType_6.dateBE,
          dateCE: data.paymentType_6.dateCE,
          amountTotal: data.paymentType_6.amountTotal,
          vatTotal: data.paymentType_6.vatTotal,
          remark: data.paymentType_6.remark,
        }));
        setAmountTotal(data.amountTotal);
        setPaymentAmount((previousState) => ({
          ...previousState,
          privateTeacherAidFund: data.paymentAmount.privateTeacherAidFund,
          providentFund: data.paymentAmount.providentFund,
          socialFund: data.paymentAmount.socialFund,
        }));
        setVatTotal(data.vatTotal);
        setVatTotalText(data.vatTotalText);
        setPayment_type_1(data.payment_type_1);
        setPayment_type_2(data.payment_type_2);
        setPayment_type_3(data.payment_type_3);
        setPayment_type_4(data.payment_type_4);
        setOtherPayment(data.otherPayment);
        setDate(data.date);
        setDateBE(data.dateBE);

        if (data.backUrl) {
          setBackUrl(data.backUrl);
        }

        if (data.payment_type_4) {
          setDisable(true);
        }

        if (data.signature) {
          setFile(data.signature)
          setSignature_base64(data.signature)
        }

        if (data.seal) {
          setSeal_file(data.seal)
          setSeal_base64(data.seal)
        }
      }
    }
  }, [data]);

  const handleOnChange = (e) => {
    e.preventDefault();

    if (e.target.id === 'bookNumber') {
      setBookNumber(e.target.value);
    } else if (e.target.id === 'numberForm') {
      setNumberForm(e.target.value);
    } else if (e.target.id === 'withholdingTaxer') {
      setWithholdingTaxer(e.target.value);
    } else if (e.target.id === 'withholdingAddress') {
      setWithholdingAddress(e.target.value);
    } else if (e.target.id === 'orderNumber') {
      setOrderNumber(e.target.value);
    } else if (e.target.id.includes('paymentAmount')) {
      if (e.target.id.split('_')[1] === 'privateTeacherAidFund') {

        setPaymentAmount((previousState) => ({
          ...previousState,
          privateTeacherAidFund: e.target.value ? Number(e.target.value) : "",
        }));
      } else if (e.target.id.split('_')[1] === 'providentFund') {

        setPaymentAmount((previousState) => ({
          ...previousState,
          providentFund: e.target.value ? Number(e.target.value) : "",
        }));
      }
      if (e.target.id.split('_')[1] === 'socialFund') {

        setPaymentAmount((previousState) => ({
          ...previousState,
          socialFund: e.target.value ? Number(e.target.value) : "",
        }));
      }
    } else if (e.target.id === 'otherPayment') {
      setOtherPayment(e.target.value);
    }
  };

  useEffect(() => {
    let _amountTotal = 0;
    let _vatTotal = 0;

    let payment_array = [
      paymentType_1,
      paymentType_2,
      paymentType_3,
      paymentType_4_A,
      paymentType_4_B_1_1,
      paymentType_4_B_1_2,
      paymentType_4_B_1_3,
      paymentType_4_B_1_4,
      paymentType_4_B_2_1,
      paymentType_4_B_2_2,
      paymentType_4_B_2_3,
      paymentType_4_B_2_4,
      paymentType_4_B_2_5,
      paymentType_5,
      paymentType_6,
    ];

    payment_array.forEach((paymentType) => {
      _amountTotal += paymentType.amountTotal !== '' ? Number(paymentType.amountTotal) : 0;
      _vatTotal += paymentType.vatTotal !== '' ? Number(paymentType.vatTotal) : 0;
    });

    setAmountTotal(_amountTotal);
    setVatTotal(_vatTotal);
    setVatTotalText(numberToTextTh(_vatTotal));
  }, [
    paymentType_1,
    paymentType_2,
    paymentType_3,
    paymentType_4_A,
    paymentType_4_B_1_1,
    paymentType_4_B_1_2,
    paymentType_4_B_1_3,
    paymentType_4_B_1_4,
    paymentType_4_B_2_1,
    paymentType_4_B_2_2,
    paymentType_4_B_2_3,
    paymentType_4_B_2_4,
    paymentType_4_B_2_5,
    paymentType_5,
    paymentType_6,
  ]);

  useEffect(() => {
    if (formType_1) {
      setFormType_2(false);
      setFormType_3(false);
      setFormType_4(false);
      setFormType_5(false);
      setFormType_6(false);
      setFormType_7(false);
    }
  }, [formType_1]);

  useEffect(() => {
    if (formType_2) {
      setFormType_1(false);
      setFormType_3(false);
      setFormType_4(false);
      setFormType_5(false);
      setFormType_6(false);
      setFormType_7(false);
    }
  }, [formType_2]);

  useEffect(() => {
    if (formType_3) {
      setFormType_1(false);
      setFormType_2(false);
      setFormType_4(false);
      setFormType_5(false);
      setFormType_6(false);
      setFormType_7(false);
    }
  }, [formType_3]);

  useEffect(() => {
    if (formType_4) {
      setFormType_1(false);
      setFormType_2(false);
      setFormType_3(false);
      setFormType_5(false);
      setFormType_6(false);
      setFormType_7(false);
    }
  }, [formType_4]);

  useEffect(() => {
    if (formType_5) {
      setFormType_1(false);
      setFormType_2(false);
      setFormType_3(false);
      setFormType_4(false);
      setFormType_6(false);
      setFormType_7(false);
    }
  }, [formType_5]);

  useEffect(() => {
    if (formType_6) {
      setFormType_1(false);
      setFormType_2(false);
      setFormType_3(false);
      setFormType_4(false);
      setFormType_5(false);
      setFormType_7(false);
    }
  }, [formType_6]);

  useEffect(() => {
    if (formType_7) {
      setFormType_1(false);
      setFormType_2(false);
      setFormType_3(false);
      setFormType_4(false);
      setFormType_5(false);
      setFormType_6(false);
    }
  }, [formType_7]);

  useEffect(() => {
    if (payment_type_1) {
      setPayment_type_2(false);
      setPayment_type_3(false);
      setPayment_type_4(false);
    }
  }, [payment_type_1]);

  useEffect(() => {
    if (payment_type_2) {
      setPayment_type_1(false);
      setPayment_type_3(false);
      setPayment_type_4(false);
    }
  }, [payment_type_2]);

  useEffect(() => {
    if (payment_type_3) {
      setPayment_type_1(false);
      setPayment_type_2(false);
      setPayment_type_4(false);
    }
  }, [payment_type_3]);

  useEffect(() => {
    if (payment_type_4) {
      setPayment_type_1(false);
      setPayment_type_2(false);
      setPayment_type_3(false);
    }
    setDisable(!payment_type_4);
  }, [payment_type_4]);

  useEffect(() => {
    if (disable) {
      setOtherPayment('');
    }
  }, [disable]);

  const handleCancel = (e) => {
    history.push({
      pathname: backUrl,
      state: {},
    });
  };

  const handleOnChange_taxpayer_13 = (e) => {
    let index = Number(e.target.id);

    if (e.target.value !== '') {
      if (index !== 12) {
        inputId_taxpayer_13[index + 1].focus();
      }
    } else {
      if (index !== 0) {
        inputId_taxpayer_13[index - 1].focus();
      }
    }
  };

  const handleOnChange_other_13 = (e) => {
    setShowSuggestionsTaxId(true);
    setWithholdingTaxNumberError(false);

    const _withholdingTax = e.target.value;

    setWithholdingTaxId((prev) => ({
      ...prev,
      zero: _withholdingTax[0] ? _withholdingTax[0] : '',
      one: _withholdingTax[1] ? _withholdingTax[1] : '',
      two: _withholdingTax[2] ? _withholdingTax[2] : '',
      three: _withholdingTax[3] ? _withholdingTax[3] : '',
      four: _withholdingTax[4] ? _withholdingTax[4] : '',
      five: _withholdingTax[5] ? _withholdingTax[5] : '',
      six: _withholdingTax[6] ? _withholdingTax[6] : '',
      seven: _withholdingTax[7] ? _withholdingTax[7] : '',
      eight: _withholdingTax[8] ? _withholdingTax[8] : '',
      nine: _withholdingTax[9] ? _withholdingTax[9] : '',
      ten: _withholdingTax[10] ? _withholdingTax[10] : '',
      eleven: _withholdingTax[11] ? _withholdingTax[11] : '',
      twelve: _withholdingTax[12] ? _withholdingTax[12] : '',
    }));
  };

  const handleOnChange_other_10 = (e) => {
    const _withholdingTax = e.target.value;

    setWithholdingTaxId_10((prev) => ({
      ...prev,
      zero: _withholdingTax[0] ? _withholdingTax[0] : '',
      one: _withholdingTax[1] ? _withholdingTax[1] : '',
      two: _withholdingTax[2] ? _withholdingTax[2] : '',
      three: _withholdingTax[3] ? _withholdingTax[3] : '',
      four: _withholdingTax[4] ? _withholdingTax[4] : '',
      five: _withholdingTax[5] ? _withholdingTax[5] : '',
      six: _withholdingTax[6] ? _withholdingTax[6] : '',
      seven: _withholdingTax[7] ? _withholdingTax[7] : '',
      eight: _withholdingTax[8] ? _withholdingTax[8] : '',
      nine: _withholdingTax[9] ? _withholdingTax[9] : '',
    }));
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleUploadPic = async (e) => {
    if (e.target.id === 'signature') {
      setFile(URL.createObjectURL(e.target.files[0]));
      setSignature_base64(await getBase64(e.target.files[0]));
    } else if (e.target.id === 'seal') {
      setSeal_file(URL.createObjectURL(e.target.files[0]));
      setSeal_base64(await getBase64(e.target.files[0]));
    }
  };

  const stackItemStyles = {
    root: {
      display: 'flex',
      justifyContent: 'center',
      border: '2px solid',
      borderRadius: '5px',
    },
  };

  const getTaxId = () => {
    let taxId = {
      zero: companyTaxId[0],
      one: companyTaxId[1],
      two: companyTaxId[2],
      three: companyTaxId[3],
      four: companyTaxId[4],
      five: companyTaxId[5],
      six: companyTaxId[6],
      seven: companyTaxId[7],
      eight: companyTaxId[8],
      nine: companyTaxId[9],
      ten: companyTaxId[10],
      eleven: companyTaxId[11],
      twelve: companyTaxId[12],
    };
    let witholder_taxId = withholdingTaxId;

    let taxId_10 = {
      zero: '',
      one: '',
      two: '',
      three: '',
      four: '',
      five: '',
      six: '',
      seven: '',
      eight: '',
      nine: '',
    };
    let witholder_taxId_10 = withholdingTaxId_10;

    return [taxId, witholder_taxId, witholder_taxId_10, taxId_10];
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let isCorrect = true;

    if (getWithholdingTaxNumberError()) {
      isCorrect = false;
      setWithholdingTaxNumberError(true);
    }

    if (getPaymentTypeError()) {
      isCorrect = false;
      setPaymentTypeError(true);
    }

    if (getFormTypeError()) {
      isCorrect = false;
      setFormTypeError(true);
    }

    if (getPayment_typeError()) {
      isCorrect = false;
      setPayment_TypeError(true);
    }

    if (isCorrect) {
      let [taxId, witholder_taxId, witholder_taxId_10] = getTaxId();

      let _company = company;
      _company.taxIdForm = taxId;

      let _customerTaxId = '';
      if (witholder_taxId) {
        _customerTaxId =
          witholder_taxId.zero +
          witholder_taxId.one +
          witholder_taxId.two +
          witholder_taxId.three +
          witholder_taxId.four +
          witholder_taxId.five +
          witholder_taxId.six +
          witholder_taxId.seven +
          witholder_taxId.eight +
          witholder_taxId.nine +
          witholder_taxId.ten +
          witholder_taxId.eleven +
          witholder_taxId.twelve;
      }

      let _customer = {
        name: withholdingTaxer,
        taxId: _customerTaxId,
        taxIdForm: witholder_taxId,
        taxId10Form: witholder_taxId_10,
        addressLineOne: withholdingAddress,
        email: email,
      };

      history.push({
        pathname: '/documents/withholding-taxs/confirm',
        state: {
          bookNumber: bookNumber,
          number: numberForm,
          company: _company,
          customer: _customer,
          orderNumber: orderNumber,
          formType_1: formType_1,
          formType_2: formType_2,
          formType_3: formType_3,
          formType_4: formType_4,
          formType_5: formType_5,
          formType_6: formType_6,
          formType_7: formType_7,
          paymentType_1: paymentType_1,
          paymentType_2: paymentType_2,
          paymentType_3: paymentType_3,
          paymentType_4_A: paymentType_4_A,
          paymentType_4_B_1_1: paymentType_4_B_1_1,
          paymentType_4_B_1_2: paymentType_4_B_1_2,
          paymentType_4_B_1_3: paymentType_4_B_1_3,
          paymentType_4_B_1_4: paymentType_4_B_1_4,
          paymentType_4_B_2_1: paymentType_4_B_2_1,
          paymentType_4_B_2_2: paymentType_4_B_2_2,
          paymentType_4_B_2_3: paymentType_4_B_2_3,
          paymentType_4_B_2_4: paymentType_4_B_2_4,
          paymentType_4_B_2_5: paymentType_4_B_2_5,
          paymentType_5: paymentType_5,
          paymentType_6: paymentType_6,
          amountTotal: amountTotal,
          vatTotal: vatTotal,
          vatTotalText: vatTotalText,
          paymentAmount: paymentAmount,
          payment_type_1: payment_type_1,
          payment_type_2: payment_type_2,
          payment_type_3: payment_type_3,
          payment_type_4: payment_type_4,
          otherPayment: otherPayment,
          date: date,
          dateBE: dateBE,
          dateCE: dateCE,
          signature: signature_base64,
          seal: seal_base64,
          referExpenseNote: referExpenseNote,
          backUrl: backUrl,
        },
      });
    }
  };

  function isIsoDate(str) {
    if (!(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str) || /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/.test(str))) return false;
    var d = new Date(str);
    if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/.test(str)) {
      return d.toISOString() === new Date(str).toISOString();
    }
    return d.toISOString() === str;
  }

  function checkDateFormat(str) {
    let datesplit = str.split('/');
    if (
      datesplit[0].length === 2 &&
      !isNaN(datesplit[0]) &&
      datesplit[1].length === 2 &&
      !isNaN(datesplit[1]) &&
      datesplit[2].length === 4 &&
      !isNaN(datesplit[2])
    )
      return true;
    return false;
  }

  const getWithholdingTaxNumberError = () => {
    if (
      (withholdingTaxId.zero === '' ||
        withholdingTaxId.one === '' ||
        withholdingTaxId.two === '' ||
        withholdingTaxId.three === '' ||
        withholdingTaxId.four === '' ||
        withholdingTaxId.five === '' ||
        withholdingTaxId.six === '' ||
        withholdingTaxId.seven === '' ||
        withholdingTaxId.eight === '' ||
        withholdingTaxId.nine === '' ||
        withholdingTaxId.ten === '' ||
        withholdingTaxId.eleven === '' ||
        withholdingTaxId.twelve === '') &&
      (withholdingTaxId_10.zero === '' ||
        withholdingTaxId_10.one === '' ||
        withholdingTaxId_10.two === '' ||
        withholdingTaxId_10.three === '' ||
        withholdingTaxId_10.four === '' ||
        withholdingTaxId_10.five === '' ||
        withholdingTaxId_10.six === '' ||
        withholdingTaxId_10.seven === '' ||
        withholdingTaxId_10.eight === '' ||
        withholdingTaxId_10.nine === '')
    ) {
      return true;
    }
    return false;
  };

  const getFormTypeError = () => {
    if (!formType_1 && !formType_2 && !formType_3 && !formType_4 && !formType_5 && !formType_6 && !formType_7) {
      return true;
    }
    return false;
  };

  const getPayment_typeError = () => {
    if (!payment_type_1 && !payment_type_2 && !payment_type_3 && !payment_type_4) {
      return true;
    }
    return false;
  };

  const getPaymentTypeError = () => {
    if (
      paymentType_1.date === '' &&
      paymentType_1.amountTotal === '' &&
      paymentType_1.vatTotal === '' &&
      paymentType_2.date === '' &&
      paymentType_2.amountTotal === '' &&
      paymentType_2.vatTotal === '' &&
      paymentType_3.date === '' &&
      paymentType_3.amountTotal === '' &&
      paymentType_3.vatTotal === '' &&
      paymentType_4_A.date === '' &&
      paymentType_4_A.amountTotal === '' &&
      paymentType_4_A.vatTotal === '' &&
      paymentType_4_B_1_1.date === '' &&
      paymentType_4_B_1_1.amountTotal === '' &&
      paymentType_4_B_1_1.vatTotal === '' &&
      paymentType_4_B_1_2.date === '' &&
      paymentType_4_B_1_2.amountTotal === '' &&
      paymentType_4_B_1_2.vatTotal === '' &&
      paymentType_4_B_1_3.date === '' &&
      paymentType_4_B_1_3.amountTotal === '' &&
      paymentType_4_B_1_3.vatTotal === '' &&
      paymentType_4_B_1_4.date === '' &&
      paymentType_4_B_1_4.amountTotal === '' &&
      paymentType_4_B_1_4.vatTotal === '' &&
      paymentType_4_B_2_1.date === '' &&
      paymentType_4_B_2_1.amountTotal === '' &&
      paymentType_4_B_2_1.vatTotal === '' &&
      paymentType_4_B_2_2.date === '' &&
      paymentType_4_B_2_2.amountTotal === '' &&
      paymentType_4_B_2_2.vatTotal === '' &&
      paymentType_4_B_2_3.date === '' &&
      paymentType_4_B_2_3.amountTotal === '' &&
      paymentType_4_B_2_3.vatTotal === '' &&
      paymentType_4_B_2_4.date === '' &&
      paymentType_4_B_2_4.amountTotal === '' &&
      paymentType_4_B_2_4.vatTotal === '' &&
      paymentType_4_B_2_5.date === '' &&
      paymentType_4_B_2_5.amountTotal === '' &&
      paymentType_4_B_2_5.vatTotal === '' &&
      paymentType_5.date === '' &&
      paymentType_5.amountTotal === '' &&
      paymentType_5.vatTotal === '' &&
      paymentType_6.date === '' &&
      paymentType_6.amountTotal === '' &&
      paymentType_6.vatTotal === ''
    ) {
      return true;
    }
    return false;
  };

  function handleSelectSuggestions(e, suggestion) {
    setWithholdingTaxer(suggestion.name);
    setWithholdingTaxId({
      zero: suggestion.taxId[0],
      one: suggestion.taxId[1],
      two: suggestion.taxId[2],
      three: suggestion.taxId[3],
      four: suggestion.taxId[4],
      five: suggestion.taxId[5],
      six: suggestion.taxId[6],
      seven: suggestion.taxId[7],
      eight: suggestion.taxId[8],
      nine: suggestion.taxId[9],
      ten: suggestion.taxId[10],
      eleven: suggestion.taxId[11],
      twelve: suggestion.taxId[12],
    });

    if (suggestion.unstructure) {
      let _customerAddress = '';

      if (suggestion.addressLineOne) _customerAddress = _customerAddress + suggestion.addressLineOne;
      if (suggestion.addressLineTwo) _customerAddress = _customerAddress + ' ' + suggestion.addressLineTwo;
      if (suggestion.postcode) _customerAddress = _customerAddress + ' ' + suggestion.postcode;
      if (suggestion.countryCode !== 'TH') _customerAddress = _customerAddress + ' ' + suggestion.countryName;

      setWithholdingAddress(_customerAddress);
    } else {
      let _customerAddress = '';

      if (suggestion.buildingNumber) _customerAddress = _customerAddress + suggestion.buildingNumber;
      if (suggestion.address) _customerAddress = _customerAddress + ' ' + suggestion.address;
      if (suggestion.streetName) _customerAddress = _customerAddress + ' ' + suggestion.streetPrefix + suggestion.streetName;
      if (suggestion.subDistrictName)
        _customerAddress =
          _customerAddress + ' ' + (suggestion.subDistrictPrefix === 'เขต' ? 'เขต ' : suggestion.subDistrictPrefix) + suggestion.subDistrictName;
      if (suggestion.districtName)
        _customerAddress =
          _customerAddress + ' ' + (suggestion.districtPrefix === 'แขวง' ? 'แขวง ' : suggestion.districtPrefix) + suggestion.districtName;
      if (suggestion.provinceName)
        _customerAddress = _customerAddress + ' ' + (suggestion.provincePrefix ? suggestion.provincePrefix : '') + suggestion.provinceName;
      if (suggestion.postcode) _customerAddress = _customerAddress + ' ' + suggestion.postcode;

      setWithholdingAddress(_customerAddress);
    }

    setEmail(suggestion.email);
  }

  return (
    <form onSubmit={handleSubmit}>
      <h2 style={{ marginLeft: '0', marginTop: '25px' }}>{t('withholdingTax.header')}</h2>
      <Stack>
        <div
          style={{
            width: '1000px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 30,
            background: '#ffffff',
            marginLeft: '0px',
            marginTop: '0px',
            boxShadow: theme.effects.elevation4,
          }}
        >
          ฉบับที่ 1 (สําหรับผู้ถูกหักภาษีณที่จ่ายใช้แนบพร้อมกับแบบแสดงรายการภาษี)
          <br />
          ฉบับที่ 2 (สําหรับผู้ถูกหักภาษีณที่จ่ายเก็บไว้เป็นหลักฐาน)
          <Stack vertical styles={{ root: { border: '2px solid' } }}>
            <Stack horizontal horizontalAlign="space-between">
              <span></span>
              <Stack styles={{ root: { alignItems: 'center', marginTop: 10 } }}>
                <span style={{ marginLeft: 145, fontWeight: 'bold', fontSize: 24 }}>หนังสือรับรองการหักภาษี ณ ที่จ่าย</span>
                <span style={{ marginLeft: 145 }}>ตามมาตรา 50 ทวิ แห่งประมวลรัษฎากร</span>
              </Stack>

              <Stack vertical>
                <Stack horizontal tokens={{ childrenGap: 6 }}>
                  <Stack styles={{ root: { marginTop: 10, width: 32 } }}>เล่มที่</Stack>
                  <TextField id="bookNumber" value={bookNumber} onChange={(e) => handleOnChange(e)} styles={{ root: { width: 130 } }} />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 6 }}>
                  <Stack styles={{ root: { marginTop: 10, width: 32 } }}>เลขที่</Stack>
                  <TextField
                    id="numberForm"
                    value={numberForm}
                    required
                    onChange={(e) => handleOnChange(e)}
                    styles={{ root: { width: 130, marginRight: 10 } }}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Stack vertical styles={stackItemStyles}>
              <Stack horizontal horizontalAlign="space-between" style={{ marginTop: 10, fontWeight: 'bold' }}>
                <span>ผู้มีหน้าที่หักภาษี ณ ที่จ่าย:-</span>
                <Stack horizontal styles={{ root: { marginRight: 10 } }}>
                  <span style={{ marginRight: 10 }}>เลขประจําตัวผู้เสียภาษีอากร (13 หลัก)*</span>

                  <span>
                    <Stack horizontal>
                      <input
                        style={{ width: 10, border: '1px solid' }}
                        id={0}
                        readOnly
                        value={companyTaxId[0] ? companyTaxId[0] : ''}
                        ref={(input) => (inputId_taxpayer_13[0] = input)}
                        onChange={(e) => handleOnChange_taxpayer_13(e)}
                        type="text"
                        maxLength="1"
                      />
                      -
                      <input
                        style={{
                          width: 10,
                          border: '1px solid',
                          borderRight: '1px dotted',
                        }}
                        id={1}
                        readOnly
                        value={companyTaxId[1] ? companyTaxId[1] : ''}
                        ref={(input) => (inputId_taxpayer_13[1] = input)}
                        onChange={(e) => handleOnChange_taxpayer_13(e)}
                        type="text"
                        maxLength="1"
                      />
                      <input
                        style={{
                          width: 10,
                          border: '1px solid',
                          borderLeft: '1px dotted',
                          borderRight: '1px dotted',
                        }}
                        id={2}
                        readOnly
                        value={companyTaxId[2] ? companyTaxId[2] : ''}
                        ref={(input) => (inputId_taxpayer_13[2] = input)}
                        onChange={(e) => handleOnChange_taxpayer_13(e)}
                        type="text"
                        maxLength="1"
                      />
                      <input
                        style={{
                          width: 10,
                          border: '1px solid',
                          borderLeft: '1px dotted',
                          borderRight: '1px dotted',
                        }}
                        id={3}
                        readOnly
                        value={companyTaxId[3] ? companyTaxId[3] : ''}
                        ref={(input) => (inputId_taxpayer_13[3] = input)}
                        onChange={(e) => handleOnChange_taxpayer_13(e)}
                        type="text"
                        maxLength="1"
                      />
                      <input
                        style={{
                          width: 10,
                          border: '1px solid',
                          borderLeft: '1px dotted',
                        }}
                        id={4}
                        readOnly
                        value={companyTaxId[4] ? companyTaxId[4] : ''}
                        ref={(input) => (inputId_taxpayer_13[4] = input)}
                        onChange={(e) => handleOnChange_taxpayer_13(e)}
                        type="text"
                        maxLength="1"
                      />
                      -
                      <input
                        style={{
                          width: 10,
                          border: '1px solid',
                          borderRight: '1px dotted',
                        }}
                        id={5}
                        readOnly
                        value={companyTaxId[5] ? companyTaxId[5] : ''}
                        ref={(input) => (inputId_taxpayer_13[5] = input)}
                        onChange={(e) => handleOnChange_taxpayer_13(e)}
                        type="text"
                        maxLength="1"
                      />
                      <input
                        style={{
                          width: 10,
                          border: '1px solid',
                          borderLeft: '1px dotted',
                          borderRight: '1px dotted',
                        }}
                        id={6}
                        readOnly
                        value={companyTaxId[6] ? companyTaxId[6] : ''}
                        ref={(input) => (inputId_taxpayer_13[6] = input)}
                        onChange={(e) => handleOnChange_taxpayer_13(e)}
                        type="text"
                        maxLength="1"
                      />
                      <input
                        style={{
                          width: 10,
                          border: '1px solid',
                          borderLeft: '1px dotted',
                          borderRight: '1px dotted',
                        }}
                        id={7}
                        readOnly
                        value={companyTaxId[7] ? companyTaxId[7] : ''}
                        ref={(input) => (inputId_taxpayer_13[7] = input)}
                        onChange={(e) => handleOnChange_taxpayer_13(e)}
                        type="text"
                        maxLength="1"
                      />
                      <input
                        style={{
                          width: 10,
                          border: '1px solid',
                          borderLeft: '1px dotted',
                          borderRight: '1px dotted',
                        }}
                        id={8}
                        readOnly
                        value={companyTaxId[8] ? companyTaxId[8] : ''}
                        ref={(input) => (inputId_taxpayer_13[8] = input)}
                        onChange={(e) => handleOnChange_taxpayer_13(e)}
                        type="text"
                        maxLength="1"
                      />
                      <input
                        style={{
                          width: 10,
                          border: '1px solid',
                          borderLeft: '1px dotted',
                        }}
                        id={9}
                        readOnly
                        value={companyTaxId[9] ? companyTaxId[9] : ''}
                        ref={(input) => (inputId_taxpayer_13[9] = input)}
                        onChange={(e) => handleOnChange_taxpayer_13(e)}
                        type="text"
                        maxLength="1"
                      />
                      -
                      <input
                        style={{
                          width: 10,
                          border: '1px solid',
                          borderRight: '1px dotted',
                        }}
                        id={10}
                        readOnly
                        value={companyTaxId[10] ? companyTaxId[10] : ''}
                        ref={(input) => (inputId_taxpayer_13[10] = input)}
                        onChange={(e) => handleOnChange_taxpayer_13(e)}
                        type="text"
                        maxLength="1"
                      />
                      <input
                        style={{
                          width: 10,
                          border: '1px solid',
                          borderLeft: '1px dotted',
                        }}
                        id={11}
                        readOnly
                        value={companyTaxId[11] ? companyTaxId[11] : ''}
                        ref={(input) => (inputId_taxpayer_13[11] = input)}
                        onChange={(e) => handleOnChange_taxpayer_13(e)}
                        type="text"
                        maxLength="1"
                      />
                      -
                      <input
                        style={{ width: 10, border: '1px solid' }}
                        id={12}
                        readOnly
                        value={companyTaxId[12] ? companyTaxId[12] : ''}
                        ref={(input) => (inputId_taxpayer_13[12] = input)}
                        onChange={(e) => handleOnChange_taxpayer_13(e)}
                        type="text"
                        maxLength="1"
                      />
                    </Stack>
                  </span>
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack horizontal>
                  <span style={{ marginTop: 10, fontWeight: 'bold' }}>ชื่อ</span>
                  <TextField
                    borderless
                    readOnly
                    value={companyName ? companyName + ' (' + companyBranchText + ')' : ''}
                    styles={{ root: { width: 500 } }}
                    description="(ให้ระบุว่าเป็น บุคคล นิติบุคคล บริษัท สมาคม หรือคณะบุคคล)"
                  />
                </Stack>
              </Stack>
              <Stack horizontal>
                <span style={{ marginTop: 10, fontWeight: 'bold' }}>ที่อยู่</span>
                <TextField
                  borderless
                  readOnly
                  styles={{ root: { width: 600 } }}
                  value={companyName ? companyAllAddress : ''}
                  description="(ให้ระบุ ชื่ออาคาร/หมู่บ้าน ห้องเลขที่ ชั้นที่ เลขที่ ตรอก/ซอย หมู่ที่ ถนน ตำบล/แขวง อำเภอ/เขต จังหวัด)"
                />
              </Stack>
            </Stack>
            <Stack styles={stackItemStyles}>
              <Stack horizontal horizontalAlign="space-between" style={{ marginTop: 10, fontWeight: 'bold' }}>
                <span>ผู้ถูกหักภาษี ณ ที่จ่าย:-</span>
                <Stack horizontal>
                  <TextField
                    prefix="เลขประจําตัวผู้เสียภาษีอากร (13 หลัก)*"
                    onRenderInput={(props) => (
                      <div>
                        <TextField
                          id="withholdingTaxId"
                          styles={{ root: { width: 165 } }}
                          onChange={(e) => handleOnChange_other_13(e)}
                          value={
                            withholdingTaxId.zero +
                            withholdingTaxId.one +
                            withholdingTaxId.two +
                            withholdingTaxId.three +
                            withholdingTaxId.four +
                            withholdingTaxId.five +
                            withholdingTaxId.six +
                            withholdingTaxId.seven +
                            withholdingTaxId.eight +
                            withholdingTaxId.nine +
                            withholdingTaxId.ten +
                            withholdingTaxId.eleven +
                            withholdingTaxId.twelve
                          }
                          onBlur={() => {
                            setShowSuggestions(false);
                            setShowSuggestionsTaxId(false);
                          }}
                        />
                        {showSuggestionsTaxId && suggestionList[0] !== undefined && (
                          <div style={contactListStyle}>
                            {suggestionList.map((suggestion, index) => {
                              return (
                                <div
                                  key={index}
                                  id={suggestion.name}
                                  className={controlClass.hover}
                                  style={contactOptionStyle}
                                  onMouseDown={(e) => handleSelectSuggestions(e, suggestion)}
                                >
                                  {suggestion.taxId} {suggestion.name}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                    styles={{
                      prefix: { color: '#323130', background: 'none', fontSize: 14, fontWeight: 600 },
                      fieldGroup: { border: '0px black solid' },
                      root: { marginRight: 10 },
                    }}
                  />
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <span style={{ marginTop: 10, fontWeight: 'bold', width: 27 }}>ชื่อ</span>
                  <div>
                    <TextField
                      id="withholdingTaxer"
                      required
                      value={withholdingTaxer}
                      onChange={(e) => {
                        setWithholdingTaxer(e.target.value);
                        if (e.target.value) {
                          setShowSuggestions(true);
                        } else {
                          setShowSuggestions(false);
                        }
                      }}
                      styles={{ root: { width: 480 } }}
                      description="(ให้ระบุว่าเป็น บุคคล นิติบุคคล บริษัท สมาคม หรือคณะบุคคล)"
                      onBlur={() => {
                        setShowSuggestions(false);
                        setShowSuggestionsTaxId(false);
                      }}
                    />
                    {showSuggestions && suggestionList[0] !== undefined && (
                      <div style={contactListStyle}>
                        {suggestionList.map((suggestion, index) => {
                          return (
                            <div
                              key={index}
                              id={suggestion.name}
                              className={controlClass.hover}
                              style={contactOptionStyle}
                              onMouseDown={(e) => handleSelectSuggestions(e, suggestion)}
                            >
                              {suggestion.name} {suggestion.taxId}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </Stack>
                <Stack horizontal style={{ marginTop: 10, fontWeight: 'bold' }}>
                  <span>
                    <Stack horizontal>
                      <TextField
                        prefix="เลขประจําตัวผู้เสียภาษีอากร"
                        onRenderInput={(props) => (
                          <TextField
                            id="withholdingTaxId_10"
                            styles={{ root: { width: 165 } }}
                            onChange={(e) => handleOnChange_other_10(e)}
                            // defaultValue={() => {
                            //   const value =
                            //     withholdingTaxId_10.zero +
                            //     withholdingTaxId_10.one +
                            //     withholdingTaxId_10.two +
                            //     withholdingTaxId_10.three +
                            //     withholdingTaxId_10.four +
                            //     withholdingTaxId_10.five +
                            //     withholdingTaxId_10.six +
                            //     withholdingTaxId_10.seven +
                            //     withholdingTaxId_10.eight +
                            //     withholdingTaxId_10.nine;

                            //   return value;
                            // }}
                            value={
                              withholdingTaxId_10.zero +
                              withholdingTaxId_10.one +
                              withholdingTaxId_10.two +
                              withholdingTaxId_10.three +
                              withholdingTaxId_10.four +
                              withholdingTaxId_10.five +
                              withholdingTaxId_10.six +
                              withholdingTaxId_10.seven +
                              withholdingTaxId_10.eight +
                              withholdingTaxId_10.nine
                            }
                          />
                        )}
                        styles={{
                          prefix: { color: '#323130', background: 'none', fontSize: 14, fontWeight: 600 },
                          fieldGroup: { border: '0px black solid' },
                          root: { marginRight: 10 },
                        }}
                      />
                    </Stack>
                  </span>
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <span style={{ marginTop: 10, fontWeight: 'bold', width: 27 }}>ที่อยู่</span>
                  <TextField
                    id="withholdingAddress"
                    value={withholdingAddress}
                    required
                    onChange={(e) => handleOnChange(e)}
                    styles={{ root: { width: 580 } }}
                    description="(ให้ระบุ ชื่ออาคาร/หมู่บ้าน ห้องเลขที่ ชั้นที่ เลขที่ ตรอก/ซอย หมู่ที่ ถนน ตำบล/แขวง อำเภอ/เขต จังหวัด)"
                  />
                </Stack>

                <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { marginRight: 10 } }}>
                  <span style={{ marginTop: 10, fontWeight: 'bold' }}>อีเมล</span>
                  <TextField
                    id="email"
                    styles={{ root: { width: 165 } }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                  />
                </Stack>
              </Stack>
              <Stack horizontal>
                <Stack vertical>
                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <span style={{ marginTop: 10, fontWeight: 'bold' }}>ลำดับที่</span>
                    <TextField id="orderNumber" onChange={(e) => handleOnChange(e)} value={orderNumber} styles={{ root: { width: 80 } }} />
                    <span style={{ marginTop: 10 }}>ในแบบ</span>
                  </Stack>
                  <Stack vertical>
                    <span>(ให้สามารถอ้างอิงหรือสอบยันกันได้ระหว่างลำดับที่ตาม</span>
                    <span>หนังสือรับรองฯ กับแบบยื่นรายการภาษีหักที่จ่าย)</span>
                  </Stack>
                </Stack>
                <Stack vertical style={{ marginTop: 10, fontWeight: 'bold' }}>
                  <span style={{ marginLeft: 10 }}>
                    <Stack horizontal>
                      <span style={{ marginLeft: 10 }}>
                        <Stack horizontal>
                          <Checkbox
                            id="formType_0_(1) ภ.ง.ด.1ก"
                            onChange={(e) => {
                              setFormTypeError(false);
                              setFormType_1((prev) => !prev);
                            }}
                            autoFocus
                            ref={(Checkbox) => (checkBox[0] = Checkbox)}
                            checked={formType_1}
                          />
                          <span>(1) ภ.ง.ด.1ก</span>
                        </Stack>
                      </span>
                      <span style={{ marginLeft: 10 }}>
                        <Stack horizontal>
                          <Checkbox
                            id="formType_1_(2) ภ.ง.ด.1ก พิเศษ"
                            onChange={(e) => {
                              setFormTypeError(false);
                              setFormType_2((prev) => !prev);
                            }}
                            ref={(Checkbox) => (checkBox[1] = Checkbox)}
                            checked={formType_2}
                          />
                          <span>(2) ภ.ง.ด.1ก พิเศษ</span>
                        </Stack>
                      </span>
                      <span style={{ marginLeft: 10 }}>
                        <Stack horizontal>
                          <Checkbox
                            id="formType_2_(3) ภ.ง.ด.2"
                            onChange={(e) => {
                              setFormTypeError(false);
                              setFormType_3((prev) => !prev);
                            }}
                            ref={(Checkbox) => (checkBox[2] = Checkbox)}
                            checked={formType_3}
                          />
                          <span>(3) ภ.ง.ด.2</span>
                        </Stack>
                      </span>
                      <span style={{ marginLeft: 10 }}>
                        <Stack horizontal>
                          <Checkbox
                            id="formType_3_(4) ภ.ง.ด.3"
                            onChange={(e) => {
                              setFormTypeError(false);
                              setFormType_4((prev) => !prev);
                            }}
                            ref={(Checkbox) => (checkBox[3] = Checkbox)}
                            checked={formType_4}
                          />
                          <span>(4) ภ.ง.ด.3</span>
                        </Stack>
                      </span>
                    </Stack>
                    <Stack horizontal style={{ marginTop: 10 }}>
                      <span style={{ marginLeft: 10 }}>
                        <Stack horizontal>
                          <Checkbox
                            id="formType_4_(5) ภ.ง.ด.2ก"
                            onChange={(e) => {
                              setFormTypeError(false);
                              setFormType_5((prev) => !prev);
                            }}
                            ref={(Checkbox) => (checkBox[4] = Checkbox)}
                            checked={formType_5}
                          />
                          <span>(5) ภ.ง.ด.2ก</span>
                        </Stack>
                      </span>
                      <span style={{ marginLeft: 10 }}>
                        <Stack horizontal styles={{ root: { width: 131.45 } }}>
                          <Checkbox
                            id="formType_5_(6) ภ.ง.ด.3ก"
                            onChange={(e) => {
                              setFormTypeError(false);
                              setFormType_6((prev) => !prev);
                            }}
                            ref={(Checkbox) => (checkBox[5] = Checkbox)}
                            checked={formType_6}
                          />
                          <span>(6) ภ.ง.ด.3ก</span>
                        </Stack>
                      </span>
                      <span style={{ marginLeft: 10 }}>
                        <Stack horizontal>
                          <Checkbox
                            id="formType_6_(7) ภ.ง.ด.53"
                            onChange={(e) => {
                              setFormTypeError(false);
                              setFormType_7((prev) => !prev);
                            }}
                            ref={(Checkbox) => (checkBox[6] = Checkbox)}
                            checked={formType_7}
                          />
                          <span>(7) ภ.ง.ด.53</span>
                        </Stack>
                      </span>
                    </Stack>
                    {formTypeError && (
                      <p
                        style={{
                          marginLeft: 10,
                          marginTop: 0,
                          marginBottom: 0,
                          color: 'red',
                          fontSize: 12,
                          fontWeight: 400,
                          fontFamily:
                            '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                        }}
                      >
                        กรุณาเลือกแบบ
                      </p>
                    )}
                  </span>
                </Stack>
              </Stack>
            </Stack>
            <Stack styles={stackItemStyles}>
              <Stack horizontal horizontalAlign="space-between" styles={{ root: { borderBottom: '2px solid' } }}>
                <Stack styles={{ root: { marginTop: 10, fontWeight: 'bold' } }}>ประเภทเงินได้พึงประเมินที่จ่าย</Stack>
                <Stack horizontal>
                  <input
                    readOnly
                    style={{
                      fontWeight: 'bold',
                      width: 168.8,
                      textAlign: 'center',
                    }}
                    value="วัน เดือน หรือปีภาษี ที่จ่าย"
                    type="text"
                  />
                  <input
                    readOnly
                    style={{
                      fontWeight: 'bold',
                      width: 168.8,
                      textAlign: 'center',
                    }}
                    value="จำนวนเงินที่จ่าย"
                    type="text"
                  />
                  <input
                    readOnly
                    style={{
                      fontWeight: 'bold',
                      width: 168.8,
                      textAlign: 'center',
                    }}
                    value="ภาษีที่หักและนำส่งไว้"
                    type="text"
                  />
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack styles={{ root: { marginTop: 10 } }}>1. เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส ฯลฯ ตามมาตรา 40 (1)</Stack>
                <Stack horizontal>
                  <DatePicker
                    name="date"
                    id="paymentType_1_date"
                    allowTextInput={true}
                    className={controlClass.control}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    formatDate={onFormatDate}
                    styles={{ 
                      root: { width: 176.4, marginBottom: 0 }, 
                      // errorMessage: { margin: 0}, 
                      // description: { margin: 0}  
                    }}
                    onSelectDate={(date) => {
                      if (date !== null && date !== undefined) {
                        setPaymentTypeError(false);
                        setPaymentType_1((previousState) => ({
                          ...previousState,
                          date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                          dateBE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') +
                              '/' +
                              `${date.getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (date.getFullYear() + 543)
                            : '',
                          dateCE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                            : '',
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (checkDateFormat(e.target.value)) {
                        let value = e.target.value;
                        let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                        let date_parts = date.format().split('+');

                        let isoDate = date_parts[0] + '.000Z';

                        setPaymentTypeError(false);
                        setPaymentType_1((previousState) => ({
                          ...previousState,
                          date: isoDate,
                          dateBE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            (new Date(isoDate).getFullYear() + 543),
                          dateCE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            new Date(isoDate).getFullYear(),
                        }));
                      } else {
                        setPaymentType_1((previousState) => ({
                          ...previousState,
                          date: '',
                          dateBE: '',
                          dateCE: '',
                        }));
                      }
                    }}
                    isRequired={paymentType_1.amountTotal !== '' || paymentType_1.vatTotal !== ''}
                    value={isIsoDate(paymentType_1.date) ? new Date(paymentType_1.date) : ''}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_1_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_1((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_1((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    required={paymentType_1.date !== '' || paymentType_1.vatTotal !== ''}
                    value={paymentType_1.amountTotal}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_1_vatTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_1((previousState) => ({
                          ...previousState,
                          vatTotal: e.target.value
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_1((previousState) => ({
                          ...previousState,
                          vatTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    required={paymentType_1.date !== '' || paymentType_1.amountTotal !== ''}
                    value={paymentType_1.vatTotal}
                  />
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack styles={{ root: { marginTop: 10 } }}>2. ค่าธรรมเนียม ค่านายหน้า ฯลฯ ตามมาตรา 40 (2)</Stack>
                <Stack horizontal>
                  <DatePicker
                    name="date"
                    id="paymentType_2_date"
                    allowTextInput={true}
                    className={controlClass.control}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    formatDate={onFormatDate}
                    styles={{ root: { width: 176.4, marginBottom: 0 } }}
                    onSelectDate={(date) => {
                      if (date !== null && date !== undefined) {
                        setPaymentTypeError(false);
                        setPaymentType_2((previousState) => ({
                          ...previousState,
                          date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                          dateBE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') +
                              '/' +
                              `${date.getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (date.getFullYear() + 543)
                            : '',
                          dateCE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                            : '',
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (checkDateFormat(e.target.value)) {
                        let value = e.target.value;
                        let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                        let date_parts = date.format().split('+');

                        let isoDate = date_parts[0] + '.000Z';

                        setPaymentTypeError(false);
                        setPaymentType_2((previousState) => ({
                          ...previousState,
                          date: isoDate,
                          dateBE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            (new Date(isoDate).getFullYear() + 543),
                          dateCE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            new Date(isoDate).getFullYear(),
                        }));
                      } else {
                        setPaymentType_2((previousState) => ({
                          ...previousState,
                          date: '',
                          dateBE: '',
                          dateCE: '',
                        }));
                      }
                    }}
                    isRequired={paymentType_2.amountTotal !== '' || paymentType_2.vatTotal !== ''}
                    value={isIsoDate(paymentType_2.date) ? new Date(paymentType_2.date) : ''}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_2_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_2((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_2((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    required={paymentType_2.date !== '' || paymentType_2.vatTotal !== ''}
                    value={paymentType_2.amountTotal}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_2_vatTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_2((previousState) => ({
                          ...previousState,
                          vatTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_2((previousState) => ({
                          ...previousState,
                          vatTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    required={paymentType_2.date !== '' || paymentType_2.amountTotal !== ''}
                    value={paymentType_2.vatTotal}
                  />
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack styles={{ root: { marginTop: 10 } }}>3. ค่าแห่งลิขสิทธิ์ ฯลฯ ตามมาตรา 40 (3)</Stack>
                <Stack horizontal>
                  <DatePicker
                    name="date"
                    id="paymentType_3_date"
                    allowTextInput={true}
                    className={controlClass.control}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    formatDate={onFormatDate}
                    styles={{ root: { width: 176.4, marginBottom: 0 } }}
                    onSelectDate={(date) => {
                      if (date !== null && date !== undefined) {
                        setPaymentTypeError(false);
                        setPaymentType_3((previousState) => ({
                          ...previousState,
                          date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                          dateBE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') +
                              '/' +
                              `${date.getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (date.getFullYear() + 543)
                            : '',
                          dateCE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                            : '',
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (checkDateFormat(e.target.value)) {
                        let value = e.target.value;
                        let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                        let date_parts = date.format().split('+');

                        let isoDate = date_parts[0] + '.000Z';

                        setPaymentTypeError(false);
                        setPaymentType_3((previousState) => ({
                          ...previousState,
                          date: isoDate,
                          dateBE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            (new Date(isoDate).getFullYear() + 543),
                          dateCE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            new Date(isoDate).getFullYear(),
                        }));
                      } else {
                        setPaymentType_3((previousState) => ({
                          ...previousState,
                          date: '',
                          dateBE: '',
                          dateCE: '',
                        }));
                      }
                    }}
                    isRequired={paymentType_3.amountTotal !== '' || paymentType_3.vatTotal !== ''}
                    value={isIsoDate(paymentType_3.date) ? new Date(paymentType_3.date) : ''}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_3_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_3((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_3((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    required={paymentType_3.date !== '' || paymentType_3.vatTotal !== ''}
                    value={paymentType_3.amountTotal}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_3_vatTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_3((previousState) => ({
                          ...previousState,
                          vatTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_3((previousState) => ({
                          ...previousState,
                          vatTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    required={paymentType_3.date !== '' || paymentType_3.amountTotal !== ''}
                    value={paymentType_3.vatTotal}
                  />
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack styles={{ root: { marginTop: 10 } }}>4. (ก) ดอกเบี้ย ฯลฯ ตามมาตรา 40 (4) (ก)</Stack>
                <Stack horizontal>
                  <DatePicker
                    name="date"
                    id="paymentType_4_A_date"
                    allowTextInput={true}
                    className={controlClass.control}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    formatDate={onFormatDate}
                    styles={{ root: { width: 176.4, marginBottom: 0 } }}
                    onSelectDate={(date) => {
                      if (date !== null && date !== undefined) {
                        setPaymentTypeError(false);
                        setPaymentType_4_A((previousState) => ({
                          ...previousState,
                          date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                          dateBE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') +
                              '/' +
                              `${date.getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (date.getFullYear() + 543)
                            : '',
                          dateCE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                            : '',
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (checkDateFormat(e.target.value)) {
                        let value = e.target.value;
                        let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                        let date_parts = date.format().split('+');

                        let isoDate = date_parts[0] + '.000Z';

                        setPaymentTypeError(false);
                        setPaymentType_4_A((previousState) => ({
                          ...previousState,
                          date: isoDate,
                          dateBE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            (new Date(isoDate).getFullYear() + 543),
                          dateCE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            new Date(isoDate).getFullYear(),
                        }));
                      } else {
                        setPaymentType_4_A((previousState) => ({
                          ...previousState,
                          date: '',
                          dateBE: '',
                          dateCE: '',
                        }));
                      }
                    }}
                    isRequired={paymentType_4_A.amountTotal !== '' || paymentType_4_A.vatTotal !== ''}
                    value={isIsoDate(paymentType_4_A.date) ? new Date(paymentType_4_A.date) : ''}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_4_A_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_A((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_4_A((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    required={paymentType_4_A.date !== '' || paymentType_4_A.vatTotal !== ''}
                    value={paymentType_4_A.amountTotal}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_4_A_vatTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_A((previousState) => ({
                          ...previousState,
                          vatTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_4_A((previousState) => ({
                          ...previousState,
                          vatTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    required={paymentType_4_A.date !== '' || paymentType_4_A.amountTotal !== ''}
                    value={paymentType_4_A.vatTotal}
                  />
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack vertical>
                  <Stack styles={{ root: { marginTop: 10, marginLeft: 19 } }}>(ข) เงินปันผล เงินส่วนแบ่งกำไร ฯลฯ ตามมาตรา 40 (4) (ข)</Stack>
                  <Stack styles={{ root: { marginTop: 10, marginLeft: 43 } }}>(1) กรณีผู้ได้รับเงินปันผลได้รับเครดิตภาษี โดยจ่ายจากกำไรสุทธิ</Stack>
                  <Stack styles={{ root: { marginTop: 10, marginLeft: 67 } }}>ของกิจการที่ต้องเสียภาษีเงินได้นิติบุคคลในอัตราดังนี้</Stack>
                  <Stack style={{ marginTop: 5, marginLeft: 67 }}>(1.1) อัตราร้อยละ 30 ของกำไรสุทธิ</Stack>
                </Stack>
                <Stack horizontal styles={{ root: { marginTop: 80 } }}>
                  {/* <span style={{ border: '1px solid', borderRadius: 3 }}> */}
                    <DatePicker
                      // borderless
                      name="date"
                      id="paymentType_4_B_1_1_date"
                      allowTextInput={true}
                      className={controlClass.control}
                      firstDayOfWeek={firstDayOfWeek}
                      strings={DayPickerStrings}
                      formatDate={onFormatDate}
                      styles={{
                        root: { width: 176.4, /* marginTop: 63, */ marginBottom: 0 },
                      }}
                      onSelectDate={(date) => {
                        if (date !== null && date !== undefined) {
                          setPaymentTypeError(false);
                          setPaymentType_4_B_1_1((previousState) => ({
                            ...previousState,
                            date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                            dateBE: isIsoDate(date.toISOString())
                              ? `${date.getDate()}`.padStart(2, '0') +
                                '/' +
                                `${date.getMonth() + 1}`.padStart(2, '0') +
                                '/' +
                                (date.getFullYear() + 543)
                              : '',
                            dateCE: isIsoDate(date.toISOString())
                              ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                              : '',
                          }));
                        }
                      }}
                      onBlur={(e) => {
                        if (checkDateFormat(e.target.value)) {
                          let value = e.target.value;
                          let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                          let date_parts = date.format().split('+');

                          let isoDate = date_parts[0] + '.000Z';

                          setPaymentTypeError(false);
                          setPaymentType_4_B_1_1((previousState) => ({
                            ...previousState,
                            date: isoDate,
                            dateBE:
                              `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                              '/' +
                              `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (new Date(isoDate).getFullYear() + 543),
                            dateCE:
                              `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                              '/' +
                              `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              new Date(isoDate).getFullYear(),
                          }));
                        } else {
                          setPaymentType_4_B_1_1((previousState) => ({
                            ...previousState,
                            date: '',
                            dateBE: '',
                            dateCE: '',
                          }));
                        }
                      }}
                      isRequired={paymentType_4_B_1_1.amountTotal !== '' || paymentType_4_B_1_1.vatTotal !== ''}
                      value={isIsoDate(paymentType_4_B_1_1.date) ? new Date(paymentType_4_B_1_1.date) : ''}
                    />
                  {/* </span> */}
                  <TextField
                    // multiline
                    resizable={false}
                    style={{
                      textAlign: 'end',
                      marginBottom: 0,
                      // paddingTop: '70px',
                      // height: '95px',
                      width: 174.4,
                    }}
                    id="paymentType_4_B_1_1_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_1_1((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_4_B_1_1((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    required={paymentType_4_B_1_1.date !== '' || paymentType_4_B_1_1.vatTotal !== ''}
                    value={paymentType_4_B_1_1.amountTotal}
                  />
                  <TextField
                    // multiline
                    resizable={false}
                    style={{
                      textAlign: 'end',
                      marginBottom: 0,
                      // paddingTop: '70px',
                      // height: '95px',
                      width: 174.4,
                    }}
                    id="paymentType_4_B_1_1_vatTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_1_1((previousState) => ({
                          ...previousState,
                          vatTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_4_B_1_1((previousState) => ({
                          ...previousState,
                          vatTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    required={paymentType_4_B_1_1.date !== '' || paymentType_4_B_1_1.amountTotal !== ''}
                    value={paymentType_4_B_1_1.vatTotal}
                  />
                </Stack>
              </Stack>

              <Stack horizontal horizontalAlign="space-between">
                <Stack styles={{ root: { marginTop: 10, marginLeft: 67 } }}>(1.2) อัตราร้อยละ 25 ของกำไรสุทธิ</Stack>
                <Stack horizontal>
                  <DatePicker
                    name="date"
                    id="paymentType_4_B_1_2_date"
                    allowTextInput={true}
                    className={controlClass.control}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    formatDate={onFormatDate}
                    styles={{ root: { width: 176.4, marginBottom: 0 } }}
                    onSelectDate={(date) => {
                      if (date !== null && date !== undefined) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_1_2((previousState) => ({
                          ...previousState,
                          date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                          dateBE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') +
                              '/' +
                              `${date.getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (date.getFullYear() + 543)
                            : '',
                          dateCE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                            : '',
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (checkDateFormat(e.target.value)) {
                        let value = e.target.value;
                        let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                        let date_parts = date.format().split('+');

                        let isoDate = date_parts[0] + '.000Z';

                        setPaymentTypeError(false);
                        setPaymentType_4_B_1_2((previousState) => ({
                          ...previousState,
                          date: isoDate,
                          dateBE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            (new Date(isoDate).getFullYear() + 543),
                          dateCE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            new Date(isoDate).getFullYear(),
                        }));
                      } else {
                        setPaymentType_4_B_1_2((previousState) => ({
                          ...previousState,
                          date: '',
                          dateBE: '',
                          dateCE: '',
                        }));
                      }
                    }}
                    isRequired={paymentType_4_B_1_2.amountTotal !== '' || paymentType_4_B_1_2.vatTotal !== ''}
                    value={isIsoDate(paymentType_4_B_1_2.date) ? new Date(paymentType_4_B_1_2.date) : ''}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_4_B_1_2_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_1_2((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_4_B_1_2((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    required={paymentType_4_B_1_2.date !== '' || paymentType_4_B_1_2.vatTotal !== ''}
                    value={paymentType_4_B_1_2.amountTotal}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_4_B_1_2_vatTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_1_2((previousState) => ({
                          ...previousState,
                          vatTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_4_B_1_2((previousState) => ({
                          ...previousState,
                          vatTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    required={paymentType_4_B_1_2.date !== '' || paymentType_4_B_1_2.amountTotal !== ''}
                    value={paymentType_4_B_1_2.vatTotal}
                  />
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack styles={{ root: { marginTop: 10, marginLeft: 67 } }}>(1.3) อัตราร้อยละ 20 ของกำไรสุทธิ</Stack>
                <Stack horizontal>
                  <DatePicker
                    name="date"
                    id="paymentType_4_B_1_3_date"
                    allowTextInput={true}
                    className={controlClass.control}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    formatDate={onFormatDate}
                    styles={{ root: { width: 176.4, marginBottom: 0 } }}
                    onSelectDate={(date) => {
                      if (date !== null && date !== undefined) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_1_3((previousState) => ({
                          ...previousState,
                          date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                          dateBE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') +
                              '/' +
                              `${date.getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (date.getFullYear() + 543)
                            : '',
                          dateCE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                            : '',
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (checkDateFormat(e.target.value)) {
                        let value = e.target.value;
                        let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                        let date_parts = date.format().split('+');

                        let isoDate = date_parts[0] + '.000Z';

                        setPaymentTypeError(false);
                        setPaymentType_4_B_1_3((previousState) => ({
                          ...previousState,
                          date: isoDate,
                          dateBE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            (new Date(isoDate).getFullYear() + 543),
                          dateCE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            new Date(isoDate).getFullYear(),
                        }));
                      } else {
                        setPaymentType_4_B_1_3((previousState) => ({
                          ...previousState,
                          date: '',
                          dateBE: '',
                          dateCE: '',
                        }));
                      }
                    }}
                    isRequired={paymentType_4_B_1_3.amountTotal !== '' || paymentType_4_B_1_3.vatTotal !== ''}
                    value={isIsoDate(paymentType_4_B_1_3.date) ? new Date(paymentType_4_B_1_3.date) : ''}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_4_B_1_3_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_1_3((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_4_B_1_3((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    required={paymentType_4_B_1_3.date !== '' || paymentType_4_B_1_3.vatTotal !== ''}
                    value={paymentType_4_B_1_3.amountTotal}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_4_B_1_3_vatTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_1_3((previousState) => ({
                          ...previousState,
                          vatTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_4_B_1_3((previousState) => ({
                          ...previousState,
                          vatTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                        setPaymentTypeError(false);
                      }
                    }}
                    required={paymentType_4_B_1_3.date !== '' || paymentType_4_B_1_3.amountTotal !== ''}
                    value={paymentType_4_B_1_3.vatTotal}
                  />
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <Stack horizontal styles={{ root: { marginTop: 10, marginLeft: 67 } }}>
                    (1.4) อัตราอื่นๆ (ระบุ)
                  </Stack>
                  <TextField
                    styles={{ root: { width: 60 } }}
                    id="paymentType_4_B_1_4_percentVat"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_4_B_1_4((previousState) => ({
                          ...previousState,
                          percentVat: e.target.value ? Number(e.target.value) : "",
                        }));
                        if (e.target.value !== '' && paymentType_4_B_1_4.amountTotal !== '') {
                          setPaymentType_4_B_1_4((previousState) => ({
                            ...previousState,
                            vatTotal: Number(e.target.value) * (Number(paymentType_4_B_1_4.amountTotal) / 100),
                          }));
                        } else {
                          setPaymentType_4_B_1_4((previousState) => ({
                            ...previousState,
                            vatTotal: '',
                          }));
                        }
                      }
                    }}
                    required={paymentType_4_B_1_4.amountTotal !== '' || paymentType_4_B_1_4.vatTotal !== '' || paymentType_4_B_1_4.date !== ''}
                    value={paymentType_4_B_1_4.percentVat}
                  />
                  <Stack horizontal styles={{ root: { marginTop: 10, marginLeft: 67 } }}>
                    ของกำไรสุทธิ
                  </Stack>
                </Stack>
                <Stack horizontal>
                  <DatePicker
                    name="date"
                    id="paymentType_4_B_1_4_date"
                    allowTextInput={true}
                    className={controlClass.control}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    formatDate={onFormatDate}
                    styles={{ root: { width: 176.4, marginBottom: 0 } }}
                    onSelectDate={(date) => {
                      if (date !== null && date !== undefined) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_1_4((previousState) => ({
                          ...previousState,
                          date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                          dateBE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') +
                              '/' +
                              `${date.getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (date.getFullYear() + 543)
                            : '',
                          dateCE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                            : '',
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (checkDateFormat(e.target.value)) {
                        let value = e.target.value;
                        let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                        let date_parts = date.format().split('+');

                        let isoDate = date_parts[0] + '.000Z';

                        setPaymentTypeError(false);
                        setPaymentType_4_B_1_4((previousState) => ({
                          ...previousState,
                          date: isoDate,
                          dateBE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            (new Date(isoDate).getFullYear() + 543),
                          dateCE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            new Date(isoDate).getFullYear(),
                        }));
                      } else {
                        setPaymentType_4_B_1_4((previousState) => ({
                          ...previousState,
                          date: '',
                          dateBE: '',
                          dateCE: '',
                        }));
                      }
                    }}
                    isRequired={
                      paymentType_4_B_1_4.amountTotal !== '' || paymentType_4_B_1_4.vatTotal !== '' || paymentType_4_B_1_4.percentVat !== ''
                    }
                    value={isIsoDate(paymentType_4_B_1_4.date) ? new Date(paymentType_4_B_1_4.date) : ''}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_4_B_1_4_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_4_B_1_4((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value
                        }));
                      }
                      if (e.target.value !== '' && paymentType_4_B_1_4.percentVat !== '') {
                        setPaymentType_4_B_1_4((previousState) => ({
                          ...previousState,
                          vatTotal: Number(e.target.value) * (Number(paymentType_4_B_1_4.percentVat) / 100),
                        }));
                      } else {
                        setPaymentType_4_B_1_4((previousState) => ({
                          ...previousState,
                          vatTotal: '',
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentType_4_B_1_4((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                      if (e.target.value !== '' && paymentType_4_B_1_4.percentVat !== '') {
                        setPaymentType_4_B_1_4((previousState) => ({
                          ...previousState,
                          vatTotal: Number(e.target.value) * (Number(paymentType_4_B_1_4.percentVat) / 100),
                        }));
                      } else {
                        setPaymentType_4_B_1_4((previousState) => ({
                          ...previousState,
                          vatTotal: '',
                        }));
                      }
                    }}
                    required={paymentType_4_B_1_4.percentVat !== '' || paymentType_4_B_1_4.vatTotal !== '' || paymentType_4_B_1_4.date !== ''}
                    value={paymentType_4_B_1_4.amountTotal}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_4_B_1_4_vatTotal"
                    required={paymentType_4_B_1_4.amountTotal !== '' || paymentType_4_B_1_4.percentVat !== '' || paymentType_4_B_1_4.date !== ''}
                    value={paymentType_4_B_1_4.vatTotal}
                  />
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack vertical>
                  <Stack styles={{ root: { marginTop: 10, marginLeft: 43 } }}>(2) กรณีผู้ได้รับเงินปันผลไม่ได้รับเครดิตภาษี เนื่องจากจ่ายจาก</Stack>
                  <Stack styles={{ root: { marginTop: 10, marginLeft: 67 } }}>(2.1) กำไรสุทธิของกิจการที่ได้รับยกเว้นภาษีเงินได้นิติบุคคล</Stack>
                </Stack>
                <Stack horizontal styles={{ root: { marginTop: 113 } }}>
                  {/* <span style={{ border: '1px solid', borderRadius: 3 }}> */}
                    <DatePicker
                      // borderless
                      name="date"
                      id="paymentType_4_B_2_1_date"
                      allowTextInput={true}
                      className={controlClass.control}
                      firstDayOfWeek={firstDayOfWeek}
                      strings={DayPickerStrings}
                      formatDate={onFormatDate}
                      styles={{
                        root: { width: 176.4, marginBottom: 0 },
                      }}
                      onSelectDate={(date) => {
                        if (date !== null && date !== undefined) {
                          setPaymentTypeError(false);
                          setPaymentType_4_B_2_1((previousState) => ({
                            ...previousState,
                            date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                            dateBE: isIsoDate(date.toISOString())
                              ? `${date.getDate()}`.padStart(2, '0') +
                                '/' +
                                `${date.getMonth() + 1}`.padStart(2, '0') +
                                '/' +
                                (date.getFullYear() + 543)
                              : '',
                            dateCE: isIsoDate(date.toISOString())
                              ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                              : '',
                          }));
                        }
                      }}
                      onBlur={(e) => {
                        if (checkDateFormat(e.target.value)) {
                          let value = e.target.value;
                          let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                          let date_parts = date.format().split('+');

                          let isoDate = date_parts[0] + '.000Z';

                          setPaymentTypeError(false);
                          setPaymentType_4_B_2_1((previousState) => ({
                            ...previousState,
                            date: isoDate,
                            dateBE:
                              `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                              '/' +
                              `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (new Date(isoDate).getFullYear() + 543),
                            dateCE:
                              `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                              '/' +
                              `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              new Date(isoDate).getFullYear(),
                          }));
                        } else {
                          setPaymentType_4_B_2_1((previousState) => ({
                            ...previousState,
                            date: '',
                            dateBE: '',
                            dateCE: '',
                          }));
                        }
                      }}
                      isRequired={paymentType_4_B_2_1.amountTotal !== '' || paymentType_4_B_2_1.vatTotal !== ''}
                      value={isIsoDate(paymentType_4_B_2_1.date) ? new Date(paymentType_4_B_2_1.date) : ''}
                    />
                  {/* </span> */}
                  <TextField
                    // multiline
                    resizable={false}
                    style={{
                      textAlign: 'end',
                      marginBottom: 0,
                      width: 174.4,
                    }}
                    id="paymentType_4_B_2_1_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_1((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_1((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                    }}
                    required={paymentType_4_B_2_1.date !== '' || paymentType_4_B_2_1.vatTotal !== ''}
                    value={paymentType_4_B_2_1.amountTotal}
                  />
                  <TextField
                    // multiline
                    resizable={false}
                    style={{
                      textAlign: 'end',
                      marginBottom: 0,
                      width: 174.4,
                    }}
                    id="paymentType_4_B_2_1_vatTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_1((previousState) => ({
                          ...previousState,
                          vatTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_1((previousState) => ({
                          ...previousState,
                          vatTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                    }}
                    required={paymentType_4_B_2_1.amountTotal !== '' || paymentType_4_B_2_1.date !== ''}
                    value={paymentType_4_B_2_1.vatTotal}
                  />
                </Stack>
              </Stack>

              <Stack horizontal horizontalAlign="space-between">
                <Stack>
                  <Stack styles={{ root: { marginTop: 10, marginLeft: 67 } }}>(2.2) เงินปันผลหรือเงินส่วนแบ่งของกำไรที่ได้รับยกเว้นไม่ต้อง</Stack>
                  <Stack styles={{ root: { marginTop: 10, marginLeft: 105 } }}>นำมารวมคำนวณเป็นรายได้เพื่อเสียภาษีเงินได้นิติบุคคล</Stack>
                </Stack>
                <Stack horizontal styles={{ root: { marginTop: 23 } }}>
                  {/* <span style={{ border: '1px solid', borderRadius: 3 }}> */}
                    <DatePicker
                      // borderless
                      name="date"
                      id="paymentType_4_B_2_2_date"
                      allowTextInput={true}
                      className={controlClass.control}
                      firstDayOfWeek={firstDayOfWeek}
                      strings={DayPickerStrings}
                      formatDate={onFormatDate}
                      styles={{
                        root: { width: 176.4, marginBottom: 0 },
                      }}
                      onSelectDate={(date) => {
                        if (date !== null && date !== undefined) {
                          setPaymentTypeError(false);
                          setPaymentType_4_B_2_2((previousState) => ({
                            ...previousState,
                            date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                            dateBE: isIsoDate(date.toISOString())
                              ? `${date.getDate()}`.padStart(2, '0') +
                                '/' +
                                `${date.getMonth() + 1}`.padStart(2, '0') +
                                '/' +
                                (date.getFullYear() + 543)
                              : '',
                            dateCE: isIsoDate(date.toISOString())
                              ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                              : '',
                          }));
                        }
                      }}
                      onBlur={(e) => {
                        if (checkDateFormat(e.target.value)) {
                          let value = e.target.value;
                          let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                          let date_parts = date.format().split('+');

                          let isoDate = date_parts[0] + '.000Z';

                          setPaymentTypeError(false);
                          setPaymentType_4_B_2_2((previousState) => ({
                            ...previousState,
                            date: isoDate,
                            dateBE:
                              `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                              '/' +
                              `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (new Date(isoDate).getFullYear() + 543),
                            dateCE:
                              `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                              '/' +
                              `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              new Date(isoDate).getFullYear(),
                          }));
                        } else {
                          setPaymentType_4_B_2_2((previousState) => ({
                            ...previousState,
                            date: '',
                            dateBE: '',
                            dateCE: '',
                          }));
                        }
                      }}
                      isRequired={paymentType_4_B_2_2.amountTotal !== '' || paymentType_4_B_2_2.vatTotal !== ''}
                      value={isIsoDate(paymentType_4_B_2_2.date) ? new Date(paymentType_4_B_2_2.date) : ''}
                    />
                  {/* </span> */}
                  <TextField
                    // multiline
                    resizable={false}
                    style={{
                      textAlign: 'end',
                      marginBottom: 0,
                      width: 174.4,
                    }}
                    id="paymentType_4_B_2_2_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_2((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_2((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                    }}
                    required={paymentType_4_B_2_2.date !== '' || paymentType_4_B_2_2.vatTotal !== ''}
                    value={paymentType_4_B_2_2.amountTotal}
                  />
                  <TextField
                    // multiline
                    resizable={false}
                    style={{
                      textAlign: 'end',
                      marginBottom: 0,
                      width: 174.4,
                    }}
                    id="paymentType_4_B_2_2_vatTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_2((previousState) => ({
                          ...previousState,
                          vatTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_2((previousState) => ({
                          ...previousState,
                          vatTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                    }}
                    required={paymentType_4_B_2_2.amountTotal !== '' || paymentType_4_B_2_2.date !== ''}
                    value={paymentType_4_B_2_2.vatTotal}
                  />
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack>
                  <Stack styles={{ root: { marginTop: 10, marginLeft: 67 } }}>(2.3) กำไรสุทธิส่วนที่ได้หักผลขาดทุนสุทธิยกมาไม่เกิน 5 ปี</Stack>
                  <Stack styles={{ root: { marginTop: 10, marginLeft: 105 } }}>ก่อนรอบระยะเวลาบัญชีปีปัจจุบัน</Stack>
                </Stack>
                <Stack horizontal styles={{ root: { marginTop: 23 } }}>
                  {/* <span style={{ border: '1px solid', borderRadius: 3 }}> */}
                    <DatePicker
                      // borderless
                      name="date"
                      id="paymentType_4_B_2_3_date"
                      allowTextInput={true}
                      className={controlClass.control}
                      firstDayOfWeek={firstDayOfWeek}
                      strings={DayPickerStrings}
                      formatDate={onFormatDate}
                      styles={{
                        root: { width: 176.4, marginBottom: 0 },
                      }}
                      onSelectDate={(date) => {
                        if (date !== null && date !== undefined) {
                          setPaymentTypeError(false);
                          setPaymentType_4_B_2_3((previousState) => ({
                            ...previousState,
                            date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                            dateBE: isIsoDate(date.toISOString())
                              ? `${date.getDate()}`.padStart(2, '0') +
                                '/' +
                                `${date.getMonth() + 1}`.padStart(2, '0') +
                                '/' +
                                (date.getFullYear() + 543)
                              : '',
                            dateCE: isIsoDate(date.toISOString())
                              ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                              : '',
                          }));
                        }
                      }}
                      onBlur={(e) => {
                        if (checkDateFormat(e.target.value)) {
                          let value = e.target.value;
                          let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                          let date_parts = date.format().split('+');

                          let isoDate = date_parts[0] + '.000Z';

                          setPaymentTypeError(false);
                          setPaymentType_4_B_2_3((previousState) => ({
                            ...previousState,
                            date: isoDate,
                            dateBE:
                              `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                              '/' +
                              `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (new Date(isoDate).getFullYear() + 543),
                            dateCE:
                              `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                              '/' +
                              `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              new Date(isoDate).getFullYear(),
                          }));
                        } else {
                          setPaymentType_4_B_2_3((previousState) => ({
                            ...previousState,
                            date: '',
                            dateBE: '',
                            dateCE: '',
                          }));
                        }
                      }}
                      isRequired={paymentType_4_B_2_3.amountTotal !== '' || paymentType_4_B_2_3.vatTotal !== ''}
                      value={isIsoDate(paymentType_4_B_2_3.date) ? new Date(paymentType_4_B_2_3.date) : ''}
                    />
                  {/* </span> */}
                  <TextField
                    // multiline
                    resizable={false}
                    style={{
                      textAlign: 'end',
                      marginBottom: 0,
                      width: 174.4,
                    }}
                    id="paymentType_4_B_2_3_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_3((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_3((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                    }}
                    required={paymentType_4_B_2_3.date !== '' || paymentType_4_B_2_3.vatTotal !== ''}
                    value={paymentType_4_B_2_3.amountTotal}
                  />
                  <TextField
                    // multiline
                    resizable={false}
                    style={{
                      textAlign: 'end',
                      marginBottom: 0,
                      width: 174.4,
                    }}
                    id="paymentType_4_B_2_3_vatTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_3((previousState) => ({
                          ...previousState,
                          vatTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_3((previousState) => ({
                          ...previousState,
                          vatTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                    }}
                    required={paymentType_4_B_2_3.amountTotal !== '' || paymentType_4_B_2_3.date !== ''}
                    value={paymentType_4_B_2_3.vatTotal}
                  />
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between"></Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack styles={{ root: { marginTop: 10, marginLeft: 67 } }}>(2.4) กำไรที่รับรู้ทางบัญชีโดยวิธีส่วนได้เสีย (equity method)</Stack>
                <Stack horizontal>
                  <DatePicker
                    name="date"
                    id="paymentType_4_B_2_4_date"
                    allowTextInput={true}
                    className={controlClass.control}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    formatDate={onFormatDate}
                    styles={{ root: { width: 176.4, marginBottom: 0 } }}
                    onSelectDate={(date) => {
                      if (date !== null && date !== undefined) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_4((previousState) => ({
                          ...previousState,
                          date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                          dateBE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') +
                              '/' +
                              `${date.getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (date.getFullYear() + 543)
                            : '',
                          dateCE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                            : '',
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (checkDateFormat(e.target.value)) {
                        let value = e.target.value;
                        let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                        let date_parts = date.format().split('+');

                        let isoDate = date_parts[0] + '.000Z';

                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_4((previousState) => ({
                          ...previousState,
                          date: isoDate,
                          dateBE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            (new Date(isoDate).getFullYear() + 543),
                          dateCE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            new Date(isoDate).getFullYear(),
                        }));
                      } else {
                        setPaymentType_4_B_2_4((previousState) => ({
                          ...previousState,
                          date: '',
                          dateBE: '',
                          dateCE: '',
                        }));
                      }
                    }}
                    isRequired={paymentType_4_B_2_4.amountTotal !== '' || paymentType_4_B_2_4.vatTotal !== ''}
                    value={isIsoDate(paymentType_4_B_2_4.date) ? new Date(paymentType_4_B_2_4.date) : ''}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_4_B_2_4_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_4((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_4((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                    }}
                    required={paymentType_4_B_2_4.date !== '' || paymentType_4_B_2_4.vatTotal !== ''}
                    value={paymentType_4_B_2_4.amountTotal}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_4_B_2_4_vatTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_4((previousState) => ({
                          ...previousState,
                          vatTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_4((previousState) => ({
                          ...previousState,
                          vatTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                    }}
                    required={paymentType_4_B_2_4.amountTotal !== '' || paymentType_4_B_2_4.date !== ''}
                    value={paymentType_4_B_2_4.vatTotal}
                  />
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <Stack styles={{ root: { marginTop: 10, marginLeft: 67 } }}>(2.5) อื่นๆ (ระบุ)</Stack>
                  <TextField
                    id="paymentType_4_B_2_5_remark"
                    onChange={(e) =>
                      setPaymentType_4_B_2_5((previousState) => ({
                        ...previousState,
                        remark: e.target.value,
                      }))
                    }
                    isRequired={paymentType_4_B_2_5.amountTotal !== '' || paymentType_4_B_2_5.vatTotal !== '' || paymentType_4_B_2_5.date !== ''}
                    value={paymentType_4_B_2_5.remark}
                  />
                </Stack>
                <Stack horizontal>
                  <DatePicker
                    name="date"
                    id="paymentType_4_B_2_5_date"
                    allowTextInput={true}
                    className={controlClass.control}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    formatDate={onFormatDate}
                    styles={{ root: { width: 176.4, marginBottom: 0 } }}
                    onSelectDate={(date) => {
                      if (date !== null && date !== undefined) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_5((previousState) => ({
                          ...previousState,
                          date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                          dateBE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') +
                              '/' +
                              `${date.getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (date.getFullYear() + 543)
                            : '',
                          dateCE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                            : '',
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (checkDateFormat(e.target.value)) {
                        let value = e.target.value;
                        let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                        let date_parts = date.format().split('+');

                        let isoDate = date_parts[0] + '.000Z';

                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_5((previousState) => ({
                          ...previousState,
                          date: isoDate,
                          dateBE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            (new Date(isoDate).getFullYear() + 543),
                          dateCE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            new Date(isoDate).getFullYear(),
                        }));
                      } else {
                        setPaymentType_4_B_2_5((previousState) => ({
                          ...previousState,
                          date: '',
                          dateBE: '',
                          dateCE: '',
                        }));
                      }
                    }}
                    isRequired={paymentType_4_B_2_5.amountTotal !== '' || paymentType_4_B_2_5.vatTotal !== '' || paymentType_4_B_2_5.remark !== ''}
                    value={isIsoDate(paymentType_4_B_2_5.date) ? new Date(paymentType_4_B_2_5.date) : ''}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_4_B_2_5_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_5((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_5((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                    }}
                    required={paymentType_4_B_2_5.remark !== '' || paymentType_4_B_2_5.vatTotal !== '' || paymentType_4_B_2_5.date !== ''}
                    value={paymentType_4_B_2_5.amountTotal}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_4_B_2_5_vatTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_5((previousState) => ({
                          ...previousState,
                          vatTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_4_B_2_5((previousState) => ({
                          ...previousState,
                          vatTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                    }}
                    required={paymentType_4_B_2_5.amountTotal !== '' || paymentType_4_B_2_5.remark !== '' || paymentType_4_B_2_5.date !== ''}
                    value={paymentType_4_B_2_5.vatTotal}
                  />
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack vertical>
                  <Stack styles={{ root: { marginTop: 10 } }}>5. การจ่ายเงินได้ที่ต้องหักภาษี ณ ที่จ่าย ตามคำสั่งกรมสรรพากรที่ออก</Stack>
                  <Stack styles={{ root: { marginTop: 10, marginLeft: 19 } }}>ตามมาตรา 3 เตรส เช่น รางวัล ส่วนลดหรือประโยชน์ใดๆ เนื่องจาก</Stack>
                  <Stack styles={{ root: { marginTop: 10, marginLeft: 19 } }}>การส่งเสริมการขาย รางวัล ในการประกวด การแข่งขัน การชิงโชค</Stack>
                  <Stack styles={{ root: { marginTop: 10, marginLeft: 19 } }}>ค่าแสดงของนักแสดงสาธารณะ ค่าจ้าง ทำของ ค่าโฆษณา ค่าเช่า</Stack>
                  <Stack styles={{ root: { marginTop: 10, marginLeft: 19 } }}>ค่าขนส่ง ค่าบริการ ค่าเบี้ยประกันวินาศภัย</Stack>
                </Stack>
                <Stack horizontal styles={{ root: { marginTop: 23 } }}>
                  {/* <span style={{ border: '1px solid', borderRadius: 3 }}> */}
                    <DatePicker
                      // borderless
                      name="date"
                      id="paymentType_5_date"
                      allowTextInput={true}
                      className={controlClass.control}
                      firstDayOfWeek={firstDayOfWeek}
                      strings={DayPickerStrings}
                      formatDate={onFormatDate}
                      styles={{
                        root: { width: 176.4, marginBottom: 0 },
                      }}
                      onSelectDate={(date) => {
                        if (date !== null && date !== undefined) {
                          setPaymentTypeError(false);
                          setPaymentType_5((previousState) => ({
                            ...previousState,
                            date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                            dateBE: isIsoDate(date.toISOString())
                              ? `${date.getDate()}`.padStart(2, '0') +
                                '/' +
                                `${date.getMonth() + 1}`.padStart(2, '0') +
                                '/' +
                                (date.getFullYear() + 543)
                              : '',
                            dateCE: isIsoDate(date.toISOString())
                              ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                              : '',
                          }));
                        }
                      }}
                      onBlur={(e) => {
                        if (checkDateFormat(e.target.value)) {
                          let value = e.target.value;
                          let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                          let date_parts = date.format().split('+');

                          let isoDate = date_parts[0] + '.000Z';

                          setPaymentTypeError(false);
                          setPaymentType_5((previousState) => ({
                            ...previousState,
                            date: isoDate,
                            dateBE:
                              `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                              '/' +
                              `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (new Date(isoDate).getFullYear() + 543),
                            dateCE:
                              `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                              '/' +
                              `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              new Date(isoDate).getFullYear(),
                          }));
                        } else {
                          setPaymentType_5((previousState) => ({
                            ...previousState,
                            date: '',
                            dateBE: '',
                            dateCE: '',
                          }));
                        }
                      }}
                      isRequired={paymentType_5.amountTotal !== '' || paymentType_5.vatTotal !== ''}
                      value={isIsoDate(paymentType_5.date) ? new Date(paymentType_5.date) : ''}
                    />
                  {/* </span> */}
                  <TextField
                    // multiline
                    resizable={false}
                    style={{
                      textAlign: 'end',
                      marginBottom: 0,
                      width: 174.4,
                    }}
                    id="paymentType_5_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_5((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_5((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                    }}
                    required={paymentType_5.date !== '' || paymentType_5.vatTotal !== ''}
                    value={paymentType_5.amountTotal}
                  />
                  <TextField
                    // multiline
                    resizable={false}
                    style={{
                      textAlign: 'end',
                      marginBottom: 0,
                      width: 174.4,
                    }}
                    id="paymentType_5_vatTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_5((previousState) => ({
                          ...previousState,
                          vatTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_5((previousState) => ({
                          ...previousState,
                          vatTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                    }}
                    required={paymentType_5.amountTotal !== '' || paymentType_5.date !== ''}
                    value={paymentType_5.vatTotal}
                  />
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between"></Stack>
              <Stack horizontal horizontalAlign="space-between"></Stack>
              <Stack horizontal horizontalAlign="space-between">
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <Stack styles={{ root: { marginTop: 10 } }}>6. อื่นๆ (ระบุ)</Stack>
                  <TextField
                    id="paymentType_6_remark"
                    onChange={(e) =>
                      setPaymentType_6((previousState) => ({
                        ...previousState,
                        remark: e.target.value,
                      }))
                    }
                    required={paymentType_6.date !== '' || paymentType_6.vatTotal !== '' || paymentType_6.amountTotal !== ''}
                    value={paymentType_6.remark}
                  />
                </Stack>
                <Stack horizontal>
                  <DatePicker
                    name="date"
                    id="paymentType_6_date"
                    allowTextInput={true}
                    className={controlClass.control}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    formatDate={onFormatDate}
                    styles={{ root: { width: 176.4, marginBottom: 0 } }}
                    onSelectDate={(date) => {
                      if (date !== null && date !== undefined) {
                        setPaymentTypeError(false);
                        setPaymentType_6((previousState) => ({
                          ...previousState,
                          date: isIsoDate(date.toISOString()) ? date.toISOString() : '',
                          dateBE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') +
                              '/' +
                              `${date.getMonth() + 1}`.padStart(2, '0') +
                              '/' +
                              (date.getFullYear() + 543)
                            : '',
                          dateCE: isIsoDate(date.toISOString())
                            ? `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                            : '',
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (checkDateFormat(e.target.value)) {
                        let value = e.target.value;
                        let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                        let date_parts = date.format().split('+');

                        let isoDate = date_parts[0] + '.000Z';

                        setPaymentTypeError(false);
                        setPaymentType_6((previousState) => ({
                          ...previousState,
                          date: isoDate,
                          dateBE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            (new Date(isoDate).getFullYear() + 543),
                          dateCE:
                            `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                            '/' +
                            `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                            '/' +
                            new Date(isoDate).getFullYear(),
                        }));
                      } else {
                        setPaymentType_6((previousState) => ({
                          ...previousState,
                          date: '',
                          dateBE: '',
                          dateCE: '',
                        }));
                      }
                    }}
                    isRequired={paymentType_6.amountTotal !== '' || paymentType_6.vatTotal !== '' || paymentType_6.remark !== ''}
                    value={isIsoDate(paymentType_6.date) ? new Date(paymentType_6.date) : ''}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_6_amountTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_6((previousState) => ({
                          ...previousState,
                          amountTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_6((previousState) => ({
                          ...previousState,
                          amountTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                    }}
                    required={paymentType_6.date !== '' || paymentType_6.vatTotal !== '' || paymentType_6.remark !== ''}
                    value={paymentType_6 ? paymentType_6.amountTotal : ''}
                  />
                  <TextField
                    style={{ textAlign: 'end' }}
                    id="paymentType_6_vatTotal"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_6((previousState) => ({
                          ...previousState,
                          vatTotal: e.target.value
                        }));
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value >= 0) {
                        setPaymentTypeError(false);
                        setPaymentType_6((previousState) => ({
                          ...previousState,
                          vatTotal: Math.round(Number(e.target.value) * 100) / 100,
                        }));
                      }
                    }}
                    required={paymentType_6.amountTotal !== '' || paymentType_6.date !== '' || paymentType_6.remark !== ''}
                    value={paymentType_6.vatTotal}
                  />
                </Stack>
              </Stack>
              <Stack styles={{ root: { borderBottom: '2px solid' } }}></Stack>
              <Stack horizontal horizontalAlign="space-between">
                <span></span>

                <Stack horizontal>
                  <Stack styles={{ root: { marginTop: 10, fontWeight: 'bold' } }}>รวมเงินที่จ่ายและภาษีที่หักนำส่ง</Stack>
                  <TextField
                    readOnly
                    borderless
                    style={{
                      textAlign: 'end',
                      fontWeight: 'bold',
                      width: 174.4,
                    }}
                    id="amountTotal"
                    value={amountTotal.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  />
                  <TextField
                    readOnly
                    borderless
                    style={{
                      textAlign: 'end',
                      fontWeight: 'bold',
                      width: 174.4,
                    }}
                    id="vatTotal"
                    value={vatTotal.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  />
                </Stack>
              </Stack>
              <Stack horizontal>
                <Stack horizontal styles={{ root: { marginTop: 10 } }}>
                  <span style={{ fontWeight: 'bold' }}>รวมเงินภาษีที่หักนำส่ง</span> (ตัวอักษร)
                </Stack>
                <TextField readOnly borderless id="vatTotalText" style={{ width: 500, fontSize: 24 }} value={vatTotalText} />
              </Stack>
            </Stack>
            <Stack styles={stackItemStyles}>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack horizontal styles={{ root: { marginTop: 10 } }}>
                  <span style={{ fontWeight: 'bold' }}>เงินที่จ่ายเข้า</span>
                  <span style={{ marginLeft: 10 }}>กบข./กสจ./กองทุนสงเคราะห์ครูโรงเรียนเอกชน</span>
                </Stack>
                <TextField
                  id="paymentAmount_privateTeacherAidFund"
                  value={paymentAmount.privateTeacherAidFund}
                  type='number'
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      handleOnChange(e);
                    }
                  }}
                  styles={{ root: { width: 80 } }}
                  ref={(input) => (input_paymentType[15] = input)}
                />
                <Stack styles={{ root: { marginTop: 10 } }}>บาท กองทุนประกันสังคม</Stack>
                <TextField
                  id="paymentAmount_providentFund"
                  value={paymentAmount.providentFund}
                  type='number'
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      handleOnChange(e);
                    }
                  }}
                  styles={{ root: { width: 80 } }}
                  ref={(input) => (input_paymentType[16] = input)}
                />
                <Stack styles={{ root: { marginTop: 10 } }}>บาท กองทุนสํารองเลี้ยงชีพ</Stack>
                <TextField
                  id="paymentAmount_socialFund"
                  value={paymentAmount.socialFund}
                  type='number'
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      handleOnChange(e);
                    }
                  }}
                  styles={{ root: { width: 80 } }}
                  ref={(input) => (input_paymentType[17] = input)}
                />
                <Stack styles={{ root: { marginTop: 10 } }}>บาท</Stack>
              </Stack>
            </Stack>
            <Stack styles={stackItemStyles}>
              <Stack horizontal>
                <Stack styles={{ root: { marginTop: 10, fontWeight: 'bold' } }}>ผู้จ่ายเงิน</Stack>
                <span style={{ marginLeft: 10 }}>
                  <Stack horizontal>
                    <span style={{ marginLeft: 10 }}>
                      <Stack horizontal>
                        <Checkbox
                          styles={{ root: { marginTop: 10 } }}
                          id="payment_0_(1) หัก ณ ที่จ่าย"
                          checked={payment_type_1}
                          onChange={(e) => {
                            setPayment_TypeError(false);
                            setPayment_type_1((prev) => !prev);
                          }}
                          ref={(checkBox) => (checkBox_payment[0] = checkBox)}
                        />
                        <Stack styles={{ root: { marginTop: 10 } }}>(1) หัก ณ ที่จ่าย</Stack>
                      </Stack>
                    </span>
                    <span style={{ marginLeft: 10 }}>
                      <Stack horizontal>
                        <Checkbox
                          styles={{ root: { marginTop: 10 } }}
                          id="payment_1_(2) ออกให้ตลอดไป"
                          checked={payment_type_2}
                          onChange={(e) => {
                            setPayment_TypeError(false);
                            setPayment_type_2((prev) => !prev);
                          }}
                          ref={(checkBox) => (checkBox_payment[1] = checkBox)}
                        />
                        <Stack styles={{ root: { marginTop: 10 } }}>(2) ออกให้ตลอดไป</Stack>
                      </Stack>
                    </span>
                    <span style={{ marginLeft: 10 }}>
                      <Stack horizontal>
                        <Checkbox
                          styles={{ root: { marginTop: 10 } }}
                          id="payment_2_(3) ออกให้ครั้งเดียว"
                          checked={payment_type_3}
                          onChange={(e) => {
                            setPayment_TypeError(false);
                            setPayment_type_3((prev) => !prev);
                          }}
                          ref={(checkBox) => (checkBox_payment[2] = checkBox)}
                        />
                        <Stack styles={{ root: { marginTop: 10 } }}>(3) ออกให้ครั้งเดียว</Stack>
                      </Stack>
                    </span>
                    <span style={{ marginLeft: 10 }}>
                      <Stack horizontal>
                        <Checkbox
                          styles={{ root: { marginTop: 10 } }}
                          id="payment_3_(4) อื่นๆ"
                          checked={payment_type_4}
                          onChange={(e) => {
                            setPayment_TypeError(false);
                            setPayment_type_4((prev) => !prev);
                          }}
                          ref={(checkBox) => (checkBox_payment[3] = checkBox)}
                        />
                        <Stack horizontal>
                          <Stack styles={{ root: { marginTop: 10 } }}>(4) อื่นๆ (ระบุ)</Stack>
                          <TextField
                            id="otherPayment"
                            disabled={disable ? disable : false}
                            value={otherPayment}
                            onChange={(e) => handleOnChange(e)}
                          />
                        </Stack>
                      </Stack>
                    </span>
                  </Stack>
                  {payment_TypeError && (
                    <p
                      style={{
                        marginLeft: 10,
                        marginTop: 0,
                        marginBottom: 0,
                        color: 'red',
                        fontSize: 12,
                        fontWeight: 400,
                        fontFamily:
                          '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
                      }}
                    >
                      กรุณาเลือกแบบ
                    </p>
                  )}
                </span>
              </Stack>
            </Stack>
            <Stack horizontal>
              <Stack
                horizontal
                styles={{
                  root: {
                    display: 'flex',
                    border: '2px solid',
                    borderRadius: '5px',
                    width: '400px',
                  },
                }}
              >
                <Stack styles={{ root: { marginTop: 10, fontWeight: 'bold' } }}>คำเตือน</Stack>

                <span style={{ marginLeft: 10 }}>
                  <Stack vertical styles={{ root: { marginTop: 10 } }}>
                    <span>ผู้มีหน้าที่ออกหนังสือรับรองการหักภาษีณที่จ่าย</span>
                    <span>ฝ่าฝืนไม่ปฏิบัติตามมาตรา50 ทวิแห่งประมวล</span>
                    <span>รัษฎากร ต้องรับโทษทางอาญาตามมาตรา 35</span>
                    <span>แห่งประมวลรัษฎากร</span>
                  </Stack>
                </span>
              </Stack>
              <Stack
                vertical
                styles={{
                  root: {
                    alignItems: 'center',
                    border: '2px solid',
                    borderRadius: '5px',
                    width: 600,
                    paddingBottom: 10,
                  },
                }}
              >
                <span style={{ marginTop: 10 }}>ขอรับรองว่าข้อความและตัวเลขดังกล่าวข้างต้นถูกต้องตรงกับความจริงทุกประการ</span>

                <Stack horizontal>
                  <Stack vertical style={{ alignItems: 'center' }}>
                    <Stack horizontal>
                      <Stack styles={{ root: { marginTop: 20 } }}>ลงชื่อ&nbsp;</Stack>
                      <Stack styles={{ root: { marginTop: 10 } }}>
                        {file ? (
                          <img src={file} width="100px" alt="signatureFile" />
                        ) : (
                          <>
                            <input
                              type="file"
                              id="signature"
                              ref={(input) => (inputFiles[0] = input)}
                              onChange={(e) => handleUploadPic(e)}
                              style={{ display: 'none' }}
                            />
                            <PrimaryButton text="ลายเซ็น" onClick={() => inputFiles[0].click()} />
                          </>
                        )}
                      </Stack>
                      <Stack styles={{ root: { marginTop: 20 } }}>&nbsp;ผู้มีหน้าที่หัก ณ ที่จ่าย</Stack>
                    </Stack>
                    <Stack horizontal>
                      <DatePicker
                        name="date"
                        allowTextInput={true}
                        className={controlClass.control}
                        firstDayOfWeek={firstDayOfWeek}
                        strings={DayPickerStrings}
                        formatDate={onFormatDate}
                        placeholder="วัน/เดือน/ปี"
                        styles={{
                          root: { width: 195, marginBottom: 5, marginTop: 5 },
                        }}
                        onSelectDate={(date) => {
                          if (date !== null && date !== undefined) {
                            setDate(date.toISOString());
                            setDateBE(
                              `${date.getDate()}`.padStart(2, '0') +
                                '/' +
                                `${date.getMonth() + 1}`.padStart(2, '0') +
                                '/' +
                                (date.getFullYear() + 543)
                            );
                            setDateCE(
                              `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                            );
                          }
                        }}
                        onBlur={(e) => {
                          if (checkDateFormat(e.target.value)) {
                            let value = e.target.value;
                            let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
                            let date_parts = date.format().split('+');

                            let isoDate = date_parts[0] + '.000Z';

                            setDate(isoDate);
                            setDateBE(
                              `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                                '/' +
                                `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                                '/' +
                                (new Date(isoDate).getFullYear() + 543)
                            );
                            setDateCE(
                              `${new Date(isoDate).getDate()}`.padStart(2, '0') +
                                '/' +
                                `${new Date(isoDate).getMonth() + 1}`.padStart(2, '0') +
                                '/' +
                                new Date(isoDate).getFullYear()
                            );
                          } else {
                            setDate('');
                            setDateBE('');
                            setDateCE('');
                          }
                        }}
                        isRequired
                        value={new Date(date)}
                      />
                    </Stack>

                    <span>(วัน เดือน ปี ที่ออกหนังสือรับรองฯ)</span>
                  </Stack>

                  <Stack vertical style={{ alignItems: 'center' }}>
                    <Stack style={{ marginLeft: 20, marginTop: 10 }}>
                      {seal_file ? (
                        <img src={seal_file} height="80" alt="sealFile" />
                      ) : (
                        <>
                          <input
                            type="file"
                            id="seal"
                            ref={(input) => (inputFiles[1] = input)}
                            onChange={(e) => handleUploadPic(e)}
                            style={{ display: 'none' }}
                          />
                          <PrimaryButton text="ประทับตรานิติบุคคล (ถ้ามี)" onClick={() => inputFiles[1].click()} />
                        </>
                      )}
                    </Stack>
                    <Stack style={{ marginLeft: 20, marginTop: 5 }}>(ใช้รูปภาพแบบไม่มีพื้นหลัง)</Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack vertical>
            <span>หมายเหตุ เลขประจำตัวผู้เสียภาษีอากร (13 หลัก)* หมายถึง 1. กรณีบุคคลธรรมดาไทย ให้ใช้เลขประจำตัวประชาชนของกรมการปกครอง</span>
            <span style={{ paddingLeft: '382px' }}>2. กรณีนิติบุคคล ให้ใช้เลขทะเบียนนิติบุคคลของกรมพัฒนาธุรกิจการค้า</span>
            <span style={{ paddingLeft: '382px' }}>3. กรณีอื่นๆ นอกเหนือจาก 1. และ 2. ให้ใช้เลขประจำตัวผู้เสียภาษีอากร (13 หลัก) ของกรมสรรพากร</span>
          </Stack>
          <br />
          {paymentTypeError && (
            <p
              style={{
                textAlign: 'center',
                color: 'red',
                fontSize: 12,
                fontWeight: 400,
                fontFamily:
                  '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
              }}
            >
              กรุณาระบุประเภทเงินได้พึงประเมินที่จ่ายอย่างน้อย 1 แถว
            </p>
          )}
          {withholdingTaxNumberError && (
            <p
              style={{
                textAlign: 'center',
                margin: 0,
                color: 'red',
                fontSize: 12,
                fontWeight: 400,
                fontFamily:
                  '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
              }}
            >
              กรุณากรอกเลขประจำตัวผู้เสียภาษี
            </p>
          )}
          <br />
          {companyName && (
            <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
              <PrimaryButton text={t('withholdingTax.next')} type="submit" value="next" />
              <DefaultButton text={t('withholdingTax.cancel')} onClick={handleCancel} />
            </Stack>
          )}
          {!companyName && <Spinner label={t('withholdingTax.loading')} labelPosition="right" />}
          <br />
          <br />
        </div>
        <br />
        <br />
      </Stack>
    </form>
  );
};

export default WitholdingTax;
