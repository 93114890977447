import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import paginate from 'jw-paginate';

import { getTheme, SelectionMode, DetailsListLayoutMode, ShimmeredDetailsList } from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const PaymentHistory = () => {
  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [pageData, setPageData] = useState({});
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);
    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    let hour = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  useEffect(() => {
    console.log('userEffect get orders Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        // Do something with the tokenResponse
        console.log('Token response: ', tokenResponse);

        axios
          .get(API_URL + '/billings/orders', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);
              console.log('Docs number: ', response.data.length);

              //set page
              console.log('Page data: ', paginate(response.data.length, 1, 5, 10));
              setPageData(paginate(response.data.length, currentPage, pageSize, 10));

              setDocuments(response.data);

              const _docs = [];

              if (response.data.length > 0) {
                for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, response.data.length); i++) {
                  let createdTime = toThaiDateString(response.data[i].createdTime);

                  let _grandTotal = 0;
                  let _description = '';

                  let _status = '';
                  let _paymentMethod = '';

                  if (response.data[i].description) {
                    _description = response.data[i].description;
                  }

                  if (response.data[i].data && response.data[i].data.grandTotal && response.data[i].data.grandTotal !== undefined) {
                    _grandTotal = response.data[i].data.grandTotal;
                  }

                  if (response.data[i].payment.status) {
                    if (response.data[i].payment.status === 'successful') {
                      _status = 'สำเร็จ';
                    } else if (response.data[i].payment.status === 'pending') {
                      _status = 'รอชำระ';
                    }
                  }

                  if (response.data[i].payment.method) {
                    _paymentMethod = 'QR Code เงินสด';
                  }

                  _docs.push({
                    key: response.data[i].id,
                    createdBy: response.data[i].createdBy,
                    createdTime: createdTime,
                    createdTimeValue: response.data[i].createdTime,
                    description: _description,
                    paymentMethod: _paymentMethod,
                    paymentStatus: _status,
                    grandTotal: _grandTotal,
                  });
                }
              }

              console.log('Docs data', _docs);
              setItems(_docs);

              setLoadDataComplete(true);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (documents.length > 0) {
      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
        let createdTime = toThaiDateString(documents[i].createdTime);

        let _grandTotal = 0;
        let _status = '';
        let _paymentMethod = '';

        if (documents[i].data && documents[i].data.grandTotal && documents[i].data.grandTotal !== undefined) {
          _grandTotal = documents[i].data.grandTotal;
        }

        if (documents[i].payment.status) {
          if (documents[i].payment.status === 'successful') {
            _status = 'สำเร็จ';
          } else if (documents[i].payment.status === 'pending') {
            _status = 'รอชำระ';
          }
        }

        if (documents[i].payment.method) {
          _paymentMethod = 'QR Code เงินสด';
        }

        _docs.push({
          key: documents[i].id,
          createdBy: documents[i].createdBy,
          createdTime: createdTime,
          createdTimeValue: documents[i].createdTime,
          description: documents[i].description,
          paymentMethod: _paymentMethod,
          paymentStatus: _status,
          grandTotal: _grandTotal,
        });
      }
      console.log('Docs data', _docs);

      setItems(_docs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const columns = [
    {
      key: 'column1',
      name: 'วันที่',
      fieldName: 'date',
      minWidth: 150,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.createdTime}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: 'รายละเอียด',
      fieldName: 'description',
      minWidth: 180,
      maxWidth: 350,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        return <span>{item.description}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'วิธีชำระเงิน',
      fieldName: 'paymentMethod',
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        return <span>{item.paymentMethod}</span>;
      },
      isPadded: true,
    },

    {
      key: 'column4',
      name: 'จำนวนเงิน',
      fieldName: 'amount',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.grandTotal.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บ.
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'สถานะ',
      fieldName: 'status',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        return <span>{item.paymentStatus}</span>;
      },
    },
  ];

  return (
    <div>
      <div
        style={{
          width: '880px',
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 0,
          paddingBottom: 10,
          background: '#ffffff',
          marginLeft: '0',
          marginTop: '0',
          boxShadow: theme.effects.elevation4,
        }}
      >
        <ShimmeredDetailsList
          items={items || []}
          enableShimmer={!loadDataComplete}
          compact={false}
          columns={columns}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
        />
      </div>

      <br />
      <center>
        {!(documents.length === 0 || documents.length < pageSize) && (
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={pageData.totalPages}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
          />
        )}
      </center>
    </div>
  );
};

export default PaymentHistory;
