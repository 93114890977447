import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../../../authConfig';

import { PrimaryButton, Stack, getTheme, Spinner, Separator, ChoiceGroup } from '@fluentui/react';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

//Style
const centerItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const stackTokens = { childrenGap: 10 };

const DocumentsCheckout = () => {
  const history = useHistory();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const companyId = sessionStorage.getItem('companyId') || '';

  const [credit, setCredit] = useState(50);
  const [price, setPrice] = useState(399);

  const [vatTotal, setVatTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const [base64QrCode, setBase64QrCode] = useState('');

  const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyTaxId, setCompanyTaxId] = useState('');
  const [companyBranchText, setCompanyBranchText] = useState('');
  const [companyAllAddress, setCompanyAllAddress] = useState('');

  // Processing dialog style
  const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4,
    },
  };

  const calGrandTotal = (_price) => {
    console.log('Price: ', _price);
    let vat = 0.07;
    let _vatTotal = Math.round(vat * _price * 100) / 100;
    setVatTotal(_vatTotal);
    setGrandTotal(_price + _vatTotal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    window.scrollTo(0, 0);

    console.log('Submit!');

    console.log('Credit: ', credit);
    console.log('Price: ', price);
    console.log('Vat Total: ', vatTotal);
    console.log('Grand Total: ', grandTotal);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            API_URL + '/gbprimepay/qrcode',
            {
              creditType: 'documents',
              creditAmount: credit,
              customer: company,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
              responseType: 'arraybuffer',
            }
          )
          .then((response) => {
            console.log('GB Prime Pay data: ', response);

            if (response.data) {
              setBase64QrCode('data:image/png;base64,' + Buffer.from(response.data, 'binary').toString('base64'));
            }

            setIsLoading(false);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log('Company state: ', company);

    if (company.taxId) {
      console.log('Company useEffect call!');
      console.log(company);
      console.log(company.id);

      let _company = company;

      let _companyAllAddress =
        company.buildingNumber +
        ' ' +
        (!/^-*$/.test(company.address) && company.address.trim() && !/^-*$/.test(company.address.trim()) ? company.address : '') +
        ' ' +
        (!/^-*$/.test(company.streetName) && company.streetName.trim() && !/^-*$/.test(company.streetName.trim())
          ? company.streetPrefix + company.streetName
          : '') +
        ' ' +
        company.subDistrictPrefix +
        company.subDistrictName +
        ' ' +
        company.districtPrefix +
        company.districtName +
        ' ' +
        company.provincePrefix +
        company.provinceName +
        ' ' +
        company.postcode;

      setCompanyAllAddress(_companyAllAddress);

      _company.allAddress = _companyAllAddress;

      setCompany(_company);

      setIsLoading(false);
    }
  }, [company]);

  useEffect(() => {
    console.log('userEffect initialize Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  console.log(_company);
  
                  setCompany(_company);
  
                  setCompanyName(_company.name);
                  setCompanyEmail(_company.email);
                  setCompanyTaxId(_company.taxId);
                  setCompanyBranchText(_company.branchText);
                } else {
                  console.log(response.data[0]);
  
                  setCompany(response.data[0]);
  
                  setCompanyName(response.data[0].name);
                  setCompanyEmail(response.data[0].email);
                  setCompanyTaxId(response.data[0].taxId);
                  setCompanyBranchText(response.data[0].branchText);

                }
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    calGrandTotal(price);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      {!base64QrCode && !isLoading && (
        <Stack>
          <div
            style={{
              width: '850px',
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 30,
              marginTop: 0,
              background: '#ffffff',
              marginLeft: '0px',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <div className="ms-Grid-row">
              <h2 style={{ margin: '0%' }}>{`1. เลือกปริมาณเอกสาร`}</h2>
              &nbsp;&nbsp;
              <ChoiceGroup
                name="plan"
                label={`เติมเครดิตเอกสารจำนวน`}
                selectedKey={credit}
                required
                options={[
                  {
                    key: 50,
                    text: '             50 เอกสาร (ราคา 399 บาท)            ',
                    imageSrc: '/images/paper.png',
                    selectedImageSrc: '/images/paper.png',
                    imageSize: { height: 50 },
                  },
                  {
                    key: 100,
                    text: '             100 เอกสาร (ราคา 799 บาท)            ',
                    imageSrc: '/images/paper.png',
                    selectedImageSrc: '/images/paper.png',
                    imageSize: { height: 50 },
                  },
                  {
                    key: 300,
                    text: '          300 เอกสาร (ราคา 2,399 บาท)         ',
                    imageSrc: '/images/paper.png',
                    selectedImageSrc: '/images/paper.png',
                    imageSize: { height: 50 },
                  },
                  {
                    key: 500,
                    text: '           500 เอกสาร (ราคา 3,499 บาท)         ',
                    imageSrc: '/images/paper.png',
                    selectedImageSrc: '/images/paper.png',
                    imageSize: { height: 50 },
                  },
                  {
                    key: 1000,
                    text: '           1,000 เอกสาร (ราคา 4,999 บาท)          ',
                    imageSrc: '/images/paper.png',
                    selectedImageSrc: '/images/paper.png',
                    imageSize: { height: 50 },
                  },
                  {
                    key: 3000,
                    text: '        3,000 เอกสาร (ราคา 8,999 บาท)        ',
                    imageSrc: '/images/paper.png',
                    selectedImageSrc: '/images/paper.png',
                    imageSize: { height: 50 },
                  },
                ]}
                onChange={(e, selectedOption) => {
                  console.log(selectedOption);
                  setCredit(selectedOption.key);

                  switch (selectedOption.key) {
                    case 50:
                      setPrice(399);
                      calGrandTotal(399);
                      break;
                    case 100:
                      setPrice(799);
                      calGrandTotal(799);
                      break;
                    case 300:
                      setPrice(2399);
                      calGrandTotal(2399);
                      break;
                    case 500:
                      setPrice(3499);
                      calGrandTotal(3499);
                      break;
                    case 1000:
                      setPrice(4999);
                      calGrandTotal(4999);
                      break;
                    case 3000:
                      setPrice(8999);
                      calGrandTotal(8999);
                      break;
                    default:
                      setPrice(0);
                  }
                }}
              />
              <br />
              <br />
            </div>
          </div>

          <div
            style={{
              width: '850px',
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 30,
              background: '#ffffff',
              marginLeft: '0px',
              marginTop: '30px',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <Stack horizontal>
              <Stack vertical style={{ width: '570px' }}>
                <h2 style={{ margin: '0%' }}>2. ข้อมูลสำหรับออก ใบเสร็จรับเงิน/ใบกำกับภาษี</h2>
                <br />

                <span style={{ color: '#106EBE', fontSize: '20px', fontWeight: 'bold' }}>
                  {companyName} ({companyBranchText})
                </span>
                <br />
                <span style={{ color: '#106EBE', fontSize: '18px' }}>{companyAllAddress}</span>
                <br />
                <span style={{ color: '#106EBE', fontSize: '18px' }}>เลขประจำตัวผู้เสียภาษี {companyTaxId}</span>
              </Stack>
              <Separator vertical />
              <Stack vertical style={{ marginLeft: '25px' }}>
                <br />
                <br />

                <span style={{ fontSize: '14px' }}>
                  ใบเสร็จรับเงิน/ใบกำกับภาษี <br />
                  จัดส่งที่อีเมล {companyEmail}
                  <br />
                  (ภายใน 3 วันทำการ)
                </span>
                <br />
                <span style={{ fontSize: '14px' }}>
                  *หากต้องการเปลี่ยนข้อมูล <br />
                  กรุณาติดต่อ Line: @leceipt <br />
                  หลังจากชำระเงินทันที
                </span>
              </Stack>
            </Stack>

            <br />
            <br />
          </div>

          <div
            style={{
              width: '850px',
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 30,
              background: '#ffffff',
              marginLeft: '0px',
              marginTop: '30px',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <Stack horizontal tokens={stackTokens}>
              <Stack vertical tokens={stackTokens}>
                <h2 style={{ margin: '0%' }}>3. เลือกวิธีการชำระเงิน</h2>
                &nbsp;&nbsp;
                <h4 style={{ marginTop: '-5px' }}>เติมเครดิตอัตโนมัติ เร็วทันใจ ตลอด 24 ชม.</h4>
                <Stack horizontal tokens={stackTokens}>
                  <img
                    src={'/images/promtpay-logo.png'}
                    width="260"
                    alt="promtpayLogo"
                    border="0"
                    style={{
                      marginLeft: '0px',
                      marginTop: '-20px',
                    }}
                  ></img>
                </Stack>
                <small>*ซื้อแล้ว ไม่คืนเงิน</small>
              </Stack>

              <Separator vertical />

              <Stack
                vertical
                tokens={stackTokens}
                style={{
                  marginLeft: '0px',
                  paddingLeft: '15px',
                }}
              >
                <h1
                  style={{
                    marginTop: '5px',
                    marginLeft: '410px',
                  }}
                >
                  สรุปคำสั่งซื้อ
                </h1>
                <Separator />
                <h3 style={{ textAlign: 'right' }}>
                  เติมเครดิตเอกสาร จำนวน{' '}
                  {credit.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  })}
                  &nbsp;เอกสาร&nbsp;&nbsp;&nbsp;
                  <span style={{ paddingLeft: 90 }}>
                    {price.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </h3>

                <h3 style={{ textAlign: 'right' }}>
                  ภาษีมูลค่าเพิ่ม 7%&nbsp;
                  <span style={{ paddingLeft: 110 }}>
                    {vatTotal.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </h3>
                <br />
                <Separator />
                <h2
                  style={{
                    marginTop: '5px',
                    textAlign: 'right',
                  }}
                >
                  ยอดรวม
                  <span style={{ paddingLeft: 80 }}>
                    {grandTotal.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </h2>

                <div></div>
                <center>
                  <br />
                  <small>
                    หากกดปุ่ม "ชำระเงิน" ถือว่าท่านยอมรับ{' '}
                    <a href="https://www.leceipt.com/terms-conditions" target="_blank" rel="noreferrer">
                      เงื่อนไขและข้อตกลงการใช้งาน
                    </a>
                  </small>
                  <br />
                  <br />
                  <br />
                  <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
                    {!isLoading ? (
                      <PrimaryButton
                        type="submit"
                        text="ชำระเงินผ่าน QR Code"
                        value="pay"
                        style={{
                          width: 550,
                          height: 50,
                          fontSize: 25,
                          letterSpacing: '1.5px',
                        }}
                      />
                    ) : (
                      <Spinner label="กำลังดำเนินการ..." labelPosition="right" />
                    )}
                  </Stack>
                </center>
              </Stack>
            </Stack>
            <br />

            <br />
            <br />
          </div>

          <br />
          <br />
        </Stack>
      )}

      {base64QrCode && !isLoading && (
        <Stack vertical>
          <center>
            <h1 style={{ marginLeft: '0px', marginTop: '25px' }}>
              {`เติมเครดิต: ` +
                credit.toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }) +
                ` เอกสาร`}
            </h1>
            <h3>ระบบจะเติมเครดิตให้อัตโนมัติหลังจากชำระเงิน ตลอด 24 ชม.</h3>
            <img src={base64QrCode} width="433px" alt="base64qrcode" />
          </center>
        </Stack>
      )}

      {isLoading && (
        <Stack>
          <br />
          <br />
          <br />
          <Stack horizontal horizontalAlign="center">
            <div>
              {company.taxId ? (
                <Spinner label="กำลังดำเนินการ..." labelPosition="down" styles={spinnerStyles} />
              ) : (
                <Spinner label="กำลังโหลด..." labelPosition="down" styles={spinnerStyles} />
              )}
            </div>
            <br />
          </Stack>
        </Stack>
      )}
    </form>
  );
};

export default DocumentsCheckout;
