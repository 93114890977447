import React from 'react';

//center loading
const centerItem = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

const Loading = () => {
  console.log('Loading re-render');

  return (
    <div style={{ height: '100vh' }}>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div style={centerItem}>
        <img width="60" src="/images/loading.gif" alt="loadinggif" />
      </div>
    </div>
  );
};

export default Loading;
