import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL;

export async function updateProductQuantity(items, tokenResponse) {
    const companyId = sessionStorage.getItem('companyId') || '';

    const fetchProductArray = []

        items.forEach(_item => {
        if (_item.sku) {
            fetchProductArray.push(
            new Promise((resolve, reject) => {
                axios
                .get(
                `${API_URL}/products?search=${_item.sku}`, 
                {
                    headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                        'Content-Type': 'application/json',
                        'cid': companyId
                    },
                }
                ).then((response => {
                console.log(response)

                const { count, products } = response.data.data

                if (count === 1) {
                    resolve(products[0])
                } else {
                    resolve({})
                }
                })).catch(err => resolve({}))

            })

            
            )
        }
        })
        

        await Promise.all(fetchProductArray).then((valueArrayOnFinish) => {
            const filterEmptyObject = valueArrayOnFinish.filter(value => Object.keys(value).length !== 0);
            const removeDuplicateArray = filterEmptyObject.filter(
                (_product, index, _productList) =>
                index === _productList.findIndex((_pd) => _pd.id === _product.id)
            )

            removeDuplicateArray.forEach((_product => {
                const { skus } = _product.data
    
                console.log("skus", skus)
    
                skus.forEach(_sku => {
                items.forEach(_item => {
                    if (_sku.sellerSku === _item.sku) {
                        _sku.available = _sku.available - _item.quantity
    
                        console.log("_sku", _sku)
                    }
                })
                })
            }))
    
            console.log("removeDuplicateArray", removeDuplicateArray)
    
            removeDuplicateArray.forEach(_updatedProduct => (
            axios
                .patch(
                API_URL + '/products/' + _updatedProduct.id,
                {
                    replace: '/data',
                    value: _updatedProduct.data,
                },
                {
                    headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'Content-Type': 'application/json',
                    'cid': companyId
                    },
                }
                ).then(response => {
                console.log(response)
                })
            ))
        

      })

}