import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Stack, Icon, PrimaryButton, FocusTrapZone, Spinner } from '@fluentui/react';

const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
const API_URL = process.env.REACT_APP_API_URL;

const client_id = process.env.REACT_APP_QUICKBOOKS_CLIENT_ID;
const redirect_uri = PUBLIC_URL + '/etax/sources/quickbooks/connect';

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const QuickBooksConnect = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t } = useTranslation();

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const [status, setStatus] = useState('');

  const csrfState = Math.random().toString(36).substring(7);

  const callBackUrl = `https://appcenter.intuit.com/connect/oauth2?client_id=${client_id}&response_type=code&scope=com.intuit.quickbooks.accounting&redirect_uri=${redirect_uri}&state=${csrfState}`;

  useEffect(() => {
    console.log('userEffect retrieve users data!');

    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    const realmId = query.get('realmId');

    if (code) {
      setIsLoading(true);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + '/sources/quickbooks',
              {
                code: code,
                realmId: realmId,
                redirect_uri: redirect_uri,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              }
            )
            .then(
              (response) => {
                console.log(response);

                if (response.data) {
                  if (response.data.canLogin) {
                    history.push({
                      pathname: '/etax/sources/quickbooks',
                      state: {},
                    });
                  } else {
                    setStatus('fail');
                  }
                }

                setIsLoading(false);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/sources/quickbooks', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            })
            .then(
              (response) => {
                console.log('QuickBooks sources: ', response);

                if (response.data) {
                  if (!response.data.disabled && response.data.canLogin) {
                    history.push({
                      pathname: '/etax/sources/quickbooks',
                      state: {},
                    });
                  }
                }

                setIsLoading(false);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {!isLoading && (
        <Stack>
          <br />

          <h1 style={{ marginLeft: '0px', marginTop: '27px' }}>
            <Icon iconName="CloudImportExport" style={{ color: '#106ebe' }} /> {t('quickBookConnect.header')}
          </h1>
          <h3 style={{ marginTop: '0px', marginBottom: '10px' }}>{t('quickBookConnect.subHeader1')}</h3>

          <br />
          <br />
        </Stack>
      )}
      {status === '' && !isLoading && (
        <FocusTrapZone disabled={true}>
          <Stack horizontalAlign="center">
            <Stack
              style={{
                border: '1px solid rgb(134, 134, 134)',
                width: '350px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <br />
              <Stack horizontal horizontalAlign="center">
                <img src="/images/quickbooks.jpeg" width="200px" style={{ margin: 20 }} alt="quickbook" />
              </Stack>
              <form>
                <br />
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
                  <PrimaryButton
                    style={{
                      fontSize: '20px',
                      height: '50px',
                      width: '255px',
                    }}
                    href={callBackUrl}
                  >
                    {t('quickBookConnect.connect')}
                  </PrimaryButton>
                </Stack>
              </form>
              <br />
              <br />
            </Stack>
          </Stack>
        </FocusTrapZone>
      )}
      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              {false ? (
                <Spinner label={t('quickBookConnect.processing')} labelPosition="down" styles={spinnerStyles} />
              ) : (
                <Spinner label={t('quickBookConnect.loading')} labelPosition="down" styles={spinnerStyles} />
              )}
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'success' && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: '1px solid rgb(134, 134, 134)',
              width: '300px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#0078D4' }}>{t('quickBookConnect.successMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/success03.gif" alt="success03gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  history.push({
                    pathname: '/etax/sources/paypal',
                    state: {},
                  });
                }}
              >
                {t('quickBookConnect.successButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'fail' && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: '1px solid rgb(134, 134, 134)',
              width: '300px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#F25139' }}>{t('quickBookConnect.failMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/fail01.gif" alt="fail01gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  setStatus('');
                }}
              >
                {t('quickBookConnect.failButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      <br />
      <br />
      {status === '' && !isLoading && (
        <center>
          <br />
          <br />
          <span style={{ color: 'gray' }}>{t('quickBookConnect.footer1')}</span>
          <br />
          <br />
          <span style={{ color: 'gray', fontWeight: 'bold' }}>{t('quickBookConnect.footer2')}</span>
        </center>
      )}
    </Stack>
  );
};

export default QuickBooksConnect;
