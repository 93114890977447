import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../../../authConfig';
import { useTranslation } from 'react-i18next';

import Handlebars from 'handlebars';

import { useHistory, useLocation } from 'react-router-dom';

import { PrimaryButton, DefaultButton, Stack, Spinner, MessageBar, MessageBarType } from '@fluentui/react';
import PdfAllPages from '../../../documents/pdf/view/PdfAllPages';

const API_URL = process.env.REACT_APP_API_URL;

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

const TemplatePreview = () => {
  console.log('Re render');

  const { t, i18n } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const data = location.state;
  console.log(data);

  if (data === undefined) {
    history.push('/etax/templates/pdf');
  }

  const jsonData = JSON.parse(data.data);
  console.log(jsonData);

  Handlebars.registerHelper('decimal', function (number) {
    return parseFloat(number).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  });

  Handlebars.registerHelper('isValue', function (value) {
    return value !== undefined && value !== null && value !== ' ' && value !== '-';
  });

  Handlebars.registerHelper('numberWithCommas', function (number) {
    return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  });

  Handlebars.registerHelper('filterPostcode', function (postcode) {
    let _filterPostcode = '';

    if (postcode !== '00000') {
      _filterPostcode = postcode;
    }

    return _filterPostcode;
  });

  Handlebars.registerHelper('notShowThailand', function (countryName) {
    let _notShowThailand = '';

    if (countryName !== 'Thailand' && countryName !== 'ไทย') {
      _notShowThailand = countryName;
    }

    return _notShowThailand;
  });

  const template = Handlebars.compile(data.htmlTemplate);
  const htmlString = template(jsonData);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [documentType, setDocumentType] = useState({
    key: data.documentType.key,
    text: data.documentType.text,
  });
  const [clickSave, setClickSave] = useState(false);
  const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);
  const [showMessageBarError, setShowMessageBarError] = useState(false);

  const [url, setUrl] = useState('');

  const companyId = sessionStorage.getItem('companyId') || '';

  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('submit click');

    setClickSave(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        let _apiEndpoint = API_URL + '/etax/templates/' + documentType.key + '/pdf';

        if (documentType.key === 'quotation' || documentType.key === 'invoice' || documentType.key === 'billing-note' || documentType.key === 'deliveryorder') {
          _apiEndpoint = API_URL + '/documents/templates/' + documentType.key + '/pdf';
        }

        axios
          .put(
            _apiEndpoint,
            {
              '$content-type': 'text/plain',
              $content: data.htmlTemplate,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
                'cid': companyId
            },
            }
          )
          .then(
            (response) => {
              console.log(response);
              setClickSave(false);
              setShowMessageBarSuccess(true);
              window.scrollTo(0, 0);
            },
            (error) => {
              console.log(error);
              setClickSave(false);
              setShowMessageBarError(true);
              window.scrollTo(0, 0);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const handleEdit = (e) => {
    e.preventDefault();

    history.push({
      pathname: '/etax/templates/pdf',
      state: data,
    });
  };

  const getDocumentText = (documentTypeObj) => {
    let _documentTypeText = '';
    if (documentTypeObj.key === 'receipt-taxinvoice') _documentTypeText = t('pdfTemplate.receipt-taxinvoice');
    if (documentTypeObj.key === 'receipt-taxinvoice-abb') _documentTypeText = t('pdfTemplate.receipt-taxinvoice-abb');
    if (documentTypeObj.key === 'receipt') _documentTypeText = t('pdfTemplate.receipt');
    if (documentTypeObj.key === 'invoice-taxinvoice') _documentTypeText = t('pdfTemplate.invoice-taxinvoice');
    if (documentTypeObj.key === 'deliveryorder-taxinvoice') _documentTypeText = t('pdfTemplate.deliveryorder-taxinvoice');
    if (documentTypeObj.key === 'taxinvoice') _documentTypeText = t('pdfTemplate.taxinvoice');
    if (documentTypeObj.key === 'debit-note') _documentTypeText = t('pdfTemplate.debit-note');
    if (documentTypeObj.key === 'credit-note') _documentTypeText = t('pdfTemplate.credit-note');
    if (documentTypeObj.key === 'quotation') _documentTypeText = t('pdfTemplate.quotation');
    if (documentTypeObj.key === 'invoice') _documentTypeText = t('pdfTemplate.invoice');
    if (documentTypeObj.key === 'billing-note') _documentTypeText = t('pdfTemplate.billing-note');
    if (documentTypeObj.key === 'deliveryorder') _documentTypeText = t('pdfTemplate.deliveryorder');

    return _documentTypeText;
  };

  useEffect(() => {
    console.log('userEffect call get pdf!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            API_URL + '/templates/htmltopdf',
            { htmlString: htmlString },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
                'cid': companyId
              },
              responseType: 'blob',
            }
          )
          .then((response) => {
            setUrl(window.URL.createObjectURL(response.data));
          });
      })
      .catch((error) => {
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('userEffect call documentType change!');
  }, [documentType]);

  // useEffect(() => {
  //   console.log('userEffect initial call!');
  //   let _documentTypeText = '';
  //   if (data.documentType.key === 'receipt-taxinvoice') _documentTypeText = t('pdfTemplate.receipt-taxinvoice');
  //   if (data.documentType.key === 'receipt-taxinvoice-abb') _documentTypeText = t('pdfTemplate.receipt-taxinvoice-abb');
  //   if (data.documentType.key === 'receipt') _documentTypeText = t('pdfTemplate.receipt');
  //   if (data.documentType.key === 'invoice-taxinvoice') _documentTypeText = t('pdfTemplate.invoice-taxinvoice');
  //   if (data.documentType.key === 'deliveryorder-taxinvoice') _documentTypeText = t('pdfTemplate.deliveryorder-taxinvoice');
  //   if (data.documentType.key === 'taxinvoice') _documentTypeText = t('pdfTemplate.taxinvoice');
  //   if (data.documentType.key === 'debit-note') _documentTypeText = t('pdfTemplate.debit-note');
  //   if (data.documentType.key === 'credit-note') _documentTypeText = t('pdfTemplate.credit-note');
  //   if (data.documentType.key === 'quotation') _documentTypeText = t('pdfTemplate.quotation');
  //   if (data.documentType.key === 'invoice') _documentTypeText = t('pdfTemplate.invoice');
  //   if (data.documentType.key === 'billing-note') _documentTypeText = t('pdfTemplate.billing-note');

  //   setDocumentType({
  //     key: data.documentType.key,
  //     text: _documentTypeText,
  //   });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useDidMountEffect(() => {
    let _documentTypeText = '';
    if (documentType.key === 'receipt-taxinvoice') _documentTypeText = t('pdfTemplate.receipt-taxinvoice');
    if (documentType.key === 'receipt-taxinvoice-abb') _documentTypeText = t('pdfTemplate.receipt-taxinvoice-abb');
    if (documentType.key === 'receipt') _documentTypeText = t('pdfTemplate.receipt');
    if (documentType.key === 'invoice-taxinvoice') _documentTypeText = t('pdfTemplate.invoice-taxinvoice');
    if (documentType.key === 'deliveryorder-taxinvoice') _documentTypeText = t('pdfTemplate.deliveryorder-taxinvoice');
    if (documentType.key === 'taxinvoice') _documentTypeText = t('pdfTemplate.taxinvoice');
    if (documentType.key === 'debit-note') _documentTypeText = t('pdfTemplate.debit-note');
    if (documentType.key === 'credit-note') _documentTypeText = t('pdfTemplate.credit-note');
    if (documentType.key === 'quotation') _documentTypeText = t('pdfTemplate.quotation');
    if (documentType.key === 'invoice') _documentTypeText = t('pdfTemplate.invoice');
    if (documentType.key === 'billing-note') _documentTypeText = t('pdfTemplate.billing-note');
    if (documentType.key === 'deliveryorder') _documentTypeText = t('pdfTemplate.deliveryorder');

    setDocumentType((prev) => ({ ...prev, text: _documentTypeText }));
  }, [i18n.language]);

  return (
    <Stack
      vertical
      verticalAlign="start"
      style={{
        background: 'rgb(204,204,204)',
        width: '100%',
        minHeight: '100vh',
      }}
    >
      {showMessageBarSuccess && (
        <div>
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => setShowMessageBarSuccess(false)}
            dismissButtonAriaLabel="Close"
          >
            {t('templatePreview.updateSuccess')}
          </MessageBar>
          <br />
        </div>
      )}
      {showMessageBarError && (
        <div>
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setShowMessageBarError(false)}
            dismissButtonAriaLabel="Close"
          >
            {t('templatePreview.updateFail')}
          </MessageBar>
          <br />
        </div>
      )}
      <h2 style={centerItem}>
        {t('templatePreview.header')} {getDocumentText(documentType)}
      </h2>

      <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
        {!clickSave && <PrimaryButton text={t('templatePreview.save')} iconProps={{ iconName: 'SaveTemplate' }} onClick={handleSubmit} />}
        {!clickSave && <DefaultButton text={t('templatePreview.back')} iconProps={{ iconName: 'Back' }} onClick={handleEdit} />}

        {clickSave && <Spinner label={t('templatePreview.saving')} labelPosition="right" />}
      </Stack>

      <br />
      <br />
      {!url && <Spinner label={t('templatePreview.loading')} labelPosition="down" styles={spinnerStyles} />}
      {url && (
        <Stack horizontal horizontalAlign="center">
          <PdfAllPages pdf={url} />
        </Stack>
      )}
      <br />
    </Stack>
  );
};

export default TemplatePreview;
