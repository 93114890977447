import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { useHistory, useLocation } from 'react-router-dom';

import { PrimaryButton, DefaultButton, Stack, getTheme, Spinner, ChoiceGroup, Separator, Dialog, DialogType } from '@fluentui/react';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

//Style
const centerItem = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

const stackTokens = { childrenGap: 15 };

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const Confirm = () => {
  console.log('Re render');

  const history = useHistory();
  const location = useLocation();

  const [data] = useState(location.state);

  if (data && data.id === '') {
    history.push({
      pathname: '/etax/signatures/register/companies',
      state: {},
    });
  }

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [period, setPeriod] = useState(1);
  const [taxId, setTaxId] = useState('');
  const [type, setType] = useState('');
  const [nameTH, setNameTH] = useState('');
  const [nameEN, setNameEN] = useState('');
  const [email, setEmail] = useState('');

  const [id, setId] = useState('');
  const [address, setAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [district, setDistrict] = useState('');
  const [province, setProvince] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [contactMobile, setContactMobile] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  const [enterpriseNameTH, setEnterpriseNameTH] = useState('');
  const [enterpriseNameEN, setEnterpriseNameEN] = useState('');

  const [isUpdating, setIsUpdating] = useState(false);

  const companyId = sessionStorage.getItem('companyId') || '';

  function handleSubmit(event) {
    event.preventDefault();

    setIsUpdating(true);

    let submitData = {
      period: period,
      taxId: taxId,
      type: type,
      nameTH: nameTH,
      nameEN: nameEN,
      email: email,

      id: id,
      address: address,
      postcode: postcode,
      district: district,
      province: province,
      phone: phone,
      mobile: mobile,

      firstName: firstName,
      lastName: lastName,
      contactMobile: contactMobile,
      contactEmail: contactEmail,

      ca: 'INET',
      status: 'created',
    };

    console.log('Submit data: ', JSON.stringify(submitData));

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(API_URL + '/certificate-requests', submitData, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'application/json',
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data.id) {
                history.push({
                  pathname: '/etax/certificates',
                  state: response.data,
                });

                setIsUpdating(false);
              }
            },
            (error) => {
              console.log(error);

              setIsUpdating(false);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        if (error.errorMessage.indexOf('interaction_required') !== -1) {
          instance.acquireTokenRedirect(silentRequest);
        }

        setIsUpdating(false);
      });
  }

  const handleEdit = (e) => {
    e.preventDefault();

    history.push({
      pathname: '/ca/contacts',
      state: {
        period: period,
        taxId: taxId,
        type: type,
        nameTH: nameTH,
        nameEN: nameEN,
        email: email,

        id: id,
        address: address,
        postcode: postcode,
        district: district,
        province: province,
        phone: phone,
        mobile: mobile,

        firstName: firstName,
        lastName: lastName,
        contactMobile: contactMobile,
        contactEmail: contactEmail,
      },
    });
  };

  useEffect(() => {
    console.log('useEffect data call!: ', data);

    if (data !== undefined) {
      setPeriod(data.period);
      setTaxId(data.taxId);
      setType(data.type);
      setNameTH(data.nameTH);
      setNameEN(data.nameEN);
      setEmail(data.email);

      setId(data.id);
      setAddress(data.address);
      setPostcode(data.postcode);
      setDistrict(data.district);
      setProvince(data.province);
      setPhone(data.phone);
      setMobile(data.mobile);

      setFirstName(data.firstName);
      setLastName(data.lastName);
      setContactMobile(data.contactMobile);
      setContactEmail(data.contactEmail);

      if (data.type === 'ห้างหุ้นส่วนสามัญ') {
        setEnterpriseNameTH(`ห้างหุ้นส่วนสามัญ ${data.nameTH}`);
        setEnterpriseNameEN(`${data.nameEN} Ordinary Partnership`);
      } else if (data.type === 'ห้างหุ้นส่วนจำกัด') {
        setEnterpriseNameTH(`ห้างหุ้นส่วนจำกัด ${data.nameTH}`);
        setEnterpriseNameEN(`${data.nameEN} Limited Partnership`);
      } else if (data.type === 'บริษัทจำกัด') {
        setEnterpriseNameTH(`บริษัท ${data.nameTH} จำกัด`);
        setEnterpriseNameEN(`${data.nameEN} Company Limited`);
      } else if (data.type === 'บริษัทมหาชนจำกัด') {
        setEnterpriseNameTH(`บริษัท ${data.nameTH} จำกัด (มหาชน)`);
        setEnterpriseNameEN(`${data.nameEN} Public Company Limited`);
      } else {
        setEnterpriseNameTH(data.nameTH);
        setEnterpriseNameEN(data.nameEN);
      }
    }
  }, [data]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h2 style={{ marginLeft: '2%', marginTop: '4%' }}>ข้อมูลสำหรับขอใบรับรองอิเล็กทรอนิกส์</h2>
        <div
          className="ms-Grid"
          dir="ltr"
          style={{
            width: '750px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 30,
            background: '#ffffff',
            margin: '2%',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <div className="ms-Grid-row">
            <b style={{ margin: '0%' }}>1. ข้อมูลบนใบรับรองอิเล็กทรอนิกส์</b>
            <Separator />

            <Stack horizontal tokens={stackTokens} style={{ paddingLeft: '5%' }}>
              <ChoiceGroup
                selectedKey={period}
                options={[
                  { key: 1, text: 'ใบรับรองอิเล็กทรอนิกส์ 1 ปี (ราคา 3,000 ยังไม่รวม Vat)' },
                  { key: 2, text: 'ใบรับรองอิเล็กทรอนิกส์ 2 ปี (ราคา 5,000 ยังไม่รวม Vat)' },
                ]}
                onChange={(e, selectedOption) => setPeriod(selectedOption.key)}
                label="ระยะเวลาที่ใช้ใบรับรองอิเล็กทรอนิกส์"
                required={true}
              />
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="left" style={{ paddingLeft: '5%' }}>
              <Stack vertical verticalAlign="top" style={{ textAlign: 'right' }}>
                <br />
                <span>เลขประจำตัวผู้เสียภาษีของนิติบุคคล :</span>

                <br />
                <span>ชื่อนิติบุคคล / องค์กร ภาษาไทย (CN) :</span>

                <br />
                <span>อีเมล :</span>
                <br />
              </Stack>
              <Stack vertical verticalAlign="top" style={{ textAlign: 'left', paddingLeft: '1%' }}>
                <br />
                <b>{taxId}</b>

                <br />
                {type === 'ห้างหุ้นส่วนสามัญ' && <b>ห้างหุ้นส่วนสามัญ {nameTH}</b>}
                {type === 'ห้างหุ้นส่วนจำกัด' && <b>ห้างหุ้นส่วนจำกัด {nameTH}</b>}
                {type === 'บริษัทจำกัด' && <b>บริษัท {nameTH} จำกัด</b>}
                {type === 'บริษัทมหาชนจำกัด' && <b>บริษัท {nameTH} จำกัด (มหาชน)</b>}
                {type === 'นิติบุคคลอื่น ภายใต้กฎหมายเฉพาะ' && <b>{nameTH}</b>}
                <br />
                <b>{email}</b>
                <br />
              </Stack>
            </Stack>
          </div>
        </div>

        <div
          className="ms-Grid"
          dir="ltr"
          style={{
            width: '750px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 30,
            background: '#ffffff',
            margin: '2%',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <div className="ms-Grid-row">
            <b style={{ margin: '0%' }}>2. ข้อมูลนิติบุคคล</b>
            <Separator />
            <Stack horizontal horizontalAlign="left" style={{ paddingLeft: '5%' }}>
              <Stack vertical verticalAlign="top" style={{ textAlign: 'right' }}>
                <br />
                <span>ทะเบียนนิติบุคคลเลขที่ :</span>
                <br />
                <span>เลขประจำตัวผู้เสียภาษีอากร :</span>
                <br />
                <span>ชื่อนิติบุคคล / องค์กร (ภาษาไทย) :</span>
                <br />
                <span>&nbsp;&nbsp;ชื่อนิติบุคคล / องค์กร (ภาษาอังกฤษ) :</span>
                <br />
                <span>ที่อยู่ (ตามหนังสือรับรองนิติบุคคล) :</span>
                <br />
                <span>โทรศัพท์ :</span>
                <br />
                <span>โทรศัพท์มือถือ :</span>
                <br />
              </Stack>
              <Stack vertical verticalAlign="top" style={{ textAlign: 'left', paddingLeft: '1%' }}>
                <br />
                <b>{id}</b>
                <br />
                <b>{taxId}</b>
                <br />
                <b>{enterpriseNameTH}</b>
                <br />
                <b>{enterpriseNameEN}</b>
                <br />
                <b>{address}</b>
                <br />
                <b>{phone}</b>
                <br />
                <b>{mobile}</b>
                <br />
              </Stack>
            </Stack>
          </div>
        </div>

        <div
          className="ms-Grid"
          dir="ltr"
          style={{
            width: '750px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 30,
            background: '#ffffff',
            margin: '2%',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <div className="ms-Grid-row">
            <b style={{ margin: '0%' }}>3. ข้อมูลผู้ประสานงาน</b>
            <Separator />

            <Stack horizontal horizontalAlign="left" style={{ paddingLeft: '5%' }}>
              <Stack vertical verticalAlign="top" style={{ textAlign: 'right' }}>
                <br />
                <span>ชื่อ-นามสกุล :</span>
                <br />
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;โทรศัพท์มือถือ
                  :
                </span>
                <br />
                <span>อีเมล :</span>
                <br />
              </Stack>
              <Stack vertical verticalAlign="top" style={{ textAlign: 'left', paddingLeft: '1%' }}>
                <br />
                <b>
                  {firstName} {lastName}
                </b>
                <br />
                <b>{contactMobile}</b>
                <br />
                <b>{contactEmail}</b>
                <br />
              </Stack>
            </Stack>
          </div>
        </div>
        <br />

        <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
          <PrimaryButton name="confirm" text="สร้างใบคำขอ" type="submit" />
          <DefaultButton text="ย้อนกลับ" style={{ marginLeft: '15px' }} onClick={handleEdit} />
        </Stack>

        <br />
        <br />
        <br />
        <Dialog
          hidden={!isUpdating}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'กำลังดำเนินการ...',
          }}
        >
          <Spinner label="แบบคำขอใบรับรองฯ..." labelPosition="down" styles={spinnerStyles} />
        </Dialog>
      </form>
    </div>
  );
};

export default Confirm;
