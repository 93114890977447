import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';

export default function PdfAllPages(props) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { pdf } = props;

  //console.log('PDF data: ', pdf);

  return (
    <Document
      file={pdf}
      //file="/Test.pdf"
      //options={{ workerSrc: "/pdf.worker.js" }}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {/* <br/> */}

      {Array.from(new Array(numPages), (el, index) => (
        <div key={`page_${index + 1}`}>
          <Page pageNumber={index + 1} scale={1.5} />
          <br />
        </div>
      ))}

      {/* <Page pageNumber={1} /> */}
    </Document>
  );
}
