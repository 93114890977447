import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { parse } from 'papaparse';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../../authConfig';

import { Stack, Link, Dialog, Icon, DefaultButton, DialogFooter, DialogType } from '@fluentui/react';

var moment = require('moment'); // require
moment().format();

const xlsx = require('xlsx');

const API_URL = process.env.REACT_APP_API_URL;

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== '') return false;
  }
  return true;
}

const ExcelContext = React.createContext();

export default function DropzoneDragDrop(props) {
  const history = useHistory();

  const { t } = useTranslation();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: '.csv, .xlsx, .xls',
    multiple: false,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const [test, setTest] = useState(false);

  const [acceptedFilesList, setAcceptedFilesList] = useState([]);
  const [rowDetails, setRowDetails] = useState([]);

  const [passItems, setPassItems] = React.useState([]);
  const [failItems, setFailItems] = React.useState([]);

  const [disabled, setDisabled] = React.useState(false);

  const [complete, setComplete] = React.useState(false);

  const [inComplete, setInComplete] = React.useState(false);

  const [dialogAlert, setDialogAlert] = useState({
    isClick: false,
    message: '',
  });

  const setDefault = () => {
    setTest(true);

    setFailItems([]);
    setPassItems([]);
    setDisabled(false);
    setComplete(false);
    setInComplete(false);
    setAcceptedFilesList([]);
    setRowDetails([]);
  };

  useEffect(() => {
    let newList = [...acceptedFilesList, ...acceptedFiles];
    setAcceptedFilesList(newList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  useEffect(() => {
    if (rowDetails[0] !== undefined) {
      let pass = [];

      rowDetails.forEach((document, index) => {
        let newObj = document;
        newObj.key = index + 1;
        newObj.date = document.date;
        newObj.referNo = document.number;
        newObj.receiptType = document['documentType'];
        newObj.companyName = document['company']['name'];
        newObj.customerName = document['customer']['name'];
        newObj.taxId = document['customer']['taxId'];
        newObj.vat = document.vatTotal.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        newObj.total = document.grandTotal.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        newObj.amount = document.amountTotal.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        newObj.status = '';

        pass.push(newObj);
      });

      openFile('pass', pass);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowDetails]);

  const openFile = (event, item) => {
    history.push({
      pathname: event === 'pass' ? '/etax/sources/excel/dragdrop/check' : '/etax/sources/excel/fail',
      state: {
        item: item,
      },
    });
  };

  useEffect(() => {
    acceptedFilesList.map(async (file) => {
      if (file.name.includes('.csv')) {
        const text = await file.text();
        const result = parse(text, { header: true });

        let filterData = result.data.filter((obj) => !isEmpty(obj));

        let dataWithoutEmptyKey = [];
        filterData.forEach((obj) => {
          delete obj[''];
          dataWithoutEmptyKey.push(obj);
        });

        if (!dataWithoutEmptyKey[0]) {
          setDialogAlert((prev) => ({
            ...prev,
            isClick: true,
            message: t('excelDragDropDropzone.dropzoneAlertCSV'),
          }));

          return setAcceptedFilesList([]);
        }

        history.push({
          pathname: '/etax/sources/excel/dragdrop/check',
          state: {
            columns: Object.keys(dataWithoutEmptyKey[0]),
            data: dataWithoutEmptyKey,
            fileName: file.name,
          },
        });
      } else {
        readExcel(file);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFilesList]);

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const workbook = xlsx.read(bufferArray, { type: 'buffer' });

        const worksheetName = workbook.SheetNames[0];

        const worksheet = workbook.Sheets[worksheetName];

        const columnsArray = xlsx.utils.sheet_to_json(worksheet, {
          header: 1,
        })[0];

        if (!columnsArray) {
          setDialogAlert((prev) => ({
            ...prev,
            isClick: true,
            message: t('excelDragDropDropzone.dropzoneAlertExcel'),
          }));

          return setAcceptedFilesList([]);
        }

        const data = xlsx.utils.sheet_to_json(worksheet);

        resolve({ columnsArray: columnsArray, data: data, fileName: file.name });
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((obj) => {
      history.push({
        pathname: '/etax/sources/excel/dragdrop/check',
        state: {
          columns: obj.columnsArray,
          data: obj.data,
          fileName: obj.fileName,
        },
      });
    });
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        //Check documents spending limit
        await axios
          .get(API_URL + '/subscriptions', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log('Subscriptions data: ', response);

              if (response.data) {
                if (response.data && response.data.documents) {
                  console.log('Subscription etax documents: ', response.data.documents);

                  let _subscriptionDocuments = response.data.documents;

                  if (_subscriptionDocuments.spent + 1 > _subscriptionDocuments.limit + _subscriptionDocuments.topup) {
                    history.push({
                      pathname: '/billings',
                      state: {},
                    });
                  }
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ExcelContext.Provider
      value={{
        passItems: passItems,
        setPassItems: setPassItems,
        failItems: failItems,
        setFailItems: setFailItems,
        disabled,
        setDisabled,
        complete,
        setComplete,
        inComplete,
        setInComplete,
        acceptedFilesList: acceptedFilesList,
        setAcceptedFilesList: setAcceptedFilesList,
        rowDetails: rowDetails,
        setRowDetails: setRowDetails,
        setDefault,
        test,
        setTest,
      }}
    >
      <Stack>
        <div>
          <br />
          <Stack horizontal horizontalAlign="center">
            <h1>{t('excelDragDropDropzone.header')}</h1>
          </Stack>
          <br />
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <div className="center">
              <button type="button" style={{ padding: '10px 20px', fontSize: '16px' }} onClick={open}>
                {t('excelDragDropDropzone.upload')}
              </button>
              <p>{t('excelDragDropDropzone.uploadDescription')}</p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <Stack vertical horizontalAlign="center">
          {/* <Link href="https://www.leceipt.com/docs/etax/การสร้าง-e-tax-จาก-excel" target="_blank" underline>
                {t('excelDragDropDropzone.article')}
              </Link>
              <br /> */}
          <Link href="/excel-templates/Leceipt-Excel-DragDrop-Template.xlsx" target="_blank" underline>
            {t('excelDragDropDropzone.excelTemplate')}
          </Link>
        </Stack>
      </Stack>
      <Dialog
        hidden={!dialogAlert.isClick}
        onDismiss={() =>
          setDialogAlert((prev) => ({
            ...prev,
            isClick: false,
          }))
        }
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: <Icon iconName="Error" />,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24, fontSize: 81, paddingBottom: 0 }, subText: { textAlign: 'center' } },
          subText: dialogAlert.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center', marginRight: 0 } }}>
          <DefaultButton
            onClick={() =>
              setDialogAlert((prev) => ({
                ...prev,
                isClick: false,
              }))
            }
            text={t('documents.close')}
          />
        </DialogFooter>
      </Dialog>
    </ExcelContext.Provider>
  );
}

<DropzoneDragDrop />;

export { ExcelContext };
