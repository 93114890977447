import React, { useState, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { TextField, PrimaryButton, DefaultButton, Stack, getTheme } from '@fluentui/react';

const theme = getTheme();

const stackTokens = { childrenGap: 15 };

const Contacts = () => {
  console.log('Re render');

  const history = useHistory();
  const location = useLocation();

  const [data] = useState(location.state);

  if (data && data.id === '') {
    history.push({
      pathname: '/etax/signatures/register/companies',
      state: {},
    });
  }

  const [period, setPeriod] = useState(1);
  const [taxId, setTaxId] = useState('');
  const [type, setType] = useState('');
  const [nameTH, setNameTH] = useState('');
  const [nameEN, setNameEN] = useState('');
  const [email, setEmail] = useState('');

  const [id, setId] = useState('');
  const [address, setAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [district, setDistrict] = useState('');
  const [province, setProvince] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [contactMobile, setContactMobile] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  function handleSubmit(event) {
    event.preventDefault();

    history.push({
      pathname: '/ca/confirm',
      state: {
        period: period,
        taxId: taxId,
        type: type,
        nameTH: nameTH,
        nameEN: nameEN,
        email: email,

        id: id,
        address: address,
        postcode: postcode,
        district: district,
        province: province,
        phone: phone,
        mobile: mobile,

        firstName: firstName,
        lastName: lastName,
        contactMobile: contactMobile,
        contactEmail: contactEmail,
      },
    });
  }

  const handleEdit = (e) => {
    e.preventDefault();

    history.push({
      pathname: '/ca/address',
      state: {
        period: period,
        taxId: taxId,
        type: type,
        nameTH: nameTH,
        nameEN: nameEN,
        email: email,

        id: id,
        address: address,
        postcode: postcode,
        district: district,
        province: province,
        phone: phone,
        mobile: mobile,

        firstName: firstName,
        lastName: lastName,
        contactMobile: contactMobile,
        contactEmail: contactEmail,
      },
    });
  };

  const getErrorMessage = (value) => {
    return value && value !== '0' ? '' : 'กรุณากรอกข้อมูล';
  };

  useEffect(() => {
    console.log('useEffect data call!: ', data);

    if (data !== undefined) {
      setPeriod(data.period);
      setTaxId(data.taxId);
      setType(data.type);
      setNameTH(data.nameTH);
      setNameEN(data.nameEN);
      setEmail(data.email);

      setId(data.id);
      setAddress(data.address);
      setPostcode(data.postcode);
      setDistrict(data.district);
      setProvince(data.province);
      setPhone(data.phone);
      setMobile(data.mobile);

      setFirstName(data.firstName);
      setLastName(data.lastName);
      setContactMobile(data.contactMobile);
      setContactEmail(data.contactEmail);
    }
  }, [data]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h2 style={{ marginLeft: '2%', marginTop: '4%' }}>กรอกข้อมูลผู้ประสานงาน</h2>

        <div
          className="ms-Grid"
          dir="ltr"
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 30,
            background: '#ffffff',
            margin: '2%',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <div className="ms-Grid-row">
            <Stack horizontal tokens={stackTokens}>
              <TextField
                name="firstName"
                label="ชื่อ"
                styles={{ root: { width: 200 } }}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />

              <TextField
                name="lastName"
                label="นามสกุล"
                styles={{ root: { width: 200 } }}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />
            </Stack>

            <br />

            <Stack horizontal tokens={stackTokens}>
              <TextField
                name="contactMobile"
                label="โทรศัพท์มือถือ"
                styles={{ root: { width: 200 } }}
                value={contactMobile}
                onChange={(e) => setContactMobile(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />

              <TextField
                name="contactEmail"
                label="อีเมล"
                styles={{ root: { width: 200 } }}
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />
            </Stack>

            <br />

            <br />
            <br />

            <PrimaryButton name="next" text="ต่อไป" type="submit" />
            <DefaultButton text="ย้อนกลับ" style={{ marginLeft: '15px' }} onClick={handleEdit} />

            <br />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Contacts;
