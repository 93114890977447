import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../../authConfig';

import * as signalR from '@microsoft/signalr';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  SelectionMode,
  mergeStyleSets,
  TooltipHost,
  ActionButton,
  Icon,
  Spinner,
  SpinnerSize,
  CommandBar,
  Stack,
  DocumentCard,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardLogo,
  DocumentCardType,
  Text,
  DefaultButton,
  MessageBar,
  MessageBarType,
  Link,
  ContextualMenu,
} from '@fluentui/react';

// import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import CountUp from 'react-countup';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
});

const cardStyles = {
  cardStyles: {
    root: {
      background: 'white',
      borderLeft: '5px solid #0078d4',
      width: '30%',
      minWidth: '315px',
      maxWidth: '424px',
      height: '130px',
    },
  },
  header: {
    root: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingTop: '20px',
      textAlign: 'center',
    },
  },
  amount: {
    root: {
      fontSize: 30,
      paddingBottom: '25px',
      textAlign: 'center',
    },
  },
};

function formatDateTime(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = '' + d.getHours(),
    minute = '' + d.getMinutes(),
    second = '' + d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [year, month, day, hour, minute, second].join('');
}

const Xml = (props) => {
  console.log('Home render!!!');

  const history = useHistory();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const [totalProcessingDocuments, setTotalProcessingDocuments] = useState(0);
  const [pageData, setPageData] = useState({});
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  //CommandBars Items
  const [documentType, setDocumentType] = useState({ key: 'ALL', text: 'เอกสารทุกประเภท' });

  //Display Summary
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const [totalGrand, setTotalGrand] = useState(0);

  const [showCertificateSetup, setShowCertificateSetup] = useState(false);
  const [showXmlDeliverSetup, setShowXmlDeliverSetup] = useState(false);

  const [enableDelete, setEnableDelete] = useState(false);

  const getMonth = (goBack) => {
    let monthNames = [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม.',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState({ key: getMonth(0).key, text: 'เดือนนี้' });

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(getTo(getMonth(0).month, getMonth(0).year));

  const commandBarItems = [
    {
      key: documentType.key,
      text: documentType.text,
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Page' },
      subMenuProps: {
        items: [
          {
            key: 'ALL',
            text: 'เอกสารทุกประเภท',
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('เอกสารทุกประเภท');
              setDocumentType({ key: 'ALL', text: 'เอกสารทุกประเภท' });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 'RECEIPT-TAXINVOICE',
            text: 'ใบเสร็จรับเงิน/ใบกำกับภาษี',
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('ใบเสร็จรับเงิน/ใบกำกับภาษี');
              setDocumentType({ key: 'RECEIPT-TAXINVOICE', text: 'ใบเสร็จรับเงิน/ใบกำกับภาษี' });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 'RECEIPT',
            text: 'ใบเสร็จรับเงิน',
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('ใบเสร็จรับเงิน');
              setDocumentType({ key: 'RECEIPT', text: 'ใบเสร็จรับเงิน' });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 'INVOICE-TAXINVOICE',
            text: 'ใบแจ้งหนี้/ใบกำกับภาษี',
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('ใบแจ้งหนี้/ใบกำกับภาษี');
              setDocumentType({ key: 'INVOICE-TAXINVOICE', text: 'ใบแจ้งหนี้/ใบกำกับภาษี' });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 'DELIVERYORDER-TAXINVOICE',
            text: 'ใบส่งของ/ใบกำกับภาษี',
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('ใบส่งของ/ใบกำกับภาษี');
              setDocumentType({ key: 'DELIVERYORDER-TAXINVOICE', text: 'ใบส่งของ/ใบกำกับภาษี' });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 'TAXINVOICE',
            text: 'ใบกำกับภาษี',
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('ใบกำกับภาษี');
              setDocumentType({ key: 'TAXINVOICE', text: 'ใบกำกับภาษี' });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 'DEBIT-NOTE',
            text: 'ใบเพิ่มหนี้',
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('ใบเพิ่มหนี้');
              setDocumentType({ key: 'DEBIT-NOTE', text: 'ใบเพิ่มหนี้' });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 'CREDIT-NOTE',
            text: 'ใบลดหนี้',
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('ใบลดหนี้');
              setDocumentType({ key: 'CREDIT-NOTE', text: 'ใบลดหนี้' });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 'QUOTATION',
            text: 'ใบเสนอราคา',
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('ใบเสนอราคา');
              setDocumentType({ key: 'QUOTATION', text: 'ใบเสนอราคา' });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: queryMonth.text,
      iconProps: { iconName: 'Calendar' },
      subMenuProps: {
        items: [
          {
            key: getMonth(0).key,
            text: getMonth(0).text,
            iconProps: { iconName: 'Calendar' },
            onClick: () => {
              console.log('Selected month: ', getMonth(0).key);
              setQueryMonth({ key: getMonth(0).key, text: getMonth(0).text });
              setFrom(getFrom(getMonth(0).month, getMonth(0).year));
              setTo(getTo(getMonth(0).month, getMonth(0).year));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: getMonth(1).key,
            text: getMonth(1).text,
            iconProps: { iconName: 'Calendar' },
            onClick: () => {
              console.log('Selected month: ', getMonth(1).key);
              setQueryMonth({ key: getMonth(1).key, text: getMonth(1).text });
              setFrom(getFrom(getMonth(1).month, getMonth(1).year));
              setTo(getTo(getMonth(1).month, getMonth(1).year));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: getMonth(2).key,
            text: getMonth(2).text,
            iconProps: { iconName: 'Calendar' },
            onClick: () => {
              console.log('Selected month: ', getMonth(2).key);
              setQueryMonth({ key: getMonth(2).key, text: getMonth(2).text });
              setFrom(getFrom(getMonth(2).month, getMonth(2).year));
              setTo(getTo(getMonth(2).month, getMonth(2).year));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: getMonth(3).key,
            text: getMonth(3).text,
            iconProps: { iconName: 'Calendar' },
            onClick: () => {
              console.log('Selected month: ', getMonth(3).key);
              setQueryMonth({ key: getMonth(3).key, text: getMonth(3).text });
              setFrom(getFrom(getMonth(3).month, getMonth(3).year));
              setTo(getTo(getMonth(3).month, getMonth(3).year));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: getMonth(4).key,
            text: getMonth(4).text,
            iconProps: { iconName: 'Calendar' },
            onClick: () => {
              console.log('Selected month: ', getMonth(4).key);
              setQueryMonth({ key: getMonth(4).key, text: getMonth(4).text });
              setFrom(getFrom(getMonth(4).month, getMonth(4).year));
              setTo(getTo(getMonth(4).month, getMonth(4).year));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: getMonth(5).key,
            text: getMonth(5).text,
            iconProps: { iconName: 'Calendar' },
            onClick: () => {
              console.log(getMonth(5).key);
              setQueryMonth({ key: getMonth(5).key, text: getMonth(5).text });
              setFrom(getFrom(getMonth(5).month, getMonth(5).year));
              setTo(getTo(getMonth(5).month, getMonth(5).year));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
        ],
      },
    },
    {
      key: 'download',
      text: 'ดาวน์โหลด',
      iconProps: { iconName: 'Download' },
      onClick: () => {
        console.log('Download');
        downloadZip();
      },
    },
    {
      key: 'refresh',
      text: 'รีเฟรช',
      iconProps: { iconName: 'Refresh' },
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);
      },
    },
  ];

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);

    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    let hour = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const getTotalProcessingDocuments = () => {
    console.log('Get total processing document...');
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/jobs/xml/processing/total', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log('Total processing jobs response: ', response);

              if (response.data[0]) {
                console.log('Total processing jobs: ', response.data[0]);

                setTotalProcessingDocuments(response.data[0]);
              } else {
                setTotalProcessingDocuments(0);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    console.log('userEffect Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        console.log(tokenResponse);

        //Check test certificate
        axios
          .get(API_URL + '/accounts', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (responseAcc) => {
              if (responseAcc.data) {
                if (responseAcc.data.settings.etax.enableDelete) {
                  setEnableDelete(responseAcc.data.settings.etax.enableDelete);
                }

                axios
                  .get(API_URL + '/certificates/' + responseAcc.data.settings.etax.defaultCertificateId, {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                    },
                  })
                  .then(
                    (responseCert) => {
                      if (responseCert.data) {
                        let certName = responseCert.data.certificateName;

                        console.log('Certificate name: ', responseCert.data.certificateName);

                        if (certName.search('Test Only') > -1) {
                          setShowCertificateSetup(true);
                        } else {
                          axios
                            .get(API_URL + '/etax/users', {
                              headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                              },
                            })
                            .then(
                              (responseUser) => {
                                console.log('etaxUsers: ', responseUser);

                                if (responseUser.data && responseUser.data.disabled) {
                                  setShowXmlDeliverSetup(true);
                                }
                              },
                              (error) => {
                                console.log(error);
                              }
                            );
                        }
                      }
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
              }
            },
            (error) => {
              console.log(error);
            }
          );

        //SignalR
        const documentsConnection = new signalR.HubConnectionBuilder()
          .withUrl('https://leceipt.azurewebsites.net/api/OnDocumentsChanged', {
            headers: {
              oid: tokenResponse.idTokenClaims.oid,
              product: 'etax',
              fileExtension: 'xml',
            },
          })
          .configureLogging(signalR.LogLevel.Information)
          .build();

        documentsConnection.on('documentUpdated', (data) => {
          console.log('SignalR push documentUpdated!!');
          console.log(data);

          setGetNewDocuments(true);
        });

        async function documentsStart() {
          try {
            await documentsConnection.start();
            console.log('SignalR Documents Connected.');
          } catch (err) {
            console.log(err);
            setTimeout(documentsStart, 5000);
          }
        }

        documentsConnection.onclose(documentsStart);

        // Start the connection.
        documentsStart();

        const jobsConnection = new signalR.HubConnectionBuilder()
          .withUrl('https://leceipt.azurewebsites.net/api/OnJobsChanged', {
            headers: {
              oid: tokenResponse.idTokenClaims.oid,
              product: 'etax',
              fileExtension: 'xml',
            },
          })
          .configureLogging(signalR.LogLevel.Information)
          .build();

        jobsConnection.on('jobUpdated', (data) => {
          console.log('SignalR push jobUpdated!!');
          console.log(data);

          getTotalProcessingDocuments();
        });

        async function jobsStart() {
          try {
            await jobsConnection.start();
            console.log('SignalR Jobs Connected.');
          } catch (err) {
            console.log(err);
            setTimeout(jobsStart, 5000);
          }
        }

        jobsConnection.onclose(jobsStart);

        // Start the connection.
        jobsStart();
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    getTotalProcessingDocuments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        // Do something with the tokenResponse
        console.log('Token response: ', tokenResponse);

        axios
          .get(API_URL + '/etax/documents/' + documentType.key + '/xml?from=' + from + '&to=' + to, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);
              console.log('Docs number: ', response.data.length);

              setTotalDocuments(response.data.length);

              //set page
              console.log('Page data: ', paginate(response.data.length, 1, 5, 10));
              setPageData(paginate(response.data.length, currentPage, pageSize, 10));

              setDocuments(response.data);

              const _docs = [];

              if (response.data.length > 0) {
                for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, response.data.length); i++) {
                  let fileSize = formatBytes(response.data[i].file.size);
                  let createdTime = toThaiDateString(response.data[i].createdTime);

                  let _amountTotal = 0;
                  let _vatTotal = 0;
                  let _grandTotal = 0;

                  if (response.data[i].data.amountTotal) {
                    _amountTotal = response.data[i].data.amountTotal;
                  }

                  if (response.data[i].data.vatTotal) {
                    _vatTotal = response.data[i].data.vatTotal;
                  }

                  if (response.data[i].data.grandTotal) {
                    _grandTotal = response.data[i].data.grandTotal;
                  }

                  let _rdStatus = '-';

                  if (response.data[i].deliveries.rd.status === 'pending') {
                    _rdStatus = 'รอส่งอัตโนมัติ';
                  } else if (response.data[i].deliveries.rd.status === 'successful') {
                    _rdStatus = 'นำส่งสำเร็จ';
                  } else if (response.data[i].deliveries.rd.status === 'fail') {
                    _rdStatus = 'นำส่งไม่สำเร็จ';
                  }

                  _docs.push({
                    key: response.data[i].id,
                    fileName: response.data[i].file.name + '.' + response.data[i].file.extension,
                    name: response.data[i].data.name,
                    number: response.data[i].data.number,
                    value: response.data[i].file.name,
                    fileExtension: response.data[i].file.extension,
                    createdBy: response.data[i].createdBy,
                    createdTime: createdTime,
                    createdTimeValue: response.data[i].createdTime,
                    fileSize: fileSize,
                    fileSizeRaw: response.data[i].file.size,
                    type: response.data[i].type,
                    signature: response.data[i].signatures[0].certificateName,
                    product: response.data[i].product,
                    customerName: response.data[i].data.customer.name,
                    amountTotal: _amountTotal,
                    vatTotal: _vatTotal,
                    grandTotal: _grandTotal,
                    rdStatus: _rdStatus,
                  });
                }

                let _totalVat = 0;
                let _totalGrand = 0;

                for (let i = 0; i < response.data.length; i++) {
                  _totalVat = _totalVat + response.data[i].data.vatTotal;
                  _totalGrand = _totalGrand + response.data[i].data.grandTotal;
                }

                setTotalVat(_totalVat);
                setTotalGrand(_totalGrand);
              }

              console.log('Docs data', _docs);
              setItems(_docs);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    if (documents.length > 0) {
      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
        let fileSize = formatBytes(documents[i].file.size);
        let createdTime = toThaiDateString(documents[i].createdTime);

        let _amountTotal = 0;
        let _vatTotal = 0;
        let _grandTotal = 0;

        if (documents[i].data.amountTotal) {
          _amountTotal = documents[i].data.amountTotal;
        }

        if (documents[i].data.vatTotal) {
          _vatTotal = documents[i].data.vatTotal;
        }

        if (documents[i].data.grandTotal) {
          _grandTotal = documents[i].data.grandTotal;
        }

        let _rdStatus = '-';

        if (documents[i].deliveries.rd.status === 'pending') {
          _rdStatus = 'รอส่งอัตโนมัติ';
        } else if (documents[i].deliveries.rd.status === 'successful') {
          _rdStatus = 'นำส่งสำเร็จ';
        } else if (documents[i].deliveries.rd.status === 'fail') {
          _rdStatus = 'นำส่งไม่สำเร็จ';
        }

        _docs.push({
          key: documents[i].id,
          fileName: documents[i].file.name + '.' + documents[i].file.extension,
          name: documents[i].data.name,
          number: documents[i].data.number,
          value: documents[i].file.name,
          fileExtension: documents[i].file.extension,
          createdBy: documents[i].createdBy,
          createdTime: createdTime,
          createdTimeValue: documents[i].createdTime,
          fileSize: fileSize,
          fileSizeRaw: documents[i].file.size,
          type: documents[i].type,
          signature: documents[i].signatures[0].certificateName,
          product: documents[i].product,
          customerName: documents[i].data.customer.name,
          amountTotal: _amountTotal,
          vatTotal: _vatTotal,
          grandTotal: _grandTotal,
          rdStatus: _rdStatus,
        });
      }
      console.log('Docs data', _docs);

      setItems(_docs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const download = (event, item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/files/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              console.log(response.headers);

              const time = formatDateTime(item.createdTimeValue);

              let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g, '') + '.' + item.fileExtension;

              console.log('file name: ', fileName);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName); //or any other extension
              document.body.appendChild(link);
              link.click();
              link.remove();
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const deleteFile = (event, item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .delete(API_URL + '/etax/documents/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);
              setLoadDataComplete(false);
              setGetNewDocuments(true);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const openFile = (event, item) => {
    history.push({
      pathname: '/etax/documents/xml/view',
      state: {
        item: item,
      },
    });
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const downloadZip = () => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/files/' + documentType.key + '/xml?from=' + from + '&to=' + to, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              console.log(response.headers);

              let zipFileName = from.substr(0, 4) + from.substr(5, 2) + '-' + documentType.key + '-XML-Leceipt.zip';

              console.log('Zip file name: ', zipFileName);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', zipFileName); //or any other extension
              document.body.appendChild(link);
              link.click();
              link.remove();
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const columns = [
    {
      key: 'column1',
      name: 'File Type',
      className: classNames.fileIconCell,
      iconClassName: classNames.fileIconHeaderIcon,
      ariaLabel: 'Column operations for File type, Press to sort on File type',
      iconName: 'Page',
      isIconOnly: true,
      fieldName: 'name',
      minWidth: 16,
      maxWidth: 16,
      onColumnClick: '',

      onRender: (item) => (
        <TooltipHost content={`ไฟล์ ${item.fileExtension}`}>
          <Icon {...getFileTypeIconProps({ extension: item.fileExtension, size: 16, imageFileType: 'svg' })} />
        </TooltipHost>
      ),
    },
    {
      key: 'column2',
      name: 'วันที่สร้าง',
      fieldName: 'createdTime',
      minWidth: 70,
      maxWidth: 125,
      isResizable: true,
      isSorted: true,
      isSortedDescending: true,
      onColumnClick: '',
      data: 'number',
      onRender: (item) => {
        return <span>{item.createdTime}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: 'เอกสาร',
      fieldName: 'name',
      minWidth: 140,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton
            title="เปิดไฟล์"
            className={classNames.fileIconImg}
            onClick={(event) => {
              openFile(event, item);
            }}
          >
            {item.name}
          </ActionButton>
        );
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: 'เลขที่',
      fieldName: 'number',
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton
            title="ดาวน์โหลด"
            className={classNames.fileIconImg}
            onClick={(event) => {
              openFile(event, item);
            }}
          >
            {item.number}
          </ActionButton>
        );
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: 'ลูกค้า',
      fieldName: 'customerName',
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: 'Vat',
      fieldName: 'vatTotal',
      minWidth: 50,
      maxWidth: 60,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.vatTotal.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: 'ยอด (รวม Vat)',
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 70,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.grandTotal.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column8',
      name: 'สถานะนำส่ง',
      fieldName: 'status',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span style={{ display: 'block', textAlign: 'center' }}>{item.rdStatus}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column9',
      name: '',
      fieldName: 'command',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        return (
          <DefaultButton
            text="เลือก"
            menuProps={{
              items: [
                {
                  key: 'open',
                  text: 'เปิดเอกสาร',
                  iconProps: { iconName: 'OpenFile' },
                  onClick: (event) => {
                    openFile(event, item);
                  },
                },
                {
                  key: 'download',
                  text: 'ดาวน์โหลด',
                  iconProps: { iconName: 'DownloadDocument' },
                  onClick: (event) => {
                    download(event, item);
                  },
                },
              ],
              shouldFocusOnMount: true,
              directionalHintFixed: true,
            }}
            // Optional callback to customize menu rendering
            menuAs={(props) => <ContextualMenu {...props} />}
          />
        );
      },
    },
    {
      key: 'column10',
      name: '',
      fieldName: 'command',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isCollapsible: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (enableDelete) {
          return (
            <ActionButton
              iconProps={{
                iconName: 'Delete',

                styles: {
                  root: {
                    color: '#D65633',
                  },
                },
              }}
              title="ลบ"
              className={classNames.fileIconImg}
              onClick={(event) => {
                deleteFile(event, item);
              }}
            >
              ลบ
            </ActionButton>
          );
        }
      },
    },
  ];

  const isCompactMode = false;

  return (
    <div style={homeStyles}>
      {showCertificateSetup && (
        <MessageBar
          onDismiss={() => {
            setShowCertificateSetup(false);
          }}
          messageBarType={MessageBarType.warning}
          dismissButtonAriaLabel="ปิด"
          isMultiline={false}
        >
          <Link
            onClick={() => {
              history.push({ pathname: '/etax/certificates', state: {} });
            }}
            underline
          >
            ติดตั้งใบรับรองอิเล็กทรอนิกส์
          </Link>{' '}
          เพื่อใช้งานจริง
        </MessageBar>
      )}
      {showXmlDeliverSetup && (
        <MessageBar
          onDismiss={() => {
            setShowXmlDeliverSetup(false);
          }}
          messageBarType={MessageBarType.warning}
          dismissButtonAriaLabel="ปิด"
          isMultiline={false}
        >
          <Link
            onClick={() => {
              history.push({ pathname: '/etax/xml/deliver', state: {} });
            }}
            underline
          >
            ตั้งค่าการนำส่งไฟล์ XML อัตโนมัติ
          </Link>{' '}
          เพื่อนำส่งไฟล์ XML เข้าระบบ e-Tax Invoice & e-Receipt แบบอัตโนมัติ
        </MessageBar>
      )}

      <Stack>
        <center>
          <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>เอกสาร XML</h2>
        </center>

        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
          <DocumentCard aria-label="จำนวนเอกสาร" styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
            <DocumentCardLogo {...{ logoIcon: 'Documentation' }} title="จำนวนเอกสาร" />
            <DocumentCardDetails>
              <DocumentCardTitle title="จำนวนเอกสาร" styles={cardStyles.header} />
              <Text styles={cardStyles.amount}>
                <CountUp end={totalDocuments} separator="," duration={1} /> ใบ
              </Text>
            </DocumentCardDetails>
          </DocumentCard>

          <DocumentCard aria-label="ภาษีขาย (Vat)" styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
            <DocumentCardLogo {...{ logoIcon: 'Bank' }} title="จำนวนเอกสาร" />
            <DocumentCardDetails>
              <DocumentCardTitle title="ภาษีขาย (Vat)" styles={cardStyles.header} />
              <Text styles={cardStyles.amount}>
                <CountUp end={totalVat} separator="," decimals={2} decimal="." duration={1} /> บาท
              </Text>
            </DocumentCardDetails>
          </DocumentCard>

          <DocumentCard aria-label="ยอดขาย (รวม Vat)" styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
            <DocumentCardLogo {...{ logoIcon: 'Money' }} title="จำนวนเอกสาร" />
            <DocumentCardDetails>
              <DocumentCardTitle title="ยอดขาย (รวม Vat)" styles={cardStyles.header} />
              <Text styles={cardStyles.amount}>
                <CountUp end={totalGrand} separator="," decimals={2} decimal="." duration={1} /> บาท
              </Text>
            </DocumentCardDetails>
          </DocumentCard>
        </Stack>
      </Stack>

      {totalProcessingDocuments > 0 && (
        <div>
          <br />
          <Spinner label="กำลังสร้างเอกสารและลงลายเซ็นดิจิทัล..." size={SpinnerSize.large} />
        </div>
      )}
      <br />
      <Stack horizontal horizontalAlign="center">
        <Stack
          vertical
          style={{
            width: '98%',
            maxWidth: '1350px',
          }}
        >
          <CommandBar items={commandBarItems} />
          <ShimmeredDetailsList
            items={items || []}
            enableShimmer={!loadDataComplete}
            compact={isCompactMode}
            columns={columns}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
          />
        </Stack>
      </Stack>
      <br />
      <center>
        {!(documents.length === 0 || documents.length < pageSize) && (
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={pageData.totalPages}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
          />
        )}

        <br />
        <br />
      </center>
    </div>
  );
};

export default Xml;
