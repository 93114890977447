import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../../../authConfig';

import {
  PrimaryButton,
  Stack,
  getTheme,
  Toggle,
  Dialog,
  DialogType,
  DialogFooter,
  Spinner,
  DocumentCard,
  DocumentCardDetails,
  DocumentCardStatus,
  TooltipHost,
  Separator,
  ChoiceGroup,
} from '@fluentui/react';

const API_URL = process.env.REACT_APP_API_URL;

const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

const OMISE_PUBLIC_KEY = process.env.REACT_APP_OMISE_PUBLIC_KEY;

const theme = getTheme();

//Style

const centerItem = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

const stackTokens = { childrenGap: 10 };

const Checkout = () => {
  const history = useHistory();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [plan, setPlan] = useState({ key: 'STARTER', text: 'แพ็กเกจ เริ่มต้น 50 เอกสาร/เดือน' });
  const [billing, setBilling] = useState(1);
  const [autoRenew, setAutoRenew] = useState(true);
  const [price, setPrice] = useState(399);
  const [total, setTotal] = useState(399);
  const [vatTotal, setVatTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const [chargeStatus, setChargeStatus] = useState('');

  //Credit Card
  const [customerId, setCustomerId] = useState('');

  const [frevationCoin, setFrevationCoin] = useState(0);

  const percentUsedCoin = 50;

  const [useCoin, setUseCoin] = useState(false);
  const [coinDiscount, setCoinDiscount] = useState(0);

  const [cards, setCards] = useState([
    {
      id: '',
      expiration_month: '',
      expiration_year: '',
      brand: '',
      last_digits: '',
    },
  ]);

  // Processing dialog style
  const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4,
    },
  };

  //Credit Card
  const cardStyles = {
    root: { display: 'inline-block', marginRight: 20, width: 260 },
  };

  const calGrandTotal = (_price, _billing, useCoin) => {
    console.log('Price: ', _price);
    let vat = 0.07;
    let coinDiscount = 0;
    let _total;
    if (_billing == 12) {
      _total = _price * 10;
    } else {
      _total = _price;
    }
    if (useCoin) {
      if (_total * (percentUsedCoin / 100) > frevationCoin) {
        coinDiscount = frevationCoin;
      } else {
        coinDiscount = _total / 2;
      }
    }

    let _vatTotal = Math.round(vat * (_total - coinDiscount) * 100) / 100;
    setCoinDiscount(coinDiscount);
    setVatTotal(_vatTotal);
    setGrandTotal(_total - coinDiscount + _vatTotal);
  };

  const charge = (tokenId) => {
    console.log('Call charge!');
    console.log('Pland id: ', plan.key);

    setChargeStatus('processing');

    let data;
    if (tokenId) {
      data = {
        tokenId: tokenId,
        planId: plan.key,
        billing: billing,
        autoRenew: autoRenew,
      };
    } else {
      data = {
        customerId: customerId,
        planId: plan.key,
        billing: billing,
        autoRenew: autoRenew,
      };
    }

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(API_URL + '/omise/charge/cards', data, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'application/json',
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data.authorize_uri) {
                window.location.href = response.data.authorize_uri;
              } else {
                setChargeStatus('fail');
              }
            },
            (error) => {
              console.log(error);
              console.log('Charge fail!');
              setChargeStatus('fail');
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const omiseFormOpen = () => {
    window.OmiseCard.open({
      amount: grandTotal * 100,
      frameLabel: 'Leceipt',
      currency: 'THB',
      defaultPaymentMethod: 'credit_card',
      onCreateTokenSuccess: (nonce) => {
        if (nonce.startsWith('tokn_')) {
          charge(nonce);
        }
        console.log('Nonce: ', nonce);
      },
      onFormClosed: () => {},
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setChargeStatus('');

    console.log('Submit!');

    console.log('Plan: ', plan);
    console.log('Billing: ', billing);
    console.log('Price: ', price);
    console.log('Total: ', total);
    console.log('Vat Total: ', vatTotal);
    console.log('Grand Total: ', grandTotal);
    console.log('Auto renew: ', autoRenew);

    if (customerId == null || customerId == '' || customerId == undefined || cards[0] == null || cards[0] == '' || cards[0] == undefined) {
      omiseFormOpen();
    } else {
      charge();
    }
  };

  const loadOmise = () => {
    const existingScript = document.getElementById('Omise');

    if (!existingScript) {
      //Add Omise.js script
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://cdn.omise.co/omise.js';

      script.id = 'Omise';

      document.body.appendChild(script);

      script.onload = () => {
        let logoUrl = PUBLIC_URL + '/logo512.png';

        window.OmiseCard.configure({
          publicKey: OMISE_PUBLIC_KEY,
          image: logoUrl,
        });

        console.log('OmiseCard config: ', OMISE_PUBLIC_KEY);
      };
    }
  };

  const getAccounts = (accessToken) => {
    axios
      .get(API_URL + '/accounts', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then(
        (response) => {
          console.log('Accounts data: ', response);

          if (response.data) {
            setFrevationCoin(response.data.balances.coins.credits);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    console.log('userEffect initialize Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        getAccounts(tokenResponse.accessToken);

        axios
          .get(API_URL + '/omise', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log('Omise data: ', response);

              if (response.data) {
                console.log(response.data);

                if (response.data && response.data.customer.id) {
                  console.log('Omise customer id: ', response.data.customer.id);

                  let _defaultCard = response.data.customer.default_card;

                  setCustomerId(response.data.customer.id);

                  let _items = [];

                  response.data.customer.cards.data.forEach((item) => {
                    if (_defaultCard === item.id) {
                      _items.push({
                        id: item.id,
                        expiration_month: item.expiration_month,
                        expiration_year: item.expiration_year,
                        brand: item.brand,
                        last_digits: item.last_digits,
                      });
                    }
                  });

                  setCards(_items);
                }
              }

              setIsLoading(false);
            },
            (error) => {
              console.log(error);

              setIsLoading(false);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    calGrandTotal(price, billing, useCoin);

    loadOmise();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Stack>
        <h2 style={{ margin: '2%' }}>เปลี่ยนแพ็กเกจ</h2>
        <div
          style={{
            width: '850px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 30,
            marginTop: 0,
            background: '#ffffff',
            margin: '2%',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <div className="ms-Grid-row">
            <h3 style={{ margin: '0%' }}>1. เลือกแพ็กเกจ</h3>&nbsp;&nbsp;
            <ChoiceGroup
              name="plan"
              label="แพ็กเกจ"
              selectedKey={plan.key}
              required
              options={[
                {
                  key: 'STARTER',
                  text: 'แพ็กเกจ เริ่มต้น 50 เอกสาร/เดือน',
                  imageSrc: 'https://www.leceipt.com/wp-content/uploads/2021/03/files.png',
                  selectedImageSrc: '/images/files.png',
                  imageSize: { height: 50 },
                },
                {
                  key: 'SME',
                  text: 'แพ็กเกจ SME 100 เอกสาร/เดือน',
                  imageSrc: 'https://www.leceipt.com/wp-content/uploads/2021/03/documents.png',
                  selectedImageSrc: '/images/documents.png',
                  imageSize: { height: 50 },
                },
              ]}
              onChange={(e, selectedOption) => {
                console.log(selectedOption);
                setPlan(selectedOption);
                setBilling(1);

                switch (selectedOption.key) {
                  case 'FREE':
                    setPrice(0);
                    setTotal(0);
                    calGrandTotal(0, 1, useCoin);
                    break;
                  case 'STARTER':
                    setPrice(399);
                    setTotal(399);
                    calGrandTotal(399, 1, useCoin);
                    break;
                  case 'SME':
                    setPrice(799);
                    setTotal(799);
                    calGrandTotal(799, 1, useCoin);
                    break;
                  case 'BUSINESS':
                    setPrice(3499);
                    setTotal(3499);
                    calGrandTotal(3499, 1, useCoin);
                    break;
                  case 'ENTERPRISE':
                    setPrice(4999);
                    setTotal(4999);
                    calGrandTotal(4999, 1, useCoin);
                    break;
                  default:
                    setPrice(0);
                }
              }}
            />
            <br />
            <ChoiceGroup
              name="bill"
              label="รายเดือน/รายปี"
              selectedKey={billing}
              required
              options={[
                {
                  key: 1,
                  text: 'ชำระรายเดือน ' + price.toLocaleString(undefined, { maximumFractionDigits: 2 }) + ' บาท/เดือน',
                  imageSrc: '/images/monthly.png',
                  selectedImageSrc: '/images/monthly.png',
                  imageSize: { height: 50 },
                },
                {
                  key: 12,
                  text:
                    'ชำระรายปี ' +
                    (price * 12).toLocaleString(undefined, { maximumFractionDigits: 2 }) +
                    ' บาท/ปี' +
                    ' (ส่วนลด ' +
                    (price * 2).toLocaleString(undefined, { maximumFractionDigits: 2 }) +
                    ' บาท)',
                  imageSrc: '/images/yearly.png',
                  selectedImageSrc: '/images/yearly.png',
                  imageSize: { height: 50 },
                },
              ]}
              onChange={(e, selectedOption) => {
                console.log(selectedOption);
                setBilling(selectedOption.key);

                if (selectedOption.key == 12) {
                  setTotal(12 * price);
                  calGrandTotal(price, 12, useCoin);
                } else {
                  setTotal(price);
                  calGrandTotal(price, 1, useCoin);
                }
              }}
            />
            <br />
            <br />
          </div>
        </div>

        <div
          style={{
            width: '850px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 30,
            background: '#ffffff',
            margin: '2%',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <Stack horizontal tokens={stackTokens}>
            <Stack vertical tokens={stackTokens}>
              <h3 style={{ margin: '0%' }}>2. เลือกวิธีการชำระเงิน</h3>&nbsp;&nbsp;
              <Stack horizontal tokens={stackTokens}>
                <img src={'/images/omise-payment-logo.png'} alt="omisePayment" height="80" border="0" style={{ marginLeft: '-20px' }}></img>
              </Stack>
              {customerId && cards[0] && cards[0].last_digits && (
                <TooltipHost content="เพิ่ม/เปลี่ยนบัตรหลัก">
                  <DocumentCard
                    styles={cardStyles}
                    onClick={() => {
                      history.push({
                        pathname: '/billings',
                        state: {
                          pivot: 'cards',
                        },
                      });
                    }}
                  >
                    <DocumentCardDetails>
                      <DocumentCardStatus statusIcon="PaymentCard" status={'**** **** **** ' + cards[0].last_digits} />
                      <small style={{ padding: 0, marginLeft: 20 }}>
                        หมดอายุ {cards[0].expiration_month}/{cards[0].expiration_year}
                      </small>
                      <Stack horizontal>
                        {cards[0].brand === 'Visa' && (
                          <div>
                            <img src="/images/visa.png" alt="visaPng" width="80" style={{ padding: 0, marginLeft: 20 }} align="right" />
                          </div>
                        )}
                        {cards[0].brand === 'MasterCard' && (
                          <img
                            src="/images/mastercard.png"
                            alt="mastercardPng"
                            width="60"
                            style={{ paddingTop: 17, paddingRight: 20, paddingBottom: 10, marginLeft: 20 }}
                            align="right"
                          />
                        )}
                        {cards[0].brand === 'JCB' && (
                          <img
                            src="/images/jcb.png"
                            alt="jcbPng"
                            width="48"
                            style={{ paddingTop: 17, paddingRight: 30, paddingBottom: 10, marginLeft: 20 }}
                            align="right"
                          />
                        )}

                        <small style={{ paddingLeft: 90, paddingTop: 40, color: 'green' }}>บัตรหลัก</small>
                      </Stack>
                    </DocumentCardDetails>
                  </DocumentCard>
                </TooltipHost>
              )}
              <Toggle
                label="ต่ออายุอัตโนมัติ"
                inlineLabel
                onText="เปิด"
                offText="ปิด"
                defaultChecked
                onChange={(e, checked) => {
                  console.log('Toggle: ', checked);
                  setAutoRenew(checked);
                }}
              />
              <small>รับประกันความพึงพอใจ</small>
              <small>คืนเงิน 100% ภายใน 30 วัน</small>
              <small>ยกเว้น ค่าใบรับรองอิเล็กทรอนิกส์ ไม่คืนเงิน</small>
            </Stack>

            <Separator vertical />

            <Stack vertical tokens={stackTokens} style={{ marginLeft: '0px', paddingLeft: '15px' }}>
              <h1 style={{ marginTop: '5px' }}>สรุปคำสั่งซื้อ</h1>
              <Separator />
              <Stack horizontal horizontalAlign="end">
                <Stack vertical>
                  <h4 style={{ textAlign: 'right', width: '350px' }}>
                    {plan.text}&nbsp;&nbsp;&nbsp;{billing == 12 ? <span>(ชำระ 1 ปี)</span> : <span>(ชำระ 1 เดือน)</span>}
                  </h4>
                </Stack>
                <Stack vertical style={{ width: '60px' }}></Stack>
                <Stack vertical style={{ textAlign: 'right', width: '110px' }}>
                  <h4>{total.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท</h4>
                </Stack>
              </Stack>
              {billing == 12 ? (
                <Stack horizontal horizontalAlign="end">
                  <Stack vertical>
                    <h4 style={{ textAlign: 'right', color: 'red' }}>ส่วนลด&nbsp;</h4>
                  </Stack>
                  <Stack vertical style={{ width: '60px' }}></Stack>
                  <Stack vertical style={{ textAlign: 'right', width: '110px' }}>
                    <h4 style={{ textAlign: 'right', color: 'red' }}>
                      {'-' + (price * 2).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท
                    </h4>
                  </Stack>
                </Stack>
              ) : (
                <div></div>
              )}
              <Stack horizontal horizontalAlign="end">
                <Stack horizontal>
                  <h4 style={{ textAlign: 'right' }}>
                    ใช้ {useCoin ? coinDiscount.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : ''} Frevation
                    Coins&nbsp;
                  </h4>
                  <Toggle
                    inlineLabel
                    style={{ marginTop: 8 }}
                    disabled={frevationCoin === 0 ? true : false}
                    onChange={(e, checked) => {
                      console.log('Toggle: ', checked);
                      setUseCoin(checked);
                      calGrandTotal(price, billing, checked);
                    }}
                  />
                </Stack>
                <Stack vertical style={{ width: '60px' }}></Stack>
                <Stack vertical style={{ textAlign: 'right', width: '110px' }}>
                  <h4>
                    {coinDiscount !== 0
                      ? '-' + coinDiscount.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
                      : coinDiscount.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}{' '}
                    บาท
                  </h4>
                </Stack>
              </Stack>

              <Stack horizontal horizontalAlign="end">
                <Stack vertical>
                  <h4 style={{ textAlign: 'right' }}>ภาษีมูลค่าเพิ่ม 7%&nbsp;</h4>
                </Stack>
                <Stack vertical style={{ width: '60px' }}></Stack>
                <Stack vertical style={{ textAlign: 'right', width: '110px' }}>
                  <h4>{vatTotal.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท</h4>
                </Stack>
              </Stack>
              <br />
              <Separator />
              <Stack horizontal horizontalAlign="space-between">
                <h2 style={{ marginTop: '5px' }}>ยอดรวม</h2>
                <h2 style={{ marginTop: '5px' }}>
                  {grandTotal.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท
                </h2>
              </Stack>

              <div></div>
              <center>
                <br />
                <small>
                  หากกดปุ่ม "ชำระเงิน" ถือว่าท่านยอมรับ{' '}
                  <a href="https://www.leceipt.com/terms-conditions" target="_blank" rel="noreferrer">
                    เงื่อนไขและข้อตกลงการใช้งาน
                  </a>
                </small>
                <br />
                <br />
                <br />
                <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
                  {!isLoading ? (
                    <PrimaryButton type="submit" text="ชำระเงิน" value="pay" />
                  ) : (
                    <Spinner label="กำลังโหลดข้อมูล..." labelPosition="right" />
                  )}
                </Stack>
              </center>
            </Stack>
          </Stack>
          <br />

          <br />
          <br />
        </div>
        <br />
        <br />

        <Dialog
          hidden={!(chargeStatus === 'processing')}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'กำลังดำเนินการ...',
          }}
        >
          <Spinner label="เข้าสู่การชำระเงิน..." labelPosition="down" styles={spinnerStyles} />
        </Dialog>

        <Dialog
          hidden={!(chargeStatus === 'successful')}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'การชำระเงินสำเร็จ',
            subText: 'ขอบคุณที่ใช้บริการ',
          }}
        >
          <center>
            <img height="180" src="/images/success03.gif" alt="success03gif" />
          </center>
          <br />
          <br />
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                window.location.href = '/etax/subscriptions';
              }}
              text="รายละเอียด"
            />
          </DialogFooter>
        </Dialog>

        <Dialog
          hidden={!(chargeStatus === 'fail')}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'การชำระเงินไม่สำเร็จ',
            subText: 'กรุณาทำรายการใหม่',
          }}
        >
          <center>
            <img width="200" src="/images/fail01.gif" alt="fail01gif" />
          </center>
          <br />
          <br />
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                window.location.reload();
              }}
              text="เริ่มใหม่"
            />
          </DialogFooter>
        </Dialog>
      </Stack>
    </form>
  );
};

export default Checkout;
