import React from 'react';
import ReactDOM from 'react-dom';

// React 18
// import { createRoot } from 'react-dom/client';

import App from './components/App';

//import * as serviceWorker from './serviceWorker';
//import authentication from 'react-azure-adb2c';
/* 
authentication.initialize({
    instance: process.env.REACT_APP_ADD_INSTANCE, 
    tenant: process.env.REACT_APP_AAD_DOMAIN,
    signInPolicy: process.env.REACT_APP_AAD_POLICY_ID,
    applicationId: process.env.REACT_APP_AAD_CLIENT_ID,
    cacheLocation: 'sessionStorage',
    //scopes: ['openid', 'https://leceipt.onmicrosoft.com/api/user_impersonation'],
    scopes: [process.env.REACT_APP_AAD_SCOPES],
    redirectUri: process.env.REACT_APP_URL,
    //validateAuthority: false,
    postLogoutRedirectUri: window.location.origin,
    
    /* validateAuthority: false,
    silentLoginOnly: false, 
});


authentication.run(() => {
    //ReactDOM.render(<App />, document.getElementById('root'));
    ReactDOM.render(<App />, document.querySelector('#root'));
    //serviceWorker.unregister();
});
   */
/* 
ReactDOM.render(
    <App />
    , document.querySelector('#root')
);
 */

// MSAL imports
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import './translations/i18n';


//import App from "./test.jsx";

//console.log = function () {};

//console.log('########################', process.env.NODE_ENV)

if (process.env.NODE_ENV !== "development") {
    console.log = function () {};
}

const msalInstance = new PublicClientApplication(msalConfig);

/* 
// React 18
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
 */

ReactDOM.render(
	// <React.StrictMode>
		<App pca={msalInstance} />,
	// </React.StrictMode>,
	document.getElementById('root')
);

/* 
ReactDOM.render(
    <App />
    , document.querySelector('#root')
);
 */



