import React, { useState } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../../../authConfig';

import { useHistory } from 'react-router-dom';

import { Stack, Icon, PrimaryButton, DefaultButton, TextField, FocusTrapZone, Spinner } from '@fluentui/react';

import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

var forge = require('node-forge');

const API_URL = process.env.REACT_APP_API_URL;

//Dropzone Style

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return 'rgb(134, 134, 134)';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #ffffff;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-top: 5px;
`;

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const P12 = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const history = useHistory();

  const [selectedFile, setSelectedFile] = useState(null);
  const [loadP12Complete, setLoadP12Complete] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');

  const FileDropzone = () => {
    //Dropzone
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } = useDropzone({
      accept: '.pfx, .p12, application/x-pkcs12, application/pkcs12',
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      multiple: false,
      minSize: 0,
      maxSize: 20971520,
      onDrop: (files) => {
        console.log(files);
        console.log('File[0]: ', files[0]);

        if (files[0].type === 'application/x-pkcs12' || files[0].type === 'application/pkcs12') {
          setSelectedFile(files[0]);
          console.log('Set selected file complete.');

          setLoadP12Complete(true);
        }
      },
    });

    return (
      <div>
        <div style={{ width: 700 }}>
          <Container
            {...getRootProps({
              isDragActive,
              isDragAccept,
              isDragReject,
            })}
          >
            <input {...getInputProps()} />
            <br />
            <div style={{ fontSize: '80px', color: 'rgba(45, 45, 83, 0.808)' }}>
              <Icon iconName="Certificate" style={{ color: '#106ebe' }} />
            </div>
            <Stack horizontal horizontalAlign="center">
              <br />
              <PrimaryButton style={{ fontSize: '20px', height: '50px', width: '150px' }} onClick={open} text="เลือกไฟล์" />

              <br />
              <br />
              <br />
              <br />
            </Stack>
            <Stack horizontal horizontalAlign="center">
              <br />
              <br />

              <span style={{ fontSize: '25px', color: 'rgb(151, 151, 151)', fontWeight: '500' }}>
                วางไฟล์หรือคลิกที่นี่เพื่ออัปโหลด ไฟล์ .p12 หรือ .pfx
              </span>
            </Stack>
          </Container>
        </div>
      </div>
    );
  };

  const openFile = () => {
    let reader = new FileReader();
    reader.onload = function (e) {
      let fileContent = e.target.result;

      // decrypt p12 using the password returned by getPassword(), the password should be protected and not hard coded
      try {
        // get p12 as ASN.1 object
        var p12Asn1 = forge.asn1.fromDer(fileContent);

        var p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, password);

        // get bags by type
        var certBags = p12.getBags({ bagType: forge.pki.oids.certBag });
        var pkeyBags = p12.getBags({ bagType: forge.pki.oids.pkcs8ShroudedKeyBag });
        // fetching certBag
        var certBag = certBags[forge.pki.oids.certBag][0];
        // fetching keyBag
        var keybag = pkeyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0];
        // generate pem from private key
        //var privateKeyPem = forge.pki.privateKeyToPem(keybag.key);
        // generate pem from cert
        //var certificate = forge.pki.certificateToPem(certBag.cert);

        if (certBag && keybag) {
          console.log('Success then upload file!');

          setIsLoading(true);

          uploadFile();
        }
      } catch (error) {
        console.log(error);
        console.log('Can not open file.');
        setPasswordError('รหัสเปิดไฟล์ไม่ถูกต้อง กรุณาลองอีกครั้ง...');
      }
    };

    reader.readAsBinaryString(selectedFile);
  };

  const uploadFile = () => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        console.log('Access token: ', tokenResponse.accessToken);
        console.log('Token: ', tokenResponse);

        let promises = [];

        //Encode base64
        let buff = Buffer.from(password);
        let base64pwd = buff.toString('base64');

        let formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('password', base64pwd);

        promises.push(
          axios.post(API_URL + '/certificates', formData, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'multipart/form-data',
            },
          })
        );

        Promise.all(promises).then(function (results) {
          results.forEach(function (response) {
            console.log('Response upload: ', response.data);

            if (response.data.id) {
              setIsLoading(false);
              setUploadStatus('successful');
            } else {
              setIsLoading(false);
              setUploadStatus('fail');
            }
          });
        });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const clearData = () => {
    setSelectedFile(null);
    setIsLoading(false);
    setUploadStatus('');
    setPassword('');
    setPasswordError('');
    setLoadP12Complete(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('password: ', password);
    console.log('selected file: ', selectedFile);

    openFile();
  };

  return (
    <Stack>
      <h1 style={{ marginLeft: '0px', marginTop: '27px' }}>
        <Icon iconName="Certificate" style={{ color: '#106ebe' }} /> ใบรับรองอิเล็กทรอนิกส์
      </h1>
      <h3 style={{ marginTop: '0px', marginBottom: '10px' }}>ใบรับรองอิเล็กทรอนิกส์จาก TDID หรือ INET</h3>
      <br />

      {!loadP12Complete && !isLoading && (
        <Stack>
          <FileDropzone />

          <br />
          <br />
          <br />
          <Stack horizontal horizontalAlign="center">
            <DefaultButton
              style={{ fontSize: '20px', height: '50px' }}
              onClick={() => {
                history.push({
                  pathname: '/etax/certificates/add',
                  state: {},
                });
              }}
            >
              ย้อนกลับ
            </DefaultButton>
          </Stack>
        </Stack>
      )}

      {loadP12Complete && !isLoading && uploadStatus === '' && (
        <FocusTrapZone disabled={false}>
          <Stack horizontalAlign="center">
            <br />
            <Stack style={{ border: '1px solid rgb(134, 134, 134)', width: '350px', backgroundColor: '#FFFFFF' }}>
              <br />
              <br />

              <Stack horizontal horizontalAlign="center">
                <Icon iconName="AzureKeyVault" style={{ fontSize: '55px', color: '#106ebe' }} />
              </Stack>

              <br />
              <form onSubmit={handleSubmit}>
                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="รหัสสำหรับเปิดไฟล์ .p12 หรือ .pfx"
                    type="password"
                    canRevealPassword
                    revealPasswordAriaLabel="แสดงรหัส"
                    required
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError('');
                    }}
                    value={password}
                    errorMessage={passwordError}
                  />
                </Stack>

                <br />
                <br />
                <br />
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
                  <PrimaryButton style={{ fontSize: '20px', height: '50px' }} type="submit">
                    อัปโหลด
                  </PrimaryButton>

                  <DefaultButton
                    style={{ fontSize: '20px', height: '50px' }}
                    onClick={() => {
                      clearData();
                    }}
                  >
                    ย้อนกลับ
                  </DefaultButton>
                </Stack>
              </form>
              <br />
              <br />
            </Stack>
          </Stack>
        </FocusTrapZone>
      )}

      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              <Spinner label="กำลังดำเนินการ..." labelPosition="down" styles={spinnerStyles} />
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {uploadStatus === 'successful' && (
        <Stack horizontal horizontalAlign="center">
          <Stack style={{ border: '1px solid rgb(134, 134, 134)', width: '300px', backgroundColor: '#FFFFFF' }}>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#0078D4' }}>สำเร็จ!</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/success03.gif" alt="success03gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  history.push({
                    pathname: '/etax/certificates',
                    state: {},
                  });

                  clearData();
                }}
              >
                รายละเอียดใบรับรอง
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      {uploadStatus === 'fail' && (
        <Stack horizontal horizontalAlign="center">
          <Stack style={{ border: '1px solid rgb(134, 134, 134)', width: '300px', backgroundColor: '#FFFFFF' }}>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#F25139' }}>ไม่สำเร็จ!</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/fail01.gif" alt="fail01gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  clearData();
                  history.push({
                    pathname: '/etax/certificates/add/p12',
                    state: {},
                  });
                }}
              >
                ลองอีกครั้ง
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default P12;
