import React from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, getTheme, Icon } from '@fluentui/react';

const theme = getTheme();

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const cardStyle = {
  width: 250,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 20,
  paddingBottom: 30,
  background: '#ffffff',
  marginLeft: '0',
  marginTop: '10px',
  boxShadow: theme.effects.elevation4,
};

const ExcelMenu = () => {
  const { t, i18n } = useTranslation();

  const history = useHistory();

  return (
    <div style={homeStyles}>
      <br />
      <Stack>
        <center>
          <h1 style={{ marginLeft: '0px', marginTop: '25px' }}>
            <Icon iconName="ExcelDocument" style={{ color: '#106ebe' }} /> {t('excelMenu.header')}
          </h1>

          <h2>{t('excelMenu.subHeader')}</h2>
        </center>

        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
          <Stack
            onClick={() =>
              history.push({
                pathname: '/etax/sources/excel',
                state: {
                  command: "eTax"
                },
              })
            }
            styles={{ root: { cursor: 'pointer' } }}
          >
            <div style={cardStyle}>
              <Stack>
                <center>
                  <Icon iconName="TripleColumnEdit" style={{ fontSize: '150px', color: '#106ebe' }} />
                  <h1>Template</h1>
                  <h2>{t('excelMenu.descriptionFirstMenu')}</h2>
                </center>
              </Stack>
            </div>
          </Stack>
          <Stack
            onClick={() =>
              history.push({
                pathname: '/etax/sources/excel',
                state: {
                  command: "creditDebit"
                },
              })
            }
            styles={{ root: { cursor: 'pointer' } }}
          >
            <div style={cardStyle}>
              <Stack>
                <center>
                  <Icon iconName="TouchPointer" style={{ fontSize: '150px', color: '#106ebe' }} />
                  <h1>Template</h1>
                  <h2 style={{ ...(i18n.language === 'th' ? { marginBottom: 0 } : {}) }}>{t('excelMenu.descriptionSecondMenu')}</h2>
                  {/* {i18n.language === 'th' ? <h2 style={{ marginTop: 0 }}>Drag & Drop</h2> : null} */}
                </center>
              </Stack>
            </div>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default ExcelMenu;
