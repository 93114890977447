import { useLocation, useHistory } from 'react-router-dom';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { validateData, validateDataEN } from './validate';

var moment = require('moment'); // require
moment().format();

export default function ContactsResult(props) {
  const history = useHistory();
  const location = useLocation();

  const { t, i18n } = useTranslation();

  const [items, setItems] = useState(location.state.items);
  const mergeArray = location.state.mergeArray;
  const contactList = location.state.contactList;

  console.log('items', items);

  const isCompactMode = false;

  const columns = [
    {
      key: 'column1',
      name: t('contactDropzoneResult.name'),
      fieldName: 'name',
      minWidth: 130,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'column2',
      name: t('contactDropzoneResult.taxId'),
      fieldName: 'taxId',
      minWidth: 70,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'column3',
      name: t('contactDropzoneResult.branchNumber'),
      fieldName: 'branchNumber',
      minWidth: 70,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'column4',
      name: t('contactDropzoneResult.error'),
      fieldName: 'error',
      minWidth: 300,
      isResizable: true,
      data: 'string',
      isMultiline: true,
      onRender: (value) => {
        return (
          <div className={mergeStyles({ color: 'red' })}>
            {value.error.map((text, index) => {
              return <div key={`err-${index}`}>{text}</div>;
            })}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    let failItems = [];
    mergeArray.forEach((row) => {
      if (validateData(row, contactList).length > 0) {
        failItems.push({
          name: row['name'],
          taxId: row['taxId'],
          branchNumber: row['branchNumber'],
          error: i18n.language === 'th' ? validateData(row, contactList) : validateDataEN(row, contactList),
        });
      }
    });

    setItems(failItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div>
      <Stack
        horizontal
        horizontalAlign="center"
        tokens={{ childrenGap: 25 }}
        style={{
          paddingTop: '10px',
          paddingBottom: '10px',
          background: '#4D4D4D',
          width: 'calc(100vw - 303px)',
          minWidth: '1113px',
        }}
      >
        <button style={{ width: '90px', height: '30px' }} onClick={() => history.push({ pathname: '/contacts/excel/upload', state: {} })}>
          {t('contactDropzoneResult.back')}
        </button>
      </Stack>
      <Stack horizontal horizontalAlign="center">
        <h1>{t('contactDropzoneResult.header')}</h1>
      </Stack>

      <Stack
        vertical
        style={{
          width: '98%',
          minWidth: '1000px',
          marginLeft: '10px',
          maxWidth: '1350px',
        }}
      >
        <DetailsList
          items={items}
          compact={isCompactMode}
          columns={columns}
          selectionMode={SelectionMode.none}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
        />
      </Stack>
    </div>
  );
}

<ContactsResult />;
