export const whtIncomes = [
  { incomeCode: '', incomeNameTh: '-', incomeNameEn: '-' },
  {
    incomeCode: '390',
    incomeNameTh: 'วิชาชีพอิสระ - ประณีตศิลปกรรม',
    incomeNameEn: 'วิชาชีพอิสระ - ประณีตศิลปกรรม',
  },
  {
    incomeCode: '391',
    incomeNameTh: 'วิชาชีพอิสระ - การบัญชี',
    incomeNameEn: 'วิชาชีพอิสระ - การบัญชี',
  },
  {
    incomeCode: '392',
    incomeNameTh: 'วิชาชีพอิสระ - สถาปัตยกรรม',
    incomeNameEn: 'วิชาชีพอิสระ - สถาปัตยกรรม',
  },
  {
    incomeCode: '393',
    incomeNameTh: 'วิชาชีพอิสระ - วิศวกรรม',
    incomeNameEn: 'วิชาชีพอิสระ - วิศวกรรม',
  },
  {
    incomeCode: '394',
    incomeNameTh: 'วิชาชีพอิสระ - การประกอบโรคศิลปะ',
    incomeNameEn: 'วิชาชีพอิสระ - การประกอบโรคศิลปะ',
  },
  {
    incomeCode: '395',
    incomeNameTh: 'วิชาชีพอิสระ - วิชากฎหมาย',
    incomeNameEn: 'วิชาชีพอิสระ - วิชากฎหมาย',
  },
  { incomeCode: '396', incomeNameTh: 'ค่านายหน้า', incomeNameEn: 'ค่านายหน้า' },
  {
    incomeCode: '397',
    incomeNameTh: 'ดอกเบี้ยพันธบัตร ดอกเบี้ยหุ้นกู้ ฯ ตามมาตรา 40(4)(ก)',
    incomeNameEn: 'ดอกเบี้ยพันธบัตร ดอกเบี้ยหุ้นกู้ ฯ ตามมาตรา 40(4)(ก)',
  },
  {
    incomeCode: '398',
    incomeNameTh: 'เงินปันผล หรือส่วนแบ่งกำไร ฯ ตามมาตรา 40(4)(ข)',
    incomeNameEn: 'เงินปันผล หรือส่วนแบ่งกำไร ฯ ตามมาตรา 40(4)(ข)',
  },
  {
    incomeCode: '399',
    incomeNameTh:
      'ค่าบริการอื่นที่ไม่ใช่ค่าบริการ โรงแรม ภัตตาคารและค่าเบี้ยประกันชีวิต',
    incomeNameEn:
      'ค่าบริการอื่นที่ไม่ใช่ค่าบริการ โรงแรม ภัตตาคารและค่าเบี้ยประกันชีวิต',
  },
  {
    incomeCode: '400',
    incomeNameTh: 'ค่าเบี้ยประกันวินาศภัย',
    incomeNameEn: 'ค่าเบี้ยประกันวินาศภัย',
  },
  {
    incomeCode: '401',
    incomeNameTh: 'ค่าขนส่งที่ไม่ใช่ขนส่งสาธารณะ',
    incomeNameEn: 'ค่าขนส่งที่ไม่ใช่ขนส่งสาธารณะ',
  },
  {
    incomeCode: '402',
    incomeNameTh: 'รางวัลส่วนลด หรือประโยชน์จากการส่งเสริมการขาย',
    incomeNameEn: 'รางวัลส่วนลด หรือประโยชน์จากการส่งเสริมการขาย',
  },
  {
    incomeCode: '403',
    incomeNameTh:
      'ซื้อสินค้าประเภทยางแผ่น มันสำปะหลัง ปอ ข้าว ข้าวโพด อ้อย เมล็ดกาแฟ ผลปาล์มน้ำมัน',
    incomeNameEn:
      'ซื้อสินค้าประเภทยางแผ่น มันสำปะหลัง ปอ ข้าว ข้าวโพด อ้อย เมล็ดกาแฟ ผลปาล์มน้ำมัน',
  },
  { incomeCode: '404', incomeNameTh: 'ค่าโฆษณา', incomeNameEn: 'ค่าโฆษณา' },
  {
    incomeCode: '405',
    incomeNameTh: 'รางวัลการประกวด/แข่งขัน/ชิงโชค',
    incomeNameEn: 'รางวัลการประกวด/แข่งขัน/ชิงโชค',
  },
  {
    incomeCode: '406',
    incomeNameTh: 'ค่าจ้างทำของ',
    incomeNameEn: 'ค่าจ้างทำของ',
  },
  {
    incomeCode: '407',
    incomeNameTh: 'วิชาชีพอิสระ',
    incomeNameEn: 'วิชาชีพอิสระ',
  },
  {
    incomeCode: '408',
    incomeNameTh: 'การให้เช่าทรัพย์สิน',
    incomeNameEn: 'การให้เช่าทรัพย์สิน',
  },
  { incomeCode: '999', incomeNameTh: 'อื่นๆ', incomeNameEn: 'อื่นๆ' },
];

export const getWhtIncomeList = (language) => {
  //console.log(district);
  //console.log('ProvinceCode:', provinceCode);
  //console.log(district.length);

  let list = [];
  //list.push({ key: '', text: 'กรุณาเลือก' });
  for (let i = 0; i < whtIncomes.length; i++) {
    if (language === 'en') {
      list.push({
        key: whtIncomes[i].incomeCode,
        text: whtIncomes[i].incomeNameEn,
      });
    } else {
      list.push({
        key: whtIncomes[i].incomeCode,
        text: whtIncomes[i].incomeNameTh,
      });
    }
  }

  return list;

  //let list = this.district.filter();
};

export const getWhtIncome = (incomeCode, language) => {
  //console.log(district);
  //console.log('ProvinceCode:', provinceCode);
  //console.log(district.length);

  let list = {};
  //list.push({ key: '', text: 'กรุณาเลือก' });
  for (let i = 0; i < whtIncomes.length; i++) {
    if (whtIncomes[i].incomeCode === incomeCode) {
      if (language === 'en') {
        list = {
          key: whtIncomes[i].incomeCode,
          text: whtIncomes[i].incomeNameEn,
        };
      } else {
        list = {
          key: whtIncomes[i].incomeCode,
          text: whtIncomes[i].incomeNameTh,
        };
      }
    }
  }

  return list;

  //let list = this.district.filter();
};

export const getWhtIncomeByName = (incomeName, language) => {
  let list = {};

  for (let i = 0; i < whtIncomes.length; i++) {
    if (language === 'en') {
      if (whtIncomes[i].incomeNameEn === incomeName) {
        list = {
          key: whtIncomes[i].incomeCode,
          text: whtIncomes[i].incomeNameEn,
        };
      }
    } else {
      if (whtIncomes[i].incomeNameTh === incomeName) {
        list = {
          key: whtIncomes[i].incomeCode,
          text: whtIncomes[i].incomeNameTh,
        };
      }
    }
  }

  return list;
};

export const getWhtIncomeByNameWithoutLanguage = (incomeName) => {
  let list = {};

  for (let i = 0; i < whtIncomes.length; i++) {
    if (
      whtIncomes[i].incomeNameEn === incomeName ||
      whtIncomes[i].incomeNameTh === incomeName
    ) {
      list = {
        key: whtIncomes[i].incomeCode,
        textEn: whtIncomes[i].incomeNameEn,
        textTh: whtIncomes[i].incomeNameTh,
      };
    }
  }

  return list;
};
