import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  Icon,
  Calendar,
  DateRangeType,
  Spinner,
  SpinnerSize,
  SearchBox,
  CommandBar,
  Stack,
  PrimaryButton,
  DefaultButton,
  TextField,
  ContextualMenu,
  CheckboxVisibility,
  Dialog,
  DialogType,
  DialogFooter,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import { numberToTextEn, numberToTextTh } from '../../../helpers/BahtWord';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const onFormatDate = (date, format) => {
  if (format === 'CE') {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
  }
};

const DynamicsCrm = () => {
  console.log('Home render!!!');

  const history = useHistory();

  const { t, i18n } = useTranslation();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem('ms-sales-pageSize') * 1 || 10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('ms-sales-currentPage') * 1 || 1);

  const dateType = { key: 'document', text: 'วันที่บนเอกสาร' };

  // active => active
  // complete => 100001
  // partial => 100002
  // cancel => 100003
  const [invoiceType, setInvoiceType] = useState(
    (sessionStorage.getItem('ms-sales-invoiceType-key') && {
      key: sessionStorage.getItem('ms-sales-invoiceType-key'),
      text: sessionStorage.getItem('ms-sales-invoiceType-text'),
    }) || {
      key: 'all',
      text: t('dynamicCrm.allType'),
    }
  );

  const [allDocs, setAllDocs] = useState([]);

  const [company, setCompany] = useState('');
  const [search, setSearch] = useState('');

  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  // const nonVatText = '-';

  const [isLoading, setIsLoading] = useState(false);

  const isCompactMode = false;

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        console.log('isCreateDocument: ', isCreateDocument);
        console.log('isCreateDocumentComplete: ', isCreateDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const [createdBy, setCreatedBy] = useState('');

  const companyId = sessionStorage.getItem('companyId') || '';

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('ms-sales-primarySort') || 'createdTime');
  const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
    if (sessionStorage.getItem('ms-sales-sort-createdTime') === 'false') {
      return false;
    } else {
      return true;
    }
  });

  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('ms-sales-sort-number')) {
      if (sessionStorage.getItem('ms-sales-sort-number') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('ms-sales-sort-customerName')) {
      if (sessionStorage.getItem('ms-sales-sort-customerName') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingAmountTotal, setIsSortedDescendingAmountTotal] = useState(() => {
    if (sessionStorage.getItem('ms-sales-sort-amountTotal')) {
      if (sessionStorage.getItem('ms-sales-sort-amountTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(() => {
    if (sessionStorage.getItem('ms-sales-sort-vatTotal')) {
      if (sessionStorage.getItem('ms-sales-sort-vatTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem('ms-sales-sort-grandTotal')) {
      if (sessionStorage.getItem('ms-sales-sort-grandTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const DayPickerStrings = {
    months: [
      t('dynamicCrm.months01'),
      t('dynamicCrm.months02'),
      t('dynamicCrm.months03'),
      t('dynamicCrm.months04'),
      t('dynamicCrm.months05'),
      t('dynamicCrm.months06'),
      t('dynamicCrm.months07'),
      t('dynamicCrm.months08'),
      t('dynamicCrm.months09'),
      t('dynamicCrm.months10'),
      t('dynamicCrm.months11'),
      t('dynamicCrm.months12'),
    ],

    shortMonths: [
      t('dynamicCrm.shortMonths01'),
      t('dynamicCrm.shortMonths02'),
      t('dynamicCrm.shortMonths03'),
      t('dynamicCrm.shortMonths04'),
      t('dynamicCrm.shortMonths05'),
      t('dynamicCrm.shortMonths06'),
      t('dynamicCrm.shortMonths07'),
      t('dynamicCrm.shortMonths08'),
      t('dynamicCrm.shortMonths09'),
      t('dynamicCrm.shortMonths10'),
      t('dynamicCrm.shortMonths11'),
      t('dynamicCrm.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('dynamicCrm.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('dynamicCrm.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  // Processing dialog style
  const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4,
    },
  };

  const getMonth = (goBack) => {
    let monthNames = [
      t('dynamicCrm.months01'),
      t('dynamicCrm.months02'),
      t('dynamicCrm.months03'),
      t('dynamicCrm.months04'),
      t('dynamicCrm.months05'),
      t('dynamicCrm.months06'),
      t('dynamicCrm.months07'),
      t('dynamicCrm.months08'),
      t('dynamicCrm.months09'),
      t('dynamicCrm.months10'),
      t('dynamicCrm.months11'),
      t('dynamicCrm.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [date, setDate] = useState(sessionStorage.getItem('ms-sales-date') ? new Date(sessionStorage.getItem('ms-sales-date')) : new Date());

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem('ms-sales-queryMonth-key') && {
      key: sessionStorage.getItem('ms-sales-queryMonth-key'),
      text: sessionStorage.getItem('ms-sales-queryMonth-text'),
    }) || { key: getMonth(0).year + '' + getMonth(0).key, text: t('dynamicCrm.thisMonth') }
  );

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);

    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);

    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('ms-sales-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('ms-sales-to') || getTo(getMonth(0).month, getMonth(0).year));

  let _pageDropdown = [];

  for (let i = 1; i <= 35; i++) {
    _pageDropdown.push({
      key: i,
      text: t('dynamicCrm.page') + ' ' + i,
      iconProps: { iconName: 'Page' },
      onClick: () => {
        console.log('Selected page: ' + i);
        setCurrentPage(i);
      },
    });
  }

  const [pageDropdown, setPageDropdown] = useState(_pageDropdown);

  function getDocumentTypeText(key) {
    if (key === 'all') return t('dynamicCrm.allType');
    if (key === 'active') return t('dynamicCrm.activeType');
    if (key === 100001) return t('dynamicCrm.completeType');
    if (key === 100002) return t('dynamicCrm.partialType');
    if (key === 100003) return t('dynamicCrm.cancelType');
  }

  function getDateText(monthObject) {
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('dynamicCrm.thisMonth');

    let monthNames = [
      t('dynamicCrm.months01'),
      t('dynamicCrm.months02'),
      t('dynamicCrm.months03'),
      t('dynamicCrm.months04'),
      t('dynamicCrm.months05'),
      t('dynamicCrm.months06'),
      t('dynamicCrm.months07'),
      t('dynamicCrm.months08'),
      t('dynamicCrm.months09'),
      t('dynamicCrm.months10'),
      t('dynamicCrm.months11'),
      t('dynamicCrm.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: invoiceType.key,
      text: getDocumentTypeText(invoiceType.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'all',
            text: t('dynamicCrm.allType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('เอกสารทุกประเภท');
              setInvoiceType({ key: 'all', text: t('dynamicCrm.allType') });

              sessionStorage.setItem('ms-sales-invoiceType-key', 'all');
              sessionStorage.setItem('ms-sales-invoiceType-text', t('dynamicCrm.allType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingAmountTotal(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('ms-sales-primarySort', 'createdTime');
              sessionStorage.setItem('ms-sales-sort-createdTime', 'false');
              sessionStorage.setItem('ms-sales-sort-number', 'false');
              sessionStorage.setItem('ms-sales-sort-customerName', 'false');
              sessionStorage.setItem('ms-sales-sort-amountTotal', 'false');
              sessionStorage.setItem('ms-sales-sort-vatTotal', 'false');
              sessionStorage.setItem('ms-sales-sort-grandTotal', 'false');
            },
          },
          {
            key: 'active',
            text: t('dynamicCrm.activeType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('Active');
              setInvoiceType({ key: 'active', text: t('dynamicCrm.activeType') });

              sessionStorage.setItem('ms-sales-invoiceType-key', 'active');
              sessionStorage.setItem('ms-sales-invoiceType-text', t('dynamicCrm.activeType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingAmountTotal(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('ms-sales-primarySort', 'createdTime');
              sessionStorage.setItem('ms-sales-sort-createdTime', 'false');
              sessionStorage.setItem('ms-sales-sort-number', 'false');
              sessionStorage.setItem('ms-sales-sort-customerName', 'false');
              sessionStorage.setItem('ms-sales-sort-amountTotal', 'false');
              sessionStorage.setItem('ms-sales-sort-vatTotal', 'false');
              sessionStorage.setItem('ms-sales-sort-grandTotal', 'false');
            },
          },
          {
            key: 100001,
            text: t('dynamicCrm.completeType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('Complete');
              setInvoiceType({ key: 100001, text: t('dynamicCrm.completeType') });

              sessionStorage.setItem('ms-sales-invoiceType-key', 100001);
              sessionStorage.setItem('ms-sales-invoiceType-text', t('dynamicCrm.completeType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingAmountTotal(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('ms-sales-primarySort', 'createdTime');
              sessionStorage.setItem('ms-sales-sort-createdTime', 'false');
              sessionStorage.setItem('ms-sales-sort-number', 'false');
              sessionStorage.setItem('ms-sales-sort-customerName', 'false');
              sessionStorage.setItem('ms-sales-sort-amountTotal', 'false');
              sessionStorage.setItem('ms-sales-sort-vatTotal', 'false');
              sessionStorage.setItem('ms-sales-sort-grandTotal', 'false');
            },
          },
          {
            key: 100002,
            text: t('dynamicCrm.partialType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('Partial');
              setInvoiceType({ key: 100002, text: t('dynamicCrm.partialType') });

              sessionStorage.setItem('ms-sales-invoiceType-key', 100002);
              sessionStorage.setItem('ms-sales-invoiceType-text', t('dynamicCrm.partialType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingAmountTotal(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('ms-sales-primarySort', 'createdTime');
              sessionStorage.setItem('ms-sales-sort-createdTime', 'false');
              sessionStorage.setItem('ms-sales-sort-number', 'false');
              sessionStorage.setItem('ms-sales-sort-customerName', 'false');
              sessionStorage.setItem('ms-sales-sort-amountTotal', 'false');
              sessionStorage.setItem('ms-sales-sort-vatTotal', 'false');
              sessionStorage.setItem('ms-sales-sort-grandTotal', 'false');
            },
          },
          {
            key: 100003,
            text: t('dynamicCrm.cancelType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('Cancel');
              setInvoiceType({ key: 100003, text: t('dynamicCrm.cancelType') });

              sessionStorage.setItem('ms-sales-invoiceType-key', 100003);
              sessionStorage.setItem('ms-sales-invoiceType-text', t('dynamicCrm.cancelType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingAmountTotal(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('ms-sales-primarySort', 'createdTime');
              sessionStorage.setItem('ms-sales-sort-createdTime', 'false');
              sessionStorage.setItem('ms-sales-sort-number', 'false');
              sessionStorage.setItem('ms-sales-sort-customerName', 'false');
              sessionStorage.setItem('ms-sales-sort-amountTotal', 'false');
              sessionStorage.setItem('ms-sales-sort-vatTotal', 'false');
              sessionStorage.setItem('ms-sales-sort-grandTotal', 'false');
            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('dynamicCrm.months01'),
                    t('dynamicCrm.months02'),
                    t('dynamicCrm.months03'),
                    t('dynamicCrm.months04'),
                    t('dynamicCrm.months05'),
                    t('dynamicCrm.months06'),
                    t('dynamicCrm.months07'),
                    t('dynamicCrm.months08'),
                    t('dynamicCrm.months09'),
                    t('dynamicCrm.months10'),
                    t('dynamicCrm.months11'),
                    t('dynamicCrm.months12'),
                  ];

                  setDate(_date);

                  sessionStorage.setItem('ms-sales-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  sessionStorage.setItem(
                    'ms-sales-queryMonth-key',
                    adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0')
                  );
                  sessionStorage.setItem('ms-sales-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem('ms-sales-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem('ms-sales-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);

                  setCurrentPage(1);

                  setSearch('');

                  setPrimarySort('createdTime');
                  setIsSortedDescendingCreatedTime(true);
                  setIsSortedDescendingNumber(false);
                  setIsSortedDescendingCustomerName(false);
                  setIsSortedDescendingAmountTotal(false);
                  setIsSortedDescendingVatTotal(false);
                  setIsSortedDescendingGrandTotal(false);

                  sessionStorage.setItem('ms-sales-primarySort', 'createdTime');
                  sessionStorage.setItem('ms-sales-sort-createdTime', 'true');
                  sessionStorage.setItem('ms-sales-sort-number', 'false');
                  sessionStorage.setItem('ms-sales-sort-customerName', 'false');
                  sessionStorage.setItem('ms-sales-sort-amountTotal', 'false');
                  sessionStorage.setItem('ms-sales-sort-vatTotal', 'false');
                  sessionStorage.setItem('ms-sales-sort-grandTotal', 'false');
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
            </div>
          );
        },
      },
    },
    {
      key: pageSize,
      text: pageSize + ' ' + t('dynamicCrm.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 10,
            text: '10 ' + t('dynamicCrm.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 เอกสาร/หน้า');
              setPageSize(10);
              sessionStorage.setItem('ms-sales-pageSize', 10);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 20,
            text: '20 ' + t('dynamicCrm.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 เอกสาร/หน้า');
              setPageSize(20);
              sessionStorage.setItem('ms-sales-pageSize', 20);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 30,
            text: '30 ' + t('dynamicCrm.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 เอกสาร/หน้า');
              setPageSize(30);
              sessionStorage.setItem('ms-sales-pageSize', 30);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 40,
            text: '40 ' + t('dynamicCrm.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 เอกสาร/หน้า');
              setPageSize(40);
              sessionStorage.setItem('ms-sales-pageSize', 40);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 50,
            text: '50 ' + t('dynamicCrm.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 เอกสาร/หน้า');
              setPageSize(50);
              sessionStorage.setItem('ms-sales-pageSize', 50);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 100,
            text: '100 ' + t('dynamicCrm.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('100 เอกสาร/หน้า');
              setPageSize(100);
              sessionStorage.setItem('ms-sales-pageSize', 100);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
        ],
      },
    },
    {
      key: currentPage,
      text: t('dynamicCrm.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: 'refresh',
      text: t('dynamicCrm.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isCreateDocument || search,
      onClick: () => {
        console.log('Refresh');

        setCurrentPage(1);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
  ];

  const overflowItems = [
    {
      key: 'disconnect',
      text: t('dynamicCrm.disconnect'),
      onClick: () => {
        console.log('ms-sales disconnect!');

        setDialogConfirm((prev) => ({
          ...prev,
          isClick: true,
          header: t('dynamicCrm.disconnect'),
          message: t('dynamicCrm.disconnectConfirm'),
          onConfirm: () => {
            disconnect(true);
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));
          },
          onCancel: () =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            })),
        }));
      },
      iconProps: { iconName: 'PlugDisconnected' },
    //   disabled: !loadDataComplete || isCreateDocument || search,
    },
  ];

  const createDocument = async (documentType, item) => {
    setIsLoading(true);

    let documentPath = '/etax/documents/receipts/confirm';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices/confirm';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoices-abb/confirm';
        break;
      case 'RECEIPT':
        documentPath = '/etax/documents/receipts/confirm';
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices/confirm';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices/confirm';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices/confirm';
        break;
      default:
        break;
    }

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        const [responseAccount, responseContact, responseInvoiceDetail] = await Promise.all([
          axios
            .get(API_URL + '/sources/dynamics/crm/accounts', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
              params: {
                customerId: item.customerId,
              },
            })
            .catch((error) => console.log(error)),

          axios
            .get(API_URL + '/sources/dynamics/crm/contacts', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
              params: {
                customerId: item.customerId,
              },
            })
            .catch((error) => console.log(error)),

          axios
            .get(API_URL + '/sources/dynamics/crm/invoices/' + item.key, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            })
            .catch((error) => console.log(error)),
        ]);

        let _customer = {};

        if (responseAccount.data.length) {
          const customerData = responseAccount.data[0];

          _customer.addressLineOne = customerData.address1_composite;
          _customer.addressLineTwo = '';
          _customer.branchNumber = '';
          _customer.branchText = '';
          _customer.email = customerData.emailaddress1;
          _customer.name = customerData.name;
          _customer.phone = customerData.telephone1;
          _customer.phoneCountryCode = '+66';
          _customer.postcode = customerData.address1_postalcode;
          _customer.taxId = 'N/A';
          _customer.taxNumberType = 'OTHR';
          _customer.language = 'th';
          _customer.unstructure = true;
        } else if (responseContact.data.length) {
          const customerData = responseContact.data[0];

          _customer.addressLineOne = customerData.address1_composite;
          _customer.addressLineTwo = '';
          _customer.branchNumber = '';
          _customer.branchText = '';
          _customer.email = customerData.emailaddress1;
          _customer.name = customerData.fullname;
          _customer.phone = customerData.telephone1;
          _customer.phoneCountryCode = '+66';
          _customer.postcode = customerData.address1_postalcode;
          _customer.taxId = 'N/A';
          _customer.taxNumberType = 'OTHR';
          _customer.language = 'th';
          _customer.unstructure = true;
        }

        let _date = new Date(item.createdTime);

        let _dateBE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
        let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

        const itemData = responseInvoiceDetail.data;

        let _items = [];
        let _includeVat = false;

        for (let i = 0; i < itemData.length; i++) {
          let _percentVat = zeroVat;
          let _percentVatText = zeroVatText;
          if (itemData[i].tax) {
            _percentVat = percentVat;
            _percentVatText = percentVatText;
          }

          _items.push({
            number: i + 1 + '',
            description: itemData[i].productname,
            quantity: itemData[i].quantity,
            unitCode: '',
            unitName: '-',
            price: itemData[i].priceperunit,
            discount: itemData[i].volumediscountamount,
            total: (itemData[i].priceperunit - itemData[i].volumediscountamount) * itemData[i].quantity,
            percentVat: _percentVat,
            percentVatText: _percentVatText,
          });
        }

        if (item.items.length) {
          _items.push(item.items[0]);
        }

        let _vatPriceTotal = 0;
        let _zeroVatPriceTotal = 0;
        let _nonVatPriceTotal = 0;

        _items.forEach((item) => {
          if (item.percentVat === percentVat) {
            _vatPriceTotal = _vatPriceTotal + item.total;
          } else if (item.percentVat === zeroVat) {
            _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
          } else if (item.percentVat === nonVat) {
            _nonVatPriceTotal = _nonVatPriceTotal + item.total;
          }
        });

        history.push({
          pathname: documentPath,
          state: {
            number: item.number,
            reference: '',
            company: {
              ...company,
              includeVat: _includeVat,
            },
            customer: _customer,
            date: _date,
            dateBE: _dateBE,
            dateCE: _dateCE,
            items: _items,
            includeVat: _includeVat,
            discount: item.discount * 1,
            amountTotal: item.amountTotal * 1,
            vatTotal: item.vatTotal * 1,
            grandTotal: item.grandTotal * 1,
            nonVatPriceTotal: _nonVatPriceTotal,
            zeroVatPriceTotal: _zeroVatPriceTotal,
            vatPriceTotal: _vatPriceTotal,
            percentVat: percentVat,
            reIssue: false,
            backUrl: '/etax/sources/dynamics/crm',
            grandTotalTH: numberToTextTh(item.grandTotal * 1),
            grandTotalEN: numberToTextEn(item.grandTotal * 1),
          },
        });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    console.log('userEffect initial company call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  if (!_company.includeVat) {
                    _company.includeVat = true;
                  }
  
                  setCompany(_company);
                } else {
                  if (!response.data[0].includeVat) {
                    response.data[0].includeVat = true;
                  }
  
                  setCompany(response.data[0]);
                }
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getNewDocuments) {
      if (search) {
        const searchInvoices = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
              await axios
                .get(API_URL + '/sources/dynamics/crm/invoices', {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                  },
                  params: {
                    search: search,
                  },
                })
                .then(async (response) => {
                  if (response.data.length) {
                    const _number = response.data[0].invoicenumber;

                    await axios
                      .get(API_URL + `/documents?number=${_number}`, {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                          'Content-Type': 'application/json',
                        },
                      })
                      .then((responseAllDocs) => {
                        let invoice_list = response.data;

                        let total_items = response.data.length;

                        if (primarySort === 'createdTime') {
                          if (isSortedDescendingCreatedTime) {
                            invoice_list.sort(function (a, b) {
                              return new Date(a.createdon) - new Date(b.createdon);
                            });
                          } else {
                            invoice_list.sort(function (a, b) {
                              return new Date(b.createdon) - new Date(a.createdon);
                            });
                          }
                        } else if (primarySort === 'number') {
                          if (isSortedDescendingNumber) {
                            invoice_list.sort((a, b) => {
                              if (b.invoicenumber < a.invoicenumber) {
                                return -1;
                              }
                              if (b.invoicenumber > a.invoicenumber) {
                                return 1;
                              }
                              return 0;
                            });
                          } else {
                            invoice_list.sort((a, b) => {
                              if (a.invoicenumber < b.invoicenumber) {
                                return -1;
                              }
                              if (a.invoicenumber > b.invoicenumber) {
                                return 1;
                              }
                              return 0;
                            });
                          }
                        } else if (primarySort === 'customerName') {
                          if (isSortedDescendingCustomerName) {
                            invoice_list.sort((a, b) => {
                              if (b.name < a.name) {
                                return -1;
                              }
                              if (b.name > a.name) {
                                return 1;
                              }
                              return 0;
                            });
                          } else {
                            invoice_list.sort((a, b) => {
                              if (a.name < b.name) {
                                return -1;
                              }
                              if (a.name > b.name) {
                                return 1;
                              }
                              return 0;
                            });
                          }
                        } else if (primarySort === 'vatTotal') {
                          if (isSortedDescendingVatTotal) {
                            invoice_list.sort(function (a, b) {
                              return b.totaltax - a.totaltax;
                            });
                          } else {
                            invoice_list.sort(function (a, b) {
                              return a.totaltax - b.totaltax;
                            });
                          }
                        } else if (primarySort === 'grandTotal') {
                          if (isSortedDescendingGrandTotal) {
                            invoice_list.sort(function (a, b) {
                              return b.totalamount - a.totalamount;
                            });
                          } else {
                            invoice_list.sort(function (a, b) {
                              return a.totalamount - b.totalamount;
                            });
                          }
                        }

                        let _pageDropdown = [];

                        for (let i = 1; i <= Math.ceil(total_items / pageSize); i++) {
                          _pageDropdown.push({
                            key: i,
                            text: 'หน้า ' + i,
                            iconProps: { iconName: 'Page' },
                            onClick: () => {
                              console.log('Selected page: ' + i);
                              setCurrentPage(i);
                            },
                          });
                        }

                        setPageDropdown(_pageDropdown);

                        if (currentPage > Math.ceil(total_items / pageSize)) {
                          setCurrentPage(1);
                        }

                        setPageData(paginate(total_items, 1, pageSize, 10));
                        setDocuments(invoice_list);

                        const _docs = [];

                        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, invoice_list.length); i++) {
                          const _items = [];

                          if (invoice_list[i].freightamount > 0) {
                            _items.push({
                              number: i + 1 + '',
                              description: 'ค่าขนส่ง',
                              quantity: 1,
                              unitCode: '',
                              unitName: '-',
                              price: invoice_list[i].freightamount,
                              discount: 0,
                              total: (invoice_list[i].freightamount - 0) * 1,
                              percentVat: zeroVat,
                              percentVatText: zeroVatText,
                            });
                          }

                          _docs.push({
                            key: invoice_list[i].invoiceid,
                            createdTime: invoice_list[i].createdon,
                            number: invoice_list[i].invoicenumber,
                            customerId: invoice_list[i]._customerid_value,
                            customerName: invoice_list[i].name,
                            amountTotal: invoice_list[i].totalamount - invoice_list[i].totaltax,
                            vatTotal: invoice_list[i].totaltax,
                            grandTotal: invoice_list[i].totalamount,
                            discount: invoice_list[i].totaldiscountamount,
                            items: _items,
                            etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === invoice_list[i].invoicenumber),
                          });
                        }

                        setItems(_docs);

                        setLoadDataComplete(true);
                        setGetNewDocuments(false);
                        setIsCreateDocumentComplete(false);
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else {
                    setItems([]);

                    setLoadDataComplete(true);
                    setGetNewDocuments(false);
                    setIsCreateDocumentComplete(false);
                  }
                });
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
            //   instance.acquireTokenRedirect(silentRequest);
            });
        };

        searchInvoices();
      } else {
        const listInvoices = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
              const [response, responseAllDocs] = await Promise.all([
                axios
                  .get(API_URL + '/sources/dynamics/crm/invoices', {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                    },
                    params: {
                      status: invoiceType.key,
                      from: from,
                      to: to,
                    },
                  })
                  .catch((error) => console.log(error)),

                axios
                  .get(API_URL + '/etax/documents/ALL/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                    },
                  })
                  .catch((error) => console.log(error)),
              ]);

              setAllDocs(responseAllDocs.data);

              console.log('response', response);

              let invoice_list = response.data;

              let total_items = response.data.length;

              if (primarySort === 'createdTime') {
                if (isSortedDescendingCreatedTime) {
                  invoice_list.sort(function (a, b) {
                    return new Date(a.createdon) - new Date(b.createdon);
                  });
                } else {
                  invoice_list.sort(function (a, b) {
                    return new Date(b.createdon) - new Date(a.createdon);
                  });
                }
              } else if (primarySort === 'number') {
                if (isSortedDescendingNumber) {
                  invoice_list.sort((a, b) => {
                    if (b.invoicenumber < a.invoicenumber) {
                      return -1;
                    }
                    if (b.invoicenumber > a.invoicenumber) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  invoice_list.sort((a, b) => {
                    if (a.invoicenumber < b.invoicenumber) {
                      return -1;
                    }
                    if (a.invoicenumber > b.invoicenumber) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'customerName') {
                if (isSortedDescendingCustomerName) {
                  invoice_list.sort((a, b) => {
                    if (b.name < a.name) {
                      return -1;
                    }
                    if (b.name > a.name) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  invoice_list.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'vatTotal') {
                if (isSortedDescendingVatTotal) {
                  invoice_list.sort(function (a, b) {
                    return b.totaltax - a.totaltax;
                  });
                } else {
                  invoice_list.sort(function (a, b) {
                    return a.totaltax - b.totaltax;
                  });
                }
              } else if (primarySort === 'grandTotal') {
                if (isSortedDescendingGrandTotal) {
                  invoice_list.sort(function (a, b) {
                    return b.totalamount - a.totalamount;
                  });
                } else {
                  invoice_list.sort(function (a, b) {
                    return a.totalamount - b.totalamount;
                  });
                }
              }

              let _pageDropdown = [];

              for (let i = 1; i <= Math.ceil(total_items / pageSize); i++) {
                _pageDropdown.push({
                  key: i,
                  text: 'หน้า ' + i,
                  iconProps: { iconName: 'Page' },
                  onClick: () => {
                    console.log('Selected page: ' + i);
                    setCurrentPage(i);
                  },
                });
              }

              setPageDropdown(_pageDropdown);

              if (currentPage > Math.ceil(total_items / pageSize)) {
                setCurrentPage(1);
              }

              setPageData(paginate(total_items, 1, pageSize, 10));
              setDocuments(invoice_list);

              const _docs = [];

              for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, invoice_list.length); i++) {
                const _items = [];

                if (invoice_list[i].freightamount > 0) {
                  _items.push({
                    number: i + 1 + '',
                    description: 'ค่าขนส่ง',
                    quantity: 1,
                    unitCode: '',
                    unitName: '-',
                    price: invoice_list[i].freightamount,
                    discount: 0,
                    total: (invoice_list[i].freightamount - 0) * 1,
                    percentVat: zeroVat,
                    percentVatText: zeroVatText,
                  });
                }

                _docs.push({
                  key: invoice_list[i].invoiceid,
                  createdTime: invoice_list[i].createdon,
                  number: invoice_list[i].invoicenumber,
                  customerId: invoice_list[i]._customerid_value,
                  customerName: invoice_list[i].name,
                  amountTotal: invoice_list[i].totalamount - invoice_list[i].totaltax,
                  vatTotal: invoice_list[i].totaltax,
                  grandTotal: invoice_list[i].totalamount,
                  discount: invoice_list[i].totaldiscountamount,
                  items: _items,
                  etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === invoice_list[i].invoicenumber),
                });
              }

              console.log(responseAllDocs.data);

              setItems(_docs);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
            //   instance.acquireTokenRedirect(silentRequest);
            });
        };

        listInvoices();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    if (documents.length > 0) {
      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
        const _items = [];

        if (documents[i].freightamount > 0) {
          _items.push({
            number: i + 1 + '',
            description: 'ค่าขนส่ง',
            quantity: 1,
            unitCode: '',
            unitName: '-',
            price: documents[i].freightamount,
            discount: 0,
            total: (documents[i].freightamount - 0) * 1,
            percentVat: zeroVat,
            percentVatText: zeroVatText,
          });
        }

        _docs.push({
          key: documents[i].invoiceid,
          createdTime: documents[i].createdon,
          number: documents[i].invoicenumber,
          customerId: documents[i]._customerid_value,
          customerName: documents[i].name,
          amountTotal: documents[i].totalamount - documents[i].totaltax,
          vatTotal: documents[i].totaltax,
          grandTotal: documents[i].totalamount,
          discount: documents[i].totaldiscountamount,
          items: _items,
          etaxCreated: allDocs.some((_allDocs) => _allDocs.data.number === documents[i].invoicenumber),
        });
      }

      setItems(_docs);
    }

    sessionStorage.setItem('ms-sales-currentPage', currentPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    console.log('isCreateDocumentComplete useEffect: ', isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
      let _items = selection.getItems();
      console.log('_items: ', _items);

      let _selectedItems = selection.getSelection();

      if (isCreateDocumentComplete) {
        setIsCreateDocumentComplete(false);

        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status != '') {
            _items[i].status = '';
          }
        }
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status != '') {
            _items[i].status = '';
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {
          console.log('select item key: ', _selectedItems[i].key);

          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === _selectedItems[i].key) {
              console.log('set pending item key: ', _items[j].key);
              _items[j].status = 'pending';
              console.log('_items: ', _items);
            }
          }
        }
      }

      setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const columns = [
    {
      key: 'column1',
      name: t('dynamicCrm.createdTime'),
      fieldName: 'createdTime',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      isSorted: primarySort === 'createdTime' ? true : false,
      isSortedDescending: isSortedDescendingCreatedTime,
      isSortedAscending: !isSortedDescendingCreatedTime,
      onColumnClick: () => {
        console.log('Sort document createTime!');
        setPrimarySort('createdTime');
        setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

        sessionStorage.setItem('ms-sales-primarySort', 'createdTime');
        sessionStorage.setItem('ms-sales-sort-createdTime', !isSortedDescendingCreatedTime === false ? 'false' : 'true');

        sessionStorage.removeItem('ms-sales-sort-number');
        sessionStorage.removeItem('ms-sales-sort-customerName');
        sessionStorage.removeItem('ms-sales-sort-amountTotal');
        sessionStorage.removeItem('ms-sales-sort-vatTotal');
        sessionStorage.removeItem('ms-sales-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'number',
      onRender: (item) => {
        return <span>{onFormatDate(new Date(item.createdTime), 'BE')}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('dynamicCrm.number'),
      fieldName: 'number',
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        sessionStorage.setItem('ms-sales-primarySort', 'number');
        sessionStorage.setItem('ms-sales-sort-number', !isSortedDescendingNumber === false ? 'false' : 'true');

        sessionStorage.removeItem('ms-sales-sort-createdTime');
        sessionStorage.removeItem('ms-sales-sort-documentDate');
        sessionStorage.removeItem('ms-sales-sort-customerName');
        sessionStorage.removeItem('ms-sales-sort-amountTotal');
        sessionStorage.removeItem('ms-sales-sort-vatTotal');
        sessionStorage.removeItem('ms-sales-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.number}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('dynamicCrm.customerName'),
      fieldName: 'customerName',
      minWidth: 80,
      maxWidth: 300,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort document customerName!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        sessionStorage.setItem('ms-sales-primarySort', 'customerName');
        sessionStorage.setItem('ms-sales-sort-customerName', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.removeItem('ms-sales-sort-createdTime');
        sessionStorage.removeItem('ms-sales-sort-documentDate');
        sessionStorage.removeItem('ms-sales-sort-number');
        sessionStorage.removeItem('ms-sales-sort-amountTotal');
        sessionStorage.removeItem('ms-sales-sort-vatTotal');
        sessionStorage.removeItem('ms-sales-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('dynamicCrm.amountTotal'),
      fieldName: 'amountTotal',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'amountTotal' ? true : false,
      isSortedDescending: isSortedDescendingAmountTotal,
      isSortedAscending: !isSortedDescendingAmountTotal,
      onColumnClick: () => {
        console.log('Sort document amountTotal!');
        setPrimarySort('amountTotal');
        setIsSortedDescendingAmountTotal(!isSortedDescendingAmountTotal);

        sessionStorage.setItem('ms-sales-primarySort', 'amountTotal');
        sessionStorage.setItem('ms-sales-sort-amountTotal', !isSortedDescendingAmountTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('ms-sales-sort-createdTime');
        sessionStorage.removeItem('ms-sales-sort-number');
        sessionStorage.removeItem('ms-sales-sort-customerName');
        sessionStorage.removeItem('ms-sales-sort-documentDate');
        sessionStorage.removeItem('ms-sales-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.amountTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('dynamicCrm.vatTotal'),
      fieldName: 'vatTotal',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'vatTotal' ? true : false,
      isSortedDescending: isSortedDescendingVatTotal,
      isSortedAscending: !isSortedDescendingVatTotal,
      onColumnClick: () => {
        console.log('Sort document vatTotal!');
        setPrimarySort('vatTotal');
        setIsSortedDescendingVatTotal(!isSortedDescendingVatTotal);

        sessionStorage.setItem('ms-sales-primarySort', 'vatTotal');
        sessionStorage.setItem('ms-sales-sort-vatTotal', !isSortedDescendingVatTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('ms-sales-sort-createdTime');
        sessionStorage.removeItem('ms-sales-sort-number');
        sessionStorage.removeItem('ms-sales-sort-customerName');
        sessionStorage.removeItem('ms-sales-sort-documentDate');
        sessionStorage.removeItem('ms-sales-sort-amountTotal');
        sessionStorage.removeItem('ms-sales-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.vatTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: t('dynamicCrm.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'grandTotal' ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        console.log('Sort document grandTotal!');
        setPrimarySort('grandTotal');
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        sessionStorage.setItem('ms-sales-primarySort', 'grandTotal');
        sessionStorage.setItem('ms-sales-sort-grandTotal', !isSortedDescendingGrandTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('ms-sales-sort-createdTime');
        sessionStorage.removeItem('ms-sales-sort-customerName');
        sessionStorage.removeItem('ms-sales-sort-number');
        sessionStorage.removeItem('ms-sales-sort-amountTotal');
        sessionStorage.removeItem('ms-sales-sort-vatTotal');
        sessionStorage.removeItem('ms-sales-sort-documentDate');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: 'Leceipt',
      fieldName: 'etaxCreated',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        if (item.etaxCreated) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Icon iconName="SkypeCircleCheck" style={{ fontSize: '20px', color: 'green' }} />
            </div>
          );
        }
      },
    },
    {
      key: 'column8',
      name: '',
      fieldName: 'command',
      minWidth: 120,
      maxWidth: 120,
      isResizable: false,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('dynamicCrm.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('dynamicCrm.processing')} labelPosition="right" style={{ marginLeft: '0px' }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('dynamicCrm.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('dynamicCrm.fail')}</span>;
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('dynamicCrm.create')}
                iconProps={{ iconName: 'Add', style: { color: '#106ebe' } }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('dynamicCrm.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('RECEIPT-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('dynamicCrm.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('RECEIPT-TAXINVOICE-ABB', item);
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('dynamicCrm.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('RECEIPT', item);
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('dynamicCrm.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('INVOICE-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('dynamicCrm.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('DELIVERYORDER-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('dynamicCrm.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('TAXINVOICE', item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        }
      },
    },
  ];

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + '/sources',
              {
                source: 'dynamics-crm',
                replace: '/disabled',
                value: true,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              }
            )
            .then((response) => {
              console.log(response);

              history.push({
                pathname: '/etax/sources/dynamics/crm/connect',
                state: {},
              });

              setIsLoading(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const createSelectedDocuments = async (documentType) => {
    let _selectionItems = selection.getItems();

    let postUrl = '';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf';
        break;
      case 'RECEIPT':
        postUrl = API_URL + '/etax/documents/receipts/pdf-xml';
        break;
      case 'INVOICE-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/deliveryorders-taxinvoices/pdf-xml';
        break;
      case 'TAXINVOICE':
        postUrl = API_URL + '/etax/documents/taxinvoices/pdf-xml';
        break;
      default:
        break;
    }

    if (postUrl) {
      await instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          for (let i = 0; i < selectedItems.length; i++) {
            console.log('create selected document: ', selectedItems[i]);

            for (let j = 0; j < _selectionItems.length; j++) {
              if (_selectionItems[j].key === selectedItems[i].key) {
                _selectionItems[j].status = 'processing';

                setItems([..._selectionItems]);

                const [responseAccount, responseContact, responseInvoiceDetail] = await Promise.all([
                  axios
                    .get(API_URL + '/sources/dynamics/crm/accounts', {
                      headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                      },
                      params: {
                        customerId: selectedItems[i].customerId,
                      },
                    })
                    .catch((error) => console.log(error)),

                  axios
                    .get(API_URL + '/sources/dynamics/crm/contacts', {
                      headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                      },
                      params: {
                        customerId: selectedItems[i].customerId,
                      },
                    })
                    .catch((error) => console.log(error)),

                  axios
                    .get(API_URL + '/sources/dynamics/crm/invoices/' + selectedItems[i].key, {
                      headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                      },
                    })
                    .catch((error) => console.log(error)),
                ]);

                let _customer = {};

                if (responseAccount.data.length) {
                  const customerData = responseAccount.data[0];

                  _customer.addressLineOne = customerData.address1_composite;
                  _customer.addressLineTwo = '';
                  _customer.branchNumber = '';
                  _customer.branchText = '';
                  _customer.email = customerData.emailaddress1;
                  _customer.name = customerData.name;
                  _customer.phone = customerData.telephone1;
                  _customer.phoneCountryCode = '+66';
                  _customer.postcode = customerData.address1_postalcode;
                  _customer.taxId = 'N/A';
                  _customer.taxNumberType = 'OTHR';
                  _customer.language = 'th';
                  _customer.unstructure = true;
                } else if (responseContact.data.length) {
                  const customerData = responseContact.data[0];

                  _customer.addressLineOne = customerData.address1_composite;
                  _customer.addressLineTwo = '';
                  _customer.branchNumber = '';
                  _customer.branchText = '';
                  _customer.email = customerData.emailaddress1;
                  _customer.name = customerData.fullname;
                  _customer.phone = customerData.telephone1;
                  _customer.phoneCountryCode = '+66';
                  _customer.postcode = customerData.address1_postalcode;
                  _customer.taxId = 'N/A';
                  _customer.taxNumberType = 'OTHR';
                  _customer.language = 'th';
                  _customer.unstructure = true;
                }

                let _date = new Date(selectedItems[i].createdTime);

                let _dateBE =
                  `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
                let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

                const itemData = responseInvoiceDetail.data;

                let _items = [];
                let _includeVat = false;

                for (let i = 0; i < itemData.length; i++) {
                  let _percentVat = zeroVat;
                  let _percentVatText = zeroVatText;
                  if (itemData[i].tax) {
                    _percentVat = percentVat;
                    _percentVatText = percentVatText;
                  }

                  _items.push({
                    number: i + 1 + '',
                    description: itemData[i].productname,
                    quantity: itemData[i].quantity,
                    unitCode: '',
                    unitName: '-',
                    price: itemData[i].priceperunit,
                    discount: itemData[i].volumediscountamount,
                    total: (itemData[i].priceperunit - itemData[i].volumediscountamount) * itemData[i].quantity,
                    percentVat: _percentVat,
                    percentVatText: _percentVatText,
                  });
                }

                if (selectedItems[i].items.length) {
                  _items.push(selectedItems[i].items[0]);
                }

                let _vatPriceTotal = 0;
                let _zeroVatPriceTotal = 0;
                let _nonVatPriceTotal = 0;

                _items.forEach((item) => {
                  if (item.percentVat === percentVat) {
                    _vatPriceTotal = _vatPriceTotal + item.total;
                  } else if (item.percentVat === zeroVat) {
                    _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                  } else if (item.percentVat === nonVat) {
                    _nonVatPriceTotal = _nonVatPriceTotal + item.total;
                  }
                });

                const promise_PDF_XML = await new Promise(async (resolve) => {
                  await axios
                    .post(
                      postUrl,
                      {
                        number: selectedItems[i].number,
                        reference: '',
                        company: {
                          ...company,
                          includeVat: _includeVat,
                        },
                        customer: _customer,
                        date: _date,
                        dateBE: _dateBE,
                        dateCE: _dateCE,
                        items: _items,
                        includeVat: _includeVat,
                        discount: selectedItems[i].discount * 1,
                        amountTotal: selectedItems[i].amountTotal * 1,
                        vatTotal: selectedItems[i].vatTotal * 1,
                        grandTotal: selectedItems[i].grandTotal * 1,
                        nonVatPriceTotal: _nonVatPriceTotal,
                        zeroVatPriceTotal: _zeroVatPriceTotal,
                        vatPriceTotal: _vatPriceTotal,
                        percentVat: percentVat,
                        reIssue: false,
                        grandTotalTH: numberToTextTh(selectedItems[i].grandTotal * 1),
                        grandTotalEN: numberToTextEn(selectedItems[i].grandTotal * 1),
                      },
                      {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                        },
                      }
                    )
                    .then(
                      async (response) => {
                        console.log('Create document response: ', response);

                        let count = 0;

                        let interval = setInterval(async () => {
                          await axios
                            .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                              headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                'Content-Type': 'application/json',
                              },
                            })
                            .then((jobsResponse) => {
                              count += 1;
                              console.log('Jobs response: ', j, jobsResponse.data.status, count);

                              if (jobsResponse.data.status === 'complete') {
                                _selectionItems[j].status = 'successful';
                                _selectionItems[j].etaxCreated = true;

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(true);
                              } else if (count === 300) {
                                _selectionItems[j].status = 'fail';

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(false);
                              }
                            });
                        }, 1000);
                      },
                      (error) => {
                        console.log('Error Create document response: ', error);
                        _selectionItems[j].status = 'fail';
                        setItems([..._selectionItems]);
                        resolve(false);
                      }
                    );
                }); //promise

                Promise.all([promise_PDF_XML]).then((value) => {
                  console.log(i, value);
                });
              } //if
            } //for j
          } //for i
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });

      setIsCreateDocumentComplete(true);
      setIsCreateDocument(false);
    }

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  return (
    <div style={homeStyles}>
      {!isLoading && (
        <div>
          <Stack>
            <center>
              <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('dynamicCrm.header')}</h2>
              {!loadDataComplete && (
                <div>
                  <Spinner
                    label={t('dynamicCrm.loading')}
                    labelPosition="right"
                    style={{
                      marginTop: '21px',
                      marginBottom: '2px',
                    }}
                  />
                  <br />
                </div>
              )}
            </center>
          </Stack>

          {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
            <Stack vertical tokens={{ childrenGap: '10px' }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <TextField
                  name="createdBy"
                  label={t('dynamicCrm.createdBy')}
                  description={t('dynamicCrm.createdByDesc')}
                  styles={{ root: { width: 200 } }}
                  onChange={(e) => {
                    setCreatedBy(e.target.value);
                  }}
                  value={createdBy}
                  disabled={isCreateDocument}
                />
              </Stack>
              <br />
            </Stack>
          )}

          {selectedItems.length > 0 && !isCreateDocument && (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
              <PrimaryButton
                text={t('dynamicCrm.createDocument')}
                iconProps={{ iconName: 'Add' }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('dynamicCrm.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT-TAXINVOICE');
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('dynamicCrm.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT-TAXINVOICE-ABB');
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('dynamicCrm.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('RECEIPT');
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('dynamicCrm.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('INVOICE-TAXINVOICE');
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('dynamicCrm.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('DELIVERYORDER-TAXINVOICE');
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('dynamicCrm.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments('TAXINVOICE');
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
              <DefaultButton
                text={t('dynamicCrm.cancel')}
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  let _items = selection.getItems();
                  let _selectedItems = selection.getSelection();
                  console.log('selection: ', selection.getSelection());

                  for (let i = 0; i < _selectedItems.length; i++) {
                    console.log('select item key: ', _selectedItems[i].key);
                    _selectedItems[i].status = '';

                    for (let j = 0; j < _items.length; j++) {
                      if (_items[j].key === _selectedItems[i].key) {
                        console.log('item key: ', _items[j].key);
                        _items[j].status = '';
                        _selectedItems[i].status = '';
                      }
                    }
                  }

                  selection.setAllSelected(false);

                  setItems(_items);
                  console.log('selection: ', selection.getSelection());
                }}
              />
              <br />
            </Stack>
          )}

          {isCreateDocument && (
            <div>
              <Spinner label={t('dynamicCrm.signProcessing')} size={SpinnerSize.large} />
            </div>
          )}

          {isCreateDocumentComplete && (
            <center>
              <PrimaryButton
                text={t('dynamicCrm.createdDocuments')}
                iconProps={{ iconName: 'Documentation' }}
                onClick={async () => {
                  console.log(selectedItems);

                  history.push({
                    pathname: '/etax/documents/all',
                    state: {},
                  });
                }}
              />
            </center>
          )}

          <br />

          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                minWidth: '800px',
                maxWidth: '1350px',
              }}
            >
              <Stack 
                horizontal 
                styles={{
                  root: {
                    backgroundColor: 'white',
                  },
                }} 
                horizontalAlign="space-between"
              >
                <Stack
                  styles={{
                    root: {
                      width: 'calc(100vw - 700px)',
                      minWidth: '700px',
                      maxWidth: '900px',
                    },
                  }}
                >
                  <CommandBar items={commandBarItems} />
                </Stack>
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                    },
                  }}
                  tokens={{ childrenGap: '10px' }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        width: 270,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete}
                    placeholder={t('dynamicCrm.searchBoxPlaceholder')}
                    onChange={(e) => {
                      setSearch(e ? e.target.value : '');
                    }}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setLoadDataComplete(false);
                        setDocuments([]);
                        setItems([]);
                        setGetNewDocuments(true);

                        setCurrentPage(1);
                      }
                    }}
                    onClear={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    onSearch={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    value={search}
                  />
                  <PrimaryButton
                    disabled={!loadDataComplete || !search}
                    styles={{
                      root: {
                        marginTop: 6,
                        marginRight: 18,
                        width: 90,
                      },
                    }}
                    text={t('dynamicCrm.search')}
                    onClick={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                  />
                </Stack>
              </Stack>
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selection={selection}
                selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
              />
            </Stack>
          </Stack>
          <br />
          <center>
            {!(currentPage === 1 && items.length < pageSize) && !(!loadDataComplete || isCreateDocument) && (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{
                  iconName: 'DoubleChevronLeft',
                }}
                previousPageIconProps={{
                  iconName: 'ChevronLeft',
                }}
                nextPageIconProps={{
                  iconName: 'ChevronRight',
                }}
                lastPageIconProps={{
                  iconName: 'DoubleChevronRight',
                }}
              />
            )}

            <br />
            <br />
          </center>
        </div>
      )}

      {isLoading && (
        <div>
          <br />
          <br />
          <br />
          <br />
          <Stack>
            <Stack horizontal horizontalAlign="center">
              <div>
                <Spinner label={t('dynamicCrm.loading')} labelPosition="down" styles={spinnerStyles} />
              </div>
            </Stack>
          </Stack>
          <br />
        </div>
      )}
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default DynamicsCrm;
