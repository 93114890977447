import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, Icon, PrimaryButton, FocusTrapZone, Spinner, TextField } from '@fluentui/react';

// const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
const API_URL = process.env.REACT_APP_API_URL;

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const LineShoppingConnect = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const history = useHistory();

  const { t } = useTranslation();

  const [apiKey, setApiKey] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const [status, setStatus] = useState('');

  const companyId = sessionStorage.getItem('companyId') || '';

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    /* 
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        history.push({
          pathname: '/etax/sources/line-shopping',
          state: {
            apiKey: apiKey,
          },
        });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
 */

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            API_URL + '/sources/line-shopping',
            {
              apiKey: apiKey,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                cid: companyId
            },
            }
          )
          .then(
            (response) => {
              console.log(response);

              if (response.data) {
                if (response.data.canLogin) {
                  history.push({
                    pathname: '/etax/sources/line-shopping',
                    state: {},
                  });
                } else {
                  setStatus('fail');
                }
              }

              setApiKey('');
              setIsLoading(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    console.log('userEffect retrieve users data!');

    setIsLoading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/sources/line-shopping', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
          })
          .then(
            (response) => {
              console.log('Line Shopping sources: ', response);

              if (response.data) {
                if (!response.data.disabled && response.data.canLogin) {
                  history.push({
                    pathname: '/etax/sources/line-shopping',
                    state: {},
                  });
                }
              }

              setIsLoading(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {!isLoading && (
        <Stack>
          <br />

          <h1 style={{ marginLeft: '0px', marginTop: '27px' }}>
            <Icon iconName="CloudImportExport" style={{ color: '#106ebe' }} /> {t('lineShoppingConnect.header')}
          </h1>
          <h3 style={{ marginTop: '0px', marginBottom: '10px' }}>{t('lineShoppingConnect.subHeader1')}</h3>

          <br />
          <br />
        </Stack>
      )}
      {status === '' && !isLoading && (
        <FocusTrapZone disabled={true}>
          <Stack horizontalAlign="center">
            <Stack style={{ border: '1px solid rgb(134, 134, 134)', width: '350px', backgroundColor: '#FFFFFF' }}>
              <br />
              <br />

              <Stack horizontal horizontalAlign="center">
                <img src="/images/line-shopping-logo.png" width="255px" alt="line shopping logo" />
              </Stack>
              {/* <br /> */}
              <br />
              <form onSubmit={handleSubmit}>
                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="API Key"
                    required
                    onChange={(e) => {
                      setApiKey(e.target.value);
                    }}
                    value={apiKey}
                    style={{ width: '250px' }}
                  />
                </Stack>

                {/* <br /> */}
                <br />
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
                  <PrimaryButton style={{ fontSize: '20px', height: '50px', width: '255px' }} type="submit">
                    {t('lineShoppingConnect.connect')}
                  </PrimaryButton>
                </Stack>
              </form>
              <br />
              <br />
            </Stack>
          </Stack>
        </FocusTrapZone>
      )}
      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              {apiKey !== '' ? (
                <Spinner label={t('lineShoppingConnect.processing')} labelPosition="down" styles={spinnerStyles} />
              ) : (
                <Spinner label={t('lineShoppingConnect.loading')} labelPosition="down" styles={spinnerStyles} />
              )}
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'success' && (
        <Stack horizontal horizontalAlign="center">
          <Stack style={{ border: '1px solid rgb(134, 134, 134)', width: '300px', backgroundColor: '#FFFFFF' }}>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#0078D4' }}>{t('lineShoppingConnect.successMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/success03.gif" alt="success03gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  history.push({
                    pathname: '/etax/sources/line-shopping',
                    state: {},
                  });
                }}
              >
                {t('lineShoppingConnect.successButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'fail' && (
        <Stack horizontal horizontalAlign="center">
          <Stack style={{ border: '1px solid rgb(134, 134, 134)', width: '300px', backgroundColor: '#FFFFFF' }}>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#F25139' }}>{t('lineShoppingConnect.failMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/fail01.gif" alt="fail01gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  setStatus('');
                }}
              >
                {t('lineShoppingConnect.failButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      <br />
      <br />
      {status === '' && !isLoading && (
        <center>
          <br />
          <br />
          <span style={{ color: 'gray' }}>{t('lineShoppingConnect.footer1')}</span>
          <br />
          <br />
          <span style={{ color: 'gray', fontWeight: 'bold' }}>{t('lineShoppingConnect.footer2')}</span>
        </center>
      )}
    </Stack>
  );
};

export default LineShoppingConnect;
