import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as React from 'react';
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';

import { useTranslation } from 'react-i18next';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../../authConfig';

import { PrimaryButton, DefaultButton, Stack, Selection, Spinner, CheckboxVisibility, SpinnerSize } from '@fluentui/react';
import PdfAllPages from '../../../etax/documents/pdf/view/PdfAllPages';
import { numberToTextTh } from '../../../../helpers/BahtWord';

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function WithholdingTaxUploadList(props) {
  const history = useHistory();

  const { t } = useTranslation();

  const location = useLocation();

  const [items, setItems] = useState(location.state.items);

  const [isCompactMode] = React.useState(false);

  const [inComplete, setInComplete] = React.useState(false);

  const [url, setUrl] = useState('');
  const [isloadingPdf, setIsloadingPdf] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);

        if (_selectedItems[0] !== undefined) {
          setIsSelectDocuments(true);
          setIsCreateDocumentComplete(false);
        } else {
          setIsSelectDocuments(false);
        }
      },
    })
  );

  const [isSelectDocuments, setIsSelectDocuments] = useState(false);
  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const classNames = mergeStyleSets({
    alignRight: {
      textAlign: 'right',
    },
    top: {
      position: 'absolute',
      left: '0px',
      top: '85px',
      z_index: -1,
    },
  });

  const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4,
    },
  };

  const viewPDF = (item) => {
    setIsloadingPdf(true);

    let _addressLineOne = '';
    if (item['ชื่ออาคาร']) _addressLineOne = _addressLineOne + item['ชื่ออาคาร'] + ' ';
    if (item['ห้องเลขที่']) _addressLineOne = _addressLineOne + item['ห้องเลขที่'] + ' ';
    if (item['ชั้นที่']) _addressLineOne = _addressLineOne + item['ชั้นที่'] + ' ';
    if (item['หมู่บ้าน']) _addressLineOne = _addressLineOne + item['หมู่บ้าน'] + ' ';
    if (item['เลขที่']) _addressLineOne = _addressLineOne + item['เลขที่'] + ' ';

    if (item['หมู่ที่']) _addressLineOne = _addressLineOne + 'หมู่ ' + item['หมู่ที่'] + ' ';
    if (item['ตรอก/ซอย']) _addressLineOne = _addressLineOne + 'ซอย ' + item['ตรอก/ซอย'] + ' ';
    if (item['แยก']) _addressLineOne = _addressLineOne + 'แยก ' + item['แยก'] + ' ';
    if (item['ถนน']) _addressLineOne = _addressLineOne + 'ถนน ' + item['ถนน'] + ' ';

    let _provincePrefix = 'จ.';
    let _districtPrefix = 'อ.';
    let _subDistrictPrefix = 'ต.';

    if (item['จังหวัด']?.includes('กรุงเทพ')) {
      _provincePrefix = '';
      _districtPrefix = 'เขต ';
      _subDistrictPrefix = 'แขวง ';
    }

    if (item['ตำบล/แขวง']) _addressLineOne = _addressLineOne + _subDistrictPrefix + item['ตำบล/แขวง'] + ' ';
    if (item['อำเภอ/เขต']) _addressLineOne = _addressLineOne + _districtPrefix + item['อำเภอ/เขต'] + ' ';
    if (item['จังหวัด']) _addressLineOne = _addressLineOne + _provincePrefix + item['จังหวัด'] + ' ';
    if (item['รหัสไปรษณีย์']) _addressLineOne = _addressLineOne + item['รหัสไปรษณีย์'];

    let _customer = {
      name: '',
      taxId: item['เลขประจำตัวผู้เสียภาษีอากร'],
      taxIdForm: {
        zero: item['เลขประจำตัวผู้เสียภาษีอากร'][0],
        one: item['เลขประจำตัวผู้เสียภาษีอากร'][1],
        two: item['เลขประจำตัวผู้เสียภาษีอากร'][2],
        three: item['เลขประจำตัวผู้เสียภาษีอากร'][3],
        four: item['เลขประจำตัวผู้เสียภาษีอากร'][4],
        five: item['เลขประจำตัวผู้เสียภาษีอากร'][5],
        six: item['เลขประจำตัวผู้เสียภาษีอากร'][6],
        seven: item['เลขประจำตัวผู้เสียภาษีอากร'][7],
        eight: item['เลขประจำตัวผู้เสียภาษีอากร'][8],
        nine: item['เลขประจำตัวผู้เสียภาษีอากร'][9],
        ten: item['เลขประจำตัวผู้เสียภาษีอากร'][10],
        eleven: item['เลขประจำตัวผู้เสียภาษีอากร'][11],
        twelve: item['เลขประจำตัวผู้เสียภาษีอากร'][12],
      },
      taxId10Form: '',
      addressLineOne: _addressLineOne || '-',
    };

    let _formType_1 = false;
    let _formType_2 = false;
    let _formType_3 = false;
    let _formType_4 = false;
    let _formType_5 = false;
    let _formType_6 = false;
    let _formType_7 = false;

    if (item.pnd === 'PND53') _formType_7 = true;
    if (item.pnd === 'PND3') _formType_4 = true;
    if (item.pnd === 'PND2') _formType_3 = true;

    let _paymentType_1 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_2 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_3 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_A = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_1_1 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_1_2 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_1_3 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_1_4 = {
      date: '',
      dateBE: '',
      dateCE: '',
      percentVat: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_2_1 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_2_2 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_2_3 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_2_4 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_2_5 = {
      date: '',
      dateBE: '',
      dateCE: '',
      remark: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_5 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_6 = {
      date: '',
      dateBE: '',
      dateCE: '',
      remark: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _total = 0;

    if (item.pnd === 'PND53') {
      if (item['คำนำหน้าชื่อ']) _customer.name = _customer.name + item['คำนำหน้าชื่อ'];
      if (item['ชื่อ']) _customer.name = _customer.name + ' ' + item['ชื่อ'];

      item.incomes.forEach((income) => {
        _total = _total + Number(income['จำนวนเงินที่จ่าย']);

        if (
          income['ประเภทเงินได้'].includes('เงินเดือน') ||
          income['ประเภทเงินได้'].includes('ค่าจ้าง') ||
          income['ประเภทเงินได้'].includes('เบี้ยเลี้ยง') ||
          income['ประเภทเงินได้'].includes('โบนัส')
        ) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_1.date = _date;
          _paymentType_1.dateBE = _dateBE;
          _paymentType_1.dateCE = _dateCE;
          _paymentType_1.amountTotal = Number(_paymentType_1.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_1.vatTotal = Number(_paymentType_1.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (income['ประเภทเงินได้'].includes('ค่าธรรมเนียม') || income['ประเภทเงินได้'].includes('ค่านายหน้า')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_2.date = _date;
          _paymentType_2.dateBE = _dateBE;
          _paymentType_2.dateCE = _dateCE;
          _paymentType_2.amountTotal = Number(_paymentType_2.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_2.vatTotal = Number(_paymentType_2.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (income['ประเภทเงินได้'].includes('ค่าแห่งลิขสิทธิ์')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_3.date = _date;
          _paymentType_3.dateBE = _dateBE;
          _paymentType_3.dateCE = _dateCE;
          _paymentType_3.amountTotal = Number(_paymentType_3.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_3.vatTotal = Number(_paymentType_3.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (income['ประเภทเงินได้'].includes('ดอกเบี้ย')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }
          _paymentType_4_A.date = _date;
          _paymentType_4_A.dateBE = _dateBE;
          _paymentType_4_A.dateCE = _dateCE;
          _paymentType_4_A.amountTotal = Number(_paymentType_4_A.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_4_A.vatTotal = Number(_paymentType_4_A.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (income['ประเภทเงินได้'].includes('เงินปันผล') || income['ประเภทเงินได้'].includes('เงินส่วนแบ่งกำไร')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          if (Number(income['อัตราภาษี']) === 30) {
            _paymentType_4_B_1_1.date = _date;
            _paymentType_4_B_1_1.dateBE = _dateBE;
            _paymentType_4_B_1_1.dateCE = _dateCE;
            _paymentType_4_B_1_1.amountTotal = Number(_paymentType_4_B_1_1.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
            _paymentType_4_B_1_1.vatTotal = Number(_paymentType_4_B_1_1.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
          } else if (Number(income['อัตราภาษี']) === 25) {
            _paymentType_4_B_1_2.date = _date;
            _paymentType_4_B_1_2.dateBE = _dateBE;
            _paymentType_4_B_1_2.dateCE = _dateCE;
            _paymentType_4_B_1_2.amountTotal = Number(_paymentType_4_B_1_2.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
            _paymentType_4_B_1_2.vatTotal = Number(_paymentType_4_B_1_2.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
          } else if (Number(income['อัตราภาษี']) === 20) {
            _paymentType_4_B_1_3.date = _date;
            _paymentType_4_B_1_3.dateBE = _dateBE;
            _paymentType_4_B_1_3.dateCE = _dateCE;
            _paymentType_4_B_1_3.amountTotal = Number(_paymentType_4_B_1_3.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
            _paymentType_4_B_1_3.vatTotal = Number(_paymentType_4_B_1_3.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
          } else {
            _paymentType_4_B_1_4.date = _date;
            _paymentType_4_B_1_4.dateBE = _dateBE;
            _paymentType_4_B_1_4.dateCE = _dateCE;
            _paymentType_4_B_1_4.percentVat = Number(income['อัตราภาษี']);
            _paymentType_4_B_1_4.amountTotal = Number(_paymentType_4_B_1_4.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
            _paymentType_4_B_1_4.vatTotal = Number(_paymentType_4_B_1_4.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
          }
        } else if (
          income['ประเภทเงินได้'].includes('รางวัล') ||
          income['ประเภทเงินได้'].includes('ส่วนลด') ||
          income['ประเภทเงินได้'].includes('การแข่งขัน') ||
          income['ประเภทเงินได้'].includes('การชิงโชค') ||
          income['ประเภทเงินได้'].includes('ค่าแสดง') ||
          income['ประเภทเงินได้'].includes('ค่าจ้าง') ||
          income['ประเภทเงินได้'].includes('ค่าโฆษณา') ||
          income['ประเภทเงินได้'].includes('ค่าเช่า') ||
          income['ประเภทเงินได้'].includes('ค่าขนส่ง') ||
          income['ประเภทเงินได้'].includes('ค่าบริการ') ||
          income['ประเภทเงินได้'].includes('ค่าเบี้ยประกันวินาศภัย')
        ) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_5.date = _date;
          _paymentType_5.dateBE = _dateBE;
          _paymentType_5.dateCE = _dateCE;
          _paymentType_5.amountTotal = Number(_paymentType_5.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_5.vatTotal = Number(_paymentType_5.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_6.date = _date;
          _paymentType_6.dateBE = _dateBE;
          _paymentType_6.dateCE = _dateCE;
          _paymentType_6.remark = income['ประเภทเงินได้'];
          _paymentType_6.amountTotal = Number(_paymentType_6.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_6.vatTotal = Number(_paymentType_6.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        }
      });
    } else if (item.pnd === 'PND3') {
      if (item['คำนำหน้าชื่อ']) _customer.name = _customer.name + item['คำนำหน้าชื่อ'];
      if (item['ชื่อ']) _customer.name = _customer.name + ' ' + item['ชื่อ'];
      if (item['ชื่อกลาง']) _customer.name = _customer.name + ' ' + item['ชื่อกลาง'];
      if (item['ชื่อสกุล']) _customer.name = _customer.name + ' ' + item['ชื่อสกุล'];

      item.incomes.forEach((income) => {
        _total = _total + Number(income['จำนวนเงินที่จ่าย']);

        if (
          income['ประเภทเงินได้'].includes('เงินเดือน') ||
          income['ประเภทเงินได้'].includes('ค่าจ้าง') ||
          income['ประเภทเงินได้'].includes('เบี้ยเลี้ยง') ||
          income['ประเภทเงินได้'].includes('โบนัส')
        ) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_1.date = _date;
          _paymentType_1.dateBE = _dateBE;
          _paymentType_1.dateCE = _dateCE;
          _paymentType_1.amountTotal = Number(_paymentType_1.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_1.vatTotal = Number(_paymentType_1.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (income['ประเภทเงินได้'].includes('ค่าธรรมเนียม') || income['ประเภทเงินได้'].includes('ค่านายหน้า')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_2.date = _date;
          _paymentType_2.dateBE = _dateBE;
          _paymentType_2.dateCE = _dateCE;
          _paymentType_2.amountTotal = Number(_paymentType_2.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_2.vatTotal = Number(_paymentType_2.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (income['ประเภทเงินได้'].includes('ค่าแห่งลิขสิทธิ์')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_3.date = _date;
          _paymentType_3.dateBE = _dateBE;
          _paymentType_3.dateCE = _dateCE;
          _paymentType_3.amountTotal = Number(_paymentType_3.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_3.vatTotal = Number(_paymentType_3.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (
          income['ประเภทเงินได้'].includes('รางวัล') ||
          income['ประเภทเงินได้'].includes('ส่วนลด') ||
          income['ประเภทเงินได้'].includes('การแข่งขัน') ||
          income['ประเภทเงินได้'].includes('การชิงโชค') ||
          income['ประเภทเงินได้'].includes('ค่าแสดง') ||
          income['ประเภทเงินได้'].includes('ค่าจ้าง') ||
          income['ประเภทเงินได้'].includes('ค่าโฆษณา') ||
          income['ประเภทเงินได้'].includes('ค่าเช่า') ||
          income['ประเภทเงินได้'].includes('ค่าขนส่ง') ||
          income['ประเภทเงินได้'].includes('ค่าบริการ') ||
          income['ประเภทเงินได้'].includes('ค่าเบี้ยประกันวินาศภัย')
        ) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_5.date = _date;
          _paymentType_5.dateBE = _dateBE;
          _paymentType_5.dateCE = _dateCE;
          _paymentType_5.amountTotal = Number(_paymentType_5.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_5.vatTotal = Number(_paymentType_5.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_6.date = _date;
          _paymentType_6.dateBE = _dateBE;
          _paymentType_6.dateCE = _dateCE;
          _paymentType_6.remark = income['ประเภทเงินได้'];
          _paymentType_6.amountTotal = Number(_paymentType_6.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_6.vatTotal = Number(_paymentType_6.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        }
      });
    } else if (item.pnd === 'PND2') {
      if (item['คำนำหน้าชื่อ']) _customer.name = _customer.name + item['คำนำหน้าชื่อ'];
      if (item['ชื่อ']) _customer.name = _customer.name + ' ' + item['ชื่อ'];
      if (item['ชื่อกลาง']) _customer.name = _customer.name + ' ' + item['ชื่อกลาง'];
      if (item['ชื่อสกุล']) _customer.name = _customer.name + ' ' + item['ชื่อสกุล'];

      item.incomes.forEach((income) => {
        _total = _total + Number(income['จำนวนเงินที่จ่าย']);

        if (income['เงินได้ตามมาตรา'].includes('403N')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_3.date = _date;
          _paymentType_3.dateBE = _dateBE;
          _paymentType_3.dateCE = _dateCE;
          _paymentType_3.amountTotal = Number(_paymentType_3.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_3.vatTotal = Number(_paymentType_3.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (income['เงินได้ตามมาตรา'].includes('404A')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_4_A.date = _date;
          _paymentType_4_A.dateBE = _dateBE;
          _paymentType_4_A.dateCE = _dateCE;
          _paymentType_4_A.amountTotal = Number(_paymentType_4_A.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_4_A.vatTotal = Number(_paymentType_4_A.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (
          income['เงินได้ตามมาตรา'].includes('404B') ||
          income['เงินได้ตามมาตรา'].includes('404G') ||
          income['เงินได้ตามมาตรา'].includes('404T')
        ) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_4_B_1_4.date = _date;
          _paymentType_4_B_1_4.dateBE = _dateBE;
          _paymentType_4_B_1_4.dateCE = _dateCE;
          _paymentType_4_B_1_4.percentVat = Number(income['อัตราภาษี']);
          _paymentType_4_B_1_4.amountTotal = Number(_paymentType_4_B_1_4.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_4_B_1_4.vatTotal = Number(_paymentType_4_B_1_4.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        }
      });
    }

    let _totalVat = 0;
    item.incomes.forEach((income) => {
      _totalVat = _totalVat + Number(income['จำนวนเงินภาษีที่หัก']);
    });

    let _vatTotalText = numberToTextTh(_totalVat);

    let _paymentAmount = {
      privateTeacherAidFund: '',
      providentFund: '',
      socialFund: '',
    };

    let _payment_type_1 = false;
    let _payment_type_2 = false;
    let _payment_type_3 = false;
    let _payment_type_4 = false;

    if (item.incomes[0]['เงื่อนไขการหัก'] === '1' || item.incomes[0]['เงื่อนไขการหัก'] === 1) _payment_type_1 = true;
    if (item.incomes[0]['เงื่อนไขการหัก'] === '2' || item.incomes[0]['เงื่อนไขการหัก'] === 2) _payment_type_2 = true;

    const _dateSplit = item.incomes[0]['วันเดือนปีที่จ่าย'].split('/');

    let _date = '';
    let _dateBE = '';
    let _dateCE = '';

    if (Number(_dateSplit[2]) < 2100) {
      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
    } else {
      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
    }

    history.push({
      pathname: '/documents/withholding-taxs/confirm',
      state: {
        // location state
        bookNumber: '',
        number: item['เลขที่เอกสาร'],
        company: item.company,
        customer: _customer,
        orderNumber: '',
        formType_1: _formType_1,
        formType_2: _formType_2,
        formType_3: _formType_3,
        formType_4: _formType_4,
        formType_5: _formType_5,
        formType_6: _formType_6,
        formType_7: _formType_7,
        paymentType_1: _paymentType_1,
        paymentType_2: _paymentType_2,
        paymentType_3: _paymentType_3,
        paymentType_4_A: _paymentType_4_A,
        paymentType_4_B_1_1: _paymentType_4_B_1_1,
        paymentType_4_B_1_2: _paymentType_4_B_1_2,
        paymentType_4_B_1_3: _paymentType_4_B_1_3,
        paymentType_4_B_1_4: _paymentType_4_B_1_4,
        paymentType_4_B_2_1: _paymentType_4_B_2_1,
        paymentType_4_B_2_2: _paymentType_4_B_2_2,
        paymentType_4_B_2_3: _paymentType_4_B_2_3,
        paymentType_4_B_2_4: _paymentType_4_B_2_4,
        paymentType_4_B_2_5: _paymentType_4_B_2_5,
        paymentType_5: _paymentType_5,
        paymentType_6: _paymentType_6,
        amountTotal: _total,
        vatTotal: _totalVat,
        vatTotalText: _vatTotalText,
        paymentAmount: _paymentAmount,
        payment_type_1: _payment_type_1,
        payment_type_2: _payment_type_2,
        payment_type_3: _payment_type_3,
        payment_type_4: _payment_type_4,
        otherPayment: '',
        date: _date,
        dateBE: _dateBE,
        dateCE: _dateCE,
        signature: '',
        seal: '',
        referExpenseNote: {},
        backUrl: '/withholding-taxs/excel/list',
      },
    });
  };

  const columns = [
    {
      key: 'column1',
      name: t('whtDropzoneUploadList.date'),
      fieldName: 'วันเดือนปีที่จ่าย',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return <div>{item['วันเดือนปีที่จ่าย']}</div>;
      },
    },
    {
      key: 'column2',
      name: t('whtDropzoneUploadList.number'),
      fieldName: 'เลขที่เอกสาร',
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return <div>{item['เลขที่เอกสาร']}</div>;
      },
    },
    {
      key: 'column3',
      name: t('whtDropzoneUploadList.taxId'),
      fieldName: 'เลขประจำตัวผู้เสียภาษีอากร',
      minWidth: 165,
      maxWidth: 200,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return <div>{item['เลขประจำตัวผู้เสียภาษีอากร']}</div>;
      },
    },
    {
      key: 'column4',
      name: t('whtDropzoneUploadList.name'),
      fieldName: 'ชื่อ',
      minWidth: 165,
      maxWidth: 170,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        let _name = '';
        if (item['คำนำหน้าชื่อ']) _name = _name + item['คำนำหน้าชื่อ'];
        if (item['ชื่อ']) _name = _name + ' ' + item['ชื่อ'];
        if (item['ชื่อกลาง']) _name = _name + ' ' + item['ชื่อกลาง'];
        if (item['ชื่อสกุล']) _name = _name + ' ' + item['ชื่อสกุล'];

        return <div>{_name}</div>;
      },
    },
    {
      key: 'column5',
      name: t('whtDropzoneUploadList.amountTotal'),
      fieldName: 'จำนวนเงินที่จ่าย',
      className: classNames.alignRight,
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        let _total = 0;
        item.incomes.forEach((income) => {
          _total = _total + Number(income['จำนวนเงินที่จ่าย']);
        });

        return (
          <div>
            {_total.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </div>
        );
      },
    },
    {
      key: 'column6',
      name: t('whtDropzoneUploadList.vatTotal'),
      fieldName: 'จำนวนเงินภาษีที่หัก',
      className: classNames.alignRight,
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        let _totalVat = 0;
        item.incomes.forEach((income) => {
          _totalVat = _totalVat + Number(income['จำนวนเงินภาษีที่หัก']);
        });

        return (
          <div>
            {_totalVat.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </div>
        );
      },
    },
    {
      key: 'column10',
      name: isCreateDocument || isSelectDocuments ? t('whtDropzoneUploadList.docStatus') : '',
      fieldName: 'command',
      minWidth: 250,
      maxWidth: 250,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isSelectDocuments) {
          if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('whtDropzoneUploadList.success')}</span>;
          } else {
            return;
          }
        } else if (isCreateDocument) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('whtDropzoneUploadList.pending')}</span>;
          } else if (item.status === 'processing') {
            return (
              <span>
                <Spinner label={t('whtDropzoneUploadList.processing')} labelPosition="right" style={{ marginLeft: '-130px' }} />
              </span>
            );
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('whtDropzoneUploadList.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('whtDropzoneUploadList.fail')}</span>;
          }
        } else if (isCreateDocumentComplete) {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                styles={{ root: { width: 110, marginRight: 10 } }}
                disabled={isCreateDocument}
                text={t('whtDropzoneUploadList.viewPDF')}
                onClick={() => {
                  viewPDF(item);
                }}
              />
              {item.status === 'successful' && <span style={{ color: 'green' }}>{t('whtDropzoneUploadList.success')}</span>}
              {item.status === 'fail' && <span style={{ color: 'red' }}>{t('whtDropzoneUploadList.fail')}</span>}
              {!(item.status === 'successful' || item.status === 'fail') && (
                <DefaultButton
                  text={t('whtDropzoneUploadList.createDoc')}
                  disabled={isSelectDocuments}
                  onClick={() => {
                    createDocument(item);
                  }}
                />
              )}
            </span>
          );
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                styles={{ root: { width: 110, marginRight: 10 } }}
                disabled={isCreateDocument}
                text={t('whtDropzoneUploadList.viewPDF')}
                onClick={() => {
                  viewPDF(item);
                }}
              />
              {item.status === 'successful' && <span style={{ color: 'green' }}>{t('whtDropzoneUploadList.success')}</span>}
              {item.status !== 'successful' && (
                <DefaultButton
                  text={t('whtDropzoneUploadList.createDoc')}
                  disabled={isSelectDocuments}
                  onClick={() => {
                    createDocument(item);
                  }}
                />
              )}
            </span>
          );
        }
      },
    },
  ];

  const createDocument = async (item) => {
    let _addressLineOne = '';
    if (item['ชื่ออาคาร']) _addressLineOne = _addressLineOne + item['ชื่ออาคาร'] + ' ';
    if (item['ห้องเลขที่']) _addressLineOne = _addressLineOne + item['ห้องเลขที่'] + ' ';
    if (item['ชั้นที่']) _addressLineOne = _addressLineOne + item['ชั้นที่'] + ' ';
    if (item['หมู่บ้าน']) _addressLineOne = _addressLineOne + item['หมู่บ้าน'] + ' ';
    if (item['เลขที่']) _addressLineOne = _addressLineOne + item['เลขที่'] + ' ';
    if (item['หมู่ที่']) _addressLineOne = _addressLineOne + 'หมู่ ' + item['หมู่ที่'] + ' ';
    if (item['ตรอก/ซอย']) _addressLineOne = _addressLineOne + 'ซอย ' + item['ตรอก/ซอย'] + ' ';
    if (item['แยก']) _addressLineOne = _addressLineOne + 'แยก ' + item['แยก'] + ' ';
    if (item['ถนน']) _addressLineOne = _addressLineOne + 'ถนน ' + item['ถนน'] + ' ';

    let _provincePrefix = 'จ.';
    let _districtPrefix = 'อ.';
    let _subDistrictPrefix = 'ต.';

    if (item['จังหวัด']?.includes('กรุงเทพ')) {
      _provincePrefix = '';
      _districtPrefix = 'เขต ';
      _subDistrictPrefix = 'แขวง ';
    }

    if (item['ตำบล/แขวง']) _addressLineOne = _addressLineOne + _subDistrictPrefix + item['ตำบล/แขวง'] + ' ';
    if (item['อำเภอ/เขต']) _addressLineOne = _addressLineOne + _districtPrefix + item['อำเภอ/เขต'] + ' ';
    if (item['จังหวัด']) _addressLineOne = _addressLineOne + _provincePrefix + item['จังหวัด'] + ' ';
    if (item['รหัสไปรษณีย์']) _addressLineOne = _addressLineOne + item['รหัสไปรษณีย์'];

    let _customer = {
      name: '',
      taxId: item['เลขประจำตัวผู้เสียภาษีอากร'],
      taxIdForm: {
        zero: item['เลขประจำตัวผู้เสียภาษีอากร'][0],
        one: item['เลขประจำตัวผู้เสียภาษีอากร'][1],
        two: item['เลขประจำตัวผู้เสียภาษีอากร'][2],
        three: item['เลขประจำตัวผู้เสียภาษีอากร'][3],
        four: item['เลขประจำตัวผู้เสียภาษีอากร'][4],
        five: item['เลขประจำตัวผู้เสียภาษีอากร'][5],
        six: item['เลขประจำตัวผู้เสียภาษีอากร'][6],
        seven: item['เลขประจำตัวผู้เสียภาษีอากร'][7],
        eight: item['เลขประจำตัวผู้เสียภาษีอากร'][8],
        nine: item['เลขประจำตัวผู้เสียภาษีอากร'][9],
        ten: item['เลขประจำตัวผู้เสียภาษีอากร'][10],
        eleven: item['เลขประจำตัวผู้เสียภาษีอากร'][11],
        twelve: item['เลขประจำตัวผู้เสียภาษีอากร'][12],
      },
      taxId10Form: '',
      addressLineOne: _addressLineOne || '-',
    };

    let _formType_1 = false;
    let _formType_2 = false;
    let _formType_3 = false;
    let _formType_4 = false;
    let _formType_5 = false;
    let _formType_6 = false;
    let _formType_7 = false;

    if (item.pnd === 'PND53') _formType_7 = true;
    if (item.pnd === 'PND3') _formType_4 = true;
    if (item.pnd === 'PND2') _formType_3 = true;

    let _paymentType_1 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_2 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_3 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_A = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_1_1 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_1_2 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_1_3 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_1_4 = {
      date: '',
      dateBE: '',
      dateCE: '',
      percentVat: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_2_1 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_2_2 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_2_3 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_2_4 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_4_B_2_5 = {
      date: '',
      dateBE: '',
      dateCE: '',
      remark: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_5 = {
      date: '',
      dateBE: '',
      dateCE: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _paymentType_6 = {
      date: '',
      dateBE: '',
      dateCE: '',
      remark: '',
      amountTotal: '',
      vatTotal: '',
    };

    let _total = 0;

    if (item.pnd === 'PND53') {
      if (item['คำนำหน้าชื่อ']) _customer.name = _customer.name + item['คำนำหน้าชื่อ'];
      if (item['ชื่อ']) _customer.name = _customer.name + ' ' + item['ชื่อ'];

      item.incomes.forEach((income) => {
        _total = _total + Number(income['จำนวนเงินที่จ่าย']);

        if (
          income['ประเภทเงินได้'].includes('เงินเดือน') ||
          income['ประเภทเงินได้'].includes('ค่าจ้าง') ||
          income['ประเภทเงินได้'].includes('เบี้ยเลี้ยง') ||
          income['ประเภทเงินได้'].includes('โบนัส')
        ) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_1.date = _date;
          _paymentType_1.dateBE = _dateBE;
          _paymentType_1.dateCE = _dateCE;
          _paymentType_1.amountTotal = Number(_paymentType_1.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_1.vatTotal = Number(_paymentType_1.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (income['ประเภทเงินได้'].includes('ค่าธรรมเนียม') || income['ประเภทเงินได้'].includes('ค่านายหน้า')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_2.date = _date;
          _paymentType_2.dateBE = _dateBE;
          _paymentType_2.dateCE = _dateCE;
          _paymentType_2.amountTotal = Number(_paymentType_2.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_2.vatTotal = Number(_paymentType_2.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (income['ประเภทเงินได้'].includes('ค่าแห่งลิขสิทธิ์')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_3.date = _date;
          _paymentType_3.dateBE = _dateBE;
          _paymentType_3.dateCE = _dateCE;
          _paymentType_3.amountTotal = Number(_paymentType_3.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_3.vatTotal = Number(_paymentType_3.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (income['ประเภทเงินได้'].includes('ดอกเบี้ย')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_4_A.date = _date;
          _paymentType_4_A.dateBE = _dateBE;
          _paymentType_4_A.dateCE = _dateCE;
          _paymentType_4_A.amountTotal = Number(_paymentType_4_A.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_4_A.vatTotal = Number(_paymentType_4_A.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (income['ประเภทเงินได้'].includes('เงินปันผล') || income['ประเภทเงินได้'].includes('เงินส่วนแบ่งกำไร')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          if (Number(income['อัตราภาษี']) === 30) {
            _paymentType_4_B_1_1.date = _date;
            _paymentType_4_B_1_1.dateBE = _dateBE;
            _paymentType_4_B_1_1.dateCE = _dateCE;
            _paymentType_4_B_1_1.amountTotal = Number(_paymentType_4_B_1_1.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
            _paymentType_4_B_1_1.vatTotal = Number(_paymentType_4_B_1_1.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
          } else if (Number(income['อัตราภาษี']) === 25) {
            _paymentType_4_B_1_2.date = _date;
            _paymentType_4_B_1_2.dateBE = _dateBE;
            _paymentType_4_B_1_2.dateCE = _dateCE;
            _paymentType_4_B_1_2.amountTotal = Number(_paymentType_4_B_1_2.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
            _paymentType_4_B_1_2.vatTotal = Number(_paymentType_4_B_1_2.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
          } else if (Number(income['อัตราภาษี']) === 20) {
            _paymentType_4_B_1_3.date = _date;
            _paymentType_4_B_1_3.dateBE = _dateBE;
            _paymentType_4_B_1_3.dateCE = _dateCE;
            _paymentType_4_B_1_3.amountTotal = Number(_paymentType_4_B_1_3.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
            _paymentType_4_B_1_3.vatTotal = Number(_paymentType_4_B_1_3.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
          } else {
            _paymentType_4_B_1_4.date = _date;
            _paymentType_4_B_1_4.dateBE = _dateBE;
            _paymentType_4_B_1_4.dateCE = _dateCE;
            _paymentType_4_B_1_4.percentVat = Number(income['อัตราภาษี']);
            _paymentType_4_B_1_4.amountTotal = Number(_paymentType_4_B_1_4.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
            _paymentType_4_B_1_4.vatTotal = Number(_paymentType_4_B_1_4.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
          }
        } else if (
          income['ประเภทเงินได้'].includes('รางวัล') ||
          income['ประเภทเงินได้'].includes('ส่วนลด') ||
          income['ประเภทเงินได้'].includes('การแข่งขัน') ||
          income['ประเภทเงินได้'].includes('การชิงโชค') ||
          income['ประเภทเงินได้'].includes('ค่าแสดง') ||
          income['ประเภทเงินได้'].includes('ค่าจ้าง') ||
          income['ประเภทเงินได้'].includes('ค่าโฆษณา') ||
          income['ประเภทเงินได้'].includes('ค่าเช่า') ||
          income['ประเภทเงินได้'].includes('ค่าขนส่ง') ||
          income['ประเภทเงินได้'].includes('ค่าบริการ') ||
          income['ประเภทเงินได้'].includes('ค่าเบี้ยประกันวินาศภัย')
        ) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_5.date = _date;
          _paymentType_5.dateBE = _dateBE;
          _paymentType_5.dateCE = _dateCE;
          _paymentType_5.amountTotal = Number(_paymentType_5.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_5.vatTotal = Number(_paymentType_5.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_6.date = _date;
          _paymentType_6.dateBE = _dateBE;
          _paymentType_6.dateCE = _dateCE;
          _paymentType_6.remark = income['ประเภทเงินได้'];
          _paymentType_6.amountTotal = Number(_paymentType_6.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_6.vatTotal = Number(_paymentType_6.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        }
      });
    } else if (item.pnd === 'PND3') {
      if (item['คำนำหน้าชื่อ']) _customer.name = _customer.name + item['คำนำหน้าชื่อ'];
      if (item['ชื่อ']) _customer.name = _customer.name + ' ' + item['ชื่อ'];
      if (item['ชื่อกลาง']) _customer.name = _customer.name + ' ' + item['ชื่อกลาง'];
      if (item['ชื่อสกุล']) _customer.name = _customer.name + ' ' + item['ชื่อสกุล'];

      item.incomes.forEach((income) => {
        _total = _total + Number(income['จำนวนเงินที่จ่าย']);

        if (
          income['ประเภทเงินได้'].includes('เงินเดือน') ||
          income['ประเภทเงินได้'].includes('ค่าจ้าง') ||
          income['ประเภทเงินได้'].includes('เบี้ยเลี้ยง') ||
          income['ประเภทเงินได้'].includes('โบนัส')
        ) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_1.date = _date;
          _paymentType_1.dateBE = _dateBE;
          _paymentType_1.dateCE = _dateCE;
          _paymentType_1.amountTotal = Number(_paymentType_1.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_1.vatTotal = Number(_paymentType_1.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (income['ประเภทเงินได้'].includes('ค่าธรรมเนียม') || income['ประเภทเงินได้'].includes('ค่านายหน้า')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_2.date = _date;
          _paymentType_2.dateBE = _dateBE;
          _paymentType_2.dateCE = _dateCE;
          _paymentType_2.amountTotal = Number(_paymentType_2.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_2.vatTotal = Number(_paymentType_2.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (income['ประเภทเงินได้'].includes('ค่าแห่งลิขสิทธิ์')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_3.date = _date;
          _paymentType_3.dateBE = _dateBE;
          _paymentType_3.dateCE = _dateCE;
          _paymentType_3.amountTotal = Number(_paymentType_3.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_3.vatTotal = Number(_paymentType_3.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (
          income['ประเภทเงินได้'].includes('รางวัล') ||
          income['ประเภทเงินได้'].includes('ส่วนลด') ||
          income['ประเภทเงินได้'].includes('การแข่งขัน') ||
          income['ประเภทเงินได้'].includes('การชิงโชค') ||
          income['ประเภทเงินได้'].includes('ค่าแสดง') ||
          income['ประเภทเงินได้'].includes('ค่าจ้าง') ||
          income['ประเภทเงินได้'].includes('ค่าโฆษณา') ||
          income['ประเภทเงินได้'].includes('ค่าเช่า') ||
          income['ประเภทเงินได้'].includes('ค่าขนส่ง') ||
          income['ประเภทเงินได้'].includes('ค่าบริการ') ||
          income['ประเภทเงินได้'].includes('ค่าเบี้ยประกันวินาศภัย')
        ) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_5.date = _date;
          _paymentType_5.dateBE = _dateBE;
          _paymentType_5.dateCE = _dateCE;
          _paymentType_5.amountTotal = Number(_paymentType_5.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_5.vatTotal = Number(_paymentType_5.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_6.date = _date;
          _paymentType_6.dateBE = _dateBE;
          _paymentType_6.dateCE = _dateCE;
          _paymentType_6.remark = income['ประเภทเงินได้'];
          _paymentType_6.amountTotal = Number(_paymentType_6.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_6.vatTotal = Number(_paymentType_6.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        }
      });
    } else if (item.pnd === 'PND2') {
      if (item['คำนำหน้าชื่อ']) _customer.name = _customer.name + item['คำนำหน้าชื่อ'];
      if (item['ชื่อ']) _customer.name = _customer.name + ' ' + item['ชื่อ'];
      if (item['ชื่อกลาง']) _customer.name = _customer.name + ' ' + item['ชื่อกลาง'];
      if (item['ชื่อสกุล']) _customer.name = _customer.name + ' ' + item['ชื่อสกุล'];

      item.incomes.forEach((income) => {
        _total = _total + Number(income['จำนวนเงินที่จ่าย']);

        if (income['เงินได้ตามมาตรา'].includes('403N')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_3.date = _date;
          _paymentType_3.dateBE = _dateBE;
          _paymentType_3.dateCE = _dateCE;
          _paymentType_3.amountTotal = Number(_paymentType_3.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_3.vatTotal = Number(_paymentType_3.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (income['เงินได้ตามมาตรา'].includes('404A')) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_4_A.date = _date;
          _paymentType_4_A.dateBE = _dateBE;
          _paymentType_4_A.dateCE = _dateCE;
          _paymentType_4_A.amountTotal = Number(_paymentType_4_A.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_4_A.vatTotal = Number(_paymentType_4_A.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        } else if (
          income['เงินได้ตามมาตรา'].includes('404B') ||
          income['เงินได้ตามมาตรา'].includes('404G') ||
          income['เงินได้ตามมาตรา'].includes('404T')
        ) {
          const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

          let _date = '';
          let _dateBE = '';
          let _dateCE = '';

          if (Number(_dateSplit[2]) < 2100) {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
          } else {
            _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
            _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
            _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
          }

          _paymentType_4_B_1_4.date = _date;
          _paymentType_4_B_1_4.dateBE = _dateBE;
          _paymentType_4_B_1_4.dateCE = _dateCE;
          _paymentType_4_B_1_4.percentVat = Number(income['อัตราภาษี']);
          _paymentType_4_B_1_4.amountTotal = Number(_paymentType_4_B_1_4.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
          _paymentType_4_B_1_4.vatTotal = Number(_paymentType_4_B_1_4.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
        }
      });
    }

    let _totalVat = 0;
    item.incomes.forEach((income) => {
      _totalVat = _totalVat + Number(income['จำนวนเงินภาษีที่หัก']);
    });

    let _vatTotalText = numberToTextTh(_totalVat);

    let _paymentAmount = {
      privateTeacherAidFund: '',
      providentFund: '',
      socialFund: '',
    };

    let _payment_type_1 = false;
    let _payment_type_2 = false;
    let _payment_type_3 = false;
    let _payment_type_4 = false;

    if (item.incomes[0]['เงื่อนไขการหัก'] === '1' || item.incomes[0]['เงื่อนไขการหัก'] === 1) _payment_type_1 = true;
    if (item.incomes[0]['เงื่อนไขการหัก'] === '2' || item.incomes[0]['เงื่อนไขการหัก'] === 2) _payment_type_2 = true;

    const _dateSplit = item.incomes[0]['วันเดือนปีที่จ่าย'].split('/');

    let _date = '';
    let _dateBE = '';
    let _dateCE = '';

    if (Number(_dateSplit[2]) < 2100) {
      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
    } else {
      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
    }

    history.push({
      pathname: '/documents/withholding-taxs',
      state: {
        bookNumber: '',
        number: item['เลขที่เอกสาร'],
        company: item.company,
        customer: _customer,
        orderNumber: '',
        formType_1: _formType_1,
        formType_2: _formType_2,
        formType_3: _formType_3,
        formType_4: _formType_4,
        formType_5: _formType_5,
        formType_6: _formType_6,
        formType_7: _formType_7,
        paymentType_1: _paymentType_1,
        paymentType_2: _paymentType_2,
        paymentType_3: _paymentType_3,
        paymentType_4_A: _paymentType_4_A,
        paymentType_4_B_1_1: _paymentType_4_B_1_1,
        paymentType_4_B_1_2: _paymentType_4_B_1_2,
        paymentType_4_B_1_3: _paymentType_4_B_1_3,
        paymentType_4_B_1_4: _paymentType_4_B_1_4,
        paymentType_4_B_2_1: _paymentType_4_B_2_1,
        paymentType_4_B_2_2: _paymentType_4_B_2_2,
        paymentType_4_B_2_3: _paymentType_4_B_2_3,
        paymentType_4_B_2_4: _paymentType_4_B_2_4,
        paymentType_4_B_2_5: _paymentType_4_B_2_5,
        paymentType_5: _paymentType_5,
        paymentType_6: _paymentType_6,
        amountTotal: _total,
        vatTotal: _totalVat,
        vatTotalText: _vatTotalText,
        paymentAmount: _paymentAmount,
        payment_type_1: _payment_type_1,
        payment_type_2: _payment_type_2,
        payment_type_3: _payment_type_3,
        payment_type_4: _payment_type_4,
        otherPayment: '',
        date: _date,
        dateBE: _dateBE,
        dateCE: _dateCE,
        signature: '',
        seal: '',
        referExpenseNote: {},
        backUrl: '/withholding-taxs/excel/list',
      },
    });
  };

  const createSelectedDocuments = async () => {
    let _items = selection.getItems();

    let _selectedItems = selectedItems;
    _items.forEach((item) => {
      if (_selectedItems.some((selectedItem) => item.key === selectedItem.key)) {
        item.status = 'pending';
      }
    });

    setItems([..._items]);

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        for (let i = 0; i < selectedItems.length; i++) {
          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === selectedItems[i].key) {
              _items[j].status = 'processing';
              setItems([..._items]);

              let _addressLineOne = '';
              if (selectedItems[i]['ชื่ออาคาร']) _addressLineOne = _addressLineOne + selectedItems[i]['ชื่ออาคาร'] + ' ';
              if (selectedItems[i]['ห้องเลขที่']) _addressLineOne = _addressLineOne + selectedItems[i]['ห้องเลขที่'] + ' ';
              if (selectedItems[i]['ชั้นที่']) _addressLineOne = _addressLineOne + selectedItems[i]['ชั้นที่'] + ' ';
              if (selectedItems[i]['หมู่บ้าน']) _addressLineOne = _addressLineOne + selectedItems[i]['หมู่บ้าน'] + ' ';
              if (selectedItems[i]['เลขที่']) _addressLineOne = _addressLineOne + selectedItems[i]['เลขที่'] + ' ';

              if (selectedItems[i]['หมู่ที่']) _addressLineOne = _addressLineOne + 'หมู่ ' + selectedItems[i]['หมู่ที่'] + ' ';
              if (selectedItems[i]['ตรอก/ซอย']) _addressLineOne = _addressLineOne + 'ซอย ' + selectedItems[i]['ตรอก/ซอย'] + ' ';
              if (selectedItems[i]['แยก']) _addressLineOne = _addressLineOne + 'แยก ' + selectedItems[i]['แยก'] + ' ';
              if (selectedItems[i]['ถนน']) _addressLineOne = _addressLineOne + 'ถนน ' + selectedItems[i]['ถนน'] + ' ';

              let _provincePrefix = 'จ.';
              let _districtPrefix = 'อ.';
              let _subDistrictPrefix = 'ต.';

              if (selectedItems[i]['จังหวัด']?.includes('กรุงเทพ')) {
                _provincePrefix = '';
                _districtPrefix = 'เขต ';
                _subDistrictPrefix = 'แขวง ';
              }

              if (selectedItems[i]['ตำบล/แขวง']) _addressLineOne = _addressLineOne + _subDistrictPrefix + selectedItems[i]['ตำบล/แขวง'] + ' ';
              if (selectedItems[i]['อำเภอ/เขต']) _addressLineOne = _addressLineOne + _districtPrefix + selectedItems[i]['อำเภอ/เขต'] + ' ';
              if (selectedItems[i]['จังหวัด']) _addressLineOne = _addressLineOne + _provincePrefix + selectedItems[i]['จังหวัด'] + ' ';
              if (selectedItems[i]['รหัสไปรษณีย์']) _addressLineOne = _addressLineOne + selectedItems[i]['รหัสไปรษณีย์'];

              let _customer = {
                name: '',
                taxId: selectedItems[i]['เลขประจำตัวผู้เสียภาษีอากร'],
                taxIdForm: {
                  zero: selectedItems[i]['เลขประจำตัวผู้เสียภาษีอากร'][0],
                  one: selectedItems[i]['เลขประจำตัวผู้เสียภาษีอากร'][1],
                  two: selectedItems[i]['เลขประจำตัวผู้เสียภาษีอากร'][2],
                  three: selectedItems[i]['เลขประจำตัวผู้เสียภาษีอากร'][3],
                  four: selectedItems[i]['เลขประจำตัวผู้เสียภาษีอากร'][4],
                  five: selectedItems[i]['เลขประจำตัวผู้เสียภาษีอากร'][5],
                  six: selectedItems[i]['เลขประจำตัวผู้เสียภาษีอากร'][6],
                  seven: selectedItems[i]['เลขประจำตัวผู้เสียภาษีอากร'][7],
                  eight: selectedItems[i]['เลขประจำตัวผู้เสียภาษีอากร'][8],
                  nine: selectedItems[i]['เลขประจำตัวผู้เสียภาษีอากร'][9],
                  ten: selectedItems[i]['เลขประจำตัวผู้เสียภาษีอากร'][10],
                  eleven: selectedItems[i]['เลขประจำตัวผู้เสียภาษีอากร'][11],
                  twelve: selectedItems[i]['เลขประจำตัวผู้เสียภาษีอากร'][12],
                },
                taxId10Form: '',
                addressLineOne: _addressLineOne || '-',
              };

              let _formType_1 = false;
              let _formType_2 = false;
              let _formType_3 = false;
              let _formType_4 = false;
              let _formType_5 = false;
              let _formType_6 = false;
              let _formType_7 = false;

              if (selectedItems[i].pnd === 'PND53') _formType_7 = true;
              if (selectedItems[i].pnd === 'PND3') _formType_4 = true;
              if (selectedItems[i].pnd === 'PND2') _formType_3 = true;

              let _paymentType_1 = {
                date: '',
                dateBE: '',
                dateCE: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _paymentType_2 = {
                date: '',
                dateBE: '',
                dateCE: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _paymentType_3 = {
                date: '',
                dateBE: '',
                dateCE: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _paymentType_4_A = {
                date: '',
                dateBE: '',
                dateCE: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _paymentType_4_B_1_1 = {
                date: '',
                dateBE: '',
                dateCE: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _paymentType_4_B_1_2 = {
                date: '',
                dateBE: '',
                dateCE: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _paymentType_4_B_1_3 = {
                date: '',
                dateBE: '',
                dateCE: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _paymentType_4_B_1_4 = {
                date: '',
                dateBE: '',
                dateCE: '',
                percentVat: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _paymentType_4_B_2_1 = {
                date: '',
                dateBE: '',
                dateCE: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _paymentType_4_B_2_2 = {
                date: '',
                dateBE: '',
                dateCE: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _paymentType_4_B_2_3 = {
                date: '',
                dateBE: '',
                dateCE: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _paymentType_4_B_2_4 = {
                date: '',
                dateBE: '',
                dateCE: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _paymentType_4_B_2_5 = {
                date: '',
                dateBE: '',
                dateCE: '',
                remark: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _paymentType_5 = {
                date: '',
                dateBE: '',
                dateCE: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _paymentType_6 = {
                date: '',
                dateBE: '',
                dateCE: '',
                remark: '',
                amountTotal: '',
                vatTotal: '',
              };

              let _total = 0;

              if (selectedItems[i].pnd === 'PND53') {
                if (selectedItems[i]['คำนำหน้าชื่อ']) _customer.name = _customer.name + selectedItems[i]['คำนำหน้าชื่อ'];
                if (selectedItems[i]['ชื่อ']) _customer.name = _customer.name + ' ' + selectedItems[i]['ชื่อ'];

                selectedItems[i].incomes.forEach((income) => {
                  _total = _total + Number(income['จำนวนเงินที่จ่าย']);

                  if (
                    income['ประเภทเงินได้'].includes('เงินเดือน') ||
                    income['ประเภทเงินได้'].includes('ค่าจ้าง') ||
                    income['ประเภทเงินได้'].includes('เบี้ยเลี้ยง') ||
                    income['ประเภทเงินได้'].includes('โบนัส')
                  ) {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    _paymentType_1.date = _date;
                    _paymentType_1.dateBE = _dateBE;
                    _paymentType_1.dateCE = _dateCE;
                    _paymentType_1.amountTotal = Number(_paymentType_1.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                    _paymentType_1.vatTotal = Number(_paymentType_1.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                  } else if (income['ประเภทเงินได้'].includes('ค่าธรรมเนียม') || income['ประเภทเงินได้'].includes('ค่านายหน้า')) {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    _paymentType_2.date = _date;
                    _paymentType_2.dateBE = _dateBE;
                    _paymentType_2.dateCE = _dateCE;
                    _paymentType_2.amountTotal = Number(_paymentType_2.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                    _paymentType_2.vatTotal = Number(_paymentType_2.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                  } else if (income['ประเภทเงินได้'].includes('ค่าแห่งลิขสิทธิ์')) {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    _paymentType_3.date = _date;
                    _paymentType_3.dateBE = _dateBE;
                    _paymentType_3.dateCE = _dateCE;
                    _paymentType_3.amountTotal = Number(_paymentType_3.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                    _paymentType_3.vatTotal = Number(_paymentType_3.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                  } else if (income['ประเภทเงินได้'].includes('ดอกเบี้ย')) {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    _paymentType_4_A.date = _date;
                    _paymentType_4_A.dateBE = _dateBE;
                    _paymentType_4_A.dateCE = _dateCE;
                    _paymentType_4_A.amountTotal = Number(_paymentType_4_A.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                    _paymentType_4_A.vatTotal = Number(_paymentType_4_A.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                  } else if (income['ประเภทเงินได้'].includes('เงินปันผล') || income['ประเภทเงินได้'].includes('เงินส่วนแบ่งกำไร')) {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    if (Number(income['อัตราภาษี']) === 30) {
                      _paymentType_4_B_1_1.date = _date;
                      _paymentType_4_B_1_1.dateBE = _dateBE;
                      _paymentType_4_B_1_1.dateCE = _dateCE;
                      _paymentType_4_B_1_1.amountTotal = Number(_paymentType_4_B_1_1.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                      _paymentType_4_B_1_1.vatTotal = Number(_paymentType_4_B_1_1.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                    } else if (Number(income['อัตราภาษี']) === 25) {
                      _paymentType_4_B_1_2.date = _date;
                      _paymentType_4_B_1_2.dateBE = _dateBE;
                      _paymentType_4_B_1_2.dateCE = _dateCE;
                      _paymentType_4_B_1_2.amountTotal = Number(_paymentType_4_B_1_2.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                      _paymentType_4_B_1_2.vatTotal = Number(_paymentType_4_B_1_2.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                    } else if (Number(income['อัตราภาษี']) === 20) {
                      _paymentType_4_B_1_3.date = _date;
                      _paymentType_4_B_1_3.dateBE = _dateBE;
                      _paymentType_4_B_1_3.dateCE = _dateCE;
                      _paymentType_4_B_1_3.amountTotal = Number(_paymentType_4_B_1_3.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                      _paymentType_4_B_1_3.vatTotal = Number(_paymentType_4_B_1_3.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                    } else {
                      _paymentType_4_B_1_4.date = _date;
                      _paymentType_4_B_1_4.dateBE = _dateBE;
                      _paymentType_4_B_1_4.dateCE = _dateCE;
                      _paymentType_4_B_1_4.percentVat = Number(income['อัตราภาษี']);
                      _paymentType_4_B_1_4.amountTotal = Number(_paymentType_4_B_1_4.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                      _paymentType_4_B_1_4.vatTotal = Number(_paymentType_4_B_1_4.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                    }
                  } else if (
                    income['ประเภทเงินได้'].includes('รางวัล') ||
                    income['ประเภทเงินได้'].includes('ส่วนลด') ||
                    income['ประเภทเงินได้'].includes('การแข่งขัน') ||
                    income['ประเภทเงินได้'].includes('การชิงโชค') ||
                    income['ประเภทเงินได้'].includes('ค่าแสดง') ||
                    income['ประเภทเงินได้'].includes('ค่าจ้าง') ||
                    income['ประเภทเงินได้'].includes('ค่าโฆษณา') ||
                    income['ประเภทเงินได้'].includes('ค่าเช่า') ||
                    income['ประเภทเงินได้'].includes('ค่าขนส่ง') ||
                    income['ประเภทเงินได้'].includes('ค่าบริการ') ||
                    income['ประเภทเงินได้'].includes('ค่าเบี้ยประกันวินาศภัย')
                  ) {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    _paymentType_5.date = _date;
                    _paymentType_5.dateBE = _dateBE;
                    _paymentType_5.dateCE = _dateCE;
                    _paymentType_5.amountTotal = Number(_paymentType_5.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                    _paymentType_5.vatTotal = Number(_paymentType_5.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                  } else {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    _paymentType_6.date = _date;
                    _paymentType_6.dateBE = _dateBE;
                    _paymentType_6.dateCE = _dateCE;
                    _paymentType_6.remark = income['ประเภทเงินได้'];
                    _paymentType_6.amountTotal = Number(_paymentType_6.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                    _paymentType_6.vatTotal = Number(_paymentType_6.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                  }
                });
              } else if (selectedItems[i].pnd === 'PND3') {
                if (selectedItems[i]['คำนำหน้าชื่อ']) _customer.name = _customer.name + selectedItems[i]['คำนำหน้าชื่อ'];
                if (selectedItems[i]['ชื่อ']) _customer.name = _customer.name + ' ' + selectedItems[i]['ชื่อ'];
                if (selectedItems[i]['ชื่อกลาง']) _customer.name = _customer.name + ' ' + selectedItems[i]['ชื่อกลาง'];
                if (selectedItems[i]['ชื่อสกุล']) _customer.name = _customer.name + ' ' + selectedItems[i]['ชื่อสกุล'];

                selectedItems[i].incomes.forEach((income) => {
                  _total = _total + Number(income['จำนวนเงินที่จ่าย']);

                  if (
                    income['ประเภทเงินได้'].includes('เงินเดือน') ||
                    income['ประเภทเงินได้'].includes('ค่าจ้าง') ||
                    income['ประเภทเงินได้'].includes('เบี้ยเลี้ยง') ||
                    income['ประเภทเงินได้'].includes('โบนัส')
                  ) {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    _paymentType_1.date = _date;
                    _paymentType_1.dateBE = _dateBE;
                    _paymentType_1.dateCE = _dateCE;
                    _paymentType_1.amountTotal = Number(_paymentType_1.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                    _paymentType_1.vatTotal = Number(_paymentType_1.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                  } else if (income['ประเภทเงินได้'].includes('ค่าธรรมเนียม') || income['ประเภทเงินได้'].includes('ค่านายหน้า')) {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    _paymentType_2.date = _date;
                    _paymentType_2.dateBE = _dateBE;
                    _paymentType_2.dateCE = _dateCE;
                    _paymentType_2.amountTotal = Number(_paymentType_2.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                    _paymentType_2.vatTotal = Number(_paymentType_2.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                  } else if (income['ประเภทเงินได้'].includes('ค่าแห่งลิขสิทธิ์')) {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    _paymentType_3.date = _date;
                    _paymentType_3.dateBE = _dateBE;
                    _paymentType_3.dateCE = _dateCE;
                    _paymentType_3.amountTotal = Number(_paymentType_3.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                    _paymentType_3.vatTotal = Number(_paymentType_3.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                  } else if (
                    income['ประเภทเงินได้'].includes('รางวัล') ||
                    income['ประเภทเงินได้'].includes('ส่วนลด') ||
                    income['ประเภทเงินได้'].includes('การแข่งขัน') ||
                    income['ประเภทเงินได้'].includes('การชิงโชค') ||
                    income['ประเภทเงินได้'].includes('ค่าแสดง') ||
                    income['ประเภทเงินได้'].includes('ค่าจ้าง') ||
                    income['ประเภทเงินได้'].includes('ค่าโฆษณา') ||
                    income['ประเภทเงินได้'].includes('ค่าเช่า') ||
                    income['ประเภทเงินได้'].includes('ค่าขนส่ง') ||
                    income['ประเภทเงินได้'].includes('ค่าบริการ') ||
                    income['ประเภทเงินได้'].includes('ค่าเบี้ยประกันวินาศภัย')
                  ) {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    _paymentType_5.date = _date;
                    _paymentType_5.dateBE = _dateBE;
                    _paymentType_5.dateCE = _dateCE;
                    _paymentType_5.amountTotal = Number(_paymentType_5.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                    _paymentType_5.vatTotal = Number(_paymentType_5.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                  } else {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    _paymentType_6.date = _date;
                    _paymentType_6.dateBE = _dateBE;
                    _paymentType_6.dateCE = _dateCE;
                    _paymentType_6.remark = income['ประเภทเงินได้'];
                    _paymentType_6.amountTotal = Number(_paymentType_6.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                    _paymentType_6.vatTotal = Number(_paymentType_6.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                  }
                });
              } else if (selectedItems[i].pnd === 'PND2') {
                if (selectedItems[i]['คำนำหน้าชื่อ']) _customer.name = _customer.name + selectedItems[i]['คำนำหน้าชื่อ'];
                if (selectedItems[i]['ชื่อ']) _customer.name = _customer.name + ' ' + selectedItems[i]['ชื่อ'];
                if (selectedItems[i]['ชื่อกลาง']) _customer.name = _customer.name + ' ' + selectedItems[i]['ชื่อกลาง'];
                if (selectedItems[i]['ชื่อสกุล']) _customer.name = _customer.name + ' ' + selectedItems[i]['ชื่อสกุล'];

                selectedItems[i].incomes.forEach((income) => {
                  _total = _total + Number(income['จำนวนเงินที่จ่าย']);

                  if (income['เงินได้ตามมาตรา'].includes('403N')) {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    _paymentType_3.date = _date;
                    _paymentType_3.dateBE = _dateBE;
                    _paymentType_3.dateCE = _dateCE;
                    _paymentType_3.amountTotal = Number(_paymentType_3.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                    _paymentType_3.vatTotal = Number(_paymentType_3.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                  } else if (income['เงินได้ตามมาตรา'].includes('404A')) {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    _paymentType_4_A.date = _date;
                    _paymentType_4_A.dateBE = _dateBE;
                    _paymentType_4_A.dateCE = _dateCE;
                    _paymentType_4_A.amountTotal = Number(_paymentType_4_A.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                    _paymentType_4_A.vatTotal = Number(_paymentType_4_A.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                  } else if (
                    income['เงินได้ตามมาตรา'].includes('404B') ||
                    income['เงินได้ตามมาตรา'].includes('404G') ||
                    income['เงินได้ตามมาตรา'].includes('404T')
                  ) {
                    const _dateSplit = income['วันเดือนปีที่จ่าย'].split('/');

                    let _date = '';
                    let _dateBE = '';
                    let _dateCE = '';

                    if (Number(_dateSplit[2]) < 2100) {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                    } else {
                      _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                      _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                      _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
                    }

                    _paymentType_4_B_1_4.date = _date;
                    _paymentType_4_B_1_4.dateBE = _dateBE;
                    _paymentType_4_B_1_4.dateCE = _dateCE;
                    _paymentType_4_B_1_4.percentVat = Number(income['อัตราภาษี']);
                    _paymentType_4_B_1_4.amountTotal = Number(_paymentType_4_B_1_4.amountTotal) + Number(income['จำนวนเงินที่จ่าย']);
                    _paymentType_4_B_1_4.vatTotal = Number(_paymentType_4_B_1_4.vatTotal) + Number(income['จำนวนเงินภาษีที่หัก']);
                  }
                });
              }

              let _totalVat = 0;
              selectedItems[i].incomes.forEach((income) => {
                _totalVat = _totalVat + Number(income['จำนวนเงินภาษีที่หัก']);
              });

              let _vatTotalText = numberToTextTh(_totalVat);

              let _paymentAmount = {
                privateTeacherAidFund: '',
                providentFund: '',
                socialFund: '',
              };

              let _payment_type_1 = false;
              let _payment_type_2 = false;
              let _payment_type_3 = false;
              let _payment_type_4 = false;

              if (selectedItems[i].incomes[0]['เงื่อนไขการหัก'] === '1' || selectedItems[i].incomes[0]['เงื่อนไขการหัก'] === 1)
                _payment_type_1 = true;
              if (selectedItems[i].incomes[0]['เงื่อนไขการหัก'] === '2' || selectedItems[i].incomes[0]['เงื่อนไขการหัก'] === 2)
                _payment_type_2 = true;

              const _dateSplit = selectedItems[i].incomes[0]['วันเดือนปีที่จ่าย'].split('/');

              let _date = '';
              let _dateBE = '';
              let _dateCE = '';

              if (Number(_dateSplit[2]) < 2100) {
                _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + _dateSplit[2]).toISOString();
                _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) + 543);
                _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
              } else {
                _date = new Date(_dateSplit[1] + '/' + _dateSplit[0] + '/' + (Number(_dateSplit[2]) - 543)).toISOString();
                _dateBE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + _dateSplit[2];
                _dateCE = _dateSplit[0] + '/' + _dateSplit[1] + '/' + (Number(_dateSplit[2]) - 543);
              }

              const objToSent = {
                bookNumber: '',
                number: selectedItems[i]['เลขที่เอกสาร'],
                company: selectedItems[i].company,
                customer: _customer,
                orderNumber: '',
                formType_1: _formType_1,
                formType_2: _formType_2,
                formType_3: _formType_3,
                formType_4: _formType_4,
                formType_5: _formType_5,
                formType_6: _formType_6,
                formType_7: _formType_7,
                paymentType_1: _paymentType_1,
                paymentType_2: _paymentType_2,
                paymentType_3: _paymentType_3,
                paymentType_4_A: _paymentType_4_A,
                paymentType_4_B_1_1: _paymentType_4_B_1_1,
                paymentType_4_B_1_2: _paymentType_4_B_1_2,
                paymentType_4_B_1_3: _paymentType_4_B_1_3,
                paymentType_4_B_1_4: _paymentType_4_B_1_4,
                paymentType_4_B_2_1: _paymentType_4_B_2_1,
                paymentType_4_B_2_2: _paymentType_4_B_2_2,
                paymentType_4_B_2_3: _paymentType_4_B_2_3,
                paymentType_4_B_2_4: _paymentType_4_B_2_4,
                paymentType_4_B_2_5: _paymentType_4_B_2_5,
                paymentType_5: _paymentType_5,
                paymentType_6: _paymentType_6,
                amountTotal: _total,
                vatTotal: _totalVat,
                vatTotalText: _vatTotalText,
                paymentAmount: _paymentAmount,
                payment_type_1: _payment_type_1,
                payment_type_2: _payment_type_2,
                payment_type_3: _payment_type_3,
                payment_type_4: _payment_type_4,
                otherPayment: '',
                date: _date,
                dateBE: _dateBE,
                dateCE: _dateCE,
                signature: '',
                seal: '',
                referExpenseNote: {},
              };

              // console.log('objToSent', objToSent);

              const promise_PDF_XML = await new Promise(async (resolve) => {
                await axios
                  .post(API_URL + '/documents/withholdingtaxs/pdf', objToSent, {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                    },
                  })
                  .then(
                    async (response) => {
                      console.log('Create document response: ', response);

                      let count = 0;

                      let interval = setInterval(async () => {
                        await axios
                          .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                            headers: {
                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                              'Content-Type': 'application/json',
                            },
                          })
                          .then((jobsResponse) => {
                            count += 1;
                            console.log('Jobs response: ', j, jobsResponse.data.status, count);

                            if (jobsResponse.data.status === 'complete') {
                              _items[j].status = 'successful';

                              setItems([..._items]);

                              clearInterval(interval);

                              resolve(true);
                            } else if (count === 60) {
                              _items[j].status = 'fail';

                              setItems([..._items]);

                              clearInterval(interval);

                              resolve(false);
                            }
                          });
                      }, 1000);
                    },
                    (error) => {
                      console.log('Error Create document response: ', error);
                      _items[j].status = 'fail';
                      setItems([..._items]);
                      resolve(false);
                    }
                  );
              }); //promise

              Promise.all([promise_PDF_XML]).then((value) => {
                console.log(i, value);
              });
            } //if
          } //for j
        } //for i
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
      });

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  useEffect(() => {
    for (const obj of items) {
      if (obj.status === 'ไม่สำเร็จ') {
        setInComplete(true);

        for (const obj of items) {
          if (obj.status === '') {
            obj.status = '-';
          }
        }
      }
    }
  }, [items]);

  return (
    <Stack>
      {!url ? (
        <>
          {isloadingPdf ? (
            <Stack>
              <Stack horizontal horizontalAlign="center">
                <br />
                <br />
                <div className="certificates-loading-center">
                  <Spinner label="กำลังดำเนินการ..." labelPosition="down" styles={spinnerStyles} />
                </div>
                <br />
              </Stack>
            </Stack>
          ) : (
            <>
              {inComplete ? (
                <Stack horizontal horizontalAlign="center">
                  <h1>{t('whtDropzoneUploadList.tryAgain')}</h1>
                </Stack>
              ) : (
                <Stack horizontal horizontalAlign="center">
                  <h1>{t('whtDropzoneUploadList.header')}</h1>
                </Stack>
              )}

              <Stack styles={{ root: { marginBottom: 10 } }}>
                {selectedItems.length > 0 && !isCreateDocument && (
                  <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                    <PrimaryButton
                      text={t('whtDropzoneUploadList.createDoc')}
                      iconProps={{ iconName: 'Add' }}
                      onClick={async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments();
                      }}
                    />
                    <DefaultButton
                      text={t('whtDropzoneUploadList.cancel')}
                      style={{ marginLeft: '10px' }}
                      onClick={() => {
                        selection.setAllSelected(false);
                      }}
                    />
                    <br />
                  </Stack>
                )}

                {isCreateDocument && (
                  <div>
                    <Spinner label={t('whtDropzoneUploadList.signProcessing')} size={SpinnerSize.large} />
                  </div>
                )}

                {isCreateDocumentComplete && (
                  <center>
                    <PrimaryButton
                      text={t('whtDropzoneUploadList.viewAll')}
                      onClick={async () => {
                        history.push({
                          pathname: '/etax/documents/all',
                          state: {},
                        });
                      }}
                    />
                  </center>
                )}
              </Stack>

              <Stack
                vertical
                style={{
                  width: '98%',
                  minWidth: '1200px',
                  marginLeft: '10px',
                  maxWidth: '1350px',
                }}
              >
                <DetailsList
                  items={items || []}
                  compact={isCompactMode}
                  columns={columns}
                  selection={selection}
                  selectionMode={isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  checkboxVisibility={CheckboxVisibility.always}
                  selectionPreservedOnEmptyClick={true}
                />
              </Stack>
            </>
          )}
        </>
      ) : (
        <>
          <Stack
            horizontal
            horizontalAlign="center"
            tokens={{ childrenGap: 25 }}
            style={{
              paddingTop: '10px',
              paddingBottom: '10px',
              background: '#4D4D4D',
              width: 'calc(100vw - 303px)',
              minWidth: '1113px',
            }}
          >
            <button style={{ width: '90px', height: '30px' }} onClick={() => setUrl('')}>
              กลับ
            </button>
          </Stack>
          <Stack horizontal horizontalAlign="center">
            <PdfAllPages pdf={url} />
          </Stack>
        </>
      )}
    </Stack>
  );
}

export default WithholdingTaxUploadList;
