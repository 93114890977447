import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';
import { useTranslation } from 'react-i18next';

import { DetailsListLayoutMode, ShimmeredDetailsList, SelectionMode, Stack, PrimaryButton, TextField } from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const RdEtax = () => {
  console.log('Home render!!!');

  const { t } = useTranslation();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const documents = [];
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const getNewDocuments = true;

  const pageData = {};
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const [taxId, setTaxId] = useState('');
  const [isClick, setIsClick] = useState(false);

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);

    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year}`;
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');
  }, [getNewDocuments]);

  useEffect(() => {
    if (documents.length > 0) {
      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {}
      console.log('Docs data', _docs);

      setItems(_docs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const columns = [
    {
      key: 'column1',
      name: t('rdEtax.taxId'),
      fieldName: 'taxId',
      minWidth: 80,
      maxWidth: 150,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.key}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('rdEtax.name'),
      fieldName: 'name',
      minWidth: 150,
      maxWidth: 250,
      data: 'string',
      onRender: (item) => {
        return <span>{item.name}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('rdEtax.taxInvoice'),
      fieldName: 'taxInvoice',
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      isMultiline: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.taxInvoice}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('rdEtax.receipt'),
      fieldName: 'receipt',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      isMultiline: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.receipt}</span>;
      },
    },
    {
      key: 'column5',
      name: t('rdEtax.registerDate'),
      fieldName: 'registerDate',
      minWidth: 70,
      maxWidth: 80,
      isResizable: true,
      onColumnClick: (item) => {
        console.log('Sort vatTotal!');
      },
      data: 'string',
      onRender: (item) => {
        return <span style={{ display: 'block', textAlign: 'left' }}>{item.registerDate}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: t('rdEtax.endDate'),
      fieldName: 'endDate',
      minWidth: 70,
      maxWidth: 80,
      isResizable: true,
      onColumnClick: (item) => {},
      data: 'string',
      onRender: (item) => {
        return <span style={{ display: 'block', textAlign: 'left' }}>{item.endDate}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: t('rdEtax.remark'),
      fieldName: 'remark',
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return <span style={{ display: 'block', textAlign: 'left' }}>{item.remark}</span>;
      },
      isPadded: true,
    },
  ];

  const isCompactMode = false;

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('taxId: ', taxId);

    setIsClick(true);
    setLoadDataComplete(false);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(() => {
        axios
          .post(
            'https://etaxws.rd.go.th/etaxapi/services/checkRegstration',
            {
              taxNo: taxId,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(
            (response) => {
              console.log(response);

              let _docs = [];
              let _data = response.data;
              let _registerDate = '';
              let _endDate = '-';

              if (_data.status && _data.data.taxNo) {
                console.log('e-Tax data: ', _data);

                _registerDate = toThaiDateString(new Date(_data.data.registerDatetime).toISOString());

                if (_data.data.endDatetime) {
                  _endDate = toThaiDateString(new Date(_data.data.endDatetime).toISOString());
                }

                _docs.push({
                  key: _data.data.taxNo,
                  name: _data.data.taxName,
                  taxInvoice: _data.data.documentType.indexOf('ใบกำกับภาษี') !== -1 ? 'Yes' : '-',
                  receipt: _data.data.documentType.indexOf('รับ') !== -1 ? 'Yes' : '-',
                  registerDate: _registerDate,
                  endDate: _endDate,
                  remark: _data.data.remark || '-',
                });

                console.log('_docs: ', _docs);
              }
              setItems(_docs);
              setLoadDataComplete(true);
            },
            (error) => {
              console.log(error);
              setLoadDataComplete(true);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
        setLoadDataComplete(true);
      });
  };

  return (
    <div style={homeStyles}>
      <Stack>
        <center>
          <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('rdEtax.header')}</h2>
          <h3>
            {t('rdEtax.subHeader')} {toThaiDateString(new Date())}
          </h3>
          <h4>{t('rdEtax.subHeader2')}</h4>
        </center>

        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}></Stack>
      </Stack>

      <br />

      <form onSubmit={handleSubmit}>
        <Stack vertical>
          <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 15 }}>
            <TextField
              name="taxId"
              label={t('rdEtax.taxId2')}
              required
              styles={{ root: { width: 310 } }}
              onChange={(e) => {
                setTaxId(e.target.value);
              }}
              value={taxId}
            />
            <PrimaryButton
              iconProps={{ iconName: 'Search' }}
              name="search"
              text={t('rdEtax.search')}
              style={{ marginTop: '29px' }}
              type="submit"
              value="search"
            />
          </Stack>
        </Stack>
      </form>

      {!isClick && (
        <Stack>
          <br />
          <br />
          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                maxWidth: '800px',
              }}
            >
              <p>{t('rdEtax.footer1')}</p>
              <p>{t('rdEtax.footer2')}</p>
            </Stack>
          </Stack>
        </Stack>
      )}

      <br />
      <br />

      {isClick && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            vertical
            style={{
              width: '98%',
              maxWidth: '1350px',
            }}
          >
            <ShimmeredDetailsList
              items={items || []}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
            />
          </Stack>
        </Stack>
      )}

      <br />
      <center>
        {!(documents.length === 0 || documents.length < pageSize) && loadDataComplete && (
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={pageData.totalPages}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
          />
        )}

        <br />
        <br />
      </center>
    </div>
  );
};

export default RdEtax;
