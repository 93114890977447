export const percentWht = [
  { percentWhtCode: 0, percentWhtName: '0%' },
  { percentWhtCode: 0.5, percentWhtName: '0.50%' },
  { percentWhtCode: 0.75, percentWhtName: '0.75%' },
  { percentWhtCode: 1, percentWhtName: '1%' },
  { percentWhtCode: 2, percentWhtName: '2%' },
  { percentWhtCode: 3, percentWhtName: '3%' },
  { percentWhtCode: 5, percentWhtName: '5%' },
  { percentWhtCode: 10, percentWhtName: '10%' },
  { percentWhtCode: 15, percentWhtName: '15%' },
 
];

export const getPercentWhtList = () => {
  //console.log(district);
  //console.log('ProvinceCode:', provinceCode);
  //console.log(district.length);

  let list = [];
  //list.push({ key: '', text: 'กรุณาเลือก' });
  for (let i = 0; i < percentWht.length; i++) {

    list.push({ key: percentWht[i].percentWhtCode, text: percentWht[i].percentWhtName });

  }

  return list;

};

export const getPercentWht = (percentWhtCode) => {
  //console.log(district);
  //console.log('ProvinceCode:', provinceCode);
  //console.log(district.length);

  let list = {};
  //list.push({ key: '', text: 'กรุณาเลือก' });
  for (let i = 0; i < percentWht.length; i++) {
    if (percentWht[i].percentWhtCode === percentWhtCode) {

      list = { key: percentWht[i].percentWhtCode, text: percentWht[i].percentWhtName };

    }
  }

  return list;

  //let list = this.district.filter();
};

