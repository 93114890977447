import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  DatePicker,
  DayOfWeek,
  mergeStyleSets,
  PrimaryButton,
  DefaultButton,
  IconButton,
  MessageBar,
  MessageBarType,
  Dropdown,
  Stack,
  getTheme,
  Shimmer,
  Toggle,
  Separator,
  Spinner,
  ComboBox,
} from '@fluentui/react';
import { useLocation, useHistory } from 'react-router-dom';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { getProvinceList, getDistrictList, getSubDistrictList, getProvince, getDistrict, getSubDistrict } from '../../../providers/AreaData';
import { getUnit, getUnitList } from '../../../providers/UnitData';
import { getCountry, getCountryList } from '../../../providers/CountryData';
import { getWhtIncomeList } from '../../../providers/WhtIncomeData';
import { getPercentWhtList } from '../../../providers/PercentWhtData';
import { numberToTextEn, numberToTextTh } from '../../../helpers/BahtWord';

const API_URL = process.env.REACT_APP_API_URL;

const contactListStyle = {
  background: 'white',
  border: '1px solid #eee',
  boxShadow: '0 2px 3px #ccc',
  boxSizing: 'border-box',
  maxHeight: 200,
  width: 310,
  position: 'absolute',
  overflow: 'auto',
  zIndex: 100000000,
};

const contactOptionStyle = {
  padding: '0px 8px',
  paddingTop: '8px',
  height: 26,
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

const theme = getTheme();

const onFormatDate = (date, format) => {
  if (format === 'CE') {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
  }
};

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '300px',
  },
  hover: {
    selectors: {
      '&:hover': { background: theme.palette.neutralLight },
    },
  },
});

const dropdownStyles = {
  dropdown: { width: 150 },
};

const ExpenseNote = () => {
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation();

  const [data] = useState(location.state);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  console.log('Render');

  const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyTaxId, setCompanyTaxId] = useState('');
  const [companyBranchText, setCompanyBranchText] = useState('');
  const [companyAllAddress, setCompanyAllAddress] = useState('');

  //Customer Detail
  const [name, setName] = useState('');
  const [buildingNumber, setBuildingNumber] = useState('');
  const [address, setAddress] = useState('');
  const [streetPrefix, setStreetPrefix] = useState('');
  const [streetName, setStreetName] = useState('');
  const [provincePrefix, setProvincePrefix] = useState('');
  const [province, setProvince] = useState({ key: '', text: '' });
  const [subDistrictPrefix, setSubDistrictPrefix] = useState('');
  const [subDistrict, setSubDistrict] = useState({ key: '', text: '' });
  const [subDistrictList, setSubDistrictList] = useState([]);
  const [districtPrefix, setDistrictPrefix] = useState('');
  const [district, setDistrict] = useState({ key: '', text: '' });
  const [districtList, setDistrictList] = useState([]);
  const [postcode, setPostCode] = useState('');
  const [phone, setPhone] = useState('');
  const [taxNumberType, setTaxNumberType] = useState('');
  const [taxId, setTaxId] = useState('');
  const [branchText, setBranchText] = useState('');
  const [branchDisplay, setBranchDisplay] = useState([
    { key: '00000', text: 'สำนักงานใหญ่' },
    { key: 'branchNumber', text: 'สาขาเลขที่' },
  ]);

  const [branch, setBranch] = useState('00000');
  const [branchNumber, setBranchNumber] = useState('00000');
  const [email, setEmail] = useState('');

  const [number, setNumber] = useState('');
  const [date, setDate] = useState(new Date().toISOString());
  const [dateBE, setDateBE] = useState(onFormatDate(new Date(), 'BE'));
  const [dateCE, setDateCE] = useState(onFormatDate(new Date(), 'CE'));
  const [note, setNote] = useState('');
  const [receivedBy, setReceivedBy] = useState('');

  const [showTaxId, setShowTaxId] = useState(true);
  const [showBranch, setShowBranch] = useState(false);
  const [showBranchNumber, setShowBranchNumber] = useState(false);

  const [amountTotal, setAmountTotal] = useState(0);

  // VAT type
  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  const nonVatText = '-';

  const [vatPriceTotal, setVatPriceTotal] = useState(0);
  const [zeroVatPriceTotal, setZeroVatPriceTotal] = useState(0);
  const [nonVatPriceTotal, setNonVatPriceTotal] = useState(0);

  const [discountDisable, setDiscountDisable] = useState(false);

  const [vatTotal, setVatTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [grandTotalEN, setGrandTotalEN] = useState('');
  const [grandTotalTH, setGrandTotalTH] = useState('');

  const [whtTotal, setWhtTotal] = useState(0);

  const [language, setLanguage] = useState(sessionStorage.getItem('address-language') || 'th');
  const [structure, setStructure] = useState(true);
  const [lineOne, setLineOne] = useState('');
  const [lineTwo, setLineTwo] = useState('');

  const provinceList = getProvinceList(language);
  const unitList = getUnitList(language);
  const countryList = getCountryList(language);
  const [whtIncomeList, setWhtIncomeList] = useState(getWhtIncomeList(language));
  const percentWhtList = getPercentWhtList();

  const [firstDayOfWeek] = React.useState(DayOfWeek.Sunday);

  const [items, setItems] = useState([]);

  const [includeVat, setIncludeVat] = useState(false);
  const [discount, setDiscount] = useState(0);

  const [backUrl, setBackUrl] = useState('/expenses/all');

  //Style
  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  //Error Message
  const [provinceErrMsg, setProvinceErrMsg] = useState('');
  const [districtErrMsg, setDistrictErrMsg] = useState('');
  const [subDistrictErrMsg, setSubDistrictErrMsg] = useState('');

  const [taxNumberTypeErrMsg, setTaxNumberTypeErrMsg] = useState('');

  const [contactSuggestion, setContactSuggestion] = useState();
  const [contactList, setContactList] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showSuggestionsTaxId, setShowSuggestionsTaxId] = useState(false);

  const dateRef = useRef();

  const DropdownErrMsg = t('expenseNote.dropdownErrMsg');

  const phoneRegEx = /(^0[1-9][0-9]{1,13}$|^$)/g;
  let phoneRef = useRef(null);

  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [reference, setReference] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [dueDateBE, setDueDateBE] = useState('');
  const [dueDateCE, setDueDateCE] = useState('');
  const dueDateRef = useRef();

  const [taxInvoiceNumber, setTaxInvoiceNumber] = useState('');
  const [taxInvoiceDate, setTaxInvoiceDate] = useState('');
  const [taxInvoiceDateBE, setTaxInvoiceDateBE] = useState('');
  const [taxInvoiceDateCE, setTaxInvoiceDateCE] = useState('');
  const taxInvoiceDateRef = useRef();

  const [countryCode, setCountryCode] = useState('TH');
  const [countryName, setCountryName] = useState('ไทย');

  const companyId = sessionStorage.getItem('companyId') || '';

  const DayPickerStrings = {
    months: [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ],

    shortMonths: [
      t('documents.shortMonths01'),
      t('documents.shortMonths02'),
      t('documents.shortMonths03'),
      t('documents.shortMonths04'),
      t('documents.shortMonths05'),
      t('documents.shortMonths06'),
      t('documents.shortMonths07'),
      t('documents.shortMonths08'),
      t('documents.shortMonths09'),
      t('documents.shortMonths10'),
      t('documents.shortMonths11'),
      t('documents.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('expenseNote.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('expenseNote.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  const [updateSuccess, setUpdateSuccess] = useState(false);

  //Payment
  const [payment, setPayment] = useState(false);
  const [bankList, setBankList] = useState([
    { key: 'KBANK', text: 'ธนาคารกสิกรไทย' },
    { key: 'SCB', text: 'ธนาคารไทยพาณิชย์' },
    { key: 'KTB', text: 'ธนาคารกรุงไทย' },
    { key: 'TTB', text: 'ธนาคารทีทีบี' },
    { key: 'BBL', text: 'ธนาคารกรุงเทพ' },
    { key: 'BAY', text: 'ธนาคารกรุงศรีอยุธยา' },
    { key: 'UOB', text: 'ธนาคารยูโอบี' },
  ]);
  const [accountTypeList, setAccountTypeList] = useState([
    { key: 'SAVING', text: 'ออมทรัพย์' },
    { key: 'CURRENT', text: 'กระแสรายวัน' },
  ]);

  const [paymentList, setPaymentList] = useState([
    {
      paymentCode: 'BANK',
      paymentName: 'ธนาคาร',
      date: new Date().toISOString(),
      dateBE: onFormatDate(new Date(), 'BE'),
      dateCE: onFormatDate(new Date(), 'CE'),
      bankCode: '',
      bankName: '',
      typeCode: '',
      typeName: '',
      accountName: '',
      accountNumber: '',
      paidAmount: 0,
      paymentNote: '',
    },
  ]);

  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        //Check documents spending limit
        axios
          .get(API_URL + '/subscriptions', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log('Subscriptions data: ', response);

              if (response.data) {
                if (response.data && response.data.documents) {
                  console.log('Subscription etax documents: ', response.data.documents);

                  let _subscriptionDocuments = response.data.documents;

                  if (_subscriptionDocuments.spent + 2 > _subscriptionDocuments.limit + _subscriptionDocuments.topup) {
                    history.push({
                      pathname: '/etax/billings',
                      state: {},
                    });
                  }
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );

        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                  
                  if (_company.includeVat && !data) {
                    setIncludeVat(_company.includeVat);
                  } else {
                    _company.includeVat = false;
                  }
  
                  setCompany(_company);
  
                  setCompanyName(_company.name);
                  setCompanyPhone(_company.phone);
                  setCompanyEmail(_company.email);
                  setCompanyTaxId(_company.taxId);
                  setCompanyBranchText(_company.branchText);
                } else {
                  if (response.data[0].includeVat && !data) {
                    setIncludeVat(response.data[0].includeVat);
                  } else {
                    response.data[0].includeVat = false;
                  }
  
                  setCompany(response.data[0]);
  
                  setCompanyName(response.data[0].name);
                  setCompanyPhone(response.data[0].phone);
                  setCompanyEmail(response.data[0].email);
                  setCompanyTaxId(response.data[0].taxId);
                  setCompanyBranchText(response.data[0].branchText);
                }

                
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('userEffect retrieve contact data!');

    async function getContact() {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/contacts', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            })
            .then((response) => {
              console.log(response.data);
              setContactList(response.data);
            });
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    getContact();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Company state: ', company);

    if (company.taxId) {
      console.log('Company useEffect call!');
      console.log(company);
      console.log(company.id);

      setCompanyAllAddress(
        company.buildingNumber +
          ' ' +
          (!/^-*$/.test(company.address) && company.address.trim() && !/^-*$/.test(company.address.trim()) ? company.address : '') +
          ' ' +
          (!/^-*$/.test(company.streetName) && company.streetName.trim() && !/^-*$/.test(company.streetName.trim())
            ? company.streetPrefix + company.streetName
            : '') +
          ' ' +
          company.subDistrictPrefix +
          company.subDistrictName +
          ' ' +
          company.districtPrefix +
          company.districtName +
          ' ' +
          company.provincePrefix +
          company.provinceName +
          ' ' +
          company.postcode
      );
    }
  }, [company]);

  useEffect(() => {
    console.log('Language Effect: ', language);

    console.log('Province: ', province);

    if (language != null) {
      setProvince(getProvince(province.key, language));
      setDistrict(getDistrict(district.key, language));
      setDistrictList(getDistrictList(province.key, language));
      setSubDistrict(getSubDistrict(subDistrict.key, language));
      setSubDistrictList(getSubDistrictList(district.key, language));
      setCountryName(getCountry(countryCode, language).text);

      handleBranch(branchNumber, language);

      setRoadPrefix(streetName);
      setAreaPrefix(province.key);

      const values = [...items];

      values.forEach((value) => {
        value.unitCode = getUnit(value.unitCode, language).key;
        value.unitName = getUnit(value.unitCode, language).text;
      });

      setItems(values);

      if (language === 'en') {
        setBankList([
          { key: 'KBANK', text: 'Kasikorn Bank' },
          { key: 'SCB', text: 'Siam Commercial Bank' },
          { key: 'KTB', text: 'Krung Thai Bank' },
          { key: 'TTB', text: 'TTB Bank' },
          { key: 'BBL', text: 'Bangkok Bank' },
          { key: 'BAY', text: 'Bank of Ayudhya' },
          { key: 'UOB', text: 'UOB Bank' },
        ]);
        setAccountTypeList([
          { key: 'SAVING', text: 'Saving' },
          { key: 'CURRENT', text: 'Current' },
        ]);

        paymentList.forEach((bank, index) => {
          switch (bank.bankCode) {
            case 'KBANK':
              paymentList[index]['bankName'] = 'Kasikorn Bank';
              break;
            case 'SCB':
              paymentList[index]['bankName'] = 'Siam Commercial Bank';
              break;
            case 'KTB':
              paymentList[index]['bankName'] = 'Krung Thai Bank';
              break;
            case 'TTB':
              paymentList[index]['bankName'] = 'TTB Bank';
              break;
            case 'BBL':
              paymentList[index]['bankName'] = 'Bangkok Bank';
              break;
            case 'BAY':
              paymentList[index]['bankName'] = 'Bank of Ayudhya';
              break;
            case 'UOB':
              paymentList[index]['bankName'] = 'UOB Bank';
              break;
            // no default
          }
        });

        paymentList.forEach((bank, index) => {
          switch (bank.paymentCode) {
            case 'BANK':
              paymentList[index]['paymentName'] = 'Bank';
              break;
            case 'CASH':
              paymentList[index]['paymentName'] = 'Cash';
              break;
            // no default
          }
        });

        paymentList.forEach((bank, index) => {
          switch (bank.typeCode) {
            case 'SAVING':
              paymentList[index]['typeName'] = 'Saving';
              break;
            case 'CURRENT':
              paymentList[index]['typeName'] = 'Current';
              break;
            // no default
          }
        });
      } else {
        setBankList([
          { key: 'KBANK', text: 'ธนาคารกสิกรไทย' },
          { key: 'SCB', text: 'ธนาคารไทยพาณิชย์' },
          { key: 'KTB', text: 'ธนาคารกรุงไทย' },
          { key: 'TTB', text: 'ธนาคารทีทีบี' },
          { key: 'BBL', text: 'ธนาคารกรุงเทพ' },
          { key: 'BAY', text: 'ธนาคารกรุงศรีอยุธยา' },
          { key: 'UOB', text: 'ธนาคารยูโอบี' },
        ]);
        setAccountTypeList([
          { key: 'SAVING', text: 'ออมทรัพย์' },
          { key: 'CURRENT', text: 'กระแสรายวัน' },
        ]);

        paymentList.forEach((bank, index) => {
          switch (bank.bankCode) {
            case 'KBANK':
              paymentList[index]['bankName'] = 'ธนาคารกสิกรไทย';
              break;
            case 'SCB':
              paymentList[index]['bankName'] = 'ธนาคารไทยพาณิชย์';
              break;
            case 'KTB':
              paymentList[index]['bankName'] = 'ธนาคารกรุงไทย';
              break;
            case 'TTB':
              paymentList[index]['bankName'] = 'ธนาคารทีทีบี';
              break;
            case 'BBL':
              paymentList[index]['bankName'] = 'ธนาคารกรุงเทพ';
              break;
            case 'BAY':
              paymentList[index]['bankName'] = 'ธนาคารกรุงศรีอยุธยา';
              break;
            case 'UOB':
              paymentList[index]['bankName'] = 'ธนาคารยูโอบี';
              break;
            // no default
          }
        });

        paymentList.forEach((bank, index) => {
          switch (bank.paymentCode) {
            case 'BANK':
              paymentList[index]['paymentName'] = 'ธนาคาร';
              break;
            case 'CASH':
              paymentList[index]['paymentName'] = 'เงินสด';
              break;
            // no default
          }
        });

        paymentList.forEach((bank, index) => {
          switch (bank.typeCode) {
            case 'SAVING':
              paymentList[index]['typeName'] = 'ออมทรัพย์';
              break;
            case 'CURRENT':
              paymentList[index]['typeName'] = 'กระแสรายวัน';
              break;
            // no default
          }
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    console.log('useEffect data call!: ', data);

    if (data !== undefined) {
      if (data.command === 'Contact') {
        setName(data.customer.name);

        setPostCode(data.customer.postcode);
        setPhone(data.customer.phone);
        setTaxNumberType(data.customer.taxNumberType);
        setTaxId(data.customer.taxId);
        setBranchText(data.customer.branchText);
        setBranchNumber(data.customer.branchNumber);

        let _language = '';

        if (data.customer.language != null) {
          setLanguage(data.customer.language);
          _language = data.customer.language;
        } else {
          _language = language;
        }

        if (data.customer.branchNumber === '00000' && !(data.customer.branchText.includes('สาขา') || data.customer.branchText.includes('Branch'))) {
          setBranch('00000');
          setShowBranchNumber(false);
        } else if (
          data.customer.branchNumber.length === 5 &&
          (data.customer.branchText.includes('สาขา') || data.customer.branchText.includes('Branch'))
        ) {
          setBranch('branchNumber');
          setShowBranchNumber(true);
        }

        setEmail(data.customer.email);
        setContactSuggestion(data.contactSuggestion);

        if (data.customer.taxNumberType === 'TXID') {
          setShowBranch(true);
        } else {
          setShowBranch(false);
        }

        if (data.customer.taxNumberType === 'OTHR') {
          setShowTaxId(false);
        } else {
          setShowTaxId(true);
        }

        setStructure(!data.customer.unstructure);

        if (data.customer.unstructure) {
          setLineOne(data.customer.addressLineOne);
          setLineTwo(data.customer.addressLineTwo);

          setCountryCode(data.customer.countryCode);
          setCountryName(data.customer.countryName);
        } else {
          setBuildingNumber(data.customer.buildingNumber);
          setAddress(data.customer.address);
          setStreetPrefix(data.customer.streetPrefix);
          setStreetName(data.customer.streetName);
          setProvincePrefix(data.customer.provincePrefix);
          setProvince({
            key: data.customer.provinceCode,
            text: data.customer.provinceName,
          });

          setSubDistrictList(getSubDistrictList(data.customer.districtCode, _language));
          setSubDistrictPrefix(data.customer.subDistrictPrefix);
          setSubDistrict({
            key: data.customer.subDistrictCode,
            text: data.customer.subDistrictName,
          });

          setDistrictList(getDistrictList(data.customer.provinceCode, _language));
          setDistrictPrefix(data.customer.districtPrefix);
          setDistrict({
            key: data.customer.districtCode,
            text: data.customer.districtName,
          });
        }

        setItems([
          {
            number: '',
            description: '',
            quantity: '',
            unitCode: '',
            unitName: '-',
            price: '',
            discount: 0,
            percentVat: percentVat,
            percentVatText: percentVatText,
            total: 0,
            whtIncomeCode: '',
            whtIncomeName: '-',
            percentWht: 0,
            whtTotal: 0,
          },
        ]);

        setBackUrl(data.backUrl);
      } else if (data.command === 'editFromDocument') {
        setNumber(data.number);

        if (data.date) {
          setDate(data.date);
          setDateBE(data.dateBE);
          setDateCE(data.dateCE);
        }

        //Company
        setCompany(data.company);
        setCompanyName(data.company.name);
        setCompanyBranchText(data.company.branchText);
        setCompanyPhone(data.company.phone);
        setCompanyEmail(data.company.email);
        setCompanyTaxId(data.company.taxId);

        //Customer
        setName(data.customer.name);

        setPostCode(data.customer.postcode);
        setPhone(data.customer.phone);
        setTaxNumberType(data.customer.taxNumberType);
        setTaxId(data.customer.taxId);
        setBranchText(data.customer.branchText);
        setBranchNumber(data.customer.branchNumber);

        setCountryCode(data.customer.countryCode || 'TH');
        setCountryName(data.customer.countryName || 'ไทย');

        if (data.customer.branchNumber === '00000' && !(data.customer.branchText.includes('สาขา') || data.customer.branchText.includes('Branch'))) {
          setBranch('00000');
          setShowBranchNumber(false);
        } else if (
          data.customer.branchNumber.length === 5 &&
          (data.customer.branchText.includes('สาขา') || data.customer.branchText.includes('Branch'))
        ) {
          setBranch('branchNumber');
          setShowBranchNumber(true);
        }

        setEmail(data.customer.email);

        if (data.contactSuggestion) {
          setContactSuggestion(data.contactSuggestion);
        } else {
          async function getContactSuggestion() {
            instance
              .acquireTokenSilent({
                ...silentRequest,
                account: account,
              })
              .then((tokenResponse) => {
                axios
                  .get(API_URL + '/contacts', {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId
                    },
                  })
                  .then((response) => {
                    let suggestion = response.data.filter((contact) => contact.taxId === data.customer.taxId);

                    setContactSuggestion(suggestion[0]);
                  });
              })
              .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
              });
          }

          getContactSuggestion();
        }

        if (data.customer.taxNumberType === 'TXID') {
          setShowBranch(true);
        } else {
          setShowBranch(false);
        }

        if (data.customer.taxNumberType === 'OTHR') {
          setShowTaxId(false);
        } else {
          setShowTaxId(true);
        }

        setLanguage(data.customer.language);
        setStructure(!data.customer.unstructure);

        if (data.customer.unstructure) {
          setLineOne(data.customer.addressLineOne);
          setLineTwo(data.customer.addressLineTwo);
        } else {
          setBuildingNumber(data.customer.buildingNumber);
          setAddress(data.customer.address);
          setStreetPrefix(data.customer.streetPrefix);
          setStreetName(data.customer.streetName);
          setProvincePrefix(data.customer.provincePrefix);
          setProvince({
            key: data.customer.provinceCode,
            text: data.customer.provinceName,
          });

          setSubDistrictList(getSubDistrictList(data.customer.districtCode, data.customer.language));
          setSubDistrictPrefix(data.customer.subDistrictPrefix);
          setSubDistrict({
            key: data.customer.subDistrictCode,
            text: data.customer.subDistrictName,
          });

          setDistrictList(getDistrictList(data.customer.provinceCode, data.customer.language));
          setDistrictPrefix(data.customer.districtPrefix);
          setDistrict({
            key: data.customer.districtCode,
            text: data.customer.districtName,
          });
        }

        setDiscount(data.discount);

        let _items = [];
        let nonVatPrice = 0;
        let zeroVatPrice = 0;
        let vatPrice = 0;

        data.items.forEach((item, index) => {
          if (item.percentVat === nonVat) {
            nonVatPrice += item.total;
          } else if (item.percentVat === zeroVat) {
            zeroVatPrice += item.total;
          } else {
            vatPrice += item.total;
          }

          let _percentVat = '';
          let _percentVatText = '';

          if (item.percentVat === 0) {
            _percentVat = item.percentVat;
            _percentVatText = zeroVatText;
          } else if (item.percentVat === -1) {
            _percentVat = item.percentVat;
            _percentVatText = nonVatText;
          } else {
            _percentVat = percentVat;
            _percentVatText = percentVatText;
          }

          _items.push({
            number: item.number,
            description: item.description,
            quantity: item.quantity,
            unitCode: item.unitCode,
            unitName: item.unitName,
            price: item.price,
            discount: item.discount,
            percentVat: _percentVat,
            percentVatText: _percentVatText,
            total: item.total,
            whtIncomeCode: item.whtIncomeCode,
            whtIncomeName: item.whtIncomeName,
            percentWht: item.percentWht,
            whtTotal: item.whtTotal,
          });
        });

        setVatPriceTotal(vatPrice);
        setZeroVatPriceTotal(zeroVatPrice);
        setNonVatPriceTotal(nonVatPrice);

        setItems(_items);

        setAmountTotal(data.amountTotal);
        setVatTotal(data.vatTotal);
        setGrandTotal(data.grandTotal);

        setGrandTotalEN(data.grandTotalEN);
        setGrandTotalTH(data.grandTotalTH);

        setNote(data.note);
        setReceivedBy(data.receivedBy);

        //Cancel and Replace Doc

        if (data.customer.language === 'en') {
          setBranchDisplay([
            { key: '00000', text: 'Head office' },
            { key: 'branchNumber', text: 'Branch' },
          ]);
        } else {
          setBranchDisplay([
            { key: '00000', text: 'สำนักงานใหญ่' },
            { key: 'branchNumber', text: 'สาขาเลขที่' },
          ]);
        }

        if (data.backUrl) {
          setBackUrl(data.backUrl);
        }

        setIncludeVat(data.includeVat);

        setDueDate(data.dueDate);
        setDueDateBE(data.dueDateBE);
        setDueDateCE(data.dueDateCE);
        setReference(data.reference);

        setTaxInvoiceNumber(data.taxInvoice.number);
        setTaxInvoiceDate(data.taxInvoice.date);
        setTaxInvoiceDateBE(data.taxInvoice.dateBE);
        setTaxInvoiceDateCE(data.taxInvoice.dateCE);

        if (data.paymentList?.length) {
          if (data.paymentList[0].paymentCode) {
            setPayment(true);
            setPaymentList(data.paymentList);
          }
        }
      } else {
        setNumber(data.number);

        if (data.date) {
          setDate(data.date);
          setDateBE(data.dateBE);
          setDateCE(data.dateCE);
        }

        //Company
        setCompany(data.company);
        setCompanyName(data.company.name);
        setCompanyBranchText(data.company.branchText);
        setCompanyPhone(data.company.phone);
        setCompanyEmail(data.company.email);
        setCompanyTaxId(data.company.taxId);

        //Customer
        setName(data.customer.name);

        setPostCode(data.customer.postcode);
        setPhone(data.customer.phone);
        setTaxNumberType(data.customer.taxNumberType);
        setTaxId(data.customer.taxId);
        setBranchText(data.customer.branchText);
        setBranchNumber(data.customer.branchNumber);

        setCountryCode(data.customer.countryCode || 'TH');
        setCountryName(data.customer.countryName || 'ไทย');

        if (data.customer.branchNumber === '00000' && !(data.customer.branchText.includes('สาขา') || data.customer.branchText.includes('Branch'))) {
          setBranch('00000');
          setShowBranchNumber(false);
        } else if (
          data.customer.branchNumber.length === 5 &&
          (data.customer.branchText.includes('สาขา') || data.customer.branchText.includes('Branch'))
        ) {
          setBranch('branchNumber');
          setShowBranchNumber(true);
        }

        setEmail(data.customer.email);

        if (data.contactSuggestion) {
          setContactSuggestion(data.contactSuggestion);
        } else {
          async function getContactSuggestion() {
            instance
              .acquireTokenSilent({
                ...silentRequest,
                account: account,
              })
              .then((tokenResponse) => {
                axios
                  .get(API_URL + '/contacts', {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId
                    },
                  })
                  .then((response) => {
                    let suggestion = response.data.filter((contact) => contact.taxId === data.customer.taxId);

                    setContactSuggestion(suggestion[0]);
                  });
              })
              .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
              });
          }

          getContactSuggestion();
        }

        if (data.customer.taxNumberType === 'TXID') {
          setShowBranch(true);
        } else {
          setShowBranch(false);
        }

        if (data.customer.taxNumberType === 'OTHR') {
          setShowTaxId(false);
        } else {
          setShowTaxId(true);
        }

        setLanguage(data.customer.language);
        setStructure(!data.customer.unstructure);

        if (data.customer.unstructure) {
          setLineOne(data.customer.addressLineOne);
          setLineTwo(data.customer.addressLineTwo);
        } else {
          setBuildingNumber(data.customer.buildingNumber);
          setAddress(data.customer.address);
          setStreetPrefix(data.customer.streetPrefix);
          setStreetName(data.customer.streetName);
          setProvincePrefix(data.customer.provincePrefix);
          setProvince({
            key: data.customer.provinceCode,
            text: data.customer.provinceName,
          });

          setSubDistrictList(getSubDistrictList(data.customer.districtCode, data.customer.language));
          setSubDistrictPrefix(data.customer.subDistrictPrefix);
          setSubDistrict({
            key: data.customer.subDistrictCode,
            text: data.customer.subDistrictName,
          });

          setDistrictList(getDistrictList(data.customer.provinceCode, data.customer.language));
          setDistrictPrefix(data.customer.districtPrefix);
          setDistrict({
            key: data.customer.districtCode,
            text: data.customer.districtName,
          });
        }

        setDiscount(data.discount);

        let _items = [];
        let nonVatPrice = 0;
        let zeroVatPrice = 0;
        let vatPrice = 0;

        data.items.forEach((item, index) => {
          if (item.percentVat === nonVat) {
            nonVatPrice += item.total;
          } else if (item.percentVat === zeroVat) {
            zeroVatPrice += item.total;
          } else {
            vatPrice += item.total;
          }

          let _percentVat = '';
          let _percentVatText = '';

          if (item.percentVat === 0) {
            _percentVat = item.percentVat;
            _percentVatText = zeroVatText;
          } else if (item.percentVat === -1) {
            _percentVat = item.percentVat;
            _percentVatText = nonVatText;
          } else {
            _percentVat = percentVat;
            _percentVatText = percentVatText;
          }

          _items.push({
            number: item.number,
            description: item.description,
            quantity: item.quantity,
            unitCode: item.unitCode,
            unitName: item.unitName,
            price: item.price,
            discount: item.discount,
            percentVat: _percentVat,
            percentVatText: _percentVatText,
            total: item.total,
            whtIncomeCode: item.whtIncomeCode,
            whtIncomeName: item.whtIncomeName,
            percentWht: item.percentWht,
            whtTotal: item.whtTotal,
          });
        });

        setVatPriceTotal(vatPrice);
        setZeroVatPriceTotal(zeroVatPrice);
        setNonVatPriceTotal(nonVatPrice);

        setItems(_items);

        setAmountTotal(data.amountTotal);
        setVatTotal(data.vatTotal);
        setGrandTotal(data.grandTotal);

        setGrandTotalEN(data.grandTotalEN);
        setGrandTotalTH(data.grandTotalTH);

        setNote(data.note);
        setReceivedBy(data.receivedBy);

        //Cancel and Replace Doc

        if (data.customer.language === 'en') {
          setBranchDisplay([
            { key: '00000', text: 'Head office' },
            { key: 'branchNumber', text: 'Branch' },
          ]);
        } else {
          setBranchDisplay([
            { key: '00000', text: 'สำนักงานใหญ่' },
            { key: 'branchNumber', text: 'สาขาเลขที่' },
          ]);
        }

        if (data.backUrl) {
          setBackUrl(data.backUrl);
        }

        setIncludeVat(data.includeVat);

        setDueDate(data.dueDate);
        setDueDateBE(data.dueDateBE);
        setDueDateCE(data.dueDateCE);
        setReference(data.reference);

        setTaxInvoiceNumber(data.taxInvoice.number);
        setTaxInvoiceDate(data.taxInvoice.date);
        setTaxInvoiceDateBE(data.taxInvoice.dateBE);
        setTaxInvoiceDateCE(data.taxInvoice.dateCE);

        if (data.paymentList?.length) {
          if (data.paymentList[0].paymentCode) {
            setPayment(true);
            setPaymentList(data.paymentList);
          }
        }
      }
    } else {
      setItems([
        {
          number: '',
          description: '',
          quantity: '',
          unitCode: '',
          unitName: '-',
          price: '',
          discount: 0,
          percentVat: percentVat,
          percentVatText: percentVatText,
          total: 0,
          whtIncomeCode: '',
          whtIncomeName: '-',
          percentWht: 0,
          whtTotal: 0,
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    console.log('useEffect items changed!: ', items);

    let vatTypeArray = [];

    items.forEach((item) => {
      if (!vatTypeArray.includes(item.percentVat)) {
        vatTypeArray.push(item.percentVat);
      }
      if (vatTypeArray.length > 1) {
        setDiscountDisable(true);
        setDiscount(0);
      } else {
        setDiscountDisable(false);
      }
    });

    calAmountTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    console.log('useEffect items changed!: ', items);
    calAllLineTotal();
    calAmountTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeVat]);

  useEffect(() => {
    console.log('useEffect special discount changed!: ', discount);
    calAmountTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount]);

  useEffect(() => {
    let suggestions = contactList.filter((contact) => contact.name.includes(name));

    setSuggestionList(suggestions);
  }, [name, contactList]);

  useEffect(() => {
    let suggestions = contactList.filter((contact) => contact.taxId.includes(taxId));

    setSuggestionList(suggestions);
  }, [taxId, contactList]);

  const handleAddPaymentList = () => {
    setPaymentList([
      ...paymentList,
      {
        paymentCode: '',
        paymentName: '',
        date: new Date().toISOString(),
        dateBE: onFormatDate(new Date(), 'BE'),
        dateCE: onFormatDate(new Date(), 'CE'),
        bankCode: '',
        bankName: '',
        typeCode: '',
        typeName: '',
        accountName: '',
        accountNumber: '',
        paidAmount: 0,
        paymentNote: '',
      },
    ]);
  };

  const handleChangePaymentDate = (index, date) => {
    const values = [...paymentList];
    values[index]['date'] = date.toISOString();
    values[index]['dateBE'] =
      `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
    values[index]['dateCE'] = `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();

    setPaymentList(values);
  };

  const handleChangePaymentName = (index, event, selectedOption) => {
    console.log('Change index:', index);
    console.log('Event name: ', event);
    console.log('Selected option: ', selectedOption);
    const values = [...paymentList];
    if (selectedOption) {
      values[index]['paymentCode'] = selectedOption.key;
      values[index]['paymentName'] = selectedOption.text;
    }

    setPaymentList(values);
  };

  const handleChangeBankName = (index, event, selectedOption) => {
    console.log('Change index:', index);
    console.log('Event name: ', event);
    console.log('Selected option: ', selectedOption);
    const values = [...paymentList];
    if (selectedOption) {
      values[index]['bankCode'] = selectedOption.key;
      values[index]['bankName'] = selectedOption.text;
    }

    setPaymentList(values);
  };

  const handleChangeBankType = (index, event, selectedOption) => {
    console.log('Change index:', index);
    console.log('Event name: ', event);
    console.log('Selected option: ', selectedOption);
    const values = [...paymentList];
    if (selectedOption) {
      values[index]['typeCode'] = selectedOption.key;
      values[index]['typeName'] = selectedOption.text;
    }

    setPaymentList(values);
  };

  const handleChangeAccountName = (index, event) => {
    console.log('Change index:', index);
    console.log('Event name: ', event);
    const values = [...paymentList];

    values[index]['accountName'] = event.target.value;

    setPaymentList(values);
  };

  const handleChangeAccountNumber = (index, event) => {
    console.log('Change index:', index);
    console.log('Event name: ', event);
    const values = [...paymentList];
    values[index]['accountNumber'] = event.target.value;

    setPaymentList(values);
  };

  const handleChangePaidAmount = (index, event) => {
    console.log('Change index:', index);
    console.log('Event name: ', event);
    const values = [...paymentList];
    values[index]['paidAmount'] = event.target.value;

    setPaymentList(values);
  };

  const handleChangePaymentNote = (index, event) => {
    console.log('Change index:', index);
    console.log('Event name: ', event);
    const values = [...paymentList];
    values[index]['paymentNote'] = event.target.value;

    setPaymentList(values);
  };

  const handleTaxType = (selectedOption) => {
    console.log('Change tax type:', selectedOption.key);

    setTaxNumberType(selectedOption.key);

    if (selectedOption.key === 'TXID') {
      setShowBranch(true);
      setBranchNumber('00000');

      if (language === 'en') {
        setBranchText('Head office');
      } else {
        setBranchText('สำนักงานใหญ่');
      }
    } else {
      setShowBranch(false);
      setBranchText('');
      setBranchNumber('');
    }

    if (selectedOption.key === 'OTHR') {
      setShowTaxId(false);
      setTaxId('N/A');
    } else {
      setTaxId('');
      setShowTaxId(true);
    }
  };

  const handleBranch = (branchNum, lang) => {
    console.log('Change branch:', branchNum);
    if (branchNum === '00000') {
      setShowBranchNumber(false);
      setBranch('00000');
      setBranchNumber('00000');

      if (lang === 'en') {
        setBranchText('Head office');
      } else {
        setBranchText('สำนักงานใหญ่');
      }
    } else {
      if (branchNum !== '') {
        setBranch('branchNumber');
        if (branchNum === 'branchNumber') {
          setBranchNumber('00000');

          if (lang === 'en') {
            setBranchText('Branch 00000');
          } else {
            setBranchText('สาขา 00000');
          }
        } else {
          setBranchNumber(branchNum);

          if (lang === 'en') {
            setBranchText('Branch ' + branchNum);
          } else {
            setBranchText('สาขา ' + branchNum);
          }
        }
        setShowBranchNumber(true);
      }
    }

    if (lang === 'en') {
      setBranchDisplay([
        { key: '00000', text: 'Head office' },
        { key: 'branchNumber', text: 'Branch' },
      ]);
    } else {
      setBranchDisplay([
        { key: '00000', text: 'สำนักงานใหญ่' },
        { key: 'branchNumber', text: 'สาขาเลขที่' },
      ]);
    }
  };

  const handleChangeItem = (index, event, selectedOption) => {
    console.log('Change index:', index);
    console.log('selectedOption:', selectedOption);
    const values = [...items];
    if (selectedOption) {
      if (selectedOption.id === 'percentVat') {
        values[index]['percentVat'] = selectedOption.key;
        values[index]['percentVatText'] = selectedOption.text;
      } else {
        values[index]['unitCode'] = selectedOption.key;
        values[index]['unitName'] = selectedOption.text;
      }
    } else {
      values[index][event.target.name] = event.target.value;
    }

    setItems(values);
    calLineTotal(index);
  };

  const handleBlurItem = (index, event) => {
    const values = [...items];
    values[index][event.target.name] = Math.round(values[index][event.target.name] * 100) / 100;
    setItems(values);
    calLineTotal(index);
  };

  const handleBlurPaymentList = (index, event) => {
    const values = [...paymentList];
    values[index][event.target.name] = Math.round(values[index][event.target.name] * 100) / 100;
    setPaymentList(values);
  };

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        number: '',
        description: '',
        quantity: '',
        unitCode: '',
        unitName: '-',
        price: 0,
        discount: 0,
        percentVat: percentVat,
        percentVatText: percentVatText,
        total: 0,
        whtIncomeCode: '',
        whtIncomeName: '-',
        percentWht: 0,
        whtTotal: 0,
      },
    ]);
  };

  const handleRemovePaymentList = (index) => {
    console.log('Delete index:', index);
    const values = [...paymentList];
    values.splice(index, 1);
    setPaymentList(values);
  };

  const handleRemoveItem = (index) => {
    console.log('Delete index:', index);
    const values = [...items];
    values.splice(index, 1);
    setItems(values);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let isCorrect = true;

    if (structure) {
      if (province.key == undefined || province.key == null || province.key == '') {
        isCorrect = false;
        setProvinceErrMsg(DropdownErrMsg);
      }

      if (district.key == undefined || district.key == null || district.key == '') {
        isCorrect = false;
        setDistrictErrMsg(DropdownErrMsg);
      }

      if (subDistrict.key == undefined || subDistrict.key == null || subDistrict.key == '') {
        isCorrect = false;
        setSubDistrictErrMsg(DropdownErrMsg);
      }
    }

    if (taxNumberType == undefined || taxNumberType == null || taxNumberType == '') {
      isCorrect = false;
      setTaxNumberTypeErrMsg(DropdownErrMsg);
    }

    if (date == undefined || date == null || date == '') {
      isCorrect = false;
      dateRef.current.focus();
    }

    if (taxNumberType === 'TXID') {
      if (taxId.length !== 13 || branchNumber.length !== 5 || isNaN(branchNumber)) {
        console.log('error txid', branchNumber.length);
        isCorrect = false;
      }
    } else if (taxNumberType === 'NIDN') {
      if (taxId.length !== 13) {
        isCorrect = false;
      }
    } else if (taxNumberType === 'CCPT') {
      if (taxId.length === 0) {
        isCorrect = false;
      }
    }

    if (postcode) {
      if (countryCode === 'TH') {
        if (isNaN(postcode)) isCorrect = false;
        if (postcode.length !== 5) isCorrect = false;
      }
    }

    if (phoneNumberError) {
      isCorrect = false;
      phoneRef.focus();
    }

    items.forEach((item) => {
      if (item.total < 0) {
        isCorrect = false;
      }
    });

    console.log(
      'Name:',
      name,
      '\nbuildingNumber:',
      buildingNumber,
      '\naddress:',
      address,
      '\nstreetName:',
      streetName,
      '\nprovincePrefix:',
      provincePrefix,
      '\nprovince:',
      province,
      '\ndistrictPrefix:',
      districtPrefix,
      '\ndistrict:',
      district,
      '\nsubDistrictPrefix:',
      subDistrictPrefix,
      '\nsubDistrict:',
      subDistrict,
      '\npostcode:',
      postcode,
      '\nphone:',
      phone,
      '\ntaxId:',
      taxId,
      '\nbranchNumber:',
      branchNumber,
      '\nemail:',
      email
    );

    console.log(
      'number:',
      number,
      '\ndate:',
      date,
      '\nnote:',
      note,
      '\namountTotal:',
      amountTotal,
      '\npercentVat:',
      percentVat,
      '\nvatTotal:',
      vatTotal,
      '\ngrandTotal:',
      grandTotal
    );
    console.log('Items', items);

    if (isCorrect) {
      let _customer = {};

      if (structure) {
        _customer = {
          address: address,
          branchNumber: branchNumber,
          branchText: branchText,
          buildingNumber: buildingNumber,
          streetPrefix: streetPrefix,
          streetName: streetName,
          districtPrefix: districtPrefix,
          districtCode: district.key,
          districtName: district.text,
          email: email,
          name: name,
          phone: phone,
          phoneCountryCode: '+66',
          postcode: postcode,
          provincePrefix: provincePrefix,
          provinceCode: province.key,
          provinceName: province.text,
          subDistrictPrefix: subDistrictPrefix,
          subDistrictCode: subDistrict.key,
          subDistrictName: subDistrict.text,
          taxId: taxId,
          taxNumberType: taxNumberType,
          language: language,
          countryCode: countryCode,
          countryName: countryName,
          unstructure: false,
        };
      } else {
        _customer = {
          addressLineOne: lineOne,
          addressLineTwo: lineTwo,
          branchNumber: branchNumber,
          branchText: branchText,
          email: email,
          name: name,
          phone: phone,
          phoneCountryCode: '+66',
          postcode: postcode,
          taxId: taxId,
          taxNumberType: taxNumberType,
          language: language,
          countryCode: countryCode,
          countryName: countryName,
          unstructure: true,
        };
      }

      let _taxInvoice = {
        number: taxInvoiceNumber,
        date: taxInvoiceDate,
        dateBE: taxInvoiceDateBE,
        dateCE: taxInvoiceDateCE,
      };

      console.log(paymentList);
      console.log('_customer', _customer);

      if (data?.command === 'editFromDocument') {
        instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
          //Check documents spending limit
          axios
            .put(
              API_URL + '/expenses/expense-notes/' + data.id,
              {
                company: company,
                customer: _customer,
                date: date,
                dateBE: dateBE,
                dateCE: dateCE,
                discount: discount,
                amountTotal: amountTotal >= 0 ? amountTotal : 0,
                vatTotal: vatTotal >= 0 ? vatTotal : 0,
                grandTotal: grandTotal >= 0 ? grandTotal : 0,
                grandTotalTH: grandTotalTH,
                grandTotalEN: grandTotalEN,
                nonVatPriceTotal: nonVatPriceTotal >= 0 ? nonVatPriceTotal : 0,
                zeroVatPriceTotal: zeroVatPriceTotal >= 0 ? zeroVatPriceTotal : 0,
                vatPriceTotal: vatPriceTotal >= 0 ? vatPriceTotal : 0,
                items: items,
                note: note,
                number: number,
                percentVat: percentVat,
                receivedBy: receivedBy,
                includeVat: includeVat,
                whtTotal: whtTotal,
                dueDate: dueDate,
                dueDateBE: dueDateBE,
                dueDateCE: dueDateCE,
                reference: reference,
                taxInvoice: _taxInvoice,
                paidTotal: grandTotal - whtTotal >= 0 ? grandTotal - whtTotal : 0,
                paymentList: paymentList,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              }
            )
            .then((response) => {
              console.log('response', response);
              setUpdateSuccess(true);
              window.scrollTo(0, 0);
            });
        });
      } else {
        history.push({
          pathname: '/expenses/expense-notes/confirm',
          state: {
            company: company,
            customer: _customer,
            date: date,
            dateBE: dateBE,
            dateCE: dateCE,
            discount: discount,
            amountTotal: amountTotal >= 0 ? amountTotal : 0,
            vatTotal: vatTotal >= 0 ? vatTotal : 0,
            grandTotal: grandTotal >= 0 ? grandTotal : 0,
            grandTotalTH: grandTotalTH,
            grandTotalEN: grandTotalEN,
            nonVatPriceTotal: nonVatPriceTotal >= 0 ? nonVatPriceTotal : 0,
            zeroVatPriceTotal: zeroVatPriceTotal >= 0 ? zeroVatPriceTotal : 0,
            vatPriceTotal: vatPriceTotal >= 0 ? vatPriceTotal : 0,
            items: items,
            note: note,
            number: number,
            percentVat: percentVat,
            receivedBy: receivedBy,
            backUrl: backUrl,
            includeVat: includeVat,
            contactSuggestion: contactSuggestion,
            whtTotal: whtTotal,
            dueDate: dueDate,
            dueDateBE: dueDateBE,
            dueDateCE: dueDateCE,
            reference: reference,
            taxInvoice: _taxInvoice,
            paidTotal: grandTotal - whtTotal >= 0 ? grandTotal - whtTotal : 0,
            paymentList: paymentList,
          },
        });
      }
    }
  };

  const handleCancel = (e) => {
    history.push({
      pathname: backUrl,
      state: {},
    });
  };

  const calLineTotal = (index) => {
    const values = [...items];

    console.log('values', values);

    values[index]['total'] = Math.round(values[index]['quantity'] * (values[index]['price'] - values[index]['discount']) * 100) / 100;

    // cal witholding tax

    console.log('value index total:', values[index]['total']);
    let _itemPercentVat = values[index]['percentVat'];

    if (_itemPercentVat < 0) {
      _itemPercentVat = 0;
    }

    let _itemVat = 0;
    let _itemTotalAfterVat = 0;
    let _itemTotalBeforeVat = 0;
    let _whtTotal = 0;

    if (includeVat) {
      _itemVat = Math.round(((values[index]['total'] * _itemPercentVat) / (100 + _itemPercentVat)) * 100) / 100;
      _itemTotalAfterVat = Math.round(values[index]['total'] * 100) / 100;
      _itemTotalBeforeVat = _itemTotalAfterVat - _itemVat;
    } else {
      _itemTotalBeforeVat = Math.round(values[index]['total'] * 100) / 100;
      _itemVat = Math.round(_itemTotalBeforeVat * _itemPercentVat * 100) / 100;
      _itemTotalAfterVat = _itemTotalBeforeVat + _itemVat;
    }

    console.log('total before vat:', _itemTotalBeforeVat);

    _whtTotal = _itemTotalBeforeVat * (Math.round((values[index]['percentWht'] / 100) * 100) / 100);

    console.log('wht total:', _whtTotal);

    values[index]['whtTotal'] = Math.round(_whtTotal * 100) / 100;

    setItems(values);
    calAmountTotal();
  };

  const calAllLineTotal = (index) => {
    for (let i = 0; i < items.length; i++) {
      calLineTotal(i);
    }
  };

  const calAmountTotal = () => {
    let sumVatTotal = 0;
    let sumGrandTotal = 0;

    let sumAmountTotal = 0;
    let nonVatPrice = 0;
    let zeroVatPrice = 0;
    let vatPrice = 0;

    let sumWhtTotal = 0;

    if (includeVat) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].percentVat === nonVat) {
          nonVatPrice += items[i]['total'];
          sumGrandTotal += items[i]['total'];
          sumWhtTotal += items[i]['whtTotal'];
        } else if (items[i].percentVat === zeroVat) {
          zeroVatPrice += items[i]['total'];
          sumGrandTotal += items[i]['total'];
          sumWhtTotal += items[i]['whtTotal'];
        } else {
          vatPrice += items[i]['total'];
          sumGrandTotal += items[i]['total'];
          sumWhtTotal += items[i]['whtTotal'];
        }
      }

      if (!discountDisable) {
        sumGrandTotal -= discount;
        if (vatPrice !== 0) {
          vatPrice -= discount;
        } else if (zeroVatPrice !== 0) {
          zeroVatPrice -= discount;
        } else {
          nonVatPrice -= discount;
        }
      }

      sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;
      vatPrice = Math.round(vatPrice * 100) / 100;
      nonVatPrice = Math.round(nonVatPrice * 100) / 100;
      zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;
      sumWhtTotal = Math.round(sumWhtTotal * 100) / 100;

      sumVatTotal =
        Math.round(((vatPrice * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
      sumAmountTotal = sumGrandTotal - sumVatTotal;

      setNonVatPriceTotal(nonVatPrice);
      setZeroVatPriceTotal(zeroVatPrice - Math.round(((zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100);
      setVatPriceTotal(vatPrice - Math.round(((vatPrice * percentVat) / (100 + percentVat)) * 100) / 100);
    } else {
      for (let i = 0; i < items.length; i++) {
        if (items[i].percentVat === nonVat) {
          nonVatPrice += items[i]['total'];
          sumAmountTotal += items[i]['total'];
          sumWhtTotal += items[i]['whtTotal'];
        } else if (items[i].percentVat === zeroVat) {
          zeroVatPrice += items[i]['total'];
          sumAmountTotal += items[i]['total'];
          sumWhtTotal += items[i]['whtTotal'];
        } else {
          vatPrice += items[i]['total'];
          sumAmountTotal += items[i]['total'];
          sumWhtTotal += items[i]['whtTotal'];
        }
      }

      if (!discountDisable) {
        sumAmountTotal -= discount;
        if (vatPrice !== 0) {
          vatPrice -= discount;
        } else if (zeroVatPrice !== 0) {
          zeroVatPrice -= discount;
        } else {
          nonVatPrice -= discount;
        }
      }

      sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;
      vatPrice = Math.round(vatPrice * 100) / 100;
      nonVatPrice = Math.round(nonVatPrice * 100) / 100;
      zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;
      sumWhtTotal = Math.round(sumWhtTotal * 100) / 100;

      sumVatTotal = Math.round(((vatPrice * percentVat) / 100) * 100) / 100 + Math.round(((zeroVatPrice * zeroVat) / 100) * 100) / 100;
      sumGrandTotal = sumAmountTotal + sumVatTotal;

      setNonVatPriceTotal(nonVatPrice);
      setZeroVatPriceTotal(zeroVatPrice);
      setVatPriceTotal(vatPrice);
    }

    setAmountTotal(sumAmountTotal);
    setVatTotal(sumVatTotal);
    setGrandTotal(sumGrandTotal);
    setWhtTotal(sumWhtTotal);

    setGrandTotalEN(numberToTextEn(sumGrandTotal));
    setGrandTotalTH(numberToTextTh(sumGrandTotal));
  };

  const handleStructure = (checked) => {
    console.log('Address structure:', checked);

    setStructure(checked);
    if (checked) {
      setBuildingNumber('');
      setAddress('');
      setStreetPrefix('');
      setStreetName('');
      setProvincePrefix('');
      setProvince({ key: '', text: '' });
      setDistrictPrefix('');
      setDistrict({ key: '', text: '' });
      setSubDistrict('');
      setSubDistrict({ key: '', text: '' });

      setCountryCode('TH');
      setCountryName('ไทย');
    } else {
      setLineOne('');
      setLineTwo('');
    }
  };

  function handleSelectSuggestions(e, suggestion) {
    setContactSuggestion(suggestion);

    setName(suggestion.name);

    if (suggestion.addressLineOne) {
      setStructure(false);
      setLineOne(suggestion.addressLineOne);
      setLineTwo(suggestion.addressLineTwo);

      setCountryCode(suggestion.countryCode);
      setCountryName(suggestion.countryName);
    }

    if (suggestion.buildingNumber) {
      setStructure(true);

      setBuildingNumber(suggestion.buildingNumber);
      setAddress(suggestion.address);

      setStreetName(suggestion.streetName);

      if (suggestion.language != null) {
        setLanguage(suggestion.language);
      }

      setProvincePrefix(suggestion.provincePrefix);
      setProvince({
        key: suggestion.provinceCode,
        text: suggestion.provinceName,
      });

      setDistrictList(getDistrictList(suggestion.provinceCode, suggestion.language));
      setDistrictPrefix(suggestion.districtPrefix);
      setDistrict({
        key: suggestion.districtCode,
        text: suggestion.districtName,
      });

      setSubDistrictList(getSubDistrictList(suggestion.districtCode, suggestion.language));
      setSubDistrictPrefix(suggestion.subDistrictPrefix);
      setSubDistrict({
        key: suggestion.subDistrictCode,
        text: suggestion.subDistrictName,
      });

      if (suggestion.language === 'en') {
        setStreetPrefix('');
      } else {
        setStreetPrefix(suggestion.streetPrefix);
      }

      setAreaPrefix(suggestion.provinceCode);
    }

    setPostCode(suggestion.postcode);

    if (suggestion.taxNumberType === 'TXID') {
      setTaxNumberType(suggestion.taxNumberType);
      setShowTaxId(true);
      setShowBranch(true);

      setBranchNumber(suggestion.branchNumber);

      if (suggestion.language != null) {
        if (suggestion.branchNumber === '00000') {
          if (suggestion.language === 'en') {
            setBranchText('Head office');
          } else {
            setBranchText('สำนักงานใหญ่');
          }
        } else {
          if (suggestion.language === 'en') {
            setBranchText('Branch ' + suggestion.branchNumber);
          } else {
            setBranchText('สาขา ' + suggestion.branchNumber);
          }
        }
      } else {
        if (suggestion.branchNumber === '00000') {
          if (language === 'en') {
            setBranchText('Head office');
          } else {
            setBranchText('สำนักงานใหญ่');
          }
        } else {
          if (language === 'en') {
            setBranchText('Branch ' + suggestion.branchNumber);
          } else {
            setBranchText('สาขา ' + suggestion.branchNumber);
          }
        }
      }

      if (suggestion.branchNumber === '00000' && !(suggestion.branchText.includes('สาขา') || suggestion.branchText.includes('Branch'))) {
        setBranch('00000');
        setShowBranchNumber(false);
      } else if (suggestion.branchNumber.length === 5 && (suggestion.branchText.includes('สาขา') || suggestion.branchText.includes('Branch'))) {
        setBranch('branchNumber');
        setShowBranchNumber(true);
      }
    } else {
      if (suggestion.taxNumberType === 'OTHR') {
        setShowTaxId(false);
      } else {
        setShowTaxId(true);
      }
      setTaxNumberType(suggestion.taxNumberType);
      setShowBranch(false);
      setShowBranchNumber(false);
      setBranchText('');
      setBranchNumber('');
    }

    setTaxId(suggestion.taxId);

    setPhone(suggestion.phone);
    setEmail(suggestion.email);
  }

  const handleWhtIncomeCode = (index, event, selectedOption) => {
    console.log('Change index:', index);
    console.log('selectedOption:', selectedOption);
    console.log('event:', event);

    const values = [...items];
    if (selectedOption?.key && selectedOption?.text) {
      values[index]['whtIncomeCode'] = selectedOption.key;
      values[index]['whtIncomeName'] = selectedOption.text;
    } else {
      values[index]['whtIncomeCode'] = '999';
      values[index]['whtIncomeName'] = event.target.value;

      let _whtIncomeList = whtIncomeList;
      for (var i = 0; i < _whtIncomeList.length; i++) {
        if (_whtIncomeList[i].key === '999') {
          _whtIncomeList[i].text = 'อื่นๆ - ' + event.target.value;
          setWhtIncomeList(_whtIncomeList);
          break;
        }
      }
    }

    setItems(values);
  };

  const handlePercentWht = (index, event, selectedOption) => {
    console.log('Change index:', index);
    console.log('selectedOption:', selectedOption);
    console.log('event:', event);

    const values = [...items];
    if (selectedOption?.key !== undefined && selectedOption?.text !== undefined) {
      values[index]['percentWht'] = Math.round(selectedOption.key * 100) / 100;
    }
    calLineTotal(index);

    console.log('items value:', values);

    setItems(values);
  };

  const getErrorMessage = (value) => {
    return value && value !== '0' ? '' : t('expenseNote.getErrorMessage');
  };

  const getPostCodeErrorMessage = (value) => {
    if (countryCode === 'TH') {
      if (isNaN(value)) return t('expenseNote.getPostCodeErrorMessage1');
      if (!value || value.length !== 5) return t('expenseNote.getPostCodeErrorMessage2');
    }
    return '';
  };

  let postCodeRef = useRef(false);

  useLayoutEffect(() => {
    if (postCodeRef.current) {
      postCodeRef.focus();
      postCodeRef.blur();
    } else {
      postCodeRef.current = true;
    }
  }, [countryCode]);

  const getTaxIdErrorMessage = (value) => {
    if ((taxNumberType == 'TXID' || taxNumberType == 'NIDN') && taxId.length !== 13) {
      return t('expenseNote.getTaxIdErrorMessage');
    } else if (value === '' || value === null || value === undefined) {
      return t('expenseNote.getErrorMessage');
    } else {
      return '';
    }
  };

  const getBranchNumberErrorMessage = (value) => {
    if (branchNumber.length !== 5) {
      return t('expenseNote.getBranchNumberErrorMessage2');
    } else if (isNaN(value)) {
      return t('expenseNote.getBranchNumberErrorMessage1');
    } else if (value === '' || value === null || value === undefined) {
      return t('expenseNote.getErrorMessage');
    } else {
      return '';
    }
  };

  const getPhoneNumberErrorMessage = (value) => {
    setPhoneNumberError(false);
    if (value === null || value === undefined) {
      setPhoneNumberError(true);
      return t('expenseNote.getErrorMessage');
    } else if (phoneRegEx.test(value) !== true) {
      setPhoneNumberError(true);
      return t('expenseNote.getPhoneNumberErrorMessage');
    }
  };

  const setRoadPrefix = (_streetName) => {
    if (_streetName === '') {
      setStreetPrefix('');
    } else {
      if (language === 'th') {
        setStreetPrefix('ถ.');
      } else {
        setStreetPrefix('');
      }
    }
  };

  const setAreaPrefix = (_provinceCode) => {
    if (_provinceCode === '10') {
      if (language === 'th') {
        setSubDistrictPrefix('แขวง');
        setDistrictPrefix('เขต');
        setProvincePrefix('');
      } else {
        setSubDistrictPrefix('');
        setDistrictPrefix('');
        setProvincePrefix('');
      }
    } else {
      if (language === 'en') {
        setSubDistrictPrefix('');
        setDistrictPrefix('');
        setProvincePrefix('');
      } else {
        setSubDistrictPrefix('ต.');
        setDistrictPrefix('อ.');
        setProvincePrefix('จ.');
      }
    }
  };

  const updateCompany = (replace, value) => {
    console.log('userEffect updateCompany call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        //Check documents spending limit
        axios
          .patch(
            API_URL + '/etax/companies/' + company.id,
            {
              replace: replace,
              value: value,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            }
          )
          .then(
            (response) => {
              console.log('Company data: ', response);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      {updateSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setUpdateSuccess(false)}
          dismissButtonAriaLabel="Close"
          style={{
            fontSize: 14,
          }}
        >
          {t('expenseNote.updateSuccess')}
        </MessageBar>
      )}

      <Stack>
        <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('expenseNote.header')}</h2>
        <div
          style={{
            width: '1000px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 30,
            background: '#ffffff',
            marginLeft: '0px',
            marginTop: '0px',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <Stack horizontal>
            {companyName ? (
              <div className="ms-Grid-col ms-lg7">
                <h4 style={{ marginBottom: 5, marginTop: 5 }}>
                  {companyName} ({companyBranchText})
                </h4>
                <span>{companyAllAddress}</span>
                <br />
                {t('expenseNote.companyTel')} {companyPhone} {t('expenseNote.companyEmail')} {companyEmail}
                <br />
                {t('expenseNote.companyTaxId')} {companyTaxId}
              </div>
            ) : (
              <div className="ms-Grid-col ms-lg7">
                <Shimmer height="1%" width="50%" />
                <br />
                <Shimmer width="65%" />
                <br />
                <Shimmer width="65%" />
              </div>
            )}

            <div className="ms-Grid-col ms-sm12 ms-lg5">
              <center>
                <h2 style={{ color: '#0C479D', marginBottom: 5, marginTop: 5 }}>{t('expenseNote.documentHeader')}</h2>
                <strong style={{ color: '#696969' }}>{t('expenseNote.documentSubHeader')}</strong>
                <h4>{t('expenseNote.original')}</h4>
              </center>
            </div>
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <Stack vertical>
              <Stack horizontal tokens={{ childrenGap: 15 }}>
                <div>
                  <TextField
                    name="name"
                    label={t('expenseNote.customerName')}
                    autoComplete="true"
                    styles={{ root: { width: 310 } }}
                    onChange={(e) => {
                      setName(e.target.value);
                      if (e.target.value) {
                        setShowSuggestions(true);
                      } else {
                        setShowSuggestions(false);
                      }
                    }}
                    value={name}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                    autoFocus
                    onBlur={() => {
                      setShowSuggestions(false);
                      setShowSuggestionsTaxId(false);
                    }}
                  />
                  {showSuggestions && suggestionList[0] !== undefined && (
                    <div style={contactListStyle}>
                      {suggestionList.map((suggestion, index) => {
                        return (
                          <div
                            key={index}
                            id={suggestion.name}
                            className={controlClass.hover}
                            style={contactOptionStyle}
                            onMouseDown={(e) => handleSelectSuggestions(e, suggestion)}
                          >
                            {suggestion.name} {suggestion.taxId}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <Dropdown
                  placeholder={t('expenseNote.pleaseSelect')}
                  label={t('expenseNote.country')}
                  options={countryList}
                  selectedKey={countryCode}
                  styles={{ root: { width: 190 } }}
                  disabled={structure}
                  onChange={(e, selectedOption) => {
                    setCountryCode(selectedOption.key);
                    setCountryName(selectedOption.text);
                  }}
                />
                <Toggle
                  label={t('expenseNote.addressStructure')}
                  onText={t('expenseNote.on')}
                  offText={t('expenseNote.off')}
                  checked={structure}
                  onChange={() => {
                    handleStructure(!structure);
                  }}
                />
              </Stack>

              {!structure && (
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                  <TextField
                    name="lineOne"
                    label={t('expenseNote.lineOne')}
                    styles={{ root: { width: 615 } }}
                    onChange={(e) => {
                      setLineOne(e.target.value);
                    }}
                    value={lineOne}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                </Stack>
              )}

              {structure && (
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                  <TextField
                    name="buildingNumber"
                    label={t('expenseNote.buildingNumber')}
                    styles={{ root: { width: 100 } }}
                    onChange={(e) => {
                      setBuildingNumber(e.target.value);
                    }}
                    value={buildingNumber}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <TextField
                    name="address"
                    label={t('expenseNote.address')}
                    styles={{ root: { width: 350 } }}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    value={address}
                  />
                  <TextField
                    name="streetName"
                    label={t('expenseNote.streetName')}
                    styles={{ root: { width: 135 } }}
                    onChange={(e) => {
                      setStreetName(e.target.value);

                      setRoadPrefix(e.target.value);
                    }}
                    value={streetName}
                  />
                </Stack>
              )}

              <Stack horizontal tokens={{ childrenGap: 15 }}>
                {structure ? (
                  <Stack horizontal tokens={{ childrenGap: 15 }}>
                    <Dropdown
                      placeholder={t('expenseNote.pleaseSelect')}
                      label={t('expenseNote.province')}
                      options={provinceList}
                      styles={dropdownStyles}
                      selectedKey={province.key}
                      onChange={(e, selectedOption) => {
                        setProvince(selectedOption);
                        setDistrict({ key: '', text: '' });
                        setSubDistrict({ key: '', text: '' });
                        setDistrictList(getDistrictList(selectedOption.key, language));
                        setSubDistrictList([]);

                        setAreaPrefix(selectedOption.key);

                        console.log('Province changed', districtList);

                        setProvinceErrMsg('');
                        setDistrictErrMsg('');
                        setSubDistrictErrMsg('');
                      }}
                      onBlur={() => {
                        console.log(province.key);
                        if (province.key === '') {
                          setProvinceErrMsg(DropdownErrMsg);
                        } else {
                          setProvinceErrMsg('');
                        }
                      }}
                      errorMessage={provinceErrMsg}
                      required
                    />
                    <Dropdown
                      placeholder={t('expenseNote.pleaseSelect')}
                      label={t('expenseNote.district')}
                      options={districtList}
                      selectedKey={district.key}
                      styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        setDistrict(selectedOption);
                        setSubDistrict({ key: '', text: '' });
                        setSubDistrictList(getSubDistrictList(selectedOption.key, language));
                        console.log('District changed', subDistrictList);

                        setDistrictErrMsg('');
                        setSubDistrictErrMsg('');
                      }}
                      onBlur={() => {
                        console.log(district.key);
                        if (province.key !== '' && district.key === '') {
                          setDistrictErrMsg(DropdownErrMsg);
                        } else {
                          setDistrictErrMsg('');
                        }
                      }}
                      errorMessage={districtErrMsg}
                      required
                    />
                    <Dropdown
                      placeholder={t('expenseNote.pleaseSelect')}
                      label={t('expenseNote.subDistrict')}
                      options={subDistrictList}
                      selectedKey={subDistrict.key}
                      styles={dropdownStyles}
                      onChange={(e, selectedOption) => {
                        setSubDistrict(selectedOption);

                        setSubDistrictErrMsg('');
                      }}
                      onBlur={() => {
                        console.log(subDistrict.key);
                        if (district.key !== '' && subDistrict.key === '') {
                          setSubDistrictErrMsg(DropdownErrMsg);
                        } else {
                          setSubDistrictErrMsg('');
                        }
                      }}
                      errorMessage={subDistrictErrMsg}
                      required
                    />
                  </Stack>
                ) : (
                  <TextField
                    name="lineTwo"
                    label={t('expenseNote.lineTwo')}
                    styles={{ root: { width: 480 } }}
                    onChange={(e) => {
                      setLineTwo(e.target.value);
                    }}
                    value={lineTwo}
                  />
                )}
                <TextField
                  name="postcode"
                  label={t('expenseNote.postcode')}
                  componentRef={(input) => (postCodeRef = input)}
                  styles={{ root: { width: 120 } }}
                  onChange={(e) => {
                    setPostCode(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (!e.target.value.trim()) {
                      setPostCode('');
                    } else {
                      setPostCode(e.target.value.trim());
                    }
                  }}
                  value={postcode}
                  onGetErrorMessage={getPostCodeErrorMessage}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
                <Toggle
                  label={t('expenseNote.english')}
                  onText={t('expenseNote.on')}
                  offText={t('expenseNote.off')}
                  checked={language === 'en' ? true : false}
                  onChange={() => {
                    if (language === 'en') {
                      setLanguage('th');
                      sessionStorage.setItem('address-language', 'th');
                    } else {
                      setLanguage('en');
                      sessionStorage.setItem('address-language', 'en');
                    }
                  }}
                />
              </Stack>

              <Stack horizontal tokens={{ childrenGap: 15 }}>
                <Dropdown
                  placeholder={t('expenseNote.pleaseSelect')}
                  label={t('expenseNote.taxNumberType')}
                  options={[
                    {
                      key: 'TXID',
                      text: t('expenseNote.txid'),
                    },
                    {
                      key: 'NIDN',
                      text: t('expenseNote.nidn'),
                    },
                    {
                      key: 'CCPT',
                      text: t('expenseNote.ccpt'),
                    },
                    { key: 'OTHR', text: t('expenseNote.othr') },
                  ]}
                  styles={{ root: { width: 180 } }}
                  selectedKey={taxNumberType}
                  onChange={(e, selectedOption) => {
                    handleTaxType(selectedOption);

                    setTaxNumberTypeErrMsg('');
                  }}
                  onBlur={() => {
                    console.log(taxNumberType);
                    if (taxNumberType === '' || taxNumberType === undefined) {
                      setTaxNumberTypeErrMsg(DropdownErrMsg);
                    } else {
                      setTaxNumberTypeErrMsg('');
                    }
                  }}
                  errorMessage={taxNumberTypeErrMsg}
                  required
                />
                {showTaxId && (
                  <div>
                    <TextField
                      name="taxId"
                      label={t('expenseNote.number')}
                      styles={{ root: { width: 120 } }}
                      autoComplete="true"
                      value={taxId}
                      onChange={(e) => {
                        setTaxId(e.target.value);
                        if (e.target.value) {
                          setShowSuggestionsTaxId(true);
                        } else {
                          setShowSuggestionsTaxId(false);
                        }
                      }}
                      onGetErrorMessage={getTaxIdErrorMessage}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                      onBlur={() => {
                        setShowSuggestions(false);
                        setShowSuggestionsTaxId(false);
                      }}
                    />
                    {showSuggestionsTaxId && suggestionList[0] !== undefined && (
                      <div style={contactListStyle}>
                        {suggestionList.map((suggestion, index) => {
                          return (
                            <div
                              key={index}
                              id={suggestion.name}
                              className={controlClass.hover}
                              style={contactOptionStyle}
                              onMouseDown={(e) => handleSelectSuggestions(e, suggestion)}
                            >
                              {suggestion.taxId} {suggestion.name}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}

                {showBranch && (
                  <Stack horizontal tokens={{ childrenGap: 15 }}>
                    <Dropdown
                      placeholder={t('expenseNote.pleaseSelect')}
                      label={t('expenseNote.branchDisplay')}
                      options={branchDisplay}
                      selectedKey={branch}
                      styles={{ root: { width: 160 } }}
                      onChange={(e, selectedOption) => handleBranch(selectedOption.key, language)}
                    />
                    {showBranchNumber && (
                      <TextField
                        name="branchNumber"
                        label={t('expenseNote.number')}
                        description={t('expenseNote.branchNumberDesc')}
                        styles={{ root: { width: 110 } }}
                        value={branchNumber}
                        required={showBranchNumber}
                        onChange={(e) => {
                          setBranchNumber(e.target.value);

                          if (language === 'en') {
                            setBranchText('Branch ' + e.target.value);
                          } else {
                            setBranchText('สาขา ' + e.target.value);
                          }
                        }}
                        onGetErrorMessage={getBranchNumberErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                      />
                    )}
                  </Stack>
                )}
              </Stack>

              <Stack horizontal tokens={{ childrenGap: 15 }}>
                <TextField
                  componentRef={(input) => (phoneRef = input)}
                  name="phone"
                  label={t('expenseNote.tel')}
                  styles={{ root: { width: 200 } }}
                  onGetErrorMessage={getPhoneNumberErrorMessage}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                />
                <TextField
                  name="email"
                  label={t('expenseNote.email')}
                  styles={{ root: { width: 300 } }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                />

                <Toggle
                  label={t('expenseNote.priceIncludeVat')}
                  onText={t('expenseNote.on')}
                  offText={t('expenseNote.off')}
                  checked={includeVat}
                  onChange={() => {
                    setIncludeVat(!includeVat);

                    setCompany({
                      ...company,
                      includeVat: !includeVat,
                    });

                    updateCompany('includeVat', !includeVat);
                  }}
                  style={{ alignItems: 'right' }}
                />
              </Stack>
            </Stack>
            <Stack vertical></Stack>

            <Stack vertical>
              <Stack horizontal>
                <TextField
                  name="number"
                  label={t('expenseNote.number')}
                  styles={{ root: { width: 150 } }}
                  onChange={(e) => {
                    setNumber(e.target.value);
                  }}
                  value={number}
                  onGetErrorMessage={getErrorMessage}
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
              </Stack>
              <Stack horizontal>
                <DatePicker
                  name="date"
                  label={t('expenseNote.date')}
                  placeholder={t('expenseNote.pleaseSelectDate')}
                  allowTextInput={false}
                  className={controlClass.control}
                  firstDayOfWeek={firstDayOfWeek}
                  strings={DayPickerStrings}
                  formatDate={onFormatDate}
                  styles={{ root: { width: 150 } }}
                  onSelectDate={(date) => {
                    setDateBE(
                      `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543)
                    );
                    setDateCE(`${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear());

                    setDate(date.toISOString());
                  }}
                  value={new Date(date)}
                  isRequired={true}
                  componentRef={dateRef}
                />
              </Stack>
              <Stack horizontal style={{ marginTop: '30px' }}>
                <DatePicker
                  name="dueDate"
                  label={t('expenseNote.dueDate')}
                  placeholder={t('expenseNote.pleaseSelectDate')}
                  allowTextInput={false}
                  className={controlClass.control}
                  firstDayOfWeek={firstDayOfWeek}
                  strings={DayPickerStrings}
                  formatDate={onFormatDate}
                  styles={{ root: { width: 150 } }}
                  onSelectDate={(dueDate) => {
                    setDueDateBE(
                      `${dueDate.getDate()}`.padStart(2, '0') +
                        '/' +
                        `${dueDate.getMonth() + 1}`.padStart(2, '0') +
                        '/' +
                        (dueDate.getFullYear() + 543)
                    );
                    setDueDateCE(
                      `${dueDate.getDate()}`.padStart(2, '0') + '/' + `${dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + dueDate.getFullYear()
                    );

                    setDueDate(dueDate.toISOString());
                  }}
                  value={dueDate ? new Date(dueDate) : ''}
                  componentRef={dueDateRef}
                />
              </Stack>
              <Stack horizontal>
                <TextField
                  name="reference"
                  label={t('expenseNote.reference')}
                  styles={{ root: { width: 150 } }}
                  onChange={(e) => {
                    setReference(e.target.value);
                  }}
                  value={reference}
                />
              </Stack>
            </Stack>
          </Stack>

          <div>
            <br />
            <Separator />
            <br />
          </div>

          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <TextField
              name="taxInvoiceNumber"
              label={t('expenseNote.taxInvoiceNumber')}
              styles={{ root: { width: 200 } }}
              onChange={(e) => {
                setTaxInvoiceNumber(e.target.value);
              }}
              value={taxInvoiceNumber}
            />
            <DatePicker
              name="taxInvoiceDate"
              label={t('expenseNote.taxInvoiceDate')}
              placeholder={t('expenseNote.pleaseSelectDate')}
              allowTextInput={false}
              className={controlClass.control}
              firstDayOfWeek={firstDayOfWeek}
              strings={DayPickerStrings}
              formatDate={onFormatDate}
              styles={{ root: { width: 125 } }}
              onSelectDate={(taxInvoiceDate) => {
                setTaxInvoiceDateBE(
                  `${taxInvoiceDate.getDate()}`.padStart(2, '0') +
                    '/' +
                    `${taxInvoiceDate.getMonth() + 1}`.padStart(2, '0') +
                    '/' +
                    (taxInvoiceDate.getFullYear() + 543)
                );
                setTaxInvoiceDateCE(
                  `${taxInvoiceDate.getDate()}`.padStart(2, '0') +
                    '/' +
                    `${taxInvoiceDate.getMonth() + 1}`.padStart(2, '0') +
                    '/' +
                    taxInvoiceDate.getFullYear()
                );

                setTaxInvoiceDate(taxInvoiceDate.toISOString());
              }}
              value={taxInvoiceDate ? new Date(taxInvoiceDate) : ''}
              componentRef={taxInvoiceDateRef}
            />
          </Stack>

          <div>
            <br />
            <Separator />
            <br />
          </div>
          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <span align="center" style={{ width: '50px' }}>
              {t('expenseNote.itemNumber')}
            </span>
            <span align="center" style={{ width: '200px' }}>
              {t('expenseNote.item')}
            </span>
            <span align="center" style={{ width: '70px' }}>
              {t('expenseNote.quantity')}
            </span>
            <span align="center" style={{ width: '35px' }}>
              {t('expenseNote.unit')}
            </span>
            <span align="center" style={{ width: '100px' }}>
              {t('expenseNote.unitPrice')}
            </span>
            <span align="center" style={{ width: '50px' }}>
              {t('expenseNote.discount')}
            </span>
            <span align="center" style={{ width: '50px' }}>
              {t('expenseNote.itemVat')}
            </span>
            <span align="center" style={{ width: '90px' }}>
              {includeVat ? <span>{t('expenseNote.totalIncludeVat')}</span> : <span>{t('expenseNote.totalBeforeVat')}</span>}
            </span>
            <span align="center" style={{ width: '100px' }}>
              {t('expenseNote.itemWhtType')}
            </span>

            <span align="center" style={{ width: '90px' }}>
              {t('expenseNote.itemWhtTotal')}
            </span>
          </Stack>
          <br />

          {items.map((item, index) => {
            return (
              <div key={index}>
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                  <TextField
                    name="number"
                    styles={{ root: { width: 50 } }}
                    value={items[index]['number']}
                    onChange={(event) => handleChangeItem(index, event)}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <TextField
                    name="description"
                    styles={{ root: { width: 300 } }}
                    value={items[index]['description']}
                    onChange={(event) => handleChangeItem(index, event)}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <TextField
                    name="quantity"
                    styles={{ root: { width: 70 } }}
                    type="number"
                    min="0"
                    step="1"
                    value={items[index]['quantity']}
                    onChange={(event) => handleChangeItem(index, event)}
                    onBlur={(event) => handleBlurItem(index, event)}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <Dropdown
                    name="unitCode"
                    options={unitList}
                    selectedKey={items[index]['unitCode']}
                    styles={{ root: { width: 80 } }}
                    onChange={(event, selectedOption) => {
                      console.log(selectedOption);
                      handleChangeItem(index, event, selectedOption);
                    }}
                  />
                  <TextField
                    name="price"
                    styles={{
                      root: { width: 100 },
                    }}
                    type="number"
                    min="0"
                    step=".01"
                    value={items[index]['price']}
                    onChange={(event) => handleChangeItem(index, event)}
                    onBlur={(event) => handleBlurItem(index, event)}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <TextField
                    name="discount"
                    styles={{
                      root: { width: 80 },
                    }}
                    type="number"
                    min="0"
                    step=".01"
                    value={items[index]['discount']}
                    onChange={(event) => handleChangeItem(index, event)}
                    onBlur={(event) => handleBlurItem(index, event)}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <Dropdown
                    name="percentVat"
                    options={[
                      { key: nonVat, text: nonVatText, id: 'percentVat' },
                      { key: zeroVat, text: zeroVatText, id: 'percentVat' },
                      {
                        key: percentVat,
                        text: percentVatText,
                        id: 'percentVat',
                      },
                    ]}
                    selectedKey={items[index]['percentVat']}
                    styles={{ root: { width: 65 } }}
                    onChange={(event, selectedOption) => {
                      console.log(selectedOption);
                      handleChangeItem(index, event, selectedOption);
                    }}
                  />
                  <TextField
                    name="total"
                    styles={{ root: { width: 110 } }}
                    disabled
                    readOnly
                    value={items[index]['total'].toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  />

                  <Separator vertical />

                  <ComboBox
                    name="whtIncomeCode"
                    allowFreeform={true}
                    options={whtIncomeList}
                    selectedKey={items[index]['whtIncomeCode']}
                    styles={{ root: { width: 80 } }}
                    onChange={(event, selectedOption) => {
                      console.log(selectedOption);
                      handleWhtIncomeCode(index, event, selectedOption);
                    }}
                  />

                  <ComboBox
                    name="percentWht"
                    allowFreeform={true}
                    options={percentWhtList}
                    text={String(items[index]['whtTotal'])}
                    selectedKey={items[index]['percentWht']}
                    styles={{ root: { width: 80 } }}
                    onChange={(event, selectedOption) => {
                      console.log(selectedOption);
                      handlePercentWht(index, event, selectedOption);
                    }}
                  />

                  <IconButton iconProps={{ iconName: 'Delete' }} title={t('expenseNote.delete')} onClick={() => handleRemoveItem(index)} />
                </Stack>

                <br />
              </div>
            );
          })}

          <Stack horizontal style={centerItem}>
            <PrimaryButton iconProps={{ iconName: 'Add' }} name="addItem" text={t('expenseNote.addItem')} onClick={() => handleAddItem()} />
          </Stack>
          <br />
          <Stack horizontal horizontalAlign="space-between">
            <Stack vertical>
              <Stack horizontal>
                <TextField
                  name="note"
                  label={t('expenseNote.note')}
                  styles={{ root: { width: 450 } }}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                  value={note}
                />
              </Stack>
              <Stack horizontal>
                <br />
              </Stack>
              <Stack horizontal>
                <br />
                <TextField
                  name="receivedBy"
                  label={t('expenseNote.receivedBy')}
                  styles={{ root: { width: 450 } }}
                  onChange={(e) => {
                    setReceivedBy(e.target.value);
                  }}
                  value={receivedBy}
                />
              </Stack>
              <br />
              <br />
            </Stack>
            <Stack horizontalAlign="end">
              <br />
              <Stack horizontal>
                <span style={{ width: 178, fontSize: '18px' }}>{t('expenseNote.discount')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>{' '}
                {
                  <TextField
                    name="specialDiscount"
                    styles={{
                      root: { width: 150 },
                    }}
                    type="number"
                    min="0"
                    step=".01"
                    value={discount}
                    onChange={(event) => setDiscount(event.target.value)}
                    onBlur={(event) => setDiscount(Math.round(event.target.value * 100) / 100)}
                    disabled={discountDisable}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                }
                &nbsp;&nbsp;&nbsp;&nbsp;{t('expenseNote.thb')}
              </Stack>
              <br />

              {includeVat ? (
                <Stack horizontal>
                  <span style={{ width: 178, fontSize: '18px' }}>{t('expenseNote.amountTotalBeforeVat')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={amountTotal.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    />
                  }
                  &nbsp;&nbsp;&nbsp;&nbsp;{t('expenseNote.thb')}
                </Stack>
              ) : (
                <Stack horizontal>
                  <span style={{ width: 178, fontSize: '18px' }}>{t('expenseNote.amountTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={amountTotal.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    />
                  }
                  &nbsp;&nbsp;&nbsp;&nbsp;{t('expenseNote.thb')}
                </Stack>
              )}

              <br />
              <Stack horizontal>
                <span style={{ width: 178, fontSize: '18px' }}>{t('expenseNote.vat')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {
                  <TextField
                    styles={{ root: { width: 150 } }}
                    disabled
                    readOnly
                    value={vatTotal.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  />
                }
                &nbsp;&nbsp;&nbsp;&nbsp;{t('expenseNote.thb')}
              </Stack>
              <br />
              <Stack horizontal>
                <span style={{ width: 178, fontSize: '18px' }}>{t('expenseNote.grandTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {
                  <TextField
                    styles={{ root: { width: 150 } }}
                    disabled
                    readOnly
                    value={grandTotal.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                  />
                }
                &nbsp;&nbsp;&nbsp;&nbsp;{t('expenseNote.thb')}
              </Stack>
            </Stack>
          </Stack>
          <br />
          <Stack horizontal horizontalAlign="space-between">
            <Stack vertical>
              <Stack horizontalAlign="start">
                <br />
                <Stack horizontal>
                  <span style={{ width: 250, fontSize: '18px' }}>{t('expenseNote.whtTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>{' '}
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={whtTotal.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    />
                  }
                  &nbsp;&nbsp;&nbsp;&nbsp;{t('expenseNote.thb')}
                </Stack>
                <br />
                <Stack horizontal>
                  <span style={{ width: 250, fontSize: '18px' }}>{t('expenseNote.totalToPay')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>{' '}
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={(grandTotal - whtTotal).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    />
                  }
                  &nbsp;&nbsp;&nbsp;&nbsp;{t('expenseNote.thb')}
                </Stack>
              </Stack>
            </Stack>

            <Stack vertical>
              <Stack horizontalAlign="end">
                <br />
                <Stack horizontal>
                  <span style={{ width: 178 }}>{t('expenseNote.nonVatPriceTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={nonVatPriceTotal.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    />
                  }
                  &nbsp;&nbsp;&nbsp;&nbsp;{t('expenseNote.thb')}
                </Stack>
                <br />
                <Stack horizontal>
                  <span style={{ width: 178 }}>{t('expenseNote.zeroVatPriceTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={zeroVatPriceTotal.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    />
                  }
                  &nbsp;&nbsp;&nbsp;&nbsp;{t('expenseNote.thb')}
                </Stack>
                <br />
                <Stack horizontal>
                  <span style={{ width: 178 }}>{t('expenseNote.vatPriceTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={vatPriceTotal.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      })}
                    />
                  }
                  &nbsp;&nbsp;&nbsp;&nbsp;{t('expenseNote.thb')}
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <div>
            <br />
            <Separator />
            <br />
          </div>
          <Stack horizontal>
            <Stack vertical style={{ width: 800 }}>
              <Stack horizontal>
                <Toggle
                  label={t('expenseNote.payment')}
                  onText={t('expenseNote.on')}
                  offText={t('expenseNote.off')}
                  checked={payment}
                  onChange={() => {
                    const values = [...paymentList];
                    values[0]['paidAmount'] = grandTotal - whtTotal;

                    setPaymentList(values);

                    setPayment(!payment);
                  }}
                />
              </Stack>
              {payment && (
                <Stack>
                  {paymentList.map((_payment, index) => (
                    <Stack horizontal horizontalAlign="space-between">
                      <Stack vertical>
                        <br />
                        <Separator />
                        <br />
                        <Stack horizontal>
                          <h4>
                            {t('expenseNote.payNumber')} {index + 1}
                          </h4>
                          <div style={{ marginLeft: 15, paddingTop: 10 }}>
                            <IconButton
                              iconProps={{ iconName: 'Delete' }}
                              title={t('expenseNote.delete')}
                              onClick={() => handleRemovePaymentList(index)}
                            />
                          </div>
                        </Stack>
                        <Stack horizontal>
                          <DatePicker
                            name="date"
                            label={t('expenseNote.payDate')}
                            placeholder={t('expenseNote.pleaseSelectDate')}
                            allowTextInput={false}
                            firstDayOfWeek={firstDayOfWeek}
                            strings={DayPickerStrings}
                            formatDate={onFormatDate}
                            styles={{ root: { width: 150 } }}
                            onSelectDate={(date) => handleChangePaymentDate(index, date)}
                            value={new Date(_payment.date)}
                            isRequired={true}
                            componentRef={dateRef}
                          />
                        </Stack>
                        <Stack horizontal>
                          <Dropdown
                            placeholder={t('expenseNote.pleaseSelectDate')}
                            label={t('expenseNote.payBy')}
                            options={[
                              { key: 'BANK', text: t('expenseNote.BANK') },
                              { key: 'CASH', text: t('expenseNote.CASH') },
                            ]}
                            styles={{
                              root: {
                                width: 150,
                                marginRight: 15,
                              },
                            }}
                            selectedKey={_payment['paymentCode']}
                            onChange={(event, selectedOption) => {
                              console.log(selectedOption);
                              handleChangePaymentName(index, event, selectedOption);
                            }}
                          />
                          {_payment['paymentCode'] === 'CASH' && (
                            <>
                              <TextField
                                name="paidAmount"
                                label={t('expenseNote.paidAmount')}
                                styles={{
                                  root: {
                                    width: 120,
                                    marginRight: 15,
                                  },
                                }}
                                onChange={(e) => {
                                  handleChangePaidAmount(index, e);
                                }}
                                onBlur={(event) => handleBlurPaymentList(index, event)}
                                value={_payment['paidAmount'].toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                })}
                              />
                              <TextField
                                name="paymentNote"
                                label={t('expenseNote.paymentNote')}
                                styles={{
                                  root: { width: 615 },
                                }}
                                onChange={(e) => {
                                  handleChangePaymentNote(index, e);
                                }}
                                value={_payment['paymentNote']}
                              />
                            </>
                          )}
                        </Stack>
                        {_payment['paymentCode'] === 'BANK' && (
                          <Stack horizontal>
                            <Dropdown
                              placeholder={t('expenseNote.pleaseSelect')}
                              label={t('expenseNote.bankName')}
                              options={bankList}
                              styles={{
                                root: {
                                  width: 150,
                                  marginRight: 15,
                                },
                              }}
                              selectedKey={_payment['bankCode']}
                              onChange={(event, selectedOption) => {
                                console.log(selectedOption);
                                handleChangeBankName(index, event, selectedOption);
                              }}
                            />
                            <Dropdown
                              placeholder={t('expenseNote.pleaseSelect')}
                              label={t('expenseNote.accountType')}
                              options={accountTypeList}
                              styles={{
                                root: {
                                  width: 120,
                                  marginRight: 15,
                                },
                              }}
                              selectedKey={_payment['typeCode']}
                              onChange={(event, selectedOption) => {
                                console.log(selectedOption);
                                handleChangeBankType(index, event, selectedOption);
                              }}
                            />
                            <TextField
                              name="accountName"
                              label={t('expenseNote.accountName')}
                              styles={{
                                root: {
                                  width: 285,
                                  marginRight: 15,
                                },
                              }}
                              onChange={(e) => {
                                handleChangeAccountName(index, e);
                              }}
                              value={_payment['accountName']}
                            />
                            <TextField
                              name="accountNumber"
                              label={t('expenseNote.accountNumber')}
                              styles={{
                                root: {
                                  width: 150,
                                  marginRight: 15,
                                },
                              }}
                              onChange={(e) => {
                                handleChangeAccountNumber(index, e);
                              }}
                              value={_payment['accountNumber']}
                            />
                            <TextField
                              name="paidAmount"
                              label={t('expenseNote.paidAmount')}
                              styles={{
                                root: {
                                  width: 150,
                                },
                              }}
                              onChange={(e) => {
                                handleChangePaidAmount(index, e);
                              }}
                              onBlur={(event) => handleBlurPaymentList(index, event)}
                              value={_payment['paidAmount'].toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}
                            />
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  ))}
                  <Stack horizontal>
                    <br />
                  </Stack>
                  <Stack horizontal>
                    <br />
                    <PrimaryButton
                      iconProps={{ iconName: 'Add' }}
                      style={{ letterSpacing: '1px' }}
                      name="addPaymentList"
                      text={t('expenseNote.addPaymentList')}
                      onClick={() => handleAddPaymentList()}
                    />
                    <br />
                  </Stack>
                  <Stack horizontal>
                    <br />
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>

          <br />
          <br />
          {companyName && (
            <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
              {data?.id ? (
                <PrimaryButton name="save" text={t('expenseNote.save')} iconProps={{ iconName: 'Save' }} type="submit" />
              ) : (
                <PrimaryButton style={{ width: '125px', letterSpacing: '1px' }} text={t('expenseNote.next')} type="submit" value="next" />
              )}
              <DefaultButton text={t('expenseNote.cancel')} onClick={handleCancel} />
            </Stack>
          )}
          {!companyName && <Spinner label={t('expenseNote.loading')} labelPosition="right" />}

          <br />
          <br />
        </div>
        <br />
        <br />
      </Stack>
    </form>
  );
};

export default ExpenseNote;
