import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as React from 'react';
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';

import { useTranslation } from 'react-i18next';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import ConvertType from './components/convertType';

import {
  PrimaryButton,
  DefaultButton,
  Stack,
  Selection,
  Spinner,
  CheckboxVisibility,
  SpinnerSize,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
import PdfAllPages from '../../etax/documents/pdf/view/PdfAllPages';
import { updateProductQuantity } from '../../../helpers/fetchData/updateProductQuantity';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  minWidth: 'calc(100vw - 310px)',
  background: '#FAF9F8',
  position: 'relative',
};

function ExcelSuccess(props) {
  const history = useHistory();

  const { t } = useTranslation();

  const location = useLocation();

  const [passItemsObj] = useState(location.state);
  const [items, setItems] = useState(passItemsObj.item);

  const [isCompactMode] = React.useState(false);

  const [inComplete, setInComplete] = React.useState(false);

  const [url, setUrl] = useState('');
  const [isloadingPdf, setIsloadingPdf] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);

        if (_selectedItems[0] !== undefined) {
          setIsSelectDocuments(true);
          setIsCreateDocumentComplete(false);
        } else {
          setIsSelectDocuments(false);
        }
      },
    })
  );

  const [isSelectDocuments, setIsSelectDocuments] = useState(false);
  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const [showDifferenceMonth, setShowDifferenceMonth] = useState(false);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const classNames = mergeStyleSets({
    alignRight: {
      textAlign: 'right',
    },
    top: {
      position: 'absolute',
      left: '0px',
      top: '85px',
      z_index: -1,
    },
  });

  const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4,
    },
  };

  const viewPDF = (item) => {
    setIsloadingPdf(true);

    let objToSent = ConvertType(item);

    console.log(objToSent, 'obj_to_send');
    let url = '';

    if (item.documentType === 'RECEIPT-TAXINVOICE' || item.documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษี') {
      url = API_URL + '/etax/documents/receipts-taxinvoices/pdf-preview';
    } else if (item.documentType === 'RECEIPT-TAXINVOICE-ABB' || item.documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ') {
      url = API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf-preview';
    } else if (item.documentType === 'RECEIPT' || item.documentType === 'ใบเสร็จรับเงิน') {
      url = API_URL + '/etax/documents/receipts/pdf-preview';
    } else if (item.documentType === 'INVOICE-TAXINVOICE' || item.documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี') {
      url = API_URL + '/etax/documents/invoices-taxinvoices/pdf-preview';
    } else if (item.documentType === 'DELIVERYORDER-TAXINVOICE' || item.documentType === 'ใบส่งของ/ใบกำกับภาษี') {
      url = API_URL + '/etax/documents/deliveryorders-taxinvoices/pdf-preview';
    } else if (item.documentType === 'TAXINVOICE' || item.documentType === 'ใบกำกับภาษี') {
      url = API_URL + '/etax/documents/taxinvoices/pdf-preview';
    } else if (item.documentType === 'DEBIT-NOTE' || item.documentType === 'ใบเพิ่มหนี้') {
      url = API_URL + '/etax/documents/debit-notes/pdf-preview';
    } else if (item.documentType === 'CREDIT-NOTE' || item.documentType === 'ใบลดหนี้') {
      url = API_URL + '/etax/documents/credit-notes/pdf-preview';
    } else if (item.documentType === 'QUOTATION' || item.documentType === 'ใบเสนอราคา') {
      url = API_URL + '/documents/quotations/pdf-preview';
    } else if (item.documentType === 'INVOICE' || item.documentType === 'ใบแจ้งหนี้') {
      url = API_URL + '/documents/invoices/pdf-preview';
    } else if (item.documentType === 'BILLING-NOTE' || item.documentType === 'ใบวางบิล') {
      url = API_URL + '/documents/billing-notes/pdf-preview';
    } else if (item.documentType === 'DELIVERYORDER' || item.documentType === 'ใบส่งของ') {
      url = API_URL + '/documents/deliveryorders/pdf-preview';
    }

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      if (!item.reIssue) {
        axios
          .post(url, objToSent, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'application/json',
            },
            responseType: 'blob',
          })
          .then((response) => {
            setUrl(window.URL.createObjectURL(response.data));

            setIsloadingPdf(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        axios
          .post(url, objToSent, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'application/json',
            },
            responseType: 'blob',
          })
          .then((response) => {
            setUrl(window.URL.createObjectURL(response.data));

            setIsloadingPdf(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const columns = [
    {
      key: 'column1',
      name: t('excelDropzoneUploadList.key'), // ลำดับ
      fieldName: 'key',
      minWidth: 40,
      maxWidth: 40,
    },
    {
      key: 'column2',
      name: t('excelDropzoneUploadList.dateBE'), // วันที่
      fieldName: 'dateBE',
      minWidth: 70,
      maxWidth: 120,
      isResizable: true,

      data: 'string',
      onRender: (item) => {
        return <div>{item.dateBE}</div>;
      },
    },
    {
      key: 'column3',
      name: t('excelDropzoneUploadList.referNo'), // เลขที่เอกสาร
      fieldName: 'referNo',
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,

      data: 'string',
    },
    {
      key: 'column4',
      name: t('excelDropzoneUploadList.receiptType'), // ชื่อเอกสาร (ใบเสร็จ, ใบกำกับ)
      fieldName: 'receiptType',
      minWidth: 80,
      maxWidth: 200,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        let _documentType = item.receiptType;
        if (item.receiptType === 'RECEIPT-TAXINVOICE') {
          _documentType = t('excelDropzoneUploadList.receipt-taxinvoice');
        } else if (item.receiptType === 'INVOICE-TAXINVOICE') {
          _documentType = t('excelDropzoneUploadList.invoice-taxinvoice');
        } else if (item.receiptType === 'DELIVERYORDER-TAXINVOICE') {
          _documentType = t('excelDropzoneUploadList.deliveryorder-taxinvoice');
        } else if (item.receiptType === 'TAXINVOICE') {
          _documentType = t('excelDropzoneUploadList.taxinvoice');
        } else if (item.receiptType === 'RECEIPT-TAXINVOICE-ABB') {
          _documentType = t('excelDropzoneUploadList.receipt-taxinvoice-abb');
        } else if (item.receiptType === 'RECEIPT') {
          _documentType = t('excelDropzoneUploadList.receipt');
        } else if (item.receiptType === 'DEBIT-NOTE') {
          _documentType = t('excelDropzoneUploadList.debit-note');
        } else if (item.receiptType === 'CREDIT-NOTE') {
          _documentType = t('excelDropzoneUploadList.credit-note');
        } else if (item.receiptType === 'QUOTATION') {
          _documentType = t('excelDropzoneUploadList.quotation');
        } else if (item.receiptType === 'INVOICE') {
          _documentType = t('excelDropzoneUploadList.invoice');
        } else if (item.receiptType === 'BILLING-NOTE') {
          _documentType = t('excelDropzoneUploadList.billing-note');
        } else if (item.receiptType === 'DELIVERYORDER') {
          _documentType = t('excelDropzoneUploadList.deliveryorder');
        }

        return <div>{_documentType}</div>;
      },
    },
    {
      key: 'column5',
      name: t('excelDropzoneUploadList.customerName'), // ชื่อผู้ซื้อ
      fieldName: 'customerName',
      minWidth: 80,
      maxWidth: 150,
      isResizable: true,

      data: 'string',
    },
    {
      key: 'column6',
      name: t('excelDropzoneUploadList.vat'), // จำนวนภาษีมูลค่าเพิ่ม
      fieldName: 'vat',
      className: classNames.alignRight,
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,
      data: 'number',
      onRender: (item) => {
        if (
          item.receiptType === 'RECEIPT' ||
          item.receiptType === 'ใบเสร็จรับเงิน' ||
          item.receiptType === 'QUOTATION' ||
          item.receiptType === 'ใบเสนอราคา' ||
          item.receiptType === 'INVOICE' ||
          item.receiptType === 'ใบแจ้งหนี้' ||
          item.receiptType === 'BILLING-NOTE' ||
          item.receiptType === 'ใบวางบิล' ||
          item.receiptType === 'DELIVERYORDER' ||
          item.receiptType === 'ใบส่งของ'
        ) {
          if (item.company.registerVat) {
            return <div>{item.vat}</div>;
          } else {
            return <div></div>;
          }
        } else {
          return <div>{item.vat}</div>;
        }
      },
    },
    {
      key: 'column7',
      name: t('excelDropzoneUploadList.total'),
      fieldName: 'total',
      className: classNames.alignRight,
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,
      data: 'number',
      onRender: (item) => {
        if (
          item.receiptType === 'RECEIPT' ||
          item.receiptType === 'ใบเสร็จรับเงิน' ||
          item.receiptType === 'QUOTATION' ||
          item.receiptType === 'ใบเสนอราคา' ||
          item.receiptType === 'INVOICE' ||
          item.receiptType === 'ใบแจ้งหนี้' ||
          item.receiptType === 'BILLING-NOTE' ||
          item.receiptType === 'ใบวางบิล' ||
          item.receiptType === 'DELIVERYORDER' ||
          item.receiptType === 'ใบส่งของ'
        ) {
          if (item.company.registerVat) {
            return <div>{item.total}</div>;
          } else {
            return <div>{item.amount}</div>;
          }
        } else {
          return <div>{item.total}</div>;
        }
      },
    },
    {
      key: 'column10',
      name: isCreateDocument || isSelectDocuments ? t('excelDropzoneUploadList.docStatus') : '',
      fieldName: 'command',
      minWidth: 280,
      maxWidth: 280,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isSelectDocuments) {
          if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('excelDropzoneUploadList.success')}</span>;
          } else {
            return;
          }
        } else if (isCreateDocument) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('excelDropzoneUploadList.pending')}</span>;
          } else if (item.status === 'processing') {
            return (
              <Stack horizontal>
                <Spinner label={t('excelDropzoneUploadList.processing')} labelPosition="right" />
              </Stack>
            );
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('excelDropzoneUploadList.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('excelDropzoneUploadList.fail')}</span>;
          }
        } else if (isCreateDocumentComplete) {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                styles={{ root: { marginRight: 10 } }}
                disabled={isCreateDocument}
                text={t('excelDropzoneUploadList.viewPDF')}
                iconProps={{ iconName: 'Search', style: { color: '#106ebe' } }}
                onClick={() => {
                  viewPDF(item);
                }}
              />
              {item.status === 'successful' && <span style={{ color: 'green' }}>{t('excelDropzoneUploadList.success')}</span>}
              {item.status === 'fail' && <span style={{ color: 'red' }}>{t('excelDropzoneUploadList.fail')}</span>}
              {!(item.status === 'successful' || item.status === 'fail') && (
                <DefaultButton
                  text={t('excelDropzoneUploadList.createDoc')}
                  iconProps={{ iconName: 'Add', style: { color: '#106ebe' } }}
                  disabled={isSelectDocuments}
                  onClick={() => {
                    createDocument(item);
                  }}
                />
              )}
            </span>
          );
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                styles={{ root: { marginRight: 10 } }}
                disabled={isCreateDocument}
                text={t('excelDropzoneUploadList.viewPDF')}
                iconProps={{ iconName: 'Search', style: { color: '#106ebe' } }}
                onClick={() => {
                  viewPDF(item);
                }}
              />
              {item.status === 'successful' && <span style={{ color: 'green' }}>{t('excelDropzoneUploadList.success')}</span>}
              {item.status !== 'successful' && (
                <DefaultButton
                  text={t('excelDropzoneUploadList.createDoc')}
                  iconProps={{ iconName: 'Add', style: { color: '#106ebe' } }}
                  disabled={isSelectDocuments}
                  onClick={() => {
                    createDocument(item);
                  }}
                />
              )}
            </span>
          );
        }
      },
    },
  ];

  const createDocument = async (item) => {
    let documentPath = '';

    if (item.documentType === 'RECEIPT-TAXINVOICE' || item.documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษี') {
      documentPath = '/etax/documents/receipts-taxinvoices';
    } else if (item.documentType === 'RECEIPT-TAXINVOICE-ABB' || item.documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ') {
      documentPath = '/etax/documents/receipts-taxinvoices-abb';
    } else if (item.documentType === 'RECEIPT' || item.documentType === 'ใบเสร็จรับเงิน') {
      documentPath = '/etax/documents/receipts';
    } else if (item.documentType === 'INVOICE-TAXINVOICE' || item.documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี') {
      documentPath = '/etax/documents/invoices-taxinvoices';
    } else if (item.documentType === 'DELIVERYORDER-TAXINVOICE' || item.documentType === 'ใบส่งของ/ใบกำกับภาษี') {
      documentPath = '/etax/documents/deliveryorders-taxinvoices';
    } else if (item.documentType === 'TAXINVOICE' || item.documentType === 'ใบกำกับภาษี') {
      documentPath = '/etax/documents/taxinvoices';
    } else if (item.documentType === 'DEBIT-NOTE' || item.documentType === 'ใบเพิ่มหนี้') {
      documentPath = '/etax/documents/debit-notes';
    } else if (item.documentType === 'CREDIT-NOTE' || item.documentType === 'ใบลดหนี้') {
      documentPath = '/etax/documents/credit-notes';
    } else if (item.documentType === 'QUOTATION' || item.documentType === 'ใบเสนอราคา') {
      documentPath = '/documents/quotations';
    } else if (item.documentType === 'INVOICE' || item.documentType === 'ใบแจ้งหนี้') {
      documentPath = '/documents/invoices';
    } else if (item.documentType === 'BILLING-NOTE' || item.documentType === 'ใบวางบิล') {
      documentPath = '/documents/billing-notes';
    } else if (item.documentType === 'DELIVERYORDER' || item.documentType === 'ใบส่งของ') {
      documentPath = '/documents/deliveryorders';
    }

    let objToSent = ConvertType(item);
    let _includeVat = false;
    if (objToSent.company.registerVat) {
      _includeVat = true;
    }
    if (item.documentType === 'RECEIPT-TAXINVOICE-ABB' || item.documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ') {
      objToSent.customer = {
        name: null,
        addressLineOne: null,
        addressLineTwo: null,
        postcode: null,
        branchNumber: null,
        branchText: null,
        taxNumberType: null,
        taxId: null,
        phoneCountryCode: '+66',
        phone: null,
        email: null,
        unstructure: true,
      };
    }

    if (
      item.documentType === 'DEBIT-NOTE' ||
      item.documentType === 'ใบเพิ่มหนี้' ||
      item.documentType === 'CREDIT-NOTE' ||
      item.documentType === 'ใบลดหนี้'
    ) {
      history.push({
        pathname: documentPath,
        state: {
          command: 'EditConfirm',
          amountTotal: objToSent.amountTotal,
          company: {
            ...objToSent.company,
            includeVat: _includeVat,
          },
          customer: objToSent.customer,
          date: objToSent.date,
          dateBE: objToSent.dateBE,
          dateCE: objToSent.dateCE,
          grandTotal: objToSent.grandTotal,
          items: objToSent.items,
          percentVat: objToSent.percentVat,
          receivedBy: objToSent.receivedBy,
          vatTotal: objToSent.vatTotal,
          reIssue: objToSent.reIssue,
          discount: objToSent.discount,
          backUrl: '/etax/sources/excel',
          includeVat: objToSent.includeVat,
          note: objToSent.note,
          number: objToSent.number,
          reference: objToSent.reference,
          refer: objToSent.refer,
          calculationMethod: item.calculationMethod,
        },
      });
    } else {
      if (objToSent.reIssue) {
        let _state = {
          command: 'EDIT',
          amountTotal: objToSent.amountTotal,
          company: {
            ...objToSent.company,
            includeVat: _includeVat,
          },
          customer: objToSent.customer,
          date: objToSent.date,
          dateBE: objToSent.dateBE,
          dateCE: objToSent.dateCE,
          grandTotal: objToSent.grandTotal,
          items: objToSent.items,
          percentVat: objToSent.percentVat,
          receivedBy: objToSent.receivedBy,
          vatTotal: objToSent.vatTotal,
          reIssue: objToSent.reIssue,
          discount: objToSent.discount,
          backUrl: '/etax/sources/excel',
          includeVat: objToSent.includeVat,
          note: objToSent.note,
          number: objToSent.number,
          reference: objToSent.reference,
          refer: objToSent.refer,
        };

        if (
          item.documentType === 'INVOICE-TAXINVOICE' ||
          item.documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี' ||
          item.documentType === 'DELIVERYORDER-TAXINVOICE' ||
          item.documentType === 'ใบส่งของ/ใบกำกับภาษี'
        ) {
          _state.dueDate = item.dueDate;
          _state.dueDateBE = item.dueDateBE;
          _state.dueDateCE = item.dueDateCE;
        }

        history.push({
          pathname: documentPath,
          state: _state,
        });
      } else {
        let _state = {
          command: 'EDIT',
          amountTotal: objToSent.amountTotal,
          company: {
            ...objToSent.company,
            includeVat: _includeVat,
          },
          customer: objToSent.customer,
          date: objToSent.date,
          dateBE: objToSent.dateBE,
          dateCE: objToSent.dateCE,
          grandTotal: objToSent.grandTotal,
          items: objToSent.items,
          percentVat: objToSent.percentVat,
          receivedBy: objToSent.receivedBy,
          vatTotal: objToSent.vatTotal,
          reIssue: objToSent.reIssue,
          discount: objToSent.discount,
          backUrl: '/etax/sources/excel',
          includeVat: objToSent.includeVat,
          note: objToSent.note,
          reference: objToSent.reference,
          number: objToSent.number,
        };

        if (
          item.documentType === 'INVOICE-TAXINVOICE' ||
          item.documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี' ||
          item.documentType === 'DELIVERYORDER-TAXINVOICE' ||
          item.documentType === 'ใบส่งของ/ใบกำกับภาษี'
        ) {
          _state.dueDate = item.dueDate;
          _state.dueDateBE = item.dueDateBE;
          _state.dueDateCE = item.dueDateCE;
        }

        history.push({
          pathname: documentPath,
          state: _state,
        });
      }
    }
  };

  const createSelectedDocuments = async () => {
    let _items = selection.getItems();

    let _selectedItems = selectedItems;
    _items.forEach((item) => {
      if (_selectedItems.some((selectedItem) => item.key === selectedItem.key)) {
        item.status = 'pending';
      }
    });

    setItems([..._items]);

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        for (let i = 0; i < selectedItems.length; i++) {
          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === selectedItems[i].key) {
              _items[j].status = 'processing';
              setItems([..._items]);

              let postUrl = '';

              if (selectedItems[i].documentType === 'RECEIPT-TAXINVOICE' || selectedItems[i].documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษี') {
                postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml';
              } else if (
                selectedItems[i].documentType === 'RECEIPT-TAXINVOICE-ABB' ||
                selectedItems[i].documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ'
              ) {
                postUrl = API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf';
              } else if (selectedItems[i].documentType === 'RECEIPT' || selectedItems[i].documentType === 'ใบเสร็จรับเงิน') {
                postUrl = API_URL + '/etax/documents/receipts/pdf-xml';
              } else if (selectedItems[i].documentType === 'INVOICE-TAXINVOICE' || selectedItems[i].documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี') {
                postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml';
              } else if (selectedItems[i].documentType === 'DELIVERYORDER-TAXINVOICE' || selectedItems[i].documentType === 'ใบส่งของ/ใบกำกับภาษี') {
                postUrl = API_URL + '/etax/documents/deliveryorders-taxinvoices/pdf-xml';
              } else if (selectedItems[i].documentType === 'TAXINVOICE' || selectedItems[i].documentType === 'ใบกำกับภาษี') {
                postUrl = API_URL + '/etax/documents/taxinvoices/pdf-xml';
              } else if (selectedItems[i].documentType === 'DEBIT-NOTE' || selectedItems[i].documentType === 'ใบเพิ่มหนี้') {
                postUrl = API_URL + '/etax/documents/debit-notes/pdf-xml';
              } else if (selectedItems[i].documentType === 'CREDIT-NOTE' || selectedItems[i].documentType === 'ใบลดหนี้') {
                postUrl = API_URL + '/etax/documents/credit-notes/pdf-xml';
              } else if (selectedItems[i].documentType === 'QUOTATION' || selectedItems[i].documentType === 'ใบเสนอราคา') {
                postUrl = API_URL + '/documents/quotations/pdf';
              } else if (selectedItems[i].documentType === 'INVOICE' || selectedItems[i].documentType === 'ใบแจ้งหนี้') {
                postUrl = API_URL + '/documents/invoices/pdf';
              } else if (selectedItems[i].documentType === 'BILLING-NOTE' || selectedItems[i].documentType === 'ใบวางบิล') {
                postUrl = API_URL + '/documents/billing-notes/pdf';
              } else if (selectedItems[i].documentType === 'DELIVERYORDER' || selectedItems[i].documentType === 'ใบส่งของ') {
                postUrl = API_URL + '/documents/deliveryorders/pdf';
              }

              let objToSent = ConvertType(selectedItems[i]);

              if (
                selectedItems[i].documentType === 'RECEIPT-TAXINVOICE-ABB' ||
                selectedItems[i].documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ'
              ) {
                objToSent.customer = {
                  name: null,
                  addressLineOne: null,
                  addressLineTwo: null,
                  postcode: null,
                  branchNumber: null,
                  branchText: null,
                  taxNumberType: null,
                  taxId: null,
                  phoneCountryCode: '+66',
                  phone: null,
                  email: null,
                  unstructure: true,
                };
              }

              const promise_PDF_XML = await new Promise(async (resolve) => {
                await axios
                  .post(postUrl, objToSent, {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                    },
                  })
                  .then(
                    async (response) => {
                      console.log('Create document response: ', response);

                      let count = 0;

                      let interval = setInterval(async () => {
                        await axios
                          .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                            headers: {
                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                              'Content-Type': 'application/json',
                            },
                          })
                          .then(async (jobsResponse) => {
                            count += 1;
                            console.log('Jobs response: ', j, jobsResponse.data.status, count);

                            if (jobsResponse.data.status === 'complete') {
                              _items[j].status = 'successful';

                              setItems([..._items]);

                              clearInterval(interval);

                              if (!(selectedItems[i].documentType === 'DEBIT-NOTE' || 
                                  selectedItems[i].documentType === 'ใบเพิ่มหนี้' ||
                                  selectedItems[i].documentType === 'CREDIT-NOTE' || 
                                  selectedItems[i].documentType === 'ใบลดหนี้')) {
                                
                                await updateProductQuantity(objToSent.items, tokenResponse)
                              } 


                              resolve(true);
                            } else if (count === 300) {
                              _items[j].status = 'fail';

                              setItems([..._items]);

                              clearInterval(interval);

                              resolve(false);
                            }
                          });
                      }, 1000);
                    },
                    (error) => {
                      console.log('Error Create document response: ', error);
                      _items[j].status = 'fail';
                      setItems([..._items]);
                      resolve(false);
                    }
                  );
              }); //promise

              Promise.all([promise_PDF_XML]).then((value) => {
                console.log(i, value);
              });
            } //if
          } //for j
        } //for i
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
      });

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  useEffect(() => {
    for (const obj of items) {
      if (obj.status === 'ไม่สำเร็จ') {
        setInComplete(true);

        for (const obj of items) {
          if (obj.status === '') {
            obj.status = '-';
          }
        }
      }
    }
  }, [items]);

  useEffect(() => {
    if (
      items.some((doc) => !(new Date(doc.date).getMonth() === new Date().getMonth() && new Date(doc.date).getFullYear() === new Date().getFullYear()))
    ) {
      setShowDifferenceMonth(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={homeStyles}>
      {showDifferenceMonth && (
        <MessageBar
          messageBarType={MessageBarType.warning}
          isMultiline={false}
          onDismiss={() => setShowDifferenceMonth(false)}
          dismissButtonAriaLabel={t('documents.close')}
          style={{
            fontSize: 14,
          }}
        >
          {t('excelDropzoneUploadList.showDifferenceMonth')}
        </MessageBar>
      )}
      {!url ? (
        <>
          {isloadingPdf ? (
            <Stack>
              <Stack horizontal horizontalAlign="center">
                <br />
                <br />
                <div className="certificates-loading-center">
                  <Spinner label={t('excelDropzoneUploadList.processing2')} labelPosition="down" styles={spinnerStyles} />
                </div>
                <br />
              </Stack>
            </Stack>
          ) : (
            <>
              {inComplete ? (
                <Stack horizontal horizontalAlign="center">
                  <h1>{t('excelDropzoneUploadList.tryAgain')}</h1>
                </Stack>
              ) : (
                <Stack horizontal horizontalAlign="center">
                  <h1>{t('excelDropzoneUploadList.header')}</h1>
                </Stack>
              )}

              <Stack styles={{ root: { marginBottom: 10 } }}>
                {selectedItems.length > 0 && !isCreateDocument && (
                  <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                    <PrimaryButton
                      text={t('excelDropzoneUploadList.createDoc')}
                      iconProps={{ iconName: 'Add' }}
                      onClick={async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments();
                      }}
                    />
                    <DefaultButton
                      text={t('excelDropzoneUploadList.cancel')}
                      style={{ marginLeft: '10px' }}
                      onClick={() => {
                        selection.setAllSelected(false);
                      }}
                    />
                    <br />
                  </Stack>
                )}

                {isCreateDocument && (
                  <div>
                    <Spinner label={t('excelDropzoneUploadList.signProcessing')} size={SpinnerSize.large} />
                  </div>
                )}

                {isCreateDocumentComplete && (
                  <center>
                    <PrimaryButton
                      text={t('excelDropzoneUploadList.viewAll')}
                      iconProps={{ iconName: 'Documentation' }}
                      onClick={async () => {
                        history.push({
                          pathname: '/etax/documents/all',
                          state: {},
                        });
                      }}
                    />
                  </center>
                )}
              </Stack>

              <Stack horizontal horizontalAlign="center">
                <Stack
                  vertical
                  style={{
                    width: '98%',
                    minWidth: '800px',
                    maxWidth: '1350px',
                  }}
                >
                  <DetailsList
                    items={items || []}
                    compact={isCompactMode}
                    columns={columns}
                    selection={selection}
                    selectionMode={isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    checkboxVisibility={CheckboxVisibility.always}
                    selectionPreservedOnEmptyClick={true}
                  />
                </Stack>
              </Stack>
            </>
          )}
        </>
      ) : (
        <>
          <Stack
            horizontal
            horizontalAlign="center"
            tokens={{ childrenGap: 25 }}
            style={{
              paddingTop: '10px',
              paddingBottom: '10px',
              background: '#4D4D4D',
              width: 'calc(100vw - 303px)',
              minWidth: '1113px',
            }}
          >
            <button style={{ width: '90px', height: '30px' }} onClick={() => setUrl('')}>
              {t('excelDropzoneUploadList.back')}
            </button>
          </Stack>
          <Stack horizontal horizontalAlign="center">
            <PdfAllPages pdf={url} />
          </Stack>
        </>
      )}
    </div>
  );
}

export default ExcelSuccess;
