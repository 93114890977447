import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { silentRequest } from '../../../authConfig';

import { useMsal, useAccount } from '@azure/msal-react';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  SearchBox,
  Spinner,
  SpinnerSize,
  CommandBar,
  Stack,
  PrimaryButton,
  DefaultButton,
  TextField,
  Text,
  ContextualMenu,
  CheckboxVisibility,
  Icon,
  Calendar,
  DateRangeType,
  Dialog,
  DialogType,
  DialogFooter,
  Checkbox,
  DatePicker,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

function findStart (currentPage, pageSize, initPageSize, totalOrder) {
  let start = 0

  let _initPageSize = initPageSize
  if (totalOrder !== initPageSize) {
      _initPageSize = _initPageSize - 1
  }

  if (pageSize < _initPageSize) {
    const offset = (currentPage - 1) * pageSize
    const startArray = []

    let _total = totalOrder
    
    while (_total >= _initPageSize) {
      startArray.push((startArray.length + 1) * _initPageSize)
      _total = _total - _initPageSize
    }

    if (_total < _initPageSize) {
      startArray.push(startArray.length * _initPageSize + _total)
    }
    
    start = startArray.findIndex(range => offset <= range)
  } else if (pageSize >= _initPageSize) {
    start = currentPage - 1
  } 
  
  return start
}

const onFormatDate = (date, format) => {
  if (format === 'CE') {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
  }
};

const formatDate = (d) => {
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

function TRCloud() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const companyId = sessionStorage.getItem('companyId') || '';
  
  const { t, i18n } = useTranslation();

  const [company, setCompany] = useState('');
  const [items, setItems] = useState([]);

  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';

  const [totalDocuments, setTotalDocuments] = useState(0);

  const totalProcessingDocuments = 0;

  const [documents, setDocuments] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);

  const [pageData, setPageData] = useState({});
  const pageSize = 50
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('trCloud-currentPage') || 1);

  const dateType = { key: 'document', text: 'วันที่บนเอกสาร' };

  const [createdBy, setCreatedBy] = useState('');

  const [lastStart, setLastStart] = useState(sessionStorage.getItem('trCloud-lastStart') || 0)
  const [currentStart, setCurrentStart] = useState(sessionStorage.getItem('trCloud-currentStart') || 0)
  const [initPageSize, setInitPageSize] = useState(sessionStorage.getItem('trCloud-initPageSize') || 0)

  const [documentStatus, setDocumentStatus] = useState(
    (sessionStorage.getItem('trCloud-orderStatus-key') && {
      key: sessionStorage.getItem('trCloud-orderStatus-key'),
      text: sessionStorage.getItem('trCloud-orderStatus-text'),
    }) || {
      key: 'all',
      text: t('trCloud.allType'),
    }
  );

  const [allDocs, setAllDocs] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const isCompactMode = false;

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        console.log('isCreateDocument: ', isCreateDocument);
        console.log('isCreateDocumentComplete: ', isCreateDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('trCloud-primarySort') || 'createdTime');

  const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
    if (sessionStorage.getItem('trCloud-sort-createdTime') === 'false') {
      return false;
    } else {
      return true;
    }
  });

  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('trCloud-sort-number')) {
      if (sessionStorage.getItem('trCloud-sort-number') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('trCloud-sort-customerName')) {
      if (sessionStorage.getItem('trCloud-sort-customerName') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(() => {
    if (sessionStorage.getItem('trCloud-sort-vatTotal')) {
      if (sessionStorage.getItem('trCloud-sort-vatTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem('trCloud-sort-grandTotal')) {
      if (sessionStorage.getItem('trCloud-sort-grandTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const [isSortedDescendingEtaxCreated, setIsSortedDescendingEtaxCreated] = useState(() => {
    if (sessionStorage.getItem("trCloud-sort-etaxCreated")) {
        if (sessionStorage.getItem("trCloud-sort-etaxCreated") === "true") {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
  });

  const [search, setSearch] = useState(sessionStorage.getItem('trCloud-search') || '');

  const DayPickerStrings = {
    months: [
      t('trCloud.months01'),
      t('trCloud.months02'),
      t('trCloud.months03'),
      t('trCloud.months04'),
      t('trCloud.months05'),
      t('trCloud.months06'),
      t('trCloud.months07'),
      t('trCloud.months08'),
      t('trCloud.months09'),
      t('trCloud.months10'),
      t('trCloud.months11'),
      t('trCloud.months12'),
    ],

    shortMonths: [
      t('trCloud.shortMonths01'),
      t('trCloud.shortMonths02'),
      t('trCloud.shortMonths03'),
      t('trCloud.shortMonths04'),
      t('trCloud.shortMonths05'),
      t('trCloud.shortMonths06'),
      t('trCloud.shortMonths07'),
      t('trCloud.shortMonths08'),
      t('trCloud.shortMonths09'),
      t('trCloud.shortMonths10'),
      t('trCloud.shortMonths11'),
      t('trCloud.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('trCloud.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('trCloud.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  const createDocument = async (documentType, item) => {
    // setIsLoading(true);
    let invoicesDetail = await getInvoiceDetail(item.key);

    console.log('invoicesDetail: ', invoicesDetail);

    let documentPath = '/etax/documents/receipts';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoice-abb';
        break;
      case 'RECEIPT':
        documentPath = '/etax/documents/receipts';
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices';
        break;
      case 'CREDIT-NOTE':
        documentPath = '/etax/documents/credit-notes';
        break;
      default:
        break;
    }

    let _date = new Date(item.createdTime);

    let _dateBE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
    let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

    let _customer = {
      addressLineOne: item.customerAddress ? item.customerAddress : 'N/A',
      addressLineTwo: '',
      email: item.customerEmail,
      name: item.customerName ? item.customerName : 'N/A',
      phone: item.customerPhone,
      phoneCountryCode: '+66',
      postcode: item.customerPostcode ? item.customerPostcode : '00000',
      taxId: item.customerTaxId,
      taxNumberType: item._taxNumberType,
      branchText: item._branchText,
      branchNumber: item._branchNumber,
      countryCode: 'TH',
      countryName: 'ไทย',
      language: 'th',
      unstructure: true,
    };

    console.log(_date);
    console.log(_dateBE);
    console.log(_dateCE);
    console.log('_customer: ', _customer);

    let _items = [];
    let _discount = Number(invoicesDetail.head.discount) || 0;

    invoicesDetail.detail.forEach((item, index) => {
      if (Number(item.price) * Number(item.quantity) - (item.discount ? Number(item.discount) : 0) < 0) {
        _discount = _discount + Math.abs(Number(item.price) * Number(item.quantity) - (item.discount ? Number(item.discount) : 0));
      } else {
        let _percent = item.vat.replace(/\D/g, '');
  
        let _percentVat = percentVat;
        let _percentVatText = percentVatText;
  
        if (Number(_percent) === 0) {
          _percentVat = zeroVat;
          _percentVatText = zeroVatText;
        }
        let _total = Number(item.price) * Number(item.quantity) - (item.discount ? Number(item.discount) : 0);
  
        let item_obj = {
          number: _items.length + 1 + '',
          description: item.description ? item.description : 'N/A',
          quantity: Number(item.quantity),
          unitCode: '',
          unitName: '-',
          price: Number(item.price),
          discount: item.discount ? Math.round((Number(item.discount) / Number(item.quantity)) * 100) / 100 : 0,
          percentVat: _percentVat,
          percentVatText: _percentVatText,
          total: _total,
        };
  
        _items.push(item_obj);

      }
    });

    console.log('_items: ', _items);

    let discountDisable = false;
    let vatTypeArray = [];

    _items.forEach((item) => {
      if (!vatTypeArray.includes(item.percentVat)) {
        vatTypeArray.push(item.percentVat);
      }
      if (vatTypeArray.length > 1) {
        discountDisable = true;
        _discount = 0;
      }
    });

    if (documentType === 'CREDIT-NOTE') {
      const previousAmountTotal = Number(invoicesDetail.head.cn2_original) >= 0 ? Number(invoicesDetail.head.cn2_original) : 0;

      let sumAmountTotal = 0;

      let sumDiffAmountTotal = 0;
      let sumVatTotal = 0;
      let sumGrandTotal = 0;

      let zeroVatPrice = 0;
      let vatPrice = 0;

      if (item.includeVat) {
        for (let i = 0; i < _items.length; i++) {
          sumGrandTotal += _items[i]['total'];
          if (_items[i].percentVat === zeroVat) {
            zeroVatPrice += _items[i]['total'];
          } else {
            vatPrice += _items[i]['total'];
          }
        }

        if (!discountDisable) {
          sumGrandTotal -= _discount;
          if (vatPrice !== 0) {
            vatPrice -= _discount;
          } else if (zeroVatPrice !== 0) {
            zeroVatPrice -= _discount;
          }
        }

        sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;

        zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;
        vatPrice = Math.round(vatPrice * 100) / 100;

        sumVatTotal = (vatPrice * percentVat) / (100 + percentVat) + (zeroVatPrice * zeroVat) / (100 + zeroVat);
        sumVatTotal = Math.round(sumVatTotal * 100) / 100;

        sumDiffAmountTotal = sumGrandTotal - sumVatTotal;
        sumDiffAmountTotal = Math.round(sumDiffAmountTotal * 100) / 100;

        sumAmountTotal = previousAmountTotal - sumDiffAmountTotal;
        sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;
      } else {
        for (let i = 0; i < _items.length; i++) {
          sumDiffAmountTotal += _items[i]['total'];
          if (_items[i].percentVat === zeroVat) {
            zeroVatPrice += _items[i]['total'];
          } else {
            vatPrice += _items[i]['total'];
          }
        }

        if (!discountDisable) {
          sumDiffAmountTotal -= _discount;
          if (vatPrice !== 0) {
            vatPrice -= _discount;
          } else if (zeroVatPrice !== 0) {
            zeroVatPrice -= _discount;
          }
        }

        sumDiffAmountTotal = Math.round(sumDiffAmountTotal * 100) / 100;

        zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;
        vatPrice = Math.round(vatPrice * 100) / 100;

        sumVatTotal = (vatPrice * percentVat) / 100;
        sumVatTotal = Math.round(sumVatTotal * 100) / 100;

        sumAmountTotal = previousAmountTotal - sumDiffAmountTotal;
        sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;

        sumGrandTotal = sumDiffAmountTotal + sumVatTotal;
        sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;
      }

      const today = new Date();

      let referDate = '';
      let referDateBE = '';
      let referDateCE = '';

      if (item.reference) {
        let invoiceFromNumber = item.reference ? await getInvoiceDetailByNumber(item.reference) : [];

        const referInvoice = invoiceFromNumber.find((_invoice) => _invoice.invoice_ref_no === item.reference);

        const _referDateObject = referInvoice ? new Date(referInvoice.issue_date) : today;

        referDate = _referDateObject.toISOString();
        referDateBE =
          `${_referDateObject.getDate()}`.padStart(2, '0') +
          '/' +
          `${_referDateObject.getMonth() + 1}`.padStart(2, '0') +
          '/' +
          (_referDateObject.getFullYear() + 543);
        referDateCE =
          `${_referDateObject.getDate()}`.padStart(2, '0') +
          '/' +
          `${_referDateObject.getMonth() + 1}`.padStart(2, '0') +
          '/' +
          _referDateObject.getFullYear();
      } else {
        referDate = _date.toISOString();
        referDateBE = _dateBE;
        referDateCE = _dateCE;
      }

      const _refer = {
        number: item.reference || 'N/A',
        reasonCode: 'CDNG99',
        reasonName: 'เหตุอื่น (ระบุสาเหตุ กรณีขายสินค้า)',
        specificReason: invoicesDetail.head.invoice_note || 'N/A',
        typeCode: '388',
        typeName: 'ใบกำกับภาษี',
        date: referDate,
        dateBE: referDateBE,
        dateCE: referDateCE,
        amountTotal: previousAmountTotal >= 0 ? previousAmountTotal : 0,
        diffAmountTotal: sumDiffAmountTotal >= 0 ? sumDiffAmountTotal : 0,
        items: [],
      };

      console.log("_items", _items)

      history.push({
        pathname: documentPath,
        state: {
          command: 'EditConfirm',
          amountTotal: sumAmountTotal >= 0 ? sumAmountTotal : 0,
          company: {
            ...company,
            includeVat: item.includeVat,
          },
          number: item.number,
          reference: item.reference,
          customer: _customer,
          date: _date.toISOString(),
          dateBE: _dateBE,
          dateCE: _dateCE,
          grandTotal: sumGrandTotal >= 0 ? sumGrandTotal : 0,
          items: _items,
          percentVat: percentVat,
          vatTotal: sumVatTotal >= 0 ? sumVatTotal : 0,
          discount: _discount,
          backUrl: '/etax/sources/trcloud',
          includeVat: item.includeVat,
          refer: _refer,
          calculationMethod: 'creditPrice',
          note: item.note
        },
      });
    } else {
      history.push({
        pathname: documentPath,
        state: {
          command: 'EDIT',
          amountTotal: item.amountTotal,
          company: {
            ...company,
            includeVat: item.includeVat,
          },
          number: item.number,
          reference: item.reference,
          customer: _customer,
          date: _date.toISOString(),
          dateBE: _dateBE,
          dateCE: _dateCE,
          grandTotal: item.grandTotal,
          items: _items,
          percentVat: percentVat,
          vatTotal: item.vatTotal,
          reIssue: false,
          discount: _discount,
          backUrl: '/etax/sources/trcloud',
          includeVat: item.includeVat,
          note: item.note
        },
      });
    }
  };

  const createSelectedDocuments = async (documentType) => {
    let _selectedItems = selection.getItems();

    let postUrl = '';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf';
        break;
      case 'RECEIPT':
        postUrl = API_URL + '/etax/documents/receipts/pdf-xml';
        break;
      case 'INVOICE-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/deliveryorders-taxinvoices/pdf-xml';
        break;
      case 'TAXINVOICE':
        postUrl = API_URL + '/etax/documents/taxinvoices/pdf-xml';
        break;
      case 'CREDIT-NOTE':
        postUrl = API_URL + '/etax/documents/credit-notes/pdf-xml';
        break;
      default:
        break;
    }

    if (postUrl) {
      await instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          for (let i = 0; i < selectedItems.length; i++) {
            console.log('create selected document: ', selectedItems[i]);

            for (let j = 0; j < _selectedItems.length; j++) {
              if (_selectedItems[j].key === selectedItems[i].key) {

                if (selectedItems[i].docType === 'cn2' && documentType !== 'CREDIT-NOTE') {
                  _selectedItems[j].status = 'fail';
                  setItems([..._selectedItems]);
                  continue;
                } else if (selectedItems[i].docType !== 'cn2' && documentType === 'CREDIT-NOTE') {
                  _selectedItems[j].status = 'fail';
                  setItems([..._selectedItems]);
                  continue;
                }

                _selectedItems[j].status = 'processing';
                setItems([..._selectedItems]);

                let invoicesDetail = await getInvoiceDetail(selectedItems[i].key);

                let _date = new Date(selectedItems[i].createdTime);

                let _dateBE =
                  `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
                let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

                let _customer = {
                  addressLineOne: selectedItems[i].customerAddress ? selectedItems[i].customerAddress : 'N/A',
                  addressLineTwo: '',
                  email: !selectedItems[i].customerEmail.includes('*') ? selectedItems[i].customerEmail : '',
                  name: selectedItems[i].customerName ? selectedItems[i].customerName : 'N/A',
                  phone: !selectedItems[i].customerPhone.includes('*') ? selectedItems[i].customerPhone : '',
                  phoneCountryCode: '+66',
                  postcode: selectedItems[i].customerPostcode ? selectedItems[i].customerPostcode : '00000',
                  taxId: selectedItems[i].customerTaxId,
                  taxNumberType: selectedItems[i]._taxNumberType,
                  branchText: selectedItems[i]._branchText,
                  branchNumber: selectedItems[i]._branchNumber,
                  countryCode: 'TH',
                  countryName: 'ไทย',
                  language: 'th',
                  unstructure: true,
                };

                let _items = [];
                let _discount = Number(invoicesDetail.head.discount) || 0;

                invoicesDetail.detail.forEach((item, index) => {
                  if (Number(item.price) * Number(item.quantity) - (item.discount ? Number(item.discount) : 0) < 0) {
                    _discount = _discount + Math.abs(Number(item.price) * Number(item.quantity) - (item.discount ? Number(item.discount) : 0));
                  } else {
                    let _percent = item.vat.replace(/\D/g, '');
  
                    let _percentVat = percentVat;
                    let _percentVatText = percentVatText;
  
                    if (Number(_percent) === 0) {
                      _percentVat = zeroVat;
                      _percentVatText = zeroVatText;
                    }
  
                    let _total = Number(item.price) * Number(item.quantity) - (item.discount ? Number(item.discount) : 0);
  
                    let item_obj = {
                      number: _items.length + 1 + '',
                      description: item.description ? item.description : 'N/A',
                      quantity: Number(item.quantity),
                      unitCode: '',
                      unitName: '-',
                      price: Number(item.price),
                      discount: item.discount ? Math.round((Number(item.discount) / Number(item.quantity)) * 100) / 100 : 0,
                      percentVat: _percentVat,
                      percentVatText: _percentVatText,
                      total: _total,
                    };
  
                    _items.push(item_obj);

                  }
                });

                let _amountTotal = 0;
                let _vatTotal = 0;
                let _grandTotal = 0;

                if (selectedItems[i].includeVat) {
                  _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                  _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                  _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                } else {
                  _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                  _vatTotal = Math.round(((_amountTotal * percentVat) / 100) * 100) / 100;
                  _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                }

                let _vatPriceTotal = 0;
                let _zeroVatPriceTotal = 0;
                let _nonVatPriceTotal = 0;

                _items.forEach((item) => {
                  if (item.percentVat === percentVat) {
                    _vatPriceTotal = _vatPriceTotal + item.total;
                  } else if (item.percentVat === zeroVat) {
                    _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                  }
                });

                let _data = {
                  number: selectedItems[i].number,
                  company: {
                    ...company,
                    includeVat: selectedItems[i].includeVat,
                  },
                  reference: selectedItems[i].reference,
                  customer: _customer,
                  date: _date.toISOString(),
                  dateBE: _dateBE,
                  dateCE: _dateCE,
                  grandTotal: _grandTotal,
                  items: _items,
                  percentVat: percentVat,
                  discount: _discount,
                  amountTotal: _amountTotal,
                  vatTotal: _vatTotal,
                  nonVatPriceTotal: _nonVatPriceTotal,
                  zeroVatPriceTotal: _zeroVatPriceTotal,
                  vatPriceTotal: _vatPriceTotal,
                  includeVat: selectedItems[i].includeVat,
                  reIssue: false,
                  receivedBy: createdBy,
                  createdBy: createdBy,
                  note: selectedItems[i].note,
                };

                if (documentType === 'CREDIT-NOTE') {
                  const previousAmountTotal = Number(invoicesDetail.head.cn2_original) >= 0 ? Number(invoicesDetail.head.cn2_original) : 0;

                  let sumAmountTotal = 0;

                  let sumDiffAmountTotal = 0;
                  let sumVatTotal = 0;
                  let sumGrandTotal = 0;

                  let nonVatPrice = 0;
                  let zeroVatPrice = 0;
                  let vatPrice = 0;

                  if (selectedItems[i].includeVat) {
                    for (let i = 0; i < _items.length; i++) {
                      if (_items[i].percentVat === nonVat) {
                        nonVatPrice += _items[i]['total'];
                      } else if (_items[i].percentVat === zeroVat) {
                        zeroVatPrice += _items[i]['total'];
                      } else {
                        vatPrice += _items[i]['total'];
                      }
                    }

                    const vatPriceBeforeVat = (vatPrice * 100) / (100 + percentVat);
                    const totalItem = vatPriceBeforeVat + zeroVatPrice + nonVatPrice;

                    vatPrice = vatPriceBeforeVat - (vatPriceBeforeVat / totalItem) * _discount;
                    zeroVatPrice = zeroVatPrice - (zeroVatPrice / totalItem) * _discount;
                    nonVatPrice = nonVatPrice - (nonVatPrice / totalItem) * _discount;

                    nonVatPrice = Math.round(nonVatPrice * 100) / 100;
                    zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;
                    vatPrice = Math.round(vatPrice * 100) / 100;

                    _nonVatPriceTotal = nonVatPrice;
                    _zeroVatPriceTotal = zeroVatPrice;
                    _vatPriceTotal = vatPrice;

                    sumVatTotal = (vatPrice * percentVat) / 100 + (zeroVatPrice * zeroVat) / 100;
                    sumVatTotal = Math.round(sumVatTotal * 100) / 100;

                    sumDiffAmountTotal = totalItem - _discount;
                    sumDiffAmountTotal = Math.round(sumDiffAmountTotal * 100) / 100;

                    sumAmountTotal = previousAmountTotal - sumDiffAmountTotal;
                    sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;

                    sumGrandTotal = sumDiffAmountTotal + sumVatTotal;
                    sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;
                  } else {
                    for (let i = 0; i < _items.length; i++) {
                      sumDiffAmountTotal += _items[i]['total'];
                      if (_items[i].percentVat === nonVat) {
                        nonVatPrice += _items[i]['total'];
                      } else if (_items[i].percentVat === zeroVat) {
                        zeroVatPrice += _items[i]['total'];
                      } else {
                        vatPrice += _items[i]['total'];
                      }
                    }

                    const totalItem = sumDiffAmountTotal;

                    sumDiffAmountTotal -= _discount;
                    vatPrice = vatPrice - (vatPrice / totalItem) * _discount;
                    zeroVatPrice = zeroVatPrice - (zeroVatPrice / totalItem) * _discount;
                    nonVatPrice = nonVatPrice - (nonVatPrice / totalItem) * _discount;

                    sumDiffAmountTotal = Math.round(sumDiffAmountTotal * 100) / 100;

                    nonVatPrice = Math.round(nonVatPrice * 100) / 100;
                    zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;
                    vatPrice = Math.round(vatPrice * 100) / 100;

                    _nonVatPriceTotal = nonVatPrice;
                    _zeroVatPriceTotal = zeroVatPrice;
                    _vatPriceTotal = vatPrice;

                    sumVatTotal = (vatPrice * percentVat) / 100;
                    sumVatTotal = Math.round(sumVatTotal * 100) / 100;

                    sumAmountTotal = previousAmountTotal - sumDiffAmountTotal;
                    sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;

                    sumGrandTotal = sumDiffAmountTotal + sumVatTotal;
                    sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;
                  }

                  const today = new Date();
                  let referDate = '';
                  let referDateBE = '';
                  let referDateCE = '';

                  if (selectedItems[i].reference) {
                    let invoiceFromNumber = selectedItems[i].reference ? await getInvoiceDetailByNumber(selectedItems[i].reference) : [];

                    const referInvoice = invoiceFromNumber.find((_invoice) => _invoice.invoice_ref_no === selectedItems[i].reference);

                    const _referDateObject = referInvoice ? new Date(referInvoice.issue_date) : today;

                    referDate = _referDateObject.toISOString();
                    referDateBE =
                      `${_referDateObject.getDate()}`.padStart(2, '0') +
                      '/' +
                      `${_referDateObject.getMonth() + 1}`.padStart(2, '0') +
                      '/' +
                      (_referDateObject.getFullYear() + 543);
                    referDateCE =
                      `${_referDateObject.getDate()}`.padStart(2, '0') +
                      '/' +
                      `${_referDateObject.getMonth() + 1}`.padStart(2, '0') +
                      '/' +
                      _referDateObject.getFullYear();
                  } else {
                    referDate = _date.toISOString();
                    referDateBE = _dateBE;
                    referDateCE = _dateCE;
                  }

                  const _refer = {
                    number: selectedItems[i].reference || 'N/A',
                    reasonCode: 'CDNG99',
                    reasonName: 'เหตุอื่น (ระบุสาเหตุ กรณีขายสินค้า)',
                    specificReason: invoicesDetail.head.invoice_note || 'N/A',
                    typeCode: '388',
                    typeName: 'ใบกำกับภาษี',
                    date: referDate,
                    dateBE: referDateBE,
                    dateCE: referDateCE,
                    amountTotal: previousAmountTotal >= 0 ? previousAmountTotal : 0,
                    diffAmountTotal: sumDiffAmountTotal >= 0 ? sumDiffAmountTotal : 0,
                    items: [],
                  };

                  _data = {
                    number: selectedItems[i].number,
                    company: {
                      ...company,
                      includeVat: selectedItems[i].includeVat,
                    },
                    reference: selectedItems[i].reference,
                    customer: _customer,
                    date: _date.toISOString(),
                    dateBE: _dateBE,
                    dateCE: _dateCE,
                    amountTotal: sumAmountTotal >= 0 ? sumAmountTotal : 0,
                    vatTotal: sumVatTotal >= 0 ? sumVatTotal : 0,
                    grandTotal: sumGrandTotal >= 0 ? sumGrandTotal : 0,
                    items: _items,
                    percentVat: percentVat,
                    discount: _discount,
                    includeVat: selectedItems[i].includeVat,
                    nonVatPriceTotal: _nonVatPriceTotal,
                    zeroVatPriceTotal: _zeroVatPriceTotal,
                    vatPriceTotal: _vatPriceTotal,
                    refer: _refer,
                    receivedBy: createdBy,
                    createdBy: createdBy,
                    note: selectedItems[i].note,
                  };
                }

                const promise_PDF_XML = await new Promise(async (resolve) => {
                  await axios
                    .post(postUrl, _data, {
                      headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                      },
                    })
                    .then(
                      async (response) => {
                        console.log('Create document response: ', response);

                        let count = 0;

                        let interval = await setInterval(async () => {
                          await axios
                            .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                              headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                'Content-Type': 'application/json',
                              },
                            })
                            .then((jobsResponse) => {
                              count += 1;
                              console.log('Jobs response: ', j, jobsResponse.data.status, count);

                              if (jobsResponse.data.status === 'complete') {
                                _selectedItems[j].status = 'successful';
                                _selectedItems[j].etaxCreated = true;

                                setItems([..._selectedItems]);

                                clearInterval(interval);

                                resolve(true);
                              } else if (count === 300) {
                                _selectedItems[j].status = 'fail';

                                setItems([..._selectedItems]);

                                clearInterval(interval);

                                resolve(false);
                              }
                            });
                        }, 1000);
                      },
                      (error) => {
                        console.log('Error Create document response: ', error);
                        _selectedItems[j].status = 'fail';
                        setItems([..._selectedItems]);
                        resolve(false);
                      }
                    );
                }); //promise

                Promise.all([promise_PDF_XML]).then((value) => {
                  console.log(i, value);
                });
              } //if
            } //for j
          } //for i
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });

      setIsCreateDocumentComplete(true);
      setIsCreateDocument(false);
    }

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  const columns = [
    {
      key: 'column1',
      name: t('trCloud.createdTime'),
      fieldName: 'createdTime',
      minWidth: 70,
      maxWidth: 90,
      isResizable: true,
      isSorted: primarySort === 'createdTime' ? true : false,
      isSortedDescending: !isSortedDescendingCreatedTime,
      isSortedAscending: isSortedDescendingCreatedTime,
      onColumnClick: (item) => {
        console.log('Sort document createTime!');
        setPrimarySort('createdTime');
        setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

        sessionStorage.setItem('trCloud-primarySort', 'createdTime');
        sessionStorage.setItem('trCloud-sort-createdTime', !isSortedDescendingCreatedTime === false ? 'false' : 'true');

        sessionStorage.removeItem('trCloud-sort-number');
        sessionStorage.removeItem('trCloud-sort-customerName');
        sessionStorage.removeItem('trCloud-sort-vatTotal');
        sessionStorage.removeItem('trCloud-sort-grandTotal');
        sessionStorage.removeItem('trCloud-sort-etaxCreated');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);

        setSelectedItems([]);
      },
      data: 'number',
      onRender: (item) => {
        return <span>{item.createdTime?.split('T')[0] || ''}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('trCloud.number'),
      fieldName: 'number',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        sessionStorage.setItem('trCloud-primarySort', 'number');
        sessionStorage.setItem('trCloud-sort-number', !isSortedDescendingNumber === false ? 'false' : 'true');

        sessionStorage.removeItem('trCloud-sort-createdTime');
        sessionStorage.removeItem('trCloud-sort-customerName');
        sessionStorage.removeItem('trCloud-sort-vatTotal');
        sessionStorage.removeItem('trCloud-sort-grandTotal');
        sessionStorage.removeItem('trCloud-sort-etaxCreated');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setInitPageSize(0)
        setTotalDocuments(0)
        setCurrentStart(0)

        setCurrentPage(1);

        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.number}</span>;
      },
      // isPadded: true,
    },
    {
      key: 'column3',
      name: t('trCloud.customerName'),
      fieldName: 'customerName',
      minWidth: 80,
      maxWidth: 135,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        sessionStorage.setItem('trCloud-primarySort', 'customerName');
        sessionStorage.setItem('trCloud-sort-customerName', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.removeItem('trCloud-sort-createdTime');
        sessionStorage.removeItem('trCloud-sort-number');
        sessionStorage.removeItem('trCloud-sort-vatTotal');
        sessionStorage.removeItem('trCloud-sort-grandTotal');
        sessionStorage.removeItem('trCloud-sort-etaxCreated');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setInitPageSize(0)
        setTotalDocuments(0)
        setCurrentStart(0)

        setCurrentPage(1);

        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      // isPadded: true,
    },
    {
      key: 'column4',
      name: t('trCloud.customerAddress'),
      fieldName: 'customerAddress',
      minWidth: 120,
      maxWidth: 325,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerAddress}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('trCloud.phone'),
      fieldName: 'name',
      minWidth: 80,
      maxWidth: 105,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerPhone}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: t('trCloud.vatTotal'),
      fieldName: 'vatTotal',
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      isSorted: primarySort === 'vatTotal' ? true : false,
      isSortedDescending: isSortedDescendingVatTotal,
      isSortedAscending: !isSortedDescendingVatTotal,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('vatTotal');
        setIsSortedDescendingVatTotal(!isSortedDescendingVatTotal);

        sessionStorage.setItem('trCloud-primarySort', 'vatTotal');
        sessionStorage.setItem('trCloud-sort-vatTotal', !isSortedDescendingVatTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('trCloud-sort-createdTime');
        sessionStorage.removeItem('trCloud-sort-number');
        sessionStorage.removeItem('trCloud-sort-customerName');
        sessionStorage.removeItem('trCloud-sort-grandTotal');
        sessionStorage.removeItem('trCloud-sort-etaxCreated');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setInitPageSize(0)
        setTotalDocuments(0)
        setCurrentStart(0)

        setCurrentPage(1);

        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.vatTotal?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) || ''}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: t('trCloud.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 70,
      isResizable: true,
      isSorted: primarySort === 'grandTotal' ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        setPrimarySort('grandTotal');
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        sessionStorage.setItem('trCloud-primarySort', 'grandTotal');
        sessionStorage.setItem('trCloud-sort-grandTotal', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.removeItem('trCloud-sort-createdTime');
        sessionStorage.removeItem('trCloud-sort-number');
        sessionStorage.removeItem('trCloud-sort-customerName');
        sessionStorage.removeItem('trCloud-sort-vatTotal');
        sessionStorage.removeItem('trCloud-sort-etaxCreated');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setInitPageSize(0)
        setTotalDocuments(0)
        setCurrentStart(0)

        setCurrentPage(1);

        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.grandTotal?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) || ''}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column8',
      name: 'Leceipt',
      fieldName: 'etaxCreated',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      isSorted: primarySort === "etaxCreated" ? true : false,
      isSortedDescending: isSortedDescendingEtaxCreated,
      isSortedAscending: !isSortedDescendingEtaxCreated,
      onColumnClick: () => {
        console.log("Sort document number!");
        setPrimarySort("etaxCreated");
        setIsSortedDescendingEtaxCreated(!isSortedDescendingEtaxCreated);

        sessionStorage.setItem('trCloud-primarySort', 'etaxCreated');
        sessionStorage.setItem('trCloud-sort-etaxCreated', !isSortedDescendingEtaxCreated === false ? 'false' : 'true');

        sessionStorage.removeItem('trCloud-sort-createdTime');
        sessionStorage.removeItem('trCloud-sort-number');
        sessionStorage.removeItem('trCloud-sort-customerName');
        sessionStorage.removeItem('trCloud-sort-vatTotal');
        sessionStorage.removeItem('trCloud-sort-grandTotal');

        handleSort("etaxCreated", !isSortedDescendingEtaxCreated);
    },
      data: 'string',
      onRender: (item) => {
        if (item.etaxCreated) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Icon iconName="SkypeCircleCheck" style={{ fontSize: '20px', color: 'green' }} />
            </div>
          );
        }
      },
    },
    {
      key: 'column9',
      name: '',
      fieldName: 'download',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('trCloud.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('trCloud.processing')} labelPosition="right" style={{ marginLeft: '0px' }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('trCloud.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('trCloud.fail')}</span>;
          }
        } else {
          let defaultButtonOption = [
            {
              key: 'RECEIPT-TAXINVOICE',
              text: t('trCloud.receipt-taxinvoice'),
              iconProps: { iconName: 'PageAdd' },
              onClick: () => {
                createDocument('RECEIPT-TAXINVOICE', item);
              },
            },
            {
              key: 'RECEIPT-TAXINVOICE-ABB',
              text: t('trCloud.receipt-taxinvoice-abb'),
              iconProps: { iconName: 'PageAdd' },
              onClick: () => {
                createDocument('RECEIPT-TAXINVOICE-ABB', item);
              },
            },
            {
              key: 'RECEIPT',
              text: t('trCloud.receipt'),
              iconProps: { iconName: 'PageAdd' },
              onClick: () => {
                createDocument('RECEIPT', item);
              },
            },
            {
              key: 'INVOICE-TAXINVOICE',
              text: t('trCloud.invoice-taxinvoice'),
              iconProps: { iconName: 'PageAdd' },
              onClick: () => {
                createDocument('INVOICE-TAXINVOICE', item);
              },
            },
            {
              key: 'DELIVERYORDER-TAXINVOICE',
              text: t('trCloud.deliveryorder-taxinvoice'),
              iconProps: { iconName: 'PageAdd' },
              onClick: () => {
                createDocument('DELIVERYORDER-TAXINVOICE', item);
              },
            },
            {
              key: 'TAXINVOICE',
              text: t('trCloud.taxinvoice'),
              iconProps: { iconName: 'PageAdd' },
              onClick: () => {
                createDocument('TAXINVOICE', item);
              },
            },
          ];

          if (item.docType === 'cn2') {
            defaultButtonOption = [
              {
                key: 'CREDIT-NOTE',
                text: t('trCloud.creditNote'),
                iconProps: { iconName: 'PageAdd' },
                onClick: () => {
                  createDocument('CREDIT-NOTE', item);
                },
              },
            ];
          }

          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('trCloud.create')}
                iconProps={{ iconName: 'Add', style: { color: '#106ebe' } }}
                menuProps={{
                  items: defaultButtonOption,
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        }
      },
    },
  ];

  const getMonth = (goBack) => {
    let monthNames = [
      t('trCloud.months01'),
      t('trCloud.months02'),
      t('trCloud.months03'),
      t('trCloud.months04'),
      t('trCloud.months05'),
      t('trCloud.months06'),
      t('trCloud.months07'),
      t('trCloud.months08'),
      t('trCloud.months09'),
      t('trCloud.months10'),
      t('trCloud.months11'),
      t('trCloud.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    sessionStorage.getItem('trCloud-queryMonth-key')
      ? sessionStorage.getItem('trCloud-queryMonth-type')
        ? {
            type: sessionStorage.getItem('trCloud-queryMonth-type'),
            key: sessionStorage.getItem('trCloud-queryMonth-key'),
            text: sessionStorage.getItem('trCloud-queryMonth-text'),
          }
        : {
            key: sessionStorage.getItem('trCloud-queryMonth-key'),
            text: sessionStorage.getItem('trCloud-queryMonth-text'),
          }
      : { key: getMonth(0).year + '' + getMonth(0).key, text: t('lazada.thisMonth') }
  );

  const [timeRangeDate, setTimeRangeDate] = useState(sessionStorage.getItem('trCloud-queryMonth-type') ? true : false);
  const [fromDate, setFromDate] = useState(
    sessionStorage.getItem('trCloud-queryMonth-type') ? new Date(sessionStorage.getItem('trCloud-fromDate')) : null
  );
  const [toDate, setToDate] = useState(sessionStorage.getItem('trCloud-queryMonth-type') ? new Date(sessionStorage.getItem('trCloud-toDate')) : null);
  const [timeRangeError, setTimeRangeError] = useState(false);

  const getFromTRCloud = (month, year) => {
    let _firstDay = new Date(year, month, 1);

    return formatDate(_firstDay);
  };

  const getToTRCloud = (month, year) => {
    let _lastDay = new Date(year, month + 1, 0);

    return formatDate(_lastDay);
  };

  const getFromTRCloudDMY = (day, month, year) => {
    let _firstDay = new Date(year, month, day);

    return formatDate(_firstDay);
  };

  const getToTRCloudDMY = (day, month, year) => {
    let _lastDay = new Date(year, month, day);

    return formatDate(_lastDay);
  };

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);

    return formatDate(_lastDay);
  };

  const getFromDMY = (day, month, year) => {
    let _firstDay = new Date(year, month, day);

    return formatDate(_firstDay);
  };

  const getToDMY = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);

    return formatDate(_lastDay);
  };

  const [fromTRCloud, setFromTRCloud] = useState(
    sessionStorage.getItem('trCloud-fromTRCloud') ? sessionStorage.getItem('trCloud-fromTRCloud') : getFromTRCloud(getMonth(0).month, getMonth(0).year)
  );
  const [toTRCloud, setToTRCloud] = useState(
    sessionStorage.getItem('trCloud-toTRCloud') ? sessionStorage.getItem('trCloud-toTRCloud') : getToTRCloud(getMonth(0).month, getMonth(0).year)
  );

  const [from, setFrom] = useState(
    sessionStorage.getItem('trCloud-from') ? sessionStorage.getItem('trCloud-from') : getFrom(getMonth(0).month, getMonth(0).year)
  );
  const [to, setTo] = useState(
    sessionStorage.getItem('trCloud-to') ? sessionStorage.getItem('trCloud-to') : getTo(getMonth(0).month, getMonth(0).year)
  );

  const [pageDropdown, setPageDropdown] = useState([]);

  const [date, setDate] = useState(sessionStorage.getItem('trCloud-date') ? new Date(sessionStorage.getItem('trCloud-date')) : new Date());

  const history = useHistory();

  function getDocumentTypeText(key) {
    if (key === 'all') return t('trCloud.allType');
    if (key === 'Debtor') return t('trCloud.debtor');
    if (key === 'Partial') return t('trCloud.partial');
    if (key === 'Cash') return t('trCloud.cash');
    if (key === 'Paid') return t('trCloud.paid');
    if (key === 'all') return t('trCloud.allOrderType');
    if (key === 'CREDIT-NOTE') return t('trCloud.creditNote');
  }

  function getDateText(monthObject) {
    if (monthObject.type === 'dateRange') {
      let _splitDate = monthObject.key.split(':');
      let _fromSplit = _splitDate[0].split('-');
      let _toSplit = _splitDate[1].split('-');

      if (i18n.language === 'th') {
        let _from = _fromSplit[2] + '/' + _fromSplit[1] + '/' + (Number(_fromSplit[0]) + 543);
        let _to = _toSplit[2] + '/' + _toSplit[1] + '/' + (Number(_toSplit[0]) + 543);

        return _from + ' - ' + _to;
      }
      let _from = _fromSplit[2] + '/' + _fromSplit[1] + '/' + Number(_fromSplit[0]);
      let _to = _toSplit[2] + '/' + _toSplit[1] + '/' + Number(_toSplit[0]);

      return _from + ' - ' + _to;
    }

    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('trCloud.thisMonth');

    let monthNames = [
      t('trCloud.months01'),
      t('trCloud.months02'),
      t('trCloud.months03'),
      t('trCloud.months04'),
      t('trCloud.months05'),
      t('trCloud.months06'),
      t('trCloud.months07'),
      t('trCloud.months08'),
      t('trCloud.months09'),
      t('trCloud.months10'),
      t('trCloud.months11'),
      t('trCloud.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: documentStatus.key,
      text: getDocumentTypeText(documentStatus.key),
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: 'all',
            text: t('trCloud.allType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('ใบกำกับภาษีทั้งหมด');
              setDocumentStatus({ key: 'all', text: t('trCloud.allType') });

              sessionStorage.setItem('trCloud-orderStatus-key', 'all');
              sessionStorage.setItem('trCloud-orderStatus-text', t('trCloud.allType'));

              setLoadDataComplete(false);
              setDocuments([]);
              setItems([]);
              setGetNewDocuments(true);

              setInitPageSize(0)
              setTotalDocuments(0)
              setCurrentStart(0)

              setCurrentPage(1);
            },
          },
          {
            key: 'Debtor',
            text: t('trCloud.debtor'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              setDocumentStatus({ key: 'Debtor', text: t('trCloud.debtor') });

              sessionStorage.setItem('trCloud-orderStatus-key', 'Debtor');
              sessionStorage.setItem('trCloud-orderStatus-text', t('trCloud.debtor'));

              setLoadDataComplete(false);
              setDocuments([]);
              setItems([]);
              setGetNewDocuments(true);

              setInitPageSize(0)
              setTotalDocuments(0)
              setCurrentStart(0)

              setCurrentPage(1);
            },
          },
          {
            key: 'Partial',
            text: t('trCloud.partial'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              setDocumentStatus({ key: 'Partial', text: t('trCloud.partial') });

              sessionStorage.setItem('trCloud-orderStatus-key', 'Partial');
              sessionStorage.setItem('trCloud-orderStatus-text', t('trCloud.partial'));

              setLoadDataComplete(false);
              setDocuments([]);
              setItems([]);
              setGetNewDocuments(true);

              setInitPageSize(0)
              setTotalDocuments(0)
              setCurrentStart(0)

              setCurrentPage(1);
            },
          },
          {
            key: 'Cash',
            text: t('trCloud.cash'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              setDocumentStatus({ key: 'Cash', text: t('trCloud.cash') });

              sessionStorage.setItem('trCloud-orderStatus-key', 'Cash');
              sessionStorage.setItem('trCloud-orderStatus-text', t('trCloud.cash'));

              setLoadDataComplete(false);
              setDocuments([]);
              setItems([]);
              setGetNewDocuments(true);

              setInitPageSize(0)
              setTotalDocuments(0)
              setCurrentStart(0)

              setCurrentPage(1);
            },
          },
          {
            key: 'Paid',
            text: t('trCloud.paid'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              setDocumentStatus({ key: 'Paid', text: t('trCloud.paid') });

              sessionStorage.setItem('trCloud-orderStatus-key', 'Paid');
              sessionStorage.setItem('trCloud-orderStatus-text', t('trCloud.paid'));

              setLoadDataComplete(false);
              setDocuments([]);
              setItems([]);
              setGetNewDocuments(true);

              setInitPageSize(0)
              setTotalDocuments(0)
              setCurrentStart(0)

              setCurrentPage(1);
            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      // text: queryMonth.text,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Stack styles={{ root: { padding: '12px 12px 0px 12px' } }}>
                <Checkbox
                  label={t('sellSummary.timeRange')}
                  checked={timeRangeDate}
                  onChange={(e) => {
                    setTimeRangeDate((prev) => !prev);
                  }}
                />
              </Stack>
              {timeRangeDate ? (
                <Stack>
                  <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                      <Text>{t('sellSummary.fromRange')}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '80%', justifyContent: 'center' /* alignItems: 'center' */ } }}>
                      <DatePicker
                        styles={{ root: { width: '90%' } }}
                        placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                        strings={DayPickerStrings}
                        value={fromDate}
                        maxDate={toDate}
                        formatDate={onFormatDate}
                        onSelectDate={(_date) => {
                          setFromDate(_date);

                          let adjustDate = _date;

                          setFromTRCloud(getFromTRCloudDMY(adjustDate.getDate(), adjustDate.getMonth(), adjustDate.getFullYear()));
                          setFrom(getFromDMY(adjustDate.getDate(), adjustDate.getMonth(), adjustDate.getFullYear()))
                          setTimeRangeError(false);
                          if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                      <Text>{t('sellSummary.toRange')}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '80%' /* alignItems: 'center' */ } }}>
                      <DatePicker
                        styles={{ root: { width: '90%' } }}
                        placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                        strings={DayPickerStrings}
                        value={toDate}
                        minDate={fromDate}
                        formatDate={onFormatDate}
                        onSelectDate={(_date) => {
                          setToDate(_date);

                          let adjustDate = _date;

                          setToTRCloud(getToTRCloudDMY(adjustDate.getDate(), adjustDate.getMonth(), adjustDate.getFullYear()));
                          setTo(getToDMY(adjustDate.getDate(), adjustDate.getMonth(), adjustDate.getFullYear()))
                          setTimeRangeError(false);
                          if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  {timeRangeError ? (
                    <Stack styles={{ root: { padding: '12px 0px 0px 12px' } }}>
                      <Text styles={{ root: { fontSize: 12, color: '#A4262C' } }}>{t('sellSummary.timeRangeError')}</Text>
                    </Stack>
                  ) : null}
                  <Stack horizontal horizontalAlign="end" styles={{ root: { width: '92%', height: 52, padding: '10px 0px 10px 0px' } }}>
                    <PrimaryButton
                      text={t('sellSummary.search')}
                      onClick={() => {
                        if (!timeRangeError && fromDate && toDate) {
                          setQueryMonth({
                            type: 'dateRange',
                            key: formatDate(fromDate) + ':' + formatDate(toDate),
                            text: formatDate(fromDate) + ' - ' + formatDate(toDate),
                          });

                          sessionStorage.setItem('trCloud-queryMonth-type', 'dateRange');
                          sessionStorage.setItem('trCloud-queryMonth-key', formatDate(fromDate) + ':' + formatDate(toDate));
                          sessionStorage.setItem('trCloud-queryMonth-text', formatDate(fromDate) + ' - ' + formatDate(toDate));

                          sessionStorage.setItem('trCloud-fromTRCloud', getFromTRCloudDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                          sessionStorage.setItem('trCloud-toTRCloud', getToTRCloudDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                          sessionStorage.setItem('trCloud-from', getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                          sessionStorage.setItem('trCloud-to', getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                          sessionStorage.setItem('trCloud-fromDate', fromDate.toISOString());
                          sessionStorage.setItem('trCloud-toDate', toDate.toISOString());

                          setLoadDataComplete(false);
                          setDocuments([]);
                          setItems([]);
                          setGetNewDocuments(true);

                          setInitPageSize(0)
                          setTotalDocuments(0)
                          setCurrentStart(0)

                          setCurrentPage(1);
                        }
                      }}
                      style={{ width: '125px', letterSpacing: '1px' }}
                    />
                  </Stack>
                </Stack>
              ) : (
                <Calendar
                  onSelectDate={(_date) => {
                    let monthNames = [
                      t('trCloud.months01'),
                      t('trCloud.months02'),
                      t('trCloud.months03'),
                      t('trCloud.months04'),
                      t('trCloud.months05'),
                      t('trCloud.months06'),
                      t('trCloud.months07'),
                      t('trCloud.months08'),
                      t('trCloud.months09'),
                      t('trCloud.months10'),
                      t('trCloud.months11'),
                      t('trCloud.months12'),
                    ];

                    setDate(_date);

                    sessionStorage.setItem('trCloud-date', _date.toISOString());

                    let adjustDate = _date;

                    let year = adjustDate.getFullYear() + 543;

                    setQueryMonth({
                      key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                      text: monthNames[adjustDate.getMonth()] + ' ' + year,
                    });

                    sessionStorage.setItem('trCloud-queryMonth-type', '');
                    sessionStorage.setItem(
                      'trCloud-queryMonth-key',
                      adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0')
                    );
                    sessionStorage.setItem('trCloud-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                    setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                    setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                    setFromTRCloud(getFromTRCloud(adjustDate.getMonth(), adjustDate.getFullYear()));
                    setToTRCloud(getToTRCloud(adjustDate.getMonth(), adjustDate.getFullYear()));

                    sessionStorage.setItem('trCloud-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                    sessionStorage.setItem('trCloud-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));
                    
                    sessionStorage.setItem('trCloud-fromTRCloud', getFromTRCloud(adjustDate.getMonth(), adjustDate.getFullYear()));
                    sessionStorage.setItem('trCloud-toTRCloud', getToTRCloud(adjustDate.getMonth(), adjustDate.getFullYear()));

                    setLoadDataComplete(false);
                    setDocuments([]);
                    setItems([]);
                    setGetNewDocuments(true);

                    setInitPageSize(0)
                    setTotalDocuments(0)
                    setCurrentStart(0)

                    setCurrentPage(1);
                  }}
                  isMonthPickerVisible={true}
                  highlightSelectedMonth={true}
                  isDayPickerVisible={false}
                  dateRangeType={DateRangeType.Day}
                  autoNavigateOnSelection={false}
                  showGoToToday={false}
                  value={date}
                  strings={DayPickerStrings}
                  showSixWeeksByDefault={true}
                />
              )}
            </div>
          );
        },
      },
    },
    // {
    //   key: 'pageSize ' + pageSize,
    //   text: pageSize + ' ' + t('shopee.documentPerPage'),
    //   cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
    //   iconProps: { iconName: 'NumberedList' },
    //   disabled: !loadDataComplete || isCreateDocument,
    //   subMenuProps: {
    //     items: [
    //       {
    //         key: 'pageSize ' + 10,
    //         text: '10 ' + t('shopee.documentPerPage'),
    //         iconProps: { iconName: 'NumberedList' },
    //         onClick: () => {
    //           console.log('10 ออเดอร์/หน้า');
    //           setPageSize(10);
    //           sessionStorage.setItem('trCloud-pageSize', 10);

    //           setLoadDataComplete(false);
    //           setDocuments([]);
    //           setItems([]);
    //           setGetNewDocuments(true);

    //           setInitPageSize(0)
    //           setTotalDocuments(0)
    //           setCurrentStart(0)

    //           setCurrentPage(1);
    //         },
    //       },
    //       // {
    //       //   key: 'pageSize ' + 20,
    //       //   text: '20 ' + t('shopee.documentPerPage'),
    //       //   iconProps: { iconName: 'NumberedList' },
    //       //   onClick: () => {
    //       //     console.log('20 ออเดอร์/หน้า');
    //       //     setPageSize(20);
    //       //     sessionStorage.setItem('trCloud-pageSize', 20);

    //       //     setLoadDataComplete(false);
    //       //     setDocuments([]);
    //       //     setItems([]);
    //       //     setGetNewDocuments(true);

    //       //     setInitPageSize(0)
    //       //     setTotalDocuments(0)
    //       //     setCurrentStart(0)

    //       //     setCurrentPage(1);
    //       //   },
    //       // },
    //       // {
    //       //   key: 'pageSize ' + 30,
    //       //   text: '30 ' + t('shopee.documentPerPage'),
    //       //   iconProps: { iconName: 'NumberedList' },
    //       //   onClick: () => {
    //       //     console.log('30 ออเดอร์/หน้า');
    //       //     setPageSize(30);
    //       //     sessionStorage.setItem('trCloud-pageSize', 30);

    //       //     setLoadDataComplete(false);
    //       //     setDocuments([]);
    //       //     setItems([]);
    //       //     setGetNewDocuments(true);

    //       //     setInitPageSize(0)
    //       //     setTotalDocuments(0)
    //       //     setCurrentStart(0)

    //       //     setCurrentPage(1);
    //       //   },
    //       // },
    //       // {
    //       //   key: 'pageSize ' + 40,
    //       //   text: '40 ' + t('shopee.documentPerPage'),
    //       //   iconProps: { iconName: 'NumberedList' },
    //       //   onClick: () => {
    //       //     console.log('40 ออเดอร์/หน้า');
    //       //     setPageSize(40);
    //       //     sessionStorage.setItem('trCloud-pageSize', 40);

    //       //     setLoadDataComplete(false);
    //       //     setDocuments([]);
    //       //     setItems([]);
    //       //     setGetNewDocuments(true);

    //       //     setInitPageSize(0)
    //       //     setTotalDocuments(0)
    //       //     setCurrentStart(0)

    //       //     setCurrentPage(1);
    //       //   },
    //       // },
    //       {
    //         key: 'pageSize ' + 50,
    //         text: '50 ' + t('shopee.documentPerPage'),
    //         iconProps: { iconName: 'NumberedList' },
    //         onClick: () => {
    //           console.log('50 ออเดอร์/หน้า');
    //           setPageSize(50);
    //           sessionStorage.setItem('trCloud-pageSize', 50);

    //           setLoadDataComplete(false);
    //           setDocuments([]);
    //           setItems([]);
    //           setGetNewDocuments(true);

    //           setInitPageSize(0)
    //           setTotalDocuments(0)
    //           setCurrentStart(0)

    //           setCurrentPage(1);
    //         },
    //       },
    //       // {
    //       //   key: 'pageSize ' + 100,
    //       //   text: '100 ' + t('shopee.documentPerPage'),
    //       //   iconProps: { iconName: 'NumberedList' },
    //       //   disabled: initPageSize < 100,
    //       //   onClick: () => {
    //       //     console.log('100 ออเดอร์/หน้า');
    //       //     setPageSize(100);
    //       //     sessionStorage.setItem('trCloud-pageSize', 100);

    //       //     setLoadDataComplete(false);
    //       //     setDocuments([]);
    //       //     setItems([]);
    //       //     setGetNewDocuments(true);

    //       //     setInitPageSize(0)
    //       //     setTotalDocuments(0)
    //       //     setCurrentStart(0)

    //       //     setCurrentPage(1);
    //       //   },
    //       // },
    //     ],
    //   },
    // },
    {
      key: currentPage,
      text: t('shopee.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: 'refresh',
      text: t('trCloud.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isCreateDocument,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
  ];

  const overflowItems = [
    {
      key: 'disconnect',
      text: t('trCloud.disconnect'),
      onClick: () => {
        console.log('TRCloud disconnect!');
        setDialogConfirm((prev) => ({
          ...prev,
          isClick: true,
          header: t('trCloud.disconnect'),
          message: t('trCloud.disconnectConfirm'),
          onConfirm: () => {
            disconnect(true);
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));
          },
          onCancel: () =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            })),
        }));
      },
      iconProps: { iconName: 'PlugDisconnected' },
    },
  ];

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + '/sources',
              {
                source: 'trcloud',
                replace: '/disabled',
                value: true,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              }
            )
            .then((response) => {
              console.log('disconnect: ', response);

              history.push({
                pathname: '/etax/sources/trcloud/connect',
                state: {},
              });

              setIsLoading(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  async function getInvoiceDetail(invoiceId) {
    let invoiceDetail = {};

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        await axios
          .get(
            API_URL + '/sources/trcloud/invoices/' + invoiceId
            // 'http://localhost:7071/api/TRCloud_getInvoiceDetail'
          , {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
            // params: {
            //   invoiceId: invoiceId
            // }
          })
          .then(
            function (response) {
              invoiceDetail = response.data;
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    return invoiceDetail;
  }

  async function getInvoiceDetailByNumber(invoiceNumber) {
    let invoiceDetail = {};

    console.log('invoiceNumber', invoiceNumber)

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        await axios
          .get(
            // API_URL + `/sources/trcloud/invoices?invoiceNumber=${invoiceNumber}`
            API_URL + `/sources/trcloud/invoices/search`
            , {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
            params: {
              from: '',
              to: '',
              status: '',
              keyword: invoiceNumber,
              start: 0,
              // keyword: invoiceNumber,
            },
          })
          .then(
            function (response) {
              invoiceDetail = response.data.result;
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    return invoiceDetail;
  }

  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  setCompany(_company);
                } else {
                  setCompany(response.data[0]);

                }

                setGetNewDocuments(true);
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('getNewOrder Running!!!');
    
    // const companyId = 15
    // const passkey = "300eab085db033f64b49d253146cb1b9"
    // const encryptHead = "5db033"
    // const endpointServer = "https://fmacc.trcloud.co"
    // const origin = "https://my.leceipt.com"

    if (getNewDocuments) {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          const [response, responseAllDocs] = await Promise.all([
            search ? 
            axios
            .get(
              // API_URL + `/sources/trcloud/invoices?invoiceNumber=${search}`
              API_URL + `/sources/trcloud/invoices/search`
            //   'http://localhost:7071/api/TRCloud_searchInvoiceByKeyword'
              , {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
              params: {
                from: fromTRCloud,
                to: toTRCloud,
                status: documentStatus.key !== 'all' ? documentStatus.key : '',
                keyword: search,
                start: currentStart
              },
            }).catch((error) => console.log(error)) 
            :
            axios
              .get(
                API_URL + '/sources/trcloud/invoices'
                // "http://localhost:7071/api/TRCloud_getInvoiceList"
                , {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
                params: {
                  from: fromTRCloud,
                  to: toTRCloud,
                  status: documentStatus.key !== 'all' ? documentStatus.key : '',
                  start: currentStart
                },
              })
              .catch((error) => console.log(error))
              ,

            axios
              .get(API_URL + '/etax/documents/ALL/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              })
              .catch((error) => console.log(error)),
          ]);

          console.log('response from backend', response)
          console.log("responseAllDocs", responseAllDocs)

          // const responseResult = response.data.result
          const responseTotal = Number(response.data.count)
          const resultRemovedDuplicate = response.data.result
          const _currentStart = Number(response.data.start)

          let invoices = resultRemovedDuplicate

          setTotalDocuments(responseTotal)

          let _pageSize = pageSize

          if (_currentStart === 0) {
            let _initPageSize = [...response.data.result].length
            let _lastStart = 0
            if ([...response.data.result].length !== responseTotal) {
              _initPageSize = [...response.data.result].length - 1
              _lastStart = Math.trunc(responseTotal / _initPageSize)
            }

            // if (_initPageSize >= 100) {
            //   _pageSize = 100
            //   setPageSize(100)
            // } else if (_initPageSize >= 50) {
            //   _pageSize = 50
            //   setPageSize(50)
            // } else {
            //   _pageSize = 10
            //   setPageSize(10)
            // }
            // sessionStorage.setItem('trCloud-pageSize', _pageSize)

            setInitPageSize(_initPageSize)

            setLastStart(_lastStart)

            sessionStorage.setItem('trCloud-initPageSize', _initPageSize)
            sessionStorage.setItem('trCloud-lastStart', _lastStart)
            
            if (_currentStart !== _lastStart) {
              invoices.pop()
            }
          } else if (_currentStart !== Number(lastStart)) {
            invoices.pop()
          }

          setCurrentStart(_currentStart)

          sessionStorage.setItem('trCloud-currentStart', _currentStart)
          sessionStorage.setItem('trCloud-search', search)

          setPageData(paginate(responseTotal, currentPage, _pageSize, 10));
          setDocuments(invoices);

          setAllDocs(responseAllDocs.data);

          let _pageDropdown = [];

            for (let i = 1; i <= Math.ceil(responseTotal / _pageSize); i++) {
              _pageDropdown.push({
                key: i,
                text: t('lazada.page') + ' ' + i,
                iconProps: { iconName: 'Page' },
                onClick: () => {
                  console.log('Selected page: ' + i);
                  setCurrentPage(i);
                },
              });
            }

            setPageDropdown(_pageDropdown);

          const _docs = [];

          if (invoices.length > 0) {
            if (primarySort === 'createdTime') {
              if (isSortedDescendingCreatedTime) {
                invoices = invoices.reverse();
              }
            } else if (primarySort === 'number') {
              if (isSortedDescendingNumber) {
                invoices.sort((a, b) => {
                  return b.invoice_number - a.invoice_number;
                });
              } else {
                invoices.sort((a, b) => {
                  return a.invoice_number - b.invoice_number;
                });
              }
            }
            if (primarySort === 'customerName') {
              if (isSortedDescendingCustomerName) {
                invoices.sort((a, b) => {
                  let NameA = a.organization || a.name;
                  let NameB = b.organization || b.name;

                  if (NameB < NameA) {
                    return -1;
                  }
                  if (NameB > NameA) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                invoices.sort((a, b) => {
                  let NameA = a.organization || a.name;
                  let NameB = b.organization || b.name;

                  if (NameA < NameB) {
                    return -1;
                  }
                  if (NameA > NameB) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'vatTotal') {
              if (isSortedDescendingVatTotal) {
                invoices.sort(function (a, b) {
                  return Math.round(Number(b.tax.replace(',', '')) * 100) / 100 - Math.round(Number(a.tax.replace(',', '')) * 100) / 100;
                });
              } else {
                invoices.sort(function (a, b) {
                  return Math.round(Number(a.tax.replace(',', '')) * 100) / 100 - Math.round(Number(b.tax.replace(',', '')) * 100) / 100;
                });
              }
            } else if (primarySort === 'grandTotal') {
              if (isSortedDescendingGrandTotal) {
                invoices.sort(function (a, b) {
                  return (
                    Math.round(Number(b.grand_total.replace(',', '')) * 100) / 100 - Math.round(Number(a.grand_total.replace(',', '')) * 100) / 100
                  );
                });
              } else {
                invoices.sort(function (a, b) {
                  return (
                    Math.round(Number(b.grand_total.replace(',', '')) * 100) / 100 - Math.round(Number(a.grand_total.replace(',', '')) * 100) / 100
                  );
                });
              }
            }

            let initPage = initPageSize
            if (_currentStart === 0) {
              initPage = invoices.length
              if (invoices.length !== responseTotal) {
                initPage = invoices.length - 1
              }
            }
            // for (let i = 0; i < Math.min(pageSize, invoices.length); i++) {
            for (let i = ((currentPage - 1) - Math.ceil(_currentStart * initPage / _pageSize)) * _pageSize; i < Math.min((currentPage - Math.ceil(_currentStart * initPage / _pageSize)) * _pageSize, invoices.length); i++) {
              let createdTime = invoices[i].issue_date;

              let _amountTotal = Math.round(Number(invoices[i].total.replace(',', '')) * 100) / 100;
              let _vatTotal = Math.round(Number(invoices[i].tax.replace(',', '')) * 100) / 100;
              let _grandTotal = Math.round(Number(invoices[i].grand_total) * 100) / 100;

              let _email = invoices[i].email;
              let _customerAddress = invoices[i].address.replaceAll("\r\n", " ").substring(0, 256)
              let _customerPostcode = '';
              let _customerPhone = invoices[i].telephone;
              let _taxId = invoices[i].tax_id ? invoices[i].tax_id.replaceAll(" ", "").replaceAll("-", "").replaceAll("\n", "").replaceAll("\t", "").replaceAll("\r", "") : "";

              const findTaxIdList = _taxId.match(/\d+/g);
              console.log("findTaxIdList", findTaxIdList)
              if (findTaxIdList) {
                findTaxIdList.forEach((number) => {
                  if (number.length === 13) {
                    _taxId = number;
                  }
                });
              }

              if (_customerPhone?.[0] === '6' && _customerPhone?.[1] === '6') {
                if (_customerPhone?.[2] !== '0') {
                  _customerPhone = _customerPhone.replace('66', '0');
                } else {
                  _customerPhone = _customerPhone.replace('66', '');
                }
              } else if (_customerPhone?.[0] === '+' && _customerPhone?.[1] === '6' && _customerPhone?.[2] === '6') {
                if (_customerPhone?.[3] !== '0') {
                  _customerPhone = _customerPhone.replace('+66', '0');
                } else {
                  _customerPhone = _customerPhone.replace('+66', '');
                }
              } else if (_customerPhone?.includes("(+66)")) {
                if (_customerPhone?.[5] !== '0') {
                  _customerPhone = _customerPhone.replace('(+66)', '0');
                } else {
                  _customerPhone = _customerPhone.replace('(+66)', '');
                }
              }


              if (_customerPhone.includes('*')) {
                _customerPhone = '';
              }

              if (_email.includes('*')) {
                _email = '';
              }

              let _taxNumberType = '';
              let _branchNumber = '';
              let _branchText = '';

              if (_taxId !== '' && ((invoices[i].branch !== '' && invoices[i].branch !== '-') || (
                invoices[i].organization.includes("บริษัท") ||
                invoices[i].organization.includes("ห้างหุ้นส่วน") ||
                invoices[i].organization.includes("Company") ||
                invoices[i].organization.includes("company") ||
                invoices[i].organization.includes("ltd.") ||
                invoices[i].organization.includes("Ltd.") ||
                invoices[i].organization.includes("Limited") ||
                invoices[i].organization.includes("limited") ||
                invoices[i].name.includes("บริษัท") ||
                invoices[i].name.includes("ห้างหุ้นส่วน") ||
                invoices[i].name.includes("Company") ||
                invoices[i].name.includes("company") ||
                invoices[i].name.includes("ltd.") ||
                invoices[i].name.includes("Ltd.") ||
                invoices[i].name.includes("Limited") ||
                invoices[i].name.includes("limited")
              ))) {
                // _taxNumberType = 'TXID';
                // _branchText = 'สำนักงานใหญ่';

                // if (invoices[i].branch.toLowerCase().includes('head office')) _branchText = 'Head Office';
                // _branchNumber = '00000';
                if (
                  invoices[i].branch.includes('สำนักงานใหญ่') ||
                  invoices[i].branch.toLowerCase().includes('head office') ||
                  Number(invoices[i].branch) === 0 ||
                  isNaN(invoices[i].branch)
                ) {
                  _branchNumber = '00000';
                  _branchText = 'สำนักงานใหญ่';

                  if (invoices[i].branch.toLowerCase().includes('head office')) {
                    _branchText = 'Head Office';
                  }
                } else {
                  _branchNumber = invoices[i].branch.padStart(5, '0');
                  _branchText = 'สาขาที่ ' + invoices[i].branch.padStart(5, '0');
                }

                _taxNumberType = 'TXID';
              } else if (
                _taxId !== '' && 
                !isNaN(_taxId) &&
                !_taxId.includes('*') &&
                (invoices[i].branch === '' || invoices[i].branch === '-')
              ) {
                _taxNumberType = 'NIDN';
                if (_taxId.length !== 13) {
                  _taxId = _taxId.padStart(13, "0")
                }
              } else {
                _taxNumberType = 'OTHR';
                _taxId = 'N/A';
                _branchNumber = '';
                _branchText = '';
              }

              if (_taxId.includes('*') || !_taxId || isNaN(_taxId) || Number(_taxId) === 0) {
                _taxNumberType = 'OTHR';
                _taxId = 'N/A';
                _branchNumber = '';
                _branchText = '';
              }

              let findPostcode = _customerAddress.match(/\d+/g);

              if (findPostcode) {
                findPostcode.forEach((number) => {
                  if (number.length === 5) {
                    _customerPostcode = number;
                    _customerAddress = _customerAddress.replace(number, '');
                  }
                });
              }

              _docs.push({
                key: invoices[i].invoice_id,
                createdTime: createdTime,
                number: invoices[i].invoice_ref_no,
                reference: invoices[i].reference,
                customerName: invoices[i].organization.replaceAll("\r\n", " ") || invoices[i].name.replaceAll("\r\n", " "),
                amountTotal: _amountTotal,
                vatTotal: _vatTotal,
                grandTotal: _grandTotal,
                customerAddress: _customerAddress,
                customerPhone: _customerPhone,
                customerEmail: _email,
                customerPostcode: _customerPostcode || '00000',
                customerTaxId: _taxId,
                _branchNumber: _branchNumber,
                _branchText: _branchText,
                _taxNumberType: _taxNumberType,
                includeVat: invoices[i].tax_option === 'in' ? true : false,
                etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === invoices[i].invoice_ref_no),
                docType: invoices[i].doc_type
              });
            }
          }

          if (primarySort === 'etaxCreated') {
            if (isSortedDescendingEtaxCreated) {
              _docs.sort(function(x, y) {
                  return (x.etaxCreated === y.etaxCreated) ? 0 : x.etaxCreated ? -1 : 1;
              })
            } else {
              _docs.sort(function(x, y) {
                  return (x.etaxCreated === y.etaxCreated) ? 0 : x.etaxCreated ? 1 : -1;
              })
            }
          }

          setItems(_docs);
          setLoadDataComplete(true);
          setGetNewDocuments(false);
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    sessionStorage.setItem('trCloud-currentPage', currentPage)
    
    const _start = findStart(currentPage, pageSize, initPageSize, totalDocuments)

    if (_start !== currentStart) {
      setCurrentStart(_start)

      setLoadDataComplete(false);
      setDocuments([]);
      setItems([]);
      setGetNewDocuments(true);

    } else {
      if (documents.length > 0) {
        const _docs = [];

        for (let i = ((currentPage - 1) - Math.ceil(currentStart * initPageSize / pageSize)) * pageSize; i < Math.min((currentPage - Math.ceil(currentStart * initPageSize / pageSize)) * pageSize, documents.length); i++) {
          let createdTime = documents[i].issue_date;

          let _amountTotal = Math.round(Number(documents[i].total.replace(',', '')) * 100) / 100;
          let _vatTotal = Math.round(Number(documents[i].tax.replace(',', '')) * 100) / 100;
          let _grandTotal = Math.round(Number(documents[i].grand_total) * 100) / 100;

          let _email = documents[i].email;
          let _customerAddress = documents[i].address.replaceAll("\r\n", " ").substring(0, 256)
          let _customerPostcode = '';
          let _customerPhone = documents[i].telephone;
          let _taxId = documents[i].tax_id ? documents[i].tax_id.replaceAll(" ", "").replaceAll("-", "").replaceAll("\n", "").replaceAll("\t", "").replaceAll("\r", "") : "";

          const findTaxIdList = _taxId.match(/\d+/g);
              console.log("findTaxIdList", findTaxIdList)
              if (findTaxIdList) {
                findTaxIdList.forEach((number) => {
                  if (number.length === 13) {
                    _taxId = number;
                  }
                });
              }

          if (_customerPhone?.[0] === '6' && _customerPhone?.[1] === '6') {
            if (_customerPhone?.[2] !== '0') {
              _customerPhone = _customerPhone.replace('66', '0');
            } else {
              _customerPhone = _customerPhone.replace('66', '');
            }
          } else if (_customerPhone?.[0] === '+' && _customerPhone?.[1] === '6' && _customerPhone?.[2] === '6') {
            if (_customerPhone?.[3] !== '0') {
              _customerPhone = _customerPhone.replace('+66', '0');
            } else {
              _customerPhone = _customerPhone.replace('+66', '');
            }
          } else if (_customerPhone?.includes("(+66)")) {
            if (_customerPhone?.[5] !== '0') {
              _customerPhone = _customerPhone.replace('(+66)', '0');
            } else {
              _customerPhone = _customerPhone.replace('(+66)', '');
            }
          }

          if (_customerPhone.includes('*')) {
            _customerPhone = '';
          }

          if (_email.includes('*')) {
            _email = '';
          }

          let _taxNumberType = '';
          let _branchNumber = '';
          let _branchText = '';

          if (_taxId !== '' && ((documents[i].branch !== '' && documents[i].branch !== '-') || (
            documents[i].organization.includes("บริษัท") ||
            documents[i].organization.includes("ห้างหุ้นส่วน") ||
            documents[i].organization.includes("Company") ||
            documents[i].organization.includes("company") ||
            documents[i].organization.includes("ltd.") ||
            documents[i].organization.includes("Ltd.") ||
            documents[i].organization.includes("Limited") ||
            documents[i].organization.includes("limited") ||
            documents[i].name.includes("บริษัท") ||
            documents[i].name.includes("ห้างหุ้นส่วน") ||
            documents[i].name.includes("Company") ||
            documents[i].name.includes("company") ||
            documents[i].name.includes("ltd.") ||
            documents[i].name.includes("Ltd.") ||
            documents[i].name.includes("Limited") ||
            documents[i].name.includes("limited")
          ))) {
            if (
              documents[i].branch.includes('สำนักงานใหญ่') ||
              documents[i].branch.toLowerCase().includes('head office') ||
              Number(documents[i].branch) === 0 ||
              isNaN(documents[i].branch)
            ) {
              _branchNumber = '00000';
              _branchText = 'สำนักงานใหญ่';

              if (documents[i].branch.toLowerCase().includes('head office')) {
                _branchText = 'Head Office';
              }
            } else {
              _branchNumber = documents[i].branch.padStart(5, '0');
              _branchText = 'สาขาที่ ' + documents[i].branch.padStart(5, '0');
            }

            _taxNumberType = 'TXID';
          } else if (
            _taxId !== '' && 
            !isNaN(_taxId) && 
            !_taxId.includes('*') && 
            (documents[i].branch === '' || documents[i].branch === '-')
            ) {
            _taxNumberType = 'NIDN';
            if (_taxId.length !== 13) {
              _taxId = _taxId.padStart(13, "0")
            }
          } else {
            _taxNumberType = 'OTHR';
            _taxId = 'N/A';
            _branchNumber = '';
            _branchText = '';
          }

          if (_taxId.includes('*') || !_taxId || isNaN(_taxId)) {
            _taxNumberType = 'OTHR';
            _taxId = 'N/A';
            _branchNumber = '';
            _branchText = '';
          }

          let findPostcode = _customerAddress.match(/\d+/g);

          if (findPostcode) {
            findPostcode.forEach((number) => {
              if (number.length === 5) {
                _customerPostcode = number;
                _customerAddress = _customerAddress.replace(number, '');
              }
            });
          }

          _docs.push({
            key: documents[i].invoice_id,
            createdTime: createdTime,
            number: documents[i].invoice_ref_no,
            reference: documents[i].reference,
            customerName: documents[i].organization.replaceAll("\r\n", " ") || documents[i].name.replaceAll("\r\n", " "),
            amountTotal: _amountTotal,
            vatTotal: _vatTotal,
            grandTotal: _grandTotal,
            customerAddress: _customerAddress,
            customerPhone: _customerPhone,
            customerEmail: _email,
            customerPostcode: _customerPostcode || '00000',
            customerTaxId: _taxId,
            _branchNumber: _branchNumber,
            _branchText: _branchText,
            _taxNumberType: _taxNumberType,
            note: documents[i].invoice_note,
            includeVat: documents[i].tax_option === 'in' ? true : false,
            etaxCreated: allDocs.some((_allDocs) => _allDocs.data.number === documents[i].invoice_ref_no),
            docType: documents[i].doc_type
          });
        }

        setItems(_docs);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleSort = (primary, isSorted) => {
    if (items.length > 0) {
        let _items = selection.getItems();
        let _selectedItems = selection.getSelection();
        console.log("selection: ", selection.getSelection());

        for (let i = 0; i < _selectedItems.length; i++) {
            console.log("select item key: ", _selectedItems[i].key);
            _selectedItems[i].status = "";

            for (let j = 0; j < _items.length; j++) {
                if (_items[j].key === _selectedItems[i].key) {
                    console.log("item key: ", _items[j].key);
                    _items[j].status = "";
                    _selectedItems[i].status = "";
                }
            }
        }

        selection.setAllSelected(false);

        if (primary === "etaxCreated") {
            if (isSorted) {
                _items.sort(function(x, y) {
                    return (x.etaxCreated === y.etaxCreated) ? 0 : x.etaxCreated ? -1 : 1;
                })

                setItems(_items);
            } else {
                _items.sort(function(x, y) {
                    return (x.etaxCreated === y.etaxCreated) ? 0 : x.etaxCreated ? 1 : -1;
                })

                setItems(_items);
            }
        }
    }
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  useEffect(() => {
    console.log('isCreateDocumentComplete useEffect: ', isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
      let _items = selection.getItems();
      console.log('_items: ', _items);

      let _selectedItems = selection.getSelection();

      if (isCreateDocumentComplete) {
        setIsCreateDocumentComplete(false);

        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status != '') {
            _items[i].status = '';
          }
        }
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status != '') {
            _items[i].status = '';
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {
          console.log('select item key: ', _selectedItems[i].key);

          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === _selectedItems[i].key) {
              console.log('set pending item key: ', _items[j].key);
              _items[j].status = 'pending';
              console.log('_items: ', _items);
            }
          }
        }
      }

      setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  return (
    <div style={homeStyles}>
      {!isLoading ? (
        <div>
          <Stack>
            <center>
              <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('trCloud.header')}</h2>
            </center>
          </Stack>

          {/* {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
            <Stack vertical tokens={{ childrenGap: '10px' }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <h3>{t('trCloud.subHeader')}</h3>
              </Stack>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <TextField
                  name="prefixNumber"
                  label={t('trCloud.prefixNumber')}
                  description={t('trCloud.prefixNumberDescription')}
                  styles={{ root: { width: 200 } }}
                  onChange={(e) => {
                    setPrefixNumber(e.target.value);
                  }}
                  value={prefixNumber}
                  disabled={isCreateDocument}
                />
                <TextField
                  name="countingNumber"
                  label={t('trCloud.countingNumber')}
                  description={countingNumber.length === 0 ? '' : t('trCloud.countingNumberDesciption')}
                  styles={{ root: { width: 150 } }}
                  onChange={(e) => {
                    if (!/\D/.test(e.target.value)) {
                      setCountingNumber(e.target.value);
                    }
                  }}
                  value={countingNumber}
                  errorMessage={countingNumber.length === 0 ? t('trCloud.countingNumberErrorMessage') : ''}
                  disabled={isCreateDocument}
                  required
                />
              </Stack>

              {countingNumber.length > 0 ? (
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                  {!isCreateDocument ? (
                    <h4>
                      {t('trCloud.startDocumentNumber')} {prefixNumber}
                      {countingNumber}
                    </h4>
                  ) : (
                    <h4>
                      {t('trCloud.endDocumentNumber')} {prefixNumber}
                      {countingNumber}
                    </h4>
                  )}
                </Stack>
              ) : (
                <br />
              )}
            </Stack>
          )} */}

          {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
            <Stack vertical tokens={{ childrenGap: '10px' }} styles={{ root: { marginTop: 10 } }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <TextField
                  name="createdBy"
                  label={t('shipnity.createdBy')}
                  description={t('shipnity.createdByDesc')}
                  styles={{ root: { width: 200 } }}
                  onChange={(e) => {
                    setCreatedBy(e.target.value);
                  }}
                  value={createdBy}
                  disabled={isCreateDocument}
                />
              </Stack>
              <br />
            </Stack>
          )}

          {selectedItems.length > 0 && !isCreateDocument && (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
              <PrimaryButton
                text={t('trCloud.createDocument')}
                iconProps={{ iconName: 'Add' }}
                menuProps={{
                  items:
                      [
                          {
                            key: 'RECEIPT-TAXINVOICE',
                            text: t('trCloud.receipt-taxinvoice'),
                            iconProps: { iconName: 'PageAdd' },
                            onClick: async (event) => {
                              setIsCreateDocument(true);
                              await createSelectedDocuments('RECEIPT-TAXINVOICE');
                            },
                          },
                          {
                            key: 'RECEIPT-TAXINVOICE-ABB',
                            text: t('trCloud.receipt-taxinvoice-abb'),
                            iconProps: { iconName: 'PageAdd' },
                            onClick: async (event) => {
                              setIsCreateDocument(true);
                              await createSelectedDocuments('RECEIPT-TAXINVOICE-ABB');
                            },
                          },
                          {
                            key: 'RECEIPT',
                            text: t('trCloud.receipt'),
                            iconProps: { iconName: 'PageAdd' },
                            onClick: async (event) => {
                              setIsCreateDocument(true);
                              await createSelectedDocuments('RECEIPT');
                            },
                          },
                          {
                            key: 'INVOICE-TAXINVOICE',
                            text: t('trCloud.invoice-taxinvoice'),
                            iconProps: { iconName: 'PageAdd' },
                            onClick: async (event) => {
                              setIsCreateDocument(true);
                              await createSelectedDocuments('INVOICE-TAXINVOICE');
                            },
                          },
                          {
                            key: 'DELIVERYORDER-TAXINVOICE',
                            text: t('trCloud.deliveryorder-taxinvoice'),
                            iconProps: { iconName: 'PageAdd' },
                            onClick: async (event) => {
                              setIsCreateDocument(true);
                              await createSelectedDocuments('DELIVERYORDER-TAXINVOICE');
                            },
                          },
                          {
                            key: 'TAXINVOICE',
                            text: t('trCloud.taxinvoice'),
                            iconProps: { iconName: 'PageAdd' },
                            onClick: async (event) => {
                              setIsCreateDocument(true);
                              await createSelectedDocuments('TAXINVOICE');
                            },
                          },
                          {
                            key: 'CREDIT-NOTE',
                            text: t('trCloud.creditNote'),
                            iconProps: { iconName: 'PageAdd' },
                            onClick: async (event) => {
                              setIsCreateDocument(true);
                              await createSelectedDocuments('CREDIT-NOTE');
                            },
                          }
                        ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
              <DefaultButton
                text={t('trCloud.cancel')}
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  let _items = selection.getItems();
                  let _selectedItems = selection.getSelection();
                  console.log('selection: ', selection.getSelection());

                  for (let i = 0; i < _selectedItems.length; i++) {
                    console.log('select item key: ', _selectedItems[i].key);
                    _selectedItems[i].status = '';

                    for (let j = 0; j < _items.length; j++) {
                      if (_items[j].key === _selectedItems[i].key) {
                        console.log('item key: ', _items[j].key);
                        _items[j].status = '';
                        _selectedItems[i].status = '';
                      }
                    }
                  }

                  selection.setAllSelected(false);

                  setItems(_items);
                  console.log('selection: ', selection.getSelection());
                }}
              />
              <br />
            </Stack>
          )}

          {isCreateDocument && (
            <div>
              <Spinner label={t('trCloud.signProcessing')} size={SpinnerSize.large} />
            </div>
          )}

          {isCreateDocumentComplete && (
            <center>
              <PrimaryButton
                text={t('trCloud.createdDocuments')}
                iconProps={{ iconName: 'Documentation' }}
                onClick={async () => {
                  console.log(selectedItems);
                  history.push({
                    pathname: '/etax/documents/all',
                    state: {},
                  });
                }}
              />
            </center>
          )}

          {totalProcessingDocuments > 0 && (
            <div>
              <br />
              <Spinner label={t('trCloud.signProcessing')} size={SpinnerSize.large} />
            </div>
          )}
          <br />
          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                minWidth: '800px',
                maxWidth: '1350px',
              }}
            >
              <Stack 
                horizontal 
                styles={{
                  root: {
                    backgroundColor: 'white',
                  },
                }}
                horizontalAlign="space-between"
              >
                <Stack
                  styles={{
                    root: {
                      width: 'calc(100vw - 700px)',
                      minWidth: '700px',
                      maxWidth: '900px',
                    },
                  }}
                >
                  <CommandBar
                    items={commandBarItems}
                    overflowItems={overflowItems}
                  />

                </Stack>
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                    },
                  }}
                  tokens={{ childrenGap: '10px' }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        marginLeft: 6,
                        marginRight: 10,
                        width: 200,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete || isCreateDocument}
                    placeholder={t('trCloud.searchBoxPlaceholder')}
                    onChange={(e) => setSearch(e ? e.target.value : '')}
                    onClear={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setInitPageSize(0)
                      setTotalDocuments(0)
                      setCurrentStart(0)

                      setCurrentPage(1);
                    }}
                    onSearch={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setInitPageSize(0)
                      setTotalDocuments(0)
                      setCurrentStart(0)

                      setCurrentPage(1);
                    }}
                    value={search}
                  />
                  <PrimaryButton
                    disabled={!loadDataComplete || !search || isCreateDocument}
                    styles={{
                      root: {
                        marginTop: 6,
                        marginRight: 8,
                        width: 90,
                      },
                    }}
                    text={t('lazada.search')}
                    onClick={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setInitPageSize(0)
                      setTotalDocuments(0)
                      setCurrentStart(0)

                      setCurrentPage(1);
                    }}
                  />
                </Stack>
              </Stack>
              {items.length && items[0].key ? (
                <ShimmeredDetailsList
                  items={items || []}
                  enableShimmer={!loadDataComplete}
                  compact={isCompactMode}
                  columns={columns}
                  selection={selection}
                  selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  checkboxVisibility={CheckboxVisibility.always}
                  selectionPreservedOnEmptyClick={true}
                />
              ) : (
                <ShimmeredDetailsList
                  items={[{}]}
                  enableShimmer={!loadDataComplete}
                  compact={isCompactMode}
                  columns={columns}
                  selection={selection}
                  selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  checkboxVisibility={CheckboxVisibility.always}
                  selectionPreservedOnEmptyClick={true}
                  onRenderRow={() => {
                    return (
                      <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: '#FFFFFF', height: 55 } }}>
                        <Text>{t('documents.noItemFound')}</Text>
                      </Stack>
                    );
                  }}
                />
              )}
            </Stack>
          </Stack>
          <br />
          <center>
            {!(items.length === 0 || (currentPage === 1 && items.length < pageSize)) && !(!loadDataComplete || isCreateDocument) && (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
                previousPageIconProps={{ iconName: 'ChevronLeft' }}
                nextPageIconProps={{ iconName: 'ChevronRight' }}
                lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
              />
            )}

            <br />
            <br />
          </center>
        </div>
      ) : (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              <Spinner label={t('trCloud.loading')} labelPosition="down" styles={spinnerStyles} />
            </div>
            <br />
          </Stack>
        </Stack>
      )}
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </div>
  );
}

export default TRCloud;
