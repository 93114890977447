import React, { useEffect, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import paginate from 'jw-paginate';

import {
  DefaultButton,
  Stack,
  ActionButton,
  mergeStyles,
  mergeStyleSets,
  PrimaryButton,
  Spinner,
  TextField,
  DetailsList,
  CommandBar,
  SelectionMode,
} from '@fluentui/react';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../authConfig';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

var moment = require('moment'); // require
moment().format();

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

//Dialog Style
// Success dialog style
const iconClass = mergeStyles({
  fontSize: 120,
  height: 120,
  width: 120,
  margin: '0 60px',
});

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
  blue: [
    {
      color: '#0078D4',
    },
    iconClass,
  ],
});

const centerItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export default function Upload() {
  const history = useHistory();

  const { t } = useTranslation();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: '.pdf, .zip',
    multiple: true,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const [company, setCompany] = React.useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyTaxId, setCompanyTaxId] = useState('');
  const [companyBranchText, setCompanyBranchText] = useState('');
  const [companyAllAddress, setCompanyAllAddress] = useState('');

  const [pageSize, setPageSize] = useState(10);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [documents, setDocuments] = useState([]);

  const [accountValid, setAccountValid] = useState(false);
  const [sentComplete, setSentComplete] = useState(false);
  const [sending, setSending] = useState(false);
  const [items, setItems] = useState([]);
  const [pageData, setPageData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  let emailRef = useRef(null);
  const [receiverEmail, setReceiverEmail] = useState('');
  const [message, setMessage] = useState('');
  const [noEmailError, setNoEmailError] = useState(false);
  const [emptyEmailError, setEmptyEmailError] = useState(false);
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    let _acceptedFiles = acceptedFiles;

    _acceptedFiles.forEach((file, index) => {
      file.index = index + 1;
      file.submitDate = 'รอกดส่ง';
      file.status = 'รอกดส่ง';
    });

    setPageData(paginate(_acceptedFiles.length, currentPage, pageSize, 10));

    setDocuments(_acceptedFiles);

    const _docs = [];

    if (_acceptedFiles.length > 0) {
      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, _acceptedFiles.length); i++) {
        _docs.push({
          index: _acceptedFiles[i].index,
          name: _acceptedFiles[i].name,
          size: formatBytes(_acceptedFiles[i].size),
          submitDate: _acceptedFiles[i].submitDate,
          status: _acceptedFiles[i].status,
          file: _acceptedFiles[i],
        });
      }
    }

    console.log('Docs data', _docs);
    setItems(_docs);

    setCurrentPage(1);
    setLoadDataComplete(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  useEffect(() => {
    if (documents.length > 0) {
      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
        _docs.push({
          index: documents[i].index,
          name: documents[i].name,
          size: formatBytes(documents[i].size),
          submitDate: documents[i].submitDate,
          status: documents[i].status,
          file: documents[i],
        });
      }

      console.log('Docs data', _docs);
      setItems(_docs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        //Check documents spending limit
        /* 
        axios
          .get(API_URL + '/subscriptions', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log('Subscriptions data: ', response);

              if (response.data) {
                if (response.data && response.data.documents) {
                  console.log('Subscription etax documents: ', response.data.documents);

                  let _subscriptionDocuments = response.data.documents;

                  if (_subscriptionDocuments.spent + 1 > _subscriptionDocuments.limit + _subscriptionDocuments.topup) {
                    history.push({
                      pathname: '/billings',
                      state: {},
                    });
                  }
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );
 */
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                console.log(response.data[0]);

                setCompany(response.data[0]);
                setCompanyName(response.data[0].name);
                setCompanyEmail(response.data[0].email);
                setCompanyTaxId(response.data[0].taxId);
                setCompanyBranchText(response.data[0].branchText);
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Company state: ', company);

    if (company.taxId) {
      console.log('Company useEffect call!');
      console.log(company);
      console.log(company.id);

      setCompanyAllAddress(
        company.buildingNumber +
          ' ' +
          (!/^-*$/.test(company.address) && company.address.trim() && !/^-*$/.test(company.address.trim()) ? company.address + ' ' : '') +
          (!/^-*$/.test(company.streetName) && company.streetName.trim() && !/^-*$/.test(company.streetName.trim())
            ? company.streetPrefix + company.streetName + ' '
            : '') +
          company.subDistrictPrefix +
          company.subDistrictName +
          ' ' +
          company.districtPrefix +
          company.districtName +
          ' ' +
          company.provincePrefix +
          company.provinceName +
          ' ' +
          company.postcode
      );
    }
  }, [company]);

  const commandBarItems = [
    {
      key: 'report',
      text: t('sentUpload.sentReport'),
      iconProps: { iconName: 'TextDocument' },
      disabled: !sentComplete,
      onClick: () => {
        console.log('report');

        report();
      },
    },
    {
      key: pageSize,
      text: pageSize + ' ' + t('documents.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 10,
            text: '10 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 เอกสาร/หน้า');
              setPageSize(10);
              setCurrentPage(1);
            },
          },
          {
            key: 20,
            text: '20 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 เอกสาร/หน้า');
              setPageSize(20);
              setCurrentPage(1);
            },
          },
          {
            key: 30,
            text: '30 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 เอกสาร/หน้า');
              setPageSize(30);
              setCurrentPage(1);
            },
          },
          {
            key: 40,
            text: '40 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 เอกสาร/หน้า');
              setPageSize(40);
              setCurrentPage(1);
            },
          },
          {
            key: 50,
            text: '50 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 เอกสาร/หน้า');
              setPageSize(50);
              setCurrentPage(1);
            },
          },
        ],
      },
    },
  ];

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);
    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    let hour = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
  };

  const openFile = (item) => {
    openFile_pdf(item);
  };

  const openFile_pdf = (item) => {
    history.push({
      pathname: '/sent/documents/pdf/view',
      state: {
        item: item,
      },
    });
  };

  function arrayTo2DArray2(list, howMany) {
    let idx = 0;
    let result = [];

    while (idx < list.length) {
      if (idx % howMany === 0) result.push([]);
      result[result.length - 1].push(list[idx++]);
    }

    return result;
  }

  const report = () => {
    let all_docs = arrayTo2DArray2(items, 21);
    console.log('doc', all_docs);

    const _createdTimeValue = new Date().toISOString();
    const _createdTime = toThaiDateString(_createdTimeValue);

    console.log(_createdTime);

    history.push({
      pathname: '/sent/upload/report',
      state: {
        // location state
        all_docs: all_docs,
        totalPages: all_docs.length,
        createdTime: _createdTime,
        createdTimeValue: _createdTimeValue,
        companyName: companyName,
        companyTaxId: companyTaxId,
        companyAllAddress: companyAllAddress,
        companyEmail: companyEmail,
        receiverEmail: receiverEmail,
        headOffice: companyBranchText ? (companyBranchText.includes('สำนักงานใหญ่') ? true : false) : false,
        branchOffice: companyBranchText ? (companyBranchText.includes('สำนักงานใหญ่') ? false : true) : false,
      },
    });
  };

  const emailLimit = (value) => {
    if (!value || value.length <= 320) {
      setReceiverEmail(value || '');
    }
  };

  const messageLimit = (value) => {
    if (!value || value.length <= 280) {
      setMessage(value || '');
    }
  };

  const UploadPDF = (payload) => {
    setSending(true);

    payload.items.forEach((item) => {
      item.status = 'pending';
      item.submitDate = '-';
    });

    setItems([...payload.items]);

    payload.instance
      .acquireTokenSilent({ ...silentRequest, account: payload.account })
      .then(async (tokenResponse) => {
        let _items = payload.items;
        for (let i = 0; i < _items.length; i++) {
          let row = _items[i];
          row.status = 'processing';
          row.submitDate = '-';
          setItems([..._items]);

          const promise = await new Promise(async (resolve) => {
            let formData = new FormData();

            formData.append('file', row.file);
            formData.append('fileName', row.name);
            formData.append('to', payload.to);
            formData.append('message', payload.message);

            await axios
              .post(API_URL + '/sents', formData, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'multipart/form-data',
                },
                timeout: 60000,
              })
              .then(
                (response) => {
                  console.log(true, response);
                  row.submitDate = toThaiDateString(response.data.createdTime);
                  row.status = 'successful';
                  setItems([..._items]);
                  resolve(true);
                },
                (error) => {
                  console.log('Error sending document response: ', error);
                  row.status = 'fail';
                  setItems([..._items]);
                  resolve(false);
                }
              );
          });

          Promise.all([promise]).then((result) => {
            if (_items.length - 1 === i) {
              setSentComplete(true);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        payload.instance.acquireTokenRedirect(silentRequest);
      });
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const columns = [
    {
      key: 'column1',
      name: t('sentUpload.index'),
      fieldName: 'index',
      minWidth: 20,
      maxWidth: 20,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.index}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('sentUpload.file'),
      fieldName: 'name',
      minWidth: 300,
      maxWidth: 300,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton
            title="เปิดไฟล์"
            className={classNames.fileIconImg}
            onClick={() => {
              openFile(item);
            }}
          >
            {item.name}
          </ActionButton>
        );
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('sentUpload.fileSize'),
      fieldName: 'size',
      minWidth: 70,
      maxWidth: 70,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return (
          <Stack>
            <span style={{ textAlign: 'right' }}>{item.size}</span>
          </Stack>
        );
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('sentUpload.sentDate'),
      fieldName: 'submitDate',
      minWidth: 125,
      maxWidth: 155,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.submitDate}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('sentUpload.status'),
      fieldName: 'status',
      minWidth: 70,
      maxWidth: 125,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        if (sending) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('sentUpload.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('sentUpload.processing')} labelPosition="right" styles={{ root: { justifyContent: 'left' } }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('sentUpload.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('sentUpload.fail')}</span>;
          }
        } else {
          return <span>{item.status}</span>;
        }
      },
      isPadded: true,
    },
  ];

  return (
    <div style={homeStyles}>
      <Stack>
        <center>
          <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{accountValid ? t('sentUpload.chooseFile') : t('sentUpload.receiverInfo')}</h2>
          {!accountValid && <h4>{t('sentUpload.leceiptReceiverOnly')}</h4>}
        </center>
        <Stack horizontal horizontalAlign="center">
          {accountValid ? (
            <Stack
              vertical
              style={{
                width: '98%',
                maxWidth: '1350px',
              }}
              tokens={{ childrenGap: 30 }}
            >
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className="center">
                  <button type="button" style={{ padding: '10px 20px', fontSize: '16px' }} onClick={open}>
                    {t('sentUpload.upload')}
                  </button>
                  <p style={{ marginBottom: 0 }}>{t('sentUpload.uploadDescription')}</p>
                  <p style={{ marginTop: 0 }}>{t('sentUpload.uploadDescriptionSize')}</p>
                </div>
              </div>
              <Stack>
                <CommandBar items={commandBarItems} />
                <DetailsList columns={columns} items={items || []} selectionMode={SelectionMode.none} />
                <br />
                <center>
                  {!(documents.length === 0 || documents.length < pageSize) && loadDataComplete && (
                    <Pagination
                      selectedPageIndex={currentPage - 1}
                      pageCount={pageData.totalPages}
                      onPageChange={onPageChange}
                      format
                      firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
                      previousPageIconProps={{ iconName: 'ChevronLeft' }}
                      nextPageIconProps={{ iconName: 'ChevronRight' }}
                      lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
                    />
                  )}
                </center>
              </Stack>

              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 10 }}>
                {sentComplete ? (
                  <DefaultButton
                    text={t('sentUpload.back')}
                    styles={{ root: { width: 100 } }}
                    onClick={() =>
                      history.push({
                        pathname: '/sent',
                        state: {},
                      })
                    }
                  />
                ) : (
                  <>
                    <PrimaryButton
                      text={t('sentUpload.sent')}
                      disabled={sending || items[0] === undefined}
                      styles={{ root: { width: 100 } }}
                      onClick={() => {
                        if (items[0] !== undefined) {
                          UploadPDF({
                            instance: instance,
                            account: account,
                            to: receiverEmail,
                            message: message,
                            items: items,
                          });
                        }
                      }}
                    />
                    <DefaultButton text={t('sentUpload.back')} styles={{ root: { width: 100 } }} onClick={() => setAccountValid((prev) => !prev)} />
                  </>
                )}
              </Stack>
            </Stack>
          ) : (
            <Stack vertical tokens={{ childrenGap: 30 }}>
              <Stack
                vertical
                tokens={{ childrenGap: 10 }}
                style={{
                  minWidth: '500px',
                }}
              >
                <TextField
                  label={t('sentUpload.email')}
                  required
                  componentRef={(input) => (emailRef = input)}
                  value={receiverEmail}
                  errorMessage={
                    emptyEmailError
                      ? t('sentUpload.fillEmail')
                      : noEmailError
                      ? t('sentUpload.noEmail')
                      : invalidEmailError
                      ? t('sentUpload.wrongEmail')
                      : ''
                  }
                  onChange={(e) => {
                    setEmptyEmailError(false);
                    setNoEmailError(false);
                    setInvalidEmailError(false);
                    emailLimit(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setEmptyEmailError(true);
                    } else if (!validateEmail(e.target.value)) {
                      setInvalidEmailError(true);
                    }
                  }}
                />

                <TextField
                  label={t('sentUpload.message')}
                  multiline
                  rows={10}
                  value={message}
                  resizable={false}
                  onChange={(e) => messageLimit(e.target.value)}
                />
              </Stack>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 20 }}>
                {isDownloading ? (
                  <Spinner label={t('sentUpload.downloading')} labelPosition="right" style={centerItem} />
                ) : (
                  <>
                    <PrimaryButton
                      text={t('sentUpload.next')}
                      styles={{ root: { width: 100 } }}
                      onClick={() => {
                        if (receiverEmail) {
                          setIsDownloading(true);

                          instance
                            .acquireTokenSilent({
                              ...silentRequest,
                              account: account,
                            })
                            .then(async (tokenResponse) => {
                              await axios
                                .get(API_URL + '/sents/check?email=' + receiverEmail, {
                                  headers: {
                                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                                  },
                                })
                                .then(
                                  (response) => {
                                    console.log(response);
                                    if (response.data.isExist) {
                                      setAccountValid((prev) => !prev);
                                    } else {
                                      setNoEmailError(true);
                                    }

                                    setIsDownloading(false);
                                  },
                                  (error) => {
                                    console.log(error);
                                  }
                                );
                            });
                        } else {
                          setEmptyEmailError(true);
                          emailRef.focus();
                        }
                      }}
                    />
                    <DefaultButton
                      text={t('sentUpload.back')}
                      styles={{ root: { width: 100 } }}
                      onClick={() =>
                        history.push({
                          pathname: '/sent',
                          state: {},
                        })
                      }
                    />
                  </>
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </div>
  );
}
