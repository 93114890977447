export const translationEn = {
  "header": {
    "profile": "Profile",
    "resetPassword": "Reset Password",
    "logout": "Log Out",
  },
  "navigation": {
    "folder": "Folders",
    "sales": "Sales",
    "accountReceivable": "Account Receivable",
    "purchasing": "Purchasing",
    "withholdingTax": "Withholding Tax",
    "send-receivedDocument": "Send & Received",
    "sendDocument": "Send Document",
    "inbox": "Inbox",
    "sentItems": "Sent Documents",
    "organizationContacts": "Organization",
    "company": "Company",
    "organization": "Organization",
    "contacts": "Contacts",
    "externalDataPaid": "External Data ERP",
    "externalData": "External Data",
    "fileExcel": "Import Excel",
    "reports": "Reports",
    "salesReport": "Sales Report",
    "outputTaxReport": "Output Tax Report",
    "inputTaxReport": "Input Tax Report",
    "xmlDeliveryReport": "XML Delivery Report",
    "fileRdPrep": "Export RDPrep",
    "pp30": "PP.30",
    "pnd2": "PND.2",
    "pnd3": "PND.3",
    "pnd53": "PND.53",
    "rdData": "RD Data",
    "vatRd": "VAT",
    "taxIdRd": "Tax ID",
    "eTaxRd": "e-Tax Invoice & e-Receipt",
    "setting": "Settings",
    "certificates": "Certificates",
    "xmlDelivery": "XML Delivery",
    "templates": "Templates",
    "emailSetting": "Email",
    "apiConnect": "API Key",
    "userManual": "User Manual",
    "confirmLogout": "Confirm Logout.",
    "createDocument": "Documents",
    "eTaxDocument": "e-Tax Documents",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "deliveryorders": "Delivery Order",
    "businessDocument": "Business Documents",
    "withholdingTaxDocument": "Withholding Tax",
    "serviceZone": "Contact Us",
    "tel": "Tel. 082-579-8555",
    "workdays": "8.30 a.m. - 5.30 p.m.",
    "version": "Version:",
    "suggestService": "Feedback",
    "product": "Products",
    "productList": "Product List",
    "productStockControl": "Stock Control",
    "emailDeliveries": "History Email Log",
    "smsDeliveries": "History SMS Log",
    "storages": "Cloud Storage",
  },
  "documents": {
    "warningSetupCertificate": "e-Tax Invoice & e-Receipt documents will complete with real electronic certificate only.",
    "warningSetupCertificateLink": "Set up electronic certificate.",
    "warningXmlDelivery": "XML files will submit to the Revenue Department every 5th of each month.",
    "close": "Close",
    "allDocuments": "All Sales Documents",
    "totalDocuments": "Total Documents",
    "notDeposit": "Not Deposited",
    "deposit": "Deposited",
    "vat": "VAT",
    "salesAmount": "Sales (Include VAT)",
    "documents": "Docs",
    "thb": "THB",
    "signProcessing": "Creating and signing document...",
    "search": "Search",
    "searchPlaceholder": "Number Name Tax ID",
    "sendFileByEmail": "Send file by email",
    "sendToEmail": "Send to email",
    "ccToEmail": "CC to email",
    "sendEmail": "Send email",
    "sendBatchSms": "Send SMS",
    "cancel": "Cancel",
    "sendFileBySms": "Send file by SMS",
    "mobileNumer": "Mobile number",
    "exampleNumber": "Ex. 0825798555",
    "sendSms": "Send SMS",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "allDocumentsType": "All Documents",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "deliveryorder": "Delivery Order",
    "createdDate": "Created Date",
    "documentDate": "Document Date",
    "thisMonth": "This Month",
    "documentPerPage": "Docs/Page",
    "page": "Page",
    "refresh": "Refresh",
    "confirmSendEmail": "Please confirm to send email",
    "confirmSendSms": "Please confirm to send SMS",
    "create": "Create",
    "combinedReceipt": "Combine Receipt",
    "combinedReceiptAlert": "Some document type can not create combine Receipt, please select new documents.",
    "download": "Download",
    "emailAlert": "Please fill valid email address.",
    "mobileNumberAlert": "Please fill valid mobile phone number.",
    "number": "Number",
    "customerName": "Customer",
    "grandTotal": "Total (Include VAT)",
    "document": "Document",
    "openFile": "Open File",
    "email": "Email ",
    "emailDate": "Email Date",
    "sendingDate": "Sending Date",
    "sendingEmail": "Sending email...",
    "sendingSms": "Sending SMS...",
    "success": "Success",
    "emailNotFound": "Email not found",
    "phoneNotFound": "Phone Number not found",
    "fail": "Fail",
    "file": "File",
    "openDocument": "Open Document",
    "reCreateDocument": "Recreate Document",
    "reIssuedDocument": "Reissue Document",
    "delete": "Delete",
    "deleteDisable": "Delete (Submitted XML)",
    "sendEmailSuccess": "Sent email successfully!",
    "sendEmailFail": "Sent email fail!",
    "sendSmsSuccess": "Sent SMS successfully!",
    "sendSmsFail": "Sent SMS fail!",
    "instantTopup": "Instant documents topup 24 hr.",
    "select": "Select",
    "isRequiredErrorMessage": "Please select date",
    "noItemFound": "Document not found",
    "documentStatus": "Status",
    "confirm": "Confirm",
    "confirmChangeStatus": "Change Document Status",
    "allStatus": "All Status",
    "draftStatus": "Draft",
    "openStatus": "Open",
    "partialPaidStatus": "Partial Paid",
    "sentStatus": "Sent",
    "paidStatus": "Paid",
    "voidStatus": "Void",
    "uncollectibleStatus": "Uncollectible",
    "status": "Status",
    "batchChangeStatus": "Change Status",
    "processing": "Processing...",
    "recordPaymentDialogHeader": "Record Payments",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "payment": "Payment Method",
    "paymentNote": "Note for your records",
    "paymentAmount": "Payment Amount",
    "recordGrandTotal": "Amount Due",
    "amountPaidErrorMessage": "Make amount more than 0",
    "amountPaidErrorMessage2": "The maximum allowed value is ",
    "pleaseSelect": "Please Select",
    "deleteConfirmHeader": "Delete Document",
    "deleteConfirm1": "Are you sure you want to delete ",
    "deleteConfirm2": " No. ",
    "deleteConfirm3": "?",
    "deleteConfirm4": " ",
    "logoutConfirmHeader": "Logging Out",
    "logoutConfirm": "Are you sure you want to log out?",
    "showRepeatNumberMessage": "There are duplicate document number",
    "recordNoteHeader": "Note",
    "recordNoteAddNoteButton": "Add New Note",
    "getErrorMessageTaxIdZeroOnly": "Invalid value. Please change Tax Number Type to N/A",
    "credits": "Credits",
    "emailSentDate": "Sent Date:",
    "smsSentDate": "Sent Date:",
  },
  "accountReceivable": {
    "header": "Account Receivable",
    "taxWithheld": "Tax Withheld",
    "custom": "Custom",
    "taxWithheldRate": "Rate",
    "taxWithheldAmount": "Tax Withheld",
    "totalPaid": "Money Payment Receive",
    "totalBeforeWht": "Amount after Withheld",
    "totalPaymentRec": "Total Payment Receive",
  },
  "expenseDocuments": {
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "eWithholdingTaxDownload": "Download e-Withholding Tax File",
    "header": "All Expense Documents",
    "totalDocuments": "Total Documents",
    "vat": "VAT",
    "expenseAmount": "Expense (Include VAT)",
    "expenseNote": "Create Expense Note",
    "signProcessing": "Creating and signing document...",
    "documentDate": "Document Date",
    "number": "Number",
    "customerName": "Payee",
    "vatColumn": "VAT",
    "grandTotal": "Total (Include VAT)",
    "whtTotal": "Withholding Tax",
    "paymentTotal": "Total",
    "file": "File",
    "select": "Select",
    "open": "View/Edit",
    "download": "Download",
    "reCreateDocument": "Recreate Document",
    "withholdingTaxs": "Create Withholding Tax Certificate",
    "delete": "Delete",
    "document": "Document",
    "thb": "THB",
  },
  "WithholdingTaxDocuments": {
    "totalDocuments": "Total Documents",
    "vat": "VAT",
    "expenseAmount": "Expense (Include VAT)",
    "expenseNote": "Create Expense Note",
    "signProcessing": "Creating and signing document...",
    "documentDate": "Document Date",
    "number": "Number",
    "customerName": "Customer",
    "vatColumn": "VAT",
    "grandTotal": "Total (Include VAT)",
    "whtTotal": "Withholding Tax",
    "paymentTotal": "Total",
    "file": "File",
    "select": "Select",
    "open": "View/Edit",
    "download": "Download",
    "reCreateDocument": "Recreate Document",
    "withholdingTaxs": "Create Withholding Tax Certificate",
    "delete": "Delete",
    "document": "Document",
    "search": "Search",
    "thb": "THB",
    "upload": "Upload",
    "header": "Withholding Tax Certificate",
    "total": "Total",
    "taxTotal": "Tax Total",
  },
  "whtDropzone": {
    "dropzoneAlertCSV": "Sorry, the file is missing some columns. Please update and upload again",
    "dropzoneAlertExcel": "Sorry, the file is missing some columns. Please update and upload again",
    "dropzoneAlert500": "Exceed 500 documents limit per file",
    "processing": "Processing...",
    "header": "Upload Excel to Create Withholding Tax Certificate",
    "upload": "Upload",
    "uploadDescription": "upload file .csv, .xls, .xlsx to Create Withholding Tax Certificate",
    "pnd2": "PND 2 Template for upload",
    "pnd3": "PND 3 Template for upload",
    "pnd53": "PND 53 Template for upload",
    "article": "Article for uploading CSV",
  },
  "whtDropzoneResult": {
    "number": "Document No.",
    "taxId": "Tax ID",
    "dateBE": "Date",
    "error": "Error Message",
    "header": "Sorry, we found some data are incorrect",
    "back": "Back",
  },
  "whtDropzoneUploadList": {
    "header": "Create Withholding Tax Certificate",
    "back": "Back",
    "cancel": "Cancel",
    "date": "Date",
    "number": "Document No.",
    "taxId": "Tax ID",
    "name": "Name",
    "amountTotal": "Total",
    "vatTotal": "VAT",
    "docStatus": "Status",
    "success": "Success",
    "pending": "Pending",
    "processing": "Processing...",
    "fail": "Fail",
    "viewPDF": "View PDF",
    "createDoc": "Create",
    "tryAgain": "Please Try Again",
    "signProcessing": "Creating and signing document...",
    "viewAll": "All Documents",
  },
  "excelDropzone": {
    "dropzoneAlertCSV": "Sorry, the file is missing some columns. Please update and upload again",
    "dropzoneAlertExcel": "Sorry, the file is missing some columns. Please update and upload again",
    "dropzoneAlert500": "Exceed 500 documents limit per file",
    "dropzoneAlertOther": "Please check the file or change to xlsx, xls and upload again",
    "dropzoneAlertNoOrder": "Sorry, the file is missing order column in row ",
    "dropzoneAlertOrderInvalid1": "Sorry, order column in row ",
    "dropzoneAlertOrderInvalid2": " is invalid. Please input number only",
    "dropzoneAlertNoData": "Sorry, the file is missing some data. Please update and upload again",
    "processing": "Processing...",
    "header": "Upload Excel to Create e-Tax",
    "upload": "Upload",
    "uploadDescription": "upload file .csv, .xls, .xlsx to Create e-Tax",
    "article": "Article for uploading Excel",
    "excelTemplate": "Excel Template for upload",
    "excelTemplateNew": "Excel Template",
    "excelTemplateNewCreditDebit": "Excel Template For Credit and Debit Note",
  },
  "excelDragDropDropzone": {
    "dropzoneAlertCSV": "Sorry, the file is missing some columns. Please update and upload again",
    "dropzoneAlertExcel": "Sorry, the file is missing some columns. Please update and upload again",
    "dropzoneAlert500": "Exceed 500 documents limit per file",
    "dropzoneAlertOther": "Please check the file or change to xlsx, xls and upload again",
    "dropzoneAlertNoOrder": "Sorry, the file is missing order column in row ",
    "dropzoneAlertOrderInvalid1": "Sorry, order column in row ",
    "dropzoneAlertOrderInvalid2": " is invalid. Please input number only",
    "processing": "Processing...",
    "header": "Upload Excel to Create e-Tax",
    "upload": "Upload",
    "uploadDescription": "upload file .csv, .xls, .xlsx to Create e-Tax",
    "article": "Article for uploading Excel",
    "excelTemplate": "Excel Template for upload",
  },
  "excelDragDropCheck": {
    "checkError": "Please drag and drop in all required columns",
    "processing": "Processing...",
    "confirm": "Confirm",
    "back": "Back",
    "close": "Close",
    "file": "File:",
    "reset": "Reset Column",
    "columnFromFile": "Column from file",
    "columnList": "Column",
    "columnPosition": "Column Positon",
  },
  "excelDragDropFail": {
    "order": "Order",
    "number": "Document No.",
    "dateBE": "Date",
    "error": "Error Message",
    "header": "Sorry, we found some data are incorrect",
    "back": "Back",
  },
  "excelDragDropSuccess": {
    "date": "Date",
    "number": "Document No.",
    "taxId": "Tax ID",
    "name": "Name",
    "amountTotal": "Total",
    "vatTotal": "VAT",
    "key": "Order",
    "dateBE": "Date",
    "referNo": "Document No.",
    "receiptType": "Document Type",
    "customerName": "Name",
    "vat": "VAT",
    "total": "Total",
    "docStatus": "Status",
    "success": "Success",
    "pending": "Pending",
    "processing": "Processing...",
    "processing2": "Processing...",
    "fail": "Fail",
    "viewPDF": "View PDF",
    "createDoc": "Create",
    "tryAgain": "Please Try Again",
    "header": "Create e-Tax",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "viewAll": "All Documents",
    "back": "Back",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Abbreviated Tax Invoice",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "deliveryorder": "Delivery Order",
  },
  "excelDragDropRowName": {
    "order": "Order",
    "documentType": "Document Type",
    "number": "Number",
    "dateBE": "Date",
    "dueDateBE": "Due Date",
    "reference": "Reference",
    "includeVat": "IncludeVat",
    "calculationMethod": "Calculation Method",
    "discount": "Discount",
    "language": "Language",
    "customerName": "Name",
    "customerAddressLineOne": "Address Line 1",
    "customerAddressLineTwo": "Address Line 2",
    "customerPostcode": "Postcode",
    "customerCountryName": "Country Name",
    "customerTaxNumberType": "Tax Number Type",
    "customerTaxId": "Tax ID",
    "customerBranchNumber": "Branch Number",
    "customerPhone": "Phone",
    "customerEmail": "E-mail",
    "note": "Note",
    "createdBy": "Created By",
    "receivedBy": "Received By",
    "reIssue": "Reissue",
    "referTypeCode": "Refer Type Code",
    "referNumber": "Refer Number",
    "referDateBE": "Refer Date",
    "referAmountTotal": "Refer Amount Total",
    "referReasonCode": "Refer Reason Code",
    "referSpecificReason": "Refer Specific Reason",

    "itemDescription1": "Item Description 1",
    "itemQuantity1": "Item Quantity 1",
    "itemUnitName1": "Item Unit Name 1",
    "itemPrice1": "Item Price 1",
    "itemDiscount1": "Item Discount 1",
    "itemPercentVat1": "Item PercentVat 1",

    "itemDescription2": "Item Description 2",
    "itemQuantity2": "Item Quantity 2",
    "itemUnitName2": "Item Unit Name 2",
    "itemPrice2": "Item Price 2",
    "itemDiscount2": "Item Discount 2",
    "itemPercentVat2": "Item PercentVat 2",

    "itemDescription3": "Item Description 3",
    "itemQuantity3": "Item Quantity 3",
    "itemUnitName3": "Item Unit Name 3",
    "itemPrice3": "Item Price 3",
    "itemDiscount3": "Item Discount 3",
    "itemPercentVat3": "Item PercentVat 3",

    "itemDescription4": "Item Description 4",
    "itemQuantity4": "Item Quantity 4",
    "itemUnitName4": "Item Unit Name 4",
    "itemPrice4": "Item Price 4",
    "itemDiscount4": "Item Discount 4",
    "itemPercentVat4": "Item PercentVat 4",

    "itemDescription5": "Item Description 5",
    "itemQuantity5": "Item Quantity 5",
    "itemUnitName5": "Item Unit Name 5",
    "itemPrice5": "Item Price 5",
    "itemDiscount5": "Item Discount 5",
    "itemPercentVat5": "Item PercentVat 5",

    "noRow": "Columns List",
  },
  "excelDropzoneResult": {
    "order": "Order",
    "number": "Document No.",
    "dateBE": "Date",
    "error": "Error Message",
    "header": "Sorry, we found some data are incorrect",
    "back": "Back",
  },
  "excelDropzoneUploadList": {
    "date": "Date",
    "number": "Document No.",
    "taxId": "Tax ID",
    "name": "Name",
    "amountTotal": "Total",
    "vatTotal": "VAT",
    "key": "Order",
    "dateBE": "Date",
    "referNo": "Document No.",
    "receiptType": "Document Type",
    "customerName": "Name",
    "vat": "VAT",
    "total": "Total",
    "docStatus": "Status",
    "success": "Success",
    "pending": "Pending",
    "processing": "Processing...",
    "processing2": "Processing...",
    "fail": "Fail",
    "viewPDF": "View PDF",
    "createDoc": "Create",
    "tryAgain": "Please Try Again",
    "header": "Create e-Tax",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "viewAll": "All Documents",
    "back": "Back",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Abbreviated Tax Invoice",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "deliveryorder": "Delivery Order",
    "showDifferenceMonth": "There is document that has date not within current month. Please check before creating e-Tax.",
  },
  "contactDropzone": {
    "dropzoneAlertCSV": "Sorry, the file is missing some columns. Please update and upload again",
    "dropzoneAlertExcel": "Sorry, the file is missing some columns. Please update and upload again",
    "dropzoneAlert500": "Exceed 500 documents limit per file",
    "processing": "Processing...",
    "header": "Upload Excel to Add New Contact",
    "upload": "Upload",
    "uploadDescription": "upload file .csv, .xls, .xlsx to Add New Contact",
    "excelTemplate": "Excel Template for upload",
  },
  "contactDropzoneResult": {
    "error": "Error Message",
    "header": "Sorry, we found some data are incorrect",
    "back": "Back",
    "name": "Name",
    "taxId": "Tax ID",
    "branchNumber": "Branch",
  },
  "contactDropzoneUploadList": {
    "header": "Add New Contacts",
    "back": "Back",
    "cancel": "Cancel",
    "taxId": "Tax ID",
    "branchNumber": "Branch",
    "address": "Address",
    "name": "Name",
    "status": "Status",
    "amountTotal": "Total",
    "vatTotal": "VAT",
    "docStatus": "Status",
    "success": "Success",
    "pending": "Pending",
    "processing": "Processing...",
    "fail": "Fail",
    "viewPDF": "View PDF",
    "addContact": "Add",
    "createDoc": "Create",
    "tryAgain": "Please Try Again",
    "signProcessing": "Creating and signing document...",
    "viewAll": "All Contacts",
    "processing2": "Processing...",
  },
  "inbox": {
    "inboxDate": "Date",
    "sender": "Sender",
    "email": "Email",
    "file": "File",
    "fileSize": "File Size (KB)",
    "message": "Message",
    "header": "Inbox",
    "searchPlaceholder": "Sender, Email, File Name",
  },
  "sent": {
    "sentDate": "Date",
    "receiver": "Receiver",
    "email": "Email",
    "file": "File",
    "fileSize": "File Size (KB)",
    "status": "Status",
    "message": "Message",
    "header": "Sent History",
    "searchPlaceholder": "Receiver, Email, File Name",
    "sentEmail": "Send",
  },
  "sentUpload": {
    "sentDate": "Date",
    "receiver": "Receiver",
    "file": "File",
    "fileSize": "File Size (KB)",
    "status": "Status",
    "sentEmail": "Sent E-mail",
    "sentReport": "Sent Report",
    "index": "No.",
    "chooseFile": "Choose File",
    "receiverInfo": "Receiver Information",
    "leceiptReceiverOnly": "(E-mail on Leceipt only)",
    "upload": "Upload",
    "uploadDescription": "Upload file .pdf, .zip to send",
    "uploadDescriptionSize": "limit file size to 35 MB",
    "back": "Back",
    "sent": "Send",
    "email": "Email",
    "fillEmail": "Please fill E-mail",
    "noEmail": "This E-mail is not registerd to Leceipt",
    "wrongEmail": "Invalid E-mail format",
    "message": "Message",
    "downloading": "Downloading...",
    "next": "Next",
    "success": "Success",
    "pending": "Pending",
    "processing": "Processing...",
    "fail": "Fail",
  },
  "sentReport": {
    "download": "Download",
    "back": "Back",
    "downloading": "Downloading...",
    "processing": "Processing",
    "processingMessage": "Creating Sent Report",
  },
  "company": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "documentHeader": "Receipt/Tax Invoice",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "tel": "Tel",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Receipt/Tax Invoice No.",
    "referDate": "Refer Receipt/Tax Invoice Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "taxIdError": "Please fill 13 digit number",
    "updateSuccess": "Updated Successfully!",
    "updateFail": "Updated Fail!",
    "header": "Company Information",
    "headerDescription": "(Fill out this form for displaying in documents)",
    "companyInfo": "Company Details",
    "taxId": "Tax ID",
    "branchDisplay": "Head Office/Branch",
    "branchNumber": "Branch",
    "branchNumberDesc": "ex. 00001",
    "search": "Search",
    "name": "Name",
    "namePlaceHolder": "",
    "addressForCreate": "Address Details",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetPrefix": "Street Prefix",
    "streetName": "Street",
    "provincePrefix": "Province Prefix",
    "province": "Province",
    "districtPrefix": "District Prefix",
    "district": "District (Choose Province first)",
    "subDistrictPrefix": "Subdistrict Prefix",
    "subDistrict": "Subdistrict (Choose District first)",
    "postcode": "Postcode",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "phone": "Tel.",
    "email": "Email",
    "contactForCustomer": "Contact",
    "registerVat": "Register VAT",
    "registerVatYes": "Register VAT",
    "registerVatNo": "Not register VAT",
    "saveSeparator": "Click below button to save",
    "save": "Save",
    "saving": "Saving...",
    "notDisplay": "None",
    "back": "Back",
    "addCompany": "Add Company",
    "add": "Add",
  },
  "contact": {
    "upload": "Upload",
    "refresh": "Refresh",
    "name": "Name",
    "taxId": "Tax ID",
    "address": "Address",
    "contactName": "Contact Name",
    "phone": "Tel.",
    "edit": "View/Edit",
    "delete": "Delete",
    "receipt-taxinvoice": "Create Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Create Receipt/Abbreviated Tax Invoice",
    "receipt": "Create Receipt",
    "invoice-taxinvoice": "Create Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Create Delivery Order/Tax Invoice",
    "taxinvoice": "Create Tax Invoice",
    "debit-note": "Create Debit Note",
    "credit-note": "Create Credit Note",
    "quotation": "Create Quotation",
    "invoice": "Create Invoice",
    "billing-note": "Create Billing Note",
    "deliveryorder": "Create Delivery Order",
    "expense-note": "Create Expense Note",
    "withholding-tax": "Create Withholding Tax Certificate",
    "select": "Select",
    "updateSuccess": "Updated Successfully!",
    "addContact": "Add new contact",
    "searchPlaceholder": "Contact Name, Tax ID, Tel.",
    "close": "Close",
    "header": "All Contacts",
    "deleteConfirmHeader": "Delete Contact",
    "deleteConfirm1": "Are you sure you want to delete ",
    "deleteConfirm2": " ?",
  },
  "addContact": {
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "documentHeader": "Receipt/Tax Invoice",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "othr": "N/A",
    "number": "Number",
    "tel": "Tel",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Receipt/Tax Invoice No.",
    "referDate": "Refer Receipt/Tax Invoice Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "taxIdError": "Please fill 13 digit number",
    "updateSuccess": "Updated Successfully!",
    "updateFail": "Updated Fail!",
    "name": "Name",
    "namePlaceHolder": "",
    "addressForCreate": "Address",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "contactForCustomer": "Contact",
    "registerVat": "Register VAT",
    "registerVatYes": "Register VAT",
    "registerVatNo": "Not register VAT",
    "saveSeparator": "Click below button to save",
    "save": "Save",
    "saving": "Saving...",
    "notDisplay": "None",
    "alreadyHaveTaxId": "This Tax ID is already used",
    "alreadyHaveContact": "This contact is already added",
    "processing": "Processing...",
    "header": "Contact Information",
    "headerDescription": "(Fill out this form for displaying in documents)",
    "companyInfo": "Company Details",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "english": "English",
    "on": "On",
    "off": "Off",
    "taxId": "Tax ID",
    "branchDisplay": "Branch",
    "branchNumber": "No.",
    "branchNumberDesc": "ex. 00001",
    "search": "Search",
    "companyName": "Name",
    "addressHeader": "Address Details",
    "addressStructure": "Address Structure",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetPrefix": "Street Prefix",
    "streetName": "Street",
    "provincePrefix": "Province Prefix",
    "province": "Province",
    "districtPrefix": "District Prefix",
    "district": "District (Choose Province first)",
    "subDistrictPrefix": "Subdistrict Prefix",
    "subDistrict": "Subdistrict (Choose District first)",
    "postcode": "Postcode",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "country": "Country",
    "contactHeader": "Contact Details",
    "phone": "Tel.",
    "website": "Website",
    "fax": "Fax",
    "email": "Email",
    "contactHeader2": "Other Contact Details",
    "contactName": "Contact Name",
    "contactNickName": "Contact Nickname",
    "contactEmail": "Contact Email",
    "contactPhone": "Contact Tel.",
    "contactDepartment": "Department",
    "contactPosition": "Position",
    "paymentHeader": "Payment Details",
    "accountName": "Account Name",
    "bankName": "Bank",
    "bankBranch": "Branch",
    "accountType": "Account Type",
    "accountNumber": "Account Number",
    "paymentNote": "Payment Note",
    "addBank": "Add Bank",
    "back": "Back",
    "getErrorMessageTaxIdZeroOnly": "Invalid value"
  },
  "person": {
    "upload": "Upload",
    "refresh": "Refresh",
    "name": "Name",
    "taxId": "Tax ID",
    "address": "Address",
    "contactName": "Contact Name",
    "phone": "Tel.",
    "edit": "View/Edit",
    "delete": "Delete",
    "receipt-taxinvoice": "Create Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Create Receipt/Abbreviated Tax Invoice",
    "receipt": "Create Receipt",
    "invoice-taxinvoice": "Create Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Create Delivery Order/Tax Invoice",
    "taxinvoice": "Create Tax Invoice",
    "debit-note": "Create Debit Note",
    "credit-note": "Create Credit Note",
    "quotation": "Create Quotation",
    "invoice": "Create Invoice",
    "billing-note": "Create Billing Note",
    "deliveryorder": "Create Delivery Order",
    "expense-note": "Create Expense Note",
    "withholding-tax": "Create Withholding Tax Certificate",
    "select": "Select",
    "updateSuccess": "Updated Successfully!",
    "addContact": "Add new contact",
    "searchPlaceholder": "Contact Name, Tax ID, Tel.",
    "close": "Close",
    "header": "All Contacts",
    "deleteConfirmHeader": "Delete Contact",
    "deleteConfirm1": "Are you sure you want to delete ",
    "deleteConfirm2": " ?",
  },
  "addPerson": {
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "documentHeader": "Receipt/Tax Invoice",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "othr": "N/A",
    "number": "Number",
    "tel": "Tel",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Receipt/Tax Invoice No.",
    "referDate": "Refer Receipt/Tax Invoice Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "taxIdError": "Please fill 13 digit number",
    "updateSuccess": "Updated Successfully!",
    "updateFail": "Updated Fail!",
    "name": "Name",
    "namePlaceHolder": "",
    "addressForCreate": "Address",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "contactForCustomer": "Contact",
    "registerVat": "Register VAT",
    "registerVatYes": "Register VAT",
    "registerVatNo": "Not register VAT",
    "saveSeparator": "Click below button to save",
    "save": "Save",
    "saving": "Saving...",
    "notDisplay": "None",
    "alreadyHaveTaxId": "This Tax ID is already used",
    "alreadyHaveContact": "This contact is already added",
    "processing": "Processing...",
    "header": "Add Person",
    "headerDescription": "(Fill out this form for displaying in documents)",
    "companyInfo": "Company Details",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "english": "English",
    "on": "On",
    "off": "Off",
    "taxId": "Tax ID",
    "branchDisplay": "Branch",
    "branchNumber": "No.",
    "branchNumberDesc": "ex. 00001",
    "search": "Search",
    "companyName": "Name",
    "addressHeader": "Address Details",
    "addressStructure": "Address Structure",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetPrefix": "Street Prefix",
    "streetName": "Street",
    "provincePrefix": "Province Prefix",
    "province": "Province",
    "districtPrefix": "District Prefix",
    "district": "District (Choose Province first)",
    "subDistrictPrefix": "Subdistrict Prefix",
    "subDistrict": "Subdistrict (Choose District first)",
    "postcode": "Postcode",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "country": "Country",
    "contactHeader": "Contact Details",
    "phone": "Tel.",
    "website": "Website",
    "fax": "Fax",
    "email": "Email",
    "contactHeader2": "Other Contact Details",
    "contactName": "Contact Name",
    "contactNickName": "Contact Nickname",
    "contactEmail": "Contact Email",
    "contactPhone": "Contact Tel.",
    "contactDepartment": "Department",
    "contactPosition": "Position",
    "paymentHeader": "Payment Details",
    "accountName": "Account Name",
    "bankName": "Bank",
    "bankBranch": "Branch",
    "accountType": "Account Type",
    "accountNumber": "Account Number",
    "paymentNote": "Payment Note",
    "addBank": "Add Bank",
    "back": "Back",
    "organization": "Organization",
    "label": "Label",
    "addOneMorePhone": "Add phone",
    "addOneMoreEmail": "Add email",
    "owner": "Owner",
    "visibleTo": "Visible to",
  },
  "product": {
    "header": "Product List",
    "addProduct": "Create Product",
    "productName": "Product Name",
    "price": "Price",
    "thb": "(THB)",
    "quantity": "Quantity",
    "updateTime": "Updated on",
    "status": "Status",
    "select": "Select",
    "min": "Min.",
    "max": "Max.",
    "categoryPlaceHolder": "Category",
    "search": "Search",
    "reset": "Reset",
    "recover": "Recover",
    "edit": "Edit",
    "deactivate": "Deactivate",
    "activate": "Activate",
    "copy": "Copy",
    "delete": "Delete",
    "cancel": "Cancel",
    "save": "Save",
    "collapse": "Collapse",
    "variations": "Variations",
    "expand": "Expand",
    "productPerPage": "Product/Page",
    "noItemFound": "Product not found",
  },
  "addProduct": {
    "header": "Create Product",
    "usedSku": "This SKU ID has already been used",
    "pleaseFillInformation": "Please fill information",
    "basicInformation": "Basic information",
    "productName": "Product Name",
    "category": "Category",
    "description": "Description",
    "productAttributes": "Product Attributes",
    "brand": "Brand",
    "salesInformation": "Sales Information",
    "enableVariations": "Enable Variations",
    "on": "On",
    "off": "Off",
    "variationName": "Variation Name",
    "option": "Option",
    "addVariation": "Add Variation",
    "variationList": "Variation List",
    "price": "Price",
    "discount": "Discount",
    "quantity": "Quantity",
    "apply": "Apply",
    "unit": "Unit",
    "percentVat": "VAT (%)",
    "sellerSku": "Seller SKU",
    "save": "Save",
    "SaveAsDraft": "Save as a draft",
    "variation1": "variation 1",
    "variation2": "variation 2",
    "back": "Back",
    "duplicatedSku": "SKU ID is duplicated",
    "tryAgain": "An error has occurred. Please try again",
  },
  "units": {
    "EA": "EA",
    "BX": "BX",
    "NMP": "PACK",
    "AU": "AU",
    "BAG": "BAG",
    "BKT": "BKT",
    "BT": "BT",
    "CAN": "CAN",
    "CELL": "CELL",
    "DAY": "DAY",
    "DR": "DR",
    "DZ": "DZ",
    "GLL": "GLL",
    "JOB": "JOB",
    "PCS": "PCS",
    "SET": "SET",
    "M": "METRE",
    "YD": "YARD",
  },
  "receiptTaxInvoice": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "header": "Create Receipt/Tax Invoice",
    "documentHeader": "Receipt/Tax Invoice",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetName": "Street Name",
    "province": "Province",
    "district": "District",
    "subDistrict": "Subdistrict",
    "postcode": "Postcode",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "branchDisplay": "Head Office/Branch",
    "branchNumberDesc": "Ex. 00001",
    "tel": "Tel",
    "email": "Email",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Receipt/Tax Invoice No.",
    "referDate": "Refer Receipt/Tax Invoice Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
  },
  "receiptTaxInvoiceConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Receipt/Tax Invoice",
  },
  "receiptTaxInvoiceAbb": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "header": "Create Receipt/Abbreviated Tax Invoice",
    "documentHeader": "Receipt/Abbreviated Tax Invoice",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetName": "Street Name",
    "province": "Province",
    "district": "District",
    "subDistrict": "Subdistrict",
    "postcode": "Postcode",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "branchDisplay": "Head Office/Branch",
    "branchNumberDesc": "Ex. 00001",
    "tel": "Tel",
    "email": "Email",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Receipt/Abbreviated Tax Invoice No.",
    "referDate": "Refer Receipt/Abbreviated Tax Invoice Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "reason1": "Invalid name",
    "reason2": "Invalid address",
    "reason3": "Other reason",
  },
  "receiptTaxInvoiceAbbConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Receipt/Abbreviated Tax Invoice",
  },
  "taxInvoice": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "header": "Create Tax Invoice",
    "documentHeader": "Tax Invoice",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetName": "Street Name",
    "province": "Province",
    "district": "District",
    "subDistrict": "Subdistrict",
    "postcode": "Postcode",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "branchDisplay": "Head Office/Branch",
    "branchNumberDesc": "Ex. 00001",
    "tel": "Tel",
    "email": "Email",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Tax Invoice No.",
    "referDate": "Refer Tax Invoice Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
  },
  "taxInvoiceConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Tax Invoice",
  },
  "receipt": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "header": "Create Receipt",
    "documentHeader": "Receipt",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetName": "Street Name",
    "province": "Province",
    "district": "District",
    "subDistrict": "Subdistrict",
    "postcode": "Postcode",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "branchDisplay": "Head Office/Branch",
    "branchNumberDesc": "Ex. 00001",
    "tel": "Tel",
    "email": "Email",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Receipt No.",
    "referDate": "Refer Receipt Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "total": "Total (THB)",
    "specificAmount": "Total (THB)",
  },
  "receiptConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Receipt",
  },
  "batchReceipt": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "header": "Create Receipt",
    "documentHeader": "Receipt",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetName": "Street Name",
    "province": "Province",
    "district": "District",
    "subDistrict": "Subdistrict",
    "postcode": "Postcode",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "branchDisplay": "Head Office/Branch",
    "branchNumberDesc": "Ex. 00001",
    "tel": "Tel",
    "email": "Email",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Receipt No.",
    "referDate": "Refer Receipt Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "total": "Total (THB)",
    "specificAmount": "Total (THB)",
    "invoiceNumber": "Document No.",
    "invoiceDate": "Date",
    "invoiceVat": "VAT",
    "invoiceAmountTotal": "Subtotal (THB)",
    "invoiceVAT": "VAT (THB)",
    "invoiceGrandTotal": "Total (THB)",
  },
  "batchReceiptConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Receipt",
  },
  "invoiceTaxInvoice": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "header": "Create Invoice/Tax Invoice",
    "documentHeader": "Invoice/Tax Invoice",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetName": "Street Name",
    "province": "Province",
    "district": "District",
    "subDistrict": "Subdistrict",
    "postcode": "Postcode",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "branchDisplay": "Head Office/Branch",
    "branchNumberDesc": "Ex. 00001",
    "tel": "Tel",
    "email": "Email",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Invoice/Tax Invoice No.",
    "referDate": "Refer Invoice/Tax Invoice Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "dueDate": "Due Date",
  },
  "invoiceTaxInvoiceConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Invoice/Tax Invoice",
  },
  "deliveryOrderTaxInvoice": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "header": "Create Delivery Order/Tax Invoice",
    "documentHeader": "Delivery Order/Tax Invoice",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetName": "Street Name",
    "province": "Province",
    "district": "District",
    "subDistrict": "Subdistrict",
    "postcode": "Postcode",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "branchDisplay": "Head Office/Branch",
    "branchNumberDesc": "Ex. 00001",
    "tel": "Tel",
    "email": "Email",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Delivery Order/Tax Invoice No.",
    "referDate": "Refer Delivery Order/Tax Invoice Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "dueDate": "Due Date",
  },
  "deliveryOrderTaxInvoiceConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Delivery Order/Tax Invoice",
  },
  "debitNoteTaxInvoice": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "header": "Create Debit Note",
    "documentHeader": "Debit Note",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetName": "Street Name",
    "province": "Province",
    "district": "District",
    "subDistrict": "Subdistrict",
    "postcode": "Postcode",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "branchDisplay": "Head Office/Branch",
    "branchNumberDesc": "Ex. 00001",
    "tel": "Tel",
    "email": "Email",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "dueDate": "Due Date",
    "calculationMethod": "Calculation Method",
    "previousAmountTotal": "Last Tax Inv. Amount",
    "rightAmountTotal": "Correct Value",
    "referTypeCode": "Document Type",
    "referNumber": "Refer Document No.",
    "referDate": "Refer Document Date",
    "reason": "Reason",
    "diff": "Different",
    "debitPrice": "Method 1",
    "actualPrice": "Method 2",
  },
  "debitNoteTaxInvoiceConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Debit Note",
  },
  "creditNoteTaxInvoice": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "header": "Create Credit Note",
    "documentHeader": "Credit Note",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetName": "Street Name",
    "province": "Province",
    "district": "District",
    "subDistrict": "Subdistrict",
    "postcode": "Postcode",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "branchDisplay": "Head Office/Branch",
    "branchNumberDesc": "Ex. 00001",
    "tel": "Tel",
    "email": "Email",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "dueDate": "Due Date",
    "calculationMethod": "Calculation Method",
    "previousAmountTotal": "Last Tax Inv. Amount",
    "beforeVat": "(Before VAT)",
    "rightAmountTotal": "Correct Value",
    "referTypeCode": "Document Type",
    "referNumber": "Refer Document No.",
    "referDate": "Refer Document Date",
    "reason": "Reason",
    "diff": "Different",
    "creditPrice": "Method 1",
    "actualPrice": "Method 2",
  },
  "creditNoteTaxInvoiceConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Credit Note",
  },
  "quotation": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "header": "Create Quotation",
    "documentHeader": "Quotation",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetName": "Street Name",
    "province": "Province",
    "district": "District",
    "subDistrict": "Subdistrict",
    "postcode": "Postcode",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "branchDisplay": "Head Office/Branch",
    "branchNumberDesc": "Ex. 00001",
    "tel": "Tel",
    "email": "Email",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Delivery Order/Tax Invoice No.",
    "referDate": "Refer Delivery Order/Tax Invoice Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "dueDate": "Due Date",
    "contactName": "Contact Name",
    "total": "Total (THB)",
    "payment": "Payment Methods",
    "accountName": "Account Name",
    "bankName": "Bank Name",
    "bankBranch": "Branch",
    "accountType": "Account Type",
    "accountNumber": "Account No.",
    "addBank": "Add Bank",
    "paymentNote": "Payment Note",
    "promtpayQr": "PromtPay QR",
    "taxNumber": "Tax No.",
    "paypalConnectSuccess": "PayPal Connected",
    "paypalConnect": "Please connect to PayPal",
    "stripeConnectSuccess": "Stripe Connected",
    "stripeConnect": "Please connect to Stripe",
    "paymentGateway": "Payment Gateway",
    "notSelect": "N/A",
  },
  "quotationConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Quotation",
  },
  "invoice": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "header": "Create Invoice",
    "documentHeader": "Invoice",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetName": "Street Name",
    "province": "Province",
    "district": "District",
    "subDistrict": "Subdistrict",
    "postcode": "Postcode",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "branchDisplay": "Head Office/Branch",
    "branchNumberDesc": "Ex. 00001",
    "tel": "Tel",
    "email": "Email",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Delivery Order/Tax Invoice No.",
    "referDate": "Refer Delivery Order/Tax Invoice Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "dueDate": "Due Date",
    "contactName": "Contact Name",
    "total": "Total (THB)",
    "payment": "Payment Methods",
    "accountName": "Account Name",
    "bankName": "Bank Name",
    "bankBranch": "Branch",
    "accountType": "Account Type",
    "accountNumber": "Account No.",
    "addBank": "Add Bank",
    "paymentNote": "Payment Note",
    "promtpayQr": "PromtPay QR",
    "taxNumber": "Tax No.",
    "paypalConnectSuccess": "PayPal Connected",
    "paypalConnect": "Please connect to PayPal",
    "stripeConnectSuccess": "Stripe Connected",
    "stripeConnect": "Please connect to Stripe",
    "paymentGateway": "Payment Gateway",
    "notSelect": "N/A",
  },
  "invoiceConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Invoice",
  },
  "deliveryorders": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "header": "Create Delivery Order",
    "documentHeader": "Delivery Order",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetName": "Street Name",
    "province": "Province",
    "district": "District",
    "subDistrict": "Subdistrict",
    "postcode": "Postcode",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "branchDisplay": "Head Office/Branch",
    "branchNumberDesc": "Ex. 00001",
    "tel": "Tel",
    "email": "Email",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Delivery Order/Tax Invoice No.",
    "referDate": "Refer Delivery Order/Tax Invoice Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "dueDate": "Due Date",
    "contactName": "Contact Name",
    "total": "Total (THB)",
    "payment": "Payment Methods",
    "accountName": "Account Name",
    "bankName": "Bank Name",
    "bankBranch": "Branch",
    "accountType": "Account Type",
    "accountNumber": "Account No.",
    "addBank": "Add Bank",
    "paymentNote": "Payment Note",
    "promtpayQr": "PromtPay QR",
    "taxNumber": "Tax No.",
    "paypalConnectSuccess": "PayPal Connected",
    "paypalConnect": "Please connect to PayPal",
    "stripeConnectSuccess": "Stripe Connected",
    "stripeConnect": "Please connect to Stripe",
    "paymentGateway": "Payment Gateway",
    "notSelect": "N/A",
  },
  "deliveryordersConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Delivery Order",
  },
  "billingNote": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "header": "Create Billing Note",
    "documentHeader": "Billing Note",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Customer Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetName": "Street Name",
    "province": "Province",
    "district": "District",
    "subDistrict": "Subdistrict",
    "postcode": "Postcode",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "branchDisplay": "Head Office/Branch",
    "branchNumberDesc": "Ex. 00001",
    "tel": "Tel",
    "email": "Email",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Delivery Order/Tax Invoice No.",
    "referDate": "Refer Delivery Order/Tax Invoice Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "dueDate": "Due Date",
    "contactName": "Contact Name",
    "total": "Total (THB)",
    "payment": "Payment Methods",
    "accountName": "Account Name",
    "bankName": "Bank Name",
    "bankBranch": "Branch",
    "accountType": "Account Type",
    "accountNumber": "Account No.",
    "addBank": "Add Bank",
    "paymentNote": "Payment Note",
    "promtpayQr": "PromtPay QR",
    "taxNumber": "Tax No.",
    "paypalConnectSuccess": "PayPal Connected",
    "paypalConnect": "Please connect to PayPal",
    "stripeConnectSuccess": "Stripe Connected",
    "stripeConnect": "Please connect to Stripe",
    "paymentGateway": "Payment Gateway",
    "notSelect": "N/A",
  },
  "billingNoteConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Billing Note",
  },
  "withholdingTax": {
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "header": "Create Withholding Tax Certificate",
  },
  "withholdingTaxConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Withholding Tax Certificate",
  },
  "expenseNote": {
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "dropdownErrMsg": "Please select",
    "getErrorMessage": "Please fill information",
    "getPostCodeErrorMessage1": "Please fill only number",
    "getPostCodeErrorMessage2": "Please fill 5 digit number",
    "getTaxIdErrorMessage": "Please fill 13 digit number",
    "getBranchNumberErrorMessage1": "Please fill only number",
    "getBranchNumberErrorMessage2": "Please fill 5 digit number",
    "getPhoneNumberErrorMessage": "Incorrect format ex. 0812345678",
    "header": "Create Expense Note",
    "documentHeader": "Expense Note",
    "documentSubHeader": "",
    "original": "Original",
    "companyTel": "Tel",
    "companyEmail": "Email",
    "companyTaxId": "Tax ID",
    "customerName": "Payee Name",
    "pleaseSelect": "Please Select",
    "country": "Country",
    "addressStructure": "Address Structure",
    "on": "On",
    "off": "Off",
    "lineOne": "Address Line 1",
    "lineTwo": "Address Line 2",
    "buildingNumber": "Building No.",
    "address": "Address",
    "streetName": "Street Name",
    "province": "Province",
    "district": "District",
    "subDistrict": "Subdistrict",
    "postcode": "Postcode",
    "english": "English",
    "taxNumberType": "Tax Number Type",
    "txid": "Tax ID",
    "nidn": "ID Number",
    "ccpt": "Passport Number",
    "othr": "N/A",
    "number": "Number",
    "branchDisplay": "Head Office/Branch",
    "branchNumberDesc": "Ex. 00001",
    "tel": "Tel",
    "email": "Email",
    "priceIncludeVat": "Price Include Vat",
    "date": "Date",
    "pleaseSelectDate": "Please Select Date",
    "reference": "Reference",
    "itemNumber": "Number",
    "item": "Item",
    "quantity": "Quantity",
    "unit": "Unit",
    "unitPrice": "Price/Unit",
    "discount": "Discount",
    "itemVat": "VAT",
    "totalIncludeVat": "Total (THB)",
    "totalBeforeVat": "Total (THB)",
    "totalErrorMessage1": "Value must be positive",
    "delete": "Delete",
    "addItem": "Add Item",
    "note": "Note",
    "receivedBy": "Received By",
    "createdBy": "Created By",
    "reIssueMessage1": "This is to cancel and issue a new tax invoice instead of the old tax invoice.",
    "amountTotalBeforeVat": "Subtotal",
    "amountTotal": "Subtotal",
    "thb": "THB",
    "vat": "VAT",
    "grandTotal": "Total",
    "referNumber": "Refer Delivery Order/Tax Invoice No.",
    "referDate": "Refer Delivery Order/Tax Invoice Date",
    "reason": "Reason",
    "specificReason": "Specific Reason",
    "nonVatPriceTotal": "Non VAT Total",
    "zeroVatPriceTotal": "Zero VAT Total",
    "vatPriceTotal": "VAT Total",
    "next": "Next",
    "cancel": "Cancel",
    "loading": "Loading...",
    "isRequiredErrorMessage": "Please select date",
    "dueDate": "Due Date",
    "contactName": "Contact Name",
    "total": "Total (THB)",
    "payment": "Payments",
    "accountName": "Account Name",
    "bankName": "Bank Name",
    "bankBranch": "Branch",
    "accountType": "Account Type",
    "accountNumber": "Account No.",
    "addBank": "Add Bank",
    "paymentNote": "Payment Note",
    "promtpayQr": "PromtPay QR",
    "taxNumber": "Tax No.",
    "updateSuccess": "Updated Successfully!",
    "taxInvoiceNumber": "Tax Invoice No.",
    "taxInvoiceDate": "Tax Invoice Date",
    "itemWhtType": "Withholding Tax Type",
    "itemWhtTotal": "Withholding Tax",
    "whtTotal": "Total Withholding Tax",
    "totalToPay": "Pay Amount",
    "payNumber": "Payment",
    "payDate": "Payment Date",
    "payBy": "Pay By",
    "BANK": "Bank",
    "CASH": "Cash",
    "paiphonedAmount": "Paid Amount",
    "addPaymentList": "Add Payment",
    "save": "Save",
  },
  "expenseNoteConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Expense Note",
  },
  "xero": {
    "loading": "Loading...",
    "page": "Page",
    "allDocuments": "All Documents",
    "documentDate": "Date",
    "number": "Number",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from Xero.",
    "header": "Xero Invoices",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "createDocument": "Create Documents",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "createdDocuments": "All Documents",
    "search": "Search",
    "searchBoxPlaceholder": "Invoice Number",
    "create": "Create",
  },
  "xeroConnect": {
    "header": "Connect Xero",
    "subHeader1": "List Xero invoices for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list Xero invoices for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from Xero.",
  },
  "dynamicBc": {
    "loading": "Loading...",
    "page": "Page",
    "allDocuments": "All Documents",
    "documentDate": "Date",
    "number": "Number",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from Microsoft Dynamics 365 Business Central.",
    "header": "Microsoft Dynamics 365 Business Central Invoices",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "createDocument": "Create Documents",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "createdDocuments": "All Documents",
    "search": "Search",
    "searchBoxPlaceholder": "Invoice Number",
    "create": "Create",
    "allType": "All Status",
    "paidType": "Paid",
    "openType": "Open",
    "draftType": "Draft",
    "documentPerPage": "Docs/Page",
    "vatTotal": "VAT",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "timeRange": "Range mode",
    "fromRange": "From",
    "toRange": "To",
    "timeRangeError": "Please select time range within 30 days",
    "timeRangeError2": "Please select time range within 15 days",
  },
  "dynamicBcConnect": {
    "header": "Connect Microsoft Dynamics 365 Business Central",
    "subHeader1": "List invoices for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list invoices for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from Microsoft Dynamics 365 Business Central.",
  },
  "dynamicCrm": {
    "loading": "Loading...",
    "page": "Page",
    "allDocuments": "All Documents",
    "documentDate": "Date",
    "number": "Number",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from Microsoft Dynamic 365 Sales (CRM).",
    "header": "Microsoft Dynamic 365 Sales (CRM) Invoices",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "createDocument": "Create Documents",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "createdDocuments": "All Documents",
    "search": "Search",
    "searchBoxPlaceholder": "Invoice Number",
    "create": "Create",
    "allType": "All Status",
    "paidType": "Paid",
    "openType": "Open",
    "draftType": "Draft",
    "documentPerPage": "Docs/Page",
    "vatTotal": "VAT",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "activeType": "Active",
    "completeType": "Complete",
    "partialType": "Partial",
    "cancelType": "Cancel",
    "createdTime": "Create Time",
    "amountTotal": "Total (Not Include VAT)",
  },
  "dynamicCrmConnect": {
    "header": "Connect Microsoft Dynamics 365 Sales (CRM)",
    "subHeader1": "List invoices for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list invoices for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from Microsoft Dynamics 365 Sales (CRM).",
    "resourceUrl": "Ex. https://my-org.crm.dynamics.com",
  },
  "dynamicFo": {
    "loading": "Loading...",
    "page": "Page",
    "allDocuments": "All Documents",
    "documentDate": "Date",
    "number": "Number",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "creditNote": "Credit Note",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from Microsoft Dynamics 365 Finance and Operations.",
    "header": "Microsoft Dynamics 365 Finance and Operations Invoices",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "createDocument": "Create Documents",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "createdDocuments": "All Documents",
    "search": "Search",
    "searchBoxPlaceholder": "Invoice Number",
    "create": "Create",
    "allType": "All Status",
    "paidType": "Paid",
    "openType": "Open",
    "draftType": "Draft",
    "documentPerPage": "Docs/Page",
    "vatTotal": "VAT",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "timeRange": "Range mode",
    "fromRange": "From",
    "toRange": "To",
    "timeRangeError": "Please select time range within 30 days",
    "timeRangeError2": "Please select time range within 15 days",
    "selectCompanyCoachMark": "Select your company here.",
    "journalNumber": "Journal Number",
    "createCreditNote": "Create Credit Note",
    "creditNoteNumber": "Number",
    "referNumber": "Refer Document No.",
  },
  "dynamicFoConnect": {
    "header": "Connect Microsoft Dynamics 365 Finance and Operations",
    "subHeader1": "List invoices for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list invoices for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from Microsoft Dynamics 365 Finance and Operations.",
    "resourceUrl": "Ex. https://my-org.operations.dynamics.com",
  },
  "jdCentral": {
    "loading": "Loading...",
    "page": "Page",
    "allDocuments": "All Documents",
    "documentDate": "Date",
    "number": "Number",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from JD Central.",
    "header": "JD Central Orders",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "createDocument": "Create Documents",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "createdDocuments": "All Documents",
    "search": "Search",
    "searchBoxPlaceholder": "Order ID, Name",
    "create": "Create",
    "allType": "All Status",
    "unpaidType": "Unpaid",
    "readyToship": "Ready to ship",
    "shipping": "Shipping",
    "shipped": "Shipped",
    "paidType": "Paid",
    "openType": "Open",
    "draftType": "Draft",
    "documentPerPage": "Docs/Page",
    "vatTotal": "VAT",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "activeType": "Active",
    "completeType": "Complete",
    "partialType": "Partial",
    "cancelType": "Cancel",
    "createdTime": "Create Time",
    "amountTotal": "Total (Not Include VAT)",
    "orderId": "Order ID",
    "payment": "Payment",
    "orders": "Orders",
    "orderUnit": "order",
    "vat": "VAT",
    "thb": "THB",
    "saleIncludeVat": "Total Sales (Include VAT)",
    "sale": "Total Sales",
    "displayShipCost": "Include Shipping Cost",
    "on": "On",
    "off": "Off",
  },
  "jdCentralConnect": {
    "header": "Connect JD Central",
    "subHeader1": "List Orders for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list Orders for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from JD Central.",
  },
  "lazada": {
    "loading": "Loading...",
    "page": "Page",
    "allDocuments": "All Documents",
    "documentDate": "Date",
    "number": "Number",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from Lazada.",
    "header": "Lazada Orders",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "createDocument": "Create Documents",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "createdDocuments": "All Documents",
    "search": "Search",
    "searchBoxPlaceholder": "Order ID",
    "create": "Create",
    "allType": "All Status",
    "delivered": "Delivered",
    "shipped": "Shipped",
    "readyToShip": "Ready to ship",
    "unpaidType": "Unpaid",
    "returned": "Returned",
    "shippedBack": "Shipped Back",
    "shipping": "Shipping",
    "paidType": "Paid",
    "openType": "Open",
    "draftType": "Draft",
    "documentPerPage": "Orders/Page",
    "vatTotal": "VAT",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "activeType": "Active",
    "completeType": "Complete",
    "partialType": "Partial",
    "cancelType": "Cancel",
    "createdTime": "Create Time",
    "amountTotal": "Total (Not Include VAT)",
    "orderId": "Order ID",
    "payment": "Payment",
    "orders": "Orders",
    "orderUnit": "order",
    "vat": "VAT",
    "thb": "THB",
    "saleIncludeVat": "Total (Include VAT)",
    "sale": "Total",
    "displayShipCost": "Include Shipping Cost",
    "on": "On",
    "off": "Off",
    "total": "Total",
    "month": "Month: ",
    "automation": "Automation",
    "onAutomationStatus": "Working",
    "offAutomationStatus": "Not working",
    "awb": "AWB",
    "transactionList": "Transaction List",
    "payoutList": "Payout List",
    "messageBarAWBFails": "Cannot download AWB. Please check your order.",
    "isRequest": "Request TIV",
  },
  "lazadaConnect": {
    "header": "Connect Lazada",
    "subHeader1": "List Orders for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list Orders for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from Lazada.",
  },
  "lazadaAutomation": {
    "triggerStatus": "Create document when order status",
    "receiptTaxInvoice": "Receipt/Tax Invoice",
    "orderStatus": "Order status",
    "number": "Number",
    "prefix": "Prefix number",
    "suffix": "Running number",
    "year": "Year",
    "month": "Month",
    "day": "Day",
    "example": "Ex.",
    "showShippingFee": "Include Shipping Cost",
    "forceCreate": "Create Receipt/Tax Invoice Abb. If customer not request Receipt/Tax Invoice.",
    "receiptTaxInvoiceAbb": "Receipt/Tax Invoice Abb",
    "autoSendEmail": "Auto Sending Email to Customer",
    "onAutoSendEmail": "Automatic send document to customer's email after create document.",
    "offAutoSendEmail": "Automatic create document but not send to customer's email.",
    "none": "-",
    "createOnlyBillingAddress": "Create Receipt/Tax Invoice from order requesting Tax Invoice only.",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "on": "On",
    "off": "Off",
    "onCreateOnlyBillingAddressNote": "Create document only order that request Tax Invoice.",
    "offCreateOnlyBillingAddressNote": "Create document every order (Use shipping address, if customer not fill billing address).",
    "sendEmailOnlyBillingAddress": "Send email to customer who request Tax Invoice only.",
    "cc": "CC to email",
    "unSavedMessage": "There are unsaved changes. Are you sure want to leave this page?",
    "emailSetting": "Email Setting",
  },
  "lazadaFinance": {
    "loading": "Loading...",
    "page": "Page",
    "allDocuments": "All Documents",
    "documentDate": "Date",
    "number": "Number",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from Lazada.",
    "header": "Lazada Transaction List",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "createDocument": "Create Documents",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "createdDocuments": "All Documents",
    "search": "Search",
    "searchBoxPlaceholder": "Order ID",
    "create": "Create",
    "allType": "All Status",
    "delivered": "Delivered",
    "shipped": "Shipped",
    "readyToShip": "Ready to ship",
    "unpaidType": "Unpaid",
    "returned": "Returned",
    "shippedBack": "Shipped Back",
    "shipping": "Shipping",
    "paidType": "Paid",
    "openType": "Open",
    "draftType": "Draft",
    "documentPerPage": "Orders/Page",
    "vatTotal": "VAT",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "activeType": "Active",
    "completeType": "Complete",
    "partialType": "Partial",
    "cancelType": "Cancel",
    "createdTime": "Create Time",
    "amountTotal": "Total (Not Include VAT)",
    "orderId": "Order ID",
    "payment": "Payment",
    "orders": "Orders",
    "orderUnit": "order",
    "vat": "VAT",
    "thb": "THB",
    "saleIncludeVat": "Total (Include VAT)",
    "sale": "Total",
    "displayShipCost": "Include Shipping Cost",
    "on": "On",
    "off": "Off",
    "total": "Total",
    "month": "Month: ",
    "automation": "Automation",
    "onAutomationStatus": "Working",
    "offAutomationStatus": "Not working",
    "transactionDate": "Transaction Date",
    "transactionType": "Transaction Type",
    "transactionNumber": "Transaction Number",
    "orderNumber": "Order Number",
    "orderItemID": "Order Item ID",
    "itemName": "Item Name",
    "comment": "Comment",
    "amount": "Amount",
    "statementPeriod": "Statement Period",
    "taxInvoiceNumber": "Tax Invoice Number",
    "shipmentProvider": "Shipment Provider",
  },
  "lazadaPayout": {
    "loading": "Loading...",
    "page": "Page",
    "allDocuments": "All Documents",
    "documentDate": "Date",
    "number": "Number",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from Lazada.",
    "header": "Lazada Payout List",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "createDocument": "Create Documents",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "createdDocuments": "All Documents",
    "search": "Search",
    "searchBoxPlaceholder": "Order ID",
    "create": "Create",
    "allType": "All Status",
    "delivered": "Delivered",
    "shipped": "Shipped",
    "readyToShip": "Ready to ship",
    "unpaidType": "Unpaid",
    "returned": "Returned",
    "shippedBack": "Shipped Back",
    "shipping": "Shipping",
    "paidType": "Paid",
    "openType": "Open",
    "draftType": "Draft",
    "documentPerPage": "Orders/Page",
    "vatTotal": "VAT",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "activeType": "Active",
    "completeType": "Complete",
    "partialType": "Partial",
    "cancelType": "Cancel",
    "createdTime": "Create Time",
    "amountTotal": "Total (Not Include VAT)",
    "orderId": "Order ID",
    "payment": "Payment",
    "orders": "Orders",
    "orderUnit": "order",
    "vat": "VAT",
    "thb": "THB",
    "saleIncludeVat": "Total (Include VAT)",
    "sale": "Total",
    "displayShipCost": "Include Shipping Cost",
    "on": "On",
    "off": "Off",
    "total": "Total",
    "month": "Month: ",
    "automation": "Automation",
    "onAutomationStatus": "Working",
    "offAutomationStatus": "Not working",
    "transactionDate": "Transaction Date",
    "transactionType": "Transaction Type",
    "transactionNumber": "Transaction Number",
    "orderNumber": "Order Number",
    "orderItemID": "Order Item ID",
    "itemName": "Item Name",
    "comment": "Comment",
    "amount": "Amount",
    "statementPeriod": "Statement Period",
    "taxInvoiceNumber": "Tax Invoice Number",
    "statementNumber": "Statement Number",
    "itemRevenue": "Item Revenue",
    "feesTotal": "Fees Total",
    "shipmentFee": "Shipment Fee",
    "payout": "Payout",
    "createdAt": "Created At",
    "updatedAt": "Updated At",
  },
  "lineShopping": {
    "loading": "Loading...",
    "page": "Page",
    "allDocuments": "All Documents",
    "documentDate": "Date",
    "number": "Number",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from LINE SHOPPING.",
    "header": "LINE SHOPPING Orders",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "createDocument": "Create Documents",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "createdDocuments": "All Documents",
    "search": "Search",
    "searchBoxPlaceholder": "Order Number, Customer Name",
    "create": "Create",
    "allType": "All Status",
    "delivered": "Delivered",
    "shipped": "Shipped",
    "readyToShip": "Ready to ship",
    "unpaidType": "Unpaid",
    "returned": "Returned",
    "shippedBack": "Shipped Back",
    "shipping": "Shipping",
    "paidType": "Paid",
    "openType": "Open",
    "draftType": "Draft",
    "documentPerPage": "Orders/Page",
    "vatTotal": "VAT",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "activeType": "Active",
    "completeType": "Complete",
    "partialType": "Partial",
    "cancelType": "Cancel",
    "createdTime": "Create Time",
    "amountTotal": "Total (Not Include VAT)",
    "orderId": "Order ID",
    "payment": "Payment",
    "orders": "Orders",
    "orderUnit": "order",
    "vat": "VAT",
    "thb": "THB",
    "saleIncludeVat": "Total (Include VAT)",
    "sale": "Total",
    "displayShipCost": "Include Shipping Cost",
    "on": "On",
    "off": "Off",
    "total": "Total",
    "month": "Month: ",
    "finalized": "FINALIZED",
    "completed": "COMPLETED",
    "expired": "EXPIRED",
    "canceled": "CANCELED",
  },
  "lineShoppingConnect": {
    "header": "Connect LINE SHOPPING",
    "subHeader1": "List orders for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list Orders for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from LINE SHOPPING.",
  },
  "paypal": {
    "allDocuments": "All Documents",
    "customerAddress": "Address",
    "phone": "Phone",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "refresh": "Refresh",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "allType": "All Status",
    "paidType": "Paid",
    "openType": "Open",
    "draftType": "Draft",
    "documentPerPage": "Docs/Page",
    "vatTotal": "VAT",
    "page": "Page",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "createdTime": "Created Date",
    "number": "Number",
    "documentDate": "Date",
    "customerName": "Customer",
    "customerEmail": "E-mail",
    "grandTotal": "Total (Include VAT)",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "create": "Create",
    "disconnectConfirm": "Please confirm to disconnect from PayPal.",
    "disconnect": "Disconnect",
    "header": "PayPal Invoices",
    "loading": "Loading...",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "createDocument": "Create Documents",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "createdDocuments": "All Documents",
    "searchBoxPlaceholder": "Invoice Number",
    "search": "Search",
    "footer": "Disconnect PayPal",
    "processing2": "Processing...",
    "duplicateInvoiceNumber": "Invoice number is duplicate"
  },
  "paypalConnect": {
    "header": "Connect PayPal",
    "subHeader1": "List invoices for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list invoices for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from PayPal.",
  },
  "stripe": {
    "allDocuments": "All Documents",
    "customerAddress": "Address",
    "phone": "Phone",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "refresh": "Refresh",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "allType": "All Status",
    "paidType": "Paid",
    "openType": "Open",
    "draftType": "Draft",
    "documentPerPage": "Docs/Page",
    "vatTotal": "VAT",
    "page": "Page",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "createdTime": "Created Date",
    "number": "Number",
    "documentDate": "Date",
    "customerName": "Customer",
    "customerEmail": "E-mail",
    "grandTotal": "Total (Include VAT)",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "create": "Create",
    "disconnectConfirm": "Please confirm to disconnect from Stripe.",
    "disconnect": "Disconnect",
    "header": "Stripe Invoices",
    "loading": "Loading...",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "createDocument": "Create Documents",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "createdDocuments": "All Documents",
    "searchBoxPlaceholder": "Invoice Number",
    "search": "Search",
    "footer": "Disconnect Stripe",
    "processing2": "Processing...",
  },
  "stripeConnect": {
    "header": "Connect Stripe",
    "subHeader1": "List invoices for create e-Tax Invoice & e-Receipt on Leceipt",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list invoices for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from Stripe.",
  },
  "peak": {
    "page": "Page",
    "allDocuments": "All Documents",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "createDocument": "Create Documents",
    "createdDocuments": "All Documents",
    "search": "Search",
    "searchBoxPlaceholder": "Invoice Number",
    "create": "Create",
    "openType": "Open",
    "vatTotal": "VAT",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "thb": "THB",
    "allType": "All Status",
    "draftType": "Draft",
    "pendingApproveType": "Pending Approval",
    "pendingPaymentType": "Pending Payment",
    "overdueType": "Overdue",
    "paidType": "Paid",
    "paidType2": "Paid",
    "voidType": "Void",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "number": "Number",
    "documentDate": "Date",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "view": "View",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "createEtax": "Create e-Tax",
    "documentPerPage": "Docs/Page",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from Peak.",
    "header": "PEAK Account Documents",
    "documents": "Total Documents",
    "documentsUnit": "Document",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "viewAllEtax": "All Documents",
    "loading": "Loading...",
  },
  "peakConnect": {
    "header": "Connect PEAK Account",
    "subHeader1": "List documents for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list documents for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from PEAK.",
    "plsContact": "Contact PEAK for User Token",
  },
  "quickBook": {
    "page": "Page",
    "allDocuments": "All Documents",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "create": "Create",
    "openType": "Open",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "allType": "All Status",
    "draftType": "Draft",
    "pendingApproveType": "Pending Approval",
    "pendingPaymentType": "Pending Payment",
    "overdueType": "Overdue",
    "paidType": "Paid",
    "paidType2": "Paid",
    "voidType": "Void",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "number": "Number",
    "documentDate": "Date",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "view": "View",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "createEtax": "Create e-Tax",
    "documentPerPage": "Docs/Page",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from QuickBooks.",
    "header": "QuickBooks Invoices",
    "documents": "Total Documents",
    "documentsUnit": "Document",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "viewAllEtax": "All Documents",
    "loading": "Loading...",
    "createdTime": "Created Date",
    "vatTotal": "VAT",
    "createDocument": "Create Documents",
    "createdDocuments": "All Documents",
    "search": "Search",
    "searchBoxPlaceholder": "Invoice Number",
  },
  "quickBookConnect": {
    "header": "Connect QuickBooks",
    "subHeader1": "List Invoices for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list Invoices for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from QuickBooks.",
  },
  "sapB1": {
    "page": "Page",
    "allDocuments": "All Documents",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "create": "Create",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "allType": "All Status",
    "draftType": "Draft",
    "pendingApproveType": "Pending Approval",
    "pendingPaymentType": "Pending Payment",
    "overdueType": "Overdue",
    "voidType": "Void",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "number": "Number",
    "documentDate": "Date",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "view": "View",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "createEtax": "Create e-Tax",
    "documentPerPage": "Docs/Page",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from SAP Business One.",
    "header": "SAP Business One Invoices",
    "documents": "Total Documents",
    "documentsUnit": "Document",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "viewAllEtax": "All Documents",
    "loading": "Loading...",
    "createdTime": "Created Date",
    "vatTotal": "VAT",
    "createDocument": "Create Documents",
    "createdDocuments": "All Documents",
    "search": "Search",
    "searchBoxPlaceholder": "Invoice Number",
    "paidType": "Paid",
    "deliveredType": "Delivered",
    "openType": "Open",
    "closeType": "Close",
    "taxId": "Tax ID",
  },
  "sapB1Connect": {
    "header": "Connect SAP Business One",
    "subHeader1": "List Invoices for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list Invoices for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from SAP Business One.",
  },
  "sapS4Hana": {
    "page": "Page",
    "allDocuments": "All Documents",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "create": "Create",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "allType": "All Status",
    "draftType": "Draft",
    "pendingApproveType": "Pending Approval",
    "pendingPaymentType": "Pending Payment",
    "overdueType": "Overdue",
    "voidType": "Void",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "number": "Number",
    "documentDate": "Date",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "view": "View",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "createEtax": "Create e-Tax",
    "documentPerPage": "Docs/Page",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from SAP S/4HANA.",
    "documents": "Total Documents",
    "documentsUnit": "Document",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "viewAllEtax": "All Documents",
    "loading": "Loading...",
    "createdTime": "Created Date",
    "vatTotal": "VAT",
    "createDocument": "Create Documents",
    "createdDocuments": "All Documents",
    "search": "Search",
    "paidType": "Paid",
    "deliveredType": "Delivered",
    "openType": "Open",
    "closeType": "Close",
    "taxId": "Tax ID",
    "customerCode": "Partner Code",
    "amountTotal": "Subtotal",
    "header": "SAP S/4HANA Billing Documents",
    "searchBoxPlaceholder": "Billing Documents Number",
  },
  "sapS4HanaConnect": {
    "header": "Connect SAP S/4HANA",
    "subHeader1": "List Invoices for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list Invoices for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from SAP S/4HANA.",
  },
  "shipnity": {
    "page": "Page",
    "allDocuments": "All Documents",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "create": "Create",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "allType": "All Status",
    "draftType": "Draft",
    "pendingApproveType": "Pending Approval",
    "pendingPaymentType": "Pending Payment",
    "overdueType": "Overdue",
    "voidType": "Void",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "documentDate": "Date",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "view": "View",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "createEtax": "Create e-Tax",
    "documentPerPage": "Docs/Page",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from Shipnity.",
    "documents": "Total Documents",
    "documentsUnit": "Document",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "viewAllEtax": "All Documents",
    "loading": "Loading...",
    "createdTime": "Created Date",
    "vatTotal": "VAT",
    "createDocument": "Create Documents",
    "createdDocuments": "All Documents",
    "search": "Search",
    "paidType": "Paid",
    "deliveredType": "Delivered",
    "openType": "Open",
    "closeType": "Close",
    "taxId": "Tax ID",
    "customerCode": "Partner Code",
    "amountTotal": "Subtotal",
    "header": "Shipnity Order",
    "searchBoxPlaceholder": "Anything but Document No.",
    "closed": "Closed",
    "open": "Open",
    "checkBank": "Check Bank",
    "printOrder": "Print Order",
    "packing": "Packing",
    "payLater": "Pay Later",
    "realPreorder": "Real Preorder",
    "preorder": "Preorder",
    "orderNumber": "Order No.",
    "number": "Document No.",
    "processing2": "Processing...",
    "footer": "Disconnect Shipnity",
  },
  "shipnityConnect": {
    "header": "Connect Shipnity",
    "subHeader1": "List Orders for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list Orders for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from Shipnity.",
    "shipnityEmail": "E-mail",
    "token": "Token (provided by Shipnity)",
  },
  "shopee": {
    "page": "Page",
    "allDocuments": "All Documents",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "create": "Create",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "draftType": "Draft",
    "pendingApproveType": "Pending Approval",
    "pendingPaymentType": "Pending Payment",
    "overdueType": "Overdue",
    "voidType": "Void",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "documentDate": "Date",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "view": "View",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "createEtax": "Create e-Tax",
    "documentPerPage": "Docs/Page",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from Shopee.",
    "documents": "Total Documents",
    "documentsUnit": "Document",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "viewAllEtax": "All Documents",
    "loading": "Loading...",
    "createdTime": "Created Date",
    "vatTotal": "VAT",
    "createDocument": "Create Documents",
    "createdDocuments": "All Documents",
    "search": "Search",
    "paidType": "Paid",
    "deliveredType": "Delivered",
    "openType": "Open",
    "closeType": "Close",
    "taxId": "Tax ID",
    "customerCode": "Partner Code",
    "amountTotal": "Subtotal",
    "header": "Shopee Order",
    "closed": "Closed",
    "open": "Open",
    "checkBank": "Check Bank",
    "printOrder": "Print Order",
    "packing": "Packing",
    "payLater": "Pay Later",
    "realPreorder": "Real Preorder",
    "preorder": "Preorder",
    "orderNumber": "Order No.",
    "number": "Document No.",
    "processing2": "Processing...",
    "footer": "Disconnect Shopee",
    "COMPLETED": "COMPLETED",
    "ToConfirmReceive": "TO CONFIRM RECEIVE",
    "SHIPPED": "SHIPPED",
    "readyToSHIPPED": "READY TO SHIPPED",
    "PROCESSED": "PROCESSED",
    "InvoicePENDING": "INVOICE PENDING",
    "UNPAID": "UNPAID",
    "InCANCEL": "IN CANCEL",
    "CANCELLED": "CANCELLED",
    "updateTime": "Update Time",
    "createTime": "Create Time",
    "updatedDate": "Updated Date",
    "day1": "",
    "day2": " Date",
    "orderId": "Order No.",
    "total": "Subtotal",
    "allType": "All Type",
    "orders": "Orders",
    "orderUnit": "order",
    "month": "Month: ",
    "displayShipCost": "Include Shipping Cost",
    "on": "On",
    "off": "Off",
    "searchBoxPlaceholder": "Order No.",
    "buyerPayment": "Total Amount",
    "automation": "Automation",
    "onAutomationStatus": "Working",
    "offAutomationStatus": "Not working",
    "isRequest": "Request TIV",
  },
  "shopeeConnect": {
    "header": "Connect Shopee",
    "subHeader1": "List Orders for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list Orders for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from Shopee.",
  },
  "shopeeAutomation": {
    "triggerStatus": "Create document when order status",
    "receiptTaxInvoice": "Receipt/Tax Invoice",
    "orderStatus": "Order Status",
    "number": "Number",
    "prefix": "Prefix number",
    "suffix": "Running number",
    "year": "Year",
    "month": "Month",
    "day": "Day",
    "example": "Ex.",
    "showShippingFee": "Show Shipping Fee in Document",
    "forceCreate": "Create Receipt/Tax Invoice Abb. If customer not request Receipt/Tax Invoice.",
    "receiptTaxInvoiceAbb": "Receipt/Tax Invoice Abb",
    "autoSendEmail": "Automatic Send Email to Customer",
    "onAutoSendEmail": "Automatic send document to customer's email after create document.",
    "offAutoSendEmail": "Automatic create document but not send to customer's email.",
    "none": "-",
    "createOnlyBillingAddress": "Create document only order that request Tax Invoice",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "autoSendEmailNote": "If customer did not request Tax Invoice, system can not send email.",
    "on": "On",
    "off": "Off",
    "onCreateOnlyBillingAddressNote": "Create document only order that request Tax Invoice.",
    "offCreateOnlyBillingAddressNote": "Create document every order (Use shipping address, if customer not fill billing address).",
    "sendEmailOnlyBillingAddress": "Send email to customer who request Tax Invoice only.",
    "cc": "CC to email",
    "unSavedMessage": "There are unsaved changes. Are you sure want to leave this page?",
    "emailSetting": "Email Setting",
  },  
  "tiktokShop": {
    "page": "Page",
    "allDocuments": "All Documents",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "create": "Create",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "draftType": "Draft",
    "pendingApproveType": "Pending Approval",
    "pendingPaymentType": "Pending Payment",
    "overdueType": "Overdue",
    "voidType": "Void",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "documentDate": "Date",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "view": "View",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "createEtax": "Create e-Tax",
    "documentPerPage": "Docs/Page",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from TikTok Shop.",
    "documents": "Total Documents",
    "documentsUnit": "Document",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "viewAllEtax": "All Documents",
    "loading": "Loading...",
    "createdTime": "Created Date",
    "vatTotal": "VAT",
    "createDocument": "Create Documents",
    "createdDocuments": "All Documents",
    "search": "Search",
    "paidType": "Paid",
    "deliveredType": "Delivered",
    "openType": "Open",
    "closeType": "Close",
    "taxId": "Tax ID",
    "customerCode": "Partner Code",
    "amountTotal": "Subtotal",
    "header": "TikTok Shop Order",
    "closed": "Closed",
    "open": "Open",
    "checkBank": "Check Bank",
    "printOrder": "Print Order",
    "packing": "Packing",
    "payLater": "Pay Later",
    "realPreorder": "Real Preorder",
    "preorder": "Preorder",
    "orderNumber": "Order No.",
    "number": "Document No.",
    "processing2": "Processing...",
    "footer": "Disconnect TikTok Shop",
    "COMPLETED": "COMPLETED",
    "SHIPPED": "SHIPPED",
    "readyToSHIPPED": "READY TO SHIPPED",
    "PROCESSED": "PROCESSED",
    "InvoicePENDING": "INVOICE PENDING",
    "UNPAID": "UNPAID",
    "InCANCEL": "IN CANCEL",
    "CANCELLED": "CANCELLED",
    "updateTime": "Update Time",
    "createTime": "Create Time",
    "updateTimeColumn": "Update Time",
    "updatedDate": "Updated Date",
    "day1": "",
    "day2": " Date",
    "orderId": "Order No.",
    "total": "Subtotal",
    "allType": "ALL ORDER STATUS",
    "orders": "Orders",
    "orderUnit": "order",
    "month": "Month: ",
    "displayShipCost": "Include Shipping Cost",
    "on": "On",
    "off": "Off",
    "searchBoxPlaceholder": "Order No.",
    "buyerPayment": "Total Amount",
    "automation": "Automation",
    "onAutomationStatus": "Working",
    "offAutomationStatus": "Not working",
    "completeStatus": "COMPLETED",
    "deliveredStatus": "DELIVERED",
    "inTransitStatus": "IN_TRANSIT",
    "partiallyShippingStatus": "PARTIALLY_SHIPPING",
    "awaitingCollectionStatus": "AWAITING_COLLECTION",
    "awaitingShipmentStatus": "AWAITING_SHIPMENT",
    "unpaidStatus": "UNPAID",
    "canceledStatus": "CANCELLED",
    "shipmentProvider": "Shipment Provider",
    "productName": "Product Name",
    "subTotal": "Sub Total",
    "settlement": "Settle Amount",
    "settlementDate": "Date",
    "commission": "Commission",
    "sortType1": "Settlement Time",
    "sortType0": "Trade Time",
    "transactionFee": "Transaction Fee",
  },
  "tiktokShopConnect": {
    "header": "Connect TikTok Shop",
    "subHeader1": "List Orders for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list Orders for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from TikTok Shop.",
  },
  "tiktokShopAutomation": {
    "triggerStatus": "Create document when order status",
    "receiptTaxInvoice": "Receipt/Tax Invoice",
    "orderStatus": "Order status",
    "number": "Number",
    "prefix": "Prefix number",
    "suffix": "Running number",
    "year": "Year",
    "month": "Month",
    "day": "Day",
    "example": "Ex.",
    "showShippingFee": "Include Shipping Cost",
    "forceCreate": "Create Receipt/Tax Invoice Abb. If customer not request Receipt/Tax Invoice.",
    "receiptTaxInvoiceAbb": "Receipt/Tax Invoice Abb",
    "autoSendEmail": "Auto Sending Email to Customer",
    "onAutoSendEmail": "Automatic send document to customer's email after create document.",
    "offAutoSendEmail": "Automatic create document but not send to customer's email.",
    "none": "-",
    "createOnlyBillingAddress": "Create Receipt/Tax Invoice from order requesting Tax Invoice only.",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "on": "On",
    "off": "Off",
    "onCreateOnlyBillingAddressNote": "Create document only order that request Tax Invoice.",
    "offCreateOnlyBillingAddressNote": "Create document every order (Use shipping address, if customer not fill billing address).",
    "sendEmailOnlyBillingAddress": "Send email to customer who request Tax Invoice only.",
    "cc": "CC to email",
    "unSavedMessage": "There are unsaved changes. Are you sure want to leave this page?",
    "emailSetting": "Email Setting",
  },
  "trCloud": {
    "page": "Page",
    "allDocuments": "All Documents",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "create": "Create",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "draftType": "Draft",
    "pendingApproveType": "Pending Approval",
    "pendingPaymentType": "Pending Payment",
    "overdueType": "Overdue",
    "voidType": "Void",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "documentDate": "Date",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "view": "View",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "createEtax": "Create e-Tax",
    "documentPerPage": "Docs/Page",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from TRCloud.",
    "documents": "Total Documents",
    "documentsUnit": "Document",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "viewAllEtax": "All Documents",
    "loading": "Loading...",
    "vatTotal": "VAT",
    "createDocument": "Create Documents",
    "createdDocuments": "All Documents",
    "search": "Search",
    "paidType": "Paid",
    "deliveredType": "Delivered",
    "openType": "Open",
    "closeType": "Close",
    "taxId": "Tax ID",
    "customerCode": "Partner Code",
    "amountTotal": "Subtotal",
    "header": "TRCloud Invoices",
    "closed": "Closed",
    "open": "Open",
    "checkBank": "Check Bank",
    "printOrder": "Print Order",
    "packing": "Packing",
    "payLater": "Pay Later",
    "realPreorder": "Real Preorder",
    "preorder": "Preorder",
    "orderNumber": "Order No.",
    "processing2": "Processing...",
    "footer": "Disconnect TRCloud",
    "COMPLETED": "COMPLETED",
    "SHIPPED": "SHIPPED",
    "readyToSHIPPED": "READY TO SHIPPED",
    "PROCESSED": "PROCESSED",
    "InvoicePENDING": "INVOICE PENDING",
    "UNPAID": "UNPAID",
    "InCANCEL": "IN CANCEL",
    "CANCELLED": "CANCELLED",
    "updateTime": "Update Time",
    "createTime": "Create Time",
    "updatedDate": "Updated Date",
    "day1": "",
    "day2": " Date",
    "orderId": "Order No.",
    "total": "Subtotal",
    "orders": "Orders",
    "orderUnit": "order",
    "month": "Month",
    "displayShipCost": "Include Shipping Cost",
    "on": "On",
    "off": "Off",
    "allType": "All Type",
    "debtor": "Debtor",
    "partial": "Partial",
    "cash": "Cash",
    "paid": "Paid",
    "createdTime": "Document Date",
    "documentTotal": "Total Documents",
    "vat": "VAT",
    "sale": "Sales (Include VAT)",
    "thb": "THB",
    "number": "Document No.",
    "searchBoxPlaceholder": "Keyword",
    "creditNote": "Credit Note",
    "allOrderType": "Tax Invoice",
  },
  "trCloudConnect": {
    "header": "Connect TRCloud",
    "subHeader1": "List Invoices for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list Invoices for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from TRCloud.",
  },
  "wooCommerce": {
    "loading": "Loading...",
    "page": "Page",
    "allDocuments": "All Documents",
    "documentDate": "Date",
    "number": "Number",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "paid": "Paid",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from Woo Commerce.",
    "header": "Woo Commerce Orders",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "createDocument": "Create Documents",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "createdDocuments": "All Documents",
    "search": "Search",
    "searchBoxPlaceholder": "Keyword",
    "create": "Create",
    "allType": "All Status",
    "pendingType": "Pending",
    "processingType": "Processing",
    "on-holdType": "On-hold",
    "completedType": "Completed",
    "cancelledType": "Cancelled",
    "refundedType": "Refunded",
    "failedType": "Failed",
    "trashType": "Trash",
    "documentPerPage": "Docs/Page",
    "vatTotal": "VAT",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "timeRange": "Range mode",
    "fromRange": "From",
    "toRange": "To",
    "timeRangeError": "Please select time range within 30 days",
    "timeRangeError2": "Please select time range within 15 days",
    "day1": "",
    "day2": " Date",
    "updateTime": "Update Time",
    "createTime": "Create Time",
    "updatedDate": "Updated Date",
    "pendingTypeColumn": "Pending",
    "processingTypeColumn": "Processing",
    "on-holdTypeColumn": "On-hold",
    "completedTypeColumn": "Completed",
    "cancelledTypeColumn": "Cancelled",
    "refundedTypeColumn": "Refunded",
    "failedTypeColumn": "Failed",
    "trashTypeColumn": "Trash",
    "createdTime": "Created Date",
  },
  "wooCommerceConnect": {
    "header": "Connect Woo Commerce",
    "subHeader1": "List orders for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list orders for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from Woo Commerce.",
    "resourceUrl": "Ex. https://my-org.operations.dynamics.com",
    "shopUrl": "Ex. https://my-shop.com",
  },
  "zoho": {
    "page": "Page",
    "allDocuments": "All Documents",
    "thisMonth": "This Month",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "goToToday": "Go to today",
    "isRequiredErrorMessage": "Please select date",
    "anotherOrganization": "Another Company",
    "authorised": "Authorised",
    "draft": "Draft",
    "voided": "Voided",
    "deleted": "Deleted",
    "subHeader": "Fill start document number for creating documents.",
    "prefixNumber": "Document prefix number",
    "prefixNumberDescription": "Ex. RE202201",
    "countingNumber": "Running number",
    "countingNumberDesciption": "Ex. 00001",
    "countingNumberErrorMessage": "Please fill number.",
    "startDocumentNumber": "Start document number",
    "endDocumentNumber": "Next document number",
    "create": "Create",
    "createdBy": "Created By/Received By",
    "createdByDesc": "This will be displayed in every selected documents",
    "draftType": "Draft",
    "pendingApproveType": "Pending Approval",
    "pendingPaymentType": "Pending Payment",
    "overdueType": "Overdue",
    "voidType": "Void",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "documentDate": "Date",
    "customerName": "Customer",
    "customerAddress": "Address",
    "phone": "Phone",
    "grandTotal": "Total (Include VAT)",
    "view": "View",
    "pending": "Pending",
    "processing": "Processing...",
    "success": "Success",
    "fail": "Fail",
    "createEtax": "Create e-Tax",
    "documentPerPage": "Docs/Page",
    "refresh": "Refresh",
    "disconnect": "Disconnect",
    "disconnectConfirm": "Please confirm to disconnect from Zoho.",
    "documents": "Total Documents",
    "documentsUnit": "Document",
    "cancel": "Cancel",
    "signProcessing": "Creating and signing document...",
    "viewAllEtax": "All Documents",
    "loading": "Loading...",
    "vatTotal": "VAT",
    "createDocument": "Create Documents",
    "createdDocuments": "All Documents",
    "search": "Search",
    "paidType": "Paid",
    "deliveredType": "Delivered",
    "openType": "Open",
    "closeType": "Close",
    "taxId": "Tax ID",
    "customerCode": "Partner Code",
    "amountTotal": "Subtotal",
    "header": "Zoho Invoices",
    "closed": "Closed",
    "open": "Open",
    "checkBank": "Check Bank",
    "printOrder": "Print Order",
    "packing": "Packing",
    "payLater": "Pay Later",
    "realPreorder": "Real Preorder",
    "preorder": "Preorder",
    "orderNumber": "Order No.",
    "processing2": "Processing...",
    "footer": "Disconnect Zoho",
    "COMPLETED": "COMPLETED",
    "SHIPPED": "SHIPPED",
    "readyToSHIPPED": "READY TO SHIPPED",
    "PROCESSED": "PROCESSED",
    "InvoicePENDING": "INVOICE PENDING",
    "UNPAID": "UNPAID",
    "InCANCEL": "IN CANCEL",
    "CANCELLED": "CANCELLED",
    "updateTime": "Update Time",
    "createTime": "Create Time",
    "updatedDate": "Updated Date",
    "day1": "",
    "day2": " Date",
    "orderId": "Order No.",
    "total": "Subtotal",
    "orders": "Orders",
    "orderUnit": "order",
    "month": "Month",
    "displayShipCost": "Include Shipping Cost",
    "on": "On",
    "off": "Off",
    "allType": "All Type",
    "debtor": "Debtor",
    "partial": "Partial",
    "cash": "Cash",
    "paid": "Paid",
    "createdTime": "Document Date",
    "documentTotal": "Total Documents",
    "vat": "VAT",
    "sale": "Sales (Include VAT)",
    "thb": "THB",
    "number": "Document No.",
    "email": "E-mail",
    "messageBar": "Sorry, you have reached data calling limit for today",
    "searchBoxPlaceholder": "Invoice No., Name",
  },
  "zohoConnect": {
    "header": "Connect Zoho",
    "subHeader1": "List Invoices for create e-Tax Invoice & e-Receipt on Leceipt",
    "subHeader2": "This connector has subscription fee (Free trial)",
    "connect": "Connect",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successButton": "Next",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "This connector will list Invoices for create e-Tax Invoice & e-Receipt",
    "footer2": "but not store any data from Zoho.",
  },
  "inputTax": {
    "warningSetupCertificate": "e-Tax Invoice & e-Receipt documents will complete with real electronic certificate only.",
    "warningSetupCertificateLink": "Set up electronic certificate.",
    "warningXmlDelivery": "XML files will submit to the Revenue Department every 5th of each month.",
    "close": "Close",
    "allDocuments": "All Sales Documents",
    "totalDocuments": "Total Documents",
    "vat": "VAT",
    "salesAmount": "Sales (Include VAT)",
    "documents": "Docs",
    "thb": "THB",
    "signProcessing": "Creating and signing document...",
    "sendFileByEmail": "Send file by email",
    "sendToEmail": "Send to email",
    "ccToEmail": "CC to email",
    "sendEmail": "Send email",
    "cancel": "Cancel",
    "sendFileBySms": "Send file by SMS",
    "mobileNumer": "Mobile number",
    "exampleNumber": "Ex. 0825798555",
    "sendSms": "Send SMS",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "allDocumentsType": "All Documents",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "createdDate": "Created Date",
    "documentDate": "Document Date",
    "thisMonth": "This Month",
    "documentPerPage": "Docs/Page",
    "page": "Page",
    "refresh": "Refresh",
    "confirmSendEmail": "Please confirm to send email",
    "create": "Create",
    "combinedReceipt": "Combine Receipt",
    "combinedReceiptAlert": "Some document type can not create combine Receipt, please select new documents.",
    "download": "Download",
    "emailAlert": "Please fill valid email address.",
    "mobileNumberAlert": "Please fill valid mobile phone number.",
    "number": "Number",
    "customerName": "Seller Name",
    "grandTotal": "Total (Include VAT)",
    "document": "Document",
    "openFile": "Open File",
    "email": "Email ",
    "emailDate": "Email Date",
    "sendingEmail": "Sending email...",
    "success": "Success",
    "emailNotFound": "Email not found",
    "fail": "Fail",
    "file": "File",
    "openDocument": "Open Document",
    "reCreateDocument": "Recreate Document",
    "reIssuedDocument": "Reissue Document",
    "delete": "Delete",
    "deleteDisable": "Delete (Submitted XML)",
    "sendEmailSuccess": "Sent email successfully!",
    "sendEmailFail": "Sent email fail!",
    "sendSmsSuccess": "Sent SMS successfully!",
    "sendSmsFail": "Sent SMS fail!",
    "instantTopup": "Instant documents topup 24 hr.",
    "select": "Select",
    "isRequiredErrorMessage": "Please select date",
    "inputTaxReport": "Input Tax Report",
    "downloadPdf": "Download PDF",
    "downloadCsv": "Download CSV",
    "taxId": "Tax ID",
    "branchNumber": "Branch No.",
    "amountTotal": "Subtotal",
    "vatTotal": "VAT",
    "header": "Input Tax Report",
    "documentUnit": "document",
    "purchaseTotal": "Total Purchase (Not Include VAT)",
    "purchaseVat": "VAT",
    "loading": "Loading...",
    "search": "Search",
    "searchPlaceholder": "Search",
  },
  "inputTaxConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Input Tax Report",
    "downloadPdf": "Download PDF",
    "downloadCsv": "Download CSV",
    "back": "Back",
    "loading": "Loading...",
  },
  "emailDeliveries": {
    "warningSetupCertificate": "e-Tax Invoice & e-Receipt documents will complete with real electronic certificate only.",
    "warningSetupCertificateLink": "Set up electronic certificate.",
    "warningXmlDelivery": "XML files will submit to the Revenue Department every 5th of each month.",
    "close": "Close",
    "allDocuments": "All Sales Documents",
    "totalDocuments": "Total Documents",
    "vat": "VAT",
    "salesAmount": "Sales (Include VAT)",
    "documents": "Docs",
    "thb": "THB",
    "signProcessing": "Creating and signing document...",
    "sendFileByEmail": "Send file by email",
    "sendToEmail": "Send to email",
    "ccToEmail": "CC to email",
    "sendEmail": "Send email",
    "cancel": "Cancel",
    "sendFileBySms": "Send file by SMS",
    "mobileNumer": "Mobile number",
    "exampleNumber": "Ex. 0825798555",
    "sendSms": "Send SMS",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "allDocumentsType": "All Documents",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "createdDate": "Sent Date",
    "documentDate": "Document Date",
    "thisMonth": "This Month",
    "documentPerPage": "Docs/Page",
    "page": "Page",
    "refresh": "Refresh",
    "confirmSendEmail": "Please confirm to send email",
    "create": "Create",
    "combinedReceipt": "Combine Receipt",
    "combinedReceiptAlert": "Some document type can not create combine Receipt, please select new documents.",
    "download": "Download",
    "emailAlert": "Please fill valid email address.",
    "mobileNumberAlert": "Please fill valid mobile phone number.",
    "number": "Number",
    "customerName": "Customer Name",
    "grandTotal": "Total (Include VAT)",
    "document": "Document",
    "openFile": "Open File",
    "email": "Email ",
    "emailDate": "Email Date",
    "sendingEmail": "Sending email...",
    "success": "Success",
    "emailNotFound": "Email not found",
    "fail": "Fail",
    "file": "File",
    "openDocument": "Open Document",
    "reCreateDocument": "Recreate Document",
    "reIssuedDocument": "Reissue Document",
    "delete": "Delete",
    "deleteDisable": "Delete (Submitted XML)",
    "sendEmailSuccess": "Sent email successfully!",
    "sendEmailFail": "Sent email fail!",
    "sendSmsSuccess": "Sent SMS successfully!",
    "sendSmsFail": "Sent SMS fail!",
    "instantTopup": "Instant documents topup 24 hr.",
    "select": "Select",
    "isRequiredErrorMessage": "Please select date",
    "inputTaxReport": "Input Tax Report",
    "downloadPdf": "Download PDF",
    "downloadCsv": "Download CSV",
    "taxId": "Tax ID",
    "branchNumber": "Branch No.",
    "amountTotal": "Subtotal",
    "vatTotal": "VAT",
    "header": "History Email Log",
    "documentUnit": "document",
    "purchaseTotal": "Total Purchase (Not Include VAT)",
    "purchaseVat": "VAT",
    "loading": "Loading...",
    "search": "Search",
    "searchPlaceholder": "Search",
    "saleTotal": "Total Sale (Not Include VAT)",
    "saleVat": "VAT",
    "to": "To",
    "documentTypeName": "Document Type",
    "provider": "Email Provider",
    "timeRange": "Range mode",
    "fromRange": "From",
    "toRange": "To",
    "timeRangeError": "Please select time range within 30 days",
    "timeRangeError2": "Please select time range within 15 days",
    "month": "Month ",
    "company": "Company ",
    "companyTaxId": "Tax ID ",
    "address": "Address ",
  },
  "smsDeliveries": {
    "warningSetupCertificate": "e-Tax Invoice & e-Receipt documents will complete with real electronic certificate only.",
    "warningSetupCertificateLink": "Set up electronic certificate.",
    "warningXmlDelivery": "XML files will submit to the Revenue Department every 5th of each month.",
    "close": "Close",
    "allDocuments": "All Sales Documents",
    "totalDocuments": "Total Documents",
    "vat": "VAT",
    "salesAmount": "Sales (Include VAT)",
    "documents": "Docs",
    "thb": "THB",
    "signProcessing": "Creating and signing document...",
    "sendFileByEmail": "Send file by email",
    "sendToEmail": "Send to email",
    "ccToEmail": "CC to email",
    "sendEmail": "Send email",
    "cancel": "Cancel",
    "sendFileBySms": "Send file by SMS",
    "mobileNumer": "Mobile number",
    "exampleNumber": "Ex. 0825798555",
    "sendSms": "Send SMS",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "allDocumentsType": "All Documents",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "createdDate": "Sent Date",
    "documentDate": "Document Date",
    "thisMonth": "This Month",
    "documentPerPage": "Docs/Page",
    "page": "Page",
    "refresh": "Refresh",
    "confirmSendEmail": "Please confirm to send email",
    "create": "Create",
    "combinedReceipt": "Combine Receipt",
    "combinedReceiptAlert": "Some document type can not create combine Receipt, please select new documents.",
    "download": "Download",
    "emailAlert": "Please fill valid email address.",
    "mobileNumberAlert": "Please fill valid mobile phone number.",
    "number": "Number",
    "customerName": "Customer Name",
    "grandTotal": "Total (Include VAT)",
    "document": "Document",
    "openFile": "Open File",
    "email": "Email ",
    "emailDate": "Email Date",
    "sendingEmail": "Sending email...",
    "success": "Success",
    "emailNotFound": "Email not found",
    "fail": "Fail",
    "file": "File",
    "openDocument": "Open Document",
    "reCreateDocument": "Recreate Document",
    "reIssuedDocument": "Reissue Document",
    "delete": "Delete",
    "deleteDisable": "Delete (Submitted XML)",
    "sendEmailSuccess": "Sent email successfully!",
    "sendEmailFail": "Sent email fail!",
    "sendSmsSuccess": "Sent SMS successfully!",
    "sendSmsFail": "Sent SMS fail!",
    "instantTopup": "Instant documents topup 24 hr.",
    "select": "Select",
    "isRequiredErrorMessage": "Please select date",
    "inputTaxReport": "Input Tax Report",
    "downloadPdf": "Download PDF",
    "downloadCsv": "Download CSV",
    "taxId": "Tax ID",
    "branchNumber": "Branch No.",
    "amountTotal": "Subtotal",
    "vatTotal": "VAT",
    "header": "History SMS Log",
    "documentUnit": "document",
    "purchaseTotal": "Total Purchase (Not Include VAT)",
    "purchaseVat": "VAT",
    "loading": "Loading...",
    "search": "Search",
    "searchPlaceholder": "Search",
    "saleTotal": "Total Sale (Not Include VAT)",
    "saleVat": "VAT",
    "to": "To",
    "documentTypeName": "Document Type",
    "provider": "Email Provider",
    "timeRange": "Range mode",
    "fromRange": "From",
    "toRange": "To",
    "timeRangeError": "Please select time range within 30 days",
    "timeRangeError2": "Please select time range within 15 days",
    "month": "Month ",
    "company": "Company ",
    "companyTaxId": "Tax ID ",
    "address": "Address ",
    "message": "Message",
  },
  "outputTax": {
    "warningSetupCertificate": "e-Tax Invoice & e-Receipt documents will complete with real electronic certificate only.",
    "warningSetupCertificateLink": "Set up electronic certificate.",
    "warningXmlDelivery": "XML files will submit to the Revenue Department every 5th of each month.",
    "close": "Close",
    "allDocuments": "All Sales Documents",
    "totalDocuments": "Total Documents",
    "vat": "VAT",
    "salesAmount": "Sales (Include VAT)",
    "documents": "Docs",
    "thb": "THB",
    "signProcessing": "Creating and signing document...",
    "sendFileByEmail": "Send file by email",
    "sendToEmail": "Send to email",
    "ccToEmail": "CC to email",
    "sendEmail": "Send email",
    "cancel": "Cancel",
    "sendFileBySms": "Send file by SMS",
    "mobileNumer": "Mobile number",
    "exampleNumber": "Ex. 0825798555",
    "sendSms": "Send SMS",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "allDocumentsType": "All Documents",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "createdDate": "Created Date",
    "documentDate": "Document Date",
    "thisMonth": "This Month",
    "documentPerPage": "Docs/Page",
    "page": "Page",
    "refresh": "Refresh",
    "confirmSendEmail": "Please confirm to send email",
    "create": "Create",
    "combinedReceipt": "Combine Receipt",
    "combinedReceiptAlert": "Some document type can not create combine Receipt, please select new documents.",
    "download": "Download",
    "emailAlert": "Please fill valid email address.",
    "mobileNumberAlert": "Please fill valid mobile phone number.",
    "number": "Number",
    "customerName": "Customer",
    "grandTotal": "Total (Include VAT)",
    "document": "Document",
    "openFile": "Open File",
    "email": "Email ",
    "emailDate": "Email Date",
    "sendingEmail": "Sending email...",
    "success": "Success",
    "emailNotFound": "Email not found",
    "fail": "Fail",
    "file": "File",
    "openDocument": "Open Document",
    "reCreateDocument": "Recreate Document",
    "reIssuedDocument": "Reissue Document",
    "delete": "Delete",
    "deleteDisable": "Delete (Submitted XML)",
    "sendEmailSuccess": "Sent email successfully!",
    "sendEmailFail": "Sent email fail!",
    "sendSmsSuccess": "Sent SMS successfully!",
    "sendSmsFail": "Sent SMS fail!",
    "instantTopup": "Instant documents topup 24 hr.",
    "select": "Select",
    "isRequiredErrorMessage": "Please select date",
    "expenseTaxReport": "Expense Tax Report",
    "downloadPdf": "Download PDF",
    "downloadCsv": "Download CSV",
    "taxId": "Tax ID",
    "branchNumber": "Branch No.",
    "amountTotal": "Subtotal",
    "vatTotal": "VAT",
    "documentUnit": "document",
    "purchaseTotal": "Total Purchase (Not Include VAT)",
    "purchaseVat": "VAT",
    "loading": "Loading...",
    "search": "Search",
    "searchPlaceholder": "Search",
    "report": "Output Tax Report",
    "header": "Output Tax",
    "saleTotal": "Total Sale (Not Include VAT)",
    "saleVat": "VAT",
  },
  "outputTaxConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Output Tax Report",
    "downloadPdf": "Download PDF",
    "downloadCsv": "Download CSV",
    "back": "Back",
    "loading": "Loading...",
  },
  "sellSummary": {
    "warningSetupCertificate": "e-Tax Invoice & e-Receipt documents will complete with real electronic certificate only.",
    "warningSetupCertificateLink": "Set up electronic certificate.",
    "warningXmlDelivery": "XML files will submit to the Revenue Department every 5th of each month.",
    "close": "Close",
    "allDocuments": "All Sales Documents",
    "totalDocuments": "Total Documents",
    "vat": "VAT",
    "salesAmount": "Sales (Include VAT)",
    "documents": "Docs",
    "thb": "THB",
    "signProcessing": "Creating and signing document...",
    "sendFileByEmail": "Send file by email",
    "sendToEmail": "Send to email",
    "ccToEmail": "CC to email",
    "sendEmail": "Send email",
    "cancel": "Cancel",
    "sendFileBySms": "Send file by SMS",
    "mobileNumer": "Mobile number",
    "exampleNumber": "Ex. 0825798555",
    "sendSms": "Send SMS",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "allDocumentsType": "All Documents",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "createdDate": "Created Date",
    "documentDate": "Document Date",
    "thisMonth": "This Month",
    "documentPerPage": "Docs/Page",
    "page": "Page",
    "refresh": "Refresh",
    "confirmSendEmail": "Please confirm to send email",
    "create": "Create",
    "combinedReceipt": "Combine Receipt",
    "combinedReceiptAlert": "Some document type can not create combine Receipt, please select new documents.",
    "download": "Download",
    "emailAlert": "Please fill valid email address.",
    "mobileNumberAlert": "Please fill valid mobile phone number.",
    "number": "Number",
    "customerName": "Customer",
    "grandTotal": "Total (Include VAT)",
    "document": "Document",
    "openFile": "Open File",
    "email": "Email ",
    "emailDate": "Email Date",
    "sendingEmail": "Sending email...",
    "success": "Success",
    "emailNotFound": "Email not found",
    "fail": "Fail",
    "file": "File",
    "openDocument": "Open Document",
    "reCreateDocument": "Recreate Document",
    "reIssuedDocument": "Reissue Document",
    "delete": "Delete",
    "deleteDisable": "Delete (Submitted XML)",
    "sendEmailSuccess": "Sent email successfully!",
    "sendEmailFail": "Sent email fail!",
    "sendSmsSuccess": "Sent SMS successfully!",
    "sendSmsFail": "Sent SMS fail!",
    "instantTopup": "Instant documents topup 24 hr.",
    "select": "Select",
    "isRequiredErrorMessage": "Please select date",
    "expenseTaxReport": "Expense Tax Report",
    "downloadPdf": "Download PDF",
    "downloadCsv": "Download CSV",
    "taxId": "Tax ID",
    "branchNumber": "Branch No.",
    "amountTotal": "Subtotal",
    "vatTotal": "VAT",
    "documentUnit": "document",
    "purchaseTotal": "Total Purchase (Not Include VAT)",
    "purchaseVat": "VAT",
    "loading": "Loading...",
    "search": "Search",
    "searchPlaceholder": "Search",
    "saleTotal": "Total Sale (Not Include VAT)",
    "saleVat": "VAT",
    "report": "Sales Report",
    "header": "Sales Report",
    "saleTotalWithVat": "Total Sales (Include VAT)",
    "timeRange": "Range mode",
    "fromRange": "From",
    "toRange": "To",
    "timeRangeError": "Please select time range within 30 days",
    "timeRangeError2": "Please select time range within 15 days",
  },
  "sellSummaryConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating Sales Report",
    "downloadPdf": "Download PDF",
    "downloadCsv": "Download CSV",
    "back": "Back",
    "loading": "Loading...",
  },
  "xmlUpload": {
    "warningSetupCertificate": "e-Tax Invoice & e-Receipt documents will complete with real electronic certificate only.",
    "warningSetupCertificateLink": "Set up electronic certificate.",
    "warningXmlDelivery": "XML files will submit to the Revenue Department every 5th of each month.",
    "close": "Close",
    "allDocuments": "All Sales Documents",
    "totalDocuments": "Total Documents",
    "vat": "VAT",
    "salesAmount": "Sales (Include VAT)",
    "documents": "Docs",
    "thb": "THB",
    "signProcessing": "Creating and signing document...",
    "sendFileByEmail": "Send file by email",
    "sendToEmail": "Send to email",
    "ccToEmail": "CC to email",
    "sendEmail": "Send email",
    "cancel": "Cancel",
    "sendFileBySms": "Send file by SMS",
    "mobileNumer": "Mobile number",
    "exampleNumber": "Ex. 0825798555",
    "sendSms": "Send SMS",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "allDocumentsType": "All Documents",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "createdDate": "Created Date",
    "documentDate": "Document Date",
    "thisMonth": "This Month",
    "documentPerPage": "Docs/Page",
    "page": "Page",
    "refresh": "Refresh",
    "confirmSendEmail": "Please confirm to send email",
    "create": "Create",
    "combinedReceipt": "Combine Receipt",
    "combinedReceiptAlert": "Some document type can not create combine Receipt, please select new documents.",
    "download": "Download",
    "emailAlert": "Please fill valid email address.",
    "mobileNumberAlert": "Please fill valid mobile phone number.",
    "number": "Number",
    "customerName": "Customer",
    "grandTotal": "Total (Include VAT)",
    "document": "Document",
    "openFile": "Open File",
    "email": "Email ",
    "emailDate": "Email Date",
    "sendingEmail": "Sending email...",
    "success": "Success",
    "emailNotFound": "Email not found",
    "fail": "Fail",
    "file": "File",
    "openDocument": "Open Document",
    "reCreateDocument": "Recreate Document",
    "reIssuedDocument": "Reissue Document",
    "delete": "Delete",
    "deleteDisable": "Delete (Submitted XML)",
    "sendEmailSuccess": "Sent email successfully!",
    "sendEmailFail": "Sent email fail!",
    "sendSmsSuccess": "Sent SMS successfully!",
    "sendSmsFail": "Sent SMS fail!",
    "instantTopup": "Instant documents topup 24 hr.",
    "select": "Select",
    "isRequiredErrorMessage": "Please select date",
    "expenseTaxReport": "Expense Tax Report",
    "downloadPdf": "Download PDF",
    "downloadCsv": "Download CSV",
    "taxId": "Tax ID",
    "branchNumber": "Branch No.",
    "amountTotal": "Subtotal",
    "vatTotal": "VAT",
    "documentUnit": "document",
    "purchaseTotal": "Total Purchase (Not Include VAT)",
    "purchaseVat": "VAT",
    "loading": "Loading...",
    "search": "Search",
    "searchPlaceholder": "Search",
    "saleTotal": "Total Sale (Not Include VAT)",
    "saleVat": "VAT",
    "saleTotalWithVat": "Total Sale (Include VAT)",
    "report": "XML Delivery Report",
    "header": "XML Delivery Report",
    "rdStatus": "Delivery Status",
    "rdTime": "Delivery Date",
    "rdRefNumber": "RD Ref No.",
  },
  "xmlUploadConfirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating XML Delivery Report",
    "downloadPdf": "Download PDF",
    "downloadCsv": "Download CSV",
    "back": "Back",
    "loading": "Loading...",
  },
  "pnd2": {
    "warningSetupCertificate": "e-Tax Invoice & e-Receipt documents will complete with real electronic certificate only.",
    "warningSetupCertificateLink": "Set up electronic certificate.",
    "warningXmlDelivery": "XML files will submit to the Revenue Department every 5th of each month.",
    "close": "Close",
    "allDocuments": "All Sales Documents",
    "vat": "VAT",
    "salesAmount": "Sales (Include VAT)",
    "documents": "Docs",
    "thb": "THB",
    "signProcessing": "Creating and signing document...",
    "sendSms": "Send SMS",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "allDocumentsType": "All Documents",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "createdDate": "Created Date",
    "thisMonth": "This Month",
    "documentPerPage": "Docs/Page",
    "page": "Page",
    "refresh": "Refresh",
    "confirmSendEmail": "Please confirm to send email",
    "create": "Create",
    "combinedReceipt": "Combine Receipt",
    "combinedReceiptAlert": "Some document type can not create combine Receipt, please select new documents.",
    "download": "Download",
    "emailAlert": "Please fill valid email address.",
    "mobileNumberAlert": "Please fill valid mobile phone number.",
    "number": "Number",
    "customerName": "Customer",
    "document": "Document",
    "openFile": "Open File",
    "email": "Email ",
    "emailDate": "Email Date",
    "sendingEmail": "Sending email...",
    "success": "Success",
    "emailNotFound": "Email not found",
    "fail": "Fail",
    "openDocument": "Open Document",
    "reCreateDocument": "Recreate Document",
    "reIssuedDocument": "Reissue Document",
    "delete": "Delete",
    "deleteDisable": "Delete (Submitted XML)",
    "instantTopup": "Instant documents topup 24 hr.",
    "select": "Select",
    "isRequiredErrorMessage": "Please select date",
    "expenseTaxReport": "Expense Tax Report",
    "branchNumber": "Branch No.",
    "amountTotal": "Subtotal",
    "purchaseTotal": "Total Purchase (Not Include VAT)",
    "purchaseVat": "VAT",
    "loading": "Loading...",
    "search": "Search",
    "searchPlaceholder": "Search",
    "saleTotal": "Total Sale (Not Include VAT)",
    "saleVat": "VAT",
    "saleTotalWithVat": "Total Sale (Include VAT)",
    "report": "XML Delivery Report",
    "rdStatus": "Delivery Status",
    "rdTime": "Delivery Date",
    "rdRefNumber": "RD Ref No.",
    "downloadTxt": "Download File for RDPrep (.txt)",
    "downloadCsv": "Download File for RDPrep (.csv)",
    "row": "No.",
    "documentDate": "Document Date",
    "taxId": "Tax ID",
    "name": "Name",
    "grandTotal": "Total",
    "vatTotal": "VAT",
    "file": "File",
    "sendEmailSuccess": "Sent email successfully!",
    "sendEmailFail": "Sent email fail!",
    "sendSmsSuccess": "Sent SMS successfully!",
    "sendSmsFail": "Sent SMS fail!",
    "header": "P.N.D.2",
    "subHeader": "(Download File .txt, .csv for uploading to RDPrep to pay for P.N.D.2)",
    "totalDocuments": "Total Documents",
    "documentUnit": "document",
    "totalGrand": "Total",
    "totalVat": "VAT",
    "sendFileByEmail": "Send file by email",
    "sendToEmail": "Send to email",
    "ccToEmail": "CC to email",
    "sendEmail": "Send email",
    "cancel": "Cancel",
    "sendFileBySms": "Send file by SMS",
    "mobileNumer": "Mobile number",
    "exampleNumber": "Ex. 0825798555",
  },
  "pnd3": {
    "warningSetupCertificate": "e-Tax Invoice & e-Receipt documents will complete with real electronic certificate only.",
    "warningSetupCertificateLink": "Set up electronic certificate.",
    "warningXmlDelivery": "XML files will submit to the Revenue Department every 5th of each month.",
    "close": "Close",
    "allDocuments": "All Sales Documents",
    "vat": "VAT",
    "salesAmount": "Sales (Include VAT)",
    "documents": "Docs",
    "thb": "THB",
    "signProcessing": "Creating and signing document...",
    "sendSms": "Send SMS",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "allDocumentsType": "All Documents",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "createdDate": "Created Date",
    "thisMonth": "This Month",
    "documentPerPage": "Docs/Page",
    "page": "Page",
    "refresh": "Refresh",
    "confirmSendEmail": "Please confirm to send email",
    "create": "Create",
    "combinedReceipt": "Combine Receipt",
    "combinedReceiptAlert": "Some document type can not create combine Receipt, please select new documents.",
    "download": "Download",
    "emailAlert": "Please fill valid email address.",
    "mobileNumberAlert": "Please fill valid mobile phone number.",
    "number": "Number",
    "customerName": "Customer",
    "document": "Document",
    "openFile": "Open File",
    "email": "Email ",
    "emailDate": "Email Date",
    "sendingEmail": "Sending email...",
    "success": "Success",
    "emailNotFound": "Email not found",
    "fail": "Fail",
    "openDocument": "Open Document",
    "reCreateDocument": "Recreate Document",
    "reIssuedDocument": "Reissue Document",
    "delete": "Delete",
    "deleteDisable": "Delete (Submitted XML)",
    "instantTopup": "Instant documents topup 24 hr.",
    "select": "Select",
    "isRequiredErrorMessage": "Please select date",
    "expenseTaxReport": "Expense Tax Report",
    "branchNumber": "Branch No.",
    "amountTotal": "Subtotal",
    "purchaseTotal": "Total Purchase (Not Include VAT)",
    "purchaseVat": "VAT",
    "loading": "Loading...",
    "search": "Search",
    "searchPlaceholder": "Search",
    "saleTotal": "Total Sale (Not Include VAT)",
    "saleVat": "VAT",
    "saleTotalWithVat": "Total Sale (Include VAT)",
    "report": "XML Delivery Report",
    "rdStatus": "Delivery Status",
    "rdTime": "Delivery Date",
    "rdRefNumber": "RD Ref No.",
    "downloadTxt": "Download File for RDPrep (.txt)",
    "downloadCsv": "Download File for RDPrep (.csv)",
    "row": "No.",
    "documentDate": "Document Date",
    "taxId": "Tax ID",
    "name": "Name",
    "grandTotal": "Total",
    "vatTotal": "VAT",
    "file": "File",
    "sendEmailSuccess": "Sent email successfully!",
    "sendEmailFail": "Sent email fail!",
    "sendSmsSuccess": "Sent SMS successfully!",
    "sendSmsFail": "Sent SMS fail!",
    "totalDocuments": "Total Documents",
    "documentUnit": "document",
    "totalGrand": "Total",
    "totalVat": "VAT",
    "sendFileByEmail": "Send file by email",
    "sendToEmail": "Send to email",
    "ccToEmail": "CC to email",
    "sendEmail": "Send email",
    "cancel": "Cancel",
    "sendFileBySms": "Send file by SMS",
    "mobileNumer": "Mobile number",
    "exampleNumber": "Ex. 0825798555",
    "header": "P.N.D.2",
    "subHeader": "(Download File .txt, .csv for uploading to RDPrep to pay for P.N.D.2)",  
  },
  "pnd53": {
    "warningSetupCertificate": "e-Tax Invoice & e-Receipt documents will complete with real electronic certificate only.",
    "warningSetupCertificateLink": "Set up electronic certificate.",
    "warningXmlDelivery": "XML files will submit to the Revenue Department every 5th of each month.",
    "close": "Close",
    "allDocuments": "All Sales Documents",
    "vat": "VAT",
    "salesAmount": "Sales (Include VAT)",
    "documents": "Docs",
    "thb": "THB",
    "signProcessing": "Creating and signing document...",
    "sendSms": "Send SMS",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "allDocumentsType": "All Documents",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "createdDate": "Created Date",
    "thisMonth": "This Month",
    "documentPerPage": "Docs/Page",
    "page": "Page",
    "refresh": "Refresh",
    "confirmSendEmail": "Please confirm to send email",
    "create": "Create",
    "combinedReceipt": "Combine Receipt",
    "combinedReceiptAlert": "Some document type can not create combine Receipt, please select new documents.",
    "download": "Download",
    "emailAlert": "Please fill valid email address.",
    "mobileNumberAlert": "Please fill valid mobile phone number.",
    "number": "Number",
    "customerName": "Customer",
    "document": "Document",
    "openFile": "Open File",
    "email": "Email ",
    "emailDate": "Email Date",
    "sendingEmail": "Sending email...",
    "success": "Success",
    "emailNotFound": "Email not found",
    "fail": "Fail",
    "openDocument": "Open Document",
    "reCreateDocument": "Recreate Document",
    "reIssuedDocument": "Reissue Document",
    "delete": "Delete",
    "deleteDisable": "Delete (Submitted XML)",
    "instantTopup": "Instant documents topup 24 hr.",
    "select": "Select",
    "isRequiredErrorMessage": "Please select date",
    "expenseTaxReport": "Expense Tax Report",
    "branchNumber": "Branch No.",
    "amountTotal": "Subtotal",
    "purchaseTotal": "Total Purchase (Not Include VAT)",
    "purchaseVat": "VAT",
    "loading": "Loading...",
    "search": "Search",
    "searchPlaceholder": "Search",
    "saleTotal": "Total Sale (Not Include VAT)",
    "saleVat": "VAT",
    "saleTotalWithVat": "Total Sale (Include VAT)",
    "report": "XML Delivery Report",
    "rdStatus": "Delivery Status",
    "rdTime": "Delivery Date",
    "rdRefNumber": "RD Ref No.",
    "downloadTxt": "Download File for RDPrep (.txt)",
    "downloadCsv": "Download File for RDPrep (.csv)",
    "row": "No.",
    "documentDate": "Document Date",
    "taxId": "Tax ID",
    "name": "Name",
    "grandTotal": "Total",
    "vatTotal": "VAT",
    "file": "File",
    "sendEmailSuccess": "Sent email successfully!",
    "sendEmailFail": "Sent email fail!",
    "sendSmsSuccess": "Sent SMS successfully!",
    "sendSmsFail": "Sent SMS fail!",
    "totalDocuments": "Total Documents",
    "documentUnit": "document",
    "totalGrand": "Total",
    "totalVat": "VAT",
    "sendFileByEmail": "Send file by email",
    "sendToEmail": "Send to email",
    "ccToEmail": "CC to email",
    "sendEmail": "Send email",
    "cancel": "Cancel",
    "sendFileBySms": "Send file by SMS",
    "mobileNumer": "Mobile number",
    "exampleNumber": "Ex. 0825798555",
    "header": "P.N.D.53",
    "subHeader": "(Download File .txt, .csv for uploading to RDPrep to pay for P.N.D.53)",  
  },
  "pp30": {
    "warningSetupCertificate": "e-Tax Invoice & e-Receipt documents will complete with real electronic certificate only.",
    "warningSetupCertificateLink": "Set up electronic certificate.",
    "warningXmlDelivery": "XML files will submit to the Revenue Department every 5th of each month.",
    "close": "Close",
    "allDocuments": "All Sales Documents",
    "vat": "VAT",
    "salesAmount": "Sales (Include VAT)",
    "documents": "Docs",
    "thb": "THB",
    "signProcessing": "Creating and signing document...",
    "sendSms": "Send SMS",
    "shortMonths01": "Jan",
    "shortMonths02": "Feb",
    "shortMonths03": "Mar",
    "shortMonths04": "Apr",
    "shortMonths05": "May",
    "shortMonths06": "Jun",
    "shortMonths07": "Jul",
    "shortMonths08": "Aug",
    "shortMonths09": "Sep",
    "shortMonths10": "Oct",
    "shortMonths11": "Nov",
    "shortMonths12": "Dec",
    "months01": "January",
    "months02": "February",
    "months03": "March",
    "months04": "April",
    "months05": "May",
    "months06": "June",
    "months07": "July",
    "months08": "August",
    "months09": "September",
    "months10": "October",
    "months11": "November",
    "months12": "December",
    "allDocumentsType": "All Documents",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "createdDate": "Created Date",
    "thisMonth": "This Month",
    "documentPerPage": "Docs/Page",
    "page": "Page",
    "refresh": "Refresh",
    "confirmSendEmail": "Please confirm to send email",
    "create": "Create",
    "combinedReceipt": "Combine Receipt",
    "combinedReceiptAlert": "Some document type can not create combine Receipt, please select new documents.",
    "download": "Download",
    "emailAlert": "Please fill valid email address.",
    "mobileNumberAlert": "Please fill valid mobile phone number.",
    "number": "Number",
    "customerName": "Customer",
    "document": "Document",
    "openFile": "Open File",
    "email": "Email ",
    "emailDate": "Email Date",
    "sendingEmail": "Sending email...",
    "success": "Success",
    "emailNotFound": "Email not found",
    "fail": "Fail",
    "openDocument": "Open Document",
    "reCreateDocument": "Recreate Document",
    "reIssuedDocument": "Reissue Document",
    "delete": "Delete",
    "deleteDisable": "Delete (Submitted XML)",
    "instantTopup": "Instant documents topup 24 hr.",
    "select": "Select",
    "isRequiredErrorMessage": "Please select date",
    "expenseTaxReport": "Expense Tax Report",
    "branchNumber": "Branch No.",
    "amountTotal": "Subtotal",
    "purchaseTotal": "Total Purchase (Not Include VAT)",
    "purchaseVat": "VAT",
    "loading": "Loading...",
    "search": "Search",
    "searchPlaceholder": "Search",
    "saleTotal": "Total Sale (Not Include VAT)",
    "saleVat": "VAT",
    "saleTotalWithVat": "Total Sale (Include VAT)",
    "report": "XML Delivery Report",
    "rdStatus": "Delivery Status",
    "rdTime": "Delivery Date",
    "rdRefNumber": "RD Ref No.",
    "downloadTxt": "Download File for RDPrep (.txt)",
    "downloadCsv": "Download File for RDPrep (.csv)",
    "row": "No.",
    "taxId": "Tax ID",
    "name": "Name",
    "grandTotal": "Total",
    "vatTotal": "VAT",
    "file": "File",
    "sendEmailSuccess": "Sent email successfully!",
    "sendEmailFail": "Sent email fail!",
    "sendSmsSuccess": "Sent SMS successfully!",
    "sendSmsFail": "Sent SMS fail!",
    "totalDocuments": "Total Documents",
    "documentUnit": "document",
    "totalGrand": "Total",
    "sendFileByEmail": "Send file by email",
    "sendToEmail": "Send to email",
    "ccToEmail": "CC to email",
    "sendEmail": "Send email",
    "cancel": "Cancel",
    "sendFileBySms": "Send file by SMS",
    "mobileNumer": "Mobile number",
    "exampleNumber": "Ex. 0825798555",
    "vatTax": "Output Tax",
    "expenseTax": "Input Tax",
    "pdf": "Download P.P.30",
    "documentDate": "Document Date",
    "header": "P.P.30",
    "subHeader": "(Download File .txt, .csv for uploading to RDPrep to pay for P.P.30)",      
    "totalVat": "Output Tax",
    "totalExpenseTax": "Input Tax",
    "vatDiff": "Difference",
  },
  "pp30Summary": {
    "header": "P.P.30 Details",
    "month": "Year/Month",
    "vatPurchase": "Input Tax",
    "totalPurchaseThisMonth": "Total Purchases",
    "vatPurchaseThisMonth": "Input Tax",
    "thb": "THB",
    "vatSales": "Output Tax",
    "totalSalesThisMonth": "Total Sales",
    "totalSalesZero": "Sales subject to 0% tax rate",
    "totalSalesNon": "Sales subject to Exempted tax rate",
    "totalSalesVat": "Sales subject to 7% tax rate",
    "vatSalesThisMonth": "Output Tax",
    "totalVatThisMonth": "Difference",
    "previousVat": "Excess tax payment carried forward from last month/Tax Credit",
    "totalVat": "Tax Payable",
    "next": "Next",
    "cancel": "Cancel",
    "minus": "-",
  },
  "pp30Confirm": {
    "confirm": "Confirm",
    "edit": "Edit",
    "processing": "Processing",
    "processingMessage": "Creating P.P.30",
    "downloadPdf": "Download PDF",
    "downloadCsv": "Download CSV",
    "back": "Back",
    "loading": "Loading...",
    "taxId": "Tax ID : ",
    "taxId2": "is not registered with ",
    "taxId3": "The Revenue Department of Thailand",
    "download": "Download",
  },
  "rdVat": {
    "number": "No.",
    "taxId": "Tax ID",
    "branch": "Branch",
    "name": "Name",
    "address": "Address",
    "postcode": "Postcode",
    "registerDate": "Register Date",
    "header": "Company or Juristic Partnership List on",
    "subHeader": "(From The Revenue Department of Thailand Database)",
    "taxId2": "Tax ID",
    "search": "Search",
    "footer1": "Search for Company or Juristic Partnership which is registered with the Revenue Department of Thailand. The service is used for verifying which Companies or Juristic Partnerships can issue tax invoices or not.",
    "footer2": "Tax invoice issued by any Company or Juristic Partnership which is not registered with the Revenue Department of Thailand is illegal and can cause damage to receiver. Therefore; you should use the service to verify your business partners",
  },
  "certificates": {
    "cancelConfirmHeader": "Cancel Digital Certificate",
    "deleteConfirmHeader": "Delete Digital Certificate",
    "cancelConfirm": "Please confirm to cancel digital certificate.",
    "deleteConfirm": "Please confirm to delete digital certificate.",
    "serialNumber": "S/N:",
    "email": "E-mail:",
    "issuedBy": "Issued By:",
    "start": "Issued date:",
    "end": "Expired date:",
    "choose": "Choose",
    "delete": "Delete",
    "header": "Digital Certificates",
    "loading": "Loading...",
    "cancelCertificate": "Uninstall Digital Certificate",
    "add": "Add Digital Certificate",
    "installStep": "Setup Digital Certificate Procedure",
    "certificateCreateDescription1": "* Personnel will send Digital Certificate request form via E-mail within 2 workdays",
    "certificateCreateDescription2": "Please follow steps below when you get the request form",
    "firstAgreement1": "1.Sign the request form",
    "firstAgreement2": "Authorized person has to sign and seal (if available) every pages",
    "firstAgreement3": "Sign the request form by authorized person and withness and",
    "firstAgreement4": "contracts with stamp duty",
    "secondAgreement1": "2. Attachment Documents",
    "secondAgreement2": "Business Registration Certificate",
    "secondAgreement3": "Authorized person has to sign and seal (if available) every pages",
    "secondAgreement4": "Copy of Authorized person ID Card",
    "secondAgreement5": "Sign every copies",
    "thirdAgreement1": "3. Send all Attachment Documents and form via E-mail",
    "thirdAgreement2": "Send those to support@leceipt.com",
    "fourthAgreement1": "4. Send original documents via mail",
    "fourthAgreement2": "Personnel will inform the address after finishing review documents in E-mail",
    "processing": "Processing...",
    "processing2": "Processing...",
    "secondStep": "Send original documents via mail",
    "footer1": "Please wait personnel to contact you after sent original documents via mail. Personnel will contact you within 2 workdays",
    "footer2": "Contact us on 082-579-8555 in Thailand. The service is available 8.30 a.m. - 5.30 p.m.",
    "footer3": "Address for sending documents",
    "footer4": "Digital Certificate Management",
    "footer5": "Frevation Co.,Ltd.",
    "footer6": "682 Moo 1 Sukhaphiban 1 Road",
    "footer7": "Borabue Borabue Maha Sarakham 44130",
    "footer8": "Tel. 082-579-8555",
    "footer9": "We will request for digital cetificate after receive the documents. Normally, CA (Certification Authority) will proceed within 3 - 5 workdays",
    "footer10": "Contact for progress on 082-579-8555 in Thailand. The service is available 8.30 a.m. - 5.30 p.m.",
  },
  "xmlDeliver": {
    "header": "XML Delivery",
    "subHeader": "Connect Leceipt and e-Tax Invoice & e-Receipt for XML Delivery",
    "user": "e-Tax Username",
    "password": "Password",
    "revealPassword": "Reveal",
    "connect": "Connect",
    "disconnect": "Disconnect",
    "disconnectConfirmHeader": "Disconnect from XML Delivery",
    "disconnectConfirm": "Please confirm to disconnect from XML Delivery (Any files which are created before disconnecting will still be delivered)",
    "processing": "Processing...",
    "loading": "Loading...",
    "successMessage": "Connect Success!",
    "successMessage2": "Connect Success!",
    "details": "Details",
    "failMessage": "Connect Fail!",
    "failButton": "Try Again",
    "footer1": "XML files created after connecting will be deliveried automatically.",
    "footer2": "XML files created before connecting will not be deliveried to The Revenue Department of Thailand.",
  },
  "pdfTemplate": {
    "showMessageBarSuccess": "Reset document template successfully!",
    "showMessageBarError": "Reset document template fail!",
    "header": "Document Templates",
    "documentType": "Document Type",
    "receipt-taxinvoice": "Receipt/Tax Invoice",
    "receipt-taxinvoice-abb": "Receipt/Tax Invoice Abb",
    "receipt": "Receipt",
    "invoice-taxinvoice": "Invoice/Tax Invoice",
    "deliveryorder-taxinvoice": "Delivery Order/Tax Invoice",
    "taxinvoice": "Tax Invoice",
    "debit-note": "Debit Note",
    "credit-note": "Credit Note",
    "quotation": "Quotation",
    "invoice": "Invoice",
    "billing-note": "Billing Note",
    "deliveryorder": "Delivery Order",
    "preview": "Preview",
    "disconnectConfirmHeader": "Reset Template",
    "disconnectConfirm1": "Please confirm to reset ",
    "disconnectConfirm2": " template (the template will become default template)",
    "reset": "Reset template",
    "loading": "Loading...",
    "example": "Example Data:",
    "jsonParameter": "(JSON Parameters)",
  },
  "templatePreview": {
    "updateSuccess": "Updated Successfully!",
    "updateFail": "Updated Fail!",
    "header": "Template Preview:",
    "save": "Save",
    "back": "Back",
    "saving": "Saving...",
    "loading": "Loading...",
  },
  "emailSetting": {
    "getErrorMessage": "Please fill information",
    "disconnect": "Remove E-mail Setting",
    "disconnectConfirm": "Please confirm to remove E-mail setting",
    "updateSuccess": "Set Successfully!",
    "updateFail": "Set Fail!",
    "showMessageBarResetSuccess": "Reset E-mail format successfully!",
    "showMessageBarResetError": "Reset E-mail format Fail!",
    "showMessageBarUpdateSuccess": "Save E-mail format successfully!",
    "showMessageBarUpdateError": "Save E-mail format Fail!",
    "header": "E-mail Setting",
    "processing": "Processing...",
    "toggleCompanyEmail": "Use E-mail For Business",
    "on": "On",
    "off": "Off",
    "pleaseSelect": "Please Select",
    "provider": "E-mail Service provider",
    "email": "E-mail:",
    "disconnectButton": "Remove setting",
    "senderName": "Sender",
    "senderEmail": "E-mail",
    "revealPassword": "Reveal",
    "save": "Save",
    "back": "Back",
    "customizeFormat": "Customizing E-mail Format",
    "testSending": "For Testing",
    "saveFirst": "(Please save first)",
    "send": "Send",
    "sending": "Sending...",
    "resetConfirmHeader": "Reset E-mail Format",
    "resetConfirm": "Please confirm to reset E-mail format (the format will become default format)",
    "saveSeparator": "Click below button to save",
    "saveSeparator2": "Click below button to save",
    "loading": "Loading...",
    "loading2": "Loading...",
    "saving": "Saving...",
    "saving2": "Saving...",
    "resetting": "Resetting...",
    "reset": "Reset",
  },
  "apiKeys": {
    "header": "API Key",
    "privateKey": "Private API Key",
    "createKeyToConnect": "Create API Key for connecting",
    "createKeyToConnect2": "Create API Key for connecting",
    "name": "Name:",
    "key": "API Key:",
    "createdDate": "Create Date:",
    "keyDescription": "API Key will be revealed only once after creating. If you forgot API Key, please delete and create new one.",
    "keyDescription2": "Private API Key is very important as Username + Password. Please never disclose this key.",
    "keyDescription3": "(If you do not use API, you do not have to create API Key.)",
    "delete": "Delete API Key",
    "deleteConfirm": "Please confirm to delete API Key",
    "processing": "Processing...",
    "loading": "Loading...",
    "keyName": "API Key Name",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "generate": "Generate API Key",
  },
  "taxId": {
    "header": "Verification Tax ID and ID Number Service",
    "subHeader": "Verify any Tax ID or ID Number which is in database on",
    "subHeader2": "(From The Revenue Department of Thailand Database)",
    "taxId": "Tax ID or ID Number",
    "search": "Search",
    "footer1": "The service is for verify any Tax ID or ID Number which is real and valid. Any invalid Tax ID or ID Number cause damage to any agencies or entrepreneurs.",
    "footer2": "Therefore; you should use the service to verify Them.",
    "processing": "Processing...",
    "found": "Found",
    "notFound": "Not found",
    "found2": "in The Revenue Department of Thailand Database",
  },
  "rdEtax": {
    "taxId": "Tax ID",
    "taxId2": "Tax ID",
    "name": "Name",
    "taxInvoice": "Tax Invoice",
    "receipt": "Receipt",
    "registerDate": "Register Date",
    "endDate": "Expiry Date",
    "remark": "Remark",
    "header": "List of Company or Juristic Partnership",
    "subHeader": "which can issue e-Tax Invoice & e-Receipt on",
    "subHeader2": "(From The Revenue Department of Thailand Database)",
    "search": "Search",
    "footer1": "The service is used for verifying which Companies or Juristic Partnerships can issue e-Tax Invoice & e-Receipt or not. e-Tax Invoice & e-Receipt issued by any Company or Juristic Partnership which is not registered with the Revenue Department of Thailand is illegal and can cause damage to receiver.",
    "footer2": "Therefore; you should use the service to verify your business partners",
  },
  "excelValidate": {
    "documentType": "documentType ห้ามเว้นว่าง"
  },
  "feedback": {
    "header": "Report issues or Suggest new services",
    "sending": "Sending...",
    "sent": "Sent successfully",
    "pleaseSelect": "Please Select",
    "category": "Category",
    "REPORT": "Report",
    "FUNCTION": "Suggest functions",
    "SERVICE": "Suggest Services",
    "PAYMENT": "Payment",
    "title": "Subject",
    "titleDescription": "Subject of issues or services",
    "description": "Message",
    "descriptionDetail": "Message details of the subject",
    "send": "Send",
  },
  "viewPdf": {
    "loading": "Loading...",
    "download": "Download",
    "print": "Print",
    "back": "Back",
    "signBy": "The document is signed with digital Certificate by",
  },
  "viewXml": {
    "loading": "Loading...",
    "download": "Download",
    "print": "Print",
    "back": "Back",
    "signBy": "The document is signed with digital Certificate by",
  },
  "viewPdfExpenseNote": {
    "loading": "Loading...",
    "download": "Download",
    "print": "Print",
    "back": "Back",
    "signBy": "The document is signed with digital Certificate by",
  },
  "excelMenu": {
    "header": "Upload Excel",
    "subHeader": "to Create e-Tax with file .csv .xls .xlsx",
    "template": "Edit Template in all required columns",
    "dragdrop": "Drag & Drop in all required columns",
    "descriptionFirstMenu": "e-Tax and Business Documents",
    "descriptionSecondMenu": "Credit Note, Debit Note",
  },
  "storagesConnect": {
    "header": "Connect to S3 Cloud Storage",
    "selectBucket": "Select Buckets",
    "back": "Back",
    "backupLong": "Backup",
    "backupTrue": "Copy files to cloud when create documents.",
    "backupFalse": "Do not copy files to cloud storage.",
  },
  "storages": {
    "header": "Cloud Storage",
    "creating": "Creating...",
    "createIncomplete": "Create Incomplete",
    "createComplete": "Create Complete",
    "upload": "Upload",
    "uploading": "Uploading...",
    "uploadComplete": "Upload Complete",
    "uploadIncomplete": "Upload Incomplete",
    "delete": "Delete",
    "deleting": "Deleting...",
    "deleteComplete": "Delete Complete",
    "deleteIncomplete": "Delete Incomplete",
    "download": "Download",
    "downloading": "Downloading...",
    "downloadComplete": "Download Complete",
    "downloadIncomplete": "Download Incomplete",
    "loading": "Loading...",
    "copied": "Copied to Clipboard",
    "share": "Share",
    "shareIncomplete": "Share Incomplete",
    "new": "New",
    "newFolder": "New Folder",
    "newFolderName": "Name",
    "folder": "Folder",
    "create": "Create",
    "cancel": "Cancel",
    "disconnect": "Disconnect",
    "deleteConfirmMessage": "Do you want to delete?",
    "open": "Open",
    "file": "File",
    "name": "Name",
    "modified": "Modified",
    "size": "Size",
    "documents": "Documents",
    "alertMessageDownloadManyLargeFiles": "Cannot download multiple large files at the same time.",
    "alertMessageDownloadManyFiles": "Maximum download is 100 files",
    "alertMessageDownloadFolder": "Cannot download folder",
    "disconnectConfirm": "Please confirm to disconnect",
  },
};