import { Stack } from '@fluentui/react';
import { memo } from 'react';
import { useDrop } from 'react-dnd';
import { ROW_NAMES, ROW_NAMES_REQUIRE } from './rowName';
import { useTranslation } from 'react-i18next';

const style = {
  height: '52px',
  width: '500px',
  padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
  border: '1px solid rgb(193, 193, 193)',
  color: 'rgb(50, 49, 48)',
  backgroundColor: 'rgb(255, 255, 255)',
  borderRadius: '2px',
};

const noRowStyle = {
  height: '26px',
  width: '216.8px',
  padding: '0.5rem 0rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
  border: '1px solid rgb(193, 193, 193)',
  color: 'rgb(50, 49, 48)',
  backgroundColor: 'rgb(241, 241, 241)',
  borderTopLeftRadius: '2px',
  borderTopRightRadius: '2px',
};

const noRowStyleItem = {
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
};

const ItemTypes = {
  BOX: 'box',
};

export const Dustbin = memo(function Dustbin({ children, styles, title }) {
  const { t } = useTranslation();

  const ROW_NAMES_TEXT = {
    order: t('excelDragDropRowName.order'),
    documentType: t('excelDragDropRowName.documentType'),
    number: t('excelDragDropRowName.number'),
    dateBE: t('excelDragDropRowName.dateBE'),
    dueDateBE: t('excelDragDropRowName.dueDateBE'),
    reference: t('excelDragDropRowName.reference'),
    includeVat: t('excelDragDropRowName.includeVat'),
    calculationMethod: t('excelDragDropRowName.calculationMethod'),
    discount: t('excelDragDropRowName.discount'),
    language: t('excelDragDropRowName.language'),
    customerName: t('excelDragDropRowName.customerName'),
    customerAddressLineOne: t('excelDragDropRowName.customerAddressLineOne'),
    customerAddressLineTwo: t('excelDragDropRowName.customerAddressLineTwo'),
    customerPostcode: t('excelDragDropRowName.customerPostcode'),
    customerCountryName: t('excelDragDropRowName.customerCountryName'),
    customerTaxNumberType: t('excelDragDropRowName.customerTaxNumberType'),
    customerTaxId: t('excelDragDropRowName.customerTaxId'),
    customerBranchNumber: t('excelDragDropRowName.customerBranchNumber'),
    customerPhone: t('excelDragDropRowName.customerPhone'),
    customerEmail: t('excelDragDropRowName.customerEmail'),
    note: t('excelDragDropRowName.note'),
    createdBy: t('excelDragDropRowName.createdBy'),
    receivedBy: t('excelDragDropRowName.receivedBy'),
    reIssue: t('excelDragDropRowName.reIssue'),
    referTypeCode: t('excelDragDropRowName.referTypeCode'),
    referNumber: t('excelDragDropRowName.referNumber'),
    referDateBE: t('excelDragDropRowName.referDateBE'),
    referAmountTotal: t('excelDragDropRowName.referAmountTotal'),
    referReasonCode: t('excelDragDropRowName.referReasonCode'),
    referSpecificReason: t('excelDragDropRowName.referSpecificReason'),

    itemDescription1: t('excelDragDropRowName.itemDescription1'),
    itemQuantity1: t('excelDragDropRowName.itemQuantity1'),
    itemUnitName1: t('excelDragDropRowName.itemUnitName1'),
    itemPrice1: t('excelDragDropRowName.itemPrice1'),
    itemDiscount1: t('excelDragDropRowName.itemDiscount1'),
    itemPercentVat1: t('excelDragDropRowName.itemPercentVat1'),

    itemDescription2: t('excelDragDropRowName.itemDescription2'),
    itemQuantity2: t('excelDragDropRowName.itemQuantity2'),
    itemUnitName2: t('excelDragDropRowName.itemUnitName2'),
    itemPrice2: t('excelDragDropRowName.itemPrice2'),
    itemDiscount2: t('excelDragDropRowName.itemDiscount2'),
    itemPercentVat2: t('excelDragDropRowName.itemPercentVat2'),

    itemDescription3: t('excelDragDropRowName.itemDescription3'),
    itemQuantity3: t('excelDragDropRowName.itemQuantity3'),
    itemUnitName3: t('excelDragDropRowName.itemUnitName3'),
    itemPrice3: t('excelDragDropRowName.itemPrice3'),
    itemDiscount3: t('excelDragDropRowName.itemDiscount3'),
    itemPercentVat3: t('excelDragDropRowName.itemPercentVat3'),

    itemDescription4: t('excelDragDropRowName.itemDescription4'),
    itemQuantity4: t('excelDragDropRowName.itemQuantity4'),
    itemUnitName4: t('excelDragDropRowName.itemUnitName4'),
    itemPrice4: t('excelDragDropRowName.itemPrice4'),
    itemDiscount4: t('excelDragDropRowName.itemDiscount4'),
    itemPercentVat4: t('excelDragDropRowName.itemPercentVat4'),

    itemDescription5: t('excelDragDropRowName.itemDescription5'),
    itemQuantity5: t('excelDragDropRowName.itemQuantity5'),
    itemUnitName5: t('excelDragDropRowName.itemUnitName5'),
    itemPrice5: t('excelDragDropRowName.itemPrice5'),
    itemDiscount5: t('excelDragDropRowName.itemDiscount5'),
    itemPercentVat5: t('excelDragDropRowName.itemPercentVat5'),

    noRow: t('excelDragDropRowName.noRow'),
  };

  // console.log('ROW_NAMES_TEXT: ', ROW_NAMES_TEXT());

  const [, drop] = useDrop({
    accept: ItemTypes.BOX,
    drop: () => ({ name: title }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (item) => {
      const { currentColumnName } = item;
      return Object.keys(ROW_NAMES).some((columnName) => currentColumnName === columnName);
    },
  });

  return ROW_NAMES.noRow === title ? (
    <div ref={drop}>
      <Stack>
        <div style={noRowStyle}>{t('excelDragDropCheck.columnList')}</div>
        <div style={noRowStyleItem}>{children}</div>
      </Stack>
    </div>
  ) : (
    <div ref={drop} style={style}>
      <Stack horizontal horizontalAlign="space-between">
        <p style={{ flexDirection: 'column' }}>
          {ROW_NAMES_TEXT[title]}
          {ROW_NAMES_REQUIRE.includes(title) ? <span style={{ color: 'red' }}> *</span> : <></>}
        </p>
        <Stack verticalAlign="center" styles={{ root: { marginTop: -9 } }}>
          {children}
        </Stack>
      </Stack>
    </div>
  );
});
