import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, Icon, PrimaryButton, TextField, FocusTrapZone, Spinner } from '@fluentui/react';

const API_URL = process.env.REACT_APP_API_URL;

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const WooCommerceConnect = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const history = useHistory();

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const [status, setStatus] = useState('');

  const [shopUrl, setShopUrl] = useState(sessionStorage.getItem('wooCommerceConnect-shopUrl') || '');
  const [consumerKey, setConsumerKey] = useState(sessionStorage.getItem('wooCommerceConnect-consumerKey') || '');
  const [consumerSecret, setConsumerSecret] = useState(sessionStorage.getItem('wooCommerceConnect-consumerSecret') || '');

  const companyId = sessionStorage.getItem('companyId') || '';

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            API_URL + '/sources/woo-commerce',
            {
              shopUrl: shopUrl,
              consumerKey: consumerKey,
              consumerSecret: consumerSecret
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            }
          )
          .then(
            (response) => {
              console.log(response);

              if (response.data) {
                if (response.data.canLogin) {
                  history.push({
                    pathname: '/etax/sources/woo-commerce',
                    state: {},
                  });
                } else {
                  setStatus('fail');
                }
              }

              setShopUrl("");
              setConsumerKey("")
              setConsumerSecret("")
              setIsLoading(false);
            },
            (error) => {
              console.log(error);

              setStatus('fail');

              setShopUrl("");
              setConsumerKey("")
              setConsumerSecret("")
              setIsLoading(false);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

  };

  useEffect(() => {
    console.log('userEffect retrieve users data!');

    setIsLoading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/sources/woo-commerce', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log('woo-commerce sources: ', response);

              if (response.data) {
                if (!response.data.disabled && response.data.canLogin) {
                  history.push({
                    pathname: '/etax/sources/woo-commerce',
                    state: {},
                  });
                }
              }

              setIsLoading(false);
            },
            (error) => {
              console.log(error);

              setIsLoading(false);

            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {!isLoading && (
        <Stack>
          <br />

          <h1 style={{ marginLeft: "0px", marginTop: "27px" }}>
            <Icon iconName="CloudImportExport" style={{ color: "#106ebe" }} /> {t("wooCommerceConnect.header")}
          </h1>
          <h3 style={{ marginTop: "0px", marginBottom: "10px", alignSelf: "center" }}>{t("wooCommerceConnect.subHeader1")}</h3>
          {/* <h3 style={{ marginTop: "0px", marginBottom: "10px", alignSelf: "center" }}>{t("wooCommerceConnect.subHeader2")}</h3> */}

          <br />
          <br />
        </Stack>
      )}
      {status === "" && !isLoading && (
        <FocusTrapZone disabled={true}>
          <Stack horizontalAlign="center">
            <Stack
              style={{
                border: "1px solid rgb(134, 134, 134)",
                width: "350px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <br />
              <Stack horizontal horizontalAlign="center">
                <img src="/images/woo-commerce-logo.png" alt="woo-commerce" width="250px" style={{ margin: 0 }} />
              </Stack>
              <form onSubmit={handleSubmit}>
                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Shop URL"
                    description={t("wooCommerceConnect.shopUrl")}
                    required
                    onChange={(e) => {
                      setShopUrl(e.target.value);
                      sessionStorage.setItem("wooCommerceConnect-shopUrl", e.target.value);
                    }}
                    value={shopUrl}
                    style={{ width: "280px" }}
                  />
                </Stack>
                <br />
                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Consumer Key"
                    required
                    onChange={(e) => {
                      setConsumerKey(e.target.value);
                      sessionStorage.setItem("wooCommerceConnect-consumerKey", e.target.value);
                    }}
                    value={consumerKey}
                    style={{ width: "280px" }}
                  />
                </Stack>

                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Consumer Secret"
                    type="password"
                    canRevealPassword
                    required
                    onChange={(e) => {
                      setConsumerSecret(e.target.value);
                      sessionStorage.setItem("wooCommerceConnect-consumerSecret", e.target.value);
                    }}
                    value={consumerSecret}
                    style={{ width: "250px" }}
                  />
                </Stack>

                <br />
                <br />
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "30px" }}>
                  <PrimaryButton
                    style={{
                      fontSize: "20px",
                      height: "50px",
                      width: "280px",
                    }}
                    type="submit"
                  >
                    {t("wooCommerceConnect.connect")}
                  </PrimaryButton>
                </Stack>
              </form>
              <br />
              <br />
            </Stack>
          </Stack>
        </FocusTrapZone>
      )}
      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              {false ? (
                <Spinner label={t("wooCommerceConnect.processing")} labelPosition="down" styles={spinnerStyles} />
              ) : (
                <Spinner label={t("wooCommerceConnect.loading")} labelPosition="down" styles={spinnerStyles} />
              )}
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {status === "success" && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: "1px solid rgb(134, 134, 134)",
              width: "300px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: "0px", color: "#0078D4" }}>{t("wooCommerceConnect.successMessage")}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/success03.gif" alt="success03gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: "20px", height: "50px" }}
                onClick={() => {
                  history.push({
                    pathname: "/etax/sources/paypal",
                    state: {},
                  });
                }}
              >
                {t("wooCommerceConnect.successButton")}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      {status === "fail" && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: "1px solid rgb(134, 134, 134)",
              width: "300px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: "0px", color: "#F25139" }}>{t("wooCommerceConnect.failMessage")}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/fail01.gif" alt="fail01gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: "20px", height: "50px" }}
                onClick={() => {
                  setStatus("");
                }}
              >
                {t("wooCommerceConnect.failButton")}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      <br />
      <br />
      {status === "" && !isLoading && (
        <center>
          <br />
          <br />
          <span style={{ color: "gray" }}>{t("wooCommerceConnect.footer1")}</span>
          <br />
          <br />
          <span style={{ color: "gray", fontWeight: "bold" }}>{t("wooCommerceConnect.footer2")}</span>
        </center>
      )}
    </Stack>
  );
};

export default WooCommerceConnect;
