import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';
import { useTranslation } from 'react-i18next';

import {
  TextField,
  PrimaryButton,
  Dropdown,
  Stack,
  getTheme,
  Icon,
  Spinner,
  MessageBar,
  MessageBarType,
  Separator,
  ChoiceGroup,
  Toggle,
  DefaultButton,
  Dialog,
  DialogType,
  DialogFooter,
} from '@fluentui/react';

import { GoogleLogin } from 'react-google-login';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const stackTokens = { childrenGap: 10 };

const cardStyle = {
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 20,
  paddingBottom: 30,
  background: '#ffffff',
  marginLeft: '0',
  marginTop: '10px',
  boxShadow: theme.effects.elevation4,
};

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const Emails = () => {
  console.log('Re render');

  const { t } = useTranslation();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [saveClick, setSaveClick] = useState(false);
  const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);
  const [showMessageBarResetSuccess, setShowMessageBarResetSuccess] = useState(false);
  const [showMessageBarUpdateSuccess, setShowMessageBarUpdateSuccess] = useState(false);
  const [showMessageBarError, setShowMessageBarError] = useState(false);
  const [showMessageBarResetError, setShowMessageBarResetError] = useState(false);
  const [showMessageBarUpdateError, setShowMessageBarUpdateError] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [email, setEmail] = useState('');
  const [senderName, setSenderName] = useState('');

  const [enableEmail, setEnableEmail] = useState(false);
  const [provider, setProvider] = useState('');
  const [host, setHost] = useState('');
  const [encryption, setEncryption] = useState('');
  const [port, setPort] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameDisable, setUsernameDisable] = useState(false);
  const [providerDisable, setProviderDisable] = useState(false);

  const [to, setTo] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sendClick, setSendClick] = useState(false);

  const [sourceProvider, setSourceProvider] = useState('');

  const [clickUpdate, setClickUpdate] = useState(false);
  const [clickReset, setClickReset] = useState(false);

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const microsoftAuthorizeUrl =
    'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=68ca3418-6580-475c-bbb5-d38e051e58c6&response_type=code&redirect_uri=https://my.leceipt.com/settings/emails&response_mode=query&scope=user.read offline_access openid email profile Mail.Send&state=microsoft&prompt=select_account';

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    console.log('draft-js:', draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const [isLoadComplete, setIsLoadComplete] = useState(true);

  const companyId = sessionStorage.getItem('companyId') || '';

  const getEmailTemplateData = () => {
    setIsLoadComplete(false);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        let _apiEndpoint = API_URL + '/emails/templates';

        axios
          .get(_apiEndpoint, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data) {
                console.log(response.data);
                setSubject(response.data.subject);
                setMessage(response.data.message);

                let contentState = stateFromHTML(response.data.message);

                setEditorState(EditorState.createWithContent(contentState));
              } else {
              }

              setIsLoadComplete(true);
            },
            (error) => {
              console.log(error);

              setIsLoadComplete(true);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const deleteEmailTemplate = () => {
    setClickReset(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        let _apiEndpoint = API_URL + '/emails/templates';

        axios
          .delete(_apiEndpoint, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data) {
                console.log(response.data);

                getEmailTemplateData();

                setShowMessageBarResetSuccess(true);
                window.scrollTo(0, 0);
              }

              setClickReset(false);
            },
            (error) => {
              console.log(error);
              setShowMessageBarResetError(true);
              window.scrollTo(0, 0);

              setClickReset(false);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const updateEmailTemplate = (e) => {
    console.log('update click');

    setClickUpdate(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        let _apiEndpoint = API_URL + '/emails/templates';

        axios
          .put(
            _apiEndpoint,
            {
              subject: subject,
              message: message,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
                'cid': companyId
              },
            }
          )
          .then(
            (response) => {
              console.log(response);

              setShowMessageBarUpdateSuccess(true);
              window.scrollTo(0, 0);

              setClickUpdate(false);
            },
            (error) => {
              console.log(error);

              setShowMessageBarUpdateError(true);
              window.scrollTo(0, 0);

              setClickUpdate(false);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    console.log('userEffect retrieve emails data!');

    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');
    const state = query.get('state');

    console.log('code: ', code);

    if (code) {
      if (state === 'microsoft') {
        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
              .post(
                API_URL + '/sources/emails',
                {
                  provider: 'microsoft',
                  code: code,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'cid': companyId
                  },
                }
              )
              .then(
                (response) => {
                  console.log('Microsoft save setting response: ', response);

                  if (response.data) {
                    if (response.data.statusCode === 200) {
                      console.log('Save Email setting complete.');
                      setSourceProvider('microsoft');
                      setProviderDisable(true);
                    } else {
                      console.log('Save Email setting fail.');
                    }
                  }

                  setIsLoading(false);
                  setSaveClick(false);

                  if (response.data.statusCode === 200) {
                    setShowMessageBarSuccess(true);

                    setEnableEmail(true);
                    setProvider('microsoft');
                    setSourceProvider('microsoft');
                    setProviderDisable(true);
                    setEmail(response.data.data.userPrincipalName);
                  } else {
                    setShowMessageBarError(true);
                  }
                  window.scrollTo(0, 0);
                },
                (error) => {
                  console.log(error);

                  setIsLoading(false);
                  setSaveClick(false);

                  setShowMessageBarError(true);
                  window.scrollTo(0, 0);
                }
              );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);

            setIsLoading(false);
            setSaveClick(false);

            setShowMessageBarError(true);
            window.scrollTo(0, 0);
          });
      }
    } else {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/sources/emails', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            })
            .then(
              (response) => {
                console.log('Email sources: ', response);

                if (response.data.id) {
                  if (!response.data.disabled) {
                    console.log('Email sources disabled.');

                    setEnableEmail(true);
                    setProvider(response.data.provider);
                    setSourceProvider(response.data.provider);
                    setProviderDisable(true);
                    setEmail(response.data.email);

                    if (response.data.provider === 'smtp' || response.data.provider === 'sendgrid') {
                      setSenderName(response.data.senderName);
                      setHost(response.data.host);
                      setEncryption(response.data.encryption);
                      setPort(response.data.port);
                      setUsername(response.data.username);
                    }

                    getEmailTemplateData();
                  }
                }

                setIsLoading(false);
              },
              (error) => {
                console.log(error);

                setIsLoading(false);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('enable email: ', enableEmail);
  }, [enableEmail]);

  function handleSubmit(event) {
    event.preventDefault();

    setSaveClick(true);

    console.log('email: ', email);
    console.log('senderName: ', senderName);
    console.log('provider: ', provider);
    console.log('host: ', host);
    console.log('encryption: ', encryption);
    console.log('port: ', port);
    console.log('username: ', username);
    console.log('password: ', password);

    let _username = username;

    if (provider === 'sendgrid') {
      _username = 'apikey';
    }

    let isCorrect = true;

    if (isCorrect) {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + '/sources/emails',
              {
                email: email,
                senderName: senderName,
                provider: provider,
                host: host,
                encryption: encryption,
                port: port,
                username: _username,
                password: password,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              }
            )
            .then(
              (response) => {
                console.log('save setting response: ', response);

                if (response.data) {
                  if (response.data.statusCode === 200) {
                    console.log('Save SMTP setting complete.');
                  } else {
                    console.log('Save SMTP setting fail.');
                  }
                }

                setSaveClick(false);

                if (response.data.statusCode === 200) {
                  setShowMessageBarSuccess(true);

                  setSourceProvider(provider);
                  setProviderDisable(true);
                } else {
                  setShowMessageBarError(true);
                }
                window.scrollTo(0, 0);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      setSaveClick(false);
      setShowMessageBarError(true);
      window.scrollTo(0, 0);
    }
  }

  const getErrorMessage = (value) => {
    return value && value !== '0' && value.trim() ? '' : t('emailSetting.getErrorMessage');
  };

  const sendEmail = (event) => {
    event.preventDefault();

    setSendClick(true);

    console.log('send email click!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            API_URL + '/sources/emails/send',
            {
              to: to,
              subject: subject,
              message: message,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            }
          )
          .then(
            (response) => {
              console.log('email call response: ', response);

              if (response.data) {
                console.log('email response: ', response.data);
              }

              setSendClick(false);
            },
            (error) => {
              console.log(error);
              setSendClick(false);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
        setSendClick(false);
      });
  };

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + '/sources',
              {
                source: 'emails',
                replace: '/disabled',
                value: true,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              }
            )
            .then((response) => {
              console.log('disconnect: ', response);

              axios
                .patch(
                  API_URL + '/sources',
                  {
                    source: 'emails',
                    replace: '/provider',
                    value: 'none',
                  },
                  {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId
                    },
                  }
                )
                .then((resp) => {
                  console.log('disconnect: ', resp);

                  setIsLoading(false);
                  setEnableEmail(false);
                  setSourceProvider('none');
                  setProviderDisable(false);
                })
                .catch((error) => {
                  //Acquire token silent failure, and send an interactive request
                  console.log(error);
                  instance.acquireTokenRedirect(silentRequest);
                });
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const responseGoogle = (response) => {
    console.log('Google response: ', response);

    setIsLoading(true);

    if (response.code) {
      console.log('Google code response: ', response.code);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + '/sources/emails',
              {
                provider: 'google',
                code: response.code,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              }
            )
            .then(
              (response) => {
                console.log('Google save setting response: ', response);

                if (response.data) {
                  if (response.data.statusCode === 200) {
                    console.log('Save Email setting complete.');
                    setSourceProvider('google');
                    setProviderDisable(true);
                  } else {
                    console.log('Save Email setting fail.');
                  }
                }

                setIsLoading(false);
                setSaveClick(false);

                if (response.data.statusCode === 200) {
                  setShowMessageBarSuccess(true);
                  setEmail(response.data.data.email);
                } else {
                  setShowMessageBarError(true);
                }
                window.scrollTo(0, 0);
              },
              (error) => {
                console.log(error);

                setIsLoading(false);
                setSaveClick(false);

                setShowMessageBarError(true);
                window.scrollTo(0, 0);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);

          setIsLoading(false);
          setSaveClick(false);

          setShowMessageBarError(true);
          window.scrollTo(0, 0);
        });
    }
  };

  return (
    <Stack style={{ background: '#FAF9F8' }}>
      {showMessageBarSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSuccess(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('emailSetting.updateSuccess')}
        </MessageBar>
      )}
      {showMessageBarError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarError(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('emailSetting.updateFail')}
        </MessageBar>
      )}

      {showMessageBarResetSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setShowMessageBarResetSuccess(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('emailSetting.showMessageBarResetSuccess')}
        </MessageBar>
      )}
      {showMessageBarResetError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarResetError(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('emailSetting.showMessageBarResetError')}
        </MessageBar>
      )}

      {showMessageBarUpdateSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setShowMessageBarUpdateSuccess(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('emailSetting.showMessageBarUpdateSuccess')}
        </MessageBar>
      )}
      {showMessageBarUpdateError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarUpdateError(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('emailSetting.showMessageBarUpdateError')}
        </MessageBar>
      )}

      <h2 style={{ marginLeft: '0', marginTop: '27px' }}>
        <Icon iconName="Mail" style={{ color: '#106ebe' }} /> {t('emailSetting.header')}
      </h2>

      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center" style={{ marginTop: '25px', width: 670 }}>
            <br />
            <br />
            <div>
              {password !== '' ? (
                <Spinner label={t('emailSetting.processing')} labelPosition="down" styles={spinnerStyles} />
              ) : (
                <Spinner label={t('emailSetting.loading')} labelPosition="down" styles={spinnerStyles} />
              )}
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {!isLoading && (
        <div>
          <Stack horizontal tokens={stackTokens}>
            <Toggle
              label={t('emailSetting.toggleCompanyEmail')}
              inlineLabel
              onText={t('emailSetting.on')}
              offText={t('emailSetting.off')}
              styles={{ root: { width: 670 } }}
              checked={enableEmail}
              onChange={() => {
                if (enableEmail) {
                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('emailSetting.disconnect'),
                    message: t('emailSetting.disconnectConfirm'),
                    onConfirm: () => {
                      disconnect(true);
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                    },
                    onCancel: () =>
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      })),
                  }));
                } else {
                  setEnableEmail(true);
                }
              }}
            />
          </Stack>
          <form onSubmit={handleSubmit}>
            {enableEmail && (
              <div>
                <div style={cardStyle}>
                  <Dropdown
                    placeholder={t('emailSetting.pleaseSelect')}
                    label={t('emailSetting.provider')}
                    options={[
                      {
                        key: 'google',
                        text: 'Google (Gmail / G Suite / Google Workspace)',
                      },
                      {
                        key: 'microsoft',
                        text: 'Microsoft (Hotmail / Live / Outlook.com / Office 365 / Microsoft 365)',
                      },
                      {
                        key: 'sendgrid',
                        text: 'SendGrid',
                      },
                      {
                        key: 'smtp',
                        text: 'SMTP',
                      },
                    ]}
                    disabled={providerDisable}
                    selectedKey={provider}
                    required={enableEmail}
                    styles={{ root: { width: 630 } }}
                    onChange={(e, selectedOption) => {
                      if (selectedOption.key === 'google') {
                        setProvider('google');
                      } else if (selectedOption.key === 'microsoft') {
                        setProvider('microsoft');
                      } else if (selectedOption.key === 'sendgrid') {
                        setProvider('sendgrid');
                        setHost('smtp.sendgrid.net');
                        setEncryption('None');
                        setPort('587');
                        setUsername('apikey');
                        setUsernameDisable(false);
                      } else if (selectedOption.key === 'smtp') {
                        setProvider('smtp');
                        setHost('');
                        setEncryption('');
                        setPort('');
                        setUsernameDisable(false);
                      }
                    }}
                  />

                  {provider === 'google' && (
                    <center>
                      <br />
                      {sourceProvider === 'google' ? (
                        <div>
                          <h3>
                            {t('emailSetting.email')} {email}
                          </h3>
                          <br />
                          <PrimaryButton
                            name="disconnectButton"
                            text={t('emailSetting.disconnectButton')}
                            iconProps={{ iconName: 'Delete' }}
                            onClick={() => {
                              setDialogConfirm((prev) => ({
                                ...prev,
                                isClick: true,
                                header: t('emailSetting.disconnect'),
                                message: t('emailSetting.disconnectConfirm'),
                                onConfirm: () => {
                                  disconnect(true);
                                  setDialogConfirm((prev) => ({
                                    ...prev,
                                    isClick: false,
                                  }));
                                },
                                onCancel: () =>
                                  setDialogConfirm((prev) => ({
                                    ...prev,
                                    isClick: false,
                                  })),
                              }));
                            }}
                          />
                        </div>
                      ) : (
                        <GoogleLogin
                          clientId="477116153552-d2aleu4q2kje6322gc0okvot2u2gno3e.apps.googleusercontent.com"
                          buttonText="Sign in with Google"
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                          cookiePolicy={'single_host_origin'}
                          accessType="offline"
                          responseType="code"
                          approvalPrompt="force"
                          prompt="consent"
                          scope="https://www.googleapis.com/auth/gmail.send"
                          theme="dark"
                        />
                      )}
                    </center>
                  )}

                  {provider === 'microsoft' && (
                    <center>
                      <br />
                      {sourceProvider === 'microsoft' ? (
                        <div>
                          <h3>
                            {t('emailSetting.email')} {email}
                          </h3>
                          <br />
                          <PrimaryButton
                            name="disconnectButton"
                            text={t('emailSetting.disconnectButton')}
                            iconProps={{ iconName: 'Delete' }}
                            onClick={() => {
                              setDialogConfirm((prev) => ({
                                ...prev,
                                isClick: true,
                                header: t('emailSetting.disconnect'),
                                message: t('emailSetting.disconnectConfirm'),
                                onConfirm: () => {
                                  disconnect(true);
                                  setDialogConfirm((prev) => ({
                                    ...prev,
                                    isClick: false,
                                  }));
                                },
                                onCancel: () =>
                                  setDialogConfirm((prev) => ({
                                    ...prev,
                                    isClick: false,
                                  })),
                              }));
                            }}
                          />
                        </div>
                      ) : (
                        <input
                          type="image"
                          src="https://docs.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_signin_dark.svg"
                          alt="inputimage"
                          onClick={() => {
                            setIsLoading(true);
                            window.location.assign(microsoftAuthorizeUrl);
                          }}
                        />
                      )}
                    </center>
                  )}

                  {provider === 'sendgrid' && (
                    <div>
                      <br />
                      <TextField
                        name="senderName"
                        label={t('emailSetting.senderName')}
                        styles={{ root: { width: 630 } }}
                        value={senderName}
                        onChange={(e) => setSenderName(e.target.value)}
                        onGetErrorMessage={getErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                      />

                      <br />
                      <TextField
                        name="email"
                        label={t('emailSetting.senderEmail')}
                        styles={{ root: { width: 630 } }}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        onGetErrorMessage={getErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                      />
                      <br />
                      <TextField
                        name="password"
                        label="API Key"
                        styles={{ root: { width: 630 } }}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        onGetErrorMessage={getErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                        type="password"
                        canRevealPassword
                        revealPasswordAriaLabel={t('emailSetting.revealPassword')}
                      />
                      <br />
                      <br />
                      <Separator>{t('emailSetting.saveSeparator')}</Separator>
                      <br />
                      <br />

                      <div align="center">
                        {!saveClick && (
                          <PrimaryButton
                            name="save"
                            text={t('emailSetting.save')}
                            iconProps={{ iconName: 'Save' }}
                            type="submit"
                            style={{ width: 150 }}
                          />
                        )}
                        {saveClick && <Spinner label={t('emailSetting.saving')} labelPosition="right" />}
                      </div>
                    </div>
                  )}

                  {provider === 'smtp' && (
                    <div>
                      <br />
                      <TextField
                        name="host"
                        label="SMTP Host"
                        styles={{ root: { width: 630 } }}
                        value={host}
                        onChange={(e) => {
                          setHost(e.target.value);
                        }}
                        onGetErrorMessage={getErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                      />
                      <br />
                      <ChoiceGroup
                        label="Type of Encryption"
                        selectedKey={encryption}
                        styles={{
                          flexContainer: { display: 'flex' },
                        }}
                        required
                        options={[
                          {
                            key: 'None',
                            text: 'None\u00A0\u00A0\u00A0\u00A0',
                          },
                          {
                            key: 'SSL',
                            text: 'SSL\u00A0\u00A0\u00A0\u00A0',
                          },
                          { key: 'TLS', text: 'TLS\u00A0\u00A0\u00A0\u00A0' },
                          { key: 'STARTLS', text: 'STARTLS\u00A0\u00A0\u00A0\u00A0' },
                        ]}
                        onChange={(e, selectedOption) => {
                          setEncryption(selectedOption.key);
                        }}
                      />
                      <br />
                      <TextField
                        name="port"
                        label="SMTP Port"
                        styles={{ root: { width: 630 } }}
                        value={port}
                        onChange={(e) => {
                          setPort(e.target.value);
                        }}
                        onGetErrorMessage={getErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                      />
                      <br />
                      <TextField
                        name="senderName"
                        label={t('emailSetting.senderName')}
                        styles={{ root: { width: 630 } }}
                        value={senderName}
                        onChange={(e) => setSenderName(e.target.value)}
                        onGetErrorMessage={getErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                      />

                      <br />
                      <TextField
                        name="username"
                        label="SMTP Username"
                        styles={{ root: { width: 630 } }}
                        value={username}
                        onChange={(e) => {
                          setUsername(e.target.value);
                          setEmail(e.target.value);
                        }}
                        onGetErrorMessage={getErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                        disabled={usernameDisable}
                      />
                      <br />
                      <TextField
                        name="password"
                        label="SMTP Password"
                        styles={{ root: { width: 630 } }}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        onGetErrorMessage={getErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                        type="password"
                        canRevealPassword
                        revealPasswordAriaLabel={t('emailSetting.revealPassword')}
                      />
                      <br />
                      <br />
                      <Separator>{t('emailSetting.saveSeparator')}</Separator>
                      <br />
                      <br />

                      <div align="center">
                        {!saveClick && (
                          <PrimaryButton
                            name="save"
                            text={t('emailSetting.save')}
                            iconProps={{ iconName: 'Save' }}
                            type="submit"
                            style={{ width: 150 }}
                          />
                        )}
                        {saveClick && <Spinner label={t('emailSetting.saving')} labelPosition="right" />}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </form>
          <br />
          <br />

          {(sourceProvider === 'google' || sourceProvider === 'microsoft' || sourceProvider === 'sendgrid' || sourceProvider === 'smtp') &&
            sourceProvider === provider &&
            enableEmail && (
              <form onSubmit={sendEmail}>
                <h2 style={{ marginLeft: '0', marginTop: '27px' }}>
                  <Icon iconName="TextBox" style={{ color: '#106ebe' }} /> {t('emailSetting.customizeFormat')}
                </h2>
                <div style={cardStyle}>
                  <Stack horizontal horizontalAlign="space-between" tokens={stackTokens}>
                    <Stack vertical>
                      <strong>
                        {t('emailSetting.testSending')}{' '}
                        {(sourceProvider === 'smtp' || provider === 'smtp' || sourceProvider === 'sendgrid' || provider === 'sendgrid') && (
                          <span>{t('emailSetting.saveFirst')}</span>
                        )}
                      </strong>
                    </Stack>
                    <Stack vertical>
                      <div align="center">
                        {!sendClick && <DefaultButton name="send" text={t('emailSetting.send')} iconProps={{ iconName: 'Send' }} type="submit" />}
                        {sendClick && <Spinner label={t('emailSetting.sending')} labelPosition="right" />}
                      </div>
                    </Stack>
                  </Stack>
                  <Stack horizontal tokens={stackTokens}>
                    <TextField
                      name="to"
                      label="To:"
                      styles={{ root: { width: 630 } }}
                      value={to}
                      onChange={(e) => {
                        setTo(e.target.value);
                      }}
                      onGetErrorMessage={getErrorMessage}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                    />
                  </Stack>

                  <Stack horizontal tokens={stackTokens}>
                    <TextField
                      name="subject"
                      label="Subject:"
                      styles={{ root: { width: 630 } }}
                      value={subject}
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                      onGetErrorMessage={getErrorMessage}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                    />
                  </Stack>
                  <br />
                  <b>
                    Message:<span style={{ color: 'rgb(164, 38, 44)' }}> *</span>
                  </b>
                  <Stack horizontal tokens={stackTokens}>
                    <div style={{ width: 630 }}>
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                        toolbar={{
                          options: ['inline', 'blockType', 'list', 'link', 'image', 'emoji'],
                          inline: { inDropdown: false, options: ['bold', 'italic', 'underline'] },
                        }}
                        editorStyle={{ border: '1px solid', backgroundColor: 'white', height: '250px' }}
                      />
                    </div>
                  </Stack>
                  <br />
                  <br />
                  <Separator>{t('emailSetting.saveSeparator2')}</Separator>
                  <br />
                  <br />
                  <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 20 }}>
                    {isLoadComplete && !clickUpdate && !clickReset && (
                      <PrimaryButton
                        name="save"
                        text={t('emailSetting.save')}
                        iconProps={{ iconName: 'Save' }}
                        onClick={updateEmailTemplate}
                        style={{ width: 150 }}
                      />
                    )}
                    {isLoadComplete && !clickUpdate && !clickReset && (
                      <DefaultButton
                        onClick={() => {
                          setDialogConfirm((prev) => ({
                            ...prev,
                            isClick: true,
                            header: t('emailSetting.resetConfirmHeader'),
                            message: t('emailSetting.resetConfirm'),
                            onConfirm: () => {
                              deleteEmailTemplate();
                              setDialogConfirm((prev) => ({
                                ...prev,
                                isClick: false,
                              }));
                            },
                            onCancel: () =>
                              setDialogConfirm((prev) => ({
                                ...prev,
                                isClick: false,
                              })),
                          }));
                        }}
                        text={t('emailSetting.reset')}
                        iconProps={{
                          iconName: 'Refresh',
                        }}
                      />
                    )}
                    {!isLoadComplete && <Spinner label={t('emailSetting.loading2')} labelPosition="right" />}
                    {clickUpdate && <Spinner label={t('emailSetting.saving2')} labelPosition="right" />}
                    {clickReset && <Spinner label={t('emailSetting.resetting')} labelPosition="right" />}
                  </Stack>
                </div>
                <br />
                <br />
              </form>
            )}
        </div>
      )}
      <br />
      <br />
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
};

export default Emails;
