import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { silentRequest } from "../../../authConfig";

import { useMsal, useAccount } from "@azure/msal-react";
import InfiniteScroll from 'react-infinite-scroll-component';

// import paginate from "jw-paginate";

import {
    DetailsListLayoutMode,
    ShimmeredDetailsList,
    Selection,
    SelectionMode,
    Icon,
    Calendar,
    DateRangeType,
    Spinner,
    SpinnerSize,
    CommandBar,
    Stack,
    SearchBox,
    PrimaryButton,
    DefaultButton,
    TextField,
    ContextualMenu,
    Toggle,
    CheckboxVisibility,
    Dialog,
    DialogType,
    DialogFooter,
    Checkbox,
    Text,
    DatePicker,
    DetailsList,
    DetailsRow,
    Link,
    TooltipHost,
} from "@fluentui/react";

// import { Pagination } from "@uifabric/experiments/lib/Pagination";
import axios from "axios";

const homeStyles = {
    border: 0,
    margin: 0,
    width: "100%",
    background: "#FAF9F8",
};

const spinnerStyles = {
    circle: {
        height: 56,
        width: 56,
        borderWidth: 4,
    },
};

const onFormatDate = (date, format) => {
    if (format === "CE") {
        return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + date.getFullYear();
    } else {
        return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + (date.getFullYear() + 543);
    }
};

const APP_KEY = process.env.REACT_APP_TIKTOK_SHOP_APP_KEY;

const authorizationUrl =
  `https://auth-sandbox.tiktok-shops.com/oauth/authorize?app_key=${APP_KEY}&state=leceipt`

function TiktokShop() {
    const API_URL = process.env.REACT_APP_API_URL;

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const { t, i18n } = useTranslation();

    const [company, setCompany] = useState("");
    const [items, setItems] = useState([]);

    const percentVat = 7;
    const zeroVat = 0;
    // const nonVat = -1;

    const percentVatText = "7%";
    const zeroVatText = "0%";
    // const nonVatText = "-";

    const [loadDataComplete, setLoadDataComplete] = useState(false);
    const [getNewDocuments, setGetNewDocuments] = useState(false);
    const pageSize = 50

    const [moreData, setMoreData] = useState(false);

    const dateType = { key: "document", text: "วันที่บนเอกสาร" };

    const [endpointType, setEndpointType] = useState(
        (sessionStorage.getItem("tiktokShop-endpointType-key") && {
          key: sessionStorage.getItem("tiktokShop-endpointType-key"),
          text: sessionStorage.getItem("tiktokShop-endpointType-text"),
        }) || {
          key: "settlements",
          text: "Settlements",
        }
      );

      const sortType = {
        key: 1,
        text: t("tiktokShop.sortType1"),
      }
    

    const [orderStatus, setOrderStatus] = useState(
        (sessionStorage.getItem("tiktokShop-orderStatus-key") && {
            key: sessionStorage.getItem("tiktokShop-orderStatus-key") === "all" ? sessionStorage.getItem("tiktokShop-orderStatus-key") : Number(sessionStorage.getItem("tiktokShop-orderStatus-key")),
            text: sessionStorage.getItem("tiktokShop-orderStatus-text"),
        }) || {
            key: 130,
            text: t("tiktokShop.COMPLETED"),
        }
    );

    const [timeRangeField, setTimeRangeField] = useState(
        (sessionStorage.getItem("tiktokShop-timeRangeField-key") && {
            key: sessionStorage.getItem("tiktokShop-timeRangeField-key"),
            text: sessionStorage.getItem("tiktokShop-timeRangeField-text"),
        }) || {
            key: "UPDATE_TIME",
            text: t("tiktokShop.updateTime"),
        }
    );

    const [search, setSearch] = useState("");

    const [includeVat, setIncludeVat] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    const [registerVat, setRegisterVat] = useState(true);

    const isCompactMode = false;

    const [isCreateDocument, setIsCreateDocument] = useState(false);
    const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

    const [selectedItems, setSelectedItems] = useState([]);

    const [selection] = useState(
        new Selection({
            onSelectionChanged: () => {
                console.log("isCreateDocument: ", isCreateDocument);
                console.log("isCreateDocumentComplete: ", isCreateDocumentComplete);
                let _selectedItems = selection.getSelection();

                console.log(selection);

                setSelectedItems(_selectedItems);
            },
        })
    );

    const [dialogConfirm, setDialogConfirm] = useState({
        isClick: false,
        header: "",
        message: "",
        onConfirm: null,
        onCancel: null,
    });

    const [nextCursor, setNextCursor] = useState("");

    const [prefixNumber, setPrefixNumber] = useState("");
    const [countingNumber, setCountingNumber] = useState("");

    const [loadingNewData, setLoadingNewData] = useState(false);

    const companyId = sessionStorage.getItem("companyId") || "";

    const [primarySort, setPrimarySort] = useState(sessionStorage.getItem("tiktokShop-primarySort") || "createdTime");
    const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
        if (sessionStorage.getItem("tiktokShop-sort-createdTime") === "false") {
            return false;
        } else {
            return true;
        }
    });

    const [isSortedDescendingUpdatedTime, setIsSortedDescendingUpdatedTime] = useState(() => {
        if (sessionStorage.getItem("tiktokShop-sort-updatedTime") === "false") {
            return false;
        } else {
            return true;
        }
    });

    const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
        if (sessionStorage.getItem("tiktokShop-sort-number")) {
            if (sessionStorage.getItem("tiktokShop-sort-number") === "true") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });
    const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
        if (sessionStorage.getItem("tiktokShop-sort-customerName")) {
            if (sessionStorage.getItem("tiktokShop-sort-customerName") === "true") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });
    const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
        if (sessionStorage.getItem("tiktokShop-sort-grandTotal")) {
            if (sessionStorage.getItem("tiktokShop-sort-grandTotal") === "true") {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });

    const [showShippingFee, setShowShippingFee] = useState(true);

    const DayPickerStrings = {
        months: [
            t("tiktokShop.months01"),
            t("tiktokShop.months02"),
            t("tiktokShop.months03"),
            t("tiktokShop.months04"),
            t("tiktokShop.months05"),
            t("tiktokShop.months06"),
            t("tiktokShop.months07"),
            t("tiktokShop.months08"),
            t("tiktokShop.months09"),
            t("tiktokShop.months10"),
            t("tiktokShop.months11"),
            t("tiktokShop.months12"),
        ],

        shortMonths: [
            t("tiktokShop.shortMonths01"),
            t("tiktokShop.shortMonths02"),
            t("tiktokShop.shortMonths03"),
            t("tiktokShop.shortMonths04"),
            t("tiktokShop.shortMonths05"),
            t("tiktokShop.shortMonths06"),
            t("tiktokShop.shortMonths07"),
            t("tiktokShop.shortMonths08"),
            t("tiktokShop.shortMonths09"),
            t("tiktokShop.shortMonths10"),
            t("tiktokShop.shortMonths11"),
            t("tiktokShop.shortMonths12"),
        ],

        days: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"],

        shortDays: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],

        goToToday: t("tiktokShop.goToToday"),
        prevMonthAriaLabel: "ไปเดือนที่แล้ว",
        nextMonthAriaLabel: "ไปเดือนถัดไป",
        prevYearAriaLabel: "ไปปีที่แล้ว",
        nextYearAriaLabel: "ไปปีถัดไป",
        closeButtonAriaLabel: "ปิด",
        monthPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนปี",
        yearPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนเดือน",

        isRequiredErrorMessage: t("tiktokShop.isRequiredErrorMessage"),

        invalidInputErrorMessage: "รูปแบบวันที่ไม่ถูกต้อง",
    };

    const createSelectedDocuments = async (documentType) => {
        let _items = selection.getItems();

        let postUrl = "";

        switch (documentType) {
            case "RECEIPT-TAXINVOICE":
                postUrl = API_URL + "/etax/documents/receipts-taxinvoices/pdf-xml";
                break;
            case "RECEIPT-TAXINVOICE-ABB":
                postUrl = API_URL + "/etax/documents/receipts-taxinvoices-abb/pdf";
                break;
            case "RECEIPT":
                postUrl = API_URL + "/etax/documents/receipts/pdf-xml";
                break;
            case "INVOICE-TAXINVOICE":
                postUrl = API_URL + "/etax/documents/invoices-taxinvoices/pdf-xml";
                break;
            case "DELIVERYORDER-TAXINVOICE":
                postUrl = API_URL + "/etax/documents/deliveryorders-taxinvoices/pdf-xml";
                break;
            case "TAXINVOICE":
                postUrl = API_URL + "/etax/documents/taxinvoices/pdf-xml";
                break;
            default:
                break;
        }

        if (postUrl) {
            let _countingNumberLength = countingNumber.length;
            let _countingNumber = Number(countingNumber);

            await instance
                .acquireTokenSilent({ ...silentRequest, account: account })
                .then(async (tokenResponse) => {
                    for (let i = 0; i < selectedItems.length; i++) {
                        console.log("create selected document: ", selectedItems[i]);

                        for (let j = 0; j < _items.length; j++) {
                            if (_items[j].key === selectedItems[i].key) {
                                _items[j].status = "processing";
                                setItems([..._items]);

                                let _number = prefixNumber + padLeadingZeros(Number(_countingNumber), _countingNumberLength);
                                _countingNumber = padLeadingZeros(Number(_countingNumber) + 1, _countingNumberLength);
                                setCountingNumber(_countingNumber);

                                let _payload = {}

                                if (endpointType.key === 'settlements') {
                                    const [responseOrderDetails] = await Promise.all([
                                        axios
                                          // .get("http://localhost:7071/api/DynamicsFinanceOperations_getCustomer", {
                                          .get(API_URL + "/sources/tiktok-shop/orderdetails", {
                                            headers: {
                                              "Authorization": "Bearer " + tokenResponse.accessToken,
                                              "cid": companyId
                                            },
                                            params: {
                                                shop_id: shop.key,
                                                order_id_list: selectedItems[i].key,
                                            },
                                          })
                                          .catch((error) => console.log(error)),
                                      ]);
                
                                    console.log('responseOrderDetails', responseOrderDetails)
                                    if (responseOrderDetails.data.success) {
                                        const orderList = responseOrderDetails.data.data.order_list;
                
                                        orderList.forEach((order) => {
                                            const {
                                                item_list,
                                                order_id,
                                                payment_info,
                                                recipient_address,
                                            } = order;
                
                                            const { 
                                                full_address, 
                                                name, 
                                                phone, 
                                                zipcode 
                                            } = recipient_address;
                
                                            let _postcode = zipcode;
                                            let _addressLineOne = full_address;
                
                                            if (zipcode) {
                                                _addressLineOne = _addressLineOne.replace(zipcode, "");
                                            }
                
                                            let _taxId = "";
                                            let _taxNumberType = "";
                                            let _branchNumber = "";
                                            let _branchText = "";
                
                                            if (_taxId.includes("*") || !_taxId) {
                                                _taxNumberType = "OTHR";
                                                _taxId = "N/A";
                                                _branchNumber = "";
                                                _branchText = "";
                                            }
                                            let _customerPhone = phone;
                
                                            const _customerName = name;
                                            const _customerEmail = "";
                
                                            const _items = item_list.map((item, index) => {
                                                const { 
                                                    product_name, 
                                                    quantity, 
                                                    seller_sku, 
                                                    sku_name, 
                                                    sku_original_price, 
                                                    sku_seller_discount, 
                                                } = item;
                
                                                let _variation = "";
                                                if (sku_name) {
                                                    _variation = ` (${sku_name})`;
                                                }
                
                                                if (registerVat) {
                                                    return {
                                                        number: index + 1 + "",
                                                        description: product_name + _variation,
                                                        quantity: quantity,
                                                        unitCode: "",
                                                        unitName: "-",
                                                        price: sku_original_price,
                                                        discount: sku_seller_discount,
                                                        percentVat: percentVat,
                                                        percentVatText: percentVatText,
                                                        total: (sku_original_price - sku_seller_discount) * quantity,
                                                        sku: seller_sku
                                                    };
                                                } else {
                                                    return {
                                                        number: index + 1 + "",
                                                        description: product_name + _variation,
                                                        quantity: quantity,
                                                        unitCode: "",
                                                        unitName: "-",
                                                        price: sku_original_price,
                                                        discount: sku_seller_discount,
                                                        percentVat: zeroVat,
                                                        percentVatText: zeroVatText,
                                                        total: (sku_original_price - sku_seller_discount) * quantity,
                                                        sku: seller_sku
                                                    };
                                                }
                                            });
                
                                            const { 
                                                original_shipping_fee, 
                                                shipping_fee, 
                                                shipping_fee_platform_discount, 
                                                shipping_fee_seller_discount, 
                                            } = payment_info;
                
                                            if (showShippingFee) {
                                                _items.push({
                                                    number: (_items.length + 1) + "",
                                                    description: "ค่าขนส่ง",
                                                    quantity: 1,
                                                    unitCode: "",
                                                    unitName: "-",
                                                    price: original_shipping_fee,
                                                    discount: shipping_fee_seller_discount + shipping_fee_platform_discount,
                                                    percentVat: registerVat ? percentVat : zeroVat,
                                                    percentVatText: registerVat ? percentVatText : zeroVatText,
                                                    total: shipping_fee,
                                                    sku: ""
                                                });
                                            }
                
                                            const _discount = 0;
                                            let _amountTotal = 0;
                                            let _vatTotal = 0;
                                            let _grandTotal = 0;
                
                                            let _vatPriceTotal = 0;
                                            let _zeroVatPriceTotal = 0;
                                            let _nonVatPriceTotal = 0;
                
                                            if (includeVat) {
                                                _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                                _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                                _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                                                _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                                                _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                                            } else {
                                                _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                                _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                                _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                                                _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                                            }
                
                                            if (_customerPhone.includes("*") || _customerPhone.includes("(") || _customerPhone.includes(")")) {
                                                _customerPhone = "";
                                            }
                
                                            if (_postcode.includes('*')) {
                                                _postcode = "00000"
                                            }
                
                                            const _customer = {
                                                addressLineOne: _addressLineOne,
                                                addressLineTwo: "",
                                                branchNumber: _branchNumber,
                                                branchText: _branchText,
                                                email: _customerEmail,
                                                name: _customerName,
                                                phone: _customerPhone !== "" ? _customerPhone : "",
                                                phoneCountryCode: "+66",
                                                postcode: _postcode ? _postcode : "00000",
                                                taxId: _taxId,
                                                taxNumberType: _taxNumberType,
                                                language: "th",
                                                countryCode: "TH",
                                                countryName: "ไทย",
                                                unstructure: true,
                                            };

                                            _payload = {
                                                amountTotal: _amountTotal,
                                                company: {
                                                    ...company,
                                                    includeVat: includeVat,
                                                },
                                                reference: order_id,
                                                customer: _customer,
                                                date: selectedItems[i].date,
                                                dateBE: selectedItems[i].dateBE,
                                                dateCE: selectedItems[i].dateCE,
                                                discount: _discount,
                                                grandTotal: _grandTotal,
                                                nonVatPriceTotal: _nonVatPriceTotal,
                                                zeroVatPriceTotal: _zeroVatPriceTotal,
                                                vatPriceTotal: _vatPriceTotal,
                                                items: _items,
                                                percentVat: registerVat ? percentVat : 0,
                                                receivedBy: "TikTok Shop",
                                                vatTotal: _vatTotal,
                                                reIssue: false,
                                                includeVat: includeVat,
                                                number: _number,
                                                note: "",
                                            }
            
                                        });
                
                                    }

                                } else {
                                    _payload = {
                                        amountTotal: selectedItems[i].amountTotal,
                                        company: {
                                            ...company,
                                            includeVat: includeVat,
                                        },
                                        reference: selectedItems[i].orderId,
                                        customer: selectedItems[i].customer,
                                        date: selectedItems[i].date,
                                        dateBE: selectedItems[i].dateBE,
                                        dateCE: selectedItems[i].dateCE,
                                        discount: selectedItems[i].discount,
                                        grandTotal: selectedItems[i].grandTotal,
                                        nonVatPriceTotal: selectedItems[i].nonVatPriceTotal,
                                        zeroVatPriceTotal: selectedItems[i].zeroVatPriceTotal,
                                        vatPriceTotal: selectedItems[i].vatPriceTotal,
                                        items: selectedItems[i].items,
                                        percentVat: registerVat ? percentVat : 0,
                                        receivedBy: "TikTok Shop",
                                        vatTotal: selectedItems[i].vatTotal,
                                        reIssue: false,
                                        includeVat: includeVat,
                                        number: _number,
                                        note: "",
                                    }
                                }

                                const promise_PDF_XML = await new Promise(async (resolve) => {
                                    await axios
                                        .post(
                                            postUrl,
                                            _payload,
                                            {
                                                headers: {
                                                    Authorization: "Bearer " + tokenResponse.accessToken,
                                                    "cid": companyId
                                                },
                                            }
                                        )
                                        .then(
                                            async (response) => {
                                                console.log("Create document response: ", response);

                                                let count = 0;

                                                let interval = await setInterval(async () => {
                                                    await axios
                                                        .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                                                            headers: {
                                                                Authorization: "Bearer " + tokenResponse.accessToken,
                                                                "Content-Type": "application/json",
                                                                "cid": companyId
                                                            },
                                                        })
                                                        .then((jobsResponse) => {
                                                            count += 1;
                                                            console.log("Jobs response: ", j, jobsResponse.data.status, count);

                                                            if (jobsResponse.data.status === "complete") {
                                                                _items[j].status = "successful";
                                                                _items[j].etaxCreated = true;

                                                                setItems([..._items]);

                                                                clearInterval(interval);

                                                                resolve(true);
                                                            } else if (count === 300) {
                                                                _items[j].status = "fail";

                                                                setItems([..._items]);

                                                                clearInterval(interval);

                                                                resolve(false);
                                                            }
                                                        });
                                                }, 1000);
                                            },
                                            (error) => {
                                                console.log("Error Create document response: ", error);
                                                _items[j].status = "fail";
                                                setItems([..._items]);
                                                resolve(false);
                                            }
                                        );
                                }); //promise

                                Promise.all([promise_PDF_XML]).then((value) => {
                                    console.log(i, value);
                                });
                            } //if
                        } //for j
                    } //for i
                })
                .catch((error) => {
                    //Acquire token silent failure, and send an interactive request
                    console.log(error);
                    instance.acquireTokenRedirect(silentRequest);
                });
        }

        setIsCreateDocumentComplete(true);
        setIsCreateDocument(false);
    };

    const createDocument = async (documentType, item) => {
        setIsLoading(true);

        let documentPath = "/etax/documents/receipts";

        switch (documentType) {
            case "RECEIPT-TAXINVOICE":
                documentPath = "/etax/documents/receipts-taxinvoices";
                break;
            case "RECEIPT-TAXINVOICE-ABB":
                documentPath = "/etax/documents/receipts-taxinvoices-abb";
                break;
            case "RECEIPT":
                documentPath = "/etax/documents/receipts";
                break;
            case "INVOICE-TAXINVOICE":
                documentPath = "/etax/documents/invoices-taxinvoices";
                break;
            case "DELIVERYORDER-TAXINVOICE":
                documentPath = "/etax/documents/deliveryorders-taxinvoices";
                break;
            case "TAXINVOICE":
                documentPath = "/etax/documents/taxinvoices";
                break;
            default:
                break;
        }

        if (endpointType.key === 'settlements') {
            instance
                .acquireTokenSilent({ ...silentRequest, account: account })
                .then(async (tokenResponse) => {
                    const [responseOrderDetails] = await Promise.all([
                        axios
                          // .get("http://localhost:7071/api/DynamicsFinanceOperations_getCustomer", {
                          .get(API_URL + "/sources/tiktok-shop/orderdetails", {
                            headers: {
                              "Authorization": "Bearer " + tokenResponse.accessToken,
                              "cid": companyId
                            },
                            params: {
                                shop_id: shop.key,
                                order_id_list: item.key,
                            },
                          })
                          .catch((error) => console.log(error)),
                      ]);

                    console.log('responseOrderDetails', responseOrderDetails)
                    if (responseOrderDetails.data.success) {
                        const orderList = responseOrderDetails.data.data.order_list;

                        orderList.forEach((order) => {
                            const {
                                item_list,
                                order_id,
                                payment_info,
                                recipient_address,
                            } = order;

                            const { 
                                full_address, 
                                name, 
                                phone, 
                                zipcode 
                            } = recipient_address;

                            let _postcode = zipcode;
                            let _addressLineOne = full_address;

                            if (zipcode) {
                                _addressLineOne = _addressLineOne.replace(zipcode, "");
                            }

                            let _taxId = "";
                            let _taxNumberType = "";
                            let _branchNumber = "";
                            let _branchText = "";

                            if (_taxId.includes("*") || !_taxId) {
                                _taxNumberType = "OTHR";
                                _taxId = "N/A";
                                _branchNumber = "";
                                _branchText = "";
                            }
                            let _customerPhone = phone;

                            const _customerName = name;
                            const _customerEmail = "";

                            const _items = item_list.map((item, index) => {
                                const { 
                                    product_name, 
                                    quantity, 
                                    seller_sku, 
                                    sku_name, 
                                    sku_original_price, 
                                    sku_seller_discount, 
                                } = item;

                                let _variation = "";
                                if (sku_name) {
                                    _variation = ` (${sku_name})`;
                                }

                                if (registerVat) {
                                    return {
                                        number: index + 1 + "",
                                        description: product_name + _variation,
                                        quantity: quantity,
                                        unitCode: "",
                                        unitName: "-",
                                        price: sku_original_price,
                                        discount: sku_seller_discount,
                                        percentVat: percentVat,
                                        percentVatText: percentVatText,
                                        total: (sku_original_price - sku_seller_discount) * quantity,
                                        sku: seller_sku
                                    };
                                } else {
                                    return {
                                        number: index + 1 + "",
                                        description: product_name + _variation,
                                        quantity: quantity,
                                        unitCode: "",
                                        unitName: "-",
                                        price: sku_original_price,
                                        discount: sku_seller_discount,
                                        percentVat: zeroVat,
                                        percentVatText: zeroVatText,
                                        total: (sku_original_price - sku_seller_discount) * quantity,
                                        sku: seller_sku
                                    };
                                }
                            });

                            const { 
                                original_shipping_fee, 
                                shipping_fee, 
                                shipping_fee_platform_discount, 
                                shipping_fee_seller_discount, 
                            } = payment_info;

                            if (showShippingFee) {
                                _items.push({
                                    number: (_items.length + 1) + "",
                                    description: "ค่าขนส่ง",
                                    quantity: 1,
                                    unitCode: "",
                                    unitName: "-",
                                    price: original_shipping_fee,
                                    discount: shipping_fee_seller_discount + shipping_fee_platform_discount,
                                    percentVat: registerVat ? percentVat : zeroVat,
                                    percentVatText: registerVat ? percentVatText : zeroVatText,
                                    total: shipping_fee,
                                    sku: ""
                                });
                            }

                            const _discount = 0;
                            let _amountTotal = 0;
                            let _vatTotal = 0;
                            let _grandTotal = 0;

                            let _vatPriceTotal = 0;
                            let _zeroVatPriceTotal = 0;

                            if (includeVat) {
                                _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                                _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                                _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                            } else {
                                _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                                _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                            }

                            if (_customerPhone.includes("*") || _customerPhone.includes("(") || _customerPhone.includes(")")) {
                                _customerPhone = "";
                            }

                            if (_postcode.includes('*')) {
                                _postcode = "00000"
                            }

                            const _customer = {
                                addressLineOne: _addressLineOne,
                                addressLineTwo: "",
                                branchNumber: _branchNumber,
                                branchText: _branchText,
                                email: _customerEmail,
                                name: _customerName,
                                phone: _customerPhone !== "" ? _customerPhone : "",
                                phoneCountryCode: "+66",
                                postcode: _postcode ? _postcode : "00000",
                                taxId: _taxId,
                                taxNumberType: _taxNumberType,
                                language: "th",
                                countryCode: "TH",
                                countryName: "ไทย",
                                unstructure: true,
                            };

                            history.push({
                                pathname: documentPath,
                                state: {
                                    command: "EDIT",
                                    amountTotal: _amountTotal,
                                    company: {
                                        ...company,
                                        includeVat: includeVat,
                                    },
                                    reference: order_id,
                                    customer: _customer,
                                    date: item.date,
                                    dateBE: item.dateBE,
                                    dateCE: item.dateCE,
                                    grandTotal: _grandTotal,
                                    items: _items,
                                    number: "",
                                    percentVat: registerVat ? percentVat : 0,
                                    discount: _discount,
                                    vatTotal: _vatTotal,
                                    reIssue: false,
                                    receivedBy: "TikTok Shop",
                                    backUrl: "/etax/sources/tiktok-shop",
                                    includeVat: includeVat,
                                    note: "",
                                },
                            });
                        });

                    }

                }).catch((error) => {
                    //Acquire token silent failure, and send an interactive request
                    console.log(error);
                    instance.acquireTokenRedirect(silentRequest);
                });
        } else {
            history.push({
                pathname: documentPath,
                state: {
                    command: "EDIT",
                    amountTotal: item.amountTotal,
                    company: {
                        ...company,
                        includeVat: includeVat,
                    },
                    reference: item.orderId,
                    customer: item.customer,
                    date: item.date,
                    dateBE: item.dateBE,
                    dateCE: item.dateCE,
                    grandTotal: item.grandTotal,
                    items: item.items,
                    number: "",
                    percentVat: registerVat ? percentVat : 0,
                    discount: item.discount,
                    vatTotal: item.vatTotal,
                    reIssue: false,
                    receivedBy: "TikTok Shop",
                    backUrl: "/etax/sources/tiktok-shop",
                    includeVat: includeVat,
                    note: "",
                },
            });

        }

    };

    const columns = [
        {
            key: "column1",
            name: t("tiktokShop.createdTime"),
            fieldName: "createdTime",
            minWidth: 75,
            maxWidth: 100,
            isResizable: true,
            isSorted: primarySort === "createdTime" ? true : false,
            isSortedDescending: isSortedDescendingCreatedTime,
            isSortedAscending: !isSortedDescendingCreatedTime,
            onColumnClick: (item) => {
                console.log("Sort document createTime!");
                setPrimarySort("createdTime");
                setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

                // handleSort("createdTime", !isSortedDescendingCreatedTime);

                sessionStorage.setItem("tiktokShop-primarySort", "createdTime");
                sessionStorage.setItem("tiktokShop-sort-createdTime", !isSortedDescendingCreatedTime === false ? "false" : "true");

                sessionStorage.removeItem("tiktokShop-sort-updatedTime");
                sessionStorage.removeItem("tiktokShop-sort-number");
                sessionStorage.removeItem("tiktokShop-sort-customerName");
                sessionStorage.removeItem("tiktokShop-sort-grandTotal");

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setNextCursor("");
                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return <span>{item.createDateBE}</span>;
            },
            // isPadded: true,
        },
        {
            key: "column2",
            name: t("tiktokShop.updateTimeColumn"),
            fieldName: "updatedTime",
            minWidth: 75,
            maxWidth: 100,
            isResizable: true,
            isSorted: primarySort === "updatedTime" ? true : false,
            isSortedDescending: isSortedDescendingUpdatedTime,
            isSortedAscending: !isSortedDescendingUpdatedTime,
            onColumnClick: () => {
                console.log("Sort document updatedTime!");
                setPrimarySort("updatedTime");
                setIsSortedDescendingUpdatedTime(!isSortedDescendingUpdatedTime);

                // handleSort("updatedTime", !isSortedDescendingUpdatedTime);

                sessionStorage.setItem("tiktokShop-primarySort", "updatedTime");
                sessionStorage.setItem("tiktokShop-sort-updatedTime", !isSortedDescendingUpdatedTime === false ? "false" : "true");

                sessionStorage.removeItem("tiktokShop-sort-createdTime");
                sessionStorage.removeItem("tiktokShop-sort-number");
                sessionStorage.removeItem("tiktokShop-sort-customerName");
                sessionStorage.removeItem("tiktokShop-sort-vatTotal");
                sessionStorage.removeItem("tiktokShop-sort-buyerPayment");

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setNextCursor("");
                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return <span>{item.updatedTime}</span>;
            },
        },
        {
            key: "column3",
            name: t("tiktokShop.orderId"),
            fieldName: "number",
            minWidth: 130,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: primarySort === "number" ? true : false,
            isSortedDescending: isSortedDescendingNumber,
            isSortedAscending: !isSortedDescendingNumber,
            onColumnClick: () => {
                console.log("Sort document number!");
                setPrimarySort("number");
                setIsSortedDescendingNumber(!isSortedDescendingNumber);

                // handleSort("number", !isSortedDescendingNumber);

                sessionStorage.setItem("tiktokShop-primarySort", "number");
                sessionStorage.setItem("tiktokShop-sort-number", !isSortedDescendingNumber === false ? "false" : "true");

                sessionStorage.removeItem("tiktokShop-sort-createdTime");
                sessionStorage.removeItem("tiktokShop-sort-customerName");
                sessionStorage.removeItem("tiktokShop-sort-amountTotal");

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setNextCursor("");
                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return <span>{item.key}</span>;
            },
            // isPadded: true,
        },
        {
            key: "column4",
            name: t("tiktokShop.customerName"),
            fieldName: "customerName",
            minWidth: 100,
            maxWidth: 180,
            isResizable: true,
            isSorted: primarySort === "customerName" ? true : false,
            isSortedDescending: isSortedDescendingCustomerName,
            isSortedAscending: !isSortedDescendingCustomerName,
            onColumnClick: () => {
                console.log("Sort document customerName!");
                setPrimarySort("customerName");
                setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

                // handleSort("customerName", !isSortedDescendingCustomerName);

                sessionStorage.setItem("tiktokShop-primarySort", "customerName");
                sessionStorage.setItem("tiktokShop-sort-customerName", !isSortedDescendingCustomerName === false ? "false" : "true");

                sessionStorage.removeItem("tiktokShop-sort-createdTime");
                sessionStorage.removeItem("tiktokShop-sort-updatedTime");
                sessionStorage.removeItem("tiktokShop-sort-number");
                sessionStorage.removeItem("tiktokShop-sort-grandTotal");

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setNextCursor("");
                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return <span>{item.customerName}</span>;
            },
            // isPadded: true,
        },
        {
            key: "column5",
            name: t("tiktokShop.customerAddress"),
            fieldName: "customerAddress",
            minWidth: 120,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            onColumnClick: "",
            data: "string",
            onRender: (item) => {
                return <span>{item.customerAddress}</span>;
            },
            // isPadded: true,
        },
        {
          key: "column6",
          name: t("tiktokShop.shipmentProvider"),
          fieldName: "name",
          minWidth: 80,
          maxWidth: 100,
          isRowHeader: true,
          isResizable: true,
          isSorted: false,
          onColumnClick: "",
          data: "string",
          onRender: (item) => {
            return <TooltipHost content={item.trackingNumber}>{item.shippingProvider}</TooltipHost>;
          },
          // isPadded: true,
        },
        // {
        //   key: "column6",
        //   name: t("tiktokShop.total"),
        //   fieldName: "amountTotal",
        //   minWidth: 80,
        //   maxWidth: 100,
        //   isResizable: true,
        //   isSorted: primarySort === "amountTotal" ? true : false,
        //   isSortedDescending: isSortedDescendingAmountTotal,
        //   isSortedAscending: !isSortedDescendingAmountTotal,
        //   onColumnClick: () => {
        //     console.log("Sort document amountTotal!");
        //     setPrimarySort("amountTotal");
        //     setIsSortedDescendingAmountTotal(!isSortedDescendingAmountTotal);

        //     handleSort("amountTotal", !isSortedDescendingAmountTotal);

        //     sessionStorage.setItem("tiktokShop-primarySort", "amountTotal");
        //     sessionStorage.setItem("tiktokShop-sort-amountTotal", !isSortedDescendingAmountTotal === false ? "false" : "true");

        //     sessionStorage.removeItem("tiktokShop-sort-number");
        //     sessionStorage.removeItem("tiktokShop-sort-customerName");
        //     sessionStorage.removeItem("tiktokShop-sort-createdTime");

        //     setIsCreateDocumentComplete(false);
        //     setSelectedItems([]);
        //   },
        //   data: "string",
        //   onRender: (item) => {
        //     return (
        //       <span style={{ display: "block", textAlign: "right" }}>
        //         {item.amountTotal?.toLocaleString(undefined, {
        //           maximumFractionDigits: 2,
        //           minimumFractionDigits: 2,
        //         })}
        //       </span>
        //     );
        //   },
        //   // isPadded: true,
        // },
        {
            key: "column7",
            name: registerVat ? t("tiktokShop.grandTotal") : t("tiktokShop.total"),
            fieldName: "grandTotal",
            minWidth: 70,
            maxWidth: 100,
            isResizable: true,
            isSorted: primarySort === "grandTotal" ? true : false,
            isSortedDescending: isSortedDescendingGrandTotal,
            isSortedAscending: !isSortedDescendingGrandTotal,
            onColumnClick: () => {
                console.log("Sort document grandTotal!");
                setPrimarySort("grandTotal");
                setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

                // handleSort("grandTotal", !isSortedDescendingGrandTotal);

                sessionStorage.setItem("tiktokShop-primarySort", "grandTotal");
                sessionStorage.setItem("tiktokShop-sort-grandTotal", !isSortedDescendingGrandTotal === false ? "false" : "true");

                sessionStorage.removeItem("tiktokShop-sort-createdTime");
                sessionStorage.removeItem("tiktokShop-sort-updatedTime");
                sessionStorage.removeItem("tiktokShop-sort-number");
                sessionStorage.removeItem("tiktokShop-sort-customerName");

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setNextCursor("");
                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return (
                    <span style={{ display: "block", textAlign: "right" }}>
                        {Number(item.grandTotal).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                    </span>
                );
            },
            isPadded: true,
        },
        {
            key: "column8",
            name: "Leceipt",
            fieldName: "etaxCreated",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                if (item.etaxCreated) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Icon iconName="SkypeCircleCheck" style={{ fontSize: "20px", color: "green" }} />
                        </div>
                    );
                }
            },
        },
        {
            key: "column9",
            name: "",
            fieldName: "command",
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            data: "string",
            onColumnClick: "",
            onRender: (item) => {
                if (isCreateDocument || isCreateDocumentComplete) {
                    if (item.status === "pending") {
                        return <span style={{ color: "#106EBE" }}>{t("tiktokShop.pending")}</span>;
                    } else if (item.status === "processing") {
                        return <Spinner label={t("tiktokShop.processing")} labelPosition="right" style={{ marginLeft: "-35px" }} />;
                    } else if (item.status === "successful") {
                        return <span style={{ color: "green" }}>{t("tiktokShop.success")}</span>;
                    } else if (item.status === "fail") {
                        return <span style={{ color: "red" }}>{t("tiktokShop.fail")}</span>;
                    }
                } else {
                    return (
                        <span data-selection-disabled={true}>
                            <DefaultButton
                                text={t("tiktokShop.create")}
                                iconProps={{ iconName: "Add", style: { color: "#106ebe" } }}
                                menuProps={{
                                    items: [
                                        {
                                            key: "RECEIPT-TAXINVOICE",
                                            text: t("tiktokShop.receipt-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("RECEIPT-TAXINVOICE", item);
                                            },
                                        },
                                        {
                                            key: "RECEIPT-TAXINVOICE-ABB",
                                            text: t("tiktokShop.receipt-taxinvoice-abb"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("RECEIPT-TAXINVOICE-ABB", item);
                                            },
                                        },
                                        {
                                            key: "RECEIPT",
                                            text: t("tiktokShop.receipt"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("RECEIPT", item);
                                            },
                                        },
                                        {
                                            key: "INVOICE-TAXINVOICE",
                                            text: t("tiktokShop.invoice-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("INVOICE-TAXINVOICE", item);
                                            },
                                        },
                                        {
                                            key: "DELIVERYORDER-TAXINVOICE",
                                            text: t("tiktokShop.deliveryorder-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("DELIVERYORDER-TAXINVOICE", item);
                                            },
                                        },
                                        {
                                            key: "TAXINVOICE",
                                            text: t("tiktokShop.taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("TAXINVOICE", item);
                                            },
                                        },
                                    ],
                                    shouldFocusOnMount: true,
                                    directionalHintFixed: true,
                                }}
                                // Optional callback to customize menu rendering
                                menuAs={(props) => <ContextualMenu {...props} />}
                            />
                        </span>
                    );
                }
            },
        },
    ];

    const columnsSettlement = [
        {
            key: "column1",
            name: t("tiktokShop.settlementDate"),
            fieldName: "createdTime",
            minWidth: 75,
            maxWidth: 100,
            isResizable: true,
            isSorted: primarySort === "createdTime" ? true : false,
            isSortedDescending: isSortedDescendingCreatedTime,
            isSortedAscending: !isSortedDescendingCreatedTime,
            onColumnClick: (item) => {
                console.log("Sort document createTime!");
                setPrimarySort("createdTime");
                setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

                // handleSort("createdTime", !isSortedDescendingCreatedTime);

                sessionStorage.setItem("tiktokShop-primarySort", "createdTime");
                sessionStorage.setItem("tiktokShop-sort-createdTime", !isSortedDescendingCreatedTime === false ? "false" : "true");

                sessionStorage.removeItem("tiktokShop-sort-updatedTime");
                sessionStorage.removeItem("tiktokShop-sort-number");
                sessionStorage.removeItem("tiktokShop-sort-customerName");
                sessionStorage.removeItem("tiktokShop-sort-grandTotal");

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setNextCursor("");
                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return <span>{item.dateBE}</span>;
            },
            // isPadded: true,
        },
        {
            key: "column3",
            name: t("tiktokShop.orderId"),
            fieldName: "number",
            minWidth: 130,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: primarySort === "number" ? true : false,
            isSortedDescending: isSortedDescendingNumber,
            isSortedAscending: !isSortedDescendingNumber,
            onColumnClick: () => {
                console.log("Sort document number!");
                setPrimarySort("number");
                setIsSortedDescendingNumber(!isSortedDescendingNumber);

                // handleSort("number", !isSortedDescendingNumber);

                sessionStorage.setItem("tiktokShop-primarySort", "number");
                sessionStorage.setItem("tiktokShop-sort-number", !isSortedDescendingNumber === false ? "false" : "true");

                sessionStorage.removeItem("tiktokShop-sort-createdTime");
                sessionStorage.removeItem("tiktokShop-sort-updatedTime");
                sessionStorage.removeItem("tiktokShop-sort-customerName");
                sessionStorage.removeItem("tiktokShop-sort-amountTotal");

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setNextCursor("");
                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return <span>{item.key}</span>;
            },
            // isPadded: true,
        },

        {
            key: "column4",
            name: t("tiktokShop.settlement"),
            fieldName: "settlement",
            minWidth: 110,
            maxWidth: 110,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return (
                    <span style={{ display: "block", textAlign: "right" }}>
                        {Number(item.settlement).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                    </span>
                );
            },
            isPadded: true,
        },
        {
            key: "column5",
            name: t("tiktokShop.transactionFee"),
            fieldName: "transactionFee",
            minWidth: 110,
            maxWidth: 110,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return <span style={{ display: "block", textAlign: "right" }}>
                    {Number(item.transactionFee).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}</span>;
            },
            // isPadded: true,
        },
        {
            key: "column6",
            name: t("tiktokShop.commission"),
            fieldName: "commission",
            minWidth: 110,
            maxWidth: 110,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                return (
                    <span style={{ display: "block", textAlign: "right" }}>
                        {Number(item.commission).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                    </span>
                );
            },
            isPadded: true,
        },
        {
            key: "column7",
            name: t("tiktokShop.subTotal"),
            fieldName: "grandTotal",
            minWidth: 110,
            maxWidth: 110,
            isResizable: true,
            isSorted: primarySort === "grandTotal" ? true : false,
            isSortedDescending: isSortedDescendingGrandTotal,
            isSortedAscending: !isSortedDescendingGrandTotal,
            onColumnClick: () => {
                console.log("Sort document grandTotal!");
                setPrimarySort("grandTotal");
                setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

                // handleSort("grandTotal", !isSortedDescendingGrandTotal);

                sessionStorage.setItem("tiktokShop-primarySort", "grandTotal");
                sessionStorage.setItem("tiktokShop-sort-grandTotal", !isSortedDescendingGrandTotal === false ? "false" : "true");

                sessionStorage.removeItem("tiktokShop-sort-createdTime");
                sessionStorage.removeItem("tiktokShop-sort-updatedTime");
                sessionStorage.removeItem("tiktokShop-sort-number");
                sessionStorage.removeItem("tiktokShop-sort-customerName");

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setNextCursor("");
                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
            data: "string",
            onRender: (item) => {
                return (
                    <span style={{ display: "block", textAlign: "right" }}>
                        {Number(item.grandTotal).toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                    </span>
                );
            },
            isPadded: true,
        },
        {
            key: "column8",
            name: "Leceipt",
            fieldName: "etaxCreated",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            data: "string",
            onRender: (item) => {
                if (item.etaxCreated) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Icon iconName="SkypeCircleCheck" style={{ fontSize: "20px", color: "green" }} />
                        </div>
                    );
                }
            },
        },
        {
            key: "column9",
            name: "",
            fieldName: "command",
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            data: "string",
            onColumnClick: "",
            onRender: (item) => {
                if (isCreateDocument || isCreateDocumentComplete) {
                    if (item.status === "pending") {
                        return <span style={{ color: "#106EBE" }}>{t("tiktokShop.pending")}</span>;
                    } else if (item.status === "processing") {
                        return <Spinner label={t("tiktokShop.processing")} labelPosition="right" style={{ marginLeft: "-35px" }} />;
                    } else if (item.status === "successful") {
                        return <span style={{ color: "green" }}>{t("tiktokShop.success")}</span>;
                    } else if (item.status === "fail") {
                        return <span style={{ color: "red" }}>{t("tiktokShop.fail")}</span>;
                    }
                } else {
                    return (
                        <span data-selection-disabled={true}>
                            <DefaultButton
                                text={t("tiktokShop.create")}
                                iconProps={{ iconName: "Add", style: { color: "#106ebe" } }}
                                menuProps={{
                                    items: [
                                        {
                                            key: "RECEIPT-TAXINVOICE",
                                            text: t("tiktokShop.receipt-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("RECEIPT-TAXINVOICE", item);
                                            },
                                        },
                                        {
                                            key: "RECEIPT-TAXINVOICE-ABB",
                                            text: t("tiktokShop.receipt-taxinvoice-abb"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("RECEIPT-TAXINVOICE-ABB", item);
                                            },
                                        },
                                        {
                                            key: "RECEIPT",
                                            text: t("tiktokShop.receipt"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("RECEIPT", item);
                                            },
                                        },
                                        {
                                            key: "INVOICE-TAXINVOICE",
                                            text: t("tiktokShop.invoice-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("INVOICE-TAXINVOICE", item);
                                            },
                                        },
                                        {
                                            key: "DELIVERYORDER-TAXINVOICE",
                                            text: t("tiktokShop.deliveryorder-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("DELIVERYORDER-TAXINVOICE", item);
                                            },
                                        },
                                        {
                                            key: "TAXINVOICE",
                                            text: t("tiktokShop.taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: () => {
                                                createDocument("TAXINVOICE", item);
                                            },
                                        },
                                    ],
                                    shouldFocusOnMount: true,
                                    directionalHintFixed: true,
                                }}
                                // Optional callback to customize menu rendering
                                menuAs={(props) => <ContextualMenu {...props} />}
                            />
                        </span>
                    );
                }
            },
        },
    ];

    const timestamp_toDate = (timestamp) => {
        let date = new Date(timestamp * 1000);

        let month = "" + (date.getMonth() + 1);
        let day = "" + date.getDate();
        let year = date.getFullYear() + 543;

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [day, month, year].join("/");
    };

    const getMonth = (goBack) => {
        let monthNames = [
            t("tiktokShop.months01"),
            t("tiktokShop.months02"),
            t("tiktokShop.months03"),
            t("tiktokShop.months04"),
            t("tiktokShop.months05"),
            t("tiktokShop.months06"),
            t("tiktokShop.months07"),
            t("tiktokShop.months08"),
            t("tiktokShop.months09"),
            t("tiktokShop.months10"),
            t("tiktokShop.months11"),
            t("tiktokShop.months12"),
        ];

        let d = new Date();

        let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

        let year = adjustDate.getFullYear() + 543;

        return {
            month: adjustDate.getMonth(),
            year: adjustDate.getFullYear(),
            key: adjustDate.getMonth() + 1,
            text: monthNames[adjustDate.getMonth()] + " " + year,
        };
    };

    const [queryMonth, setQueryMonth] = useState(
        (sessionStorage.getItem("tiktokShop-queryMonth-key") && {
            key: sessionStorage.getItem("tiktokShop-queryMonth-key"),
            text: sessionStorage.getItem("tiktokShop-queryMonth-text"),
            ...(sessionStorage.getItem("tiktokShop-queryMonth-type") ? { type: sessionStorage.getItem("tiktokShop-queryMonth-type") } : {}),
        }) || { key: getMonth(0).year + "" + getMonth(0).key, text: t("tiktokShop.thisMonth") }
    );

    const [timeRangeDate, setTimeRangeDate] = useState(sessionStorage.getItem("tiktokShop-queryMonth-type") ? true : false);
    const [fromDate, setFromDate] = useState(sessionStorage.getItem("tiktokShop-fromDate") ? new Date(sessionStorage.getItem("tiktokShop-fromDate")) : null);
    const [toDate, setToDate] = useState(sessionStorage.getItem("tiktokShop-toDate") ? new Date(sessionStorage.getItem("tiktokShop-toDate")) : null);
    const [timeRangeError, setTimeRangeError] = useState(false);

    const getFrom = (month, year) => {
        let _firstDay = new Date(year, month, 1);

        return formatDate(_firstDay);
    };

    const getTo = (month, year) => {
        let _lastDay = new Date(year, month + 1, 1);

        return formatDate(_lastDay);
    };

    const getFromDMY = (day, month, year) => {
        let _firstDay = new Date(year, month, day);

        return formatDate(_firstDay);
    };

    const getToDMY = (day, month, year) => {
        let _lastDay = new Date(year, month, day + 1);

        return formatDate(_lastDay);
    };

    const getFromTimestampTiktokShopMY = (month, year) => {
        let _firstDay = new Date(year, month, 1);

        return Math.floor(_firstDay / 1000);
    };

    const getToTimestampTiktokShopMY = (month, year) => {
        let _lastDay = new Date(year, month + 1, 1);

        return Math.floor(_lastDay / 1000);
    };

    const getFromTimestampTiktokShopDMY = (day, month, year) => {
        let _firstDay = new Date(year, month, day);

        return Math.floor(_firstDay / 1000);
    };

    const getToTimestampTiktokShopDMY = (day, month, year) => {
        let _lastDay = new Date(year, month, day + 1);

        return Math.floor(_lastDay / 1000);
    };

    const formatDate = (d) => {
        let month = "" + (d.getMonth() + 1);
        let day = "" + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    };

    const [date, setDate] = useState(sessionStorage.getItem("tiktokShop-date") ? new Date(sessionStorage.getItem("tiktokShop-date")) : new Date());

    const [from, setFrom] = useState(sessionStorage.getItem("tiktokShop-from") || getFrom(getMonth(0).month, getMonth(0).year));
    const [to, setTo] = useState(sessionStorage.getItem("tiktokShop-to") || getTo(getMonth(0).month, getMonth(0).year));

    const [fromTimeStampTiktokShop, setFromTimeStampTiktokShop] = useState(sessionStorage.getItem("tiktokShop-from2") || getFromTimestampTiktokShopMY(getMonth(0).month, getMonth(0).year));
    const [toTimeStampTiktokShop, setToTimeStampTiktokShop] = useState(sessionStorage.getItem("tiktokShop-to2") || getToTimestampTiktokShopMY(getMonth(0).month, getMonth(0).year));

    const history = useHistory();

    const [shopList, setShopList] = useState([]);

    const [shop, setShop] = useState(
        sessionStorage.getItem("tiktokShop-shop-key") ? 
        { 
            key: sessionStorage.getItem("tiktokShop-shop-key"), 
            text: sessionStorage.getItem("tiktokShop-shop-text")
        } : 
        { 
            key: '', 
            text: t('tiktokShop.loading') 
        }
    );

    function getShopListItems() {
        let array = [];
    
        if (shopList !== undefined) {
          shopList.forEach((shop, index) => {
            array.push({
              key: shop.shopId,
              text: shop.shopName,
              iconProps: { iconName: 'CityNext2' },
              onClick: () => {
                setShop({
                  key: shop.shopId,
                  text: shop.shopName,
                });
    
                sessionStorage.setItem('tiktokShop-shop-key', shop.shopId);
                sessionStorage.setItem('tiktokShop-shop-text', shop.shopName);
    
                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setNextCursor("");
                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
              },
            });
          });
    
          array.push({
            key: array.length + 1,
            text: t('tiktokShop.anotherOrganization'),
            iconProps: { iconName: 'PlugConnected' },
            href: authorizationUrl,
          });
        }
    
        return array;
      }

    function getDateText(monthObject) {
        if (monthObject.type === "dateRange") {
            if (i18n.language === "th") {
                return onFormatDate(fromDate, "BE") + " - " + onFormatDate(toDate, "BE");
            }
            return onFormatDate(fromDate, "CE") + " - " + onFormatDate(toDate, "CE");
        }

        if (monthObject.text === "เดือนนี้" || monthObject.text === "This Month") return t("tiktokShop.thisMonth");

        let monthNames = [
            t("tiktokShop.months01"),
            t("tiktokShop.months02"),
            t("tiktokShop.months03"),
            t("tiktokShop.months04"),
            t("tiktokShop.months05"),
            t("tiktokShop.months06"),
            t("tiktokShop.months07"),
            t("tiktokShop.months08"),
            t("tiktokShop.months09"),
            t("tiktokShop.months10"),
            t("tiktokShop.months11"),
            t("tiktokShop.months12"),
        ];

        let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
        let _year = Number(monthObject.key.substring(0, 4));
        if (i18n.language === "th") {
            _year = _year + 543;
        }

        return monthNames[_monthIndex] + " " + _year;
    }

    function getOrderStatusText(key) {
        if (key === "all") return t("tiktokShop.allType");
        if (key === 130) return t("tiktokShop.completeStatus");
        if (key === 122) return t("tiktokShop.deliveredStatus");
        if (key === 121) return t("tiktokShop.inTransitStatus");
        if (key === 114) return t("tiktokShop.partiallyShippingStatus");
        if (key === 112) return t("tiktokShop.awaitingCollectionStatus");
        if (key === 111) return t("tiktokShop.awaitingShipmentStatus");
        if (key === 100) return t("tiktokShop.unpaidStatus");
        if (key === 140) return t("tiktokShop.canceledStatus");
    }

    const commandBarItems = [
        {
            key: shop.key,
            text: shop.text,
            disabled: !loadDataComplete || isCreateDocument || search,
            iconProps: { iconName: 'CityNext2' },
            subMenuProps: {
              items: getShopListItems(),
            },
        },
        {
            key: endpointType.key,
            text: endpointType.text,
            cacheKey: "myCacheKey", // changing this key will invalidate this item"s cache
            iconProps: { iconName: "Page" },
            disabled: !loadDataComplete || isCreateDocument,
            subMenuProps: {
              items: [
                {
                  key: "settlements",
                  text: "Settlements",
                  iconProps: { iconName: "Page" },
                  onClick: () => {
                    console.log("เอกสารทุกประเภท");
                    setEndpointType({ key: "settlements", text: "Settlements" });
      
                    sessionStorage.setItem("tiktokShop-endpointType-key", "settlements");
                    sessionStorage.setItem("tiktokShop-endpointType-text", "Settlements");
      
                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);

                    setNextCursor("");
                    setIsCreateDocumentComplete(false);
                    setSelectedItems([]);
                  },
                },
                {
                  key: "orders",
                  text: "Orders",
                  iconProps: { iconName: "Page" },
                  onClick: () => {
                    console.log("เอกสารทุกประเภท");
                    setEndpointType({ key: "orders", text: "Orders" });
      
                    sessionStorage.setItem("tiktokShop-endpointType-key", "orders");
                    sessionStorage.setItem("tiktokShop-endpointType-text", "Orders");
      
                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);

                    setNextCursor("");
                    setIsCreateDocumentComplete(false);
                    setSelectedItems([]);
                  },
                },
              ],
            },
          },
          ...(endpointType.key === 'settlements'
            ? [
                // {
                //     key: sortType.key,
                //     // text: sortType.text,
                //     text: sortType.key === 1 ? t("tiktokShop.sortType1") : t("tiktokShop.sortType0"),
                //     cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
                //     iconProps: { iconName: "CalendarSettings" },
                //     disabled: !loadDataComplete || isCreateDocument || search,
                //     subMenuProps: {
                //         items: [
                //             {
                //                 key: 1,
                //                 text: t("tiktokShop.sortType1"),
                //                 iconProps: { iconName: "CalendarSettings" },
                //                 onClick: () => {
                //                     setSortType({
                //                         key: 1,
                //                         text: t("tiktokShop.sortType1"),
                //                     });
        
                //                     sessionStorage.setItem("tiktokShop-sortType-key", 1);
                //                     sessionStorage.setItem("tiktokShop-sortType-text", t("tiktokShop.sortType1"));
        
                //                     setLoadDataComplete(false);
                //                     setItems([]);
                //                     setGetNewDocuments(true);
        
                //                     setNextCursor("");
                //                     setIsCreateDocumentComplete(false);
                //                     setSelectedItems([]);
                //                 },
                //             },
                //             {
                //                 key: 0,
                //                 text: t("tiktokShop.sortType0"),
                //                 iconProps: { iconName: "CalendarSettings" },
                //                 onClick: () => {
                //                     setSortType({
                //                         key: 0,
                //                         text: t("tiktokShop.sortType0"),
                //                     });
        
                //                     sessionStorage.setItem("tiktokShop-sortType-key", 0);
                //                     sessionStorage.setItem("tiktokShop-sortType-text", t("tiktokShop.sortType0"));
        
                //                     setLoadDataComplete(false);
                //                     setItems([]);
                //                     setGetNewDocuments(true);
        
                //                     setNextCursor("");
                //                     setIsCreateDocumentComplete(false);
                //                     setSelectedItems([]);
                //                 },
                //             },
                //         ],
                //     },
                // }
            ]
            : [
                {
                    key: orderStatus.key,
                    text: getOrderStatusText(orderStatus.key),
                    cacheKey: "myCacheKey", // changing this key will invalidate this item"s cache
                    iconProps: { iconName: "ProductList" },
                    disabled: !loadDataComplete || isCreateDocument || search,
                    subMenuProps: {
                        items: [
                            {
                                key: "all",
                                text: t("tiktokShop.allType"),
                                iconProps: { iconName: "ProductList" },
                                onClick: () => {
                                    setOrderStatus({ key: "all", text: t("tiktokShop.allType") });
        
                                    sessionStorage.setItem("tiktokShop-orderStatus-key", "all");
                                    sessionStorage.setItem("tiktokShop-orderStatus-text", t("tiktokShop.allType"));
        
                                    setLoadDataComplete(false);
                                    setItems([]);
                                    setGetNewDocuments(true);
        
                                    setNextCursor("");
                                    setIsCreateDocumentComplete(false);
                                    setSelectedItems([]);
                                },
                            },
                            {
                                key: 130,
                                text: t("tiktokShop.COMPLETED"),
                                iconProps: { iconName: "ProductList" },
                                onClick: () => {
                                    setOrderStatus({
                                        key: 130,
                                        text: t("tiktokShop.COMPLETED"),
                                    });
        
                                    sessionStorage.setItem("tiktokShop-orderStatus-key", 130);
                                    sessionStorage.setItem("tiktokShop-orderStatus-text", t("tiktokShop.COMPLETED"));
        
                                    setLoadDataComplete(false);
                                    setItems([]);
                                    setGetNewDocuments(true);
        
                                    setNextCursor("");
                                    setIsCreateDocumentComplete(false);
                                    setSelectedItems([]);
                                },
                            },
                            {
                                key: 122,
                                text: t("tiktokShop.deliveredStatus"),
                                iconProps: { iconName: "ProductList" },
                                onClick: () => {
                                    setOrderStatus({
                                        key: 122,
                                        text: t("tiktokShop.deliveredStatus"),
                                    });
        
                                    sessionStorage.setItem("tiktokShop-orderStatus-key", 122);
                                    sessionStorage.setItem("tiktokShop-orderStatus-text", t("tiktokShop.deliveredStatus"));
        
                                    setLoadDataComplete(false);
                                    setItems([]);
                                    setGetNewDocuments(true);
        
                                    setNextCursor("");
                                    setIsCreateDocumentComplete(false);
                                    setSelectedItems([]);
                                },
                            },
                            {
                                key: 121,
                                text: t("tiktokShop.inTransitStatus"),
                                iconProps: { iconName: "ProductList" },
                                onClick: () => {
                                    setOrderStatus({
                                        key: 121,
                                        text: t("tiktokShop.inTransitStatus"),
                                    });
        
                                    sessionStorage.setItem("tiktokShop-orderStatus-key", 121);
                                    sessionStorage.setItem("tiktokShop-orderStatus-text", t("tiktokShop.inTransitStatus"));
        
                                    setLoadDataComplete(false);
                                    setItems([]);
                                    setGetNewDocuments(true);
        
                                    setNextCursor("");
                                    setIsCreateDocumentComplete(false);
                                    setSelectedItems([]);
                                },
                            },
                            {
                                key: 114,
                                text: t("tiktokShop.partiallyShippingStatus"),
                                iconProps: { iconName: "ProductList" },
                                onClick: () => {
                                    setOrderStatus({
                                        key: 114,
                                        text: t("tiktokShop.partiallyShippingStatus"),
                                    });
        
                                    sessionStorage.setItem("tiktokShop-orderStatus-key", 114);
                                    sessionStorage.setItem("tiktokShop-orderStatus-text", t("tiktokShop.partiallyShippingStatus"));
        
                                    setLoadDataComplete(false);
                                    setItems([]);
                                    setGetNewDocuments(true);
        
                                    setNextCursor("");
                                    setIsCreateDocumentComplete(false);
                                    setSelectedItems([]);
                                },
                            },
                            {
                                key: 112,
                                text: t("tiktokShop.awaitingCollectionStatus"),
                                iconProps: { iconName: "ProductList" },
                                onClick: () => {
                                    setOrderStatus({
                                        key: 112,
                                        text: t("tiktokShop.awaitingCollectionStatus"),
                                    });
        
                                    sessionStorage.setItem("tiktokShop-orderStatus-key", 112);
                                    sessionStorage.setItem("tiktokShop-orderStatus-text", t("tiktokShop.awaitingCollectionStatus"));
        
                                    setLoadDataComplete(false);
                                    setItems([]);
                                    setGetNewDocuments(true);
        
                                    setNextCursor("");
                                    setIsCreateDocumentComplete(false);
                                    setSelectedItems([]);
                                },
                            },
                            {
                                key: 111,
                                text: t("tiktokShop.awaitingShipmentStatus"),
                                iconProps: { iconName: "ProductList" },
                                onClick: () => {
                                    setOrderStatus({
                                        key: 111,
                                        text: t("tiktokShop.awaitingShipmentStatus"),
                                    });
        
                                    sessionStorage.setItem("tiktokShop-orderStatus-key", 111);
                                    sessionStorage.setItem("tiktokShop-orderStatus-text", t("tiktokShop.awaitingShipmentStatus"));
        
                                    setLoadDataComplete(false);
                                    setItems([]);
                                    setGetNewDocuments(true);
        
                                    setNextCursor("");
                                    setIsCreateDocumentComplete(false);
                                    setSelectedItems([]);
                                },
                            },
                            {
                                key: 100,
                                text: t("tiktokShop.unpaidStatus"),
                                iconProps: { iconName: "ProductList" },
                                onClick: () => {
                                    setOrderStatus({
                                        key: 100,
                                        text: t("tiktokShop.unpaidStatus"),
                                    });
        
                                    sessionStorage.setItem("tiktokShop-orderStatus-key", 100);
                                    sessionStorage.setItem("tiktokShop-orderStatus-text", t("tiktokShop.unpaidStatus"));
        
                                    setLoadDataComplete(false);
                                    setItems([]);
                                    setGetNewDocuments(true);
        
                                    setNextCursor("");
                                    setIsCreateDocumentComplete(false);
                                    setSelectedItems([]);
                                },
                            },
                            {
                                key: 140,
                                text: t("tiktokShop.canceledStatus"),
                                iconProps: { iconName: "ProductList" },
                                onClick: () => {
                                    setOrderStatus({
                                        key: 140,
                                        text: t("tiktokShop.canceledStatus"),
                                    });
        
                                    sessionStorage.setItem("tiktokShop-orderStatus-key", 140);
                                    sessionStorage.setItem("tiktokShop-orderStatus-text", t("tiktokShop.canceledStatus"));
        
                                    setLoadDataComplete(false);
                                    setItems([]);
                                    setGetNewDocuments(true);
        
                                    setNextCursor("");
                                    setIsCreateDocumentComplete(false);
                                    setSelectedItems([]);
                                },
                            },
                        ],
                    },
                },
                {
                    key: timeRangeField.key,
                    // text: timeRangeField.text,
                    text: timeRangeField.key === "UPDATE_TIME" ? t("tiktokShop.updateTime") : timeRangeField.key === "CREATE_TIME" ? t("tiktokShop.createTime") : timeRangeField.text,
                    cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
                    iconProps: { iconName: "CalendarSettings" },
                    disabled: !loadDataComplete || isCreateDocument || search,
                    subMenuProps: {
                        items: [
                            {
                                key: "UPDATE_TIME",
                                text: t("tiktokShop.updateTime"),
                                iconProps: { iconName: "CalendarSettings" },
                                onClick: () => {
                                    console.log("วันที่อัปเดตสถานะ");
        
                                    setTimeRangeField({
                                        key: "UPDATE_TIME",
                                        text: t("tiktokShop.updateTime"),
                                    });
        
                                    sessionStorage.setItem("tiktokShop-timeRangeField-key", "UPDATE_TIME");
                                    sessionStorage.setItem("tiktokShop-timeRangeField-text", t("tiktokShop.updateTime"));
        
                                    setLoadDataComplete(false);
                                    setItems([]);
                                    setGetNewDocuments(true);
        
                                    setNextCursor("");
                                    setIsCreateDocumentComplete(false);
                                    setSelectedItems([]);
                                },
                            },
                            {
                                key: "CREATE_TIME",
                                text: t("tiktokShop.createTime"),
                                iconProps: { iconName: "CalendarSettings" },
                                onClick: () => {
                                    console.log("วันที่สร้างเอกสาร");
                                    setTimeRangeField({
                                        key: "CREATE_TIME",
                                        text: t("tiktokShop.createTime"),
                                    });
        
                                    sessionStorage.setItem("tiktokShop-timeRangeField-key", "CREATE_TIME");
                                    sessionStorage.setItem("tiktokShop-timeRangeField-text", t("tiktokShop.createTime"));
        
                                    setLoadDataComplete(false);
                                    setItems([]);
                                    setGetNewDocuments(true);
        
                                    setNextCursor("");
                                    setIsCreateDocumentComplete(false);
                                    setSelectedItems([]);
                                },
                            },
                        ],
                    },
                }
            ]),
        
        {
            key: queryMonth.key,
            text: getDateText(queryMonth),
            iconProps: { iconName: "Calendar" },
            disabled: !loadDataComplete || isCreateDocument || search,
            subMenuProps: {
                items: [
                    {
                        key: "queryMonth",
                    },
                ],
                onRenderMenuList: () => {
                    return (
                        <div style={{ width: 220 }}>
                            <Stack styles={{ root: { padding: "12px 12px 0px 12px" } }}>
                                <Checkbox
                                    label={t("sellSummary.timeRange")}
                                    checked={timeRangeDate}
                                    onChange={(e) => {
                                        setTimeRangeDate((prev) => !prev);
                                    }}
                                />
                            </Stack>
                            {timeRangeDate ? (
                                <Stack>
                                    <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                                        <Stack styles={{ root: { width: "20%", justifyContent: "center", alignItems: "center" } }}>
                                            <Text>{t("sellSummary.fromRange")}</Text>
                                        </Stack>
                                        <Stack styles={{ root: { width: "80%", justifyContent: "center" /* alignItems: "center" */ } }}>
                                            <DatePicker
                                                styles={{ root: { width: "90%" } }}
                                                placeholder={t("deliveryOrderTaxInvoice.pleaseSelect")}
                                                strings={DayPickerStrings}
                                                value={fromDate}
                                                maxDate={toDate}
                                                formatDate={onFormatDate}
                                                onSelectDate={(_date) => {
                                                    setFromDate(_date);

                                                    setTimeRangeError(false);
                                                    if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                    <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                                        <Stack styles={{ root: { width: "20%", justifyContent: "center", alignItems: "center" } }}>
                                            <Text>{t("sellSummary.toRange")}</Text>
                                        </Stack>
                                        <Stack styles={{ root: { width: "80%" /* alignItems: "center" */ } }}>
                                            <DatePicker
                                                styles={{ root: { width: "90%" } }}
                                                placeholder={t("deliveryOrderTaxInvoice.pleaseSelect")}
                                                strings={DayPickerStrings}
                                                value={toDate}
                                                minDate={fromDate}
                                                formatDate={onFormatDate}
                                                onSelectDate={(_date) => {
                                                    setToDate(_date);

                                                    setTimeRangeError(false);
                                                    if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                    {timeRangeError ? (
                                        <Stack styles={{ root: { padding: "12px 0px 0px 12px" } }}>
                                            <Text styles={{ root: { fontSize: 12, color: "#A4262C" } }}>{t("sellSummary.timeRangeError")}</Text>
                                        </Stack>
                                    ) : null}
                                    <Stack horizontal horizontalAlign="end" styles={{ root: { width: "92%", height: 52, padding: "10px 0px 10px 0px" } }}>
                                        <PrimaryButton
                                            text={t("sellSummary.search")}
                                            onClick={() => {
                                                if (!timeRangeError && fromDate && toDate) {
                                                    setQueryMonth({
                                                        type: "dateRange",
                                                        key: formatDate(fromDate) + ":" + formatDate(toDate),
                                                        text: formatDate(fromDate) + " - " + formatDate(toDate),
                                                    });

                                                    sessionStorage.setItem("tiktokShop-queryMonth-type", "dateRange");
                                                    sessionStorage.setItem("tiktokShop-queryMonth-key", formatDate(fromDate) + ":" + formatDate(toDate));
                                                    sessionStorage.setItem("tiktokShop-queryMonth-text", formatDate(fromDate) + " - " + formatDate(toDate));

                                                    setFrom(getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                                                    setTo(getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                                                    sessionStorage.setItem("tiktokShop-from", getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                                                    sessionStorage.setItem("tiktokShop-to", getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                                                    setFromTimeStampTiktokShop(getFromTimestampTiktokShopDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                                                    setToTimeStampTiktokShop(getToTimestampTiktokShopDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                                                    sessionStorage.setItem("tiktokShop-from2", getFromTimestampTiktokShopDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                                                    sessionStorage.setItem("tiktokShop-to2", getToTimestampTiktokShopDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                                                    sessionStorage.setItem("tiktokShop-fromDate", fromDate.toISOString());
                                                    sessionStorage.setItem("tiktokShop-toDate", toDate.toISOString());

                                                    setSearch("");

                                                    setLoadDataComplete(false);
                                                    setItems([]);
                                                    setGetNewDocuments(true);

                                                    setNextCursor("");
                                                    setIsCreateDocumentComplete(false);
                                                    setSelectedItems([]);
                                                }
                                            }}
                                            style={{ width: "125px", letterSpacing: "1px" }}
                                        />
                                    </Stack>
                                </Stack>
                            ) : (
                                <Calendar
                                    onSelectDate={(_date) => {
                                        let monthNames = [
                                            t("tiktokShop.months01"),
                                            t("tiktokShop.months02"),
                                            t("tiktokShop.months03"),
                                            t("tiktokShop.months04"),
                                            t("tiktokShop.months05"),
                                            t("tiktokShop.months06"),
                                            t("tiktokShop.months07"),
                                            t("tiktokShop.months08"),
                                            t("tiktokShop.months09"),
                                            t("tiktokShop.months10"),
                                            t("tiktokShop.months11"),
                                            t("tiktokShop.months12"),
                                        ];

                                        setDate(_date);

                                        sessionStorage.setItem("tiktokShop-date", _date.toISOString());

                                        let adjustDate = _date;

                                        let year = adjustDate.getFullYear() + 543;

                                        setQueryMonth({
                                            key: adjustDate.getFullYear() + "" + String(adjustDate.getMonth() + 1).padStart(2, "0"),
                                            text: monthNames[adjustDate.getMonth()] + " " + year,
                                        });

                                        sessionStorage.setItem("tiktokShop-queryMonth-type", "");
                                        sessionStorage.setItem("tiktokShop-queryMonth-key", adjustDate.getFullYear() + "" + String(adjustDate.getMonth() + 1).padStart(2, "0"));
                                        sessionStorage.setItem("tiktokShop-queryMonth-text", monthNames[adjustDate.getMonth()] + " " + year);

                                        setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                                        setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                                        sessionStorage.setItem("tiktokShop-from", getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                                        sessionStorage.setItem("tiktokShop-to", getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                                        setFromTimeStampTiktokShop(getFromTimestampTiktokShopMY(adjustDate.getMonth(), adjustDate.getFullYear()));
                                        setToTimeStampTiktokShop(getToTimestampTiktokShopMY(adjustDate.getMonth(), adjustDate.getFullYear()));

                                        sessionStorage.setItem("tiktokShop-from2", getFromTimestampTiktokShopMY(adjustDate.getMonth(), adjustDate.getFullYear()));
                                        sessionStorage.setItem("tiktokShop-to2", getToTimestampTiktokShopMY(adjustDate.getMonth(), adjustDate.getFullYear()));

                                        setSearch("");

                                        setLoadDataComplete(false);
                                        setItems([]);
                                        setGetNewDocuments(true);

                                        setNextCursor("");
                                        setIsCreateDocumentComplete(false);
                                        setSelectedItems([]);
                                    }}
                                    isMonthPickerVisible={true}
                                    highlightSelectedMonth={true}
                                    isDayPickerVisible={false}
                                    dateRangeType={DateRangeType.Day}
                                    autoNavigateOnSelection={false}
                                    showGoToToday={false}
                                    value={date}
                                    strings={DayPickerStrings}
                                    showSixWeeksByDefault={true}
                                />
                            )}
                        </div>
                    );
                },
            },
        },
        /* {
            key: "pageSize " + pageSize,
            text: pageSize + " " + t("tiktokShop.documentPerPage"),
            cacheKey: "myCacheKey", // changing this key will invalidate this item"s cache
            iconProps: { iconName: "NumberedList" },
            disabled: !loadDataComplete || isCreateDocument || search,
            subMenuProps: {
                items: [
                    {
                        key: "pageSize " + 10,
                        text: "10 " + t("tiktokShop.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("10 ออเดอร์/หน้า");
                            setPageSize(10);
                            sessionStorage.setItem("tiktokShop-pageSize", 10);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setNextCursor("");
                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                    {
                        key: "pageSize " + 20,
                        text: "20 " + t("tiktokShop.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("20 ออเดอร์/หน้า");
                            setPageSize(20);
                            sessionStorage.setItem("tiktokShop-pageSize", 20);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setNextCursor("");
                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                    {
                        key: "pageSize " + 30,
                        text: "30 " + t("tiktokShop.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("30 ออเดอร์/หน้า");
                            setPageSize(30);
                            sessionStorage.setItem("tiktokShop-pageSize", 30);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setNextCursor("");
                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                    {
                        key: "pageSize " + 40,
                        text: "40 " + t("tiktokShop.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("40 ออเดอร์/หน้า");
                            setPageSize(40);
                            sessionStorage.setItem("tiktokShop-pageSize", 40);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setNextCursor("");
                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                    {
                        key: "pageSize " + 50,
                        text: "50 " + t("tiktokShop.documentPerPage"),
                        iconProps: { iconName: "NumberedList" },
                        onClick: () => {
                            console.log("50 ออเดอร์/หน้า");
                            setPageSize(50);
                            sessionStorage.setItem("tiktokShop-pageSize", 50);

                            setSearch("");

                            setLoadDataComplete(false);
                            setItems([]);
                            setGetNewDocuments(true);

                            setNextCursor("");
                            setIsCreateDocumentComplete(false);
                            setSelectedItems([]);
                        },
                    },
                ],
            },
        }, */
        {
            key: "refresh",
            text: t("tiktokShop.refresh"),
            iconProps: { iconName: "Refresh" },
            disabled: !loadDataComplete || isCreateDocument || search,
            onClick: () => {
                console.log("Refresh");

                setSearch("");

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);

                setNextCursor("");
                setIsCreateDocumentComplete(false);
                setSelectedItems([]);
            },
        },
    ];

    const overflowItems = [
        {
            key: "disconnect",
            text: t("tiktokShop.disconnect"),
            onClick: () => {
                console.log("TikTok Shop disconnect!");
                setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t("tiktokShop.disconnect"),
                    message: t("tiktokShop.disconnectConfirm"),
                    onConfirm: () => {
                        disconnect(true);
                        setDialogConfirm((prev) => ({
                            ...prev,
                            isClick: false,
                        }));
                    },
                    onCancel: () =>
                        setDialogConfirm((prev) => ({
                            ...prev,
                            isClick: false,
                        })),
                }));
            },
            iconProps: { iconName: "PlugDisconnected" },
        },
    ];

    const padLeadingZeros = (num, size) => {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    };

    const disconnect = (_confirm) => {
        let confirm = _confirm;

        if (confirm) {
            setIsLoading(true);

            instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
                (tokenResponse) => {
                    axios
                        .patch(
                            API_URL + "/sources",
                            {
                                source: "tiktok-shop",
                                replace: "/disabled",
                                value: true,
                            },
                            {
                                headers: {
                                    Authorization: "Bearer " + tokenResponse.accessToken,
                                    "cid": companyId
                                },
                            }
                        )
                        .then((response) => {
                            console.log("disconnect: ", response);

                            history.push({
                                pathname: "/etax/sources/tiktok-shop/connect",
                                state: {},
                            });

                            setIsLoading(false);
                        })
                        .catch((error) => {
                            //Acquire token silent failure, and send an interactive request
                            console.log(error);
                            instance.acquireTokenRedirect(silentRequest);
                        });
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    };

    useEffect(() => {
        console.log("userEffect initial call!");

        setIsLoading(true);

        if (localStorage.getItem("showShippingFee") === "no") {
            setShowShippingFee(false);
        } else {
            setShowShippingFee(true);
        }

        instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then((tokenResponse) => {
                axios
                    .get(API_URL + "/etax/companies", {
                        headers: {
                            Authorization: "Bearer " + tokenResponse.accessToken,
                            "cid": companyId
                        },
                    })
                    .then(
                        (response) => {
                            console.log(response);

                            if (response.data[0]) {
                                if (companyId && response.data.find(_company => _company.id === companyId)) {
                                    const _company = response.data.find(_company => _company.id === companyId)
                                
                                    setCompany(_company);
    
                                    if (_company.registerVat) {
                                        setIncludeVat(true);
                                        setRegisterVat(true);
                                    } else {
                                        setIncludeVat(false);
                                        setRegisterVat(false);
                                    }
                                } else {
                                    setCompany(response.data[0]);
    
                                    if (response.data[0].registerVat) {
                                        setIncludeVat(true);
                                        setRegisterVat(true);
                                    } else {
                                        setIncludeVat(false);
                                        setRegisterVat(false);
                                    }

                                }

                                setGetNewDocuments(true);
                            } else {
                                history.push({
                                    pathname: "/etax/companies/profiles",
                                    state: {},
                                });
                            }

                            setIsLoading(false);
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
            })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });

            instance
                .acquireTokenSilent({ ...silentRequest, account: account })
                .then((tokenResponse) => {
                    axios
                        .get(API_URL + "/sources/tiktok-shop", {
                            headers: {
                                Authorization: "Bearer " + tokenResponse.accessToken,
                                "cid": companyId
                            },
                        })
                        .then(
                            (response) => {
                                console.log("tiktok-shop source response", response);

                                if (response.data) {
                                    setShopList(response.data.shopList)

                                    setShop({
                                        key: response.data.shopList[0].shopId,
                                        text: response.data.shopList[0].shopName,
                                    });
                                }
                            },
                            (error) => {
                                console.log(error);
                            }
                        );
                })
            .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (getNewDocuments) {
            console.log("getNewOrder Running!!!");

            if (endpointType.key === "settlements") {
                let _sort_type = sortType.key;
    
                instance
                    .acquireTokenSilent({ ...silentRequest, account: account })
                    .then(async (tokenResponse) => {
                        const [response, responseAllDocs] = await Promise.all([
                            search
                                ? axios
                                    //   .get("http://localhost:7071/api/TiktokShop_postOrderSettlements", {
                                    .get(API_URL + "/sources/tiktok-shop/orders/settlements", {
                                        headers: {
                                              Authorization: "Bearer " + tokenResponse.accessToken,
                                              "cid": companyId
                                          },
                                          params: {
                                              shop_id: shop.key,
                                              order_id: search,
                                          },
                                      })
                                      .catch((error) => console.log(error))
                                : axios
                                    //   .get("http://localhost:7071/api/TiktokShop_postSettlements", {
                                    .get(API_URL + "/sources/tiktok-shop/settlements", {
                                            headers: {
                                              Authorization: "Bearer " + tokenResponse.accessToken,
                                              "cid": companyId
                                          },
                                          params: {
                                              shop_id: shop.key,
                                              request_time_from: fromTimeStampTiktokShop,
                                              request_time_to: toTimeStampTiktokShop,
                                              cursor: nextCursor ? nextCursor : "",
                                              sort_type: _sort_type,
                                              page_size: pageSize,
                                          },
                                      })
                                      .catch((error) => console.log(error)),
    
                            axios
                                .get(API_URL + "/etax/documents/ALL/all?from=" + from + "&to=" + to + "&date=" + dateType.key, {
                                    headers: {
                                        Authorization: "Bearer " + tokenResponse.accessToken,
                                        "cid": companyId
                                    },
                                })
                                .catch((error) => console.log(error)),
                        ]);
    
                        console.log("response", response);
                        if (response.data.success) {
                            const settlement_list = response.data.data.settlement_list;
    
                            setNextCursor(response.data.data.next_cursor);
                            setMoreData(response.data.data.more);
    
                            const _docs = [];
    
                            settlement_list.forEach((order) => {
                                const {
                                    order_id,
                                    product_name,
                                    settlement_info,
                                } = order;

                                let _orderId = ""
                                if (search) {
                                    _orderId = search
                                } else {
                                    _orderId = order_id
                                }
    
                                const { 
                                    affiliate_commission,
                                    settlement_amount,
                                    settlement_time,
                                    subtotal_after_seller_discounts,
                                    transaction_fee,
                                 } = settlement_info;

                                 const _date = new Date(settlement_time * 1000);
    
                                const _dateBE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + (_date.getFullYear() + 543);
                                const _dateCE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + _date.getFullYear();
    
                                 _docs.push({
                                    key: _orderId,
                                    date: _date.toISOString(),
                                    dateBE: _dateBE,
                                    dateCE: _dateCE,
                                    orderId: _orderId,
                                    productName: product_name,
                                    transactionFee: transaction_fee,
                                    commission: affiliate_commission,
                                    grandTotal: subtotal_after_seller_discounts,
                                    settlement: settlement_amount,
                                    etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.reference === _orderId),
                                });
                            });

                            if (primarySort === "createdTime") {
                                if (isSortedDescendingCreatedTime) {
                                    _docs.sort(function (a, b) {
                                        return b.createdTimestamp - a.createdTimestamp;
                                    });
                                } else {
                                    _docs.sort(function (a, b) {
                                        return a.createdTimestamp - b.createdTimestamp;
                                    });
                                }
                            } else if (primarySort === "number") {
                                if (isSortedDescendingNumber) {
                                    _docs.sort((a, b) => {
                                        if (b.orderId < a.orderId) {
                                            return -1;
                                        }
                                        if (b.orderId > a.orderId) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                } else {
                                    _docs.sort((a, b) => {
                                        if (a.orderId < b.orderId) {
                                            return -1;
                                        }
                                        if (a.orderId > b.orderId) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                }
                            } else if (primarySort === "grandTotal") {
                                if (isSortedDescendingGrandTotal) {
                                    _docs.sort(function (a, b) {
                                        return b.grandTotal - a.grandTotal;
                                    });
                                } else {
                                    _docs.sort(function (a, b) {
                                        return a.grandTotal - b.grandTotal;
                                    });
                                }
                            }
    
                            
                            if (response.data.data.more) {
                                // _docs.push(null);
                                // setReadyToloadNewData(true);
                            } else {
                                setNextCursor("");
                            }
    
                            console.log(_docs);
    
                            setItems(_docs);
                            setLoadDataComplete(true);
                            setGetNewDocuments(false);
                        } else {
                            setItems([]);
                            setNextCursor("");
                            setMoreData(false);
                            setLoadDataComplete(true);
                            setGetNewDocuments(false);
                        }
                    })
                    .catch((error) => {
                        //Acquire token silent failure, and send an interactive request
                        console.log(error);
                        // instance.acquireTokenRedirect(silentRequest);
                        // disconnect(true);
                    });

            } else {
                let _sort_type = 1;
                if (timeRangeField.key === "CREATE_TIME") {
                    if (isSortedDescendingCreatedTime) {
                        _sort_type = 2;
                    }
                } else if (timeRangeField.key === "UPDATE_TIME") {
                    if (isSortedDescendingUpdatedTime) {
                        _sort_type = 2;
                    }
                }
    
                instance
                    .acquireTokenSilent({ ...silentRequest, account: account })
                    .then(async (tokenResponse) => {
                        const [response, responseAllDocs] = await Promise.all([
                            search
                                ? axios
                                    //   .get("http://localhost:7071/api/TiktokShop_postOrderDetail", {
                                        .get(API_URL + "/sources/tiktok-shop/orderdetails", {
                                        headers: {
                                              Authorization: "Bearer " + tokenResponse.accessToken,
                                              "cid": companyId
                                          },
                                          params: {
                                              shop_id: shop.key,
                                              order_id_list: search,
                                          },
                                      })
                                      .catch((error) => console.log(error))
                                : axios
                                    //   .get("http://localhost:7071/api/TiktokShop_postOrderList", {
                                        .get(API_URL + "/sources/tiktok-shop/orders", {
                                            headers: {
                                              Authorization: "Bearer " + tokenResponse.accessToken,
                                              "cid": companyId
                                          },
                                          params: {
                                              shop_id: shop.key,
                                              from: fromTimeStampTiktokShop,
                                              to: toTimeStampTiktokShop,
                                              order_status: orderStatus.key !== "all" ? orderStatus.key : "",
                                              cursor: nextCursor ? nextCursor : "",
                                              sort_by: timeRangeField.key,
                                              sort_type: _sort_type,
                                              page_size: pageSize,
                                          },
                                      })
                                      .catch((error) => console.log(error)),
    
                            axios
                                .get(API_URL + "/etax/documents/ALL/all?from=" + from + "&to=" + to + "&date=" + dateType.key, {
                                    headers: {
                                        Authorization: "Bearer " + tokenResponse.accessToken,
                                        "cid": companyId
                                    },
                                })
                                .catch((error) => console.log(error)),
                        ]);
    
                        console.log("response", response);
                        console.log(response.data.data.order_list.find(_order => _order.order_id === "577128692711590544"))
                        if (response.data.success) {
                            const orderList = response.data.data.order_list;
    
                            setNextCursor(response.data.data.next_cursor);
                            setMoreData(response.data.data.more);
    
                            const _docs = [];
    
                            orderList.forEach((order) => {
                                const {
                                    create_time,
                                    item_list,
                                    order_id,
                                    payment_info,
                                    recipient_address,
                                    update_time,
                                    shipping_provider,
                                    tracking_number
                                } = order;
    
                                const { 
                                    full_address, 
                                    name, 
                                    phone, 
                                    zipcode 
                                } = recipient_address;
    
                                console.log()
                                const _createdTime = timestamp_toDate(create_time);
                                const _createdTimestamp = create_time;
    
                                const _updatedTime = timestamp_toDate(update_time);
                                const _updatedTimestamp = update_time;
    
                                let _postcode = zipcode;
                                let _addressLineOne = full_address;
    
                                if (zipcode) {
                                    _addressLineOne = _addressLineOne.replace(zipcode, "");
                                }
    
                                let _taxId = "";
                                let _taxNumberType = "";
                                let _branchNumber = "";
                                let _branchText = "";
    
                                if (_taxId.includes("*") || !_taxId) {
                                    _taxNumberType = "OTHR";
                                    _taxId = "N/A";
                                    _branchNumber = "";
                                    _branchText = "";
                                }
                                let _customerPhone = phone;
    
                                const _customerName = name;
                                const _customerEmail = "";
    
                                const _items = item_list.map((item, index) => {
                                    const { 
                                        product_name, 
                                        quantity, 
                                        seller_sku, 
                                        sku_name, 
                                        sku_original_price, 
                                        sku_seller_discount, 
                                    } = item;
    
                                    let _variation = "";
                                    if (sku_name) {
                                        _variation = ` (${sku_name})`;
                                    }
    
                                    if (registerVat) {
                                        return {
                                            number: index + 1 + "",
                                            description: product_name + _variation,
                                            quantity: quantity,
                                            unitCode: "",
                                            unitName: "-",
                                            price: sku_original_price,
                                            discount: sku_seller_discount,
                                            percentVat: percentVat,
                                            percentVatText: percentVatText,
                                            total: (sku_original_price - sku_seller_discount) * quantity,
                                            sku: seller_sku
                                        };
                                    } else {
                                        return {
                                            number: index + 1 + "",
                                            description: product_name + _variation,
                                            quantity: quantity,
                                            unitCode: "",
                                            unitName: "-",
                                            price: sku_original_price,
                                            discount: sku_seller_discount,
                                            percentVat: zeroVat,
                                            percentVatText: zeroVatText,
                                            total: (sku_original_price - sku_seller_discount) * quantity,
                                            sku: seller_sku
                                        };
                                    }
                                });
    
                                const { 
                                    original_shipping_fee,
                                    shipping_fee, 
                                    shipping_fee_platform_discount, 
                                    shipping_fee_seller_discount, 
                                    } = payment_info;
    
                                if (showShippingFee) {
                                    _items.push({
                                        number: (_items.length + 1) + "",
                                        description: "ค่าขนส่ง",
                                        quantity: 1,
                                        unitCode: "",
                                        unitName: "-",
                                        price: original_shipping_fee,
                                        discount: shipping_fee_seller_discount + shipping_fee_platform_discount,
                                        percentVat: registerVat ? percentVat : zeroVat,
                                        percentVatText: registerVat ? percentVatText : zeroVatText,
                                        total: shipping_fee,
                                        sku: ""
                                    });
                                }
    
                                const _discount = 0;
                                let _amountTotal = 0;
                                let _vatTotal = 0;
                                let _grandTotal = 0;
    
                                let _vatPriceTotal = 0;
                                let _zeroVatPriceTotal = 0;
                                let _nonVatPriceTotal = 0;
    
                                if (includeVat) {
                                    _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                    _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                    _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                                    _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                                    _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                                } else {
                                    _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                    _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                    _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                                    _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                                }
    
                                const _date = new Date(_updatedTimestamp * 1000);
    
                                const _dateBE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + (_date.getFullYear() + 543);
                                const _dateCE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + _date.getFullYear();
    
                                const _createDate = new Date(Number(_createdTimestamp));
    
                                const _createDateBE = `${_createDate.getDate()}`.padStart(2, "0") + "/" + `${_createDate.getMonth() + 1}`.padStart(2, "0") + "/" + (_createDate.getFullYear() + 543);
                                const _createDateCE = `${_createDate.getDate()}`.padStart(2, "0") + "/" + `${_createDate.getMonth() + 1}`.padStart(2, "0") + "/" + _createDate.getFullYear();
    
                                if (_customerPhone.includes("*") || _customerPhone.includes("(") || _customerPhone.includes(")")) {
                                    _customerPhone = "";
                                }
    
                                if (_postcode.includes('*')) {
                                    _postcode = "00000"
                                }
    
                                const _customer = {
                                    addressLineOne: _addressLineOne,
                                    addressLineTwo: "",
                                    branchNumber: _branchNumber,
                                    branchText: _branchText,
                                    email: _customerEmail,
                                    name: _customerName,
                                    phone: _customerPhone !== "" ? _customerPhone : "",
                                    phoneCountryCode: "+66",
                                    postcode: _postcode ? _postcode : "00000",
                                    taxId: _taxId,
                                    taxNumberType: _taxNumberType,
                                    language: "th",
                                    countryCode: "TH",
                                    countryName: "ไทย",
                                    unstructure: true,
                                };
    
                                _docs.push({
                                    key: order_id,
                                    date: _date.toISOString(),
                                    dateBE: _dateBE,
                                    dateCE: _dateCE,
                                    createDate: _createDate.toISOString(),
                                    createDateBE: _createDateBE,
                                    createDateCE: _createDateCE,
                                    customer: _customer,
                                    createdTime: _createdTime,
                                    createdTimestamp: _createdTimestamp,
                                    updatedTime: _updatedTime,
                                    updatedTimestamp: _updatedTimestamp,
                                    orderId: order_id,
                                    customerName: _customerName,
                                    customerAddress: _addressLineOne,
                                    customerPostcode: _postcode ? _postcode : "00000",
                                    customerPhone: _customerPhone,
                                    customerEmail: _customerEmail,
                                    taxNumberType: _taxNumberType,
                                    branchNumber: _branchNumber,
                                    branchText: _branchText,
                                    items: _items,
                                    discount: _discount,
                                    amountTotal: _amountTotal,
                                    vatTotal: _vatTotal,
                                    grandTotal: _grandTotal,
                                    nonVatPriceTotal: _nonVatPriceTotal,
                                    zeroVatPriceTotal: _zeroVatPriceTotal,
                                    vatPriceTotal: _vatPriceTotal,
                                    customerTaxId: _taxId,
                                    note: "",
                                    etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.reference === order_id),
                                    shippingFee: showShippingFee ? shipping_fee : 0,
                                    shippingProvider: shipping_provider,
                                    trackingNumber: tracking_number
                                });
                            });
    
                            if (primarySort === "createdTime") {
                                if (isSortedDescendingCreatedTime) {
                                    _docs.sort(function (a, b) {
                                        return b.createdTimestamp - a.createdTimestamp;
                                    });
                                } else {
                                    _docs.sort(function (a, b) {
                                        return a.createdTimestamp - b.createdTimestamp;
                                    });
                                }
                            } else if (primarySort === "updatedTime") {
                                if (isSortedDescendingUpdatedTime) {
                                    _docs.sort(function (a, b) {
                                        return b.updatedTimestamp - a.updatedTimestamp;
                                    });
                                } else {
                                    _docs.sort(function (a, b) {
                                        return a.updatedTimestamp - b.updatedTimestamp;
                                    });
                                }
                            } else if (primarySort === "number") {
                                if (isSortedDescendingNumber) {
                                    _docs.sort((a, b) => {
                                        if (b.orderId < a.orderId) {
                                            return -1;
                                        }
                                        if (b.orderId > a.orderId) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                } else {
                                    _docs.sort((a, b) => {
                                        if (a.orderId < b.orderId) {
                                            return -1;
                                        }
                                        if (a.orderId > b.orderId) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                }
                            } else if (primarySort === "customerName") {
                                if (isSortedDescendingCustomerName) {
                                    _docs.sort((a, b) => {
                                        if (b.customerName < a.customerName) {
                                            return -1;
                                        }
                                        if (b.customerName > a.customerName) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                } else {
                                    _docs.sort((a, b) => {
                                        if (a.customerName < b.customerName) {
                                            return -1;
                                        }
                                        if (a.customerName > b.customerName) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                }
                            } else if (primarySort === "grandTotal") {
                                if (isSortedDescendingGrandTotal) {
                                    _docs.sort(function (a, b) {
                                        return b.grandTotal - a.grandTotal;
                                    });
                                } else {
                                    _docs.sort(function (a, b) {
                                        return a.grandTotal - b.grandTotal;
                                    });
                                }
                            }
    
                            if (response.data.data.more) {
                                // _docs.push(null);
                                // setReadyToloadNewData(true);
                            } else {
                                setNextCursor("");
                            }
    
                            console.log(_docs);
    
                            setItems(_docs);
                            setLoadDataComplete(true);
                            setGetNewDocuments(false);
                        } else {
                            setItems([]);
                            setNextCursor("");
                            setMoreData(false);
                            setLoadDataComplete(true);
                            setGetNewDocuments(false);
                        }
                    })
                    .catch((error) => {
                        //Acquire token silent failure, and send an interactive request
                        console.log(error);
                        // instance.acquireTokenRedirect(silentRequest);
                        // disconnect(true);
                    });

            }

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getNewDocuments]);

    function loadNextPage(index) {
        console.log("reach buttom");

        if (nextCursor && moreData) {
            console.log("Loading next page");

            setLoadingNewData(true);

            if (endpointType.key === "settlements") {
                let _sort_type = sortType.key;
    
                instance
                    .acquireTokenSilent({ ...silentRequest, account: account })
                    .then(async (tokenResponse) => {
                        const [response, responseAllDocs] = await Promise.all([
                            search
                                ? axios
                                    //   .get("http://localhost:7071/api/TiktokShop_postOrderSettlements", {
                                    .get(API_URL + "/sources/tiktok-shop/orders/settlements", {
                                          headers: {
                                              Authorization: "Bearer " + tokenResponse.accessToken,
                                              "cid": companyId
                                          },
                                          params: {
                                              shop_id: shop.key,
                                              order_id: search,
                                          },
                                      })
                                      .catch((error) => console.log(error))
                                : axios
                                    //   .get("http://localhost:7071/api/TiktokShop_postSettlements", {
                                    .get(API_URL + "/sources/tiktok-shop/settlements", {
                                          headers: {
                                              Authorization: "Bearer " + tokenResponse.accessToken,
                                              "cid": companyId
                                          },
                                          params: {
                                              shop_id: shop.key,
                                              request_time_from: fromTimeStampTiktokShop,
                                              request_time_to: toTimeStampTiktokShop,
                                              cursor: nextCursor ? nextCursor : "",
                                              sort_type: _sort_type,
                                              page_size: pageSize,
                                          },
                                      })
                                      .catch((error) => console.log(error)),
    
                            axios
                                .get(API_URL + "/etax/documents/ALL/all?from=" + from + "&to=" + to + "&date=" + dateType.key, {
                                    headers: {
                                        Authorization: "Bearer " + tokenResponse.accessToken,
                                        "cid": companyId
                                    },
                                })
                                .catch((error) => console.log(error)),
                        ]);
    
                        console.log("response", response);
                        if (response.data.success) {
                            const settlement_list = response.data.data.settlement_list;
    
                            setNextCursor(response.data.data.next_cursor);
                            setMoreData(response.data.data.more);
    
                            const _docs = [...items];
    
                            settlement_list.forEach((order) => {
                                const {
                                    order_id,
                                    product_name,
                                    settlement_info,
                                } = order;

                                let _orderId = ""
                                if (search) {
                                    _orderId = search
                                } else {
                                    _orderId = order_id
                                }
    
                                const { 
                                    affiliate_commission,
                                    settlement_amount,
                                    settlement_time,
                                    subtotal_after_seller_discounts,
                                    transaction_fee,
                                 } = settlement_info;

                                 const _date = new Date(settlement_time * 1000);
    
                                const _dateBE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + (_date.getFullYear() + 543);
                                const _dateCE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + _date.getFullYear();
    
                                const _createdTime = timestamp_toDate(settlement_time);
                                const _createdTimestamp = settlement_time;

                                 _docs.push({
                                    key: _orderId,
                                    date: _date.toISOString(),
                                    dateBE: _dateBE,
                                    dateCE: _dateCE,
                                    createdTime: _createdTime,
                                    createdTimestamp: _createdTimestamp,
                                    orderId: _orderId,
                                    productName: product_name,
                                    transactionFee: transaction_fee,
                                    commission: affiliate_commission,
                                    grandTotal: subtotal_after_seller_discounts,
                                    settlement: settlement_amount,
                                    etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.reference === _orderId),
                                });
                            });

                            if (primarySort === "createdTime") {
                                if (isSortedDescendingCreatedTime) {
                                    _docs.sort(function (a, b) {
                                        return b.createdTimestamp - a.createdTimestamp;
                                    });
                                } else {
                                    _docs.sort(function (a, b) {
                                        return a.createdTimestamp - b.createdTimestamp;
                                    });
                                }
                            } else if (primarySort === "number") {
                                if (isSortedDescendingNumber) {
                                    _docs.sort((a, b) => {
                                        if (b.orderId < a.orderId) {
                                            return -1;
                                        }
                                        if (b.orderId > a.orderId) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                } else {
                                    _docs.sort((a, b) => {
                                        if (a.orderId < b.orderId) {
                                            return -1;
                                        }
                                        if (a.orderId > b.orderId) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                }
                            } else if (primarySort === "grandTotal") {
                                if (isSortedDescendingGrandTotal) {
                                    _docs.sort(function (a, b) {
                                        return b.grandTotal - a.grandTotal;
                                    });
                                } else {
                                    _docs.sort(function (a, b) {
                                        return a.grandTotal - b.grandTotal;
                                    });
                                }
                            }
    
                            
                            if (response.data.data.more) {
                                // _docs.push(null);
                                // setReadyToloadNewData(true);
                            } else {
                                setNextCursor("");
                            }
    
                            console.log(_docs);
    
                            setItems(_docs);
                            setLoadDataComplete(true);
                            setGetNewDocuments(false);
                        } else {
                            setItems([]);
                            setNextCursor("");
                            setMoreData(false);
                            setLoadDataComplete(true);
                            setGetNewDocuments(false);
                        }
                    })
                    .catch((error) => {
                        //Acquire token silent failure, and send an interactive request
                        console.log(error);
                        // instance.acquireTokenRedirect(silentRequest);
                        // disconnect(true);
                    });

            } else {
                let _sort_type = 1;
                if (timeRangeField.key === "CREATE_TIME") {
                    if (isSortedDescendingCreatedTime) {
                        _sort_type = 2;
                    }
                } else if (timeRangeField.key === "UPDATE_TIME") {
                    if (isSortedDescendingUpdatedTime) {
                        _sort_type = 2;
                    }
                }
    
                instance
                    .acquireTokenSilent({ ...silentRequest, account: account })
                    .then(async (tokenResponse) => {
                        const [response, responseAllDocs] = await Promise.all([
                            search
                                ? axios
                                    //   .get("http://localhost:7071/api/TiktokShop_postOrderDetail", {
                                        .get(API_URL + "/sources/tiktok-shop/orderdetails", {
                                            headers: {
                                              Authorization: "Bearer " + tokenResponse.accessToken,
                                              "cid": companyId
                                          },
                                          params: {
                                              shop_id: shop.key,
                                              order_id_list: search,
                                          },
                                      })
                                      .catch((error) => console.log(error))
                                : axios
                                    //   .get("http://localhost:7071/api/TiktokShop_postOrderList", {
                                        .get(API_URL + "/sources/tiktok-shop/orders", {
                                            headers: {
                                              Authorization: "Bearer " + tokenResponse.accessToken,
                                              "cid": companyId
                                          },
                                          params: {
                                              shop_id: shop.key,
                                              from: fromTimeStampTiktokShop,
                                              to: toTimeStampTiktokShop,
                                              order_status: orderStatus.key !== "all" ? orderStatus.key : "",
                                              cursor: nextCursor ? nextCursor : "",
                                              sort_by: timeRangeField.key,
                                              sort_type: _sort_type,
                                              page_size: pageSize,
                                          },
                                      })
                                      .catch((error) => console.log(error)),
    
                            axios
                                .get(API_URL + "/etax/documents/ALL/all?from=" + from + "&to=" + to + "&date=" + dateType.key, {
                                    headers: {
                                        Authorization: "Bearer " + tokenResponse.accessToken,
                                        "cid": companyId
                                    },
                                })
                                .catch((error) => console.log(error)),
                        ]);
    
                        if (response.data.success) {
                            const orderList = response.data.data.order_list;
    
                            setNextCursor(response.data.data.next_cursor);
                            setMoreData(response.data.data.more);
    
                            const _docs = [...items.filter((item) => item)];
    
                            orderList.forEach((order) => {
                                const {
                                    create_time,
                                    item_list,
                                    order_id,
                                    payment_info,
                                    recipient_address,
                                    update_time,
                                    shipping_provider,
                                    tracking_number
                                } = order;
    
                                const { 
                                    full_address, 
                                    name, 
                                    phone, 
                                    zipcode 
                                } = recipient_address;
    
                                const _createdTime = timestamp_toDate(create_time);
                                const _createdTimestamp = create_time;
    
                                const _updatedTime = timestamp_toDate(update_time);
                                const _updatedTimestamp = update_time;
    
                                let _postcode = zipcode;
                                let _addressLineOne = full_address;
    
                                if (zipcode) {
                                    _addressLineOne = _addressLineOne.replace(zipcode, "");
                                }
    
                                let _taxId = "";
                                let _taxNumberType = "";
                                let _branchNumber = "";
                                let _branchText = "";
    
                                if (_taxId.includes("*") || !_taxId) {
                                    _taxNumberType = "OTHR";
                                    _taxId = "N/A";
                                    _branchNumber = "";
                                    _branchText = "";
                                }
                                let _customerPhone = phone;
    
                                const _customerName = name;
                                const _customerEmail = "";
    
                                const _items = item_list.map((item, index) => {
                                    const { 
                                        product_name, 
                                        quantity, 
                                        seller_sku, 
                                        sku_name, 
                                        sku_original_price, 
                                        sku_seller_discount, 
                                    } = item;
    
                                    let _variation = "";
                                    if (sku_name) {
                                        _variation = ` (${sku_name})`;
                                    }
    
                                    if (registerVat) {
                                        return {
                                            number: index + 1 + "",
                                            description: product_name + _variation,
                                            quantity: quantity,
                                            unitCode: "",
                                            unitName: "-",
                                            price: sku_original_price,
                                            discount: sku_seller_discount,
                                            percentVat: percentVat,
                                            percentVatText: percentVatText,
                                            total: (sku_original_price - sku_seller_discount) * quantity,
                                            sku: seller_sku
                                        };
                                    } else {
                                        return {
                                            number: index + 1 + "",
                                            description: product_name + _variation,
                                            quantity: quantity,
                                            unitCode: "",
                                            unitName: "-",
                                            price: sku_original_price,
                                            discount: sku_seller_discount,
                                            percentVat: zeroVat,
                                            percentVatText: zeroVatText,
                                            total: (sku_original_price - sku_seller_discount) * quantity,
                                            sku: seller_sku
                                        };
                                    }
                                });
    
                                const { 
                                    original_shipping_fee, 
                                    shipping_fee, 
                                    shipping_fee_platform_discount, 
                                    shipping_fee_seller_discount, 
                                } = payment_info;
    
                                if (showShippingFee) {
                                    _items.push({
                                        number: (_items.length + 1) + "",
                                        description: "ค่าขนส่ง",
                                        quantity: 1,
                                        unitCode: "",
                                        unitName: "-",
                                        price: original_shipping_fee,
                                        discount: shipping_fee_seller_discount + shipping_fee_platform_discount,
                                        percentVat: registerVat ? percentVat : zeroVat,
                                        percentVatText: registerVat ? percentVatText : zeroVatText,
                                        total: shipping_fee,
                                        sku: ""
                                    });
                                }
    
                                const _discount = 0;
                                let _amountTotal = 0;
                                let _vatTotal = 0;
                                let _grandTotal = 0;
    
                                let _vatPriceTotal = 0;
                                let _zeroVatPriceTotal = 0;
                                let _nonVatPriceTotal = 0;
    
                                if (includeVat) {
                                    _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                    _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                    _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                                    _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                                    _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                                } else {
                                    _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                    _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                                    _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                                    _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                                }
    
                                const _date = new Date(_updatedTimestamp * 1000);
    
                                const _dateBE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + (_date.getFullYear() + 543);
                                const _dateCE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + _date.getFullYear();
    
                                if (_customerPhone.includes("*") || _customerPhone.includes("(") || _customerPhone.includes(")")) {
                                    _customerPhone = "";
                                }
    
                                if (_postcode.includes('*')) {
                                    _postcode = "00000"
                                }
    
                                const _customer = {
                                    addressLineOne: _addressLineOne,
                                    addressLineTwo: "",
                                    branchNumber: _branchNumber,
                                    branchText: _branchText,
                                    email: _customerEmail,
                                    name: _customerName,
                                    phone: _customerPhone !== "" ? _customerPhone : "",
                                    phoneCountryCode: "+66",
                                    postcode: _postcode ? _postcode : "00000",
                                    taxId: _taxId,
                                    taxNumberType: _taxNumberType,
                                    language: "th",
                                    countryCode: "TH",
                                    countryName: "ไทย",
                                    unstructure: true,
                                };
    
                                _docs.push({
                                    key: order_id,
                                    date: _date.toISOString(),
                                    dateBE: _dateBE,
                                    dateCE: _dateCE,
                                    customer: _customer,
                                    createdTime: _createdTime,
                                    createdTimestamp: _createdTimestamp,
                                    updatedTime: _updatedTime,
                                    updatedTimestamp: _updatedTimestamp,
                                    orderId: order_id,
                                    customerName: _customerName,
                                    customerAddress: _addressLineOne,
                                    customerPostcode: _postcode ? _postcode : "00000",
                                    customerPhone: _customerPhone,
                                    customerEmail: _customerEmail,
                                    taxNumberType: _taxNumberType,
                                    branchNumber: _branchNumber,
                                    branchText: _branchText,
                                    items: _items,
                                    discount: _discount,
                                    amountTotal: _amountTotal,
                                    vatTotal: _vatTotal,
                                    grandTotal: _grandTotal,
                                    nonVatPriceTotal: _nonVatPriceTotal,
                                    zeroVatPriceTotal: _zeroVatPriceTotal,
                                    vatPriceTotal: _vatPriceTotal,
                                    customerTaxId: _taxId,
                                    note: "",
                                    etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.reference === order_id),
                                    shippingFee: showShippingFee ? shipping_fee : 0,
                                    shippingProvider: shipping_provider,
                                    trackingNumber: tracking_number
                                });
                            });
    
                            if (primarySort === "createdTime") {
                                if (isSortedDescendingCreatedTime) {
                                    _docs.sort(function (a, b) {
                                        return b.createdTimestamp - a.createdTimestamp;
                                    });
                                } else {
                                    _docs.sort(function (a, b) {
                                        return a.createdTimestamp - b.createdTimestamp;
                                    });
                                }
                            } else if (primarySort === "updatedTime") {
                                if (isSortedDescendingUpdatedTime) {
                                    _docs.sort(function (a, b) {
                                        return b.updatedTimestamp - a.updatedTimestamp;
                                    });
                                } else {
                                    _docs.sort(function (a, b) {
                                        return a.updatedTimestamp - b.updatedTimestamp;
                                    });
                                }
                            } else if (primarySort === "number") {
                                if (isSortedDescendingNumber) {
                                    _docs.sort((a, b) => {
                                        if (b.orderId < a.orderId) {
                                            return -1;
                                        }
                                        if (b.orderId > a.orderId) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                } else {
                                    _docs.sort((a, b) => {
                                        if (a.orderId < b.orderId) {
                                            return -1;
                                        }
                                        if (a.orderId > b.orderId) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                }
                            } else if (primarySort === "customerName") {
                                if (isSortedDescendingCustomerName) {
                                    _docs.sort((a, b) => {
                                        if (b.customerName < a.customerName) {
                                            return -1;
                                        }
                                        if (b.customerName > a.customerName) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                } else {
                                    _docs.sort((a, b) => {
                                        if (a.customerName < b.customerName) {
                                            return -1;
                                        }
                                        if (a.customerName > b.customerName) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                }
                            } else if (primarySort === "grandTotal") {
                                if (isSortedDescendingGrandTotal) {
                                    _docs.sort(function (a, b) {
                                        return b.grandTotal - a.grandTotal;
                                    });
                                } else {
                                    _docs.sort(function (a, b) {
                                        return a.grandTotal - b.grandTotal;
                                    });
                                }
                            }
    
                            if (response.data.data.more) {
                                // _docs.push(null);
                                // setReadyToloadNewData(true);
                            } else {
                                setNextCursor("");
                            }
    
                            console.log(_docs);
    
                            setLoadingNewData(false);
    
                            setItems(_docs);
                            setLoadDataComplete(true);
                            setGetNewDocuments(false);
    
                            setIsCreateDocumentComplete(false);
    
                        } else {
                            setLoadingNewData(false);
    
                            setNextCursor("");
                            setMoreData(false);
                            setLoadDataComplete(true);
                            setGetNewDocuments(false);
                        }
                    })
                    .catch((error) => {
                        //Acquire token silent failure, and send an interactive request
                        console.log(error);
                        // instance.acquireTokenRedirect(silentRequest);
                        // disconnect(true);
                    });

            }

        }
    }

    useEffect(() => {
        console.log("isCreateDocumentComplete useEffect: ", isCreateDocumentComplete);

        if (loadDataComplete && !isCreateDocument) {
            let _items = selection.getItems();
            console.log("_items: ", _items);

            let _selectedItems = selection.getSelection();

            if (isCreateDocumentComplete) {
                setIsCreateDocumentComplete(false);

                for (let i = 0; i < _items.length; i++) {
                    if (_items?.[i].status != "") {
                        _items[i].status = "";
                    }
                }
            } else {
                for (let i = 0; i < _items.length; i++) {
                    if (_items[i]) {
                        if (_items?.[i].status != "") {
                            _items[i].status = "";
                        }
                    }
                }

                for (let i = 0; i < _selectedItems.length; i++) {
                    if (_selectedItems[i]) {
                        for (let j = 0; j < _items.length; j++) {
                            if (_items[j]) {
                                if (_items?.[j].key === _selectedItems?.[i].key) {
                                    console.log("set pending item key: ", _items[j].key);
                                    _items[j].status = "pending";
                                    console.log("_items: ", _items);
                                }
                            }
                        }
                    }
                }
            }

            setItems(_items);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems]);

    // const handleSort = (primary, isSorted) => {
    //   if (items.length > 0) {
    //     let includesNull = false
    //     if (selection.getItems().some(item => item === null)) {
    //       includesNull = true
    //     }

    //     let _items = selection.getItems().filter(item => item)
    //     let _selectedItems = selection.getSelection();

    //     for (let i = 0; i < _selectedItems.length; i++) {
    //       _selectedItems[i].status = "";

    //       for (let j = 0; j < _items.length; j++) {
    //         if (_items[j].key === _selectedItems[i].key) {
    //           _items[j].status = "";
    //           _selectedItems[i].status = "";
    //         }
    //       }
    //     }

    //     selection.setAllSelected(false);

    //     if (primary === "createdTime") {
    //       if (isSorted) {
    //         _items.sort(function (a, b) {
    //           return b.createdTimestamp - a.createdTimestamp;
    //         });
    //       } else {
    //         _items.sort(function (a, b) {
    //           return a.createdTimestamp - b.createdTimestamp;
    //         });
    //       }

    //     } else if (primary === "updatedTime") {
    //       if (isSorted) {
    //         _items.sort(function (a, b) {
    //           return b.updatedTimestamp - a.updatedTimestamp;
    //         });
    //       } else {
    //         _items.sort(function (a, b) {
    //           return a.updatedTimestamp - b.updatedTimestamp;
    //         });
    //       }

    //     } else if (primary === "number") {
    //       if (isSorted) {
    //         _items.sort((a, b) => {
    //           if (b.orderId < a.orderId) {
    //             return -1;
    //           }
    //           if (b.orderId > a.orderId) {
    //             return 1;
    //           }
    //           return 0;
    //         });
    //       } else {
    //         _items.sort((a, b) => {
    //           if (a.orderId < b.orderId) {
    //             return -1;
    //           }
    //           if (a.orderId > b.orderId) {
    //             return 1;
    //           }
    //           return 0;
    //         });
    //       }

    //     } else if (primary === "customerName") {
    //       if (isSorted) {
    //         _items.sort((a, b) => {
    //           if (b.customerName < a.customerName) {
    //             return -1;
    //           }
    //           if (b.customerName > a.customerName) {
    //             return 1;
    //           }
    //           return 0;
    //         });
    //       } else {
    //         _items.sort((a, b) => {
    //           if (a.customerName < b.customerName) {
    //             return -1;
    //           }
    //           if (a.customerName > b.customerName) {
    //             return 1;
    //           }
    //           return 0;
    //         });
    //       }

    //     } else if (primary === "grandTotal") {
    //       if (isSorted) {
    //         _items.sort(function (a, b) {
    //           return b.grandTotal - a.grandTotal;
    //         });
    //       } else {
    //         _items.sort(function (a, b) {
    //           return a.grandTotal - b.grandTotal;
    //         });
    //       }
    //     }

    //     if (includesNull) {
    //       _items.push(null)
    //     }

    //     setItems(_items);
    //   }
    // };


    function getScrollThreshold(itemsLength) {
        if (itemsLength <= 10) return 0.3
        if (itemsLength <= 20) return 0.5
        if (itemsLength <= 30) return 0.7
        if (itemsLength <= 40) return 0.9
        return 1
    }

    return (
        <div style={homeStyles}>
            {!isLoading ? (
                <div>
                    <Stack>
                        <center>
                            <h2 style={{ marginLeft: "0px", marginTop: "25px" }}>{t("tiktokShop.header")}</h2>
                            {!loadDataComplete ? (
                                <div>
                                    <Spinner
                                        label={t("tiktokShop.loading")}
                                        labelPosition="right"
                                        style={{
                                            marginTop: "21px",
                                            marginBottom: "2px",
                                        }}
                                    />

                                    <br />
                                </div>
                            ) : null}
                            <Stack horizontal horizontalAlign="center">
                                <Toggle
                                    label={t("tiktokShop.displayShipCost")}
                                    inlineLabel
                                    onText={t("tiktokShop.on")}
                                    offText={t("tiktokShop.off")}
                                    checked={showShippingFee}
                                    disabled={!loadDataComplete || isCreateDocument}
                                    onChange={() => {
                                        setShowShippingFee(!showShippingFee);

                                        if (!showShippingFee) {
                                            localStorage.setItem("showShippingFee", "yes");
                                        } else {
                                            localStorage.setItem("showShippingFee", "no");
                                        }

                                        setLoadDataComplete(false);
                                        setItems([]);
                                        setGetNewDocuments(true);

                                        setNextCursor("");
                                        setIsCreateDocumentComplete(false);
                                        setSelectedItems([]);
                                    }}
                                />
                            </Stack>
                        </center>
                    </Stack>

                    {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
                        <Stack vertical tokens={{ childrenGap: "10px" }}>
                            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                                <h3>{t("tiktokShop.subHeader")}</h3>
                            </Stack>
                            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                                <TextField
                                    name="prefixNumber"
                                    label={t("tiktokShop.prefixNumber")}
                                    description={t("tiktokShop.prefixNumberDescription")}
                                    styles={{ root: { width: 200 } }}
                                    onChange={(e) => {
                                        setPrefixNumber(e.target.value);
                                    }}
                                    value={prefixNumber}
                                    disabled={isCreateDocument}
                                />
                                <TextField
                                    name="countingNumber"
                                    label={t("tiktokShop.countingNumber")}
                                    description={countingNumber.length === 0 ? "" : t("tiktokShop.countingNumberDesciption")}
                                    styles={{ root: { width: 150 } }}
                                    onChange={(e) => {
                                        if (!/\D/.test(e.target.value)) {
                                            setCountingNumber(e.target.value);
                                        }
                                    }}
                                    value={countingNumber}
                                    errorMessage={countingNumber.length === 0 ? t("tiktokShop.countingNumberErrorMessage") : ""}
                                    disabled={isCreateDocument}
                                    required
                                />
                            </Stack>
                            {countingNumber.length > 0 ? (
                                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                                    {!isCreateDocument ? (
                                        <h4>
                                            {t("tiktokShop.startDocumentNumber")} {prefixNumber}
                                            {countingNumber}
                                        </h4>
                                    ) : (
                                        <h4>
                                            {t("tiktokShop.endDocumentNumber")} {prefixNumber}
                                            {countingNumber}
                                        </h4>
                                    )}
                                </Stack>
                            ) : (
                                <br />
                            )}
                        </Stack>
                    )}

                    {selectedItems.length > 0 && !isCreateDocument && (
                        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                            <PrimaryButton
                                text={t("tiktokShop.createDocument")}
                                iconProps={{ iconName: "Add" }}
                                menuProps={{
                                    items: [
                                        {
                                            key: "RECEIPT-TAXINVOICE",
                                            text: t("tiktokShop.receipt-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    setIsCreateDocument(true);
                                                    await createSelectedDocuments("RECEIPT-TAXINVOICE");
                                                }
                                            },
                                        },
                                        {
                                            key: "RECEIPT-TAXINVOICE-ABB",
                                            text: t("tiktokShop.receipt-taxinvoice-abb"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    setIsCreateDocument(true);
                                                    await createSelectedDocuments("RECEIPT-TAXINVOICE-ABB");
                                                }
                                            },
                                        },
                                        {
                                            key: "RECEIPT",
                                            text: t("tiktokShop.receipt"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    setIsCreateDocument(true);
                                                    await createSelectedDocuments("RECEIPT");
                                                }
                                            },
                                        },
                                        {
                                            key: "INVOICE-TAXINVOICE",
                                            text: t("tiktokShop.invoice-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    setIsCreateDocument(true);
                                                    await createSelectedDocuments("INVOICE-TAXINVOICE");
                                                }
                                            },
                                        },
                                        {
                                            key: "DELIVERYORDER-TAXINVOICE",
                                            text: t("tiktokShop.deliveryorder-taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    setIsCreateDocument(true);
                                                    await createSelectedDocuments("DELIVERYORDER-TAXINVOICE");
                                                }
                                            },
                                        },
                                        {
                                            key: "TAXINVOICE",
                                            text: t("tiktokShop.taxinvoice"),
                                            iconProps: { iconName: "PageAdd" },
                                            onClick: async () => {
                                                if (countingNumber.length > 0) {
                                                    setIsCreateDocument(true);
                                                    await createSelectedDocuments("TAXINVOICE");
                                                }
                                            },
                                        },
                                    ],
                                    shouldFocusOnMount: true,
                                    directionalHintFixed: true,
                                }}
                                // Optional callback to customize menu rendering
                                menuAs={(props) => <ContextualMenu {...props} />}
                            />
                            <DefaultButton
                                text={t("tiktokShop.cancel")}
                                style={{ marginLeft: "10px" }}
                                onClick={() => {
                                    let _items = selection.getItems();
                                    let _selectedItems = selection.getSelection();
                                    console.log("selection: ", selection.getSelection());

                                    for (let i = 0; i < _selectedItems.length; i++) {
                                        console.log("select item key: ", _selectedItems[i].key);
                                        _selectedItems[i].status = "";

                                        for (let j = 0; j < _items.length; j++) {
                                            if (_items[j].key === _selectedItems[i].key) {
                                                console.log("item key: ", _items[j].key);
                                                _items[j].status = "";
                                                _selectedItems[i].status = "";
                                            }
                                        }
                                    }

                                    selection.setAllSelected(false);

                                    setItems(_items);
                                    console.log("selection: ", selection.getSelection());
                                }}
                            />
                            <br />
                        </Stack>
                    )}

                    {isCreateDocument && (
                        <div>
                            <Spinner label={t("tiktokShop.signProcessing")} size={SpinnerSize.large} />
                        </div>
                    )}

                    {isCreateDocumentComplete && (
                        <center>
                            <PrimaryButton
                                text={t("tiktokShop.createdDocuments")}
                                iconProps={{ iconName: "Documentation" }}
                                onClick={async () => {
                                    console.log(selectedItems);
                                    history.push({
                                        pathname: "/etax/documents/all",
                                        state: {},
                                    });
                                }}
                            />
                        </center>
                    )}
                    <br />
                    <Stack horizontal horizontalAlign="center">
                        <Stack
                            vertical
                            style={{
                                width: "98%",
                                minWidth: "800px",
                                maxWidth: "1350px",
                            }}
                        >
                            <Stack horizontal horizontalAlign="end" style={{ marginRight: 15, marginTop: -50 }}>
                                <Icon iconName="Robot" style={{ color: "#106ebe", marginRight: "7px", marginTop: "-3px", fontSize: "21px" }} />
                                <Link
                                    disabled={true}
                                    onClick={() => {
                                        history.push({
                                            pathname: "/etax/sources/tiktok-shop/automation",
                                            state: {},
                                        });
                                    }}
                                >
                                    <h3 style={{ marginTop: 0, width: 140 }}>{t("tiktokShop.automation")}</h3>
                                </Link>
                            </Stack>

                            <Stack horizontal horizontalAlign="end" style={{ marginRight: 13, marginTop: -10, marginBottom: 10 }}>
                                    <Icon iconName="Blocked2" style={{ color: "#106ebe", marginTop: "-2px", marginRight: "10px", fontSize: "20px" }} />
                                <span style={{ marginTop: 0, width: 140 }}>{t("tiktokShop.offAutomationStatus")}</span>
                            </Stack>

                            <Stack 
                                horizontal 
                                styles={{
                                    root: {
                                        backgroundColor: 'white',
                                    },
                                }} 
                                horizontalAlign="space-between"
                            >
                                <Stack
                                    styles={{
                                        root: {
                                        width: 'calc(100vw - 700px)',
                                        minWidth: '700px',
                                        maxWidth: '900px',
                                        },
                                    }}
                                >
                                    <CommandBar
                                        items={commandBarItems}
                                        overflowItems={overflowItems}
                                    />
                                </Stack>
                                <Stack
                                    horizontal
                                    styles={{
                                        root: {
                                            height: 44,
                                        },
                                    }}
                                    tokens={{ childrenGap: "10px" }}
                                >
                                    <SearchBox
                                        className="ms-borderColor-themePrimary"
                                        styles={{
                                            root: {
                                                marginTop: 6,
                                                marginLeft: 6,
                                                width: 200,
                                                fontSize: 13,
                                                fontWeight: 0,
                                            },
                                        }}
                                        disabled={!loadDataComplete}
                                        placeholder={t("tiktokShop.searchBoxPlaceholder")}
                                        onChange={(e) => setSearch(e ? e.target.value : "")}
                                        onClear={() => {
                                            setLoadDataComplete(false);
                                            setItems([]);
                                            setGetNewDocuments(true);

                                            setNextCursor("");
                                            setIsCreateDocumentComplete(false);
                                            setSelectedItems([]);
                                        }}
                                        onSearch={() => {
                                            setLoadDataComplete(false);
                                            setItems([]);
                                            setGetNewDocuments(true);

                                            setNextCursor("");
                                            setIsCreateDocumentComplete(false);
                                            setSelectedItems([]);
                                        }}
                                        value={search}
                                    />
                                    <PrimaryButton
                                        disabled={!loadDataComplete || !search}
                                        styles={{
                                            root: {
                                                marginTop: 6,
                                                marginRight: 8,
                                                width: 90,
                                            },
                                        }}
                                        text={t("tiktokShop.search")}
                                        onClick={() => {
                                            setLoadDataComplete(false);
                                            setItems([]);
                                            setGetNewDocuments(true);

                                            setNextCursor("");
                                            setIsCreateDocumentComplete(false);
                                            setSelectedItems([]);
                                        }}
                                    />
                                </Stack>
                            </Stack>
                            {/* <div ref={detailListRef} onScroll={() => onScroll()}> */}
                            {loadDataComplete ? (
                                <InfiniteScroll
                                    dataLength={items.length}
                                    next={loadNextPage}
                                    hasMore={isCreateDocument || selectedItems.length > 0 ? false : moreData}
                                    loader={
                                        !(isCreateDocument || selectedItems.length > 0) ? 
                                        (loadingNewData && <Spinner
                                            label={t("tiktokShop.loading")}
                                            style={{
                                                marginTop: "21px",
                                                marginBottom: "2px",
                                            }}
                                        />) : null
                                    }
                                    scrollThreshold={getScrollThreshold(items.length)}
                                    // scrollThreshold={0.9}
                               >
                                    <DetailsList
                                        items={items.length ? items : [{}]}
                                        compact={isCompactMode}
                                        columns={endpointType.key === "settlements" ? columnsSettlement : columns}
                                        selection={selection}
                                        selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                                        layoutMode={DetailsListLayoutMode.justified}
                                        isHeaderVisible={true}
                                        checkboxVisibility={CheckboxVisibility.always}
                                        selectionPreservedOnEmptyClick={true}
                                        onShouldVirtualize={ () => false }
                                        onRenderRow={(props) => {
                                            if (props) {
                                                if (((Object.keys(props.item).length === 1 && Object.keys(props.item).includes("status")) || Object.keys(props.item).length === 0) && Object.getPrototypeOf(props.item) === Object.prototype) {
                                                    return (
                                                        <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: "#FFFFFF", height: 55 } }}>
                                                            <Text>{t("documents.noItemFound")}</Text>
                                                        </Stack>
                                                    );
                                                } else {
                                                    return <DetailsRow {...props} />;
                                                }
                                            }
                                        }}
                                        // onRenderMissingItem={(index) => {
                                        //   if (readyToloadNewData) {
                                        //     setReadyToloadNewData(false)
                                        //     loadNextPage()
                                        //   }

                                        //   return loadingNewData ? (
                                        //     <Spinner
                                        //       label={t("tiktokShop.loading")}
                                        //       style={{
                                        //         marginTop: "21px",
                                        //         marginBottom: "2px",
                                        //       }}
                                        //     />) : null
                                        // }}
                                        // onRenderMissingItem={(index) => {
                                        //     if (readyToloadNewData) {
                                        //         setReadyToloadNewData(false);

                                        //         let loading = loadingNewData;
                                        //         if (!loading) {
                                        //             setLoadingNewData((prev) => true);

                                        //             setTimeout(() => loadNextPage(), 1000);
                                        //         }
                                        //     }

                                        //     return loadingNewData ? (
                                        //         <Spinner
                                        //             label={t("tiktokShop.loading")}
                                        //             style={{
                                        //                 marginTop: "21px",
                                        //                 marginBottom: "2px",
                                        //             }}
                                        //         />
                                        //     ) : null;
                                        // }}
                                    />
                                </InfiniteScroll>
                            ) : (
                                <ShimmeredDetailsList
                                    items={items || []}
                                    enableShimmer={!loadDataComplete}
                                    compact={isCompactMode}
                                    columns={endpointType.key === "settlements" ? columnsSettlement : columns}
                                    selection={selection}
                                    selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    checkboxVisibility={CheckboxVisibility.always}
                                    selectionPreservedOnEmptyClick={true}
                                    onRenderRow={() => {
                                        return (
                                            <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: "#FFFFFF", height: 55 } }}>
                                                <Text>{t("documents.noItemFound")}</Text>
                                            </Stack>
                                        );
                                    }}
                                />
                            )}
                        </Stack>
                    </Stack>
                    <br />
                    {/* <center>
            {!(items.length === 0 || (currentPage === 1 && items.length < pageSize)) && !(!loadDataComplete || isCreateDocument) && (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{
                  iconName: "DoubleChevronLeft",
                }}
                previousPageIconProps={{
                  iconName: "ChevronLeft",
                }}
                nextPageIconProps={{ iconName: "ChevronRight" }}
                lastPageIconProps={{
                  iconName: "DoubleChevronRight",
                }}
              />
            )}

            <br />
            <br />
          </center> */}
                </div>
            ) : (
                <Stack>
                    <Stack horizontal horizontalAlign="center">
                        <br />
                        <br />
                        <div className="certificates-loading-center">
                            <Spinner label={t("tiktokShop.loading")} labelPosition="down" styles={spinnerStyles} />
                        </div>
                        <br />
                    </Stack>
                </Stack>
            )}
            <Dialog
                hidden={!dialogConfirm.isClick}
                onDismiss={dialogConfirm.onCancel}
                styles={{ main: { minHeight: 0 } }}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: dialogConfirm.header,
                    styles: { header: { textAlign: "center" }, title: { paddingRight: 24 }, subText: { textAlign: "center" } },
                    subText: dialogConfirm.message,
                }}
            >
                <DialogFooter styles={{ actionsRight: { textAlign: "center" } }}>
                    <PrimaryButton onClick={dialogConfirm.onConfirm} text={t("documents.confirm")} />
                    <DefaultButton onClick={dialogConfirm.onCancel} text={t("documents.cancel")} />
                </DialogFooter>
            </Dialog>
        </div>
    );
}

export default TiktokShop;
