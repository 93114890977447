import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as React from 'react';
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { Stack } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { validateField, validateFieldEN } from './validate';

function DragDropFail(props) {
  const history = useHistory();
  const location = useLocation();

  const { t, i18n } = useTranslation();

  const failItemsObj = location.state;
  // const failItems = failItemsObj.item
  const [failItems, setFailItems] = useState(failItemsObj.item);
  const mergeArray = failItemsObj.mergeArray;

  const isCompactMode = false;

  const columns = [
    {
      key: 'column1',
      name: t('excelDragDropFail.order'), // ลำดับ
      fieldName: 'order',
      minWidth: 80,
      maxWidth: 100,
    },
    {
      key: 'column2',
      name: t('excelDragDropFail.number'), // เลขที่เอกสาร
      fieldName: 'number',
      minWidth: 80,
      maxWidth: 150,
      isResizable: true,
      data: 'string',
    },
    {
      key: 'column3',
      name: t('excelDragDropFail.dateBE'), // ชื่อเอกสาร (ใบเสร็จ, ใบกำกับ)
      fieldName: 'dateBE',
      minWidth: 70,
      maxWidth: 150,
      isResizable: true,

      data: 'string',
    },
    {
      key: 'column4',
      name: t('excelDragDropFail.error'), // วันที่
      fieldName: 'error',
      minWidth: 300,
      isResizable: true,

      data: 'string',
      isMultiline: true,
      onRender: (value) => {
        return (
          <div className={mergeStyles({ color: 'red' })}>
            {value.error.map((text, index) => {
              return <div key={`error-${index}`}>{text}</div>;
            })}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    let failItems = [];
    mergeArray.forEach((row) => {
      if (validateField(row).length > 0)
        failItems.push({
          order: row.order,
          number: row.number,
          dateBE: row.dateBE,
          error: i18n.language === 'th' ? validateField(row) : validateFieldEN(row),
        });
    });

    setFailItems(failItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div>
      <Stack
        horizontal
        horizontalAlign="center"
        tokens={{ childrenGap: 25 }}
        style={{
          paddingTop: '10px',
          paddingBottom: '10px',
          background: '#4D4D4D',
          width: 'calc(100vw - 303px)',
          minWidth: '1113px',
        }}
      >
        <button style={{ width: '90px', height: '30px' }} onClick={() => history.push({ pathname: '/etax/sources/excel/dragdrop', state: {} })}>
          {t('excelDragDropFail.back')}
        </button>
      </Stack>
      <Stack horizontal horizontalAlign="center">
        <h1>{t('excelDragDropFail.header')}</h1>
      </Stack>

      <Stack horizontal horizontalAlign="center">
        <Stack
          vertical
          style={{
            width: '98%',
            minWidth: '800px',
            maxWidth: '1350px',
          }}
        >
          <DetailsList
            items={failItems}
            compact={isCompactMode}
            columns={columns}
            selectionMode={SelectionMode.none}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
          />
        </Stack>
      </Stack>
    </div>
  );
}

export default DragDropFail;
