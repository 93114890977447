import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';
import { useTranslation } from 'react-i18next';

import { Stack, PrimaryButton, TextField, getTheme, Spinner } from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const RdTaxId = () => {
  console.log('Home render!!!');

  const { t } = useTranslation();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const documents = [];
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const getNewDocuments = true;

  const pageData = {};
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const [taxId, setTaxId] = useState('');
  const [isClick, setIsClick] = useState(false);
  const [isExist, setIsExist] = useState(false);

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);

    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year}`;
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');
  }, [getNewDocuments]);

  useEffect(() => {
    if (documents.length > 0) {
      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {}
      console.log('Docs data', _docs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('taxId: ', taxId);

    setIsClick(true);
    setLoadDataComplete(false);
    setIsExist(false);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/sources/rd/tin/' + taxId, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              let _data = response.data;

              if (_data && _data.ID.length > 0) {
                console.log('IsExist: ', _data.IsExist[0]);

                if (_data.IsExist.length > 0 && _data.IsExist[0] === 'Yes') {
                  setIsExist(true);
                } else {
                  setIsExist(false);
                }
              }
              setLoadDataComplete(true);
            },
            (error) => {
              console.log(error);
              setLoadDataComplete(true);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
        setLoadDataComplete(true);
      });
  };

  return (
    <div style={homeStyles}>
      <Stack>
        <center>
          <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('taxId.header')}</h2>
          <h3>
            {t('taxId.subHeader')} {toThaiDateString(new Date())}
          </h3>
          <h4>{t('taxId.subHeader2')}</h4>
        </center>

        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}></Stack>
      </Stack>

      <br />

      <form onSubmit={handleSubmit}>
        <Stack vertical>
          <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 15 }}>
            <TextField
              name="taxId"
              label={t('taxId.taxId')}
              required
              styles={{ root: { width: 310 } }}
              onChange={(e) => {
                setIsClick(false);
                setTaxId(e.target.value);
              }}
              value={taxId}
            />
            <PrimaryButton
              iconProps={{ iconName: 'Search' }}
              name="search"
              text={t('taxId.search')}
              style={{ marginTop: '29px' }}
              type="submit"
              value="search"
            />
          </Stack>
        </Stack>
      </form>

      {!isClick && (
        <Stack>
          <br />
          <br />
          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                maxWidth: '800px',
              }}
            >
              <p>{t('taxId.footer1')} </p>
              <p>{t('taxId.footer2')}</p>
            </Stack>
          </Stack>
        </Stack>
      )}

      <br />

      {isClick && !loadDataComplete && (
        <center>
          <div
            className="ms-Grid"
            dir="ltr"
            style={{
              width: 510,
              height: 370,
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              paddingBottom: 30,
              background: '#ffffff',
              marginTop: '50px',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <div className="ms-Grid-row">
              <Stack vertical verticalAlign="center">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Spinner label={t('taxId.processing')} labelPosition="down" styles={spinnerStyles} />
                <br />
              </Stack>
            </div>
          </div>
        </center>
      )}

      {isClick && loadDataComplete && isExist && (
        <center>
          <div
            className="ms-Grid"
            dir="ltr"
            style={{
              width: 510,
              height: 370,
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              paddingBottom: 30,
              background: '#ffffff',
              marginTop: '50px',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <div className="ms-Grid-row">
              <Stack vertical>
                <Stack horizontal horizontalAlign="center">
                  <img height="180" src="/images/success.gif" alt="successgif" />
                </Stack>
                <br />
                <Stack horizontal horizontalAlign="center">
                  <h2 style={{ color: 'green' }}>
                    {t('taxId.found')} {taxId}
                  </h2>
                </Stack>

                <Stack horizontal horizontalAlign="center">
                  <h3 style={{ color: 'green' }}>{t('taxId.found2')}</h3>
                </Stack>
                <br />
              </Stack>
            </div>
          </div>
        </center>
      )}
      {isClick && loadDataComplete && !isExist && (
        <center>
          <div
            className="ms-Grid"
            dir="ltr"
            style={{
              width: 510,
              height: 370,
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              paddingBottom: 30,
              background: '#ffffff',
              marginTop: '50px',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <div className="ms-Grid-row">
              <Stack vertical>
                <Stack horizontal horizontalAlign="center">
                  <img height="180" src="/images/fail.gif" alt="failgif" />
                </Stack>
                <br />
                <Stack horizontal horizontalAlign="center">
                  <h2 style={{ color: 'red' }}>
                    {t('taxId.notFound')} {taxId}
                  </h2>
                </Stack>

                <Stack horizontal horizontalAlign="center">
                  <h3 style={{ color: 'red' }}>{t('taxId.found2')}</h3>
                </Stack>
                <br />
              </Stack>
            </div>
          </div>
        </center>
      )}

      <br />
      <center>
        {!(documents.length === 0 || documents.length < pageSize) && loadDataComplete && (
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={pageData.totalPages}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
          />
        )}

        <br />
        <br />
      </center>
    </div>
  );
};

export default RdTaxId;
