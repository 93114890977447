import React, { useState, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { TextField, PrimaryButton, DefaultButton, Dropdown, Stack, getTheme, ChoiceGroup } from '@fluentui/react';

const theme = getTheme();

const stackTokens = { childrenGap: 15 };

const Companies = () => {
  console.log('Re render');

  const history = useHistory();
  const location = useLocation();

  const [data] = useState(location.state);

  const [period, setPeriod] = useState(1);
  const [taxId, setTaxId] = useState('');
  const [type, setType] = useState('');
  const [nameTH, setNameTH] = useState('');
  const [nameEN, setNameEN] = useState('');
  const [email, setEmail] = useState('');

  const [id, setId] = useState(data.id);
  const [address, setAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [district, setDistrict] = useState('');
  const [province, setProvince] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactMobile, setContactMobile] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  //Error Message
  const [typeErrMsg, setTypeErrMsg] = useState('');

  const DropdownErrMsg = 'กรุณาเลือก';

  function handleSubmit(event) {
    console.log('handleSubmit!');
    event.preventDefault();

    if (type === undefined || type === null || type === '') {
      console.log('Submit type: ', type);
    }

    history.push({
      pathname: '/ca/address',
      state: {
        period: period,
        taxId: taxId,
        type: type,
        nameTH: nameTH,
        nameEN: nameEN,
        email: email,

        id: id,
        address: address,
        postcode: postcode,
        district: district,
        province: province,
        phone: phone,
        mobile: mobile,

        firstName: firstName,
        lastName: lastName,
        contactPhone: contactPhone,
        contactMobile: contactMobile,
        contactEmail: contactEmail,
      },
    });
  }

  const getErrorMessage = (value) => {
    return value && value !== '0' ? '' : 'กรุณากรอกข้อมูล';
  };

  useEffect(() => {
    console.log('useEffect data call!: ', data);

    if (data !== undefined) {
      setPeriod(data.period);
      setTaxId(data.taxId);
      setType(data.type);
      setNameTH(data.nameTH);
      setNameEN(data.nameEN);
      setEmail(data.email);

      setId(data.id);
      setAddress(data.address);
      setPostcode(data.postcode);
      setDistrict(data.district);
      setProvince(data.province);
      setPhone(data.phone);
      setMobile(data.mobile);

      setFirstName(data.firstName);
      setLastName(data.lastName);
      setContactPhone(data.contactPhone);
      setContactMobile(data.contactMobile);
      setContactEmail(data.contactEmail);
    }
  }, [data]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h2 style={{ marginLeft: '2%', marginTop: '4%' }}>กรอกข้อมูลที่แสดงบนใบรับรองอิเล็กทรอนิกส์</h2>
        <div
          className="ms-Grid"
          dir="ltr"
          style={{
            width: 560,
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 30,
            background: '#ffffff',
            margin: '2%',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <div className="ms-Grid-row">
            <Stack horizontal tokens={stackTokens}>
              <ChoiceGroup
                selectedKey={period}
                options={[
                  { key: 1, text: 'ใบรับรองอิเล็กทรอนิกส์ 1 ปี (ราคา 3,000 ยังไม่รวม Vat)' },
                  { key: 2, text: 'ใบรับรองอิเล็กทรอนิกส์ 2 ปี (ราคา 5,000 ยังไม่รวม Vat)' },
                ]}
                onChange={(e, selectedOption) => setPeriod(selectedOption.key)}
                label="ท่านต้องการใช้ใบรับรองอิเล็กทรอนิกส์ ประเภทกี่ปี?"
                required={true}
              />
            </Stack>

            <br />
            <Stack horizontal tokens={stackTokens}>
              <TextField
                name="taxId"
                label="เลขประจำตัวผู้เสียภาษีของนิติบุคคล"
                placeholder="กรอกเลข 13 หลัก"
                styles={{ root: { width: 250 } }}
                value={taxId}
                onChange={(e) => {
                  setTaxId(e.target.value);
                }}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />

              <Dropdown
                name="type"
                placeholder="กรุณาเลือก"
                label="ประเภทธุรกิจ"
                options={[
                  { key: 'ห้างหุ้นส่วนสามัญ', text: 'ห้างหุ้นส่วนสามัญ' },
                  { key: 'ห้างหุ้นส่วนจำกัด', text: 'ห้างหุ้นส่วนจำกัด' },
                  { key: 'บริษัทจำกัด', text: 'บริษัทจำกัด' },
                  { key: 'บริษัทมหาชนจำกัด', text: 'บริษัทมหาชนจำกัด' },
                  { key: 'นิติบุคคลอื่น ภายใต้กฎหมายเฉพาะ', text: 'นิติบุคคลอื่น ภายใต้กฎหมายเฉพาะ' },
                ]}
                selectedKey={type}
                styles={{ root: { width: 250 } }}
                onChange={(e, selectedOption) => {
                  setType(selectedOption.key);
                }}
                onBlur={() => {
                  console.log(type);
                  if (type === '') {
                    setTypeErrMsg(DropdownErrMsg);
                  } else {
                    setTypeErrMsg('');
                  }
                }}
                errorMessage={typeErrMsg}
                required
              />
            </Stack>

            <br />
            <Stack horizontal tokens={stackTokens}>
              <TextField
                name="nameTH"
                label="ชื่อนิติบุคคล / องค์กร (ภาษาไทย)"
                placeholder="กรอกเฉพาะชื่อบริษัท เช่น ฟรีเวชั่น"
                styles={{ root: { width: 250 } }}
                value={nameTH}
                onChange={(e) => setNameTH(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />

              <TextField
                name="nameEN"
                label="ชื่อนิติบุคคล / องค์กร (ภาษาอังกฤษ)"
                placeholder="กรอกเฉพาะชื่อบริษัท เช่น Frevation"
                styles={{ root: { width: 250 } }}
                value={nameEN}
                onChange={(e) => setNameEN(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />
            </Stack>

            <br />
            <Stack horizontal tokens={stackTokens}>
              {type === 'ห้างหุ้นส่วนสามัญ' && (
                <span style={{ color: 'green', fontSize: '16px', fontWeight: 'bold', width: 250 }}>ห้างหุ้นส่วนสามัญ {nameTH}</span>
              )}
              {type === 'ห้างหุ้นส่วนจำกัด' && (
                <span style={{ color: 'green', fontSize: '16px', fontWeight: 'bold', width: 250 }}>ห้างหุ้นส่วนจำกัด {nameTH}</span>
              )}
              {type === 'บริษัทจำกัด' && (
                <span style={{ color: 'green', fontSize: '16px', fontWeight: 'bold', width: 250 }}>บริษัท {nameTH} จำกัด</span>
              )}
              {type === 'บริษัทมหาชนจำกัด' && (
                <span style={{ color: 'green', fontSize: '16px', fontWeight: 'bold', width: 250 }}>บริษัท {nameTH} จำกัด (มหาชน)</span>
              )}
              {type === 'นิติบุคคลอื่น ภายใต้กฎหมายเฉพาะ' && (
                <span style={{ color: 'green', fontSize: '16px', fontWeight: 'bold', width: 250 }}>{nameTH}</span>
              )}

              {type === 'ห้างหุ้นส่วนสามัญ' && (
                <span style={{ color: 'green', fontSize: '16fpx', fontWeight: 'bold', width: 250 }}>{nameEN} Ordinary Partnership</span>
              )}
              {type === 'ห้างหุ้นส่วนจำกัด' && (
                <span style={{ color: 'green', fontSize: '16fpx', fontWeight: 'bold', width: 250 }}>{nameEN} Limited Partnership</span>
              )}
              {type === 'บริษัทจำกัด' && (
                <span style={{ color: 'green', fontSize: '16fpx', fontWeight: 'bold', width: 250 }}>{nameEN} Company Limited</span>
              )}
              {type === 'บริษัทมหาชนจำกัด' && (
                <span style={{ color: 'green', fontSize: '16fpx', fontWeight: 'bold', width: 250 }}>{nameEN} Public Company Limited</span>
              )}
              {type === 'นิติบุคคลอื่น ภายใต้กฎหมายเฉพาะ' && (
                <span style={{ color: 'green', fontSize: '16fpx', fontWeight: 'bold', width: 250 }}>{nameEN}</span>
              )}
            </Stack>

            <br />
            <Stack horizontal tokens={stackTokens}>
              <TextField
                name="email"
                label="อีเมล"
                styles={{ root: { width: 250 } }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
                description="อีเมลนี้จะแสดงบนทุกเอกสาร ที่มีการลงลายเซ็นดิจิทัล"
              />
            </Stack>

            <br />
            <br />

            <PrimaryButton name="next" text="ต่อไป" type="submit" />
            <DefaultButton
              text="ยกเลิก"
              style={{ marginLeft: '15px' }}
              onClick={() => {
                let confirm = window.confirm('กรุณายืนยัน การยกเลิกการติดตั้งใบรับรองอิเล็กทรอนิกส์');

                if (confirm) {
                  history.push({
                    pathname: '/etax/certificates',
                    state: {},
                  });
                }
              }}
            />

            <br />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Companies;
