import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  Calendar,
  Icon,
  DateRangeType,
  Spinner,
  SpinnerSize,
  SearchBox,
  CommandBar,
  Stack,
  PrimaryButton,
  DefaultButton,
  TextField,
  ContextualMenu,
  CheckboxVisibility,
  Dialog,
  DialogFooter,
  DialogType,
  DatePicker,
  Text,
  Checkbox,
  Toggle
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import { numberToTextEn, numberToTextTh } from '../../../helpers/BahtWord';
import { getProvinceList } from '../../../providers/AreaData';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const onFormatDate = (date, format) => {
  if (format === 'CE') {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
  }
};

const WooCommerce = () => {
  console.log('Home render!!!');

  const history = useHistory();

  const { t, i18n } = useTranslation();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(false);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem('wooCommerce-pageSize') * 1 || 10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('wooCommerce-currentPage') * 1 || 1);

  const provinceList = getProvinceList("th");

  const [company, setCompany] = useState('');
  const [search, setSearch] = useState('');

  const percentVat = 7;
  const zeroVat = 0;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  // const nonVatText = '-'

  const dateType = { key: 'document', text: 'วันที่บนเอกสาร' };

  const [orderStatus, setOrderStatus] = useState(
    (sessionStorage.getItem('wooCommerce-invoiceType-key') && {
      key: sessionStorage.getItem('wooCommerce-invoiceType-key'),
      text: sessionStorage.getItem('wooCommerce-invoiceType-text'),
    }) || {
      key: 'any',
      text: t('wooCommerce.allType'),
    }
  );

  const [isLoading, setIsLoading] = useState(false);

  const isCompactMode = false;

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const registerVat = true
  const [showShippingFee, setShowShippingFee] = useState(true);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        console.log('isCreateDocument: ', isCreateDocument);
        console.log('isCreateDocumentComplete: ', isCreateDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const companyId = sessionStorage.getItem('companyId') || '';

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('wooCommerce-primarySort') || 'updatedTime');
  const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
    if (sessionStorage.getItem('wooCommerce-sort-createdTime')) {
        if (sessionStorage.getItem('wooCommerce-sort-createdTime') === 'true') {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
});
  const [isSortedDescendingUpdatedTime, setIsSortedDescendingUpdatedTime] = useState(() => {
    if (sessionStorage.getItem('wooCommerce-sort-updatedTime') === 'false') {
        return false;
    } else {
        return true;
    }
});
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('wooCommerce-sort-number')) {
      if (sessionStorage.getItem('wooCommerce-sort-number') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('wooCommerce-sort-customerName')) {
      if (sessionStorage.getItem('wooCommerce-sort-customerName') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(() => {
    if (sessionStorage.getItem('wooCommerce-sort-vatTotal')) {
      if (sessionStorage.getItem('wooCommerce-sort-vatTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem('wooCommerce-sort-grandTotal')) {
      if (sessionStorage.getItem('wooCommerce-sort-grandTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const [prefixNumber, setPrefixNumber] = useState("");
  const [countingNumber, setCountingNumber] = useState("");

  const DayPickerStrings = {
    months: [
      t('wooCommerce.months01'),
      t('wooCommerce.months02'),
      t('wooCommerce.months03'),
      t('wooCommerce.months04'),
      t('wooCommerce.months05'),
      t('wooCommerce.months06'),
      t('wooCommerce.months07'),
      t('wooCommerce.months08'),
      t('wooCommerce.months09'),
      t('wooCommerce.months10'),
      t('wooCommerce.months11'),
      t('wooCommerce.months12'),
    ],

    shortMonths: [
      t('wooCommerce.shortMonths01'),
      t('wooCommerce.shortMonths02'),
      t('wooCommerce.shortMonths03'),
      t('wooCommerce.shortMonths04'),
      t('wooCommerce.shortMonths05'),
      t('wooCommerce.shortMonths06'),
      t('wooCommerce.shortMonths07'),
      t('wooCommerce.shortMonths08'),
      t('wooCommerce.shortMonths09'),
      t('wooCommerce.shortMonths10'),
      t('wooCommerce.shortMonths11'),
      t('wooCommerce.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('wooCommerce.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('wooCommerce.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  // Processing dialog style
  const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4,
    },
  };

  const getMonth = (goBack) => {
    let monthNames = [
      t('wooCommerce.months01'),
      t('wooCommerce.months02'),
      t('wooCommerce.months03'),
      t('wooCommerce.months04'),
      t('wooCommerce.months05'),
      t('wooCommerce.months06'),
      t('wooCommerce.months07'),
      t('wooCommerce.months08'),
      t('wooCommerce.months09'),
      t('wooCommerce.months10'),
      t('wooCommerce.months11'),
      t('wooCommerce.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem('wooCommerce-queryMonth-key') && {
        key: sessionStorage.getItem('wooCommerce-queryMonth-key'),
        text: sessionStorage.getItem('wooCommerce-queryMonth-text'),
        ...(sessionStorage.getItem('wooCommerce-queryMonth-type') ? { type: sessionStorage.getItem('wooCommerce-queryMonth-type') } : {}),
    }) || { key: getMonth(0).year + '' + getMonth(0).key, text: t('wooCommerce.thisMonth') }
  );

  const [timeRangeDate, setTimeRangeDate] = useState(sessionStorage.getItem('wooCommerce-queryMonth-type') ? true : false);
  const [fromDate, setFromDate] = useState(sessionStorage.getItem('wooCommerce-fromDate') ? new Date(sessionStorage.getItem('wooCommerce-fromDate')) : null);
  const [toDate, setToDate] = useState(sessionStorage.getItem('wooCommerce-toDate') ? new Date(sessionStorage.getItem('wooCommerce-toDate')) : null);
  const [timeRangeError, setTimeRangeError] = useState(false);

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const getFromDMY = (day, month, year) => {
    let _firstDay = new Date(year, month, day);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getToDMY = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const getFromMYISO = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return _firstDay.toISOString()
  };

  const getToMYISO = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return _lastDay.toISOString()
  };

  const getFromDMYISO = (day, month, year) => {
    let _firstDay = new Date(year, month, day);
    console.log('First day: ', formatDate(_firstDay));

    return _firstDay.toISOString()
  };

  const getToDMYISO = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);
    console.log('Last day: ', formatDate(_lastDay));

    return _lastDay.toISOString()
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('wooCommerce-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('wooCommerce-to') || getTo(getMonth(0).month, getMonth(0).year));

  const [fromISO, setFromISO] = useState(sessionStorage.getItem('wooCommerce-fromISO') || getFromMYISO(getMonth(0).month, getMonth(0).year));
  const [toISO, setToISO] = useState(sessionStorage.getItem('wooCommerce-toISO') || getToMYISO(getMonth(0).month, getMonth(0).year));

  const [date, setDate] = useState(sessionStorage.getItem('wooCommerce-date') ? new Date(sessionStorage.getItem('wooCommerce-date')) : new Date());

  let _pageDropdown = [];

  for (let i = 1; i <= 35; i++) {
    _pageDropdown.push({
      key: i,
      text: t('wooCommerce.page') + ' ' + i,
      iconProps: { iconName: 'Page' },
      onClick: () => {
        console.log('Selected page: ' + i);
        setCurrentPage(i);
      },
    });
  }

  const pageDropdown = _pageDropdown;

  function getDateText(monthObject) {
    if (monthObject.type === 'dateRange') {
      if (i18n.language === 'th') {
        return onFormatDate(fromDate, 'BE') + ' - ' + onFormatDate(toDate, 'BE');
      }
      return onFormatDate(fromDate, 'CE') + ' - ' + onFormatDate(toDate, 'CE');
    }
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('wooCommerce.thisMonth');

    let monthNames = [
      t('wooCommerce.months01'),
      t('wooCommerce.months02'),
      t('wooCommerce.months03'),
      t('wooCommerce.months04'),
      t('wooCommerce.months05'),
      t('wooCommerce.months06'),
      t('wooCommerce.months07'),
      t('wooCommerce.months08'),
      t('wooCommerce.months09'),
      t('wooCommerce.months10'),
      t('wooCommerce.months11'),
      t('wooCommerce.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  function getOrderStatusText(key) {
    if (key === 'any') return t('wooCommerce.allType');
    if (key === 'pending') return t('wooCommerce.pendingType');
    if (key === 'processing') return t('wooCommerce.processingType');
    if (key === 'on-hold') return t('wooCommerce.on-holdType');
    if (key === 'completed') return t('wooCommerce.completedType');
    if (key === 'cancelled') return t('wooCommerce.cancelledType');
    if (key === 'refunded') return t('wooCommerce.refundedType');
    if (key === 'failed') return t('wooCommerce.failedType');
    if (key === 'trash') return t('wooCommerce.trashType');
  }

  const commandBarItems = [
    {
      key: orderStatus.key,
      text: getOrderStatusText(orderStatus.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: 'any',
            text: t('wooCommerce.allType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('any');
              setOrderStatus({ key: 'any', text: t('wooCommerce.allType') });

              sessionStorage.setItem('wooCommerce-invoiceType-key', 'any');
              sessionStorage.setItem('wooCommerce-invoiceType-text', t('wooCommerce.allType'));

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(false);

              sessionStorage.setItem('wooCommerce-primarySort', 'createdTime');
              sessionStorage.setItem('wooCommerce-sort-createdTime', 'false');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setIsSortedDescendingUpdatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('wooCommerce-sort-updatedTime', 'false');
              sessionStorage.setItem('wooCommerce-sort-number', 'false');
              sessionStorage.setItem('wooCommerce-sort-customerName', 'false');
              sessionStorage.setItem('wooCommerce-sort-vatTotal', 'false');
              sessionStorage.setItem('wooCommerce-sort-grandTotal', 'false');
            },
          },
          {
            key: 'pending',
            text: t('wooCommerce.pendingType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('pending');
              setOrderStatus({ key: 'pending', text: t('wooCommerce.pendingType') });

              sessionStorage.setItem('wooCommerce-invoiceType-key', 'pending');
              sessionStorage.setItem('wooCommerce-invoiceType-text', t('wooCommerce.pendingType'));

              setPrimarySort('updatedTime');
              setIsSortedDescendingUpdatedTime(false);

              sessionStorage.setItem('wooCommerce-primarySort', 'updatedTime');
              sessionStorage.setItem('wooCommerce-sort-updatedTime', 'false');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('wooCommerce-sort-createdTime', 'false');
              sessionStorage.setItem('wooCommerce-sort-number', 'false');
              sessionStorage.setItem('wooCommerce-sort-customerName', 'false');
              sessionStorage.setItem('wooCommerce-sort-vatTotal', 'false');
              sessionStorage.setItem('wooCommerce-sort-grandTotal', 'false');
            },
          },
          {
            key: 'processing',
            text: t('wooCommerce.processingType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('processing');
              setOrderStatus({ key: 'processing', text: t('wooCommerce.processingType') });

              sessionStorage.setItem('wooCommerce-invoiceType-key', 'processing');
              sessionStorage.setItem('wooCommerce-invoiceType-text', t('wooCommerce.processingType'));

              setPrimarySort('updatedTime');
              setIsSortedDescendingUpdatedTime(false);

              sessionStorage.setItem('wooCommerce-primarySort', 'updatedTime');
              sessionStorage.setItem('wooCommerce-sort-updatedTime', 'false');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('wooCommerce-sort-createdTime', 'false');
              sessionStorage.setItem('wooCommerce-sort-number', 'false');
              sessionStorage.setItem('wooCommerce-sort-customerName', 'false');
              sessionStorage.setItem('wooCommerce-sort-vatTotal', 'false');
              sessionStorage.setItem('wooCommerce-sort-grandTotal', 'false');
            },
          },
          {
            key: 'on-hold',
            text: t('wooCommerce.on-holdType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('on-hold');
              setOrderStatus({ key: 'on-hold', text: t('wooCommerce.on-holdType') });

              sessionStorage.setItem('wooCommerce-invoiceType-key', 'on-hold');
              sessionStorage.setItem('wooCommerce-invoiceType-text', t('wooCommerce.on-holdType'));

              setPrimarySort('updatedTime');
              setIsSortedDescendingUpdatedTime(false);

              sessionStorage.setItem('wooCommerce-primarySort', 'updatedTime');
              sessionStorage.setItem('wooCommerce-sort-updatedTime', 'false');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('wooCommerce-sort-createdTime', 'false');
              sessionStorage.setItem('wooCommerce-sort-number', 'false');
              sessionStorage.setItem('wooCommerce-sort-customerName', 'false');
              sessionStorage.setItem('wooCommerce-sort-vatTotal', 'false');
              sessionStorage.setItem('wooCommerce-sort-grandTotal', 'false');
            },
          },
          {
            key: 'completed',
            text: t('wooCommerce.completedType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('completed');
              setOrderStatus({ key: 'completed', text: t('wooCommerce.completedType') });

              sessionStorage.setItem('wooCommerce-invoiceType-key', 'completed');
              sessionStorage.setItem('wooCommerce-invoiceType-text', t('wooCommerce.completedType'));

              setPrimarySort('updatedTime');
              setIsSortedDescendingUpdatedTime(false);

              sessionStorage.setItem('wooCommerce-primarySort', 'updatedTime');
              sessionStorage.setItem('wooCommerce-sort-updatedTime', 'false');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('wooCommerce-sort-createdTime', 'false');
              sessionStorage.setItem('wooCommerce-sort-number', 'false');
              sessionStorage.setItem('wooCommerce-sort-customerName', 'false');
              sessionStorage.setItem('wooCommerce-sort-vatTotal', 'false');
              sessionStorage.setItem('wooCommerce-sort-grandTotal', 'false');
            },
          },
          {
            key: 'cancelled',
            text: t('wooCommerce.cancelledType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('cancelled');
              setOrderStatus({ key: 'cancelled', text: t('wooCommerce.cancelledType') });

              sessionStorage.setItem('wooCommerce-invoiceType-key', 'cancelled');
              sessionStorage.setItem('wooCommerce-invoiceType-text', t('wooCommerce.cancelledType'));

              setPrimarySort('updatedTime');
              setIsSortedDescendingUpdatedTime(false);

              sessionStorage.setItem('wooCommerce-primarySort', 'updatedTime');
              sessionStorage.setItem('wooCommerce-sort-updatedTime', 'false');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('wooCommerce-sort-createdTime', 'false');
              sessionStorage.setItem('wooCommerce-sort-number', 'false');
              sessionStorage.setItem('wooCommerce-sort-customerName', 'false');
              sessionStorage.setItem('wooCommerce-sort-vatTotal', 'false');
              sessionStorage.setItem('wooCommerce-sort-grandTotal', 'false');
            },
          },
          {
            key: 'refunded',
            text: t('wooCommerce.refundedType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('refunded');
              setOrderStatus({ key: 'refunded', text: t('wooCommerce.refundedType') });

              sessionStorage.setItem('wooCommerce-invoiceType-key', 'refunded');
              sessionStorage.setItem('wooCommerce-invoiceType-text', t('wooCommerce.refundedType'));

              setPrimarySort('updatedTime');
              setIsSortedDescendingUpdatedTime(false);

              sessionStorage.setItem('wooCommerce-primarySort', 'updatedTime');
              sessionStorage.setItem('wooCommerce-sort-updatedTime', 'false');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('wooCommerce-sort-createdTime', 'false');
              sessionStorage.setItem('wooCommerce-sort-number', 'false');
              sessionStorage.setItem('wooCommerce-sort-customerName', 'false');
              sessionStorage.setItem('wooCommerce-sort-vatTotal', 'false');
              sessionStorage.setItem('wooCommerce-sort-grandTotal', 'false');
            },
          },
          {
            key: 'failed',
            text: t('wooCommerce.failedType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('failed');
              setOrderStatus({ key: 'failed', text: t('wooCommerce.failedType') });

              sessionStorage.setItem('wooCommerce-invoiceType-key', 'failed');
              sessionStorage.setItem('wooCommerce-invoiceType-text', t('wooCommerce.failedType'));

              setPrimarySort('updatedTime');
              setIsSortedDescendingUpdatedTime(false);

              sessionStorage.setItem('wooCommerce-primarySort', 'updatedTime');
              sessionStorage.setItem('wooCommerce-sort-updatedTime', 'false');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('wooCommerce-sort-createdTime', 'false');
              sessionStorage.setItem('wooCommerce-sort-number', 'false');
              sessionStorage.setItem('wooCommerce-sort-customerName', 'false');
              sessionStorage.setItem('wooCommerce-sort-vatTotal', 'false');
              sessionStorage.setItem('wooCommerce-sort-grandTotal', 'false');
            },
          },
          {
            key: 'trash',
            text: t('wooCommerce.trashType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('trash');
              setOrderStatus({ key: 'trash', text: t('wooCommerce.trashType') });

              sessionStorage.setItem('wooCommerce-invoiceType-key', 'trash');
              sessionStorage.setItem('wooCommerce-invoiceType-text', t('wooCommerce.trashType'));

              setPrimarySort('updatedTime');
              setIsSortedDescendingUpdatedTime(false);

              sessionStorage.setItem('wooCommerce-primarySort', 'updatedTime');
              sessionStorage.setItem('wooCommerce-sort-updatedTime', 'false');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);

              setSearch('');

              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingVatTotal(false);
              setIsSortedDescendingGrandTotal(false);

              sessionStorage.setItem('wooCommerce-sort-createdTime', 'false');
              sessionStorage.setItem('wooCommerce-sort-number', 'false');
              sessionStorage.setItem('wooCommerce-sort-customerName', 'false');
              sessionStorage.setItem('wooCommerce-sort-vatTotal', 'false');
              sessionStorage.setItem('wooCommerce-sort-grandTotal', 'false');
            },
          }
        ],
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Stack styles={{ root: { padding: "12px 12px 0px 12px" } }}>
                <Checkbox
                  label={t("wooCommerce.timeRange")}
                  checked={timeRangeDate}
                  onChange={(e) => {
                    setTimeRangeDate((prev) => !prev);
                  }}
                />
              </Stack>
              {timeRangeDate ? (
                <Stack>
                <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                  <Stack styles={{ root: { width: "20%", justifyContent: "center", alignItems: "center" } }}>
                    <Text>{t("wooCommerce.fromRange")}</Text>
                  </Stack>
                  <Stack styles={{ root: { width: "80%", justifyContent: "center" /* alignItems: "center" */ } }}>
                    <DatePicker
                      styles={{ root: { width: "90%" } }}
                      placeholder={t("deliveryOrderTaxInvoice.pleaseSelect")}
                      strings={DayPickerStrings}
                      value={fromDate}
                      maxDate={toDate}
                      formatDate={onFormatDate}
                      onSelectDate={(_date) => {
                        setFromDate(_date);

                        setTimeRangeError(false);
                        if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                  <Stack styles={{ root: { width: "20%", justifyContent: "center", alignItems: "center" } }}>
                    <Text>{t("wooCommerce.toRange")}</Text>
                  </Stack>
                  <Stack styles={{ root: { width: "80%" /* alignItems: "center" */ } }}>
                    <DatePicker
                      styles={{ root: { width: "90%" } }}
                      placeholder={t("deliveryOrderTaxInvoice.pleaseSelect")}
                      strings={DayPickerStrings}
                      value={toDate}
                      minDate={fromDate}
                      formatDate={onFormatDate}
                      onSelectDate={(_date) => {
                        setToDate(_date);

                        setTimeRangeError(false);
                        if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                      }}
                    />
                  </Stack>
                </Stack>
                {timeRangeError ? (
                  <Stack styles={{ root: { padding: "12px 0px 0px 12px" } }}>
                    <Text styles={{ root: { fontSize: 12, color: "#A4262C" } }}>{t("wooCommerce.timeRangeError")}</Text>
                  </Stack>
                ) : null}
                <Stack horizontal horizontalAlign="end" styles={{ root: { width: "92%", height: 52, padding: "10px 0px 10px 0px" } }}>
                  <PrimaryButton
                    text={t("wooCommerce.search")}
                    onClick={() => {
                      if (!timeRangeError && fromDate && toDate) {
                        setQueryMonth({
                          type: "dateRange",
                          key: formatDate(fromDate) + ":" + formatDate(toDate),
                          text: formatDate(fromDate) + " - " + formatDate(toDate),
                        });

                        sessionStorage.setItem("wooCommerce-queryMonth-type", "dateRange");
                        sessionStorage.setItem("wooCommerce-queryMonth-key", formatDate(fromDate) + ":" + formatDate(toDate));
                        sessionStorage.setItem("wooCommerce-queryMonth-text", formatDate(fromDate) + " - " + formatDate(toDate));

                        setFrom(getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        setTo(getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        sessionStorage.setItem("wooCommerce-from", getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        sessionStorage.setItem("wooCommerce-to", getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        setFromISO(getFromDMYISO(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        setToISO(getToDMYISO(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        sessionStorage.setItem("wooCommerce-fromISO", getFromDMYISO(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        sessionStorage.setItem("wooCommerce-toISO", getToDMYISO(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        sessionStorage.setItem("wooCommerce-fromDate", fromDate.toISOString());
                        sessionStorage.setItem("wooCommerce-toDate", toDate.toISOString());

                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);

                        setCurrentPage(1);

                        setSearch("");

                        setPrimarySort("updatedTime");
                        setIsSortedDescendingUpdatedTime(true);
                        setIsSortedDescendingCreatedTime(false)
                        setIsSortedDescendingNumber(false);
                        setIsSortedDescendingCustomerName(false)
                        setIsSortedDescendingVatTotal(false);
                        setIsSortedDescendingGrandTotal(false);

                        sessionStorage.setItem("wooCommerce-primarySort", "updatedTime");
                        sessionStorage.setItem("wooCommerce-sort-updatedTime", "true");
                        sessionStorage.setItem("wooCommerce-sort-createdTime", "false");
                        sessionStorage.setItem("wooCommerce-sort-number", "false");
                        sessionStorage.setItem("wooCommerce-sort-customerName", "false");
                        sessionStorage.setItem("wooCommerce-sort-vatTotal", "false");
                        sessionStorage.setItem("wooCommerce-sort-grandTotal", "false");
                      }
                    }}
                    style={{ width: "125px", letterSpacing: "1px" }}
                  />
                </Stack>
              </Stack>
              ) : (
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t("wooCommerce.months01"),
                    t("wooCommerce.months02"),
                    t("wooCommerce.months03"),
                    t("wooCommerce.months04"),
                    t("wooCommerce.months05"),
                    t("wooCommerce.months06"),
                    t("wooCommerce.months07"),
                    t("wooCommerce.months08"),
                    t("wooCommerce.months09"),
                    t("wooCommerce.months10"),
                    t("wooCommerce.months11"),
                    t("wooCommerce.months12"),
                  ];

                  setDate(_date);

                  sessionStorage.setItem("wooCommerce-date", _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + "" + String(adjustDate.getMonth() + 1).padStart(2, "0"),
                    text: monthNames[adjustDate.getMonth()] + " " + year,
                  });

                  sessionStorage.setItem("wooCommerce-queryMonth-type", "");
                  sessionStorage.setItem("wooCommerce-queryMonth-key", adjustDate.getFullYear() + "" + String(adjustDate.getMonth() + 1).padStart(2, "0"));
                  sessionStorage.setItem("wooCommerce-queryMonth-text", monthNames[adjustDate.getMonth()] + " " + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setFromISO(getFromMYISO(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setToISO(getToMYISO(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem("wooCommerce-from", getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem("wooCommerce-to", getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem("wooCommerce-fromISO", getFromMYISO(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem("wooCommerce-toISO", getToMYISO(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);

                  setCurrentPage(1);

                  setSearch("");

                  setPrimarySort("updatedTime");
                  setIsSortedDescendingUpdatedTime(true);
                  setIsSortedDescendingCreatedTime(false)
                  setIsSortedDescendingNumber(false);
                  setIsSortedDescendingCustomerName(false)
                  setIsSortedDescendingVatTotal(false);
                  setIsSortedDescendingGrandTotal(false);

                  sessionStorage.setItem("wooCommerce-primarySort", "updatedTime");
                  sessionStorage.setItem("wooCommerce-sort-updatedTime", "true");
                  sessionStorage.setItem("wooCommerce-sort-createdTime", "false");
                  sessionStorage.setItem("wooCommerce-sort-number", "false");
                  sessionStorage.setItem("wooCommerce-sort-customerName", "false");
                  sessionStorage.setItem("wooCommerce-sort-vatTotal", "false");
                  sessionStorage.setItem("wooCommerce-sort-grandTotal", "false");
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
              )}
            </div>
          );
        },
      },
    },
    {
      key: pageSize,
      text: pageSize + ' ' + t('wooCommerce.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: 10,
            text: '10 ' + t('wooCommerce.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 เอกสาร/หน้า');
              setPageSize(10);
              sessionStorage.setItem('wooCommerce-pageSize', 10);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 20,
            text: '20 ' + t('wooCommerce.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 เอกสาร/หน้า');
              setPageSize(20);
              sessionStorage.setItem('wooCommerce-pageSize', 20);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 30,
            text: '30 ' + t('wooCommerce.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 เอกสาร/หน้า');
              setPageSize(30);
              sessionStorage.setItem('wooCommerce-pageSize', 30);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 40,
            text: '40 ' + t('wooCommerce.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 เอกสาร/หน้า');
              setPageSize(40);
              sessionStorage.setItem('wooCommerce-pageSize', 40);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 50,
            text: '50 ' + t('wooCommerce.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 เอกสาร/หน้า');
              setPageSize(50);
              sessionStorage.setItem('wooCommerce-pageSize', 50);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
          {
            key: 100,
            text: '100 ' + t('wooCommerce.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('100 เอกสาร/หน้า');
              setPageSize(100);
              sessionStorage.setItem('wooCommerce-pageSize', 100);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch('');
            },
          },
        ],
      },
    },
    {
      key: currentPage,
      text: t('wooCommerce.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: 'refresh',
      text: t('wooCommerce.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isCreateDocument,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
  ];

  const overflowItems = [
    {
      key: 'disconnect',
      text: t('wooCommerce.disconnect'),
      onClick: () => {
        console.log('Woo Commerce disconnect!');

        setDialogConfirm((prev) => ({
          ...prev,
          isClick: true,
          header: t('wooCommerce.disconnect'),
          message: t('wooCommerce.disconnectConfirm'),
          onConfirm: () => {
            disconnect(true);
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));
          },
          onCancel: () =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            })),
        }));
      },
      iconProps: { iconName: 'PlugDisconnected' },
    //   disabled: !loadDataComplete || isCreateDocument,
    },
  ];

  const createDocument = async (documentType, item) => {
    setIsLoading(true);

    let documentPath = '/etax/documents/receipts';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoices-abb';
        break;
      case 'RECEIPT':
        documentPath = '/etax/documents/receipts';
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices';
        break;
      default:
        break;
    }

    history.push({
      pathname: documentPath,
      state: {
        command: "EDIT",
        reference: item.number,
        company: {
          ...company,
          includeVat: item.includeVat,
        },
        customer: item.customer,
        date: item.date,
        dateBE: item.dateBE,
        dateCE: item.dateCE,
        // dueDate: item.dueDate,
        // dueDateBE: item.dueDateBE,
        // dueDateCE: item.dueDateCE,
        items: item.items,
        includeVat: item.includeVat,
        discount: item.discount * 1,
        amountTotal: item.amountTotal * 1,
        vatTotal: item.vatTotal * 1,
        grandTotal: item.grandTotal * 1,
        nonVatPriceTotal: item.nonVatPriceTotal,
        zeroVatPriceTotal: item.zeroVatPriceTotal,
        vatPriceTotal: item.vatPriceTotal,
        percentVat: percentVat,
        // receivedBy: 'Woo Commerce',
        receivedBy: '',
        reIssue: false,
        backUrl: '/etax/sources/woo-commerce',
        grandTotalTH: numberToTextTh(item.grandTotal * 1),
        grandTotalEN: numberToTextEn(item.grandTotal * 1),
        note: ""
      },
    });

  };

  useEffect(() => {
    console.log('userEffect initial call!');

    setIsLoading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              'Authorization': 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  if (!_company.includeVat) {
                    _company.includeVat = true;
                  }
  
                  setCompany(_company);
                } else {
                  if (!response.data[0].includeVat) {
                    response.data[0].includeVat = true;
                  }
  
                  setCompany(response.data[0]);

                }

              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }

              setIsLoading(false);
            },
            (error) => {
              console.log(error);

              setIsLoading(false);
            }
          );

        
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        // instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getNewDocuments) {
      if (search) {
        const searchInvoices = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
              const [response, responseAllDocs] = await Promise.all([
                axios
                //   .get('http://localhost:7071/api/WooCommerce_searchOrders', {
                  .get(API_URL + '/sources/woo-commerce/orders', {
                    headers: {
                      'Authorization': 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId,
                    },
                    params: {
                      status: orderStatus.key,
                      from: fromISO,
                      to: toISO,
                      page: currentPage,
                      per_page: pageSize,
                      search: search
                    },
                  })
                  .catch((error) => console.log(error)),

                axios
                  .get(API_URL + '/etax/documents/ALL/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                    headers: {
                      'Authorization': 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId
                    },
                  })
                  .catch((error) => console.log(error)),
              ]);
              let orders = response.data.orders

              setPageData(paginate(525, currentPage, pageSize, 10));

              const _docs = [];

              for (let i = 0; i < Math.min(pageSize, orders.length); i++) {
                const { 
                  number, 
                  date_created_gmt,
                  date_modified_gmt, 
                  discount_total, 
                  prices_include_tax, 
                  billing, 
                  line_items, 
                  shipping_lines
                } = orders[i]

                const { first_name, last_name, address_1, address_2, city, state, postcode, email, phone } = billing

                let _address = '';

                if (address_1) {
                  _address = _address + address_1;
                }

                if (address_2) {
                  _address = _address + ' ' + address_2;
                }

                if (city) {
                  _address = _address + ' ' + city;
                }

                if (state) {
                  if (state.match(/\d+/) && provinceList.some(_province => _province.key === state.match(/\d+/)[0])) {
                    const provinceObj = provinceList.find(_province => _province.key === state.match(/\d+/)[0])
                    _address = _address + ' ' + provinceObj.text;
                  } else {
                    _address = _address + ' ' + state;
                  }
                }

                const _items = line_items.map((item, index) => {
                  const { 
                    name, 
                    quantity, 
                    subtotal, 
                    subtotal_tax, 
                    total, 
                    total_tax, 
                    sku, 
                  } = item;

                  if (registerVat) {
                      return {
                          number: index + 1 + '',
                          description: name,
                          quantity: Number(quantity),
                          unitCode: '',
                          unitName: '-',
                          price: Math.round(((Number(total) + Number(total_tax)) / Number(quantity)) * 100) / 100,
                          discount: Math.round(((Number(subtotal) + Number(subtotal_tax)) - (Number(total) + Number(total_tax))) * 100) / 100,
                          percentVat: percentVat,
                          percentVatText: percentVatText,
                          total: Math.round((Number(total) + Number(total_tax)) * 100) / 100,
                          sku: sku
                      };
                  } else {
                      return {
                          number: index + 1 + '',
                          description: name,
                          quantity: Number(quantity),
                          unitCode: '',
                          unitName: '-',
                          price: Math.round(((Number(total) + Number(total_tax)) / Number(quantity)) * 100) / 100,
                          discount: Math.round(((Number(subtotal) + Number(subtotal_tax)) - (Number(total) + Number(total_tax))) * 100) / 100,
                          percentVat: zeroVat,
                          percentVatText: zeroVatText,
                          total: Math.round((Number(total) + Number(total_tax)) * 100) / 100,
                          sku: sku
                      };
                  }
              });

              if (showShippingFee) {
                if (shipping_lines.length) {
                  const { 
                    total
                  } = shipping_lines[0]
  
                  _items.push({
                      number: (_items.length + 1) + '',
                      description: 'ค่าขนส่ง',
                      quantity: 1,
                      unitCode: '',
                      unitName: '-',
                      price: Number(total),
                      discount: 0,
                      percentVat: registerVat ? percentVat : zeroVat,
                      percentVatText: registerVat ? percentVatText : zeroVatText,
                      total: Number(total),
                      sku: ''
                  });
                }
              }

              const _discount = Number(discount_total);
              let _amountTotal = 0;
              let _vatTotal = 0;
              let _grandTotal = 0;

              let _vatPriceTotal = 0;
              let _zeroVatPriceTotal = 0;
              let _nonVatPriceTotal = 0;

              if (prices_include_tax) {
                  _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                  _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                  _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                  _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                  _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
              } else {
                  _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                  _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                  _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                  _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
              }

              let _date = ''
              let _dateBE = ''
              let _dateCE = ''

              if (date_modified_gmt) {
                _date = new Date(date_modified_gmt).toISOString()
                _dateBE = onFormatDate(new Date(date_modified_gmt), 'BE')
                _dateCE = onFormatDate(new Date(date_modified_gmt), 'CE')
              }

              let _createdDate = ''
              let _createdDateBE = ''
              let _createdDateCE = ''
              if (date_created_gmt) {
                _createdDate = new Date(date_created_gmt).toISOString()
                _createdDateBE = onFormatDate(new Date(date_created_gmt), 'BE')
                _createdDateCE = onFormatDate(new Date(date_created_gmt), 'CE')
              }

              let _createdTime = onFormatDate(new Date(date_created_gmt), 'BE')

              let _customerPhone = phone;
              if (phone.includes('+66')) {
                _customerPhone = phone.replace("+66", "0")
              }
              if (_customerPhone.includes('*') || _customerPhone.includes('(') || _customerPhone.includes(')') || _customerPhone.includes('-')) {
                _customerPhone = '';
              }

              let _taxId = 'N/A';
              let _taxNumberType = 'OTHR';
              let _branchNumber = '';
              let _branchText = '';

              const _customerName = first_name + ' ' + last_name;
              const _customerEmail = email;

              const _customer = {
                addressLineOne: _address,
                addressLineTwo: '',
                branchNumber: _branchNumber,
                branchText: _branchText,
                email: _customerEmail,
                name: _customerName,
                phone: _customerPhone !== '' ? _customerPhone : '',
                phoneCountryCode: '+66',
                postcode: postcode ? postcode : '00000',
                taxId: _taxId,
                taxNumberType: _taxNumberType,
                language: 'th',
                countryCode: 'TH',
                countryName: 'ไทย',
                unstructure: true,
            };

                _docs.push({
                  key: number,
                  date: _date,
                  dateBE: _dateBE,
                  dateCE: _dateCE,
                  updatedTime: _dateBE,
                  createdDate: _createdDate,
                  createdDateBE: _createdDateBE,
                  createdDateCE: _createdDateCE,
                  createdTime: _createdTime,
                  number: number,
                  customerName: _customerName,
                  customerAddress: _address,
                  customer: _customer,
                  items: _items,
                  // customerEmail: invoice_list[i].email,
                  // customerPhoneNumber: invoice_list[i].phoneNumber,
                  amountTotal: _amountTotal,
                  vatTotal: _vatTotal,
                  grandTotal: _grandTotal,
                  customerPostcode: postcode,
                  discount: _discount,
                  includeVat: prices_include_tax,
                  nonVatPriceTotal: _nonVatPriceTotal,
                  zeroVatPriceTotal: _zeroVatPriceTotal,
                  vatPriceTotal: _vatPriceTotal,
                  // receivedBy: invoice_list[i].salesperson,
                  etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.reference === number),
                });
              }

              if (primarySort === 'createdTime') {
                if (isSortedDescendingCreatedTime) {
                  _docs.sort(function (a, b) {
                    var aa = a.createdDate,
                      bb = b.createdDate;
                    return aa < bb ? 1 : aa > bb ? -1 : 0;
                  });
                } else {
                  _docs.sort(function (a, b) {
                    var aa = a.createdDate,
                      bb = b.createdDate;
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                  });
                }
              } else if (primarySort === 'updatedTime') {
                if (isSortedDescendingUpdatedTime) {
                  _docs.sort(function (a, b) {
                    var aa = a.date,
                      bb = b.date;
                    return aa < bb ? 1 : aa > bb ? -1 : 0;
                  });
                } else {
                  _docs.sort(function (a, b) {
                    var aa = a.date,
                      bb = b.date;
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                  });
                }
              } else if (primarySort === 'number') {
                if (isSortedDescendingNumber) {
                  _docs.sort((a, b) => {
                    if (b.number < a.number) {
                      return -1;
                    }
                    if (b.number > a.number) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  _docs.sort((a, b) => {
                    if (a.number < b.number) {
                      return -1;
                    }
                    if (a.number > b.number) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'customerName') {
                if (isSortedDescendingCustomerName) {
                  _docs.sort((a, b) => {
                    if (b.customerName < a.customerName) {
                      return -1;
                    }
                    if (b.customerName > a.customerName) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  _docs.sort((a, b) => {
                    if (a.customerName < b.customerName) {
                      return -1;
                    }
                    if (a.customerName > b.customerName) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'vatTotal') {
                if (isSortedDescendingVatTotal) {
                  _docs.sort(function (a, b) {
                    return b.vatTotal - a.vatTotal;
                  });
                } else {
                  _docs.sort(function (a, b) {
                    return a.vatTotal - b.vatTotal;
                  });
                }
              } else if (primarySort === 'grandTotal') {
                if (isSortedDescendingGrandTotal) {
                  _docs.sort(function (a, b) {
                    return b.grandTotal - a.grandTotal;
                  });
                } else {
                  _docs.sort(function (a, b) {
                    return a.grandTotal - b.grandTotal;
                  });
                }
              }

              setItems(_docs);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
                      
                  
                }).catch((error) => {
                  //Acquire token silent failure, and send an interactive request
                  console.log(error);
                //   instance.acquireTokenRedirect(silentRequest);
                  setItems([]);
    
                  setLoadDataComplete(true);
                  setGetNewDocuments(false);
                  setIsCreateDocumentComplete(false);
                });
            
            
        };

        searchInvoices();
      } else {
        const listInvoices = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
              const [response, responseAllDocs] = await Promise.all([
                axios
                //   .get('http://localhost:7071/api/WooCommerce_getOrders', {
                  .get(API_URL + '/sources/woo-commerce/orders', {
                    headers: {
                      'Authorization': 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId,
                    },
                    params: {
                      status: orderStatus.key,
                      from: fromISO,
                      to: toISO,
                      page: currentPage,
                      per_page: pageSize
                    },
                  })
                  .catch((error) => console.log(error)),

                axios
                  .get(API_URL + '/etax/documents/ALL/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                    headers: {
                      'Authorization': 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId
                    },
                  })
                  .catch((error) => console.log(error)),
              ]);

              console.log('response', response)

              let orders = response.data.orders

              setPageData(paginate(525, currentPage, pageSize, 10));

              const _docs = [];

              for (let i = 0; i < Math.min(pageSize, orders.length); i++) {
                const { 
                  number, 
                  date_created_gmt, 
                  date_modified_gmt, 
                  discount_total, 
                  prices_include_tax, 
                  billing, 
                  line_items, 
                  shipping_lines, 
                } = orders[i]

                const { 
                  first_name, 
                  last_name, 
                  address_1, 
                  address_2, 
                  city, 
                  state, 
                  postcode, 
                  email, 
                  phone 
                } = billing

                let _address = '';

                if (address_1) {
                  _address = _address + address_1;
                }

                if (address_2) {
                  _address = _address + ' ' + address_2;
                }

                if (city) {
                  _address = _address + ' ' + city;
                }

                if (state) {
                  if (state.match(/\d+/) && provinceList.some(_province => _province.key === state.match(/\d+/)[0])) {
                    const provinceObj = provinceList.find(_province => _province.key === state.match(/\d+/)[0])
                    _address = _address + ' ' + provinceObj.text;
                  } else {
                    _address = _address + ' ' + state;
                  }
                }

                const _items = line_items.map((item, index) => {
                  const { 
                    name, 
                    quantity, 
                    subtotal, 
                    subtotal_tax, 
                    total, 
                    total_tax,  
                    sku, 
                  } = item;

                  if (registerVat) {
                      return {
                          number: index + 1 + '',
                          description: name,
                          quantity: Number(quantity),
                          unitCode: '',
                          unitName: '-',
                          price: Math.round(((Number(total) + Number(total_tax)) / Number(quantity)) * 100) / 100,
                          discount: Math.round(((Number(subtotal) + Number(subtotal_tax)) - (Number(total) + Number(total_tax))) * 100) / 100,
                          percentVat: percentVat,
                          percentVatText: percentVatText,
                          total: Math.round((Number(total) + Number(total_tax)) * 100) / 100,
                          sku: sku
                      };
                  } else {
                      return {
                          number: index + 1 + '',
                          description: name,
                          quantity: Number(quantity),
                          unitCode: '',
                          unitName: '-',
                          price: Math.round(((Number(total) + Number(total_tax)) / Number(quantity)) * 100) / 100,
                          discount: Math.round(((Number(subtotal) + Number(subtotal_tax)) - (Number(total) + Number(total_tax))) * 100) / 100,
                          percentVat: zeroVat,
                          percentVatText: zeroVatText,
                          total: Math.round((Number(total) + Number(total_tax)) * 100) / 100,
                          sku: sku
                      };
                  }
              });

              if (showShippingFee) {
                if (shipping_lines.length) {
                  const { 
                    total
                  } = shipping_lines[0]
  
                  _items.push({
                      number: (_items.length + 1) + '',
                      description: 'ค่าขนส่ง',
                      quantity: 1,
                      unitCode: '',
                      unitName: '-',
                      price: Number(total),
                      discount: 0,
                      percentVat: registerVat ? percentVat : zeroVat,
                      percentVatText: registerVat ? percentVatText : zeroVatText,
                      total: Number(total),
                      sku: ''
                  });
                }
              }

              const _discount = Number(discount_total);
              let _amountTotal = 0;
              let _vatTotal = 0;
              let _grandTotal = 0;

              let _vatPriceTotal = 0;
              let _zeroVatPriceTotal = 0;
              let _nonVatPriceTotal = 0;

              if (prices_include_tax) {
                  _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                  _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                  _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                  _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                  _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
              } else {
                  _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                  _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                  _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                  _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
              }


              let _date = ''
              let _dateBE = ''
              let _dateCE = ''

              if (date_modified_gmt) {
                _date = new Date(date_modified_gmt).toISOString()
                _dateBE = onFormatDate(new Date(date_modified_gmt), 'BE')
                _dateCE = onFormatDate(new Date(date_modified_gmt), 'CE')
              }

              let _createdDate = ''
              let _createdDateBE = ''
              let _createdDateCE = ''
              if (date_created_gmt) {
                _createdDate = new Date(date_created_gmt).toISOString()
                _createdDateBE = onFormatDate(new Date(date_created_gmt), 'BE')
                _createdDateCE = onFormatDate(new Date(date_created_gmt), 'CE')
              }

              let _createdTime = onFormatDate(new Date(date_created_gmt), 'BE')

              let _customerPhone = phone;
              if (phone.includes('+66')) {
                _customerPhone = phone.replace("+66", "0")
              }
              if (_customerPhone.includes('*') || _customerPhone.includes('(') || _customerPhone.includes(')') || _customerPhone.includes('-')) {
                _customerPhone = '';
              }

              let _taxId = 'N/A';
              let _taxNumberType = 'OTHR';
              let _branchNumber = '';
              let _branchText = '';

              const _customerName = first_name + ' ' + last_name;
              const _customerEmail = email;

              let _postcode = '00000'
              if (postcode) {
                if (!isNaN(postcode) && postcode.length === 5) {
                  _postcode = postcode
                }
              }

              const _customer = {
                addressLineOne: _address,
                addressLineTwo: '',
                branchNumber: _branchNumber,
                branchText: _branchText,
                email: _customerEmail,
                name: _customerName,
                phone: _customerPhone !== '' ? _customerPhone : '',
                phoneCountryCode: '+66',
                postcode: _postcode,
                taxId: _taxId,
                taxNumberType: _taxNumberType,
                language: 'th',
                countryCode: 'TH',
                countryName: 'ไทย',
                unstructure: true,
            };

                _docs.push({
                  key: number,
                  date: _date,
                  dateBE: _dateBE,
                  dateCE: _dateCE,
                  updatedTime: _dateBE,
                  createdDate: _createdDate,
                  createdDateBE: _createdDateBE,
                  createdDateCE: _createdDateCE,
                  createdTime: _createdTime,
                  number: number,
                  customerName: _customerName,
                  customerAddress: _address,
                  customer: _customer,
                  items: _items,
                  // customerEmail: invoice_list[i].email,
                  // customerPhoneNumber: invoice_list[i].phoneNumber,
                  amountTotal: _amountTotal,
                  vatTotal: _vatTotal,
                  grandTotal: _grandTotal,
                  customerPostcode: postcode,
                  discount: _discount,
                  includeVat: prices_include_tax,
                  nonVatPriceTotal: _nonVatPriceTotal,
                  zeroVatPriceTotal: _zeroVatPriceTotal,
                  vatPriceTotal: _vatPriceTotal,
                  // receivedBy: invoice_list[i].salesperson,
                  etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.reference === number),
                });
              }

              if (primarySort === 'createdTime') {
                if (isSortedDescendingCreatedTime) {
                  _docs.sort(function (a, b) {
                    var aa = a.createdDate,
                      bb = b.createdDate;
                    return aa < bb ? 1 : aa > bb ? -1 : 0;
                  });
                } else {
                  _docs.sort(function (a, b) {
                    var aa = a.createdDate,
                      bb = b.createdDate;
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                  });
                }
              } else if (primarySort === 'updatedTime') {
                if (isSortedDescendingUpdatedTime) {
                  _docs.sort(function (a, b) {
                    var aa = a.date,
                      bb = b.date;
                    return aa < bb ? 1 : aa > bb ? -1 : 0;
                  });
                } else {
                  _docs.sort(function (a, b) {
                    var aa = a.date,
                      bb = b.date;
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                  });
                }
              } else if (primarySort === 'number') {
                if (isSortedDescendingNumber) {
                  _docs.sort((a, b) => {
                    if (b.number < a.number) {
                      return -1;
                    }
                    if (b.number > a.number) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  _docs.sort((a, b) => {
                    if (a.number < b.number) {
                      return -1;
                    }
                    if (a.number > b.number) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'customerName') {
                if (isSortedDescendingCustomerName) {
                  _docs.sort((a, b) => {
                    if (b.customerName < a.customerName) {
                      return -1;
                    }
                    if (b.customerName > a.customerName) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  _docs.sort((a, b) => {
                    if (a.customerName < b.customerName) {
                      return -1;
                    }
                    if (a.customerName > b.customerName) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'vatTotal') {
                if (isSortedDescendingVatTotal) {
                  _docs.sort(function (a, b) {
                    return b.vatTotal - a.vatTotal;
                  });
                } else {
                  _docs.sort(function (a, b) {
                    return a.vatTotal - b.vatTotal;
                  });
                }
              } else if (primarySort === 'grandTotal') {
                if (isSortedDescendingGrandTotal) {
                  _docs.sort(function (a, b) {
                    return b.grandTotal - a.grandTotal;
                  });
                } else {
                  _docs.sort(function (a, b) {
                    return a.grandTotal - b.grandTotal;
                  });
                }
              }

              console.log('_docs', _docs)
              setItems(_docs);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
            //   instance.acquireTokenRedirect(silentRequest);
              setItems([]);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            });
        };

        listInvoices();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    setCurrentPage(currentPage)
    setLoadDataComplete(false);
    setItems([]);
    setGetNewDocuments(true);

    setSearch('');

    sessionStorage.setItem('wooCommerce-currentPage', currentPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    console.log('isCreateDocumentComplete useEffect: ', isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
      let _items = selection.getItems();
      console.log('_items: ', _items);

      let _selectedItems = selection.getSelection();

      if (isCreateDocumentComplete) {
        setIsCreateDocumentComplete(false);

        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status != '') {
            _items[i].status = '';
          }
        }
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status != '') {
            _items[i].status = '';
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {
          console.log('select item key: ', _selectedItems[i].key);

          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === _selectedItems[i].key) {
              console.log('set pending item key: ', _items[j].key);
              _items[j].status = 'pending';
              console.log('_items: ', _items);
            }
          }
        }
      }

      setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const handleSort = (primary, isSorted) => {
    if (items.length > 0) {
      let _items = selection.getItems();
      let _selectedItems = selection.getSelection();
      console.log('selection: ', selection.getSelection());

      for (let i = 0; i < _selectedItems.length; i++) {
        console.log('select item key: ', _selectedItems[i].key);
        _selectedItems[i].status = '';

        for (let j = 0; j < _items.length; j++) {
          if (_items[j].key === _selectedItems[i].key) {
            console.log('item key: ', _items[j].key);
            _items[j].status = '';
            _selectedItems[i].status = '';
          }
        }
      }

      selection.setAllSelected(false);

      const _newDocuments = [...items]

      if (primary === 'createdTime') {
        if (isSorted) {
          _newDocuments.sort(function (a, b) {
            var aa = a.createdDate,
              bb = b.createdDate;
            return aa < bb ? 1 : aa > bb ? -1 : 0;
          });
        } else {
          _newDocuments.sort(function (a, b) {
            var aa = a.createdDate,
              bb = b.createdDate;
            return aa < bb ? -1 : aa > bb ? 1 : 0;
          });
        }
      } else if (primary === 'updatedTime') {
        if (isSorted) {
          _newDocuments.sort(function (a, b) {
            var aa = a.date,
              bb = b.date;
            return aa < bb ? 1 : aa > bb ? -1 : 0;
          });
        } else {
          _newDocuments.sort(function (a, b) {
            var aa = a.date,
              bb = b.date;
            return aa < bb ? -1 : aa > bb ? 1 : 0;
          });
        }
      } else if (primary === 'number') {
        if (isSorted) {
          _newDocuments.sort((a, b) => {
            if (b.number < a.number) {
              return -1;
            }
            if (b.number > a.number) {
              return 1;
            }
            return 0;
          });
        } else {
          _newDocuments.sort((a, b) => {
            if (a.number < b.number) {
              return -1;
            }
            if (a.number > b.number) {
              return 1;
            }
            return 0;
          });
        }
      } else if (primary === 'customerName') {
        if (isSorted) {
          _newDocuments.sort((a, b) => {
            if (b.customerName < a.customerName) {
              return -1;
            }
            if (b.customerName > a.customerName) {
              return 1;
            }
            return 0;
          });
        } else {
          _newDocuments.sort((a, b) => {
            if (a.customerName < b.customerName) {
              return -1;
            }
            if (a.customerName > b.customerName) {
              return 1;
            }
            return 0;
          });
        }
      } else if (primary === 'vatTotal') {
        if (isSorted) {
          _newDocuments.sort(function (a, b) {
            return b.vatTotal - a.vatTotal;
          });
        } else {
          _newDocuments.sort(function (a, b) {
            return a.vatTotal - b.vatTotal;
          });
        }
      } else if (primary === 'grandTotal') {
        if (isSorted) {
          _newDocuments.sort(function (a, b) {
            return b.grandTotal - a.grandTotal;
          });
        } else {
          _newDocuments.sort(function (a, b) {
            return a.grandTotal - b.grandTotal;
          });
        }
      }

      setItems(_newDocuments);
    }
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const columns = [
      {
        key: 'column1',
        name: t('wooCommerce.createdTime'),
        fieldName: 'createdTime',
        minWidth: 70,
        maxWidth: 115,
        isResizable: true,
        isSorted: primarySort === 'createdTime' ? true : false,
        isSortedDescending: isSortedDescendingCreatedTime,
        isSortedAscending: !isSortedDescendingCreatedTime,
        onColumnClick: () => {
          console.log('Sort document createTime!');
          setPrimarySort('createdTime');
          setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

          handleSort('createdTime', !isSortedDescendingCreatedTime);

          sessionStorage.setItem('wooCommerce-primarySort', 'createdTime');
          sessionStorage.setItem('wooCommerce-sort-createdTime', !isSortedDescendingCreatedTime === false ? 'false' : 'true');

          sessionStorage.removeItem('wooCommerce-sort-updatedTime');
          sessionStorage.removeItem('wooCommerce-sort-number');
          sessionStorage.removeItem('wooCommerce-sort-customerName');
          sessionStorage.removeItem('wooCommerce-sort-vatTotal');
          sessionStorage.removeItem('wooCommerce-sort-grandTotal');

          setIsCreateDocumentComplete(false);
          setSelectedItems([]);
        },
        data: 'string',
        onRender: (item) => {
            return <span>{item.createdTime}</span>;
        },
    },
    {
      key: 'column2',
      name:
          t('wooCommerce.day1') +
          (orderStatus.key === 'any'
              ? t('wooCommerce.updatedDate')
              : orderStatus.key === 'pending'
              ? `${t('wooCommerce.pendingTypeColumn')}${t('wooCommerce.day2')}`
              : orderStatus.key === 'processing'
              ? `${t('wooCommerce.processingTypeColumn')}${t('wooCommerce.day2')}`
              : orderStatus.key === 'on-hold'
              ? `${t('wooCommerce.on-holdTypeColumn')}${t('wooCommerce.day2')}`
              : orderStatus.key === 'completed'
              ? `${t('wooCommerce.completedTypeColumn')}${t('wooCommerce.day2')}`
              : orderStatus.key === 'cancelled'
              ? `${t('wooCommerce.cancelledTypeColumn')}${t('wooCommerce.day2')}`
              : orderStatus.key === 'refunded'
              ? `${t('wooCommerce.refundedTypeColumn')}${t('wooCommerce.day2')}`
              : orderStatus.key === 'failed'
              ? `${t('wooCommerce.failedTypeColumn')}${t('wooCommerce.day2')}`
              : orderStatus.key === 'trash'
              ? `${t('wooCommerce.trashTypeColumn')}${t('wooCommerce.day2')}`
              : `${orderStatus.text}${t('wooCommerce.day2')}`),
      fieldName: 'updatedTime',
      minWidth: 70,
      maxWidth: 125,
      isResizable: true,
      isSorted: primarySort === 'updatedTime' ? true : false,
      isSortedDescending: isSortedDescendingUpdatedTime,
      isSortedAscending: !isSortedDescendingUpdatedTime,
      onColumnClick: () => {
        console.log('Sort document updatedTime!');
        setPrimarySort('updatedTime');
        setIsSortedDescendingUpdatedTime(!isSortedDescendingUpdatedTime);

        handleSort('updatedTime', !isSortedDescendingUpdatedTime);

        sessionStorage.setItem('wooCommerce-primarySort', 'updatedTime');
        sessionStorage.setItem('wooCommerce-sort-updatedTime', !isSortedDescendingUpdatedTime === false ? 'false' : 'true');

        sessionStorage.removeItem('wooCommerce-sort-createdTime');
        sessionStorage.removeItem('wooCommerce-sort-number');
        sessionStorage.removeItem('wooCommerce-sort-customerName');
        sessionStorage.removeItem('wooCommerce-sort-vatTotal');
        sessionStorage.removeItem('wooCommerce-sort-grandTotal');

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
          return <span>{item.updatedTime}</span>;
      },
  },
    {
      key: 'column3',
      name: t('wooCommerce.number'),
      fieldName: 'number',
      minWidth: 120,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        handleSort('number', !isSortedDescendingNumber);

        sessionStorage.setItem('wooCommerce-primarySort', 'number');
        sessionStorage.setItem('wooCommerce-sort-number', !isSortedDescendingNumber === false ? 'false' : 'true');

        sessionStorage.removeItem('wooCommerce-sort-createdTime');
        sessionStorage.removeItem('wooCommerce-sort-updatedTime');
        sessionStorage.removeItem('wooCommerce-sort-customerName');
        sessionStorage.removeItem('wooCommerce-sort-vatTotal');
        sessionStorage.removeItem('wooCommerce-sort-grandTotal');

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.number}</span>;
      },
    },
    {
      key: 'column4',
      name: t('wooCommerce.customerName'),
      fieldName: 'customerName',
      minWidth: 100,
      maxWidth: 110,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort document customerName!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        handleSort('customerName', !isSortedDescendingCustomerName);

        sessionStorage.setItem('wooCommerce-primarySort', 'customerName');
        sessionStorage.setItem('wooCommerce-sort-customerName', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.removeItem('wooCommerce-sort-createdTime');
        sessionStorage.removeItem('wooCommerce-sort-updatedTime');
        sessionStorage.removeItem('wooCommerce-sort-number');
        sessionStorage.removeItem('wooCommerce-sort-vatTotal');
        sessionStorage.removeItem('wooCommerce-sort-grandTotal');

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
    },
    {
      key: 'column5',
      name: t('wooCommerce.customerAddress'),
      fieldName: 'name',
      minWidth: 120,
      maxWidth: 270,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      isCollapsible: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerAddress}</span>;
      },
    },
    {
      key: 'column6',
      name: t('wooCommerce.vatTotal'),
      fieldName: 'vatTotal',
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      isSorted: primarySort === 'vatTotal' ? true : false,
      isSortedDescending: isSortedDescendingVatTotal,
      isSortedAscending: !isSortedDescendingVatTotal,
      onColumnClick: () => {
        console.log('Sort document vatTotal!');
        setPrimarySort('vatTotal');
        setIsSortedDescendingVatTotal(!isSortedDescendingVatTotal);

        handleSort('vatTotal', !isSortedDescendingVatTotal);

        sessionStorage.setItem('wooCommerce-primarySort', 'vatTotal');
        sessionStorage.setItem('wooCommerce-sort-vatTotal', !isSortedDescendingVatTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('wooCommerce-sort-createdTime');
        sessionStorage.removeItem('wooCommerce-sort-updatedTime');
        sessionStorage.removeItem('wooCommerce-sort-number');
        sessionStorage.removeItem('wooCommerce-sort-customerName');
        sessionStorage.removeItem('wooCommerce-sort-grandTotal');

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: "block", textAlign: "right" }}>
            {item.vatTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
    },
    {
      key: 'column7',
      name: t('wooCommerce.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 70,
      isResizable: true,
      isSorted: primarySort === 'grandTotal' ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        console.log('Sort document grandTotal!');
        setPrimarySort('grandTotal');
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        handleSort('grandTotal', !isSortedDescendingGrandTotal);

        sessionStorage.setItem('wooCommerce-primarySort', 'grandTotal');
        sessionStorage.setItem('wooCommerce-sort-grandTotal', !isSortedDescendingGrandTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('wooCommerce-sort-createdTime');
        sessionStorage.removeItem('wooCommerce-sort-updatedTime');
        sessionStorage.removeItem('wooCommerce-sort-number');
        sessionStorage.removeItem('wooCommerce-sort-customerName');
        sessionStorage.removeItem('wooCommerce-sort-vatTotal');

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: "block", textAlign: "right" }}>
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
    },
    {
      key: 'column8',
      name: 'Leceipt',
      fieldName: 'etaxCreated',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        if (item.etaxCreated) {
          return (
            <div style={{ textAlign: "center" }}>
              <Icon iconName="SkypeCircleCheck" style={{ fontSize: "20px", color: "green" }} />
            </div>
          );
        }
      },
    },
    {
      key: 'column9',
      name: '',
      fieldName: 'command',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === "pending") {
            return <span style={{ color: "#106EBE" }}>{t("wooCommerce.pending")}</span>;
          } else if (item.status === "processing") {
            return <Spinner label={t("wooCommerce.processing")} labelPosition="right" style={{ marginLeft: "0px" }} />;
          } else if (item.status === "successful") {
            return <span style={{ color: "green" }}>{t("wooCommerce.success")}</span>;
          } else if (item.status === "fail") {
            return <span style={{ color: "red" }}>{t("wooCommerce.fail")}</span>;
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t("wooCommerce.create")}
                iconProps={{ iconName: "Add", style: { color: "#106ebe" } }}
                menuProps={{
                  items: [
                    {
                      key: "RECEIPT-TAXINVOICE",
                      text: t("wooCommerce.receipt-taxinvoice"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: () => {
                        createDocument("RECEIPT-TAXINVOICE", item);
                      },
                    },
                    {
                      key: "RECEIPT-TAXINVOICE-ABB",
                      text: t("wooCommerce.receipt-taxinvoice-abb"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: () => {
                        createDocument("RECEIPT-TAXINVOICE-ABB", item);
                      },
                    },
                    {
                      key: "RECEIPT",
                      text: t("wooCommerce.receipt"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: () => {
                        createDocument("RECEIPT", item);
                      },
                    },
                    {
                      key: "INVOICE-TAXINVOICE",
                      text: t("wooCommerce.invoice-taxinvoice"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: () => {
                        createDocument("INVOICE-TAXINVOICE", item);
                      },
                    },
                    {
                      key: "DELIVERYORDER-TAXINVOICE",
                      text: t("wooCommerce.deliveryorder-taxinvoice"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: () => {
                        createDocument("DELIVERYORDER-TAXINVOICE", item);
                      },
                    },
                    {
                      key: "TAXINVOICE",
                      text: t("wooCommerce.taxinvoice"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: () => {
                        createDocument("TAXINVOICE", item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        }
      },
    },
  ];

  const padLeadingZeros = (num, size) => {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
} ;

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + '/sources',
              {
                source: 'woo-commerce',
                replace: '/disabled',
                value: true,
              },
              {
                headers: {
                  'Authorization': 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
              },
              }
            )
            .then((response) => {
              console.log(response);

              history.push({
                pathname: '/etax/sources/woo-commerce/connect',
                state: {},
              });

              setIsLoading(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const createSelectedDocuments = async (documentType) => {
    let _selectionItems = selection.getItems();

    let postUrl = '';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf';
        break;
      case 'RECEIPT':
        postUrl = API_URL + '/etax/documents/receipts/pdf-xml';
        break;
      case 'INVOICE-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/deliveryorders-taxinvoices/pdf-xml';
        break;
      case 'TAXINVOICE':
        postUrl = API_URL + '/etax/documents/taxinvoices/pdf-xml';
        break;
      default:
        break;
    }

    if (postUrl) {
      let _countingNumberLength = countingNumber.length;
      let _countingNumber = Number(countingNumber);

      await instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          for (let i = 0; i < selectedItems.length; i++) {
            console.log('create selected document: ', selectedItems[i]);

            for (let j = 0; j < _selectionItems.length; j++) {
              if (_selectionItems[j].key === selectedItems[i].key) {
                _selectionItems[j].status = 'processing';

                setItems([..._selectionItems]);

                let _number = prefixNumber + padLeadingZeros(Number(_countingNumber), _countingNumberLength);
                _countingNumber = padLeadingZeros(Number(_countingNumber) + 1, _countingNumberLength);
                setCountingNumber(_countingNumber);

                const promise_PDF_XML = await new Promise(async (resolve) => {
                  await axios
                    .post(
                      postUrl,
                      {
                        number: _number,
                        reference: selectedItems[i].number,
                        company: {
                          ...company,
                          includeVat: selectedItems[i].includeVat,
                        },
                        customer: selectedItems[i].customer,
                        date: selectedItems[i].date,
                        dateBE: selectedItems[i].dateBE,
                        dateCE: selectedItems[i].dateCE,
                        // dueDate: selectedItems[i].dueDate,
                        // dueDateBE: selectedItems[i].dueDateBE,
                        // dueDateCE: selectedItems[i].dueDateCE,
                        items: selectedItems[i].items,
                        includeVat: selectedItems[i].includeVat,
                        discount: selectedItems[i].discount * 1,
                        amountTotal: selectedItems[i].amountTotal * 1,
                        vatTotal: selectedItems[i].vatTotal * 1,
                        grandTotal: selectedItems[i].grandTotal * 1,
                        nonVatPriceTotal: selectedItems[i].nonVatPriceTotal,
                        zeroVatPriceTotal: selectedItems[i].zeroVatPriceTotal,
                        vatPriceTotal: selectedItems[i].vatPriceTotal,
                        percentVat: percentVat,
                        receivedBy: '',
                        reIssue: false,
                        grandTotalTH: numberToTextTh(selectedItems[i].grandTotal * 1),
                        grandTotalEN: numberToTextEn(selectedItems[i].grandTotal * 1),
                        note: ""
                      },
                      {
                        headers: {
                          'Authorization': 'Bearer ' + tokenResponse.accessToken,
                          'cid': companyId
                      },
                      }
                    )
                    .then(
                      async (response) => {
                        console.log('Create document response: ', response);

                        let count = 0;

                        let interval = setInterval(async () => {
                          await axios
                            .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                              headers: {
                                'Authorization': 'Bearer ' + tokenResponse.accessToken,
                                'Content-Type': 'application/json',
                                'cid': companyId
                              },
                            })
                            .then((jobsResponse) => {
                              count += 1;
                              console.log('Jobs response: ', j, jobsResponse.data.status, count);

                              if (jobsResponse.data.status === 'complete') {
                                _selectionItems[j].status = 'successful';
                                _selectionItems[j].etaxCreated = true;

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(true);
                              } else if (count === 300) {
                                _selectionItems[j].status = 'fail';

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(false);
                              }
                            });
                        }, 1000);
                      },
                      (error) => {
                        console.log('Error Create document response: ', error);
                        _selectionItems[j].status = 'fail';
                        setItems([..._selectionItems]);
                        resolve(false);
                      }
                    );
                }); //promise

                Promise.all([promise_PDF_XML]).then((value) => {
                  console.log(i, value);
                });
              } //if
            } //for j
          } //for i
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });

      setIsCreateDocumentComplete(true);
      setIsCreateDocument(false);
    }

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  return (
    <div style={homeStyles}>
      {!isLoading && (
        <div>
          <Stack>
            <center>
              <h2 style={{ marginLeft: "0px", marginTop: "25px" }}>{t("wooCommerce.header")}</h2>
              {!loadDataComplete && (
                <div>
                  <Spinner
                    label={t("wooCommerce.loading")}
                    labelPosition="right"
                    style={{
                      marginTop: "21px",
                      marginBottom: "2px",
                    }}
                  />
                  <br />
                </div>
              )}
            </center>
          </Stack>

          <Stack horizontal horizontalAlign="center">
            <Toggle
                label={t("lazada.displayShipCost")}
                inlineLabel
                onText={t("lazada.on")}
                offText={t("lazada.off")}
                checked={showShippingFee}
                disabled={!loadDataComplete || isCreateDocument}
                onChange={() => {
                    setShowShippingFee(!showShippingFee);

                    if (!showShippingFee) {
                        localStorage.setItem("showShippingFee", "yes");
                    } else {
                        localStorage.setItem("showShippingFee", "no");
                    }

                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);
                }}
            />
        </Stack>

          {/* {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
            <Stack vertical tokens={{ childrenGap: "10px" }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                <TextField
                  name="createdBy"
                  label={t("wooCommerce.createdBy")}
                  description={t("wooCommerce.createdByDesc")}
                  styles={{ root: { width: 200 } }}
                  onChange={(e) => {
                    setCreatedBy(e.target.value);
                  }}
                  value={createdBy}
                  disabled={isCreateDocument}
                />
              </Stack>
              <br />
            </Stack>
          )} */}

            {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
              <Stack vertical tokens={{ childrenGap: "10px" }}>
                  <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                      <h3>{t("wooCommerce.subHeader")}</h3>
                  </Stack>
                  <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                      <TextField
                          name="prefixNumber"
                          label={t("wooCommerce.prefixNumber")}
                          description={t("wooCommerce.prefixNumberDescription")}
                          styles={{ root: { width: 200 } }}
                          onChange={(e) => {
                              setPrefixNumber(e.target.value);
                          }}
                          value={prefixNumber}
                          disabled={isCreateDocument}
                      />
                      <TextField
                          name="countingNumber"
                          label={t("wooCommerce.countingNumber")}
                          description={countingNumber.length === 0 ? "" : t("wooCommerce.countingNumberDesciption")}
                          styles={{ root: { width: 150 } }}
                          onChange={(e) => {
                              if (!/\D/.test(e.target.value)) {
                                  setCountingNumber(e.target.value);
                              }
                          }}
                          value={countingNumber}
                          errorMessage={countingNumber.length === 0 ? t("wooCommerce.countingNumberErrorMessage") : ""}
                          disabled={isCreateDocument}
                          required
                      />
                  </Stack>

                  {countingNumber.length > 0 ? (
                      <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                          {!isCreateDocument ? (
                              <h4>
                                  {t("wooCommerce.startDocumentNumber")} {prefixNumber}
                                  {countingNumber}
                              </h4>
                          ) : (
                              <h4>
                                  {t("wooCommerce.endDocumentNumber")} {prefixNumber}
                                  {countingNumber}
                              </h4>
                          )}
                      </Stack>
                  ) : (
                      <br />
                  )}
              </Stack>
          )}

          {selectedItems.length > 0 && !isCreateDocument && (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
              <PrimaryButton
                text={t("wooCommerce.createDocument")}
                iconProps={{ iconName: "Add" }}
                menuProps={{
                  items: [
                    {
                      key: "RECEIPT-TAXINVOICE",
                      text: t("wooCommerce.receipt-taxinvoice"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments("RECEIPT-TAXINVOICE");
                      },
                    },
                    {
                      key: "RECEIPT-TAXINVOICE-ABB",
                      text: t("wooCommerce.receipt-taxinvoice-abb"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments("RECEIPT-TAXINVOICE-ABB");
                      },
                    },
                    {
                      key: "RECEIPT",
                      text: t("wooCommerce.receipt"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments("RECEIPT");
                      },
                    },
                    {
                      key: "INVOICE-TAXINVOICE",
                      text: t("wooCommerce.invoice-taxinvoice"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments("INVOICE-TAXINVOICE");
                      },
                    },
                    {
                      key: "DELIVERYORDER-TAXINVOICE",
                      text: t("wooCommerce.deliveryorder-taxinvoice"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments("DELIVERYORDER-TAXINVOICE");
                      },
                    },
                    {
                      key: "TAXINVOICE",
                      text: t("wooCommerce.taxinvoice"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments("TAXINVOICE");
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
              <DefaultButton
                text={t("wooCommerce.cancel")}
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  let _items = selection.getItems();
                  let _selectedItems = selection.getSelection();
                  console.log("selection: ", selection.getSelection());

                  for (let i = 0; i < _selectedItems.length; i++) {
                    console.log("select item key: ", _selectedItems[i].key);
                    _selectedItems[i].status = "";

                    for (let j = 0; j < _items.length; j++) {
                      if (_items[j].key === _selectedItems[i].key) {
                        console.log("item key: ", _items[j].key);
                        _items[j].status = "";
                        _selectedItems[i].status = "";
                      }
                    }
                  }

                  selection.setAllSelected(false);

                  setItems(_items);
                  console.log("selection: ", selection.getSelection());
                }}
              />
              <br />
            </Stack>
          )}

          {isCreateDocument && (
            <div>
              <Spinner label={t("wooCommerce.signProcessing")} size={SpinnerSize.large} />
            </div>
          )}

          {isCreateDocumentComplete && (
            <center>
              <PrimaryButton
                text={t("wooCommerce.createdDocuments")}
                iconProps={{ iconName: "Documentation" }}
                onClick={async () => {
                  console.log(selectedItems);
                  history.push({
                    pathname: "/etax/documents/all",
                    state: {},
                  });
                }}
              />
            </center>
          )}
          <br />
          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: "98%",
                minWidth: "800px",
                maxWidth: "1350px",
              }}
            >
              <Stack 
                horizontal 
                styles={{
                  root: {
                    backgroundColor: 'white',
                  },
                }}
                horizontalAlign="space-between"
              >
                <Stack
                  styles={{
                    root: {
                      width: "calc(100vw - 700px)",
                      minWidth: "700px",
                      maxWidth: "900px",
                    },
                  }}
                >
                <CommandBar 
                  items={commandBarItems} 
                  overflowItems={overflowItems}
                />
              </Stack>
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                    },
                  }}
                  tokens={{ childrenGap: "10px" }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        width: 270,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete}
                    placeholder={t("wooCommerce.searchBoxPlaceholder")}
                    onChange={(e) => {
                      setSearch(e ? e.target.value : "");
                    }}
                    onClear={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    onSearch={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    value={search}
                  />
                  <PrimaryButton
                    disabled={!loadDataComplete || !search}
                    styles={{
                      root: {
                        marginTop: 6,
                        marginRight: 18,
                        width: 90,
                      },
                    }}
                    text={t("wooCommerce.search")}
                    onClick={() => {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                  />
                </Stack>
              </Stack>
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selection={selection}
                selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
              />
            </Stack>
          </Stack>
          <br />
          <center>
            {!(currentPage === 1 && items.length < pageSize) && !(!loadDataComplete || isCreateDocument) && (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{
                  iconName: "DoubleChevronLeft",
                }}
                previousPageIconProps={{
                  iconName: "ChevronLeft",
                }}
                nextPageIconProps={{
                  iconName: "ChevronRight",
                }}
                lastPageIconProps={{
                  iconName: "DoubleChevronRight",
                }}
              />
            )}

            <br />
            <br />
          </center>
        </div>
      )}

      {isLoading && (
        <div>
          <br />
          <br />
          <br />
          <br />
          <Stack>
            <Stack horizontal horizontalAlign="center">
              <div>
                <Spinner label={t("wooCommerce.loading")} labelPosition="down" styles={spinnerStyles} />
              </div>
            </Stack>
          </Stack>
          <br />
        </div>
      )}
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: "center" }, title: { paddingRight: 24 }, subText: { textAlign: "center" } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: "center" } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t("documents.confirm")} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t("documents.cancel")} />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default WooCommerce;
