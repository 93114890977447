import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../../../authConfig';

import { PrimaryButton, Stack, getTheme, Spinner, Separator, ChoiceGroup } from '@fluentui/react';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

//Style

const centerItem = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

const stackTokens = { childrenGap: 10 };

const SmsCheckout = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [credit, setCredit] = useState(50);
  const [price, setPrice] = useState(99);

  const [vatTotal, setVatTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const [base64QrCode, setBase64QrCode] = useState('');

  // Processing dialog style
  const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4,
    },
  };

  const calGrandTotal = (_price) => {
    console.log('Price: ', _price);
    let vat = 0.07;
    let _vatTotal = Math.round(vat * _price * 100) / 100;
    setVatTotal(_vatTotal);
    setGrandTotal(_price + _vatTotal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    console.log('Submit!');

    console.log('Credit: ', credit);
    console.log('Price: ', price);
    console.log('Vat Total: ', vatTotal);
    console.log('Grand Total: ', grandTotal);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            API_URL + '/gbprimepay/qrcode',
            {
              creditType: 'sms',
              creditAmount: credit,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
              responseType: 'arraybuffer',
            }
          )
          .then((response) => {
            console.log('GB Prime Pay data: ', response);

            if (response.data) {
              setBase64QrCode('data:image/png;base64,' + Buffer.from(response.data, 'binary').toString('base64'));
            }

            setIsLoading(false);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);

        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log('userEffect initialize Call!');

    calGrandTotal(price);

    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      {!base64QrCode && !isLoading && (
        <Stack>
          <div
            style={{
              width: '850px',
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 30,
              marginTop: 0,
              background: '#ffffff',
              marginLeft: '0px',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <div className="ms-Grid-row">
              <h3 style={{ margin: '0%' }}>{`1. เลือกปริมาณ SMS`}</h3>&nbsp;&nbsp;
              <ChoiceGroup
                name="plan"
                label={`เติมเครดิต SMS จำนวน`}
                selectedKey={credit}
                required
                options={[
                  {
                    key: 50,
                    text: '               50 SMS (ราคา 99 บาท)              ',
                    imageSrc: '/images/sms-package.png',
                    selectedImageSrc: '/images/sms-package-select.png',
                    imageSize: { height: 50 },
                  },
                  {
                    key: 100,
                    text: '              100 SMS (ราคา 199 บาท)             ',
                    imageSrc: '/images/sms-package.png',
                    selectedImageSrc: '/images/sms-package-select.png',
                    imageSize: { height: 50 },
                  },
                  {
                    key: 250,
                    text: '              250 SMS (ราคา 499 บาท)            ',
                    imageSrc: '/images/sms-package.png',
                    selectedImageSrc: '/images/sms-package-select.png',
                    imageSize: { height: 50 },
                  },
                ]}
                onChange={(e, selectedOption) => {
                  console.log(selectedOption);
                  setCredit(selectedOption.key);

                  switch (selectedOption.key) {
                    case 50:
                      setPrice(99);
                      calGrandTotal(99);
                      break;
                    case 100:
                      setPrice(199);
                      calGrandTotal(199);
                      break;
                    case 250:
                      setPrice(499);
                      calGrandTotal(499);
                      break;
                    case 500:
                      setPrice(999);
                      calGrandTotal(999);
                      break;
                    case 1000:
                      setPrice(1999);
                      calGrandTotal(1999);
                      break;
                    case 3000:
                      setPrice(5999);
                      calGrandTotal(5999);
                      break;
                    default:
                      setPrice(0);
                  }
                }}
              />
              <br />
              <br />
            </div>
          </div>

          <div
            style={{
              width: '850px',
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 30,
              background: '#ffffff',
              marginLeft: '0px',
              marginTop: '30px',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <Stack horizontal tokens={stackTokens}>
              <Stack vertical tokens={stackTokens}>
                <h3 style={{ margin: '0%' }}>2. เลือกวิธีการชำระเงิน</h3>&nbsp;&nbsp;
                <h4 style={{ marginTop: '-5px' }}>เติมเครดิตอัตโนมัติ เร็วทันใจ ตลอด 24 ชม.</h4>
                <Stack horizontal tokens={stackTokens}>
                  <img
                    src={'/images/promtpay-logo.png'}
                    alt="promtPayLogo"
                    width="260"
                    border="0"
                    style={{ marginLeft: '0px', marginTop: '-20px' }}
                  ></img>
                </Stack>
                <small>*ซื้อแล้ว ไม่คืนเงิน</small>
              </Stack>

              <Separator vertical />

              <Stack vertical tokens={stackTokens} style={{ marginLeft: '0px', paddingLeft: '15px' }}>
                <h1 style={{ marginTop: '5px', marginLeft: '410px' }}>สรุปคำสั่งซื้อ</h1>
                <Separator />
                <h3 style={{ textAlign: 'right' }}>
                  ข้อความ SMS สำหรับส่งเอกสาร e-Tax ({credit.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                  &nbsp;SMS)&nbsp;&nbsp;&nbsp;
                  <span style={{ paddingLeft: 90 }}>
                    {price.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท
                  </span>
                </h3>
                <h3 style={{ textAlign: 'right' }}>
                  ภาษีมูลค่าเพิ่ม 7%&nbsp;
                  <span style={{ paddingLeft: 110 }}>
                    {vatTotal.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท
                  </span>
                </h3>
                <br />
                <Separator />
                <h2 style={{ marginTop: '5px', textAlign: 'right' }}>
                  ยอดรวม
                  <span style={{ paddingLeft: 80 }}>
                    {grandTotal.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })} บาท
                  </span>
                </h2>

                <div></div>
                <center>
                  <br />
                  <small>
                    หากกดปุ่ม "ชำระเงิน" ถือว่าท่านยอมรับ{' '}
                    <a href="https://www.leceipt.com/terms-conditions" target="_blank" rel="noreferrer">
                      เงื่อนไขและข้อตกลงการใช้งาน
                    </a>
                  </small>
                  <br />
                  <br />
                  <br />
                  <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
                    {!isLoading ? (
                      <PrimaryButton
                        type="submit"
                        text="ชำระเงินผ่าน QR Code"
                        value="pay"
                        style={{ width: 550, height: 50, fontSize: 25, letterSpacing: '1.5px' }}
                      />
                    ) : (
                      <Spinner label="กำลังดำเนินการ..." labelPosition="right" />
                    )}
                  </Stack>
                </center>
              </Stack>
            </Stack>
            <br />

            <br />
            <br />
          </div>

          <br />
          <br />
        </Stack>
      )}

      {base64QrCode && !isLoading && (
        <Stack vertical>
          <center>
            <h1 style={{ marginLeft: '0px', marginTop: '25px' }}>
              {`เติมเครดิต: ` + credit.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 }) + ` เอกสาร`}
            </h1>
            <h3>ระบบจะเติมเครดิตให้อัตโนมัติหลังจากชำระเงิน ตลอด 24 ชม.</h3>
            <img src={base64QrCode} width="433px" alt="base64Qr" />
          </center>
        </Stack>
      )}

      {isLoading && (
        <Stack>
          <br />
          <br />
          <br />
          <Stack horizontal horizontalAlign="center">
            <div>
              <Spinner label="กำลังดำเนินการ..." labelPosition="down" styles={spinnerStyles} />
            </div>
            <br />
          </Stack>
        </Stack>
      )}
    </form>
  );
};

export default SmsCheckout;
