import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, Icon, PrimaryButton, TextField, FocusTrapZone, Spinner } from '@fluentui/react';

const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
const API_URL = process.env.REACT_APP_API_URL;

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const DynamicsCrmConnect = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const history = useHistory();

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const [status, setStatus] = useState('');

  const [resource, setResource] = useState(sessionStorage.getItem('dynamics-crm-resourceUrl') || '');
  const [clientId, setClientId] = useState(sessionStorage.getItem('dynamics-crm-clientId') || '');
  const [clientSecret, setClientSecret] = useState(sessionStorage.getItem('dynamics-crm-clientSecret') || '');

  const redirectUri = PUBLIC_URL + '/etax/sources/dynamics/crm/connect';
  const oauth2Endpoint = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?scope=${resource}/.default offline_access&response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&client_secret=${clientSecret}`;

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    window.location.href = oauth2Endpoint;
  };

  useEffect(() => {
    console.log('userEffect retrieve users data!');

    const getSourceData = async () => {
      const query = new URLSearchParams(window.location.search);
      const code = query.get('code');

      if (code) {
        console.log('userEffect retrieve access_token!');
        console.log('Microsoft code: ', code);

        setIsLoading(true);

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
              .post(
                API_URL + '/sources/dynamics/crm',
                {
                  code: code,
                  clientId: clientId,
                  clientSecret: clientSecret,
                  redirectUri: redirectUri,
                  resourceUrl: resource,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                  },
                }
              )
              .then(
                (response) => {
                  console.log('Microsoft sources response: ', response);

                  if (response.data) {
                    if (response.data.canLogin) {
                      history.push({
                        pathname: '/etax/sources/dynamics/crm',
                        state: {},
                      });
                    } else {
                      setStatus('fail');
                    }
                  }

                  setIsLoading(false);
                },
                (error) => {
                  console.log(error);
                  setIsLoading(false);
                }
              );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);

            setIsLoading(false);
          });
      } else {
        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
              .get(API_URL + '/sources/dynamics-crm', {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              })
              .then(
                (response) => {
                  console.log('Dynamics Crm sources: ', response);

                  if (response.data) {
                    if (!response.data.disabled && response.data.canLogin) {
                      history.push({
                        pathname: '/etax/sources/dynamics/crm',
                        state: {},
                      });
                    }
                  }

                  setIsLoading(false);
                },
                (error) => {
                  console.log(error);
                  setIsLoading(false);
                }
              );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);

            setIsLoading(false);
          });
      }
    };

    getSourceData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {!isLoading && (
        <Stack>
          <br />

          <h1 style={{ marginLeft: '0px', marginTop: '27px' }}>
            <Icon iconName="Dynamics365Logo" style={{ color: '#106ebe' }} /> {t('dynamicCrmConnect.header')}
          </h1>
          <h3 style={{ marginTop: '0px', marginBottom: '10px', alignSelf: 'center' }}>{t('dynamicCrmConnect.subHeader1')}</h3>
          <h3 style={{ marginTop: '0px', marginBottom: '10px', alignSelf: 'center' }}>{t('dynamicCrmConnect.subHeader2')}</h3>

          <br />
          <br />
        </Stack>
      )}
      {status === '' && !isLoading && (
        <FocusTrapZone disabled={true}>
          <Stack horizontalAlign="center">
            <Stack
              style={{
                border: '1px solid rgb(134, 134, 134)',
                width: '350px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <br />
              <Stack horizontal horizontalAlign="center">
                <img src="/images/dynamics-crm.png" alt="dynamiccrm" width="200px" style={{ margin: 20 }} />
              </Stack>
              <form onSubmit={handleSubmit}>
                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Resource Url"
                    description={t('dynamicCrmConnect.resourceUrl')}
                    required
                    onChange={(e) => {
                      setResource(e.target.value);
                      sessionStorage.setItem('dynamics-crm-resourceUrl', e.target.value);
                    }}
                    value={resource}
                    style={{ width: '280px' }}
                  />
                </Stack>
                <br />
                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Client Id"
                    required
                    onChange={(e) => {
                      setClientId(e.target.value);
                      sessionStorage.setItem('dynamics-crm-clientId', e.target.value);
                    }}
                    value={clientId}
                    style={{ width: '280px' }}
                  />
                </Stack>

                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label="Client Secret"
                    type="password"
                    canRevealPassword
                    required
                    onChange={(e) => {
                      setClientSecret(e.target.value);
                      sessionStorage.setItem('dynamics-crm-clientSecret', e.target.value);
                    }}
                    value={clientSecret}
                    style={{ width: '250px' }}
                  />
                </Stack>

                <br />
                <br />
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
                  <PrimaryButton
                    style={{
                      fontSize: '20px',
                      height: '50px',
                      width: '280px',
                    }}
                    type="submit"
                  >
                    {t('dynamicCrmConnect.connect')}
                  </PrimaryButton>
                </Stack>
              </form>
              <br />
              <br />
            </Stack>
          </Stack>
        </FocusTrapZone>
      )}
      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              {false ? (
                <Spinner label={t('dynamicCrmConnect.processing')} labelPosition="down" styles={spinnerStyles} />
              ) : (
                <Spinner label={t('dynamicCrmConnect.loading')} labelPosition="down" styles={spinnerStyles} />
              )}
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'success' && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: '1px solid rgb(134, 134, 134)',
              width: '300px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#0078D4' }}>{t('dynamicCrmConnect.successMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/success03.gif" alt="success03gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  history.push({
                    pathname: '/etax/sources/paypal',
                    state: {},
                  });
                }}
              >
                {t('dynamicCrmConnect.successButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'fail' && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: '1px solid rgb(134, 134, 134)',
              width: '300px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#F25139' }}>{t('dynamicCrmConnect.failMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/fail01.gif" alt="fail01gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  setStatus('');
                }}
              >
                {t('dynamicCrmConnect.failButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      <br />
      <br />
      {status === '' && !isLoading && (
        <center>
          <br />
          <br />
          <span style={{ color: 'gray' }}>{t('dynamicCrmConnect.footer1')}</span>
          <br />
          <br />
          <span style={{ color: 'gray', fontWeight: 'bold' }}>{t('dynamicCrmConnect.footer2')}</span>
        </center>
      )}
    </Stack>
  );
};

export default DynamicsCrmConnect;
