import React, { useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMsal, useAccount } from '@azure/msal-react';
import { Dustbin } from './Dustbin.js';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { silentRequest } from '../../../../authConfig';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DefaultButton, PrimaryButton, Stack, Spinner, Icon, MessageBar, MessageBarType } from '@fluentui/react';

import { ROW_NAMES, ROW_NAMES_REQUIRE } from './rowName';
import { validateField } from './validate.js';
import { getCountryCodeWithoutLanguage } from '../../../../providers/CountryData.js';
import { getUnitByNameWithoutLanguage } from '../../../../providers/UnitData.js';

import qrCode from 'qrcode';
import generatePayload from 'promptpay-qr';

var moment = require('moment'); // require
moment().format();

const API_URL = process.env.REACT_APP_API_URL;

const ItemTypes = {
  BOX: 'box',
};

const { noRow } = ROW_NAMES;

const centerItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const noRowStyle = {
  height: '44px',
  padding: '0.5rem 0rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
  border: '1px solid rgb(193, 193, 193)',
  color: 'rgb(50, 49, 48)',
  backgroundColor: 'rgb(241, 241, 241)',
  borderTopLeftRadius: '2px',
  borderTopRightRadius: '2px',
};

function checkDateFormat(str) {
  if (isNaN(str)) {
    let datesplit = str.split('/');

    if (datesplit[0].length === 1) {
      datesplit[0] = '0' + datesplit[0];
    }

    if (datesplit[1].length === 1) {
      datesplit[1] = '0' + datesplit[1];
    }

    if (
      datesplit[0].length === 2 &&
      !isNaN(datesplit[0]) &&
      datesplit[1].length === 2 &&
      !isNaN(datesplit[1]) &&
      datesplit[2].length === 4 &&
      !isNaN(datesplit[2])
    )
      return true;
  }
  return false;
}

function checkExcelDateFormat(number) {
  return new Date(Math.round((number - 25569) * 86400 * 1000));
}

function getDateFromNewDateFormat(number, columnName) {
  const _dateFormatString = String(number).trim();

  const _yearFormat = _dateFormatString.substring(0, 4);
  const _monthFormat = _dateFormatString.substring(4, 6);
  const _dateFormat = _dateFormatString.substring(6, 8);

  const _dateObject = new Date(`${_yearFormat}-${_monthFormat}-${_dateFormat}`);

  const _year = _dateObject.getFullYear();
  const _month = String(_dateObject.getMonth() + 1).padStart(2, '0');
  const _date = String(_dateObject.getDate()).padStart(2, '0');

  if (columnName === 'dateBE') {
    let _dateBE = '';
    if (_year < 2100 && _year + 543 > 2563) {
      _dateBE = _date + '/' + _month + '/' + (_year + 543);
    } else {
      _dateBE = _date + '/' + _month + '/' + _year;
    }

    return _dateBE;
  } else if (columnName === 'dateCE') {
    let _dateCE = '';
    if (_year < 2100 && _year + 543 > 2563) {
      _dateCE = _date + '/' + _month + '/' + _year;
    } else {
      _dateCE = _date + '/' + _month + '/' + (_year - 543);
    }

    return _dateCE;
  } else if (columnName === 'date') {
    const dateMoment = moment(_dateObject).subtract(14, 'h');
    const dateSplit = dateMoment.format().split('+');

    return dateSplit[0] + '.000Z';
  }
}

function trimData(data) {
  data.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (typeof row[key] === 'string') {
        row[key] = row[key].trim();
      }
    });
  });
  return data;
}

const percentVat = 7;
const zeroVat = 0;
const nonVat = -1;

const percentVatText = '7%';
const zeroVatText = '0%';
const nonVatText = '-';

function setCookie(cName, cValue, expDays) {
  let date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/';
}

function getDragDropCookieValue() {
  const allcookies = document.cookie;

  const cookiearray = allcookies.split(';');

  let _value = [];

  if (cookiearray.some((_cookie) => _cookie.split('=')[0].includes('Excel-drag-drop') && _cookie.split('=')[1] !== undefined)) {
    const dragDropCookie = cookiearray.find((_cookie) => _cookie.split('=')[0].includes('Excel-drag-drop') && _cookie.split('=')[1] !== undefined);
    _value = JSON.parse(dragDropCookie.split('=')[1]);
  }

  return _value;
}

export default function ExcelDragDropCheck(props) {
  const history = useHistory();

  const { t } = useTranslation();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const location = useLocation();

  const { columns, data, fileName } = location.state;

  const [items, setItems] = useState(() => {
    if (getDragDropCookieValue().length) {
      const prevArray = [];
      getDragDropCookieValue().forEach((_obj) => prevArray.push(_obj.name));

      const newArray = [...columns].sort().join(',');

      if (newArray === prevArray.sort().join(',')) return getDragDropCookieValue();
    }
    const _items = [];

    columns.forEach((column, index) => _items.push({ id: index + 1, name: column, column: noRow }));

    return _items;
  });

  const [isLoading, setIsLoading] = useState(false);

  const [checkError, setCheckError] = useState(false);

  const handleQr = async (companyTaxId, paidTotal) => {
    let _qrPayload = await generatePayload(companyTaxId, { amount: paidTotal });

    let _url = '';
    await qrCode
      .toDataURL(_qrPayload)
      .then((url) => {
        _url = url;
      })
      .catch((err) => {
        console.error(err);
      });

    return _url;
  };

  function handleReset() {
    const _newItems = [...items];

    _newItems.map((item) => (item.column = noRow));

    setItems(_newItems);
  }
/* 
  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        //Check documents spending limit
        await axios
          .get(API_URL + '/subscriptions', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log('Subscriptions data: ', response);

              if (response.data) {
                if (response.data && response.data.documents) {
                  console.log('Subscription etax documents: ', response.data.documents);

                  let _subscriptionDocuments = response.data.documents;

                  if (_subscriptionDocuments.spent + 1 > _subscriptionDocuments.limit + _subscriptionDocuments.topup) {
                    history.push({
                      pathname: '/billings',
                      state: {},
                    });
                  }
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 */
  const MovableItem = ({ name, index, currentColumnName, moveCardHandler, setItems }) => {
    const changeItemColumn = (currentItem, columnName) => {
      setItems((prevState) => {
        if (prevState.some((item) => item.column === columnName && columnName !== noRow && item.name !== currentItem.name)) {
          const oldItem = prevState.find((item) => item.column === columnName && columnName !== noRow && item.name !== currentItem.name);

          return prevState.map((e) => {
            return {
              ...e,
              column: e.name === currentItem.name ? columnName : e.name === oldItem.name ? noRow : e.column,
            };
          });
        }
        return prevState.map((e) => {
          return {
            ...e,
            column: e.name === currentItem.name ? columnName : e.column,
          };
        });
      });
    };

    const cancelItemColumn = (itemName) => {
      setItems((prevState) => {
        return prevState.map((e) => {
          return {
            ...e,
            column: e.name === itemName ? noRow : e.column,
          };
        });
      });
    };

    const ref = useRef(null);

    const [, drop] = useDrop({
      accept: ItemTypes.BOX,
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        // Time to actually perform the action
        moveCardHandler(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag] = useDrag({
      item: { index, name, currentColumnName },
      type: ItemTypes.BOX,
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();

        if (dropResult) {
          const { name } = dropResult;
          if (Object.keys(ROW_NAMES).some((columnName) => columnName === name)) {
            changeItemColumn(item, name);
          }
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0.4 : 1;

    drag(drop(ref));

    return (
      <div ref={ref}>
        <Stack
          verticalAlign="center"
          styles={{
            root: {
              opacity,
              margin: currentColumnName === noRow ? '0.5rem 0rem' : 0,
              cursor: 'move',
              float: 'left',
              height: '70px',
              width: '200px',
              border: '1px solid #C7E0F4',
              color: '#323130',
              backgroundColor: '#C7E0F4',
              borderRadius: '2px',
              padding: '0px 10px',
            },
          }}
        >
          {currentColumnName === noRow ? (
            <Stack horizontal horizontalAlign="space-around">
              <p style={{ width: 180, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'start' }}>{name}</p>
            </Stack>
          ) : (
            <Stack horizontal horizontalAlign="space-around">
              <p style={{ width: 160, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'start' }}>{name}</p>
              <Stack verticalAlign="center">
                <Icon iconName="Cancel" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => cancelItemColumn(name)} />
              </Stack>
            </Stack>
          )}
        </Stack>
      </div>
    );
  };

  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem = items[dragIndex];

    if (dragItem) {
      setItems((prevState) => {
        const coppiedStateArray = [...prevState];

        // remove item by "hoverIndex" and put "dragItem" instead
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

        // remove item by "dragIndex" and put "prevItem" instead
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

        return coppiedStateArray;
      });
    }
  };

  const returnItemsForColumn = (columnName) => {
    return items
      .filter((item) => item.column === columnName)
      .map((item, index) => (
        <MovableItem
          key={item.id}
          name={item.name}
          currentColumnName={item.column}
          setItems={setItems}
          index={index}
          moveCardHandler={moveCardHandler}
        />
      ));
  };

  const toObj = async (data, companyData) => {
    let newArray = [];

    for (let i = 0; i < data.length; i++) {
      let document = data[i];
      let _row = document.order;
      let _documentType = document.documentType;

      let _language = 'th';
      if (document.language === 'en') {
        _language = 'en';
      }

      let _taxNumberType = document.customerTaxNumberType?.toUpperCase() || 'OTHR';
      let _taxId = String(document.customerTaxId).trim() || 'N/A';
      let _branchNumber = '';
      let _branchText = '';

      if (_taxNumberType === 'TXID') {
        _branchNumber = document.customerBranchNumber ? String(document.customerBranchNumber).trim().padStart(5, '0') : '00000';

        if (_branchNumber !== '00000') {
          if (_language === 'th') {
            _branchText = 'สาขา ' + _branchNumber;
          } else {
            _branchText = 'Branch ' + _branchNumber;
          }
        } else {
          if (_language === 'th') {
            _branchText = 'สำนักงานใหญ่';
          } else {
            _branchText = 'Head Office';
          }
        }
      } else if (_taxNumberType === 'OTHR') {
        _taxId = 'N/A';
      } else if (!['TXID', 'NIDN', 'CCPT', 'OTHR'].includes(_taxNumberType)) {
        _taxNumberType = 'OTHR';
        _taxId = 'N/A';
      }

      if (document.customerTaxId) {
        if (_taxId === '0000000000000' || Number(_taxId) === 0) {
          _taxNumberType = 'OTHR';
          _taxId = 'N/A';
          _branchNumber = '';
          _branchText = '';
        }
      }

      let _customer = {
        name: null,
        addressLineOne: null,
        addressLineTwo: null,
        countryCode: null,
        countryName: null,
        postcode: null,
        branchNumber: null,
        branchText: null,
        taxNumberType: null,
        taxId: null,
        phoneCountryCode: '+66',
        phone: null,
        email: null,
        language: _language,
        unstructure: true,
      };

      if (!(_documentType === 'RECEIPT-TAXINVOICE-ABB' || _documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ')) {
        _customer = {
          name: document.customerName || '',
          addressLineOne: String(document.customerAddressLineOne).replaceAll("\r\n", " ") || '',
          addressLineTwo: document.customerAddressLineTwo ? String(document.customerAddressLineTwo).replaceAll("\r\n", " ") : "",
          countryCode: getCountryCodeWithoutLanguage(document.customerCountryName).key || 'TH',
          countryName: document.customerCountryName || 'ไทย',
          postcode: String(document.customerPostcode).trim() || '',
          branchNumber: _branchNumber,
          branchText: _branchText,
          taxNumberType: _taxNumberType,
          taxId: _taxId,
          phoneCountryCode: '+66',
          phone: document.customerPhone || '',
          email: document.customerEmail || '',
          language: _language,
          unstructure: true,
        };
      }

      let _date = '';
      let _dateCE = '';
      let _dateBE = '';

      if (document.dateBE && !isNaN(document.dateBE)) {
        if (String(document.dateBE).trim().length === 8) {
          _dateBE = getDateFromNewDateFormat(document.dateBE, 'dateBE');
          _dateCE = getDateFromNewDateFormat(document.dateBE, 'dateCE');
          _date = getDateFromNewDateFormat(document.dateBE, 'date');
        } else {
          const _dateObject = checkExcelDateFormat(document.dateBE);

          if (_dateObject.getFullYear() < 2100 && _dateObject.getFullYear() + 543 > 2563) {
            _dateBE =
              String(_dateObject.getDate()).padStart(2, '0') +
              '/' +
              String(_dateObject.getMonth() + 1).padStart(2, '0') +
              '/' +
              (_dateObject.getFullYear() + 543);
            _dateCE =
              String(_dateObject.getDate()).padStart(2, '0') +
              '/' +
              String(_dateObject.getMonth() + 1).padStart(2, '0') +
              '/' +
              _dateObject.getFullYear();
          } else {
            _dateBE =
              String(_dateObject.getDate()).padStart(2, '0') +
              '/' +
              String(_dateObject.getMonth() + 1).padStart(2, '0') +
              '/' +
              _dateObject.getFullYear();
            _dateCE =
              String(_dateObject.getDate()).padStart(2, '0') +
              '/' +
              String(_dateObject.getMonth() + 1).padStart(2, '0') +
              '/' +
              (_dateObject.getFullYear() - 543);
          }

          let value = _dateBE;

          let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
          let dateParts = date.format().split('+');

          _date = dateParts[0] + '.000Z';
        }
      } else if (document.dateBE && checkDateFormat(document.dateBE)) {
        let dateBEParts = document.dateBE.split('/');

        if (dateBEParts[0].length === 1) {
          dateBEParts[0] = '0' + dateBEParts[0];
        }

        if (dateBEParts[1].length === 1) {
          dateBEParts[1] = '0' + dateBEParts[1];
        }

        if (Number(dateBEParts[2]) < 2100 && Number(dateBEParts[2]) + 543 > 2563) {
          _dateBE = dateBEParts[0] + '/' + dateBEParts[1] + '/' + (Number(dateBEParts[2]) + 543);
          _dateCE = dateBEParts[0] + '/' + dateBEParts[1] + '/' + dateBEParts[2];
        } else {
          _dateBE = dateBEParts[0] + '/' + dateBEParts[1] + '/' + dateBEParts[2];
          _dateCE = dateBEParts[0] + '/' + dateBEParts[1] + '/' + (Number(dateBEParts[2]) - 543);
        }

        let value = _dateBE;

        let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
        let dateParts = date.format().split('+');

        _date = dateParts[0] + '.000Z';
      }

      let _items = [];

      let _includeVat = false;
      if (document.includeVat === 'TRUE' || document.includeVat === 'true' || document.includeVat === true) {
        _includeVat = document.includeVat;
      }

      let _nonVatPrice = 0;
      let _zeroVatPrice = 0;
      let _vatPrice = 0;

      let _nonVatPriceTotal = 0;
      let _zeroVatPriceTotal = 0;
      let _vatPriceTotal = 0;

      let _amountTotal = 0;
      let _vatTotal = 0;
      let _grandTotal = 0;
      let _discount = Number(document.discount) || 0;

      let _previousAmountTotal = Number(document.referAmountTotal);
      let _diffAmountTotal = 0;
      if (_documentType === 'DEBIT-NOTE' || _documentType === 'ใบเพิ่มหนี้') {
        if (document.calculationMethod === 2) {
          // actual price
          let newZeroVatPrice = 0;
          let newVatPrice = 0;

          let newVatTotal = 0;
          for (let j = 0; j < document.items.length; j++) {
            let item = document.items[j];
            if (item.itemsPrice >= 0) {
              let _unitCode = '';

              if (item.itemsUnitName) {
                if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                  _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                } else {
                  _unitCode = '_wrongUnit';
                }
              }

              let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

              // if (item.itemsPercentVat === nonVat) {
              //   _percentVatText = nonVatText;
              //   _nonVatPrice = _nonVatPrice + _itemTotal;
              //   _amountTotal = _amountTotal + _itemTotal;
              // } else if (item.itemsPercentVat === zeroVat) {
              //   _percentVatText = zeroVatText;
              //   _zeroVatPrice = _zeroVatPrice + _itemTotal;
              //   _amountTotal = _amountTotal + _itemTotal;
              // } else {
              //   _vatPrice = _vatPrice + _itemTotal;
              //   _amountTotal = _amountTotal + _itemTotal;
              // }

              _vatPrice = _vatPrice + _itemTotal;
              _amountTotal = _amountTotal + _itemTotal;

              newVatPrice += _itemTotal;

              _items.push({
                number: item.itemsNumber,
                description: item.itemsDescription,
                quantity: item.itemsQuantity,
                unitCode: _unitCode,
                unitName: item.itemsUnitName || '-',
                price: item.itemsPrice,
                discount: item.itemsDiscount || 0,
                percentVat: percentVat,
                percentVatText: percentVatText,
                total: _itemTotal,
                sku: item.itemsSKU ? item.itemsSKU : ''
              });
            } else {
              _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
            }
          }

          _amountTotal = Math.round((_amountTotal - _discount) * 100) / 100;
          if (_vatPrice !== 0) {
            _vatPrice = Math.round((_vatPrice - _discount - _previousAmountTotal) * 100) / 100;
          } else if (_zeroVatPrice !== 0) {
            _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
          } else {
            _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
          }

          if (_includeVat) {
            newVatTotal = (newVatPrice * percentVat) / (100 + percentVat) + (newZeroVatPrice * zeroVat) / (100 + zeroVat);
            newVatTotal = Math.round(newVatTotal * 100) / 100;

            let _sumAmountTotalIncludeVat = _amountTotal;
            _sumAmountTotalIncludeVat = Math.round(_sumAmountTotalIncludeVat * 100) / 100;

            _amountTotal = _amountTotal - newVatTotal;
            _amountTotal = Math.round(_amountTotal * 100) / 100;

            let _previousAmountTotalIncludeVat = (_previousAmountTotal * 7) / 100 + _previousAmountTotal;
            _previousAmountTotalIncludeVat = Math.round(_previousAmountTotalIncludeVat * 100) / 100;

            _grandTotal = _sumAmountTotalIncludeVat - _previousAmountTotalIncludeVat;
            _grandTotal = Math.round(_grandTotal * 100) / 100;

            _diffAmountTotal = _amountTotal - _previousAmountTotal;
            _diffAmountTotal = Math.round(_diffAmountTotal * 100) / 100;

            _vatTotal = _grandTotal - _diffAmountTotal;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            _nonVatPriceTotal = 0;
            _zeroVatPriceTotal = 0;
            _vatPriceTotal = _grandTotal;

            // _grandTotal = Math.round((_amountTotal - _previousAmountTotal) * 100) / 100;
            // _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            // _diffAmountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
          } else {
            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice;
            _vatPriceTotal = _vatPrice;

            _vatTotal = (_vatPrice * percentVat) / 100;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            _diffAmountTotal = _amountTotal - _previousAmountTotal;
            _diffAmountTotal = Math.round(_diffAmountTotal * 100) / 100;

            _grandTotal = _diffAmountTotal + _vatTotal;
            _grandTotal = Math.round(_grandTotal * 100) / 100;

            // _diffAmountTotal = Math.round((_amountTotal - _previousAmountTotal) * 100) / 100;
            // _vatTotal = Math.round(((_diffAmountTotal * percentVat) / 100) * 100) / 100;
            // _grandTotal = Math.round((_diffAmountTotal + _vatTotal) * 100) / 100;
          }
        } else {
          if (_includeVat) {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice = _nonVatPrice + _itemTotal;
                  _grandTotal = _grandTotal + _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice = _zeroVatPrice + _itemTotal;
                  _grandTotal = _grandTotal + _itemTotal;
                } else {
                  _vatPrice = _vatPrice + _itemTotal;
                  _grandTotal = _grandTotal + _itemTotal;
                }

                _items.push({
                  number: item.itemsNumber,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : ''
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _grandTotal = Math.round((_grandTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice - Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;

            const vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;

            _vatTotal = (vatPriceTotal * percentVat) / 100;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            const _sumGrandBeforeVat = _grandTotal - _vatTotal;

            _amountTotal = _sumGrandBeforeVat + Number(_previousAmountTotal);
            _amountTotal = Math.round(_amountTotal * 100) / 100;

            _diffAmountTotal = _amountTotal - Number(_previousAmountTotal);
            _diffAmountTotal = Math.round(_diffAmountTotal * 100) / 100;

            // _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            // _diffAmountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
            // _amountTotal = Math.round((_diffAmountTotal + _previousAmountTotal) * 100) / 100;
          } else {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice = _nonVatPrice + _itemTotal;
                  _diffAmountTotal = _diffAmountTotal + _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice = _zeroVatPrice + _itemTotal;
                  _diffAmountTotal = _diffAmountTotal + _itemTotal;
                } else {
                  _vatPrice = _vatPrice + _itemTotal;
                  _diffAmountTotal = _diffAmountTotal + _itemTotal;
                }

                _items.push({
                  number: item.itemsNumber,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : ''
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _diffAmountTotal = Math.round((_diffAmountTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice;
            _vatPriceTotal = _vatPrice;

            _vatTotal = (_vatPrice * percentVat) / 100;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            _amountTotal = _diffAmountTotal + Number(_previousAmountTotal);
            _amountTotal = Math.round(_amountTotal * 100) / 100;

            _grandTotal = _diffAmountTotal + _vatTotal;
            _grandTotal = Math.round(_grandTotal * 100) / 100;

            // _amountTotal = Math.round((_diffAmountTotal + _previousAmountTotal) * 100) / 100;
            // _vatTotal = Math.round(((_diffAmountTotal * percentVat) / 100) * 100) / 100;
            // _grandTotal = Math.round((_diffAmountTotal + _vatTotal) * 100) / 100;
          }
        }
      } else if (_documentType === 'CREDIT-NOTE' || _documentType === 'ใบลดหนี้') {
        if (document.calculationMethod === 2) {
          // actual price
          let newZeroVatPrice = 0;
          let newVatPrice = 0;

          let newVatTotal = 0;
          for (let j = 0; j < document.items.length; j++) {
            let item = document.items[j];
            if (item.itemsPrice >= 0) {
              let _unitCode = '';

              if (item.itemsUnitName) {
                if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                  _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                } else {
                  _unitCode = '_wrongUnit';
                }
              }

              let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

              // if (item.itemsPercentVat === nonVat) {
              //   _percentVatText = nonVatText;
              //   _nonVatPrice = _nonVatPrice + _itemTotal;
              //   _amountTotal = _amountTotal + _itemTotal;
              // } else if (item.itemsPercentVat === zeroVat) {
              //   _percentVatText = zeroVatText;
              //   _zeroVatPrice = _zeroVatPrice + _itemTotal;
              //   _amountTotal = _amountTotal + _itemTotal;
              // } else {
              //   _vatPrice = _vatPrice + _itemTotal;
              //   _amountTotal = _amountTotal + _itemTotal;
              // }

              _vatPrice = _vatPrice + _itemTotal;
              _amountTotal = _amountTotal + _itemTotal;

              newVatPrice += _itemTotal;

              _items.push({
                number: item.itemsNumber,
                description: item.itemsDescription,
                quantity: item.itemsQuantity,
                unitCode: _unitCode,
                unitName: item.itemsUnitName || '-',
                price: item.itemsPrice,
                discount: item.itemsDiscount || 0,
                percentVat: percentVat,
                percentVatText: percentVatText,
                total: _itemTotal,
                sku: item.itemsSKU ? item.itemsSKU : ''
              });
            } else {
              _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
            }
          }

          _amountTotal = Math.round((_amountTotal - _discount) * 100) / 100;
          if (_vatPrice !== 0) {
            _vatPrice = Math.round((_previousAmountTotal - _vatPrice - _discount) * 100) / 100;
          } else if (_zeroVatPrice !== 0) {
            _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
          } else {
            _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
          }

          if (_includeVat) {
            newVatTotal = (newVatPrice * percentVat) / (100 + percentVat) + (newZeroVatPrice * zeroVat) / (100 + zeroVat);
            newVatTotal = Math.round(newVatTotal * 100) / 100;

            let _sumAmountTotalIncludeVat = _amountTotal;
            _sumAmountTotalIncludeVat = Math.round(_sumAmountTotalIncludeVat * 100) / 100;

            _amountTotal = _amountTotal - newVatTotal;
            _amountTotal = Math.round(_amountTotal * 100) / 100;

            let _previousAmountTotalIncludeVat = (_previousAmountTotal * 7) / 100 + _previousAmountTotal;
            _previousAmountTotalIncludeVat = Math.round(_previousAmountTotalIncludeVat * 100) / 100;

            _grandTotal = _previousAmountTotalIncludeVat - _sumAmountTotalIncludeVat;
            _grandTotal = Math.round(_grandTotal * 100) / 100;

            _diffAmountTotal = _previousAmountTotal - _amountTotal;
            _diffAmountTotal = Math.round(_diffAmountTotal * 100) / 100;

            _vatTotal = _grandTotal - _diffAmountTotal;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            _nonVatPriceTotal = 0;
            _zeroVatPriceTotal = 0;
            _vatPriceTotal = _grandTotal;

            // _grandTotal = Math.round((_previousAmountTotal - _amountTotal) * 100) / 100;
            // _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            // _diffAmountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
          } else {
            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice;
            _vatPriceTotal = _vatPrice;

            _vatTotal = (_vatPrice * percentVat) / 100;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            _diffAmountTotal = _previousAmountTotal - _amountTotal;
            _diffAmountTotal = Math.round(_diffAmountTotal * 100) / 100;

            _grandTotal = _diffAmountTotal + _vatTotal;
            _grandTotal = Math.round(_grandTotal * 100) / 100;

            // _diffAmountTotal = Math.round((_previousAmountTotal - _amountTotal) * 100) / 100;
            // _vatTotal = Math.round(((_diffAmountTotal * percentVat) / 100) * 100) / 100;
            // _grandTotal = Math.round((_diffAmountTotal + _vatTotal) * 100) / 100;
          }
        } else {
          if (_includeVat) {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice = _nonVatPrice + _itemTotal;
                  _grandTotal = _grandTotal + _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice = _zeroVatPrice + _itemTotal;
                  _grandTotal = _grandTotal + _itemTotal;
                } else {
                  _vatPrice = _vatPrice + _itemTotal;
                  _grandTotal = _grandTotal + _itemTotal;
                }

                _items.push({
                  number: item.itemsNumber,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : ''
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _grandTotal = Math.round((_grandTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice - Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;

            const vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;

            _vatTotal = (vatPriceTotal * percentVat) / 100;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            const _sumGrandBeforeVat = _grandTotal - _vatTotal;

            _amountTotal = _previousAmountTotal - _sumGrandBeforeVat;
            _amountTotal = Math.round(_amountTotal * 100) / 100;

            _diffAmountTotal = _previousAmountTotal - _amountTotal;
            _diffAmountTotal = Math.round(_diffAmountTotal * 100) / 100;

            // _vatTotal = Math.round(((_grandTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            // _diffAmountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
            // _amountTotal = Math.round((_previousAmountTotal - _diffAmountTotal) * 100) / 100;
          } else {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice = _nonVatPrice + _itemTotal;
                  _diffAmountTotal = _diffAmountTotal + _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice = _zeroVatPrice + _itemTotal;
                  _diffAmountTotal = _diffAmountTotal + _itemTotal;
                } else {
                  _vatPrice = _vatPrice + _itemTotal;
                  _diffAmountTotal = _diffAmountTotal + _itemTotal;
                }

                _items.push({
                  number: item.itemsNumber,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : ''
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _diffAmountTotal = Math.round((_diffAmountTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice;
            _vatPriceTotal = _vatPrice;

            _vatTotal = (_vatPrice * percentVat) / 100;
            _vatTotal = Math.round(_vatTotal * 100) / 100;

            _amountTotal = _previousAmountTotal - _diffAmountTotal;
            _amountTotal = Math.round(_amountTotal * 100) / 100;

            _grandTotal = _diffAmountTotal + _vatTotal;
            _grandTotal = Math.round(_grandTotal * 100) / 100;

            // _amountTotal = Math.round((_previousAmountTotal - _diffAmountTotal) * 100) / 100;
            // _vatTotal = Math.round(((_diffAmountTotal * percentVat) / 100) * 100) / 100;
            // _grandTotal = Math.round((_diffAmountTotal + _vatTotal) * 100) / 100;
          }
        }
      } else if (
        _documentType === 'QUOTATION' ||
        _documentType === 'ใบเสนอราคา' ||
        _documentType === 'INVOICE' ||
        _documentType === 'ใบแจ้งหนี้' ||
        _documentType === 'BILLING-NOTE' ||
        _documentType === 'ใบวางบิล'
      ) {
        if (companyData.registerVat) {
          if (_includeVat) {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice += _itemTotal;
                  _grandTotal += _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice += _itemTotal;
                  _grandTotal += _itemTotal;
                } else {
                  _vatPrice += _itemTotal;
                  _grandTotal += _itemTotal;
                }

                _items.push({
                  number: item.itemsNumber,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : ''
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _grandTotal = Math.round((_grandTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _vatTotal =
              Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100 +
              Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _amountTotal = _grandTotal - _vatTotal;

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice - Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;
          } else {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice += _itemTotal;
                  _amountTotal += _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice += _itemTotal;
                  _amountTotal += _itemTotal;
                } else {
                  _vatPrice += _itemTotal;
                  _amountTotal += _itemTotal;
                }

                _items.push({
                  number: item.itemsNumber,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : ''
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _amountTotal = Math.round((_amountTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _vatTotal = Math.round(((_vatPrice * percentVat) / 100) * 100) / 100 + Math.round(((_zeroVatPrice * zeroVat) / 100) * 100) / 100;
            _grandTotal = _amountTotal + _vatTotal;

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice;
            _vatPriceTotal = _vatPrice;
          }
        } else {
          for (let j = 0; j < document.items.length; j++) {
            let item = document.items[j];
            if (item.itemsPrice >= 0) {
              let _unitCode = '';

              if (item.itemsUnitName) {
                if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                  _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                } else {
                  _unitCode = '_wrongUnit';
                }
              }

              let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

              _grandTotal += _itemTotal;

              _items.push({
                number: item.itemsNumber,
                description: item.itemsDescription,
                quantity: item.itemsQuantity,
                unitCode: _unitCode,
                unitName: item.itemsUnitName || '-',
                price: item.itemsPrice,
                discount: item.itemsDiscount || 0,
                total: _itemTotal,
                sku: item.itemsSKU ? item.itemsSKU : ''
              });
            } else {
              _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
            }
          }

          _grandTotal = Math.round((_grandTotal - _discount) * 100) / 100;
          _amountTotal = _grandTotal;
        }
      } else if (
        _documentType === 'RECEIPT' ||
        _documentType === 'ใบเสร็จรับเงิน' ||
        _documentType === 'DELIVERYORDER' ||
        _documentType === 'ใบส่งของ'
      ) {
        if (companyData.registerVat) {
          if (_includeVat) {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice += _itemTotal;
                  _grandTotal += _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice += _itemTotal;
                  _grandTotal += _itemTotal;
                } else {
                  _vatPrice += _itemTotal;
                  _grandTotal += _itemTotal;
                }

                _items.push({
                  number: item.itemsNumber,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : ''
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _grandTotal = Math.round((_grandTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _vatTotal =
              Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100 +
              Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _amountTotal = _grandTotal - _vatTotal;

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice - Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;
          } else {
            for (let j = 0; j < document.items.length; j++) {
              let item = document.items[j];
              if (item.itemsPrice >= 0) {
                let _percentVatText = percentVatText;
                let _unitCode = '';

                if (item.itemsUnitName) {
                  if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                    _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                  } else {
                    _unitCode = '_wrongUnit';
                  }
                }

                let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

                if (item.itemsPercentVat === nonVat) {
                  _percentVatText = nonVatText;
                  _nonVatPrice += _itemTotal;
                  _amountTotal += _itemTotal;
                } else if (item.itemsPercentVat === zeroVat) {
                  _percentVatText = zeroVatText;
                  _zeroVatPrice += _itemTotal;
                  _amountTotal += _itemTotal;
                } else {
                  _vatPrice += _itemTotal;
                  _amountTotal += _itemTotal;
                }

                _items.push({
                  number: item.itemsNumber,
                  description: item.itemsDescription,
                  quantity: item.itemsQuantity,
                  unitCode: _unitCode,
                  unitName: item.itemsUnitName || '-',
                  price: item.itemsPrice,
                  discount: item.itemsDiscount || 0,
                  percentVat: item.itemsPercentVat,
                  percentVatText: _percentVatText,
                  total: _itemTotal,
                  sku: item.itemsSKU ? item.itemsSKU : ''
                });
              } else {
                _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
              }
            }

            _amountTotal = Math.round((_amountTotal - _discount) * 100) / 100;
            if (_vatPrice !== 0) {
              _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
            } else if (_zeroVatPrice !== 0) {
              _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
            } else {
              _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
            }

            _vatTotal = Math.round(((_vatPrice * percentVat) / 100) * 100) / 100 + Math.round(((_zeroVatPrice * zeroVat) / 100) * 100) / 100;
            _grandTotal = _amountTotal + _vatTotal;

            _nonVatPriceTotal = _nonVatPrice;
            _zeroVatPriceTotal = _zeroVatPrice;
            _vatPriceTotal = _vatPrice;
          }
        } else {
          for (let j = 0; j < document.items.length; j++) {
            let item = document.items[j];
            if (item.itemsPrice >= 0) {
              let _unitCode = '';

              if (item.itemsUnitName) {
                if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                  _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                } else {
                  _unitCode = '_wrongUnit';
                }
              }

              let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

              _grandTotal += _itemTotal;

              _items.push({
                number: item.itemsNumber,
                description: item.itemsDescription,
                quantity: item.itemsQuantity,
                unitCode: _unitCode,
                unitName: item.itemsUnitName || '-',
                price: item.itemsPrice,
                discount: item.itemsDiscount || 0,
                total: _itemTotal,
                sku: item.itemsSKU ? item.itemsSKU : ''
              });
            } else {
              _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
            }
          }

          _grandTotal = Math.round((_grandTotal - _discount) * 100) / 100;
          _amountTotal = _grandTotal;
        }
      } else {
        if (_includeVat) {
          for (let j = 0; j < document.items.length; j++) {
            let item = document.items[j];
            if (item.itemsPrice >= 0) {
              let _percentVatText = percentVatText;
              let _unitCode = '';

              if (item.itemsUnitName) {
                if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                  _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                } else {
                  _unitCode = '_wrongUnit';
                }
              }

              let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

              if (item.itemsPercentVat === nonVat) {
                _percentVatText = nonVatText;
                _nonVatPrice += _itemTotal;
                _grandTotal += _itemTotal;
              } else if (item.itemsPercentVat === zeroVat) {
                _percentVatText = zeroVatText;
                _zeroVatPrice += _itemTotal;
                _grandTotal += _itemTotal;
              } else {
                _vatPrice += _itemTotal;
                _grandTotal += _itemTotal;
              }

              _items.push({
                number: item.itemsNumber,
                description: item.itemsDescription,
                quantity: item.itemsQuantity,
                unitCode: _unitCode,
                unitName: item.itemsUnitName || '-',
                price: item.itemsPrice,
                discount: item.itemsDiscount || 0,
                percentVat: item.itemsPercentVat,
                percentVatText: _percentVatText,
                total: _itemTotal,
                sku: item.itemsSKU ? item.itemsSKU : ''
              });
            } else {
              _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
            }
          }

          _grandTotal = Math.round((_grandTotal - _discount) * 100) / 100;
          if (_vatPrice !== 0) {
            _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
          } else if (_zeroVatPrice !== 0) {
            _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
          } else {
            _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
          }

          _vatTotal =
            Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100 +
            Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
          _amountTotal = _grandTotal - _vatTotal;

          _nonVatPriceTotal = _nonVatPrice;
          _zeroVatPriceTotal = _zeroVatPrice - Math.round(((_zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
          _vatPriceTotal = _vatPrice - Math.round(((_vatPrice * percentVat) / (100 + percentVat)) * 100) / 100;
        } else {
          for (let j = 0; j < document.items.length; j++) {
            let item = document.items[j];
            if (item.itemsPrice >= 0) {
              let _percentVatText = percentVatText;
              let _unitCode = '';

              if (item.itemsUnitName) {
                if (getUnitByNameWithoutLanguage(item.itemsUnitName).key) {
                  _unitCode = getUnitByNameWithoutLanguage(item.itemsUnitName).key;
                } else {
                  _unitCode = '_wrongUnit';
                }
              }

              let _itemTotal = (item.itemsPrice - item.itemsDiscount) * item.itemsQuantity;

              if (item.itemsPercentVat === nonVat) {
                _percentVatText = nonVatText;
                _nonVatPrice += _itemTotal;
                _amountTotal += _itemTotal;
              } else if (item.itemsPercentVat === zeroVat) {
                _percentVatText = zeroVatText;
                _zeroVatPrice += _itemTotal;
                _amountTotal += _itemTotal;
              } else {
                _vatPrice += _itemTotal;
                _amountTotal += _itemTotal;
              }

              _items.push({
                number: item.itemsNumber,
                description: item.itemsDescription,
                quantity: item.itemsQuantity,
                unitCode: _unitCode,
                unitName: item.itemsUnitName || '-',
                price: item.itemsPrice,
                discount: item.itemsDiscount || 0,
                percentVat: item.itemsPercentVat,
                percentVatText: _percentVatText,
                total: _itemTotal,
                sku: item.itemsSKU ? item.itemsSKU : ''
              });
            } else {
              _discount = _discount + Math.abs((item.itemsPrice - item.itemsDiscount) * item.itemsQuantity);
            }
          }

          _amountTotal = Math.round((_amountTotal - _discount) * 100) / 100;
          if (_vatPrice !== 0) {
            _vatPrice = Math.round((_vatPrice - _discount) * 100) / 100;
          } else if (_zeroVatPrice !== 0) {
            _zeroVatPrice = Math.round((_zeroVatPrice - _discount) * 100) / 100;
          } else {
            _nonVatPrice = Math.round((_nonVatPrice - _discount) * 100) / 100;
          }

          _vatTotal = Math.round(((_vatPrice * percentVat) / 100) * 100) / 100 + Math.round(((_zeroVatPrice * zeroVat) / 100) * 100) / 100;
          _grandTotal = _amountTotal + _vatTotal;

          _nonVatPriceTotal = _nonVatPrice;
          _zeroVatPriceTotal = _zeroVatPrice;
          _vatPriceTotal = _vatPrice;
        }
      }

      let _company = { ...companyData };
      if (
        _documentType === 'QUOTATION' ||
        _documentType === 'ใบเสนอราคา' ||
        _documentType === 'INVOICE' ||
        _documentType === 'ใบแจ้งหนี้' ||
        _documentType === 'BILLING-NOTE' ||
        _documentType === 'ใบวางบิล' ||
        _documentType === 'DELIVERYORDER' ||
        _documentType === 'ใบส่งของ'
      ) {
        if (document.promptpay === 'TRUE' || document.promptpay === 'true' || document.promptpay === true) {
          _company.payment.promptpay = document.promptpay;
          let qr = await handleQr(_company.taxId, _grandTotal);
          _company.payment.promptpayQr = qr;
        }
      }

      let obj = {
        row: _row,
        documentType: _documentType || '',
        company: _company,
        customer: _customer,
        date: _date,
        dateBE: _dateBE || '',
        dateCE: _dateCE,
        number: document.number || '',
        items: _items,
        includeVat: _includeVat,
        percentVat: percentVat,
        nonVatPriceTotal: _nonVatPriceTotal > 0 ? _nonVatPriceTotal : 0,
        zeroVatPriceTotal: _zeroVatPriceTotal > 0 ? _zeroVatPriceTotal : 0,
        vatPriceTotal: _vatPriceTotal > 0 ? _vatPriceTotal : 0,
        discount: _discount || 0,
        amountTotal: _amountTotal > 0 ? _amountTotal : 0,
        vatTotal: _vatTotal > 0 ? _vatTotal : 0,
        grandTotal: _grandTotal > 0 ? _grandTotal : 0,
        note: document.note || '',
        createdBy: document.createdBy || '',
        receivedBy: document.receivedBy || '',
        reference: document.reference || '',
        reIssue: document.reIssue || false,
        ...(['BILLING-NOTE', 'ใบวางบิล', 'INVOICE', 'ใบแจ้งหนี้', 'QUOTATION', 'ใบเสนอราคา'].includes(_documentType)
          ? { whtTotal: 0, paidTotal: 0 }
          : {}),
      };

      if (
        _documentType === 'INVOICE-TAXINVOICE' ||
        _documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี' ||
        _documentType === 'DELIVERYORDER-TAXINVOICE' ||
        _documentType === 'ใบส่งของ/ใบกำกับภาษี' ||
        _documentType === 'INVOICE' ||
        _documentType === 'ใบแจ้งหนี้' ||
        _documentType === 'BILLING-NOTE' ||
        _documentType === 'ใบวางบิล' ||
        _documentType === 'DELIVERYORDER' ||
        _documentType === 'ใบส่งของ'
      ) {
        let _dueDate = '';
        let _dueDateCE = '';
        let _dueDateBE = '';
        if (document.dueDateBE && !isNaN(document.dueDateBE)) {
          if (String(document.dueDateBE).trim().length === 8) {
            _dueDateBE = getDateFromNewDateFormat(document.dueDateBE, 'dateBE');
            _dueDateCE = getDateFromNewDateFormat(document.dueDateBE, 'dateCE');
            _dueDate = getDateFromNewDateFormat(document.dueDateBE, 'date');
          } else {
            const _dateObject = checkExcelDateFormat(document.dueDateBE);

            if (_dateObject.getFullYear() < 2100 && _dateObject.getFullYear() + 543 > 2563) {
              _dueDateBE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                (_dateObject.getFullYear() + 543);
              _dueDateCE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                _dateObject.getFullYear();
            } else {
              _dueDateBE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                _dateObject.getFullYear();
              _dueDateCE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                (_dateObject.getFullYear() - 543);
            }

            let value = _dueDateBE;

            let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
            let dateParts = date.format().split('+');

            _dueDate = dateParts[0] + '.000Z';
          }
        } else if (document.dueDateBE && checkDateFormat(document.dueDateBE)) {
          let dueDateBEParts = document.dueDateBE.split('/');

          if (dueDateBEParts[0].length === 1) {
            dueDateBEParts[0] = '0' + dueDateBEParts[0];
          }

          if (dueDateBEParts[1].length === 1) {
            dueDateBEParts[1] = '0' + dueDateBEParts[1];
          }

          if (Number(dueDateBEParts[2]) < 2100 && Number(dueDateBEParts[2]) + 543 > 2563) {
            _dueDateBE = dueDateBEParts[0] + '/' + dueDateBEParts[1] + '/' + (Number(dueDateBEParts[2]) + 543);
            _dueDateCE = dueDateBEParts[0] + '/' + dueDateBEParts[1] + '/' + dueDateBEParts[2];
          } else {
            _dueDateBE = dueDateBEParts[0] + '/' + dueDateBEParts[1] + '/' + dueDateBEParts[2];
            _dueDateCE = dueDateBEParts[0] + '/' + dueDateBEParts[1] + '/' + (Number(dueDateBEParts[2]) - 543);
          }

          let value = _dueDateBE;

          let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
          let dateParts = date.format().split('+');

          _dueDate = dateParts[0] + '.000Z';
        }

        obj.dueDate = _dueDate;
        obj.dueDateBE = _dueDateBE || '';
        obj.dueDateCE = _dueDateCE;
      }

      if (
        _documentType === 'RECEIPT-TAXINVOICE' ||
        _documentType === 'INVOICE-TAXINVOICE' ||
        _documentType === 'DELIVERYORDER-TAXINVOICE' ||
        _documentType === 'TAXINVOICE' ||
        _documentType === 'RECEIPT-TAXINVOICE-ABB' ||
        _documentType === 'RECEIPT' ||
        _documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษี' ||
        _documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี' ||
        _documentType === 'ใบส่งของ/ใบกำกับภาษี' ||
        _documentType === 'ใบกำกับภาษี' ||
        _documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ' ||
        _documentType === 'ใบเสร็จรับเงิน'
      ) {
        if (document.reIssue) {
          let _referDate = '';
          let _referDateCE = '';
          let _referDateBE = '';
          if (document.referDateBE && !isNaN(document.referDateBE)) {
            if (String(document.referDateBE).trim().length === 8) {
              _referDateBE = getDateFromNewDateFormat(document.referDateBE, 'dateBE');
              _referDateCE = getDateFromNewDateFormat(document.referDateBE, 'dateCE');
              _referDate = getDateFromNewDateFormat(document.referDateBE, 'date');
            } else {
              const _dateObject = checkExcelDateFormat(document.referDateBE);

              if (_dateObject.getFullYear() < 2100 && _dateObject.getFullYear() + 543 > 2563) {
                _referDateBE =
                  String(_dateObject.getDate()).padStart(2, '0') +
                  '/' +
                  String(_dateObject.getMonth() + 1).padStart(2, '0') +
                  '/' +
                  (_dateObject.getFullYear() + 543);
                _referDateCE =
                  String(_dateObject.getDate()).padStart(2, '0') +
                  '/' +
                  String(_dateObject.getMonth() + 1).padStart(2, '0') +
                  '/' +
                  _dateObject.getFullYear();
              } else {
                _referDateBE =
                  String(_dateObject.getDate()).padStart(2, '0') +
                  '/' +
                  String(_dateObject.getMonth() + 1).padStart(2, '0') +
                  '/' +
                  _dateObject.getFullYear();
                _referDateCE =
                  String(_dateObject.getDate()).padStart(2, '0') +
                  '/' +
                  String(_dateObject.getMonth() + 1).padStart(2, '0') +
                  '/' +
                  (_dateObject.getFullYear() - 543);
              }

              let value = _referDateBE;

              let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
              let dateParts = date.format().split('+');

              _referDate = dateParts[0] + '.000Z';
            }
          } else if (document.referDateBE && checkDateFormat(document.referDateBE)) {
            let referDateBEParts = document.referDateBE.split('/');

            if (referDateBEParts[0].length === 1) {
              referDateBEParts[0] = '0' + referDateBEParts[0];
            }

            if (referDateBEParts[1].length === 1) {
              referDateBEParts[1] = '0' + referDateBEParts[1];
            }

            if (Number(referDateBEParts[2]) < 2100 && Number(referDateBEParts[2]) + 543 > 2563) {
              _referDateBE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + (Number(referDateBEParts[2]) + 543);
              _referDateCE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + referDateBEParts[2];
            } else {
              _referDateBE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + referDateBEParts[2];
              _referDateCE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + (Number(referDateBEParts[2]) - 543);
            }

            let value = _referDateBE;

            let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
            let dateParts = date.format().split('+');

            _referDate = dateParts[0] + '.000Z';
          }

          let _reasonName = '';
          let _specificReason = document.referSpecificReason || '';

          if (
            _documentType === 'RECEIPT-TAXINVOICE' ||
            _documentType === 'INVOICE-TAXINVOICE' ||
            _documentType === 'DELIVERYORDER-TAXINVOICE' ||
            _documentType === 'TAXINVOICE' ||
            _documentType === 'RECEIPT-TAXINVOICE-ABB' ||
            _documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษี' ||
            _documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี' ||
            _documentType === 'ใบส่งของ/ใบกำกับภาษี' ||
            _documentType === 'ใบกำกับภาษี' ||
            _documentType === 'ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ'
          ) {
            if (document.referReasonCode === 'TIVC01') {
              if (_language === 'en') {
                _reasonName = 'Invalid name';
              } else {
                _reasonName = 'ชื่อผิด';
              }
            } else if (document.referReasonCode === 'TIVC02') {
              if (_language === 'en') {
                _reasonName = 'Invalid address';
              } else {
                _reasonName = 'ที่อยู่ผิด';
              }
            } else if (document.referReasonCode === 'TIVC99') {
              if (_language === 'en') {
                _reasonName = 'Other reason';
              } else {
                _reasonName = 'เหตุอื่น';
              }
            }
          } else if (_documentType === 'RECEIPT' || _documentType === 'ใบเสร็จรับเงิน') {
            if (document.referReasonCode === 'RCTC01') {
              if (_language === 'en') {
                _reasonName = 'Invalid name';
              } else {
                _reasonName = 'ชื่อผิด';
              }
            } else if (document.referReasonCode === 'RCTC02') {
              if (_language === 'en') {
                _reasonName = 'Invalid address';
              } else {
                _reasonName = 'ที่อยู่ผิด';
              }
            } else if (document.referReasonCode === 'RCTC03') {
              if (_language === 'en') {
                _reasonName = 'Product return/service cancellation (full refund)';
              } else {
                _reasonName = 'รับคืนสินค้า/ยกเลิกบริการ ทั้งจำนวน';
              }
              if (!isNaN(document.referSpecificReason)) {
                if (_language === 'en') {
                  _specificReason = `${document.referSpecificReason} Baht as a refund (full refund)`;
                } else {
                  _specificReason = `รับคืนสินค้า/ยกเลิกบริการ ทั้งจำนวน ${document.referSpecificReason} บาท`;
                }
              }
            } else if (document.referReasonCode === 'RCTC04') {
              if (_language === 'en') {
                _reasonName = 'Product return/service cancellation (partial refund)';
              } else {
                _reasonName = 'รับคืนสินค้า/ยกเลิกบริการ บางส่วน';
              }
              if (!isNaN(document.referSpecificReason)) {
                if (_language === 'en') {
                  _specificReason = `${document.referSpecificReason} Baht as a refund (partial refund)`;
                } else {
                  _specificReason = `รับคืนสินค้า/ยกเลิกบริการ บางส่วน ${document.referSpecificReason} บาท`;
                }
              }
            } else if (document.referReasonCode === 'RCTC99') {
              if (_language === 'en') {
                _reasonName = 'Other reason';
              } else {
                _reasonName = 'เหตุอื่น';
              }
            }
          }

          obj.refer = {
            number: document.referNumber || '',
            date: _referDate,
            dateBE: _referDateBE || '',
            dateCE: _referDateCE,
            reasonCode: document.referReasonCode || '',
            reasonName: _reasonName,
            specificReason: _specificReason,
          };
        }
      } else if (
        _documentType === 'DEBIT-NOTE' ||
        _documentType === 'CREDIT-NOTE' ||
        _documentType === 'ใบเพิ่มหนี้' ||
        _documentType === 'ใบลดหนี้'
      ) {
        let _typeName = '';
        if (document.referTypeCode === 'T03') {
          _typeName = 'ใบเสร็จรับเงิน/ใบกำกับภาษี';
        } else if (document.referTypeCode === 'T02') {
          _typeName = 'ใบแจ้งหนี้/ใบกำกับภาษี';
        } else if (document.referTypeCode === 'T04') {
          _typeName = 'ใบส่งของ/ใบกำกับภาษี';
        } else if (document.referTypeCode === '388') {
          _typeName = 'ใบกำกับภาษี';
        }

        let _referDate = '';
        let _referDateCE = '';
        let _referDateBE = '';
        if (document.referDateBE && !isNaN(document.referDateBE)) {
          if (String(document.referDateBE).trim().length === 8) {
            _referDateBE = getDateFromNewDateFormat(document.referDateBE, 'dateBE');
            _referDateCE = getDateFromNewDateFormat(document.referDateBE, 'dateCE');
            _referDate = getDateFromNewDateFormat(document.referDateBE, 'date');
          } else {
            const _dateObject = checkExcelDateFormat(document.referDateBE);

            if (_dateObject.getFullYear() < 2100 && _dateObject.getFullYear() + 543 > 2563) {
              _referDateBE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                (_dateObject.getFullYear() + 543);
              _referDateCE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                _dateObject.getFullYear();
            } else {
              _referDateBE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                _dateObject.getFullYear();
              _referDateCE =
                String(_dateObject.getDate()).padStart(2, '0') +
                '/' +
                String(_dateObject.getMonth() + 1).padStart(2, '0') +
                '/' +
                (_dateObject.getFullYear() - 543);
            }

            let value = _referDateBE;

            let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
            let dateParts = date.format().split('+');

            _referDate = dateParts[0] + '.000Z';
          }
        } else if (document.referDateBE && checkDateFormat(document.referDateBE)) {
          let referDateBEParts = document.referDateBE.split('/');

          if (referDateBEParts[0].length === 1) {
            referDateBEParts[0] = '0' + referDateBEParts[0];
          }

          if (referDateBEParts[1].length === 1) {
            referDateBEParts[1] = '0' + referDateBEParts[1];
          }

          if (Number(referDateBEParts[2]) < 2100 && Number(referDateBEParts[2]) + 543 > 2563) {
            _referDateBE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + (Number(referDateBEParts[2]) + 543);
            _referDateCE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + referDateBEParts[2];
          } else {
            _referDateBE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + referDateBEParts[2];
            _referDateCE = referDateBEParts[0] + '/' + referDateBEParts[1] + '/' + (Number(referDateBEParts[2]) - 543);
          }

          let value = _referDateBE;

          let date = moment(value, 'DD-MM-YYYY').subtract(543, 'year').subtract(7, 'h');
          let dateParts = date.format().split('+');

          _referDate = dateParts[0] + '.000Z';
        }

        let _reasonName = '';

        if (document.referReasonCode === 'DBNG01') {
          if (_language === 'en') {
            _reasonName = `Increasing in product's price (higher than the agreed amount)`;
          } else {
            _reasonName = 'มีการเพิ่มราคาสินค้า';
          }
        } else if (document.referReasonCode === 'DBNG02') {
          if (_language === 'en') {
            _reasonName = `Invalid product's price calculation (lower than it should be)`;
          } else {
            _reasonName = 'คำนวณราคาสินค้าผิดพลาดต่ำกว่าที่เป็นจริง';
          }
        } else if (document.referReasonCode === 'DBNG99') {
          if (_language === 'en') {
            _reasonName = 'Other reason (sale case)';
          } else {
            _reasonName = 'เหตุอื่น (กรณีขายสินค้า)';
          }
        } else if (document.referReasonCode === 'DBNS01') {
          if (_language === 'en') {
            _reasonName = `Increasing in service's price (higher than the agreed amount)`;
          } else {
            _reasonName = 'มีการเพิ่มราคาค่าบริการ';
          }
        } else if (document.referReasonCode === 'DBNS02') {
          if (_language === 'en') {
            _reasonName = `Invalid service's price calculation (lower than it should be)`;
          } else {
            _reasonName = 'คำนวณราคาค่าบริการผิดพลาดต่ำกว่าที่เป็นจริง';
          }
        } else if (document.referReasonCode === 'DBNS99') {
          if (_language === 'en') {
            _reasonName = `Other reason (service case)`;
          } else {
            _reasonName = 'เหตุอื่น (กรณีบริการ)';
          }
        } else if (document.referReasonCode === 'CDNG01') {
          if (_language === 'en') {
            _reasonName = `Decreasing in product's price (lower than the agreed amount)`;
          } else {
            _reasonName = 'ลดราคาสินค้าที่ขาย';
          }
        } else if (document.referReasonCode === 'CDNG02') {
          if (_language === 'en') {
            _reasonName = 'Product defect';
          } else {
            _reasonName = 'สินค้าชำรุดเสียหาย';
          }
        } else if (document.referReasonCode === 'CDNG03') {
          if (_language === 'en') {
            _reasonName = `Invalid product's quantity`;
          } else {
            _reasonName = 'สินค้าขาดจำนวนตามที่ตกลงซื้อขาย';
          }
        } else if (document.referReasonCode === 'CDNG04') {
          if (_language === 'en') {
            _reasonName = `Invalid product's price calculation (higher than it should be)`;
          } else {
            _reasonName = 'คำนวณราคาผิดพลาดสูงกว่าที่เป็นจริง';
          }
        } else if (document.referReasonCode === 'CDNG05') {
          if (_language === 'en') {
            _reasonName = 'Product return (not as advertised)';
          } else {
            _reasonName = 'รับคืนสินค้า';
          }
        } else if (document.referReasonCode === 'CDNG99') {
          if (_language === 'en') {
            _reasonName = `Other reason (sale case)`;
          } else {
            _reasonName = 'เหตุอื่น (กรณีขายสินค้า)';
          }
        } else if (document.referReasonCode === 'CDNS01') {
          if (_language === 'en') {
            _reasonName = `Decreasing in service's price (lower than the agreed amount)`;
          } else {
            _reasonName = 'ลดราคาค่าบริการ';
          }
        } else if (document.referReasonCode === 'CDNS02') {
          if (_language === 'en') {
            _reasonName = `Invalid service's quantity`;
          } else {
            _reasonName = 'ค่าบริการขาดจำนวน';
          }
        } else if (document.referReasonCode === 'CDNS03') {
          if (_language === 'en') {
            _reasonName = `Invalid service's price calculation (higher than it should be)`;
          } else {
            _reasonName = 'คำนวณราคาค่าบริการผิดพลาดสูงกว่าที่เป็นจริง';
          }
        } else if (document.referReasonCode === 'CDNS04') {
          if (_language === 'en') {
            _reasonName = `Service cancellation`;
          } else {
            _reasonName = 'บอกเลิกสัญญาบริการ';
          }
        } else if (document.referReasonCode === 'CDNS99') {
          if (_language === 'en') {
            _reasonName = `Other reason (service case)`;
          } else {
            _reasonName = 'เหตุอื่น (กรณีบริการ)';
          }
        }

        obj.calculationMethod = document.calculationMethod || 1;

        obj.refer = {
          typeCode: document.referTypeCode || '',
          typeName: _typeName,
          number: document.referNumber || '',
          date: _referDate,
          dateBE: _referDateBE || '',
          dateCE: _referDateCE,
          reasonCode: document.referReasonCode || '',
          reasonName: _reasonName,
          specificReason: document.referSpecificReason || '',
          amountTotal: _previousAmountTotal,
          diffAmountTotal: _diffAmountTotal,
          items: [],
        };
      }

      newArray.push(obj);
    }

    return newArray;
  };

  async function hanldeSubmit(e) {
    const checkRequireColumn = [];
    items.forEach((item) => {
      if (ROW_NAMES_REQUIRE.includes(item.column)) checkRequireColumn.push(item.column);
    });

    if (checkRequireColumn.length !== ROW_NAMES_REQUIRE.length) {
      return setCheckError(true);
    }

    setCookie('Excel-drag-drop', JSON.stringify(items), 365);

    const object = {
      order: '',
      documentType: 'ใบเสร็จรับเงิน/ใบกำกับภาษี',
      number: '',
      dateBE: '',
      dueDateBE: '',
      reference: '',
      includeVat: true,
      calculationMethod: 1,
      discount: 0,
      language: 'th',
      customerName: '',
      customerAddressLineOne: '',
      customerAddressLineTwo: '',
      customerPostcode: '',
      customerCountryName: 'Thailand',
      customerTaxNumberType: 'OTHR',
      customerTaxId: 'N/A',
      customerBranchNumber: '',
      customerPhone: '',
      customerEmail: '',
      note: '',
      createdBy: '',
      receivedBy: '',
      reIssue: false,
      referTypeCode: '',
      referNumber: '',
      referDateBE: '',
      referAmountTotal: 0,
      referReasonCode: '',
      referSpecificReason: '',

      itemDescription1: '',
      itemQuantity1: 1,
      itemUnitName1: '',
      itemPrice1: 0,
      itemDiscount1: 0,
      itemPercentVat1: 7,

      itemDescription2: '',
      itemQuantity2: 1,
      itemUnitName2: '',
      itemPrice2: 0,
      itemDiscount2: 0,
      itemPercentVat2: 7,

      itemDescription3: '',
      itemQuantity3: 1,
      itemUnitName3: '',
      itemPrice3: 0,
      itemDiscount3: 0,
      itemPercentVat3: 7,

      itemDescription4: '',
      itemQuantity4: 1,
      itemUnitName4: '',
      itemPrice4: 0,
      itemDiscount4: 0,
      itemPercentVat4: 7,

      itemDescription5: '',
      itemQuantity5: 1,
      itemUnitName5: '',
      itemPrice5: 0,
      itemDiscount5: 0,
      itemPercentVat5: 7,
    };

    items.forEach((item) => {
      object[item.column] = item.name;
    });

    const itemList = [];

    const trimDataArray = trimData(data);

    trimDataArray.forEach((dataInColumn) => {
      const newObj = { ...object };

      newObj.order = Object.keys(dataInColumn).find((key) => key === object['order'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['order'])]
        : '';
      newObj.documentType = Object.keys(dataInColumn).find((key) => key === object['documentType'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['documentType'])]
        : 'ใบเสร็จรับเงิน/ใบกำกับภาษี';
      newObj.number = Object.keys(dataInColumn).find((key) => key === object['number'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['number'])]
        : '';
      newObj.dateBE = Object.keys(dataInColumn).find((key) => key === object['dateBE'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['dateBE'])]
        : '';
      newObj.dueDateBE = Object.keys(dataInColumn).find((key) => key === object['dueDateBE'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['dueDateBE'])]
        : '';
      newObj.reference = Object.keys(dataInColumn).find((key) => key === object['reference'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['reference'])]
        : '';
      newObj.includeVat = Object.keys(dataInColumn).find((key) => key === object['includeVat'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['includeVat'])]
        : true;
      newObj.calculationMethod = Object.keys(dataInColumn).find((key) => key === object['calculationMethod'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['calculationMethod'])]
        : 1;
      newObj.discount = Object.keys(dataInColumn).find((key) => key === object['discount'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['discount'])]
        : 0;
      newObj.language = Object.keys(dataInColumn).find((key) => key === object['language'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['language'])]
        : 'th';
      newObj.customerName = Object.keys(dataInColumn).find((key) => key === object['customerName'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['customerName'])]
        : '';
      newObj.customerAddressLineOne = Object.keys(dataInColumn).find((key) => key === object['customerAddressLineOne'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['customerAddressLineOne'])]
        : '';
      newObj.customerAddressLineTwo = Object.keys(dataInColumn).find((key) => key === object['customerAddressLineTwo'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['customerAddressLineTwo'])]
        : '';
      newObj.customerPostcode = Object.keys(dataInColumn).find((key) => key === object['customerPostcode'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['customerPostcode'])]
        : '';
      newObj.customerCountryName = Object.keys(dataInColumn).find((key) => key === object['customerCountryName'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['customerCountryName'])]
        : 'Thailand';
      newObj.customerTaxNumberType = Object.keys(dataInColumn).find((key) => key === object['customerTaxNumberType'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['customerTaxNumberType'])]
        : '';
      newObj.customerTaxId = Object.keys(dataInColumn).find((key) => key === object['customerTaxId'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['customerTaxId'])]
        : '';
      newObj.customerBranchNumber = Object.keys(dataInColumn).find((key) => key === object['customerBranchNumber'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['customerBranchNumber'])]
        : '';
      newObj.customerPhone = Object.keys(dataInColumn).find((key) => key === object['customerPhone'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['customerPhone'])]
        : '';
      newObj.customerEmail = Object.keys(dataInColumn).find((key) => key === object['customerEmail'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['customerEmail'])]
        : '';
      newObj.note = Object.keys(dataInColumn).find((key) => key === object['note'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['note'])]
        : '';
      newObj.createdBy = Object.keys(dataInColumn).find((key) => key === object['createdBy'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['createdBy'])]
        : '';
      newObj.receivedBy = Object.keys(dataInColumn).find((key) => key === object['receivedBy'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['receivedBy'])]
        : '';
      newObj.reIssue = Object.keys(dataInColumn).find((key) => key === object['reIssue'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['reIssue'])]
        : false;
      newObj.referTypeCode = Object.keys(dataInColumn).find((key) => key === object['referTypeCode'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['referTypeCode'])]
        : '';
      newObj.referNumber = Object.keys(dataInColumn).find((key) => key === object['referNumber'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['referNumber'])]
        : '';
      newObj.referDateBE = Object.keys(dataInColumn).find((key) => key === object['referDateBE'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['referDateBE'])]
        : '';
      newObj.referAmountTotal = Object.keys(dataInColumn).find((key) => key === object['referAmountTotal'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['referAmountTotal'])]
        : 0;
      newObj.referReasonCode = Object.keys(dataInColumn).find((key) => key === object['referReasonCode'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['referReasonCode'])]
        : '';
      newObj.referSpecificReason = Object.keys(dataInColumn).find((key) => key === object['referSpecificReason'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['referSpecificReason'])]
        : '';

      newObj.itemDescription1 = Object.keys(dataInColumn).find((key) => key === object['itemDescription1'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemDescription1'])]
        : '';
      newObj.itemQuantity1 = Object.keys(dataInColumn).find((key) => key === object['itemQuantity1'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemQuantity1'])]
        : 1;
      newObj.itemUnitName1 = Object.keys(dataInColumn).find((key) => key === object['itemUnitName1'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemUnitName1'])]
        : '';
      newObj.itemPrice1 = Object.keys(dataInColumn).find((key) => key === object['itemPrice1'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemPrice1'])]
        : 0;
      newObj.itemDiscount1 = Object.keys(dataInColumn).find((key) => key === object['itemDiscount1'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemDiscount1'])]
        : 0;
      newObj.itemPercentVat1 = Object.keys(dataInColumn).find((key) => key === object['itemPercentVat1'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemPercentVat1'])]
        : 7;

      newObj.itemDescription2 = Object.keys(dataInColumn).find((key) => key === object['itemDescription2'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemDescription2'])]
        : '';
      newObj.itemQuantity2 = Object.keys(dataInColumn).find((key) => key === object['itemQuantity2'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemQuantity2'])]
        : 1;
      newObj.itemUnitName2 = Object.keys(dataInColumn).find((key) => key === object['itemUnitName2'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemUnitName2'])]
        : '';
      newObj.itemPrice2 = Object.keys(dataInColumn).find((key) => key === object['itemPrice2'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemPrice2'])]
        : 0;
      newObj.itemDiscount2 = Object.keys(dataInColumn).find((key) => key === object['itemDiscount2'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemDiscount2'])]
        : 0;
      newObj.itemPercentVat2 = Object.keys(dataInColumn).find((key) => key === object['itemPercentVat2'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemPercentVat2'])]
        : 7;

      newObj.itemDescription3 = Object.keys(dataInColumn).find((key) => key === object['itemDescription3'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemDescription3'])]
        : '';
      newObj.itemQuantity3 = Object.keys(dataInColumn).find((key) => key === object['itemQuantity3'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemQuantity3'])]
        : 1;
      newObj.itemUnitName3 = Object.keys(dataInColumn).find((key) => key === object['itemUnitName3'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemUnitName3'])]
        : '';
      newObj.itemPrice3 = Object.keys(dataInColumn).find((key) => key === object['itemPrice3'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemPrice3'])]
        : 0;
      newObj.itemDiscount3 = Object.keys(dataInColumn).find((key) => key === object['itemDiscount3'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemDiscount3'])]
        : 0;
      newObj.itemPercentVat3 = Object.keys(dataInColumn).find((key) => key === object['itemPercentVat3'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemPercentVat3'])]
        : 7;

      newObj.itemDescription4 = Object.keys(dataInColumn).find((key) => key === object['itemDescription4'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemDescription4'])]
        : '';
      newObj.itemQuantity4 = Object.keys(dataInColumn).find((key) => key === object['itemQuantity4'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemQuantity4'])]
        : 1;
      newObj.itemUnitName4 = Object.keys(dataInColumn).find((key) => key === object['itemUnitName4'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemUnitName4'])]
        : '';
      newObj.itemPrice4 = Object.keys(dataInColumn).find((key) => key === object['itemPrice4'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemPrice4'])]
        : 0;
      newObj.itemDiscount4 = Object.keys(dataInColumn).find((key) => key === object['itemDiscount4'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemDiscount4'])]
        : 0;
      newObj.itemPercentVat4 = Object.keys(dataInColumn).find((key) => key === object['itemPercentVat4'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemPercentVat4'])]
        : 7;

      newObj.itemDescription5 = Object.keys(dataInColumn).find((key) => key === object['itemDescription5'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemDescription5'])]
        : '';
      newObj.itemQuantity5 = Object.keys(dataInColumn).find((key) => key === object['itemQuantity5'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemQuantity5'])]
        : 1;
      newObj.itemUnitName5 = Object.keys(dataInColumn).find((key) => key === object['itemUnitName5'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemUnitName5'])]
        : '';
      newObj.itemPrice5 = Object.keys(dataInColumn).find((key) => key === object['itemPrice5'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemPrice5'])]
        : 0;
      newObj.itemDiscount5 = Object.keys(dataInColumn).find((key) => key === object['itemDiscount5'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemDiscount5'])]
        : 0;
      newObj.itemPercentVat5 = Object.keys(dataInColumn).find((key) => key === object['itemPercentVat5'])
        ? dataInColumn[Object.keys(dataInColumn).find((key) => key === object['itemPercentVat5'])]
        : 7;

      itemList.push(newObj);
    });

    itemList.forEach((item) => {
      const _items = [];

      if (item.itemDescription1) {
        let _percentVat = 7;
        if (item.itemPercentVat1 === 0 || item.itemPercentVat1 === '0') {
          _percentVat = 0;
        } else if (item.itemPercentVat1 === -1 || item.itemPercentVat1 === '-1') {
          _percentVat = -1;
        }

        _items.push({
          itemsNumber: 1,
          itemsDescription: item.itemDescription1,
          itemsQuantity: Number(item.itemQuantity1) || 1,
          itemsPrice: Number(item.itemPrice1) || 0,
          itemsPercentVat: _percentVat,
          itemsUnitName: item.itemUnitName1 || '',
          itemsDiscount: Number(item.itemDiscount1) || 0,
          itemsSKU: item.itemsSKU || '',
        });
      }

      if (item.itemDescription2) {
        let _percentVat = 7;
        if (item.itemPercentVat2 === 0 || item.itemPercentVat2 === '0') {
          _percentVat = 0;
        } else if (item.itemPercentVat2 === -1 || item.itemPercentVat2 === '-1') {
          _percentVat = -1;
        }

        _items.push({
          itemsNumber: 2,
          itemsDescription: item.itemDescription2,
          itemsQuantity: Number(item.itemQuantity2) || 1,
          itemsPrice: Number(item.itemPrice2) || 0,
          itemsPercentVat: _percentVat,
          itemsUnitName: item.itemUnitName2 || '',
          itemsDiscount: Number(item.itemDiscount2) || 0,
          itemsSKU: item.itemsSKU || '',
        });
      }

      if (item.itemDescription3) {
        let _percentVat = 7;
        if (item.itemPercentVat3 === 0 || item.itemPercentVat3 === '0') {
          _percentVat = 0;
        } else if (item.itemPercentVat3 === -1 || item.itemPercentVat3 === '-1') {
          _percentVat = -1;
        }

        _items.push({
          itemsNumber: 3,
          itemsDescription: item.itemDescription3,
          itemsQuantity: Number(item.itemQuantity3) || 1,
          itemsPrice: Number(item.itemPrice3) || 0,
          itemsPercentVat: _percentVat,
          itemsUnitName: item.itemUnitName3 || '',
          itemsDiscount: Number(item.itemDiscount3) || 0,
          itemsSKU: item.itemsSKU || '',
        });
      }

      if (item.itemDescription4) {
        let _percentVat = 7;
        if (item.itemPercentVat4 === 0 || item.itemPercentVat4 === '0') {
          _percentVat = 0;
        } else if (item.itemPercentVat4 === -1 || item.itemPercentVat4 === '-1') {
          _percentVat = -1;
        }

        _items.push({
          itemsNumber: 4,
          itemsDescription: item.itemDescription4,
          itemsQuantity: Number(item.itemQuantity4) || 1,
          itemsPrice: Number(item.itemPrice4) || 0,
          itemsPercentVat: _percentVat,
          itemsUnitName: item.itemUnitName4 || '',
          itemsDiscount: Number(item.itemDiscount4) || 0,
          itemsSKU: item.itemsSKU || '',
        });
      }

      if (item.itemDescription5) {
        let _percentVat = 7;
        if (item.itemPercentVat5 === 0 || item.itemPercentVat5 === '0') {
          _percentVat = 0;
        } else if (item.itemPercentVat5 === -1 || item.itemPercentVat5 === '-1') {
          _percentVat = -1;
        }

        _items.push({
          itemsNumber: 5,
          itemsDescription: item.itemDescription5,
          itemsQuantity: Number(item.itemQuantity5) || 1,
          itemsPrice: Number(item.itemPrice5) || 0,
          itemsPercentVat: _percentVat,
          itemsUnitName: item.itemUnitName5 || '',
          itemsDiscount: Number(item.itemDiscount5) || 0,
          itemsSKU: item.itemsSKU || '',
        });
      }

      item.items = _items;
    });

    let failItems = [];
    itemList.forEach((row) => {
      if (validateField(row).length > 0)
        failItems.push({
          order: row.order,
          number: row.number,
          dateBE: row.dateBE,
          error: validateField(row),
        });
    });

    if (failItems.length > 0) {
      // openFile('fail', failItems);
      openFileFail(failItems, itemList);
    } else {
      setIsLoading(true);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          await axios
            .get(API_URL + '/etax/companies', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            })
            .then(
              async (response) => {
                if (response.data[0]) {
                  const array = await toObj(itemList, response.data[0]);

                  let pass = [];

                  if (array.length) {
                    array.forEach((document, index) => {
                      let newObj = document;
                      newObj.key = index + 1;
                      newObj.date = document.date;
                      newObj.referNo = document.number;
                      newObj.receiptType = document['documentType'];
                      newObj.companyName = document['company']['name'];
                      newObj.customerName = document['customer']['name'];
                      newObj.taxId = document['customer']['taxId'];
                      newObj.vat = document.vatTotal.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      });
                      newObj.total = document.grandTotal.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      });
                      newObj.amount = document.amountTotal.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      });
                      newObj.status = '';

                      pass.push(newObj);
                    });

                    openFile('pass', pass);
                  }
                } else {
                  history.push({
                    pathname: '/etax/companies/profiles',
                    state: {},
                  });
                }
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }
  }

  const openFile = (event, item) => {
    history.push({
      pathname: event === 'pass' ? '/etax/sources/excel/dragdrop/list' : '/etax/sources/excel/dragdrop/check/result',
      state: {
        item: item,
      },
    });
  };

  const openFileFail = (item, mergeArray) => {
    history.push({
      pathname: '/etax/sources/excel/dragdrop/check/result',
      state: {
        item: item,
        mergeArray: mergeArray,
      },
    });
  };

  return (
    <Stack>
      {checkError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setCheckError(false)}
          dismissButtonAriaLabel={t('excelDragDropCheck.close')}
          style={{
            fontSize: 14,
          }}
        >
          {t('excelDragDropCheck.checkError')}
        </MessageBar>
      )}

      <DndProvider backend={HTML5Backend}>
        <Stack>
          {isLoading ? (
            <Stack>
              <Stack horizontal horizontalAlign="center">
                <br />
                <br />
                <div className="certificates-loading-center">
                  <Spinner label={t('excelDragDropCheck.processing')} labelPosition="down" styles={spinnerStyles} />
                </div>
                <br />
              </Stack>
            </Stack>
          ) : (
            <>
              <br />
              <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
                <PrimaryButton text={t('excelDragDropCheck.confirm')} style={{ width: '125px', letterSpacing: '1px' }} onClick={hanldeSubmit} />
                <DefaultButton
                  text={t('excelDragDropCheck.back')}
                  onClick={() =>
                    history.push({
                      pathname: '/etax/sources/excel/dragdrop',
                      state: {},
                    })
                  }
                />
              </Stack>
              <br />
              <Stack horizontal horizontalAlign="center">
                <h2>
                  {t('excelDragDropCheck.file')} {fileName}
                </h2>
              </Stack>
              <br />
              <Stack horizontal styles={{ root: { paddingLeft: '318.8px' } }}>
                <DefaultButton
                  text={t('excelDragDropCheck.reset')}
                  styles={{ root: { width: '200px', paddingLeft: 0, paddingRight: 0 } }}
                  onClick={() => handleReset()}
                />
                <div style={{ width: '55.2px', textAlign: 'center' }}></div>
                <div style={{ width: '218.8px', textAlign: 'center', fontSize: '1rem', lineHeight: 'normal', color: 'rgb(50, 49, 48)' }}>
                  {t('excelDragDropCheck.columnFromFile')}
                </div>
              </Stack>
              <br />
              <Stack horizontal tokens={{ childrenGap: 15 }}>
                <Stack styles={{ root: { width: '560px' } }}>
                  <Stack horizontal styles={{ root: noRowStyle }}>
                    <div style={{ width: '298.8px' }}>{t('excelDragDropCheck.columnList')}</div>
                    <div style={{ width: '232px' }}>{t('excelDragDropCheck.columnPosition')}</div>
                    <div style={{ width: '29.8px' }}></div>
                  </Stack>
                  <Stack
                    styles={{
                      root: {
                        height: 604,
                        overflowY: 'auto',
                        border: '1px solid rgb(193, 193, 193)',
                        borderTopLeftRadius: '2px',
                        borderTopRightRadius: '2px',
                      },
                    }}
                  >
                    {Object.keys(ROW_NAMES).map((_columnName) =>
                      _columnName !== noRow ? (
                        <Dustbin key={_columnName} title={_columnName}>
                          {returnItemsForColumn(_columnName)}
                        </Dustbin>
                      ) : null
                    )}
                  </Stack>
                </Stack>
                <Stack>
                  <Dustbin title={noRow}>
                    <Stack styles={{ root: { height: 604, overflowY: 'auto', border: '1px solid rgb(193, 193, 193)' } }}>
                      {returnItemsForColumn(noRow)}
                    </Stack>
                  </Dustbin>
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
      </DndProvider>
    </Stack>
  );
}
