import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, DefaultButton, ProgressIndicator, Spinner } from '@fluentui/react';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../authConfig';

//Progress Indicator Setting
const intervalDelay = 100;
const intervalIncrement = 0.01;

const SentReport = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();
  const data = location.state;
  console.log(data);

  if (data === undefined) {
    history.push('/sent/upload');
  }

  const [percentComplete, setPercentComplete] = useState(0);
  const [html, setHTML] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);

  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const handleDownload = () => {
    setIsDownloading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            'http://localhost:7071/api/SentReportDownload',
            {
              all_docs: data.all_docs,
              totalPages: data.totalPages,
              time: data.createdTime,
              companyName: data.companyName,
              companyEmail: data.companyEmail,
              receiverEmail: data.receiverEmail,
              companyTaxId: data.companyTaxId,
              companyAllAddress: data.companyAllAddress,
              headOffice: data.headOffice,
              branchOffice: data.branchOffice,
            },
            {
              headers: {
                // Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
              },
              responseType: 'blob',
            }
          )
          .then((response) => {
            console.log(response);

            const time = data.createdTime.split(' ')[0] + '_' + data.createdTime.split(' ')[1];
            let fileName = time + '-Sent-Report.pdf';

            console.log('file name: ', fileName);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();

            setIsDownloading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const goBack = () => {
    history.push({
      pathname: '/sent',
      state: {},
    });
  };

  useEffect(() => {
    console.log('userEffect Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        try {
          axios
            .post(
              'http://localhost:7071/api/SentReport',
              {
                all_docs: data.all_docs,
                totalPages: data.totalPages,
                time: data.createdTime,
                companyName: data.companyName,
                companyEmail: data.companyEmail,
                receiverEmail: data.receiverEmail,
                companyTaxId: data.companyTaxId,
                companyAllAddress: data.companyAllAddress,
                headOffice: data.headOffice,
                branchOffice: data.branchOffice,
              },
              {
                headers: {
                  // Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'application/json',
                },
              }
            )
            .then((response) => {
              setHTML(response.data);
              setPercentComplete(100);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (e) {
          console.log(e.response);
        }
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html]);

  useEffect(() => {
    if (html === '') {
      const id = setInterval(() => {
        setPercentComplete((intervalIncrement + percentComplete) % 1);
      }, intervalDelay);
      return () => {
        clearInterval(id);
      };
    }
  });

  return (
    <div>
      <br />
      {!isDownloading && (
        <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
          <DefaultButton text={t('sentReport.download')} onClick={handleDownload} />
          <DefaultButton text={t('sentReport.back')} onClick={goBack}></DefaultButton>
        </Stack>
      )}

      {isDownloading && <Spinner label={t('sentReport.downloading')} labelPosition="right" style={centerItem} />}

      <br />
      {!html && (
        <div
          style={{
            width: '780px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            marginLeft: '0px',
            marginRight: '0px',
          }}
        >
          <ProgressIndicator
            label={t('sentReport.processing')}
            description={t('sentReport.processingMessage')}
            percentComplete={percentComplete}
            style={centerItem}
          />
          <br />
        </div>
      )}
      <div id="printableArea" dangerouslySetInnerHTML={{ __html: html }} />
      <br />
      <br />
    </div>
  );
};

export default SentReport;
