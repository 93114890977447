import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { PrimaryButton, DefaultButton, Stack, getTheme, Separator, Checkbox } from '@fluentui/react';

const theme = getTheme();

//Style
const centerItem = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

const stackTokens = { childrenGap: 15 };

const TermsConditions = () => {
  console.log('Re render');

  const history = useHistory();

  const [accept, setAccept] = useState(false);

  return (
    <div>
      <h2 style={{ margin: '2%' }}>ข้อตกลงและเงื่อนไข</h2>
      <div
        className="ms-Grid"
        dir="ltr"
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 20,
          paddingBottom: 30,
          background: '#ffffff',
          margin: '2%',
          boxShadow: theme.effects.elevation4,
        }}
      >
        <div className="ms-Grid-row">
          <b style={centerItem}>ข้อตกลงและเงื่อนไขการติดตั้งใบรับรองอิเล็กทรอนิกส์</b>
          <Separator />

          <Stack horizontal tokens={stackTokens} style={{ paddingLeft: '2%', paddingRight: '2%' }}>
            <small>
              <p>
                ข้อตกลงนี้ใช้บังคับระหว่าง บริษัท ฟรีเวชั่น จำกัด (ซึ่งต่อไปนี้เรียกว่า “ผู้ให้บริการ”) กับผู้ขอใช้บริการที่ได้ระบุไว้ตามข้อตกลงนี้
                (ซึ่งต่อไปนี้เรียกว่า “ผู้ใช้บริการ”) โดยผู้ใช้บริการตกลงและยินยอมผูกพันและปฏิบัติตามข้อตกลงและเงื่อนไขดังต่อไปนี้
              </p>
            </small>
          </Stack>

          <Stack horizontal horizontalAlign="left" style={{ paddingLeft: '2%', paddingRight: '2%' }}>
            <small>
              <ol>
                <li>
                  ผู้ใช้บริการรับทราบว่าผู้ให้บริการไม่ได้เป็นผู้ออกใบรับรองอิเล็กทรอนิกส์
                  ผู้ให้บริการอำนวยความสะดวกให้แก่ผู้ใช้บริการโดยการเป็นตัวแทนของผู้ใช้บริการในการขอใบรับรองอิเล็กทรอนิกส์จากบริษัทที่มีหน้าที่ออกใบรับรองอิเล็กทรอนิกส์
                  (CA)
                </li>
                <br />
                <li>
                  ใบรับรองอิเล็กทรอนิกส์เมื่อติดตั้งลงไปในระบบแล้วจะไม่สามารถคัดลอกหรือนำออกไป
                  เนื่องจากเป็นการออกแบบระบบคอมพิวเตอร์เพื่อความปลอดภัยไม่ให้ใบรับรองอิเล็กทรอนิกส์ถูกผู้อื่นคัดลอกออกไปได้
                </li>
                <br />
                <li>
                  ในกรณีที่ผู้ใช้บริการ ประสงค์ยกเลิกการใช้งานหรือบัญชีของผู้ใช้บริการหมดอายุ แต่ใบรับรองอิเล็กทรอนิกส์ยังไม่หมดอายุ ผู้ให้บริการจะ
                  <b>
                    <u>ไม่คืนเงิน</u>
                  </b>
                  ค่าใบรับรองอิเล็กทรอนิกส์ เนื่องจากได้นำเงินไปชำระให้กับบริษัทที่มีหน้าที่ออกใบรับรองอิเล็กทรอนิกส์ (CA) ไปแล้ว
                </li>
                <br />
                <li>
                  หากบัญชีของผู้ใช้บริการหมดอายุการใช้งานหรือผู้ใช้บริการได้แจ้งยกเลิกการใช้งาน
                  ผู้ให้บริการมีสิทธิ์ในการลบใบรับรองอิเล็กทรอนิกส์ในบัญชีของผู้ใช้งาน
                </li>
                <br />
                <li>ระเบียบและเงื่อนไขการใช้ใบรับรองอิเล็กทรอนิกส์ ฉบับนี้ เป็นส่วนหนึ่งของใบคำขอใบรับรองอิเล็กทรอนิกส์</li>
                <br />
                <li>ผู้ใช้บริการจะไม่นำใบรับรองอิเล็กทรอนิกส์ไปใช้ในทางที่ขัดต่อกฎหมาย และความสงบเรียบร้อย หรือศีลธรรมอันดีของประชาชน</li>
                <br />
                <li>
                  ผู้ใช้บริการต้องปฏิบัติตามกฎหมายว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์
                  หากผู้ให้บริการพบว่าผู้ใช้บริการมีการกระทำความผิดต่อกฎหมายดังกล่าว ผู้ให้บริการ มีสิทธิระงับ และ/หรือ
                  หยุดการให้บริการโดยไม่ต้องแจ้งให้ผู้ใช้บริการทราบล่วงหน้าและไม่ต้องคืนค่าใช้บริการใบรับรองอิเล็กทรอนิกส์
                </li>
                <br />
                <li>
                  ผู้ใช้บริการต้องปฏิบัติตามข้อบังคับ ระเบียบ ประกาศ และเงื่อนไขต่าง ๆ ของผู้ให้บริการ
                  ที่เกี่ยวข้องกับบริการที่ใช้อยู่และ/หรือที่จะออกใช้บังคับต่อไปในภายหน้า
                </li>
                <br />
                <li>
                  ใบรับรองอิเล็กทรอนิกส์ ที่ผู้ให้บริการออกให้มีอายุการใช้งานตามอายุการขอใช้บริการที่ระบุไว้ในคำขอใช้บริการใบรับรองอิเล็กทรอนิกส์
                </li>
                <br />
                <li>
                  ผู้ใช้บริการตกลงและยอมรับปฏิบัติตามโยบายใบรับรองอิเล็กทรอนิกส์ Certificate Policy (CP) และแนวทางปฏิบัติใบรับรองอิเล็กทรอนิกส์
                  Certification Practice Statement (CPS) ที่ผู้ออกใบรับรองได้แสดงไว้
                  หากมีความเสียหายเกิดขึ้นจากการดังกล่าวผู้ขอใบรับรองจะเป็นผู้รับผิดชอบต่อความเสียหายที่เกิดขึ้นทุกประการ
                </li>
                <br />
                <li>
                  ผู้ใช้บริการตกลงและยอมรับว่าในกรณีที่มีการใช้กุญแจส่วนตัว ของผู้ใช้บริการสร้างลายมือชื่ออิเล็กทรอนิกส์
                  และบันทึกลงบนข้อมูลอิเล็กทรอนิกส์ที่ใช้ในการติดต่อสื่อสารบนเครือข่ายคอมพิวเตอร์เพื่อก่อให้เกิดนิติสัมพันธ์ระหว่างบุคคลหรือองค์กร
                  ผู้ใช้บริการตกลงและยินยอมให้ถือเสมือนว่าลายมือชื่ออิเล็กทรอนิกส์มีความผูกพันตามกฎหมายเสมือนเป็นการลงลายมือชื่อของผู้ใช้บริการในเอกสารนิติกรรม
                  หรือสัญญา และยินยอมผูกพันตามเนื้อหาหรือข้อมูลนั้นทุกประการ
                </li>
                <br />
                <li>
                  ผู้ใช้บริการตกลง
                  และยินยอมให้ผู้ให้บริการเปิดเผยข้อมูลในใบรับรองอิเล็กทรอนิกส์ที่ผู้ให้บริการออกให้แก่ผู้ใช้บริการตามเอกสารใบคำขอใบรับรองอิเล็กทรอนิกส์ฉบับนี้
                  รวมทั้งยินยอมให้ผู้ให้บริการเก็บรักษา และเปิดเผยข้อมูลใด ๆ รวมถึงข้อมูลส่วนบุคคล ที่ผู้ให้บริการได้รับจากผู้ใช้บริการ เพื่อให้บุคคล
                  และ/หรือ องค์กรผู้ได้รับลายมือชื่ออิเล็กทรอนิกส์จากผู้ใช้บริการ สามารถตรวจสอบตัวตนของตัวบุคคล และ/หรือ
                  องค์กรผู้เป็นเจ้าของลายมือชื่ออิเล็กทรอนิกส์นั้นได้
                </li>
                <br />
                <li>
                  ผู้ใช้บริการรับรองว่าข้อมูลหรือข้อเท็จจริงใด ๆ ที่ได้ให้ไว้ในเอกสารใบคำขอใบรับรองอิเล็กทรอนิกส์ ตรงตามความเป็นจริง
                  หากข้อมูลที่ผู้ขอใบรับรองให้ไว้ไม่ถูกต้องตรงตามความเป็นจริง และมีการนำไปใช้ในการที่เกิดความเสียหายแก่บุคคลใด ๆ
                  ผู้ขอใบรับรองจะเป็นผู้รับผิดชอบในความเสียหายที่เกิดขึ้นทุกประการ โดยผู้ใช้บริการจะต้องกรอก หรือระบุข้อมูลรายละเอียดต่าง ๆ
                  ตามความเป็นจริงให้ครบถ้วน และยินยอมให้ผู้ให้บริการตรวจสอบความถูกต้องและ
                  เปิดเผยข้อมูลตามที่ได้ระบุไว้ในการยื่นแบบฟอร์มคำขอใช้บริการใบรับรองอิเล็กทรอนิกส์ หากผู้ให้บริการตรวจพบว่า
                  ข้อมูลของผู้ขอใช้บริการไม่เป็นความจริง ผู้ให้บริการสามารถระงับหรือยกเลิกสิทธิการใช้งานของผู้ขอใช้บริการได้โดยไม่
                  ต้องแจ้งให้ทราบล่วงหน้า และผู้ขอใช้บริการจะต้องรับผิดชอบในความเสียหายที่เกิดขึ้นจากการกรอกข้อมูลอันเป็นเท็จ ดังกล่าว
                </li>
                <br />
                <li>
                  ผู้ใช้บริการตกลงที่จะชำระค่าธรรมเนียมการขอใช้ใบรับรองอิเล็กทรอนิกส์รวมทั้งค่าธรรมเนียมอื่นใดที่เกี่ยวข้องให้กับผู้ออกใบรับรองตามอัตราที่ผู้ออกใบรับรองกำหนด
                  โดยผู้ขอใบรับรองจะต้องชำระค่าธรรมเนียมดังกล่าวให้แก่ผู้ออกใบรับรองล่วงหน้า ตามอัตราค่าธรรมเนียมที่ผู้ออกใบรับรองเป็นผู้กำหนด
                </li>
                <br />
                <li>
                  ในกรณีผู้ใช้บริการ ประสงค์ยกเลิกการใช้ใบรับรองอิเล็กทรอนิกส์ ก่อนครบอายุการขอใช้บริการที่แจ้งไว้
                  ผู้ให้บริการไม่ต้องคืนค่าใช้บริการใบรับรองอิเล็กทรอนิกส์ให้แก่ผู้ใช้บริการ
                </li>
                <br />
                <li>
                  ก่อนครบกำหนดระยะเวลาขั้นต่ำที่ระบุไว้ในใบคำขอใบรับรองอิเล็กทรอนิกส์ ผู้ให้บริการจะแจ้งให้ผู้ใช้บริการทราบ ล่วงหน้าไม่น้อยกว่า 30
                  (สามสิบ) วัน หากผู้ใช้บริการประสงค์จะต่ออายุใบรับรองอิเล็กทรอนิกส์ให้บอกกล่าวให้ ผู้ให้บริการ ทราบเป็นลายลักษณ์อักษร
                  ทั้งนี้หากผู้ใช้บริการไม่ดำเนินการตามวรรคหนึ่งให้ถือว่าใบรับรองอิเล็กทรอนิกส์มีผลเป็นอันสิ้นสุดทันที
                </li>
                <br />
                <li>
                  ผู้ให้บริการมีสิทธิระงับการให้บริการ และ/หรือ การบอกเลิกข้อตกลงกับผู้ใช้บริการได้ทันที
                  ถ้าปรากฏว่าผู้ใช้บริการปฏิบัติผิดเงื่อนไขข้อตกลง หรือถูกศาลมีคำสั่งพิทักษ์ทรัพย์ โดยผู้ใช้บริการยอมรับว่าการกระทำใด ๆ
                  อันเนื่องมาจากการระงับการให้บริการ และ/หรือการบอกเลิกสัญญานี้ไม่เป็นการล่วงละเมิดต่อผู้ใช้บริการประการใด
                  และผู้ให้บริการไม่ต้องคืนค่าใช้บริการใบรับรองอิเล็กทรอนิกส์ ให้แก่ผู้ใช้บริการ
                </li>
                <br />
                <li>
                  ผู้ให้บริการจะทำการจัดเก็บข้อมูลส่วนบุคคลในรูปแบบของกระดาษ เอกสาร
                  และ/หรือข้อมูลทางอิเล็กทรอนิกส์เพื่อใช้เป็นหลักฐานในการตรวจสอบตามมาตรฐาน Web Trust for Certification Authority โดยมีระยะเวลา 10 ปี
                  นับตั้งแต่วันที่ผู้ใช้บริการยื่นขอใบคำขอใบรับรองอิเล็กทรอนิกส์
                </li>
                <br />
                <li>
                  เพื่อประโยชน์ในการพัฒนาหรือปรับปรุงการให้บริการ ผู้ให้บริการมีสิทธิที่จะแก้ไข เปลี่ยนแปลง
                  หรือยกเลิกเงื่อนไขการใช้ใบรับรองอิเล็กทรอนิกส์นี้ได้ ทั้งนี้ ผู้ให้บริการจะแจ้งให้ผู้ขอใช้บริการทราบโดยวิธีการทางอิเล็กทรอนิกส์
                </li>
              </ol>
            </small>
          </Stack>
        </div>
      </div>

      <Stack horizontal tokens={stackTokens} style={centerItem}>
        <Checkbox
          label="ข้าพเจ้ายอมรับ และได้อ่านข้อความในข้อตกลงและเงื่อนไขนี้เข้าใจโดยละเอียดแล้ว"
          onChange={(e, isChecked) => {
            setAccept(isChecked);
          }}
        />
      </Stack>
      <br />
      <br />
      <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
        <PrimaryButton
          name="next"
          text="ดำเนินการต่อ"
          type="submit"
          disabled={!accept}
          onClick={() => {
            history.push({
              pathname: '/ca/companies',
              state: {},
            });
          }}
        />

        <DefaultButton
          text="ย้อนกลับ"
          style={{ marginLeft: '15px' }}
          onClick={() => {
            history.push({
              pathname: '/etax/certificates/add',
              state: {},
            });
          }}
        />
      </Stack>

      <br />
      <br />
    </div>
  );
};

export default TermsConditions;
