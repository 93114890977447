import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Stack, Icon, PrimaryButton, FocusTrapZone, Spinner } from '@fluentui/react';

const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
const API_URL = process.env.REACT_APP_API_URL;

const PAYPAL_URL = process.env.REACT_APP_PAYPAL_URL;

const client_id = process.env.REACT_APP_PAYPAL_CLIENT_ID;
const redirect_uri = PUBLIC_URL + '/etax/sources/paypal/connect';
// const redirect_uri = 'https://my.leceipt.com/etax/sources/paypal/connect';

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const PaypalConnect = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const location = useLocation();
  const history = useHistory();

  const data = location.state;

  console.log(data);

  if (data) {
    sessionStorage.setItem('paypal-backUrl', data.backUrl);
  }

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);

  const [status, setStatus] = useState('');

  //   const callBackUrl = `https://www.sandbox.paypal.com/connect?flowEntry=static&client_id=${client_id}&response_type=code&scope=openid profile https://uri.paypal.com/services/invoicing&redirect_uri=${redirect_uri}`;
  const callBackUrl = `${PAYPAL_URL}/connect?flowEntry=static&client_id=${client_id}&response_type=code&scope=openid profile email https://uri.paypal.com/services/invoicing&redirect_uri=${redirect_uri}`;

  const companyId = sessionStorage.getItem('companyId') || '';

  useEffect(() => {
    console.log('userEffect retrieve users data!');

    const query = new URLSearchParams(window.location.search);
    const code = query.get('code');

    console.log('code', code);

    if (code) {
      setIsLoading(true);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + '/sources/paypal',
              {
                code: code,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId
                },
              }
            )
            .then(
              (response) => {
                console.log(response);

                if (response.data) {
                  if (response.data.canLogin) {
                    if (sessionStorage.getItem('paypal-backUrl')) {
                      history.push({
                        pathname: sessionStorage.getItem('paypal-backUrl'),
                        state: {
                          getAccessToken: true,
                          path: 'paypal',
                        },
                      });
                    } else {
                      history.push({
                        pathname: '/etax/sources/paypal',
                        state: {},
                      });
                    }
                  } else {
                    setStatus('fail');
                  }
                }

                setIsLoading(false);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/sources/paypal', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
            },
            })
            .then(
              (response) => {
                console.log('Paypal sources: ', response);

                if (response.data) {
                  if (!response.data.disabled && response.data.canLogin) {
                    history.push({
                      pathname: '/etax/sources/paypal',
                      state: {},
                    });
                  }
                }

                setIsLoading(false);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {!isLoading && (
        <Stack>
          <br />

          <h1 style={{ marginLeft: '0px', marginTop: '27px' }}>
            <Icon iconName="CloudImportExport" style={{ color: '#106ebe' }} /> {t('paypalConnect.header')}
          </h1>
          <h3 style={{ marginTop: '0px', marginBottom: '10px' }}>{t('paypalConnect.subHeader1')}</h3>

          <br />
          <br />
        </Stack>
      )}
      {status === '' && !isLoading && (
        <FocusTrapZone disabled={true}>
          <Stack horizontalAlign="center">
            <Stack
              style={{
                border: '1px solid rgb(134, 134, 134)',
                width: '350px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <br />

              <Stack horizontal horizontalAlign="center">
                <img src="/images/paypal_vertical.png" width="200px" style={{ margin: 20 }} alt="paypalvertical" />
              </Stack>
              <form>
                <br />
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
                  <PrimaryButton
                    style={{
                      fontSize: '20px',
                      height: '50px',
                      width: '255px',
                    }}
                    href={callBackUrl}
                  >
                    {t('paypalConnect.connect')}
                  </PrimaryButton>
                </Stack>
              </form>
              <br />
              <br />
            </Stack>
          </Stack>
        </FocusTrapZone>
      )}
      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              {false ? (
                <Spinner label={t('paypalConnect.processing')} labelPosition="down" styles={spinnerStyles} />
              ) : (
                <Spinner label={t('paypalConnect.loading')} labelPosition="down" styles={spinnerStyles} />
              )}
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'success' && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: '1px solid rgb(134, 134, 134)',
              width: '300px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#0078D4' }}>{t('paypalConnect.successMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/success03.gif" alt="success03gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  history.push({
                    pathname: '/etax/sources/paypal',
                    state: {},
                  });
                }}
              >
                {t('paypalConnect.successButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      {status === 'fail' && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: '1px solid rgb(134, 134, 134)',
              width: '300px',
              backgroundColor: '#FFFFFF',
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: '0px', color: '#F25139' }}>{t('paypalConnect.failMessage')}</h1>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/fail01.gif" alt="fail01gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: '20px', height: '50px' }}
                onClick={() => {
                  setStatus('');
                }}
              >
                {t('paypalConnect.failButton')}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
          </Stack>
        </Stack>
      )}

      <br />
      <br />
      {status === '' && !isLoading && (
        <center>
          <br />
          <br />
          <span style={{ color: 'gray' }}>{t('paypalConnect.footer1')}</span>
          <br />
          <br />
          <span style={{ color: 'gray', fontWeight: 'bold' }}>{t('paypalConnect.footer2')}</span>
        </center>
      )}
    </Stack>
  );
};

export default PaypalConnect;
