import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../authConfig';

// import * as signalR from '@microsoft/signalr';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Calendar,
  DateRangeType,
  Selection,
  SelectionMode,
  CheckboxVisibility,
  mergeStyleSets,
  ActionButton,
  Spinner,
  SpinnerSize,
  CommandBar,
  SearchBox,
  Stack,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  mergeStyles,
  TextField,
  MessageBar,
  MessageBarType,
  DocumentCard,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardLogo,
  DocumentCardType,
  Text,
  ContextualMenu,
  ContextualMenuItemType,
  IconButton,
  Checkbox,
  TooltipHost,
  Icon,
  DatePicker,
  Dropdown,
  HoverCard,
  HoverCardType,
  Separator,
  Persona,
  PersonaSize,
  PersonaInitialsColor,
} from '@fluentui/react';


// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import CountUp from 'react-countup';

import PdfAllPages from '../etax/documents/pdf/view/PdfAllPages';

import html2canvas from 'html2canvas';
import { getPercentWhtList } from '../../providers/PercentWhtData';
import { TextFieldEmail } from '../../helpers/customComponents/TextFieldEmail';
import { checkEmail } from '../../helpers/CheckEmail';
var JSZip = require('jszip');

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  minWidth: 'calc(100vw - 310px)',
  background: '#FAF9F8',
  position: 'relative',
};

//Dialog Style
// Success dialog style
const iconClass = mergeStyles({
  fontSize: 120,
  height: 120,
  width: 120,
  margin: '0 60px',
});

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
  test: {
    color: 'red',
  },
  blue: [
    {
      color: '#0078D4',
    },
    iconClass,
  ],
});

const cardStyles = {
  cardStyles: {
    root: {
      background: 'white',
      borderLeft: '5px solid #0078d4',
      width: '27%',
      minWidth: '332px',
      maxWidth: '424px',
      height: '130px',
    },
  },
  header: {
    root: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingTop: '20px',
      textAlign: 'center',
    },
  },
  amount: {
    root: {
      fontSize: 30,
      paddingBottom: '25px',
      textAlign: 'center',
    },
  },
};

function formatDateTime(date) {
  let d = new Date(date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = '' + d.getHours(),
    minute = '' + d.getMinutes(),
    second = '' + d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [year, month, day, hour, minute, second].join('');
}

const onFormatDate = (date, format) => {
  if (format === 'CE') {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
  }
};

const AccountReceivable = () => {
  console.log('Home render!!!');

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const companyId = sessionStorage.getItem('companyId') || '';

  const printRef = useRef();
  const [url, setUrl] = useState('');

  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);

  const [totalProcessingDocuments, setTotalProcessingDocuments] = useState(0);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem('accRec-pageSize') * 1 || 10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('accRec-currentPage') * 1 || 1);

  const [search, setSearch] = useState('');
  const [previousSearch, setPreviousSearch] = useState('');

  const [enableTopup, setEnableTopup] = useState(false);

  //CommandBars Items
  const [documentType, setDocumentType] = useState(
    (sessionStorage.getItem('accRec-documentType-key') && {
      key: sessionStorage.getItem('accRec-documentType-key'),
      text: sessionStorage.getItem('accRec-documentType-text'),
    }) || {
      key: 'ALL',
      text: t('documents.allDocumentsType'),
    }
  );

  const [isClickEmail, setIsClickEmail] = useState(false);
  const [isClickCopyEmail, setIsClickCopyEmail] = useState(false);
  const [fileIdEmail, setFileIdEmail] = useState('');
  const [fileNameEmail, setFileNameEmail] = useState('');
  const [fileDisplayName, setFileDisplayName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [copyEmailList, setCopyEmailList] = useState([])
  const [copyEmail, setCopyEmail] = useState('');
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [errorMessageCopyEmail, setErrorMessageCopyEmail] = useState('');

  const [isClickSms, setIsClickSms] = useState(false);
  const [fileIdSms, setFileIdSms] = useState('');
  const [fileNameSms, setFileNameSms] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [showSmsSuccess, setShowSmsSuccess] = useState(false);
  const [showSmsError, setShowSmsError] = useState(false);
  const [errorMessageSms, setErrorMessageSms] = useState('');

  const [changeDocumentStatusPayload, setChangeDocumentStatusPayload] = useState({
    isClick: false,
    documentId: '',
    type: '',
    number: '',
    statusKey: '',
    statusText: '',
    prevStatusKey: '',
  });

  const bankList = [
    { key: 'KBANK', text: 'ธนาคารกสิกรไทย' },
    { key: 'SCB', text: 'ธนาคารไทยพาณิชย์' },
    { key: 'KTB', text: 'ธนาคารกรุงไทย' },
    { key: 'TTB', text: 'ธนาคารทีทีบี' },
    { key: 'BBL', text: 'ธนาคารกรุงเทพ' },
    { key: 'BAY', text: 'ธนาคารกรุงศรีอยุธยา' },
    { key: 'UOB', text: 'ธนาคารยูโอบี' },
  ];

  const withheldRateList = [...getPercentWhtList(), { key: -1, text: t('accountReceivable.custom') }];

  const accountTypeList = [
    { key: 'SAVING', text: 'ออมทรัพย์' },
    { key: 'CURRENT', text: 'กระแสรายวัน' },
  ];

  const [showErrorMessageRecordPayment, setShowErrorMessageRecordPayment] = useState(false);
  const [loadingRecordPayment, setLoadingRecordPayment] = useState(false);
  const [recordPayment, setRecordPayment] = useState({
    isClick: false,
    isChange: false,
    documentId: '',
    invoiceNumber: '',
    amountDue: 0,
    paymentList: [
      {
        paymentCode: '',
        paymentName: '',
        date: new Date().toISOString(),
        dateBE: onFormatDate(new Date(), 'BE'),
        dateCE: onFormatDate(new Date(), 'CE'),
        bankCode: '',
        bankName: '',
        typeCode: '',
        typeName: '',
        accountName: '',
        accountNumber: '',
        paidAmount: 0,
        originalPaidAmount: 0,
        paymentNote: '',
      },
    ],
    taxWithheld: false,
    taxWithheldAmount: 0,
    taxWithheldRate: 0,
    includeVat: true,
    vatTotal: 0,
    getTotalPaid: function () {
      return this.paymentList.reduce((accumulator, payment) => accumulator + Number(payment.paidAmount), 0);
    },
  });

  const [recordNote, setRecordNote] = useState({
    isClick: false,
    isChange: false,
    isLoading: false,
    documentId: '',
    noteList: [{ date: new Date(), dateBE: onFormatDate(new Date(), 'BE'), dateCE: onFormatDate(new Date(), 'CE'), message: '' }],
  });

  //Display Summary
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalAccruedIncome, setTotalAccruedIncome] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);

  const percentVat = 7;

  const [isProcessing, setIsProcessing] = useState(false);
  const [isProcessingChangeStatus, setIsProcessingChangeStatus] = useState(false);
  const [isEmailSetting, setIsEmailSetting] = useState(false);

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
  });

  const [dialogAlert, setDialogAlert] = useState({
    isClick: false,
    message: '',
  });

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('accRec-primarySort') || 'createdTime');

  const [sortDirection, setSortDirection] = useState(
    sessionStorage.getItem('accRec-sortDirection') || 'DESC'
  );

  const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
    if (sessionStorage.getItem('accRec-sort-createdTime') && sessionStorage.getItem('accRec-sort-createdTime') === 'false') {
      return false;
    } else {
      return true;
    }
  });
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('accRec-sort-number') && sessionStorage.getItem('accRec-sort-number') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem('accRec-sort-documentDate') && sessionStorage.getItem('accRec-sort-documentDate') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('accRec-sort-customerName') && sessionStorage.getItem('accRec-sort-customerName') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem('accRec-sort-grandTotal') && sessionStorage.getItem('accRec-sort-grandTotal') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingName, setIsSortedDescendingName] = useState(() => {
    if (sessionStorage.getItem('accRec-sort-name') && sessionStorage.getItem('accRec-sort-name') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingEmailReceiver, setIsSortedDescendingEmailReceiver] = useState(() => {
    if (sessionStorage.getItem('accRec-sort-emailReceiver') && sessionStorage.getItem('accRec-sort-emailReceiver') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingEmailTime, setIsSortedDescendingEmailTime] = useState(() => {
    if (sessionStorage.getItem('accRec-sort-emailTime') && sessionStorage.getItem('accRec-sort-emailTime') === 'true') {
      return true;
    } else {
      return false;
    }
  });

  const [enableSms, setEnableSms] = useState(false);

  const getMonth = (goBack) => {
    let monthNames = [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem("accRec-queryMonth-key") && {
      key: sessionStorage.getItem("accRec-queryMonth-key"),
      text: sessionStorage.getItem("accRec-queryMonth-text"),
      ...(sessionStorage.getItem("accRec-queryMonth-type") ? { type: sessionStorage.getItem("accRec-queryMonth-type") } : {}),
    }) || { key: getMonth(0).year + '' + getMonth(0).key, text: t('documents.thisMonth') }
  );

  const [timeRangeDate, setTimeRangeDate] = useState(sessionStorage.getItem("accRec-queryMonth-type") ? true : false);
  const [fromDate, setFromDate] = useState(sessionStorage.getItem("accRec-fromDate") ? new Date(sessionStorage.getItem("accRec-fromDate")) : null);
  const [toDate, setToDate] = useState(sessionStorage.getItem("accRec-toDate") ? new Date(sessionStorage.getItem("accRec-toDate")) : null);
  const [timeRangeError, setTimeRangeError] = useState(false);

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const getFromDMY = (day, month, year) => {
    let _firstDay = new Date(year, month, day);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getToDMY = (day, month, year) => {
    let _lastDay = new Date(year, month, day + 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };
  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('accRec-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('accRec-to') || getTo(getMonth(0).month, getMonth(0).year));

  const [date, setDate] = useState(sessionStorage.getItem('accRec-date') ? new Date(sessionStorage.getItem('accRec-date')) : new Date());

  const [pageDropdown, setPageDropdown] = useState([]);
  const [dateType, setDateType] = useState(
    (sessionStorage.getItem('accRec-dateType-key') && {
      key: sessionStorage.getItem('accRec-dateType-key'),
      text: sessionStorage.getItem('accRec-dateType-text'),
    }) || { key: 'created', text: t('documents.createdDate') }
  );

  const [statusType, setStatusType] = useState(
    (sessionStorage.getItem('accRec-status-key') && {
      key: sessionStorage.getItem('accRec-status-key'),
      text: sessionStorage.getItem('accRec-status-text'),
    }) || { key: 'all', text: t('documents.allStatus') }
  );

  const resetSort = () => {
    if (dateType.key === 'document') {
      setPrimarySort('documentDate');
      setIsSortedDescendingDocumentDate(true);
      setIsSortedDescendingCreatedTime(false);

      sessionStorage.setItem('accRec-primarySort', 'documentDate');
      sessionStorage.setItem('accRec-sort-documentDate', 'true');
      sessionStorage.setItem('accRec-sort-createdTime', 'false');
    } else {
      setPrimarySort('createdTime');
      setIsSortedDescendingCreatedTime(true);
      setIsSortedDescendingDocumentDate(false);

      sessionStorage.setItem('accRec-primarySort', 'createdTime');
      sessionStorage.setItem('accRec-sort-createdTime', 'true');
      sessionStorage.setItem('accRec-sort-documentDate', 'false');
    }

    setIsSortedDescendingNumber(false);
    setIsSortedDescendingCustomerName(false);
    setIsSortedDescendingGrandTotal(false);
    setIsSortedDescendingName(false);
    setIsSortedDescendingEmailReceiver(false);
    setIsSortedDescendingEmailTime(false);

    setSortDirection('DESC');

    sessionStorage.setItem('accRec-sort-number', 'false');
    sessionStorage.setItem('accRec-sort-customerName', 'false');
    sessionStorage.setItem('accRec-sort-customerPhone', 'false');
    sessionStorage.setItem('accRec-sort-vatTotal', 'false');
    sessionStorage.setItem('accRec-sort-grandTotal', 'false');
    sessionStorage.setItem('accRec-sort-name', 'false');
    sessionStorage.setItem('accRec-sort-emailReceiver', 'false');
    sessionStorage.setItem('accRec-sort-emailTime', 'false');
    sessionStorage.setItem('accRec-sortDirection', 'DESC');
  };

  const DayPickerStrings = {
    months: [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ],

    shortMonths: [
      t('documents.shortMonths01'),
      t('documents.shortMonths02'),
      t('documents.shortMonths03'),
      t('documents.shortMonths04'),
      t('documents.shortMonths05'),
      t('documents.shortMonths06'),
      t('documents.shortMonths07'),
      t('documents.shortMonths08'),
      t('documents.shortMonths09'),
      t('documents.shortMonths10'),
      t('documents.shortMonths11'),
      t('documents.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: 'ไปวันที่ปัจจุบัน',
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('documents.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  function getDocumentTypeText(key) {
    if (key === 'ALL') return t('documents.allDocumentsType');
    if (key === 'RECEIPT-TAXINVOICE') return t('documents.receipt-taxinvoice');
    if (key === 'RECEIPT-TAXINVOICE-ABB') return t('documents.receipt-taxinvoice-abb');
    if (key === 'RECEIPT') return t('documents.receipt');
    if (key === 'INVOICE-TAXINVOICE') return t('documents.invoice-taxinvoice');
    if (key === 'DELIVERYORDER-TAXINVOICE') return t('documents.deliveryorder-taxinvoice');
    if (key === 'TAXINVOICE') return t('documents.taxinvoice');
    if (key === 'DEBIT-NOTE') return t('documents.debit-note');
    if (key === 'CREDIT-NOTE') return t('documents.credit-note');
    if (key === 'QUOTATION') return t('documents.quotation');
    if (key === 'INVOICE') return t('documents.invoice');
    if (key === 'BILLING-NOTE') return t('documents.billing-note');
    if (key === 'DELIVERYORDER') return t('documents.deliveryorder');

    if (key === 'created') return t('documents.createdDate');
    if (key === 'document') return t('documents.documentDate');

    if (key === 'all') return t('documents.allStatus');
    if (key === 'draft') return t('documents.draftStatus');
    if (key === 'open') return t('documents.openStatus');
    if (key === 'sent') return t('documents.sentStatus');
    if (key === 'partial-paid') return t('documents.partialPaidStatus');
    if (key === 'paid') return t('documents.paidStatus');
    if (key === 'void') return t('documents.voidStatus');
    if (key === 'uncollectible') return t('documents.uncollectibleStatus');
  }

  function getDateText(monthObject) {
    if (monthObject.type === 'dateRange') {
      if (i18n.language === 'th') {
        return onFormatDate(fromDate, 'BE') + ' - ' + onFormatDate(toDate, 'BE');
      }
      return onFormatDate(fromDate, 'CE') + ' - ' + onFormatDate(toDate, 'CE');
    }
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('documents.thisMonth');

    let monthNames = [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: documentType.key,
      text: getDocumentTypeText(documentType.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isProcessing || isProcessingChangeStatus,
      subMenuProps: {
        items: [
          {
            key: 'ALL',
            text: t('documents.allDocumentsType'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.allDocumentsType'));
              setDocumentType({
                key: 'ALL',
                text: t('documents.allDocumentsType'),
              });
              sessionStorage.setItem('accRec-documentType-key', 'ALL');
              sessionStorage.setItem('accRec-documentType-text', t('documents.allDocumentsType'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'INVOICE-TAXINVOICE',
            text: t('documents.invoice-taxinvoice'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.invoice-taxinvoice'));
              setDocumentType({
                key: 'INVOICE-TAXINVOICE',
                text: t('documents.invoice-taxinvoice'),
              });
              sessionStorage.setItem('accRec-documentType-key', 'INVOICE-TAXINVOICE');
              sessionStorage.setItem('accRec-documentType-text', t('documents.invoice-taxinvoice'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'INVOICE',
            text: t('documents.invoice'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.invoice'));
              setDocumentType({
                key: 'INVOICE',
                text: t('documents.invoice'),
              });
              sessionStorage.setItem('accRec-documentType-key', 'INVOICE');
              sessionStorage.setItem('accRec-documentType-text', t('documents.invoice'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);

              setCurrentPage(1);

              resetSort();
            },
          },
          {
            key: 'BILLING-NOTE',
            text: t('documents.billing-note'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log(t('documents.billing-note'));
              setDocumentType({
                key: 'BILLING-NOTE',
                text: t('documents.billing-note'),
              });
              sessionStorage.setItem('accRec-documentType-key', 'BILLING-NOTE');
              sessionStorage.setItem('accRec-documentType-text', t('documents.billing-note'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);

              setCurrentPage(1);

              resetSort();
            },
          },
        ],
      },
    },
    {
      key: statusType.key,
      text: getDocumentTypeText(statusType.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'IssueTracking' },
      disabled: !loadDataComplete || isProcessing || isProcessingChangeStatus,
      subMenuProps: {
        items: [
          {
            key: 'all',
            text: t('documents.allStatus'),
            iconProps: { iconName: 'IssueTracking' },
            onClick: () => {
              setStatusType({ key: 'all', text: t('documents.allStatus') });
              sessionStorage.setItem('accRec-status-key', 'all');
              sessionStorage.setItem('accRec-status-text', t('documents.allStatus'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);

              setCurrentPage(1);

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(true);

              setIsSortedDescendingNumber(false);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);
            },
          },
          {
            key: 'draft',
            text: t('documents.draftStatus'),
            iconProps: { iconName: 'IssueTracking' },
            onClick: () => {
              setStatusType({ key: 'draft', text: t('documents.draftStatus') });
              sessionStorage.setItem('accRec-status-key', 'draft');
              sessionStorage.setItem('accRec-status-text', t('documents.draftStatus'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);

              setCurrentPage(1);

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(true);

              setIsSortedDescendingNumber(false);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);
            },
          },
          {
            key: 'open',
            text: t('documents.openStatus'),
            iconProps: { iconName: 'IssueTracking' },
            onClick: () => {
              setStatusType({ key: 'open', text: t('documents.openStatus') });
              sessionStorage.setItem('accRec-status-key', 'open');
              sessionStorage.setItem('accRec-status-text', t('documents.openStatus'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);

              setCurrentPage(1);

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(true);

              setIsSortedDescendingNumber(false);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);
            },
          },
          {
            key: 'sent',
            text: t('documents.sentStatus'),
            iconProps: { iconName: 'IssueTracking' },
            onClick: () => {
              setStatusType({ key: 'sent', text: t('documents.sentStatus') });
              sessionStorage.setItem('accRec-status-key', 'sent');
              sessionStorage.setItem('accRec-status-text', t('documents.sentStatus'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);

              setCurrentPage(1);

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(true);

              setIsSortedDescendingNumber(false);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);
            },
          },
          {
            key: 'partial-paid',
            text: t('documents.partialPaidStatus'),
            iconProps: { iconName: 'IssueTracking' },
            onClick: () => {
              setStatusType({ key: 'partial-paid', text: t('documents.partialPaidStatus') });
              sessionStorage.setItem('accRec-status-key', 'partial-paid');
              sessionStorage.setItem('accRec-status-text', t('documents.partialPaidStatus'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);

              setCurrentPage(1);

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(true);

              setIsSortedDescendingNumber(false);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);
            },
          },
          {
            key: 'paid',
            text: t('documents.paidStatus'),
            iconProps: { iconName: 'IssueTracking' },
            onClick: () => {
              setStatusType({ key: 'paid', text: t('documents.paidStatus') });
              sessionStorage.setItem('accRec-status-key', 'paid');
              sessionStorage.setItem('accRec-status-text', t('documents.paidStatus'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);

              setCurrentPage(1);

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(true);

              setIsSortedDescendingNumber(false);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);
            },
          },
          {
            key: 'void',
            text: t('documents.voidStatus'),
            iconProps: { iconName: 'IssueTracking' },
            onClick: () => {
              setStatusType({ key: 'void', text: t('documents.voidStatus') });
              sessionStorage.setItem('accRec-status-key', 'void');
              sessionStorage.setItem('accRec-status-text', t('documents.voidStatus'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);

              setCurrentPage(1);

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(true);

              setIsSortedDescendingNumber(false);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);
            },
          },
          {
            key: 'uncollectible',
            text: t('documents.uncollectibleStatus'),
            iconProps: { iconName: 'IssueTracking' },
            onClick: () => {
              setStatusType({ key: 'uncollectible', text: t('documents.uncollectibleStatus') });
              sessionStorage.setItem('accRec-status-key', 'uncollectible');
              sessionStorage.setItem('accRec-status-text', t('documents.uncollectibleStatus'));

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);

              setCurrentPage(1);

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(true);

              setIsSortedDescendingNumber(false);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);
            },
          },
        ],
      },
    },
    {
      key: dateType.key,
      text: getDocumentTypeText(dateType.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'CalendarSettings' },
      disabled: !loadDataComplete || isProcessing || isProcessingChangeStatus,
      subMenuProps: {
        items: [
          {
            key: 'created',
            text: t('documents.createdDate'),
            iconProps: { iconName: 'CalendarSettings' },
            onClick: () => {
              console.log(t('documents.createdDate'));
              setDateType({ key: 'created', text: t('documents.createdDate') });
              sessionStorage.setItem('accRec-dateType-key', 'created');
              sessionStorage.setItem('accRec-dateType-text', t('documents.createdDate'));

              sessionStorage.setItem('accRec-sortDirection', 'DESC');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);

              setCurrentPage(1);

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(true);

              setIsSortedDescendingNumber(false);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);

              setSortDirection('DESC');

            },
          },
          {
            key: 'document',
            text: t('documents.documentDate'),
            iconProps: { iconName: 'CalendarSettings' },
            onClick: () => {
              console.log(t('documents.documentDate'));
              setDateType({ key: 'document', text: t('documents.documentDate') });
              sessionStorage.setItem('accRec-dateType-key', 'document');
              sessionStorage.setItem('accRec-dateType-text', t('documents.documentDate'));

              sessionStorage.setItem('accRec-sortDirection', 'DESC');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);

              setCurrentPage(1);

              setPrimarySort('documentDate');
              setIsSortedDescendingDocumentDate(true);

              setIsSortedDescendingCreatedTime(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);

              setSortDirection('DESC');

            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Stack styles={{ root: { padding: '12px 12px 0px 12px' } }}>
                <Checkbox
                  label={t('sellSummary.timeRange')}
                  checked={timeRangeDate}
                  onChange={(e) => {
                    setTimeRangeDate((prev) => !prev);
                  }}
                />
              </Stack>
              {timeRangeDate ? (
                <Stack>
                  <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                      <Text>{t('sellSummary.fromRange')}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '80%', justifyContent: 'center' /* alignItems: 'center' */ } }}>
                      <DatePicker
                        styles={{ root: { width: '90%' } }}
                        placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                        strings={DayPickerStrings}
                        value={fromDate}
                        maxDate={toDate}
                        formatDate={onFormatDate}
                        onSelectDate={(_date) => {
                          setFromDate(_date);

                          setTimeRangeError(false);
                          if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                    <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                      <Text>{t('sellSummary.toRange')}</Text>
                    </Stack>
                    <Stack styles={{ root: { width: '80%' /* alignItems: 'center' */ } }}>
                      <DatePicker
                        styles={{ root: { width: '90%' } }}
                        placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                        strings={DayPickerStrings}
                        value={toDate}
                        minDate={fromDate}
                        formatDate={onFormatDate}
                        onSelectDate={(_date) => {
                          setToDate(_date);

                          setTimeRangeError(false);
                          if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                        }}
                      />
                    </Stack>
                  </Stack>
                  {timeRangeError ? (
                    <Stack styles={{ root: { padding: '12px 0px 0px 12px' } }}>
                      <Text styles={{ root: { fontSize: 12, color: '#A4262C' } }}>{t('sellSummary.timeRangeError')}</Text>
                    </Stack>
                  ) : null}
                  <Stack horizontal horizontalAlign="end" styles={{ root: { width: '92%', height: 52, padding: '10px 0px 10px 0px' } }}>
                    <PrimaryButton
                      text={t('sellSummary.search')}
                      onClick={() => {
                        if (!timeRangeError && fromDate && toDate) {
                          setQueryMonth({
                            type: 'dateRange',
                            key: formatDate(fromDate) + ':' + formatDate(toDate),
                            text: formatDate(fromDate) + ' - ' + formatDate(toDate),
                          });

                          sessionStorage.setItem("accRec-queryMonth-type", "dateRange");
                          sessionStorage.setItem("accRec-queryMonth-key", formatDate(fromDate) + ":" + formatDate(toDate));
                          sessionStorage.setItem("accRec-queryMonth-text", formatDate(fromDate) + " - " + formatDate(toDate));

                          setFrom(getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                          setTo(getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                          sessionStorage.setItem("accRec-from", getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                          sessionStorage.setItem("accRec-to", getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                          sessionStorage.setItem("accRec-fromDate", fromDate.toISOString());
                          sessionStorage.setItem("accRec-toDate", toDate.toISOString());

                          setLoadDataComplete(false);
                          setItems([]);
                          setGetNewDocuments(true);
                          setPreviousSearch('');
                          setSearch('');

                          setTotalDocuments(0);

                          setCurrentPage(1);

                          resetSort();
                        }
                      }}
                      style={{ width: '125px', letterSpacing: '1px' }}
                    />
                  </Stack>
                </Stack>
              ) : (
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('documents.months01'),
                    t('documents.months02'),
                    t('documents.months03'),
                    t('documents.months04'),
                    t('documents.months05'),
                    t('documents.months06'),
                    t('documents.months07'),
                    t('documents.months08'),
                    t('documents.months09'),
                    t('documents.months10'),
                    t('documents.months11'),
                    t('documents.months12'),
                  ];

                  setDate(_date);

                  sessionStorage.setItem('accRec-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  sessionStorage.setItem("accRec-queryMonth-type", "");
                  sessionStorage.setItem('accRec-queryMonth-key', adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'));
                  sessionStorage.setItem('accRec-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem('accRec-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem('accRec-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);
                  setPreviousSearch('');
                  setSearch('');

                  setTotalDocuments(0);
                  setTotalAccruedIncome(0);
                  
                  
                  setTotalPaid(0);

                  setCurrentPage(1);

                  resetSort();
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
              )}
            </div>
          );
        },
      },
    },
    {
      key: pageSize,
      text: pageSize + ' ' + t('documents.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete || isProcessing || isProcessingChangeStatus,
      subMenuProps: {
        items: [
          {
            key: 10,
            text: '10 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 ' + t('documents.documentPerPage'));
              setPageSize(10);
              sessionStorage.setItem('accRec-pageSize', 10);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 20,
            text: '20 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 ' + t('documents.documentPerPage'));
              setPageSize(20);
              sessionStorage.setItem('accRec-pageSize', 20);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 30,
            text: '30 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 ' + t('documents.documentPerPage'));
              setPageSize(30);
              sessionStorage.setItem('accRec-pageSize', 30);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 40,
            text: '40 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 ' + t('documents.documentPerPage'));
              setPageSize(40);
              sessionStorage.setItem('accRec-pageSize', 40);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 50,
            text: '50 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 ' + t('documents.documentPerPage'));
              setPageSize(50);
              sessionStorage.setItem('accRec-pageSize', 50);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
        ],
      },
    },
    {
      key: currentPage,
      text: t('documents.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isProcessing || isProcessingChangeStatus,
      subMenuProps: {
        items: pageDropdown,
      },
    },

    {
      key: 'refresh',
      text: t('documents.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isProcessing || isProcessingChangeStatus,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalAccruedIncome(0);
        
        
        setTotalPaid(0);

        setCurrentPage(1);

        resetSort();
      },
    },

    {
      key: 'batch-send-email',
      text: t('documents.sendEmail'),
      iconProps: { iconName: 'MailForward' },
      disabled: !loadDataComplete || isProcessing || !selectedItems.length || isProcessingChangeStatus,
      onClick: async () => {
        console.log('Send batch email.');
        console.log('Selected items:', selectedItems);

        if (isEmailSetting) {
          async function batchSendEmailProcessing() {
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));

            setIsProcessing(true);

            return await batchSendEmail();
          }

          if (selectedItems.length > 0) {
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: true,
              header: t('documents.sendEmail'),
              message: `${t('documents.confirmSendEmail')} ${selectedItems.length} ${t('documents.document')}?`,
              onConfirm: () => batchSendEmailProcessing(),
            }));
          }
        } else {
          history.push({
            pathname: '/settings/emails',
            state: {},
          });
        }
      },
    },
/* 
    ...(enableSms
      ? [
          {
            key: 'batch-send-sms',
            text: t('documents.sendBatchSms'),
            iconProps: { iconName: 'Message' },
            disabled: !loadDataComplete || isProcessing || !selectedItems.length || isProcessingSms || isProcessingChangeStatus,
            onClick: async () => {
              if (selectedItems.length > 0) {
                setDialogConfirm((prev) => ({
                  ...prev,
                  isClick: true,
                  header: t('documents.sendSms'),
                  message: `${t('documents.confirmSendSms')} ${selectedItems.length} ${t('documents.document')}?`,
                  onConfirm: async () => {
                    setDialogConfirm((prev) => ({
                      ...prev,
                      isClick: false,
                    }));
                    setIsProcessingSms(true);

                    await batchSendSms();
                  },
                }));
              }
            },
          },
        ]
      : []),
       */
    {
      key: 'batch-changeStatus',
      text: t('documents.batchChangeStatus'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'IssueTracking' },
      disabled: !loadDataComplete || isProcessing || selectedItems.length === 0 || isProcessingChangeStatus,
      subMenuProps: {
        items: [
          {
            key: 'draft',
            text: t('documents.draftStatus'),
            onClick: () => {
              batchChangeStatus('draft');
            },
          },
          {
            key: 'open',
            text: t('documents.openStatus'),
            onClick: () => {
              batchChangeStatus('open');
            },
          },
          {
            key: 'sent',
            text: t('documents.sentStatus'),
            onClick: () => {
              batchChangeStatus('sent');
            },
          },

          {
            key: 'partial-paid',
            text: t('documents.partialPaidStatus'),
            onClick: () => {
              batchChangeStatus('partial-paid');
            },
          },
          {
            key: 'paid',
            text: t('documents.paidStatus'),
            onClick: () => {
              batchChangeStatus('paid');
            },
          },
          {
            key: 'void',
            text: t('documents.voidStatus'),
            onClick: () => {
              batchChangeStatus('void');
            },
          },
          {
            key: 'uncollectible',
            text: t('documents.uncollectibleStatus'),
            onClick: () => {
              batchChangeStatus('uncollectible');
            },
          },
        ],
      },
    },
    /* 
    {
      key: 'batch-receipts',
      text: t('documents.create') + ' ' + t('documents.combinedReceipt'),
      iconProps: { iconName: 'PageAdd' },
      disabled: !loadDataComplete || isProcessing || !selectedItems.length || isProcessingSms || isProcessingChangeStatus,
      onClick: async () => {
        console.log('Selected items:', selectedItems);

        if (
          selectedItems.every(
            (selectedItem) =>
              selectedItem.type === 'INVOICE-TAXINVOICE' ||
              selectedItem.type === 'DELIVERYORDER-TAXINVOICE' ||
              selectedItem.type === 'TAXINVOICE' ||
              selectedItem.type === 'QUOTATION' ||
              selectedItem.type === 'INVOICE' ||
              selectedItem.type === 'BILLING-NOTE' ||
              selectedItem.type === 'DELIVERYORDER'
          )
        ) {
          let _items = [];
          let _amountTotal = 0;
          let _vatTotal = 0;
          let _grandTotal = 0;

          let _vatPriceTotal = 0;
          let _zeroVatPriceTotal = 0;
          let _nonVatPriceTotal = 0;

          selectedItems.forEach((_item, index) => {
            let _percentVat = '';
            let _percentVatText = '';

            if (_item.data.items.some((itemInInvoice) => itemInInvoice.percentVat === percentVat)) {
              _percentVat = percentVat;
              _percentVatText = percentVatText;
            } else if (_item.data.items.every((itemInInvoice) => itemInInvoice.percentVat === zeroVat)) {
              _percentVat = zeroVat;
              _percentVatText = zeroVatText;
            } else if (_item.data.items.every((itemInInvoice) => itemInInvoice.percentVat === nonVat)) {
              _percentVat = nonVat;
              _percentVatText = nonVatText;
            }

            _items.push({
              number: String(index + 1),
              description: _item.data.number,
              quantity: 1, // 1
              unitCode: '',
              unitName: '-',
              price: _item.data.amountTotal, // amountTotal
              discount: 0,
              percentVat: _percentVat,
              percentVatText: _percentVatText,
              total: _item.data.amountTotal,

              date: _item.data.date,
              dateBE: _item.data.dateBE,
              dateCE: _item.data.dateCE,
              vatTotal: _item.data.vatTotal,
              grandTotal: _item.data.grandTotal,
            });

            _vatPriceTotal = _vatPriceTotal + _item.data.vatPriceTotal;
            _zeroVatPriceTotal = _zeroVatPriceTotal + _item.data.zeroVatPriceTotal;
            _nonVatPriceTotal = _nonVatPriceTotal + _item.data.nonVatPriceTotal;

            _amountTotal = _amountTotal + _item.data.amountTotal;
            _vatTotal = _vatTotal + _item.data.vatTotal;
            _grandTotal = _grandTotal + _item.data.grandTotal;
          });

          history.push({
            pathname: '/etax/documents/batch-receipts',
            state: {
              command: 'reCreate',
              company: selectedItems[0].data.company,
              customer: selectedItems[0].data.customer,
              number: '',
              date: new Date().toISOString(),
              dateBE: onFormatDate(new Date(), 'BE'),
              dateCE: onFormatDate(new Date(), 'CE'),
              discount: 0,
              items: _items,
              amountTotal: _amountTotal,
              vatTotal: _vatTotal,
              grandTotal: _grandTotal,
              vatPriceTotal: _vatPriceTotal,
              zeroVatPriceTotal: _zeroVatPriceTotal,
              nonVatPriceTotal: _nonVatPriceTotal,
              note: selectedItems[0].data.note,
              createdBy: selectedItems[0].data.createdBy,
              receivedBy: selectedItems[0].data.receivedBy,
              percentVat: selectedItems[0].data.percentVat,
              includeVat: selectedItems[0].data.includeVat,
              reIssue: false,
              refer: {},
              reference: selectedItems[0].data.reference,
              backUrl: '/etax/documents/accounts-receivable/all',
            },
          });
        } else {
          setDialogAlert((prev) => ({
            ...prev,
            isClick: true,
            message: t('documents.combinedReceiptAlert'),
          }));
        }
      },
    },
     */
    {
      key: 'download',
      text: t('documents.download'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Download' },
      disabled: !loadDataComplete || isProcessing || selectedItems.length === 0 || isProcessingChangeStatus,
      subMenuProps: {
        items: [
          {
            key: 'download-pdf-zip',
            text: t('documents.download') + ' PDF',
            iconProps: { iconName: 'ZipFolder' },
            onClick: () => {
              console.log('Download PDF');
              selectedDownloadZip('pdf');
            },
          },
          {
            key: 'download-xml-zip',
            text: t('documents.download') + ' XML',
            iconProps: { iconName: 'ZipFolder' },
            onClick: () => {
              console.log('Download XML');
              selectedDownloadZip('xml');
            },
          },
        ],
      },
    },
  ];

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);
    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    let hour = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const UploadPDF = (fileName, file, email) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        let formData = new FormData();

        formData.append('file', file);
        formData.append('fileName', fileName);
        formData.append('to', email);
        formData.append('message', '');

        await axios
          .post(API_URL + '/sents', formData, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'multipart/form-data',
            },
            timeout: 60000,
          })
          .then(
            (response) => {
              console.log(true, response);
            },
            (error) => {
              console.log('Error sending document response: ', error);
            }
          );
      })
      .catch((error) => {
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const sendEmail = (fileName, fileId, email, copyEmail) => {
    if (email !== '' && email !== null && email !== undefined) {
      if ((isClickCopyEmail && copyEmail !== '' && copyEmail !== null && copyEmail !== undefined) || !isClickCopyEmail) {
        setIsClickEmail(false);

        setIsClickCopyEmail(false);
        setErrorMessageCopyEmail('');

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
              .post(
                API_URL + '/emails/send',
                {
                  fileId: fileId,
                  email: email,
                  copyEmail: copyEmail,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then(
                (response) => {
                  console.log(response);

                  const _prevStatusKey = items.find((item) => item.key === fileId).data.status;

                  if (_prevStatusKey === 'new' || _prevStatusKey === 'draft' || _prevStatusKey === 'open') {
                    const _documentId = fileId;
                    const _statusKey = 'sent';

                    axios
                      .patch(
                        API_URL + `/documents/${_documentId}`,
                        {
                          replace: '/data/status',
                          value: _statusKey,
                        },
                        {
                          headers: {
                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                          },
                        }
                      )
                      .then(
                        async (response) => {
                          console.log('response change document status', response);

                          const _index = items.findIndex((item) => item.key === _documentId);

                          if (_index > -1) {
                            const _updateItems = [...items];

                            _updateItems[_index].documentStatus = _statusKey;
                            setItems(_updateItems);
                          }

                          setChangeDocumentStatusPayload((prev) => ({
                            ...prev,
                            documentId: '',
                            type: '',
                            number: '',
                            statusKey: '',
                            statusText: '',
                            prevStatusKey: '',
                          }));

                          await instance
                            .acquireTokenSilent({ ...silentRequest, account: account })
                            .then(async (tokenResponse) => {
                              if (documentType.key === 'ALL') {
                                const [invoiceTaxInvoice, invoice, billingNote] = await Promise.all([
                                  axios
                                    .get(API_URL + '/documents/summary/INVOICE-TAXINVOICE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                                      headers: {
                                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                                      },
                                    })
                                    .catch((error) => console.log(error)),
                                  axios
                                    .get(API_URL + '/documents/summary/INVOICE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                                      headers: {
                                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                                      },
                                    })
                                    .catch((error) => console.log(error)),
                                  axios
                                    .get(API_URL + '/documents/summary/BILLING-NOTE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                                      headers: {
                                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                                      },
                                    })
                                    .catch((error) => console.log(error)),
                                ]);

                                const countTotal = invoiceTaxInvoice.data.countTotal + invoice.data.countTotal + billingNote.data.countTotal;
                                const totalAccruedIncome =
                                  invoiceTaxInvoice.data.totalAccruedIncome + invoice.data.totalAccruedIncome + billingNote.data.totalAccruedIncome;
                                const totalPaid = invoiceTaxInvoice.data.totalPaid + invoice.data.totalPaid + billingNote.data.totalPaid;

                                setTotalDocuments(countTotal);
                                setTotalAccruedIncome(totalAccruedIncome);
                                setTotalPaid(totalPaid);
                              } else {
                                axios
                                  .get(
                                    API_URL + '/documents/summary/' + documentType.key + '/all?from=' + from + '&to=' + to + '&date=' + dateType.key,
                                    {
                                      headers: {
                                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                                      },
                                    }
                                  )
                                  .then((response) => {
                                    console.log('Documents summary:', response);

                                    const { countTotal, totalAccruedIncome, totalPaid } = response.data;

                                    setTotalDocuments(countTotal);
                                    setTotalAccruedIncome(totalAccruedIncome);
                                    setTotalPaid(totalPaid);
                                  })
                                  .catch((error) => console.log(error));
                              }
                            })
                            .catch((error) => {
                              //Acquire token silent failure, and send an interactive request
                              console.log(error);
                              instance.acquireTokenRedirect(silentRequest);
                            });
                        },
                        (error) => {
                          console.log(error);
                        }
                      );
                  }

                  setIsClickEmail(false);
                  setShowEmailSuccess(true);
                  window.scrollTo(0, 0);
                },
                (error) => {
                  console.log(error);
                  setIsClickEmail(false);
                  setShowEmailError(true);
                  window.scrollTo(0, 0);
                }
              );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });

        let file = '';

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
              .get(API_URL + '/etax/files/' + fileId, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
                responseType: 'blob',
              })
              .then(
                (response) => {
                  console.log(response);

                  file = response.data;

                  let allEmails = email.split(';');

                  allEmails.forEach((email) => {
                    console.log(email, true);

                    instance
                      .acquireTokenSilent({
                        ...silentRequest,
                        account: account,
                      })
                      .then(async (tokenResponse) => {
                        await axios
                          .get(API_URL + '/sents/check?email=' + email, {
                            headers: {
                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                            },
                          })
                          .then(
                            (response) => {
                              console.log(response);
                              if (response.data.isExist) {
                                UploadPDF(fileName, file, email);
                              }
                            },
                            (error) => {
                              console.log(error);
                            }
                          );
                      });
                  });
                },
                (error) => {
                  console.log(error);
                }
              );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });
      } else {
        setErrorMessageCopyEmail(t('documents.emailAlert'));
      }
    }
  };

  const sendSms = (fileId, mobile) => {
    const re = /^0[1-9]{1}[0-9]{8}/;

    if (mobile !== '' && mobile !== null && mobile !== undefined && re.test(String(mobile))) {
      setIsClickSms(false);
      setErrorMessageSms('');

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + '/etax/sms/',
              {
                fileId: fileId,
                mobile: mobile,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'application/json',
                },
              }
            )
            .then(
              (response) => {
                console.log(response);

                const _prevStatusKey = items.find((item) => item.key === fileId).data.status;

                if (_prevStatusKey === 'new' || _prevStatusKey === 'draft' || _prevStatusKey === 'open') {
                  const _documentId = fileId;
                  const _statusKey = 'sent';

                  axios
                    .patch(
                      API_URL + `/documents/${_documentId}`,
                      {
                        replace: '/data/status',
                        value: _statusKey,
                      },
                      {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                        },
                      }
                    )
                    .then(
                      async (response) => {
                        console.log('response change document status', response);

                        const _index = items.findIndex((item) => item.key === _documentId);

                        if (_index > -1) {
                          const _updateItems = [...items];

                          _updateItems[_index].documentStatus = _statusKey;
                          setItems(_updateItems);
                        }

                        setChangeDocumentStatusPayload((prev) => ({
                          ...prev,
                          documentId: '',
                          type: '',
                          number: '',
                          statusKey: '',
                          statusText: '',
                          prevStatusKey: '',
                        }));

                        await instance
                          .acquireTokenSilent({ ...silentRequest, account: account })
                          .then(async (tokenResponse) => {
                            if (documentType.key === 'ALL') {
                              const [invoiceTaxInvoice, invoice, billingNote] = await Promise.all([
                                axios
                                  .get(API_URL + '/documents/summary/INVOICE-TAXINVOICE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                                    headers: {
                                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                                    },
                                  })
                                  .catch((error) => console.log(error)),
                                axios
                                  .get(API_URL + '/documents/summary/INVOICE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                                    headers: {
                                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                                    },
                                  })
                                  .catch((error) => console.log(error)),
                                axios
                                  .get(API_URL + '/documents/summary/BILLING-NOTE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                                    headers: {
                                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                                    },
                                  })
                                  .catch((error) => console.log(error)),
                              ]);

                              const countTotal = invoiceTaxInvoice.data.countTotal + invoice.data.countTotal + billingNote.data.countTotal;
                              const totalAccruedIncome =
                                invoiceTaxInvoice.data.totalAccruedIncome + invoice.data.totalAccruedIncome + billingNote.data.totalAccruedIncome;
                              const totalPaid = invoiceTaxInvoice.data.totalPaid + invoice.data.totalPaid + billingNote.data.totalPaid;

                              setTotalDocuments(countTotal);
                              setTotalAccruedIncome(totalAccruedIncome);
                              setTotalPaid(totalPaid);
                            } else {
                              axios
                                .get(
                                  API_URL + '/documents/summary/' + documentType.key + '/all?from=' + from + '&to=' + to + '&date=' + dateType.key,
                                  {
                                    headers: {
                                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                                    },
                                  }
                                )
                                .then((response) => {
                                  console.log('Documents summary:', response);

                                  const { countTotal, totalAccruedIncome, totalPaid } = response.data;

                                  setTotalDocuments(countTotal);
                                  setTotalAccruedIncome(totalAccruedIncome);
                                  setTotalPaid(totalPaid);
                                })
                                .catch((error) => console.log(error));
                            }
                          })
                          .catch((error) => {
                            //Acquire token silent failure, and send an interactive request
                            console.log(error);
                            instance.acquireTokenRedirect(silentRequest);
                          });
                      },
                      (error) => {
                        console.log(error);
                      }
                    );
                }

                setIsClickSms(false);
                setShowSmsSuccess(true);
                window.scrollTo(0, 0);
              },
              (error) => {
                console.log(error);
                setIsClickSms(false);
                setShowSmsError(true);
                window.scrollTo(0, 0);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      setErrorMessageSms(t('documents.mobileNumberAlert'));
    }
  };

  const changeDocumentStatus = () => {
    setChangeDocumentStatusPayload((prev) => ({
      ...prev,
      isClick: false,
    }));

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        const _documentId = changeDocumentStatusPayload.documentId;
        const _statusKey = changeDocumentStatusPayload.statusKey;

        axios
          .patch(
            API_URL + `/documents/${_documentId}`,
            {
              replace: '/data/status',
              value: _statusKey,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            }
          )
          .then(
            async (response) => {
              console.log('response change document status', response);

              const _index = items.findIndex((item) => item.key === _documentId);

              if (_index > -1) {
                const _updateItems = [...items];

                _updateItems[_index].documentStatus = _statusKey;
                setItems(_updateItems);
              }

              setChangeDocumentStatusPayload((prev) => ({
                ...prev,
                documentId: '',
                type: '',
                number: '',
                statusKey: '',
                statusText: '',
                prevStatusKey: '',
              }));

              await instance
                .acquireTokenSilent({ ...silentRequest, account: account })
                .then(async (tokenResponse) => {
                  if (documentType.key === 'ALL') {
                    const [invoiceTaxInvoice, invoice, billingNote] = await Promise.all([
                      axios
                        .get(API_URL + '/documents/summary/INVOICE-TAXINVOICE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                          headers: {
                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                          },
                        })
                        .catch((error) => console.log(error)),
                      axios
                        .get(API_URL + '/documents/summary/INVOICE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                          headers: {
                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                          },
                        })
                        .catch((error) => console.log(error)),
                      axios
                        .get(API_URL + '/documents/summary/BILLING-NOTE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                          headers: {
                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                          },
                        })
                        .catch((error) => console.log(error)),
                    ]);

                    const countTotal = invoiceTaxInvoice.data.countTotal + invoice.data.countTotal + billingNote.data.countTotal;
                    const totalAccruedIncome =
                      invoiceTaxInvoice.data.totalAccruedIncome + invoice.data.totalAccruedIncome + billingNote.data.totalAccruedIncome;
                    const totalPaid = invoiceTaxInvoice.data.totalPaid + invoice.data.totalPaid + billingNote.data.totalPaid;

                    setTotalDocuments(countTotal);
                    setTotalAccruedIncome(totalAccruedIncome);
                    setTotalPaid(totalPaid);
                  } else {
                    axios
                      .get(API_URL + '/documents/summary/' + documentType.key + '/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                        },
                      })
                      .then((response) => {
                        console.log('Documents summary:', response);

                        const { countTotal, totalAccruedIncome, totalPaid } = response.data;

                        setTotalDocuments(countTotal);
                        setTotalAccruedIncome(totalAccruedIncome);
                        setTotalPaid(totalPaid);
                      })
                      .catch((error) => console.log(error));
                  }
                })
                .catch((error) => {
                  //Acquire token silent failure, and send an interactive request
                  console.log(error);
                  instance.acquireTokenRedirect(silentRequest);
                });
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log('error change document status', error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const getTotalProcessingDocuments = () => {
    console.log('Get total processing document...');
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/jobs/processing/total', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log('Total processing jobs response: ', response);

              if (response.data[0] > 0) {
                console.log('Total processing jobs: ', response.data[0]);

                setTotalProcessingDocuments(response.data[0] * 1);
              } else {
                setTotalProcessingDocuments(0);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  async function getPNGorZip() {
    setTimeout(async () => {
      try {
        const zip = new JSZip();

        console.log('download...');
        const pdfElements = printRef.current.getElementsByClassName('react-pdf__Page__canvas');

        if (pdfElements) {
          if (pdfElements.length > 1) {
            const promises = [];

            async function getCanvasBlobToPNG(elements, index) {
              const canvas = await html2canvas(elements[index]);

              return new Promise(function (resolve, reject) {
                canvas.toBlob(function (blob) {
                  resolve(zip.file(`${index + 1}.png`, blob));
                });
              });
            }

            for (let i = 0; i < pdfElements.length; i++) {
              promises.push(getCanvasBlobToPNG(pdfElements, i));
            }

            Promise.all(promises).then(async () => {
              const blob = await zip.generateAsync({
                type: 'blob',
              });

              const link = document.createElement('a');
              link.setAttribute('href', window.URL.createObjectURL(blob));
              link.setAttribute('download', new Date().toISOString() + '-image.zip');
              link.style.display = 'none';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setUrl('');
            });
          } else {
            const canvas = await html2canvas(pdfElements[0]);

            const data = canvas.toDataURL('image/png');

            const link = document.createElement('a');

            if (typeof link.download === 'string') {
              link.href = data;
              link.download = new Date().toISOString() + '-image.png';

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setUrl('');
            } else {
              window.open(data);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000);
  }

  const batchSendEmail = async () => {
    console.log('Call batchSendEmail function...');

    let _items = selection.getItems();

    for (let i = 0; i < selectedItems.length; i++) {
      console.log('batchSendEmail: ', selectedItems[i]);

      for (let j = 0; j < _items.length; j++) {
        if (_items[j].key === selectedItems[i].key) {
          console.log('item key: ', _items[j].key);

          if (_items[j].customerEmail) {
            _items[j].emailTime = 'processing';

            console.log('items: ', _items);
            setItems([..._items]);

            console.log('pdfFileName: ', _items[j].pdfFileName);
            console.log('key: ', _items[j].key);
            console.log('customerEmail: ', _items[j].customerEmail);

            await instance
              .acquireTokenSilent({ ...silentRequest, account: account })
              .then(async (tokenResponse) => {
                await axios
                  .post(
                    API_URL + '/emails/send',
                    {
                      fileId: _items[j].key,
                      email: _items[j].customerEmail,
                      copyEmail: '',
                    },
                    {
                      headers: {
                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                        'Content-Type': 'application/json',
                      },
                    }
                  )
                  .then(
                    async (response) => {
                      console.log(response);

                      if (response?.status === 200) {
                        _items[j].emailTime = 'successful';

                        if (_items[j].documentStatus === 'new' || _items[j].documentStatus === 'draft' || _items[j].documentStatus === 'open') {
                          await axios
                            .patch(
                              API_URL + `/documents/${_items[j].key}`,
                              {
                                replace: '/data/status',
                                value: 'sent',
                              },
                              {
                                headers: {
                                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                                },
                              }
                            )
                            .then(
                              (response) => {
                                _items[j].documentStatus = 'sent';
                              },
                              (error) => {
                                console.log(error);
                              }
                            );
                        }
                      } else {
                        _items[j].emailTime = 'fail';
                      }

                      setItems([..._items]);
                    },
                    (error) => {
                      console.log(error);

                      _items[j].emailTime = 'fail';
                    }
                  );
              })
              .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
              });

            let file = '';

            instance
              .acquireTokenSilent({ ...silentRequest, account: account })
              .then((tokenResponse) => {
                axios
                  .get(API_URL + '/etax/files/' + _items[j].key, {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                    },
                    responseType: 'blob',
                  })
                  .then(
                    (response) => {
                      console.log(response);

                      file = response.data;

                      let allEmails = _items[j].customerEmail.split(';');

                      allEmails.forEach((email) => {
                        console.log(email, true);

                        instance
                          .acquireTokenSilent({
                            ...silentRequest,
                            account: account,
                          })
                          .then(async (tokenResponse) => {
                            await axios
                              .get(API_URL + '/sents/check?email=' + email, {
                                headers: {
                                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                                },
                              })
                              .then(
                                (response) => {
                                  console.log(response);
                                  if (response.data.isExist) {
                                    UploadPDF(_items[j].pdfFileName, file, email);
                                  }
                                },
                                (error) => {
                                  console.log(error);
                                }
                              );
                          });
                      });
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
              })
              .catch((error) => {
                //Acquire token silent failure, and send an interactive request
                console.log(error);
                instance.acquireTokenRedirect(silentRequest);
              });
          } else {
            _items[j].emailTime = 'email not found';
          }

          console.log('item: ', _items[j]);
        }
      }
    }

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        if (documentType.key === 'ALL') {
          const [invoiceTaxInvoice, invoice, billingNote] = await Promise.all([
            axios
              .get(API_URL + '/documents/summary/INVOICE-TAXINVOICE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              })
              .catch((error) => console.log(error)),
            axios
              .get(API_URL + '/documents/summary/INVOICE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              })
              .catch((error) => console.log(error)),
            axios
              .get(API_URL + '/documents/summary/BILLING-NOTE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              })
              .catch((error) => console.log(error)),
          ]);

          const countTotal = invoiceTaxInvoice.data.countTotal + invoice.data.countTotal + billingNote.data.countTotal;
          const totalAccruedIncome =
            invoiceTaxInvoice.data.totalAccruedIncome + invoice.data.totalAccruedIncome + billingNote.data.totalAccruedIncome;
          const totalPaid = invoiceTaxInvoice.data.totalPaid + invoice.data.totalPaid + billingNote.data.totalPaid;

          setTotalDocuments(countTotal);
          setTotalAccruedIncome(totalAccruedIncome);
          setTotalPaid(totalPaid);
        } else {
          axios
            .get(API_URL + '/documents/summary/' + documentType.key + '/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            })
            .then((response) => {
              console.log('Documents summary:', response);

              const { countTotal, totalAccruedIncome, totalPaid } = response.data;

              setTotalDocuments(countTotal);
              setTotalAccruedIncome(totalAccruedIncome);
              setTotalPaid(totalPaid);
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    setIsProcessing(false);
  };

  const batchChangeStatus = async (documentStatus) => {
    let _items = selection.getItems();

    for (let i = 0; i < selectedItems.length; i++) {
      for (let j = 0; j < _items.length; j++) {
        if (_items[j].key === selectedItems[i].key) {
          console.log('item key: ', _items[j].key);

          _items[j].documentStatus = 'processing';

          setItems([..._items]);

          await instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
              axios
                .patch(
                  API_URL + `/documents/${_items[j].key}`,
                  {
                    replace: '/data/status',
                    value: documentStatus,
                  },
                  {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                    },
                  }
                )
                .then(
                  async (response) => {
                    console.log(response);

                    if (response?.data) {
                      _items[j].documentStatus = documentStatus;
                    } else {
                      _items[j].documentStatus = 'fail';
                    }

                    setItems([..._items]);
                  },
                  (error) => {
                    console.log(error);

                    _items[j].documentStatus = 'fail';
                  }
                );
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });

          console.log('item: ', _items[j]);
        }
      }
    }

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        if (documentType.key === 'ALL') {
          const [invoiceTaxInvoice, invoice, billingNote] = await Promise.all([
            axios
              .get(API_URL + '/documents/summary/INVOICE-TAXINVOICE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              })
              .catch((error) => console.log(error)),
            axios
              .get(API_URL + '/documents/summary/INVOICE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              })
              .catch((error) => console.log(error)),
            axios
              .get(API_URL + '/documents/summary/BILLING-NOTE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              })
              .catch((error) => console.log(error)),
          ]);

          const countTotal = invoiceTaxInvoice.data.countTotal + invoice.data.countTotal + billingNote.data.countTotal;
          const totalAccruedIncome =
            invoiceTaxInvoice.data.totalAccruedIncome + invoice.data.totalAccruedIncome + billingNote.data.totalAccruedIncome;
          const totalPaid = invoiceTaxInvoice.data.totalPaid + invoice.data.totalPaid + billingNote.data.totalPaid;

          setTotalDocuments(countTotal);
          setTotalAccruedIncome(totalAccruedIncome);
          setTotalPaid(totalPaid);
        } else {
          axios
            .get(API_URL + '/documents/summary/' + documentType.key + '/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            })
            .then((response) => {
              console.log('Documents summary:', response);

              const { countTotal, totalAccruedIncome, totalPaid } = response.data;

              setTotalDocuments(countTotal);
              setTotalAccruedIncome(totalAccruedIncome);
              setTotalPaid(totalPaid);
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    setIsProcessingChangeStatus(false);
  };

  useEffect(() => {
    console.log('userEffect Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        console.log(tokenResponse);

        //Check test certificate
        axios
        //   .get(API_URL + '/companies-details/' + companyId, {
          .get(API_URL + '/companies-details', { 
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (responseCompanyDetail) => {
              if (responseCompanyDetail.data) {
                console.log('responseCompanyDetail data: ', responseCompanyDetail.data);

                if (responseCompanyDetail.data.billings?.enableTopup) {
                    setEnableTopup(responseCompanyDetail.data.billings.enableTopup);
                }

                if (responseCompanyDetail.data.settings?.enableSms) {
                    setEnableSms(responseCompanyDetail.data.settings.enableSms);
                }

                if (responseCompanyDetail.data.settings?.etax?.defaultCertificateId) {
                    axios
                    .get(API_URL + '/certificates/' + responseCompanyDetail.data.settings?.etax?.defaultCertificateId, {
                        headers: {
                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                            'cid': companyId
                    },
                    })
                    .then(
                        (responseCert) => {
                        if (responseCert.data) {
                            let certName = responseCert.data.certificateName;

                            console.log('Certificate name: ', responseCert.data.certificateName);

                            if (certName.search('Test Only') > -1) {
                            // setShowCertificateSetup(true);
                            } else {
                            axios
                                .get(API_URL + '/etax/users', {
                                headers: {
                                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                                },
                                })
                                .then(
                                (responseUser) => {
                                    console.log('etaxUsers: ', responseUser);
                                },
                                (error) => {
                                    console.log(error);
                                }
                                );
                            }
                        }
                        },
                        (error) => {
                        console.log(error);
                        }
                    );
                } else {

                    axios
                        .get(API_URL + '/accounts', {
                            headers: {
                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                            },
                        })
                        .then(
                            (responseAcc) => {
                                if (responseAcc.data) {
                                    console.log('Account data: ', responseAcc.data);

                                    if (responseAcc.data.billings?.enableTopup) {
                                        setEnableTopup(responseAcc.data.billings.enableTopup);
                                    }

                                    if (responseAcc.data.settings?.enableSms) {
                                        setEnableSms(responseAcc.data.settings.enableSms);
                                    }

                                    if (responseAcc.data.settings?.etax?.defaultCertificateId) {
                                        axios
                                        .get(API_URL + '/certificates/' + responseAcc.data.settings?.etax?.defaultCertificateId, {
                                            headers: {
                                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                                'cid': companyId
                                            },
                                        })
                                        .then(
                                            (responseCert) => {
                                            if (responseCert.data) {
                                                let certName = responseCert.data.certificateName;

                                                console.log('Certificate name: ', responseCert.data.certificateName);

                                                if (certName.search('Test Only') > -1) {
                                                // setShowCertificateSetup(true);
                                                } else {
                                                axios
                                                    .get(API_URL + '/etax/users', {
                                                    headers: {
                                                        Authorization: 'Bearer ' + tokenResponse.accessToken,
                                                        'cid': companyId
                                                    },
                                                    })
                                                    .then(
                                                    (responseUser) => {
                                                        console.log('etaxUsers: ', responseUser);

                                                    },
                                                    (error) => {
                                                        console.log(error);
                                                    }
                                                    );
                                                }
                                            }
                                            },
                                            (error) => {
                                                console.log(error);
                                            }
                                        );
                                    }
                                }

                            },
                            (error) => {
                                console.log(error);
                            }
                        );



                }
              }
            },
            (error) => {
              console.log(error);
            }
          );
/* 
        const documentsConnection = new signalR.HubConnectionBuilder()
          .withUrl('https://leceipt.azurewebsites.net/api/OnDocumentsChanged', {
            headers: {
              oid: tokenResponse.idTokenClaims.oid,
              product: 'etax',
              fileExtension: 'pdf',
            },
          })
          .configureLogging(signalR.LogLevel.Information)
          .build();

        documentsConnection.on('documentUpdated', (data) => {
          console.log('SignalR push documentUpdated!!');
          console.log(data);

          if (data && data.createdTime) {
            let diffMilliSeconds = new Date() - new Date(data.createdTime);

            console.log('diffMilliSeconds:', diffMilliSeconds);

            // refresh only document create or update in last 15 sec.

            if (diffMilliSeconds < 15000) {
              setDocumentType({
                key: 'ALL',
                text: t('documents.allDocumentsType'),
              });
              setDateType({ key: 'created', text: t('documents.createdDate') });
              setQueryMonth({
                key: getMonth(0).year + '' + getMonth(0).key,
                text: t('documents.thisMonth'),
              });
              setFrom(getFrom(getMonth(0).month, getMonth(0).year));
              setTo(getTo(getMonth(0).month, getMonth(0).year));
              setDate(new Date())
              setPageSize(10);
              setCurrentPage(1);

              sessionStorage.setItem('accRec-documentType-key', 'ALL');
              sessionStorage.setItem('accRec-documentType-text', t('documents.allDocumentsType'));
              sessionStorage.setItem('accRec-dateType-key', 'created');
              sessionStorage.setItem('accRec-dateType-text', t('documents.createdDate'));
              sessionStorage.setItem('accRec-queryMonth-key', getMonth(0).year + '' + getMonth(0).key);
              sessionStorage.setItem('accRec-queryMonth-text', getMonth(0).text);
              sessionStorage.setItem('accRec-from', getFrom(getMonth(0).month, getMonth(0).year));
              sessionStorage.setItem('accRec-to', getTo(getMonth(0).month, getMonth(0).year));
              sessionStorage.setItem('accRec-date', new Date());
              sessionStorage.setItem('accRec-pageSize', 10);
              sessionStorage.setItem('accRec-currentPage', 1);
              sessionStorage.setItem('accRec-sortDirection', 'DESC');

              setPrimarySort('createdTime');
              setIsSortedDescendingCreatedTime(true);
              setIsSortedDescendingDocumentDate(false);
              setIsSortedDescendingNumber(false);
              setIsSortedDescendingCustomerName(false);
              setIsSortedDescendingGrandTotal(false);
              setIsSortedDescendingName(false);
              setIsSortedDescendingEmailReceiver(false);
              setIsSortedDescendingEmailTime(false);

              setSortDirection('DESC');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setTotalDocuments(0);
              setTotalAccruedIncome(0);
              
              
              setTotalPaid(0);
            }
          }
        });

        async function documentsStart() {
          try {
            await documentsConnection.start();
            console.log('SignalR Documents Connected.');
          } catch (err) {
            console.log(err);
            setTimeout(documentsStart, 5000);
          }
        }

        documentsConnection.onclose(documentsStart);

        // Start the connection.
        documentsStart();

        const jobsConnection = new signalR.HubConnectionBuilder()
          .withUrl('https://leceipt.azurewebsites.net/api/OnJobsChanged', {
            headers: {
              oid: tokenResponse.idTokenClaims.oid,
              product: 'etax',
              fileExtension: 'pdf',
            },
          })
          .configureLogging(signalR.LogLevel.Information)
          .build();

        jobsConnection.on('jobUpdated', (data) => {
          console.log('SignalR push jobUpdated!!');
          console.log(data);

          getTotalProcessingDocuments();
        });

        async function jobsStart() {
          try {
            await jobsConnection.start();
            console.log('SignalR Jobs Connected.');
          } catch (err) {
            console.log(err);
            setTimeout(jobsStart, 5000);
          }
        }

        jobsConnection.onclose(jobsStart);
        jobsStart();
 */
        axios
          .get(API_URL + '/sources/emails', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log('Email sources: ', response);

              if (response.data.id) {
                if (!response.data.disabled) {
                  setIsEmailSetting(true);
                }
              } else {
                setIsEmailSetting(false);
              }
            },
            (error) => {
              console.log(error);

              setIsEmailSetting(false);
            }
          );

        axios
          .get(API_URL + '/functions/wakeup', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then((response) => {
            console.log('Wake up function: ', response);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    getTotalProcessingDocuments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');

    if (getNewDocuments) {
      let documentsListEndpoint =
        API_URL +
        '/documents/accounts-receivable/' +
        documentType.key +
        '/all?from=' +
        from +
        '&to=' +
        to +
        '&date=' +
        dateType.key +
        '&offset=' +
        (currentPage - 1) * pageSize +
        '&limit=' +
        pageSize + 
        '&sortDirection=' +
        sortDirection +
        '&sortBy=' +
        primarySort;
      let documentsSummaryEndpoint = API_URL + '/documents/summary/' + documentType.key + '/all?from=' + from + '&to=' + to + '&date=' + dateType.key;

      if (search) {
        documentsListEndpoint =
          API_URL +
          '/documents/accounts-receivable/search/' +
          documentType.key +
          '/all?from=' +
          from +
          '&to=' +
          to +
          '&date=' +
          dateType.key +
          '&offset=' +
          (currentPage - 1) * pageSize +
          '&limit=' +
          pageSize +
          '&search=' +
          search + 
          '&sortDirection=' +
          sortDirection +
          '&sortBy=' +
          primarySort;
        documentsSummaryEndpoint =
          API_URL +
          '/documents/summary/search/' +
          documentType.key +
          '/all?from=' +
          from +
          '&to=' +
          to +
          '&date=' +
          dateType.key +
          '&search=' +
          search;
      }

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          if (documentType.key === 'ALL') {
            const [invoiceTaxInvoice, invoice, billingNote] = await Promise.all([
              axios
                .get(API_URL + '/documents/summary/INVOICE-TAXINVOICE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                  },
                })
                .catch((error) => console.log(error)),
              axios
                .get(API_URL + '/documents/summary/INVOICE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                  },
                })
                .catch((error) => console.log(error)),
              axios
                .get(API_URL + '/documents/summary/BILLING-NOTE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                  },
                })
                .catch((error) => console.log(error)),
            ]);

            console.log(invoiceTaxInvoice.data.countTotal);
            console.log(invoice.data.countTotal);
            console.log(billingNote.data.countTotal);

            const countTotal = invoiceTaxInvoice.data.countTotal + invoice.data.countTotal + billingNote.data.countTotal;
            const totalAccruedIncome =
              invoiceTaxInvoice.data.totalAccruedIncome + invoice.data.totalAccruedIncome + billingNote.data.totalAccruedIncome;
            const totalPaid = invoiceTaxInvoice.data.totalPaid + invoice.data.totalPaid + billingNote.data.totalPaid;

            setTotalDocuments(countTotal);
            setTotalAccruedIncome(totalAccruedIncome);
            setTotalPaid(totalPaid);
          } else {
            axios
              .get(documentsSummaryEndpoint, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              })
              .then((response) => {
                console.log('Documents summary:', response);

                const { countTotal, totalAccruedIncome, totalPaid } = response.data;

                setTotalDocuments(countTotal);
                setTotalAccruedIncome(totalAccruedIncome);
                setTotalPaid(totalPaid);
              })
              .catch((error) => console.log(error));
          }

          let _documents;
          let _totalDocument;

          const [response] = await Promise.all([
            axios
              .get(documentsListEndpoint, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              })
              .catch((error) => console.log(error)),
          ]);

          if (statusType.key !== 'all') {
            _documents = response.data.data.documents.filter((doc) => doc.data.status === statusType.key);
            _totalDocument = _documents.length;
          } else {
            _documents = response.data.data.documents;
            _totalDocument = response.data.data.countTotal;
          }

          console.log('response data', response.data);

          console.log('response data document', response.data.data.documents);

          // _documents = response.data.data.documents;
          // _totalDocument = response.data.data.countTotal;

          _documents.forEach((doc) => {
            if (doc.type === 'CREDIT-NOTE') {
              doc.data.amountTotal = -Math.abs(doc.data.amountTotal);
              doc.data.grandTotal = -Math.abs(doc.data.grandTotal);
              doc.data.vatTotal = -Math.abs(doc.data.vatTotal);
            }
          });

          //Page dropdown
          let _pageDropdown = [];

          for (let i = 1; i <= Math.ceil(_totalDocument / pageSize); i++) {
            _pageDropdown.push({
              key: i,
              text: t('documents.page') + ' ' + i,
              iconProps: { iconName: 'Page' },
              onClick: () => {
                console.log('Selected page: ' + i);
                setCurrentPage(i);

                setLoadDataComplete(false);
                setItems([]);
                setGetNewDocuments(true);
              },
            });
          }

          setPageDropdown(_pageDropdown);

          if (currentPage > Math.ceil(_totalDocument / pageSize)) {
            setCurrentPage(1);
          }
/* 
          //Sorting
          if (_documents && _documents.length > 0) {
            if (primarySort === 'createdTime') {
              if (isSortedDescendingCreatedTime) {
                _documents.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.createdTime) - new Date(a.createdTime);
                });
              } else {
                _documents.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(a.createdTime) - new Date(b.createdTime);
                });
              }
            } else if (primarySort === 'number') {
              if (isSortedDescendingNumber) {
                _documents.sort((a, b) => {
                  return (
                    /[A-Za-z]/.test(b.data.number) - /[A-Za-z]/.test(a.data.number) ||
                    (b.data.number.toUpperCase() < a.data.number.toUpperCase()
                      ? -1
                      : b.data.number.toUpperCase() > a.data.number.toUpperCase()
                      ? 1
                      : 0)
                  );
                });
              } else {
                _documents.sort((a, b) => {
                  return (
                    /[A-Za-z]/.test(a.data.number) - /[A-Za-z]/.test(b.data.number) ||
                    (a.data.number.toUpperCase() < b.data.number.toUpperCase()
                      ? -1
                      : a.data.number.toUpperCase() > b.data.number.toUpperCase()
                      ? 1
                      : 0)
                  );
                });
              }
            } else if (primarySort === 'documentDate') {
              if (isSortedDescendingDocumentDate) {
                _documents.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.data.date) - new Date(a.data.date);
                });
              } else {
                _documents.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(a.data.date) - new Date(b.data.date);
                });
              }
            } else if (primarySort === 'customerName') {
              if (isSortedDescendingCustomerName) {
                _documents.sort((a, b) => {
                  if (b.data.customer.name < a.data.customer.name) {
                    return -1;
                  }
                  if (b.data.customer.name > a.data.customer.name) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                _documents.sort((a, b) => {
                  if (a.data.customer.name < b.data.customer.name) {
                    return -1;
                  }
                  if (a.data.customer.name > b.data.customer.name) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'customerPhone') {
              if (isSortedDescendingCustomerPhone) {
                _documents.sort((a, b) => {
                  if (b.data.customer.phone < a.data.customer.phone) {
                    return -1;
                  }
                  if (b.data.customer.phone > a.data.customer.phone) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                _documents.sort((a, b) => {
                  if (a.data.customer.phone < b.data.customer.phone) {
                    return -1;
                  }
                  if (a.data.customer.phone > b.data.customer.phone) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'vatTotal') {
              if (isSortedDescendingVatTotal) {
                _documents.sort(function (a, b) {
                  return b.data.vatTotal - a.data.vatTotal;
                });
              } else {
                _documents.sort(function (a, b) {
                  return a.data.vatTotal - b.data.vatTotal;
                });
              }
            } else if (primarySort === 'grandTotal') {
              if (isSortedDescendingGrandTotal) {
                _documents.sort(function (a, b) {
                  return b.data.grandTotal - a.data.grandTotal;
                });
              } else {
                _documents.sort(function (a, b) {
                  return a.data.grandTotal - b.data.grandTotal;
                });
              }
            } else if (primarySort === 'name') {
              if (isSortedDescendingName) {
                _documents.sort((a, b) => {
                  if (b.data.name < a.data.name) {
                    return -1;
                  }
                  if (b.data.name > a.data.name) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                _documents.sort((a, b) => {
                  if (a.data.name < b.data.name) {
                    return -1;
                  }
                  if (a.data.name > b.data.name) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'emailReceiver') {
              if (isSortedDescendingEmailReceiver) {
                _documents.sort((a, b) => {
                  if (b.data.customer.email < a.data.customer.email) {
                    return -1;
                  }
                  if (b.data.customer.email > a.data.customer.email) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                _documents.sort((a, b) => {
                  if (a.data.customer.email < b.data.customer.email) {
                    return -1;
                  }
                  if (a.data.customer.email > b.data.customer.email) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'emailTime') {
              if (isSortedDescendingEmailTime) {
                _documents.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.deliveries.email.time) - new Date(a.deliveries.email.time);
                });
              } else {
                _documents.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(a.deliveries.email.time) - new Date(b.deliveries.email.time);
                });
              }
            }
          }
 */
          //set page
          setPageData(paginate(_totalDocument, currentPage, pageSize, 10));

          const _docs = [];

          if (_documents.length > 0) {
            for (let i = 0; i < _documents.length; i++) {
              let fileSize = formatBytes(_documents[i].file.size);
              let createdTime = toThaiDateString(_documents[i].createdTime);

              let _companyEmail = '';

              let _customerEmail = '';
              let _customerPhone = '';
              let _amountTotal = 0;
              let _vatTotal = 0;
              let _grandTotal = 0;

              if (
                _documents[i].data.company &&
                _documents[i].data.company.email &&
                _documents[i].data.company.email !== null &&
                _documents[i].data.company.email !== undefined
              ) {
                _companyEmail = _documents[i].data.company.email;
              }

              if (
                _documents[i].data.customer &&
                _documents[i].data.customer.email &&
                _documents[i].data.customer.email !== null &&
                _documents[i].data.customer.email !== undefined
              ) {
                _customerEmail = _documents[i].data.customer.email;
              }

              if (
                _documents[i].data.customer &&
                _documents[i].data.customer.phone &&
                _documents[i].data.customer.phone !== null &&
                _documents[i].data.customer.phone !== undefined
              ) {
                _customerPhone = _documents[i].data.customer.phone;
              }

              if (_documents[i].type === 'WITHHOLDING-TAX') {
                _amountTotal = '-';
                _vatTotal = '-';
                _grandTotal = '-';
              } else {
                if (_documents[i].data.amountTotal) {
                  _amountTotal = _documents[i].data.amountTotal;
                }

                if (_documents[i].data.vatTotal) {
                  _vatTotal = _documents[i].data.vatTotal;
                }

                if (_documents[i].data.grandTotal) {
                  _grandTotal = _documents[i].data.grandTotal;
                }
              }

              let _isPdfXml = false;

              if (_documents[i].xmlFile && _documents[i].xmlFile.size) {
                _isPdfXml = true;
              }

              let _documentStatus = _documents[i].data.status;

              if (_documents[i].data.status === 'open' || _documents[i].data.status === 'new') {
                if (_documents[i]?.deliveries?.email?.time || _documents[i]?.deliveries?.sms?.response?.date_created) {
                  _documentStatus = 'sent';
                }
              }

              let _whtTotal = _documents[i].data.items.reduce((accumulator, item) => accumulator + (item.whtTotal ? item.whtTotal : 0), 0);
              let _whtRate = -1;
              if (_documents[i].data.items.every((item) => item.percentWht === _documents[i].data.items[0].percentWht)) {
                _whtRate = _documents[i].data.items[0].percentWht;
              }

              _docs.push({
                key: _documents[i].id,
                pdfFileName: _documents[i].file.displayName,
                name: _documents[i].data.name,
                fileExtension: _documents[i].file.extension,
                isPdfXml: _isPdfXml,
                createdBy: _documents[i].createdBy,
                createdTime: createdTime,
                createdTimeValue: _documents[i].createdTime,
                fileSize: fileSize,
                fileSizeRaw: _documents[i].file.size,
                type: _documents[i].type,
                signature: _documents[i].signatures[0].certificateName,
                product: _documents[i].product,
                number: _documents[i].data.number,
                companyEmail: _companyEmail,
                customerName: _documents[i].data.customer.name,
                customerEmail: _customerEmail,
                customerPhone: _customerPhone,
                amountTotal: _amountTotal,
                vatTotal: _vatTotal,
                grandTotal: _grandTotal,
                data: _documents[i].data,
                documentDate: _documents[i].data.dateBE,
                xmlData: _documents[i].xmlFile,
                rdStatus: _documents[i].deliveries.rd.status,
                digitalSign: _documents[i].digitalSign,
                reIssue: _documents[i].data.reIssue,
                emailReceiver: _documents[i]?.deliveries?.email?.receiver
                  ? _documents[i].deliveries.email.receiver
                  : _documents[i].data.customer.email,
                emailTime: _documents[i]?.deliveries?.email?.time ? toThaiDateString(_documents[i].deliveries.email.time) : '',
                emailTimeValue: _documents[i]?.deliveries?.email?.time,
                documentDateValue: _documents[i].data.date,
                smsTime: _documents[i]?.deliveries?.sms?.response?.date_created
                  ? toThaiDateString(_documents[i]?.deliveries?.sms?.response?.date_created)
                  : '',
                smsTimeValue: _documents[i]?.deliveries?.sms?.response?.date_created,
                documentStatus: _documentStatus,
                paymentList: _documents[i].data.paymentList || [],
                accRecPaymentList: _documents[i].data.accountsReceivable?.paymentList || [],
                accRecWithholdingTax: _documents[i].data.accountsReceivable?.withholdingTax || { rate: 0, amount: 0 },
                noteList: _documents[i].data.accountsReceivable?.noteList || [],
                whtTotal: _whtTotal,
                whtRate: _whtRate,
                includeVat: _documents[i].data.includeVat,
              });
            }
          }

          console.log('Docs data', _docs);
          setItems(_docs);

          setLoadDataComplete(true);
          setGetNewDocuments(false);
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          // instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  const download = (item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/files/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              console.log(response.headers);

              const time = formatDateTime(item.createdTimeValue);

              let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g, '') + '.' + item.fileExtension;

              console.log('file name: ', fileName);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName); //or any other extension
              document.body.appendChild(link);
              link.click();
              link.remove();
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    if (url) {
      getPNGorZip();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const deleteFile = (item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .delete(API_URL + '/etax/documents/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);
              setLoadDataComplete(false);
              setGetNewDocuments(true);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        // Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const openFile = (item) => {
    if (item.fileExtension === 'pdf') {
      openFile_pdf(item);
    } else if (item.fileExtension === 'xml') {
      openFile_xml(item);
    }
  };

  const openFile_pdf = (item) => {
    history.push({
      pathname: '/etax/documents/pdf/view',
      state: {
        item: item,
        backUrl: '/etax/documents/accounts-receivable/all',
      },
    });
  };

  const openFile_xml = (item) => {
    history.push({
      pathname: '/etax/documents/xml/view',
      state: {
        item: item,
        backUrl: '/etax/documents/accounts-receivable/all',
      },
    });
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
    setGetNewDocuments(true);
    setLoadDataComplete(false);
  };

  const selectedDownloadZip = (fileExt) => {
    console.log(selectedItems);
    if (selectedItems.length > 0 && items.length > 0) {
      let _documentIds = '';

      for (let i = 0; i < selectedItems.length; i++) {
        if (i === selectedItems.length - 1) {
          _documentIds += selectedItems[i].key;
        } else {
          _documentIds += selectedItems[i].key + ',';
        }
      }

      console.log(_documentIds);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/files/zip', {
              params: {
                fileExtension: fileExt,
                documentIds: _documentIds,
              },
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
              responseType: 'blob',
            })
            .then(
              (response) => {
                console.log(response.headers);

                let zipFileName = formatDateTime(new Date().toISOString()) + '-' + fileExt.toUpperCase() + '-Leceipt.zip';

                console.log('Zip file name: ', zipFileName);

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', zipFileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }
  };

  const reCreateDocument = async (documentType, item) => {
    console.log(item);

    let documentPath = '/etax/documents/receipts';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoices-abb';
        break;
      case 'RECEIPT':
        if (item.data.items[0].date) {
          documentPath = '/etax/documents/batch-receipts';
        } else {
          documentPath = '/etax/documents/receipts';
        }
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices';
        break;
      case 'DEBIT-NOTE':
        documentPath = '/etax/documents/debit-notes';
        break;
      case 'CREDIT-NOTE':
        documentPath = '/etax/documents/credit-notes';
        break;
      case 'QUOTATION':
        documentPath = '/documents/quotations';
        break;
      case 'INVOICE':
        documentPath = '/documents/invoices';
        break;
      case 'BILLING-NOTE':
        documentPath = '/documents/billing-notes';
        break;
      case 'DELIVERYORDER':
        documentPath = '/documents/deliveryorders';
        break;
      default:
        break;
    }

    if (documentType === item.type) {
      history.push({
        pathname: documentPath,
        state: {
          command: 'reCreate',
          company: item.data.company,
          customer: item.data.customer,
          number: item.data.number,
          date: item.data.date,
          dateBE: item.data.dateBE,
          dateCE: item.data.dateCE,
          dueDate: item.data.dueDate,
          dueDateBE: item.data.dueDateBE,
          dueDateCE: item.data.dueDateCE,
          discount: item.data.discount,
          items: item.data.items,
          amountTotal: item.data.amountTotal,
          vatTotal: item.data.vatTotal,
          grandTotal: item.data.grandTotal,
          note: item.data.note,
          createdBy: item.data.createdBy,
          receivedBy: item.data.receivedBy,
          percentVat: item.data.percentVat,
          includeVat: item.data.includeVat,
          reIssue: item.data.reIssue,
          refer: item.data.refer,
          reference: item.data.reference,
          createdTime: item.data.createdTime,
          id: item.data.id,
          message: item.data.message,
          name: item.data.name,
          payment: item.data.payment,
          schemeVersionID: item.data.schemeVersionID,
          status: item.data.status,
          typeCode: item.data.typeCode,
          backUrl: '/etax/documents/accounts-receivable/all',
        },
      });
    } else {
      let _reference = item.data.reference;

      if (
        item.type === 'INVOICE-TAXINVOICE' ||
        item.type === 'DELIVERYORDER-TAXINVOICE' ||
        item.type === 'QUOTATION' ||
        item.type === 'INVOICE' ||
        item.type === 'BILLING-NOTE' ||
        item.type === 'DELIVERYORDER'
      ) {
        _reference = item.data.number;
      }

      history.push({
        pathname: documentPath,
        state: {
          command: 'reCreate',
          company: item.data.company,
          customer: item.data.customer,
          number: '',
          date: null,
          dateBE: null,
          dateCE: null,
          dueDate: item.data.dueDate,
          dueDateBE: item.data.dueDateBE,
          dueDateCE: item.data.dueDateCE,
          discount: item.data.discount,
          items: item.data.items,
          amountTotal: item.data.amountTotal,
          vatTotal: item.data.vatTotal,
          grandTotal: item.data.grandTotal,
          note: item.data.note,
          createdBy: item.data.createdBy,
          receivedBy: item.data.receivedBy,
          percentVat: item.data.percentVat,
          includeVat: item.data.includeVat,
          reIssue: item.data.reIssue,
          refer: item.data.refer,
          reference: _reference,
          createdTime: item.data.createdTime,
          id: item.data.id,
          message: item.data.message,
          name: item.data.name,
          payment: item.data.payment,
          schemeVersionID: item.data.schemeVersionID,
          status: item.data.status,
          typeCode: item.data.typeCode,
          backUrl: '/etax/documents/accounts-receivable/all',
        },
      });
    }
  };

  const reCreateCreditAndDebit = async (documentType, item) => {
    console.log(item);

    let documentPath = '/etax/documents/debit-notes';

    switch (documentType) {
      case 'DEBIT-NOTE':
        documentPath = '/etax/documents/debit-notes';
        break;
      case 'CREDIT-NOTE':
        documentPath = '/etax/documents/credit-notes';
        break;
      default:
        break;
    }

    let _refer = item.data.refer;
    _refer.items = item.data.items;

    history.push({
      pathname: documentPath,
      state: {
        command: 'reCreate',
        company: item.data.company,
        customer: item.data.customer,
        number: item.data.number,
        date: item.data.date,
        dateBE: item.data.dateBE,
        dateCE: item.data.dateCE,
        discount: item.data.discount,
        items: item.data.items,
        amountTotal: item.data.amountTotal,
        vatTotal: item.data.vatTotal,
        grandTotal: item.data.grandTotal,
        note: item.data.note,
        createdBy: item.data.createdBy,
        receivedBy: item.data.receivedBy,
        percentVat: item.data.percentVat,
        includeVat: item.data.includeVat,
        reIssue: item.data.reIssue,
        refer: _refer,
        createdTime: item.data.createdTime,
        id: item.data.id,
        message: item.data.message,
        name: item.data.name,
        payment: item.data.payment,
        schemeVersionID: item.data.schemeVersionID,
        status: item.data.status,
        typeCode: item.data.typeCode,
        backUrl: '/etax/documents/accounts-receivable/all',
      },
    });
  };

  const reIssueDocument = async (item) => {
    console.log(item);

    let documentPath = '/etax/documents/receipts';

    switch (item.type) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoices-abb';
        break;
      case 'RECEIPT':
        if (item.data.items[0].date) {
          documentPath = '/etax/documents/batch-receipts';
        } else {
          documentPath = '/etax/documents/receipts';
        }
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices';
        break;
      case 'DEBIT-NOTE':
        documentPath = '/etax/documents/debit-notes';
        break;
      case 'CREDIT-NOTE':
        documentPath = '/etax/documents/credit-notes';
        break;
      default:
        break;
    }

    history.push({
      pathname: documentPath,
      state: {
        command: 'reIssue',
        company: item.data.company,
        customer: item.data.customer,
        number: item.data.number,
        date: item.data.date,
        dateBE: item.data.dateBE,
        dateCE: item.data.dateCE,
        dueDate: item.data.dueDate,
        dueDateBE: item.data.dueDateBE,
        dueDateCE: item.data.dueDateCE,
        discount: item.data.discount,
        items: item.data.items,
        amountTotal: item.data.amountTotal,
        vatTotal: item.data.vatTotal,
        grandTotal: item.data.grandTotal,
        note: item.data.note,
        createdBy: item.data.createdBy,
        receivedBy: item.data.receivedBy,
        percentVat: item.data.percentVat,
        includeVat: item.data.includeVat,
        reIssue: item.data.reIssue,
        refer: item.data.refer,
        reference: item.data.reference,
        createdTime: item.data.createdTime,
        id: item.data.id,
        message: item.data.message,
        name: item.data.name,
        payment: item.data.payment,
        schemeVersionID: item.data.schemeVersionID,
        status: item.data.status,
        typeCode: item.data.typeCode,
        backUrl: '/etax/documents/accounts-receivable/all',
      },
    });
  };

  const handleChangeDocumentStatus = (documentId, docType, docNumber, statusKey, statusText) => {
    setChangeDocumentStatusPayload((prev) => ({
      ...prev,
      isClick: true,
      documentId: documentId,
      type: docType,
      number: docNumber,
      statusKey: statusKey,
      statusText: statusText,
    }));
  };

  const handleRecordPayment = (documentId, docNumber, _grandTotal, _paymentList, _withholdingTax, _whtTotal, _whtRate, _includeVat, _vatTotal) => {
    let defaultPaymentList = [
      {
        paymentCode: '',
        paymentName: '',
        date: new Date().toISOString(),
        dateBE: onFormatDate(new Date(), 'BE'),
        dateCE: onFormatDate(new Date(), 'CE'),
        bankCode: '',
        bankName: '',
        typeCode: '',
        typeName: '',
        accountName: '',
        accountNumber: '',
        paidAmount: 0,
        originalPaidAmount: 0,
        paymentNote: '',
      },
    ];

    if (_paymentList.length) {
      if (_paymentList.some((_payment) => !_payment.originalPaidAmount)) {
        _paymentList.map((_payment) => (_payment.originalPaidAmount = _payment.paidAmount));
        defaultPaymentList = _paymentList;
      } else {
        defaultPaymentList = _paymentList;
      }

      const { rate, amount } = _withholdingTax;
      const _taxWithheld = rate !== 0 ? true : false;

      setRecordPayment((prev) => ({
        ...prev,
        isClick: true,
        isChange: false,
        documentId: documentId,
        invoiceNumber: docNumber,
        amountDue: _includeVat ? _grandTotal : Math.round((_grandTotal + _vatTotal) * 100) / 100,
        paymentList: defaultPaymentList,
        taxWithheld: _taxWithheld,
        taxWithheldRate: rate,
        taxWithheldAmount: amount,
        includeVat: _includeVat,
        vatTotal: _vatTotal,
      }));
    } else {
      defaultPaymentList[0].paidAmount = _includeVat
        ? Math.round((_grandTotal - _whtTotal) * 100) / 100
        : Math.round((_grandTotal - _whtTotal + _vatTotal) * 100) / 100;
      defaultPaymentList[0].originalPaidAmount = _grandTotal;

      const _taxWithheld = _whtRate ? true : false;
      const _taxWithheldRate = _whtRate ? _whtRate : 0;
      const _taxWithheldAmount = _whtRate ? _whtTotal : 0;

      setRecordPayment((prev) => ({
        ...prev,
        isClick: true,
        isChange: true,
        documentId: documentId,
        invoiceNumber: docNumber,
        amountDue: _includeVat ? _grandTotal : Math.round((_grandTotal + _vatTotal) * 100) / 100,
        paymentList: defaultPaymentList,
        taxWithheld: _taxWithheld,
        taxWithheldRate: _taxWithheldRate,
        taxWithheldAmount: _taxWithheldAmount,
        includeVat: _includeVat,
        vatTotal: _vatTotal,
      }));
    }
  };

  const handleChangePaymentList = (e, index) => {
    setShowErrorMessageRecordPayment(false);

    const values = [...recordPayment.paymentList];
    values[index][e.target.name] = e.target.value;
    if (e.target.name === 'paidAmount') {
      values[index]['originalPaidAmount'] = e.target.value;
    }
    setRecordPayment((prev) => ({
      ...prev,
      isChange: true,
      paymentList: values,
    }));
  };

  const handleChangeNoteList = (e, index) => {
    const values = [...recordNote.noteList];
    values[index][e.target.name] = e.target.value;
    setRecordNote((prev) => ({
      ...prev,
      isChange: true,
      noteList: values,
    }));
  };

  const handleBlurPaymentList = (e, index) => {
    const values = [...recordPayment.paymentList];
    values[index][e.target.name] = Math.round(values[index][e.target.name] * 100) / 100;
    if (e.target.name === 'paidAmount') {
      values[index]['originalPaidAmount'] = Math.round(values[index]['originalPaidAmount'] * 100) / 100;
    }
    setRecordPayment((prev) => ({
      ...prev,
      isChange: true,
      paymentList: values,
    }));
  };

  const handleChangePaymentDate = (index, date) => {
    const values = [...recordPayment.paymentList];

    values[index]['date'] = date.toISOString();
    values[index]['dateBE'] = onFormatDate(date, 'BE');
    values[index]['dateCE'] = onFormatDate(date, 'CE');

    setRecordPayment((prev) => ({
      ...prev,
      isChange: true,
      paymentList: values,
    }));
  };

  const handleChangeNoteDate = (index, date) => {
    const values = [...recordNote.noteList];

    values[index]['date'] = date.toISOString();
    values[index]['dateBE'] = onFormatDate(date, 'BE');
    values[index]['dateCE'] = onFormatDate(date, 'CE');

    setRecordNote((prev) => ({
      ...prev,
      isChange: true,
      noteList: values,
    }));
  };

  const handleSelectOption = (index, e, selectedOption) => {
    const values = [...recordPayment.paymentList];

    if (selectedOption) {
      if (e.target.id === 'paymentMethod') {
        values[index]['paymentCode'] = selectedOption.key;
        values[index]['paymentName'] = selectedOption.text;

        if (
          selectedOption.key === 'BANK' &&
          !(values[index].bankCode && values[index].typeCode && values[index].accountName && values[index].accountNumber)
        ) {
          const paymentWithBank = values.find(
            (payment) => payment.paymentCode === 'BANK' && payment.bankCode && payment.typeCode && payment.accountName && payment.accountNumber
          );

          values[index].bankCode = paymentWithBank?.bankCode || '';
          values[index].bankName = paymentWithBank?.bankName || '';
          values[index].typeCode = paymentWithBank?.typeCode || '';
          values[index].typeName = paymentWithBank?.typeName || '';
          values[index].accountName = paymentWithBank?.accountName || '';
          values[index].accountNumber = paymentWithBank?.accountNumber || '';
        }
      } else if (e.target.id === 'bank') {
        values[index]['bankCode'] = selectedOption.key;
        values[index]['bankName'] = selectedOption.text;
      } else if (e.target.id === 'accountType') {
        values[index]['typeCode'] = selectedOption.key;
        values[index]['typeName'] = selectedOption.text;
      }
    }

    setRecordPayment((prev) => ({
      ...prev,
      isChange: true,
      paymentList: values,
    }));
  };

  const handleAddPaymentList = () => {
    setRecordPayment((prev) => ({
      ...prev,
      isChange: true,
      paymentList: [
        ...prev.paymentList,
        {
          paymentCode: '',
          paymentName: '',
          date: new Date().toISOString(),
          dateBE: onFormatDate(new Date(), 'BE'),
          dateCE: onFormatDate(new Date(), 'CE'),
          bankCode: '',
          bankName: '',
          typeCode: '',
          typeName: '',
          accountName: '',
          accountNumber: '',
          paidAmount: 0,
          originalPaidAmount: 0,
          paymentNote: '',
        },
      ],
    }));
  };

  const handleAddNoteList = () => {
    setRecordNote((prev) => ({
      ...prev,
      isChange: true,
      noteList: [{ date: new Date(), dateBE: onFormatDate(new Date(), 'BE'), dateCE: onFormatDate(new Date(), 'CE'), message: '' }, ...prev.noteList],
    }));
  };

  const handleRemovePaymentList = (index) => {
    const values = [...recordPayment.paymentList];
    values.splice(index, 1);

    if (values.length) {
      setRecordPayment((prev) => ({
        ...prev,
        isChange: true,
        paymentList: values,
      }));
    } else {
      setRecordPayment((prev) => ({
        ...prev,
        isChange: true,
        paymentList: values,
        taxWithheld: false,
        taxWithheldAmount: 0,
        taxWithheldRate: 0,
      }));
    }
  };

  const handleRemoveNoteList = (index) => {
    const values = [...recordNote.noteList];
    values.splice(index, 1);
    setRecordNote((prev) => ({
      ...prev,
      isChange: true,
      noteList: values,
    }));
  };

  const handleRecordPaymentList = async (e) => {
    let isCorrect = true;

    if (recordPayment.paymentList.some((_payment) => _payment.paidAmount < 0)) isCorrect = false;

    const _amountPaid = recordPayment.paymentList.reduce((accumulator, payment) => accumulator + payment.paidAmount, 0);
    if (Math.round((_amountPaid + recordPayment.taxWithheldAmount) * 100) / 100 > recordPayment.amountDue) isCorrect = false;
    if (recordPayment.paymentList.some((_payment) => !_payment.paymentCode)) isCorrect = false;
    if (recordPayment.getTotalPaid() - recordPayment.taxWithheldAmount < 0) isCorrect = false;

    if (!isCorrect) setShowErrorMessageRecordPayment(true);

    if (isCorrect) {
      setLoadingRecordPayment(true);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          const _documentId = recordPayment.documentId;
          const _paymentList = recordPayment.paymentList;

          let _statusKey = 'paid';
          if (recordPayment.amountDue > Math.round((_amountPaid + recordPayment.taxWithheldAmount) * 100) / 100) {
            _statusKey = 'partial-paid';
          }

          const _documentData = items.find((_item) => _item.key === _documentId);

          const _value = {
            paymentList: _paymentList,
            withholdingTax: {
              rate: recordPayment.taxWithheldRate,
              amount: recordPayment.taxWithheldAmount,
            },
            noteList: _documentData.noteList,
          };

          axios
            .patch(
              API_URL + `/documents/${_documentId}`,
              {
                replace: '/data/accountsReceivable',
                value: _value,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              }
            )
            .then((responseRecordPayment) => {
              console.log('response recordPayment', responseRecordPayment);

              setLoadingRecordPayment(false);
              setRecordPayment((prev) => ({
                ...prev,
                isClick: false,
              }));

              axios
                .patch(
                  API_URL + `/documents/${_documentId}`,
                  {
                    replace: '/data/status',
                    value: _statusKey,
                  },
                  {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                    },
                  }
                )
                .then(
                  async (response) => {
                    console.log('response change document status', response);

                    const _index = items.findIndex((item) => item.key === _documentId);

                    if (_index > -1) {
                      const _updateItems = [...items];

                      _updateItems[_index].documentStatus = _statusKey;
                      _updateItems[_index].accRecPaymentList = _value.paymentList;
                      _updateItems[_index].accRecWithholdingTax = _value.withholdingTax;
                      setItems(_updateItems);
                    }

                    if (documentType.key === 'ALL') {
                      const [invoiceTaxInvoice, invoice, billingNote] = await Promise.all([
                        axios
                          .get(API_URL + '/documents/summary/INVOICE-TAXINVOICE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                            headers: {
                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                            },
                          })
                          .catch((error) => console.log(error)),
                        axios
                          .get(API_URL + '/documents/summary/INVOICE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                            headers: {
                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                            },
                          })
                          .catch((error) => console.log(error)),
                        axios
                          .get(API_URL + '/documents/summary/BILLING-NOTE/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                            headers: {
                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                            },
                          })
                          .catch((error) => console.log(error)),
                      ]);

                      const countTotal = invoiceTaxInvoice.data.countTotal + invoice.data.countTotal + billingNote.data.countTotal;
                      const totalAccruedIncome =
                        invoiceTaxInvoice.data.totalAccruedIncome + invoice.data.totalAccruedIncome + billingNote.data.totalAccruedIncome;
                      const totalPaid = invoiceTaxInvoice.data.totalPaid + invoice.data.totalPaid + billingNote.data.totalPaid;

                      setTotalDocuments(countTotal);
                      setTotalAccruedIncome(totalAccruedIncome);
                      setTotalPaid(totalPaid);
                    } else {
                      axios
                        .get(API_URL + '/documents/summary/' + documentType.key + '/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                          headers: {
                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                          },
                        })
                        .then((response) => {
                          console.log('Documents summary:', response);

                          const { countTotal, totalAccruedIncome, totalPaid } = response.data;

                          setTotalDocuments(countTotal);
                          setTotalAccruedIncome(totalAccruedIncome);
                          setTotalPaid(totalPaid);
                        })
                        .catch((error) => console.log(error));
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
                );
            });
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }
  };

  const handleRecordNoteList = async (e) => {
    let isCorrect = true;

    if (isCorrect) {
      setRecordNote((prev) => ({ ...prev, isLoading: true }));

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          const _documentId = recordNote.documentId;
          const _noteList = recordNote.noteList;

          const _documentData = items.find((_item) => _item.key === _documentId);

          const _value = {
            paymentList: _documentData.accRecPaymentList || [],
            withholdingTax: _documentData.accRecWithholdingTax || null,
            noteList: _noteList,
          };

          axios
            .patch(
              API_URL + `/documents/${_documentId}`,
              {
                replace: '/data/accountsReceivable',
                value: _value,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              }
            )
            .then((responseRecordNote) => {
              console.log('response RecordNote', responseRecordNote);

              const _index = items.findIndex((item) => item.key === _documentId);

              if (_index > -1) {
                const _updateItems = [...items];

                _updateItems[_index].accRecPaymentList = _value.paymentList;
                _updateItems[_index].accRecWithholdingTax = _value.withholdingTax;
                _updateItems[_index].noteList = _noteList;
                setItems(_updateItems);
              }

              setRecordNote((prev) => ({
                ...prev,
                isClick: false,
                isLoading: false,
              }));
            });
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }
  };

  const documentStatusSubText = () => {
    let _docType = changeDocumentStatusPayload.type;
    if (i18n.language === 'en') {
      if (changeDocumentStatusPayload.type === 'ใบแจ้งหนี้/ใบกำกับภาษี') _docType = 'Invoice/Tax Invoice';
      if (changeDocumentStatusPayload.type === 'ใบแจ้งหนี้') _docType = 'Invoice';
      if (changeDocumentStatusPayload.type === 'ใบวางบิล') _docType = 'Billing Note';
    }

    if (i18n.language === 'en')
      return `Change status of ${_docType} No.${changeDocumentStatusPayload.number} to ${changeDocumentStatusPayload.statusText}`;
    return `${_docType} เลขที่ ${changeDocumentStatusPayload.number} เป็น ${changeDocumentStatusPayload.statusText}`;
  };

  const columns = [
    {
      key: 'column1',
      name: t('documents.createdDate'),
      fieldName: 'createdTime',
      minWidth: 70,
      maxWidth: 125,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'createdTime' ? true : false,
      isSortedDescending: isSortedDescendingCreatedTime,
      isSortedAscending: !isSortedDescendingCreatedTime,
      onColumnClick: () => {
        console.log('Sort created time!');
        setPrimarySort('createdTime');
        setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

        // handleSort('createdTime', !isSortedDescendingCreatedTime);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        
        

        sessionStorage.setItem('accRec-primarySort', 'createdTime');
        sessionStorage.setItem('accRec-sort-createdTime', !isSortedDescendingCreatedTime === true ? 'true' : 'false');

        sessionStorage.setItem('accRec-sort-number', 'false');
        sessionStorage.setItem('accRec-sort-documentDate', 'false');
        sessionStorage.setItem('accRec-sort-customerName', 'false');
        sessionStorage.setItem('accRec-sort-customerPhone', 'false');
        sessionStorage.setItem('accRec-sort-vatTotal', 'false');
        sessionStorage.setItem('accRec-sort-grandTotal', 'false');
        sessionStorage.setItem('accRec-sort-name', 'false');
        sessionStorage.setItem('accRec-sort-emailTime', 'false');
        sessionStorage.setItem('accRec-sort-emailReceiver', 'false');

        if (!isSortedDescendingCreatedTime) {
            setSortDirection('DESC');
            sessionStorage.setItem('accRec-sortDirection', 'DESC');
        } else {
            setSortDirection('ASC');
            sessionStorage.setItem('accRec-sortDirection', 'ASC');
        }

      },
      data: 'string',
      onRender: (item) => {
        let _styleProp = {};

        if (item.documentStatus === 'open') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'sent') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'partial-paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'fail') {
          _styleProp = { color: 'red' };
        }

        return <span style={_styleProp}>{item.createdTime}</span>;
      },
    },
    {
      key: 'column2',
      name: t('documents.number'),
      fieldName: 'number',
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        // handleSort('number', !isSortedDescendingNumber);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        
        

        sessionStorage.setItem('accRec-primarySort', 'number');
        sessionStorage.setItem('accRec-sort-number', !isSortedDescendingNumber === true ? 'true' : 'false');

        sessionStorage.setItem('accRec-sort-createdTime', 'false');
        sessionStorage.setItem('accRec-sort-documentDate', 'false');
        sessionStorage.setItem('accRec-sort-customerName', 'false');
        sessionStorage.setItem('accRec-sort-customerPhone', 'false');
        sessionStorage.setItem('accRec-sort-vatTotal', 'false');
        sessionStorage.setItem('accRec-sort-grandTotal', 'false');
        sessionStorage.setItem('accRec-sort-name', 'false');
        sessionStorage.setItem('accRec-sort-emailTime', 'false');
        sessionStorage.setItem('accRec-sort-emailReceiver', 'false');

        if (!isSortedDescendingNumber) {
            setSortDirection('DESC');
            sessionStorage.setItem('accRec-sortDirection', 'DESC');
        } else {
            setSortDirection('ASC');
            sessionStorage.setItem('accRec-sortDirection', 'ASC');
        }

      },
      data: 'string',
      onRender: (item) => {
        let _styleProp = {};

        if (item.documentStatus === 'open') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'sent') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'partial-paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'fail') {
          _styleProp = { color: 'red' };
        }

        return (
          <ActionButton
            title={t('documents.download')}
            className={classNames.fileIconImg}
            onClick={() => {
              openFile(item);
            }}
          >
            <span style={_styleProp}>{item.number}</span>
          </ActionButton>
        );
      },
    },
    {
      key: 'column3',
      name: t('documents.documentDate'),
      fieldName: 'documentDate',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'documentDate' ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log('Sort documentDate!');
        setPrimarySort('documentDate');
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        // handleSort('documentDate', !isSortedDescendingDocumentDate);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        
        

        sessionStorage.setItem('accRec-primarySort', 'documentDate');
        sessionStorage.setItem('accRec-sort-documentDate', !isSortedDescendingDocumentDate === true ? 'true' : 'false');

        sessionStorage.setItem('accRec-sort-createdTime', 'false');
        sessionStorage.setItem('accRec-sort-number', 'false');
        sessionStorage.setItem('accRec-sort-customerName', 'false');
        sessionStorage.setItem('accRec-sort-customerPhone', 'false');
        sessionStorage.setItem('accRec-sort-vatTotal', 'false');
        sessionStorage.setItem('accRec-sort-grandTotal', 'false');
        sessionStorage.setItem('accRec-sort-name', 'false');
        sessionStorage.setItem('accRec-sort-emailTime', 'false');
        sessionStorage.setItem('accRec-sort-emailReceiver', 'false');

        if (!isSortedDescendingDocumentDate) {
            setSortDirection('DESC');
            sessionStorage.setItem('accRec-sortDirection', 'DESC');
        } else {
            setSortDirection('ASC');
            sessionStorage.setItem('accRec-sortDirection', 'ASC');
        }

      },
      data: 'string',
      onRender: (item) => {
        let _styleProp = {};

        if (item.documentStatus === 'open') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'sent') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'partial-paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'fail') {
          _styleProp = { color: 'red' };
        }

        return <span style={_styleProp}>{item.documentDate}</span>;
      },
    },
    {
      key: 'column4',
      name: t('documents.customerName'),
      fieldName: 'customerName',
      minWidth: 90,
      maxWidth: 220,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort customerName!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        // handleSort('customerName', !isSortedDescendingCustomerName);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        
        

        sessionStorage.setItem('accRec-primarySort', 'customerName');
        sessionStorage.setItem('accRec-sort-customerName', !isSortedDescendingCustomerName === true ? 'true' : 'false');

        sessionStorage.setItem('accRec-sort-createdTime', 'false');
        sessionStorage.setItem('accRec-sort-number', 'false');
        sessionStorage.setItem('accRec-sort-customerPhone', 'false');
        sessionStorage.setItem('accRec-sort-documentDate', 'false');
        sessionStorage.setItem('accRec-sort-vatTotal', 'false');
        sessionStorage.setItem('accRec-sort-grandTotal', 'false');
        sessionStorage.setItem('accRec-sort-name', 'false');
        sessionStorage.setItem('accRec-sort-emailTime', 'false');
        sessionStorage.setItem('accRec-sort-emailReceiver', 'false');

        if (!isSortedDescendingCustomerName) {
            setSortDirection('DESC');
            sessionStorage.setItem('accRec-sortDirection', 'DESC');
        } else {
            setSortDirection('ASC');
            sessionStorage.setItem('accRec-sortDirection', 'ASC');
        }

      },
      data: 'string',
      onRender: (item) => {
        let _styleProp = {};

        if (item.documentStatus === 'open') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'sent') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'partial-paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'fail') {
          _styleProp = { color: 'red' };
        }

        return <span style={_styleProp}>{item.customerName}</span>;
      },
    },
    {
      key: 'column5',
      name: t('documents.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'grandTotal' ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        console.log('Sort grandTotal!');
        setPrimarySort('grandTotal');
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        // handleSort('grandTotal', !isSortedDescendingGrandTotal);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        
        

        sessionStorage.setItem('accRec-primarySort', 'grandTotal');
        sessionStorage.setItem('accRec-sort-grandTotal', !isSortedDescendingGrandTotal === true ? 'true' : 'false');

        sessionStorage.setItem('accRec-sort-createdTime', 'false');
        sessionStorage.setItem('accRec-sort-number', 'false');
        sessionStorage.setItem('accRec-sort-documentDate', 'false');
        sessionStorage.setItem('accRec-sort-customerName', 'false');
        sessionStorage.setItem('accRec-sort-customerPhone', 'false');
        sessionStorage.setItem('accRec-sort-vatTotal', 'false');
        sessionStorage.setItem('accRec-sort-name', 'false');
        sessionStorage.setItem('accRec-sort-emailTime', 'false');
        sessionStorage.setItem('accRec-sort-emailReceiver', 'false');

        if (!isSortedDescendingGrandTotal) {
            setSortDirection('DESC');
            sessionStorage.setItem('accRec-sortDirection', 'DESC');
        } else {
            setSortDirection('ASC');
            sessionStorage.setItem('accRec-sortDirection', 'ASC');
        }

      },
      data: 'number',
      onRender: (item) => {
        let _styleProp = {};

        if (item.documentStatus === 'open') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'sent') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'partial-paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'fail') {
          _styleProp = { color: 'red' };
        }

        return (
          <span style={{ display: 'block', textAlign: 'right', ..._styleProp }}>
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
    },
    {
      key: 'column6',
      name: t('documents.document'),
      fieldName: 'name',
      minWidth: 160,
      maxWidth: 180,
      isCollapsible: true,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'name' ? true : false,
      isSortedDescending: isSortedDescendingName,
      isSortedAscending: !isSortedDescendingName,
      onColumnClick: () => {
        console.log('Sort name!');
        setPrimarySort('name');
        setIsSortedDescendingName(!isSortedDescendingName);

        // handleSort('name', !isSortedDescendingName);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        
        

        sessionStorage.setItem('accRec-primarySort', 'name');
        sessionStorage.setItem('accRec-sort-name', !isSortedDescendingName === true ? 'true' : 'false');

        sessionStorage.setItem('accRec-sort-createdTime', 'false');
        sessionStorage.setItem('accRec-sort-number', 'false');
        sessionStorage.setItem('accRec-sort-documentDate', 'false');
        sessionStorage.setItem('accRec-sort-customerName', 'false');
        sessionStorage.setItem('accRec-sort-customerPhone', 'false');
        sessionStorage.setItem('accRec-sort-vatTotal', 'false');
        sessionStorage.setItem('accRec-sort-grandTotal', 'false');
        sessionStorage.setItem('accRec-sort-emailTime', 'false');
        sessionStorage.setItem('accRec-sort-emailReceiver', 'false');

        if (!isSortedDescendingName) {
            setSortDirection('DESC');
            sessionStorage.setItem('accRec-sortDirection', 'DESC');
        } else {
            setSortDirection('ASC');
            sessionStorage.setItem('accRec-sortDirection', 'ASC');
        }

      },
      data: 'string',
      onRender: (item) => {
        let _styleProp = {};

        if (item.documentStatus === 'open') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'sent') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'partial-paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'fail') {
          _styleProp = { color: 'red' };
        }

        return (
          <ActionButton
            title={t('documents.openFile')}
            className={classNames.fileIconImg}
            onClick={() => {
              openFile(item);
            }}
          >
            <span style={_styleProp}>{item.name}</span>
          </ActionButton>
        );
      },
    },
    {
      key: 'column7',
      name: t('documents.email'),
      fieldName: 'emailReceiver',
      minWidth: 80,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'emailReceiver' ? true : false,
      isSortedDescending: isSortedDescendingEmailReceiver,
      isSortedAscending: !isSortedDescendingEmailReceiver,
      onColumnClick: () => {
        console.log('Sort emailReceiver!');
        setPrimarySort('emailReceiver');
        setIsSortedDescendingEmailReceiver(!isSortedDescendingEmailReceiver);

        // handleSort('emailReceiver', !isSortedDescendingEmailReceiver);

        setLoadDataComplete(false);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        
        

        sessionStorage.setItem('accRec-primarySort', 'emailReceiver');
        sessionStorage.setItem('accRec-sort-emailReceiver', !isSortedDescendingEmailReceiver === true ? 'true' : 'false');

        sessionStorage.setItem('accRec-sort-createdTime', 'false');
        sessionStorage.setItem('accRec-sort-number', 'false');
        sessionStorage.setItem('accRec-sort-customerName', 'false');
        sessionStorage.setItem('accRec-sort-documentDate', 'false');
        sessionStorage.setItem('accRec-sort-vatTotal', 'false');
        sessionStorage.setItem('accRec-sort-grandTotal', 'false');
        sessionStorage.setItem('accRec-sort-name', 'false');
        sessionStorage.setItem('accRec-sort-emailTime', 'false');

        if (!isSortedDescendingEmailReceiver) {
            setSortDirection('DESC');
            sessionStorage.setItem('accRec-sortDirection', 'DESC');
        } else {
            setSortDirection('ASC');
            sessionStorage.setItem('accRec-sortDirection', 'ASC');
        }

      },
      data: 'string',
      onRender: (item) => {
        let _styleProp = {};

        if (item.documentStatus === 'open') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'sent') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'partial-paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'fail') {
          _styleProp = { color: 'red' };
        }

        return <span style={_styleProp}>{item.emailReceiver}</span>;
      },
    },
    ...(enableSms
      ? [
          {
            key: 'column8',
            name: t('documents.sendingDate'),
            fieldName: 'smsTime',
            minWidth: 90,
            maxWidth: 125,
            isRowHeader: true,
            isResizable: true,
            isSorted: primarySort === 'emailTime' ? true : false,
            isSortedDescending: isSortedDescendingEmailTime,
            isSortedAscending: !isSortedDescendingEmailTime,
            onColumnClick: () => {
              console.log('Sort emailTime!');
              setPrimarySort('emailTime');
              setIsSortedDescendingEmailTime(!isSortedDescendingEmailTime);

            //   handleSort('emailTime', !isSortedDescendingEmailTime);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              
              

              sessionStorage.setItem('accRec-primarySort', 'emailTime');
              sessionStorage.setItem('accRec-sort-emailTime', !isSortedDescendingEmailTime === true ? 'true' : 'false');

              sessionStorage.setItem('accRec-sort-number', 'false');
              sessionStorage.setItem('accRec-sort-documentDate', 'false');
              sessionStorage.setItem('accRec-sort-customerName', 'false');
              sessionStorage.setItem('accRec-sort-customerPhone', 'false');
              sessionStorage.setItem('accRec-sort-vatTotal', 'false');
              sessionStorage.setItem('accRec-sort-grandTotal', 'false');
              sessionStorage.setItem('accRec-sort-name', 'false');
              sessionStorage.setItem('accRec-sort-emailReceiver', 'false');

              if (!isSortedDescendingEmailTime) {
                setSortDirection('DESC');
                sessionStorage.setItem('accRec-sortDirection', 'DESC');
              } else {
                setSortDirection('ASC');
                sessionStorage.setItem('accRec-sortDirection', 'ASC');
              }

            },
            data: 'string',
            onRender: (item) => {
              if (item.smsTime === 'successful') {
                return <span style={{ color: 'green' }}>{t('documents.success')}</span>;
              } else if (item.smsTime === 'Phone Number not found') {
                return <span style={{ color: 'red' }}>{t('documents.phoneNotFound')}</span>;
              } else if (item.smsTime === 'fail') {
                return <span style={{ color: 'red' }}>{t('documents.fail')}</span>;
              }
              if (isProcessing) {
                if (item.emailTime === 'processing') {
                  return <Spinner label={t('documents.sendingEmail')} labelPosition="right" style={{ marginLeft: '0px' }} />;
                } else if (item.emailTime === 'successful') {
                  return <span style={{ color: 'green' }}>{t('documents.success')}</span>;
                } else if (item.emailTime === 'email not found') {
                  return <span style={{ color: 'red' }}>{t('documents.emailNotFound')}</span>;
                } else if (item.emailTime === 'fail') {
                  return <span style={{ color: 'red' }}>{t('documents.fail')}</span>;
                }
              } else if (item.emailTime === 'successful') {
                return <span style={{ color: 'green' }}>{t('documents.success')}</span>;
              } else if (item.emailTime === 'email not found') {
                return <span style={{ color: 'red' }}>{t('documents.emailNotFound')}</span>;
              } else if (item.emailTime === 'fail') {
                return <span style={{ color: 'red' }}>{t('documents.fail')}</span>;
              }
              return (
                <Stack horizontal>
                  {!['successful', 'email not found', 'fail', ''].includes(item.emailTime) && (
                    <TooltipHost content={`วันที่ส่ง Email: ${item.emailTime}`}>
                      <Icon iconName="MailForward" style={{ fontSize: '25px', color: '#0078D4', marginRight: 10, marginTop: -3 }} />
                    </TooltipHost>
                  )}
                  {!['successful', 'Phone Number not found', 'fail', ''].includes(item.smsTime) && (
                    <TooltipHost content={`วันที่ส่ง SMS: ${item.smsTime}`}>
                      <Icon iconName="Message" style={{ fontSize: '20px', color: '#0078D4' }} />
                    </TooltipHost>
                  )}
                </Stack>
              );
            },
          },
        ]
      : [
          {
            key: 'column8',
            name: t('documents.emailDate'),
            fieldName: 'emailTime',
            minWidth: 90,
            maxWidth: 125,
            isRowHeader: true,
            isResizable: true,
            isSorted: primarySort === 'emailTime' ? true : false,
            isSortedDescending: isSortedDescendingEmailTime,
            isSortedAscending: !isSortedDescendingEmailTime,
            onColumnClick: () => {
              console.log('Sort emailTime!');
              setPrimarySort('emailTime');
              setIsSortedDescendingEmailTime(!isSortedDescendingEmailTime);

            //   handleSort('emailTime', !isSortedDescendingEmailTime);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              
              

              sessionStorage.setItem('accRec-primarySort', 'emailTime');
              sessionStorage.setItem('accRec-sort-emailTime', !isSortedDescendingEmailTime === true ? 'true' : 'false');

              sessionStorage.setItem('accRec-sort-number', 'false');
              sessionStorage.setItem('accRec-sort-documentDate', 'false');
              sessionStorage.setItem('accRec-sort-customerName', 'false');
              sessionStorage.setItem('accRec-sort-customerPhone', 'false');
              sessionStorage.setItem('accRec-sort-vatTotal', 'false');
              sessionStorage.setItem('accRec-sort-grandTotal', 'false');
              sessionStorage.setItem('accRec-sort-name', 'false');
              sessionStorage.setItem('accRec-sort-emailReceiver', 'false');

              if (!isSortedDescendingEmailTime) {
                setSortDirection('DESC');
                sessionStorage.setItem('accRec-sortDirection', 'DESC');
              } else {
                setSortDirection('ASC');
                sessionStorage.setItem('accRec-sortDirection', 'ASC');
              }

            },
            data: 'string',
            onRender: (item) => {
              let _styleProp = {};

              if (item.documentStatus === 'open') {
                _styleProp = { color: '#106EBE' };
              }
              if (item.documentStatus === 'sent') {
                _styleProp = { color: '#106EBE' };
              }
              if (item.documentStatus === 'partial-paid') {
                _styleProp = { color: 'green' };
              }
              if (item.documentStatus === 'paid') {
                _styleProp = { color: 'green' };
              }
              if (item.documentStatus === 'fail') {
                _styleProp = { color: 'red' };
              }

              if (item.emailTime === 'processing') {
                return <Spinner label={t('documents.sendingEmail')} labelPosition="right" style={{ marginLeft: '0px' }} />;
              } else if (item.emailTime === 'successful') {
                return <span style={{ color: 'green' }}>{t('documents.success')}</span>;
              } else if (item.emailTime === 'email not found') {
                return <span style={{ color: 'red' }}>{t('documents.emailNotFound')}</span>;
              } else if (item.emailTime === 'fail') {
                return <span style={{ color: 'red' }}>{t('documents.fail')}</span>;
              } else {
                return <span style={_styleProp}>{item.emailTime}</span>;
              }
            },
          },
        ]),

    {
      key: 'column9',
      name: t('documents.recordNoteHeader'),
      fieldName: 'note',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        let _styleProp = {};

        if (item.documentStatus === 'open') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'sent') {
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'partial-paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'paid') {
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'fail') {
          _styleProp = { color: 'red' };
        }

        if (!item.noteList.length) return '';
        return (
          <HoverCard
            instantOpenOnClick
            type={HoverCardType.plain}
            plainCardProps={{
              onRenderPlainCard: () => (
                <Stack styles={{ root: { padding: 16, width: 300 } }} tokens={{ childrenGap: 8 }}>
                  {item.noteList.map((note, index) => (
                    <div key={`note-${index}`} style={{ border: '1px solid rgba(0,0,0,0.125)', borderRadius: 2, padding: 20 }}>
                      <Stack tokens={{ childrenGap: 16 }}>
                        <Stack /* styles={{ root: { padding: '10px 16px 10px 16px' } }} */>
                          <Text>{note.message}</Text>
                        </Stack>
                        <Stack horizontal>
                          {/* <Stack styles={{ root: { justifyContent: 'center' } }}>
                            <Icon iconName="LocationDot" styles={{ root: { color: index === 0 ? 'rgb(56, 140, 0)' : 0 } }} />
                          </Stack> */}
                          <Text
                            styles={{ root: { color: 'rgb(173, 181, 189)' } }} /* styles={{ root: { padding: '0px 0px 0px 2px' } }} variant="large" */
                          >
                            {i18n.language === 'th' ? note.dateBE : note.dateCE}
                          </Text>
                        </Stack>
                      </Stack>
                    </div>
                  ))}
                </Stack>
              ),
            }}
          >
            <Text styles={{ root: _styleProp }}>{item.noteList[0].message}</Text>
          </HoverCard>
        );
      },
    },
    {
      key: 'column10',
      name: t('documents.status'),
      fieldName: 'documentStatus',
      minWidth: 80,
      maxWidth: 80,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        let _docStatusText = t('documents.draftStatus');
        let _styleProp = {};

        if (item.documentStatus === 'draft') _docStatusText = t('documents.draftStatus');
        if (item.documentStatus === 'open') {
          _docStatusText = t('documents.openStatus');
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'sent') {
          _docStatusText = t('documents.sentStatus');
          _styleProp = { color: '#106EBE' };
        }
        if (item.documentStatus === 'partial-paid') {
          _docStatusText = t('documents.partialPaidStatus');
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'paid') {
          _docStatusText = t('documents.paidStatus');
          _styleProp = { color: 'green' };
        }
        if (item.documentStatus === 'void') {
          _docStatusText = t('documents.voidStatus');
          // _styleProp = { color: 'gray' };
        }
        if (item.documentStatus === 'uncollectible') {
          _docStatusText = t('documents.uncollectibleStatus');
          // _styleProp = { color: 'gray' };
        }
        if (item.documentStatus === 'fail') {
          _docStatusText = t('documents.fail');
          _styleProp = { color: 'red' };
        }

        if (item.documentStatus === 'processing') {
          return <Spinner label={t('documents.processing')} labelPosition="right" style={{ marginLeft: '0px' }} />;
        }
        return <span style={_styleProp}>{_docStatusText}</span>;
      },
    },
    {
      key: 'column11',
      name: '',
      fieldName: 'command',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        const options_etax = [
          {
            key: 'open',
            text: t('documents.openDocument'),
            iconProps: { iconName: 'OpenFile' },
            onClick: () => {
              openFile_pdf(item);
            },
          },
          {
            key: 'download',
            text: t('documents.download'),
            iconProps: { iconName: 'DownloadDocument' },
            onClick: () => {
              download(item);
            },
          },

          {
            key: 'divider_1',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'status',
            text: t('documents.documentStatus'),
            iconProps: { iconName: 'IssueTracking' },
            subMenuProps: {
              items: [
                {
                  key: 'draft',
                  text: t('documents.draftStatus'),
                  itemProps: { styles: { checkmarkIcon: { color: '#0078D4' } } },
                  canCheck: true,
                  isChecked: item.documentStatus === 'draft' || item.documentStatus === 'new',
                  onClick: () => handleChangeDocumentStatus(item.key, item.name, item.number, 'draft', t('documents.draftStatus')),
                },
                {
                  key: 'open',
                  text: t('documents.openStatus'),
                  itemProps: { styles: { checkmarkIcon: { color: '#0078D4' } } },
                  canCheck: true,
                  isChecked: item.documentStatus === 'open',
                  onClick: () => handleChangeDocumentStatus(item.key, item.name, item.number, 'open', t('documents.openStatus')),
                },
                {
                  key: 'sent',
                  text: t('documents.sentStatus'),
                  itemProps: { styles: { checkmarkIcon: { color: '#0078D4' } } },
                  canCheck: true,
                  isChecked: item.documentStatus === 'sent',
                  onClick: () => handleChangeDocumentStatus(item.key, item.name, item.number, 'sent', t('documents.sentStatus')),
                },
                {
                  key: 'partial-paid',
                  text: t('documents.partialPaidStatus'),
                  itemProps: { styles: { checkmarkIcon: { color: '#0078D4' } } },
                  canCheck: true,
                  isChecked: item.documentStatus === 'partial-paid',
                  onClick: () => handleChangeDocumentStatus(item.key, item.name, item.number, 'partial-paid', t('documents.partialPaidStatus')),
                },
                {
                  key: 'paid',
                  text: t('documents.paidStatus'),
                  itemProps: { styles: { checkmarkIcon: { color: '#0078D4' } } },
                  canCheck: true,
                  isChecked: item.documentStatus === 'paid',
                  onClick: () => handleChangeDocumentStatus(item.key, item.name, item.number, 'paid', t('documents.paidStatus')),
                },
                {
                  key: 'void',
                  text: t('documents.voidStatus'),
                  itemProps: { styles: { checkmarkIcon: { color: '#0078D4' } } },
                  canCheck: true,
                  isChecked: item.documentStatus === 'void',
                  onClick: () => handleChangeDocumentStatus(item.key, item.name, item.number, 'void', t('documents.voidStatus')),
                },
                {
                  key: 'uncollectible',
                  text: t('documents.uncollectibleStatus'),
                  itemProps: { styles: { checkmarkIcon: { color: '#0078D4' } } },
                  canCheck: true,
                  isChecked: item.documentStatus === 'uncollectible',
                  onClick: () => handleChangeDocumentStatus(item.key, item.name, item.number, 'uncollectible', t('documents.uncollectibleStatus')),
                },
              ],
            },
          },
          {
            key: 'recordPayment',
            text: t('documents.recordPaymentDialogHeader'),
            iconProps: { iconName: 'PaymentCard' },
            onClick: () =>
              handleRecordPayment(
                item.key,
                item.number,
                item.includeVat ? item.grandTotal : item.amountTotal,
                item.accRecPaymentList,
                item.accRecWithholdingTax,
                item.whtTotal,
                item.whtRate,
                item.includeVat,
                item.vatTotal
              ),
          },
          {
            key: 'recordNote',
            text: t('documents.recordNoteHeader'),
            iconProps: { iconName: 'AddNotes' },
            onClick: () =>
              setRecordNote((prev) => ({
                ...prev,
                isClick: true,
                documentId: item.key,
                noteList: item.noteList.length
                  ? item.noteList
                  : [{ date: new Date(), dateBE: onFormatDate(new Date(), 'BE'), dateCE: onFormatDate(new Date(), 'CE'), message: '' }],
              })),
          },
          {
            key: 'divider_2',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'email',
            text: t('documents.email'),
            iconProps: { iconName: 'MailForward' },
            onClick: async () => {
              console.log('isEmailSetting:', isEmailSetting);

              if (isEmailSetting) {
                setIsClickEmail(true);
                setFileNameEmail(item.name);
                setFileDisplayName(item.pdfFileName);
                setCustomerEmail(item.customerEmail);
                setEmailList(item.customerEmail ? [item.customerEmail] : []);
                setCopyEmailList(item.companyEmail ? [item.companyEmail] : [])
                setCopyEmail(item.companyEmail);
                setFileIdEmail(item.key);
              } else {
                history.push({
                  pathname: '/settings/emails',
                  state: {},
                });
              }
            },
          },
          ...(enableSms ? [{
            key: 'sms',
            text: 'SMS',
            iconProps: { iconName: 'Message' },
            onClick: () => {
              setIsClickSms(true);
              setFileNameSms(item.name);
              setCustomerPhone(item.customerPhone);
              setFileIdSms(item.key);
            },
          },] : []),          
          {
            key: 'divider_3',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'reCreate',
            text: t('documents.reCreateDocument'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument(item.type, item);
            },
          },
          {
            key: 'reIssue',
            text: t('documents.reIssuedDocument'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reIssueDocument(item);
            },
          },
          {
            key: 'RECEIPT-TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.receipt-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('RECEIPT-TAXINVOICE', item);
            },
          },
          {
            key: 'RECEIPT-TAXINVOICE-ABB',
            text: t('documents.create') + ' ' + t('documents.receipt-taxinvoice-abb'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('RECEIPT-TAXINVOICE-ABB', item);
            },
          },
          {
            key: 'RECEIPT',
            text: t('documents.create') + ' ' + t('documents.receipt'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('RECEIPT', item);
            },
          },
          {
            key: 'INVOICE-TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.invoice-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('INVOICE-TAXINVOICE', item);
            },
          },
          {
            key: 'DELIVERYORDER-TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.deliveryorder-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('DELIVERYORDER-TAXINVOICE', item);
            },
          },
          {
            key: 'TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('TAXINVOICE', item);
            },
          },
          {
            key: 'DEBIT-NOTE',
            text: t('documents.create') + ' ' + t('documents.debit-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateCreditAndDebit('DEBIT-NOTE', item);
            },
          },
          {
            key: 'CREDIT-NOTE',
            text: t('documents.create') + ' ' + t('documents.credit-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateCreditAndDebit('CREDIT-NOTE', item);
            },
          },
          {
            key: 'divider_4',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'QUOTATION',
            text: t('documents.create') + ' ' + t('documents.quotation'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('QUOTATION', item);
            },
          },
          {
            key: 'INVOICE',
            text: t('documents.create') + ' ' + t('documents.invoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('INVOICE', item);
            },
          },
          {
            key: 'BILLING-NOTE',
            text: t('documents.create') + ' ' + t('documents.billing-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('BILLING-NOTE', item);
            },
          },
          {
            key: 'DELIVERYORDER',
            text: t('documents.create') + ' ' + t('documents.deliveryorder'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('DELIVERYORDER', item);
            },
          },
          {
            key: 'divider_5',
            itemType: ContextualMenuItemType.Divider,
          },
          item.rdStatus !== 'successful'
            ? {
                key: 'delete',
                text: t('documents.delete'),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'RECEIPT-TAXINVOICE') _itemType = 'Receipt/Tax Invoice';
                    if (item.type === 'RECEIPT-TAXINVOICE-ABB') _itemType = 'Receipt/Tax Invoice Abb';
                    if (item.type === 'RECEIPT') _itemType = 'Receipt';
                    if (item.type === 'INVOICE-TAXINVOICE') _itemType = 'Invoice/Tax Invoice';
                    if (item.type === 'DELIVERYORDER-TAXINVOICE') _itemType = 'Delivery Order/Tax Invoice';
                    if (item.type === 'TAXINVOICE') _itemType = 'Tax Invoice';
                    if (item.type === 'DEBIT-NOTE') _itemType = 'Debit Note';
                    if (item.type === 'CREDIT-NOTE') _itemType = 'Credit Note';
                    if (item.type === 'QUOTATION') _itemType = 'Quotation';
                    if (item.type === 'INVOICE') _itemType = 'Invoice';
                    if (item.type === 'BILLING-NOTE') _itemType = 'Billing Note';
                    if (item.type === 'DELIVERYORDER') _itemType = 'Delivery Order';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: `${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3'
                    )}`,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              }
            : {
                key: 'delete',
                text: t('documents.deleteDisable'),
                iconProps: { iconName: 'Delete' },
                disabled: true,
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'RECEIPT-TAXINVOICE') _itemType = 'Receipt/Tax Invoice';
                    if (item.type === 'RECEIPT-TAXINVOICE-ABB') _itemType = 'Receipt/Tax Invoice Abb';
                    if (item.type === 'RECEIPT') _itemType = 'Receipt';
                    if (item.type === 'INVOICE-TAXINVOICE') _itemType = 'Invoice/Tax Invoice';
                    if (item.type === 'DELIVERYORDER-TAXINVOICE') _itemType = 'Delivery Order/Tax Invoice';
                    if (item.type === 'TAXINVOICE') _itemType = 'Tax Invoice';
                    if (item.type === 'DEBIT-NOTE') _itemType = 'Debit Note';
                    if (item.type === 'CREDIT-NOTE') _itemType = 'Credit Note';
                    if (item.type === 'QUOTATION') _itemType = 'Quotation';
                    if (item.type === 'INVOICE') _itemType = 'Invoice';
                    if (item.type === 'BILLING-NOTE') _itemType = 'Billing Note';
                    if (item.type === 'DELIVERYORDER') _itemType = 'Delivery Order';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: `${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3'
                    )}`,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              },
        ];

        const options_document = [
          {
            key: 'open',
            text: t('documents.openDocument'),
            iconProps: { iconName: 'OpenFile' },
            onClick: () => {
              openFile_pdf(item);
            },
          },
          {
            key: 'download',
            text: t('documents.download'),
            iconProps: { iconName: 'DownloadDocument' },
            onClick: () => {
              download(item);
            },
          },

          {
            key: 'divider_1',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'status',
            text: t('documents.documentStatus'),
            iconProps: { iconName: 'IssueTracking' },
            subMenuProps: {
              items: [
                {
                  key: 'draft',
                  text: t('documents.draftStatus'),
                  itemProps: { styles: { checkmarkIcon: { color: '#0078D4' } } },
                  canCheck: true,
                  isChecked: item.documentStatus === 'draft' || item.documentStatus === 'new',
                  onClick: () => handleChangeDocumentStatus(item.key, item.name, item.number, 'draft', t('documents.draftStatus')),
                },
                {
                  key: 'open',
                  text: t('documents.openStatus'),
                  itemProps: { styles: { checkmarkIcon: { color: '#0078D4' } } },
                  canCheck: true,
                  isChecked: item.documentStatus === 'open',
                  onClick: () => handleChangeDocumentStatus(item.key, item.name, item.number, 'open', t('documents.openStatus')),
                },
                {
                  key: 'sent',
                  text: t('documents.sentStatus'),
                  itemProps: { styles: { checkmarkIcon: { color: '#0078D4' } } },
                  canCheck: true,
                  isChecked: item.documentStatus === 'sent',
                  onClick: () => handleChangeDocumentStatus(item.key, item.name, item.number, 'sent', t('documents.sentStatus')),
                },
                {
                  key: 'partial-paid',
                  text: t('documents.partialPaidStatus'),
                  itemProps: { styles: { checkmarkIcon: { color: '#0078D4' } } },
                  canCheck: true,
                  isChecked: item.documentStatus === 'partial-paid',
                  onClick: () => handleChangeDocumentStatus(item.key, item.name, item.number, 'partial-paid', t('documents.partialPaidStatus')),
                },
                {
                  key: 'paid',
                  text: t('documents.paidStatus'),
                  itemProps: { styles: { checkmarkIcon: { color: '#0078D4' } } },
                  canCheck: true,
                  isChecked: item.documentStatus === 'paid',
                  onClick: () => handleChangeDocumentStatus(item.key, item.name, item.number, 'paid', t('documents.paidStatus')),
                },
                {
                  key: 'void',
                  text: t('documents.voidStatus'),
                  itemProps: { styles: { checkmarkIcon: { color: '#0078D4' } } },
                  canCheck: true,
                  isChecked: item.documentStatus === 'void',
                  onClick: () => handleChangeDocumentStatus(item.key, item.name, item.number, 'void', t('documents.voidStatus')),
                },
                {
                  key: 'uncollectible',
                  text: t('documents.uncollectibleStatus'),
                  itemProps: { styles: { checkmarkIcon: { color: '#0078D4' } } },
                  canCheck: true,
                  isChecked: item.documentStatus === 'uncollectible',
                  onClick: () => handleChangeDocumentStatus(item.key, item.name, item.number, 'uncollectible', t('documents.uncollectibleStatus')),
                },
              ],
            },
          },
          {
            key: 'recordPayment',
            text: t('documents.recordPaymentDialogHeader'),
            iconProps: { iconName: 'PaymentCard' },
            onClick: () =>
              handleRecordPayment(
                item.key,
                item.number,
                item.includeVat ? item.grandTotal : item.amountTotal,
                item.accRecPaymentList,
                item.accRecWithholdingTax,
                item.whtTotal,
                item.whtRate,
                item.includeVat,
                item.vatTotal
              ),
          },
          {
            key: 'recordNote',
            text: t('documents.recordNoteHeader'),
            iconProps: { iconName: 'AddNotes' },
            onClick: () =>
              setRecordNote((prev) => ({
                ...prev,
                isClick: true,
                documentId: item.key,
                noteList: item.noteList.length
                  ? item.noteList
                  : [{ date: new Date(), dateBE: onFormatDate(new Date(), 'BE'), dateCE: onFormatDate(new Date(), 'CE'), message: '' }],
              })),
          },
          {
            key: 'divider_2',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'email',
            text: t('documents.email'),
            iconProps: { iconName: 'MailForward' },
            onClick: async () => {
              console.log('isEmailSetting:', isEmailSetting);

              if (isEmailSetting) {
                setIsClickEmail(true);
                setFileNameEmail(item.name);
                setFileDisplayName(item.pdfFileName);
                setCustomerEmail(item.customerEmail);
                setEmailList(item.customerEmail ? [item.customerEmail] : []);
                setCopyEmailList(item.companyEmail ? [item.companyEmail] : [])
                setCopyEmail(item.companyEmail);
                setFileIdEmail(item.key);
              } else {
                history.push({
                  pathname: '/settings/emails',
                  state: {},
                });
              }
            },
          },
          ...(enableSms ? [{
            key: 'sms',
            text: 'SMS',
            iconProps: { iconName: 'Message' },
            onClick: () => {
              setIsClickSms(true);
              setFileNameSms(item.name);
              setCustomerPhone(item.customerPhone);
              setFileIdSms(item.key);
            },
          },] : []),
          {
            key: 'divider_3',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'RECEIPT-TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.receipt-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('RECEIPT-TAXINVOICE', item);
            },
          },
          {
            key: 'RECEIPT-TAXINVOICE-ABB',
            text: t('documents.create') + ' ' + t('documents.receipt-taxinvoice-abb'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('RECEIPT-TAXINVOICE-ABB', item);
            },
          },
          {
            key: 'RECEIPT',
            text: t('documents.create') + ' ' + t('documents.receipt'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('RECEIPT', item);
            },
          },
          {
            key: 'INVOICE-TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.invoice-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('INVOICE-TAXINVOICE', item);
            },
          },
          {
            key: 'DELIVERYORDER-TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.deliveryorder-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('DELIVERYORDER-TAXINVOICE', item);
            },
          },
          {
            key: 'TAXINVOICE',
            text: t('documents.create') + ' ' + t('documents.taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('TAXINVOICE', item);
            },
          },
          {
            key: 'DEBIT-NOTE',
            text: t('documents.create') + ' ' + t('documents.debit-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateCreditAndDebit('DEBIT-NOTE', item);
            },
          },
          {
            key: 'CREDIT-NOTE',
            text: t('documents.create') + ' ' + t('documents.credit-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateCreditAndDebit('CREDIT-NOTE', item);
            },
          },
          {
            key: 'divider_4',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'reCreate',
            text: t('documents.reCreateDocument'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument(item.type, item);
            },
          },
          {
            key: 'QUOTATION',
            text: t('documents.create') + ' ' + t('documents.quotation'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('QUOTATION', item);
            },
          },
          {
            key: 'INVOICE',
            text: t('documents.create') + ' ' + t('documents.invoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('INVOICE', item);
            },
          },
          {
            key: 'BILLING-NOTE',
            text: t('documents.create') + ' ' + t('documents.billing-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('BILLING-NOTE', item);
            },
          },
          {
            key: 'DELIVERYORDER',
            text: t('documents.create') + ' ' + t('documents.deliveryorder'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateDocument('DELIVERYORDER', item);
            },
          },
          {
            key: 'divider_5',
            itemType: ContextualMenuItemType.Divider,
          },
          item.rdStatus !== 'successful'
            ? {
                key: 'delete',
                text: t('documents.delete'),
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'RECEIPT-TAXINVOICE') _itemType = 'Receipt/Tax Invoice';
                    if (item.type === 'RECEIPT-TAXINVOICE-ABB') _itemType = 'Receipt/Tax Invoice Abb';
                    if (item.type === 'RECEIPT') _itemType = 'Receipt';
                    if (item.type === 'INVOICE-TAXINVOICE') _itemType = 'Invoice/Tax Invoice';
                    if (item.type === 'DELIVERYORDER-TAXINVOICE') _itemType = 'Delivery Order/Tax Invoice';
                    if (item.type === 'TAXINVOICE') _itemType = 'Tax Invoice';
                    if (item.type === 'DEBIT-NOTE') _itemType = 'Debit Note';
                    if (item.type === 'CREDIT-NOTE') _itemType = 'Credit Note';
                    if (item.type === 'QUOTATION') _itemType = 'Quotation';
                    if (item.type === 'INVOICE') _itemType = 'Invoice';
                    if (item.type === 'BILLING-NOTE') _itemType = 'Billing Note';
                    if (item.type === 'DELIVERYORDER') _itemType = 'Delivery Order';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: `${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3'
                    )}`,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              }
            : {
                key: 'delete',
                text: t('documents.deleteDisable'),
                iconProps: { iconName: 'Delete' },
                disabled: true,
                onClick: () => {
                  let _itemType = item.name;
                  if (i18n.language === 'en') {
                    if (item.type === 'RECEIPT-TAXINVOICE') _itemType = 'Receipt/Tax Invoice';
                    if (item.type === 'RECEIPT-TAXINVOICE-ABB') _itemType = 'Receipt/Tax Invoice Abb';
                    if (item.type === 'RECEIPT') _itemType = 'Receipt';
                    if (item.type === 'INVOICE-TAXINVOICE') _itemType = 'Invoice/Tax Invoice';
                    if (item.type === 'DELIVERYORDER-TAXINVOICE') _itemType = 'Delivery Order/Tax Invoice';
                    if (item.type === 'TAXINVOICE') _itemType = 'Tax Invoice';
                    if (item.type === 'DEBIT-NOTE') _itemType = 'Debit Note';
                    if (item.type === 'CREDIT-NOTE') _itemType = 'Credit Note';
                    if (item.type === 'QUOTATION') _itemType = 'Quotation';
                    if (item.type === 'INVOICE') _itemType = 'Invoice';
                    if (item.type === 'BILLING-NOTE') _itemType = 'Billing Note';
                    if (item.type === 'DELIVERYORDER') _itemType = 'Delivery Order';
                  }

                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('documents.deleteConfirmHeader'),
                    message: `${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                      'documents.deleteConfirm3'
                    )}`,
                    onConfirm: () => {
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                      deleteFile(item);
                    },
                  }));
                },
              },
        ];

        return (
          <span data-selection-disabled={true}>
            <DefaultButton
              text={t('documents.select')}
              menuProps={{
                items:
                  item.type === 'INVOICE-TAXINVOICE'
                    ? options_etax.filter((option) => option.key !== item.type)
                    : options_document.filter((option) => option.key !== item.type),
                shouldFocusOnMount: true,
                directionalHintFixed: true,
              }}
              // Optional callback to customize menu rendering
              menuAs={(props) => <ContextualMenu {...props} />}
            />
          </span>
        );
      },
    },
  ];

  const isCompactMode = false;

  function TextFieldEmailComponent(props) {
    return (
      <TextFieldEmail 
        style={{ width: 240}}
        selectedItems={[...emailList.map(_email => (
          { key: _email, text: _email }
        ))]}
        onItemsRemoved={(removeItems, removeItemIndex) => {
          const currentSelectedItemsCopy = [...emailList];
          currentSelectedItemsCopy.splice(removeItemIndex, 1);
          // this.setState({ currentSelectedItems: [...currentSelectedItemsCopy] });
          setEmailList([...currentSelectedItemsCopy])
  
          let multiEmail = '';
  
          currentSelectedItemsCopy.forEach((email, index) => {
            multiEmail = multiEmail + email;
  
            if (currentSelectedItemsCopy.length - 1 !== index) {
              multiEmail = multiEmail + ';';
            }
          });
          setCustomerEmail(multiEmail);
        }}
        onBlur={(e) => {
          if (checkEmail(e.target.value) && !emailList.includes(e.target.value)) {
              const currentSelectedItemsCopy = [...emailList, e.target.value];
  
              setEmailList(currentSelectedItemsCopy)
  
              let multiEmail = '';
  
              currentSelectedItemsCopy.forEach((email, index) => {
                multiEmail = multiEmail + email;
  
                if (currentSelectedItemsCopy.length - 1 !== index) {
                  multiEmail = multiEmail + ';';
                }
              });
              setCustomerEmail(multiEmail);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
              if (checkEmail(e.target.value) && !emailList.includes(e.target.value)) {
                  const currentSelectedItemsCopy = [...emailList, e.target.value];
  
                  setEmailList(currentSelectedItemsCopy)
  
                  let multiEmail = '';
  
                  currentSelectedItemsCopy.forEach((email, index) => {
                    multiEmail = multiEmail + email;
  
                    if (currentSelectedItemsCopy.length - 1 !== index) {
                      multiEmail = multiEmail + ';';
                    }
                  });
                  setCustomerEmail(multiEmail);
  
              }
          }
        }}
      />
    );
  }

  function TextFieldCopyEmailComponent(props) {
    return (
      <TextFieldEmail 
        style={{ width: 240}}
        selectedItems={[...copyEmailList.map(_email => (
          { key: _email, text: _email }
        ))]}
        onItemsRemoved={(removeItems, removeItemIndex) => {
          const currentSelectedItemsCopy = [...copyEmailList];
          currentSelectedItemsCopy.splice(removeItemIndex, 1);
          // this.setState({ currentSelectedItems: [...currentSelectedItemsCopy] });
          setCopyEmailList([...currentSelectedItemsCopy])

          let multiEmail = '';

          currentSelectedItemsCopy.forEach((email, index) => {
            multiEmail = multiEmail + email;

            if (currentSelectedItemsCopy.length - 1 !== index) {
              multiEmail = multiEmail + ';';
            }
          });
          setCopyEmail(multiEmail);
        }}
        onBlur={(e) => {
          if (checkEmail(e.target.value) && !copyEmailList.includes(e.target.value)) {
              const currentSelectedItemsCopy = [...copyEmailList, e.target.value];

              setCopyEmailList(currentSelectedItemsCopy)

              let multiEmail = '';

              currentSelectedItemsCopy.forEach((email, index) => {
                multiEmail = multiEmail + email;

                if (currentSelectedItemsCopy.length - 1 !== index) {
                  multiEmail = multiEmail + ';';
                }
              });
              setCopyEmail(multiEmail);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
              if (checkEmail(e.target.value) && !copyEmailList.includes(e.target.value)) {
                  const currentSelectedItemsCopy = [...copyEmailList, e.target.value];

                  setCopyEmailList(currentSelectedItemsCopy)

                  let multiEmail = '';

                  currentSelectedItemsCopy.forEach((email, index) => {
                    multiEmail = multiEmail + email;

                    if (currentSelectedItemsCopy.length - 1 !== index) {
                      multiEmail = multiEmail + ';';
                    }
                  });
                  setCopyEmail(multiEmail);

              }
          }
        }}
      />
    );
  }

  return (
    <>
      <div style={homeStyles}>
        {/* 
        {showCertificateSetup && (
          <MessageBar
            onDismiss={() => {
              setShowCertificateSetup(false);
            }}
            messageBarType={MessageBarType.warning}
            dismissButtonAriaLabel={t('documents.close')}
            isMultiline={false}
            style={{
              fontSize: 14,
            }}
          >
            {t('documents.warningSetupCertificate')}
            {'  '}
            <Link
              onClick={() => {
                history.push({
                  pathname: '/etax/certificates',
                  state: {},
                });
              }}
              underline
            >
              {t('documents.warningSetupCertificateLink')}
            </Link>
          </MessageBar>
        )}
        {showXmlDeliverSetup ? (
          <MessageBar
            onDismiss={() => {
              setShowXmlDeliverSetup(false);
            }}
            messageBarType={MessageBarType.warning}
            dismissButtonAriaLabel={t('documents.close')}
            isMultiline={false}
            style={{
              fontSize: 14,
            }}
          >
            <Link
              onClick={() => {
                history.push({
                  pathname: '/etax/xml/deliver',
                  state: {},
                });
              }}
              underline
            >
              ตั้งค่าการนำส่งไฟล์ XML อัตโนมัติ
            </Link>{' '}
            เพื่อนำส่งไฟล์ XML เข้าระบบ e-Tax Invoice & e-Receipt แบบอัตโนมัติ
          </MessageBar>
        ) : (
          showXmlDeliver && (
            <MessageBar
              onDismiss={() => {
                setShowXmlDeliver(false);
              }}
              messageBarType={MessageBarType.warning}
              dismissButtonAriaLabel={t('documents.close')}
              isMultiline={false}
              style={{
                fontSize: 14,
              }}
            >
              {t('documents.warningXmlDelivery')}
            </MessageBar>
          )
        )}
 */}
        {showEmailSuccess && (
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => setShowEmailSuccess(false)}
            dismissButtonAriaLabel={t('documents.close')}
            style={{
              fontSize: 14,
            }}
          >
            {t('documents.sendEmailSuccess')}
          </MessageBar>
        )}
        {showEmailError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setShowEmailError(false)}
            dismissButtonAriaLabel={t('documents.close')}
            style={{
              fontSize: 14,
            }}
          >
            {t('documents.sendEmailFail')}
          </MessageBar>
        )}

        {showSmsSuccess && (
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => setShowSmsSuccess(false)}
            dismissButtonAriaLabel={t('documents.close')}
            style={{
              fontSize: 14,
            }}
          >
            {t('documents.sendSmsSuccess')}
          </MessageBar>
        )}

        {showSmsError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setShowSmsError(false)}
            dismissButtonAriaLabel={t('documents.close')}
            style={{
              fontSize: 14,
            }}
          >
            {t('documents.sendSmsFail')}
          </MessageBar>
        )}

        {(showEmailSuccess || showEmailError || showSmsSuccess || showSmsError) && <br />}
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('accountReceivable.header')}</h2>
          </Stack>
          {enableTopup && (
            <Stack
              horizontal
              horizontalAlign="center"
              style={{
                marginTop: '-15px',
              }}
            >
              <ActionButton
                iconProps={{
                  iconName: 'QRCode',
                  style: {
                    fontSize: 22,
                    marginTop: '-5px',
                  },
                }}
                onClick={() => {
                  history.push({
                    pathname: '/billings',
                    state: {},
                  });
                }}
              >
                <span
                  style={{
                    fontSize: 16,
                  }}
                >
                  {t('documents.instantTopup')}
                </span>
              </ActionButton>
            </Stack>
          )}
          <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
            <DocumentCard aria-label={t('documents.totalDocuments')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Documentation' }} title={t('documents.totalDocuments')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('documents.totalDocuments')} styles={cardStyles.header} />
                <Text styles={cardStyles.amount}>
                  <CountUp end={totalDocuments} separator="," duration={1} /> {t('documents.documents')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>

            <DocumentCard aria-label={t('documents.notDeposit')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Money' }} title={t('documents.notDeposit')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('documents.notDeposit')} styles={cardStyles.header} />

                <Text styles={cardStyles.amount}>
                  <CountUp end={totalAccruedIncome} separator="," decimals={2} decimal="." duration={1} /> {t('documents.thb')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>

            <DocumentCard aria-label={t('documents.deposit')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Money' }} title={t('documents.deposit')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('documents.deposit')} styles={cardStyles.header} />
                <Text styles={cardStyles.amount}>
                  <CountUp end={totalPaid} separator="," decimals={2} decimal="." duration={1} /> {t('documents.thb')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>
          </Stack>
        </Stack>

        {totalProcessingDocuments !== '' && totalProcessingDocuments > 0 && (
          <div>
            <br />
            <Spinner label={t('documents.signProcessing')} size={SpinnerSize.medium} labelPosition="left" />
          </div>
        )}
        <br />
        <Stack horizontal horizontalAlign="center">
          <Stack
            vertical
            style={{
              width: '98%',
              minWidth: '800px',
              maxWidth: '1350px',
            }}
          >
            <Stack 
              horizontal 
              // className="ms-bgColor-white"
              horizontalAlign="space-between"
              styles={{
                root: {
                  backgroundColor: 'white',
                },
              }}
            >
              <Stack
                styles={{
                  root: {
                    width: 'calc(100vw - 700px)',
                    minWidth: '700px',
                    maxWidth: '900px',
                  },
                }}
              >
                <CommandBar items={commandBarItems} />
              </Stack>

              <Stack
                horizontal
                styles={{
                  root: {
                    height: 44,
                  },
                }}
                tokens={{ childrenGap: '10px' }}
              >
                <SearchBox
                  // className="ms-borderColor-themePrimary"
                  styles={{
                    root: {
                      marginTop: 6,
                      marginLeft: 6,
                      width: 200,
                      fontSize: 13,
                      fontWeight: 0,
                    },
                  }}
                  disabled={!loadDataComplete}
                  placeholder={t('documents.searchPlaceholder')}
                  onChange={(e) => setSearch(e ? e.target.value.replace(/\s/g, '') : '')}
                  onBlur={(e) => {
                    if (!e.target.value && previousSearch) {
                      setLoadDataComplete(false);
                      setItems([]);
                      setGetNewDocuments(true);
                      setPreviousSearch('');

                      setCurrentPage(1);

                      setTotalDocuments(0);
                      setTotalAccruedIncome(0);
                      
                      
                      setTotalPaid(0);

                      resetSort();
                    }
                  }}
                  onClear={() => {
                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);
                    setPreviousSearch('');

                    setCurrentPage(1);

                    setTotalDocuments(0);
                    setTotalAccruedIncome(0);
                    
                    
                    setTotalPaid(0);

                    resetSort();
                  }}
                  onSearch={() => {
                    setCurrentPage(1);
                    setPreviousSearch(search);
                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);

                    setTotalDocuments(0);
                    setTotalAccruedIncome(0);
                    
                    
                    setTotalPaid(0);

                    resetSort();
                  }}
                  value={search}
                />
                <PrimaryButton
                  disabled={!loadDataComplete || !search}
                  styles={{
                    root: {
                      marginTop: 6,
                      marginRight: 8,
                      width: 90,
                    },
                  }}
                  text={t('documents.search')}
                  onClick={() => {
                    setCurrentPage(1);
                    setPreviousSearch(search);
                    setLoadDataComplete(false);
                    setItems([]);
                    setGetNewDocuments(true);

                    setTotalDocuments(0);
                    setTotalAccruedIncome(0);
                    
                    
                    setTotalPaid(0);

                    resetSort();
                  }}
                />
              </Stack>
            </Stack>
            {items.length ? (
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selection={selection}
                selectionMode={!loadDataComplete || isProcessing ? SelectionMode.none : SelectionMode.multiple}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
                // onRenderRow={(props) => {
                //   if (props) {
                //     const _styles = {};
                //     if (props.item.documentStatus === 'paid' || props.item.documentStatus === 'partial-paid') {
                //       _styles.cell = {
                //         color: 'green',
                //       };
                //     } else if (props.item.documentStatus === 'sent' || props.item.documentStatus === 'open') {
                //       // _styles.root = {
                //       //   backgroundColor: '#cfe2f3',
                //       //   selectors: {
                //       //     ':hover': {
                //       //       background: '#9fc5e8',
                //       //     },
                //       //   },
                //       // };
                //       _styles.cell = {
                //         color: '#106EBE',
                //       };
                //     } else if (props.item.documentStatus === 'fail') {
                //       // _styles.root = {
                //       //   backgroundColor: '#f4cccc',
                //       //   selectors: {
                //       //     ':hover': {
                //       //       background: '#ea9999',
                //       //     },
                //       //   },
                //       // };
                //       _styles.cell = {
                //         color: 'red',
                //       };
                //     }

                //     return <DetailsRow {...props} styles={_styles} />;
                //   }
                //   return null;
                // }}
              />
            ) : (
              <ShimmeredDetailsList
                items={[{}]}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selection={selection}
                selectionMode={!loadDataComplete || isProcessing ? SelectionMode.none : SelectionMode.multiple}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
                onRenderRow={() => {
                  return (
                    <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: '#FFFFFF', height: 55 } }}>
                      <Text>{t('documents.noItemFound')}</Text>
                    </Stack>
                  );
                }}
              />
            )}
          </Stack>
        </Stack>
        <br />
        <center>
          {loadDataComplete && (
            <Pagination
              selectedPageIndex={currentPage - 1}
              pageCount={pageData.totalPages}
              onPageChange={onPageChange}
              format
              firstPageIconProps={{
                iconName: 'DoubleChevronLeft',
              }}
              previousPageIconProps={{
                iconName: 'ChevronLeft',
              }}
              nextPageIconProps={{ iconName: 'ChevronRight' }}
              lastPageIconProps={{
                iconName: 'DoubleChevronRight',
              }}
            />
          )}
          <br />
          <br />
        </center>

        <Dialog
          hidden={!isClickEmail}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: t('documents.sendFileByEmail'),
            subText: fileNameEmail,
          }}
        >
          <Stack vertical>
            <div>
              <label>{t('documents.sendToEmail')}</label>
            </div>
            <TextFieldEmailComponent />
            <br />
            <Checkbox
              label={t('documents.ccToEmail')}
              checked={isClickCopyEmail}
              onChange={(e) => {
                console.log('copy email checkbox: ', e.target.value);
                setIsClickCopyEmail((prev) => !prev);
              }}
            />
            {isClickCopyEmail && (
              <div style={{ marginTop: '8px' }}>
                {/* <TextField
                  value={copyEmail}
                  onChange={(e) => {
                    setCopyEmail(e.target.value);
                    setErrorMessageCopyEmail('');
                  }}
                  errorMessage={errorMessageCopyEmail}
                /> */}
                <TextFieldCopyEmailComponent />
              </div>
            )}
            <br />
            <br />
          </Stack>
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                if (isClickCopyEmail) {
                  sendEmail(fileDisplayName, fileIdEmail, customerEmail, copyEmail);
                } else {
                  sendEmail(fileDisplayName, fileIdEmail, customerEmail, '');
                }
              }}
              text={t('documents.sendEmail')}
            />
            <DefaultButton onClick={() => setIsClickEmail(false)} text={t('documents.cancel')} />
          </DialogFooter>
        </Dialog>

        <Dialog
          hidden={!isClickSms}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: t('documents.sendFileBySms'),
            subText: fileNameSms,
          }}
        >
          <TextField
            label={t('documents.mobileNumer')}
            value={customerPhone}
            required
            onChange={(e) => {
              setCustomerPhone(e.target.value);
              setErrorMessageSms('');
            }}
            errorMessage={errorMessageSms}
            description={t('documents.exampleNumber')}
          />
          <br />
          <br />
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                sendSms(fileIdSms, customerPhone);
              }}
              text={t('documents.sendSms')}
            />
            <DefaultButton onClick={() => setIsClickSms(false)} text={t('documents.cancel')} />
          </DialogFooter>
        </Dialog>

        <Dialog
          hidden={!changeDocumentStatusPayload.isClick}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: t('documents.confirmChangeStatus'),
            subText: documentStatusSubText(),
          }}
        >
          <DialogFooter>
            <PrimaryButton onClick={() => changeDocumentStatus()} text={t('documents.confirm')} />
            <DefaultButton
              onClick={() =>
                setChangeDocumentStatusPayload((prev) => ({
                  ...prev,
                  isClick: false,
                  documentId: '',
                  type: '',
                  number: '',
                  statusKey: '',
                  statusText: '',
                  prevStatusKey: '',
                }))
              }
              text={t('documents.cancel')}
            />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!recordPayment.isClick}
          onDismiss={() =>
            setRecordPayment((prev) => ({
              ...prev,
              isClick: false,
            }))
          }
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: t('documents.recordPaymentDialogHeader') + ' #' + recordPayment.invoiceNumber,
            styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 } },
            // subText: documentStatusSubText(),
          }}
          modalProps={{
            styles: { main: { maxHeight: 680 } },
          }}
          minWidth={900}
          maxWidth={900}
          back
        >
          <Stack tokens={{ childrenGap: '20 0' /* padding: `${0}px ${20}px ${0}px ${20}px` */ }} styles={{ root: { paddingBottom: 10 } }}>
            {recordPayment.paymentList.map((_payment, index) => (
              <Stack key={`payment_${index}`}>
                <Stack horizontal tokens={{ childrenGap: '0 10' }}>
                  <Stack verticalAlign="end" horizontalAlign="center" styles={{ root: { width: '5%', paddingBottom: 4 } }}>
                    <Persona text={String(index + 1)} hidePersonaDetails={true} size={PersonaSize.size24} initialsColor={PersonaInitialsColor.blue} />
                  </Stack>
                  <Dropdown
                    id="paymentMethod"
                    label={t('documents.payment')}
                    options={[
                      { key: 'BANK', text: t('expenseNote.BANK') },
                      { key: 'CASH', text: t('expenseNote.CASH') },
                    ]}
                    styles={{
                      root: {
                        width: '20%',
                      },
                    }}
                    selectedKey={recordPayment.paymentList[index].paymentCode}
                    onChange={(e, selectedOption) => {
                      setShowErrorMessageRecordPayment(false);
                      handleSelectOption(index, e, selectedOption);
                    }}
                    disabled={loadingRecordPayment}
                    errorMessage={
                      showErrorMessageRecordPayment && !recordPayment.paymentList[index].paymentCode ? t('documents.pleaseSelect') : undefined
                    }
                    required
                  />
                  <TextField
                    name="paidAmount"
                    label={t('documents.paymentAmount')}
                    styles={{
                      root: {
                        width: '20%',
                      },
                    }}
                    value={_payment.paidAmount}
                    onFocus={(e) => {
                      if (recordPayment.taxWithheldRate !== -1) {
                        const values = [...recordPayment.paymentList];

                        values.map((_payment) => (_payment.paidAmount = _payment.originalPaidAmount));

                        setRecordPayment((prev) => ({ ...prev, paymentList: values, taxWithheld: false, taxWithheldAmount: 0, taxWithheldRate: 0 }));
                      }
                    }}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) handleChangePaymentList(e, index);
                    }}
                    onBlur={(e) => handleBlurPaymentList(e, index)}
                    onGetErrorMessage={(value) => {
                      if (Number(value) < 0) return t('documents.amountPaidErrorMessage') + ' ' + t('documents.thb');

                      let _maxValue = recordPayment.amountDue;
                      recordPayment.paymentList.forEach((payment, i) => {
                        if (index !== i) _maxValue = _maxValue - payment.paidAmount;
                      });
                      if (_maxValue < 0) _maxValue = 0;
                      if (Number(value) > _maxValue && Number(value) > 0)
                        return t('documents.amountPaidErrorMessage2') + _maxValue + ' ' + t('documents.thb');

                      return undefined;
                    }}
                    disabled={loadingRecordPayment}
                  />
                  <DatePicker
                    styles={{
                      root: {
                        width: '20%',
                      },
                    }}
                    // textField={{ prefix: t('documents.date') }}
                    allowTextInput={false}
                    label={t('documents.date')}
                    // disableAutoFocus
                    formatDate={onFormatDate}
                    strings={DayPickerStrings}
                    placeholder={t('documents.pleaseSelectDate')}
                    value={new Date(recordPayment.paymentList[index].date)}
                    onSelectDate={(date) => handleChangePaymentDate(index, date)}
                    disabled={loadingRecordPayment}
                  />

                  <TextField
                    name="paymentNote"
                    label={t('documents.paymentNote')}
                    styles={{
                      root: {
                        width: '30%',
                      },
                    }}
                    onChange={(e) => handleChangePaymentList(e, index)}
                    value={recordPayment.paymentList[index].paymentNote}
                    disabled={loadingRecordPayment}
                  />
                  <Stack verticalAlign="end" horizontalAlign="center" styles={{ root: { width: '5%', fontSize: 24, color: '#0078D4' } }}>
                    <IconButton
                      styles={{ icon: { fontSize: 24 } }}
                      iconProps={{ iconName: 'Delete' }}
                      title={t('expenseNote.delete')}
                      onClick={() => handleRemovePaymentList(index)}
                      disabled={loadingRecordPayment}
                    />
                  </Stack>
                </Stack>
                {recordPayment.paymentList[index].paymentCode === 'BANK' ? (
                  <>
                    <Stack>
                      <Stack horizontal tokens={{ childrenGap: '0 10' }}>
                        <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { width: '5%' } }}></Stack>
                        <Dropdown
                          id="bank"
                          placeholder={t('expenseNote.pleaseSelect')}
                          label={t('expenseNote.bankName')}
                          options={bankList}
                          styles={{
                            root: {
                              width: '20%',
                            },
                          }}
                          selectedKey={recordPayment.paymentList[index].bankCode}
                          onChange={(e, selectedOption) => {
                            // setShowErrorMessageRecordPayment(false);
                            handleSelectOption(index, e, selectedOption);
                          }}
                          disabled={loadingRecordPayment}
                        />
                        <Dropdown
                          id="accountType"
                          placeholder={t('expenseNote.pleaseSelect')}
                          label={t('expenseNote.accountType')}
                          options={accountTypeList}
                          styles={{
                            root: {
                              width: '20%',
                            },
                          }}
                          selectedKey={recordPayment.paymentList[index].typeCode}
                          onChange={(e, selectedOption) => {
                            // setShowErrorMessageRecordPayment(false);
                            handleSelectOption(index, e, selectedOption);
                          }}
                          disabled={loadingRecordPayment}
                        />
                        <TextField
                          name="accountName"
                          label={t('expenseNote.accountName')}
                          styles={{
                            root: {
                              width: '20%',
                            },
                          }}
                          onChange={(e) => handleChangePaymentList(e, index)}
                          value={recordPayment.paymentList[index].accountName}
                          disabled={loadingRecordPayment}
                        />
                        <TextField
                          name="accountNumber"
                          label={t('expenseNote.accountNumber')}
                          styles={{
                            root: {
                              width: '30%',
                            },
                          }}
                          onChange={(e) => handleChangePaymentList(e, index)}
                          value={recordPayment.paymentList[index].accountNumber}
                          disabled={loadingRecordPayment}
                        />
                        <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { width: '5%' } }}></Stack>
                      </Stack>
                    </Stack>
                  </>
                ) : null}
              </Stack>
            ))}
            <Stack horizontal horizontalAlign="center">
              <br />
              <PrimaryButton
                iconProps={{ iconName: 'Add' }}
                style={{ letterSpacing: '1px' }}
                name="addPaymentList"
                text={t('expenseNote.addPaymentList')}
                onClick={() => handleAddPaymentList()}
                disabled={loadingRecordPayment}
              />
              <br />
            </Stack>
            <Stack tokens={{ childrenGap: 10 }}>
              <Separator />
              <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }}>
                <Stack styles={{ root: { width: '50%' } }}>
                  <Checkbox
                    label={t('accountReceivable.taxWithheld')}
                    checked={recordPayment.taxWithheld}
                    disabled={loadingRecordPayment}
                    onChange={(e, checked) => {
                      if (checked) {
                        setRecordPayment((prev) => ({ ...prev, isChange: true, taxWithheld: !prev.taxWithheld }));
                      } else {
                        setRecordPayment((prev) => ({
                          ...prev,
                          isChange: true,
                          taxWithheld: !prev.taxWithheld,
                          taxWithheldAmount: 0,
                          taxWithheldRate: 0,
                        }));
                      }
                    }}
                  />
                  {recordPayment.taxWithheld ? (
                    <Stack horizontal tokens={{ childrenGap: '0 10' }}>
                      <Dropdown
                        id="taxWithheld"
                        placeholder={t('expenseNote.pleaseSelect')}
                        label={t('accountReceivable.taxWithheldRate')}
                        options={withheldRateList}
                        styles={{
                          root: {
                            width: '30%',
                          },
                        }}
                        selectedKey={recordPayment.taxWithheldRate}
                        onChange={(e, selectedOption) => {
                          if (selectedOption.key < 0) {
                            setRecordPayment((prev) => ({ ...prev, isChange: true, taxWithheldRate: selectedOption.key }));
                          } else {
                            if (recordPayment.includeVat) {
                              const _amountPaid = recordPayment.paymentList.reduce(
                                (accumulator, payment) => accumulator + payment.originalPaidAmount,
                                0
                              );
                              const _amountPaidBeforeTax = _amountPaid - Math.round(((_amountPaid * percentVat) / (100 + percentVat)) * 100) / 100;
                              const _withheldAmount = Math.round(_amountPaidBeforeTax * (selectedOption.key / 100) * 100) / 100;

                              const values = [...recordPayment.paymentList];
                              values[0].paidAmount = Math.round((values[0].originalPaidAmount - _withheldAmount) * 100) / 100;

                              setRecordPayment((prev) => ({
                                ...prev,
                                isChange: true,
                                taxWithheldRate: selectedOption.key,
                                taxWithheldAmount: _withheldAmount,
                                paymentList: values,
                              }));
                            } else {
                              const _amountPaid = recordPayment.paymentList.reduce(
                                (accumulator, payment) => accumulator + payment.originalPaidAmount,
                                0
                              );
                              // const _amountPaidBeforeTax = _amountPaid - Math.round(((_amountPaid * percentVat) / (100 + percentVat)) * 100) / 100;
                              const _withheldAmount = Math.round(_amountPaid * (selectedOption.key / 100) * 100) / 100;

                              const values = [...recordPayment.paymentList];
                              values[0].paidAmount =
                                Math.round((values[0].originalPaidAmount - _withheldAmount + recordPayment.vatTotal) * 100) / 100;

                              setRecordPayment((prev) => ({
                                ...prev,
                                isChange: true,
                                taxWithheldRate: selectedOption.key,
                                taxWithheldAmount: _withheldAmount,
                                paymentList: values,
                              }));
                            }
                          }
                        }}
                        disabled={loadingRecordPayment}
                      />
                      <TextField
                        name="taxWithheldAmount"
                        label={t('accountReceivable.taxWithheldAmount')}
                        styles={{
                          root: {
                            width: '60%',
                          },
                        }}
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            if (recordPayment.includeVat) {
                              const values = [...recordPayment.paymentList];
                              values[0].paidAmount = Math.round((values[0].originalPaidAmount - Number(e.target.value)) * 100) / 100;

                              setRecordPayment((prev) => ({ ...prev, isChange: true, paymentList: values, taxWithheldAmount: e.target.value }));
                            } else {
                              const values = [...recordPayment.paymentList];
                              values[0].paidAmount =
                                Math.round((values[0].originalPaidAmount - Number(e.target.value) + recordPayment.vatTotal) * 100) / 100;

                              setRecordPayment((prev) => ({ ...prev, isChange: true, paymentList: values, taxWithheldAmount: e.target.value }));
                            }
                          }
                        }}
                        onBlur={(e) => setRecordPayment((prev) => ({ ...prev, taxWithheldAmount: Math.round(prev.taxWithheldAmount * 100) / 100 }))}
                        value={recordPayment.taxWithheldAmount}
                        disabled={loadingRecordPayment || recordPayment.taxWithheldRate >= 0}
                        onGetErrorMessage={(value) => {
                          if (recordPayment.getTotalPaid() - Number(value) < 0) return 'ห้ามระบุมูลค่าหัก ณ ที่จ่าย มากกว่าจำนวนที่จ่าย';

                          return undefined;
                        }}
                      />
                    </Stack>
                  ) : null}
                </Stack>
                <Stack styles={{ root: { width: '50%' } }}>
                  <Stack tokens={{ childrenGap: '10 0' }}>
                    <Stack horizontal>
                      <span style={{ width: '50%' }}>
                        <Text>{t('documents.recordGrandTotal')}</Text>
                      </span>
                      <span style={{ width: '30%', paddingLeft: 8, textAlign: 'end' }}>
                        <Text>
                          {recordPayment.amountDue.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}{' '}
                        </Text>
                      </span>
                      <span style={{ width: '20%', textAlign: 'center' }}>{t('documents.thb')}</span>
                    </Stack>
                    {recordPayment.taxWithheld ? (
                      <>
                        <br />
                        <Stack horizontal>
                          <span style={{ width: '50%' }}>
                            <Text>{t('accountReceivable.totalPaid')}</Text>
                          </span>
                          <span style={{ width: '30%', paddingLeft: 8, textAlign: 'end' }}>
                            <Text>
                              {recordPayment.getTotalPaid().toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}{' '}
                            </Text>
                          </span>
                          <span style={{ width: '20%', textAlign: 'center' }}>{t('documents.thb')}</span>
                        </Stack>
                        <Stack horizontal>
                          <span style={{ width: '50%' }}>
                            <Text>{t('accountReceivable.taxWithheldAmount')}</Text>
                          </span>
                          <span style={{ width: '30%', paddingLeft: 8, textAlign: 'end' }}>
                            <Text>
                              {Number(recordPayment.taxWithheldAmount).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}{' '}
                            </Text>
                          </span>
                          <span style={{ width: '20%', textAlign: 'center' }}>{t('documents.thb')}</span>
                        </Stack>
                        <Stack horizontal styles={{ root: { fontWeight: 'bold' } }}>
                          <span style={{ width: '50%' }}>
                            <Text styles={{ root: { fontWeight: 'bold' } }}>{t('accountReceivable.totalPaymentRec')}</Text>
                          </span>
                          <span style={{ width: '30%', paddingLeft: 8, textAlign: 'end' }}>
                            <Text styles={{ root: { fontWeight: 'bold' } }}>
                              {(recordPayment.getTotalPaid() + Number(recordPayment.taxWithheldAmount)).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}{' '}
                            </Text>
                          </span>
                          <span style={{ width: '20%', textAlign: 'center' }}>{t('documents.thb')}</span>
                        </Stack>
                      </>
                    ) : (
                      <>
                        <br />
                        <Stack horizontal styles={{ root: { fontWeight: 'bold' } }}>
                          <span style={{ width: '50%' }}>
                            <Text styles={{ root: { fontWeight: 'bold' } }}>{t('accountReceivable.totalPaymentRec')}</Text>
                          </span>
                          <span style={{ width: '30%', paddingLeft: 8, textAlign: 'end' }}>
                            <Text styles={{ root: { fontWeight: 'bold' } }}>
                              {recordPayment.getTotalPaid().toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}{' '}
                            </Text>
                          </span>
                          <span style={{ width: '20%', textAlign: 'center' }}>{t('documents.thb')}</span>
                        </Stack>
                        <Stack horizontal styles={{ root: { height: 17.6 } }}>
                          <span style={{ width: '50%' }}>
                            <Text></Text>
                          </span>
                          <span style={{ width: '30%', paddingLeft: 8, textAlign: 'end' }}>
                            <Text></Text>
                          </span>
                          <span style={{ width: '20%', textAlign: 'center' }}></span>
                        </Stack>
                        <Stack horizontal styles={{ root: { height: 17.6 } }}>
                          <span style={{ width: '50%' }}>
                            <Text></Text>
                          </span>
                          <span style={{ width: '30%', paddingLeft: 8, textAlign: 'end' }}>
                            <Text></Text>
                          </span>
                          <span style={{ width: '20%', textAlign: 'center' }}></span>
                        </Stack>
                      </>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {loadingRecordPayment ? (
            <DialogFooter styles={{ actions: { lineHeight: 0 } }}>
              <Spinner label={t('documents.processing')} labelPosition="right" />
            </DialogFooter>
          ) : (
            <DialogFooter styles={{ actionsRight: { textAlign: 'center' }, actions: { lineHeight: 0 } }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 10 }}>
                <PrimaryButton
                  onClick={() => {
                    if (recordPayment.isChange) {
                      handleRecordPaymentList();
                    } else {
                      setRecordPayment((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                    }
                  }}
                  text={t('company.save')}
                  iconProps={{ iconName: 'Save' }}
                />

                <DefaultButton
                  onClick={() =>
                    setRecordPayment((prev) => ({
                      ...prev,
                      isClick: false,
                    }))
                  }
                  text={t('documents.cancel')}
                />
              </Stack>
            </DialogFooter>
          )}
        </Dialog>
        <Dialog
          hidden={!recordNote.isClick}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: t('documents.recordNoteHeader'),
            styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 } },
            // subText: documentStatusSubText(),
          }}
        >
          <Stack tokens={{ childrenGap: '20 0' }}>
            <Stack horizontal horizontalAlign="center">
              <br />
              <PrimaryButton
                iconProps={{ iconName: 'Add' }}
                style={{ letterSpacing: '1px' }}
                name="recordNoteAddNoteButton"
                text={t('documents.recordNoteAddNoteButton')}
                onClick={() => handleAddNoteList()}
                disabled={recordNote.isLoading}
              />
              <br />
            </Stack>
            {recordNote.noteList.map((note, index) => (
              <div key={"note_" + index}>
                <Stack horizontal>
                  <DatePicker
                    styles={{
                      root: {
                        width: '90%',
                      },
                    }}
                    textField={{ prefix: t('documents.date') }}
                    // label={t('documents.date')}
                    disableAutoFocus
                    formatDate={onFormatDate}
                    allowTextInput={false}
                    strings={DayPickerStrings}
                    placeholder={t('documents.pleaseSelectDate')}
                    value={new Date(note.date)}
                    onSelectDate={(date) => handleChangeNoteDate(index, date)}
                    disabled={recordNote.isLoading}
                  />
                  <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    title={t('expenseNote.delete')}
                    onClick={() => handleRemoveNoteList(index)}
                    disabled={recordNote.isLoading}
                  />
                </Stack>
                <Stack style={{ marginTop: 10 }}>
                  <TextField
                    name="message"
                    // placeholder={t('documents.recordNoteHeader')}
                    // styles={{ field: { padding: 50 },  }}
                    // style={{ padding: 50 }}
                    multiline
                    rows={5}
                    resizable={false}
                    onChange={(e) => handleChangeNoteList(e, index)}
                    value={note.message}
                    disabled={recordNote.isLoading}
                  />
                </Stack>
              </div>
            ))}
          </Stack>
          {recordNote.isLoading ? (
            <DialogFooter styles={{ actions: { lineHeight: 0 } }}>
              <Spinner label={t('documents.processing')} labelPosition="right" />
            </DialogFooter>
          ) : (
            <DialogFooter styles={{ actionsRight: { textAlign: 'center' }, actions: { lineHeight: 0 } }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 10 }}>
                <PrimaryButton
                  onClick={() => {
                    if (recordNote.isChange) {
                      handleRecordNoteList();
                    } else {
                      setRecordNote((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                    }
                  }}
                  text={t('company.save')}
                  iconProps={{ iconName: 'Save' }}
                />

                <DefaultButton
                  onClick={() =>
                    setRecordNote((prev) => ({
                      ...prev,
                      isClick: false,
                    }))
                  }
                  text={t('documents.cancel')}
                />
              </Stack>
            </DialogFooter>
          )}
        </Dialog>
        <Dialog
          hidden={!dialogAlert.isClick}
          onDismiss={() =>
            setDialogAlert((prev) => ({
              ...prev,
              isClick: false,
            }))
          }
          styles={{ main: { minHeight: 0 } }}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: <Icon iconName="Error" />,
            styles: {
              header: { textAlign: 'center' },
              title: { paddingRight: 24, fontSize: 81, paddingBottom: 0 },
              subText: { textAlign: 'center' },
            },
            subText: dialogAlert.message,
          }}
        >
          <DialogFooter styles={{ actionsRight: { textAlign: 'center', marginRight: 0 } }}>
            <DefaultButton
              onClick={() =>
                setDialogAlert((prev) => ({
                  ...prev,
                  isClick: false,
                }))
              }
              text={t('documents.close')}
            />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!dialogConfirm.isClick}
          onDismiss={() =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }))
          }
          styles={{ main: { minHeight: 0 } }}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: dialogConfirm.header,
            styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
            subText: dialogConfirm.message,
          }}
        >
          <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
            <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
            <DefaultButton
              onClick={() =>
                setDialogConfirm((prev) => ({
                  ...prev,
                  isClick: false,
                }))
              }
              text={t('documents.cancel')}
            />
          </DialogFooter>
        </Dialog>
      </div>
      {url ? (
        <div ref={printRef} style={{ position: 'absolute', zIndex: -1 }}>
          <PdfAllPages pdf={url} />
        </div>
      ) : null}
    </>
  );
};

export default AccountReceivable;
