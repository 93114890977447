import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, Icon, PrimaryButton, FocusTrapZone, Spinner } from '@fluentui/react';

const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
const API_URL = process.env.REACT_APP_API_URL;
const APP_KEY = process.env.REACT_APP_JDCENTRAL_APP_KEY;

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const JdCentralConnect = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const history = useHistory();

  const { t } = useTranslation();

  const [password, setPassword] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const [status, setStatus] = useState('');

  const query = new URLSearchParams(window.location.href);
  const code = query.get('code');

  const callBackUrl =
    'https://oauth.jd.co.th/oauth2/to_login?app_key=' +
    APP_KEY +
    '&response_type=code&redirect_uri=' +
    PUBLIC_URL +
    '/etax/sources/jdcentral/connect&state=' +
    Math.floor(Math.random() * 100000000) +
    '&scope=snsapi_base';

  useEffect(() => {
    console.log('userEffect retrieve users data!');

    if (code) {
      setIsLoading(true);

      console.log('code: ', code);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + '/sources/jdcentral',
              {
                code: code,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              }
            )
            .then(
              (response) => {
                console.log(response);

                if (response.data) {
                  if (response.data.canLogin) {
                    history.push({
                      pathname: '/etax/sources/jdcentral',
                      state: {},
                    });
                  } else {
                    setStatus('fail');
                  }
                }

                setPassword('');
                setIsLoading(false);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/sources/jdcentral', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            })
            .then(
              (response) => {
                console.log('JD Central sources: ', response);

                if (response.data) {
                  if (!response.data.disabled && response.data.canLogin) {
                    history.push({
                      pathname: '/etax/sources/jdcentral',
                      state: {},
                    });
                  }
                }

                setIsLoading(false);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack>
      {!isLoading && (
        <Stack>
          <br />

          <h1 style={{ marginLeft: '0px', marginTop: '27px' }}>
            <Icon iconName="CloudImportExport" style={{ color: '#106ebe' }} /> {t('jdCentralConnect.header')}
          </h1>
          <h3 style={{ marginTop: '0px', marginBottom: '10px' }}>{t('jdCentralConnect.subHeader1')}</h3>

          <br />
          <br />
        </Stack>
      )}
      {status === '' && !isLoading && (
        <FocusTrapZone disabled={true}>
          <Stack horizontalAlign="center">
            <Stack style={{ border: '1px solid rgb(134, 134, 134)', width: '350px', backgroundColor: '#FFFFFF' }}>
              <br />
              <br />

              <Stack horizontal horizontalAlign="center">
                <img src="/images/JDcentral.jpg" width="200px" alt="jdcentral" />
              </Stack>
              <br />
              <br />

              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
                <PrimaryButton style={{ fontSize: '20px', height: '50px', width: '255px' }} type="submit" href={callBackUrl}>
                  {t('jdCentralConnect.connect')}
                </PrimaryButton>
              </Stack>
              <br />
              <br />
            </Stack>
          </Stack>
        </FocusTrapZone>
      )}
      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              {password !== '' ? (
                <Spinner label={t('jdCentralConnect.processing')} labelPosition="down" styles={spinnerStyles} />
              ) : (
                <Spinner label={t('jdCentralConnect.loading')} labelPosition="down" styles={spinnerStyles} />
              )}
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      <br />
      <br />
      {status === '' && !isLoading && (
        <center>
          <br />
          <br />
          <span style={{ color: 'gray' }}>{t('jdCentralConnect.footer1')}</span>
          <br />
          <br />
          <span style={{ color: 'gray', fontWeight: 'bold' }}>{t('jdCentralConnect.footer2')}</span>
        </center>
      )}
    </Stack>
  );
};

export default JdCentralConnect;
