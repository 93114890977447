import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import axios from "axios";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";

import paginate from "jw-paginate";

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  Icon,
  Spinner,
  SpinnerSize,
  SearchBox,
  CommandBar,
  Stack,
  PrimaryButton,
  DefaultButton,
  TextField,
  ContextualMenu,
  CheckboxVisibility,
  Dialog,
  DialogFooter,
  DialogType,
  DatePicker,
  Text,
  Coachmark,
  TeachingBubbleContent,
  DirectionalHint
} from "@fluentui/react";

// import { Pagination } from "@uifabric/experiments/lib/Pagination";
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import { numberToTextEn, numberToTextTh } from "../../../helpers/BahtWord";
import { getBranchFromName } from "../../../helpers/GetBranchFromName";

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: "100%",
  background: "#FAF9F8",
};

const onFormatDate = (date, format) => {
  if (format === "CE") {
    return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, "0") + "/" + `${date.getMonth() + 1}`.padStart(2, "0") + "/" + (date.getFullYear() + 543);
  }
};

const formatDate = (d) => {
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const DynamicsFinanceOperations = () => {
  console.log("Home render!!!");

  const history = useHistory();

  const { t, i18n } = useTranslation();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(false);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem("ms-fo-pageSize") * 1 || 10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem("ms-fo-currentPage") * 1 || 1);

  const [company, setCompany] = useState("");
  const [search, setSearch] = useState("");

  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = "7%";
  const zeroVatText = "0%";
  // const nonVatText = "-"

  const dateType = { key: "document", text: "วันที่บนเอกสาร" };

  const [endpointType, setEndpointType] = useState(
    (sessionStorage.getItem("ms-fo-endpointType-key") && {
      key: sessionStorage.getItem("ms-fo-endpointType-key"),
      text: sessionStorage.getItem("ms-fo-endpointType-text"),
    }) || {
      key: "SalesInvoice",
      text: "Sales Invoices",
    }
  );

  const [allDocs, setAllDocs] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const isCompactMode = false;

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        console.log("isCreateDocument: ", isCreateDocument);
        console.log("isCreateDocumentComplete: ", isCreateDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: "",
    message: "",
    onConfirm: null,
    onCancel: null,
    children: null
  });

  const [isCoachmarkVisible, setIsCoachmarkVisible] = useState(sessionStorage.getItem("dynamicFo-isCoachmarkVisible") === "false" ? false : true);

  const [createdBy, setCreatedBy] = useState("");

  const [dynamicsCompanies, setDynamicsCompanies] = useState([]);
  const [dynamicsCompany, setDynamicsCompany] = useState({ key: "", text: "กำลังโหลด..." });

  const companyId = sessionStorage.getItem("companyId") || "";

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem("ms-fo-primarySort") || "documentDate");
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem("ms-fo-sort-documentDate") === "false") {
      return false;
    } else {
      return true;
    }
  });
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem("ms-fo-sort-number")) {
      if (sessionStorage.getItem("ms-fo-sort-number") === "true") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('ms-fo-sort-customerName')) {
      if (sessionStorage.getItem('ms-fo-sort-customerName') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(() => {
    if (sessionStorage.getItem("ms-fo-sort-vatTotal")) {
      if (sessionStorage.getItem("ms-fo-sort-vatTotal") === "true") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem("ms-fo-sort-grandTotal")) {
      if (sessionStorage.getItem("ms-fo-sort-grandTotal") === "true") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const targetButton = useRef(null);

  const DayPickerStrings = {
    months: [
      t("dynamicFo.months01"),
      t("dynamicFo.months02"),
      t("dynamicFo.months03"),
      t("dynamicFo.months04"),
      t("dynamicFo.months05"),
      t("dynamicFo.months06"),
      t("dynamicFo.months07"),
      t("dynamicFo.months08"),
      t("dynamicFo.months09"),
      t("dynamicFo.months10"),
      t("dynamicFo.months11"),
      t("dynamicFo.months12"),
    ],

    shortMonths: [
      t("dynamicFo.shortMonths01"),
      t("dynamicFo.shortMonths02"),
      t("dynamicFo.shortMonths03"),
      t("dynamicFo.shortMonths04"),
      t("dynamicFo.shortMonths05"),
      t("dynamicFo.shortMonths06"),
      t("dynamicFo.shortMonths07"),
      t("dynamicFo.shortMonths08"),
      t("dynamicFo.shortMonths09"),
      t("dynamicFo.shortMonths10"),
      t("dynamicFo.shortMonths11"),
      t("dynamicFo.shortMonths12"),
    ],

    days: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"],

    shortDays: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],

    goToToday: t("dynamicFo.goToToday"),
    prevMonthAriaLabel: "ไปเดือนที่แล้ว",
    nextMonthAriaLabel: "ไปเดือนถัดไป",
    prevYearAriaLabel: "ไปปีที่แล้ว",
    nextYearAriaLabel: "ไปปีถัดไป",
    closeButtonAriaLabel: "ปิด",
    monthPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนปี",
    yearPickerHeaderAriaLabel: "{0}, เลือกเพื่อเปลี่ยนเดือน",

    isRequiredErrorMessage: t("dynamicFo.isRequiredErrorMessage"),

    invalidInputErrorMessage: "รูปแบบวันที่ไม่ถูกต้อง",
  };

  // Processing dialog style
  const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4,
    },
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem("ms-fo-queryMonth-key") && {
        key: sessionStorage.getItem("ms-fo-queryMonth-key"),
        text: sessionStorage.getItem("ms-fo-queryMonth-text"),
        ...(sessionStorage.getItem("ms-fo-queryMonth-type") ? { type: sessionStorage.getItem("ms-fo-queryMonth-type") } : {}),
    }) || { type: "dateRange",
      key: formatDate(new Date()) + ":" + formatDate(new Date()),
      text: formatDate(new Date()) + " - " + formatDate(new Date()),
    }
  );

  const [fromDate, setFromDate] = useState(sessionStorage.getItem("ms-fo-fromDate") ? new Date(sessionStorage.getItem("ms-fo-fromDate")) : new Date());
  const [toDate, setToDate] = useState(sessionStorage.getItem("ms-fo-toDate") ? new Date(sessionStorage.getItem("ms-fo-toDate")) : new Date());
  const [timeRangeError, setTimeRangeError] = useState(false);

  const getFromDMY = (day, month, year) => {
    let _firstDay = new Date(year, month, day);
    console.log("First day: ", formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getToDMY = (day, month, year) => {
      let _lastDay = new Date(year, month, day + 1);
      console.log("Last day: ", formatDate(_lastDay));

      return formatDate(_lastDay);
  };

  const [referInvoiceNumber, setReferInvoiceNumber] = useState('')
  const [openRefer, setOpenRefer] = useState(false)
  const [openReferSelectedItems, setOpenReferSelectedItems] = useState(false)
  const [selectItem, setSelectItem] = useState(null)

  const [from, setFrom] = useState(sessionStorage.getItem("ms-fo-from") || getFromDMY(new Date().getDate(), new Date().getMonth(), new Date().getFullYear()));
  const [to, setTo] = useState(sessionStorage.getItem("ms-fo-to") || getToDMY(new Date().getDate(), new Date().getMonth(), new Date().getFullYear()));

  let _pageDropdown = [];

  for (let i = 1; i <= 35; i++) {
    _pageDropdown.push({
      key: i,
      text: t("dynamicFo.page") + " " + i,
      iconProps: { iconName: "Page" },
      onClick: () => {
        console.log("Selected page: " + i);
        setCurrentPage(i);
      },
    });
  }

  const [pageDropdown, setPageDropdown] = useState(_pageDropdown);

  function getCompany_items() {
    let array = [];

    if (dynamicsCompanies !== undefined) {
      dynamicsCompanies.forEach((_company) => {
        array.push({
          key: _company.DataArea,
          text: _company.Name,
          iconProps: { iconName: "CityNext2" },
          onClick: () => {
            setDynamicsCompany({
              key: _company.DataArea,
              text: _company.Name,
            });

            sessionStorage.setItem("ms-fo-company-key", _company.DataArea);
            sessionStorage.setItem("ms-fo-company-text", _company.Name);

            setLoadDataComplete(false);
            setItems([]);
            setGetNewDocuments(true);

            setSearch("");

            setCurrentPage(1);
          },
        });
      });
    }

    return array;
  }

  function getDateText(monthObject) {
    if (monthObject.type === 'dateRange') {
      if (i18n.language === 'th') {
        return onFormatDate(fromDate, 'BE') + ' - ' + onFormatDate(toDate, 'BE');
      }
      return onFormatDate(fromDate, 'CE') + ' - ' + onFormatDate(toDate, 'CE');
    }
    if (monthObject.text === "เดือนนี้" || monthObject.text === "This Month") return t("dynamicFo.thisMonth");

    let monthNames = [
      t("dynamicFo.months01"),
      t("dynamicFo.months02"),
      t("dynamicFo.months03"),
      t("dynamicFo.months04"),
      t("dynamicFo.months05"),
      t("dynamicFo.months06"),
      t("dynamicFo.months07"),
      t("dynamicFo.months08"),
      t("dynamicFo.months09"),
      t("dynamicFo.months10"),
      t("dynamicFo.months11"),
      t("dynamicFo.months12"),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === "th") {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + " " + _year;
  }

  const commandBarItems = [
    {
      key: dynamicsCompany.key,
      text: dynamicsCompany.text,
      disabled: !loadDataComplete || isCreateDocument,
      iconProps: { iconName: "CityNext2" },
      subMenuProps: {
        items: getCompany_items(),
      },
    },
    {
      key: endpointType.key,
      text: endpointType.text,
      cacheKey: "myCacheKey", // changing this key will invalidate this item"s cache
      iconProps: { iconName: "Page" },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: "SalesInvoice",
            text: "Sales Invoices",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("เอกสารทุกประเภท");
              setEndpointType({ key: "SalesInvoice", text: "Sales Invoices" });

              sessionStorage.setItem("ms-fo-endpointType-key", "SalesInvoice");
              sessionStorage.setItem("ms-fo-endpointType-text", "Sales Invoices");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
  
              setSearch("");
  
              setCurrentPage(1);
            },
          },
          {
            key: "LedgerJournal",
            text: "Ledger Journal",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("เอกสารทุกประเภท");
              setEndpointType({ key: "LedgerJournal", text: "Ledger Journal" });

              sessionStorage.setItem("ms-fo-endpointType-key", "LedgerJournal");
              sessionStorage.setItem("ms-fo-endpointType-text", "Ledger Journal");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
  
              setSearch("");
  
              setCurrentPage(1);
            },
          },
          {
            key: "CustomerPaymentJournal",
            text: "Customer Payment Journal",
            iconProps: { iconName: "Page" },
            onClick: () => {
              console.log("เอกสารทุกประเภท");
              setEndpointType({ key: "CustomerPaymentJournal", text: "Customer Payment Journal" });

              sessionStorage.setItem("ms-fo-endpointType-key", "CustomerPaymentJournal");
              sessionStorage.setItem("ms-fo-endpointType-text", "Customer Payment Journal");

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
  
              setSearch("");
  
              setCurrentPage(1);
            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: "Calendar" },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: "queryMonth",
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
                <Stack>
                <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                  <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                    <Text>{t('dynamicFo.fromRange')}</Text>
                  </Stack>
                  <Stack styles={{ root: { width: '80%', justifyContent: 'center' /* alignItems: 'center' */ } }}>
                    <DatePicker
                      styles={{ root: { width: '90%' } }}
                      placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                      strings={DayPickerStrings}
                      value={fromDate}
                      maxDate={toDate}
                      formatDate={onFormatDate}
                      onSelectDate={(_date) => {
                        setFromDate(_date);

                        setTimeRangeError(false);
                        if (Math.ceil(Math.abs(_date - toDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack horizontal styles={{ root: { height: 42, paddingTop: 10 } }}>
                  <Stack styles={{ root: { width: '20%', justifyContent: 'center', alignItems: 'center' } }}>
                    <Text>{t('dynamicFo.toRange')}</Text>
                  </Stack>
                  <Stack styles={{ root: { width: '80%' /* alignItems: 'center' */ } }}>
                    <DatePicker
                      styles={{ root: { width: '90%' } }}
                      placeholder={t('deliveryOrderTaxInvoice.pleaseSelect')}
                      strings={DayPickerStrings}
                      value={toDate}
                      minDate={fromDate}
                      formatDate={onFormatDate}
                      onSelectDate={(_date) => {
                        setToDate(_date);

                        setTimeRangeError(false);
                        if (Math.ceil(Math.abs(_date - fromDate) / (1000 * 60 * 60 * 24)) > 30) setTimeRangeError(true);
                      }}
                    />
                  </Stack>
                </Stack>
                {timeRangeError ? (
                  <Stack styles={{ root: { padding: '12px 0px 0px 12px' } }}>
                    <Text styles={{ root: { fontSize: 12, color: '#A4262C' } }}>{t('dynamicFo.timeRangeError')}</Text>
                  </Stack>
                ) : null}
                <Stack horizontal horizontalAlign="end" styles={{ root: { width: '92%', height: 52, padding: '10px 0px 10px 0px' } }}>
                  <PrimaryButton
                    text={t('dynamicFo.search')}
                    onClick={() => {
                      if (!timeRangeError && fromDate && toDate) {
                        setQueryMonth({
                          type: 'dateRange',
                          key: formatDate(fromDate) + ':' + formatDate(toDate),
                          text: formatDate(fromDate) + ' - ' + formatDate(toDate),
                        });

                        sessionStorage.setItem("ms-fo-queryMonth-type", "dateRange");
                        sessionStorage.setItem("ms-fo-queryMonth-key", formatDate(fromDate) + ":" + formatDate(toDate));
                        sessionStorage.setItem("ms-fo-queryMonth-text", formatDate(fromDate) + " - " + formatDate(toDate));

                        setFrom(getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        setTo(getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        sessionStorage.setItem("ms-fo-from", getFromDMY(fromDate.getDate(), fromDate.getMonth(), fromDate.getFullYear()));
                        sessionStorage.setItem("ms-fo-to", getToDMY(toDate.getDate(), toDate.getMonth(), toDate.getFullYear()));

                        sessionStorage.setItem("ms-fo-fromDate", fromDate.toISOString());
                        sessionStorage.setItem("ms-fo-toDate", toDate.toISOString());

                        setLoadDataComplete(false);
                        setItems([]);
                        setGetNewDocuments(true);

                        setCurrentPage(1);

                        setSearch("");

                        setPrimarySort("documentDate");
                        setIsSortedDescendingDocumentDate(true);
                        setIsSortedDescendingNumber(false);
                        setIsSortedDescendingVatTotal(false);
                        setIsSortedDescendingGrandTotal(false);

                        sessionStorage.setItem("ms-fo-primarySort", "documentDate");
                        sessionStorage.setItem("ms-fo-sort-documentDate", "true");
                        sessionStorage.setItem("ms-fo-sort-number", "false");
                        sessionStorage.setItem("ms-fo-sort-customerName", "false");
                        sessionStorage.setItem("ms-fo-sort-vatTotal", "false");
                        sessionStorage.setItem("ms-fo-sort-grandTotal", "false");
                      }
                    }}
                    style={{ width: '125px', letterSpacing: '1px' }}
                  />
                </Stack>
              </Stack>
              
            </div>
          );
        },
      },
    },
    {
      key: pageSize,
      text: pageSize + " " + t("dynamicFo.documentPerPage"),
      cacheKey: "myCacheKey", // changing this key will invalidate this item"s cache
      iconProps: { iconName: "NumberedList" },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: [
          {
            key: 10,
            text: "10 " + t("dynamicFo.documentPerPage"),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              console.log("10 เอกสาร/หน้า");
              setPageSize(10);
              sessionStorage.setItem("ms-fo-pageSize", 10);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch("");
            },
          },
          {
            key: 20,
            text: "20 " + t("dynamicFo.documentPerPage"),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              console.log("20 เอกสาร/หน้า");
              setPageSize(20);
              sessionStorage.setItem("ms-fo-pageSize", 20);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch("");
            },
          },
          {
            key: 30,
            text: "30 " + t("dynamicFo.documentPerPage"),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              console.log("30 เอกสาร/หน้า");
              setPageSize(30);
              sessionStorage.setItem("ms-fo-pageSize", 30);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch("");
            },
          },
          {
            key: 40,
            text: "40 " + t("dynamicFo.documentPerPage"),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              console.log("40 เอกสาร/หน้า");
              setPageSize(40);
              sessionStorage.setItem("ms-fo-pageSize", 40);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch("");
            },
          },
          {
            key: 50,
            text: "50 " + t("dynamicFo.documentPerPage"),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              console.log("50 เอกสาร/หน้า");
              setPageSize(50);
              sessionStorage.setItem("ms-fo-pageSize", 50);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch("");
            },
          },
          {
            key: 100,
            text: "100 " + t("dynamicFo.documentPerPage"),
            iconProps: { iconName: "NumberedList" },
            onClick: () => {
              console.log("100 เอกสาร/หน้า");
              setPageSize(100);
              sessionStorage.setItem("ms-fo-pageSize", 100);

              setCurrentPage(1);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
              setSearch("");
            },
          },
        ],
      },
    },
    {
      key: currentPage + "page",
      text: t("dynamicFo.page") + " " + currentPage,
      iconProps: { iconName: "Page" },
      disabled: !loadDataComplete || isCreateDocument,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: "refresh",
      text: t("dynamicFo.refresh"),
      iconProps: { iconName: "Refresh" },
      disabled: !loadDataComplete || isCreateDocument,
      onClick: () => {
        console.log("Refresh");

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
      },
    },
  ];

  const overflowItems = [
    {
      key: "disconnect",
      text: t("dynamicFo.disconnect"),
      onClick: () => {
        console.log("dynamics disconnect!");

        setDialogConfirm((prev) => ({
          ...prev,
          isClick: true,
          header: t("dynamicFo.disconnect"),
          message: t("dynamicFo.disconnectConfirm"),
          onConfirm: () => {
            disconnect(true);
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));
          },
          onCancel: () =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            })),
        }));
      },
      iconProps: { iconName: "PlugDisconnected" },
    //   disabled: !loadDataComplete || isCreateDocument,
    },
  ];

  const createDocument = async (documentType, item) => {
    setIsLoading(true);

    let documentPath = "/etax/documents/receipts/confirm";

    switch (documentType) {
      case "RECEIPT-TAXINVOICE":
        documentPath = "/etax/documents/receipts-taxinvoices/confirm";
        break;
      case "RECEIPT-TAXINVOICE-ABB":
        documentPath = "/etax/documents/receipts-taxinvoices-abb/confirm";
        break;
      case "RECEIPT":
        documentPath = "/etax/documents/receipts/confirm";
        break;
      case "INVOICE-TAXINVOICE":
        documentPath = "/etax/documents/invoices-taxinvoices/confirm";
        break;
      case "DELIVERYORDER-TAXINVOICE":
        documentPath = "/etax/documents/deliveryorders-taxinvoices/confirm";
        break;
      case "TAXINVOICE":
        documentPath = "/etax/documents/taxinvoices/confirm";
        break;
      default:
        break;
    }

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        if (endpointType.key === 'SalesInvoice') {
          const [responseCustomer, responseInvoiceDetail] = await Promise.all([
            axios
              // .get("http://localhost:7071/api/DynamicsFinanceOperations_getCustomer", {
              .get(API_URL + '/sources/dynamics/fo/customers', {
                headers: {
                  "Authorization": "Bearer " + tokenResponse.accessToken,
                  "cid": companyId
                },
                params: {
                  dataAreaId: dynamicsCompany.key,
                  CustomerAccount: item.customerId,
                },
              })
              .catch((error) => console.log(error)),
  
            axios
              // .get("http://localhost:7071/api/DynamicsFinanceOperations_getSalesInvoiceV2Lines", {
              .get(API_URL + '/sources/dynamics/fo/salesinvoicelines', {
                headers: {
                  "Authorization": "Bearer " + tokenResponse.accessToken,
                  "cid": companyId
                },
                params: {
                  dataAreaId: dynamicsCompany.key,
                  InvoiceNumber: item.number,
                },
              })
              .catch((error) => console.log(error)),
          ]);
  
          const _customerInfo = responseCustomer.data.value[0];
          const invoiceLine = responseInvoiceDetail.data.value;
  
          console.log("_customerInfo", _customerInfo)
          console.log("invoiceLine", invoiceLine)
  
          const { PartyType, FullPrimaryAddress, PrimaryContactEmail, PrimaryContactPhone, 
            OrganizationName, AddressZipCode, IsSalesTaxIncludedInPrices, IdentificationNumber, 
            BPC_Tax_VATid, BPC_Tax_WHTid, BPC_HeadOffice, BPC_BranchNo } = _customerInfo
  
          // let d = item.documentDate.split("-");
  
          const _date = item.documentDate
          const _dateBE = onFormatDate(new Date(_date), "BE")
          const _dateCE = onFormatDate(new Date(_date), "CE")
  
          // let _dateBE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + (_date.getFullYear() + 543);
          // let _dateCE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + _date.getFullYear();
  
          // let dueD = item.dueDate.split("-");
  
          // let _dueDate = new Date(dueD[2] + "/" + dueD[1] + "/" + dueD[0]);
  
          // let _dueDateBE =
          //   `${_dueDate.getDate()}`.padStart(2, "0") + "/" + `${_dueDate.getMonth() + 1}`.padStart(2, "0") + "/" + (_dueDate.getFullYear() + 543);
          // let _dueDateCE =
          //   `${_dueDate.getDate()}`.padStart(2, "0") + "/" + `${_dueDate.getMonth() + 1}`.padStart(2, "0") + "/" + _dueDate.getFullYear();
  
          let _includeVat = true
          if (IsSalesTaxIncludedInPrices === "No") {
            _includeVat = false
          }
  
          let _taxId = "N/A";
          let _taxNumberType = "OTHR";
          let _branchNumber = "";
          let _branchText = "";
  
          if (BPC_Tax_VATid && BPC_Tax_VATid.length === 13) {
            _taxId = BPC_Tax_VATid;
          } else if (BPC_Tax_WHTid && BPC_Tax_WHTid.length === 13) {
            _taxId = BPC_Tax_WHTid;
          } else if (IdentificationNumber && IdentificationNumber.length === 13) {
            _taxId = IdentificationNumber;
          } else if (IdentificationNumber && IdentificationNumber.length !== 13) {
            _taxId = IdentificationNumber;
            _taxNumberType = "CCPT";
          }

          let _customerName = OrganizationName

          const { name, branchNumber, branchText } = getBranchFromName(OrganizationName)

          if (branchNumber) {
            _customerName = name
            _taxNumberType = "TXID";
            _branchNumber = branchNumber
            _branchText = branchText
          } else {
            if (PartyType) {
              if (PartyType === "Organization") {
                _taxNumberType = "TXID";
    
                if (BPC_HeadOffice === "Yes") {
                  _branchNumber = "00000";
                  _branchText = "Head Office";
                } else {
                  if (BPC_BranchNo) {
                    _branchNumber = BPC_BranchNo.padStart(5, "0")
                    _branchText = "Branch " + _branchNumber
                  } else {
                    _branchNumber = "00000";
                    _branchText = "Head Office";
                  }
                }
              } else {
                _taxNumberType = "NIDN";
              }
            }
          }
  
  
          if (_taxId === "N/A" || !_taxId || Number(_taxId) === 0) {
            _taxId = "N/A"
            _taxNumberType = "OTHR";
            _branchNumber = "";
            _branchText = "";
          } else if (_taxId.length !== 13) {
            _taxNumberType = "CCPT"
          }
  
          let _postcode = "00000"
          if (AddressZipCode) {
            _postcode = AddressZipCode
          } else {
            let findPostcode = FullPrimaryAddress.match(/\d+/g);
    
            if (findPostcode && findPostcode.length > 0) {
              findPostcode.forEach((number) => {
                if (number.length === 5) {
                  _postcode = number;
                }
              });
            }
          }
  
          let _phoneNumber = ""
          if (PrimaryContactPhone) {
            if (PrimaryContactPhone.includes(",")) {
              _phoneNumber = PrimaryContactPhone.split(",")[0]
            } else {
              _phoneNumber = PrimaryContactPhone
            }
          }

          _phoneNumber = _phoneNumber.replaceAll("-", "")
  
          let _customer = {
            addressLineOne: FullPrimaryAddress.replace(`${_postcode}`, ""),
            addressLineTwo: "",
            branchNumber: _branchNumber,
            branchText: _branchText,
            email: PrimaryContactEmail,
            name: _customerName,
            phone: _phoneNumber,
            phoneCountryCode: "+66",
            postcode: _postcode,
            taxId: _taxId,
            taxNumberType: _taxNumberType,
            language: "th",
            unstructure: true,
          };
  
          let _items = [];
          let _amountTotal = 0
  
          for (let i = 0; i < invoiceLine.length; i++) {
            const { LineTotalTaxAmount, ProductDescription, InvoicedQuantity, 
              SalesPrice, LineTotalDiscountAmount, LineAmount } = invoiceLine[i]
  
            let _percentVat = zeroVat;
            let _percentVatText = zeroVatText;
            if (LineTotalTaxAmount > 0) {
              _percentVat = percentVat;
              _percentVatText = percentVatText;
            }
  
            _amountTotal = _amountTotal + LineAmount
  
            _items.push({
              number: i + 1 + "",
              description: ProductDescription || "-",
              quantity: InvoicedQuantity,
              unitCode: "",
              unitName: "-",
              price: SalesPrice,
              discount: Math.round((LineTotalDiscountAmount / InvoicedQuantity) * 100) / 100,
              total: LineAmount,
              percentVat: _percentVat,
              percentVatText: _percentVatText,
            });
          }
  
          let _vatPriceTotal = 0;
          let _zeroVatPriceTotal = 0;
          let _nonVatPriceTotal = 0;
  
          _items.forEach((item) => {
            if (item.percentVat === percentVat) {
              _vatPriceTotal = _vatPriceTotal + item.total;
            } else if (item.percentVat === zeroVat) {
              _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
            } else if (item.percentVat === nonVat) {
              _nonVatPriceTotal = _nonVatPriceTotal + item.total;
            }
          });
  
          // console.log({
          //   pathname: documentPath,
          //   state: {
          //     number: item.number,
          //     reference: "",
          //     company: {
          //       ...company,
          //       includeVat: _includeVat,
          //     },
          //     customer: _customer,
          //     date: _date,
          //     dateBE: _dateBE,
          //     dateCE: _dateCE,
          //     // dueDate: _dueDate,
          //     // dueDateBE: _dueDateBE,
          //     // dueDateCE: _dueDateCE,
          //     items: _items,
          //     includeVat: _includeVat,
          //     discount: item.discount * 1,
          //     amountTotal: _amountTotal * 1,
          //     vatTotal: item.vatTotal * 1,
          //     grandTotal: item.grandTotal * 1,
          //     nonVatPriceTotal: _nonVatPriceTotal,
          //     zeroVatPriceTotal: _zeroVatPriceTotal,
          //     vatPriceTotal: _vatPriceTotal,
          //     percentVat: percentVat,
          //     receivedBy: "",
          //     reIssue: false,
          //     backUrl: "/etax/sources/dynamics/finance-operations",
          //     grandTotalTH: numberToTextTh(item.grandTotal * 1),
          //     grandTotalEN: numberToTextEn(item.grandTotal * 1),
          //   },
          // })
  
          history.push({
            pathname: documentPath,
            state: {
              number: item.number,
              reference: "",
              company: {
                ...company,
                includeVat: _includeVat,
              },
              customer: _customer,
              date: _date,
              dateBE: _dateBE,
              dateCE: _dateCE,
              // dueDate: _dueDate,
              // dueDateBE: _dueDateBE,
              // dueDateCE: _dueDateCE,
              items: _items,
              includeVat: _includeVat,
              discount: item.discount * 1,
              amountTotal: _amountTotal * 1,
              vatTotal: item.vatTotal * 1,
              grandTotal: item.grandTotal * 1,
              nonVatPriceTotal: _nonVatPriceTotal,
              zeroVatPriceTotal: _zeroVatPriceTotal,
              vatPriceTotal: _vatPriceTotal,
              percentVat: percentVat,
              receivedBy: "",
              reIssue: false,
              backUrl: "/etax/sources/dynamics/finance-operations",
              grandTotalTH: numberToTextTh(item.grandTotal * 1),
              grandTotalEN: numberToTextEn(item.grandTotal * 1),
            },
          });
        } else {
          
          const [responseCustomer, responseCustomerTaxID] = await Promise.all([
            axios
              // .get("http://localhost:7071/api/DynamicsFinanceOperations_getCustomer", {
              .get(API_URL + '/sources/dynamics/fo/customers', {
                headers: {
                  "Authorization": "Bearer " + tokenResponse.accessToken,
                  "cid": companyId
                },
                params: {
                  dataAreaId: dynamicsCompany.key,
                  CustomerAccount: item.customerId,
                },
              })
              .catch((error) => console.log(error)),

              axios
                // .get('http://localhost:7071/api/DynamicsFinanceOperations_getCustomerTaxID', {
                .get(API_URL + '/sources/dynamics/fo/tax-service-tax-registration-number-customers', {
                    headers: {
                    "Authorization": "Bearer " + tokenResponse.accessToken,
                    "cid": companyId
                    },
                    params: {
                        CustomerAccount: item.customerId,
                    },
                })
                .catch((error) => console.log(error)),
          ]);
  
          console.log("responseCustomer", responseCustomer)
          console.log("_customerInfoTaxID", responseCustomerTaxID)

          const _customerInfo = responseCustomer.data.value[0];
          const _customerInfoTaxID = responseCustomerTaxID.data.value[0] || {
            TaxRegstrationType: "",
            ValidFrom: "",
            ValidTo: "",
            CustAccountNum: "",
            LegalEntity: "",
            CountryRegionId: "",
            RegistrationNumber: "",
            Section: "",
            CustAccountName: "",
            Issuer: "",
            IssueDate: "",
            Description: ""
          };

          if (_customerInfo) {
            const { PartyType, 
              FullPrimaryAddress, 
              PrimaryContactEmail, 
              PrimaryContactPhone, 
              OrganizationName, 
              AddressZipCode, 
              IdentificationNumber, 
              BPC_Tax_VATid, 
              BPC_Tax_WHTid, 
              BPC_HeadOffice, 
              BPC_BranchNo } = _customerInfo
    
            const {
              RegistrationNumber,
            } = _customerInfoTaxID
            // let d = item.documentDate.split("-");
    
            const _date = item.documentDate
            const _dateBE = onFormatDate(new Date(_date), "BE")
            const _dateCE = onFormatDate(new Date(_date), "CE")
    
            // let _dateBE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + (_date.getFullYear() + 543);
            // let _dateCE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + _date.getFullYear();
    
            // let dueD = item.dueDate.split("-");
    
            // let _dueDate = new Date(dueD[2] + "/" + dueD[1] + "/" + dueD[0]);
    
            // let _dueDateBE =
            //   `${_dueDate.getDate()}`.padStart(2, "0") + "/" + `${_dueDate.getMonth() + 1}`.padStart(2, "0") + "/" + (_dueDate.getFullYear() + 543);
            // let _dueDateCE =
            //   `${_dueDate.getDate()}`.padStart(2, "0") + "/" + `${_dueDate.getMonth() + 1}`.padStart(2, "0") + "/" + _dueDate.getFullYear();
    
            let _includeVat = item.includeVat
            // if (IsSalesTaxIncludedInPrices === "No") {
            //   _includeVat = false
            // }
    
            let _taxId = "N/A";
            let _taxNumberType = "OTHR";
            let _branchNumber = "";
            let _branchText = "";
  
            if (RegistrationNumber && RegistrationNumber.length === 13) {
              _taxId = RegistrationNumber;
            } else if (BPC_Tax_VATid && BPC_Tax_VATid.length === 13) {
              _taxId = BPC_Tax_VATid;
            } else if (BPC_Tax_WHTid && BPC_Tax_WHTid.length === 13) {
              _taxId = BPC_Tax_WHTid;
            } else if (IdentificationNumber && IdentificationNumber.length === 13) {
              _taxId = IdentificationNumber;
            } else if (IdentificationNumber && IdentificationNumber.length !== 13) {
              _taxId = IdentificationNumber;
              _taxNumberType = "CCPT";
            }
    
            let _customerName = OrganizationName

            const { name, branchNumber, branchText } = getBranchFromName(OrganizationName)

            if (branchNumber) {
              _customerName = name
              _taxNumberType = "TXID";
              _branchNumber = branchNumber
              _branchText = branchText
            } else {
              if (PartyType) {
                if (PartyType === "Organization") {
                  _taxNumberType = "TXID";
      
                  if (BPC_HeadOffice === "Yes") {
                    _branchNumber = "00000";
    
                    if (OrganizationName.includes('บริษัท') || OrganizationName.includes('ห้างหุ้นส่วน')) {
                      _branchText = "สำนักงานใหญ่";
                    } else if (
                      OrganizationName.includes('Company') ||
                      OrganizationName.includes('company') ||
                      OrganizationName.includes('ltd.') ||
                      OrganizationName.includes('Ltd.') ||
                      OrganizationName.includes('Limited') ||
                      OrganizationName.includes('limited')
                    ) {
                      _branchText = "Head Office";
                    } else {
                      _branchText = "สำนักงานใหญ่";
                    }
    
                  } else {
                    if (BPC_BranchNo) {
                      _branchNumber = BPC_BranchNo.padStart(5, "0")
    
                      if (OrganizationName.includes('บริษัท') || OrganizationName.includes('ห้างหุ้นส่วน')) {
                        _branchText = "สาขา " + _branchNumber
                      } else if (
                        OrganizationName.includes('Company') ||
                        OrganizationName.includes('company') ||
                        OrganizationName.includes('ltd.') ||
                        OrganizationName.includes('Ltd.') ||
                        OrganizationName.includes('Limited') ||
                        OrganizationName.includes('limited')
                      ) {
                        _branchText = "Branch " + _branchNumber
                      } else {
                        _branchText = "สาขา " + _branchNumber
                      }
                    } else {
                      _branchNumber = "00000";
                      if (OrganizationName.includes('บริษัท') || OrganizationName.includes('ห้างหุ้นส่วน')) {
                        _branchText = "สำนักงานใหญ่";
                      } else if (
                        OrganizationName.includes('Company') ||
                        OrganizationName.includes('company') ||
                        OrganizationName.includes('ltd.') ||
                        OrganizationName.includes('Ltd.') ||
                        OrganizationName.includes('Limited') ||
                        OrganizationName.includes('limited')
                      ) {
                        _branchText = "Head Office";
                      } else {
                        _branchText = "สำนักงานใหญ่";
                      }
                    }
                  }
                } else {
                  _taxNumberType = "NIDN";
                }
              }
            }
    
            if (_taxId === "N/A" || !_taxId || Number(_taxId) === 0) {
              _taxId = "N/A"
              _taxNumberType = "OTHR";
              _branchNumber = "";
              _branchText = "";
            } else if (_taxId.length !== 13) {
              _taxNumberType = "CCPT"
            }
    
            let _postcode = "00000"
            if (AddressZipCode) {
              _postcode = AddressZipCode
            } else {
              let findPostcode = FullPrimaryAddress.match(/\d+/g);
      
              if (findPostcode && findPostcode.length > 0) {
                findPostcode.forEach((number) => {
                  if (number.length === 5) {
                    _postcode = number;
                  }
                });
              }
            }
    
            let _phoneNumber = ""
            if (PrimaryContactPhone) {
              if (PrimaryContactPhone.includes(",")) {
                _phoneNumber = PrimaryContactPhone.split(",")[0]
              } else {
                _phoneNumber = PrimaryContactPhone
              }
            }

            _phoneNumber = _phoneNumber.replaceAll("-", "")
    
            let _customer = {
              addressLineOne: FullPrimaryAddress.replace(`${_postcode}`, "").replace(/THA$/,"").replace(/\n/g," "),
              addressLineTwo: "",
              branchNumber: _branchNumber,
              branchText: _branchText,
              email: PrimaryContactEmail,
              name: _customerName,
              phone: _phoneNumber,
              phoneCountryCode: "+66",
              postcode: _postcode,
              taxId: _taxId,
              taxNumberType: _taxNumberType,
              language: "th",
              unstructure: true,
            };
    
            let _vatPriceTotal = 0;
            let _zeroVatPriceTotal = 0;
            let _nonVatPriceTotal = 0;
    
            item.items.forEach((item) => {
              if (item.percentVat === percentVat) {
                _vatPriceTotal = _vatPriceTotal + item.total;
              } else if (item.percentVat === zeroVat) {
                _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
              } else if (item.percentVat === nonVat) {
                _nonVatPriceTotal = _nonVatPriceTotal + item.total;
              }
            });
    
            history.push({
              pathname: documentPath,
              state: {
                number: item.number,
                reference: "",
                company: {
                  ...company,
                  includeVat: _includeVat,
                },
                customer: _customer,
                date: _date,
                dateBE: _dateBE,
                dateCE: _dateCE,
                // dueDate: _dueDate,
                // dueDateBE: _dueDateBE,
                // dueDateCE: _dueDateCE,
                items: item.items,
                includeVat: _includeVat,
                discount: item.discount * 1,
                amountTotal: item.amountTotal * 1,
                vatTotal: item.vatTotal * 1,
                grandTotal: item.grandTotal * 1,
                nonVatPriceTotal: _nonVatPriceTotal,
                zeroVatPriceTotal: _zeroVatPriceTotal,
                vatPriceTotal: _vatPriceTotal,
                percentVat: percentVat,
                receivedBy: "",
                reIssue: false,
                backUrl: "/etax/sources/dynamics/finance-operations",
                grandTotalTH: numberToTextTh(item.grandTotal * 1),
                grandTotalEN: numberToTextEn(item.grandTotal * 1),
              },
            });

          } else {
            const _date = item.documentDate
            const _dateBE = onFormatDate(new Date(_date), "BE")
            const _dateCE = onFormatDate(new Date(_date), "CE")
    
            // let _dateBE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + (_date.getFullYear() + 543);
            // let _dateCE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + _date.getFullYear();
    
            // let dueD = item.dueDate.split("-");
    
            // let _dueDate = new Date(dueD[2] + "/" + dueD[1] + "/" + dueD[0]);
    
            // let _dueDateBE =
            //   `${_dueDate.getDate()}`.padStart(2, "0") + "/" + `${_dueDate.getMonth() + 1}`.padStart(2, "0") + "/" + (_dueDate.getFullYear() + 543);
            // let _dueDateCE =
            //   `${_dueDate.getDate()}`.padStart(2, "0") + "/" + `${_dueDate.getMonth() + 1}`.padStart(2, "0") + "/" + _dueDate.getFullYear();
            let _customer = {
              addressLineOne: "",
              addressLineTwo: "",
              branchNumber: "",
              branchText: "",
              email: "",
              name: "",
              phone: "",
              phoneCountryCode: "+66",
              postcode: "",
              taxId: "",
              taxNumberType: "",
              language: "th",
              unstructure: true,
            };

            let _includeVat = true
    
            let _vatPriceTotal = 0;
            let _zeroVatPriceTotal = 0;
            let _nonVatPriceTotal = 0;
    
            item.items.forEach((item) => {
              if (item.percentVat === percentVat) {
                _vatPriceTotal = _vatPriceTotal + item.total;
              } else if (item.percentVat === zeroVat) {
                _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
              } else if (item.percentVat === nonVat) {
                _nonVatPriceTotal = _nonVatPriceTotal + item.total;
              }
            });
    
            history.push({
              pathname: documentPath.replace('/confirm', ''),
              state: {
                command: "EDIT",
                number: item.number,
                reference: "",
                company: {
                  ...company,
                  includeVat: _includeVat,
                },
                customer: _customer,
                date: _date,
                dateBE: _dateBE,
                dateCE: _dateCE,
                // dueDate: _dueDate,
                // dueDateBE: _dueDateBE,
                // dueDateCE: _dueDateCE,
                items: item.items,
                includeVat: _includeVat,
                discount: item.discount * 1,
                amountTotal: item.amountTotal * 1,
                vatTotal: item.vatTotal * 1,
                grandTotal: item.grandTotal * 1,
                nonVatPriceTotal: _nonVatPriceTotal,
                zeroVatPriceTotal: _zeroVatPriceTotal,
                vatPriceTotal: _vatPriceTotal,
                percentVat: percentVat,
                receivedBy: "",
                reIssue: false,
                backUrl: "/etax/sources/dynamics/finance-operations",
                grandTotalTH: numberToTextTh(item.grandTotal * 1),
                grandTotalEN: numberToTextEn(item.grandTotal * 1),
              
              },
            });
          }
  
  
        }

        
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    console.log("userEffect initial call!");

    setIsLoading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + "/etax/companies", {
            headers: {
              "Authorization": "Bearer " + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  if (!_company.includeVat) {
                    _company.includeVat = true;
                  }
  
                  setCompany(_company);
                } else {
                  if (!response.data[0].includeVat) {
                    response.data[0].includeVat = true;
                  }
  
                  setCompany(response.data[0]);
                }
              } else {
                history.push({
                  pathname: "/etax/companies/profiles",
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );

        axios
        //   .get("http://localhost:7071/api/DynamicsFinanceOperations_getCompanies", {
          .get(API_URL + '/sources/dynamics/fo/companies', {
            headers: {
              "Authorization": "Bearer " + tokenResponse.accessToken,
              "cid": companyId
            },
          })
          .then(
            (response) => {
              console.log("Dynamics companies: ", response);

              if (response.data && response.data.value.length > 0) {
                // console.log("Dynaics companies: ", response.data);

                setDynamicsCompanies(response.data.value);

                if (response.data.value.some(_company => _company.DataArea === sessionStorage.getItem("ms-fo-company-key"))) {
                  setDynamicsCompany({
                    key: sessionStorage.getItem("ms-fo-company-key"),
                    text: sessionStorage.getItem("ms-fo-company-text"),
                  });
                } else {
                  setDynamicsCompany({
                    key: response.data.value[0].DataArea,
                    text: response.data.value[0].Name,
                  });
                }

                setGetNewDocuments(true);
              } else {
                // disconnect(true);
              }

              setIsLoading(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        // instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  const createCreditNote = async (referInvoiceNumber, item) => {
    setIsLoading(true);

    let documentPath = "/etax/documents/credit-notes/confirm";

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        const [responseSearchReferNumber, responseCustomer, responseInvoiceDetail] = await Promise.all([
          axios
            // .get("http://localhost:7071/api/DynamicsFinanceOperations_searchSalesInvoice", {
            .get(API_URL + '/sources/dynamics/fo/salesinvoicelines', {
                headers: {
                "Authorization": "Bearer " + tokenResponse.accessToken,
                "cid": companyId,
              },
              params: {
                dataAreaId: dynamicsCompany.key,
                InvoiceNumber: referInvoiceNumber,
              },
            }),
    
          axios
          // .get("http://localhost:7071/api/DynamicsFinanceOperations_getCustomer", {
          .get(API_URL + '/sources/dynamics/fo/customers', {
            headers: {
              "Authorization": "Bearer " + tokenResponse.accessToken,
              "cid": companyId
            },
            params: {
              dataAreaId: dynamicsCompany.key,
              CustomerAccount: item.customerId,
            },
          })
          .catch((error) => console.log(error)),
    
          axios
            // .get("http://localhost:7071/api/DynamicsFinanceOperations_getSalesInvoiceV2Lines", {
            .get(API_URL + '/sources/dynamics/fo/salesinvoicelines', {
              headers: {
                "Authorization": "Bearer " + tokenResponse.accessToken,
                "cid": companyId
              },
              params: {
                dataAreaId: dynamicsCompany.key,
                InvoiceNumber: item.number,
              },
            })
            .catch((error) => console.log(error)),
        ]);
    
        const _customerInfo = responseCustomer.data.value[0];
        const invoiceLineRefer = responseSearchReferNumber.data.value;
        const invoiceLine = responseInvoiceDetail.data.value;

        console.log("invoiceLineRefer", invoiceLineRefer)
        console.log("invoiceLine", invoiceLine)

        if (!invoiceLineRefer.length) {
          setIsLoading(false);
          return;
        }
    
        const { PartyType, FullPrimaryAddress, PrimaryContactEmail, PrimaryContactPhone, 
          OrganizationName, AddressZipCode, IsSalesTaxIncludedInPrices, IdentificationNumber, 
          BPC_Tax_VATid, BPC_Tax_WHTid, BPC_HeadOffice, BPC_BranchNo } = _customerInfo
    
        const _date = item.documentDate
        const _dateBE = onFormatDate(new Date(_date), "BE")
        const _dateCE = onFormatDate(new Date(_date), "CE")
    
        let _includeVat = true
        if (IsSalesTaxIncludedInPrices === "No") {
          _includeVat = false
        }
    
        let _taxId = "N/A";
        let _taxNumberType = "OTHR";
        let _branchNumber = "";
        let _branchText = "";
    
        if (BPC_Tax_VATid && BPC_Tax_VATid.length === 13) {
          _taxId = BPC_Tax_VATid;
        } else if (BPC_Tax_WHTid && BPC_Tax_WHTid.length === 13) {
          _taxId = BPC_Tax_WHTid;
        } else if (IdentificationNumber && IdentificationNumber.length === 13) {
          _taxId = IdentificationNumber;
        } else if (IdentificationNumber && IdentificationNumber.length !== 13) {
          _taxId = IdentificationNumber;
          _taxNumberType = "CCPT";
        }
    
        let _customerName = OrganizationName
    
        const { name, branchNumber, branchText } = getBranchFromName(OrganizationName)
    
        if (branchNumber) {
          _customerName = name
          _taxNumberType = "TXID";
          _branchNumber = branchNumber
          _branchText = branchText
        } else {
          if (PartyType) {
            if (PartyType === "Organization") {
              _taxNumberType = "TXID";
    
              if (BPC_HeadOffice === "Yes") {
                _branchNumber = "00000";
                _branchText = "Head Office";
              } else {
                if (BPC_BranchNo) {
                  _branchNumber = BPC_BranchNo.padStart(5, "0")
                  _branchText = "Branch " + _branchNumber
                } else {
                  _branchNumber = "00000";
                  _branchText = "Head Office";
                }
              }
            } else {
              _taxNumberType = "NIDN";
            }
          }
        }
    
    
        if (_taxId === "N/A" || !_taxId || Number(_taxId) === 0) {
          _taxId = "N/A"
          _taxNumberType = "OTHR";
          _branchNumber = "";
          _branchText = "";
        } else if (_taxId.length !== 13) {
          _taxNumberType = "CCPT"
        }
    
        let _postcode = "00000"
        if (AddressZipCode) {
          _postcode = AddressZipCode
        } else {
          let findPostcode = FullPrimaryAddress.match(/\d+/g);
    
          if (findPostcode && findPostcode.length > 0) {
            findPostcode.forEach((number) => {
              if (number.length === 5) {
                _postcode = number;
              }
            });
          }
        }
    
        let _phoneNumber = ""
        if (PrimaryContactPhone) {
          if (PrimaryContactPhone.includes(",")) {
            _phoneNumber = PrimaryContactPhone.split(",")[0]
          } else {
            _phoneNumber = PrimaryContactPhone
          }
        }
    
        _phoneNumber = _phoneNumber.replaceAll("-", "")
    
        let _customer = {
          addressLineOne: FullPrimaryAddress.replace(`${_postcode}`, ""),
          addressLineTwo: "",
          branchNumber: _branchNumber,
          branchText: _branchText,
          email: PrimaryContactEmail,
          name: _customerName,
          phone: _phoneNumber,
          phoneCountryCode: "+66",
          postcode: _postcode,
          taxId: _taxId,
          taxNumberType: _taxNumberType,
          language: "th",
          unstructure: true,
        };
    
        let _items = [];
        let _amountTotal = 0
    
        for (let i = 0; i < invoiceLine.length; i++) {
          const { LineTotalTaxAmount, ProductDescription, InvoicedQuantity, 
            SalesPrice, LineTotalDiscountAmount, LineAmount } = invoiceLine[i]
    
          let _percentVat = zeroVat;
          let _percentVatText = zeroVatText;
          if (Math.abs(LineTotalTaxAmount) > 0) {
            _percentVat = percentVat;
            _percentVatText = percentVatText;
          }
    
          _amountTotal = _amountTotal + Math.abs(LineAmount)
    
          _items.push({
            number: i + 1 + "",
            description: ProductDescription || "-",
            quantity: InvoicedQuantity,
            unitCode: "",
            unitName: "-",
            price: Math.abs(SalesPrice),
            discount: Math.abs(Math.round((LineTotalDiscountAmount / InvoicedQuantity) * 100) / 100),
            total: Math.abs(LineAmount),
            percentVat: _percentVat,
            percentVatText: _percentVatText,
          });
        }
    
        let _vatPriceTotal = 0;
        let _zeroVatPriceTotal = 0;
        let _nonVatPriceTotal = 0;
    
        _items.forEach((item) => {
          if (item.percentVat === percentVat) {
            _vatPriceTotal = _vatPriceTotal + item.total;
          } else if (item.percentVat === zeroVat) {
            _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
          } else if (item.percentVat === nonVat) {
            _nonVatPriceTotal = _nonVatPriceTotal + item.total;
          }
        });
    
        let previousAmountTotal = 0
        let _discount = 0
        const _referItems = []
    
        for (let i = 0; i < invoiceLineRefer.length; i++) {
          const { LineTotalTaxAmount, ProductDescription, InvoicedQuantity, 
            SalesPrice, LineTotalDiscountAmount, LineAmount } = invoiceLineRefer[i]
    
          let _percentVat = zeroVat;
          let _percentVatText = zeroVatText;
          if (LineTotalTaxAmount > 0) {
            _percentVat = percentVat;
            _percentVatText = percentVatText;
          }
    
          previousAmountTotal = previousAmountTotal + LineAmount
    
          _referItems.push({
            number: i + 1 + "",
            description: ProductDescription || "-",
            quantity: InvoicedQuantity,
            unitCode: "",
            unitName: "-",
            price: SalesPrice,
            discount: Math.round((LineTotalDiscountAmount / InvoicedQuantity) * 100) / 100,
            total: LineAmount,
            percentVat: _percentVat,
            percentVatText: _percentVatText,
          });
        }
    
        let discountDisable = false;
        let vatTypeArray = [];
    
        _referItems.forEach((item) => {
          if (!vatTypeArray.includes(item.percentVat)) {
            vatTypeArray.push(item.percentVat);
          }
          if (vatTypeArray.length > 1) {
            discountDisable = true;
            _discount = 0;
          }
        });
    
    
          let sumAmountTotal = 0;
    
          let sumDiffAmountTotal = 0;
          let sumVatTotal = 0;
          let sumGrandTotal = 0;
    
          let zeroVatPrice = 0;
          let vatPrice = 0;
    
          if (_includeVat) {
            for (let i = 0; i < _items.length; i++) {
              sumGrandTotal += _items[i]['total'];
              if (_items[i].percentVat === zeroVat) {
                zeroVatPrice += _items[i]['total'];
              } else {
                vatPrice += _items[i]['total'];
              }
            }
    
            if (!discountDisable) {
              sumGrandTotal -= _discount;
              if (vatPrice !== 0) {
                vatPrice -= _discount;
              } else if (zeroVatPrice !== 0) {
                zeroVatPrice -= _discount;
              }
            }
    
            sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;
    
            zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;
            vatPrice = Math.round(vatPrice * 100) / 100;
    
            sumVatTotal = (vatPrice * percentVat) / (100 + percentVat) + (zeroVatPrice * zeroVat) / (100 + zeroVat);
            sumVatTotal = Math.round(sumVatTotal * 100) / 100;
    
            sumDiffAmountTotal = sumGrandTotal - sumVatTotal;
            sumDiffAmountTotal = Math.round(sumDiffAmountTotal * 100) / 100;
    
            sumAmountTotal = previousAmountTotal - sumDiffAmountTotal;
            sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;
          } else {
            for (let i = 0; i < _items.length; i++) {
              sumDiffAmountTotal += _items[i]['total'];
              if (_items[i].percentVat === zeroVat) {
                zeroVatPrice += _items[i]['total'];
              } else {
                vatPrice += _items[i]['total'];
              }
            }
    
            if (!discountDisable) {
              sumDiffAmountTotal -= _discount;
              if (vatPrice !== 0) {
                vatPrice -= _discount;
              } else if (zeroVatPrice !== 0) {
                zeroVatPrice -= _discount;
              }
            }
    
            sumDiffAmountTotal = Math.round(sumDiffAmountTotal * 100) / 100;
    
            zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;
            vatPrice = Math.round(vatPrice * 100) / 100;
    
            sumVatTotal = (vatPrice * percentVat) / 100;
            sumVatTotal = Math.round(sumVatTotal * 100) / 100;
    
            sumAmountTotal = previousAmountTotal - sumDiffAmountTotal;
            sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;
    
            sumGrandTotal = sumDiffAmountTotal + sumVatTotal;
            sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;
          }
    
          let referDate = '';
          let referDateBE = '';
          let referDateCE = '';
          
          referDate = invoiceLineRefer[0].InvoiceDate
          referDateBE = onFormatDate(new Date(referDate), "BE")
          referDateCE = onFormatDate(new Date(referDate), "CE")
          
    
          const _refer = {
            number: referInvoiceNumber || 'N/A',
            reasonCode: 'CDNG99',
            reasonName: 'เหตุอื่น (ระบุสาเหตุ กรณีขายสินค้า)',
            specificReason: 'N/A',
            typeCode: '388',
            typeName: 'ใบกำกับภาษี',
            date: referDate,
            dateBE: referDateBE,
            dateCE: referDateCE,
            amountTotal: previousAmountTotal >= 0 ? previousAmountTotal : 0,
            diffAmountTotal: sumDiffAmountTotal >= 0 ? sumDiffAmountTotal : 0,
            items: _referItems,
          };
    
          history.push({
            pathname: documentPath,
            state: {
              command: 'EditConfirm',
              amountTotal: sumAmountTotal >= 0 ? sumAmountTotal : 0,
              company: {
                ...company,
                includeVat: _includeVat,
              },
              number: item.number,
              reference: "",
              customer: _customer,
              date: _date,
              dateBE: _dateBE,
              dateCE: _dateCE,
              grandTotal: sumGrandTotal >= 0 ? sumGrandTotal : 0,
              items: _items,
              percentVat: percentVat,
              vatTotal: sumVatTotal >= 0 ? sumVatTotal : 0,
              discount: _discount,
              backUrl: "/etax/sources/dynamics/finance-operations",
              includeVat: _includeVat,
              refer: _refer,
              calculationMethod: 'creditPrice',
            },
          });

      }).catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
    
  }

  const createSelectedCreditNote = async () => {
    let _selectionItems = selection.getItems();
    
    let postUrl = API_URL + '/etax/documents/credit-notes/pdf-xml'

    if (postUrl) {
      await instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          for (let i = 0; i < selectedItems.length; i++) {
            for (let j = 0; j < _selectionItems.length; j++) {
              if (_selectionItems[j].key === selectedItems[i].key) {
                _selectionItems[j].status = "processing";

                if (selectedItems[i].grandTotal > 0) {
                  _selectionItems[j].status = "fail";

                  setItems([..._selectionItems]);

                  continue;
                }

                setItems([..._selectionItems]);

                let _payload = {}

                const [responseSearchReferNumber, responseCustomer, responseInvoiceDetail] = await Promise.all([
                  axios
                    // .get("http://localhost:7071/api/DynamicsFinanceOperations_searchSalesInvoice", {
                    .get(API_URL + '/sources/dynamics/fo/salesinvoicelines', {
                        headers: {
                        "Authorization": "Bearer " + tokenResponse.accessToken,
                        "cid": companyId,
                      },
                      params: {
                        dataAreaId: dynamicsCompany.key,
                        InvoiceNumber: selectedItems[i].referInvoiceNumber,
                      },
                    }),
            
                  axios
                  // .get("http://localhost:7071/api/DynamicsFinanceOperations_getCustomer", {
                  .get(API_URL + '/sources/dynamics/fo/customers', {
                    headers: {
                      "Authorization": "Bearer " + tokenResponse.accessToken,
                      "cid": companyId
                    },
                    params: {
                      dataAreaId: dynamicsCompany.key,
                      CustomerAccount: selectedItems[i].customerId,
                    },
                  })
                  .catch((error) => console.log(error)),
            
                  axios
                    // .get("http://localhost:7071/api/DynamicsFinanceOperations_getSalesInvoiceV2Lines", {
                    .get(API_URL + '/sources/dynamics/fo/salesinvoicelines', {
                      headers: {
                        "Authorization": "Bearer " + tokenResponse.accessToken,
                        "cid": companyId
                      },
                      params: {
                        dataAreaId: dynamicsCompany.key,
                        InvoiceNumber: selectedItems[i].number,
                      },
                    })
                    .catch((error) => console.log(error)),
                ]);
            
                const _customerInfo = responseCustomer.data.value[0];
                const invoiceLineRefer = responseSearchReferNumber.data.value;
                const invoiceLine = responseInvoiceDetail.data.value;
        
                console.log("invoiceLineRefer", invoiceLineRefer)
                console.log("invoiceLine", invoiceLine)
        
                if (!invoiceLineRefer.length || !_customerInfo) {
                  _selectionItems[j].status = "fail";

                    setItems([..._selectionItems]);

                    continue;
                }
            
                const { PartyType, FullPrimaryAddress, PrimaryContactEmail, PrimaryContactPhone, 
                  OrganizationName, AddressZipCode, IsSalesTaxIncludedInPrices, IdentificationNumber, 
                  BPC_Tax_VATid, BPC_Tax_WHTid, BPC_HeadOffice, BPC_BranchNo } = _customerInfo
            
                const _date = selectedItems[i].documentDate
                const _dateBE = onFormatDate(new Date(_date), "BE")
                const _dateCE = onFormatDate(new Date(_date), "CE")
            
                let _includeVat = true
                if (IsSalesTaxIncludedInPrices === "No") {
                  _includeVat = false
                }
            
                let _taxId = "N/A";
                let _taxNumberType = "OTHR";
                let _branchNumber = "";
                let _branchText = "";
            
                if (BPC_Tax_VATid && BPC_Tax_VATid.length === 13) {
                  _taxId = BPC_Tax_VATid;
                } else if (BPC_Tax_WHTid && BPC_Tax_WHTid.length === 13) {
                  _taxId = BPC_Tax_WHTid;
                } else if (IdentificationNumber && IdentificationNumber.length === 13) {
                  _taxId = IdentificationNumber;
                } else if (IdentificationNumber && IdentificationNumber.length !== 13) {
                  _taxId = IdentificationNumber;
                  _taxNumberType = "CCPT";
                }
            
                let _customerName = OrganizationName
            
                const { name, branchNumber, branchText } = getBranchFromName(OrganizationName)
            
                if (branchNumber) {
                  _customerName = name
                  _taxNumberType = "TXID";
                  _branchNumber = branchNumber
                  _branchText = branchText
                } else {
                  if (PartyType) {
                    if (PartyType === "Organization") {
                      _taxNumberType = "TXID";
            
                      if (BPC_HeadOffice === "Yes") {
                        _branchNumber = "00000";
                        _branchText = "Head Office";
                      } else {
                        if (BPC_BranchNo) {
                          _branchNumber = BPC_BranchNo.padStart(5, "0")
                          _branchText = "Branch " + _branchNumber
                        } else {
                          _branchNumber = "00000";
                          _branchText = "Head Office";
                        }
                      }
                    } else {
                      _taxNumberType = "NIDN";
                    }
                  }
                }
            
            
                if (_taxId === "N/A" || !_taxId || Number(_taxId) === 0) {
                  _taxId = "N/A"
                  _taxNumberType = "OTHR";
                  _branchNumber = "";
                  _branchText = "";
                } else if (_taxId.length !== 13) {
                  _taxNumberType = "CCPT"
                }
            
                let _postcode = "00000"
                if (AddressZipCode) {
                  _postcode = AddressZipCode
                } else {
                  let findPostcode = FullPrimaryAddress.match(/\d+/g);
            
                  if (findPostcode && findPostcode.length > 0) {
                    findPostcode.forEach((number) => {
                      if (number.length === 5) {
                        _postcode = number;
                      }
                    });
                  }
                }
            
                let _phoneNumber = ""
                if (PrimaryContactPhone) {
                  if (PrimaryContactPhone.includes(",")) {
                    _phoneNumber = PrimaryContactPhone.split(",")[0]
                  } else {
                    _phoneNumber = PrimaryContactPhone
                  }
                }
            
                _phoneNumber = _phoneNumber.replaceAll("-", "")
            
                let _customer = {
                  addressLineOne: FullPrimaryAddress.replace(`${_postcode}`, ""),
                  addressLineTwo: "",
                  branchNumber: _branchNumber,
                  branchText: _branchText,
                  email: PrimaryContactEmail,
                  name: _customerName,
                  phone: _phoneNumber,
                  phoneCountryCode: "+66",
                  postcode: _postcode,
                  taxId: _taxId,
                  taxNumberType: _taxNumberType,
                  language: "th",
                  unstructure: true,
                };
            
                let _items = [];
                let _amountTotal = 0
            
                for (let i = 0; i < invoiceLine.length; i++) {
                  const { LineTotalTaxAmount, ProductDescription, InvoicedQuantity, 
                    SalesPrice, LineTotalDiscountAmount, LineAmount } = invoiceLine[i]
            
                  let _percentVat = zeroVat;
                  let _percentVatText = zeroVatText;
                  if (Math.abs(LineTotalTaxAmount) > 0) {
                    _percentVat = percentVat;
                    _percentVatText = percentVatText;
                  }
            
                  _amountTotal = _amountTotal + Math.abs(LineAmount)
            
                  _items.push({
                    number: i + 1 + "",
                    description: ProductDescription || "-",
                    quantity: InvoicedQuantity,
                    unitCode: "",
                    unitName: "-",
                    price: Math.abs(SalesPrice),
                    discount: Math.abs(Math.round((LineTotalDiscountAmount / InvoicedQuantity) * 100) / 100),
                    total: Math.abs(LineAmount),
                    percentVat: _percentVat,
                    percentVatText: _percentVatText,
                  });
                }
            
                let _vatPriceTotal = 0;
                let _zeroVatPriceTotal = 0;
                let _nonVatPriceTotal = 0;
            
                _items.forEach((item) => {
                  if (item.percentVat === percentVat) {
                    _vatPriceTotal = _vatPriceTotal + item.total;
                  } else if (item.percentVat === zeroVat) {
                    _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                  } else if (item.percentVat === nonVat) {
                    _nonVatPriceTotal = _nonVatPriceTotal + item.total;
                  }
                });
            
                let previousAmountTotal = 0
                let _discount = 0
                const _referItems = []
            
                for (let i = 0; i < invoiceLineRefer.length; i++) {
                  const { LineTotalTaxAmount, ProductDescription, InvoicedQuantity, 
                    SalesPrice, LineTotalDiscountAmount, LineAmount } = invoiceLineRefer[i]
            
                  let _percentVat = zeroVat;
                  let _percentVatText = zeroVatText;
                  if (LineTotalTaxAmount > 0) {
                    _percentVat = percentVat;
                    _percentVatText = percentVatText;
                  }
            
                  previousAmountTotal = previousAmountTotal + LineAmount
            
                  _referItems.push({
                    number: i + 1 + "",
                    description: ProductDescription || "-",
                    quantity: InvoicedQuantity,
                    unitCode: "",
                    unitName: "-",
                    price: SalesPrice,
                    discount: Math.round((LineTotalDiscountAmount / InvoicedQuantity) * 100) / 100,
                    total: LineAmount,
                    percentVat: _percentVat,
                    percentVatText: _percentVatText,
                  });
                }
            
                let discountDisable = false;
                let vatTypeArray = [];
            
                _referItems.forEach((item) => {
                  if (!vatTypeArray.includes(item.percentVat)) {
                    vatTypeArray.push(item.percentVat);
                  }
                  if (vatTypeArray.length > 1) {
                    discountDisable = true;
                    _discount = 0;
                  }
                });
            
            
                  let sumAmountTotal = 0;
            
                  let sumDiffAmountTotal = 0;
                  let sumVatTotal = 0;
                  let sumGrandTotal = 0;
            
                  let nonVatPrice = 0;
                  let zeroVatPrice = 0;
                  let vatPrice = 0;
            
                  if (_includeVat) {
                    for (let i = 0; i < _items.length; i++) {
                      sumGrandTotal += _items[i]['total'];
                      if (_items[i].percentVat === nonVat) {
                        nonVatPrice += _items[i]['total'];
                      } else if (_items[i].percentVat === zeroVat) {
                        zeroVatPrice += _items[i]['total'];
                      } else {
                        vatPrice += _items[i]['total'];
                      }
                    }
            
                    if (!discountDisable) {
                      sumGrandTotal -= _discount;
                      if (vatPrice !== 0) {
                        vatPrice -= _discount;
                      } else if (zeroVatPrice !== 0) {
                        zeroVatPrice -= _discount;
                      } else {
                        nonVatPrice -= _discount;
                      }
                    }
            
                    sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;
            
                    nonVatPrice = Math.round(nonVatPrice * 100) / 100;
                    zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;
                    vatPrice = Math.round(vatPrice * 100) / 100;
            
                    sumVatTotal = (vatPrice * percentVat) / (100 + percentVat) + (zeroVatPrice * zeroVat) / (100 + zeroVat);
                    sumVatTotal = Math.round(sumVatTotal * 100) / 100;
            
                    sumDiffAmountTotal = sumGrandTotal - sumVatTotal;
                    sumDiffAmountTotal = Math.round(sumDiffAmountTotal * 100) / 100;
            
                    sumAmountTotal = previousAmountTotal - sumDiffAmountTotal;
                    sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;
                  } else {
                    for (let i = 0; i < _items.length; i++) {
                      sumDiffAmountTotal += _items[i]['total'];
                      if (_items[i].percentVat === nonVat) {
                        nonVatPrice += _items[i]['total'];
                      } else if (_items[i].percentVat === zeroVat) {
                        zeroVatPrice += _items[i]['total'];
                      } else {
                        vatPrice += _items[i]['total'];
                      }
                    }
            
                    if (!discountDisable) {
                      sumDiffAmountTotal -= _discount;
                      if (vatPrice !== 0) {
                        vatPrice -= _discount;
                      } else if (zeroVatPrice !== 0) {
                        zeroVatPrice -= _discount;
                      } else {
                        nonVatPrice -= _discount;
                      }
                    }
            
                    sumDiffAmountTotal = Math.round(sumDiffAmountTotal * 100) / 100;
            
                    nonVatPrice = Math.round(nonVatPrice * 100) / 100;
                    zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;
                    vatPrice = Math.round(vatPrice * 100) / 100;
            
                    sumVatTotal = (vatPrice * percentVat) / 100;
                    sumVatTotal = Math.round(sumVatTotal * 100) / 100;
            
                    sumAmountTotal = previousAmountTotal - sumDiffAmountTotal;
                    sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;
            
                    sumGrandTotal = sumDiffAmountTotal + sumVatTotal;
                    sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;
                  }
            
                  let referDate = '';
                  let referDateBE = '';
                  let referDateCE = '';
                  
                  referDate = invoiceLineRefer[0].InvoiceDate
                  referDateBE = onFormatDate(new Date(referDate), "BE")
                  referDateCE = onFormatDate(new Date(referDate), "CE")
                  
            
                  const _refer = {
                    number: selectedItems[i].referInvoiceNumber || 'N/A',
                    reasonCode: 'CDNG99',
                    reasonName: 'เหตุอื่น (ระบุสาเหตุ กรณีขายสินค้า)',
                    specificReason: 'N/A',
                    typeCode: '388',
                    typeName: 'ใบกำกับภาษี',
                    date: referDate,
                    dateBE: referDateBE,
                    dateCE: referDateCE,
                    amountTotal: previousAmountTotal >= 0 ? previousAmountTotal : 0,
                    diffAmountTotal: sumDiffAmountTotal >= 0 ? sumDiffAmountTotal : 0,
                    items: _referItems,
                  };
            
                  _payload = {
                    number: selectedItems[i].number,
                    company: {
                      ...company,
                      includeVat: _includeVat,
                    },
                    reference: "",
                    customer: _customer,
                    date: _date,
                    dateBE: _dateBE,
                    dateCE: _dateCE,
                    amountTotal: sumAmountTotal >= 0 ? sumAmountTotal : 0,
                    vatTotal: sumVatTotal >= 0 ? sumVatTotal : 0,
                    grandTotal: sumGrandTotal >= 0 ? sumGrandTotal : 0,
                    items: _items,
                    percentVat: percentVat,
                    discount: _discount,
                    includeVat: _includeVat,
                    nonVatPriceTotal: _nonVatPriceTotal,
                    zeroVatPriceTotal: _zeroVatPriceTotal,
                    vatPriceTotal: _vatPriceTotal,
                    refer: _refer,
                    receivedBy: createdBy,
                    createdBy: createdBy,
                    note: '',
                  }
                  console.log("_referItems", _referItems)
                  console.log("_payload", _payload)

                  const promise_PDF_XML = await new Promise(async (resolve) => {
                    await axios
                      .post(postUrl, _payload, {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                        },
                      })
                      .then(
                        async (response) => {
                          console.log('Create document response: ', response);
    
                          let count = 0;
    
                          let interval = setInterval(async () => {
                            await axios
                              .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                                headers: {
                                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                                  'Content-Type': 'application/json',
                                },
                              })
                              .then((jobsResponse) => {
                                count += 1;
                                console.log('Jobs response: ', j, jobsResponse.data.status, count);
    
                                if (jobsResponse.data.status === 'complete') {
                                  _selectionItems[j].status = 'successful';
                                  _selectionItems[j].etaxCreated = true;
    
                                  setItems([..._selectionItems]);
    
                                  clearInterval(interval);
    
                                  resolve(true);
                                } else if (count === 300) {
                                  _selectionItems[j].status = 'fail';
    
                                  setItems([..._selectionItems]);
    
                                  clearInterval(interval);
    
                                  resolve(false);
                                }
                              });
                          }, 1000);
                        },
                        (error) => {
                          console.log('Error Create document response: ', error);
                          _selectionItems[j].status = 'fail';
                          setItems([..._selectionItems]);
                          resolve(false);
                        }
                      );
                  });
    
                  Promise.all([promise_PDF_XML]).then((value) => {
                    console.log(i, value);

                  });
              }
            }
          }
        }).catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });

        setIsCreateDocumentComplete(true);
        setIsCreateDocument(false);
    }

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  }

  useEffect(() => {
    if (getNewDocuments) {
      if (search) {
        const searchInvoices = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
                const [response, responseAllDocs] = await Promise.all([
                  axios
                    // .get("http://localhost:7071/api/DynamicsFinanceOperations_searchSalesInvoice", {
                    .get(API_URL + '/sources/dynamics/fo/salesinvoices/search', {
                        headers: {
                        "Authorization": "Bearer " + tokenResponse.accessToken,
                        "cid": companyId,
                      },
                      params: {
                        dataAreaId: dynamicsCompany.key,
                        search: search,
                        from: from,
                        to: to,
                      },
                    }),
  
                  axios
                    .get(API_URL + "/etax/documents/ALL/all?from=" + from + "&to=" + to + "&date=" + dateType.key, {
                      headers: {
                        "Authorization": "Bearer " + tokenResponse.accessToken,
                        "cid": companyId
                      },
                    })
                    .catch((error) => console.log(error)),
                ]);

                setAllDocs(responseAllDocs.data);
                   
                console.log("response.data.value", response.data.value)
                let invoice_list = response.data.value
                let total_items = invoice_list.length;

                let _pageDropdown = [];

                for (let i = 1; i <= Math.ceil(total_items / pageSize); i++) {
                  _pageDropdown.push({
                    key: i,
                    text: "หน้า " + i,
                    iconProps: { iconName: "Page" },
                    onClick: () => {
                      console.log("Selected page: " + i);
                      setCurrentPage(i);
                    },
                  });
                }

                setPageDropdown(_pageDropdown);

                if (currentPage > Math.ceil(total_items / pageSize)) {
                  setCurrentPage(1);
                }

                setPageData(paginate(total_items, 1, pageSize, 10));
                setDocuments(invoice_list);

                const _docs = [];

                for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, invoice_list.length); i++) {
                  const { 
                    InvoiceNumber, 
                    InvoiceDate, 
                    InvoiceAddressZipCode, 
                    TotalTaxAmount, 
                    InvoiceAddressStreetNumber, 
                    InvoiceAddressStreet, 
                    TotalDiscountAmount, 
                    InvoiceCustomerAccountNumber, 
                    InvoiceAddressCity, 
                    InvoiceAddressState, 
                    TotalInvoiceAmount, 
                  } = invoice_list[i]
  
                  let _address = "";
  
                  if (InvoiceAddressStreet) {
                    _address = _address + InvoiceAddressStreet;
                  }
  
                  if (InvoiceAddressStreetNumber) {
                    _address = _address + " " + InvoiceAddressStreetNumber;
                  }
  
                  if (InvoiceAddressCity) {
                    _address = _address + " " + InvoiceAddressCity;
                  }
  
                  if (InvoiceAddressState) {
                    _address = _address + " " + InvoiceAddressState;
                  }
  
                  if (InvoiceAddressZipCode) {
                    _address = _address + " " + InvoiceAddressZipCode;
                  }
            
  
                  _docs.push({
                    key: InvoiceNumber + '-' + i,
                    documentDate: InvoiceDate,
                    // dueDate: _dueDate,
                    number: InvoiceNumber,
                    customerId: InvoiceCustomerAccountNumber,
                    // customerName: invoice_list[i].customerName,
                    customerAddress: _address,
                    // customerEmail: invoice_list[i].email,
                    // customerPhoneNumber: invoice_list[i].phoneNumber,
                    // amountTotal: invoice_list[i].totalAmountExcludingTax,
                    vatTotal: TotalTaxAmount,
                    grandTotal: TotalInvoiceAmount,
                    customerPostcode: InvoiceAddressZipCode,
                    discount: TotalDiscountAmount,
                    // includeVat: invoice_list[i].pricesIncludeTax,
                    // receivedBy: invoice_list[i].salesperson,
                    etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === InvoiceNumber),
                  });
                }

                if (primarySort === "documentDate") {
                  if (isSortedDescendingDocumentDate) {
                    _docs.sort(function (a, b) {
                      var aa = a.documentDate,
                        bb = b.documentDate;
                      return aa < bb ? 1 : aa > bb ? -1 : 0;
                    });
                  } else {
                    _docs.sort(function (a, b) {
                      var aa = a.documentDate,
                        bb = b.documentDate;
                      return aa < bb ? -1 : aa > bb ? 1 : 0;
                    });
                  }
                } else if (primarySort === "number") {
                  if (isSortedDescendingNumber) {
                    _docs.sort((a, b) => {
                      if (b.number < a.number) {
                        return -1;
                      }
                      if (b.number > a.number) {
                        return 1;
                      }
                      return 0;
                    });
                  } else {
                    _docs.sort((a, b) => {
                      if (a.number < b.number) {
                        return -1;
                      }
                      if (a.number > b.number) {
                        return 1;
                      }
                      return 0;
                    });
                  }
                } else if (primarySort === "customerName") {
                  if (isSortedDescendingCustomerName) {
                    _docs.sort((a, b) => {
                      if (b.customerId < a.customerId) {
                        return -1;
                      }
                      if (b.customerId > a.customerId) {
                        return 1;
                      }
                      return 0;
                    });
                  } else {
                    _docs.sort((a, b) => {
                      if (a.customerId < b.customerId) {
                        return -1;
                      }
                      if (a.customerId > b.customerId) {
                        return 1;
                      }
                      return 0;
                    });
                  }
                } else if (primarySort === "vatTotal") {
                  if (isSortedDescendingVatTotal) {
                    _docs.sort(function (a, b) {
                      return b.vatTotal - a.vatTotal;
                    });
                  } else {
                    _docs.sort(function (a, b) {
                      return a.vatTotal - b.vatTotal;
                    });
                  }
                } else if (primarySort === "grandTotal") {
                  if (isSortedDescendingGrandTotal) {
                    _docs.sort(function (a, b) {
                      return b.grandTotal - a.grandTotal;
                    });
                  } else {
                    _docs.sort(function (a, b) {
                      return a.grandTotal - b.grandTotal;
                    });
                  }
                }

                setItems(_docs);

                setLoadDataComplete(true);
                setGetNewDocuments(false);
                setIsCreateDocumentComplete(false);
                      
                  
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
            //   instance.acquireTokenRedirect(silentRequest);
              setItems([]);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            });
        };

        const searchLedgerJournal = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
                const [response, responseAllDocs] = await Promise.all([
                  axios
                // .get("http://localhost:7071/api/DynamicsFinanceOperations_searchLedgerJournalLines", {
                .get(API_URL + '/sources/dynamics/fo/ledger-journal-lines/search', {
                    headers: {
                    "Authorization": "Bearer " + tokenResponse.accessToken,
                    "cid": companyId,
                  },
                  params: {
                    dataAreaId: dynamicsCompany.key,
                    search: search,
                    from: from,
                    to: to,
                  },
                }),
  
                  axios
                    .get(API_URL + "/etax/documents/ALL/all?from=" + from + "&to=" + to + "&date=" + dateType.key, {
                      headers: {
                        "Authorization": "Bearer " + tokenResponse.accessToken,
                        "cid": companyId
                      },
                    })
                    .catch((error) => console.log(error)),
                ]);

                setAllDocs(responseAllDocs.data);
                let valueList = response.data.value

                const receiptList = []
                valueList.forEach(_receipt => {
                  if (receiptList.length && receiptList.some(_item => _item.JournalBatchNumber === _receipt.JournalBatchNumber)) {
                    const _index = receiptList.findIndex(_itemInReceipt => _itemInReceipt.JournalBatchNumber === _receipt.JournalBatchNumber)

                    receiptList[_index].items.push({
                      number: (receiptList[_index].items.length + 1) + '',
                      Text: _receipt.Text,
                      CreditAmount: _receipt.CreditAmount,
                      DebitAmount: _receipt.DebitAmount
                    })

                    receiptList[_index].grandTotal = receiptList[_index].grandTotal + _receipt.CreditAmount - _receipt.DebitAmount
                    receiptList[_index].vatTotal = (receiptList[_index].grandTotal * percentVat) / 100
                  } else {
                    const newReceiptObj = {..._receipt}
                    newReceiptObj.items = [{
                      number: 1 + '',
                      Text: _receipt.Text,
                      CreditAmount: _receipt.CreditAmount,
                      DebitAmount: _receipt.DebitAmount
                    }]

                    newReceiptObj.grandTotal = _receipt.CreditAmount - _receipt.DebitAmount
                    newReceiptObj.vatTotal = (newReceiptObj.grandTotal * percentVat) / 100

                    receiptList.push(newReceiptObj)
                  }
                })

                if (primarySort === "documentDate") {
                  if (isSortedDescendingDocumentDate) {
                    receiptList.sort(function (a, b) {
                      var aa = a.TransDate,
                        bb = b.TransDate;
                      return aa < bb ? 1 : aa > bb ? -1 : 0;
                    });
                  } else {
                    receiptList.sort(function (a, b) {
                      var aa = a.TransDate,
                        bb = b.TransDate;
                      return aa < bb ? -1 : aa > bb ? 1 : 0;
                    });
                  }
                } else if (primarySort === "number") {
                  if (isSortedDescendingNumber) {
                    receiptList.sort((a, b) => {
                      if (b.Document < a.Document) {
                        return -1;
                      }
                      if (b.Document > a.Document) {
                        return 1;
                      }
                      return 0;
                    });
                  } else {
                    receiptList.sort((a, b) => {
                      if (a.Document < b.Document) {
                        return -1;
                      }
                      if (a.Document > b.Document) {
                        return 1;
                      }
                      return 0;
                    });
                  }
                } else if (primarySort === "customerName") {
                  if (isSortedDescendingCustomerName) {
                    receiptList.sort((a, b) => {
                      if (b.AccountDisplayValue < a.AccountDisplayValue) {
                        return -1;
                      }
                      if (b.AccountDisplayValue > a.AccountDisplayValue) {
                        return 1;
                      }
                      return 0;
                    });
                  } else {
                    receiptList.sort((a, b) => {
                      if (a.AccountDisplayValue < b.AccountDisplayValue) {
                        return -1;
                      }
                      if (a.AccountDisplayValue > b.AccountDisplayValue) {
                        return 1;
                      }
                      return 0;
                    });
                  }
                } else if (primarySort === "vatTotal") {
                  if (isSortedDescendingVatTotal) {
                    receiptList.sort(function (a, b) {
                      return b.vatTotal - a.vatTotal;
                    });
                  } else {
                    receiptList.sort(function (a, b) {
                      return a.vatTotal - b.vatTotal;
                    });
                  }
                } else if (primarySort === "grandTotal") {
                  if (isSortedDescendingGrandTotal) {
                    receiptList.sort(function (a, b) {
                      return b.grandTotal - a.grandTotal;
                    });
                  } else {
                    receiptList.sort(function (a, b) {
                      return a.grandTotal - b.grandTotal;
                    });
                  }
                }

                let total_items = receiptList.length;

                let _pageDropdown = [];

                for (let i = 1; i <= Math.ceil(total_items / pageSize); i++) {
                  _pageDropdown.push({
                    key: i,
                    text: "หน้า " + i,
                    iconProps: { iconName: "Page" },
                    onClick: () => {
                      console.log("Selected page: " + i);
                      setCurrentPage(i);
                    },
                  });
                }

                setPageDropdown(_pageDropdown);

                if (currentPage > Math.ceil(total_items / pageSize)) {
                  setCurrentPage(1);
                }

                setPageData(paginate(total_items, 1, pageSize, 10));
                setDocuments(receiptList);

                const _docs = [];

                for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, receiptList.length); i++) {
                  const { 
                    JournalBatchNumber,
                    TransDate,
                    Document,
                    AccountDisplayValue,
                    OverrideSalesTax,
                    items
                  } = receiptList[i]
  
                  let _discount = 0
                  let _amountTotal = 0;
                  let _vatTotal = 0;
                  let _grandTotal = 0;

                  let _vatPriceTotal = 0;
                  let _zeroVatPriceTotal = 0;

                  const _items = []

                  items.forEach(_itemWithDebitCredit => {
                    const _text = _itemWithDebitCredit.Text
                    const _price = _itemWithDebitCredit.CreditAmount

                    _discount = _discount + _itemWithDebitCredit.DebitAmount

                    if (_price > 0) {
                      _items.push({
                        number: (_items.length + 1) + '',
                        description: _text || "-",
                        quantity: 1,
                        unitCode: "",
                        unitName: "-",
                        price: _price,
                        discount: 0,
                        total: _price,
                        percentVat: percentVat,
                        percentVatText: percentVatText,
                      })
                    }

                  })

                  let _includeVat = false

                  if (OverrideSalesTax === 'No') {
                    _includeVat = true

                    _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                    _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                    _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                    
                  } else {
                    _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                    _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                  }
                  
                  _docs.push({
                    key: Document + "_docs" + i,
                    documentDate: TransDate,
                    journalNumber: JournalBatchNumber,
                    // dueDate: _dueDate,
                    number: Document,
                    customerId: AccountDisplayValue,
                    // customerName: invoice_list[i].customerName,
                    customerAddress: '',
                    // customerEmail: invoice_list[i].email,
                    // customerPhoneNumber: invoice_list[i].phoneNumber,
                    amountTotal: _amountTotal,
                    vatTotal: _vatTotal,
                    grandTotal: _grandTotal,
                    // customerPostcode: InvoiceAddressZipCode,
                    discount: _discount,
                    includeVat: _includeVat,
                    // includeVat: invoice_list[i].pricesIncludeTax,
                    // receivedBy: invoice_list[i].salesperson,
                    etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === Document),
                    items: _items
                  });
                }

                setItems(_docs);

                setLoadDataComplete(true);
                setGetNewDocuments(false);
                setIsCreateDocumentComplete(false);
                      
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
            //   instance.acquireTokenRedirect(silentRequest);
              setItems([]);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            });
        };

        const searchCustomerPaymentJournal = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {

                const [response, responseAllDocs] = await Promise.all([
                  axios
                // .get("http://localhost:7071/api/DynamicsFinanceOperations_searchCustomerPaymentJournalLines", {
                .get(API_URL + '/sources/dynamics/fo/customer-payment-journal-lines/search', {
                    headers: {
                    "Authorization": "Bearer " + tokenResponse.accessToken,
                    "cid": companyId,
                  },
                  params: {
                    dataAreaId: dynamicsCompany.key,
                    search: search,
                    from: from,
                    to: to,
                  },
                }),
  
                  axios
                    .get(API_URL + "/etax/documents/ALL/all?from=" + from + "&to=" + to + "&date=" + dateType.key, {
                      headers: {
                        "Authorization": "Bearer " + tokenResponse.accessToken,
                        "cid": companyId
                      },
                    })
                    .catch((error) => console.log(error)),
                ]);

                setAllDocs(responseAllDocs.data);

                let valueList = response.data.value

                const receiptList = []
                valueList.forEach(_receipt => {
                  if (receiptList.length && receiptList.some(_item => _item.JournalBatchNumber === _receipt.JournalBatchNumber)) {
                    const _index = receiptList.findIndex(_itemInReceipt => _itemInReceipt.JournalBatchNumber === _receipt.JournalBatchNumber)

                    receiptList[_index].items.push({
                      number: (receiptList[_index].items.length + 1) + '',
                      Text: _receipt.TransactionText,
                      CreditAmount: _receipt.CreditAmount,
                      DebitAmount: _receipt.DebitAmount
                    })

                    receiptList[_index].grandTotal = receiptList[_index].grandTotal + _receipt.CreditAmount - _receipt.DebitAmount
                    receiptList[_index].vatTotal = (receiptList[_index].grandTotal * percentVat) / 100
                  } else {
                    const newReceiptObj = {..._receipt}
                    newReceiptObj.items = [{
                      number: 1 + '',
                      Text: _receipt.TransactionText,
                      CreditAmount: _receipt.CreditAmount,
                      DebitAmount: _receipt.DebitAmount
                    }]

                    newReceiptObj.grandTotal = _receipt.CreditAmount - _receipt.DebitAmount
                    newReceiptObj.vatTotal = (newReceiptObj.grandTotal * percentVat) / 100

                    receiptList.push(newReceiptObj)
                  }
                })

                if (primarySort === "documentDate") {
                  if (isSortedDescendingDocumentDate) {
                    receiptList.sort(function (a, b) {
                      var aa = a.TransactionDate,
                        bb = b.TransactionDate;
                      return aa < bb ? 1 : aa > bb ? -1 : 0;
                    });
                  } else {
                    receiptList.sort(function (a, b) {
                      var aa = a.TransactionDate,
                        bb = b.TransactionDate;
                      return aa < bb ? -1 : aa > bb ? 1 : 0;
                    });
                  }
                } else if (primarySort === "number") {
                  if (isSortedDescendingNumber) {
                    receiptList.sort((a, b) => {
                      if (b.Voucher < a.Voucher) {
                        return -1;
                      }
                      if (b.Voucher > a.Voucher) {
                        return 1;
                      }
                      return 0;
                    });
                  } else {
                    receiptList.sort((a, b) => {
                      if (a.Voucher < b.Voucher) {
                        return -1;
                      }
                      if (a.Voucher > b.Voucher) {
                        return 1;
                      }
                      return 0;
                    });
                  }
                } else if (primarySort === "customerName") {
                  if (isSortedDescendingCustomerName) {
                    receiptList.sort((a, b) => {
                      if (b.AccountDisplayValue < a.AccountDisplayValue) {
                        return -1;
                      }
                      if (b.AccountDisplayValue > a.AccountDisplayValue) {
                        return 1;
                      }
                      return 0;
                    });
                  } else {
                    receiptList.sort((a, b) => {
                      if (a.AccountDisplayValue < b.AccountDisplayValue) {
                        return -1;
                      }
                      if (a.AccountDisplayValue > b.AccountDisplayValue) {
                        return 1;
                      }
                      return 0;
                    });
                  }
                } else if (primarySort === "vatTotal") {
                  if (isSortedDescendingVatTotal) {
                    receiptList.sort(function (a, b) {
                      return b.vatTotal - a.vatTotal;
                    });
                  } else {
                    receiptList.sort(function (a, b) {
                      return a.vatTotal - b.vatTotal;
                    });
                  }
                } else if (primarySort === "grandTotal") {
                  if (isSortedDescendingGrandTotal) {
                    receiptList.sort(function (a, b) {
                      return b.grandTotal - a.grandTotal;
                    });
                  } else {
                    receiptList.sort(function (a, b) {
                      return a.grandTotal - b.grandTotal;
                    });
                  }
                }

                let total_items = receiptList.length;

                let _pageDropdown = [];

                for (let i = 1; i <= Math.ceil(total_items / pageSize); i++) {
                  _pageDropdown.push({
                    key: i,
                    text: "หน้า " + i,
                    iconProps: { iconName: "Page" },
                    onClick: () => {
                      console.log("Selected page: " + i);
                      setCurrentPage(i);
                    },
                  });
                }

                setPageDropdown(_pageDropdown);

                if (currentPage > Math.ceil(total_items / pageSize)) {
                  setCurrentPage(1);
                }

                setPageData(paginate(total_items, 1, pageSize, 10));
                setDocuments(receiptList);

                const _docs = [];

                for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, receiptList.length); i++) {
                  const { 
                    JournalBatchNumber,
                    Voucher,
                    TransactionDate,
                    AccountDisplayValue,
                    OverrideSalesTax,
                    items
                  } = receiptList[i]
  
                  let _discount = 0
                  let _amountTotal = 0;
                  let _vatTotal = 0;
                  let _grandTotal = 0;

                  let _vatPriceTotal = 0;
                  let _zeroVatPriceTotal = 0;

                  const _items = []

                  items.forEach(_itemWithDebitCredit => {
                    const _text = _itemWithDebitCredit.Text
                    const _price = _itemWithDebitCredit.CreditAmount

                    _discount = _discount + _itemWithDebitCredit.DebitAmount

                    if (_price > 0) {
                      _items.push({
                        number: (_items.length + 1) + '',
                        description: _text || "-",
                        quantity: 1,
                        unitCode: "",
                        unitName: "-",
                        price: _price,
                        discount: 0,
                        total: _price,
                        percentVat: percentVat,
                        percentVatText: percentVatText,
                      })
                    }

                  })

                  let _includeVat = false

                  if (OverrideSalesTax === 'No') {
                    _includeVat = true

                    _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                    _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                    _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                    
                  } else {
                    _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                    _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                  }
                  
                  _docs.push({
                    key: Voucher + "_docs" + i,
                    documentDate: TransactionDate,
                    journalNumber: JournalBatchNumber,
                    // dueDate: _dueDate,
                    number: Voucher,
                    customerId: AccountDisplayValue,
                    // customerName: invoice_list[i].customerName,
                    customerAddress: '',
                    // customerEmail: invoice_list[i].email,
                    // customerPhoneNumber: invoice_list[i].phoneNumber,
                    amountTotal: _amountTotal,
                    vatTotal: _vatTotal,
                    grandTotal: _grandTotal,
                    // customerPostcode: InvoiceAddressZipCode,
                    discount: _discount,
                    includeVat: _includeVat,
                    // includeVat: invoice_list[i].pricesIncludeTax,
                    // receivedBy: invoice_list[i].salesperson,
                    etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === Voucher),
                    items: _items
                  });
                }

                setItems(_docs);
                console.log("_docs", _docs)

                setLoadDataComplete(true);
                setGetNewDocuments(false);
                setIsCreateDocumentComplete(false);
                      
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
            //   instance.acquireTokenRedirect(silentRequest);
              setItems([]);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            });
        };

        if (endpointType.key === 'SalesInvoice') {
          searchInvoices();
        } else if (endpointType.key === 'LedgerJournal') {
          searchLedgerJournal();
        } else {
          searchCustomerPaymentJournal()
        }
      } else {
        const listInvoices = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
              const [response, responseAllDocs] = await Promise.all([
                axios
                //   .get("http://localhost:7071/api/DynamicsFinanceOperations_getSalesInvoice", {
                  .get(API_URL + '/sources/dynamics/fo/salesinvoices', {
                    headers: {
                      "Authorization": "Bearer " + tokenResponse.accessToken,
                      "cid": companyId
                    },
                    params: {
                      dataAreaId: dynamicsCompany.key,
                      status: endpointType.key,
                      from: from,
                      to: to,
                    },
                  })
                  .catch((error) => console.log(error)),

                axios
                  .get(API_URL + "/etax/documents/ALL/all?from=" + from + "&to=" + to + "&date=" + dateType.key, {
                    headers: {
                      "Authorization": "Bearer " + tokenResponse.accessToken,
                      "cid": companyId
                    },
                  })
                  .catch((error) => console.log(error)),
              ]);

              console.log(API_URL + "/etax/documents/ALL/all?from=" + from + "&to=" + to + "&date=" + dateType.key)

              setAllDocs(responseAllDocs.data);

              let invoice_list = response.data.value
              let total_items = invoice_list.length;

              console.log(invoice_list)

              let _pageDropdown = [];

              for (let i = 1; i <= Math.ceil(total_items / pageSize); i++) {
                _pageDropdown.push({
                  key: i,
                  text: "หน้า " + i,
                  iconProps: { iconName: "Page" },
                  onClick: () => {
                    console.log("Selected page: " + i);
                    setCurrentPage(i);
                  },
                });
              }

              setPageDropdown(_pageDropdown);

              if (currentPage > Math.ceil(total_items / pageSize)) {
                setCurrentPage(1);
              }

              setPageData(paginate(total_items, 1, pageSize, 10));
              setDocuments(invoice_list);

              const _docs = [];

              for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, invoice_list.length); i++) {
                const { 
                  InvoiceNumber, 
                  InvoiceDate, 
                  InvoiceAddressZipCode, 
                  TotalTaxAmount, 
                  InvoiceAddressStreetNumber, 
                  InvoiceAddressStreet, 
                  TotalDiscountAmount, 
                  InvoiceCustomerAccountNumber, 
                  InvoiceAddressCity, 
                  InvoiceAddressState, 
                  TotalInvoiceAmount, 
                } = invoice_list[i]

                let _address = "";

                if (InvoiceAddressStreet) {
                  _address = _address + InvoiceAddressStreet;
                }

                if (InvoiceAddressStreetNumber) {
                  _address = _address + " " + InvoiceAddressStreetNumber;
                }

                if (InvoiceAddressCity) {
                  _address = _address + " " + InvoiceAddressCity;
                }

                if (InvoiceAddressState) {
                  _address = _address + " " + InvoiceAddressState;
                }

                if (InvoiceAddressZipCode) {
                  _address = _address + " " + InvoiceAddressZipCode;
                }
                

                _docs.push({
                  key: InvoiceNumber + '-' + i,
                  documentDate: InvoiceDate,
                  // dueDate: _dueDate,
                  number: InvoiceNumber,
                  customerId: InvoiceCustomerAccountNumber,
                  // customerName: invoice_list[i].customerName,
                  customerAddress: _address,
                  // customerEmail: invoice_list[i].email,
                  // customerPhoneNumber: invoice_list[i].phoneNumber,
                  // amountTotal: invoice_list[i].totalAmountExcludingTax,
                  vatTotal: TotalTaxAmount,
                  grandTotal: TotalInvoiceAmount,
                  customerPostcode: InvoiceAddressZipCode,
                  discount: TotalDiscountAmount,
                  // includeVat: invoice_list[i].pricesIncludeTax,
                  // receivedBy: invoice_list[i].salesperson,
                  etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === InvoiceNumber),
                });
              }

              if (primarySort === "documentDate") {
                if (isSortedDescendingDocumentDate) {
                  _docs.sort(function (a, b) {
                    var aa = a.documentDate,
                      bb = b.documentDate;
                    return aa < bb ? 1 : aa > bb ? -1 : 0;
                  });
                } else {
                  _docs.sort(function (a, b) {
                    var aa = a.documentDate,
                      bb = b.documentDate;
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                  });
                }
              } else if (primarySort === "number") {
                if (isSortedDescendingNumber) {
                  _docs.sort((a, b) => {
                    if (b.number < a.number) {
                      return -1;
                    }
                    if (b.number > a.number) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  _docs.sort((a, b) => {
                    if (a.number < b.number) {
                      return -1;
                    }
                    if (a.number > b.number) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === "customerName") {
                if (isSortedDescendingCustomerName) {
                  _docs.sort((a, b) => {
                    if (b.customerId < a.customerId) {
                      return -1;
                    }
                    if (b.customerId > a.customerId) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  _docs.sort((a, b) => {
                    if (a.customerId < b.customerId) {
                      return -1;
                    }
                    if (a.customerId > b.customerId) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === "vatTotal") {
                if (isSortedDescendingVatTotal) {
                  _docs.sort(function (a, b) {
                    return b.vatTotal - a.vatTotal;
                  });
                } else {
                  _docs.sort(function (a, b) {
                    return a.vatTotal - b.vatTotal;
                  });
                }
              } else if (primarySort === "grandTotal") {
                if (isSortedDescendingGrandTotal) {
                  _docs.sort(function (a, b) {
                    return b.grandTotal - a.grandTotal;
                  });
                } else {
                  _docs.sort(function (a, b) {
                    return a.grandTotal - b.grandTotal;
                  });
                }
              }

              setItems(_docs);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
            //   instance.acquireTokenRedirect(silentRequest);
              setItems([]);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            });
        };

        const listLedgerJournal = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
              const [response, responseAllDocs] = await Promise.all([
                axios
                //   .get('http://localhost:7071/api/DynamicsFinanceOperations_getLedgerJournalLines', {
                  .get(API_URL + '/sources/dynamics/fo/ledger-journal-lines', {
                    headers: {
                      "Authorization": "Bearer " + tokenResponse.accessToken,
                      "cid": companyId
                    },
                    params: {
                      dataAreaId: dynamicsCompany.key,
                      from: from,
                      to: to,
                    },
                  })
                  .catch((error) => console.log(error)),

                axios
                  .get(API_URL + "/etax/documents/ALL/all?from=" + from + "&to=" + to + "&date=" + dateType.key, {
                    headers: {
                      "Authorization": "Bearer " + tokenResponse.accessToken,
                      "cid": companyId
                    },
                  })
                  .catch((error) => console.log(error)),
              ]);

              console.log(API_URL + "/etax/documents/ALL/all?from=" + from + "&to=" + to + "&date=" + dateType.key)

              setAllDocs(responseAllDocs.data);

              let valueList = response.data.value

              console.log("valueList", valueList)

              const receiptList = []
              valueList.forEach(_receipt => {
                if (receiptList.length && receiptList.some(_item => _item.JournalBatchNumber === _receipt.JournalBatchNumber)) {
                  const _index = receiptList.findIndex(_itemInReceipt => _itemInReceipt.JournalBatchNumber === _receipt.JournalBatchNumber)

                  receiptList[_index].items.push({
                    number: (receiptList[_index].items.length + 1) + '',
                    Text: _receipt.Text,
                    CreditAmount: _receipt.CreditAmount,
                    DebitAmount: _receipt.DebitAmount
                  })

                  receiptList[_index].grandTotal = receiptList[_index].grandTotal + _receipt.CreditAmount - _receipt.DebitAmount
                  receiptList[_index].vatTotal = (receiptList[_index].grandTotal * percentVat) / 100
                } else {
                  const newReceiptObj = {..._receipt}
                  newReceiptObj.items = [{
                    number: 1 + '',
                    Text: _receipt.Text,
                    CreditAmount: _receipt.CreditAmount,
                    DebitAmount: _receipt.DebitAmount
                  }]

                  newReceiptObj.grandTotal = _receipt.CreditAmount - _receipt.DebitAmount
                  newReceiptObj.vatTotal = (newReceiptObj.grandTotal * percentVat) / 100

                  receiptList.push(newReceiptObj)
                }
              })

              if (primarySort === "documentDate") {
                if (isSortedDescendingDocumentDate) {
                  receiptList.sort(function (a, b) {
                    var aa = a.TransDate,
                      bb = b.TransDate;
                    return aa < bb ? 1 : aa > bb ? -1 : 0;
                  });
                } else {
                  receiptList.sort(function (a, b) {
                    var aa = a.TransDate,
                      bb = b.TransDate;
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                  });
                }
              } else if (primarySort === "number") {
                if (isSortedDescendingNumber) {
                  receiptList.sort((a, b) => {
                    if (b.Document < a.Document) {
                      return -1;
                    }
                    if (b.Document > a.Document) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  receiptList.sort((a, b) => {
                    if (a.Document < b.Document) {
                      return -1;
                    }
                    if (a.Document > b.Document) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === "customerName") {
                if (isSortedDescendingCustomerName) {
                  receiptList.sort((a, b) => {
                    if (b.AccountDisplayValue < a.AccountDisplayValue) {
                      return -1;
                    }
                    if (b.AccountDisplayValue > a.AccountDisplayValue) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  receiptList.sort((a, b) => {
                    if (a.AccountDisplayValue < b.AccountDisplayValue) {
                      return -1;
                    }
                    if (a.AccountDisplayValue > b.AccountDisplayValue) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === "vatTotal") {
                if (isSortedDescendingVatTotal) {
                  receiptList.sort(function (a, b) {
                    return b.vatTotal - a.vatTotal;
                  });
                } else {
                  receiptList.sort(function (a, b) {
                    return a.vatTotal - b.vatTotal;
                  });
                }
              } else if (primarySort === "grandTotal") {
                if (isSortedDescendingGrandTotal) {
                  receiptList.sort(function (a, b) {
                    return b.grandTotal - a.grandTotal;
                  });
                } else {
                  receiptList.sort(function (a, b) {
                    return a.grandTotal - b.grandTotal;
                  });
                }
              }

              let total_items = receiptList.length;

              let _pageDropdown = [];

              for (let i = 1; i <= Math.ceil(total_items / pageSize); i++) {
                _pageDropdown.push({
                  key: i,
                  text: "หน้า " + i,
                  iconProps: { iconName: "Page" },
                  onClick: () => {
                    console.log("Selected page: " + i);
                    setCurrentPage(i);
                  },
                });
              }

              setPageDropdown(_pageDropdown);

              if (currentPage > Math.ceil(total_items / pageSize)) {
                setCurrentPage(1);
              }

              setPageData(paginate(total_items, 1, pageSize, 10));
              setDocuments(receiptList);

              const _docs = [];

              for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, receiptList.length); i++) {
                const { 
                  JournalBatchNumber,
                  TransDate,
                  Document,
                  AccountDisplayValue,
                  OverrideSalesTax,
                  items
                } = receiptList[i]

                  let _discount = 0
                  let _amountTotal = 0;
                  let _vatTotal = 0;
                  let _grandTotal = 0;

                  let _vatPriceTotal = 0;
                  let _zeroVatPriceTotal = 0;

                  const _items = []

                  items.forEach(_itemWithDebitCredit => {
                    const _text = _itemWithDebitCredit.Text
                    const _price = _itemWithDebitCredit.CreditAmount

                    _discount = _discount + _itemWithDebitCredit.DebitAmount

                    if (_price > 0) {
                      _items.push({
                        number: (_items.length + 1) + '',
                        description: _text || "-",
                        quantity: 1,
                        unitCode: "",
                        unitName: "-",
                        price: _price,
                        discount: 0,
                        total: _price,
                        percentVat: percentVat,
                        percentVatText: percentVatText,
                      })
                    }

                  })

                  let _includeVat = false

                  if (OverrideSalesTax === 'No') {
                    _includeVat = true

                    _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                    _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                    _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                    
                  } else {
                    _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                    _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                  }
  
                  _docs.push({
                    key: Document + "_docs" + i,
                    documentDate: TransDate,
                    journalNumber: JournalBatchNumber,
                    // dueDate: _dueDate,
                    number: Document,
                    customerId: AccountDisplayValue,
                    // customerName: invoice_list[i].customerName,
                    customerAddress: '',
                    // customerEmail: invoice_list[i].email,
                    // customerPhoneNumber: invoice_list[i].phoneNumber,
                    amountTotal: _amountTotal,
                    vatTotal: _vatTotal,
                    grandTotal: _grandTotal,
                    // customerPostcode: InvoiceAddressZipCode,
                    discount: _discount,
                    includeVat: _includeVat,
                    // includeVat: invoice_list[i].pricesIncludeTax,
                    // receivedBy: invoice_list[i].salesperson,
                    etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === Document),
                    items: _items
                  });

              }

              

              console.log('_docs', _docs)

              console.log(_docs.find(item => item.items.length > 1))

              setItems(_docs);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
            //   instance.acquireTokenRedirect(silentRequest);
              setItems([]);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            });
        };

        const listCustomerPaymentJournal = async () => {
          instance
            .acquireTokenSilent({ ...silentRequest, account: account })
            .then(async (tokenResponse) => {
              const [response, responseAllDocs] = await Promise.all([
                axios
                //   .get('http://localhost:7071/api/DynamicsFinanceOperations_getCustomerPaymentJournalLines', {
                  .get(API_URL + '/sources/dynamics/fo/customer-payment-journal-lines', {
                    headers: {
                      "Authorization": "Bearer " + tokenResponse.accessToken,
                      "cid": companyId
                    },
                    params: {
                      dataAreaId: dynamicsCompany.key,
                      from: from,
                      to: to,
                    },
                  })
                  .catch((error) => console.log(error)),

                axios
                  .get(API_URL + "/etax/documents/ALL/all?from=" + from + "&to=" + to + "&date=" + dateType.key, {
                    headers: {
                      "Authorization": "Bearer " + tokenResponse.accessToken,
                      "cid": companyId
                    },
                  })
                  .catch((error) => console.log(error)),
              ]);

              // console.log(API_URL + "/etax/documents/ALL/all?from=" + from + "&to=" + to + "&date=" + dateType.key)
              console.log('responseAllDocs.data', responseAllDocs.data)

              setAllDocs(responseAllDocs.data);

              let valueList = response.data.value

              console.log("valueList", valueList)

              const receiptList = []
              valueList.forEach(_receipt => {
                if (receiptList.length && receiptList.some(_item => _item.JournalBatchNumber === _receipt.JournalBatchNumber)) {
                  const _index = receiptList.findIndex(_itemInReceipt => _itemInReceipt.JournalBatchNumber === _receipt.JournalBatchNumber)

                  receiptList[_index].items.push({
                    number: (receiptList[_index].items.length + 1) + '',
                    Text: _receipt.TransactionText,
                    CreditAmount: _receipt.CreditAmount,
                    DebitAmount: _receipt.DebitAmount
                  })

                  receiptList[_index].grandTotal = receiptList[_index].grandTotal + _receipt.CreditAmount - _receipt.DebitAmount
                  receiptList[_index].vatTotal = (receiptList[_index].grandTotal * percentVat) / 100
                } else {
                  const newReceiptObj = {..._receipt}
                  newReceiptObj.items = [{
                    number: 1 + '',
                    Text: _receipt.TransactionText,
                    CreditAmount: _receipt.CreditAmount,
                    DebitAmount: _receipt.DebitAmount
                  }]

                  newReceiptObj.grandTotal = _receipt.CreditAmount - _receipt.DebitAmount
                  newReceiptObj.vatTotal = (newReceiptObj.grandTotal * percentVat) / 100

                  receiptList.push(newReceiptObj)
                }
              })

              console.log(false, receiptList)

              if (primarySort === "documentDate") {
                if (isSortedDescendingDocumentDate) {
                  receiptList.sort(function (a, b) {
                    var aa = a.TransactionDate,
                      bb = b.TransactionDate;
                    return aa < bb ? 1 : aa > bb ? -1 : 0;
                  });
                } else {
                  receiptList.sort(function (a, b) {
                    var aa = a.TransactionDate,
                      bb = b.TransactionDate;
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                  });
                }
              } else if (primarySort === "number") {
                if (isSortedDescendingNumber) {
                  receiptList.sort((a, b) => {
                    if (b.Voucher < a.Voucher) {
                      return -1;
                    }
                    if (b.Voucher > a.Voucher) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  receiptList.sort((a, b) => {
                    if (a.Voucher < b.Voucher) {
                      return -1;
                    }
                    if (a.Voucher > b.Voucher) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === "customerName") {
                if (isSortedDescendingCustomerName) {
                  receiptList.sort((a, b) => {
                    if (b.AccountDisplayValue < a.AccountDisplayValue) {
                      return -1;
                    }
                    if (b.AccountDisplayValue > a.AccountDisplayValue) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  receiptList.sort((a, b) => {
                    if (a.AccountDisplayValue < b.AccountDisplayValue) {
                      return -1;
                    }
                    if (a.AccountDisplayValue > b.AccountDisplayValue) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === "vatTotal") {
                if (isSortedDescendingVatTotal) {
                  receiptList.sort(function (a, b) {
                    return b.vatTotal - a.vatTotal;
                  });
                } else {
                  receiptList.sort(function (a, b) {
                    return a.vatTotal - b.vatTotal;
                  });
                }
              } else if (primarySort === "grandTotal") {
                if (isSortedDescendingGrandTotal) {
                  receiptList.sort(function (a, b) {
                    return b.grandTotal - a.grandTotal;
                  });
                } else {
                  receiptList.sort(function (a, b) {
                    return a.grandTotal - b.grandTotal;
                  });
                }
              }

              let total_items = receiptList.length;

              let _pageDropdown = [];

              for (let i = 1; i <= Math.ceil(total_items / pageSize); i++) {
                _pageDropdown.push({
                  key: i,
                  text: "หน้า " + i,
                  iconProps: { iconName: "Page" },
                  onClick: () => {
                    console.log("Selected page: " + i);
                    setCurrentPage(i);
                  },
                });
              }

              setPageDropdown(_pageDropdown);

              if (currentPage > Math.ceil(total_items / pageSize)) {
                setCurrentPage(1);
              }

              setPageData(paginate(total_items, 1, pageSize, 10));
              setDocuments(receiptList);

              const _docs = [];

              for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, receiptList.length); i++) {
                const { 
                  JournalBatchNumber,
                  Voucher,
                  TransactionDate,
                  AccountDisplayValue,
                  OverrideSalesTax,
                  items
                } = receiptList[i]

                  let _discount = 0
                  let _amountTotal = 0;
                  let _vatTotal = 0;
                  let _grandTotal = 0;

                  let _vatPriceTotal = 0;
                  let _zeroVatPriceTotal = 0;

                  const _items = []

                  items.forEach(_itemWithDebitCredit => {
                    const _text = _itemWithDebitCredit.Text
                    const _price = _itemWithDebitCredit.CreditAmount

                    _discount = _discount + _itemWithDebitCredit.DebitAmount

                    if (_price > 0) {
                      _items.push({
                        number: (_items.length + 1) + '',
                        description: _text || "-",
                        quantity: 1,
                        unitCode: "",
                        unitName: "-",
                        price: _price,
                        discount: 0,
                        total: _price,
                        percentVat: percentVat,
                        percentVatText: percentVatText,
                      })
                    }

                  })

                  let _includeVat = false

                  if (OverrideSalesTax === 'No') {
                    _includeVat = true

                    _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                    _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
                    _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                    
                  } else {
                    _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
                    _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
                    _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
                  }
  
                  _docs.push({
                    key: Voucher + "_docs" + i,
                    documentDate: TransactionDate,
                    journalNumber: JournalBatchNumber,
                    // dueDate: _dueDate,
                    number: Voucher,
                    customerId: AccountDisplayValue,
                    // customerName: invoice_list[i].customerName,
                    customerAddress: '',
                    // customerEmail: invoice_list[i].email,
                    // customerPhoneNumber: invoice_list[i].phoneNumber,
                    amountTotal: _amountTotal,
                    vatTotal: _vatTotal,
                    grandTotal: _grandTotal,
                    // customerPostcode: InvoiceAddressZipCode,
                    discount: _discount,
                    includeVat: _includeVat,
                    // includeVat: invoice_list[i].pricesIncludeTax,
                    // receivedBy: invoice_list[i].salesperson,
                    etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.number === Voucher),
                    items: _items
                  });

              }

              

              console.log('_docs', _docs)

              console.log(_docs.find(item => item.items.length > 1))

              setItems(_docs);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
            //   instance.acquireTokenRedirect(silentRequest);
              setItems([]);

              setLoadDataComplete(true);
              setGetNewDocuments(false);
              setIsCreateDocumentComplete(false);
            });
        };

        if (endpointType.key === 'SalesInvoice') {
          listInvoices();
        } else if (endpointType.key === 'LedgerJournal') {
          listLedgerJournal()
        } else {
          listCustomerPaymentJournal()
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    if (documents.length > 0) {
      if (endpointType.key === 'SalesInvoice') {
        const _docs = [];
  
        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
          const { 
            InvoiceNumber, 
            InvoiceDate, 
            InvoiceAddressZipCode, 
            TotalTaxAmount, 
            InvoiceAddressStreetNumber, 
            InvoiceAddressStreet, 
            TotalDiscountAmount, 
            InvoiceCustomerAccountNumber, 
            InvoiceAddressCity, 
            InvoiceAddressState, 
            TotalInvoiceAmount, 
          } = documents[i]
  
          let _address = "";
  
          if (InvoiceAddressStreet) {
            _address = _address + InvoiceAddressStreet;
          }
  
          if (InvoiceAddressStreetNumber) {
            _address = _address + " " + InvoiceAddressStreetNumber;
          }
  
          if (InvoiceAddressCity) {
            _address = _address + " " + InvoiceAddressCity;
          }
  
          if (InvoiceAddressState) {
            _address = _address + " " + InvoiceAddressState;
          }
  
          if (InvoiceAddressZipCode) {
            _address = _address + " " + InvoiceAddressZipCode;
          }
          
  
          _docs.push({
            key: InvoiceNumber,
            documentDate: InvoiceDate,
            // dueDate: _dueDate,
            number: InvoiceNumber,
            customerId: InvoiceCustomerAccountNumber,
            // customerName: invoice_list[i].customerName,
            customerAddress: _address,
            // customerEmail: invoice_list[i].email,
            // customerPhoneNumber: invoice_list[i].phoneNumber,
            // amountTotal: invoice_list[i].totalAmountExcludingTax,
            vatTotal: TotalTaxAmount,
            grandTotal: TotalInvoiceAmount,
            customerPostcode: InvoiceAddressZipCode,
            discount: TotalDiscountAmount,
            // includeVat: invoice_list[i].pricesIncludeTax,
            // receivedBy: invoice_list[i].salesperson,
            etaxCreated: allDocs.some((_allDocs) => _allDocs.data.number === InvoiceNumber),
          });
        }
  
        setItems(_docs);

      } else if (endpointType.key === 'LedgerJournal') {
        const _docs = [];
  
        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
          const { 
            JournalBatchNumber,
            TransDate,
            Document,
            AccountDisplayValue,
            OverrideSalesTax,
            items
          } = documents[i]
  
          let _discount = 0
          let _amountTotal = 0;
          let _vatTotal = 0;
          let _grandTotal = 0;

          let _vatPriceTotal = 0;
          let _zeroVatPriceTotal = 0;

          const _items = []

          items.forEach(_itemWithDebitCredit => {
            const _text = _itemWithDebitCredit.Text
            const _price = _itemWithDebitCredit.CreditAmount

            _discount = _discount + _itemWithDebitCredit.DebitAmount

            if (_price > 0) {
              _items.push({
                number: (_items.length + 1) + '',
                description: _text || "-",
                quantity: 1,
                unitCode: "",
                unitName: "-",
                price: _price,
                discount: 0,
                total: _price,
                percentVat: percentVat,
                percentVatText: percentVatText,
              })
            }

          })

          console.log(_items)

          let _includeVat = false

          if (OverrideSalesTax === 'No') {
            _includeVat = true

            _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
            _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            
          } else {
            _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
          }

          _docs.push({
            key: Document + "_docs" + i,
            documentDate: TransDate,
            // dueDate: _dueDate,
            number: Document,
            journalNumber: JournalBatchNumber,
            customerId: AccountDisplayValue,
            // customerName: invoice_list[i].customerName,
            customerAddress: '',
            // customerEmail: invoice_list[i].email,
            // customerPhoneNumber: invoice_list[i].phoneNumber,
            amountTotal: _amountTotal,
            vatTotal: _vatTotal,
            grandTotal: _grandTotal,
            // customerPostcode: InvoiceAddressZipCode,
            discount: _discount,
            includeVat: _includeVat,
            // includeVat: invoice_list[i].pricesIncludeTax,
            // receivedBy: invoice_list[i].salesperson,
            etaxCreated: allDocs.some((_allDocs) => _allDocs.data.number === Document),
            items: _items
          });
        }
  
        setItems(_docs);

        console.log('_docs', _docs)
      } else {
        const _docs = [];
  
        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
          const { 
            JournalBatchNumber,
            Voucher,
            TransactionDate,
            AccountDisplayValue,
            OverrideSalesTax,
            items
          } = documents[i]
  
          let _discount = 0
          let _amountTotal = 0;
          let _vatTotal = 0;
          let _grandTotal = 0;

          let _vatPriceTotal = 0;
          let _zeroVatPriceTotal = 0;

          const _items = []

          items.forEach(_itemWithDebitCredit => {
            const _text = _itemWithDebitCredit.Text
            const _price = _itemWithDebitCredit.CreditAmount

            _discount = _discount + _itemWithDebitCredit.DebitAmount

            if (_price > 0) {
              _items.push({
                number: (_items.length + 1) + '',
                description: _text || "-",
                quantity: 1,
                unitCode: "",
                unitName: "-",
                price: _price,
                discount: 0,
                total: _price,
                percentVat: percentVat,
                percentVatText: percentVatText,
              })
            }

          })

          console.log(_items)

          let _includeVat = false

          if (OverrideSalesTax === 'No') {
            _includeVat = true

            _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
            _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            
          } else {
            _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
          }

          _docs.push({
            key: Voucher + "_docs" + i,
            documentDate: TransactionDate,
            journalNumber: JournalBatchNumber,
            // dueDate: _dueDate,
            number: Voucher,
            customerId: AccountDisplayValue,
            // customerName: invoice_list[i].customerName,
            customerAddress: '',
            // customerEmail: invoice_list[i].email,
            // customerPhoneNumber: invoice_list[i].phoneNumber,
            amountTotal: _amountTotal,
            vatTotal: _vatTotal,
            grandTotal: _grandTotal,
            // customerPostcode: InvoiceAddressZipCode,
            discount: _discount,
            includeVat: _includeVat,
            // includeVat: invoice_list[i].pricesIncludeTax,
            // receivedBy: invoice_list[i].salesperson,
            etaxCreated: allDocs.some((_allDocs) => _allDocs.data.number === Voucher),
            items: _items
          });
        }
  
        setItems(_docs);

        console.log('_docs', _docs)
      }
    }

    sessionStorage.setItem("ms-fo-currentPage", currentPage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    console.log("isCreateDocumentComplete useEffect: ", isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
      let _items = selection.getItems();
      console.log("_items: ", _items);

      let _selectedItems = selection.getSelection();

      if (isCreateDocumentComplete) {
        setIsCreateDocumentComplete(false);

        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status != "") {
            _items[i].status = "";
          }
        }
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status != "") {
            _items[i].status = "";
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {
          console.log("select item key: ", _selectedItems[i].key);

          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === _selectedItems[i].key) {
              console.log("set pending item key: ", _items[j].key);
              _items[j].status = "pending";
              console.log("_items: ", _items);
            }
          }
        }
      }

      setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const handleSort = (primary, isSorted) => {
    if (items.length > 0) {
      let _items = selection.getItems();
      let _selectedItems = selection.getSelection();
      console.log("selection: ", selection.getSelection());

      for (let i = 0; i < _selectedItems.length; i++) {
        console.log("select item key: ", _selectedItems[i].key);
        _selectedItems[i].status = "";

        for (let j = 0; j < _items.length; j++) {
          if (_items[j].key === _selectedItems[i].key) {
            console.log("item key: ", _items[j].key);
            _items[j].status = "";
            _selectedItems[i].status = "";
          }
        }
      }

      selection.setAllSelected(false);

      const _newDocuments = [...documents]

      if (endpointType.key === 'SalesInvoice') {
        if (primary === "documentDate") {
          if (isSorted) {
            _newDocuments.sort(function (a, b) {
              var aa = a.InvoiceDate,
                bb = b.InvoiceDate;
              return aa < bb ? 1 : aa > bb ? -1 : 0;
            });
          } else {
            _newDocuments.sort(function (a, b) {
              var aa = a.InvoiceDate,
                bb = b.InvoiceDate;
              return aa < bb ? -1 : aa > bb ? 1 : 0;
            });
          }
        } else if (primary === "number") {
          if (isSorted) {
            _newDocuments.sort((a, b) => {
              if (b.InvoiceNumber < a.InvoiceNumber) {
                return -1;
              }
              if (b.InvoiceNumber > a.InvoiceNumber) {
                return 1;
              }
              return 0;
            });
          } else {
            _newDocuments.sort((a, b) => {
              if (a.InvoiceNumber < b.InvoiceNumber) {
                return -1;
              }
              if (a.InvoiceNumber > b.InvoiceNumber) {
                return 1;
              }
              return 0;
            });
          }
        } else if (primary === "customerName") {
          if (isSorted) {
            _newDocuments.sort((a, b) => {
              if (b.InvoiceCustomerAccountNumber < a.InvoiceCustomerAccountNumber) {
                return -1;
              }
              if (b.InvoiceCustomerAccountNumber > a.InvoiceCustomerAccountNumber) {
                return 1;
              }
              return 0;
            });
          } else {
            _newDocuments.sort((a, b) => {
              if (a.InvoiceCustomerAccountNumber < b.InvoiceCustomerAccountNumber) {
                return -1;
              }
              if (a.InvoiceCustomerAccountNumber > b.InvoiceCustomerAccountNumber) {
                return 1;
              }
              return 0;
            });
          }
        } else if (primary === "vatTotal") {
          if (isSorted) {
            _newDocuments.sort(function (a, b) {
              return b.TotalTaxAmount - a.TotalTaxAmount;
            });
          } else {
            _newDocuments.sort(function (a, b) {
              return a.TotalTaxAmount - b.TotalTaxAmount;
            });
          }
        } else if (primary === "grandTotal") {
          if (isSorted) {
            _newDocuments.sort(function (a, b) {
              return b.TotalInvoiceAmount - a.TotalInvoiceAmount;
            });
          } else {
            _newDocuments.sort(function (a, b) {
              return a.TotalInvoiceAmount - b.TotalInvoiceAmount;
            });
          }
        }
  
        const _docs = [];
  
        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, _newDocuments.length); i++) {
          const { 
            InvoiceNumber, 
            InvoiceDate, 
            InvoiceAddressZipCode, 
            TotalTaxAmount, 
            InvoiceAddressStreetNumber, 
            InvoiceAddressStreet, 
            TotalDiscountAmount,
            InvoiceCustomerAccountNumber, 
            InvoiceAddressCity, 
            InvoiceAddressState, 
            TotalInvoiceAmount, 
          } = _newDocuments[i]
  
          let _address = "";
  
          if (InvoiceAddressStreet) {
            _address = _address + InvoiceAddressStreet;
          }
  
          if (InvoiceAddressStreetNumber) {
            _address = _address + " " + InvoiceAddressStreetNumber;
          }
  
          if (InvoiceAddressCity) {
            _address = _address + " " + InvoiceAddressCity;
          }
  
          if (InvoiceAddressState) {
            _address = _address + " " + InvoiceAddressState;
          }
  
          if (InvoiceAddressZipCode) {
            _address = _address + " " + InvoiceAddressZipCode;
          }
          
  
          _docs.push({
            key: InvoiceNumber,
            documentDate: InvoiceDate,
            // dueDate: _dueDate,
            number: InvoiceNumber,
            customerId: InvoiceCustomerAccountNumber,
            // customerName: invoice_list[i].customerName,
            customerAddress: _address,
            // customerEmail: invoice_list[i].email,
            // customerPhoneNumber: invoice_list[i].phoneNumber,
            // amountTotal: invoice_list[i].totalAmountExcludingTax,
            vatTotal: TotalTaxAmount,
            grandTotal: TotalInvoiceAmount,
            customerPostcode: InvoiceAddressZipCode,
            discount: TotalDiscountAmount,
            // includeVat: invoice_list[i].pricesIncludeTax,
            // receivedBy: invoice_list[i].salesperson,
            etaxCreated: allDocs.length ? allDocs.some((_allDocs) => _allDocs.data.number === InvoiceNumber) : false,
          });
        }
  
        setItems(_docs);
      } else if (endpointType.key === 'LedgerJournal') {
        if (primary === "documentDate") {
          if (isSorted) {
            _newDocuments.sort(function (a, b) {
              var aa = a.TransDate,
                bb = b.TransDate;
              return aa < bb ? 1 : aa > bb ? -1 : 0;
            });
          } else {
            _newDocuments.sort(function (a, b) {
              var aa = a.TransDate,
                bb = b.TransDate;
              return aa < bb ? -1 : aa > bb ? 1 : 0;
            });
          }
        } else if (primary === "number") {
          if (isSorted) {
            _newDocuments.sort((a, b) => {
              if (b.Document < a.Document) {
                return -1;
              }
              if (b.Document > a.Document) {
                return 1;
              }
              return 0;
            });
          } else {
            _newDocuments.sort((a, b) => {
              if (a.Document < b.Document) {
                return -1;
              }
              if (a.Document > b.Document) {
                return 1;
              }
              return 0;
            });
          }
        } else if (primary === "customerName") {
          if (isSorted) {
            _newDocuments.sort((a, b) => {
              if (b.AccountDisplayValue < a.AccountDisplayValue) {
                return -1;
              }
              if (b.AccountDisplayValue > a.AccountDisplayValue) {
                return 1;
              }
              return 0;
            });
          } else {
            _newDocuments.sort((a, b) => {
              if (a.AccountDisplayValue < b.AccountDisplayValue) {
                return -1;
              }
              if (a.AccountDisplayValue > b.AccountDisplayValue) {
                return 1;
              }
              return 0;
            });
          }
        } else if (primary === "vatTotal") {
          if (isSorted) {
            _newDocuments.sort(function (a, b) {
              return b.vatTotal - a.vatTotal;
            });
          } else {
            _newDocuments.sort(function (a, b) {
              return a.vatTotal - b.vatTotal;
            });
          }
        } else if (primary === "grandTotal") {
          if (isSorted) {
            _newDocuments.sort(function (a, b) {
              return b.grandTotal - a.grandTotal;
            });
          } else {
            _newDocuments.sort(function (a, b) {
              return a.grandTotal - b.grandTotal;
            });
          }
        }
  
        const _docs = [];

        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, _newDocuments.length); i++) {
          const { 
            JournalBatchNumber,
            TransDate,
            Document,
            AccountDisplayValue,
            OverrideSalesTax,
            items
          } = _newDocuments[i]

          let _discount = 0
          let _amountTotal = 0;
          let _vatTotal = 0;
          let _grandTotal = 0;

          let _vatPriceTotal = 0;
          let _zeroVatPriceTotal = 0;

          const _items = []

          items.forEach(_itemWithDebitCredit => {
            const _text = _itemWithDebitCredit.Text
            const _price = _itemWithDebitCredit.CreditAmount

            _discount = _discount + _itemWithDebitCredit.DebitAmount

            if (_price > 0) {
              _items.push({
                number: (_items.length + 1) + '',
                description: _text || "-",
                quantity: 1,
                unitCode: "",
                unitName: "-",
                price: _price,
                discount: 0,
                total: _price,
                percentVat: percentVat,
                percentVatText: percentVatText,
              })
            }

          })

          let _includeVat = false

          if (OverrideSalesTax === 'No') {
            _includeVat = true

            _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
            _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            
          } else {
            _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
          }
          
          _docs.push({
            key: Document + "_docs" + i,
            documentDate: TransDate,
            journalNumber: JournalBatchNumber,
            // dueDate: _dueDate,
            number: Document,
            customerId: AccountDisplayValue,
            // customerName: invoice_list[i].customerName,
            customerAddress: '',
            // customerEmail: invoice_list[i].email,
            // customerPhoneNumber: invoice_list[i].phoneNumber,
            amountTotal: _amountTotal,
            vatTotal: _vatTotal,
            grandTotal: _grandTotal,
            // customerPostcode: InvoiceAddressZipCode,
            discount: _discount,
            includeVat: _includeVat,
            // includeVat: invoice_list[i].pricesIncludeTax,
            // receivedBy: invoice_list[i].salesperson,
            etaxCreated: allDocs.length ? allDocs.some((_allDocs) => _allDocs.data.number === Document) : false,
            items: _items
          });
        }

        setItems(_docs);
      } else {
        if (primary === "documentDate") {
          if (isSorted) {
            _newDocuments.sort(function (a, b) {
              var aa = a.TransactionDate,
                bb = b.TransactionDate;
              return aa < bb ? 1 : aa > bb ? -1 : 0;
            });
          } else {
            _newDocuments.sort(function (a, b) {
              var aa = a.TransactionDate,
                bb = b.TransactionDate;
              return aa < bb ? -1 : aa > bb ? 1 : 0;
            });
          }
        } else if (primary === "number") {
          if (isSorted) {
            _newDocuments.sort((a, b) => {
              if (b.Voucher < a.Voucher) {
                return -1;
              }
              if (b.Voucher > a.Voucher) {
                return 1;
              }
              return 0;
            });
          } else {
            _newDocuments.sort((a, b) => {
              if (a.Voucher < b.Voucher) {
                return -1;
              }
              if (a.Voucher > b.Voucher) {
                return 1;
              }
              return 0;
            });
          }
        } else if (primary === "customerName") {
          if (isSorted) {
            _newDocuments.sort((a, b) => {
              if (b.AccountDisplayValue < a.AccountDisplayValue) {
                return -1;
              }
              if (b.AccountDisplayValue > a.AccountDisplayValue) {
                return 1;
              }
              return 0;
            });
          } else {
            _newDocuments.sort((a, b) => {
              if (a.AccountDisplayValue < b.AccountDisplayValue) {
                return -1;
              }
              if (a.AccountDisplayValue > b.AccountDisplayValue) {
                return 1;
              }
              return 0;
            });
          }
        } else if (primary === "vatTotal") {
          if (isSorted) {
            _newDocuments.sort(function (a, b) {
              return b.vatTotal - a.vatTotal;
            });
          } else {
            _newDocuments.sort(function (a, b) {
              return a.vatTotal - b.vatTotal;
            });
          }
        } else if (primary === "grandTotal") {
          if (isSorted) {
            _newDocuments.sort(function (a, b) {
              return b.grandTotal - a.grandTotal;
            });
          } else {
            _newDocuments.sort(function (a, b) {
              return a.grandTotal - b.grandTotal;
            });
          }
        }

        const _docs = [];

        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, _newDocuments.length); i++) {
          const { 
            JournalBatchNumber,
            Voucher,
            TransactionDate,
            AccountDisplayValue,
            OverrideSalesTax,
            items
          } = _newDocuments[i]

          let _discount = 0
          let _amountTotal = 0;
          let _vatTotal = 0;
          let _grandTotal = 0;

          let _vatPriceTotal = 0;
          let _zeroVatPriceTotal = 0;

          const _items = []

          items.forEach(_itemWithDebitCredit => {
            const _text = _itemWithDebitCredit.Text
            const _price = _itemWithDebitCredit.CreditAmount

            _discount = _discount + _itemWithDebitCredit.DebitAmount

            if (_price > 0) {
              _items.push({
                number: (_items.length + 1) + '',
                description: _text || "-",
                quantity: 1,
                unitCode: "",
                unitName: "-",
                price: _price,
                discount: 0,
                total: _price,
                percentVat: percentVat,
                percentVatText: percentVatText,
              })
            }

          })

          let _includeVat = false

          if (OverrideSalesTax === 'No') {
            _includeVat = true

            _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _grandTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _amountTotal = Math.round((_grandTotal - _vatTotal) * 100) / 100;
            _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            
          } else {
            _vatPriceTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _amountTotal = _items.reduce((accumulator, item) => accumulator + item.total, 0) - _discount;
            _vatTotal = Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;
            _grandTotal = Math.round((_amountTotal + _vatTotal) * 100) / 100;
          }
          
          _docs.push({
            key: Voucher + "_docs" + i,
            documentDate: TransactionDate,
            journalNumber: JournalBatchNumber,
            // dueDate: _dueDate,
            number: Voucher,
            customerId: AccountDisplayValue,
            // customerName: invoice_list[i].customerName,
            customerAddress: '',
            // customerEmail: invoice_list[i].email,
            // customerPhoneNumber: invoice_list[i].phoneNumber,
            amountTotal: _amountTotal,
            vatTotal: _vatTotal,
            grandTotal: _grandTotal,
            // customerPostcode: InvoiceAddressZipCode,
            discount: _discount,
            includeVat: _includeVat,
            // includeVat: invoice_list[i].pricesIncludeTax,
            // receivedBy: invoice_list[i].salesperson,
            etaxCreated: allDocs.length ? allDocs.some((_allDocs) => _allDocs.data.number === Voucher) : false,
            items: _items
          });
        }

        setItems(_docs);
  
      }

      setDocuments(_newDocuments)
    }
  };

  const onPageChange = (selectedPageIndex) => {
    console.log("selectedPageIndex: ", selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  useEffect(() => {
    console.log("referInvoiceNumber", referInvoiceNumber)
  }, [referInvoiceNumber])
  

  const columns = [
    {
      key: "column1",
      name: t("dynamicFo.documentDate"),
      fieldName: "documentDate",
      minWidth: 70,
      maxWidth: 125,
      isResizable: true,
      isSorted: primarySort === "documentDate" ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log("Sort document createTime!");
        setPrimarySort("documentDate");
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        handleSort("documentDate", !isSortedDescendingDocumentDate);

        sessionStorage.setItem("ms-fo-primarySort", "documentDate");
        sessionStorage.setItem("ms-fo-sort-documentDate", !isSortedDescendingDocumentDate === false ? "false" : "true");

        sessionStorage.removeItem("ms-fo-sort-number");
        sessionStorage.removeItem("ms-fo-sort-customerName");
        sessionStorage.removeItem("ms-fo-sort-vatTotal");
        sessionStorage.removeItem("ms-fo-sort-grandTotal");

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: "number",
      onRender: (item) => {
        return <span>{onFormatDate(new Date(item.documentDate), "BE")}</span>;
      },
      isPadded: true,
    },
    {
      key: "column2",
      name: t("dynamicFo.number"),
      fieldName: "number",
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === "number" ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log("Sort document number!");
        setPrimarySort("number");
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        handleSort("number", !isSortedDescendingNumber);

        sessionStorage.setItem("ms-fo-primarySort", "number");
        sessionStorage.setItem("ms-fo-sort-number", !isSortedDescendingNumber === false ? "false" : "true");

        sessionStorage.removeItem("ms-fo-sort-documentDate");
        sessionStorage.removeItem("ms-fo-sort-customerName");
        sessionStorage.removeItem("ms-fo-sort-vatTotal");
        sessionStorage.removeItem("ms-fo-sort-grandTotal");

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: "string",
      onRender: (item) => {
        return <span>{item.number}</span>;
      },
      isPadded: true,
    },
    ...(endpointType.key === 'SalesInvoice'
      ? []
      : [
        {
          key: "column10",
          name: t("dynamicFo.journalNumber"),
          fieldName: "name",
          minWidth: 80,
          maxWidth: 150,
          isRowHeader: true,
          isResizable: true,
          isSorted: false,
          isSortedDescending: false,
          sortAscendingAriaLabel: "Sorted A to Z",
          sortDescendingAriaLabel: "Sorted Z to A",
          onColumnClick: "",
          isCollapsible: true,
          data: "string",
          onRender: (item) => {
            return <span>{item.journalNumber}</span>;
          },
          isPadded: true,
        }
      ]),
    {
      key: "column3",
      name: t("dynamicFo.customerName"),
      fieldName: "customerName",
      minWidth: 80,
      maxWidth: 220,
      isResizable: true,
      isSorted: primarySort === "customerName" ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log("Sort document customerName!");
        setPrimarySort("customerName");
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        handleSort("customerName", !isSortedDescendingCustomerName);

        sessionStorage.setItem("ms-fo-primarySort", "customerName");
        sessionStorage.setItem("ms-fo-sort-customerName", !isSortedDescendingCustomerName === false ? "false" : "true");

        sessionStorage.removeItem("ms-fo-sort-documentDate");
        sessionStorage.removeItem("ms-fo-sort-number");
        sessionStorage.removeItem("ms-fo-sort-vatTotal");
        sessionStorage.removeItem("ms-fo-sort-grandTotal");

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: "string",
      onRender: (item) => {
        return <span>{item.customerId}</span>;
      },
      isPadded: true,
    },

    // {
    //   key: "column4",
    //   name: t("dynamicFo.customerAddress"),
    //   fieldName: "name",
    //   minWidth: 100,
    //   maxWidth: 270,
    //   isRowHeader: true,
    //   isResizable: true,
    //   isSorted: false,
    //   isSortedDescending: false,
    //   sortAscendingAriaLabel: "Sorted A to Z",
    //   sortDescendingAriaLabel: "Sorted Z to A",
    //   onColumnClick: "",
    //   isCollapsible: true,
    //   data: "string",
    //   onRender: (item) => {
    //     return <span>{item.customerAddress}</span>;
    //   },
    //   isPadded: true,
    // },
    ...(endpointType.key === 'SalesInvoice'
      ? [
          {
            key: "column4",
            name: t("dynamicFo.customerAddress"),
            fieldName: "name",
            minWidth: 100,
            maxWidth: 270,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: "Sorted A to Z",
            sortDescendingAriaLabel: "Sorted Z to A",
            onColumnClick: "",
            isCollapsible: true,
            data: "string",
            onRender: (item) => {
              return <span>{item.customerAddress}</span>;
            },
            isPadded: true,
          }
            
        ]
      : []),
    {
      key: "column5",
      name: t("dynamicFo.vatTotal"),
      fieldName: "vatTotal",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === "vatTotal" ? true : false,
      isSortedDescending: isSortedDescendingVatTotal,
      isSortedAscending: !isSortedDescendingVatTotal,
      onColumnClick: () => {
        console.log("Sort document vatTotal!");
        setPrimarySort("vatTotal");
        setIsSortedDescendingVatTotal(!isSortedDescendingVatTotal);

        handleSort("vatTotal", !isSortedDescendingVatTotal);

        sessionStorage.setItem("ms-fo-primarySort", "vatTotal");
        sessionStorage.setItem("ms-fo-sort-vatTotal", !isSortedDescendingVatTotal === false ? "false" : "true");

        sessionStorage.removeItem("ms-fo-sort-number");
        sessionStorage.removeItem("ms-fo-sort-customerName");
        sessionStorage.removeItem("ms-fo-sort-documentDate");
        sessionStorage.removeItem("ms-fo-sort-grandTotal");

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: "string",
      onRender: (item) => {
        return (
          <span style={{ display: "block", textAlign: "right" }}>
            {item.vatTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: "column6",
      name: t("dynamicFo.grandTotal"),
      fieldName: "grandTotal",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === "grandTotal" ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        console.log("Sort document grandTotal!");
        setPrimarySort("grandTotal");
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        handleSort("grandTotal", !isSortedDescendingGrandTotal);

        sessionStorage.setItem("ms-fo-primarySort", "grandTotal");
        sessionStorage.setItem("ms-fo-sort-grandTotal", !isSortedDescendingGrandTotal === false ? "false" : "true");

        sessionStorage.removeItem("ms-fo-sort-number");
        sessionStorage.removeItem("ms-fo-sort-customerName");
        sessionStorage.removeItem("ms-fo-sort-vatTotal");
        sessionStorage.removeItem("ms-fo-sort-documentDate");

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: "string",
      onRender: (item) => {
        return (
          <span style={{ display: "block", textAlign: "right" }}>
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: "column7",
      name: "Leceipt",
      fieldName: "etaxCreated",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      data: "string",
      onRender: (item) => {
        if (item.etaxCreated) {
          return (
            <div style={{ textAlign: "center" }}>
              <Icon iconName="SkypeCircleCheck" style={{ fontSize: "20px", color: "green" }} />
            </div>
          );
        }
      },
    },
    {
      key: "column8",
      name: "",
      fieldName: "command",
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: "string",
      onColumnClick: "",
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === "pending") {
            return <span style={{ color: "#106EBE" }}>{t("dynamicFo.pending")}</span>;
          } else if (item.status === "processing") {
            return <Spinner label={t("dynamicFo.processing")} labelPosition="right" style={{ marginLeft: "0px" }} />;
          } else if (item.status === "successful") {
            return <span style={{ color: "green" }}>{t("dynamicFo.success")}</span>;
          } else if (item.status === "fail") {
            return <span style={{ color: "red" }}>{t("dynamicFo.fail")}</span>;
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t("dynamicFo.create")}
                iconProps={{ iconName: "Add", style: { color: "#106ebe" } }}
                menuProps={{
                  items: [
                    ...(item.grandTotal < 0 ? [{
                      key: "CREDIT-NOTE",
                      text: t("dynamicFo.creditNote"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: () => {
                        setSelectItem(item)
                        setOpenRefer(true)
                      },
                    }] : [
                      {
                        key: "RECEIPT-TAXINVOICE",
                        text: t("dynamicFo.receipt-taxinvoice"),
                        iconProps: { iconName: "PageAdd" },
                        onClick: () => {
                          createDocument("RECEIPT-TAXINVOICE", item);
                        },
                      },
                      {
                        key: "RECEIPT-TAXINVOICE-ABB",
                        text: t("dynamicFo.receipt-taxinvoice-abb"),
                        iconProps: { iconName: "PageAdd" },
                        onClick: () => {
                          createDocument("RECEIPT-TAXINVOICE-ABB", item);
                        },
                      },
                      {
                        key: "RECEIPT",
                        text: t("dynamicFo.receipt"),
                        iconProps: { iconName: "PageAdd" },
                        onClick: () => {
                          createDocument("RECEIPT", item);
                        },
                      },
                      {
                        key: "INVOICE-TAXINVOICE",
                        text: t("dynamicFo.invoice-taxinvoice"),
                        iconProps: { iconName: "PageAdd" },
                        onClick: () => {
                          createDocument("INVOICE-TAXINVOICE", item);
                        },
                      },
                      {
                        key: "DELIVERYORDER-TAXINVOICE",
                        text: t("dynamicFo.deliveryorder-taxinvoice"),
                        iconProps: { iconName: "PageAdd" },
                        onClick: () => {
                          createDocument("DELIVERYORDER-TAXINVOICE", item);
                        },
                      },
                      {
                        key: "TAXINVOICE",
                        text: t("dynamicFo.taxinvoice"),
                        iconProps: { iconName: "PageAdd" },
                        onClick: () => {
                          createDocument("TAXINVOICE", item);
                        },
                      },
                    ]),
                    
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        }
      },
    },
  ];

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + "/sources",
              {
                source: "dynamics-fo",
                replace: "/disabled",
                value: true,
              },
              {
                headers: {
                  "Authorization": "Bearer " + tokenResponse.accessToken,
                  "cid": companyId
              },
              }
            )
            .then((response) => {
              console.log(response);

              history.push({
                pathname: "/etax/sources/dynamics/finance-operations/connect",
                state: {},
              });

              setIsLoading(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const createSelectedDocuments = async (documentType) => {
    let _selectionItems = selection.getItems();

    let postUrl = "";

    switch (documentType) {
      case "RECEIPT-TAXINVOICE":
        postUrl = API_URL + "/etax/documents/receipts-taxinvoices/pdf-xml";
        break;
      case "RECEIPT-TAXINVOICE-ABB":
        postUrl = API_URL + "/etax/documents/receipts-taxinvoices-abb/pdf";
        break;
      case "RECEIPT":
        postUrl = API_URL + "/etax/documents/receipts/pdf-xml";
        break;
      case "INVOICE-TAXINVOICE":
        postUrl = API_URL + "/etax/documents/invoices-taxinvoices/pdf-xml";
        break;
      case "DELIVERYORDER-TAXINVOICE":
        postUrl = API_URL + "/etax/documents/deliveryorders-taxinvoices/pdf-xml";
        break;
      case "TAXINVOICE":
        postUrl = API_URL + "/etax/documents/taxinvoices/pdf-xml";
        break;
      default:
        break;
    }

    if (postUrl) {
      await instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          for (let i = 0; i < selectedItems.length; i++) {
            console.log("create selected document: ", selectedItems[i]);

            for (let j = 0; j < _selectionItems.length; j++) {
              if (_selectionItems[j].key === selectedItems[i].key) {

                if (selectedItems[i].grandTotal < 0) {
                  _selectionItems[j].status = "fail";

                  setItems([..._selectionItems]);

                  continue;
                }

                
                _selectionItems[j].status = "processing";

                setItems([..._selectionItems]);

                let _payload = {}

                if (endpointType.key === 'SalesInvoice') {
                  const [responseCustomer, responseInvoiceDetail] = await Promise.all([
                    axios
                      // .get("http://localhost:7071/api/DynamicsFinanceOperations_getCustomer", {
                      .get(API_URL + '/sources/dynamics/fo/customers', {
                          headers: {
                          "Authorization": "Bearer " + tokenResponse.accessToken,
                          "cid": companyId
                        },
                        params: {
                          dataAreaId: dynamicsCompany.key,
                          CustomerAccount: selectedItems[i].customerId,
                        },
                      })
                      .catch((error) => console.log(error)),
  
                    axios
                      // .get("http://localhost:7071/api/DynamicsFinanceOperations_getSalesInvoiceV2Lines", {
                      .get(API_URL + '/sources/dynamics/fo/salesinvoicelines', {
                        headers: {
                          "Authorization": "Bearer " + tokenResponse.accessToken,
                          "cid": companyId
                        },
                        params: {
                          dataAreaId: dynamicsCompany.key,
                          InvoiceNumber: selectedItems[i].number,
                        },
                      })
                      .catch((error) => console.log(error)),
                  ]);
  
                  const _customerInfo = responseCustomer.data.value[0];
                  const invoiceLine = responseInvoiceDetail.data.value;
  
                  const { FullPrimaryAddress, PrimaryContactEmail, PrimaryContactPhone, 
                    OrganizationName, AddressZipCode, IdentificationNumber, 
                    BPC_Tax_VATid, BPC_Tax_WHTid, PartyType, BPC_HeadOffice, BPC_BranchNo } = _customerInfo
          
                  // let d = item.documentDate.split("-");
          
                  const _date = selectedItems[i].documentDate
                  const _dateBE = onFormatDate(new Date(_date), "BE")
                  const _dateCE = onFormatDate(new Date(_date), "CE")
          
                  // let _dateBE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + (_date.getFullYear() + 543);
                  // let _dateCE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + _date.getFullYear();
          
                  // let dueD = item.dueDate.split("-");
          
                  // let _dueDate = new Date(dueD[2] + "/" + dueD[1] + "/" + dueD[0]);
          
                  // let _dueDateBE =
                  //   `${_dueDate.getDate()}`.padStart(2, "0") + "/" + `${_dueDate.getMonth() + 1}`.padStart(2, "0") + "/" + (_dueDate.getFullYear() + 543);
                  // let _dueDateCE =
                  //   `${_dueDate.getDate()}`.padStart(2, "0") + "/" + `${_dueDate.getMonth() + 1}`.padStart(2, "0") + "/" + _dueDate.getFullYear();
          
                  let _includeVat = selectedItems[i].includeVat
                  // if (IsSalesTaxIncludedInPrices === "No") {
                  //   _includeVat = false
                  // }
  
                  let _taxId = "N/A";
                  let _taxNumberType = "OTHR";
                  let _branchNumber = "";
                  let _branchText = "";
  
                  if (BPC_Tax_VATid && BPC_Tax_VATid.length === 13) {
                    _taxId = BPC_Tax_VATid;
                  } else if (BPC_Tax_WHTid && BPC_Tax_WHTid.length === 13) {
                    _taxId = BPC_Tax_WHTid;
                  } else if (IdentificationNumber && IdentificationNumber.length === 13) {
                    _taxId = IdentificationNumber;
                  } else if (IdentificationNumber && IdentificationNumber.length !== 13) {
                    _taxId = IdentificationNumber;
                    _taxNumberType = "CCPT";
                  }

                  let _customerName = OrganizationName

                  const { name, branchNumber, branchText } = getBranchFromName(OrganizationName)
  
                  if (branchNumber) {
                    _customerName = name
                    _taxNumberType = "TXID";
                    _branchNumber = branchNumber
                    _branchText = branchText
                  } else {
                    if (PartyType) {
                      if (PartyType === "Organization") {
                        _taxNumberType = "TXID";
            
                        if (BPC_HeadOffice === "Yes") {
                          _branchNumber = "00000";
                          _branchText = "Head Office";
                        } else {
                          if (BPC_BranchNo) {
                            _branchNumber = BPC_BranchNo.padStart(5, "0")
                            _branchText = "Branch " + _branchNumber
                          } else {
                            _branchNumber = "00000";
                            _branchText = "Head Office";
                          }
                        }
                      } else {
                        _taxNumberType = "NIDN";
                      }
                    }
                  }
          
                  if (_taxId === "N/A" || !_taxId || Number(_taxId) === 0) {
                    _taxId = "N/A"
                    _taxNumberType = "OTHR";
                    _branchNumber = "";
                    _branchText = "";
                  } else if (_taxId.length !== 13) {
                    _taxNumberType = "CCPT"
                  }
  
                  let _postcode = "00000"
                  if (AddressZipCode) {
                    _postcode = AddressZipCode
                  } else {
                    let findPostcode = FullPrimaryAddress.match(/\d+/g);
  
                    if (findPostcode && findPostcode.length > 0) {
                      findPostcode.forEach((number) => {
                        if (number.length === 5) {
                          _postcode = number;
                        }
                      });
                    }
                  }
  
                  let _phoneNumber = ""
                  if (PrimaryContactPhone) {
                    if (PrimaryContactPhone.includes(",")) {
                      _phoneNumber = PrimaryContactPhone.split(",")[0]
                    } else {
                      _phoneNumber = PrimaryContactPhone
                    }
                  }

                  _phoneNumber = _phoneNumber.replaceAll("-", "")
  
                  let _customer = {
                    addressLineOne: FullPrimaryAddress.replace(`${_postcode}`, ""),
                    addressLineTwo: "",
                    branchNumber: _branchNumber,
                    branchText: _branchText,
                    email: PrimaryContactEmail,
                    name: _customerName,
                    phone: _phoneNumber,
                    phoneCountryCode: "+66",
                    postcode: _postcode,
                    taxId: _taxId,
                    taxNumberType: _taxNumberType,
                    language: "th",
                    unstructure: true,
                  };
  
                  let _items = [];
                  let _amountTotal = 0
  
                  for (let i = 0; i < invoiceLine.length; i++) {
                    const { LineTotalTaxAmount, ProductDescription, InvoicedQuantity, 
                      SalesPrice, LineTotalDiscountAmount, LineAmount } = invoiceLine[i]
  
                    let _percentVat = zeroVat;
                    let _percentVatText = zeroVatText;
                    if (LineTotalTaxAmount > 0) {
                      _percentVat = percentVat;
                      _percentVatText = percentVatText;
                    }
  
                    _amountTotal = _amountTotal + LineAmount
  
                    _items.push({
                      number: i + 1 + "",
                      description: ProductDescription || "-",
                      quantity: InvoicedQuantity,
                      unitCode: "",
                      unitName: "-",
                      price: SalesPrice,
                      discount: Math.round((LineTotalDiscountAmount / InvoicedQuantity) * 100) / 100,
                      total: LineAmount,
                      percentVat: _percentVat,
                      percentVatText: _percentVatText,
                    });
                  }
  
                  let _vatPriceTotal = 0;
                  let _zeroVatPriceTotal = 0;
                  let _nonVatPriceTotal = 0;
  
                  _items.forEach((item) => {
                    if (item.percentVat === percentVat) {
                      _vatPriceTotal = _vatPriceTotal + item.total;
                    } else if (item.percentVat === zeroVat) {
                      _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                    } else if (item.percentVat === nonVat) {
                      _nonVatPriceTotal = _nonVatPriceTotal + item.total;
                    }
                  });

                  _payload = {
                    number: selectedItems[i].number,
                    reference: "",
                    company: {
                      ...company,
                      includeVat: _includeVat,
                    },
                    customer: _customer,
                    date: _date,
                    dateBE: _dateBE,
                    dateCE: _dateCE,
                    // dueDate: _dueDate,
                    // dueDateBE: _dueDateBE,
                    // dueDateCE: _dueDateCE,
                    items: _items,
                    includeVat: _includeVat,
                    discount: selectedItems[i].discount * 1,
                    amountTotal: _amountTotal * 1,
                    vatTotal: selectedItems[i].vatTotal * 1,
                    grandTotal: selectedItems[i].grandTotal * 1,
                    nonVatPriceTotal: _nonVatPriceTotal,
                    zeroVatPriceTotal: _zeroVatPriceTotal,
                    vatPriceTotal: _vatPriceTotal,
                    percentVat: percentVat,
                    receivedBy: createdBy,
                    reIssue: false,
                    grandTotalTH: numberToTextTh(selectedItems[i].grandTotal * 1),
                    grandTotalEN: numberToTextEn(selectedItems[i].grandTotal * 1),
                  }
                } else {
                  const [responseCustomer, responseCustomerTaxID] = await Promise.all([
                    axios
                      // .get("http://localhost:7071/api/DynamicsFinanceOperations_getCustomer", {                     
                      .get(API_URL + '/sources/dynamics/fo/customers', {
                        headers: {
                          "Authorization": "Bearer " + tokenResponse.accessToken,
                          "cid": companyId
                        },
                        params: {
                          dataAreaId: dynamicsCompany.key,
                          CustomerAccount: selectedItems[i].customerId,
                        },
                      })
                      .catch((error) => console.log(error)),

                      axios
                        // .get("http://localhost:7071/api/DynamicsFinanceOperations_getCustomerTaxID", {                       
                        .get(API_URL + '/sources/dynamics/fo/tax-service-tax-registration-number-customers', {
                            headers: {
                            "Authorization": "Bearer " + tokenResponse.accessToken,
                            "cid": companyId
                          },
                          params: {
                            dataAreaId: dynamicsCompany.key,
                            CustomerAccount: selectedItems[i].customerId,
                          },
                        })
                        .catch((error) => console.log(error)),
                  ]);
  
                  const _customerInfo = responseCustomer.data.value[0];
                  const _customerInfoTaxID = responseCustomerTaxID.data.value[0] || {
                    TaxRegstrationType: "",
                    ValidFrom: "",
                    ValidTo: "",
                    CustAccountNum: "",
                    LegalEntity: "",
                    CountryRegionId: "",
                    RegistrationNumber: "",
                    Section: "",
                    CustAccountName: "",
                    Issuer: "",
                    IssueDate: "",
                    Description: ""
                  };
                  
                  console.log("_customerInfo", _customerInfo)

                  if (_customerInfo) {
                    const { FullPrimaryAddress, PrimaryContactEmail, PrimaryContactPhone, 
                      OrganizationName, AddressZipCode, IdentificationNumber, 
                      BPC_Tax_VATid, BPC_Tax_WHTid, PartyType, BPC_HeadOffice, BPC_BranchNo } = _customerInfo
            
                      const {
                        RegistrationNumber
                      } = _customerInfoTaxID
                    // let d = item.documentDate.split("-");
            
                    const _date = selectedItems[i].documentDate
                    const _dateBE = onFormatDate(new Date(_date), "BE")
                    const _dateCE = onFormatDate(new Date(_date), "CE")
            
                    // let _dateBE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + (_date.getFullYear() + 543);
                    // let _dateCE = `${_date.getDate()}`.padStart(2, "0") + "/" + `${_date.getMonth() + 1}`.padStart(2, "0") + "/" + _date.getFullYear();
            
                    // let dueD = item.dueDate.split("-");
            
                    // let _dueDate = new Date(dueD[2] + "/" + dueD[1] + "/" + dueD[0]);
            
                    // let _dueDateBE =
                    //   `${_dueDate.getDate()}`.padStart(2, "0") + "/" + `${_dueDate.getMonth() + 1}`.padStart(2, "0") + "/" + (_dueDate.getFullYear() + 543);
                    // let _dueDateCE =
                    //   `${_dueDate.getDate()}`.padStart(2, "0") + "/" + `${_dueDate.getMonth() + 1}`.padStart(2, "0") + "/" + _dueDate.getFullYear();
            
                    let _includeVat = selectedItems[i].includeVat
                    // if (IsSalesTaxIncludedInPrices === "No") {
                    //   _includeVat = false
                    // }
    
                    let _taxId = "N/A";
                    let _taxNumberType = "OTHR";
                    let _branchNumber = "";
                    let _branchText = "";
    
                    if (RegistrationNumber && RegistrationNumber.length === 13) {
                      _taxId = RegistrationNumber;
                    } else if (BPC_Tax_VATid && BPC_Tax_VATid.length === 13) {
                      _taxId = BPC_Tax_VATid;
                    } else if (BPC_Tax_WHTid && BPC_Tax_WHTid.length === 13) {
                      _taxId = BPC_Tax_WHTid;
                    } else if (IdentificationNumber && IdentificationNumber.length === 13) {
                      _taxId = IdentificationNumber;
                    } else if (IdentificationNumber && IdentificationNumber.length !== 13) {
                      _taxId = IdentificationNumber;
                      _taxNumberType = "CCPT";
                    }

                    let _customerName = OrganizationName

                    const { name, branchNumber, branchText } = getBranchFromName(OrganizationName)

                    if (branchNumber) {
                      _customerName = name
                      _taxNumberType = "TXID";
                      _branchNumber = branchNumber
                      _branchText = branchText
                    } else {
                      if (PartyType) {
                        if (PartyType === "Organization") {
                          _taxNumberType = "TXID";
              
                          if (BPC_HeadOffice === "Yes") {
                            _branchNumber = "00000";
                            
                            if (OrganizationName.includes('บริษัท') || OrganizationName.includes('ห้างหุ้นส่วน')) {
                              _branchText = "สำนักงานใหญ่";
                            } else if (
                              OrganizationName.includes('Company') ||
                              OrganizationName.includes('company') ||
                              OrganizationName.includes('ltd.') ||
                              OrganizationName.includes('Ltd.') ||
                              OrganizationName.includes('Limited') ||
                              OrganizationName.includes('limited')
                            ) {
                              _branchText = "Head Office";
                            } else {
                              _branchText = "สำนักงานใหญ่";
                            }
                          } else {
                            if (BPC_BranchNo) {
                              _branchNumber = BPC_BranchNo.padStart(5, "0")
    
                              if (OrganizationName.includes('บริษัท') || OrganizationName.includes('ห้างหุ้นส่วน')) {
                                _branchText = "สาขา " + _branchNumber
                              } else if (
                                OrganizationName.includes('Company') ||
                                OrganizationName.includes('company') ||
                                OrganizationName.includes('ltd.') ||
                                OrganizationName.includes('Ltd.') ||
                                OrganizationName.includes('Limited') ||
                                OrganizationName.includes('limited')
                              ) {
                                _branchText = "Branch " + _branchNumber
                              } else {
                                _branchText = "สาขา " + _branchNumber
                              }
                            } else {
                              _branchNumber = "00000";
                              if (OrganizationName.includes('บริษัท') || OrganizationName.includes('ห้างหุ้นส่วน')) {
                                _branchText = "สำนักงานใหญ่";
                              } else if (
                                OrganizationName.includes('Company') ||
                                OrganizationName.includes('company') ||
                                OrganizationName.includes('ltd.') ||
                                OrganizationName.includes('Ltd.') ||
                                OrganizationName.includes('Limited') ||
                                OrganizationName.includes('limited')
                              ) {
                                _branchText = "Head Office";
                              } else {
                                _branchText = "สำนักงานใหญ่";
                              }
                            }
                          }
                        } else {
                          _taxNumberType = "NIDN";
                        }
                      }
                    }
            
                    if (_taxId === "N/A" || !_taxId || Number(_taxId) === 0) {
                      _taxId = "N/A"
                      _taxNumberType = "OTHR";
                      _branchNumber = "";
                      _branchText = "";
                    } else if (_taxId.length !== 13) {
                      _taxNumberType = "CCPT"
                    }
    
                    let _postcode = "00000"
                    if (AddressZipCode) {
                      _postcode = AddressZipCode
                    } else {
                      let findPostcode = FullPrimaryAddress.match(/\d+/g);
    
                      if (findPostcode && findPostcode.length > 0) {
                        findPostcode.forEach((number) => {
                          if (number.length === 5) {
                            _postcode = number;
                          }
                        });
                      }
                    }
    
                    let _phoneNumber = ""
                    if (PrimaryContactPhone) {
                      if (PrimaryContactPhone.includes(",")) {
                        _phoneNumber = PrimaryContactPhone.split(",")[0]
                      } else {
                        _phoneNumber = PrimaryContactPhone
                      }
                    }

                    _phoneNumber = _phoneNumber.replaceAll("-", "")
    
                    let _customer = {
                      addressLineOne: FullPrimaryAddress.replace(`${_postcode}`, "").replace(/THA$/,"").replace(/\n/g," "),
                      addressLineTwo: "",
                      branchNumber: _branchNumber,
                      branchText: _branchText,
                      email: PrimaryContactEmail,
                      name: _customerName,
                      phone: _phoneNumber,
                      phoneCountryCode: "+66",
                      postcode: _postcode,
                      taxId: _taxId,
                      taxNumberType: _taxNumberType,
                      language: "th",
                      unstructure: true,
                    };
    
                    let _vatPriceTotal = 0;
                    let _zeroVatPriceTotal = 0;
                    let _nonVatPriceTotal = 0;
    
                    selectedItems[i].items.forEach((item) => {
                      if (item.percentVat === percentVat) {
                        _vatPriceTotal = _vatPriceTotal + item.total;
                      } else if (item.percentVat === zeroVat) {
                        _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                      } else if (item.percentVat === nonVat) {
                        _nonVatPriceTotal = _nonVatPriceTotal + item.total;
                      }
                    });
  
                    _payload = {
                      number: selectedItems[i].number,
                      reference: "",
                      company: {
                        ...company,
                        includeVat: _includeVat,
                      },
                      customer: _customer,
                      date: _date,
                      dateBE: _dateBE,
                      dateCE: _dateCE,
                      // dueDate: _dueDate,
                      // dueDateBE: _dueDateBE,
                      // dueDateCE: _dueDateCE,
                      items: selectedItems[i].items,
                      includeVat: _includeVat,
                      discount: selectedItems[i].discount * 1,
                      amountTotal: selectedItems[i].amountTotal * 1,
                      vatTotal: selectedItems[i].vatTotal * 1,
                      grandTotal: selectedItems[i].grandTotal * 1,
                      nonVatPriceTotal: _nonVatPriceTotal,
                      zeroVatPriceTotal: _zeroVatPriceTotal,
                      vatPriceTotal: _vatPriceTotal,
                      percentVat: percentVat,
                      receivedBy: createdBy,
                      reIssue: false,
                      grandTotalTH: numberToTextTh(selectedItems[i].grandTotal * 1),
                      grandTotalEN: numberToTextEn(selectedItems[i].grandTotal * 1),
                    }

                  } else {
                    _selectionItems[j].status = "fail";

                    setItems([..._selectionItems]);

                    continue;
                  }
                }

                const promise_PDF_XML = await new Promise(async (resolve) => {
                  await axios
                    .post(
                      postUrl,
                      _payload,
                      {
                        headers: {
                          "Authorization": "Bearer " + tokenResponse.accessToken,
                          "cid": companyId
                      },
                      }
                    )
                    .then(
                      async (response) => {
                        console.log("Create document response: ", response);

                        let count = 0;

                        let interval = setInterval(async () => {
                          await axios
                            .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                              headers: {
                                "Authorization": "Bearer " + tokenResponse.accessToken,
                                "Content-Type": "application/json",
                                "cid": companyId
                              },
                            })
                            .then((jobsResponse) => {
                              count += 1;
                              console.log("Jobs response: ", j, jobsResponse.data.status, count);

                              if (jobsResponse.data.status === "complete") {
                                _selectionItems[j].status = "successful";
                                _selectionItems[j].etaxCreated = true;

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(true);
                              } else if (count === 300) {
                                _selectionItems[j].status = "fail";

                                setItems([..._selectionItems]);

                                clearInterval(interval);

                                resolve(false);
                              }
                            });
                        }, 1000);
                      },
                      (error) => {
                        console.log("Error Create document response: ", error);
                        _selectionItems[j].status = "fail";
                        setItems([..._selectionItems]);
                        resolve(false);
                      }
                    );
                }); //promise

                Promise.all([promise_PDF_XML]).then((value) => {
                  console.log(i, value);
                });
              } //if
            } //for j
          } //for i
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          // instance.acquireTokenRedirect(silentRequest);
        });

      setIsCreateDocumentComplete(true);
      setIsCreateDocument(false);
    }

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  function DialogCreateCreditNote() {
    return <Dialog
    hidden={!openRefer}
    onDismiss={() => setOpenRefer(false)}
    styles={{ main: { minHeight: 0 } }}
    dialogContentProps={{
      type: DialogType.largeHeader,
      title: t("dynamicFo.createCreditNote"),
      styles: { header: { textAlign: "center" }, title: { paddingRight: 24 }, subText: { textAlign: "center" } },
      subText: "",
    }}
  >
    <Stack horizontalAlign="center">
      <TextField label={t("dynamicFo.referNumber")} onChange={(e, value) => setReferInvoiceNumber(value)} />
    </Stack>
    <DialogFooter styles={{ actionsRight: { textAlign: "center" } }}>
      <PrimaryButton onClick={() => {
          createCreditNote(referInvoiceNumber, selectItem)
          setReferInvoiceNumber('')
          setOpenRefer(false)
        }} text={t("documents.confirm")} />
      <DefaultButton onClick={() => setOpenRefer(false)} text={t("documents.cancel")} />
    </DialogFooter>
  </Dialog>
  }

  function DialogCreateSelectedCreditNote() {
    return <Dialog
    hidden={!openReferSelectedItems}
    onDismiss={() => setOpenReferSelectedItems(false)}
    styles={{ main: { minHeight: 0 } }}
    dialogContentProps={{
      type: DialogType.largeHeader,
      title: t("dynamicFo.createCreditNote"),
      styles: { header: { textAlign: "center" }, title: { paddingRight: 24 }, subText: { textAlign: "center" } },
      subText: "",
    }}
  >
    <Stack horizontal tokens={{ childrenGap: 10}}>
      <Text styles={{ root: { width: 176, fontWeight: 700 }}}>{t("dynamicFo.creditNoteNumber")}</Text>
      <Text styles={{ root: { width: 176, fontWeight: 700 }}}>{t("dynamicFo.referNumber")}</Text>
    </Stack>
    {selectedItems.map((_item, index) => {
      return (_item.grandTotal < 0 ? <Stack horizontal tokens={{ childrenGap: 10}}>
        <Text styles={{ root: { width: 176, paddingTop: 17 }}}>{_item.number}</Text>
        <TextField styles={{ root: { width: 176, marginTop: 10 }}} onChange={(e, value) => {
            _item.referInvoiceNumber = value
          }} />
      </Stack> : null)
    })}
    <DialogFooter styles={{ actionsRight: { textAlign: "center" } }}>
      <PrimaryButton onClick={async () => {
          setOpenReferSelectedItems(false)
          setIsCreateDocument(true);
          await createSelectedCreditNote()
        }} text={t("documents.confirm")} />
      <DefaultButton onClick={() => setOpenReferSelectedItems(false)} text={t("documents.cancel")} />
    </DialogFooter>
  </Dialog>
  }

  useEffect(() => {
    console.log("selectedItems", selectedItems)
  }, [selectedItems])
  

  return (
    <div style={homeStyles}>
      {!isLoading && (
        <div>
          <Stack>
            <center>
              <h2 style={{ marginLeft: "0px", marginTop: "25px" }}>{t("dynamicFo.header")}</h2>
              {!loadDataComplete && (
                <div>
                  <Spinner
                    label={t("dynamicFo.loading")}
                    labelPosition="right"
                    style={{
                      marginTop: "21px",
                      marginBottom: "2px",
                    }}
                  />
                  <br />
                </div>
              )}
            </center>
          </Stack>

          {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
            <Stack vertical tokens={{ childrenGap: "10px" }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
                <TextField
                  name="createdBy"
                  label={t("dynamicFo.createdBy")}
                  description={t("dynamicFo.createdByDesc")}
                  styles={{ root: { width: 200 } }}
                  onChange={(e) => {
                    setCreatedBy(e.target.value);
                  }}
                  value={createdBy}
                  disabled={isCreateDocument}
                />
              </Stack>
              <br />
            </Stack>
          )}

          {selectedItems.length > 0 && !isCreateDocument && (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: "10px" }}>
              <PrimaryButton
                text={t("dynamicFo.createDocument")}
                iconProps={{ iconName: "Add" }}
                menuProps={{
                  items: [
                    ...(selectedItems.every(_item => _item.grandTotal < 0) ? 
                    [
                      {
                        key: 'CREDIT-NOTE',
                        text: t('trCloud.creditNote'),
                        iconProps: { iconName: 'PageAdd' },
                        onClick: async (event) => {
                          setOpenReferSelectedItems(true)
                        },
                      }
                    ] : [{
                      key: "RECEIPT-TAXINVOICE",
                      text: t("dynamicFo.receipt-taxinvoice"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments("RECEIPT-TAXINVOICE");
                      },
                    },
                    {
                      key: "RECEIPT-TAXINVOICE-ABB",
                      text: t("dynamicFo.receipt-taxinvoice-abb"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments("RECEIPT-TAXINVOICE-ABB");
                      },
                    },
                    {
                      key: "RECEIPT",
                      text: t("dynamicFo.receipt"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments("RECEIPT");
                      },
                    },
                    {
                      key: "INVOICE-TAXINVOICE",
                      text: t("dynamicFo.invoice-taxinvoice"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments("INVOICE-TAXINVOICE");
                      },
                    },
                    {
                      key: "DELIVERYORDER-TAXINVOICE",
                      text: t("dynamicFo.deliveryorder-taxinvoice"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments("DELIVERYORDER-TAXINVOICE");
                      },
                    },
                    {
                      key: "TAXINVOICE",
                      text: t("dynamicFo.taxinvoice"),
                      iconProps: { iconName: "PageAdd" },
                      onClick: async () => {
                        setIsCreateDocument(true);
                        await createSelectedDocuments("TAXINVOICE");
                      },
                    }])
                    
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
              <DefaultButton
                text={t("dynamicFo.cancel")}
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  let _items = selection.getItems();
                  let _selectedItems = selection.getSelection();
                  console.log("selection: ", selection.getSelection());

                  for (let i = 0; i < _selectedItems.length; i++) {
                    console.log("select item key: ", _selectedItems[i].key);
                    _selectedItems[i].status = "";

                    for (let j = 0; j < _items.length; j++) {
                      if (_items[j].key === _selectedItems[i].key) {
                        console.log("item key: ", _items[j].key);
                        _items[j].status = "";
                        _selectedItems[i].status = "";
                      }
                    }
                  }

                  selection.setAllSelected(false);

                  setItems(_items);
                  console.log("selection: ", selection.getSelection());
                }}
              />
              <br />
            </Stack>
          )}

          {isCreateDocument && (
            <div>
              <Spinner label={t("dynamicFo.signProcessing")} size={SpinnerSize.large} />
            </div>
          )}

          {isCreateDocumentComplete && (
            <center>
              <PrimaryButton
                text={t("dynamicFo.createdDocuments")}
                iconProps={{ iconName: "Documentation" }}
                onClick={async () => {
                  console.log(selectedItems);
                  history.push({
                    pathname: "/etax/documents/all",
                    state: {},
                  });
                }}
              />
            </center>
          )}
          <br />
          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: "98%",
                minWidth: "800px",
                maxWidth: "1350px",
              }}
            >
              <Stack 
                horizontal 
                styles={{
                  root: {
                    backgroundColor: 'white',
                  },
                }}
                horizontalAlign="space-between"
              >
                <Stack
                  styles={{
                    root: {
                      width: 'calc(100vw - 700px)',
                      minWidth: '700px',
                      maxWidth: '900px',
                    },
                  }}
                  
                >
                  <div ref={targetButton}>
                    <CommandBar 
                      items={commandBarItems} 
                      overflowItems={overflowItems}
                    />
                  </div>
                  {isCoachmarkVisible && (
                    <Coachmark
                      target={targetButton.current}
                      styles={{root: {marginLeft: 100}}}
                      positioningContainerProps={{
                        directionalHint: DirectionalHint.topLeftEdge,
                        
                      }}
                    >
                      <TeachingBubbleContent
                        // headline="Example Title"
                        hasCloseButton={true}
                        closeButtonAriaLabel="Close"
                        onDismiss={() => {
                          setIsCoachmarkVisible(false)
                          sessionStorage.setItem("dynamicFo-isCoachmarkVisible", "false")
                        }}
                      >
                        {t("dynamicFo.selectCompanyCoachMark")}
                      </TeachingBubbleContent>
                    </Coachmark>
                  )}
              </Stack>
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                    },
                  }}
                  tokens={{ childrenGap: "10px" }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        width: 270,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete || isCreateDocument}
                    placeholder={t("dynamicFo.searchBoxPlaceholder")}
                    onChange={(e) => {
                      setSearch(e ? e.target.value : "");
                    }}
                    onClear={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    onSearch={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    value={search}
                  />
                  <PrimaryButton
                    disabled={!loadDataComplete || !search || isCreateDocument}
                    styles={{
                      root: {
                        marginTop: 6,
                        marginRight: 18,
                        width: 90,
                      },
                    }}
                    text={t("dynamicFo.search")}
                    onClick={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                  />
                </Stack>
              </Stack>
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selection={selection}
                selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
              />
            </Stack>
          </Stack>
          <br />
          <center>
            {!(currentPage === 1 && items.length < pageSize) && !(!loadDataComplete || isCreateDocument) && (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{
                  iconName: "DoubleChevronLeft",
                }}
                previousPageIconProps={{
                  iconName: "ChevronLeft",
                }}
                nextPageIconProps={{
                  iconName: "ChevronRight",
                }}
                lastPageIconProps={{
                  iconName: "DoubleChevronRight",
                }}
              />
            )}

            <br />
            <br />
          </center>
        </div>
      )}

      {isLoading && (
        <div>
          <br />
          <br />
          <br />
          <br />
          <Stack>
            <Stack horizontal horizontalAlign="center">
              <div>
                <Spinner label={t("dynamicFo.loading")} labelPosition="down" styles={spinnerStyles} />
              </div>
            </Stack>
          </Stack>
          <br />
        </div>
      )}
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: "center" }, title: { paddingRight: 24 }, subText: { textAlign: "center" } },
          subText: dialogConfirm.message,
        }}
      >
        {dialogConfirm.children}
        <DialogFooter styles={{ actionsRight: { textAlign: "center" } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t("documents.confirm")} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t("documents.cancel")} />
        </DialogFooter>
      </Dialog>
      <DialogCreateCreditNote />
      <DialogCreateSelectedCreditNote />
    </div>
  );
};

export default DynamicsFinanceOperations;
