import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../authConfig';

import { useHistory } from 'react-router-dom';

import {
  mergeStyles,
  PrimaryButton,
  DefaultButton,
  IconButton,
  Stack,
  getTheme,
  Shimmer,
  ShimmerElementType,
  Icon,
  FontIcon,
  DocumentCard,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardImage,
  Dialog,
  DialogType,
  Spinner,
  mergeStyleSets,
  FontWeights,
  Modal,
} from '@fluentui/react';

import { useBoolean } from '@fluentui/react-hooks';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

//Style

const centerItem = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

const iconClass = mergeStyles({
  fontSize: 20,
  margin: '0 15px',
  color: 'green',
});

const shimmerRow = [
  { type: ShimmerElementType.line, width: 100 },
  { type: ShimmerElementType.gap, width: 10 },
  { type: ShimmerElementType.line, width: 200 },
];

//Card style

const cardStyles = {
  root: { display: 'inline-block', width: 320, margin: '2%', boxShadow: theme.effects.elevation4 },
};

const cardStylesDisable = {
  root: { display: 'inline-block', width: 320, margin: '2%', boxShadow: theme.effects.elevation4, background: '#dddddd', opacity: 0.5 },
};

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

// Modal style
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },

  header: [
    theme.fonts.xxLarge,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],

  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
    fontSize: '16px',
  },
});

const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const Signatures = () => {
  const history = useHistory();

  const [clickAdd] = useState(false);
  const [certificates, setCertificates] = useState([
    {
      certificateName: '',
      email: '',
      id: '',
      validity: {
        notAfter: '',
        notBefore: '',
      },
      subject: {
        organizationIdentifier: '',
      },
      serialNumber: '',
    },
  ]);
  const [certificatesRequest, setCertificatesRequest] = useState({});
  const [isLoadComplete, setIsLoadComplete] = useState(false);
  const [isCancelComplete, setIsCancelComplete] = useState(true);

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const handleCancel = () => {
    console.log('Click cancel!');

    let confirm = window.confirm('กรุณายืนยัน การยกเลิกการติดตั้งลายเซ็นดิจิทัล');

    if (confirm) {
      setIsCancelComplete(false);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          console.log(tokenResponse);

          //Update certificates request
          axios
            .put(
              API_URL + '/certificate-requests/' + certificatesRequest.id,
              {
                period: certificatesRequest.period,
                taxId: certificatesRequest.taxId,
                type: certificatesRequest.type,
                nameTH: certificatesRequest.nameTH,
                nameEN: certificatesRequest.nameEN,
                email: certificatesRequest.email,

                identity: certificatesRequest.identity,
                address: certificatesRequest.address,
                postcode: certificatesRequest.postcode,
                district: certificatesRequest.district,
                province: certificatesRequest.province,
                phone: certificatesRequest.phone,
                mobile: certificatesRequest.mobile,

                firstName: certificatesRequest.firstName,
                lastName: certificatesRequest.lastName,
                contactPhone: certificatesRequest.contactPhone,
                contactMobile: certificatesRequest.contactMobile,
                contactEmail: certificatesRequest.contactEmail,

                ca: certificatesRequest.ca,
                createdTime: certificatesRequest.createdTime,
                updatedTime: certificatesRequest.updatedTime,
                status: 'canceled',

                document1: certificatesRequest.document1,
                document2: certificatesRequest.document2,
                document3: certificatesRequest.document3,
                document4: certificatesRequest.document4,
                document5: certificatesRequest.document5,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              }
            )
            .then(
              (response) => {
                console.log('Certificate requests update : ', response);

                if (response.data) {
                  setCertificatesRequest(response.data);
                }

                setIsCancelComplete(true);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          if (error.errorMessage.indexOf('interaction_required') !== -1) {
            instance.acquireTokenRedirect(silentRequest);
          }
        });
    }
  };

  useEffect(() => {
    console.log('userEffect initial Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        console.log(tokenResponse);

        //Retrieve account
        axios
          .get(API_URL + '/accounts', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log('Account data: ', response);

              if (response.data[0]) {
                console.log('Certificates: ', response.data[0].certificates);

                let _items = [];

                response.data[0].certificates.forEach((item, index) => {
                  _items.push({
                    certificateName: item.certificateName,
                    email: item.email,
                    id: item.id,
                    validity: {
                      notAfter: new Date(new Date(item.validity.notAfter) - 864e5).toLocaleString('th-TH', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        timeZone: 'Asia/Bangkok',
                      }),

                      notBefore: new Date(item.validity.notBefore).toLocaleDateString('th-TH', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        timeZone: 'Asia/Bangkok',
                      }),
                    },
                    subject: {
                      organizationIdentifier: item.subject.organizationIdentifier,
                    },
                    serialNumber: item.serialNumber,
                  });
                });

                setCertificates(_items);
              }
            },
            (error) => {
              console.log(error);
            }
          );

        //Retrieve certificates request
        axios
          .get(API_URL + '/certificate-requests/', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log('Certificate requests : ', response);

              if (response.data[0]) {
                console.log('Certificates request data: ', response.data);

                response.data.forEach((item, index) => {
                  if (item.status === 'created' || item.status === 'uploaded' || item.status === 'shipped') {
                    setCertificatesRequest(item);
                  }
                });
              }

              setIsLoadComplete(true);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [certificatesRequest]);

  return (
    <Stack>
      <h2 style={{ marginLeft: '0px', marginTop: '27px' }}>
        <Icon iconName="Certificate" style={{ color: '#106ebe' }} /> ใบรับรองอิเล็กทรอนิกส์
      </h2>
      {clickAdd && (
        <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 10, background: '#ffffff', margin: '2%', boxShadow: theme.effects.elevation4 }}>
          <h4 style={{ color: '#106ebe' }}>เอกสารสำหรับขอลายเซ็นดิจิทัล</h4>
          <ul>
            <li>สำเนาหนังสือรับรองบริษัท อายุไม่เกิน 3 เดือน (ทางเรามีบริการคัดสำเนา ค่าบริการ 250 บาท)</li>
            <li>สำเนาบัตรประชาชนกรรมการบริษัท</li>
          </ul>
          <h4 style={{ color: '#106ebe' }}>ค่าลายเซ็นดิจิทัล (จ่ายให้หน่วยงานที่ออกลายเซ็นดิจิทัล)</h4>
          <ul>
            <li>ลายเซ็นดิจิทัล 1 ปี ราคา 3,000 บาท (ไม่รวม Vat)</li>
            <li>ลายเซ็นดิจิทัล 2 ปี ราคา 5,000 บาท (ไม่รวม Vat)</li>
          </ul>
          <small>* ลายเซ็นดิจิทัลหลังจากติดตั้งลงไปในะระบบแล้วจะไม่สามารถคัดลอกออกไปได้</small>
          <h4 style={{ color: '#106ebe' }}>ค่าบริการ</h4>
          <ul>
            <li>
              <del>ค่าดำเนินการขอลายเซ็นดิจิทัล และค่าบริการติดตั้งลงในระบบ 5,000 บาท</del>
            </li>
            <li>
              <del>ค่าบริการลงทะเบียนแบบ บ.อ.01 ขอจัดทำใบกำกับภาษีอิเล็กทรอนิกส์และใบรับอิเล็กทรอนิกส์กับกรมสรรพากร 500 บาท</del>
            </li>
            <li style={{ color: 'green' }}>โปรโมชั่น ฟรี! ค่าติดตั้งและค่าดำเนินการ จนถึงวันที่ 30 เม.ย. 2564</li>
          </ul>
          <br />
          สอบถามรายละเอียดเพิ่มเติม หรือสั่งดำเนินการ
          <br />
          ติดต่อฝ่ายบริการลูกค้า โทร 082-579-8555 อีเมล support@leceipt.com (เวลา 8.30 - 17.30 น. ทุกวัน)
          <br />
        </div>
      )}

      <Stack horizontal>
        <div
          style={{
            width: '620px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 30,
            background: '#ffffff',
            marginLeft: 0,
            marginTop: 0,
            boxShadow: theme.effects.elevation4,
          }}
        >
          {certificates[0].certificateName ? (
            <div className="ms-Grid-row">
              <strong>ชื่อ:</strong>&nbsp;&nbsp; {certificates && certificates[0].certificateName}{' '}
              <FontIcon iconName="SkypeCircleCheck" className={iconClass} />
              <br />
              <br />
              <strong>เลขที่:</strong>&nbsp;&nbsp; {certificates && certificates[0].subject.organizationIdentifier}
              <br />
              <br />
              <strong>อีเมล:</strong>&nbsp;&nbsp; {certificates && certificates[0].email}
              <br />
              <br />
              <strong>วันที่เริ่มต้น:</strong>&nbsp;&nbsp; {certificates && certificates[0].validity.notBefore}
              <br />
              <br />
              <strong>วันที่สิ้นสุด (หมดอายุ):</strong>&nbsp;&nbsp; {certificates && certificates[0].validity.notAfter}
              <br />
              <br />
              <br />
            </div>
          ) : (
            <div className="ms-Grid-row">
              <Shimmer shimmerElements={shimmerRow} width="410px" />
              <br />
              <Shimmer shimmerElements={shimmerRow} width="410px" />
              <br />
              <Shimmer shimmerElements={shimmerRow} width="410px" />
              <br />
              <Shimmer shimmerElements={shimmerRow} width="410px" />
              <br />
              <Shimmer shimmerElements={shimmerRow} width="410px" />
              <br />
              <br />
            </div>
          )}
        </div>
      </Stack>
      <br />
      <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
        {(certificatesRequest.status === 'created' || certificatesRequest.status === 'uploaded' || certificatesRequest.status === 'shipped') && (
          <DefaultButton text="ยกเลิกการติดตั้งใบรับรอง" onClick={handleCancel} disabled={certificatesRequest.status === 'shipped' ? true : false} />
        )}
        {isLoadComplete &&
          certificatesRequest.status !== 'created' &&
          certificatesRequest.status !== 'uploaded' &&
          certificatesRequest.status !== 'shipped' && (
            <PrimaryButton
              iconProps={{ iconName: 'Add' }}
              name="add"
              text="เพิ่มใบรับรอง"
              onClick={() => {
                history.push({
                  pathname: '/ca/terms-conditions',
                  state: {},
                });
              }}
            />
          )}
        {!isLoadComplete && <Spinner label="กำลังโหลด..." labelPosition="right" />}
      </Stack>
      {(certificatesRequest.status === 'created' || certificatesRequest.status === 'uploaded' || certificatesRequest.status === 'shipped') && (
        <Stack>
          <br />
          <br />

          <h2 style={{ margin: '2%', textAlign: 'center' }}>ขั้นตอนการติดตั้ง ใบรับรองอิเล็กทรอนิกส์</h2>
          <Stack horizontal>
            <DocumentCard
              styles={certificatesRequest.status === 'uploaded' || certificatesRequest.status === 'shipped' ? cardStylesDisable : cardStyles}
              onClick={() => {
                if (certificatesRequest.status === 'created') {
                  history.push({
                    pathname: '/ca/documents',
                    state: certificatesRequest,
                  });
                }
              }}
            >
              <center>
                <DocumentCardImage
                  height={150}
                  imageSrc="/images/submit.png"
                  styles={{ root: { margin: '2%', background: '#ffffff', padding: '10%' } }}
                />
              </center>
              <DocumentCardDetails>
                <DocumentCardTitle
                  title={
                    certificatesRequest.status === 'uploaded' || certificatesRequest.status === 'shipped'
                      ? 'ท่านได้อัพโหลดเอกสารแล้ว'
                      : 'ขั้นตอนที่ 1: อัพโหลดเอกสาร'
                  }
                  shouldTruncate
                />
              </DocumentCardDetails>
            </DocumentCard>
            <DocumentCard
              styles={certificatesRequest.status === 'created' || certificatesRequest.status === 'shipped' ? cardStylesDisable : cardStyles}
              onClick={showModal}
            >
              <center>
                <DocumentCardImage
                  height={150}
                  imageSrc="/images/sent.png"
                  styles={{ root: { margin: '2%', background: '#ffffff', padding: '10%' } }}
                />
              </center>
              <DocumentCardDetails>
                <DocumentCardTitle
                  title={certificatesRequest.status === 'shipped' ? 'ท่านได้ส่งเอกสารแล้ว' : 'ขั้นตอนที่ 2: ส่งเอกสารทางไปรษณีย์'}
                  shouldTruncate
                />
              </DocumentCardDetails>
            </DocumentCard>
          </Stack>
        </Stack>
      )}

      <br />
      <br />

      <Dialog
        hidden={isCancelComplete}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: 'กำลังดำเนินการ...',
        }}
      >
        <Spinner label="ระบบกำลังดำเนินการ..." labelPosition="down" styles={spinnerStyles} />
      </Dialog>

      <Modal isOpen={isModalOpen} onDismiss={hideModal} isBlocking={false} containerClassName={contentStyles.container} dragOptions={true}>
        <div className={contentStyles.header}>
          <span id={1}>ขั้นตอนที่ 2: ส่งเอกสารทางไปรษณีย์</span>
          <IconButton styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }} onClick={hideModal} />
        </div>

        <div className={contentStyles.body}>
          <p>กรุณารอเจ้าหน้าที่โทรติดต่อท่าน ก่อนจัดส่งเอกสารทางไปรษณีย์ ปกติจะใช้เวลาประมาณ 1-2 วันทำการในการตรวจสอบเอกสาร</p>
          <p>หากต้องการสอบถาม โทร. 082-579-8555 เวลา 8.30 - 17.30 น. (ทุกวัน จันทร์ - อาทิตย์)</p>
          <br />
          <u>ที่อยู่ในการจัดส่งเอกสาร</u>
          <p>
            ฝ่ายบริหารจัดการใบรับรองอิเล็กทรอนิกส์
            <br />
            บริษัท ฟรีเวชั่น จำกัด
            <br />
            682 หมู่ 1 ถ.สุขาภิบาล 1<br />
            ต.บรบือ อ.บรบือ จ.มหาสารคาม 44130
            <br />
            โทร. 082-579-8555
          </p>
          <p>
            หลังจากบริษัท ได้รับเอกสารทางไปรษณีย์แล้ว จะดำเนินการขอใบรับรองอิเล็กทรอนิกส์ให้ท่าน ปกติบริษัทที่มีหน้าที่ออกใบรับรองอิเล็กทรอนิกส์ (CA)
            จะใช้เวลาดำเนินการประมาณ 3-5 วันทำการ
          </p>
          <p>ท่านสามารถโทรสอบถามสถานะการดำเนินงานได้ตลอด ที่เบอร์ 082-579-8555 เวลา 8.30 - 17.30 น. (ทุกวัน จันทร์ - อาทิตย์)</p>
          <br />
        </div>
      </Modal>
    </Stack>
  );
};

export default Signatures;
