export function getBranchFromName(name) {
    let newName = name
    let branchNumber = ''
    let branchText = ''

    if (newName.includes('สำนักงานใหญ่') || 
        newName.includes('Head Office') || 
        newName.includes('Head office') || 
        newName.includes('head office')) {

        branchNumber = '00000'
        if (newName.includes('สำนักงานใหญ่')) {
            branchText = 'สำนักงานใหญ่'
            newName = newName.replace('สำนักงานใหญ่', '')
        } else {
            branchText = 'Head Office'

            if (newName.includes('Head Office')) {
                newName = newName.replace('Head Office', '')
            } else if (newName.includes('Head office')) {
                newName = newName.replace('Head office', '')
            } else if (newName.includes('head office')) {
                newName = newName.replace('head office', '')
            }
        }
    } else if (newName.match(/(?<!\d)\d{5}(?!\d)/g)) {
        if (newName.match(/(?<!\d)\d{5}(?!\d)/g)) {
            branchNumber = newName.match(/(?<!\d)\d{5}(?!\d)/g)[0]
            if (newName.includes('Branch') || newName.includes('branch')) {
                branchText = 'Branch ' + branchNumber

                if (newName.includes('Branch')) {
                    newName = newName.replace('Branch', '')
                } else if (newName.includes('branch')) {
                    newName = newName.replace('branch', '')
                }
            } else {
                branchText = 'สาขา ' + branchNumber

                if (newName.includes('สาขาที่')) {
                    newName = newName.replace('สาขาที่', '')
                } else if (newName.includes('สาขา')) {
                    newName = newName.replace('สาขา', '')
                }
            }

            newName = newName.replace(branchNumber, '')
        }
    }

    return { name: newName.trim(), branchNumber, branchText}
}