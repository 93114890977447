import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { PrimaryButton, getTheme, Separator } from '@fluentui/react';

const theme = getTheme();

const ProfileUpdateComplete = () => {
  console.log('Re render');

  const history = useHistory();

  useEffect(() => {
    console.log('userEffect initial call!');
  }, []);

  return (
    <div>
      <form>
        <br />
        <center>
          <div
            className="ms-Grid"
            dir="ltr"
            style={{
              width: 370,
              height: 530,
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              paddingBottom: 30,
              background: '#ffffff',
              marginTop: '25px',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <div className="ms-Grid-row">
              <h2 style={{ margin: '2%', color: '#0078D4' }}>อัปเดตข้อมูลผู้ใช้งานสำเร็จ!</h2>
              <Separator />
              <br />
              <br />
              <img height="150" src="/images/success03.gif" alt="success03gif" />
              <br />
              <br />
              <br />
              ระบบได้อัปเดตข้อมูลผู้ใช้งาน
              <br />
              <br />
              เรียบร้อยแล้ว
              <br />
              <br />
              <br />
              <br />
              <PrimaryButton
                onClick={() => {
                  history.push({
                    pathname: '/',
                    state: {},
                  });
                }}
                text="หน้าแรก"
              />
            </div>
          </div>
        </center>
      </form>
    </div>
  );
};

export default ProfileUpdateComplete;
