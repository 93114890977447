const TextEncoder = require('text-encoding').TextEncoder;

function formatDateTime(date) {
  let d = new Date(date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = '' + d.getHours(),
    minute = '' + d.getMinutes(),
    second = '' + d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [year, month, day, hour, minute, second].join('');
}

export async function KTBTextFile(docArray) {
  let text = '';

  docArray.forEach((doc) => {
    // text = text + KTBPaymentDetail(doc) + '\n' + KTBWHT(doc) + '\n' + KTBInvoice(doc);
    text = text + KTBPaymentDetail(doc) + KTBWHT(doc) + KTBInvoice(doc);
    // text = text + KTBPaymentDetail(doc);
  });

  const convertString = text;

  const time = formatDateTime(new Date().toISOString());

  const element = document.createElement('a');
  const file = new Blob([convertString], { endings: 'native' });

  const utf_8_txt = await file.text();

  const encoder = new TextEncoder('windows-874', {
    NONSTANDARD_allowLegacyEncoding: true,
  });

  const data = encoder.encode(utf_8_txt); // now `data` is an Uint8Array

  const encoded_as_ANSI = new Blob([data]);

  element.href = URL.createObjectURL(encoded_as_ANSI);
  element.download = 'KTB-' + time + '.txt';
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

export function KTBPaymentDetail(doc) {
  const data = doc.data;
  let text = '';

  // Payment Detail
  let RecordType = 'D'; // length 1, require: true

  let PaymentRefNo1 = data.number.padEnd(20, ' '); // length 20, true
  if (data.number.length > 20) {
    PaymentRefNo1 = data.number.substring(0, 20);
  }

  let PaymentRefNo2 = ''.padEnd(20, ' '); // length 20, false
  let PaymentRefNo3 = ''.padEnd(20, ' '); // length 20, false
  let SupplierReferenceNo = ''.padEnd(15, ' '); // length 15, false

  let PayType = 'C'.padEnd(1, ' '); // length 1, true

  if (data.paymentList?.some((_payment) => _payment.paymentCode === 'BANK')) {
    PayType = 'T'.padEnd(1, ' ');
  }

  let PayeeName = data.customer.name.padEnd(100, ' '); // length 100, true
  if (data.customer.name.length > 20) {
    PaymentRefNo1 = data.customer.name.substring(0, 100);
  }

  let PayeeIdCardNo = ''.padEnd(15, ' '); // length 15, false

  let _address = '';
  if (data.customer.unstructure) {
    _address = data.customer.addressLineOne;
    if (data.customer.addressLineTwo) {
      _address = _address + ' ' + data.customer.addressLineTwo;
    }
  } else {
    _address = _address + data.customer.buildingNumber;
    if (data.customer.address) _address = _address + ' ' + data.customer.address;
    if (data.customer.streetName) _address = _address + ' ' + data.customer.streetPrefix + data.customer.streetName;

    if (data.customer.subDistrictName)
      _address =
        _address + ' ' + (data.customer.subDistrictPrefix === 'เขต' ? 'เขต ' : data.customer.subDistrictPrefix) + data.customer.subDistrictName;
    if (data.customer.districtName)
      _address = _address + ' ' + (data.customer.districtPrefix === 'แขวง' ? 'แขวง ' : data.customer.districtPrefix) + data.customer.districtName;
    if (data.customer.provinceName)
      _address = _address + ' ' + (data.customer.provincePrefix ? data.customer.provincePrefix : '') + data.customer.provinceName;
  }

  let PayeeAddress1 = '-'.padEnd(70, ' '); // length 70, true
  if (_address.substring(0, 70)) {
    PayeeAddress1 = _address.substring(0, 70).padEnd(70, ' ');
  }

  let PayeeAddress2 = '-'.padEnd(70, ' '); // length 70, true
  if (_address.substring(70, 140)) {
    PayeeAddress2 = _address.substring(70, 140).padEnd(70, ' ');
  }

  let PayeeAddress3 = ''.padEnd(70, ' '); // length 70, false
  if (_address.substring(140, 210)) {
    PayeeAddress3 = _address.substring(140, 210).padEnd(70, ' ');
  }

  let PostCode = data.customer.postcode.padEnd(5, ' '); // length 5, false

  let PayeeBankCode = ''.padEnd(3, ' '); // length 3, false
  let PayeeBankACNo = ''.padEnd(20, ' '); // length 20, false
  let EffectiveDate = ''.padEnd(8, ' '); // length 8, true

  let filterPaymentList = data.paymentList?.filter((_payment) => _payment.paymentCode === 'BANK');

  if (filterPaymentList.length) {
    if (filterPaymentList[filterPaymentList.length - 1].bankCode === 'KBANK') {
      PayeeBankCode = '004';
      PayeeBankACNo = filterPaymentList[filterPaymentList.length - 1].accountNumber.padEnd(20, ' ');
    } else if (filterPaymentList[filterPaymentList.length - 1].bankCode === 'SCB') {
      PayeeBankCode = '014';
      PayeeBankACNo = filterPaymentList[filterPaymentList.length - 1].accountNumber.padEnd(20, ' ');
    } else if (filterPaymentList[filterPaymentList.length - 1].bankCode === 'KTB') {
      PayeeBankCode = '006';
      PayeeBankACNo = filterPaymentList[filterPaymentList.length - 1].accountNumber.padEnd(20, ' ');
    } else if (filterPaymentList[filterPaymentList.length - 1].bankCode === 'TTB') {
      PayeeBankCode = '065';
      PayeeBankACNo = filterPaymentList[filterPaymentList.length - 1].accountNumber.padEnd(20, ' ');
    } else if (filterPaymentList[filterPaymentList.length - 1].bankCode === 'BBL') {
      PayeeBankCode = '002';
      PayeeBankACNo = filterPaymentList[filterPaymentList.length - 1].accountNumber.padEnd(20, ' ');
    } else if (filterPaymentList[filterPaymentList.length - 1].bankCode === 'BAY') {
      PayeeBankCode = '025';
      PayeeBankACNo = filterPaymentList[filterPaymentList.length - 1].accountNumber.padEnd(20, ' ');
    } else if (filterPaymentList[filterPaymentList.length - 1].bankCode === 'UOB') {
      PayeeBankCode = '024';
      PayeeBankACNo = filterPaymentList[filterPaymentList.length - 1].accountNumber.padEnd(20, ' ');
    }

    const dateCESplit = filterPaymentList[filterPaymentList.length - 1].dateCE.split('/');
    EffectiveDate = dateCESplit[0] + dateCESplit[1] + dateCESplit[2]; // length 8, true
  }

  let InvoiceAmount = data.amountTotal
    .toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    .padStart(20, ' '); // length 20, false
  let TotalVATAmount = data.vatTotal
    .toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    .padStart(20, ' '); // length 20, false
  let VATPercent = '7'.padStart(5, ' '); // length 5, false
  let TotalWHTAmount = data.whtTotal
    .toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    .padStart(20, ' '); // length 20, false

  let TotalTaxableAmount = data.vatPriceTotal
    .toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    .padStart(20, ' '); // length 20, false

  // let TotalDiscountAmount = data.discount
  //   .toLocaleString(undefined, {
  //     maximumFractionDigits: 2,
  //     minimumFractionDigits: 2,
  //   })
  //   .padStart(20, ' '); // length 20, false

  let TotalDiscountAmount = ''.padStart(20, ' ');

  let NetChequeTrAmount = data.paidTotal
    ? data.paidTotal
        .toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
        .padStart(20, ' ')
    : '0'
        .toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
        .padStart(20, ' '); // length 20, true

  let DeliveryMethod = ''.padEnd(2, ' '); // length 2, true
  let PickupchequeLocation = ''.padEnd(15, ' '); // length 15, true

  let ChequeNumber = ''.padEnd(10, ' '); // length 10, false
  let ChequeStatus = ''.padEnd(5, ' '); // length 5, false
  let ChequeStatusDate = ''.padEnd(8, ' '); // length 8, false

  let NotificationMethod = ''.padEnd(1, ' '); // length 1, false
  let MobileNumber = ''.padEnd(20, ' '); // length 20, false
  if (data.customer.phone) {
    MobileNumber = data.customer.phone.padEnd(20, ' ');
  }

  let EmailAddress = ''.padEnd(70, ' '); // length 70, false
  if (data.customer.email) {
    EmailAddress = data.customer.email.padEnd(70, ' ');
  }
  let FAXNumber = ''.padEnd(20, ' '); // length 20, false
  let DateReturnChequetoCompany = ''.padEnd(8, ' '); // length 8, false
  let ReturnChequeMethod = ''.padEnd(3, ' '); // length 3, false
  let StrikethroughFlag = ''.padEnd(1, ' '); // length 1, false
  let AccountPayeeOnlyFlag = ''.padEnd(1, ' '); // length 1, false
  let AcknowledgementDocumentNotify = ''.padEnd(200, ' '); // length 200, false
  let PrintLocation = ''.padEnd(15, ' '); // length 15, false

  let FileBatchNoBankReference = ''.padEnd(25, ' '); // length 25, false
  let KTBRef = ''.padEnd(30, ' '); // length 30, false
  let ForKTBSystem = ''.padEnd(151, ' '); // length 151, false
  let FreeFiller = ''.padEnd(350, ' '); // length 350, false
  let CarriageReturn = ''.padEnd(1, ''); // length 1, false
  let EndofLine = ''.padEnd(1, '\n'); // length 1, false

  text =
    RecordType +
    PaymentRefNo1 +
    PaymentRefNo2 +
    PaymentRefNo3 +
    SupplierReferenceNo +
    PayType +
    PayeeName +
    PayeeIdCardNo +
    PayeeAddress1 +
    PayeeAddress2 +
    PayeeAddress3 +
    PostCode +
    PayeeBankCode +
    PayeeBankACNo +
    EffectiveDate +
    InvoiceAmount +
    TotalVATAmount +
    VATPercent +
    TotalWHTAmount +
    TotalTaxableAmount +
    TotalDiscountAmount +
    NetChequeTrAmount +
    DeliveryMethod +
    PickupchequeLocation +
    ChequeNumber +
    ChequeStatus +
    ChequeStatusDate +
    NotificationMethod +
    MobileNumber +
    EmailAddress +
    FAXNumber +
    DateReturnChequetoCompany +
    ReturnChequeMethod +
    StrikethroughFlag +
    AccountPayeeOnlyFlag +
    AcknowledgementDocumentNotify +
    PrintLocation +
    FileBatchNoBankReference +
    KTBRef +
    ForKTBSystem +
    FreeFiller +
    CarriageReturn +
    EndofLine;

  return text;
}

export function KTBWHT(doc) {
  const data = doc.data;

  let text = '';

  // WHT
  let RecordType = 'W'; // length 1, require: true
  let PaymentRefNo1 = data.number.padEnd(20, ' '); // length 20, true
  if (data.number.length > 20) {
    PaymentRefNo1 = data.number.substring(0, 20);
  }

  let PaymentRefNo2 = ''.padEnd(20, ' '); // length 20, false
  let PaymentRefNo3 = ''.padEnd(20, ' '); // length 20, false

  let PayeeName = data.customer.name.padEnd(100, ' '); // length 100, true
  if (data.customer.name.length > 20) {
    PayeeName = data.customer.name.substring(0, 100);
  }

  let _address = '';
  if (data.customer.unstructure) {
    _address = data.customer.addressLineOne;
    if (data.customer.addressLineTwo) {
      _address = _address + ' ' + data.customer.addressLineTwo;
    }
  } else {
    _address = _address + data.customer.buildingNumber;
    if (data.customer.address) _address = _address + ' ' + data.customer.address;
    if (data.customer.streetName) _address = _address + ' ' + data.customer.streetPrefix + data.customer.streetName;

    if (data.customer.subDistrictName)
      _address =
        _address + ' ' + (data.customer.subDistrictPrefix === 'เขต' ? 'เขต ' : data.customer.subDistrictPrefix) + data.customer.subDistrictName;
    if (data.customer.districtName)
      _address = _address + ' ' + (data.customer.districtPrefix === 'แขวง' ? 'แขวง ' : data.customer.districtPrefix) + data.customer.districtName;
    if (data.customer.provinceName)
      _address = _address + ' ' + (data.customer.provincePrefix ? data.customer.provincePrefix : '') + data.customer.provinceName;
  }

  let PayeeAddress1 = '-'.padEnd(70, ' '); // length 70, true
  if (_address.substring(0, 70)) {
    PayeeAddress1 = _address.substring(0, 70).padEnd(70, ' ');
  }

  let PayeeAddress2 = '-'.padEnd(70, ' '); // length 70, true
  if (_address.substring(70, 140)) {
    PayeeAddress2 = _address.substring(70, 140).padEnd(70, ' ');
  }

  let PayeeAddress3 = ''.padEnd(70, ' '); // length 70, false
  if (_address.substring(140, 210)) {
    PayeeAddress3 = _address.substring(140, 210).padEnd(70, ' ');
  }

  let PostCode = data.customer.postcode; // length 5, false

  let PayeeTaxID = ''; // length 15, true
  let PayeeIDcardnumber = ''; // length 15, false

  if (data.customer.taxNumberType === 'TXID' || 'NIDN') {
    PayeeTaxID = data.customer.taxId.padEnd(15, ' ');
    PayeeIDcardnumber = ''.padEnd(15, ' ');
  }

  let PayeeSocialSecurityCardNo = ''.padEnd(15, ' '); // length 15, false

  let filterPaymentList = data.paymentList?.filter((_payment) => _payment.paymentCode === 'BANK');

  let dateBESplit = data.dateBE.split('/');

  if (filterPaymentList.length) {
    dateBESplit = filterPaymentList[filterPaymentList.length - 1].dateBE.split('/');
  }
  let WHTDate = dateBESplit[0] + dateBESplit[1] + dateBESplit[2]; // length 8, false
  let WHTSeq = ''.padEnd(6, ' '); // length 6, false

  let WHTType = ''; // length 1, true
  if (data.customer.taxNumberType === 'TXID') {
    WHTType = '7';
  } else if (data.customer.taxNumberType === 'NIDN') {
    const itemTypeList = data.items.find((item) => item.whtIncomeCode === '397' || item.whtIncomeCode === '398');

    if (itemTypeList) {
      WHTType = '3';
    } else {
      WHTType = '4';
    }
  }

  const itemsList = [
    {
      number: 1,
      AmountofIncome1: '',
      TaxWithheldandPaid1: '',
      TaxType1: '',
      TaxTypeDescription1: '',
      TaxRate1: '',
    },
    {
      number: 2,
      AmountofIncome1: '',
      TaxWithheldandPaid1: '',
      TaxType1: '',
      TaxTypeDescription1: '',
      TaxRate1: '',
    },
    {
      number: 3,
      AmountofIncome1: '',
      TaxWithheldandPaid1: '',
      TaxType1: '',
      TaxTypeDescription1: '',
      TaxRate1: '',
    },
    {
      number: 4,
      AmountofIncome1: '',
      TaxWithheldandPaid1: '',
      TaxType1: '',
      TaxTypeDescription1: '',
      TaxRate1: '',
    },
    {
      number: 5,
      AmountofIncome1: '',
      TaxWithheldandPaid1: '',
      TaxType1: '',
      TaxTypeDescription1: '',
      TaxRate1: '',
    },
    {
      number: 6,
      AmountofIncome1: '',
      TaxWithheldandPaid1: '',
      TaxType1: '',
      TaxTypeDescription1: '',
      TaxRate1: '',
    },
  ];

  data.items.forEach((item, index) => {
    if (item.whtTotal > 0) {
      if (item.whtIncomeCode === '397') {
        itemsList[0].AmountofIncome1 = Number(itemsList[0].AmountofIncome1) + item.total;
        itemsList[0].TaxWithheldandPaid1 = Number(itemsList[0].TaxWithheldandPaid1) + item.whtTotal;
        itemsList[0].TaxType1 = '40';
        itemsList[0].TaxTypeDescription1 = '';
        itemsList[0].TaxRate1 = String(item.percentWht);
      } else if (item.whtIncomeCode === '398') {
        itemsList[1].AmountofIncome1 = Number(itemsList[1].AmountofIncome1) + item.total;
        itemsList[1].TaxWithheldandPaid1 = Number(itemsList[1].TaxWithheldandPaid1) + item.whtTotal;
        itemsList[1].TaxType1 = '44';
        itemsList[1].TaxTypeDescription1 = '';
        itemsList[1].TaxRate1 = String(item.percentWht);
      } else if (
        item.whtIncomeCode === '390' ||
        item.whtIncomeCode === '391' ||
        item.whtIncomeCode === '392' ||
        item.whtIncomeCode === '393' ||
        item.whtIncomeCode === '394' ||
        item.whtIncomeCode === '395'
      ) {
        itemsList[2].AmountofIncome1 = Number(itemsList[2].AmountofIncome1) + item.total;
        itemsList[2].TaxWithheldandPaid1 = Number(itemsList[2].TaxWithheldandPaid1) + item.whtTotal;
        itemsList[2].TaxType1 = '1';
        itemsList[2].TaxTypeDescription1 = '';
        itemsList[2].TaxRate1 = String(item.percentWht);
      } else if (item.whtIncomeCode === '396') {
        itemsList[3].AmountofIncome1 = Number(itemsList[3].AmountofIncome1) + item.total;
        itemsList[3].TaxWithheldandPaid1 = Number(itemsList[3].TaxWithheldandPaid1) + item.whtTotal;
        itemsList[3].TaxType1 = '2';
        itemsList[3].TaxTypeDescription1 = '';
        itemsList[3].TaxRate1 = String(item.percentWht);
      } else if (
        item.whtIncomeCode === '408' ||
        item.whtIncomeCode === '407' ||
        item.whtIncomeCode === '406' ||
        item.whtIncomeCode === '405' ||
        item.whtIncomeCode === '404' ||
        item.whtIncomeCode === '403' ||
        item.whtIncomeCode === '402' ||
        item.whtIncomeCode === '401' ||
        item.whtIncomeCode === '400' ||
        item.whtIncomeCode === '399'
      ) {
        itemsList[4].AmountofIncome1 = Number(itemsList[4].AmountofIncome1) + item.total;
        itemsList[4].TaxWithheldandPaid1 = Number(itemsList[4].TaxWithheldandPaid1) + item.whtTotal;
        itemsList[4].TaxType1 = '51';
        itemsList[4].TaxTypeDescription1 = '';
        itemsList[4].TaxRate1 = String(item.percentWht);
      } else if (item.whtIncomeCode === '999') {
        itemsList[5].AmountofIncome1 = Number(itemsList[5].AmountofIncome1) + item.total;
        itemsList[5].TaxWithheldandPaid1 = Number(itemsList[5].TaxWithheldandPaid1) + item.whtTotal;
        itemsList[5].TaxType1 = '6';
        itemsList[5].TaxTypeDescription1 = '';
        itemsList[5].TaxRate1 = String(item.percentWht);
      }
    }
  });

  const filterItemList = itemsList.filter((item) => item.TaxType1 !== '');

  let AmountofIncome1 = ''.padStart(20, ' '); // length 20, true
  let TaxWithheldandPaid1 = ''.padStart(20, ' '); // length 20, true
  let TaxType1 = ''.padEnd(2, ' '); // length 2, true
  let TaxTypeDescription1 = ''.padEnd(35, ' '); // length 35, false
  let TaxRate1 = ''.padStart(5, ' '); // length 5, false

  let AmountofIncome2 = ''.padStart(20, ' '); // length 20, false
  let TaxWithheldandPaid2 = ''.padStart(20, ' '); // length 20, false
  let TaxType2 = ''.padEnd(2, ' '); // length 2, false
  let TaxTypeDescription2 = ''.padEnd(35, ' '); // length 35, false
  let TaxRate2 = ''.padStart(5, ' '); // length 5, false

  let AmountofIncome3 = ''.padStart(20, ' '); // length 20, false
  let TaxWithheldandPaid3 = ''.padStart(20, ' '); // length 20, false
  let TaxType3 = ''.padEnd(2, ' '); // length 2, false
  let TaxTypeDescription3 = ''.padEnd(35, ' '); // length 35, false
  let TaxRate3 = ''.padStart(5, ' '); // length 5, false

  let AmountofIncome4 = ''.padStart(20, ' '); // length 20, false
  let TaxWithheldandPaid4 = ''.padStart(20, ' '); // length 20, false
  let TaxType4 = ''.padEnd(2, ' '); // length 2, false
  let TaxTypeDescription4 = ''.padEnd(35, ' '); // length 35, false
  let TaxRate4 = ''.padStart(5, ' '); // length 5, false

  let AmountofIncome5 = ''.padStart(20, ' '); // length 20, false
  let TaxWithheldandPaid5 = ''.padStart(20, ' '); // length 20, false
  let TaxType5 = ''.padEnd(2, ' '); // length 2, false
  let TaxTypeDescription5 = ''.padEnd(35, ' '); // length 35, false
  let TaxRate5 = ''.padStart(5, ' '); // length 5, false

  filterItemList.forEach((item, index) => {
    if (index === 0) {
      AmountofIncome1 = item.AmountofIncome1.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).padStart(20, ' ');
      TaxWithheldandPaid1 = item.TaxWithheldandPaid1.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).padStart(20, ' ');
      TaxType1 = item.TaxType1.padEnd(2, ' ');
      TaxTypeDescription1 = item.TaxTypeDescription1.padEnd(35, ' ');
      TaxRate1 = item.TaxRate1.padStart(5, ' ');
    } else if (index === 1) {
      AmountofIncome2 = item.AmountofIncome1.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).padStart(20, ' ');
      TaxWithheldandPaid2 = item.TaxWithheldandPaid1.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).padStart(20, ' ');
      TaxType2 = item.TaxType1.padEnd(2, ' ');
      TaxTypeDescription2 = item.TaxTypeDescription1.padEnd(35, ' ');
      TaxRate2 = item.TaxRate1.padStart(5, ' ');
    } else if (index === 2) {
      AmountofIncome3 = item.AmountofIncome1.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).padStart(20, ' ');
      TaxWithheldandPaid3 = item.TaxWithheldandPaid1.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).padStart(20, ' ');
      TaxType3 = item.TaxType1.padEnd(2, ' ');
      TaxTypeDescription3 = item.TaxTypeDescription1.padEnd(35, ' ');
      TaxRate3 = item.TaxRate1.padStart(5, ' ');
    } else if (index === 3) {
      AmountofIncome4 = item.AmountofIncome1.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).padStart(20, ' ');
      TaxWithheldandPaid4 = item.TaxWithheldandPaid1.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).padStart(20, ' ');
      TaxType4 = item.TaxType1.padEnd(2, ' ');
      TaxTypeDescription4 = item.TaxTypeDescription1.padEnd(35, ' ');
      TaxRate4 = item.TaxRate1.padStart(5, ' ');
    } else if (index === 4) {
      AmountofIncome5 = item.AmountofIncome1.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).padStart(20, ' ');
      TaxWithheldandPaid5 = item.TaxWithheldandPaid1.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).padStart(20, ' ');
      TaxType5 = item.TaxType1.padEnd(2, ' ');
      TaxTypeDescription5 = item.TaxTypeDescription1.padEnd(35, ' ');
      TaxRate5 = item.TaxRate1.padStart(5, ' ');
    }
  });

  let TotalIncomePaidAmount = data.amountTotal
    .toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    .padStart(20, ' '); // length 20, true
  let TotalWithholdingTaxPaidAmount = data.whtTotal
    .toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    .padStart(20, ' '); // length 20, true

  let WHTDocumentNo = ''.padEnd(17, ' '); // length 17, false
  let WHTSequenceNo = data.number.padEnd(17, ' '); // length 17, false
  let ProvideFundPermitNumber = ''.padEnd(12, ' '); // length 12, false
  let ProvidentFundAmount = ''.padStart(20, ' '); // length 20, false
  let AmountPaidintoSocialSecurity = ''.padStart(20, ' '); // length 20, false
  let WHTPayType = '1'.padStart(1, ' '); // length 1, false

  let FileBatchNo = ''.padEnd(25, ' '); // length 25, false
  let KTBRef = ''.padEnd(30, ' '); // length 30, false
  let ForKTBSystem = ''.padStart(245, ' '); // length 245, false
  let FreeFiller = ''.padStart(225, ' '); // length 225, false
  let CarriageReturn = ''.padStart(1, ''); // length 1, false
  let EndofLine = ''.padEnd(1, '\n'); // length 1, false

  text =
    RecordType +
    PaymentRefNo1 +
    PaymentRefNo2 +
    PaymentRefNo3 +
    PayeeName +
    PayeeAddress1 +
    PayeeAddress2 +
    PayeeAddress3 +
    PostCode +
    PayeeTaxID +
    PayeeIDcardnumber +
    PayeeSocialSecurityCardNo +
    WHTDate +
    WHTSeq +
    WHTType +
    AmountofIncome1 +
    TaxWithheldandPaid1 +
    TaxType1 +
    TaxTypeDescription1 +
    TaxRate1 +
    AmountofIncome2 +
    TaxWithheldandPaid2 +
    TaxType2 +
    TaxTypeDescription2 +
    TaxRate2 +
    AmountofIncome3 +
    TaxWithheldandPaid3 +
    TaxType3 +
    TaxTypeDescription3 +
    TaxRate3 +
    AmountofIncome4 +
    TaxWithheldandPaid4 +
    TaxType4 +
    TaxTypeDescription4 +
    TaxRate4 +
    AmountofIncome5 +
    TaxWithheldandPaid5 +
    TaxType5 +
    TaxTypeDescription5 +
    TaxRate5 +
    TotalIncomePaidAmount +
    TotalWithholdingTaxPaidAmount +
    WHTDocumentNo +
    WHTSequenceNo +
    ProvideFundPermitNumber +
    ProvidentFundAmount +
    AmountPaidintoSocialSecurity +
    WHTPayType +
    FileBatchNo +
    KTBRef +
    ForKTBSystem +
    FreeFiller +
    CarriageReturn +
    EndofLine;

  return text;
}

export function KTBInvoice(doc) {
  const data = doc.data;
  let text = '';

  // Invoice
  let RecordType = 'I'; // length 1, require: true
  let PaymentRefNo1 = data.number.padEnd(20, ' '); // length 20, true
  if (data.number.length > 20) {
    PaymentRefNo1 = data.number.substring(0, 20);
  }

  let PaymentRefNo2 = ''.padEnd(20, ' '); // length 20, false
  let PaymentRefNo3 = ''.padEnd(20, ' '); // length 20, false

  let InvoiceSequence = ''.padStart(4, ' '); // length 5, false

  let SupplierReference = '1'.padEnd(16, ' '); // length 15, false

  let InvoiceNo = data.number.padEnd(15, ' '); // length 15, true
  if (data.number.length > 15) {
    InvoiceNo = data.number.substring(0, 15);
  }

  // const dateBESplit = data.dateBE.split('/');
  // let InvoiceDate = dateBESplit[0] + dateBESplit[1] + dateBESplit[2]; // length 8, false
  let InvoiceDate = ''.padEnd(8, ' '); // length 8, false

  let itemsDescription = '';
  data.items.forEach((item, index) => {
    itemsDescription = itemsDescription + item.description;
  });

  let InvoiceDetail1row1 = itemsDescription.substring(0, 25).padEnd(25, ' '); // length 25, false
  let InvoiceDetail2row2 = ''; // length 25, false
  if (itemsDescription.substring(25, 50)) {
    InvoiceDetail2row2 = itemsDescription.substring(25, 50).padEnd(25, ' ');
  } else {
    InvoiceDetail2row2 = ''.padEnd(25, ' ');
  }

  // let InvoiceDetail1row1 = ''.padEnd(25, ' '); // length 25, false
  // let InvoiceDetail2row2 = ''.padEnd(25, ' '); // length 25, false

  let InvoiceAmount = data.amountTotal
    .toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    .padStart(20, ' '); // length 20, false
  let InvoiceVATAmount = data.vatTotal
    .toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    .padStart(20, ' '); // length 20, false
  let InvoiceWithholdingTaxAmount = data.whtTotal
    .toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })
    .padStart(20, ' '); // length 20, false

  // let InvoiceAmount = ''.padStart(20, ' '); // length 20, false
  // let InvoiceVATAmount = ''.padStart(20, ' '); // length 20, false
  // let InvoiceWithholdingTaxAmount = ''.padStart(20, ' '); // length 20, false

  let InvoiceNetAmount = data.paidTotal
    ? data.paidTotal
        .toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
        .padStart(20, ' ')
    : '0'
        .toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
        .padStart(20, ' '); // length 20, true

  let FileBatchNo = ''.padEnd(25, ' '); // length 25, false
  let KTBRef = ''.padEnd(30, ' '); // length 30, false
  let ForKTBSystem = ''.padStart(100, ' '); // length 100, false
  let FreeFiller = ''.padStart(1109, ' '); // length 1109, false
  let CarriageReturn = ''.padStart(1, ''); // length 1, false
  let EndofLine = ''.padStart(1, '\n'); // length 1, false

  text =
    RecordType +
    PaymentRefNo1 +
    PaymentRefNo2 +
    PaymentRefNo3 +
    InvoiceSequence +
    SupplierReference +
    InvoiceNo +
    InvoiceDate +
    InvoiceDetail1row1 +
    InvoiceDetail2row2 +
    InvoiceAmount +
    InvoiceVATAmount +
    InvoiceWithholdingTaxAmount +
    InvoiceNetAmount +
    FileBatchNo +
    KTBRef +
    ForKTBSystem +
    FreeFiller +
    CarriageReturn +
    EndofLine;

  return text;
}
