import React from 'react';

import { useHistory } from 'react-router-dom';

import { PrimaryButton, Stack, getTheme } from '@fluentui/react';

const theme = getTheme();

//Style
const centerItem = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

const Maintenance = () => {
  const history = useHistory();

  return (
    <div>
      <div
        className="ms-Grid"
        dir="ltr"
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 10,
          background: '#ffffff',
          margin: '2%',
          textAlign: 'center',
          boxShadow: theme.effects.elevation4,
        }}
      >
        <div className="ms-Grid-row">
          <h3>ระบบรับชำระเงินรูปแบบใหม่ เร็วๆ นี้</h3>
          <br />
          <h4>การทำงานในส่วนอื่น สามารถใช้งานได้ตามปกติ</h4>
          <h4>ขออภัยในความไม่สะดวก</h4>
          <br />
        </div>
      </div>
      <br />
      <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
        <PrimaryButton
          text="หน้าแรก"
          onClick={() => {
            history.push({
              pathname: '/etax',
              state: {},
            });
          }}
        />
      </Stack>
    </div>
  );
};

export default Maintenance;
