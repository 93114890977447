import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../../authConfig';

import { useHistory } from 'react-router-dom';

import { PrimaryButton, getTheme, Separator } from '@fluentui/react';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

//center loading
const centerItem = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

const Complete = () => {
  console.log('Re render');

  const history = useHistory();

  const { orderId } = useParams();

  console.log('Order id: ', orderId);

  const [id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [createdTime, setCreatedTime] = useState('');
  const [description, setDescription] = useState('');
  const [grandTotal, setGrandTotal] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(true);
  const [isLoadComplete, setIsLoadComplete] = useState(false);

  if (orderId && orderId === '') {
    history.push({
      pathname: '/billings',
      state: {},
    });
  }

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    console.log('userEffect retrieve order data!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/orders/' + orderId, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data && response.data.payment !== null) {
                console.log('Order data: ', response.data);
                setId(response.data.orderId);
                setCreatedTime(
                  new Date(response.data.payment.createdTime).toLocaleDateString('th-TH', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    timeZone: 'Asia/Bangkok',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  }) + ' น.'
                );

                if (response.data.payment.status === 'successful') {
                  setPaymentSuccess(true);
                } else {
                  setPaymentSuccess(false);
                }

                setEmail(response.data.email);
                setDescription(response.data.description);
                setGrandTotal(response.data.grandTotal.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }));

                setIsLoadComplete(true);
              } else {
                history.push({
                  pathname: '/billings',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
              setIsLoadComplete(true);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isLoadComplete ? (
        <center>
          <div
            className="ms-Grid"
            dir="ltr"
            style={{
              width: 510,
              height: 736,
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 20,
              paddingBottom: 30,
              background: '#ffffff',
              marginTop: '50px',
              boxShadow: theme.effects.elevation4,
            }}
          >
            <div className="ms-Grid-row">
              {paymentSuccess ? (
                <h2 style={{ margin: '2%', color: '#0078D4' }}>ชำระเงินสำเร็จ</h2>
              ) : (
                <h2 style={{ margin: '2%', color: '#0078D4' }}>ชำระเงินไม่สำเร็จ</h2>
              )}
              {paymentSuccess ? (
                <img height="180" src="/images/success03.gif" alt="success03Gif" />
              ) : (
                <img height="180" src="/images/fail01.gif" alt="fail01Gif" />
              )}
              {paymentSuccess ? (
                <h3 style={{ margin: '2%', color: '#656766' }}>รายละเอียดการชำระเงิน</h3>
              ) : (
                <h3 style={{ margin: '2%', color: '#656766' }}>กรุณาลองใหม่อีกครั้ง</h3>
              )}
            </div>
            <br />
            <Separator />
            <br />

            <div className="ms-Grid-row" style={{ color: '#656766' }}>
              <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}>
                <b>รายละเอียด :</b>
              </div>
              <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>
                &nbsp;&nbsp;{description}
              </div>
            </div>

            <br />
            <div className="ms-Grid-row" style={{ color: '#656766' }}>
              <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}>
                <b>จำนวนเงิน :</b>
              </div>
              <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>
                &nbsp;&nbsp;{grandTotal} บาท
              </div>
            </div>
            <br />
            <div className="ms-Grid-row" style={{ color: '#656766' }}>
              <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}>
                <b>ผู้ใช้งาน :</b>
              </div>
              <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>
                &nbsp;&nbsp;{email}
              </div>
            </div>
            <br />

            <Separator />
            <br />
            <div className="ms-Grid-row" style={{ color: '#656766' }}>
              <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}>
                <b>วันที่ทำรายการ :</b>
              </div>
              <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>
                &nbsp;&nbsp;{createdTime}
              </div>
            </div>
            <br />
            <div className="ms-Grid-row" style={{ color: '#656766' }}>
              <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}>
                <b>เลขที่รายการ :</b>
              </div>
              <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>
                &nbsp;&nbsp;<small>{id}</small>
              </div>
            </div>

            {!paymentSuccess && (
              <div>
                <br />
                <div className="ms-Grid-row" style={{ color: '#656766' }}>
                  <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ textAlign: 'right' }}>
                    <b>สถานะ :</b>
                  </div>
                  <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" style={{ textAlign: 'left' }}>
                    &nbsp;&nbsp;ทำรายการไม่สำเร็จ
                  </div>
                </div>
              </div>
            )}
            <br />
            <Separator />

            <br />
            <PrimaryButton
              onClick={() => {
                history.push({
                  pathname: '/billings',
                  state: {},
                });
              }}
              text={paymentSuccess ? 'รายละเอียด' : 'ลองอีกครั้ง'}
            />
          </div>
        </center>
      ) : (
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div style={centerItem}>
            <img width="60" src="/images/loading.gif" alt="loadingGif" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Complete;
