import React from 'react';

import { useHistory } from 'react-router-dom';

import { Stack, Icon, DefaultButton } from '@fluentui/react';

const CertificateAdd = () => {
  const history = useHistory();

  return (
    <Stack>
      <Stack vertical>
        <h1 style={{ marginLeft: '0px', marginTop: '25px' }}>
          <Icon iconName="Certificate" style={{ color: '#106ebe' }} /> ใบรับรองอิเล็กทรอนิกส์
        </h1>
        <h3 style={{ marginLeft: '0px', marginTop: '0px' }}>
          <Icon iconName="SkypeCheck" style={{ color: '#106ebe' }} /> เลือกวิธีติดตั้งใบรับรองอิเล็กทรอนิกส์
        </h3>
        <br />

        <Stack horizontal horizontalAlign="start">
          <DefaultButton
            style={{ fontSize: '20px', height: '70px', width: '650px', textAlign: 'left' }}
            onClick={() => {
              history.push({
                pathname: '/ca/companies',
                state: {},
              });
            }}
          >
            1. ขอใบรับรองอิเล็กทรอนิกส์ใหม่ จาก INET - CA (ค่าบริการ 3,500 บาท)
          </DefaultButton>
        </Stack>
        <br />
        <br />
        <br />
        <Stack horizontal horizontalAlign="start">
          <DefaultButton
            style={{ fontSize: '20px', height: '50px' }}
            onClick={() => {
              history.push({
                pathname: '/etax/certificates',
                state: {},
              });
            }}
          >
            ย้อนกลับ
          </DefaultButton>
        </Stack>
        <br />
        <br />
      </Stack>
    </Stack>
  );
};

export default CertificateAdd;
