import { getCountryCodeWithoutLanguage } from '../../../providers/CountryData';

export function validateData(row, _contactList) {
  let error = [];

  if (row['taxNumberType'] === '' || row['taxNumberType'] === null || row['taxNumberType'] === undefined) error.push('taxNumberType => ห้ามเว้นว่าง');

  if (row['taxNumberType'].toUpperCase() !== 'TXID' && row['taxNumberType'].toUpperCase() !== 'NIDN' && row['taxNumberType'].toUpperCase() !== 'CCPT')
    error.push('taxNumberType => ไม่ถูกต้อง');

  if (row['taxNumberType'].toUpperCase() === 'TXID') {
    if (row['branchNumber'] === '' || row['branchNumber'] === null || row['branchNumber'] === undefined) error.push('branchNumber => ห้ามเว้นว่าง');
    if (isNaN(row['branchNumber'])) error.push('branchNumber => กรุณาระบุเป็นตัวเลข');
  }

  if (
    row['taxNumberType'].toUpperCase() === 'TXID' ||
    row['taxNumberType'].toUpperCase() === 'NIDN' ||
    row['taxNumberType'].toUpperCase() === 'CCPT'
  ) {
    if (row['taxId'] === '' || row['taxId'] === null || row['taxId'] === undefined) error.push('taxId => ห้ามเว้นว่าง');

    if (row['taxNumberType'].toUpperCase() !== 'CCPT') {
      if (isNaN(row['taxId']) || String(row['taxId']).trim().length !== 13) error.push('taxId => กรุณาใส่เป็นตัวเลข 13 ตัว');
    }
  }

  if (row['taxId'] === '0000000000000' || Number(row['taxId']) === 0) {
    error.push('taxId => ไม่ถูกต้อง');
  }

  if (
    _contactList.some((contact) => {
      if (
        contact.taxId === String(row['taxId']) &&
        Number(contact.branchNumber) === Number(row['branchNumber']) &&
        contact.taxNumberType === 'TXID'
      ) {
        return true;
      } else if (contact.taxId === String(row['taxId']) && (contact.taxNumberType === 'NIDN' || contact.taxNumberType === 'CCPT')) {
        return true;
      } else {
        return false;
      }
    })
  )
    error.push('taxId, branchNumber => ผู้ติดต่อนี้มีอยู่ในระบบแล้ว');

  if (row['name'] === '' || row['name'] === null || row['name'] === undefined) error.push('name => ห้ามเว้นว่าง');

  if (row['addressLineOne'] === '' || row['addressLineOne'] === null || row['addressLineOne'] === undefined)
    error.push('addressLineOne => ห้ามเว้นว่าง');

  if (row['countryName'] && row['countryName'] !== 'ไทย' && row['countryName'] !== 'Thailand') {
    if (getCountryCodeWithoutLanguage(row['countryName']).key === undefined) error.push('countryName => ไม่ถูกต้อง');
    if (String(row['postcode']).trim().length < 5) error.push('postcode => กรุณาระบุขั้นต่ำ 5 ตัว');
  }

  if (!row['countryName'] || row['countryName'] === 'ไทย' || row['countryName'] === 'Thailand') {
    if (!String(row['postcode'])) error.push('postcode => ห้ามเว้นว่าง');
    if (isNaN(String(row['postcode'])) || String(row['postcode']).trim().length !== 5) error.push('postcode => กรุณาใส่เป็นตัวเลข 5 ตัว เช่น 10500');
  }

  if (row['phone'] !== '' && row['phone'] !== null && row['phone'] !== undefined) {
    if (isNaN(String(row['phone'])) || /(^0[1-9][0-9]{1,13}$|^$)/g.test(String(row['phone'])) !== true) {
      error.push('phone => ไม่ถูกต้อง');
    }
  }

  return error;
}

export function validateDataEN(row, _contactList) {
  let error = [];

  if (row['taxNumberType'] === '' || row['taxNumberType'] === null || row['taxNumberType'] === undefined)
    error.push('taxNumberType => Must not be empty');

  if (row['taxNumberType'].toUpperCase() !== 'TXID' && row['taxNumberType'].toUpperCase() !== 'NIDN' && row['taxNumberType'].toUpperCase() !== 'CCPT')
    error.push('taxNumberType => The value is invalid');

  if (row['taxNumberType'].toUpperCase() === 'TXID') {
    if (row['branchNumber'] === '' || row['branchNumber'] === null || row['branchNumber'] === undefined)
      error.push('branchNumber => Must not be empty');
    if (isNaN(row['branchNumber'])) error.push('branchNumber => The value must be number only');
  }

  if (
    row['taxNumberType'].toUpperCase() === 'TXID' ||
    row['taxNumberType'].toUpperCase() === 'NIDN' ||
    row['taxNumberType'].toUpperCase() === 'CCPT'
  ) {
    if (row['taxId'] === '' || row['taxId'] === null || row['taxId'] === undefined) error.push('taxId => Must not be empty');

    if (row['taxNumberType'].toUpperCase() !== 'CCPT') {
      if (isNaN(row['taxId']) || String(row['taxId']).trim().length !== 13) error.push('taxId => Tax ID must contain 13 digit numbers');
    }
  }

  if (row['taxId'] === '0000000000000' || Number(row['taxId']) === 0) {
    error.push('taxId => The value is invalid');
  }

  if (
    _contactList.some((contact) => {
      if (
        contact.taxId === String(row['taxId']) &&
        Number(contact.branchNumber) === Number(row['branchNumber']) &&
        contact.taxNumberType === 'TXID'
      ) {
        return true;
      } else if (contact.taxId === String(row['taxId']) && (contact.taxNumberType === 'NIDN' || contact.taxNumberType === 'CCPT')) {
        return true;
      } else {
        return false;
      }
    })
  )
    error.push('taxId, branchNumber => This contact is already added');

  if (row['name'] === '' || row['name'] === null || row['name'] === undefined) error.push('name => Must not be empty');

  if (row['addressLineOne'] === '' || row['addressLineOne'] === null || row['addressLineOne'] === undefined)
    error.push('addressLineOne => Must not be empty');

  if (row['countryName'] && row['countryName'] !== 'ไทย' && row['countryName'] !== 'Thailand') {
    if (getCountryCodeWithoutLanguage(row['countryName']).key === undefined) error.push('countryName => The value is invalid');
    if (String(row['postcode']).trim().length < 5) error.push('postcode => Postcode must contain minimum 5 characters');
  }

  if (!row['countryName'] || row['countryName'] === 'ไทย' || row['countryName'] === 'Thailand') {
    if (!String(row['postcode'])) error.push('postcode => Must not be empty');
    if (isNaN(String(row['postcode'])) || String(row['postcode']).trim().length !== 5)
      error.push('postcode => Postcode must contain 5 digit numbers');
  }

  if (row['phone'] !== '' && row['phone'] !== null && row['phone'] !== undefined) {
    if (isNaN(String(row['phone'])) || /(^0[1-9][0-9]{1,13}$|^$)/g.test(String(row['phone'])) !== true) {
      error.push('phone => The value is invalid');
    }
  }

  return error;
}
