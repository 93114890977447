export const categoryList = [
  {
    categoryCode: "C01",
    categoryCode2: "L10",
    categoryNameTh: "กล้อง และอุปกรณ์",
    categoryNameEn: "Camera & Accessories",
  },
  {
    categoryCode: "C02",
    categoryCode2: "L10",
    categoryNameTh: "ของที่ระลึก",
    categoryNameEn: "Souvenir",
  },
  {
    categoryCode: "C03",
    categoryCode2: "L10",
    categoryNameTh: "คอมพิวเตอร์และอุปกรณ์",
    categoryNameEn: "Computer & Peripheral Equipment",
  },
  {
    categoryCode: "C04",
    categoryNameTh: "เครื่องเขียน&เครื่องใช้สำนักงาน",
    categoryNameEn: "Stationery & Office Equipment",
  },
  {
    categoryCode: "C05",
    categoryNameTh: "เครื่องเรือนและของใช้ภายในบ้าน",
    categoryNameEn: "Household Appliances",
  },
  {
    categoryCode: "C06",
    categoryNameTh: "เครื่องสำอาง",
    categoryNameEn: "Cosmetic & Perfumery Articles",
  },
  {
    categoryCode: "C07",
    categoryCode2: "L10",
    categoryNameTh: "เครื่องหนัง",
    categoryNameEn: "Leather Products",
  },
  {
    categoryCode: "C08",
    categoryNameTh: "กระเป๋าเดินทาง",
    categoryNameEn: "Luggage",
  },
  {
    categoryCode: "C09",
    categoryCode2: "L10",
    categoryNameTh: "ผลิตภัณฑ์ผ้าไหม",
    categoryNameEn: "Textile and Thai silk",
  },
  {
    categoryCode: "C10",
    categoryNameTh: "ผลิตภัณฑ์เพื่อสุขภาพ",
    categoryNameEn: "Food and Beverage Products",
  },
  {
    categoryCode: "C11",
    categoryNameTh: "ผลิตภัณฑ์อิเล็กทรอนิกส์",
    categoryNameEn: "Electrical Appliances",
  },
  {
    categoryCode: "C12",
    categoryCode2: "L10",
    categoryNameTh: "เครื่องแต่งกาย",
    categoryNameEn: "Apparel and Accessories",
  },
  {
    categoryCode: "C13",
    categoryNameTh: "อุปกรณ์กีฬาและเครื่องออกกำลัง",
    categoryNameEn: "Sporting & Fitness Equipment",
  },
  {
    categoryCode: "C14",
    categoryNameTh: "ยา และเวชภัณฑ์",
    categoryNameEn: "Pharmaceutical, Medical and Orthopaedic Goods",
  },
  {
    categoryCode: "C15",
    categoryCode2: "L07",
    categoryNameTh: "เพชร พลอย อัญมณีต่างๆ",
    categoryNameEn: "Gems & Jewellery",
  },
  {
    categoryCode: "C16",
    categoryCode2: "L08",
    categoryNameTh: "เครื่องประดับทองคำ",
    categoryNameEn: "Gold Ornament Type B",
  },
  {
    categoryCode: "C17",
    categoryCode2: "L05",
    categoryNameTh: "นาฬิกา รวมถึง Apple Watch และ Smart Watch",
    categoryNameEn: "Watches and Clocks Type B",
  },
  {
    categoryCode: "C18",
    categoryCode2: "L06",
    categoryNameTh: "แว่นตา",
    categoryNameEn: "Optical Wear Type B",
  },
  {
    categoryCode: "C19",
    categoryCode2: "L02",
    categoryNameTh: "ปากกา",
    categoryNameEn: "Pen type B",
  },
  {
    categoryCode: "C20",
    categoryCode2: "L09",
    categoryNameTh: "อุปกรณ์สื่อสาร",
    categoryNameEn: "Communication Equipment & Accessories",
  },
  {
    categoryCode: "C21",
    categoryCode2: "L01",
    categoryNameTh: "คอมพิวเตอร์แบบพกพา รวมถึง iPad และ Tablet",
    categoryNameEn: "Laptop & Tablet type S",
  },
  {
    categoryCode: "C22",
    categoryCode2: "L04",
    categoryNameTh: "กระเป๋าถือ",
    categoryNameEn: "Hand Bag Type S",
  },
  {
    categoryCode: "C23",
    categoryCode2: "L03",
    categoryNameTh: "เข็มขัด",
    categoryNameEn: "Belt  Type S",
  },
  {
    categoryCode: "C24",
    categoryNameTh: "ผลิตภัณฑ์อาหารและอุปโภค",
    categoryNameEn: "Consumer Products",
  },
  {
    categoryCode: "N01",
    categoryNameTh: "ค่าบริการ คืนภาษีไม่ได้",
    categoryNameEn: "No Refund",
  },
  {
    categoryCode: "N02",
    categoryNameTh: "สินค้าไม่มี VAT ไม่คืนภาษี",
    categoryNameEn: "No VAT",
  },
  {
    categoryCode: "O01",
    categoryCode2: "L10",
    categoryNameTh: "สินค้าเบ็ดเตล็ด",
    categoryNameEn: "Miscellaneous",
  },
]