import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../authConfig';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  SelectionMode,
  CommandBar,
  Stack,
  PrimaryButton,
  DefaultButton,
  MessageBar,
  MessageBarType,
  ContextualMenu,
  ContextualMenuItemType,
  SearchBox,
  Text,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const PersonList = (props) => {
  console.log('Home render!!!');

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const data = location.state;

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewContacts, setGetNewContacts] = useState(true);
  const [pageData, setPageData] = useState({});
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  //CommandBars Items
  const contactType = {
    key: 'ALL',
    text: 'ทั้งหมด',
  };

  const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);

  const [search, setSearch] = useState('');
  const [filterDocuments, setFilterDocuments] = useState([]);

  const companyId = sessionStorage.getItem('companyId') || '';

  const commandBarItems = [
    {
      key: 'upload',
      text: t('person.upload'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Upload' },
      onClick: () => {
        history.push({
          pathname: '/contacts/excel/upload',
          state: {},
        });
      },
    },
    {
      key: 'refresh',
      text: t('person.refresh'),
      iconProps: { iconName: 'Refresh' },
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewContacts(true);
        setSearch('');
      },
    },
  ];

  useEffect(() => {
    if (data != null && data.addComplete) {
      setShowMessageBarSuccess(true);
    }
  }, [data]);

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        // Do something with the tokenResponse
        axios
          .get(API_URL + '/contacts', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'application/json',
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log(response);

              let contacts = response.data;

              if (contactType.key === 'buyer') {
                contacts = contacts.filter((contact) => contact.buyer);
              } else if (contactType.key === 'seller') {
                contacts = contacts.filter((contact) => contact.seller);
              }

              contacts.reverse();

              //set page
              setPageData(paginate(contacts.length, currentPage, pageSize, 10));

              setDocuments(contacts);

              const _docs = [];

              if (contacts.length > 0) {
                for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, contacts.length); i++) {
                  let _address = '';

                  if (contacts[i].addressLineOne) {
                    _address = contacts[i].addressLineOne;

                    if (contacts[i].addressLineTwo) {
                      _address = _address + ' ' + contacts[i].addressLineTwo;
                    }
                    _address = _address + ' ' + contacts[i].postcode;

                    if (contacts[i].countryCode !== 'TH') {
                      _address = _address + ' ' + contacts[i].countryName;
                    }
                  }

                  if (contacts[i].buildingNumber) {
                    _address =
                      contacts[i].buildingNumber +
                      ' ' +
                      contacts[i].address +
                      ' ' +
                      contacts[i].streetPrefix +
                      contacts[i].streetName +
                      ' ' +
                      contacts[i].subDistrictPrefix +
                      contacts[i].subDistrictName +
                      ' ' +
                      contacts[i].districtPrefix +
                      contacts[i].districtName +
                      ' ' +
                      contacts[i].provincePrefix +
                      contacts[i].provinceName +
                      ' ' +
                      contacts[i].postcode;
                  }

                  _docs.push({
                    key: contacts[i].id,
                    id: contacts[i].id,
                    name: contacts[i].name,
                    taxId: contacts[i].taxId,
                    address: _address,
                    contactName: contacts[i].contactName,
                    phone: contacts[i].phone,
                  });
                }
              }

              console.log('Docs data', _docs);
              setItems(_docs);

              setCurrentPage(1);
              setLoadDataComplete(true);
              setGetNewContacts(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewContacts]);

  useEffect(() => {
    if (search) {
      if (filterDocuments.length > 0) {
        const _docs = [];

        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, filterDocuments.length); i++) {
          let _address = '';

          if (filterDocuments[i].addressLineOne) {
            _address = filterDocuments[i].addressLineOne;

            if (filterDocuments[i].addressLineTwo) {
              _address = _address + ' ' + filterDocuments[i].addressLineTwo;
            }
            _address = _address + ' ' + filterDocuments[i].postcode;

            if (filterDocuments[i].countryCode !== 'TH') {
              _address = _address + ' ' + filterDocuments[i].countryName;
            }
          }

          if (filterDocuments[i].buildingNumber) {
            _address =
              filterDocuments[i].buildingNumber +
              ' ' +
              filterDocuments[i].address +
              ' ' +
              filterDocuments[i].streetPrefix +
              filterDocuments[i].streetName +
              ' ' +
              filterDocuments[i].subDistrictPrefix +
              filterDocuments[i].subDistrictName +
              ' ' +
              filterDocuments[i].districtPrefix +
              filterDocuments[i].districtName +
              ' ' +
              filterDocuments[i].provincePrefix +
              filterDocuments[i].provinceName +
              ' ' +
              filterDocuments[i].postcode;
          }

          _docs.push({
            key: filterDocuments[i].id,
            id: filterDocuments[i].id,
            name: filterDocuments[i].name,
            taxId: filterDocuments[i].taxId,
            address: _address,
            contactName: filterDocuments[i].contactName,
            phone: filterDocuments[i].phone,
          });
        }
        console.log('Docs data', _docs);

        setItems(_docs);
      }
    } else {
      if (documents.length > 0) {
        const _docs = [];

        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
          let _address = '';

          if (documents[i].addressLineOne) {
            _address = documents[i].addressLineOne;

            if (documents[i].addressLineTwo) {
              _address = _address + ' ' + documents[i].addressLineTwo;
            }
            _address = _address + ' ' + documents[i].postcode;

            if (documents[i].countryCode !== 'TH') {
              _address = _address + ' ' + documents[i].countryName;
            }
          }

          if (documents[i].buildingNumber) {
            _address =
              documents[i].buildingNumber +
              ' ' +
              documents[i].address +
              ' ' +
              documents[i].streetPrefix +
              documents[i].streetName +
              ' ' +
              documents[i].subDistrictPrefix +
              documents[i].subDistrictName +
              ' ' +
              documents[i].districtPrefix +
              documents[i].districtName +
              ' ' +
              documents[i].provincePrefix +
              documents[i].provinceName +
              ' ' +
              documents[i].postcode;
          }

          _docs.push({
            key: documents[i].id,
            id: documents[i].id,
            name: documents[i].name,
            taxId: documents[i].taxId,
            address: _address,
            contactName: documents[i].contactName,
            phone: documents[i].phone,
          });
        }
        console.log('Docs data', _docs);

        setItems(_docs);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    let totalDocs = documents;

    let filterDocs = totalDocs.filter(
      (doc) => doc.name.includes(search) || doc.taxId.includes(search) || doc.contactName.includes(search) || doc.phone.includes(search)
    );

    setPageData(paginate(filterDocs.length, currentPage, pageSize, 10));
    setFilterDocuments(filterDocs);
    setCurrentPage(1);

    if (filterDocs.length > 0) {
      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, filterDocs.length); i++) {
        let _address = '';

        if (filterDocs[i].addressLineOne) {
          _address = filterDocs[i].addressLineOne;

          if (filterDocs[i].addressLineTwo) {
            _address = _address + ' ' + filterDocs[i].addressLineTwo;
          }
          _address = _address + ' ' + filterDocs[i].postcode;

          if (filterDocs[i].countryCode !== 'TH') {
            _address = _address + ' ' + filterDocs[i].countryName;
          }
        }

        if (filterDocs[i].buildingNumber) {
          _address =
            filterDocs[i].buildingNumber +
            ' ' +
            filterDocs[i].address +
            ' ' +
            filterDocs[i].streetPrefix +
            filterDocs[i].streetName +
            ' ' +
            filterDocs[i].subDistrictPrefix +
            filterDocs[i].subDistrictName +
            ' ' +
            filterDocs[i].districtPrefix +
            filterDocs[i].districtName +
            ' ' +
            filterDocs[i].provincePrefix +
            filterDocs[i].provinceName +
            ' ' +
            filterDocs[i].postcode;
        }

        _docs.push({
          key: filterDocs[i].id,
          id: filterDocs[i].id,
          name: filterDocs[i].name,
          taxId: filterDocs[i].taxId,
          address: _address,
          contactName: filterDocs[i].contactName,
          phone: filterDocs[i].phone,
        });
      }
      setItems(_docs);
    } else {
      setItems([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const deleteContact = (event, item) => {
    console.log('item ', item);

    let result = window.confirm(`คุณต้องการที่จะลบผู้ติดต่อ ${item.name} ใช่ไหม?`);

    if (result) {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .delete(API_URL + '/contacts/' + item.id, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            })
            .then(
              (response) => {
                console.log(response);
                setLoadDataComplete(false);
                setGetNewContacts(true);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          // Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const createDocumentfromContact = async (documentType, item) => {
    console.log(item);

    let documentPath = '/etax/documents/receipts';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoice-abb';
        break;
      case 'RECEIPT':
        documentPath = '/etax/documents/receipts';
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices';
        break;
      case 'DEBIT-NOTE':
        documentPath = '/etax/documents/debit-notes';
        break;
      case 'CREDIT-NOTE':
        documentPath = '/etax/documents/credit-notes';
        break;
      case 'QUOTATION':
        documentPath = '/documents/quotations';
        break;
      case 'INVOICE':
        documentPath = '/documents/invoices';
        break;
      case 'BILLING-NOTE':
        documentPath = '/documents/billing-notes';
        break;
      case 'DELIVERYORDER':
        documentPath = '/documents/deliveryorders';
        break;
      case 'EXPENSE-NOTE':
        documentPath = '/expenses/expense-notes';
        break;
      default:
        break;
    }

    let document = documents.filter((doc) => item.id === doc.id);

    if (document[0].addressLineOne) {
      document[0].unstructure = true;
    }

    if (document[0].buildingNumber) {
      document[0].unstructure = false;
    }

    history.push({
      pathname: documentPath,
      state: {
        command: 'Contact',
        customer: document[0],
        contactSuggestion: document[0],
        backUrl: '/contacts',
      },
    });
  };

  const reCreateWithholdingTax = async (item) => {
    console.log(item);

    let documentPath = '/documents/withholding-taxs';

    let document = documents.filter((doc) => item.id === doc.id);

    history.push({
      pathname: documentPath,
      state: {
        command: 'Contact',
        customer: document[0],
        backUrl: '/contacts',
      },
    });
  };

  const columns = [
    {
      key: 'column1',
      name: t('person.name'),
      fieldName: 'name',
      minWidth: 70,
      maxWidth: 165,
      isResizable: true,
      isSorted: true,
      isSortedDescending: true,
      onColumnClick: '',
      data: 'number',
      onRender: (item) => {
        return <span>{item.name}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('person.taxId'),
      fieldName: 'taxId',
      minWidth: 120,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.taxId}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('person.address'),
      fieldName: 'address',
      minWidth: 320,
      maxWidth: 340,
      isResizable: true,
      onColumnClick: '',
      data: 'number',
      onRender: (item) => {
        return <span>{item.address}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('person.contactName'),
      fieldName: 'contactName',
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.contactName}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('person.phone'),
      fieldName: 'phone',
      minWidth: 80,
      maxWidth: 150,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.phone}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column9',
      name: '',
      fieldName: 'command',
      minWidth: 90,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        let options = [
          {
            key: 'edit',
            text: t('person.edit'),
            iconProps: { iconName: 'Edit' },
            onClick: () => {
              history.push({
                pathname: '/contacts/add',
                state: { id: item.id },
              });
            },
          },
          {
            key: 'delete',
            text: t('person.delete'),
            iconProps: { iconName: 'Delete' },
            onClick: (event) => {
              deleteContact(event, item);
            },
          },
          {
            key: 'divider',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'RECEIPT-TAXINVOICE',
            text: t('person.receipt-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              createDocumentfromContact('RECEIPT-TAXINVOICE', item);
            },
          },
          {
            key: 'RECEIPT',
            text: t('person.receipt'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              createDocumentfromContact('RECEIPT', item);
            },
          },
          {
            key: 'INVOICE-TAXINVOICE',
            text: t('person.invoice-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              createDocumentfromContact('INVOICE-TAXINVOICE', item);
            },
          },
          {
            key: 'DELIVERYORDER-TAXINVOICE',
            text: t('person.deliveryorder-taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              createDocumentfromContact('DELIVERYORDER-TAXINVOICE', item);
            },
          },
          {
            key: 'TAXINVOICE',
            text: t('person.taxinvoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              createDocumentfromContact('TAXINVOICE', item);
            },
          },
          {
            key: 'DEBIT-NOTE',
            text: t('person.debit-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              createDocumentfromContact('DEBIT-NOTE', item);
            },
          },
          {
            key: 'CREDIT-NOTE',
            text: t('person.credit-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              createDocumentfromContact('CREDIT-NOTE', item);
            },
          },
          {
            key: 'divider_2',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'EXPENSE-NOTE',
            text: t('person.expense-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              createDocumentfromContact('EXPENSE-NOTE', item);
            },
          },
          {
            key: 'divider_3',
            itemType: ContextualMenuItemType.Divider,
          },
          {
            key: 'QUOTATION',
            text: t('person.quotation'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              createDocumentfromContact('QUOTATION', item);
            },
          },
          {
            key: 'INVOICE',
            text: t('person.invoice'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              createDocumentfromContact('INVOICE', item);
            },
          },
          {
            key: 'BILLING-NOTE',
            text: t('person.billing-note'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              createDocumentfromContact('BILLING-NOTE', item);
            },
          },
          {
            key: 'DELIVERYORDER',
            text: t('person.deliveryorder'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              createDocumentfromContact('DELIVERYORDER', item);
            },
          },
          {
            key: 'WITHHOLDING-TAX',
            text: t('person.withholding-tax'),
            iconProps: { iconName: 'PageAdd' },
            onClick: () => {
              reCreateWithholdingTax(item);
            },
          },
        ];

        return (
          <DefaultButton
            text={t('person.select')}
            menuProps={{
              items: options,
              shouldFocusOnMount: true,
              directionalHintFixed: true,
            }}
            // Optional callback to customize menu rendering
            menuAs={(props) => <ContextualMenu {...props} />}
          />
        );
      },
    },
  ];

  const isCompactMode = false;

  return (
    <div style={homeStyles}>
      {showMessageBarSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSuccess(false)}
          dismissButtonAriaLabel={t('person.close')}
          style={{
            fontSize: 14,
          }}
        >
          {t('person.updateSuccess')}
        </MessageBar>
      )}

      <Stack>
        <center>
          <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('person.header')}</h2>
        </center>
        <Stack>
          <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
            <div>
              <br />
              <PrimaryButton
                iconProps={{ iconName: 'Add' }}
                text={t('person.addContact')}
                onClick={() => {
                  history.push('/persons/add');
                }}
              />
            </div>
          </Stack>
        </Stack>
      </Stack>
      <br />
      <Stack horizontal horizontalAlign="center">
        <Stack
          vertical
          style={{
            width: '98%',
            maxWidth: '1350px',
          }}
        >
          <Stack 
            horizontal 
            styles={{
              root: {
                backgroundColor: 'white',
              },
            }}
            horizontalAlign="space-between"
          >
            <CommandBar items={commandBarItems} styles={{ root: { width: 200 } }} />
            <div style={{ height: 44, paddingLeft: 6 }}>
              <SearchBox
                className="ms-borderColor-themePrimary"
                styles={{
                  root: { margin: 6, width: 350, fontSize: 13, fontWeight: 0 },
                }}
                placeholder={t('person.searchPlaceholder')}
                onChange={(e) => setSearch(e ? e.target.value : '')}
                value={search}
              />
            </div>
          </Stack>
          {items.length ? (
            <ShimmeredDetailsList
              items={items || []}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
            />
          ) : (
            <ShimmeredDetailsList
              items={[{}]}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderRow={() => {
                return (
                  <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: '#FFFFFF', height: 55 } }}>
                    <Text>{t('documents.noItemFound')}</Text>
                  </Stack>
                );
              }}
            />
          )}
        </Stack>
      </Stack>
      <br />
      <center>
        {!(documents.length === 0 || documents.length < pageSize) && (
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={pageData.totalPages}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
          />
        )}
        <br />
        <br />
      </center>
    </div>
  );
};

export default PersonList;
