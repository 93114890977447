import { Spinner, SpinnerSize, Stack, TextField, getTheme, mergeStyleSets } from "@fluentui/react";
import { useEffect } from "react";
import { useState } from "react";
import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from "../../authConfig"; 
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const controlClass = mergeStyleSets({
    control: {
      margin: '0 0 15px 0',
      maxWidth: '300px',
    },
    hover: {
      selectors: {
        '&:hover': { background: theme.palette.neutralLight },
      },
    },
  });

  const contactListStyle = {
    background: 'white',
    border: '1px solid #eee',
    boxShadow: '0 2px 3px #ccc',
    boxSizing: 'border-box',
    maxHeight: 200,
    width: 325,
    position: 'absolute',
    overflow: 'auto',
    zIndex: 100000000,
  };
  
  const contactOptionStyle = {
    padding: '0px 8px',
    paddingTop: '8px',
    height: 26,
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  };

export default function TextFieldWithDropDown(props) {
    const { name, label, componentRef, autoComplete, styles, maxLength, 
            value, onGetErrorMessage, validateOnLoad, onChangeFunction, 
            index, description, items, setState} = props

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [showSuggestions, setShowSuggestions] = useState(false)
    const [dropdownList, setDropdownList] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    const companyId = sessionStorage.getItem('companyId') || '';

    useEffect(() => {
        // let suggestions = list.filter((item) => item.data.name.includes(value) || item.data.skus.some(_variant => _variant.sellerSku?.includes(value)));

        // console.log("dropdownList", dropdownList)
        // setDropdownList(suggestions);

        // if (!value && showSku) {
        //   setShowSku(false)
        // }
      // const source = axios.CancelToken.source()

      async function getProducts(value) {
        console.log("productName or SKU", value)

        setIsLoading(true)

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
                .get(
                  `${API_URL}/products?search=${value}`, 
                  {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                      'Content-Type': 'application/json',
                      'cid': companyId
                    },
                    // cancelToken: source.token
                  }
                ).then(response => {
                  console.log("response", response)

                  const { products } = response.data.data

                  const activeProducts = products.filter(_product => _product.data.status === "active")

                  setDropdownList(activeProducts);
                  setIsLoading(false)
                })
          }).catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });
      }

      if (value) {
        getProducts(value)
      }

      // return () => source.cancel()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    function handleSelect(e, product, variantIndex, index) {
      const {
        name,
        skus,
      } = product.data
  
      const {
        sellerSku,
        variation,
        price,
        unitCode,
        unitName
      } = skus[variantIndex]
  
      const values = [...items];
  
      values[index].number = index + 1 + ""
      values[index].description = name + " " + (variation[0] ? "(" + variation[0].value : "") + (variation[1] ? ", " + variation[1].value + ")" : (variation[0] ? ")" : ""))
      values[index].quantity = 1
      values[index].unitCode = unitCode
      values[index].unitName = unitName
      values[index].price = Number(price)
      values[index].total = Number(price)
      values[index].sku = sellerSku
  
      setState(values);
    }

    return <div>
    <TextField
      name={name}
      label={label}
      componentRef={componentRef}
      autoComplete={autoComplete}
      styles={styles}
      maxLength={maxLength}
    //   onChange={(e) => {
    //     onChangeState(e.target.value);
    //     if (e.target.value) {
    //       setShowSuggestions(true);
    //     } else {
    //       setShowSuggestions(false);
    //     }
    //   }}
      onChange={(event) => {
        onChangeFunction(index, event)
        if (event.target.value) {
          setShowSuggestions(true);
        } else {
          setShowSuggestions(false);
        }
      }}
      value={value}
      description={description ? description : ''}
      onGetErrorMessage={onGetErrorMessage}
      validateOnLoad={validateOnLoad}
      validateOnFocusOut
      required
      // autoFocus
      onBlur={() => setShowSuggestions(false)}
    />
    {showSuggestions && dropdownList[0] !== undefined && (
      <div style={contactListStyle}>
        {isLoading ? (
          <Stack verticalAlign="center" styles={{ root: { height: 30.4 }}}>
            <Spinner size={SpinnerSize.medium} />
          </Stack>
        ) : (
          dropdownList.map((_product, indexList) => {
            return _product.data.skus.map((_variant, indexVariant) => (
              (!items.some(_item => _item.sku && _item.sku === _variant.sellerSku) && _variant.status === "active") ? 
                <div
                    key={_product.id + "" + indexVariant}
                    id={_product.id + "" + indexVariant}
                    className={controlClass.hover}
                    style={contactOptionStyle}
                    onMouseDown={(e) => {
                        handleSelect(e, _product, indexVariant, index)
                        setShowSuggestions(false)
                    }}
                >
                    {_product.data.name} {(_variant.variation[0] ? "(" + _variant.variation[0].value : "") + (_variant.variation[1] ? ", " + _variant.variation[1].value + ")" : (_variant.variation[0] ? ")" : ""))} {_variant.sellerSku}
                </div>
               : null
            ))
          })

        )}
      </div>
    )}
  </div>
}