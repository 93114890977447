import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Persona,
  PersonaInitialsColor,
  PersonaSize,
  createTheme,
  Customizer,
  CommandButton,
  Icon,
  Stack,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  DefaultButton,
  ContextualMenuItemType,
} from '@fluentui/react';

import { useHistory } from 'react-router-dom';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../authConfig';
import { profileEditingRequest, passwordResetRequest } from '../authConfig';

import { ActionButton } from '@fluentui/react/lib/Button';

import * as signalR from '@microsoft/signalr';

const API_URL = process.env.REACT_APP_API_URL;

const headerStyles = {
  height: 70,
  width: '100%',
  background: '#0078D4',
  border: 0,
  paddingLeft: '12px',
  paddingTop: '15px',
  margin: 0,
  color: '#FFFFFF',
  fontSize: 26,
};

// For color persona text to white
const HeaderTheme = createTheme({
  palette: {
    themePrimary: '#0078d4',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#0b0b0b',
    neutralLighter: '#151515',
    neutralLight: '#252525',
    neutralQuaternaryAlt: '#2f2f2f',
    neutralQuaternary: '#373737',
    neutralTertiaryAlt: '#595959',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#000000',
    primaryBackground: '#000000',
    primaryText: '#ffffff',
    bodyBackground: '#000000',
    bodyText: '#ffffff',
    disabledBackground: '#151515',
    disabledText: '#595959',
  },
});

const Header = (props) => {
  const history = useHistory();

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [companyName, setCompanyName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [coins, setCoins] = useState(0);
  const [sms, setSms] = useState(0);
  const [billingsType, setBillingsType] = useState('prepaid');
  const billingsTypeRef = useRef();

  billingsTypeRef.current = billingsType;

  const [command, setCommand] = useState('');

  const [enableSms, setEnableSms] = useState(false);

  const { accountData } = props;
  const { companyData } = props;
  const { companyDetailData } = props;

  const { t, i18n } = useTranslation();

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const [getNewCompany, setGetNewCompany] = useState(true)

  const [companyList, setCompanyList] = useState([])

  const companyId = sessionStorage.getItem('companyId') || '';

  const menuProps = {
    items: [
      {
        name: t("header.profile"),
        url: '',
        key: 'user-profiles',
        iconProps: {
          iconName: 'PlayerSettings',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            },
          },
        },
        onClick: (event, element) => {
          console.log(element.key);
          setCommand(element.key);
        },
      },
      {
        name: t("header.resetPassword"),
        url: '',
        key: 'password-reset',
        iconProps: {
          iconName: 'Permissions',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            },
          },
        },
        onClick: (event, element) => {
          console.log(element.key);
          setCommand(element.key);
        },
      },

      {
        name: t("header.logout"),
        url: '',
        key: 'logout',
        iconProps: {
          iconName: 'Leave',
          styles: {
            root: {
              fontSize: 20,
              color: '#106ebe',
            },
          },
        },
        onClick: (event, element) => {
          console.log(element.key);
          setCommand(element.key);
        },
      },
    ],
    // By default, the menu will be focused when it opens. Uncomment the next line to prevent this.
    // shouldFocusOnMount: false
  };

  useEffect(() => {
    async function fetchCompanyData() {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/etax/companies', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            })
            .then(
              (response) => {
                console.log("response fetch company", response);

                setCompanyList(response.data)
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    fetchCompanyData();
  }, [])
  
  console.log("companyData", companyData)

  useEffect(() => {
    if (command === 'logout') {
      setDialogConfirm((prev) => ({
        ...prev,
        isClick: true,
        header: t('documents.logoutConfirmHeader'),
        message: `${t('documents.logoutConfirm')}`,
        onConfirm: () => instance.logout(),
        onCancel: () => {
          setCommand('');
          setDialogConfirm((prev) => ({
            ...prev,
            isClick: false,
          }));
        },
      }));
    } else if (command === 'user-profiles') {
      instance.loginRedirect(profileEditingRequest);
    } else if (command === 'password-reset') {
      instance.loginRedirect(passwordResetRequest);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [command]);

  useEffect(() => {
    if (!getNewCompany) return;

    console.log('userEffect Call!');
    console.log('companyDetailData header:', companyDetailData);

    if (accountData && accountData.profiles.name) {
      setName(accountData.profiles.name);
      setEmail(accountData.profiles.emails[0]);
    }

    if (companyDetailData && companyDetailData?.balances?.sms?.credits) {
      setSms(companyDetailData.balances.sms.credits);
    } else if (accountData && accountData?.balances?.sms?.credits) {
      setSms(accountData.balances.sms.credits);
    }

    if (companyDetailData && companyDetailData?.settings?.enableSms) {
        setEnableSms(companyDetailData.settings?.enableSms);
    } else if (accountData && accountData?.settings?.enableSms) {
        setEnableSms(accountData.settings?.enableSms);
    }

    if (companyData && companyData.name) {
      setCompanyName(companyData.name);
    } else {
      setCompanyName('กรุณาตั้งค่าข้อมูลบริษัท...');
    }

    if (companyDetailData && companyDetailData?.billings && companyDetailData?.billings?.type) {


        if (companyDetailData && companyDetailData?.billings && companyDetailData?.billings?.type === 'subscription') {
            setBillingsType('subscription');
    
            let _subscriptionDocuments = companyDetailData.subscriptions.documents;

            let _credits = _subscriptionDocuments.limit + _subscriptionDocuments.topup - _subscriptionDocuments.spent;

            setCoins(_credits);
            
        } else {

            if (billingsType === 'prepaid') {
                if (companyDetailData && companyDetailData?.balances?.documents?.credits) {
                    setCoins(companyDetailData?.balances?.documents?.credits);
                }
            }

        } 

    } else {
    
    
        if (accountData && accountData?.billings && accountData?.billings?.type === 'subscription') {
            setBillingsType('subscription');

            instance
                .acquireTokenSilent({ ...silentRequest, account: account })
                .then((tokenResponse) => {
                    //Check documents spending limit
                    axios
                        .get(API_URL + '/subscriptions', {
                        headers: {
                            Authorization: 'Bearer ' + tokenResponse.accessToken,
                        },
                        })
                        .then(
                        (response) => {
                            console.log('Subscriptions data: ', response);

                            if (response.data) {
                            if (response.data && response.data.documents) {
                                console.log('Subscription etax documents: ', response.data.documents);

                                let _subscriptionDocuments = response.data.documents;

                                let _credits = _subscriptionDocuments.limit + _subscriptionDocuments.topup - _subscriptionDocuments.spent;

                                setCoins(_credits);
                            }
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                        );
                })
                .catch((error) => {
                    //Acquire token silent failure, and send an interactive request
                    console.log(error);
                    instance.acquireTokenRedirect(silentRequest);
                });
        } else {

            if (billingsType === 'prepaid') {
                if (accountData && accountData?.balances?.documents?.credits) {
                    setCoins(accountData?.balances?.documents?.credits);
                }
            }

        } 

    }

    if (companyDetailData && companyDetailData.id) {

        const companiesDetailsConnection = new signalR.HubConnectionBuilder()
          .withUrl('https://leceipt.azurewebsites.net/api/OnCompaniesDetailsChanged', {
        // .withUrl(`http://localhost:7071/api/OnCompaniesDetailsChanged?userId=${accountData.oid}`, {
            headers: {
                // oid: accountData.oid,
                cid: companyDetailData.id,
            },
        })
        .configureLogging(signalR.LogLevel.Information)
        .build();

        companiesDetailsConnection.on('companiesDetailsUpdated', function (data) {
            console.log('SignalR push companiesDetailsUpdated!!');
            console.log(data);
            console.log('Billings type: ', billingsTypeRef.current);
        
            if (data && data.billings && data.billings.type && data.billings.type === 'subscription') {
                console.log('Billings type data: ', data.billings.type);
                setBillingsType('subscription');
                setSms(data.balances.sms.credits);
            } else {
                console.log('Set billings type to prepaid');
                setBillingsType('prepaid');
                setSms(data.balances.sms.credits);
                setCoins(data.balances.documents.credits);
            }
           
        });

        async function companiesDetailsStart() {
            try {
                await companiesDetailsConnection.start();
                console.log('SignalR CompaniesDetails Connected.');
            } catch (err) {
                console.log(err);
                setTimeout(companiesDetailsStart, 5000);
            }
        }

        companiesDetailsConnection.onclose(companiesDetailsStart);

        // Start the connection.
        companiesDetailsStart();
/* 
        const subscriptionsConnection = new signalR.HubConnectionBuilder()
        .withUrl('https://leceipt.azurewebsites.net/api/OnSubscriptionsChanged', {
            headers: {
            oid: accountData.oid,
            },
        })
        .configureLogging(signalR.LogLevel.Information)
        .build();

        subscriptionsConnection.on('subscriptionUpdated', function (data) {
        console.log('SignalR push subscriptionUpdated!!');
        console.log(data);
        console.log('Billings type: ', billingsTypeRef.current);

        if (data && billingsTypeRef.current === 'subscription') {
        if (data && data.documents) {
          console.log('Subscription etax documents: ', data.documents);

          let _subscriptionDocuments = data.documents;

          let _credits = _subscriptionDocuments.limit + _subscriptionDocuments.topup - _subscriptionDocuments.spent;

          setCoins(_credits);
        }
      }
   
        });

        async function subscriptionsStart() {
        try {
            await subscriptionsConnection.start();
            console.log('SignalR Subscriptinos Connected.');
        } catch (err) {
            console.log(err);
            setTimeout(subscriptionsStart, 5000);
        }
        }

        subscriptionsConnection.onclose(subscriptionsStart);

        // Start the connection.
        subscriptionsStart();
 */

    }

    if (getNewCompany) {
      setGetNewCompany(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewCompany]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return (
    <Stack horizontal style={headerStyles}>
      <Stack vertical style={{ width: '310px' }}>
        <Stack horizontal horizontalAlign="space-between">
          <ActionButton
            onClick={() => {
              history.push({
                pathname: '/etax/documents/all',
                state: {},
              });
            }}
          >
            {/* <img src="/images/leceipt-logo.png" width="200px" alt="leceiptlogo" /> */}
            <img src="/images/leceipt-logo.png" height="55px" alt="leceiptlogo" />
          </ActionButton>
        </Stack>
      </Stack>
      <Stack vertical style={{ width: '100%', paddingLeft: '50px', background: '#0078D4', margin: 0 }}>
        <Stack horizontal horizontalAlign="space-between" disableShrink={true}>
          <Stack horizontal>
            <span style={{ marginTop: '10px' }}>{companyName}</span>
{/* 
            <PrimaryButton  
              text={companyName}
              styles={{ root: { marginTop: '3px', marginLeft: 0, paddingLeft: 0, paddingRight: 0 }, label: { fontSize: 26, fontWeight: 400}}}
              menuProps={{
                items: [
                  ...companyList.filter(_company => companyId !== _company.id).map(_company => (
                    {
                      name: _company.name,
                      url: '',
                      key: _company.id,
                      iconProps: {
                        iconName: 'CityNext2',
                        styles: {
                          root: {
                            fontSize: 20,
                            color: '#106ebe',
                          },
                        },
                      },
                      onClick: (event, element) => {
                        setCompanyName(_company.name);

                        sessionStorage.setItem("companyId", _company.id);

                        if (window.location.pathname !== '/etax/documents/all' ||
                            window.location.pathname !== '/') {
                          window.location.replace('/etax/documents/all')
                        } else {
                          window.location.reload(false);
                        }

                      },
                    }
                  )),
                  {
                    key: 'divider_1',
                    itemType: ContextualMenuItemType.Divider,
                  },
                  {
                    name: t("company.addCompany"),
                    url: '',
                    key: 'addCompany',
                    iconProps: {
                      iconName: 'Add',
                      styles: {
                        root: {
                          fontSize: 20,
                          color: '#106ebe',
                        },
                      },
                    },
                    onClick: (event, element) => {
                      history.push({
                        pathname: '/etax/companies/profiles',
                        state: {
                          command: "ADD"
                        },
                      });
                    },
                  },
                ],
                // By default, the menu will be focused when it opens. Uncomment the next line to prevent this.
                // shouldFocusOnMount: false
              }}
            />
             */}
          </Stack>
        
          <Stack horizontal horizontalAlign="end" disableShrink={true} style={{ width: '500px' }}>
            {/* 
                        <TooltipHost
                            content="เติมเครดิตอัตโนมัติ รวดเร็ว ทันใจ ตลอด 24 ชม."
                            // This id is used on the tooltip itself, not the host
                            // (so an element with this id only exists when the tooltip is shown)
                            id="qrcode-tooltip"
                            calloutProps={{ gapSpace: 0 }}
                            // styles={hostStyles}
                        >
                            <ActionButton 
                                iconProps={{ 
                                    iconName: 'QRCode',
                                    styles: {
                                        root: {
                                            fontSize: 24,
                                            color: '#FFFFFF',
                                            marginTop: '0px',
                                            marginRight: '5px',                                           
                                
                                        },
                                        rootHovered: {
                                            backgroundColor: "red"
                                        }
                                    },
                                    
                                }} 
                                onClick={() => {
                                    history.push({
                                        pathname: '/billings/documents/checkout',
                                        //search: '?update=true',  // query string
                                        state: {}
                                    });
                                }}    
                            />
                        </TooltipHost>

                                     
*/}

            {i18n.resolvedLanguage === 'th' && (
              <span
                onClick={() => {
                  i18n.changeLanguage('en');
                }}
                style={{ fontSize: '18px', marginTop: '10px', marginRight: '15px', cursor: 'pointer' }}
              >
                <Icon iconName="LocaleLanguage" style={{ marginRight: '7px', color: '#ffffff' }} />
                English&nbsp;
              </span>
            )}

            {i18n.resolvedLanguage === 'en' && (
              <span
                onClick={() => {
                  i18n.changeLanguage('th');
                }}
                style={{ fontSize: '18px', marginTop: '10px', marginRight: '15px', cursor: 'pointer' }}
              >
                <Icon iconName="LocaleLanguage" style={{ marginRight: '7px', color: '#ffffff' }} />
                ไทย&nbsp;
              </span>
            )}
            {(billingsType === 'prepaid') && companyData.id && (
              <span
                onClick={() => {
                  history.push({
                    pathname: '/billings',
                    state: {},
                  });
                }}
                style={{ fontSize: '24px', marginTop: '10px', cursor: 'pointer' }}
              >
                {enableSms 
                    ?
                        <span>{t('documents.credits')}: {numberWithCommas(coins)} | SMS: {numberWithCommas(sms)}&nbsp;</span>
                    :
                        <span>{t('documents.credits')}: {numberWithCommas(coins)}&nbsp;</span>
                }
              </span>
            )}

            <CommandButton menuProps={menuProps} style={{ paddingRight: '2%' }}>
              <Customizer settings={{ theme: HeaderTheme }}>
                <Persona
                  secondaryText={name}
                  initialsColor={PersonaInitialsColor.darkGreen}
                  initialsTextColor="white"
                  text={email}
                  size={PersonaSize.size40}
                />
              </Customizer>
            </CommandButton>
          </Stack>
        </Stack>
      </Stack>
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
};

export default Header;
