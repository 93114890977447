import { Button,
  TableCellLayout,
  makeStyles,
  Menu,
  MenuTrigger,
  MenuButton,
  MenuPopover,
  MenuList,
  MenuItem,
  DataGrid,
  DataGridHeader,
  DataGridRow,
  DataGridHeaderCell,
  DataGridBody,
  DataGridCell,
  TabList,
  Tab,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
  Field,
  Input,
  Spinner,
  ToolbarButton,
  Title2,
  Subtitle2Stronger,
  Link,
  Tooltip,
  ProgressBar,
  Popover,
  PopoverSurface,
  Divider,
  Toolbar,
  tokens
  } from '@fluentui/react-components';

  import { Alert } from "@fluentui/react-components/unstable";

  import { useMsal, useAccount } from '@azure/msal-react';
  import { silentRequest } from '../../authConfig';

  import { useLocation, useHistory } from 'react-router-dom';

import {
    FolderRegular,
    DocumentRegular,
    DocumentPdfRegular,
    AddRegular,
    ArrowUploadRegular,
    ChevronRightRegular,
    DeleteRegular,
    ArrowDownloadRegular,
    ShareIosRegular,
    DismissCircleRegular,
    MoreHorizontalRegular,
    ImageRegular,
    DocumentTextRegular,
    CloudBidirectionalRegular,
    CheckmarkRegular,
    DismissRegular,
    ShareRegular,
    VideoClipRegular,
    DocumentTableRegular,
    FolderZipRegular,
    SearchRegular,
    ArrowClockwiseRegular
    } from "@fluentui/react-icons";
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { niceBytes } from '../../helpers/ConvertFileSize';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles({
  stack: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: 'auto',
    height: 'auto',
    boxSizing: 'border-box',
    '> *': {
      textOverflow: 'ellipsis',
    },
    '> :not(:first-child)': {
      marginTop: '0px',
    },
    '> *:not(.ms-StackItem)': {
      flexShrink: 1,
    },
  },
  wrapper: {
    columnGap: "5px",
    display: "flex",
  },
  button: {
    // display: "flex", 
    // justifyContent: 'center', 
    // alignItems: 'center', 
    // fontWeight: 600,
    // ':hover': {
    //   color: tokens.colorNeutralForeground2BrandHover
    // }
  }
})

const toThaiDateString = (isoDateTime) => {
  let date = '';
  date = new Date(isoDateTime);
  let year = date.getFullYear() + 543;
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let numOfDay = date.getDate().toString().padStart(2, '0');

  let hour = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');

  return `${numOfDay}/${month}/${year} ${hour}:${minutes} น.`;
};

const twentyFiveMB = 25 * 1024 * 1024;

const re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png|\.pdf|\.txt|\.zip|\.mp4|\.csv|\.xlsx)$/i;

const getTypeFile = (key, size) => {
  let _file = null
  let _type = ''
  if (!re.test(key)) {
    if (size !== 0) {
      _file = <DocumentRegular />
      _type = 'file'
    } else {
      _file = <FolderRegular />
      _type = 'folder'

    }
  } else {
    if (key.includes('.pdf')) {
      _file = <DocumentPdfRegular />
    } else if (key.includes('.jpg') || 
              key.includes('.jpeg') || 
              key.includes('.png')) {
      _file = <ImageRegular />
    } else if (key.includes('.txt')) {
      _file = <DocumentTextRegular />
    } else if (key.includes('.zip')) {
      _file = <FolderZipRegular />
    } else if (key.includes('.mp4')) {
      _file = <VideoClipRegular />
    } else if (key.includes('.csv')) {
      _file = <DocumentTableRegular />
    } else if (key.includes('.xlsx')) {
      _file = <DocumentTableRegular />
    }
    _type = 'file'
  }

  return { _file, _type }
}

function getTabIndex(name) {
  if (name.includes('/')) {
    const split = name.split('/')
    if (!split[split.length - 1]) {
        return split.filter(value => value).length
    }
    return split.length - 1
  } 
  return 0
}

function Strorages() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t } = useTranslation();

  const history = useHistory();

  const location = useLocation();
  const { accessKeyId,
    secretAccessKey,
    storage,
    region,
    bucket} = location.state

  const styles = useStyles();

  const [items, setItems] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedItemsKey, setSelectedItemsKey] = useState([])
  const [tabIndex, setTabIndex] = useState(0)
  const [tabListValue, setTabListValue] = useState(['Doc'])
  const [dialog, setDialog] = useState({
    open: false,
    loading: false,
    headers: '',
    value: '',
    onSubmit: null,
    onCancel: null,
    content: function () {
      return null
    },
    onSubmitLabel: '',
    onCancelLabel: '',
    dowloadingProgress: 0
  })

  const [isLoading, setIsLoading] = useState(true)

  const [contents, setContents] = useState([])

  const fileInputRef = useRef();

  const [alert, setAlert] = useState({
    open: false,
    status: '',
    message: ''
  })

  const mainDiv = useRef({})

  const [moreContent, setMoreContent] = useState({
    IsTruncated: false,
    ContinuationToken: '',
    Prefix: '',
    loading: true
  })

  const [loadingLink, setLoadingLink] = useState(false)

  

  const columns = [
    { 
      columnId: "file", 
      label: "", 
      icon: <DocumentRegular />,
      compare: (a, b) => {
        return a.type.localeCompare(b.type);
      },
      renderHeaderCell: () => {
        return t("storages.file");
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate media={item.file} />
        );
      } 
    },
    { 
      columnId: "name", 
      label: "Name", 
      width: 100,
      compare: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      renderHeaderCell: () => {
        return t("storages.name");
      },
      renderCell: (item) => {
        return (
          <ActionCell onClickEvent={() => handleClickLink('name', item)} item={item} />
        );
      }
    },
    { 
      columnId: "modified", 
      label: "Modified", 
      width: 100,
      compare: (a, b) => {
        return new Date(a.modified) - new Date(b.modified);
      },
      renderHeaderCell: () => {
        return t("storages.modified");
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>{toThaiDateString(item.modified)}</TableCellLayout>
        );
      }, 
    },
    { 
      columnId: "size", 
      label: "Size", 
      width: 100,
      compare: (a, b) => {
        return a.sizeValue - b.sizeValue;
      },
      renderHeaderCell: () => {
        return t("storages.size");
      },
      renderCell: (item) => {
        return (
          <TableCellLayout truncate>{item.size}</TableCellLayout>
        );
      }, 
    },
  ];

  useEffect(() => {
    getNewContents()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!contents.length) return;

    const _contents = contents.map(_content => ({..._content, Key: _content.Key.replace('.file_placeholder', '')}))

    setItems(_contents.filter(_content => {
      if (tabIndex === 0) {
          if (_content.Key.includes('/')) {
              const split = _content.Key.split('/')
              
              if (!split[tabIndex + 1]) return true
              return false
          } else {
              return true
          }
          
      } else {
        const values = [...tabListValue]
        values.shift()
        const _tabListValue = values.join('/')
        // console.log('tabListValue', _tabListValue)
          if (_content.Key.includes('/')) {
              const split = _content.Key.split('/')
              
              // if (!(split[tabIndex + 1]) && (split[tabIndex])) return true
              if (!(split[tabIndex + 1]) && (split[tabIndex]) && _content.Key.includes(_tabListValue)) return true
              return false
          } else {
              return false
          }
      }
    }).map(_content => {
      const { _file, _type } = getTypeFile(_content.Key, _content.Size)

      return {
        key: _content.Key,
        file: _file,
        name: _content.Key.split('/')[_content.Key.split('/').length - 1] ? _content.Key.split('/')[_content.Key.split('/').length - 1] : _content.Key.split('/')[_content.Key.split('/').length - 2],
        // path: _content.Key.replace((_content.Key.split('/')[_content.Key.split('/').length - 1] ? _content.Key.split('/')[_content.Key.split('/').length - 1] : _content.Key.split('/')[_content.Key.split('/').length - 2]) + '/', ''),
        modified: _content.LastModified,
        size: _content.Size ? niceBytes(_content.Size) : '',
        sizeValue: _content.Size,
        type: _type
      }
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contents])

  async function getNewContents() {
    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .get(
          // 'http://localhost:7071/api/AWS_getObjectInBucket',
          // 'http://localhost:7071/api/AWS_uploadObject',
          'http://localhost:7071/api/AWS_listObjects',
          {
            params: {
              accessKeyId: accessKeyId,
              secretAccessKey: secretAccessKey,
              storage: storage,
              region: region,
              bucket: bucket,
            }
          },
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          }
        )
        .then((response) => {
          
          console.log('response', response);

          if (response.data.success) {
            const { Contents, IsTruncated, NextContinuationToken, Prefix } = response.data

            setMoreContent(prev => 
              ({...prev, 
                IsTruncated: IsTruncated, 
                ContinuationToken: NextContinuationToken, 
                Prefix: Prefix,
                loading: false
              })
            )

            const _contents = Contents.map(_content => ({..._content, Key: _content.Key.replace('.file_placeholder', '')}))

            setContents(_contents)

            // setItems(_contents.filter(_content => {
            //   if (tabIndex === 0) {
            //       if (_content.Key.includes('/')) {
            //           const split = _content.Key.split('/')
                      
            //           if (!split[tabIndex + 1]) return true
            //           return false
            //       } else {
            //           return true
            //       }
                  
            //   } else {
            //     const values = [...tabListValue]
            //     values.shift()
            //     const _tabListValue = values.join('/')
            //       if (_content.Key.includes('/')) {
            //           const split = _content.Key.split('/')
                      
            //           if (!(split[tabIndex + 1]) && (split[tabIndex]) && _content.Key.includes(_tabListValue)) return true
            //           return false
            //       } else {
            //           return false
            //       }
            //   }
            // }).map(_content => {
            //   const { _file, _type } = getTypeFile(_content.Key)

            //   return {
            //   key: _content.Key,
            //   file: _file,
            //   name: _content.Key.split('/')[_content.Key.split('/').length - 1] ? _content.Key.split('/')[_content.Key.split('/').length - 1] : _content.Key.split('/')[_content.Key.split('/').length - 2],
            //   // path: _content.Key.replace((_content.Key.split('/')[_content.Key.split('/').length - 1] ? _content.Key.split('/')[_content.Key.split('/').length - 1] : _content.Key.split('/')[_content.Key.split('/').length - 2]) + '/', ''),
            //   modified: _content.LastModified,
            //   size: _content.Size ? niceBytes(_content.Size) : '',
            //   sizeValue: _content.Size,
            //   type: _type
            //   // modifiedBy: "John Doe",
            // }}))
          
            setIsLoading(false)
            setSelectedItems([])
            setSelectedItemsKey([])
          }
        });
    });
  }

  async function getNewContentsNext(moreContent) {
    if (!moreContent.IsTruncated) return

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .get(
          // 'http://localhost:7071/api/AWS_getObjectInBucket',
          // 'http://localhost:7071/api/AWS_uploadObject',
          'http://localhost:7071/api/AWS_listObjects',
          {
            params: {
              accessKeyId: accessKeyId,
              secretAccessKey: secretAccessKey,
              storage: storage,
              region: region,
              bucket: bucket,

              Prefix: moreContent?.Prefix,
              ContinuationToken: moreContent?.ContinuationToken
            }
          },
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          }
        )
        .then((response) => {
          
          console.log('response', response);

          if (response.data.success) {
            const { Contents, IsTruncated, NextContinuationToken, Prefix } = response.data

            setMoreContent(prev => 
              ({...prev, 
                IsTruncated: IsTruncated, 
                ContinuationToken: NextContinuationToken, 
                Prefix: Prefix,
                loading: false
              })
            )

            const _contents = Contents.map(_content => ({..._content, Key: _content.Key.replace('.file_placeholder', '')}))

            if (moreContent.clickLink) {
              setContents(_contents)
            } else {
              setContents([...contents, ..._contents])
            }

            setLoadingLink(false)

            // setItems(_contents.filter(_content => {
            //   if (tabIndex === 0) {
            //       if (_content.Key.includes('/')) {
            //           const split = _content.Key.split('/')
                      
            //           if (!split[tabIndex + 1]) return true
            //           return false
            //       } else {
            //           return true
            //       }
                  
            //   } else {
            //     const values = [...tabListValue]
            //     values.shift()
            //     const _tabListValue = values.join('/')
            //       if (_content.Key.includes('/')) {
            //           const split = _content.Key.split('/')
                      
            //           if (!(split[tabIndex + 1]) && (split[tabIndex]) && _content.Key.includes(_tabListValue)) return true
            //           return false
            //       } else {
            //           return false
            //       }
            //   }
            // }).map(_content => {
            //   const { _file, _type } = getTypeFile(_content.Key)

            //   return {
            //   key: _content.Key,
            //   file: _file,
            //   name: _content.Key.split('/')[_content.Key.split('/').length - 1] ? _content.Key.split('/')[_content.Key.split('/').length - 1] : _content.Key.split('/')[_content.Key.split('/').length - 2],
            //   // path: _content.Key.replace((_content.Key.split('/')[_content.Key.split('/').length - 1] ? _content.Key.split('/')[_content.Key.split('/').length - 1] : _content.Key.split('/')[_content.Key.split('/').length - 2]) + '/', ''),
            //   modified: _content.LastModified,
            //   size: _content.Size ? niceBytes(_content.Size) : '',
            //   sizeValue: _content.Size,
            //   type: _type
            //   // modifiedBy: "John Doe",
            // }}))
          
            setIsLoading(false)
            setSelectedItems([])
            setSelectedItemsKey([])
          }
        });
    });
  }

  function setPathValue(key) {
    const _split = key.split('/')
    _split.pop()   
    console.log(['Doc', ..._split])
    setTabListValue(['Doc', ..._split])
  }

  function removePathValue(index) {
    const values = [...tabListValue];
    values.splice(index + 1, values.length);

    console.log(values)
    setTabListValue(values)
  }

  function handleClickLink(columnId, item) {
    // console.log('item', item)
    if (columnId === 'name') {
      if (item.type === 'folder') {
        setTabIndex(getTabIndex(item.key))
        setPathValue(item.key)

        setLoadingLink(true)
        // setMoreContent(prev => 
        //   ({...prev, 
        //     Prefix: item.key, 
        //     ContinuationToken: '',
        //     IsTruncated: false
        //   })
        // )

        getNewContentsNext({ Prefix: item.key, IsTruncated: true, clickLink: true })
      } else {
        // const link = document.createElement("a");
        // link.href = 'https://firststorj0001.gateway.storjshare.io/testUpload%20%281%29.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=jxr7uir6nxqcwiow226b6vf3drxa%2F20230731%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20230731T120608Z&X-Amz-Expires=3600&X-Amz-Signature=88f4cc379b14145d2d16f8e6987743270fdb76748e600ede4e1e55166101d825&X-Amz-SignedHeaders=host&x-id=GetObject'
        // link.target = "_blank"
        // link.click();

        const params = {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          storage: storage,
          region: region,
          bucket: bucket,
          Objects: JSON.stringify([{ Key: item.key }])
        }
    
        instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
          axios
            .get(
              // 'http://localhost:7071/api/AWS_getObjectInBucket',
              'http://localhost:7071/api/AWS_shareObject', {
                params: params
              }, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                }
              },
            )
            .then((response) => {
              const link = document.createElement("a");
              link.href = response.data.url[0]
              link.target = "_blank"
              link.click();
              
            })
        })
      }
    }
  }

  function upload(e, type, value) {
    e.preventDefault();

    const _pathArray = [...tabListValue]
    _pathArray.shift()
    const _path = _pathArray.join('/') + '/'

    console.log('_path', _path)

    // const _accessKeyId = 'jxr7uir6nxqcwiow226b6vf3drxa'
    // const _secretAccessKey = 'jytnnngkjtsthnek7fy2ahod5sqx2a6c6fnzjlt7wembwfwf7apcm'
    // const _storage = 'storj'
    // const _region = 'ap-southeast-1'
    // const _bucket = 'firststorj0001'

    setDialog(prev => ({
      ...prev,
      loading: true,
      onSubmitLabel: '',
      onCancelLabel: ''
    }))

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .get(
          // 'http://localhost:7071/api/AWS_getObjectInBucket',
          'http://localhost:7071/api/AWS_createFolder',
          {
            params: {
              accessKeyId: accessKeyId,
              secretAccessKey: secretAccessKey,
              storage: storage,
              region: region,
              bucket: bucket,
              type: type, 
              key: value, 
              body: '', 
              path: _path
            }
          },
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          }
        )
        .then((response) => {
          
          console.log('response', response);
          if (handleStatus(response)) {
            setDialog(prev => 
              ({...prev, 
                loading: false,
                content: function () {
                  return <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <DismissRegular fontSize={32} />
                  <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.createIncomplete')}</Subtitle2Stronger>
                </div>
                },
              }))
          } else {
            setDialog(prev => 
              ({...prev, 
                loading: false,
                content: function () {
                  return <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <CheckmarkRegular fontSize={32} />
                  <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.createComplete')}</Subtitle2Stronger>
                </div>
                },
              }))
          }

          setTimeout(() => {
            setDialog({
              open: false,
              loading: false,
              headers: '',
              value: '',
              onSubmit: null,
              onCancel: null,
              content: function () {
                return null
              }
            })
          }, 2000);

          if (moreContent.Prefix) {
            getNewContentsNext({ Prefix: moreContent.Prefix, IsTruncated: true, clickLink: true })
          } else {
            getNewContents()
          }
          // if (response.data.success) {
          //   // if (response.data.Buckets.length) {
          //   //   setStatus('buckets')
          //   //   setBuckets(response.data.Buckets)
          //   // }
          //   setStatus("success");
          // } else {
          //   setStatus("fail");
          // }

          // setIsLoading(false);
        });
    })
  }

  async function handleUploadFile(e) {
    e.preventDefault();

    if (e.target.files[0]) {
      console.log(e.target.files[0])

      const _pathArray = [...tabListValue]
      _pathArray.shift()
      const _path = _pathArray.join('/') + '/'
  
      // const _accessKeyId = 'jxr7uir6nxqcwiow226b6vf3drxa'
      // const _secretAccessKey = 'jytnnngkjtsthnek7fy2ahod5sqx2a6c6fnzjlt7wembwfwf7apcm'
      // const _storage = 'storj'
      // const _region = 'ap-southeast-1'
      // const _bucket = 'firststorj0001'

      setDialog(prev => 
        ({...prev, 
          headers: ``,
          value: '',
          open: true,
          onSubmit: null,
          onCancel: () => setDialog({
            open: false,
            loading: false,
            headers: '',
            value: '',
            onSubmit: null,
            onCancel: null,
            content: function () {
              return null
            }
          }),
          loading: true,
          content: function () {
            return this.loading ? 
            <Spinner labelPosition='below' label={t('storages.uploading')} /> 
            : 
            <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
              <CheckmarkRegular fontSize={32} />
              <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.uploadComplete')}</Subtitle2Stronger>
            </div>
          },
        }))
  
      let _type = ''
      if (e.target.files[0].name.includes('.pdf')) {
        _type = '.pdf'
      } else {
        _type = '.txt'
      }
  
      let formData = new FormData();
  
      const params = {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        storage: storage,
        region: region,
        bucket: bucket,
        type: _type, 
        key: e.target.files[0].name, 
        body: '', 
        path: _path
      }
  
      // formData.append('accessKeyId', _accessKeyId);
      // formData.append('secretAccessKey', _secretAccessKey);
      // formData.append('storage', _storage);
      // formData.append('region', _region);
      // formData.append('bucket', _bucket);
      // formData.append('type', _type);
      // formData.append('key', e.target.files[0].name);
      formData.append('file', e.target.files[0]);
      // formData.append('path', _path);
  
      formData.append("query", JSON.stringify(params));
  
      instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
        axios
          .post(
            // 'http://localhost:7071/api/AWS_getObjectInBucket',
            'http://localhost:7071/api/AWS_uploadObject', formData, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'multipart/form-data',
              },
              timeout: 60000,
            }
          )
          .then((response) => {
            
            console.log('response', response);
            if (handleStatus(response)) {
              setDialog(prev => 
                ({...prev, 
                  loading: false,
                  content: function () {
                    return <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <DismissRegular fontSize={32} />
                    <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.uploadIncomplete')}</Subtitle2Stronger>
                  </div>
                  },
                }))
            } else {
              setDialog(prev => ({...prev, loading: false}))  
            }

            setTimeout(() => {
              setDialog({
                open: false,
                loading: false,
                headers: '',
                value: '',
                onSubmit: null,
                onCancel: null,
                content: function () {
                  return null
                }
              })
            }, 2000);

            if (moreContent.Prefix) {
              getNewContentsNext({ Prefix: moreContent.Prefix, IsTruncated: true, clickLink: true })
            } else {
              getNewContents()
            }
          });
      })
    }
  }
  async function handleDeleteConfirm(e, keyArray) {
    setDialog(prev => 
      ({...prev, 
        headers: ``,
        value: '',
        open: true,
        onSubmit: () => handleDelete(e, keyArray),
        onCancel: () => {
          setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          },
          onCancelLabel: 'Cancel'
        })},
        loading: false,
        content: function () {
          return <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <DeleteRegular fontSize={32} />
            <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.deleteConfirmMessage')}</Subtitle2Stronger>
          </div>
        },
        onCancelLabel: 'Cancel',
        onSubmitLabel: 'Delete'
      }))
  }

  async function handleDelete(e, keyArray) {
    e.preventDefault();
  
    // const _accessKeyId = 'jxr7uir6nxqcwiow226b6vf3drxa'
    // const _secretAccessKey = 'jytnnngkjtsthnek7fy2ahod5sqx2a6c6fnzjlt7wembwfwf7apcm'
    // const _storage = 'storj'
    // const _region = 'ap-southeast-1'
    // const _bucket = 'firststorj0001'

    const source = axios.CancelToken.source()

    const params = {
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      storage: storage,
      region: region,
      bucket: bucket,
      Objects: JSON.stringify(keyArray)
    }

    setDialog(prev => 
      ({...prev, 
        headers: ``,
        value: '',
        open: true,
        onSubmit: null,
        onCancel: () => {
          source.cancel()
          setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          },
          onCancelLabel: ''
        })},
        loading: true,
        content: function () {
          return this.loading ? 
          <Spinner labelPosition='below' label={t('storages.deleting')} /> 
          : 
          <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <CheckmarkRegular fontSize={32} />
            <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.deleteComplete')}</Subtitle2Stronger>
          </div>
        },
        onCancelLabel: '',
        onSubmitLabel: null
      }))

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .get(
          // 'http://localhost:7071/api/AWS_getObjectInBucket',
          'http://localhost:7071/api/AWS_deleteObject', {
            params: params,
            cancelToken: source.token
          }, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          },
        )
        .then((response) => {
          
          console.log('response', response);
          if (handleStatus(response)) {
            setDialog(prev => 
              ({...prev, 
                loading: false,
                content: function () {
                  return <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <DismissRegular fontSize={32} />
                  <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.deleteIncomplete')}</Subtitle2Stronger>
                </div>
                },
              }))
          } else {
            setDialog(prev => 
              ({...prev, 
                loading: false
              }))
          }

          setTimeout(() => {
            setDialog({
              open: false,
              loading: false,
              headers: '',
              value: '',
              onSubmit: null,
              onCancel: null,
              content: function () {
                return null
              }
            })
          }, 2000);

          if (moreContent.Prefix) {
            getNewContentsNext({ Prefix: moreContent.Prefix, IsTruncated: true, clickLink: true })
          } else {
            getNewContents()
          }
        });
    })
  }

  async function handleDownload(e, keyArray) {
    e.preventDefault();

    if (keyArray.some(_key => !re.test(_key.Key))) return setAlert(prev => ({...prev, open: true, status: 'error', message: t('storages.alertMessageDownloadFolder')}))
    

    if (keyArray.length > 100) return setAlert(prev => ({...prev, open: true, status: 'error', message: t('storages.alertMessageDownloadManyFiles')}))

    const foundLargeFile = contents.some(_content => keyArray.find(_keyObj => _keyObj.Key === _content.Key && _content.Size >= twentyFiveMB))

    if (foundLargeFile) {
      console.log("keyArray", keyArray)
      if (keyArray.length > 1) return setAlert(prev => ({...prev, open: true, status: 'error', message: t('storages.alertMessageDownloadManyLargeFiles')}))

      const params = {
        accessKeyId: accessKeyId,
        secretAccessKey: secretAccessKey,
        storage: storage,
        region: region,
        bucket: bucket,
        Objects: JSON.stringify(keyArray)
      }

      instance.acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
          .get(
            // 'http://localhost:7071/api/AWS_getObjectInBucket',
            'http://localhost:7071/api/AWS_shareObject', {
              params: params
            }, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              }
            },
          ).then((response) => {
            console.log(response.data.url[0])

            downloadLargeFile(response.data.url[0], keyArray[0].Key)
          })

        })

      return;
    }

    const params = {
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      storage: storage,
      region: region,
      bucket: bucket,
      Objects: JSON.stringify(keyArray)
    }

    setDialog(prev => 
      ({...prev, 
        headers: ``,
        value: '',
        open: true,
        onSubmit: null,
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          }
        }),
        loading: true,
        content: function () {
          return this.loading ? 
          <Spinner labelPosition='below' label={t('storages.downloading')} /> 
          : 
          <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <CheckmarkRegular fontSize={32} />
            <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.downloadComplete')}</Subtitle2Stronger>
          </div>
        },
      }))

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .get(
          // 'http://localhost:7071/api/AWS_getObjectInBucket',
          // 'http://localhost:7071/api/AWS_shareObject', {
          'http://localhost:7071/api/AWS_downloadObject', {
            params: params,
            responseType: 'blob'
          }, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            }
          },
        )
        .then(async (response) => {
          
          console.log('response', response);

         if (keyArray.length > 1) {
           const link = document.createElement('a');
           link.setAttribute('href', window.URL.createObjectURL(new Blob([response.data])));
           link.setAttribute('download', storage + '-' + region + '-' + new Date().toISOString() + '.zip');
           link.style.display = 'none';
           document.body.appendChild(link);
           link.click();
           document.body.removeChild(link);
         } else {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
            link.download = keyArray[0].Key;
            link.click();
         }

          setDialog(prev => ({...prev, loading: false}))

          setTimeout(() => {
            setDialog({
              open: false,
              loading: false,
              headers: '',
              value: '',
              onSubmit: null,
              onCancel: null,
              content: function () {
                return null
              }
            })
          }, 2000);

          if (moreContent.Prefix) {
            getNewContentsNext({ Prefix: moreContent.Prefix, IsTruncated: true, clickLink: true })
          } else {
            getNewContents()
          }

        }).catch(err => {
          setAlert(prev => ({...prev, open: true, status: 'error', message: 'Access Denied'}))
          setDialog(prev => 
            ({...prev, 
              loading: false,
              content: function () {
                return <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
                <DismissRegular fontSize={32} />
                <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.downloadIncomplete')}</Subtitle2Stronger>
              </div>
              },
            }))

          setTimeout(() => {
            setDialog({
              open: false,
              loading: false,
              headers: '',
              value: '',
              onSubmit: null,
              onCancel: null,
              content: function () {
                return null
              }
            })
          }, 2000);
        })
    })
  }

  async function downloadLargeFile(url, Key) {

    setDialog(prev => 
      ({...prev, 
        headers: ``,
        value: '',
        open: true,
        onSubmit: null,
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          }
        }),
        loading: true,
        dowloadingProgress: 0,
        content: function () {
          return this.loading ? 
          <>
            <Spinner labelPosition='below' label={`${t('storages.downloading')} ${this.dowloadingProgress}%`} /> 
            <br />
            <Field validationMessage="" validationState="none">
              <ProgressBar value={this.dowloadingProgress / 100} thickness="large" />
            </Field>
          </>
          : 
          <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <CheckmarkRegular fontSize={32} />
            <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.downloadComplete')}</Subtitle2Stronger>
          </div>
        },
      }))

    axios.get(url, {
      responseType: 'arraybuffer',
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // setProgress(percentage);
        setDialog(prev => ({...prev, dowloadingProgress: percentage}))

      },
    }).then(response => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
      link.download = Key
      link.click();

      setDialog(prev => ({...prev, loading: false}))

      setTimeout(() => {
        setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          }
        })
      }, 2000);

      if (moreContent.Prefix) {
        getNewContentsNext({ Prefix: moreContent.Prefix, IsTruncated: true, clickLink: true })
      } else {
        getNewContents()
      }

    })
    .catch(err => console.log("err downloadLargeFile", err))
  }

  function handleStatus(res) {
    let result = false
    if (res.data.status && res.data.status === 403) {
      setAlert(prev => ({...prev, open: true, status: 'error', message: 'Access Denied'}))
      result = true
    }

    return result
  }
  
  async function handleShare(e, keyArray) {
    e.preventDefault();

    if (keyArray.some(_key => !re.test(_key.Key))) {
      return;
    }
  
    setDialog(prev => 
      ({...prev, 
        headers: `Share ${keyArray[0].Key}`,
        value: '',
        open: true,
        onSubmit: null,
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          }
        }),
        loading: true,
        copyLink: false,
        content: function () {
          return this.loading ? 
          <Spinner labelPosition='below' label={t('storages.loading')} /> 
          : 
          <Field
            label="Link"
            validationState={this.copyLink ? "success" : ""}
            validationMessage={this.copyLink ? t('storages.copied') : ""}
          >
            <Input value={this.value} disabled />
          </Field>
        },
        onSubmitLabel: 'Copy link',
        onCancelLabel: 'Cancel',
      }))

    // const _accessKeyId = 'jxr7uir6nxqcwiow226b6vf3drxa'
    // const _secretAccessKey = 'jytnnngkjtsthnek7fy2ahod5sqx2a6c6fnzjlt7wembwfwf7apcm'
    // const _storage = 'storj'
    // const _region = 'ap-southeast-1'
    // const _bucket = 'firststorj0001'

    const params = {
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      storage: storage,
      region: region,
      bucket: bucket,
      Objects: JSON.stringify(keyArray)
    }

    console.log(params)

    instance.acquireTokenSilent({ ...silentRequest, account: account }).then((tokenResponse) => {
      axios
        .get(
          // 'http://localhost:7071/api/AWS_getObjectInBucket',
          'http://localhost:7071/api/AWS_shareObject', {
            params: params
          }, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            }
          },
        )
        .then((response) => {
          
          console.log('response', response);
          if (handleStatus(response)) {
            setDialog(prev => 
              ({...prev, 
                loading: false,
                content: function () {
                  return <div className={styles.stack} style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <DismissRegular fontSize={32} />
                  <Subtitle2Stronger style={{ paddingTop: 6 }}>{t('storages.shareIncomplete')}</Subtitle2Stronger>
                </div>
                },
              }))

              setTimeout(() => {
                setDialog({
                  open: false,
                  loading: false,
                  headers: '',
                  value: '',
                  onSubmit: null,
                  onCancel: null,
                  content: function () {
                    return null
                  }
                })
              }, 2000);
          } else {
            setDialog(prev => 
              ({...prev, 
                loading: false,
                onSubmit: () => {
                  setDialog(prev => ({...prev, copyLink: true}))
                  navigator.clipboard.writeText(response.data.url[0])},
                value: response.data.url[0]}))

          }


          if (moreContent.Prefix) {
            getNewContentsNext({ Prefix: moreContent.Prefix, IsTruncated: true, clickLink: true })
          } else {
            getNewContents()
          }
        })
    })
  }

  async function handleDisconnect(e) {
    e.preventDefault();

    setDialog(prev => 
      ({...prev, 
        headers: t('storages.disconnect'),
        value: '',
        open: true,
        onSubmit: () => history.push({
          pathname: '/settings/storages',
          state: {},
        }),
        onCancel: () => setDialog({
          open: false,
          loading: false,
          headers: '',
          value: '',
          onSubmit: null,
          onCancel: null,
          content: function () {
            return null
          }
        }),
        content: function () {
          return t('storages.disconnectConfirm')
        },
        onSubmitLabel: "Confirm",
        onCancelLabel: "Cancel"
      }))
  
    
  }

  function ActionCell({item, onClickEvent}) {
    const [hovered, setHovered] = useState(false)
  
    return (<div 
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)} 
              style={{ display: "flex", flexDirection: "row", width: "100%"}}
    >
      
        <TableCellLayout
          truncate
        >
          <Tooltip content={item.name} relationship="description" showDelay={1000} positioning={'below'}>
            <Link appearance="subtle" onClick={onClickEvent}>
              {item.name}
            </Link>
          </Tooltip>
        </TableCellLayout>
        {hovered && (
          <div style={{ display: "flex", flexDirection: "row"}}>
            {item.type !== 'folder' && (
              <ToolbarButton onClick={(e) => handleShare(e, [{Key: item.key}])} aria-label="Share" icon={<ShareRegular />} />
            )}
            <Menu>
              <MenuTrigger>
                <ToolbarButton aria-label="More" icon={<MoreHorizontalRegular />} />
              </MenuTrigger>
    
              <MenuPopover>
                <MenuList>
                  {item.type !== 'folder' ? (
                    <>
                      <MenuItem onClick={onClickEvent}>{t('storages.open')}</MenuItem>
                      <MenuItem onClick={(e) => handleShare(e, [{Key: item.key}])}>{t('storages.share')}</MenuItem>
                      <MenuItem onClick={(e) => handleDownload(e, [{Key: item.key}])}>{t('storages.download')}</MenuItem>
                    </>
                  ) : null}
                  <MenuItem onClick={(e) => handleDeleteConfirm(e, [{Key: item.key}])}>{t('storages.delete')}</MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </div>
        )}
      
    </div>)
  }

  function TextFieldSearch() {
    const [search, setSearch] = useState('')
    const [data, setData] = useState([])
    const [isLoadData, setIsLoadData] = useState(false)

    const [cancelTokenSource, setCancelTokenSource] = useState(null);

    useEffect(() => {
      if (!search) return;

      // const source = axios.CancelToken.source()
      getOptions(search)

      // return () => source.cancel()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    async function getOptions(value) {
      setIsLoadData(true)

      if (cancelTokenSource) {
        cancelTokenSource.cancel('New request initiated');
      }
  
      const newCancelTokenSource = axios.CancelToken.source();
      setCancelTokenSource(newCancelTokenSource);

      try {
        const tokenResponse = await instance.acquireTokenSilent({ ...silentRequest, account: account })

        const response = await axios
        .get(
          'http://localhost:7071/api/AWS_listAllObjectInBucket',
          // 'http://localhost:7071/api/AWS_searchObjects',
          {
            params: {
              accessKeyId: accessKeyId,
              secretAccessKey: secretAccessKey,
              storage: storage,
              region: region,
              bucket: bucket,
              // Prefix: value,
            },
            // cancelToken: source.token
            cancelToken: newCancelTokenSource.token,
          },
          {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
            
          }
        )

        const { contents } = response.data

        const _contents = contents.map(_content => ({..._content, Key: _content.Key.replace('.file_placeholder', '')}))
        const _filterContents = _contents.filter(_content => _content.Key.includes(value))

        const _data = _filterContents.map(_content => {
          if (_content.Key.includes('/')) {
            const _split = _content.Key.split('/')
            if (_split[_split.length - 1]) {
              return {
                type: getTypeFile(_split[_split.length - 1], _content.Size),
                Key: _split[_split.length - 1],
                key: _split[_split.length - 1],
                path: _content.Key
              }
            } else {
              return {
                type: getTypeFile(_split[_split.length - 2], _content.Size),
                Key: _split[_split.length - 2],
                key: _split[_split.length - 2],
                path: _content.Key
              }
            }
          } else {
            return {
              type: getTypeFile(_content.Key, _content.Size),
              Key: _content.Key,
              key: _content.Key,
              path: _content.Key
            }
          }
        })

        const _filterData = _data.filter(_content => _content.Key.includes(value))

        setData(_filterData)

        setIsLoadData(false)
        
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error:', error);
        }
      }
      
    }

    function ActionOption({data}) {
      const [hovered, setHovered] = useState(false)

      return <div style={{ 
          display: "flex", 
          flexDirection: "row", 
          width: "95%", 
          height: 44, 
          justifyContent: "space-between", 
          padding: "0px 8px",
          ...(hovered ? { backgroundColor: "#edebe9"} : {}),
          // marginRight: 10
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)} 
        key={data.key}
      >
        <div 
          style={{ display: "flex", flexDirection: "row" }} 
          onClick={() => {
            handleClickLink('name', { key: data.path, type: data.type._type})
            setSearch("")
          }}
        >
          <TableCellLayout truncate media={data.type._file} />
          <div>
            <Link appearance="subtle" style={{ padding: "12px 8px", width: 250, whiteSpace: 'nowrap',  overflow: 'hidden', display: 'inline-block', textOverflow: "ellipsis" }}>
              {data.Key}
              {/* <label style={{ textOverflow: "ellipsis", width: 200, whiteSpace: 'nowrap',  overflow: 'hidden' }}>

              </label> */}
            </Link>
          </div>
        </div>
        {hovered && (
        <div style={{ display: "flex", flexDirection: "row"}}>
          {data.type._type !== 'folder' && (
            <ToolbarButton onClick={(e) => handleShare(e, [{Key: data.path}])} aria-label="Share" icon={<ShareRegular />} />
          )}
          <Menu>
            <MenuTrigger>
              <ToolbarButton aria-label="More" icon={<MoreHorizontalRegular />} />
            </MenuTrigger>
  
            <MenuPopover>
              <MenuList>
                {data.type._type !== 'folder' ? (
                  <>
                    <MenuItem onClick={() => handleClickLink('name', { key: data.path })}>{t('storages.open')}</MenuItem>
                    <MenuItem onClick={(e) => handleShare(e, [{Key: data.path}])}>{t('storages.share')}</MenuItem>
                    <MenuItem onClick={(e) => handleDownload(e, [{Key: data.path}])}>{t('storages.download')}</MenuItem>
                  </>
                ) : null}
                <MenuItem onClick={(e) => handleDeleteConfirm(e, [{Key: data.path}])}>{t('storages.delete')}</MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
      )}
      </div>
    }
  
  const buttonRef = useRef(null);
  const positioningRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      positioningRef.current?.setTarget(buttonRef.current);
    }
  }, [buttonRef, positioningRef]);

  // const [focused, setFocused] = useState(false)
  // const onFocus = () => setFocused(true)
  // const onBlur = () => setFocused(false)



  return <Field /* onFocus={onFocus} onBlur={onBlur} */ ref={buttonRef} onChange={e => setSearch(e.target.value)} style={{ width: mainDiv.current.offsetWidth * 0.3}}>
    <Input contentBefore={<SearchRegular />} contentAfter={search ? <DismissRegular onClick={() => setSearch("")} /> : null } value={search} placeholder='ค้นหา' />
    <Popover open={search !== ""} positioning={{ positioningRef, position: "below" }} >
      <PopoverSurface style={{ width: mainDiv.current.offsetWidth * 0.3, padding: 0}}>
        {isLoadData ? <Spinner labelPosition="below" style={{ padding: "12px 0px"}} /* label={t('storages.loading')} */ /> : (
          data.map((_data, index) => (
            <ActionOption data={_data} key={`option-${index}`} />
          ))
        )}
      </PopoverSurface>
    </Popover>
  </Field>
  }

    return <div ref={mainDiv} style={{ padding: 30, width: "100%", height: window.screen.height, backgroundColor: "white", borderLeft: "1px solid #e0e0e0"}}>
      {alert.open ? (
        <>
          <Alert intent={alert.status} action={{ onClick: () => { setAlert(prev => ({...prev, open: false})) }, icon: <DismissCircleRegular />,
          "aria-label": "dismiss message", }}>{alert.message}</Alert>
          <br />
        </>
      ) : null}
      <div>
        <Title2 as='h2'><CloudBidirectionalRegular /> {t('storages.header')}</Title2>

      </div>
      <br />
      
      {isLoading ? (
        <>
          <br />
          <br />
          <Spinner labelPosition="below" label={t('storages.loading')} />
        </>
      ) : (
        <>
        <Divider />
        {/* <br /> */}
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "6px 0px"}}>
          <div style={{ display: "flex"}}>

          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuButton appearance="primary" icon={<ArrowUploadRegular />}>
                {t('storages.upload')}
              </MenuButton>
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem onClick={() => fileInputRef.current.click()}>{t('storages.file')}</MenuItem>
                {/* <MenuItem>Item b</MenuItem> */}
              </MenuList>
            </MenuPopover>
          </Menu>
        

          {selectedItemsKey.length ? (
            <>
              {selectedItemsKey.length === 1 ? (
                <Button appearance="transparent" icon={<ShareIosRegular />} onClick={(e) => handleShare(e, selectedItemsKey)} >
                  {t('storages.share')}
                </Button>
              ) : null}
                <Button appearance="transparent" icon={<ArrowDownloadRegular />} onClick={(e) => handleDownload(e, selectedItemsKey)} >
                  {t('storages.download')}
                </Button>
                <Button appearance="transparent" icon={<DeleteRegular />} onClick={(e) => handleDeleteConfirm(e, selectedItemsKey)} >
                  {t('storages.delete')}
                </Button>
              </>
            ) : (
            <>
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <MenuButton appearance="subtle" icon={<AddRegular />}>
                    {t('storages.new')}
                  </MenuButton>
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    <MenuItem 
                      onClick={() => 
                        setDialog(prev => 
                          ({...prev, 
                            headers: t('storages.newFolder'),
                            value: '',
                            open: true,
                            onSubmit: (e, type, value) => upload(e, type, value),
                            onCancel: () => setDialog({
                              open: false,
                              headers: '',
                              value: '',
                              onSubmit: null,
                              onCancel: null,
                              content: function () { return null }
                            }),
                            content: function () { 
                              return this.loading ? 
                                      (<Spinner labelPosition='below' label={t('storages.creating')} /> ) 
                                      : 
                                      (<Field
                                        label={t('storages.creating')}
                                        // validationState="success"
                                        // validationMessage="This is a success message."
                                      >
                                        <Input value={this.value} onChange={(e, data) => {
                                          setDialog(prev => ({...prev, value: data.value}))
                                        }} />
                                      </Field>)
                            }, 
                            onSubmitLabel: t('storages.create'),
                            onCancelLabel: t('storages.cancel')
                          }))
                      }>{t('storages.folder')}</MenuItem>
                    {/* <MenuItem>Item b</MenuItem> */}
                  </MenuList>
                </MenuPopover>
              </Menu>
              <Button appearance="transparent" icon={<ArrowClockwiseRegular />} onClick={(e) => {
                  setTabListValue(['Doc'])
                  setIsLoading(true)
                  setItems([])
                  setContents([])
                  setSelectedItems([])
                  setSelectedItemsKey([])
                  setTabIndex(0)
                  getNewContents()
                }}>
                {t('documents.refresh')}
              </Button>
              <Menu>
                <MenuTrigger>
                  <ToolbarButton aria-label="More" icon={<MoreHorizontalRegular />} />
                </MenuTrigger>

                <MenuPopover>
                  <MenuList>
                    <MenuItem onClick={handleDisconnect}>{t('storages.disconnect')}</MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
              </>
              
            )}
          </div>
          <TextFieldSearch />
        </div>
        {/* <br /> */}
        <Divider />
        {/* <br /> */}
        <div className={styles.wrapper}>
          <TabList onTabSelect={(e, data) => {
            setTabIndex(data.value)
            removePathValue(data.value)

            setLoadingLink(true)

            let _prefix = ''
            for (let i = 0; i < data.value; i++) {
              _prefix = _prefix + (tabListValue[i] === "Doc" ? '' : tabListValue[i])
              if (i !== 0 && i !== data.value) {
                _prefix = _prefix + '/'
              }
            }

            getNewContentsNext({ Prefix: _prefix, IsTruncated: true, clickLink: true })
          }}
            selectedValue={tabIndex}
          >
            {tabListValue.map((_str, index) => (
              <Fragment key={_str + index}>
                <Tab value={index}>{_str === "Doc" ? t("storages.documents") : _str}</Tab>
                {index !== tabListValue.length - 1 && (
                  <div style={{ paddingTop: 15}}>
                    <ChevronRightRegular />
                  </div>
                )}
              </Fragment>
            ))}
          </TabList>
        </div>
        <br />
        {loadingLink ? (
          <>
            <br />
            <br />
            <Spinner labelPosition="below" label={t('storages.loading')} />
          </>
        ) : (
        <InfiniteScroll
          style={{ padding: 10}}
          dataLength={items.length}
          next={() => {
            if (moreContent.loading) return;
            return getNewContentsNext(moreContent)
          }}
          hasMore={moreContent.loading ? false : moreContent.IsTruncated}
          loader={
            <>
              <br />
              <Spinner labelPosition='below' label={t('storages.downloading')} /> 
              <br />
            </>
          }
        >
          <DataGrid
            items={items}
            columns={columns}
            sortable
            focusMode='none'
            getRowId={(item) => item.key}
            selectionMode="multiselect"
            onSelectionChange={(e, data) => {
              // console.log(data)
              // data.selectedItems.forEach(value => console.log(value))
              if (e.target.localName === 'input') {
                const _selectedItemsKey = []
                data.selectedItems.forEach(_value => _selectedItemsKey.push({
                  Key: _value
                }))
                setSelectedItemsKey(_selectedItemsKey)
                setSelectedItems(data.selectedItems)
              }
            }}
            selectedItems={selectedItems}
            resizableColumns
            columnSizingOptions={{
              file: {
                minWidth: 20,
                defaultWidth: 20,
              },
              name: {
                defaultWidth: mainDiv.current.offsetWidth * 0.4,
                minWidth: 320,
                // idealWidth: 320,
              },
              modified: {
                defaultWidth: mainDiv.current.offsetWidth * 0.2,
                minWidth: 180,
                // idealWidth: 180,
              },
              size: {
                defaultWidth: mainDiv.current.offsetWidth * 0.2,
                minWidth: 180,
                // idealWidth: 180,
              },
            }}
            onColumnResize={(event, { columnId, width }) => {
              if (event) {
                console.log(event.offsetX, event.offsetY, columnId, width);
              }
            }}
          >
            <DataGridHeader>
              <DataGridRow selectionCell={{ "aria-label": "Select all rows" }}>
                {({ renderHeaderCell }) => (
                  <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                )}
              </DataGridRow>
            </DataGridHeader>
            <DataGridBody>
              {({ item, rowId }) => (
                <DataGridRow
                  key={rowId}
                  selectionCell={{ "aria-label": "Select row" }}
                
                >
                  {({ renderCell, columnId }) => (
                    <DataGridCell key={rowId + columnId}>{renderCell(item)}</DataGridCell>
                  )}
                </DataGridRow>
              )}
            </DataGridBody>
          </DataGrid>
        </InfiniteScroll>
        )}
        <Dialog open={dialog.open}>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>{dialog.headers}</DialogTitle>
              <DialogContent>
                {dialog.content()}
              </DialogContent>
              <DialogActions>
                {dialog.onSubmitLabel ? (
                  <Button appearance="primary" onClick={(e) => dialog.onSubmit(e, 'Folder', dialog.value)}>{dialog.onSubmitLabel}</Button>
                  ) : null}
                {dialog.onCancelLabel ? (
                  <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary" onClick={() => 
                      dialog.onCancel()
                      }>{dialog.onCancelLabel}</Button>
                  </DialogTrigger>
                ) : null}
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>

        <input onChange={handleUploadFile} multiple={false} ref={fileInputRef} type='file' hidden/>
        </>
      )}
      
    </div>
}

export default Strorages;