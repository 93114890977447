export const countryCodeAlpha3 = [
  {
    countryCode: 'ABW',
    countryNameTh: 'อารูบา',
    countryNameEn: 'Aruba',
  },
  {
    countryCode: 'AFG',
    countryNameTh: 'อัฟกานิสถาน',
    countryNameEn: 'Afghanistan',
  },
  {
    countryCode: 'AGO',
    countryNameTh: 'แองโกลา',
    countryNameEn: 'Angola',
  },
  {
    countryCode: 'AIA',
    countryNameTh: 'แองกวิลลา',
    countryNameEn: 'Anguilla',
  },
  {
    countryCode: 'ALA',
    countryNameTh: 'หมู่เกาะโอลันด์',
    countryNameEn: 'Åland Islands',
  },
  {
    countryCode: 'ALB',
    countryNameTh: 'แอลเบเนีย',
    countryNameEn: 'Albania',
  },
  {
    countryCode: 'AND',
    countryNameTh: 'อันดอร์รา',
    countryNameEn: 'Andorra',
  },
  {
    countryCode: 'ANT',
    countryNameTh: 'เนเธอแลนด์แอนทิลลิส',
    countryNameEn: 'Netherlands Antilles',
  },
  {
    countryCode: 'ARE',
    countryNameTh: 'สหรัฐอาหรับเอมิเรตส์',
    countryNameEn: 'United Arab Emirates',
  },
  {
    countryCode: 'ARG',
    countryNameTh: 'อาร์เจตินา',
    countryNameEn: 'Argentina',
  },
  {
    countryCode: 'ARM',
    countryNameTh: 'อาร์เมเนีย',
    countryNameEn: 'Armenia',
  },
  {
    countryCode: 'ASM',
    countryNameTh: 'อเมริกันซามัว',
    countryNameEn: 'American Samoa',
  },
  {
    countryCode: 'ATA',
    countryNameTh: 'แอนตาร์กติกา',
    countryNameEn: 'Antarctica',
  },
  {
    countryCode: 'ATF',
    countryNameTh: 'ดินแดนเฟรนช์เซาเทิร์นและแอนตาร์กติกแลนส์',
    countryNameEn: 'French Southern and Antarctic Lands',
  },
  {
    countryCode: 'ATG',
    countryNameTh: 'แอนติกาและบาร์บูดา',
    countryNameEn: 'Antigua and Barbuda',
  },
  {
    countryCode: 'AUS',
    countryNameTh: 'ออสเตรเลีย',
    countryNameEn: 'Australia',
  },
  {
    countryCode: 'AUT',
    countryNameTh: 'ออสเตรีย',
    countryNameEn: 'Austria',
  },
  {
    countryCode: 'AZE',
    countryNameTh: 'อาร์เซอร์ไบจาน',
    countryNameEn: 'Azerbaijan',
  },
  {
    countryCode: 'BDI',
    countryNameTh: 'บุรุนดี',
    countryNameEn: 'Burundi',
  },
  {
    countryCode: 'BEL',
    countryNameTh: 'เบลเยี่ยม',
    countryNameEn: 'Belgium',
  },
  {
    countryCode: 'BEN',
    countryNameTh: 'เบนิน',
    countryNameEn: 'Benin',
  },
  {
    countryCode: 'BES',
    countryNameTh: 'โบแนเรอซินต์เอิสตาซียึสและซาบา',
    countryNameEn: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    countryCode: 'BFA',
    countryNameTh: 'บูร์กินาฟาโซ',
    countryNameEn: 'Burkina Faso',
  },
  {
    countryCode: 'BGD',
    countryNameTh: 'บังกลาเทศ',
    countryNameEn: 'Bangladesh',
  },
  {
    countryCode: 'BGR',
    countryNameTh: 'บัลแกเรีย',
    countryNameEn: 'Bulgaria',
  },
  {
    countryCode: 'BHR',
    countryNameTh: 'บาห์เรน',
    countryNameEn: 'Bahrain',
  },
  {
    countryCode: 'BHS',
    countryNameTh: 'บาฮามาส',
    countryNameEn: 'Bahamas',
  },
  {
    countryCode: 'BIH',
    countryNameTh: 'บอสเนียและเฮอร์เซโกวีนา',
    countryNameEn: 'Bosnia and Herzegovina',
  },
  {
    countryCode: 'BLM',
    countryNameTh: 'แซ็งบาร์เตเลมี',
    countryNameEn: 'Saint Barthélemy',
  },
  {
    countryCode: 'BLR',
    countryNameTh: 'เบลารุส',
    countryNameEn: 'Belarus',
  },
  {
    countryCode: 'BLZ',
    countryNameTh: 'เบลีซ์',
    countryNameEn: 'Belize',
  },
  {
    countryCode: 'BMU',
    countryNameTh: 'เบอร์มิวดา',
    countryNameEn: 'Bermuda',
  },
  {
    countryCode: 'BOL',
    countryNameTh: 'รัฐพหุชนชาติแห่งโบลิเวีย',
    countryNameEn: 'Plurinational State of Bolivia',
  },
  {
    countryCode: 'BRA',
    countryNameTh: 'บราซิล',
    countryNameEn: 'Brazil',
  },
  {
    countryCode: 'BRB',
    countryNameTh: 'บาร์เบโดส',
    countryNameEn: 'Barbados',
  },
  {
    countryCode: 'BRN',
    countryNameTh: 'เนการาบรูไนคารุสซาลาม',
    countryNameEn: 'Negara Brunei Darussalam',
  },
  {
    countryCode: 'BTN',
    countryNameTh: 'ภูฏาน',
    countryNameEn: 'Bhutan',
  },
  {
    countryCode: 'BVT',
    countryNameTh: 'เกาะยูเว',
    countryNameEn: 'Bouvet Island',
  },
  {
    countryCode: 'BWA',
    countryNameTh: 'บอตสวานา',
    countryNameEn: 'Botswana',
  },
  {
    countryCode: 'CAF',
    countryNameTh: 'สาธารณรัฐแอฟริกากลาง',
    countryNameEn: 'Central African Republic',
  },
  {
    countryCode: 'CAN',
    countryNameTh: 'แคนาดา',
    countryNameEn: 'Canada',
  },
  {
    countryCode: 'CCK',
    countryNameTh: 'ดินแดนหมู่เกาะโคโคส(คีลิง)',
    countryNameEn: 'The Territory of Cocos (Keeling) Islands',
  },
  {
    countryCode: 'CHE',
    countryNameTh: 'สวิตเซอร์แลนด์',
    countryNameEn: 'Switzerland',
  },
  {
    countryCode: 'CHL',
    countryNameTh: 'ชิลี',
    countryNameEn: 'Chile',
  },
  {
    countryCode: 'CHN',
    countryNameTh: 'จีน',
    countryNameEn: 'China',
  },
  {
    countryCode: 'CIV',
    countryNameTh: 'โกตดิวัวร์',
    countryNameEn: "Côte d'Ivoire",
  },
  {
    countryCode: 'CMR',
    countryNameTh: 'แคเมอรูน',
    countryNameEn: 'Cameroon',
  },
  {
    countryCode: 'COD',
    countryNameTh: 'สาธารณรัฐประชาธิปไตยคองโก',
    countryNameEn: 'The Democratic Republic of the Congo',
  },
  {
    countryCode: 'COG',
    countryNameTh: 'คองโก',
    countryNameEn: 'Congo',
  },
  {
    countryCode: 'COK',
    countryNameTh: 'หมู่เกาะคุก',
    countryNameEn: 'Cook Islands',
  },
  {
    countryCode: 'COL',
    countryNameTh: 'โคลอมเบีย',
    countryNameEn: 'Colombia',
  },
  {
    countryCode: 'COM',
    countryNameTh: 'คอโมโรส',
    countryNameEn: 'Comoros',
  },
  {
    countryCode: 'CPV',
    countryNameTh: 'กาบูว์ดี',
    countryNameEn: 'Cabo Verde',
  },
  {
    countryCode: 'CRI',
    countryNameTh: 'คอสตาริกา',
    countryNameEn: 'Costa Rica',
  },
  {
    countryCode: 'CUB',
    countryNameTh: 'คิวบา',
    countryNameEn: 'Cuba',
  },
  {
    countryCode: 'CUW',
    countryNameTh: 'กือราเซา/กอร์ซอว์',
    countryNameEn: 'Curaçao',
  },
  {
    countryCode: 'CXR',
    countryNameTh: 'เกาะคริสต์มาส',
    countryNameEn: 'Christmas Island',
  },
  {
    countryCode: 'CYM',
    countryNameTh: 'หมู่เกาะเคย์แมน',
    countryNameEn: 'Cayman Islands',
  },
  {
    countryCode: 'CYP',
    countryNameTh: 'ไซปรัส',
    countryNameEn: 'Cyprus',
  },
  {
    countryCode: 'CZE',
    countryNameTh: 'สาธารณรัฐเช็ก',
    countryNameEn: 'Czech Republic',
  },
  {
    countryCode: 'DEU',
    countryNameTh: 'เยอรมนี',
    countryNameEn: 'Germany',
  },
  {
    countryCode: 'DJI',
    countryNameTh: 'จิบูตี',
    countryNameEn: 'Djibouti',
  },
  {
    countryCode: 'DMA',
    countryNameTh: 'โดมินิกา',
    countryNameEn: 'Dominica',
  },
  {
    countryCode: 'DNK',
    countryNameTh: 'เดนมาร์ก',
    countryNameEn: 'Danmark',
  },
  {
    countryCode: 'DOM',
    countryNameTh: 'สหรัสโดมิกัน',
    countryNameEn: 'Dominican Republic',
  },
  {
    countryCode: 'DZA',
    countryNameTh: 'แอลจีเรีย',
    countryNameEn: 'Algeria',
  },
  {
    countryCode: 'ECU',
    countryNameTh: 'เอกวาดอร์',
    countryNameEn: 'Ecuador',
  },
  {
    countryCode: 'EGY',
    countryNameTh: 'อียิปต์',
    countryNameEn: 'Egypt',
  },
  {
    countryCode: 'ERI',
    countryNameTh: 'เอริเทรีย',
    countryNameEn: 'Eritrea',
  },
  {
    countryCode: 'ESH',
    countryNameTh: 'เวสเทิร์นสะฮารา',
    countryNameEn: 'Western Sahara',
  },
  {
    countryCode: 'ESP',
    countryNameTh: 'สเปน',
    countryNameEn: 'Spain',
  },
  {
    countryCode: 'EST',
    countryNameTh: 'เอสโตเนีย',
    countryNameEn: 'Estonia',
  },
  {
    countryCode: 'ETH',
    countryNameTh: 'เอธิโอเปีย',
    countryNameEn: 'Ethiopia',
  },
  {
    countryCode: 'FIN',
    countryNameTh: 'ฟินแลนด์',
    countryNameEn: 'Finland',
  },
  {
    countryCode: 'FJI',
    countryNameTh: 'ฟิจิ',
    countryNameEn: 'Fiji',
  },
  {
    countryCode: 'FLK',
    countryNameTh: 'หมู่เกาะฟอล์กแลนด์ (มัลวีนัส)',
    countryNameEn: 'Falkland Islands (Malvinas)',
  },
  {
    countryCode: 'FRA',
    countryNameTh: 'ฝรั่งเศส',
    countryNameEn: 'France',
  },
  {
    countryCode: 'FRO',
    countryNameTh: 'หมู่เกาะแฟโร',
    countryNameEn: 'Faroe Islands',
  },
  {
    countryCode: 'FSM',
    countryNameTh: 'ไมโครนีเซีย',
    countryNameEn: 'Micronesia',
  },
  {
    countryCode: 'GAB',
    countryNameTh: 'กาบอง',
    countryNameEn: 'Gabon',
  },
  {
    countryCode: 'GBR',
    countryNameTh: 'สหราชอาณาจักร',
    countryNameEn: 'United Kingdom',
  },
  {
    countryCode: 'GEO',
    countryNameTh: 'จอร์เจีย',
    countryNameEn: 'Georgia',
  },
  {
    countryCode: 'GGY',
    countryNameTh: 'เกิร์นซีร์',
    countryNameEn: 'Guernsey',
  },
  {
    countryCode: 'GHA',
    countryNameTh: 'กานา',
    countryNameEn: 'Ghana',
  },
  {
    countryCode: 'GIB',
    countryNameTh: 'ยิบรอลตาร์',
    countryNameEn: 'Gibraltar',
  },
  {
    countryCode: 'GIN',
    countryNameTh: 'กินี',
    countryNameEn: 'Guinea',
  },
  {
    countryCode: 'GLP',
    countryNameTh: 'กวาเดอลูป',
    countryNameEn: 'Guadeloupe',
  },
  {
    countryCode: 'GMB',
    countryNameTh: 'แกมเบีย',
    countryNameEn: 'Gambia',
  },
  {
    countryCode: 'GNB',
    countryNameTh: 'กินีบิสเซา',
    countryNameEn: 'Guinea-Bissau',
  },
  {
    countryCode: 'GNQ',
    countryNameTh: 'อิเควทอเรียลกินี',
    countryNameEn: 'Equatorial Guinea',
  },
  {
    countryCode: 'GRC',
    countryNameTh: 'กรีซ',
    countryNameEn: 'Greece',
  },
  {
    countryCode: 'GRD',
    countryNameTh: 'เกรเนดา',
    countryNameEn: 'Grenada',
  },
  {
    countryCode: 'GRL',
    countryNameTh: 'กรีนแลนด์',
    countryNameEn: 'Greenland',
  },
  {
    countryCode: 'GTM',
    countryNameTh: 'กัวเตมาลา',
    countryNameEn: 'Guatemala',
  },
  {
    countryCode: 'GUF',
    countryNameTh: 'เฟรนช์เกียนา',
    countryNameEn: 'French Guiana',
  },
  {
    countryCode: 'GUM',
    countryNameTh: 'กวม',
    countryNameEn: 'Guam',
  },
  {
    countryCode: 'GUY',
    countryNameTh: 'กายอานา',
    countryNameEn: 'Guyana',
  },
  {
    countryCode: 'HKG',
    countryNameTh: 'ฮ่องกง',
    countryNameEn: 'Hong Kong',
  },
  {
    countryCode: 'HMD',
    countryNameTh: 'หมู่เกาะเฮิร์ดและหมู่เกาะแมกดอนัลด์',
    countryNameEn: 'Heard and McDonald Islands',
  },
  {
    countryCode: 'HND',
    countryNameTh: 'ฮอนดูรัส',
    countryNameEn: 'Honduras',
  },
  {
    countryCode: 'HRV',
    countryNameTh: 'โครเอเซีย',
    countryNameEn: 'Croatia',
  },
  {
    countryCode: 'HTI',
    countryNameTh: 'เฮติ',
    countryNameEn: 'Haiti',
  },
  {
    countryCode: 'HUN',
    countryNameTh: 'ฮังการี',
    countryNameEn: 'Hungary',
  },
  {
    countryCode: 'IDN',
    countryNameTh: 'อินโดนีเซีย',
    countryNameEn: 'Indonesia',
  },
  {
    countryCode: 'IMN',
    countryNameTh: 'เกาะแมน',
    countryNameEn: 'Isle of Man',
  },
  {
    countryCode: 'IND',
    countryNameTh: 'อินเดีย',
    countryNameEn: 'India',
  },
  {
    countryCode: 'IOT',
    countryNameTh: 'บริติชอินเดียนโอเชียนเทร์ริทอรี',
    countryNameEn: 'British Indian Ocean Territory',
  },
  {
    countryCode: 'IRL',
    countryNameTh: 'ไอร์แลนด์',
    countryNameEn: 'Ireland',
  },
  {
    countryCode: 'IRN',
    countryNameTh: 'สาธารณรัฐอิสลามอิหร่าน',
    countryNameEn: 'Islamic Republic of Iran',
  },
  {
    countryCode: 'IRQ',
    countryNameTh: 'อิรัก',
    countryNameEn: 'Iraq',
  },
  {
    countryCode: 'ISL',
    countryNameTh: 'ไอซ์แลนด์',
    countryNameEn: 'Iceland',
  },
  {
    countryCode: 'ISR',
    countryNameTh: 'อิสราเอล',
    countryNameEn: 'Israel',
  },
  {
    countryCode: 'ITA',
    countryNameTh: 'อิตาลี',
    countryNameEn: 'Italy',
  },
  {
    countryCode: 'JAM',
    countryNameTh: 'จาเมกา',
    countryNameEn: 'Jamaica',
  },
  {
    countryCode: 'JEY',
    countryNameTh: 'เจอร์ซีย์',
    countryNameEn: 'Jersey',
  },
  {
    countryCode: 'JOR',
    countryNameTh: 'จอร์แดน',
    countryNameEn: 'Jordan',
  },
  {
    countryCode: 'JPN',
    countryNameTh: 'ญี่ปุ่น',
    countryNameEn: 'Japan',
  },
  {
    countryCode: 'KAZ',
    countryNameTh: 'คาซัคสถาน',
    countryNameEn: 'Kazakhstan',
  },
  {
    countryCode: 'KEN',
    countryNameTh: 'เคนยา',
    countryNameEn: 'Kenya',
  },
  {
    countryCode: 'KGZ',
    countryNameTh: 'คีร์กีซสถาน',
    countryNameEn: 'Kyrgyzstan',
  },
  {
    countryCode: 'KHM',
    countryNameTh: 'กัมพูชา',
    countryNameEn: 'Cambodia',
  },
  {
    countryCode: 'KIR',
    countryNameTh: 'คิริบาตี',
    countryNameEn: 'Kiribati',
  },
  {
    countryCode: 'KNA',
    countryNameTh: 'เซนต์ศิตส์และเนวิส',
    countryNameEn: 'Saint Kitts and Nevis',
  },
  {
    countryCode: 'KOR',
    countryNameTh: 'เกาหลีใต้',
    countryNameEn: 'Republic of Korea',
  },
  {
    countryCode: 'KWT',
    countryNameTh: 'คูเวต',
    countryNameEn: 'Kuwait',
  },
  {
    countryCode: 'LAO',
    countryNameTh: 'สาธารณรัฐประชาธิปไตยประชาชนลาว',
    countryNameEn: "Lao People's Democratic Republic",
  },
  {
    countryCode: 'LBN',
    countryNameTh: 'เลบานอน',
    countryNameEn: 'Lebanon',
  },
  {
    countryCode: 'LBR',
    countryNameTh: 'ไลบีเรีย',
    countryNameEn: 'Liberia',
  },
  {
    countryCode: 'LBY',
    countryNameTh: 'ลิเบีย',
    countryNameEn: 'Libya',
  },
  {
    countryCode: 'LCA',
    countryNameTh: 'เซนต์ลูเซีย',
    countryNameEn: 'Saint Lucia',
  },
  {
    countryCode: 'LIE',
    countryNameTh: 'ลิกเตนส์ไตน์',
    countryNameEn: 'Liechtenstein',
  },
  {
    countryCode: 'LKA',
    countryNameTh: 'ศรีลังกา',
    countryNameEn: 'Sri Lanka',
  },
  {
    countryCode: 'LSO',
    countryNameTh: 'เลโซโท',
    countryNameEn: 'Lesotho',
  },
  {
    countryCode: 'LTU',
    countryNameTh: 'ลิทัวเนีย',
    countryNameEn: 'Lithuania',
  },
  {
    countryCode: 'LUX',
    countryNameTh: 'ลักเซมเบิร์ก',
    countryNameEn: 'Luxembourg',
  },
  {
    countryCode: 'LVA',
    countryNameTh: 'ลัตเวีย',
    countryNameEn: 'Latvia',
  },
  {
    countryCode: 'MAC',
    countryNameTh: 'มาเก๊า',
    countryNameEn: 'Macao',
  },
  {
    countryCode: 'MAF',
    countryNameTh: 'แซ็ง-มาร์แต็ง',
    countryNameEn: 'Saint Martin (French part)',
  },
  {
    countryCode: 'MAR',
    countryNameTh: 'โมร็อกโก',
    countryNameEn: 'Morocco',
  },
  {
    countryCode: 'MCO',
    countryNameTh: 'โมนาโก',
    countryNameEn: 'Monaco',
  },
  {
    countryCode: 'MDA',
    countryNameTh: 'สาธารณรัฐมอลโดวา',
    countryNameEn: 'Republic of Moldova',
  },
  {
    countryCode: 'MDG',
    countryNameTh: 'มาดากัสการ์',
    countryNameEn: 'Madagascar',
  },
  {
    countryCode: 'MDV',
    countryNameTh: 'มัลดีฟส์',
    countryNameEn: 'Maldives',
  },
  {
    countryCode: 'MEX',
    countryNameTh: 'เม็กซิโก',
    countryNameEn: 'Mexico',
  },
  {
    countryCode: 'MHL',
    countryNameTh: 'หมู่เกาะมาร์แซลล์',
    countryNameEn: 'Marshall Islands',
  },
  {
    countryCode: 'MKD',
    countryNameTh: 'สาธารณรัฐมาซิโดเนีย',
    countryNameEn: 'Republic of Macedonia',
  },
  {
    countryCode: 'MLI',
    countryNameTh: 'มาลี',
    countryNameEn: 'Mali',
  },
  {
    countryCode: 'MLT',
    countryNameTh: 'มอลตา',
    countryNameEn: 'Malta',
  },
  {
    countryCode: 'MMR',
    countryNameTh: 'พม่า',
    countryNameEn: 'Myanmar',
  },
  {
    countryCode: 'MNE',
    countryNameTh: 'มอนเตเนโกร',
    countryNameEn: 'Montenegro',
  },
  {
    countryCode: 'MNG',
    countryNameTh: 'มองโกเลีย',
    countryNameEn: 'Mongolia',
  },
  {
    countryCode: 'MNP',
    countryNameTh: 'หมู่เกาะนอร์เทิร์นมาเรียนา',
    countryNameEn: 'Northern Mariana Islands',
  },
  {
    countryCode: 'MOZ',
    countryNameTh: 'โมซัมบิก',
    countryNameEn: 'Mozambique',
  },
  {
    countryCode: 'MRT',
    countryNameTh: 'มอริเตเนีย',
    countryNameEn: 'Mauritania',
  },
  {
    countryCode: 'MSR',
    countryNameTh: 'มอนต์เซอร์รัต',
    countryNameEn: 'Montserrat',
  },
  {
    countryCode: 'MTQ',
    countryNameTh: 'มาร์ตินิก',
    countryNameEn: 'Martinique',
  },
  {
    countryCode: 'MUS',
    countryNameTh: 'เมริเซียส',
    countryNameEn: 'Mauritius',
  },
  {
    countryCode: 'MWI',
    countryNameTh: 'สาธารณรัฐมาลาวี',
    countryNameEn: 'Republic of Malawi',
  },
  {
    countryCode: 'MYS',
    countryNameTh: 'มาเลเซีย',
    countryNameEn: 'Malaysia',
  },
  {
    countryCode: 'MYT',
    countryNameTh: 'มายอต',
    countryNameEn: 'Mayotte',
  },
  {
    countryCode: 'NAM',
    countryNameTh: 'นามิเบีย',
    countryNameEn: 'Namibia',
  },
  {
    countryCode: 'NCL',
    countryNameTh: 'นิวแคลิโดเนีย',
    countryNameEn: 'New Caledonia',
  },
  {
    countryCode: 'NER',
    countryNameTh: 'ไนเจอร์',
    countryNameEn: 'Niger',
  },
  {
    countryCode: 'NFK',
    countryNameTh: 'เกาะนอร์ฟอล์ก',
    countryNameEn: 'Norfolk Island',
  },
  {
    countryCode: 'NGA',
    countryNameTh: 'ไนจีเรีย',
    countryNameEn: 'Nigeria',
  },
  {
    countryCode: 'NIC',
    countryNameTh: 'นิการากัว',
    countryNameEn: 'Nicaragua',
  },
  {
    countryCode: 'NIU',
    countryNameTh: 'นีอูเอ',
    countryNameEn: 'Niue',
  },
  {
    countryCode: 'NLD',
    countryNameTh: 'เนเธอแลนด์',
    countryNameEn: 'Netherlands',
  },
  {
    countryCode: 'NOR',
    countryNameTh: 'นอร์เวย์',
    countryNameEn: 'Norway',
  },
  {
    countryCode: 'NPL',
    countryNameTh: 'สหพันธ์สาธารณประชาชาธิปไตยเนปาล',
    countryNameEn: 'Federal Democratic Republic of Nepal',
  },
  {
    countryCode: 'NRU',
    countryNameTh: 'นาอูรู',
    countryNameEn: 'Nauru',
  },
  {
    countryCode: 'NZL',
    countryNameTh: 'นิวซีแลนด์',
    countryNameEn: 'New Zealand',
  },
  {
    countryCode: 'OMN',
    countryNameTh: 'โอมาน',
    countryNameEn: 'Oman',
  },
  {
    countryCode: 'PAK',
    countryNameTh: 'ปากีสถาน',
    countryNameEn: 'Pakistan',
  },
  {
    countryCode: 'PAN',
    countryNameTh: 'ปานามา',
    countryNameEn: 'Panama',
  },
  {
    countryCode: 'PCN',
    countryNameTh: 'หมู่เกาะพิตแคร์น',
    countryNameEn: 'Pitcairn Islands',
  },
  {
    countryCode: 'PER',
    countryNameTh: 'เปรู',
    countryNameEn: 'Peru',
  },
  {
    countryCode: 'PHL',
    countryNameTh: 'ฟิลิปปินส์',
    countryNameEn: 'Philippines',
  },
  {
    countryCode: 'PLW',
    countryNameTh: 'ปาเลา',
    countryNameEn: 'Palau',
  },
  {
    countryCode: 'PNG',
    countryNameTh: 'ปาปัวนิวกินี',
    countryNameEn: 'Papua New Guinea',
  },
  {
    countryCode: 'POL',
    countryNameTh: 'สาธารณรัฐโปแลนด์',
    countryNameEn: 'Republic of Poland',
  },
  {
    countryCode: 'PRI',
    countryNameTh: 'เปอร์โตริโก',
    countryNameEn: 'Puerto Rico',
  },
  {
    countryCode: 'PRK',
    countryNameTh: 'เกาหลีเหนือ',
    countryNameEn: "Democratic People's Republic of Korea",
  },
  {
    countryCode: 'PRT',
    countryNameTh: 'โปรตุเกส',
    countryNameEn: 'Portugal',
  },
  {
    countryCode: 'PRY',
    countryNameTh: 'ปารากวัย',
    countryNameEn: 'Paraguay',
  },
  {
    countryCode: 'PSE',
    countryNameTh: 'รัฐปาเลสไตน์',
    countryNameEn: 'State of Palestine',
  },
  {
    countryCode: 'PYF',
    countryNameTh: 'เฟรนช์โปลินีเซีย',
    countryNameEn: 'French Polynesia',
  },
  {
    countryCode: 'QAT',
    countryNameTh: 'กาตาร์',
    countryNameEn: 'Qatar',
  },
  {
    countryCode: 'REU',
    countryNameTh: 'เรอูเนียง',
    countryNameEn: 'Réunion',
  },
  {
    countryCode: 'ROU',
    countryNameTh: 'โรมาเนีย',
    countryNameEn: 'Romania',
  },
  {
    countryCode: 'RUS',
    countryNameTh: 'สหพันธรัฐรัสเซีย',
    countryNameEn: 'Russian Federation',
  },
  {
    countryCode: 'RWA',
    countryNameTh: 'รวันดา',
    countryNameEn: 'Rwanda',
  },
  {
    countryCode: 'SAU',
    countryNameTh: 'ซาอุดิอาระเบีย',
    countryNameEn: 'Saudi Arabia',
  },
  {
    countryCode: 'SDN',
    countryNameTh: 'ซูดาน',
    countryNameEn: 'Sudan',
  },
  {
    countryCode: 'SEN',
    countryNameTh: 'เซเนกัล',
    countryNameEn: 'Senegal',
  },
  {
    countryCode: 'SGP',
    countryNameTh: 'สิงคโปร์',
    countryNameEn: 'Singapore',
  },
  {
    countryCode: 'SGS',
    countryNameTh: 'เกาะเซาท์จอร์เจียและหมู่เกาะเซาท์แซนด์วิช',
    countryNameEn: 'South Georgia and the South Sandwich Islands',
  },
  {
    countryCode: 'SHN',
    countryNameTh: 'เซนต์เฮเลนา',
    countryNameEn: 'Saint Helena',
  },
  {
    countryCode: 'SJM',
    countryNameTh: 'สฟาลบาร์และยานไมเอน',
    countryNameEn: 'Svalbard and Jan Mayen',
  },
  {
    countryCode: 'SLB',
    countryNameTh: 'หมู่เกาะโซโลมอน',
    countryNameEn: 'Solomon Islands',
  },
  {
    countryCode: 'SLE',
    countryNameTh: 'เซียร์ราลีโอน',
    countryNameEn: 'Sierra Leone',
  },
  {
    countryCode: 'SLV',
    countryNameTh: 'เอลซัลวาดอร์',
    countryNameEn: 'El Salvador',
  },
  {
    countryCode: 'SMR',
    countryNameTh: 'ซานมารีโน',
    countryNameEn: 'San Marino',
  },
  {
    countryCode: 'SOM',
    countryNameTh: 'โซมาเลีย',
    countryNameEn: 'Somalia',
  },
  {
    countryCode: 'SPM',
    countryNameTh: 'แซงปีแยร์และมีเกอลง',
    countryNameEn: 'Saint Pierre and Miquelon',
  },
  {
    countryCode: 'SRB',
    countryNameTh: 'เซอร์เบีย',
    countryNameEn: 'Serbia',
  },
  {
    countryCode: 'SSD',
    countryNameTh: 'ซูดานใต้',
    countryNameEn: 'South Sudan',
  },
  {
    countryCode: 'STP',
    countryNameTh: 'เซาตูเมและปรินซิปี',
    countryNameEn: 'Sao Tome and Principe',
  },
  {
    countryCode: 'SUR',
    countryNameTh: 'ซูรินาเม',
    countryNameEn: 'Suriname',
  },
  {
    countryCode: 'SVK',
    countryNameTh: 'สโลวะเกีย',
    countryNameEn: 'Slovakia',
  },
  {
    countryCode: 'SVN',
    countryNameTh: 'สโลวีเนีย',
    countryNameEn: 'Slovenia',
  },
  {
    countryCode: 'SWE',
    countryNameTh: 'สวีเดน',
    countryNameEn: 'Sweden',
  },
  {
    countryCode: 'SWZ',
    countryNameTh: 'สวาซิแลนด์',
    countryNameEn: 'Swaziland',
  },
  {
    countryCode: 'SXM',
    countryNameTh: 'ชินต์มาร์เติน',
    countryNameEn: 'Sint Maarten (Dutch part)',
  },
  {
    countryCode: 'SYC',
    countryNameTh: 'เซเซลล์',
    countryNameEn: 'Seychelles',
  },
  {
    countryCode: 'SYR',
    countryNameTh: 'สาธารณรัฐอาหรับซีเรีย',
    countryNameEn: 'Syrian Arab Republic',
  },
  {
    countryCode: 'TCA',
    countryNameTh: 'หมู่เกาะเติร์กและหมู่เกาะเคลอส',
    countryNameEn: 'Turks and Caicos Islands',
  },
  {
    countryCode: 'TCD',
    countryNameTh: 'ชาด',
    countryNameEn: 'Tchad',
  },
  {
    countryCode: 'TGO',
    countryNameTh: 'โตโก',
    countryNameEn: 'Togo',
  },
  {
    countryCode: 'THA',
    countryNameTh: 'ไทย',
    countryNameEn: 'Thailand',
  },
  {
    countryCode: 'TJK',
    countryNameTh: 'ทาจิกิสถาน',
    countryNameEn: 'Tajikistan',
  },
  {
    countryCode: 'TKL',
    countryNameTh: 'โตเกเลา',
    countryNameEn: 'Tokelau',
  },
  {
    countryCode: 'TKM',
    countryNameTh: 'เติร์กเมนิสถาน',
    countryNameEn: 'Turkmenistan',
  },
  {
    countryCode: 'TLS',
    countryNameTh: 'ติมอร์-เลสเต',
    countryNameEn: 'Timor-Leste',
  },
  {
    countryCode: 'TON',
    countryNameTh: 'ตองกา',
    countryNameEn: 'Tonga',
  },
  {
    countryCode: 'TTO',
    countryNameTh: 'ตรินิแดดและโตเบโก',
    countryNameEn: 'Trinidad and Tobago',
  },
  {
    countryCode: 'TUN',
    countryNameTh: 'ตูนิเซีย',
    countryNameEn: 'Tunisia',
  },
  {
    countryCode: 'TUR',
    countryNameTh: 'ตุรกี',
    countryNameEn: 'Turkey',
  },
  {
    countryCode: 'TUV',
    countryNameTh: 'ตูวาลู',
    countryNameEn: 'Tuvalu',
  },
  {
    countryCode: 'TWN',
    countryNameTh: 'ไต้หวันสาธารณรัฐจีน',
    countryNameEn: 'Taiwan, Republic of China',
  },
  {
    countryCode: 'TZA',
    countryNameTh: 'สหสาธารณรัฐแทนซาเนีย',
    countryNameEn: 'United Republic of Tanzania',
  },
  {
    countryCode: 'UGA',
    countryNameTh: 'ยูกันดา',
    countryNameEn: 'Uganda',
  },
  {
    countryCode: 'UKR',
    countryNameTh: 'ยูเครน',
    countryNameEn: 'Ukraine',
  },
  {
    countryCode: 'UMI',
    countryNameTh: 'เกาะเล็กรอบนอกของสหรัฐอเมริกา',
    countryNameEn: 'United States Minor Outlying Islands',
  },
  {
    countryCode: 'URY',
    countryNameTh: 'อุรุกวัย',
    countryNameEn: 'Uruguay',
  },
  {
    countryCode: 'USA',
    countryNameTh: 'สหรัฐอเมริกา',
    countryNameEn: 'United States of America',
  },
  {
    countryCode: 'UZB',
    countryNameTh: 'อุซเบกิสถาน',
    countryNameEn: 'Uzbekistan',
  },
  {
    countryCode: 'VAT',
    countryNameTh: 'นครรัฐวาติกัน',
    countryNameEn: 'State of the Vatican City',
  },
  {
    countryCode: 'VCT',
    countryNameTh: 'เซนต์วินเซนต์และเกรนาดีนส์',
    countryNameEn: 'Saint Vincent and the Grenadines',
  },
  {
    countryCode: 'VEN',
    countryNameTh: 'สาธารณรัฐโบลีวาร์แห่งเวเนซุเอลา',
    countryNameEn: 'Bolivarian Republic of Venezuela',
  },
  {
    countryCode: 'VGB',
    countryNameTh: 'หมู่เกาะบริติซเวอร์จิน',
    countryNameEn: 'British Virgin Islands',
  },
  {
    countryCode: 'VIR',
    countryNameTh: 'หมู่เกาะเวอร์จินของสหรัฐอเมริกา',
    countryNameEn: 'United States Virgin Islands,USVI',
  },
  {
    countryCode: 'VNM',
    countryNameTh: 'เวียดนาม',
    countryNameEn: 'Viet Nam',
  },
  {
    countryCode: 'VUT',
    countryNameTh: 'วานูอาตู',
    countryNameEn: 'Vanuatu',
  },
  {
    countryCode: 'WLF',
    countryNameTh: 'วาลลิสและฟูตูนา',
    countryNameEn: 'Wallis and Futuna',
  },
  {
    countryCode: 'WSM',
    countryNameTh: 'ซามัว',
    countryNameEn: 'Samoa',
  },
  {
    countryCode: 'YEM',
    countryNameTh: 'เยเมน',
    countryNameEn: 'Yemen',
  },
  {
    countryCode: 'ZAF',
    countryNameTh: 'สาธารณรัฐแอฟริกาใต้',
    countryNameEn: 'Republic of South Africa',
  },
  {
    countryCode: 'ZMB',
    countryNameTh: 'แซมเบีย',
    countryNameEn: 'Zambia',
  },
  {
    countryCode: 'ZWE',
    countryNameTh: 'ซิมบับเว',
    countryNameEn: 'Zimbabwe',
  },
];

export const countryCodeAlpha2 = [
  {
    countryCode: 'AW',
    countryNameTh: 'อารูบา',
    countryNameEn: 'Aruba',
  },
  {
    countryCode: 'AF',
    countryNameTh: 'อัฟกานิสถาน',
    countryNameEn: 'Afghanistan',
  },
  {
    countryCode: 'AO',
    countryNameTh: 'แองโกลา',
    countryNameEn: 'Angola',
  },
  {
    countryCode: 'AI',
    countryNameTh: 'แองกวิลลา',
    countryNameEn: 'Anguilla',
  },
  {
    countryCode: 'AX',
    countryNameTh: 'หมู่เกาะโอลันด์',
    countryNameEn: 'Åland Islands',
  },
  {
    countryCode: 'AL',
    countryNameTh: 'แอลเบเนีย',
    countryNameEn: 'Albania',
  },
  {
    countryCode: 'AD',
    countryNameTh: 'อันดอร์รา',
    countryNameEn: 'Andorra',
  },
  {
    countryCode: 'AN',
    countryNameTh: 'เนเธอแลนด์แอนทิลลิส',
    countryNameEn: 'Netherlands Antilles',
  },
  {
    countryCode: 'AE',
    countryNameTh: 'สหรัฐอาหรับเอมิเรตส์',
    countryNameEn: 'United Arab Emirates',
  },
  {
    countryCode: 'AR',
    countryNameTh: 'อาร์เจตินา',
    countryNameEn: 'Argentina',
  },
  {
    countryCode: 'AM',
    countryNameTh: 'อาร์เมเนีย',
    countryNameEn: 'Armenia',
  },
  {
    countryCode: 'AS',
    countryNameTh: 'อเมริกันซามัว',
    countryNameEn: 'American Samoa',
  },
  {
    countryCode: 'AQ',
    countryNameTh: 'แอนตาร์กติกา',
    countryNameEn: 'Antarctica',
  },
  {
    countryCode: 'TF',
    countryNameTh: 'ดินแดนเฟรนช์เซาเทิร์นและแอนตาร์กติกแลนส์',
    countryNameEn: 'French Southern and Antarctic Lands',
  },
  {
    countryCode: 'AG',
    countryNameTh: 'แอนติกาและบาร์บูดา',
    countryNameEn: 'Antigua and Barbuda',
  },
  {
    countryCode: 'AU',
    countryNameTh: 'ออสเตรเลีย',
    countryNameEn: 'Australia',
  },
  {
    countryCode: 'AT',
    countryNameTh: 'ออสเตรีย',
    countryNameEn: 'Austria',
  },
  {
    countryCode: 'AZ',
    countryNameTh: 'อาร์เซอร์ไบจาน',
    countryNameEn: 'Azerbaijan',
  },
  {
    countryCode: 'BI',
    countryNameTh: 'บุรุนดี',
    countryNameEn: 'Burundi',
  },
  {
    countryCode: 'BE',
    countryNameTh: 'เบลเยี่ยม',
    countryNameEn: 'Belgium',
  },
  {
    countryCode: 'BJ',
    countryNameTh: 'เบนิน',
    countryNameEn: 'Benin',
  },
  {
    countryCode: 'BQ',
    countryNameTh: 'โบแนเรอซินต์เอิสตาซียึสและซาบา',
    countryNameEn: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    countryCode: 'BF',
    countryNameTh: 'บูร์กินาฟาโซ',
    countryNameEn: 'Burkina Faso',
  },
  {
    countryCode: 'BD',
    countryNameTh: 'บังกลาเทศ',
    countryNameEn: 'Bangladesh',
  },
  {
    countryCode: 'BG',
    countryNameTh: 'บัลแกเรีย',
    countryNameEn: 'Bulgaria',
  },
  {
    countryCode: 'BH',
    countryNameTh: 'บาห์เรน',
    countryNameEn: 'Bahrain',
  },
  {
    countryCode: 'BS',
    countryNameTh: 'บาฮามาส',
    countryNameEn: 'Bahamas',
  },
  {
    countryCode: 'BA',
    countryNameTh: 'บอสเนียและเฮอร์เซโกวีนา',
    countryNameEn: 'Bosnia and Herzegovina',
  },
  {
    countryCode: 'BL',
    countryNameTh: 'แซ็งบาร์เตเลมี',
    countryNameEn: 'Saint Barthélemy',
  },
  {
    countryCode: 'BY',
    countryNameTh: 'เบลารุส',
    countryNameEn: 'Belarus',
  },
  {
    countryCode: 'BZ',
    countryNameTh: 'เบลีซ์',
    countryNameEn: 'Belize',
  },
  {
    countryCode: 'BM',
    countryNameTh: 'เบอร์มิวดา',
    countryNameEn: 'Bermuda',
  },
  {
    countryCode: 'BO',
    countryNameTh: 'รัฐพหุชนชาติแห่งโบลิเวีย',
    countryNameEn: 'Plurinational State of Bolivia',
  },
  {
    countryCode: 'BR',
    countryNameTh: 'บราซิล',
    countryNameEn: 'Brazil',
  },
  {
    countryCode: 'BB',
    countryNameTh: 'บาร์เบโดส',
    countryNameEn: 'Barbados',
  },
  {
    countryCode: 'BN',
    countryNameTh: 'เนการาบรูไนคารุสซาลาม',
    countryNameEn: 'Negara Brunei Darussalam',
  },
  {
    countryCode: 'BT',
    countryNameTh: 'ภูฏาน',
    countryNameEn: 'Bhutan',
  },
  {
    countryCode: 'BV',
    countryNameTh: 'เกาะยูเว',
    countryNameEn: 'Bouvet Island',
  },
  {
    countryCode: 'BW',
    countryNameTh: 'บอตสวานา',
    countryNameEn: 'Botswana',
  },
  {
    countryCode: 'CF',
    countryNameTh: 'สาธารณรัฐแอฟริกากลาง',
    countryNameEn: 'Central African Republic',
  },
  {
    countryCode: 'CA',
    countryNameTh: 'แคนาดา',
    countryNameEn: 'Canada',
  },
  {
    countryCode: 'CC',
    countryNameTh: 'ดินแดนหมู่เกาะโคโคส(คีลิง)',
    countryNameEn: 'The Territory of Cocos (Keeling) Islands',
  },
  {
    countryCode: 'CH',
    countryNameTh: 'สวิตเซอร์แลนด์',
    countryNameEn: 'Switzerland',
  },
  {
    countryCode: 'CL',
    countryNameTh: 'ชิลี',
    countryNameEn: 'Chile',
  },
  {
    countryCode: 'CN',
    countryNameTh: 'จีน',
    countryNameEn: 'China',
  },
  {
    countryCode: 'CI',
    countryNameTh: 'โกตดิวัวร์',
    countryNameEn: "Côte d'Ivoire",
  },
  {
    countryCode: 'CM',
    countryNameTh: 'แคเมอรูน',
    countryNameEn: 'Cameroon',
  },
  {
    countryCode: 'CD',
    countryNameTh: 'สาธารณรัฐประชาธิปไตยคองโก',
    countryNameEn: 'The Democratic Republic of the Congo',
  },
  {
    countryCode: 'CG',
    countryNameTh: 'คองโก',
    countryNameEn: 'Congo',
  },
  {
    countryCode: 'CK',
    countryNameTh: 'หมู่เกาะคุก',
    countryNameEn: 'Cook Islands',
  },
  {
    countryCode: 'CO',
    countryNameTh: 'โคลอมเบีย',
    countryNameEn: 'Colombia',
  },
  {
    countryCode: 'KM',
    countryNameTh: 'คอโมโรส',
    countryNameEn: 'Comoros',
  },
  {
    countryCode: 'CV',
    countryNameTh: 'กาบูว์ดี',
    countryNameEn: 'Cabo Verde',
  },
  {
    countryCode: 'CR',
    countryNameTh: 'คอสตาริกา',
    countryNameEn: 'Costa Rica',
  },
  {
    countryCode: 'CU',
    countryNameTh: 'คิวบา',
    countryNameEn: 'Cuba',
  },
  {
    countryCode: 'CW',
    countryNameTh: 'กือราเซา/กอร์ซอว์',
    countryNameEn: 'Curaçao',
  },
  {
    countryCode: 'CX',
    countryNameTh: 'เกาะคริสต์มาส',
    countryNameEn: 'Christmas Island',
  },
  {
    countryCode: 'KY',
    countryNameTh: 'หมู่เกาะเคย์แมน',
    countryNameEn: 'Cayman Islands',
  },
  {
    countryCode: 'CY',
    countryNameTh: 'ไซปรัส',
    countryNameEn: 'Cyprus',
  },
  {
    countryCode: 'CZ',
    countryNameTh: 'สาธารณรัฐเช็ก',
    countryNameEn: 'Czech Republic',
  },
  {
    countryCode: 'DE',
    countryNameTh: 'เยอรมนี',
    countryNameEn: 'Germany',
  },
  {
    countryCode: 'DJ',
    countryNameTh: 'จิบูตี',
    countryNameEn: 'Djibouti',
  },
  {
    countryCode: 'DM',
    countryNameTh: 'โดมินิกา',
    countryNameEn: 'Dominica',
  },
  {
    countryCode: 'DK',
    countryNameTh: 'เดนมาร์ก',
    countryNameEn: 'Danmark',
  },
  {
    countryCode: 'DO',
    countryNameTh: 'สหรัสโดมิกัน',
    countryNameEn: 'Dominican Republic',
  },
  {
    countryCode: 'DZ',
    countryNameTh: 'แอลจีเรีย',
    countryNameEn: 'Algeria',
  },
  {
    countryCode: 'EC',
    countryNameTh: 'เอกวาดอร์',
    countryNameEn: 'Ecuador',
  },
  {
    countryCode: 'EG',
    countryNameTh: 'อียิปต์',
    countryNameEn: 'Egypt',
  },
  {
    countryCode: 'ER',
    countryNameTh: 'เอริเทรีย',
    countryNameEn: 'Eritrea',
  },
  {
    countryCode: 'EH',
    countryNameTh: 'เวสเทิร์นสะฮารา',
    countryNameEn: 'Western Sahara',
  },
  {
    countryCode: 'ES',
    countryNameTh: 'สเปน',
    countryNameEn: 'Spain',
  },
  {
    countryCode: 'EE',
    countryNameTh: 'เอสโตเนีย',
    countryNameEn: 'Estonia',
  },
  {
    countryCode: 'ET',
    countryNameTh: 'เอธิโอเปีย',
    countryNameEn: 'Ethiopia',
  },
  {
    countryCode: 'FI',
    countryNameTh: 'ฟินแลนด์',
    countryNameEn: 'Finland',
  },
  {
    countryCode: 'FJ',
    countryNameTh: 'ฟิจิ',
    countryNameEn: 'Fiji',
  },
  {
    countryCode: 'FK',
    countryNameTh: 'หมู่เกาะฟอล์กแลนด์ (มัลวีนัส)',
    countryNameEn: 'Falkland Islands (Malvinas)',
  },
  {
    countryCode: 'FR',
    countryNameTh: 'ฝรั่งเศส',
    countryNameEn: 'France',
  },
  {
    countryCode: 'FO',
    countryNameTh: 'หมู่เกาะแฟโร',
    countryNameEn: 'Faroe Islands',
  },
  {
    countryCode: 'FM',
    countryNameTh: 'ไมโครนีเซีย',
    countryNameEn: 'Micronesia',
  },
  {
    countryCode: 'GA',
    countryNameTh: 'กาบอง',
    countryNameEn: 'Gabon',
  },
  {
    countryCode: 'GB',
    countryNameTh: 'สหราชอาณาจักร',
    countryNameEn: 'United Kingdom',
  },
  {
    countryCode: 'GE',
    countryNameTh: 'จอร์เจีย',
    countryNameEn: 'Georgia',
  },
  {
    countryCode: 'GG',
    countryNameTh: 'เกิร์นซีร์',
    countryNameEn: 'Guernsey',
  },
  {
    countryCode: 'GH',
    countryNameTh: 'กานา',
    countryNameEn: 'Ghana',
  },
  {
    countryCode: 'GI',
    countryNameTh: 'ยิบรอลตาร์',
    countryNameEn: 'Gibraltar',
  },
  {
    countryCode: 'GN',
    countryNameTh: 'กินี',
    countryNameEn: 'Guinea',
  },
  {
    countryCode: 'GP',
    countryNameTh: 'กวาเดอลูป',
    countryNameEn: 'Guadeloupe',
  },
  {
    countryCode: 'GM',
    countryNameTh: 'แกมเบีย',
    countryNameEn: 'Gambia',
  },
  {
    countryCode: 'GW',
    countryNameTh: 'กินีบิสเซา',
    countryNameEn: 'Guinea-Bissau',
  },
  {
    countryCode: 'GQ',
    countryNameTh: 'อิเควทอเรียลกินี',
    countryNameEn: 'Equatorial Guinea',
  },
  {
    countryCode: 'GR',
    countryNameTh: 'กรีซ',
    countryNameEn: 'Greece',
  },
  {
    countryCode: 'GD',
    countryNameTh: 'เกรเนดา',
    countryNameEn: 'Grenada',
  },
  {
    countryCode: 'GL',
    countryNameTh: 'กรีนแลนด์',
    countryNameEn: 'Greenland',
  },
  {
    countryCode: 'GT',
    countryNameTh: 'กัวเตมาลา',
    countryNameEn: 'Guatemala',
  },
  {
    countryCode: 'GF',
    countryNameTh: 'เฟรนช์เกียนา',
    countryNameEn: 'French Guiana',
  },
  {
    countryCode: 'GU',
    countryNameTh: 'กวม',
    countryNameEn: 'Guam',
  },
  {
    countryCode: 'GY',
    countryNameTh: 'กายอานา',
    countryNameEn: 'Guyana',
  },
  {
    countryCode: 'HK',
    countryNameTh: 'ฮ่องกง',
    countryNameEn: 'Hong Kong',
  },
  {
    countryCode: 'HM',
    countryNameTh: 'หมู่เกาะเฮิร์ดและหมู่เกาะแมกดอนัลด์',
    countryNameEn: 'Heard and McDonald Islands',
  },
  {
    countryCode: 'HN',
    countryNameTh: 'ฮอนดูรัส',
    countryNameEn: 'Honduras',
  },
  {
    countryCode: 'HR',
    countryNameTh: 'โครเอเซีย',
    countryNameEn: 'Croatia',
  },
  {
    countryCode: 'HT',
    countryNameTh: 'เฮติ',
    countryNameEn: 'Haiti',
  },
  {
    countryCode: 'HU',
    countryNameTh: 'ฮังการี',
    countryNameEn: 'Hungary',
  },
  {
    countryCode: 'ID',
    countryNameTh: 'อินโดนีเซีย',
    countryNameEn: 'Indonesia',
  },
  {
    countryCode: 'IM',
    countryNameTh: 'เกาะแมน',
    countryNameEn: 'Isle of Man',
  },
  {
    countryCode: 'IN',
    countryNameTh: 'อินเดีย',
    countryNameEn: 'India',
  },
  {
    countryCode: 'IO',
    countryNameTh: 'บริติชอินเดียนโอเชียนเทร์ริทอรี',
    countryNameEn: 'British Indian Ocean Territory',
  },
  {
    countryCode: 'IE',
    countryNameTh: 'ไอร์แลนด์',
    countryNameEn: 'Ireland',
  },
  {
    countryCode: 'IR',
    countryNameTh: 'สาธารณรัฐอิสลามอิหร่าน',
    countryNameEn: 'Islamic Republic of Iran',
  },
  {
    countryCode: 'IQ',
    countryNameTh: 'อิรัก',
    countryNameEn: 'Iraq',
  },
  {
    countryCode: 'IS',
    countryNameTh: 'ไอซ์แลนด์',
    countryNameEn: 'Iceland',
  },
  {
    countryCode: 'IL',
    countryNameTh: 'อิสราเอล',
    countryNameEn: 'Israel',
  },
  {
    countryCode: 'IT',
    countryNameTh: 'อิตาลี',
    countryNameEn: 'Italy',
  },
  {
    countryCode: 'JM',
    countryNameTh: 'จาเมกา',
    countryNameEn: 'Jamaica',
  },
  {
    countryCode: 'JE',
    countryNameTh: 'เจอร์ซีย์',
    countryNameEn: 'Jersey',
  },
  {
    countryCode: 'JO',
    countryNameTh: 'จอร์แดน',
    countryNameEn: 'Jordan',
  },
  {
    countryCode: 'JP',
    countryNameTh: 'ญี่ปุ่น',
    countryNameEn: 'Japan',
  },
  {
    countryCode: 'KZ',
    countryNameTh: 'คาซัคสถาน',
    countryNameEn: 'Kazakhstan',
  },
  {
    countryCode: 'KE',
    countryNameTh: 'เคนยา',
    countryNameEn: 'Kenya',
  },
  {
    countryCode: 'KG',
    countryNameTh: 'คีร์กีซสถาน',
    countryNameEn: 'Kyrgyzstan',
  },
  {
    countryCode: 'KH',
    countryNameTh: 'กัมพูชา',
    countryNameEn: 'Cambodia',
  },
  {
    countryCode: 'KI',
    countryNameTh: 'คิริบาตี',
    countryNameEn: 'Kiribati',
  },
  {
    countryCode: 'KN',
    countryNameTh: 'เซนต์ศิตส์และเนวิส',
    countryNameEn: 'Saint Kitts and Nevis',
  },
  {
    countryCode: 'KR',
    countryNameTh: 'เกาหลีใต้',
    countryNameEn: 'Republic of Korea',
  },
  {
    countryCode: 'KW',
    countryNameTh: 'คูเวต',
    countryNameEn: 'Kuwait',
  },
  {
    countryCode: 'LA',
    countryNameTh: 'สาธารณรัฐประชาธิปไตยประชาชนลาว',
    countryNameEn: "Lao People's Democratic Republic",
  },
  {
    countryCode: 'LB',
    countryNameTh: 'เลบานอน',
    countryNameEn: 'Lebanon',
  },
  {
    countryCode: 'LR',
    countryNameTh: 'ไลบีเรีย',
    countryNameEn: 'Liberia',
  },
  {
    countryCode: 'LY',
    countryNameTh: 'ลิเบีย',
    countryNameEn: 'Libya',
  },
  {
    countryCode: 'LC',
    countryNameTh: 'เซนต์ลูเซีย',
    countryNameEn: 'Saint Lucia',
  },
  {
    countryCode: 'LI',
    countryNameTh: 'ลิกเตนส์ไตน์',
    countryNameEn: 'Liechtenstein',
  },
  {
    countryCode: 'LK',
    countryNameTh: 'ศรีลังกา',
    countryNameEn: 'Sri Lanka',
  },
  {
    countryCode: 'LS',
    countryNameTh: 'เลโซโท',
    countryNameEn: 'Lesotho',
  },
  {
    countryCode: 'LT',
    countryNameTh: 'ลิทัวเนีย',
    countryNameEn: 'Lithuania',
  },
  {
    countryCode: 'LU',
    countryNameTh: 'ลักเซมเบิร์ก',
    countryNameEn: 'Luxembourg',
  },
  {
    countryCode: 'LV',
    countryNameTh: 'ลัตเวีย',
    countryNameEn: 'Latvia',
  },
  {
    countryCode: 'MO',
    countryNameTh: 'มาเก๊า',
    countryNameEn: 'Macao',
  },
  {
    countryCode: 'FX',
    countryNameTh: 'แซ็ง-มาร์แต็ง',
    countryNameEn: 'Saint Martin (French part)',
  },
  {
    countryCode: 'MA',
    countryNameTh: 'โมร็อกโก',
    countryNameEn: 'Morocco',
  },
  {
    countryCode: 'MC',
    countryNameTh: 'โมนาโก',
    countryNameEn: 'Monaco',
  },
  {
    countryCode: 'MD',
    countryNameTh: 'สาธารณรัฐมอลโดวา',
    countryNameEn: 'Republic of Moldova',
  },
  {
    countryCode: 'MG',
    countryNameTh: 'มาดากัสการ์',
    countryNameEn: 'Madagascar',
  },
  {
    countryCode: 'MV',
    countryNameTh: 'มัลดีฟส์',
    countryNameEn: 'Maldives',
  },
  {
    countryCode: 'MX',
    countryNameTh: 'เม็กซิโก',
    countryNameEn: 'Mexico',
  },
  {
    countryCode: 'MH',
    countryNameTh: 'หมู่เกาะมาร์แซลล์',
    countryNameEn: 'Marshall Islands',
  },
  {
    countryCode: 'MK',
    countryNameTh: 'สาธารณรัฐมาซิโดเนีย',
    countryNameEn: 'Republic of Macedonia',
  },
  {
    countryCode: 'ML',
    countryNameTh: 'มาลี',
    countryNameEn: 'Mali',
  },
  {
    countryCode: 'MT',
    countryNameTh: 'มอลตา',
    countryNameEn: 'Malta',
  },
  {
    countryCode: 'MM',
    countryNameTh: 'พม่า',
    countryNameEn: 'Myanmar',
  },
  {
    countryCode: 'ME',
    countryNameTh: 'มอนเตเนโกร',
    countryNameEn: 'Montenegro',
  },
  {
    countryCode: 'MN',
    countryNameTh: 'มองโกเลีย',
    countryNameEn: 'Mongolia',
  },
  {
    countryCode: 'MP',
    countryNameTh: 'หมู่เกาะนอร์เทิร์นมาเรียนา',
    countryNameEn: 'Northern Mariana Islands',
  },
  {
    countryCode: 'MZ',
    countryNameTh: 'โมซัมบิก',
    countryNameEn: 'Mozambique',
  },
  {
    countryCode: 'MR',
    countryNameTh: 'มอริเตเนีย',
    countryNameEn: 'Mauritania',
  },
  {
    countryCode: 'MS',
    countryNameTh: 'มอนต์เซอร์รัต',
    countryNameEn: 'Montserrat',
  },
  {
    countryCode: 'MQ',
    countryNameTh: 'มาร์ตินิก',
    countryNameEn: 'Martinique',
  },
  {
    countryCode: 'MU',
    countryNameTh: 'เมริเซียส',
    countryNameEn: 'Mauritius',
  },
  {
    countryCode: 'MW',
    countryNameTh: 'สาธารณรัฐมาลาวี',
    countryNameEn: 'Republic of Malawi',
  },
  {
    countryCode: 'MY',
    countryNameTh: 'มาเลเซีย',
    countryNameEn: 'Malaysia',
  },
  {
    countryCode: 'YT',
    countryNameTh: 'มายอต',
    countryNameEn: 'Mayotte',
  },
  {
    countryCode: 'NA',
    countryNameTh: 'นามิเบีย',
    countryNameEn: 'Namibia',
  },
  {
    countryCode: 'NC',
    countryNameTh: 'นิวแคลิโดเนีย',
    countryNameEn: 'New Caledonia',
  },
  {
    countryCode: 'NE',
    countryNameTh: 'ไนเจอร์',
    countryNameEn: 'Niger',
  },
  {
    countryCode: 'NF',
    countryNameTh: 'เกาะนอร์ฟอล์ก',
    countryNameEn: 'Norfolk Island',
  },
  {
    countryCode: 'NG',
    countryNameTh: 'ไนจีเรีย',
    countryNameEn: 'Nigeria',
  },
  {
    countryCode: 'NI',
    countryNameTh: 'นิการากัว',
    countryNameEn: 'Nicaragua',
  },
  {
    countryCode: 'NU',
    countryNameTh: 'นีอูเอ',
    countryNameEn: 'Niue',
  },
  {
    countryCode: 'NL',
    countryNameTh: 'เนเธอแลนด์',
    countryNameEn: 'Netherlands',
  },
  {
    countryCode: 'NO',
    countryNameTh: 'นอร์เวย์',
    countryNameEn: 'Norway',
  },
  {
    countryCode: 'NP',
    countryNameTh: 'สหพันธ์สาธารณประชาชาธิปไตยเนปาล',
    countryNameEn: 'Federal Democratic Republic of Nepal',
  },
  {
    countryCode: 'NR',
    countryNameTh: 'นาอูรู',
    countryNameEn: 'Nauru',
  },
  {
    countryCode: 'NZ',
    countryNameTh: 'นิวซีแลนด์',
    countryNameEn: 'New Zealand',
  },
  {
    countryCode: 'OM',
    countryNameTh: 'โอมาน',
    countryNameEn: 'Oman',
  },
  {
    countryCode: 'PK',
    countryNameTh: 'ปากีสถาน',
    countryNameEn: 'Pakistan',
  },
  {
    countryCode: 'PA',
    countryNameTh: 'ปานามา',
    countryNameEn: 'Panama',
  },
  {
    countryCode: 'PN',
    countryNameTh: 'หมู่เกาะพิตแคร์น',
    countryNameEn: 'Pitcairn Islands',
  },
  {
    countryCode: 'PE',
    countryNameTh: 'เปรู',
    countryNameEn: 'Peru',
  },
  {
    countryCode: 'PH',
    countryNameTh: 'ฟิลิปปินส์',
    countryNameEn: 'Philippines',
  },
  {
    countryCode: 'PW',
    countryNameTh: 'ปาเลา',
    countryNameEn: 'Palau',
  },
  {
    countryCode: 'PG',
    countryNameTh: 'ปาปัวนิวกินี',
    countryNameEn: 'Papua New Guinea',
  },
  {
    countryCode: 'PL',
    countryNameTh: 'สาธารณรัฐโปแลนด์',
    countryNameEn: 'Republic of Poland',
  },
  {
    countryCode: 'PR',
    countryNameTh: 'เปอร์โตริโก',
    countryNameEn: 'Puerto Rico',
  },
  {
    countryCode: 'KP',
    countryNameTh: 'เกาหลีเหนือ',
    countryNameEn: "Democratic People's Republic of Korea",
  },
  {
    countryCode: 'PT',
    countryNameTh: 'โปรตุเกส',
    countryNameEn: 'Portugal',
  },
  {
    countryCode: 'PY',
    countryNameTh: 'ปารากวัย',
    countryNameEn: 'Paraguay',
  },
  {
    countryCode: 'PS',
    countryNameTh: 'รัฐปาเลสไตน์',
    countryNameEn: 'State of Palestine',
  },
  {
    countryCode: 'PF',
    countryNameTh: 'เฟรนช์โปลินีเซีย',
    countryNameEn: 'French Polynesia',
  },
  {
    countryCode: 'QA',
    countryNameTh: 'กาตาร์',
    countryNameEn: 'Qatar',
  },
  {
    countryCode: 'RE',
    countryNameTh: 'เรอูเนียง',
    countryNameEn: 'Réunion',
  },
  {
    countryCode: 'RO',
    countryNameTh: 'โรมาเนีย',
    countryNameEn: 'Romania',
  },
  {
    countryCode: 'RU',
    countryNameTh: 'สหพันธรัฐรัสเซีย',
    countryNameEn: 'Russian Federation',
  },
  {
    countryCode: 'RW',
    countryNameTh: 'รวันดา',
    countryNameEn: 'Rwanda',
  },
  {
    countryCode: 'SA',
    countryNameTh: 'ซาอุดิอาระเบีย',
    countryNameEn: 'Saudi Arabia',
  },
  {
    countryCode: 'SD',
    countryNameTh: 'ซูดาน',
    countryNameEn: 'Sudan',
  },
  {
    countryCode: 'SN',
    countryNameTh: 'เซเนกัล',
    countryNameEn: 'Senegal',
  },
  {
    countryCode: 'SG',
    countryNameTh: 'สิงคโปร์',
    countryNameEn: 'Singapore',
  },
  {
    countryCode: 'GS',
    countryNameTh: 'เกาะเซาท์จอร์เจียและหมู่เกาะเซาท์แซนด์วิช',
    countryNameEn: 'South Georgia and the South Sandwich Islands',
  },
  {
    countryCode: 'SH',
    countryNameTh: 'เซนต์เฮเลนา',
    countryNameEn: 'Saint Helena',
  },
  {
    countryCode: 'SJ',
    countryNameTh: 'สฟาลบาร์และยานไมเอน',
    countryNameEn: 'Svalbard and Jan Mayen',
  },
  {
    countryCode: 'SB',
    countryNameTh: 'หมู่เกาะโซโลมอน',
    countryNameEn: 'Solomon Islands',
  },
  {
    countryCode: 'SL',
    countryNameTh: 'เซียร์ราลีโอน',
    countryNameEn: 'Sierra Leone',
  },
  {
    countryCode: 'SV',
    countryNameTh: 'เอลซัลวาดอร์',
    countryNameEn: 'El Salvador',
  },
  {
    countryCode: 'SM',
    countryNameTh: 'ซานมารีโน',
    countryNameEn: 'San Marino',
  },
  {
    countryCode: 'SO',
    countryNameTh: 'โซมาเลีย',
    countryNameEn: 'Somalia',
  },
  {
    countryCode: 'PM',
    countryNameTh: 'แซงปีแยร์และมีเกอลง',
    countryNameEn: 'Saint Pierre and Miquelon',
  },
  {
    countryCode: 'RS',
    countryNameTh: 'เซอร์เบีย',
    countryNameEn: 'Serbia',
  },
  {
    countryCode: 'SS',
    countryNameTh: 'ซูดานใต้',
    countryNameEn: 'South Sudan',
  },
  {
    countryCode: 'ST',
    countryNameTh: 'เซาตูเมและปรินซิปี',
    countryNameEn: 'Sao Tome and Principe',
  },
  {
    countryCode: 'SR',
    countryNameTh: 'ซูรินาเม',
    countryNameEn: 'Suriname',
  },
  {
    countryCode: 'SK',
    countryNameTh: 'สโลวะเกีย',
    countryNameEn: 'Slovakia',
  },
  {
    countryCode: 'SI',
    countryNameTh: 'สโลวีเนีย',
    countryNameEn: 'Slovenia',
  },
  {
    countryCode: 'SE',
    countryNameTh: 'สวีเดน',
    countryNameEn: 'Sweden',
  },
  {
    countryCode: 'SZ',
    countryNameTh: 'สวาซิแลนด์',
    countryNameEn: 'Swaziland',
  },
  {
    countryCode: 'SX',
    countryNameTh: 'ชินต์มาร์เติน',
    countryNameEn: 'Sint Maarten (Dutch part)',
  },
  {
    countryCode: 'SC',
    countryNameTh: 'เซเซลล์',
    countryNameEn: 'Seychelles',
  },
  {
    countryCode: 'SY',
    countryNameTh: 'สาธารณรัฐอาหรับซีเรีย',
    countryNameEn: 'Syrian Arab Republic',
  },
  {
    countryCode: 'TC',
    countryNameTh: 'หมู่เกาะเติร์กและหมู่เกาะเคลอส',
    countryNameEn: 'Turks and Caicos Islands',
  },
  {
    countryCode: 'TD',
    countryNameTh: 'ชาด',
    countryNameEn: 'Tchad',
  },
  {
    countryCode: 'TG',
    countryNameTh: 'โตโก',
    countryNameEn: 'Togo',
  },
  {
    countryCode: 'TH',
    countryNameTh: 'ไทย',
    countryNameEn: 'Thailand',
  },
  {
    countryCode: 'TJ',
    countryNameTh: 'ทาจิกิสถาน',
    countryNameEn: 'Tajikistan',
  },
  {
    countryCode: 'TK',
    countryNameTh: 'โตเกเลา',
    countryNameEn: 'Tokelau',
  },
  {
    countryCode: 'TM',
    countryNameTh: 'เติร์กเมนิสถาน',
    countryNameEn: 'Turkmenistan',
  },
  {
    countryCode: 'TP',
    countryNameTh: 'ติมอร์-เลสเต',
    countryNameEn: 'Timor-Leste',
  },
  {
    countryCode: 'TO',
    countryNameTh: 'ตองกา',
    countryNameEn: 'Tonga',
  },
  {
    countryCode: 'TT',
    countryNameTh: 'ตรินิแดดและโตเบโก',
    countryNameEn: 'Trinidad and Tobago',
  },
  {
    countryCode: 'TN',
    countryNameTh: 'ตูนิเซีย',
    countryNameEn: 'Tunisia',
  },
  {
    countryCode: 'TR',
    countryNameTh: 'ตุรกี',
    countryNameEn: 'Turkey',
  },
  {
    countryCode: 'TV',
    countryNameTh: 'ตูวาลู',
    countryNameEn: 'Tuvalu',
  },
  {
    countryCode: 'TW',
    countryNameTh: 'ไต้หวันสาธารณรัฐจีน',
    countryNameEn: 'Taiwan, Republic of China',
  },
  {
    countryCode: 'TZ',
    countryNameTh: 'สหสาธารณรัฐแทนซาเนีย',
    countryNameEn: 'United Republic of Tanzania',
  },
  {
    countryCode: 'UG',
    countryNameTh: 'ยูกันดา',
    countryNameEn: 'Uganda',
  },
  {
    countryCode: 'UA',
    countryNameTh: 'ยูเครน',
    countryNameEn: 'Ukraine',
  },
  {
    countryCode: 'UM',
    countryNameTh: 'เกาะเล็กรอบนอกของสหรัฐอเมริกา',
    countryNameEn: 'United States Minor Outlying Islands',
  },
  {
    countryCode: 'UY',
    countryNameTh: 'อุรุกวัย',
    countryNameEn: 'Uruguay',
  },
  {
    countryCode: 'US',
    countryNameTh: 'สหรัฐอเมริกา',
    countryNameEn: 'United States of America',
  },
  {
    countryCode: 'UZ',
    countryNameTh: 'อุซเบกิสถาน',
    countryNameEn: 'Uzbekistan',
  },
  {
    countryCode: 'VA',
    countryNameTh: 'นครรัฐวาติกัน',
    countryNameEn: 'State of the Vatican City',
  },
  {
    countryCode: 'VC',
    countryNameTh: 'เซนต์วินเซนต์และเกรนาดีนส์',
    countryNameEn: 'Saint Vincent and the Grenadines',
  },
  {
    countryCode: 'VE',
    countryNameTh: 'สาธารณรัฐโบลีวาร์แห่งเวเนซุเอลา',
    countryNameEn: 'Bolivarian Republic of Venezuela',
  },
  {
    countryCode: 'VG',
    countryNameTh: 'หมู่เกาะบริติซเวอร์จิน',
    countryNameEn: 'British Virgin Islands',
  },
  {
    countryCode: 'VI',
    countryNameTh: 'หมู่เกาะเวอร์จินของสหรัฐอเมริกา',
    countryNameEn: 'United States Virgin Islands,USVI',
  },
  {
    countryCode: 'VN',
    countryNameTh: 'เวียดนาม',
    countryNameEn: 'Viet Nam',
  },
  {
    countryCode: 'VU',
    countryNameTh: 'วานูอาตู',
    countryNameEn: 'Vanuatu',
  },
  {
    countryCode: 'WF',
    countryNameTh: 'วาลลิสและฟูตูนา',
    countryNameEn: 'Wallis and Futuna',
  },
  {
    countryCode: 'WS',
    countryNameTh: 'ซามัว',
    countryNameEn: 'Samoa',
  },
  {
    countryCode: 'YE',
    countryNameTh: 'เยเมน',
    countryNameEn: 'Yemen',
  },
  {
    countryCode: 'ZA',
    countryNameTh: 'สาธารณรัฐแอฟริกาใต้',
    countryNameEn: 'Republic of South Africa',
  },
  {
    countryCode: 'ZM',
    countryNameTh: 'แซมเบีย',
    countryNameEn: 'Zambia',
  },
  {
    countryCode: 'ZW',
    countryNameTh: 'ซิมบับเว',
    countryNameEn: 'Zimbabwe',
  },
];

export const getCountryList = (language) => {
  let list = [];

  for (let i = 0; i < countryCodeAlpha2.length; i++) {
    if (language === 'en') {
      list.push({
        key: countryCodeAlpha2[i].countryCode,
        text: countryCodeAlpha2[i].countryNameEn,
      });
    } else {
      list.push({
        key: countryCodeAlpha2[i].countryCode,
        text: countryCodeAlpha2[i].countryNameTh,
      });
    }
  }

  return list;
};

export const getCountry = (countryCode, language) => {
  let list = {};
  for (let i = 0; i < countryCodeAlpha2.length; i++) {
    if (countryCodeAlpha2[i].countryCode === countryCode) {
      if (language === 'en') {
        list = {
          key: countryCodeAlpha2[i].countryCode,
          text: countryCodeAlpha2[i].countryNameEn,
        };
      } else {
        list = {
          key: countryCodeAlpha2[i].countryCode,
          text: countryCodeAlpha2[i].countryNameTh,
        };
      }
    }
  }

  return list;
};

export const getCountryCodeWithoutLanguage = (countryName) => {
  let list = {};
  for (let i = 0; i < countryCodeAlpha2.length; i++) {
    if (
      countryCodeAlpha2[i].countryNameTh === countryName ||
      countryCodeAlpha2[i].countryNameEn === countryName
    ) {
      list = {
        key: countryCodeAlpha2[i].countryCode,
        textEn: countryCodeAlpha2[i].countryNameEn,
        textTh: countryCodeAlpha2[i].countryNameTh,
      };
    }
  }

  return list;
};
