import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../../authConfig';
import { useTranslation } from 'react-i18next';

import { Editor } from '@tinymce/tinymce-react';
import { Controlled as CodeMirror } from 'react-codemirror2';

import { useHistory, useLocation } from 'react-router-dom';

import {
  PrimaryButton,
  DefaultButton,
  Dropdown,
  Stack,
  getTheme,
  Spinner,
  MessageBar,
  MessageBarType,
  Icon,
  Dialog,
  DialogType,
  DialogFooter,
} from '@fluentui/react';

import { receiptTaxinvoice, taxInvoice, receiptTaxinvoiceAbb, receipt, debitNote, creditNote, quotation, invoice } from './example-etax-data';

require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');

require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');
require('codemirror/mode/css/css');
require('codemirror/mode/jsx/jsx');
require('codemirror/mode/handlebars/handlebars');
require('codemirror/lib/codemirror.css');
require('codemirror/theme/dracula.css');
require('codemirror/theme/panda-syntax.css');
require('codemirror/theme/material.css');

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const stackStyles = { display: 'flex', alignItems: 'flex-end', height: '50px' };

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

const TemplatePreview = () => {
  console.log('Re render');

  const { t, i18n } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  var data = location.state;
  console.log(data);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [html, setHtml] = useState('');
  const [documentType, setDocumentType] = useState({ key: '', text: '' });
  const [exampleData, setExampleData] = useState('');
  const [referenceUrl, setReferenceUrl] = useState('');
  const [chooseDocument, setChooseDocument] = useState(false);

  const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);
  const [showMessageBarError, setShowMessageBarError] = useState(false);

  const [isLoadComplete, setIsLoadComplete] = useState(true);

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const companyId = sessionStorage.getItem('companyId') || '';

  const handleChange = (content, editor) => {
    console.log('editorChange!');

    console.log(editorRef.current.hasFocus());

    if (editorRef.current && editorRef.current.hasFocus()) {
      setHtml(content);
    }
  };

  //Set ref tiny editor
  const editorRef = useRef(null);
  const updateEditor = (content) => {
    if (editorRef.current && !editorRef.current.hasFocus()) {
      editorRef.current.setContent(content);
    }
  };

  const getTemplateData = (templateType) => {
    setIsLoadComplete(false);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        let _apiEndpoint = API_URL + '/etax/templates/' + templateType + '/pdf';

        if (templateType === 'quotation' || templateType === 'invoice' || templateType === 'billing-note' || templateType === 'deliveryorder') {
          _apiEndpoint = API_URL + '/documents/templates/' + templateType + '/pdf';
        }

        axios
          .get(_apiEndpoint, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data) {
                setHtml(response.data);

                if (templateType === 'receipt-taxinvoice') {
                  setExampleData(JSON.stringify(receiptTaxinvoice, null, 2));
                  setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-tax-invoice-pdf-file');
                } else if (templateType === 'receipt-taxinvoice-abb') {
                  setExampleData(JSON.stringify(receiptTaxinvoiceAbb, null, 2));
                  setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-abbreviated-tax-invoice-pdf-file');
                } else if (templateType === 'receipt') {
                  setExampleData(JSON.stringify(receipt, null, 2));
                  setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-pdf-file');
                } else if (templateType === 'invoice-taxinvoice') {
                  setExampleData(JSON.stringify(taxInvoice, null, 2));
                  setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-tax-invoice-pdf-file');
                } else if (templateType === 'deliveryorder-taxinvoice') {
                  setExampleData(JSON.stringify(taxInvoice, null, 2));
                  setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-tax-invoice-pdf-file');
                } else if (templateType === 'taxinvoice') {
                  setExampleData(JSON.stringify(taxInvoice, null, 2));
                  setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-tax-invoice-pdf-file');
                } else if (templateType === 'debit-note') {
                  setExampleData(JSON.stringify(debitNote, null, 2));
                  setReferenceUrl('https://developers.leceipt.com/etax/#create-a-debit-note-pdf-file');
                } else if (templateType === 'credit-note') {
                  setExampleData(JSON.stringify(creditNote, null, 2));
                  setReferenceUrl('https://developers.leceipt.com/etax/#create-a-credit-note-pdf-file');
                } else if (templateType === 'quotation') {
                  setExampleData(JSON.stringify(quotation, null, 2));
                  setReferenceUrl('https://developers.leceipt.com');
                } else if (templateType === 'invoice') {
                  setExampleData(JSON.stringify(invoice, null, 2));
                  setReferenceUrl('https://developers.leceipt.com');
                } else if (templateType === 'billing-note') {
                  setExampleData(JSON.stringify(invoice, null, 2));
                  setReferenceUrl('https://developers.leceipt.com');
                } else if (templateType === 'deliveryorder') {
                    setExampleData(JSON.stringify(invoice, null, 2));
                    setReferenceUrl('https://developers.leceipt.com');
                } 
              } else {
              }

              setIsLoadComplete(true);
            },
            (error) => {
              console.log(error);

              setIsLoadComplete(true);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const deleteTemplate = () => {
    setIsLoadComplete(false);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        let _apiEndpoint = API_URL + '/etax/templates/' + documentType.key + '/pdf';

        if (documentType.key === 'quotation' || documentType.key === 'invoice' || documentType.key === 'billing-note' || documentType.key === 'deliveryorder') {
          _apiEndpoint = API_URL + '/documents/templates/' + documentType.key + '/pdf';
        }

        axios
          .delete(_apiEndpoint, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data) {
                console.log(response.data);

                getTemplateData(documentType.key);

                setShowMessageBarSuccess(true);
              }

              setIsLoadComplete(true);
            },
            (error) => {
              console.log(error);
              setShowMessageBarError(true);
              setIsLoadComplete(true);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    console.log('userEffect Call!');

    if (data !== undefined && data.htmlTemplate !== '') {
      console.log('data not null!');

      setDocumentType({ key: data.documentType.key, text: data.documentType.text });
      setHtml(data.htmlTemplate);
      setExampleData(data.data);

      if (data.documentType.key === 'receipt-taxinvoice') {
        setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-tax-invoice-pdf-file');
      } else if (data.documentType.key === 'receipt-taxinvoice-abb') {
        setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-tax-invoice-pdf-file');
      } else if (data.documentType.key === 'receipt') {
        setReferenceUrl('https://developers.leceipt.com/etax/#create-a-receipt-tax-invoice-pdf-file');
      } else if (data.documentType.key === 'debit-note') {
        setReferenceUrl('https://developers.leceipt.com/etax/#create-a-debit-note-pdf-file');
      } else if (data.documentType.key === 'credit-note') {
        setReferenceUrl('https://developers.leceipt.com/etax/#create-a-credit-note-pdf-file');
      }
    } else {
      setDocumentType({ key: 'receipt-taxinvoice', text: t('pdfTemplate.receipt-taxinvoice') });
      getTemplateData('receipt-taxinvoice');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('userEffect call documentType change!');
    console.log('documentType: ', documentType);
    console.log('data: ', data);
    console.log('Choose document: ', chooseDocument);

    if (chooseDocument) {
      getTemplateData(documentType.key);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentType]);

  const getDocumentText = (documentTypeObj) => {
    let _documentTypeText = '';
    if (documentTypeObj.key === 'receipt-taxinvoice') _documentTypeText = t('pdfTemplate.receipt-taxinvoice');
    if (documentTypeObj.key === 'receipt-taxinvoice-abb') _documentTypeText = t('pdfTemplate.receipt-taxinvoice-abb');
    if (documentTypeObj.key === 'receipt') _documentTypeText = t('pdfTemplate.receipt');
    if (documentTypeObj.key === 'invoice-taxinvoice') _documentTypeText = t('pdfTemplate.invoice-taxinvoice');
    if (documentTypeObj.key === 'deliveryorder-taxinvoice') _documentTypeText = t('pdfTemplate.deliveryorder-taxinvoice');
    if (documentTypeObj.key === 'taxinvoice') _documentTypeText = t('pdfTemplate.taxinvoice');
    if (documentTypeObj.key === 'debit-note') _documentTypeText = t('pdfTemplate.debit-note');
    if (documentTypeObj.key === 'credit-note') _documentTypeText = t('pdfTemplate.credit-note');
    if (documentTypeObj.key === 'quotation') _documentTypeText = t('pdfTemplate.quotation');
    if (documentTypeObj.key === 'invoice') _documentTypeText = t('pdfTemplate.invoice');
    if (documentTypeObj.key === 'billing-note') _documentTypeText = t('pdfTemplate.billing-note');
    if (documentTypeObj.key === 'deliveryorder') _documentTypeText = t('pdfTemplate.deliveryorder');

    return _documentTypeText;
  };

  useDidMountEffect(() => {
    let _documentTypeText = '';
    if (documentType.key === 'receipt-taxinvoice') _documentTypeText = t('pdfTemplate.receipt-taxinvoice');
    if (documentType.key === 'receipt-taxinvoice-abb') _documentTypeText = t('pdfTemplate.receipt-taxinvoice-abb');
    if (documentType.key === 'receipt') _documentTypeText = t('pdfTemplate.receipt');
    if (documentType.key === 'invoice-taxinvoice') _documentTypeText = t('pdfTemplate.invoice-taxinvoice');
    if (documentType.key === 'deliveryorder-taxinvoice') _documentTypeText = t('pdfTemplate.deliveryorder-taxinvoice');
    if (documentType.key === 'taxinvoice') _documentTypeText = t('pdfTemplate.taxinvoice');
    if (documentType.key === 'debit-note') _documentTypeText = t('pdfTemplate.debit-note');
    if (documentType.key === 'credit-note') _documentTypeText = t('pdfTemplate.credit-note');
    if (documentType.key === 'quotation') _documentTypeText = t('pdfTemplate.quotation');
    if (documentType.key === 'invoice') _documentTypeText = t('pdfTemplate.invoice');
    if (documentType.key === 'billing-note') _documentTypeText = t('pdfTemplate.billing-note');
    if (documentType.key === 'deliveryorder') _documentTypeText = t('pdfTemplate.deliveryorder');

    setDocumentType((prev) => ({ ...prev, text: _documentTypeText }));
  }, [i18n.language]);

  return (
    <Stack>
      {showMessageBarSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSuccess(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('pdfTemplate.showMessageBarSuccess')}
        </MessageBar>
      )}
      {showMessageBarError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarError(false)}
          dismissButtonAriaLabel="Close"
        >
          {t('pdfTemplate.showMessageBarError')}
        </MessageBar>
      )}
      <form>
        <h2 style={{ marginLeft: '0px', marginTop: '27px' }}>
          <Icon iconName="FileTemplate" style={{ color: '#106ebe' }} /> {t('pdfTemplate.header')}
        </h2>
        <div
          style={{
            width: '1000px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 30,
            background: '#ffffff',
            margin: '0',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <Stack horizontal style={stackStyles} tokens={{ childrenGap: 20 }}>
            {isLoadComplete && (
              <Dropdown
                label={t('pdfTemplate.documentType')}
                options={[
                  { key: 'receipt-taxinvoice', text: t('pdfTemplate.receipt-taxinvoice') },
                  { key: 'receipt-taxinvoice-abb', text: t('pdfTemplate.receipt-taxinvoice-abb') },
                  { key: 'receipt', text: t('pdfTemplate.receipt') },
                  { key: 'invoice-taxinvoice', text: t('pdfTemplate.invoice-taxinvoice') },
                  { key: 'deliveryorder-taxinvoice', text: t('pdfTemplate.deliveryorder-taxinvoice') },
                  { key: 'taxinvoice', text: t('pdfTemplate.taxinvoice') },
                  { key: 'debit-note', text: t('pdfTemplate.debit-note') },
                  { key: 'credit-note', text: t('pdfTemplate.credit-note') },
                  { key: 'quotation', text: t('pdfTemplate.quotation') },
                  { key: 'invoice', text: t('pdfTemplate.invoice') },
                  { key: 'billing-note', text: t('pdfTemplate.billing-note') },
                  { key: 'deliveryorder', text: t('pdfTemplate.deliveryorder') },
                ]}
                styles={{ dropdown: { width: 260 } }}
                selectedKey={documentType.key}
                onChange={(e, selectedOption) => {
                  setChooseDocument(true);
                  setDocumentType(selectedOption);
                }}
              />
            )}
            {isLoadComplete && (
              <PrimaryButton
                onClick={() => {
                  history.push({
                    pathname: '/etax/templates/pdf/preview',
                    state: {
                      htmlTemplate: html,
                      data: exampleData,
                      documentType: {
                        key: documentType.key,
                        text: documentType.text,
                      },
                    },
                  });
                }}
                text={t('pdfTemplate.preview')}
                iconProps={{ iconName: 'Preview' }}
              />
            )}
            {isLoadComplete && (
              <DefaultButton
                onClick={() =>
                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: true,
                    header: t('pdfTemplate.disconnectConfirmHeader'),
                    message: t('pdfTemplate.disconnectConfirm1') + documentType.text + t('pdfTemplate.disconnectConfirm2'),
                    onConfirm: () => {
                      deleteTemplate();
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      }));
                    },
                    onCancel: () =>
                      setDialogConfirm((prev) => ({
                        ...prev,
                        isClick: false,
                      })),
                  }))
                }
                text={t('pdfTemplate.reset')}
                iconProps={{
                  iconName: 'Refresh',
                }}
              />
            )}
            {!isLoadComplete && <Spinner label={t('pdfTemplate.loading')} labelPosition="right" />}
          </Stack>
          <br />

          <Editor
            apiKey="31xz8igro2agp8bh7luzjvsnr0x852rg8bafgrh1rtsbmmoa"
            onInit={(evt, editor) => (editorRef.current = editor)}
            value={html}
            init={{
              language: 'th_TH',
              height: 500,
              menubar: false,
              extended_valid_elements:
                'style,page,page[size="A4"],page[size="A4"][layout="landscape"],page[size="A3"],page[size="A3"][layout="landscape"],page[size="A5"],page[size="A5"][layout="landscape"]',
              custom_elements:
                'style,page,page[size="A4"],page[size="A4"][layout="landscape"],page[size="A3"],page[size="A3"][layout="landscape"],page[size="A5"],page[size="A5"][layout="landscape"]',
              /* 
              plugins: [
                'advlist autolink autosave link image lists charmap print preview hr anchor pagebreak spellchecker',
                'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
                'table contextmenu directionality emoticons template textcolor paste fullpage fullscreen',
              ],
 */
              plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',

              toolbar:
                'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | code fullscreen ',
            }}
            onEditorChange={handleChange}
          />
          <br />
          <h3>HTML Code</h3>
          <CodeMirror
            value={html}
            options={{
              mode: 'xml',
              theme: 'material',
              lineNumbers: true,
            }}
            onBeforeChange={(editor, data, value) => {
              console.log('onBeforeChange');
              setHtml(value);
              updateEditor(value);
            }}
            onChange={(editor, data, value) => {
              console.log('onChange');
            }}
          />

          <br />
          <h3>
            {t('pdfTemplate.example')} {getDocumentText(documentType)}{' '}
            <a href={referenceUrl} target="_blank" rel="noreferrer">
              <small>{t('pdfTemplate.jsonParameter')}</small>
            </a>
          </h3>

          <CodeMirror
            value={exampleData}
            options={{
              mode: {
                name: 'javascript',
                json: true,
                statementIndent: 2,
              },
              theme: 'material',
              lineNumbers: true,
              lineWrapping: true,
              indentWithTabs: false,
              tabSize: 2,
              viewportMargin: Infinity,
            }}
            onBeforeChange={(editor, data, value) => {
              console.log('onBeforeChange');
              setExampleData(value);
            }}
            onChange={() => {
              console.log('onChange');
            }}
          />

          <br />
        </div>
        <br />
        <br />
      </form>
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </Stack>
  );
};

export default TemplatePreview;
