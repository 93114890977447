import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../authConfig';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Calendar,
  DateRangeType,
  SelectionMode,
  CommandBar,
  Stack,
  PrimaryButton,
  DefaultButton,
  ContextualMenu,
  SearchBox,
  ActionButton,
  mergeStyleSets,
  mergeStyles,
  TooltipHost,
  DirectionalHint,
  Text,
  Dialog,
  DialogType,
  DialogFooter,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import { useId } from '@fluentui/react-hooks';

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

//Dialog Style
// Success dialog style
const iconClass = mergeStyles({
  fontSize: 120,
  height: 120,
  width: 120,
  margin: '0 60px',
});

const classNames = mergeStyleSets({
  customerEmail: {
    verticalAlign: 'baseline',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
  blue: [
    {
      color: '#0078D4',
    },
    iconClass,
  ],
});

const Sent = () => {
  console.log('Home render!!!');

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];
  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState('');
  const [filterDocuments, setFilterDocuments] = useState([]);

  const [primarySort, setPrimarySort] = useState('createdTime');
  const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(true);
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(false);
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(false);
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(false);
  const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(false);
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(false);

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const DayPickerStrings = {
    months: [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ],

    shortMonths: [
      t('documents.shortMonths01'),
      t('documents.shortMonths02'),
      t('documents.shortMonths03'),
      t('documents.shortMonths04'),
      t('documents.shortMonths05'),
      t('documents.shortMonths06'),
      t('documents.shortMonths07'),
      t('documents.shortMonths08'),
      t('documents.shortMonths09'),
      t('documents.shortMonths10'),
      t('documents.shortMonths11'),
      t('documents.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: 'ไปวันที่ปัจจุบัน',
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('documents.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  const getMonth = (goBack) => {
    let monthNames = [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState({
    key: getMonth(0).key,
    text: t('documents.thisMonth'),
  });

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const [from, setFrom] = useState(getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(getTo(getMonth(0).month, getMonth(0).year));

  const [date, setDate] = useState(sessionStorage.getItem('sent-date') ? new Date(sessionStorage.getItem('sent-date')) : new Date());

  function getDateText(monthObject) {
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('documents.thisMonth');

    let monthNames = [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('documents.months01'),
                    t('documents.months02'),
                    t('documents.months03'),
                    t('documents.months04'),
                    t('documents.months05'),
                    t('documents.months06'),
                    t('documents.months07'),
                    t('documents.months08'),
                    t('documents.months09'),
                    t('documents.months10'),
                    t('documents.months11'),
                    t('documents.months12'),
                  ];

                  setDate(_date);

                  sessionStorage.setItem('sent-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  sessionStorage.setItem('sent-queryMonth-key', adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'));
                  sessionStorage.setItem('sent-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem('sent-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem('sent-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setSearch('');
                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);

                  setPrimarySort('createdTime');
                  setIsSortedDescendingCreatedTime(true);
                  setIsSortedDescendingDocumentDate(false);
                  setIsSortedDescendingNumber(false);
                  setIsSortedDescendingCustomerName(false);
                  setIsSortedDescendingVatTotal(false);
                  setIsSortedDescendingGrandTotal(false);
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
            </div>
          );
        },
      },
    },
    {
      key: pageSize,
      text: pageSize + ' ' + t('documents.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 10,
            text: '10 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 เอกสาร/หน้า');
              setPageSize(10);

              setSearch('');
              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 20,
            text: '20 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 เอกสาร/หน้า');
              setPageSize(20);

              setSearch('');
              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 30,
            text: '30 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 เอกสาร/หน้า');
              setPageSize(30);

              setSearch('');
              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 40,
            text: '40 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 เอกสาร/หน้า');
              setPageSize(40);

              setSearch('');
              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
          {
            key: 50,
            text: '50 ' + t('documents.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 เอกสาร/หน้า');
              setPageSize(50);

              setSearch('');
              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);
            },
          },
        ],
      },
    },
    {
      key: 'refresh',
      text: t('documents.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete,
      onClick: () => {
        console.log('Refresh');

        setSearch('');
        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setPrimarySort('createdTime');
        setIsSortedDescendingCreatedTime(true);
        setIsSortedDescendingDocumentDate(false);
        setIsSortedDescendingNumber(false);
        setIsSortedDescendingCustomerName(false);
        setIsSortedDescendingVatTotal(false);
        setIsSortedDescendingGrandTotal(false);
      },
    },
  ];

  const openFile = (item) => {
    openFile_pdf(item);
  };

  const openFile_pdf = (item) => {
    history.push({
      pathname: '/sent/documents/pdf/view',
      state: {
        item: item,
      },
    });
  };

  const download = (item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/sents/files/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              console.log(response.headers);

              let fileName = item.fileName;

              console.log('file name: ', fileName);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName); //or any other extension
              document.body.appendChild(link);
              link.click();
              link.remove();
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const deleteFile = (item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .delete(API_URL + '/sents/documents/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);
              setLoadDataComplete(false);
              setGetNewDocuments(true);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        // Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);
    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    let hour = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const TooltipEmailAndName = (value) => {
    const parentTooltipId = useId('text-tooltip');

    return (
      <TooltipHost content={value} id={parentTooltipId} directionalHint={DirectionalHint.bottomCenter}>
        <span aria-describedby={parentTooltipId}>{value}</span>
      </TooltipHost>
    );
  };

  const columns = [
    {
      key: 'column1',
      name: t('sent.sentDate'),
      fieldName: 'date',
      minWidth: 125,
      maxWidth: 125,
      isResizable: true,
      isSorted: primarySort === 'createdTime' ? true : false,
      isSortedDescending: isSortedDescendingCreatedTime,
      isSortedAscending: !isSortedDescendingCreatedTime,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('createdTime');
        setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.date}</span>;
      },
    },
    {
      key: 'column2',
      name: t('sent.receiver'),
      fieldName: 'receiver',
      minWidth: 140,
      maxWidth: 140,
      isResizable: true,
      onColumnClick: () => {},
      data: 'string',
      onRender: (item) => {
        return TooltipEmailAndName(item.name);
      },
    },
    {
      key: 'column3',
      name: t('sent.email'),
      fieldName: 'customerEmail',
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: () => {},
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton title={item.customerEmail} className={classNames.customerEmail}>
            <span>{item.customerEmail}</span>
          </ActionButton>
        );
      },
    },
    {
      key: 'column4',
      name: t('sent.file'),
      fieldName: 'fileName',
      minWidth: 135,
      maxWidth: 235,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: () => {},
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton
            title={item.fileName}
            className={classNames.fileIconImg}
            onClick={() => {
              openFile(item);
            }}
          >
            <span>{item.fileName}</span>
          </ActionButton>
        );
      },
    },
    {
      key: 'column5',
      name: t('sent.fileSize'),
      fieldName: 'fileSize',
      minWidth: 70,
      maxWidth: 70,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: () => {},
      data: 'string',
      onRender: (item) => {
        return (
          <Stack>
            <span style={{ textAlign: 'right' }}>{item.fileSize}</span>
          </Stack>
        );
      },
    },
    {
      key: 'column6',
      name: t('sent.status'),
      fieldName: 'status',
      minWidth: 80,
      maxWidth: 80,
      isCollapsible: true,
      onColumnClick: () => {},
      data: 'boolean',
      onRender: (item) => {
        return <span>{item.status ? 'ส่งสำเร็จ' : ''}</span>;
      },
    },
    {
      key: 'column7',
      name: t('sent.message'),
      fieldName: 'message',
      minWidth: 550,
      maxWidth: 650,
      onColumnClick: () => {},
      data: 'string',
      onRender: (item) => {
        return TooltipEmailAndName(item.message);
      },
    },
    {
      key: 'column8',
      name: '',
      fieldName: 'command',
      minWidth: 90,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        let options = [
          {
            key: 'open',
            text: t('documents.openDocument'),
            iconProps: { iconName: 'OpenFile' },
            onClick: () => {
              openFile_pdf(item);
            },
          },
          {
            key: 'download',
            text: t('documents.download'),
            iconProps: { iconName: 'DownloadDocument' },
            onClick: () => {
              download(item);
            },
          },
          {
            key: 'delete',
            text: t('documents.delete'),
            iconProps: { iconName: 'Delete' },
            onClick: () => {
              setDialogConfirm((prev) => ({
                ...prev,
                isClick: true,
                header: t('documents.deleteConfirmHeader'),
                message: `${t('documents.deleteConfirm1')}${item.fileName}${t('documents.deleteConfirm3')}`,
                onConfirm: () => {
                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: false,
                  }));
                  deleteFile(item);
                },
                onCancel: () =>
                  setDialogConfirm((prev) => ({
                    ...prev,
                    isClick: false,
                  })),
              }));
            },
          },
        ];

        return (
          <DefaultButton
            text={t('documents.select')}
            menuProps={{
              items: options,
              shouldFocusOnMount: true,
              directionalHintFixed: true,
            }}
            styles={{ root: { width: 120 } }}
            // Optional callback to customize menu rendering
            menuAs={(props) => <ContextualMenu {...props} />}
          />
        );
      },
    },
  ];

  const isCompactMode = false;

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        // Do something with the tokenResponse
        console.log('Token response: ', tokenResponse);

        const [response] = await Promise.all([
          axios
            .get(API_URL + '/sents?from=' + from + '&to=' + to, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            })
            .catch((error) => console.log(error)),
        ]);

        console.log('response', response);

        let sentMails = response.data;

        console.log('Docs number: ', sentMails.length);

        //Sorting
        if (sentMails && sentMails.length > 0) {
          if (primarySort === 'createdTime') {
            if (isSortedDescendingCreatedTime) {
              sentMails.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.createdTime) - new Date(a.createdTime);
              });
            } else {
              sentMails.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.createdTime) - new Date(b.createdTime);
              });
            }
          } else if (primarySort === 'number') {
            if (isSortedDescendingNumber) {
              sentMails.sort((a, b) => {
                return (
                  /[A-Za-z]/.test(b.data.number) - /[A-Za-z]/.test(a.data.number) ||
                  (b.data.number.toUpperCase() < a.data.number.toUpperCase() ? -1 : b.data.number.toUpperCase() > a.data.number.toUpperCase() ? 1 : 0)
                );
              });
            } else {
              sentMails.sort((a, b) => {
                return (
                  /[A-Za-z]/.test(a.data.number) - /[A-Za-z]/.test(b.data.number) ||
                  (a.data.number.toUpperCase() < b.data.number.toUpperCase() ? -1 : a.data.number.toUpperCase() > b.data.number.toUpperCase() ? 1 : 0)
                );
              });
            }
          } else if (primarySort === 'customerName') {
            if (isSortedDescendingCustomerName) {
              sentMails.sort((a, b) => {
                if (b.data.customer.name < a.data.customer.name) {
                  return -1;
                }
                if (b.data.customer.name > a.data.customer.name) {
                  return 1;
                }
                return 0;
              });
            } else {
              sentMails.sort((a, b) => {
                if (a.data.customer.name < b.data.customer.name) {
                  return -1;
                }
                if (a.data.customer.name > b.data.customer.name) {
                  return 1;
                }
                return 0;
              });
            }
          } else if (primarySort === 'documentDate') {
            if (isSortedDescendingDocumentDate) {
              sentMails.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.data.date) - new Date(a.data.date);
              });
            } else {
              sentMails.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.data.date) - new Date(b.data.date);
              });
            }
          } else if (primarySort === 'vatTotal') {
            if (isSortedDescendingVatTotal) {
              sentMails.sort(function (a, b) {
                return b.data.vatTotal - a.data.vatTotal;
              });
            } else {
              sentMails.sort(function (a, b) {
                return a.data.vatTotal - b.data.vatTotal;
              });
            }
          } else if (primarySort === 'grandTotal') {
            if (isSortedDescendingGrandTotal) {
              sentMails.sort(function (a, b) {
                return b.data.grandTotal - a.data.grandTotal;
              });
            } else {
              sentMails.sort(function (a, b) {
                return a.data.grandTotal - b.data.grandTotal;
              });
            }
          }
        }

        setPageData(paginate(sentMails.length, currentPage, pageSize, 10));

        setDocuments(sentMails);

        const _docs = [];

        if (sentMails.length > 0) {
          for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, sentMails.length); i++) {
            let fileSize = formatBytes(sentMails[i].file.size);
            let createdTime = toThaiDateString(sentMails[i].createdTime);

            _docs.push({
              key: sentMails[i].id,
              date: createdTime,
              name: sentMails[i].data.fromName,
              customerEmail: sentMails[i].data.to,
              fileName: sentMails[i].file.displayName + (sentMails[i].file.extension ? `.${sentMails[i].file.extension}` : ''),
              fileSize: fileSize,
              message: sentMails[i].data.message,
              status: true,
            });
          }
        }

        console.log('Docs data', _docs);
        setItems(_docs);

        setCurrentPage(1);
        setLoadDataComplete(true);
        setGetNewDocuments(false);
      })
      .catch((error) => {
        console.log(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    if (search) {
      if (filterDocuments.length > 0) {
        const _docs = [];

        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, filterDocuments.length); i++) {
          let fileSize = formatBytes(filterDocuments[i].file.size);
          let createdTime = toThaiDateString(filterDocuments[i].createdTime);

          _docs.push({
            key: filterDocuments[i].id,
            date: createdTime,
            name: filterDocuments[i].data.fromName,
            customerEmail: filterDocuments[i].data.to,
            fileName: filterDocuments[i].file.displayName + (filterDocuments[i].file.extension ? `.${filterDocuments[i].file.extension}` : ''),
            fileSize: fileSize,
            message: filterDocuments[i].data.message,
            status: true,
          });
        }
        console.log('Docs data', _docs);

        setItems(_docs);
      }
    } else {
      if (documents.length > 0) {
        const _docs = [];

        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
          let fileSize = formatBytes(documents[i].file.size);
          let createdTime = toThaiDateString(documents[i].createdTime);

          _docs.push({
            key: documents[i].id,
            date: createdTime,
            name: documents[i].data.fromName,
            customerEmail: documents[i].data.to,
            fileName: documents[i].file.displayName + (documents[i].file.extension ? `.${documents[i].file.extension}` : ''),
            fileSize: fileSize,
            message: documents[i].data.message,
            status: true,
          });
        }
        console.log('Docs data', _docs);

        setItems(_docs);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    let totalDocs = documents;

    let filterDocs = totalDocs.filter(
      (doc) => doc.data.fromName.includes(search) || doc.file.displayName.includes(search) || doc.data.to.includes(search)
    );

    setPageData(paginate(filterDocs.length, currentPage, pageSize, 10));
    setFilterDocuments(filterDocs);
    setCurrentPage(1);

    if (filterDocs.length > 0) {
      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, filterDocs.length); i++) {
        let fileSize = formatBytes(filterDocs[i].file.size);
        let createdTime = toThaiDateString(filterDocs[i].createdTime);

        _docs.push({
          key: filterDocs[i].id,
          date: createdTime,
          name: filterDocs[i].data.fromName,
          customerEmail: filterDocs[i].data.to,
          fileName: filterDocs[i].file.displayName + (filterDocs[i].file.extension ? `.${filterDocs[i].file.extension}` : ''),
          fileSize: fileSize,
          message: filterDocs[i].data.message,
          status: true,
        });
      }
      setItems(_docs);
    } else {
      setItems([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div style={homeStyles}>
      <Stack>
        <center>
          <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('sent.header')}</h2>
        </center>

        <Stack>
          <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
            <div>
              <br />
              <PrimaryButton
                iconProps={{ iconName: 'send' }}
                text={t('sent.sentEmail')}
                onClick={() => {
                  history.push('/sent/upload');
                }}
              />
            </div>
          </Stack>
        </Stack>
      </Stack>
      <br />
      <Stack horizontal horizontalAlign="center">
        <Stack
          vertical
          style={{
            width: '98%',
            maxWidth: '1350px',
          }}
        >
          <Stack horizontal className="ms-bgColor-white" horizontalAlign="space-between">
            <CommandBar
              items={commandBarItems}
              styles={{
                root: {
                  width: 'calc(100vw - 700px)',
                  minWidth: '700px',
                  maxWidth: '900px',
                },
              }}
            />
            <Stack
              horizontal
              styles={{
                root: {
                  height: 44,
                },
              }}
              tokens={{ childrenGap: '10px' }}
            >
              <SearchBox
                className="ms-borderColor-themePrimary"
                styles={{
                  root: {
                    margin: 6,
                    width: 300,
                    fontSize: 13,
                    fontWeight: 0,
                  },
                }}
                disabled={!loadDataComplete}
                placeholder={t('sent.searchPlaceholder')}
                onChange={(e) => setSearch(e ? e.target.value : '')}
                value={search}
              />
            </Stack>
          </Stack>
          {items.length ? (
            <ShimmeredDetailsList
              items={items || []}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
            />
          ) : (
            <ShimmeredDetailsList
              items={[{}]}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderRow={() => {
                return (
                  <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: '#FFFFFF', height: 55 } }}>
                    <Text>{t('documents.noItemFound')}</Text>
                  </Stack>
                );
              }}
            />
          )}
        </Stack>
      </Stack>
      <br />
      <center>
        {!(documents.length === 0 || documents.length < pageSize || pageData.totalPages === 0) && loadDataComplete && (
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={pageData.totalPages}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
          />
        )}
        <br />
        <br />
      </center>
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default Sent;
