import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import PaymentHistory from './PaymentHistory';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { Stack, getTheme, Icon, ProgressIndicator, Spinner, Separator, Pivot, PivotItem, PrimaryButton, DefaultButton } from '@fluentui/react';

import DocumentsCheckout from './documents/checkout/DocumentsCheckout';
import SmsCheckout from './sms/checkout/SmsCheckout';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const cardStyle = {
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 20,
  paddingBottom: 30,
  background: '#ffffff',
  marginLeft: '0',
  marginTop: '10px',
  boxShadow: theme.effects.elevation4,
  width: 630,
};

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const Billings = () => {
  const location = useLocation();

  const [locationState] = useState(location.state);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const [loadAccountsComplete, setLoadAccountsComplete] = useState(false);
  const [loadSubscriptionsComplete, setLoadSubscriptionsComplete] = useState(false);

  const [subscriptions, setSubscriptions] = useState('');
  const [spentPercent, setSpentPercent] = useState(0);
  const [subscriptionTo, setSubscriptionTo] = useState('');
  const [nextResetDate, setNextResetDate] = useState('');

  //Pivot menu index
  const [selectedKey, setSelectedKey] = useState('documents');

  const [billingsType, setBillingsType] = useState('prepaid');
  const [enableTopup, setEnableTopup] = useState(false);

  const getAccounts = (accessToken) => {
    axios
      .get(API_URL + '/accounts', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then(
        (response) => {
          console.log('Accounts data: ', response);

          if (response.data) {
            if (response.data.billings && response.data.billings.type === 'subscription') {
              setBillingsType('subscription');
            }

            if (response.data.billings && response.data.billings.type === 'prepaid' && response.data.billings.enableTopup === true) {
              setEnableTopup(true);
            }
          }

          setLoadAccountsComplete(true);
        },
        (error) => {
          console.log(error);

          setLoadAccountsComplete(true);
        }
      );
  };

  const getSubscriptions = (accessToken) => {
    axios
      .get(API_URL + '/subscriptions', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then(
        (response) => {
          console.log('Subscription data: ', response);

          if (response.data) {
            setSubscriptions(response.data);
            setSpentPercent(Math.floor((response.data.documents.spent * 100) / response.data.documents.limit));

            setSubscriptionTo(
              new Date(response.data.to).toLocaleDateString('th-TH', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: 'Asia/Bangkok',
              })
            );
            setNextResetDate(
              new Date(response.data.resetDate.next).toLocaleDateString('th-TH', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: 'Asia/Bangkok',
              })
            );
          }

          setLoadSubscriptionsComplete(true);
        },
        (error) => {
          console.log(error);

          setLoadSubscriptionsComplete(true);
        }
      );
  };

  useEffect(() => {
    console.log('userEffect initialize Call!');

    console.log('location state: ', locationState);

    if (locationState && locationState.pivot) {
      setSelectedKey(locationState.pivot);
    }

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        getSubscriptions(tokenResponse.accessToken);

        getAccounts(tokenResponse.accessToken);
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return billingsType === 'prepaid' && enableTopup ? (
    <div style={{ width: '880px' }}>
      <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>
        <Icon iconName="QRCode" style={{ color: '#106ebe' }} /> เติมเครดิต
      </h2>
      <Pivot selectedKey={selectedKey} onLinkClick={setSelectedKey}>
        <PivotItem headerText="เติมเครดิตเอกสาร" itemKey="documents">
          <br />

          {loadAccountsComplete && loadSubscriptionsComplete ? (
            billingsType === 'prepaid' ? (
              <DocumentsCheckout />
            ) : (
              <Stack>
                <div
                  style={{
                    width: '680px',
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 30,
                    background: '#ffffff',
                    marginLeft: '0',
                    marginTop: '0',
                    boxShadow: theme.effects.elevation4,
                  }}
                >
                  <div className="ms-Grid-row">
                    <div>
                      <h2 style={{ margin: '0%' }}>{subscriptions && subscriptions.plan.name}</h2>
                      <br />
                      <span>{'ใช้ได้ถึง ' + subscriptionTo + ' - 23:59:59 น.'}</span>
                      <br />
                      <Separator />

                      {subscriptions && (
                        <ProgressIndicator
                          label={
                            'ใช้เอกสารไป ' +
                            (subscriptions.documents.spent * 1).toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 }) +
                            ' จากทั้งหมด ' +
                            (subscriptions.documents.limit * 1).toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 }) +
                            ' เอกสาร'
                          }
                          description={spentPercent + '%'}
                          percentComplete={spentPercent / 100}
                          barHeight={12}
                          styles={{ root: { width: 350 } }}
                        />
                      )}
                      <br />
                      <small>รีเซ็ตเอกสารครั้งต่อไป: {nextResetDate}</small>
                    </div>

                    <br />
                    <br />
                  </div>
                </div>
              </Stack>
            )
          ) : (
            <Stack>
              <br />
              <br />
              <br />
              <Stack horizontal horizontalAlign="center">
                <div>
                  {loadAccountsComplete ? (
                    <Spinner label="กำลังดำเนินการ..." labelPosition="down" styles={spinnerStyles} />
                  ) : (
                    <Spinner label="กำลังโหลด..." labelPosition="down" styles={spinnerStyles} />
                  )}
                </div>
                <br />
              </Stack>
            </Stack>
          )}
        </PivotItem>
        <PivotItem headerText="เติมเครดิตSMS" itemKey="sms">
          <br />
          <SmsCheckout />
        </PivotItem>

        <PivotItem headerText="ประวัติการชำระเงิน" itemKey="history">
          <br />
          <PaymentHistory />
        </PivotItem>
      </Pivot>
    </div>
  ) : (
    <Stack>
      <h1 style={{ marginLeft: '0', marginTop: '27px' }}>
        <Icon iconName="GiftboxOpen" style={{ color: '#106ebe' }} /> เปิดใช้งานโปรแกรม Leceipt
      </h1>

      <div style={cardStyle}>
        <h1>ขอใบเสนอราคา</h1>
        <Separator />
        <h2 style={{ color: '#106ebe' }}>คลิกที่ปุ่มข้างล่างนี้เพื่อขอใบเสนอราคา</h2>
        <br />
        <PrimaryButton text="ขอใบเสนอราคา" onClick={() => {window.open('https://quotation.leceipt.com', '_blank')}}></PrimaryButton>
        <DefaultButton text="รายละเอียดราคา" onClick={() => {window.open('https://www.leceipt.com/pricing', '_blank')}} style={{ marginLeft: 20}}></DefaultButton>
        
      </div>

      <br />

      <div style={cardStyle}>
        <h1>ติดต่อสอบถาม</h1>
        <Separator />
        <h2 style={{ color: '#106ebe' }}>โทร: 082-579-8555</h2>
        <h2>อีเมล: support@leceipt.com</h2>
        <h2 style={{ color: '#00B900' }}>Line: @Leceipt</h2>
        <h3>สแกนเพิ่มเพื่อน Line QR Code</h3>
        <img src="/images/qr-code-line-leceipt.png" alt="เพิ่มเพื่อน" height="250" border="0" />
        <h3>เวลาทำการ 8.30-17.30 น. (จันทร์ - ศุกร์)</h3>
      </div>
      
      <br />
      <br />

    </Stack>
  );
};

export default Billings;
