import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../authConfig';
import { useHistory } from /* useLocation */ 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  TextField,
  PrimaryButton,
  Dropdown,
  Stack,
  getTheme,
  Shimmer,
  ShimmerElementType,
  Icon,
  Spinner,
  Toggle,
  IconButton,
  DefaultButton,
} from '@fluentui/react';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const cardStyle = {
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 20,
  paddingBottom: 30,
  background: '#ffffff',
  marginLeft: '0',
  marginTop: '10px',
  boxShadow: theme.effects.elevation4,
};

const shimmerRowHeight32 = [{ type: ShimmerElementType.line, height: 32, width: 410 }];

const dropdownOptions = [
  { key: 'work', text: 'Work' },
  { key: 'home', text: 'Home' },
  { key: 'mobile', text: 'Mobile' },
  { key: 'other', text: 'Other' },
];

const dropdownLabelOptions = [
  { key: 'customer', text: 'Customer' },
  { key: 'hot', text: 'Hot Lead' },
  { key: 'warm', text: 'Warm Lead' },
  { key: 'cold', text: 'Cold Lead' },
];

// const dropdownVisibleToOptions = [
//   { key: 'customer', text: 'Customer' },
//   { key: 'hot', text: 'Hot Lead' },
//   { key: 'warm', text: 'Warm Lead' },
//   { key: 'cold', text: 'Cold Lead' },
// ];

const centerItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const AddPerson = () => {
  console.log('Re render');

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  console.log(account);

  const { t } = useTranslation();

  // const location = useLocation();
  // const data = location.state;

  const [name, setName] = useState('');
  const [contactNickName, setContactNickName] = useState('');
  const [contactPosition, setContactPosition] = useState('');
  const [contactDepartment, setContactDepartment] = useState('');

  const [organizationList, setOrganizationList] = useState([]);
  const [organizationId, setOrganizationId] = useState('');

  const [label, setLabel] = useState({ key: '', text: '' });

  const ownerList = [{ key: account.localAccountId, text: account.name }]
  const [owner, setOwner] = useState(account.localAccountId);

  const [banks, setBanks] = useState([
    {
      bankCode: '',
      bankName: '',
      branch: '',
      typeCode: '',
      typeName: '',
      accountNumber: '',
    },
  ]);

  const [language, setLanguage] = useState('th');

  const [isLoadComplete, setIsLoadComplete] = useState(false);
  const [buttonClick, setButtonClick] = useState(false);

  const phoneRegEx = /(^0[1-9][0-9]{1,13}$|^$)/g;
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [phoneList, setPhoneList] = useState([{ type: 'work', value: '' }]);
  const [emailList, setEmailList] = useState([{ type: 'mobile', value: '' }]);

  const companyId = sessionStorage.getItem('companyId') || '';

  const history = useHistory();

  useEffect(() => {
    console.log('userEffect retrieve contact data!');

    async function getContact() {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .get(API_URL + '/contacts', {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId
              },
            })
            .then((response) => {
              const contacts = response.data.map((contact) => ({
                key: contact.id,
                text: contact.name + (contact.branchText ? ' ' + contact.branchText : ''),
              }));
              setOrganizationList(contacts);
              setIsLoadComplete(true);
            });
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }

    getContact();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Language Effect: ', language);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  function handleSubmit(event) {
    event.preventDefault();

    let isCorrect = true;

    if (phoneNumberError) {
      isCorrect = false;
    }

    const data = {
      organizationId: organizationId, // String
      name: name, // String
      nickname: contactNickName, // String
      label: label, // { key: "", text: ""}
      department: contactDepartment, // String
      postition: contactPosition, // String
      phones: phoneList, // [ { type: "", value: ""} ]
      emails: emailList, // [ { type: "", value: ""} ]
      createdBy: account.localAccountId, // oid
      owner: owner, // oid
      // visibleTo: { key: '', text: ''}
    };

    if (isCorrect) {
      console.log(data)
    }

    setButtonClick(true);
  }

  const handleAddPhone = () => {
    setPhoneList([
      ...phoneList,
      {
        type: '',
        value: '',
      },
    ]);
  };

  const handleAddEmail = () => {
    setEmailList([
      ...emailList,
      {
        type: '',
        value: '',
      },
    ]);
  };

  const handleRemoveBank = (index) => {
    console.log('Delete index:', index);
    const values = [...banks];
    values.splice(index, 1);
    setBanks(values);
  };

  const getPhoneNumberErrorMessage = (value) => {
    setPhoneNumberError(false);
    if (value === null || value === undefined) {
      setPhoneNumberError(true);
      return t('addPerson.getErrorMessage');
    } else if (phoneRegEx.test(value) !== true) {
      setPhoneNumberError(true);
      return t('addPerson.getPhoneNumberErrorMessage');
    }
  };

  function handleChangePhone(index, event, selectedOption) {
    console.log('Change index:', index);
    const values = [...phoneList];
    if (selectedOption) {
      values[index]['type'] = selectedOption.key;
    } else {
      values[index]['value'] = event.target.value;
    }

    setPhoneList(values);
  }

  function handleChangeEmail(index, event, selectedOption) {
    console.log('Change index:', index);
    const values = [...emailList];
    if (selectedOption) {
      values[index]['type'] = selectedOption.key;
    } else {
      values[index]['value'] = event.target.value;
    }

    setEmailList(values);
  }

  return (
    <Stack style={{ background: '#FAF9F8' }}>
      <form onSubmit={handleSubmit}>
        <Stack horizontal horizontalAlign="space-between">
          <h2 style={{ marginLeft: '0', marginTop: '27px' }}>
            <Icon iconName="Contact" style={{ color: '#106ebe' }} /> {t('addPerson.header')}
          </h2>
          <Toggle
            styles={{ root: { marginTop: 17 } }}
            inlineLabel
            label={t('addPerson.english')}
            onText={t('addPerson.on')}
            offText={t('addPerson.off')}
            checked={language === 'en' ? true : false}
            onChange={() => {
              if (language === 'en') {
                setLanguage('th');
              } else {
                setLanguage('en');
              }
            }}
          />
        </Stack>

        {isLoadComplete ? (
          <div style={cardStyle}>
            <Stack styles={{ root: { width: 300 } }}>
              <TextField name="name" label={t('addPerson.name')} onChange={(e) => setName(e.target.value)} required />
              <Dropdown
                name="organization"
                label={t('addPerson.organization')}
                options={organizationList}
                selectedKey={organizationId}
                onChange={(e, selectedOption) => {
                  setOrganizationId(selectedOption.key);
                }}
                styles={{ root: { width: 300 } }}
              />
              <Dropdown
                name="label"
                label={t('addPerson.label')}
                options={dropdownLabelOptions}
                selectedKey={label.key}
                onChange={(e, selectedOption) => {
                  setLabel(selectedOption);
                }}
                styles={{ root: { width: 300 } }}
              />
              {phoneList.map((phone, index) => (
                <Stack horizontal key={`phone-${index}`}>
                  {index === 0 ? (
                    <Stack>
                      <TextField
                        name="phone"
                        label={index === 0 ? t('addPerson.phone') : ''}
                        styles={{ root: { width: 165, marginRight: 15 } }}
                        onChange={(e) => {
                          handleChangePhone(index, e);
                        }}
                        onGetErrorMessage={getPhoneNumberErrorMessage}
                      />
                    </Stack>
                  ) : (
                    <Stack styles={{ root: { marginTop: index === 0 ? 27.6 : 10 } }}>
                      <TextField
                        name="phone"
                        styles={{ root: { width: 165, marginRight: 15 } }}
                        onChange={(e) => {
                          handleChangePhone(index, e);
                        }}
                        onGetErrorMessage={getPhoneNumberErrorMessage}
                      />
                    </Stack>
                  )}
                  <Stack styles={{ root: { width: 85, marginTop: index === 0 ? 27.6 : 10, marginRight: 5 } }}>
                    <Dropdown
                      // placeholder={t('addContact.pleaseSelect')}
                      options={dropdownOptions}
                      selectedKey={phone.type}
                      onChange={(e, selectedOption) => {
                        handleChangePhone(index, e, selectedOption);
                      }}
                      // required
                    />
                  </Stack>
                  <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    title={t('addContact.delete')}
                    styles={{ root: { marginTop: index === 0 ? 27.6 : 10 } }}
                    onClick={() => handleRemoveBank(index)}
                  />
                </Stack>
              ))}
              <div style={{ marginTop: 15, marginLeft: 'auto', marginRight: 'auto' }}>
                <PrimaryButton
                  iconProps={{ iconName: 'Add' }}
                  name="addPhoneList"
                  text={t('addPerson.addOneMorePhone')}
                  onClick={() => handleAddPhone()}
                />
              </div>
              {emailList.map((email, index) => (
                <Stack horizontal key={`email-${index}`}>
                  {index === 0 ? (
                    <Stack>
                      <TextField
                        name="email"
                        label={index === 0 ? t('addPerson.email') : ''}
                        styles={{ root: { width: 165, marginRight: 15 } }}
                        onChange={(e) => {
                          handleChangeEmail(index, e);
                        }}
                      />
                    </Stack>
                  ) : (
                    <Stack styles={{ root: { marginTop: index === 0 ? 27.6 : 10 } }}>
                      <TextField
                        name="email"
                        styles={{ root: { width: 165, marginRight: 15 } }}
                        onChange={(e) => {
                          handleChangeEmail(index, e);
                        }}
                      />
                    </Stack>
                  )}
                  <Stack styles={{ root: { width: 85, marginTop: index === 0 ? 27.6 : 10, marginRight: 5 } }}>
                    <Dropdown
                      // placeholder={t('addContact.pleaseSelect')}
                      options={dropdownOptions}
                      selectedKey={email.type}
                      onChange={(e, selectedOption) => {
                        handleChangeEmail(index, e, selectedOption);
                      }}
                      // required
                    />
                  </Stack>
                  <IconButton
                    iconProps={{ iconName: 'Delete' }}
                    title={t('addContact.delete')}
                    styles={{ root: { marginTop: index === 0 ? 27.6 : 10 } }}
                    onClick={() => handleRemoveBank(index)}
                  />
                </Stack>
              ))}
              <div style={{ marginTop: 15, marginLeft: 'auto', marginRight: 'auto' }}>
                <PrimaryButton
                  iconProps={{ iconName: 'Add' }}
                  name="addPhoneList"
                  text={t('addPerson.addOneMoreEmail')}
                  onClick={() => handleAddEmail()}
                />
              </div>
              <TextField
                name="contactNickName"
                label={t('addContact.contactNickName')}
                styles={{ root: { width: 300 } }}
                value={contactNickName}
                onChange={(e) => setContactNickName(e.target.value)}
              />
              <TextField
                name="contactDepartment"
                label={t('addContact.contactDepartment')}
                styles={{ root: { width: 300 } }}
                value={contactDepartment}
                onChange={(e) => setContactDepartment(e.target.value)}
              />
              <TextField
                name="contactPosition"
                label={t('addContact.contactPosition')}
                styles={{ root: { width: 300 } }}
                value={contactPosition}
                onChange={(e) => setContactPosition(e.target.value)}
              />
              <Dropdown
                name="owner"
                label={t('addPerson.owner')}
                options={ownerList}
                selectedKey={owner}
                styles={{ root: { width: 300 } }}
                onChange={(e, selectedOption) => {
                  setOwner(selectedOption.key);
                }}
              />
              {/* <Dropdown
              name="visible to"
              label={t('addContact.visibleTo')}
              options={dropdownVisibleToOptions}
              selectedKey={visibleTo}
              styles={{ root: { width: 300 } }}
            /> */}
            </Stack>
          </div>
        ) : (
          <div style={cardStyle}>
            <Stack styles={{ root: { width: 300 } }}>
              <div style={{ marginTop: 27.6 }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </div>
              <div style={{ marginTop: 27.6 }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </div>
              <div style={{ marginTop: 27.6 }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </div>
              <div style={{ marginTop: 27.6 }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </div>
              <div style={{ marginTop: 15 }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </div>
              <div style={{ marginTop: 27.6 }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </div>
              <div style={{ marginTop: 15 }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </div>
              <div style={{ marginTop: 27.6 }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </div>
              <div style={{ marginTop: 27.6 }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </div>
              <div style={{ marginTop: 27.6 }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </div>
              <div style={{ marginTop: 27.6 }}>
                <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              </div>
            </Stack>
          </div>
        )}

        <br />
        <br />
        {!buttonClick && (
          <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
            <PrimaryButton text={t('addPerson.save')} iconProps={{ iconName: 'Save' }} type="submit" value="next" />
            <DefaultButton text={t('addPerson.back')} onClick={() => history.push('/contacts')} />
          </Stack>
        )}
        {buttonClick && <Spinner label={t('addPerson.loading')} labelPosition="right" />}
        <br />
        <br />
      </form>
      {/* )} */}
    </Stack>
  );
};

export default AddPerson;
