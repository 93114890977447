import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  SelectionMode,
  mergeStyleSets,
  ActionButton,
  Spinner,
  SpinnerSize,
  CommandBar,
  SearchBox,
  Stack,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  mergeStyles,
  TextField,
  MessageBar,
  MessageBarType,
  DocumentCard,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardLogo,
  DocumentCardType,
  Text,
  ContextualMenu,
  ContextualMenuItemType,
  Calendar,
  DateRangeType,
  Checkbox,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import CountUp from 'react-countup';

import { CSVLink } from 'react-csv';
import { TextFieldEmail } from '../../../helpers/customComponents/TextFieldEmail';
import { checkEmail } from '../../../helpers/CheckEmail';

const API_URL = process.env.REACT_APP_API_URL;

const iconClass = mergeStyles({
  fontSize: 120,
  height: 120,
  width: 120,
  margin: '0 60px',
});

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
  blue: [
    {
      color: '#0078D4',
    },
    iconClass,
  ],
});

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  minWidth: 'calc(100vw - 310px)',
  background: '#FAF9F8',
  position: 'relative',
};

const cardStyles = {
  cardStyles: {
    root: {
      background: 'white',
      borderLeft: '5px solid #0078d4',
      width: '27%',
      minWidth: '332px',
      maxWidth: '424px',
      height: '130px',
    },
  },
  header: {
    root: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingTop: '20px',
      textAlign: 'center',
    },
  },
  amount: {
    root: {
      fontSize: 30,
      paddingBottom: '25px',
      textAlign: 'center',
    },
  },
};

function formatDateTime(date) {
  let d = new Date(date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = '' + d.getHours(),
    minute = '' + d.getMinutes(),
    second = '' + d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [year, month, day, hour, minute, second].join('');
}

const toThaiDateString = (isoDateTime) => {
  let date = '';
  date = new Date(isoDateTime);

  let year = date.getFullYear() + 543;
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let numOfDay = date.getDate().toString().padStart(2, '0');

  let hour = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');
  let second = date.getSeconds().toString().padStart(2, '0');

  return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
};

const headerCSV = [
  { key: 'r1', label: 'ลำดับที่' },
  { key: 'r2', label: 'สาขาที่' },
  { key: 'r3', label: 'เลขที่' },
  { key: 'r4', label: 'รหัสไปรษณีย์' },
  { key: 'r5', label: 'ยอดขายที่ต้องเสียภาษี' },
  { key: 'r6', label: 'ภาษีขายเดือนนี้' },
  { key: 'r7', label: 'ยอดซื้อ' },
  { key: 'r8', label: 'ภาษีซื้อเดือนนี้' },
  { key: 'r9', label: 'จำนวนภาษีที่ต้องชำระหรือชำระเกิน' },
];

const PP30 = () => {
  console.log('Home render!!!');

  const { t, i18n } = useTranslation();

  const csvLink = useRef();

  const history = useHistory();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const [company, setCompany] = useState({});
  const [companyName, setCompanyName] = useState('');
  const [companyBuildingNumber, setCompanyBuildingNumber] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyStreetPrefix, setCompanyStreetPrefix] = useState('');
  const [companyStreetName, setCompanyStreetName] = useState('');
  const [companyProvincePrefix, setCompanyProvincePrefix] = useState('');
  const [companyProvinceCode, setCompanyProvinceCode] = useState('');
  const [companyProvinceName, setCompanyProvinceName] = useState('');
  const [companySubDistrictPrefix, setCompanySubDistrictPrefix] = useState('');
  const [companySubDistrictCode, setCompanySubDistrictCode] = useState('');
  const [companySubDistrictName, setCompanySubDistrictName] = useState('');
  const [companyDistrictPrefix, setCompanyDistrictPrefix] = useState('');
  const [companyDistrictCode, setCompanyDistrictCode] = useState('');
  const [companyDistrictName, setCompanyDistrictName] = useState('');
  const [companyPostcode, setCompanyPostCode] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyTaxId, setCompanyTaxId] = useState('');
  const [companyBranchText, setCompanyBranchText] = useState('');
  const [companyBranchNumber, setCompanyBranchNumber] = useState('');
  const companyAllAddress = '';

  const [percentVat] = useState(7);
  const [zeroVat] = useState(0);
  const [nonVat] = useState(-1);

  const [vatPriceTotal, setVatPriceTotal] = useState(0);
  const [zeroVatPriceTotal, setZeroVatPriceTotal] = useState(0);
  const [nonVatPriceTotal, setNonVatPriceTotal] = useState(0);

  const normal = true;
  const addFund = 0;

  const [totalAmount, setTotalAmount] = useState(0);

  const exceedVatAmount = 0;

  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const totalProcessingDocuments = 0
  const [pageData, setPageData] = useState({});
  const pageSize = 10
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('pp30-currentPage') * 1 || 1);

  const [date, setDate] = useState(sessionStorage.getItem('pp30-date') ? new Date(sessionStorage.getItem('pp30-date')) : new Date());

  const [search, setSearch] = useState('');
  const [previousSearch, setPreviousSearch] = useState('');
  const filterDocuments = [];

  const [downloadStatus, setDownloadStatus] = useState('');
  const downloading = downloadStatus === 'Downloading';
  const downloadComplete = downloadStatus === 'Complete';

  const [CSV, setCSV] = useState([]);

  const DayPickerStrings = {
    months: [
      t('pp30.months01'),
      t('pp30.months02'),
      t('pp30.months03'),
      t('pp30.months04'),
      t('pp30.months05'),
      t('pp30.months06'),
      t('pp30.months07'),
      t('pp30.months08'),
      t('pp30.months09'),
      t('pp30.months10'),
      t('pp30.months11'),
      t('pp30.months12'),
    ],

    shortMonths: [
      t('pp30.shortMonths01'),
      t('pp30.shortMonths02'),
      t('pp30.shortMonths03'),
      t('pp30.shortMonths04'),
      t('pp30.shortMonths05'),
      t('pp30.shortMonths06'),
      t('pp30.shortMonths07'),
      t('pp30.shortMonths08'),
      t('pp30.shortMonths09'),
      t('pp30.shortMonths10'),
      t('pp30.shortMonths11'),
      t('pp30.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: 'ไปวันที่ปัจจุบัน',
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('pp30.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  useEffect(() => {
    if (downloadComplete) {
      setDownloadStatus('');
      csvLink.current.link.click();
    }
  }, [downloadComplete]);

  async function getCSVData() {
    console.log('handleDownload');

    let csvData = [];

    let row = {
      r1: 1,
      r2: Number(company.branchNumber),
      r3: company.buildingNumber,
      r4: company.postcode,
      r5: Math.round(totalGrand * 1e2) / 1e2,
      r6: Math.round(totalVat * 1e2) / 1e2,
      r7: Math.round(totalExpenseAmount * 1e2) / 1e2,
      r8: Math.round(totalExpenseTax * 1e2) / 1e2,
      r9: Math.round((totalVat - totalExpenseTax) * 1e2) / 1e2,
    };

    csvData.push(row);

    setCSV(csvData);
    setDownloadStatus('Complete');
  }

  const getfileName = () => {
    const time = formatDateTime(new Date().toISOString());
    let fileName = time + '-PP30.csv';
    return fileName;
  };

  const [CSVFileName] = useState(getfileName);

  const downloadTXT = () => {
    console.log('download .txt');

    let csvData = [];

    let row = {
      r1: 1,
      r2: Number(company.branchNumber),
      r3: company.buildingNumber,
      r4: company.postcode,
      r5: Math.round(totalGrand * 1e2) / 1e2,
      r6: Math.round(totalVat * 1e2) / 1e2,
      r7: Math.round(totalExpenseAmount * 1e2) / 1e2,
      r8: Math.round(totalExpenseTax * 1e2) / 1e2,
      r9: Math.round((totalVat - totalExpenseTax) * 1e2) / 1e2,
    };

    csvData.push(row);

    let stringToText =
      'ลำดับที่|สาขาที่|เลขที่|รหัสไปรษณีย์|ยอดขายที่ต้องเสียภาษี|ภาษีขายเดือนนี้|ยอดซื้อ|ภาษีซื้อเดือนนี้|จำนวนภาษีที่ต้องชำระหรือชำระเกิน\n';

    csvData.forEach(
      (row) => (stringToText = stringToText + `${row.r1}|${row.r2}|${row.r3}|${row.r4}|${row.r5}|${row.r6}|${row.r7}|${row.r8}|${row.r9}\n`)
    );

    const time = formatDateTime(new Date().toISOString());

    const element = document.createElement('a');
    const file = new Blob([stringToText], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = time + '-PP-30.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  //CommandBars Items
  const documentType = {
    key: 'ALL',
    text: 'รายการภาษีซื้อ',
  };

  const [isClickEmail, setIsClickEmail] = useState(false);
  const [isClickCopyEmail, setIsClickCopyEmail] = useState(false);
  const [fileIdEmail, setFileIdEmail] = useState('');
  const [fileNameEmail, setFileNameEmail] = useState('');
  const fileDisplayName = '';
  const [customerEmail, setCustomerEmail] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [copyEmailList, setCopyEmailList] = useState([])
  const [copyEmail, setCopyEmail] = useState('');
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [errorMessageCopyEmail, setErrorMessageCopyEmail] = useState('');

  const [isClickSms, setIsClickSms] = useState(false);
  const [fileIdSms, setFileIdSms] = useState('');
  const [fileNameSms, setFileNameSms] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [showSmsSuccess, setShowSmsSuccess] = useState(false);
  const [showSmsError, setShowSmsError] = useState(false);
  const [errorMessageSms, setErrorMessageSms] = useState('');

  //Display Summary
  const [totalVat, setTotalVat] = useState(0);
  const [totalGrand, setTotalGrand] = useState(0);

  const [totalExpenseTax, setTotalExpenseTax] = useState(0);
  const [totalExpenseAmount, setTotalExpenseAmount] = useState(0);

  const getMonth = (goBack) => {
    let monthNames = [
      t('pp30.months01'),
      t('pp30.months02'),
      t('pp30.months03'),
      t('pp30.months04'),
      t('pp30.months05'),
      t('pp30.months06'),
      t('pp30.months07'),
      t('pp30.months08'),
      t('pp30.months09'),
      t('pp30.months10'),
      t('pp30.months11'),
      t('pp30.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem('pp30-queryMonth-key') && {
      key: Number(sessionStorage.getItem('pp30-queryMonth-key')),
      year: Number(sessionStorage.getItem('pp30-queryMonth-year')),
      text: sessionStorage.getItem('pp30-queryMonth-text'),
    }) || { key: getMonth(0).key, year: getMonth(0).year, text: t('pp30.thisMonth') }
  );

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('pp30-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('pp30-to') || getTo(getMonth(0).month, getMonth(0).year));

  const dateType = {
    key: 'document',
    text: 'วันที่บนเอกสาร',
  };

  const [docType, setDocType] = useState(
    (sessionStorage.getItem('pp30-docType-key') && {
      key: sessionStorage.getItem('pp30-docType-key'),
      text: sessionStorage.getItem('pp30-docType-text'),
    }) || {
      key: 'vatTax',
      text: t('pp30.vatTax'),
    }
  );

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('pp30-primarySort') || 'documentDate');
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem('pp30-sort-documentDate')) {
      console.log('this is', true);
      if (sessionStorage.getItem('pp30-sort-documentDate') === 'false') {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('pp30-sort-number') && sessionStorage.getItem('pp30-sort-number') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('pp30-sort-customerName') && sessionStorage.getItem('pp30-sort-customerName') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingTaxId, setIsSortedDescendingTaxId] = useState(() => {
    if (sessionStorage.getItem('pp30-sort-taxId') && sessionStorage.getItem('pp30-sort-taxId') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingAmountTotal, setIsSortedDescendingAmountTotal] = useState(() => {
    if (sessionStorage.getItem('pp30-sort-amountTotal') && sessionStorage.getItem('pp30-sort-amountTotal') === 'true') {
      return true;
    } else {
      return false;
    }
  });
  const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(() => {
    if (sessionStorage.getItem('pp30-sort-vatTotal') && sessionStorage.getItem('pp30-sort-vatTotal') === 'true') {
      return true;
    } else {
      return false;
    }
  });

  const [isSortedDescendingName, setIsSortedDescendingName] = useState(() => {
    if (sessionStorage.getItem('pp30-sort-name') && sessionStorage.getItem('pp30-sort-name') === 'true') {
      return true;
    } else {
      return false;
    }
  });

  const handleDownloadCSV = () => {
    setDownloadStatus('Downloading');
    getCSVData();
  };

  const onSubmitSummary = () => {
    history.push({
      pathname: '/rdprep/pp30/summary',
      state: {
        // location state
        companyBuildingNumber: companyBuildingNumber,
        companyAddress: companyAddress,
        companyStreetPrefix: companyStreetPrefix,
        companyStreetName: companyStreetName,
        companyProvincePrefix: companyProvincePrefix,
        companyProvinceCode: companyProvinceCode,
        companyProvinceName: companyProvinceName,
        companySubDistrictPrefix: companySubDistrictPrefix,
        companySubDistrictCode: companySubDistrictCode,
        companySubDistrictName: companySubDistrictName,
        companyDistrictPrefix: companyDistrictPrefix,
        companyDistrictCode: companyDistrictCode,
        companyDistrictName: companyDistrictName,
        companyPostcode: companyPostcode,
        companyPhone: companyPhone,
        companyEmail: companyEmail,
        companyTaxId: companyTaxId,
        companyBranchText: companyBranchText,
        companyBranchNumber: companyBranchNumber,
        percentVat: percentVat,
        zeroVat: zeroVat,
        nonVat: nonVat,
        nonVatPriceTotal: nonVatPriceTotal,
        zeroVatPriceTotal: zeroVatPriceTotal,
        vatPriceTotal: vatPriceTotal,
        purchaseAmount: totalExpenseAmount,
        purchaseVat: totalExpenseTax,
        exceedVatAmount: exceedVatAmount,
        payVat: totalVat - totalExpenseTax,
        month: queryMonth.key,
        year: queryMonth.year + 543,
        totalVat: totalVat,
        totalAmount: totalAmount,
        totalGrand: totalGrand,
        companyName: companyName,
        companyAllAddress: companyAllAddress,
        normal: normal,
        addFund: !normal ? addFund : 0,
        interest: !normal ? addFund : 0,
        headOffice: companyBranchText ? (companyBranchText.includes('สำนักงานใหญ่') ? true : false) : false,
        branchOffice: companyBranchText ? (companyBranchText.includes('สำนักงานใหญ่') ? false : true) : false,
      },
    });
  };

  function getDocumentTypeText(key) {
    if (key === 'vatTax') return t('pp30.vatTax');
    if (key === 'expenseTax') return t('pp30.expenseTax');
  }

  function getDateText(monthObject) {
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('pp30.thisMonth');

    let monthNames = [
      t('pp30.months01'),
      t('pp30.months02'),
      t('pp30.months03'),
      t('pp30.months04'),
      t('pp30.months05'),
      t('pp30.months06'),
      t('pp30.months07'),
      t('pp30.months08'),
      t('pp30.months09'),
      t('pp30.months10'),
      t('pp30.months11'),
      t('pp30.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: docType.key,
      text: getDocumentTypeText(docType.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 'vatTax',
            text: t('pp30.vatTax'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('รายการภาษีขาย');
              setDocType({ key: 'vatTax', text: t('pp30.vatTax') });

              sessionStorage.setItem('pp30-docType-key', 'vatTax');
              sessionStorage.setItem('pp30-docType-text', t('pp30.vatTax'));

              setLoadDataComplete(false);
              setDocuments([]);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setCurrentPage(1);

              setTotalVat(0);
              setTotalGrand(0);
              setTotalExpenseTax(0);
            },
          },
          {
            key: 'expenseTax',
            text: t('pp30.expenseTax'),
            iconProps: { iconName: 'Page' },
            onClick: () => {
              console.log('รายการภาษีซื้อ');
              setDocType({ key: 'expenseTax', text: t('pp30.expenseTax') });

              sessionStorage.setItem('pp30-docType-key', 'expenseTax');
              sessionStorage.setItem('pp30-docType-text', t('pp30.expenseTax'));

              setLoadDataComplete(false);
              setDocuments([]);
              setItems([]);
              setGetNewDocuments(true);
              setPreviousSearch('');
              setSearch('');

              setCurrentPage(1);

              setTotalVat(0);
              setTotalGrand(0);
              setTotalExpenseTax(0);
            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('pp30.months01'),
                    t('pp30.months02'),
                    t('pp30.months03'),
                    t('pp30.months04'),
                    t('pp30.months05'),
                    t('pp30.months06'),
                    t('pp30.months07'),
                    t('pp30.months08'),
                    t('pp30.months09'),
                    t('pp30.months10'),
                    t('pp30.months11'),
                    t('pp30.months12'),
                  ];

                  setDate(_date);

                  sessionStorage.setItem('pp30-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  sessionStorage.setItem('pp30-queryMonth-key', adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'));
                  sessionStorage.setItem('pp30-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem('pp30-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem('pp30-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setDocuments([]);
                  setItems([]);
                  setGetNewDocuments(true);
                  setPreviousSearch('');
                  setSearch('');

                  setCurrentPage(1);

                  setTotalVat(0);
                  setTotalGrand(0);
                  setTotalExpenseTax(0);
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
            </div>
          );
        },
      },
    },
    {
      key: 'txt',
      text: t('pp30.downloadTxt'),
      iconProps: { iconName: 'TextDocument' },
      disabled: !loadDataComplete || items.length <= 0,
      onClick: () => {
        downloadTXT();
      },
    },
    {
      key: 'csv',
      text: t('pp30.downloadCsv'),
      iconProps: { iconName: 'ExcelDocument' },
      disabled: !loadDataComplete || items.length <= 0,
      onClick: () => {
        handleDownloadCSV();
      },
    },
    {
      key: 'pdf',
      text: t('pp30.pdf'),
      iconProps: { iconName: 'PDF' },
      disabled: !loadDataComplete,
      onClick: () => {
        console.log('pdf');

        onSubmitSummary();
      },
    },
    {
      key: 'refresh',
      text: t('pp30.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalVat(0);
        setTotalGrand(0);

        setTotalExpenseTax(0);

        setCurrentPage(1);
      },
    },
  ];

  const UploadPDF = (fileName, file, email) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        let formData = new FormData();

        formData.append('file', file);
        formData.append('fileName', fileName);
        formData.append('to', email);
        formData.append('message', '');

        await axios
          .post(API_URL + '/sents', formData, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'multipart/form-data',
            },
            timeout: 60000,
          })
          .then(
            (response) => {
              console.log(true, response);
            },
            (error) => {
              console.log('Error sending document response: ', error);
            }
          );
      })
      .catch((error) => {
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const sendEmail = (fileName, fileId, email, copyEmail) => {
    if (email !== '' && email !== null && email !== undefined) {
      if ((isClickCopyEmail && copyEmail !== '' && copyEmail !== null && copyEmail !== undefined) || !isClickCopyEmail) {
        setIsClickEmail(false);

        setIsClickCopyEmail(false);
        setErrorMessageCopyEmail('');

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
              .post(
                API_URL + '/emails/send',
                {
                  fileId: fileId,
                  email: email,
                  copyEmail: copyEmail,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then(
                (response) => {
                  console.log(response);
                  setIsClickEmail(false);
                  setShowEmailSuccess(true);
                  window.scrollTo(0, 0);
                },
                (error) => {
                  console.log(error);
                  setIsClickEmail(false);
                  setShowEmailError(true);
                  window.scrollTo(0, 0);
                }
              );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });

        let file = '';

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
              .get(API_URL + '/etax/files/' + fileId, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
                responseType: 'blob',
              })
              .then(
                (response) => {
                  console.log(response);

                  file = response.data;

                  let allEmails = email.split(';');

                  allEmails.forEach((email) => {
                    console.log(email, true);

                    instance
                      .acquireTokenSilent({
                        ...silentRequest,
                        account: account,
                      })
                      .then(async (tokenResponse) => {
                        await axios
                          .get(API_URL + '/sents/check?email=' + email, {
                            headers: {
                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                            },
                          })
                          .then(
                            (response) => {
                              console.log(response);
                              if (response.data.isExist) {
                                UploadPDF(fileName, file, email);
                              }
                            },
                            (error) => {
                              console.log(error);
                            }
                          );
                      });
                  });
                },
                (error) => {
                  console.log(error);
                }
              );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });
      } else {
        setErrorMessageCopyEmail('กรุณากรอกอีเมลที่ถูกต้อง');
      }
    }
  };

  const sendSms = (fileId, mobile) => {
    const re = /^0[1-9]{1}[0-9]{8}/;

    if (mobile !== '' && mobile !== null && mobile !== undefined && re.test(String(mobile))) {
      setIsClickSms(false);
      setErrorMessageSms('');

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + '/etax/sms/',
              {
                fileId: fileId,
                mobile: mobile,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'application/json',
                },
              }
            )
            .then(
              (response) => {
                console.log(response);
                setIsClickSms(false);
                setShowSmsSuccess(true);
                window.scrollTo(0, 0);
              },
              (error) => {
                console.log(error);
                setIsClickSms(false);
                setShowSmsError(true);
                window.scrollTo(0, 0);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      setErrorMessageSms('กรุณากรอกเบอร์มือถือที่ถูกต้อง');
    }
  };

  useEffect(() => {
    console.log('userEffect Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {

        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log('company:', response);

              if (response.data[0]) {
                setCompany(response.data[0]);

                setCompanyName(response.data[0].name);
                setCompanyBuildingNumber(response.data[0].buildingNumber);
                setCompanyAddress(response.data[0].address);
                setCompanyStreetPrefix(response.data[0].streetName);
                setCompanyStreetName(response.data[0].streetName);
                setCompanyProvincePrefix(response.data[0].provincePrefix);
                setCompanyProvinceCode(response.data[0].provinceCode);
                setCompanyProvinceName(response.data[0].provinceName);
                setCompanySubDistrictPrefix(response.data[0].subDistrictPrefix);
                setCompanySubDistrictCode(response.data[0].subDistrictCode);
                setCompanySubDistrictName(response.data[0].subDistrictName);
                setCompanyDistrictPrefix(response.data[0].subDistrictPrefix);
                setCompanyDistrictCode(response.data[0].subDistrictCode);
                setCompanyDistrictName(response.data[0].subDistrictName);
                setCompanyPostCode(response.data[0].postcode);
                setCompanyPhone(response.data[0].phone);
                setCompanyEmail(response.data[0].email);
                setCompanyTaxId(response.data[0].taxId);
                setCompanyBranchNumber(response.data[0].branchNumber);
                setCompanyBranchText(response.data[0].branchText);
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
        
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');

    if (getNewDocuments) {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          // Do something with the tokenResponse
          console.log('Token response: ', tokenResponse);

          const [pdf_response, responseExpenseNote] = await Promise.all([
            axios
              .get(API_URL + '/etax/documents/' + documentType.key + '/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              })
              .catch((error) => console.log(error)),
            axios
              .get(API_URL + '/expenses/expense-notes?from=' + from + '&to=' + to, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              })
              .catch((error) => console.log(error)),
          ]);

          console.log('responseExpenseNote', responseExpenseNote);

          let expenseNotes = responseExpenseNote.data;

          let pdf_responseData = pdf_response.data;

          let pdf_xml = pdf_responseData.filter(
            (doc) =>
              doc.type !== 'RECEIPT' &&
              doc.type !== 'QUOTATION' &&
              doc.type !== 'WITHHOLDING-TAX' &&
              doc.type !== 'INVOICE' &&
              doc.type !== 'BILLING-NOTE' &&
              doc.type !== 'DELIVERYORDER'
          );

          pdf_xml.forEach((doc) => {
            if (doc.type === 'CREDIT-NOTE') {
              doc.data.amountTotal = -Math.abs(doc.data.refer.diffAmountTotal);
              doc.data.grandTotal = -Math.abs(doc.data.grandTotal);
              doc.data.vatTotal = -Math.abs(doc.data.vatTotal);
            } else if (doc.type === 'DEBIT-NOTE') {
              doc.data.amountTotal = doc.data.refer.diffAmountTotal;
            }

            if (doc.data.reIssue) {
              doc.data.amountTotal = 0;
              doc.data.vatTotal = 0;
              doc.data.grandTotal = 0;
            }
          });

          console.log('pdf_xml', pdf_xml);

          //Sorting
          if (pdf_xml && pdf_xml.length > 0) {
            if (primarySort === 'documentDate') {
              if (isSortedDescendingDocumentDate) {
                pdf_xml.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(b.data.date) - new Date(a.data.date);
                });
              } else {
                pdf_xml.sort(function (a, b) {
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  return new Date(a.data.date) - new Date(b.data.date);
                });
              }
            } else if (primarySort === 'number') {
              if (isSortedDescendingNumber) {
                pdf_xml.sort((a, b) => {
                  return (
                    /[A-Za-z]/.test(b.data.number) - /[A-Za-z]/.test(a.data.number) ||
                    (b.data.number.toUpperCase() < a.data.number.toUpperCase()
                      ? -1
                      : b.data.number.toUpperCase() > a.data.number.toUpperCase()
                      ? 1
                      : 0)
                  );
                });
              } else {
                pdf_xml.sort((a, b) => {
                  return (
                    /[A-Za-z]/.test(a.data.number) - /[A-Za-z]/.test(b.data.number) ||
                    (a.data.number.toUpperCase() < b.data.number.toUpperCase()
                      ? -1
                      : a.data.number.toUpperCase() > b.data.number.toUpperCase()
                      ? 1
                      : 0)
                  );
                });
              }
            } else if (primarySort === 'customerName') {
              if (isSortedDescendingCustomerName) {
                pdf_xml.sort((a, b) => {
                  if (b.data.customer.name < a.data.customer.name) {
                    return -1;
                  }
                  if (b.data.customer.name > a.data.customer.name) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                pdf_xml.sort((a, b) => {
                  if (a.data.customer.name < b.data.customer.name) {
                    return -1;
                  }
                  if (a.data.customer.name > b.data.customer.name) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'taxId') {
              if (isSortedDescendingTaxId) {
                pdf_xml.sort((a, b) => {
                  if (b.data.customer.taxId < a.data.customer.taxId) {
                    return -1;
                  }
                  if (b.data.customer.taxId > a.data.customer.taxId) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                pdf_xml.sort((a, b) => {
                  if (a.data.customer.taxId < b.data.customer.taxId) {
                    return -1;
                  }
                  if (a.data.customer.taxId > b.data.customer.taxId) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'amountTotal') {
              if (isSortedDescendingAmountTotal) {
                pdf_xml.sort(function (a, b) {
                  return b.data.amountTotal - a.data.amountTotal;
                });
              } else {
                pdf_xml.sort(function (a, b) {
                  return a.data.amountTotal - b.data.amountTotal;
                });
              }
            } else if (primarySort === 'vatTotal') {
              if (isSortedDescendingVatTotal) {
                pdf_xml.sort(function (a, b) {
                  return b.data.vatTotal - a.data.vatTotal;
                });
              } else {
                pdf_xml.sort(function (a, b) {
                  return a.data.vatTotal - b.data.vatTotal;
                });
              }
            } else if (primarySort === 'name') {
              if (isSortedDescendingName) {
                pdf_xml.sort((a, b) => {
                  if (b.data.name < a.data.name) {
                    return -1;
                  }
                  if (b.data.name > a.data.name) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                pdf_xml.sort((a, b) => {
                  if (a.data.name < b.data.name) {
                    return -1;
                  }
                  if (a.data.name > b.data.name) {
                    return 1;
                  }
                  return 0;
                });
              }
            }
          }

          const _docs = [];

          if (docType.key === 'vatTax') {
            if (currentPage > Math.ceil(pdf_xml.length / pageSize)) {
              setCurrentPage(1);
            }

            //set page
            setPageData(paginate(pdf_xml.length, currentPage, pageSize, 10));

            setDocuments(pdf_xml);

            if (pdf_xml.length > 0) {
              for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, pdf_xml.length); i++) {
                let _branchText = '';
                let _amountTotal = 0;
                let _vatTotal = 0;
                let _grandTotal = 0;

                if (pdf_xml[i].data.amountTotal) {
                  _amountTotal = pdf_xml[i].data.amountTotal;
                }

                if (pdf_xml[i].data.vatTotal) {
                  _vatTotal = pdf_xml[i].data.vatTotal;
                }

                if (pdf_xml[i].data.grandTotal) {
                  _grandTotal = pdf_xml[i].data.grandTotal;
                }

                if (
                  pdf_xml[i].data.customer.branchText &&
                  pdf_xml[i].data.customer.branchText !== null &&
                  pdf_xml[i].data.customer.branchText !== undefined &&
                  pdf_xml[i].data.customer.branchText !== ''
                ) {
                  if (
                    pdf_xml[i].data.customer.branchText !== 'สำนักงานใหญ่' &&
                    pdf_xml[i].data.customer.branchText !== '(สำนักงานใหญ่)' &&
                    pdf_xml[i].data.customer.branchText !== 'Head Office'
                  ) {
                    _branchText = pdf_xml[i].data.customer.branchNumber;
                  } else {
                    _branchText = 'สำนักงานใหญ่';
                  }
                }

                let _reason = '';

                if (pdf_xml[i].data.refer?.reasonName) {
                  if (pdf_xml[i].data.refer.reasonName.includes('เหตุอื่น')) {
                    _reason = pdf_xml[i].data.refer.specificReason;
                  } else if (pdf_xml[i].data.refer.reasonName.includes('(')) {
                    let reasonName = pdf_xml[i].data.refer.reasonName;
                    _reason = reasonName.split('(').pop().split(')')[0];
                  } else {
                    _reason = pdf_xml[i].data.refer.reasonName;
                  }
                }

                _docs.push({
                  key: pdf_xml[i].id,
                  row: i + 1,
                  documentDate: pdf_xml[i].data.dateBE,
                  taxId: pdf_xml[i].data.customer.taxId,
                  grandTotal: _grandTotal,
                  vatTotal: _vatTotal,
                  type: pdf_xml[i].data.name,
                  fileExtension: pdf_xml[i].file.extension,
                  number: pdf_xml[i].data.number,
                  signature: pdf_xml[i].signatures[0].certificateName,
                  digitalSign: pdf_xml[i].digitalSign,
                  createdTimeValue: pdf_xml[i].createdTime,
                  createdBy: pdf_xml[i].createdBy,
                  documentDateValue: pdf_xml[i].data.date,
                  customerName: pdf_xml[i].data.customer.name || '-',
                  customerBranchText: _branchText,
                  customerBranchNumber: pdf_xml[i].data.customer.branchNumber,
                  amountTotal: _amountTotal,
                  reIssue: pdf_xml[i].data.reIssue,
                  reason: _reason,
                });
              }

              let _totalVat = 0;
              let _totalGrand = 0;

              let _totalAmount = 0;

              let _nonVatTotal = 0;
              let _zeroVatTotal = 0;
              let _vatPriceTotal = 0;

              for (let i = 0; i < pdf_xml.length; i++) {
                _totalVat = _totalVat + pdf_xml[i].data.vatTotal;
                _totalAmount = _totalAmount + pdf_xml[i].data.amountTotal;
                _totalGrand = _totalGrand + pdf_xml[i].data.grandTotal;

                _nonVatTotal = pdf_xml[i].data.nonVatPriceTotal ? _nonVatTotal + pdf_xml[i].data.nonVatPriceTotal : _nonVatTotal;
                _zeroVatTotal = pdf_xml[i].data.zeroVatPriceTotal ? _zeroVatTotal + pdf_xml[i].data.zeroVatPriceTotal : _zeroVatTotal;
                _vatPriceTotal = pdf_xml[i].data.vatPriceTotal ? _vatPriceTotal + pdf_xml[i].data.vatPriceTotal : _vatPriceTotal;
              }

              setNonVatPriceTotal(Math.round(_nonVatTotal * 1e2) / 1e2);
              setZeroVatPriceTotal(Math.round(_zeroVatTotal * 1e2) / 1e2);
              setVatPriceTotal(Math.round(_vatPriceTotal * 1e2) / 1e2);

              setTotalVat(Math.round(_totalVat * 1e2) / 1e2);
              setTotalGrand(Math.round(_totalGrand * 1e2) / 1e2);
              setTotalAmount(Math.round(_totalAmount * 1e2) / 1e2);

              let _totalExpenseTax = 0;
              let _totalExpenseAmount = 0;

              for (let i = 0; i < expenseNotes.length; i++) {
                _totalExpenseTax = _totalExpenseTax + expenseNotes[i].data.vatTotal;
                _totalExpenseAmount = _totalExpenseAmount + expenseNotes[i].data.amountTotal;
              }

              setTotalExpenseTax(Math.round(_totalExpenseTax * 1e2) / 1e2);
              setTotalExpenseAmount(Math.round(_totalExpenseAmount * 1e2) / 1e2);
            }
          } else {
            if (currentPage > Math.ceil(expenseNotes.length / pageSize)) {
              setCurrentPage(1);
            }

            //set page
            setPageData(paginate(expenseNotes.length, currentPage, pageSize, 10));

            setDocuments(expenseNotes);

            if (expenseNotes.length > 0) {
              for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, expenseNotes.length); i++) {
                let createdTime = toThaiDateString(expenseNotes[i].createdTime);

                let _companyEmail = '';

                let _branchText = '';
                let _customerEmail = '';
                let _customerPhone = '';
                let _amountTotal = 0;
                let _vatTotal = 0;
                let _grandTotal = 0;

                if (
                  expenseNotes[i].data.company &&
                  expenseNotes[i].data.company.email &&
                  expenseNotes[i].data.company.email !== null &&
                  expenseNotes[i].data.company.email !== undefined
                ) {
                  _companyEmail = expenseNotes[i].data.company.email;
                }

                if (
                  expenseNotes[i].data.customer &&
                  expenseNotes[i].data.customer.email &&
                  expenseNotes[i].data.customer.email !== null &&
                  expenseNotes[i].data.customer.email !== undefined
                ) {
                  _customerEmail = expenseNotes[i].data.customer.email;
                }

                if (
                  expenseNotes[i].data.customer &&
                  expenseNotes[i].data.customer.phone &&
                  expenseNotes[i].data.customer.phone !== null &&
                  expenseNotes[i].data.customer.phone !== undefined
                ) {
                  _customerPhone = expenseNotes[i].data.customer.phone;
                }

                if (
                  expenseNotes[i].data.customer.branchText &&
                  expenseNotes[i].data.customer.branchText !== null &&
                  expenseNotes[i].data.customer.branchText !== undefined &&
                  expenseNotes[i].data.customer.branchText !== ''
                ) {
                  if (
                    expenseNotes[i].data.customer.branchText !== 'สำนักงานใหญ่' &&
                    expenseNotes[i].data.customer.branchText !== '(สำนักงานใหญ่)' &&
                    expenseNotes[i].data.customer.branchText !== 'Head Office'
                  ) {
                    _branchText = expenseNotes[i].data.customer.branchNumber;
                  } else {
                    _branchText = 'สำนักงานใหญ่';
                  }
                }

                if (expenseNotes[i].type === 'WITHHOLDING-TAX') {
                  _amountTotal = '-';
                  _vatTotal = '-';
                  _grandTotal = '-';
                } else {
                  if (expenseNotes[i].data.amountTotal) {
                    _amountTotal = expenseNotes[i].data.amountTotal;
                  }

                  if (expenseNotes[i].data.vatTotal) {
                    _vatTotal = expenseNotes[i].data.vatTotal;
                  }

                  if (expenseNotes[i].data.grandTotal) {
                    _grandTotal = expenseNotes[i].data.grandTotal;
                  }
                }

                _docs.push({
                  row: i + 1,
                  key: expenseNotes[i].id,
                  createdBy: expenseNotes[i].createdBy,
                  createdTime: createdTime,
                  createdTimeValue: expenseNotes[i].createdTime,
                  type: 'บันทึกค่าใช้จ่าย',
                  product: expenseNotes[i].product,
                  number: expenseNotes[i].data.number,
                  companyEmail: _companyEmail,
                  customerName: expenseNotes[i].data.customer.name || '-',
                  customerEmail: _customerEmail,
                  customerPhone: _customerPhone,
                  amountTotal: _amountTotal,
                  vatTotal: _vatTotal,
                  grandTotal: _grandTotal,
                  data: expenseNotes[i].data,
                  documentDate: expenseNotes[i].data.dateBE,
                  whtTotal: expenseNotes[i].data.whtTotal || 0,
                  taxId: expenseNotes[i].data.customer.taxId,
                  documentDateValue: expenseNotes[i].data.date,
                  customerBranchText: _branchText,
                  customerBranchNumber: expenseNotes[i].data.customer.branchNumber,
                });
              }

              let _totalVat = 0;
              let _totalGrand = 0;

              let _totalAmount = 0;

              let _nonVatTotal = 0;
              let _zeroVatTotal = 0;
              let _vatPriceTotal = 0;

              for (let i = 0; i < pdf_xml.length; i++) {
                _totalVat = _totalVat + pdf_xml[i].data.vatTotal;
                _totalAmount = _totalAmount + pdf_xml[i].data.amountTotal;
                _totalGrand = _totalGrand + pdf_xml[i].data.grandTotal;

                _nonVatTotal = pdf_xml[i].data.nonVatPriceTotal ? _nonVatTotal + pdf_xml[i].data.nonVatPriceTotal : _nonVatTotal;
                _zeroVatTotal = pdf_xml[i].data.zeroVatPriceTotal ? _zeroVatTotal + pdf_xml[i].data.zeroVatPriceTotal : _zeroVatTotal;
                _vatPriceTotal = pdf_xml[i].data.vatPriceTotal ? _vatPriceTotal + pdf_xml[i].data.vatPriceTotal : _vatPriceTotal;
              }

              setNonVatPriceTotal(Math.round(_nonVatTotal * 1e2) / 1e2);
              setZeroVatPriceTotal(Math.round(_zeroVatTotal * 1e2) / 1e2);
              setVatPriceTotal(Math.round(_vatPriceTotal * 1e2) / 1e2);

              setTotalVat(Math.round(_totalVat * 1e2) / 1e2);
              setTotalGrand(Math.round(_totalGrand * 1e2) / 1e2);
              setTotalAmount(Math.round(_totalAmount * 1e2) / 1e2);

              let _totalExpenseTax = 0;
              let _totalExpenseAmount = 0;

              for (let i = 0; i < expenseNotes.length; i++) {
                _totalExpenseTax = _totalExpenseTax + expenseNotes[i].data.vatTotal;
                _totalExpenseAmount = _totalExpenseAmount + expenseNotes[i].data.amountTotal;
              }

              setTotalExpenseTax(Math.round(_totalExpenseTax * 1e2) / 1e2);
              setTotalExpenseAmount(Math.round(_totalExpenseAmount * 1e2) / 1e2);
            }
          }

          console.log('Docs data', _docs);
          setItems(_docs);

          setLoadDataComplete(true);
          setGetNewDocuments(false);
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
        //   instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    if (previousSearch) {
      if (filterDocuments.length > 0) {
        const _docs = [];

        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, filterDocuments.length); i++) {
          let _vatTotal = 0;
          let _grandTotal = 0;

          if (filterDocuments[i].data.vatTotal) {
            _vatTotal = filterDocuments[i].data.vatTotal;
          }

          if (filterDocuments[i].data.amountTotal) {
            _grandTotal = filterDocuments[i].data.amountTotal;
          }

          _docs.push({
            key: filterDocuments[i].id,
            row: i + 1,
            documentDate: filterDocuments[i].data.dateBE,
            taxId: filterDocuments[i].data.customer.taxId,
            name: filterDocuments[i].data.customer.name,
            grandTotal: _grandTotal,
            vatTotal: _vatTotal,
            type: filterDocuments[i].type,
            fileExtension: filterDocuments[i].file.extension,
            number: filterDocuments[i].data.number,
            signature: filterDocuments[i].signatures[0].certificateName,
            digitalSign: filterDocuments[i].digitalSign,
            createdTimeValue: filterDocuments[i].createdTime,
          });
        }

        setItems(_docs);
      }
    } else {
      if (documents.length > 0) {
        const _docs = [];

        if (docType.key === 'vatTax') {
          for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
            let _branchText = '';
            let _amountTotal = 0;
            let _vatTotal = 0;
            let _grandTotal = 0;

            if (documents[i].data.amountTotal) {
              _amountTotal = documents[i].data.amountTotal;
            }

            if (documents[i].data.vatTotal) {
              _vatTotal = documents[i].data.vatTotal;
            }

            if (documents[i].data.grandTotal) {
              _grandTotal = documents[i].data.grandTotal;
            }

            if (
              documents[i].data.customer.branchText &&
              documents[i].data.customer.branchText !== null &&
              documents[i].data.customer.branchText !== undefined &&
              documents[i].data.customer.branchText !== ''
            ) {
              if (
                documents[i].data.customer.branchText !== 'สำนักงานใหญ่' &&
                documents[i].data.customer.branchText !== '(สำนักงานใหญ่)' &&
                documents[i].data.customer.branchText !== 'Head Office'
              ) {
                _branchText = documents[i].data.customer.branchNumber;
              } else {
                _branchText = 'สำนักงานใหญ่';
              }
            }

            let _reason = '';

            if (documents[i].data.refer.reasonName) {
              if (documents[i].data.refer.reasonName.includes('เหตุอื่น')) {
                _reason = documents[i].data.refer.specificReason;
              } else if (documents[i].data.refer.reasonName.includes('(')) {
                let reasonName = documents[i].data.refer.reasonName;
                _reason = reasonName.split('(').pop().split(')')[0];
              } else {
                _reason = documents[i].data.refer.reasonName;
              }
            }

            _docs.push({
              key: documents[i].id,
              row: i + 1,
              documentDate: documents[i].data.dateBE,
              taxId: documents[i].data.customer.taxId,
              grandTotal: _grandTotal,
              vatTotal: _vatTotal,
              type: documents[i].data.name,
              fileExtension: documents[i].file.extension,
              number: documents[i].data.number,
              signature: documents[i].signatures[0].certificateName,
              digitalSign: documents[i].digitalSign,
              createdTimeValue: documents[i].createdTime,
              createdBy: documents[i].createdBy,
              documentDateValue: documents[i].data.date,
              customerName: documents[i].data.customer.name || '-',
              customerBranchText: _branchText,
              customerBranchNumber: documents[i].data.customer.branchNumber,
              amountTotal: _amountTotal,
              reIssue: documents[i].data.reIssue,
              reason: _reason,
            });
          }
        } else {
          for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
            let createdTime = toThaiDateString(documents[i].createdTime);

            let _companyEmail = '';

            let _branchText = '';
            let _customerEmail = '';
            let _customerPhone = '';
            let _amountTotal = 0;
            let _vatTotal = 0;
            let _grandTotal = 0;

            if (
              documents[i].data.company &&
              documents[i].data.company.email &&
              documents[i].data.company.email !== null &&
              documents[i].data.company.email !== undefined
            ) {
              _companyEmail = documents[i].data.company.email;
            }

            if (
              documents[i].data.customer &&
              documents[i].data.customer.email &&
              documents[i].data.customer.email !== null &&
              documents[i].data.customer.email !== undefined
            ) {
              _customerEmail = documents[i].data.customer.email;
            }

            if (
              documents[i].data.customer &&
              documents[i].data.customer.phone &&
              documents[i].data.customer.phone !== null &&
              documents[i].data.customer.phone !== undefined
            ) {
              _customerPhone = documents[i].data.customer.phone;
            }

            if (
              documents[i].data.customer.branchText &&
              documents[i].data.customer.branchText !== null &&
              documents[i].data.customer.branchText !== undefined &&
              documents[i].data.customer.branchText !== ''
            ) {
              if (
                documents[i].data.customer.branchText !== 'สำนักงานใหญ่' &&
                documents[i].data.customer.branchText !== '(สำนักงานใหญ่)' &&
                documents[i].data.customer.branchText !== 'Head Office'
              ) {
                _branchText = documents[i].data.customer.branchNumber;
              } else {
                _branchText = 'สำนักงานใหญ่';
              }
            }

            if (documents[i].type === 'WITHHOLDING-TAX') {
              _amountTotal = '-';
              _vatTotal = '-';
              _grandTotal = '-';
            } else {
              if (documents[i].data.amountTotal) {
                _amountTotal = documents[i].data.amountTotal;
              }

              if (documents[i].data.vatTotal) {
                _vatTotal = documents[i].data.vatTotal;
              }

              if (documents[i].data.grandTotal) {
                _grandTotal = documents[i].data.grandTotal;
              }
            }

            _docs.push({
              row: i + 1,
              key: documents[i].id,
              createdBy: documents[i].createdBy,
              createdTime: createdTime,
              createdTimeValue: documents[i].createdTime,
              type: 'บันทึกค่าใช้จ่าย',
              product: documents[i].product,
              number: documents[i].data.number,
              companyEmail: _companyEmail,
              customerName: documents[i].data.customer.name || '-',
              customerEmail: _customerEmail,
              customerPhone: _customerPhone,
              amountTotal: _amountTotal,
              vatTotal: _vatTotal,
              grandTotal: _grandTotal,
              data: documents[i].data,
              documentDate: documents[i].data.dateBE,
              whtTotal: documents[i].data.whtTotal || 0,
              taxId: documents[i].data.customer.taxId,
              documentDateValue: documents[i].data.date,
              customerBranchText: _branchText,
              customerBranchNumber: documents[i].data.customer.branchNumber,
            });
          }
        }

        console.log('Docs data', _docs);

        setItems(_docs);
      }
      sessionStorage.setItem('pp30-currentPage', currentPage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const download = (item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        if (docType.key === 'vatTax') {
          axios
            .get(API_URL + '/etax/files/' + item.key, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
              responseType: 'blob',
            })
            .then(
              (response) => {
                console.log(response.headers);

                const time = formatDateTime(item.createdTimeValue);

                let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g, '') + '.' + item.fileExtension;

                console.log('file name: ', fileName);

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
              },
              (error) => {
                console.log(error);
              }
            );
        } else {
          axios
            .post(API_URL + '/expenses/expense-notes/pdf', item.data, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
              responseType: 'blob',
            })
            .then(
              (response) => {
                console.log(response.headers);
                const time = formatDateTime(item.createdTimeValue);

                let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g, '') + '.pdf';
                console.log('file name: ', fileName);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
              },
              (error) => {
                console.log(error);
              }
            );
        }
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const openFile_pdf = (item) => {
    if (docType.key === 'vatTax') {
      history.push({
        pathname: '/etax/documents/pdf/view',
        state: {
          item: item,
          backUrl: '/rdprep/PP30',
        },
      });
    } else {
      history.push({
        pathname: '/expenses/pdf/view',
        state: {
          item: item,
          backUrl: '/rdprep/PP30',
        },
      });
    }
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setSearch('');
    setCurrentPage(selectedPageIndex + 1);
  };

  const columns = [
    {
      key: 'column1',
      name: t('pp30.documentDate'),
      fieldName: 'documentDate',
      minWidth: 80,
      maxWidth: 80,
      isResizable: true,
      isSorted: primarySort === 'documentDate' ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log('Sort document date!');
        setPrimarySort('documentDate');
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        sessionStorage.setItem('pp30-primarySort', 'documentDate');
        sessionStorage.setItem('pp30-sort-documentDate', !isSortedDescendingDocumentDate === true ? 'true' : 'false');

        sessionStorage.setItem('pp30-sort-number', 'false');
        sessionStorage.setItem('pp30-sort-customerName', 'false');
        sessionStorage.setItem('pp30-sort-taxId', 'false');
        sessionStorage.setItem('pp30-sort-amountTotal', 'false');
        sessionStorage.setItem('pp30-sort-vatTotal', 'false');
        sessionStorage.setItem('pp30-sort-grandTotal', 'false');
        sessionStorage.setItem('pp30-sort-name', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalVat(0);
        setTotalGrand(0);
        setTotalExpenseTax(0);
      },
      data: 'number',
      onRender: (item) => {
        return <span>{item.documentDate}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('pp30.number'),
      fieldName: 'number',
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        sessionStorage.setItem('pp30-primarySort', 'number');
        sessionStorage.setItem('pp30-sort-number', !isSortedDescendingNumber === true ? 'true' : 'false');

        sessionStorage.setItem('pp30-sort-documentDate', 'false');
        sessionStorage.setItem('pp30-sort-customerName', 'false');
        sessionStorage.setItem('pp30-sort-taxId', 'false');
        sessionStorage.setItem('pp30-sort-amountTotal', 'false');
        sessionStorage.setItem('pp30-sort-vatTotal', 'false');
        sessionStorage.setItem('pp30-sort-grandTotal', 'false');
        sessionStorage.setItem('pp30-sort-name', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalVat(0);
        setTotalGrand(0);
        setTotalExpenseTax(0);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton
            title={t('pp30.download')}
            className={classNames.fileIconImg}
            onClick={() => {
              openFile_pdf(item);
            }}
          >
            {item.number}
          </ActionButton>
        );
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('pp30.customerName'),
      fieldName: 'customerName',
      minWidth: 100,
      maxWidth: 220,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort customerName!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        sessionStorage.setItem('pp30-primarySort', 'customerName');
        sessionStorage.setItem('pp30-sort-customerName', !isSortedDescendingCustomerName === true ? 'true' : 'false');

        sessionStorage.setItem('pp30-sort-documentDate', 'false');
        sessionStorage.setItem('pp30-sort-number', 'false');
        sessionStorage.setItem('pp30-sort-taxId', 'false');
        sessionStorage.setItem('pp30-sort-amountTotal', 'false');
        sessionStorage.setItem('pp30-sort-vatTotal', 'false');
        sessionStorage.setItem('pp30-sort-grandTotal', 'false');
        sessionStorage.setItem('pp30-sort-name', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalVat(0);
        setTotalGrand(0);
        setTotalExpenseTax(0);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('pp30.taxId'),
      fieldName: 'taxId',
      minWidth: 110,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'taxId' ? true : false,
      isSortedDescending: isSortedDescendingTaxId,
      isSortedAscending: !isSortedDescendingTaxId,
      onColumnClick: () => {
        console.log('Sort taxId!');
        setPrimarySort('taxId');
        setIsSortedDescendingTaxId(!isSortedDescendingTaxId);

        sessionStorage.setItem('pp30-primarySort', 'taxId');
        sessionStorage.setItem('pp30-sort-taxId', !isSortedDescendingTaxId === true ? 'true' : 'false');

        sessionStorage.setItem('pp30-sort-documentDate', 'false');
        sessionStorage.setItem('pp30-sort-number', 'false');
        sessionStorage.setItem('pp30-sort-customerName', 'false');
        sessionStorage.setItem('pp30-sort-amountTotal', 'false');
        sessionStorage.setItem('pp30-sort-vatTotal', 'false');
        sessionStorage.setItem('pp30-sort-grandTotal', 'false');
        sessionStorage.setItem('pp30-sort-name', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalVat(0);
        setTotalGrand(0);
        setTotalExpenseTax(0);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.taxId}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('pp30.branchNumber'),
      fieldName: 'branchNumber',
      minWidth: 40,
      maxWidth: 90,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerBranchNumber}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: t('pp30.amountTotal'),
      fieldName: 'amountTotal',
      minWidth: 70,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'amountTotal' ? true : false,
      isSortedDescending: isSortedDescendingAmountTotal,
      isSortedAscending: !isSortedDescendingAmountTotal,
      onColumnClick: () => {
        console.log('Sort amountTotal!');
        setPrimarySort('amountTotal');
        setIsSortedDescendingAmountTotal(!isSortedDescendingAmountTotal);

        sessionStorage.setItem('pp30-primarySort', 'amountTotal');
        sessionStorage.setItem('pp30-sort-amountTotal', !isSortedDescendingAmountTotal === true ? 'true' : 'false');

        sessionStorage.setItem('pp30-sort-documentDate', 'false');
        sessionStorage.setItem('pp30-sort-number', 'false');
        sessionStorage.setItem('pp30-sort-customerName', 'false');
        sessionStorage.setItem('pp30-sort-taxId', 'false');
        sessionStorage.setItem('pp30-sort-vatTotal', 'false');
        sessionStorage.setItem('pp30-sort-grandTotal', 'false');
        sessionStorage.setItem('pp30-sort-name', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalVat(0);
        setTotalGrand(0);
        setTotalExpenseTax(0);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {!item.reIssue
              ? item.amountTotal.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : ''}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: t('pp30.vatTotal'),
      fieldName: 'vatTotal',
      minWidth: 60,
      maxWidth: 60,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'vatTotal' ? true : false,
      isSortedDescending: isSortedDescendingVatTotal,
      isSortedAscending: !isSortedDescendingVatTotal,
      onColumnClick: () => {
        console.log('Sort vatTotal!');
        setPrimarySort('vatTotal');
        setIsSortedDescendingVatTotal(!isSortedDescendingVatTotal);

        sessionStorage.setItem('pp30-primarySort', 'vatTotal');
        sessionStorage.setItem('pp30-sort-vatTotal', !isSortedDescendingVatTotal === true ? 'true' : 'false');

        sessionStorage.setItem('pp30-sort-documentDate', 'false');
        sessionStorage.setItem('pp30-sort-number', 'false');
        sessionStorage.setItem('pp30-sort-customerName', 'false');
        sessionStorage.setItem('pp30-sort-taxId', 'false');
        sessionStorage.setItem('pp30-sort-amountTotal', 'false');
        sessionStorage.setItem('pp30-sort-grandTotal', 'false');
        sessionStorage.setItem('pp30-sort-name', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalVat(0);
        setTotalGrand(0);
        setTotalExpenseTax(0);
      },
      data: 'number',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {!item.reIssue
              ? item.vatTotal.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : ''}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column8',
      name: t('pp30.document'),
      fieldName: 'name',
      minWidth: 150,
      maxWidth: 180,
      isRowHeader: true,
      isResizable: true,
      isCollapsible: true,
      isSorted: primarySort === 'name' ? true : false,
      isSortedDescending: isSortedDescendingName,
      isSortedAscending: !isSortedDescendingName,
      onColumnClick: () => {
        console.log('Sort name!');
        setPrimarySort('name');
        setIsSortedDescendingName(!isSortedDescendingName);

        sessionStorage.setItem('pp30-primarySort', 'name');
        sessionStorage.setItem('pp30-sort-name', !isSortedDescendingName === true ? 'true' : 'false');

        sessionStorage.setItem('pp30-sort-documentDate', 'false');
        sessionStorage.setItem('pp30-sort-number', 'false');
        sessionStorage.setItem('pp30-sort-customerName', 'false');
        sessionStorage.setItem('pp30-sort-taxId', 'false');
        sessionStorage.setItem('pp30-sort-amountTotal', 'false');
        sessionStorage.setItem('pp30-sort-vatTotal', 'false');
        sessionStorage.setItem('pp30-sort-grandTotal', 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalVat(0);
        setTotalGrand(0);
        setTotalExpenseTax(0);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton
            title={t('pp30.openFile')}
            className={classNames.fileIconImg}
            onClick={() => {
              openFile_pdf(item);
            }}
          >
            {item.type}
          </ActionButton>
        );
      },
      isPadded: true,
    },
    {
      key: 'column9',
      name: '',
      fieldName: 'download',
      minWidth: 90,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        return (
          <DefaultButton
            text={t('pp30.select')}
            menuProps={{
              items: [
                {
                  key: 'open',
                  text: t('pp30.openDocument'),
                  iconProps: { iconName: 'OpenFile' },
                  onClick: () => {
                    openFile_pdf(item);
                  },
                },
                {
                  key: 'download',
                  text: t('pp30.download'),
                  iconProps: { iconName: 'DownloadDocument' },
                  onClick: () => {
                    download(item);
                  },
                },
                {
                  key: 'divider_1',
                  itemType: ContextualMenuItemType.Divider,
                },
                {
                  key: 'email',
                  text: t('pp30.email'),
                  iconProps: { iconName: 'MailForward' },
                  onClick: () => {
                    setIsClickEmail(true);
                    setFileNameEmail(item.name);
                    setCustomerEmail(item.customerEmail);
                    setEmailList(item.customerEmail ? [item.customerEmail] : []);
                    setCopyEmailList(item.companyEmail ? [item.companyEmail] : [])
                    setCopyEmail(item.companyEmail);
                    setFileIdEmail(item.key);
                  },
                },
                {
                  key: 'sms',
                  text: 'SMS',
                  iconProps: { iconName: 'Message' },
                  onClick: () => {
                    setIsClickSms(true);
                    setFileNameSms(item.name);
                    setCustomerPhone(item.customerPhone);
                    setFileIdSms(item.key);
                  },
                },
              ],
              shouldFocusOnMount: true,
              directionalHintFixed: true,
            }}
            // Optional callback to customize menu rendering
            menuAs={(props) => <ContextualMenu {...props} />}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (search !== '') {
      let _filterDocument =
        documents.filter(
          (document) =>
            String(document.data.vatTotal).includes(search) ||
            String(document.data.amountTotal).includes(search) ||
            document.data.customer.taxId.includes(search) ||
            document.data.customer.name.includes(search)
        ) || [];

      const _docs = [];

      if (docType.key === 'vatTax') {
        if (_filterDocument.length) {
          for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, _filterDocument.length); i++) {
            let _branchText = '';
            let _amountTotal = 0;
            let _vatTotal = 0;
            let _grandTotal = 0;

            if (_filterDocument[i].data.amountTotal) {
              _amountTotal = _filterDocument[i].data.amountTotal;
            }

            if (_filterDocument[i].data.vatTotal) {
              _vatTotal = _filterDocument[i].data.vatTotal;
            }

            if (_filterDocument[i].data.grandTotal) {
              _grandTotal = _filterDocument[i].data.grandTotal;
            }

            if (
              _filterDocument[i].data.customer.branchText &&
              _filterDocument[i].data.customer.branchText !== null &&
              _filterDocument[i].data.customer.branchText !== undefined &&
              _filterDocument[i].data.customer.branchText !== ''
            ) {
              if (
                _filterDocument[i].data.customer.branchText !== 'สำนักงานใหญ่' &&
                _filterDocument[i].data.customer.branchText !== '(สำนักงานใหญ่)' &&
                _filterDocument[i].data.customer.branchText !== 'Head Office'
              ) {
                _branchText = _filterDocument[i].data.customer.branchNumber;
              } else {
                _branchText = 'สำนักงานใหญ่';
              }
            }

            let _reason = '';

            if (_filterDocument[i].data.refer.reasonName) {
              if (_filterDocument[i].data.refer.reasonName.includes('เหตุอื่น')) {
                _reason = _filterDocument[i].data.refer.specificReason;
              } else if (_filterDocument[i].data.refer.reasonName.includes('(')) {
                let reasonName = _filterDocument[i].data.refer.reasonName;
                _reason = reasonName.split('(').pop().split(')')[0];
              } else {
                _reason = _filterDocument[i].data.refer.reasonName;
              }
            }

            _docs.push({
              key: _filterDocument[i].id,
              row: i + 1,
              documentDate: _filterDocument[i].data.dateBE,
              taxId: _filterDocument[i].data.customer.taxId,
              grandTotal: _grandTotal,
              vatTotal: _vatTotal,
              type: _filterDocument[i].data.name,
              fileExtension: _filterDocument[i].file.extension,
              number: _filterDocument[i].data.number,
              signature: _filterDocument[i].signatures[0].certificateName,
              digitalSign: _filterDocument[i].digitalSign,
              createdTimeValue: _filterDocument[i].createdTime,
              createdBy: _filterDocument[i].createdBy,
              documentDateValue: _filterDocument[i].data.date,
              customerName: _filterDocument[i].data.customer.name || '-',
              customerBranchText: _branchText,
              customerBranchNumber: _filterDocument[i].data.customer.branchNumber,
              amountTotal: _amountTotal,
              reIssue: _filterDocument[i].data.reIssue,
              reason: _reason,
            });
          }
        }
      } else {
        if (_filterDocument.length) {
          for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, _filterDocument.length); i++) {
            let createdTime = toThaiDateString(_filterDocument[i].createdTime);

            let _companyEmail = '';

            let _branchText = '';
            let _customerEmail = '';
            let _customerPhone = '';
            let _amountTotal = 0;
            let _vatTotal = 0;
            let _grandTotal = 0;

            if (
              _filterDocument[i].data.company &&
              _filterDocument[i].data.company.email &&
              _filterDocument[i].data.company.email !== null &&
              _filterDocument[i].data.company.email !== undefined
            ) {
              _companyEmail = _filterDocument[i].data.company.email;
            }

            if (
              _filterDocument[i].data.customer &&
              _filterDocument[i].data.customer.email &&
              _filterDocument[i].data.customer.email !== null &&
              _filterDocument[i].data.customer.email !== undefined
            ) {
              _customerEmail = _filterDocument[i].data.customer.email;
            }

            if (
              _filterDocument[i].data.customer &&
              _filterDocument[i].data.customer.phone &&
              _filterDocument[i].data.customer.phone !== null &&
              _filterDocument[i].data.customer.phone !== undefined
            ) {
              _customerPhone = _filterDocument[i].data.customer.phone;
            }

            if (
              _filterDocument[i].data.customer.branchText &&
              _filterDocument[i].data.customer.branchText !== null &&
              _filterDocument[i].data.customer.branchText !== undefined &&
              _filterDocument[i].data.customer.branchText !== ''
            ) {
              if (
                _filterDocument[i].data.customer.branchText !== 'สำนักงานใหญ่' &&
                _filterDocument[i].data.customer.branchText !== '(สำนักงานใหญ่)' &&
                _filterDocument[i].data.customer.branchText !== 'Head Office'
              ) {
                _branchText = _filterDocument[i].data.customer.branchNumber;
              } else {
                _branchText = 'สำนักงานใหญ่';
              }
            }

            if (_filterDocument[i].type === 'WITHHOLDING-TAX') {
              _amountTotal = '-';
              _vatTotal = '-';
              _grandTotal = '-';
            } else {
              if (_filterDocument[i].data.amountTotal) {
                _amountTotal = _filterDocument[i].data.amountTotal;
              }

              if (_filterDocument[i].data.vatTotal) {
                _vatTotal = _filterDocument[i].data.vatTotal;
              }

              if (_filterDocument[i].data.grandTotal) {
                _grandTotal = _filterDocument[i].data.grandTotal;
              }
            }

            _docs.push({
              row: i + 1,
              key: _filterDocument[i].id,
              createdBy: _filterDocument[i].createdBy,
              createdTime: createdTime,
              createdTimeValue: _filterDocument[i].createdTime,
              type: 'บันทึกค่าใช้จ่าย',
              product: _filterDocument[i].product,
              number: _filterDocument[i].data.number,
              companyEmail: _companyEmail,
              customerName: _filterDocument[i].data.customer.name || '-',
              customerEmail: _customerEmail,
              customerPhone: _customerPhone,
              amountTotal: _amountTotal,
              vatTotal: _vatTotal,
              grandTotal: _grandTotal,
              data: _filterDocument[i].data,
              documentDate: _filterDocument[i].data.dateBE,
              whtTotal: _filterDocument[i].data.whtTotal || 0,
              taxId: _filterDocument[i].data.customer.taxId,
              documentDateValue: _filterDocument[i].data.date,
              customerBranchText: _branchText,
              customerBranchNumber: _filterDocument[i].data.customer.branchNumber,
            });
          }
        }
      }

      setItems(_docs);
    } else {
      if (documents.length > 0) {
        const _docs = [];

        if (docType.key === 'vatTax') {
          for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
            let _branchText = '';
            let _amountTotal = 0;
            let _vatTotal = 0;
            let _grandTotal = 0;

            if (documents[i].data.amountTotal) {
              _amountTotal = documents[i].data.amountTotal;
            }

            if (documents[i].data.vatTotal) {
              _vatTotal = documents[i].data.vatTotal;
            }

            if (documents[i].data.grandTotal) {
              _grandTotal = documents[i].data.grandTotal;
            }

            if (
              documents[i].data.customer.branchText &&
              documents[i].data.customer.branchText !== null &&
              documents[i].data.customer.branchText !== undefined &&
              documents[i].data.customer.branchText !== ''
            ) {
              if (
                documents[i].data.customer.branchText !== 'สำนักงานใหญ่' &&
                documents[i].data.customer.branchText !== '(สำนักงานใหญ่)' &&
                documents[i].data.customer.branchText !== 'Head Office'
              ) {
                _branchText = documents[i].data.customer.branchNumber;
              } else {
                _branchText = 'สำนักงานใหญ่';
              }
            }

            let _reason = '';

            if (documents[i].data.refer.reasonName) {
              if (documents[i].data.refer.reasonName.includes('เหตุอื่น')) {
                _reason = documents[i].data.refer.specificReason;
              } else if (documents[i].data.refer.reasonName.includes('(')) {
                let reasonName = documents[i].data.refer.reasonName;
                _reason = reasonName.split('(').pop().split(')')[0];
              } else {
                _reason = documents[i].data.refer.reasonName;
              }
            }

            _docs.push({
              key: documents[i].id,
              row: i + 1,
              documentDate: documents[i].data.dateBE,
              taxId: documents[i].data.customer.taxId,
              grandTotal: _grandTotal,
              vatTotal: _vatTotal,
              type: documents[i].data.name,
              fileExtension: documents[i].file.extension,
              number: documents[i].data.number,
              signature: documents[i].signatures[0].certificateName,
              digitalSign: documents[i].digitalSign,
              createdTimeValue: documents[i].createdTime,
              createdBy: documents[i].createdBy,
              documentDateValue: documents[i].data.date,
              customerName: documents[i].data.customer.name || '-',
              customerBranchText: _branchText,
              customerBranchNumber: documents[i].data.customer.branchNumber,
              amountTotal: _amountTotal,
              reIssue: documents[i].data.reIssue,
              reason: _reason,
            });
          }
        } else {
          for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
            let createdTime = toThaiDateString(documents[i].createdTime);

            let _companyEmail = '';

            let _branchText = '';
            let _customerEmail = '';
            let _customerPhone = '';
            let _amountTotal = 0;
            let _vatTotal = 0;
            let _grandTotal = 0;

            if (
              documents[i].data.company &&
              documents[i].data.company.email &&
              documents[i].data.company.email !== null &&
              documents[i].data.company.email !== undefined
            ) {
              _companyEmail = documents[i].data.company.email;
            }

            if (
              documents[i].data.customer &&
              documents[i].data.customer.email &&
              documents[i].data.customer.email !== null &&
              documents[i].data.customer.email !== undefined
            ) {
              _customerEmail = documents[i].data.customer.email;
            }

            if (
              documents[i].data.customer &&
              documents[i].data.customer.phone &&
              documents[i].data.customer.phone !== null &&
              documents[i].data.customer.phone !== undefined
            ) {
              _customerPhone = documents[i].data.customer.phone;
            }

            if (
              documents[i].data.customer.branchText &&
              documents[i].data.customer.branchText !== null &&
              documents[i].data.customer.branchText !== undefined &&
              documents[i].data.customer.branchText !== ''
            ) {
              if (
                documents[i].data.customer.branchText !== 'สำนักงานใหญ่' &&
                documents[i].data.customer.branchText !== '(สำนักงานใหญ่)' &&
                documents[i].data.customer.branchText !== 'Head Office'
              ) {
                _branchText = documents[i].data.customer.branchNumber;
              } else {
                _branchText = 'สำนักงานใหญ่';
              }
            }

            if (documents[i].type === 'WITHHOLDING-TAX') {
              _amountTotal = '-';
              _vatTotal = '-';
              _grandTotal = '-';
            } else {
              if (documents[i].data.amountTotal) {
                _amountTotal = documents[i].data.amountTotal;
              }

              if (documents[i].data.vatTotal) {
                _vatTotal = documents[i].data.vatTotal;
              }

              if (documents[i].data.grandTotal) {
                _grandTotal = documents[i].data.grandTotal;
              }
            }

            _docs.push({
              row: i + 1,
              key: documents[i].id,
              createdBy: documents[i].createdBy,
              createdTime: createdTime,
              createdTimeValue: documents[i].createdTime,
              type: 'บันทึกค่าใช้จ่าย',
              product: documents[i].product,
              number: documents[i].data.number,
              companyEmail: _companyEmail,
              customerName: documents[i].data.customer.name || '-',
              customerEmail: _customerEmail,
              customerPhone: _customerPhone,
              amountTotal: _amountTotal,
              vatTotal: _vatTotal,
              grandTotal: _grandTotal,
              data: documents[i].data,
              documentDate: documents[i].data.dateBE,
              whtTotal: documents[i].data.whtTotal || 0,
              taxId: documents[i].data.customer.taxId,
              documentDateValue: documents[i].data.date,
              customerBranchText: _branchText,
              customerBranchNumber: documents[i].data.customer.branchNumber,
            });
          }
        }

        console.log('Docs data', _docs);

        setItems(_docs);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const isCompactMode = false;

  function TextFieldEmailComponent(props) {
    return (
      <TextFieldEmail 
        style={{ width: 240}}
        selectedItems={[...emailList.map(_email => (
          { key: _email, text: _email }
        ))]}
        onItemsRemoved={(removeItems, removeItemIndex) => {
          const currentSelectedItemsCopy = [...emailList];
          currentSelectedItemsCopy.splice(removeItemIndex, 1);
          // this.setState({ currentSelectedItems: [...currentSelectedItemsCopy] });
          setEmailList([...currentSelectedItemsCopy])

          let multiEmail = '';

          currentSelectedItemsCopy.forEach((email, index) => {
            multiEmail = multiEmail + email;

            if (currentSelectedItemsCopy.length - 1 !== index) {
              multiEmail = multiEmail + ';';
            }
          });
          setCustomerEmail(multiEmail);
        }}
        onBlur={(e) => {
          if (checkEmail(e.target.value) && !emailList.includes(e.target.value)) {
              const currentSelectedItemsCopy = [...emailList, e.target.value];

              setEmailList(currentSelectedItemsCopy)

              let multiEmail = '';

              currentSelectedItemsCopy.forEach((email, index) => {
                multiEmail = multiEmail + email;

                if (currentSelectedItemsCopy.length - 1 !== index) {
                  multiEmail = multiEmail + ';';
                }
              });
              setCustomerEmail(multiEmail);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
              if (checkEmail(e.target.value) && !emailList.includes(e.target.value)) {
                  const currentSelectedItemsCopy = [...emailList, e.target.value];

                  setEmailList(currentSelectedItemsCopy)

                  let multiEmail = '';

                  currentSelectedItemsCopy.forEach((email, index) => {
                    multiEmail = multiEmail + email;

                    if (currentSelectedItemsCopy.length - 1 !== index) {
                      multiEmail = multiEmail + ';';
                    }
                  });
                  setCustomerEmail(multiEmail);

              }
          }
        }}
      />
    );
  }

  function TextFieldCopyEmailComponent(props) {
    return (
      <TextFieldEmail 
        style={{ width: 240}}
        selectedItems={[...copyEmailList.map(_email => (
          { key: _email, text: _email }
        ))]}
        onItemsRemoved={(removeItems, removeItemIndex) => {
          const currentSelectedItemsCopy = [...copyEmailList];
          currentSelectedItemsCopy.splice(removeItemIndex, 1);
          // this.setState({ currentSelectedItems: [...currentSelectedItemsCopy] });
          setCopyEmailList([...currentSelectedItemsCopy])

          let multiEmail = '';

          currentSelectedItemsCopy.forEach((email, index) => {
            multiEmail = multiEmail + email;

            if (currentSelectedItemsCopy.length - 1 !== index) {
              multiEmail = multiEmail + ';';
            }
          });
          setCopyEmail(multiEmail);
        }}
        onBlur={(e) => {
          if (checkEmail(e.target.value) && !copyEmailList.includes(e.target.value)) {
              const currentSelectedItemsCopy = [...copyEmailList, e.target.value];

              setCopyEmailList(currentSelectedItemsCopy)

              let multiEmail = '';

              currentSelectedItemsCopy.forEach((email, index) => {
                multiEmail = multiEmail + email;

                if (currentSelectedItemsCopy.length - 1 !== index) {
                  multiEmail = multiEmail + ';';
                }
              });
              setCopyEmail(multiEmail);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
              if (checkEmail(e.target.value) && !copyEmailList.includes(e.target.value)) {
                  const currentSelectedItemsCopy = [...copyEmailList, e.target.value];

                  setCopyEmailList(currentSelectedItemsCopy)

                  let multiEmail = '';

                  currentSelectedItemsCopy.forEach((email, index) => {
                    multiEmail = multiEmail + email;

                    if (currentSelectedItemsCopy.length - 1 !== index) {
                      multiEmail = multiEmail + ';';
                    }
                  });
                  setCopyEmail(multiEmail);

              }
          }
        }}
      />
    );
  }

  return (
    <>
      <div style={homeStyles}>
        {showEmailSuccess && (
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => setShowEmailSuccess(false)}
            dismissButtonAriaLabel="Close"
            style={{
              fontSize: 14,
            }}
          >
            {t('pp30.sendEmailSuccess')}
          </MessageBar>
        )}
        {showEmailError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setShowEmailError(false)}
            dismissButtonAriaLabel="Close"
            style={{
              fontSize: 14,
            }}
          >
            {t('pp30.sendEmailFail')}
          </MessageBar>
        )}

        {showSmsSuccess && (
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => setShowSmsSuccess(false)}
            dismissButtonAriaLabel="Close"
            style={{
              fontSize: 14,
            }}
          >
            {t('pp30.sendSmsSuccess')}
          </MessageBar>
        )}

        {showSmsError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setShowSmsError(false)}
            dismissButtonAriaLabel="Close"
            style={{
              fontSize: 14,
            }}
          >
            {t('pp30.sendSmsFail')}
          </MessageBar>
        )}

        {(showEmailSuccess || showEmailError || showSmsSuccess || showSmsError) && <br />}
        <Stack>
          <Stack vertical horizontalAlign="center">
            <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('pp30.header')}</h2>
            <h4 style={{ marginLeft: '0px', marginTop: '0px' }}>{t('pp30.subHeader')}</h4>
          </Stack>

          <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
            <DocumentCard aria-label={t('pp30.totalVat')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Bank' }} title={t('pp30.totalVat')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('pp30.totalVat')} styles={cardStyles.header} />
                <Text styles={cardStyles.amount}>
                  <CountUp end={totalVat} separator="," decimals={2} decimal="." duration={1} /> {t('pp30.thb')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>

            <DocumentCard aria-label={t('pp30.totalExpenseTax')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Bank' }} title={t('pp30.totalExpenseTax')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('pp30.totalExpenseTax')} styles={cardStyles.header} />
                <Text styles={cardStyles.amount}>
                  <CountUp end={totalExpenseTax} separator="," decimals={2} decimal="." duration={1} /> {t('pp30.thb')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>

            <DocumentCard aria-label={t('pp30.vatDiff')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Money' }} title={t('pp30.vatDiff')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('pp30.vatDiff')} styles={cardStyles.header} />
                <Text styles={cardStyles.amount}>
                  <CountUp end={totalVat - totalExpenseTax} separator="," decimals={2} decimal="." duration={1} /> {t('pp30.thb')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>
          </Stack>
        </Stack>

        {totalProcessingDocuments > 0 && (
          <div>
            <br />
            <Spinner label={t('pp30.signProcessing')} size={SpinnerSize.large} />
          </div>
        )}

        {downloading && (
          <div>
            <br />
            <Spinner label={t('pp30.loading')} size={SpinnerSize.large} />
          </div>
        )}

        <br />
        <Stack horizontal horizontalAlign="center">
          <Stack
            vertical
            style={{
              width: '98%',
              minWidth: '800px',
              maxWidth: '1350px',
            }}
          >
            <Stack 
              horizontal 
              styles={{
                root: {
                  backgroundColor: 'white',
                },
              }} 
              horizontalAlign="space-between"
            >
              <CommandBar
                items={commandBarItems}
                styles={{
                  root: {
                    paddingLeft: 8,
                    width: 950,
                  },
                }}
              />
              {loadDataComplete && (
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                      paddingRight: 8,
                    },
                  }}
                  tokens={{ childrenGap: '10px' }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        marginLeft: 6,
                        width: 200,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete}
                    placeholder={t('pp30.searchPlaceholder')}
                    onChange={(e) => setSearch(e ? e.target.value.replace(/\s/g, '') : '')}
                    onClear={() => {
                      setSearch('');
                    }}
                    value={search}
                  />
                </Stack>
              )}
            </Stack>
            {items.length ? (
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
              />
            ) : (
              <ShimmeredDetailsList
                items={[{}]}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderRow={() => {
                  return (
                    <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: '#FFFFFF', height: 55 } }}>
                      <Text>{t('documents.noItemFound')}</Text>
                    </Stack>
                  );
                }}
              />
            )}
          </Stack>
        </Stack>
        <br />
        <center>
          {!(documents.length === 0 || documents.length < pageSize) && loadDataComplete && (
            <Pagination
              selectedPageIndex={currentPage - 1}
              pageCount={pageData.totalPages}
              onPageChange={onPageChange}
              format
              firstPageIconProps={{
                iconName: 'DoubleChevronLeft',
              }}
              previousPageIconProps={{
                iconName: 'ChevronLeft',
              }}
              nextPageIconProps={{ iconName: 'ChevronRight' }}
              lastPageIconProps={{
                iconName: 'DoubleChevronRight',
              }}
            />
          )}
          <br />
          <br />
        </center>

        <CSVLink style={{ display: 'none' }} ref={csvLink} filename={CSVFileName} data={CSV} headers={headerCSV} enclosingCharacter="">
          Download csv
        </CSVLink>

        <Dialog
          hidden={!isClickEmail}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: t('pp30.sendFileByEmail'),
            subText: fileNameEmail,
          }}
        >
          <Stack vertical>
            <div>
              <label>{t('pp30.sendToEmail')}</label>
            </div>
            <TextFieldEmailComponent />
            <br />
            <Checkbox
              label={t('pp30.ccToEmail')}
              checked={isClickCopyEmail}
              onChange={(e) => {
                console.log('copy email checkbox: ', e.target.value);
                setIsClickCopyEmail((prev) => !prev);
              }}
            />
            {isClickCopyEmail && (
              <div style={{ marginTop: '8px' }}>
                {/* <TextField
                  value={copyEmail}
                  onChange={(e) => {
                    setCopyEmail(e.target.value);
                    setErrorMessageCopyEmail('');
                  }}
                  errorMessage={errorMessageCopyEmail}
                /> */}
                <TextFieldCopyEmailComponent />
              </div>
            )}
            <br />
            <br />
          </Stack>
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                if (isClickCopyEmail) {
                  sendEmail(fileDisplayName, fileIdEmail, customerEmail, copyEmail);
                } else {
                  sendEmail(fileDisplayName, fileIdEmail, customerEmail, '');
                }
              }}
              text={t('pp30.sendEmail')}
            />
            <DefaultButton onClick={() => setIsClickEmail(false)} text={t('pp30.cancel')} />
          </DialogFooter>
        </Dialog>

        <Dialog
          hidden={!isClickSms}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: t('pp30.sendFileBySms'),
            subText: fileNameSms,
          }}
        >
          <TextField
            label={t('pp30.mobileNumer')}
            value={customerPhone}
            required
            onChange={(e) => {
              setCustomerPhone(e.target.value);
              setErrorMessageSms('');
            }}
            errorMessage={errorMessageSms}
            description={t('pp30.exampleNumber')}
          />
          <br />
          <br />
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                sendSms(fileIdSms, customerPhone);
              }}
              text={t('pp30.sendSms')}
            />
            <DefaultButton onClick={() => setIsClickSms(false)} text={t('pp30.cancel')} />
          </DialogFooter>
        </Dialog>
      </div>
    </>
  );
};

export default PP30;
