import React, { useState, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { TextField, PrimaryButton, DefaultButton, Stack, getTheme } from '@fluentui/react';

const theme = getTheme();

const stackTokens = { childrenGap: 15 };

const Address = () => {
  console.log('Re render');

  const history = useHistory();
  const location = useLocation();

  const [data] = useState(location.state);

  if (data && data.id === '') {
    history.push({
      pathname: '/etax/signatures/register/companies',
      state: {},
    });
  }

  const [period, setPeriod] = useState(1);
  const [taxId, setTaxId] = useState('');
  const [type, setType] = useState('');
  const [nameTH, setNameTH] = useState('');
  const [nameEN, setNameEN] = useState('');
  const [email, setEmail] = useState('');

  const [id, setId] = useState('');
  const [address, setAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [district, setDistrict] = useState('');
  const [province, setProvince] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [contactMobile, setContactMobile] = useState('');
  const [contactEmail, setContactEmail] = useState('');

  function handleSubmit(event) {
    event.preventDefault();

    let isCorrect = true;

    if (type === undefined || type === null || type === '') {
      isCorrect = false;
    }

    if (isCorrect) {
      history.push({
        pathname: '/ca/contacts',
        state: {
          period: period,
          taxId: taxId,
          type: type,
          nameTH: nameTH,
          nameEN: nameEN,
          email: email,

          id: id,
          address: address,
          postcode: postcode,
          district: district,
          province: province,
          phone: phone,
          mobile: mobile,

          firstName: firstName,
          lastName: lastName,
          contactPhone: contactPhone,
          contactMobile: contactMobile,
          contactEmail: contactEmail,
        },
      });
    }
  }

  const handleEdit = (e) => {
    e.preventDefault();

    history.push({
      pathname: '/ca/companies',
      state: {
        period: period,
        taxId: taxId,
        type: type,
        nameTH: nameTH,
        nameEN: nameEN,
        email: email,

        id: id,
        address: address,
        postcode: postcode,
        district: district,
        province: province,
        phone: phone,
        mobile: mobile,

        firstName: firstName,
        lastName: lastName,
        contactPhone: contactPhone,
        contactMobile: contactMobile,
        contactEmail: contactEmail,
      },
    });
  };

  const getErrorMessage = (value) => {
    return value && value !== '0' ? '' : 'กรุณากรอกข้อมูล';
  };

  useEffect(() => {
    console.log('useEffect data call!: ', data);

    if (data !== undefined) {
      setPeriod(data.period);
      setTaxId(data.taxId);
      setType(data.type);
      setNameTH(data.nameTH);
      setNameEN(data.nameEN);
      setEmail(data.email);

      setId(data.id);
      setAddress(data.address);
      setPostcode(data.postcode);
      setDistrict(data.district);
      setProvince(data.province);
      setPhone(data.phone);
      setMobile(data.mobile);

      setFirstName(data.firstName);
      setLastName(data.lastName);
      setContactPhone(data.contactPhone);
      setContactMobile(data.contactMobile);
      setContactEmail(data.contactEmail);
    }
  }, [data]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h2 style={{ marginLeft: '2%', marginTop: '4%' }}>กรอกข้อมูลเพื่อขอใบรับรองอิเล็กทรอนิกส์</h2>
        <div
          className="ms-Grid"
          dir="ltr"
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            paddingBottom: 30,
            background: '#ffffff',
            margin: '2%',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <div className="ms-Grid-row">
            <Stack horizontal tokens={stackTokens}>
              <TextField
                name="taxId"
                label="เลขประจำตัวผู้เสียภาษี"
                placeholder="กรอกเลข 13 หลัก"
                styles={{ root: { width: 200 } }}
                value={taxId}
                onChange={(e) => setTaxId(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />

              <TextField
                name="id"
                label="เลขทะเบียนนิติบุคคล"
                styles={{ root: { width: 200 } }}
                value={id}
                onChange={(e) => setId(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />
            </Stack>

            <br />
            <Stack horizontal tokens={stackTokens}>
              <TextField
                name="address"
                label="ที่อยู่"
                placeholder="ที่อยู่ตามหนังสือรับรองนิติบุคคล (บ้านเลขที่, หมู่บ้าน, ซอย, ถนน, ตำบล/แขวง"
                styles={{ root: { width: 630 } }}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />
            </Stack>

            <br />
            <Stack horizontal tokens={stackTokens}>
              <TextField
                name="postcode"
                label="รหัสไปรษณีย์"
                styles={{ root: { width: 200 } }}
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />
              <TextField
                name="district"
                label="อำเภอ/เขต"
                styles={{ root: { width: 200 } }}
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />
              <TextField
                name="province"
                label="จังหวัด"
                styles={{ root: { width: 200 } }}
                value={province}
                onChange={(e) => setProvince(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />
            </Stack>

            <br />
            <Stack horizontal tokens={stackTokens}>
              <TextField
                name="phone"
                label="โทรศัพท์"
                placeholder="ตัวอย่าง: 021234567"
                styles={{ root: { width: 200 } }}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />
              <TextField
                name="mobile"
                label="โทรศัพท์มือถือ"
                placeholder="ตัวอย่าง: 0812345678"
                styles={{ root: { width: 200 } }}
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                onGetErrorMessage={getErrorMessage}
                validateOnLoad={false}
                validateOnFocusOut
                required
              />
            </Stack>

            <br />
            <br />

            <PrimaryButton name="next" text="ต่อไป" type="submit" />
            <DefaultButton text="ย้อนกลับ" style={{ marginLeft: '15px' }} onClick={handleEdit} />

            <br />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Address;
