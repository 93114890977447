import { numberToTextEn, numberToTextTh } from "../../../../helpers/BahtWord";

export default function ConvertType(passItem) {
  const fields = {
    customer: {
      name: {
        type: 'string',
      },
      addressLineOne: {
        type: 'string',
      },
      addressLineTwo: {
        type: 'string',
      },
      postcode: {
        type: 'string',
      },
      branchNumber: {
        type: 'string',
      },
      branchText: {
        type: 'string',
      },
      taxNumberType: {
        type: 'string',
      },
      taxId: {
        type: 'string',
      },
      phoneCountryCode: {
        type: 'string',
      },
      phone: {
        type: 'string',
      },
      email: {
        type: 'string',
      },
      unstructure: {
        type: 'boolean',
      },
    },

    date: {
      type: 'string',
    },
    dateBE: {
      type: 'string',
    },
    dateCE: {
      type: 'string',
    },
    number: {
      type: 'string',
    },
    percentVat: {
      type: 'number',
    },
    amountTotal: {
      type: 'number',
    },
    vatTotal: {
      type: 'number',
    },
    grandTotal: {
      type: 'number',
    },
    note: {
      type: 'string',
    },
    receivedBy: {
      type: 'string',
    },
    refer: {
      typeCode: {
        type: 'string',
      },
      typeName: {
        type: 'string',
      },
      number: {
        type: 'string',
      },
      date: {
        type: 'string',
      },
      dateBE: {
        type: 'string',
      },
      reasonCode: {
        type: 'string',
      },
      reasonName: {
        type: 'string',
      },
      amountTotal: {
        type: 'number',
      },
      diffAmountTotal: {
        type: 'number',
      },
    },
    items: {
      number: {
        type: 'string',
      },
      description: {
        type: 'string',
      },
      quantity: {
        type: 'number',
      },
      unitCode: {
        type: 'string',
      },
      unitName: {
        type: 'string',
      },

      price: {
        type: 'number',
      },
      discount: {
        type: 'number',
      },
      percentVat: {
        type: 'number',
      },
      percentVatText: {
        type: 'string',
      },
      total: {
        type: 'number',
      },
    },
    discount: {
      type: 'number',
    },
    reIssue: {
      type: 'boolean',
    },
    includeVat: {
      type: 'boolean',
    },
    createdBy: {
      type: 'string',
    },
    reference: {
      type: 'string',
    },
  };

  let objToSent = {
    amountTotal: passItem.amountTotal,
    company: passItem.company,
    customer: passItem.customer,
    date: passItem.date,
    dateBE: passItem.dateBE,
    dateCE: passItem.dateCE,
    grandTotal: passItem.grandTotal,
    nonVatPriceTotal: passItem.nonVatPriceTotal,
    zeroVatPriceTotal: passItem.zeroVatPriceTotal,
    vatPriceTotal: passItem.vatPriceTotal,
    items: passItem.items,
    note: passItem.note,
    number: passItem.number,
    percentVat: passItem.percentVat,
    receivedBy: passItem.receivedBy,
    vatTotal: passItem.vatTotal,
    reIssue: passItem.reIssue,
    refer: passItem.refer,
    reference: passItem.reference,
    discount: passItem.discount,
    includeVat: passItem.includeVat,
    grandTotalTH: numberToTextTh(passItem.grandTotal >= 0 ? passItem.grandTotal : 0),
    grandTotalEN: numberToTextEn(passItem.grandTotal >= 0 ? passItem.grandTotal : 0),
    ...(['BILLING-NOTE', 'ใบวางบิล', 'INVOICE', 'ใบแจ้งหนี้', 'QUOTATION', 'ใบเสนอราคา'].includes(passItem.documentType)
      ? { 
        whtTotal: passItem.whtTotal, 
        paidTotal: passItem.grandTotal - passItem.whtTotal >= 0 ? passItem.grandTotal - passItem.whtTotal : 0
      }
      : {}),
  };

  if (
    passItem.documentType === 'INVOICE-TAXINVOICE' ||
    passItem.documentType === 'ใบแจ้งหนี้/ใบกำกับภาษี' ||
    passItem.documentType === 'DELIVERYORDER-TAXINVOICE' ||
    passItem.documentType === 'ใบส่งของ/ใบกำกับภาษี'
  ) {
    objToSent.dueDate = passItem.dueDate;
    objToSent.dueDateBE = passItem.dueDateBE;
    objToSent.dueDateCE = passItem.dueDateCE;
  }

  for (const [key, value] of Object.entries(passItem)) {
    if (Object.keys(fields).includes(key)) {
      if (key === 'customer' || key === 'refer') {
        Object.keys(fields[key]).forEach((keyInObjKey) => {
          if (fields[key][keyInObjKey]['type'] === 'string') {
            objToSent[key][keyInObjKey] = String(value[keyInObjKey]);
          } else if (fields[key][keyInObjKey]['type'] === 'number') {
            objToSent[key][keyInObjKey] = Number(value[keyInObjKey]);
          } else {
            let thisboolean = value[keyInObjKey] === 'true' || value[keyInObjKey] === true;
            objToSent[key][keyInObjKey] = thisboolean;
          }
        });
      } else if (key === 'items') {
        value.forEach((object, index) => {
          for (const [keyInObjKeyArray, valueInArray] of Object.entries(object)) {
            if (Object.keys(fields[key]).includes(keyInObjKeyArray)) {
              if (fields[key][keyInObjKeyArray]['type'] === 'string') {
                objToSent[key][index][keyInObjKeyArray] = String(valueInArray);
              } else {
                objToSent[key][index][keyInObjKeyArray] = Number(valueInArray);
              }
            }
          }
        });
      } else {
        if (fields[key]['type'] === 'string') {
          objToSent[key] = String(value);
        } else if (fields[key]['type'] === 'number') {
          objToSent[key] = Number(value);
        } else {
          let thisboolean = value === 'true' || value === true;
          objToSent[key] = thisboolean;
        }
      }
    }
  }

  return objToSent;
}
