import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as React from 'react';
import { DetailsList, DetailsListLayoutMode, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { useTranslation } from 'react-i18next';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { PrimaryButton, DefaultButton, Stack, Selection, Spinner, CheckboxVisibility, SpinnerSize } from '@fluentui/react';
import { getCountryCodeWithoutLanguage } from '../../../providers/CountryData';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

function ContactsUploadList(props) {
  const history = useHistory();

  const { t } = useTranslation();

  const location = useLocation();

  const [items, setItems] = useState(location.state.items);

  const [isCompactMode] = React.useState(false);

  const [inComplete, setInComplete] = React.useState(false);

  const isloadingPdf = false;

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);

        if (_selectedItems[0] !== undefined) {
          setIsCreateDocumentComplete(false);
        }
      },
    })
  );

  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const companyId = sessionStorage.getItem('companyId') || '';

  const spinnerStyles = {
    circle: {
      height: 56,
      width: 56,
      borderWidth: 4,
    },
  };

  const columns = [
    {
      key: 'column1',
      name: t('contactDropzoneUploadList.name'),
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return <div>{item['name']}</div>;
      },
    },
    {
      key: 'column2',
      name: t('contactDropzoneUploadList.taxId'),
      fieldName: 'taxId',
      minWidth: 165,
      maxWidth: 200,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return <div>{item['taxId']}</div>;
      },
    },
    {
      key: 'column3',
      name: t('contactDropzoneUploadList.branchNumber'),
      fieldName: 'branchNumber',
      minWidth: 90,
      maxWidth: 90,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return <div>{item['branchNumber']}</div>;
      },
    },
    {
      key: 'column4',
      name: t('contactDropzoneUploadList.address'),
      fieldName: 'address',
      minWidth: 500,
      maxWidth: 550,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        let _address = item['addressLineOne'];
        if (item['addressLineTwo']) _address = _address + ' ' + item['addressLineTwo'];
        if (item['countryName']) _address = _address + ' ' + item['countryName'];
        if (item['postcode']) _address = _address + ' ' + item['postcode'];

        return <div>{_address}</div>;
      },
    },
    {
      key: 'column5',
      name: t('contactDropzoneUploadList.status'),
      fieldName: 'command',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('contactDropzoneUploadList.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('contactDropzoneUploadList.processing')} labelPosition="right" style={{ marginLeft: '-5px' }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('contactDropzoneUploadList.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('contactDropzoneUploadList.fail')}</span>;
          }
        }
      },
    },
  ];

  const createSelectedDocuments = async () => {
    let _items = selection.getItems();

    let _selectedItems = selectedItems;

    // const _country = getCountryCodeWithoutLanguage(_selectedItems[0]['countryName']);

    // let _branchNumber = '';
    // let _branchText = '';
    // if (_selectedItems[0]['taxNumberType'].toUpperCase() === 'TXID') {
    //   if (Number(_selectedItems[0]['branchNumber']) > 0) {
    //     _branchNumber = String(_selectedItems[0]['branchNumber']).padStart(5, '0');
    //     _branchText = 'สาขา ' + String(_selectedItems[0]['branchNumber']).padStart(5, '0');
    //   } else if (Number(_selectedItems[0]['branchNumber']) === 0) {
    //     _branchNumber = '00000';
    //     _branchText = 'สำนักงานใหญ่';
    //   }
    // }

    // let _language = 'th';
    // if (_selectedItems[0]['language'] === 'en') _language = 'en';

    // const data = {
    //   addressLineOne: _selectedItems[0]['addressLineOne'],
    //   addressLineTwo: _selectedItems[0]['addressLineTwo'] ? _selectedItems[0]['addressLineTwo'] : '',
    //   unstructure: false,
    //   postcode: String(_selectedItems[0]['postcode']),
    //   countryCode: _country.key,
    //   countryName: _language === 'th' ? _country.textTh : _country.textEn,
    //   name: _selectedItems[0]['name'],
    //   taxId: String(_selectedItems[0]['taxId']),
    //   taxNumberType: _selectedItems[0]['taxNumberType'].toUpperCase(),
    //   branchNumber: _branchNumber,
    //   branchText: _branchText,
    //   email: _selectedItems[0]['email'] ? _selectedItems[0]['email'] : '',
    //   phone: _selectedItems[0]['phone'] ? String(_selectedItems[0]['phone']) : '',
    //   phoneCountryCode: '+66',
    //   language: _language,
    //   website: _selectedItems[0]['website'] ? _selectedItems[0]['website'] : '',
    //   fax: _selectedItems[0]['fax'] ? String(_selectedItems[0]['fax']) : '',
    //   contactName: _selectedItems[0]['contactName'] ? _selectedItems[0]['contactName'] : '',
    //   contactPhone: _selectedItems[0]['contactPhone'] ? String(_selectedItems[0]['contactPhone']) : '',
    //   contactEmail: _selectedItems[0]['contactEmail'] ? _selectedItems[0]['contactEmail'] : '',
    //   contactNickName: '',
    //   contactPosition: _selectedItems[0]['contactPosition'] ? _selectedItems[0]['contactPosition'] : '',
    //   contactDepartment: _selectedItems[0]['contactDepartment'] ? _selectedItems[0]['contactDepartment'] : '',
    //   payment: {
    //     accountName: '',
    //     banks: [
    //       {
    //         bankCode: '',
    //         bankName: '',
    //         branch: '',
    //         typeCode: '',
    //         typeName: '',
    //         accountNumber: '',
    //       },
    //     ],
    //     note: '',
    //   },
    //   buyer: false,
    //   seller: true,
    //   note: _selectedItems[0]['note'] ? _selectedItems[0]['note'] : '',
    // };

    // console.log('data', data);

    _items.forEach((item) => {
      if (_selectedItems.some((selectedItem) => item.key === selectedItem.key)) {
        item.status = 'pending';
      }
    });

    setItems([..._items]);

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        for (let i = 0; i < selectedItems.length; i++) {
          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === selectedItems[i].key) {
              _items[j].status = 'processing';
              setItems([..._items]);

              const _country = getCountryCodeWithoutLanguage(selectedItems[i]['countryName']);

              let _branchNumber = '';
              let _branchText = '';
              if (selectedItems[i]['taxNumberType'].toUpperCase() === 'TXID') {
                if (Number(selectedItems[i]['branchNumber']) > 0) {
                  _branchNumber = String(selectedItems[i]['branchNumber']).padStart(5, '0');
                  _branchText = 'สาขา ' + String(selectedItems[i]['branchNumber']).padStart(5, '0');
                } else if (Number(selectedItems[i]['branchNumber']) === 0) {
                  _branchNumber = '00000';
                  _branchText = 'สำนักงานใหญ่';
                }
              }

              let _language = 'th';
              if (selectedItems[i]['language'] === 'en') _language = 'en';

              const data = {
                addressLineOne: selectedItems[i]['addressLineOne'],
                addressLineTwo: selectedItems[i]['addressLineTwo'] ? selectedItems[i]['addressLineTwo'] : '',
                unstructure: false,
                postcode: String(selectedItems[i]['postcode']),
                countryCode: _country.key,
                countryName: _language === 'th' ? _country.textTh : _country.textEn,
                name: selectedItems[i]['name'],
                taxId: String(selectedItems[i]['taxId']).trim(),
                taxNumberType: selectedItems[i]['taxNumberType'].toUpperCase(),
                branchNumber: _branchNumber,
                branchText: _branchText,
                email: selectedItems[i]['email'] ? selectedItems[i]['email'] : '',
                phone: selectedItems[i]['phone'] ? String(selectedItems[i]['phone']) : '',
                phoneCountryCode: '+66',
                language: _language,
                website: selectedItems[i]['website'] ? selectedItems[i]['website'] : '',
                fax: selectedItems[i]['fax'] ? String(selectedItems[i]['fax']) : '',
                contactName: selectedItems[i]['contactName'] ? selectedItems[i]['contactName'] : '',
                contactPhone: selectedItems[i]['contactPhone'] ? String(selectedItems[i]['contactPhone']) : '',
                contactEmail: selectedItems[i]['contactEmail'] ? selectedItems[i]['contactEmail'] : '',
                contactNickName: '',
                contactPosition: selectedItems[i]['contactPosition'] ? selectedItems[i]['contactPosition'] : '',
                contactDepartment: selectedItems[i]['contactDepartment'] ? selectedItems[i]['contactDepartment'] : '',
                payment: {
                  accountName: '',
                  banks: [
                    {
                      bankCode: '',
                      bankName: '',
                      branch: '',
                      typeCode: '',
                      typeName: '',
                      accountNumber: '',
                    },
                  ],
                  note: '',
                },
                buyer: false,
                seller: true,
                note: selectedItems[i]['note'] ? selectedItems[i]['note'] : '',
              };

              console.log('data', data);

              const promise_PDF_XML = await new Promise(async (resolve) => {
                axios
                  .post(API_URL + '/contacts', data, {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                      'Content-Type': 'application/json',
                      'cid': companyId
                    },
                  })
                  .then(
                    async (response) => {
                      console.log('add contact response: ', response);

                      _items[j].status = 'successful';
                      setItems([..._items]);
                      resolve(true);
                    },
                    (error) => {
                      console.log('Error add contact response: ', error);
                      _items[j].status = 'fail';
                      setItems([..._items]);
                      resolve(false);
                    }
                  );
              }); //promise

              Promise.all([promise_PDF_XML]).then((value) => {
                console.log(i, value);
              });
            } //if
          } //for j
        } //for i
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
      });

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  useEffect(() => {
    for (const obj of items) {
      if (obj.status === 'ไม่สำเร็จ') {
        setInComplete(true);

        for (const obj of items) {
          if (obj.status === '') {
            obj.status = '-';
          }
        }
      }
    }
  }, [items]);

  return (
    <Stack>
      <>
        {isloadingPdf ? (
          <Stack>
            <Stack horizontal horizontalAlign="center">
              <br />
              <br />
              <div className="certificates-loading-center">
                <Spinner label={t('contactDropzoneUploadList.processing2')} labelPosition="down" styles={spinnerStyles} />
              </div>
              <br />
            </Stack>
          </Stack>
        ) : (
          <>
            {inComplete ? (
              <Stack horizontal horizontalAlign="center">
                <h1>{t('contactDropzoneUploadList.tryAgain')}</h1>
              </Stack>
            ) : (
              <Stack horizontal horizontalAlign="center">
                <h1>{t('contactDropzoneUploadList.header')}</h1>
              </Stack>
            )}

            <Stack styles={{ root: { marginBottom: 10 } }}>
              {selectedItems.length > 0 && !isCreateDocument && (
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                  <PrimaryButton
                    text={t('contactDropzoneUploadList.addContact')}
                    onClick={async () => {
                      setIsCreateDocument(true);
                      await createSelectedDocuments();
                    }}
                  />
                  <DefaultButton
                    text={t('contactDropzoneUploadList.cancel')}
                    style={{ marginLeft: '10px' }}
                    onClick={() => {
                      selection.setAllSelected(false);
                    }}
                  />
                  <br />
                </Stack>
              )}

              {isCreateDocument && (
                <div>
                  <Spinner label={t('contactDropzoneUploadList.processing')} size={SpinnerSize.large} />
                </div>
              )}

              {isCreateDocumentComplete && (
                <center>
                  <PrimaryButton
                    text={t('contactDropzoneUploadList.viewAll')}
                    onClick={async () => {
                      history.push({
                        pathname: '/contacts',
                        state: {},
                      });
                    }}
                  />
                </center>
              )}
            </Stack>

            <Stack
              vertical
              style={{
                width: '98%',
                minWidth: '1200px',
                marginLeft: '10px',
                maxWidth: '1350px',
              }}
            >
              <DetailsList
                items={items || []}
                compact={isCompactMode}
                columns={columns}
                selection={selection}
                selectionMode={isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
              />
            </Stack>
          </>
        )}
      </>
    </Stack>
  );
}

export default ContactsUploadList;
