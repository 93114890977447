import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, PrimaryButton, DefaultButton, ProgressIndicator, Spinner, Icon, getTheme } from '@fluentui/react';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import PdfAllPages from '../../etax/documents/pdf/view/PdfAllPages';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();
//Progress Indicator Setting
const intervalDelay = 100;
const intervalIncrement = 0.01;

const PP30Confirm = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const data = location.state;

  const [percentComplete, setPercentComplete] = useState(0);
  const html = '';
  const [isDownloading, setIsDownloading] = useState(false);

  const [rdError, setRdError] = useState(false);
  const [rdData, setRdData] = useState();

  const [url, setUrl] = useState('');

  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  function formatDateTime(date) {
    let d = new Date(date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      hour = '' + d.getHours(),
      minute = '' + d.getMinutes(),
      second = '' + d.getSeconds();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hour.length < 2) hour = '0' + hour;
    if (minute.length < 2) minute = '0' + minute;
    if (second.length < 2) second = '0' + second;

    return [year, month, day, hour, minute, second].join('');
  }

  const handleDownload = () => {
    setIsDownloading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(
            API_URL + '/reports/pp30/pdf',
            {
              companyBuildingNumber: rdData.HouseNumber[0],
              companyMooNumber: rdData.MooNumber[0],
              companyBuildingName: rdData.BuildingName[0],
              companyRoomNumber: rdData.RoomNumber[0],
              companyFloorNumber: rdData.FloorNumber[0],
              companyVillageName: rdData.VillageName[0],
              companySoiName: rdData.SoiName[0],
              companyAddress: data.companyAddress,
              companyStreetPrefix: data.companyStreetPrefix,
              companyStreetName: rdData.StreetName[0],
              companyProvincePrefix: data.companyProvincePrefix,
              companyProvinceCode: data.companyProvinceCode,
              companyProvinceName: rdData.vProvince[0],
              companySubDistrictPrefix: data.companySubDistrictPrefix,
              companySubDistrictCode: data.companySubDistrictCode,
              companySubDistrictName: rdData.Thambol[0],
              companyDistrictPrefix: data.companyDistrictPrefix,
              companyDistrictCode: data.companyDistrictCode,
              companyDistrictName: rdData.Amphur[0],
              companyPostcode: rdData.PostCode[0],
              companyPhone: data.companyPhone,
              companyEmail: data.companyEmail,
              companyTaxId: rdData.NID[0],
              companyBranchText: data.companyBranchText,
              companyBranchNumber: String(rdData.BranchNumber).padStart(5, '0'),
              percentVat: data.percentVat,
              zeroVat: data.zeroVat,
              nonVat: data.nonVat,
              nonVatPriceTotal: data.nonVatPriceTotal,
              zeroVatPriceTotal: data.zeroVatPriceTotal,
              vatPriceTotal: data.vatPriceTotal,
              purchaseAmount: data.purchaseAmount,
              purchaseVat: data.purchaseVat,
              exceedVatAmount: data.exceedVatAmount,
              payVat: data.payVat,
              month: data.month,
              year: data.year,
              totalVat: data.totalVat,
              totalAmount: data.totalAmount,
              totalGrand: data.totalGrand,
              companyName: rdData.TitleName[0] + ' ' + rdData.Name[0],
              companyAllAddress: data.companyAllAddress,
              normal: data.normal,
              addFund: data.addFund,
              interest: data.interest,
              headOffice: data.headOffice,
              branchOffice: data.branchOffice,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
              },
              responseType: 'blob',
            }
          )
          .then((response) => {
            console.log(response);

            const time = formatDateTime(new Date().toISOString());

            let fileName = time + '-Report-PP30.pdf';

            console.log('file name: ', fileName);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();

            setIsDownloading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const goBack = () => {
    history.push({
      pathname: '/rdprep/pp30',
      state: data,
    });
  };

  useEffect(() => {
    console.log('userEffect Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/sources/rd/vat/' + data.companyTaxId, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            console.log('response from rd vat', response);
            if (!response.data.msgerr[0]) {
              setRdData(response.data);

              axios
                .post(
                  API_URL + '/reports/pp30/pdf',
                  {
                    companyBuildingNumber: response.data.HouseNumber[0],
                    companyMooNumber: response.data.MooNumber[0],
                    companyBuildingName: response.data.BuildingName[0],
                    companyRoomNumber: response.data.RoomNumber[0],
                    companyFloorNumber: response.data.FloorNumber[0],
                    companyVillageName: response.data.VillageName[0],
                    companySoiName: response.data.SoiName[0],
                    companyAddress: data.companyAddress,
                    companyStreetPrefix: data.companyStreetPrefix,
                    companyStreetName: response.data.StreetName[0],
                    companyProvincePrefix: data.companyProvincePrefix,
                    companyProvinceCode: data.companyProvinceCode,
                    companyProvinceName: response.data.vProvince[0],
                    companySubDistrictPrefix: data.companySubDistrictPrefix,
                    companySubDistrictCode: data.companySubDistrictCode,
                    companySubDistrictName: response.data.Thambol[0],
                    companyDistrictPrefix: data.companyDistrictPrefix,
                    companyDistrictCode: data.companyDistrictCode,
                    companyDistrictName: response.data.Amphur[0],
                    companyPostcode: response.data.PostCode[0],
                    companyPhone: data.companyPhone,
                    companyEmail: data.companyEmail,
                    companyTaxId: response.data.NID[0],
                    companyBranchText: data.companyBranchText,
                    companyBranchNumber: String(response.data.BranchNumber[0]).padStart(5, '0'),
                    percentVat: data.percentVat,
                    zeroVat: data.zeroVat,
                    nonVat: data.nonVat,
                    nonVatPriceTotal: data.nonVatPriceTotal,
                    zeroVatPriceTotal: data.zeroVatPriceTotal,
                    vatPriceTotal: data.vatPriceTotal,
                    purchaseAmount: data.purchaseAmount,
                    purchaseVat: data.purchaseVat,
                    exceedVatAmount: data.exceedVatAmount,
                    payVat: data.payVat,
                    month: data.month,
                    year: data.year,
                    totalVat: data.totalVat,
                    totalAmount: data.totalAmount,
                    totalGrand: data.totalGrand,
                    companyName: response.data.TitleName[0] + ' ' + response.data.Name[0],
                    companyAllAddress: data.companyAllAddress,
                    normal: data.normal,
                    addFund: data.addFund,
                    interest: data.interest,
                    headOffice: data.headOffice,
                    branchOffice: data.branchOffice,
                  },
                  {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                      'Content-Type': 'application/json',
                    },
                    responseType: 'blob',
                  }
                )
                .then((response) => {
                  console.log('from pp30 => ', response.data);
                  setUrl(window.URL.createObjectURL(response.data));
                  setPercentComplete(100);
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              setRdError(true);
            }
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html]);

  useEffect(() => {
    if (html === '') {
      const id = setInterval(() => {
        setPercentComplete((intervalIncrement + percentComplete) % 1);
      }, intervalDelay);
      return () => {
        clearInterval(id);
      };
    }
  });

  return (
    <div>
      <br />
      <br />
      {rdError ? (
        <>
          <Stack horizontal tokens={{ childrenGap: 30 }}>
            <Stack>
              <Icon iconName="ProfileSearch" style={{ fontSize: 168 }} />
            </Stack>
            <Stack styles={{ root: { width: 480 } }}>
              <div
                style={{
                  fontSize: 28,
                  marginBottom: 0,
                  marginTop: 25,
                }}
              >
                {t('pp30Confirm.taxId')} <br />
                {data.companyTaxId}
              </div>
              <br />
              <div
                style={{
                  fontSize: 22,
                  marginBottom: 30,
                }}
              >
                {t('pp30Confirm.taxId2')} <br />
                {t('pp30Confirm.taxId3')}
              </div>

              <Stack horizontal>
                <DefaultButton text={t('pp30Confirm.back')} onClick={goBack}></DefaultButton>
              </Stack>
            </Stack>
          </Stack>
        </>
      ) : (
        <>
          {!isDownloading && (
            <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
              <PrimaryButton text={t('pp30Confirm.download')} onClick={handleDownload} />
              <DefaultButton text={t('pp30Confirm.back')} onClick={goBack}></DefaultButton>
            </Stack>
          )}

          {isDownloading && <Spinner label={t('pp30Confirm.loading')} labelPosition="right" style={centerItem} />}

          <br />

          {!url ? (
            <Stack horizontal horizontalAlign="center">
              <div
                style={{
                  width: '780px',
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 20,
                  marginLeft: '0px',
                  marginRight: '0px',
                  background: '#FFFFFF',
                  boxShadow: theme.effects.elevation4,
                }}
              >
                <ProgressIndicator
                  label={t('pp30Confirm.processing')}
                  description={t('pp30Confirm.processingMessage')}
                  percentComplete={percentComplete}
                  style={centerItem}
                />
                <br />
              </div>
              <br />
              <br />
            </Stack>
          ) : (
            <Stack horizontal horizontalAlign="center">
              <PdfAllPages pdf={url} />
            </Stack>
          )}
        </>
      )}

      <br />
      <br />
    </div>
  );
};

export default PP30Confirm;
