import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, PrimaryButton, DefaultButton, TextField, Separator } from '@fluentui/react';

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const PP30Summary = () => {
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation();

  const data = location.state;
  console.log(data);

  if (data === undefined) {
    history.push('/reports/pp30');
  }

  const [purchaseAmount, setPurchaseAmount] = useState(0);
  const [purchaseVat, setPurchaseVat] = useState(0);
  const [exceedVatAmount, setExceedVatAmount] = useState(0);
  const [vatPriceTotal, setVatPriceTotal] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const [vat, setVat] = useState(0);
  const [payVat, setPayVat] = useState(0);

  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const goBack = () => {
    history.push({
      pathname: '/rdprep/pp30',
      state: {},
    });
  };

  const handleSubmit = () => {
    history.push({
      pathname: '/rdprep/pp30/confirm',
      state: {
        // location state
        companyBuildingNumber: data.companyBuildingNumber,
        companyAddress: data.companyAddress,
        companyStreetPrefix: data.companyStreetPrefix,
        companyStreetName: data.companyStreetName,
        companyProvincePrefix: data.companyProvincePrefix,
        companyProvinceCode: data.companyProvinceCode,
        companyProvinceName: data.companyProvinceName,
        companySubDistrictPrefix: data.companySubDistrictPrefix,
        companySubDistrictCode: data.companySubDistrictCode,
        companySubDistrictName: data.companySubDistrictName,
        companyDistrictPrefix: data.companyDistrictPrefix,
        companyDistrictCode: data.companyDistrictCode,
        companyDistrictName: data.companyDistrictName,
        companyPostcode: data.companyPostcode,
        companyPhone: data.companyPhone,
        companyEmail: data.companyEmail,
        companyTaxId: data.companyTaxId,
        companyBranchText: data.companyBranchText,
        companyBranchNumber: data.companyBranchNumber,
        percentVat: data.percentVat,
        zeroVat: data.zeroVat,
        nonVat: data.nonVat,
        nonVatPriceTotal: data.nonVatPriceTotal,
        zeroVatPriceTotal: data.zeroVatPriceTotal,
        vatPriceTotal: vatPriceTotal,
        purchaseAmount: purchaseAmount,
        purchaseVat: purchaseVat,
        exceedVatAmount: exceedVatAmount,
        payVat: payVat,
        month: data.month,
        year: data.year,
        totalVat: totalVat,
        totalAmount: data.totalAmount,
        totalGrand: data.totalGrand,
        companyName: data.companyName,
        companyAllAddress: data.companyAllAddress,
        normal: data.normal,
        addFund: data.addFund,
        interest: data.interest,
        headOffice: data.headOffice,
        branchOffice: data.branchOffice,
      },
    });
  };

  useEffect(() => {
    if (data !== undefined) {
      setVatPriceTotal(data.vatPriceTotal ? data.vatPriceTotal : 0);
      setPurchaseAmount(data.purchaseAmount ? data.purchaseAmount : 0);
      setPurchaseVat(data.purchaseVat ? data.purchaseVat : 0);
      setExceedVatAmount(data.exceedVatAmount ? data.exceedVatAmount : 0);
      setPayVat(data.payVat ? data.payVat : 0);
      setTotalVat(data.totalVat ? data.totalVat : 0);
    }
  }, [data]);

  useEffect(() => {
    setPurchaseVat(purchaseAmount ? Math.round(purchaseAmount * (data.percentVat / 100) * 100) / 100 : 0);
    setVatPriceTotal(data.totalAmount - data.nonVatPriceTotal - data.zeroVatPriceTotal);
    setTotalVat(Math.round(vatPriceTotal * (data.percentVat / 100) * 100) / 100);
    setPayVat(totalVat - purchaseVat);
    setVat(payVat - exceedVatAmount);
  }, [
    purchaseAmount,
    data.percentVat,
    exceedVatAmount,
    purchaseVat,
    data.totalVat,
    payVat,
    data.totalAmount,
    data.nonVatPriceTotal,
    data.zeroVatPriceTotal,
    vatPriceTotal,
    totalVat,
  ]);

  return (
    <div style={homeStyles}>
      <center>
        <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('pp30Summary.header')}</h2>
      </center>
      <Stack horizontalAlign="center">
        <Stack styles={{ root: { background: 'white', height: 420, width: 890 } }}>
          <Stack styles={{ root: { margin: 20, marginBottom: 10 } }}>
            {t('pp30Summary.month')} : {data.year}/{data.month}
          </Stack>
          <Separator />
          <Stack horizontal horizontalAlign="space-between" styles={{ root: { margin: 20, paddingTop: 10 } }}>
            <Stack vertical styles={{ root: { width: 400 } }}>
              <div style={{ marginBottom: 17 }}>
                <strong>{t('pp30Summary.vatPurchase')}</strong>
              </div>
              <Stack horizontal horizontalAlign="space-between" styles={{ root: { marginBottom: 10 } }}>
                <span>
                  <strong>{t('pp30Summary.totalPurchaseThisMonth')}</strong>
                </span>
                <Stack horizontal style={{ marginTop: -8 }}>
                  <TextField
                    type="number"
                    style={{ textAlign: 'end', width: 140 }}
                    onChange={(e) => setPurchaseAmount(e.target.value)}
                    value={purchaseAmount}
                  ></TextField>{' '}
                  <span style={{ paddingTop: 7 }}>
                    <strong>{t('pp30Summary.thb')}</strong>
                  </span>
                </Stack>
              </Stack>
              <br />
              <br />
              <br />
              <Stack horizontal horizontalAlign="space-between" styles={{ root: { paddingTop: 23 } }}></Stack>
              <Stack horizontal horizontalAlign="space-between">
                <span>
                  <strong>{t('pp30Summary.vatPurchaseThisMonth')}</strong>
                </span>
                <Stack horizontal style={{ marginTop: -8 }}>
                  <TextField
                    type="number"
                    style={{ textAlign: 'end', width: 140 }}
                    onChange={(e) => setPurchaseVat(e.target.value)}
                    value={purchaseVat}
                  ></TextField>{' '}
                  <span style={{ paddingTop: 7 }}>
                    <strong>{t('pp30Summary.thb')}</strong>
                  </span>
                </Stack>
              </Stack>
            </Stack>
            <Stack vertical styles={{ root: { width: 400 } }}>
              <div style={{ marginBottom: 17 }}>
                <strong>{t('pp30Summary.vatSales')}</strong>
              </div>
              <Stack horizontal horizontalAlign="space-between" styles={{ root: { marginBottom: 10 } }}>
                <span>
                  <strong>{t('pp30Summary.totalSalesThisMonth')}</strong>
                </span>
                <span>
                  <strong>
                    {data.totalAmount.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{' '}
                    {t('pp30Summary.thb')}
                  </strong>
                </span>
              </Stack>
              <Stack horizontal horizontalAlign="space-between" styles={{ root: { marginBottom: 10 } }}>
                <span>
                  <strong>{t('pp30Summary.minus')}</strong> {t('pp30Summary.totalSalesZero')}
                </span>
                <span>
                  {data.zeroVatPriceTotal.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}{' '}
                  {t('pp30Summary.thb')}
                </span>
              </Stack>
              <Stack horizontal horizontalAlign="space-between" styles={{ root: { marginBottom: 10 } }}>
                <span>
                  <strong>{t('pp30Summary.minus')}</strong> {t('pp30Summary.totalSalesNon')}
                </span>
                <span>
                  {data.nonVatPriceTotal.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}{' '}
                  {t('pp30Summary.thb')}
                </span>
              </Stack>
              <Stack horizontal horizontalAlign="space-between" styles={{ root: { marginBottom: 10 } }}>
                <span>{t('pp30Summary.totalSalesVat')}</span>
                <span>
                  {vatPriceTotal.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}{' '}
                  {t('pp30Summary.thb')}
                </span>
              </Stack>
              <Stack horizontal horizontalAlign="space-between">
                <span>
                  <strong>{t('pp30Summary.vatSalesThisMonth')}</strong>
                </span>
                <span>
                  <strong>
                    {totalVat.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{' '}
                    {t('pp30Summary.thb')}
                  </strong>
                </span>
              </Stack>
            </Stack>
          </Stack>
          <Separator />
          <Stack horizontalAlign="end" styles={{ root: { margin: 20 } }}>
            <Stack vertical styles={{ root: { width: 400 } }}>
              <Stack horizontal horizontalAlign="space-between" styles={{ root: { marginBottom: 10 } }}>
                <span>
                  <strong>{t('pp30Summary.totalVatThisMonth')}</strong>
                </span>
                <span>
                  <strong>
                    {payVat.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{' '}
                    {t('pp30Summary.thb')}
                  </strong>
                </span>
              </Stack>
              <Stack horizontal horizontalAlign="space-between" styles={{ root: { marginBottom: 10 } }}>
                <span style={{ paddingTop: 7 }}>
                  <strong>{t('pp30Summary.previousVat')}</strong>
                </span>
                <Stack horizontal>
                  <TextField
                    type="number"
                    style={{ textAlign: 'end', width: 140 }}
                    onChange={(e) => setExceedVatAmount(e.target.value)}
                    value={
                      exceedVatAmount
                        ? exceedVatAmount.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                        : 0
                    }
                  ></TextField>{' '}
                  <span style={{ paddingTop: 7 }}>
                    <strong>{t('pp30Summary.thb')}</strong>
                  </span>
                </Stack>
              </Stack>
              <Stack horizontal horizontalAlign="space-between" styles={{ root: { marginBottom: 10 } }}>
                <span>
                  <strong>{t('pp30Summary.totalVat')}</strong>
                </span>
                <span>
                  <strong>
                    {vat.toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}{' '}
                    {t('pp30Summary.thb')}
                  </strong>
                </span>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <br />
        <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
          <PrimaryButton text={t('pp30Summary.next')} onClick={handleSubmit} value="next" />
          <DefaultButton text={t('pp30Summary.cancel')} onClick={goBack} />
        </Stack>
      </Stack>
    </div>
  );
};

export default PP30Summary;
