import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  SelectionMode,
  Spinner,
  SpinnerSize,
  CommandBar,
  SearchBox,
  Stack,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  Calendar,
  DateRangeType,
  TextField,
  MessageBar,
  MessageBarType,
  DocumentCard,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardLogo,
  DocumentCardType,
  Text,
  ContextualMenu,
  ContextualMenuItemType,
  IconButton,
  Checkbox,
} from '@fluentui/react';

// import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import CountUp from 'react-countup';

import { getDistrictFromString, getProvinceFromString, getSubDistrictFromString } from '../../../providers/AreaData';

import PdfAllPages from '../../etax/documents/pdf/view/PdfAllPages';
import { CSVLink } from 'react-csv';
import html2canvas from 'html2canvas';
import { TextFieldEmail } from '../../../helpers/customComponents/TextFieldEmail';
import { checkEmail } from '../../../helpers/CheckEmail';
var JSZip = require('jszip');

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  minWidth: 'calc(100vw - 310px)',
  background: '#FAF9F8',
  position: 'relative',
};

const cardStyles = {
  cardStyles: {
    root: {
      background: 'white',
      borderLeft: '5px solid #0078d4',
      width: '27%',
      minWidth: '332px',
      maxWidth: '424px',
      height: '130px',
    },
  },
  header: {
    root: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingTop: '20px',
      textAlign: 'center',
    },
  },
  amount: {
    root: {
      fontSize: 30,
      paddingBottom: '25px',
      textAlign: 'center',
    },
  },
};

function formatDateTime(date) {
  let d = new Date(date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = '' + d.getHours(),
    minute = '' + d.getMinutes(),
    second = '' + d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [year, month, day, hour, minute, second].join('');
}

const headerCSV = [
  { key: 'r1', label: 'ลำดับที่' },
  { key: 'r2', label: 'เลขประจำตัวผู้เสียภาษีอากร' },
  { key: 'r3', label: 'สาขาที่' },
  { key: 'r4', label: 'คำนำหน้าชื่อ' },
  { key: 'r5', label: 'ชื่อ' },
  { key: 'r6', label: 'ชื่ออาคาร' },
  { key: 'r7', label: 'ห้องเลขที่' },
  { key: 'r8', label: 'ชั้นที่' },
  { key: 'r9', label: 'หมู่บ้าน' },
  { key: 'r10', label: 'เลขที่' },
  { key: 'r11', label: 'หมู่ที่' },
  { key: 'r12', label: 'ตรอก/ซอย' },
  { key: 'r13', label: 'แยก' },
  { key: 'r14', label: 'ถนน' },
  { key: 'r15', label: 'ตำบล/แขวง' },
  { key: 'r16', label: 'อำเภอ/เขต' },
  { key: 'r17', label: 'จังหวัด' },
  { key: 'r18', label: 'รหัสไปรษณีย์' },
  { key: 'r19', label: 'วันเดือนปีที่จ่าย' },
  { key: 'r20', label: 'ประเภทเงินได้' },
  { key: 'r21', label: 'อัตราภาษี' },
  { key: 'r22', label: 'จำนวนเงินได้ที่จ่าย' },
  { key: 'r23', label: 'จำนวนภาษีที่หัก' },
  { key: 'r24', label: 'เงื่อนไขการหักภาษี' },
];

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== '') return false;
  }
  return true;
}

const PND53 = () => {
  console.log('Home render!!!');

  const { t, i18n } = useTranslation();

  const csvLink = useRef();

  const history = useHistory();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const printRef = useRef();
  const [url, setUrl] = useState('');
  const [fileName, setFileName] = useState('');

  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const totalProcessingDocuments = 0
  const [pageData, setPageData] = useState({});
  const pageSize = 10
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('pnd53-currentPage') * 1 || 1);

  const [search, setSearch] = useState('');
  const [previousSearch, setPreviousSearch] = useState('');
  const [filterDocuments] = useState([]);

  const [downloadStatus, setDownloadStatus] = useState('');
  const downloading = downloadStatus === 'Downloading';
  const downloadComplete = downloadStatus === 'Complete';

  const [CSV, setCSV] = useState([]);

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('pnd53-primarySort') || 'documentDate');

  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem('pnd53-sort-documentDate') === 'false') {
      return false;
    } else {
      return true;
    }
  });

  const DayPickerStrings = {
    months: [
      t('pnd53.months01'),
      t('pnd53.months02'),
      t('pnd53.months03'),
      t('pnd53.months04'),
      t('pnd53.months05'),
      t('pnd53.months06'),
      t('pnd53.months07'),
      t('pnd53.months08'),
      t('pnd53.months09'),
      t('pnd53.months10'),
      t('pnd53.months11'),
      t('pnd53.months12'),
    ],

    shortMonths: [
      t('pnd53.shortMonths01'),
      t('pnd53.shortMonths02'),
      t('pnd53.shortMonths03'),
      t('pnd53.shortMonths04'),
      t('pnd53.shortMonths05'),
      t('pnd53.shortMonths06'),
      t('pnd53.shortMonths07'),
      t('pnd53.shortMonths08'),
      t('pnd53.shortMonths09'),
      t('pnd53.shortMonths10'),
      t('pnd53.shortMonths11'),
      t('pnd53.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: 'ไปวันที่ปัจจุบัน',
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('pnd53.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  useEffect(() => {
    if (downloadComplete) {
      setDownloadStatus('');
      csvLink.current.link.click();
    }
  }, [downloadComplete]);

  function getRowItemUnstructure(document, _item) {
    let _paymentType = '1';
    if (document.data.payment_type_2 || document.data.payment_type_3) _paymentType = '2';

    let _addressLineOne = document.data.customer.addressLineOne;

    let _province = getProvinceFromString(_addressLineOne).text;
    let _district = getDistrictFromString(_addressLineOne).text;
    let _subDistrict = getSubDistrictFromString(_addressLineOne).text;
    let _postcode;

    let findPostcode = _addressLineOne.match(/\d+/g);

    findPostcode.forEach((number) => {
      if (number.length === 5) {
        _postcode = number;
        _addressLineOne = _addressLineOne.replace(number, '');
      }
    });

    _addressLineOne = _addressLineOne.replace(_province, '');
    _addressLineOne = _addressLineOne.replace('จ.', '');

    _addressLineOne = _addressLineOne.replace(_district, '');
    _addressLineOne = _addressLineOne.replace('อ.', '');
    _addressLineOne = _addressLineOne.replace('เขต', '');

    _addressLineOne = _addressLineOne.replace(_subDistrict, '');
    _addressLineOne = _addressLineOne.replace('ต.', '');
    _addressLineOne = _addressLineOne.replace('แขวง', '');

    let _customerName = document.data.customer.name;
    let _titleName = 'บริษัท';

    _customerName = _customerName.replace(_titleName, '');

    return {
      r1: '',
      r2: String(document.data.customer.taxId),
      r3: '00000',
      r4: _titleName,
      r5: _customerName,
      r6: '',
      r7: '',
      r8: '',
      r9: '',
      r10: _addressLineOne.trim(),
      r11: '',
      r12: '',
      r13: '',
      r14: '',
      r15: _subDistrict,
      r16: _district,
      r17: _province,
      r18: _postcode,
      r19: document.data.referExpenseNote.dateBE,
      r20: _item.whtIncomeName === 'อื่นๆ' ? _item.description : _item.whtIncomeName,
      r21: _item.percentWht,
      r22: _item.total,
      r23: _item.whtTotal,
      r24: _paymentType,
    };
  }

  function getRowItemStructure(document, _item) {
    let _paymentType = '1';
    if (document.data.payment_type_2 || document.data.payment_type_3) _paymentType = '2';

    let _customerName = document.data.customer.name;
    let _titleName = 'บริษัท';

    _customerName = _customerName.replace(_titleName, '');

    return {
      r1: '',
      r2: String(document.data.customer.taxId),
      r3: document.data.referExpenseNote.customer.branchNumber,
      r4: _titleName,
      r5: _customerName,
      r6: '',
      r7: '',
      r8: '',
      r9: '',
      r10: document.data.referExpenseNote.customer.buildingNumber,
      r11: document.data.referExpenseNote.customer.address,
      r12: '',
      r13: '',
      r14: document.data.referExpenseNote.customer.streetName,
      r15: document.data.referExpenseNote.customer.subDistrictName,
      r16: document.data.referExpenseNote.customer.districtName,
      r17: document.data.referExpenseNote.customer.provinceName,
      r18: document.data.referExpenseNote.customer.postcode,
      r19: document.data.referExpenseNote.dateBE,
      r20: _item.whtIncomeName === 'อื่นๆ' ? _item.description : _item.whtIncomeName,
      r21: _item.percentWht,
      r22: _item.total,
      r23: _item.whtTotal,
      r24: _paymentType,
    };
  }

  function getRowPaymentTypeStructure(document, paymentType) {
    let _paymentTypeDes;
    if (paymentType === 'paymentType_1') _paymentTypeDes = 'เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส';
    if (paymentType === 'paymentType_2') _paymentTypeDes = 'ค่าธรรมเนียม ค่านายหน้า';
    if (paymentType === 'paymentType_3') _paymentTypeDes = 'ค่าแห่งลิขสิทธิ์';
    if (paymentType === 'paymentType_4_A') _paymentTypeDes = 'ดอกเบี้ย';
    if (paymentType === 'paymentType_4_B_1_1') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_1_2') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_1_3') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_1_4') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_2_1') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_2_2') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_2_3') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_2_4') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_2_5') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_5') _paymentTypeDes = 'ค่าจ้างทำของ ค่าโฆษณา ค่าเช่า ค่าขนส่ง ค่าบริการ ค่าเบี้ยประกันวินาศภัย';
    if (paymentType === 'paymentType_6') _paymentTypeDes = document.data[paymentType].remark;

    let _paymentType = '1';
    if (document.data.payment_type_2 || document.data.payment_type_3) _paymentType = '2';

    let _customerName = document.data.customer.name;
    let _titleName = 'บริษัท';

    _customerName = _customerName.replace(_titleName, '');

    return {
      r1: '',
      r2: String(document.data.customer.taxId),
      r3: document.data.referExpenseNote.customer.branchNumber,
      r4: _titleName,
      r5: _customerName,
      r6: '',
      r7: '',
      r8: '',
      r9: '',
      r10: document.data.referExpenseNote.customer.buildingNumber,
      r11: document.data.referExpenseNote.customer.address,
      r12: '',
      r13: '',
      r14: document.data.referExpenseNote.customer.streetName,
      r15: document.data.referExpenseNote.customer.subDistrictName,
      r16: document.data.referExpenseNote.customer.districtName,
      r17: document.data.referExpenseNote.customer.provinceName,
      r18: document.data.referExpenseNote.customer.postcode,
      r19: document.data[paymentType].dateBE,
      r20: _paymentTypeDes,
      r21: (document.data[paymentType].vatTotal / document.data[paymentType].amountTotal) * 100,
      r22: document.data[paymentType].amountTotal,
      r23: document.data[paymentType].vatTotal,
      r24: _paymentType,
    };
  }

  function getRowPaymentTypeUnstructure(document, paymentType) {
    let _paymentTypeDes;
    if (paymentType === 'paymentType_1') _paymentTypeDes = 'เงินเดือน ค่าจ้าง เบี้ยเลี้ยง โบนัส';
    if (paymentType === 'paymentType_2') _paymentTypeDes = 'ค่าธรรมเนียม ค่านายหน้า';
    if (paymentType === 'paymentType_3') _paymentTypeDes = 'ค่าแห่งลิขสิทธิ์';
    if (paymentType === 'paymentType_4_A') _paymentTypeDes = 'ดอกเบี้ย';
    if (paymentType === 'paymentType_4_B_1_1') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_1_2') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_1_3') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_1_4') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_2_1') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_2_2') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_2_3') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_2_4') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_4_B_2_5') _paymentTypeDes = 'เงินปันผล เงินส่วนแบ่งกำไร';
    if (paymentType === 'paymentType_5') _paymentTypeDes = 'ค่าจ้างทำของ ค่าโฆษณา ค่าเช่า ค่าขนส่ง ค่าบริการ ค่าเบี้ยประกันวินาศภัย';
    if (paymentType === 'paymentType_6') _paymentTypeDes = document.data[paymentType].remark;

    let _paymentType = '1';
    if (document.data.payment_type_2 || document.data.payment_type_3) _paymentType = '2';

    let _addressLineOne = document.data.customer.addressLineOne;

    let _province = getProvinceFromString(_addressLineOne).text;
    let _district = getDistrictFromString(_addressLineOne).text;
    let _subDistrict = getSubDistrictFromString(_addressLineOne).text;
    let _postcode;

    let findPostcode = _addressLineOne.match(/\d+/g);

    findPostcode.forEach((number) => {
      if (number.length === 5) {
        _postcode = number;
        _addressLineOne = _addressLineOne.replace(number, '');
      }
    });

    _addressLineOne = _addressLineOne.replace(_province, '');
    _addressLineOne = _addressLineOne.replace('จ.', '');

    _addressLineOne = _addressLineOne.replace(_district, '');
    _addressLineOne = _addressLineOne.replace('อ.', '');
    _addressLineOne = _addressLineOne.replace('เขต', '');

    _addressLineOne = _addressLineOne.replace(_subDistrict, '');
    _addressLineOne = _addressLineOne.replace('ต.', '');
    _addressLineOne = _addressLineOne.replace('แขวง', '');

    let _customerName = document.data.customer.name;
    let _titleName = 'บริษัท';

    _customerName = _customerName.replace(_titleName, '');

    return {
      r1: '',
      r2: String(document.data.customer.taxId),
      r3: '00000',
      r4: _titleName,
      r5: _customerName,
      r6: '',
      r7: '',
      r8: '',
      r9: '',
      r10: _addressLineOne.trim(),
      r11: '',
      r12: '',
      r13: '',
      r14: '',
      r15: _subDistrict,
      r16: _district,
      r17: _province,
      r18: _postcode,
      r19: document.data[paymentType].dateBE,
      r20: _paymentTypeDes,
      r21: (document.data[paymentType].vatTotal / document.data[paymentType].amountTotal) * 100,
      r22: document.data[paymentType].amountTotal,
      r23: document.data[paymentType].vatTotal,
      r24: _paymentType,
    };
  }

  async function getCSVData() {
    console.log('handleDownload');

    let csvData = [];

    let _documents = [...documents];

    if (_documents && _documents.length > 0) {
      _documents.sort(function (a, b) {
        let _documentDateA;
        if (a.data.paymentType_1.dateBE) {
          _documentDateA = a.data.paymentType_1.dateBE;
        } else if (a.data.paymentType_2.dateBE) {
          _documentDateA = a.data.paymentType_2.dateBE;
        } else if (a.data.paymentType_3.dateBE) {
          _documentDateA = a.data.paymentType_3.dateBE;
        } else if (a.data.paymentType_4_A.dateBE) {
          _documentDateA = a.data.paymentType_4_A.dateBE;
        } else if (a.data.paymentType_4_B_1_1.dateBE) {
          _documentDateA = a.data.paymentType_4_B_1_1.dateBE;
        } else if (a.data.paymentType_4_B_1_2.dateBE) {
          _documentDateA = a.data.paymentType_4_B_1_2.dateBE;
        } else if (a.data.paymentType_4_B_1_3.dateBE) {
          _documentDateA = a.data.paymentType_4_B_1_3.dateBE;
        } else if (a.data.paymentType_4_B_1_4.dateBE) {
          _documentDateA = a.data.paymentType_4_B_1_4.dateBE;
        } else if (a.data.paymentType_4_B_2_1.dateBE) {
          _documentDateA = a.data.paymentType_4_B_2_1.dateBE;
        } else if (a.data.paymentType_4_B_2_2.dateBE) {
          _documentDateA = a.data.paymentType_4_B_2_2.dateBE;
        } else if (a.data.paymentType_4_B_2_3.dateBE) {
          _documentDateA = a.data.paymentType_4_B_2_3.dateBE;
        } else if (a.data.paymentType_4_B_2_4.dateBE) {
          _documentDateA = a.data.paymentType_4_B_2_4.dateBE;
        } else if (a.data.paymentType_4_B_2_5.dateBE) {
          _documentDateA = a.data.paymentType_4_B_2_5.dateBE;
        } else if (a.data.paymentType_5.dateBE) {
          _documentDateA = a.data.paymentType_5.dateBE;
        } else if (a.data.paymentType_6.dateBE) {
          _documentDateA = a.data.paymentType_6.dateBE;
        }

        let _documentDateB;
        if (b.data.paymentType_1.dateBE) {
          _documentDateB = b.data.paymentType_1.dateBE;
        } else if (b.data.paymentType_2.dateBE) {
          _documentDateB = b.data.paymentType_2.dateBE;
        } else if (b.data.paymentType_3.dateBE) {
          _documentDateB = b.data.paymentType_3.dateBE;
        } else if (b.data.paymentType_4_A.dateBE) {
          _documentDateB = b.data.paymentType_4_A.dateBE;
        } else if (b.data.paymentType_4_B_1_1.dateBE) {
          _documentDateB = b.data.paymentType_4_B_1_1.dateBE;
        } else if (b.data.paymentType_4_B_1_2.dateBE) {
          _documentDateB = b.data.paymentType_4_B_1_2.dateBE;
        } else if (b.data.paymentType_4_B_1_3.dateBE) {
          _documentDateB = b.data.paymentType_4_B_1_3.dateBE;
        } else if (b.data.paymentType_4_B_1_4.dateBE) {
          _documentDateB = b.data.paymentType_4_B_1_4.dateBE;
        } else if (b.data.paymentType_4_B_2_1.dateBE) {
          _documentDateB = b.data.paymentType_4_B_2_1.dateBE;
        } else if (b.data.paymentType_4_B_2_2.dateBE) {
          _documentDateB = b.data.paymentType_4_B_2_2.dateBE;
        } else if (b.data.paymentType_4_B_2_3.dateBE) {
          _documentDateB = b.data.paymentType_4_B_2_3.dateBE;
        } else if (b.data.paymentType_4_B_2_4.dateBE) {
          _documentDateB = b.data.paymentType_4_B_2_4.dateBE;
        } else if (b.data.paymentType_4_B_2_5.dateBE) {
          _documentDateB = b.data.paymentType_4_B_2_5.dateBE;
        } else if (b.data.paymentType_5.dateBE) {
          _documentDateB = b.data.paymentType_5.dateBE;
        } else if (b.data.paymentType_6.dateBE) {
          _documentDateB = b.data.paymentType_6.dateBE;
        }

        let _dateSplitA = _documentDateA.split('/');
        let _dateA = new Date(_dateSplitA[1] + '/' + _dateSplitA[0] + '/' + _dateSplitA[2]);

        let _dateSplitB = _documentDateB.split('/');
        let _dateB = new Date(_dateSplitB[1] + '/' + _dateSplitB[0] + '/' + _dateSplitB[2]);

        return _dateA - _dateB;
      });
    }

    for (let i = 0; i < _documents.length; i++) {
      if (!isEmpty(_documents[i].data.referExpenseNote)) {
        if (!_documents[i].data.referExpenseNote.customer.unstructure) {
          console.log('this is structure');
          if (_documents[i].data.referExpenseNote?.whtTotal > 0) {
            const _items = _documents[i].data.referExpenseNote.items;

            _items.forEach((_item) => {
              if (_item.percentWht > 0) {
                csvData.push(getRowItemStructure(_documents[i], _item));
              }
            });
          } else {
            if (_documents[i].data.paymentType_1.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_1'));
            }

            if (_documents[i].data.paymentType_2.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_2'));
            }

            if (_documents[i].data.paymentType_3.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_3'));
            }

            if (_documents[i].data.paymentType_4_A.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_A'));
            }

            if (_documents[i].data.paymentType_4_B_1_1.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_1_1'));
            }

            if (_documents[i].data.paymentType_4_B_1_2.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_1_2'));
            }
            if (_documents[i].data.paymentType_4_B_1_3.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_1_3'));
            }
            if (_documents[i].data.paymentType_4_B_1_4.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_1_4'));
            }
            if (_documents[i].data.paymentType_4_B_2_1.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_2_1'));
            }
            if (_documents[i].data.paymentType_4_B_2_2.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_2_2'));
            }
            if (_documents[i].data.paymentType_4_B_2_3.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_2_3'));
            }
            if (_documents[i].data.paymentType_4_B_2_4.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_2_4'));
            }
            if (_documents[i].data.paymentType_4_B_2_5.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_2_5'));
            }
            if (_documents[i].data.paymentType_5.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_5'));
            }
            if (_documents[i].data.paymentType_6.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_6'));
            }
          }
        } else {
          console.log('this is unstructure');
          if (_documents[i].data.referExpenseNote?.whtTotal > 0) {
            const _items = _documents[i].data.referExpenseNote.items;

            _items.forEach((_item) => {
              if (_item.percentWht > 0) {
                csvData.push(getRowItemUnstructure(_documents[i], _item));
              }
            });
          } else {
            if (_documents[i].data.paymentType_1.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_1'));
            }

            if (_documents[i].data.paymentType_2.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_2'));
            }

            if (_documents[i].data.paymentType_3.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_3'));
            }

            if (_documents[i].data.paymentType_4_A.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_A'));
            }

            if (_documents[i].data.paymentType_4_B_1_1.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_1'));
            }

            if (_documents[i].data.paymentType_4_B_1_2.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_2'));
            }
            if (_documents[i].data.paymentType_4_B_1_3.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_3'));
            }
            if (_documents[i].data.paymentType_4_B_1_4.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_4'));
            }
            if (_documents[i].data.paymentType_4_B_2_1.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_1'));
            }
            if (_documents[i].data.paymentType_4_B_2_2.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_2'));
            }
            if (_documents[i].data.paymentType_4_B_2_3.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_3'));
            }
            if (_documents[i].data.paymentType_4_B_2_4.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_4'));
            }
            if (_documents[i].data.paymentType_4_B_2_5.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_5'));
            }
            if (_documents[i].data.paymentType_5.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_5'));
            }
            if (_documents[i].data.paymentType_6.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_6'));
            }
          }
        }
      } else {
        if (_documents[i].data.paymentType_1.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_1'));
        }

        if (_documents[i].data.paymentType_2.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_2'));
        }

        if (_documents[i].data.paymentType_3.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_3'));
        }

        if (_documents[i].data.paymentType_4_A.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_A'));
        }

        if (_documents[i].data.paymentType_4_B_1_1.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_1'));
        }

        if (_documents[i].data.paymentType_4_B_1_2.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_2'));
        }
        if (_documents[i].data.paymentType_4_B_1_3.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_3'));
        }
        if (_documents[i].data.paymentType_4_B_1_4.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_4'));
        }
        if (_documents[i].data.paymentType_4_B_2_1.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_1'));
        }
        if (_documents[i].data.paymentType_4_B_2_2.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_2'));
        }
        if (_documents[i].data.paymentType_4_B_2_3.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_3'));
        }
        if (_documents[i].data.paymentType_4_B_2_4.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_4'));
        }
        if (_documents[i].data.paymentType_4_B_2_5.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_5'));
        }
        if (_documents[i].data.paymentType_5.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_5'));
        }
        if (_documents[i].data.paymentType_6.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_6'));
        }
      }
    }

    let result = csvData.map((data, index) => ({ ...data, r1: index + 1 }));
    if (result.length) {
      console.log('result', result);
      setCSV(result);
      setDownloadStatus('Complete');
    }
  }

  const getfileName = () => {
    const time = formatDateTime(new Date().toISOString());
    let fileName = time + '-PND-53.csv';
    return fileName;
  };

  const [CSVFileName] = useState(getfileName);

  const downloadTXT = () => {
    console.log('download .txt');

    let csvData = [];

    let _documents = [...documents];

    if (_documents && _documents.length > 0) {
      _documents.sort(function (a, b) {
        let _documentDateA;
        if (a.data.paymentType_1.dateBE) {
          _documentDateA = a.data.paymentType_1.dateBE;
        } else if (a.data.paymentType_2.dateBE) {
          _documentDateA = a.data.paymentType_2.dateBE;
        } else if (a.data.paymentType_3.dateBE) {
          _documentDateA = a.data.paymentType_3.dateBE;
        } else if (a.data.paymentType_4_A.dateBE) {
          _documentDateA = a.data.paymentType_4_A.dateBE;
        } else if (a.data.paymentType_4_B_1_1.dateBE) {
          _documentDateA = a.data.paymentType_4_B_1_1.dateBE;
        } else if (a.data.paymentType_4_B_1_2.dateBE) {
          _documentDateA = a.data.paymentType_4_B_1_2.dateBE;
        } else if (a.data.paymentType_4_B_1_3.dateBE) {
          _documentDateA = a.data.paymentType_4_B_1_3.dateBE;
        } else if (a.data.paymentType_4_B_1_4.dateBE) {
          _documentDateA = a.data.paymentType_4_B_1_4.dateBE;
        } else if (a.data.paymentType_4_B_2_1.dateBE) {
          _documentDateA = a.data.paymentType_4_B_2_1.dateBE;
        } else if (a.data.paymentType_4_B_2_2.dateBE) {
          _documentDateA = a.data.paymentType_4_B_2_2.dateBE;
        } else if (a.data.paymentType_4_B_2_3.dateBE) {
          _documentDateA = a.data.paymentType_4_B_2_3.dateBE;
        } else if (a.data.paymentType_4_B_2_4.dateBE) {
          _documentDateA = a.data.paymentType_4_B_2_4.dateBE;
        } else if (a.data.paymentType_4_B_2_5.dateBE) {
          _documentDateA = a.data.paymentType_4_B_2_5.dateBE;
        } else if (a.data.paymentType_5.dateBE) {
          _documentDateA = a.data.paymentType_5.dateBE;
        } else if (a.data.paymentType_6.dateBE) {
          _documentDateA = a.data.paymentType_6.dateBE;
        }

        let _documentDateB;
        if (b.data.paymentType_1.dateBE) {
          _documentDateB = b.data.paymentType_1.dateBE;
        } else if (b.data.paymentType_2.dateBE) {
          _documentDateB = b.data.paymentType_2.dateBE;
        } else if (b.data.paymentType_3.dateBE) {
          _documentDateB = b.data.paymentType_3.dateBE;
        } else if (b.data.paymentType_4_A.dateBE) {
          _documentDateB = b.data.paymentType_4_A.dateBE;
        } else if (b.data.paymentType_4_B_1_1.dateBE) {
          _documentDateB = b.data.paymentType_4_B_1_1.dateBE;
        } else if (b.data.paymentType_4_B_1_2.dateBE) {
          _documentDateB = b.data.paymentType_4_B_1_2.dateBE;
        } else if (b.data.paymentType_4_B_1_3.dateBE) {
          _documentDateB = b.data.paymentType_4_B_1_3.dateBE;
        } else if (b.data.paymentType_4_B_1_4.dateBE) {
          _documentDateB = b.data.paymentType_4_B_1_4.dateBE;
        } else if (b.data.paymentType_4_B_2_1.dateBE) {
          _documentDateB = b.data.paymentType_4_B_2_1.dateBE;
        } else if (b.data.paymentType_4_B_2_2.dateBE) {
          _documentDateB = b.data.paymentType_4_B_2_2.dateBE;
        } else if (b.data.paymentType_4_B_2_3.dateBE) {
          _documentDateB = b.data.paymentType_4_B_2_3.dateBE;
        } else if (b.data.paymentType_4_B_2_4.dateBE) {
          _documentDateB = b.data.paymentType_4_B_2_4.dateBE;
        } else if (b.data.paymentType_4_B_2_5.dateBE) {
          _documentDateB = b.data.paymentType_4_B_2_5.dateBE;
        } else if (b.data.paymentType_5.dateBE) {
          _documentDateB = b.data.paymentType_5.dateBE;
        } else if (b.data.paymentType_6.dateBE) {
          _documentDateB = b.data.paymentType_6.dateBE;
        }

        let _dateSplitA = _documentDateA.split('/');
        let _dateA = new Date(_dateSplitA[1] + '/' + _dateSplitA[0] + '/' + _dateSplitA[2]);

        let _dateSplitB = _documentDateB.split('/');
        let _dateB = new Date(_dateSplitB[1] + '/' + _dateSplitB[0] + '/' + _dateSplitB[2]);

        return _dateA - _dateB;
      });
    }

    for (let i = 0; i < _documents.length; i++) {
      if (!isEmpty(_documents[i].data.referExpenseNote)) {
        if (!_documents[i].data.referExpenseNote.customer.unstructure) {
          console.log('this is structure');
          if (_documents[i].data.referExpenseNote?.whtTotal > 0) {
            const _items = _documents[i].data.referExpenseNote.items;

            _items.forEach((_item) => {
              if (_item.percentWht > 0) {
                csvData.push(getRowItemStructure(_documents[i], _item));
              }
            });
          } else {
            if (_documents[i].data.paymentType_1.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_1'));
            }

            if (_documents[i].data.paymentType_2.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_2'));
            }

            if (_documents[i].data.paymentType_3.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_3'));
            }

            if (_documents[i].data.paymentType_4_A.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_A'));
            }

            if (_documents[i].data.paymentType_4_B_1_1.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_1_1'));
            }

            if (_documents[i].data.paymentType_4_B_1_2.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_1_2'));
            }
            if (_documents[i].data.paymentType_4_B_1_3.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_1_3'));
            }
            if (_documents[i].data.paymentType_4_B_1_4.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_1_4'));
            }
            if (_documents[i].data.paymentType_4_B_2_1.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_2_1'));
            }
            if (_documents[i].data.paymentType_4_B_2_2.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_2_2'));
            }
            if (_documents[i].data.paymentType_4_B_2_3.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_2_3'));
            }
            if (_documents[i].data.paymentType_4_B_2_4.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_2_4'));
            }
            if (_documents[i].data.paymentType_4_B_2_5.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_4_B_2_5'));
            }
            if (_documents[i].data.paymentType_5.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_5'));
            }
            if (_documents[i].data.paymentType_6.vatTotal) {
              csvData.push(getRowPaymentTypeStructure(_documents[i], 'paymentType_6'));
            }
          }
        } else {
          console.log('this is unstructure');
          if (_documents[i].data.referExpenseNote?.whtTotal > 0) {
            const _items = _documents[i].data.referExpenseNote.items;

            _items.forEach((_item) => {
              if (_item.percentWht > 0) {
                csvData.push(getRowItemUnstructure(_documents[i], _item));
              }
            });
          } else {
            if (_documents[i].data.paymentType_1.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_1'));
            }

            if (_documents[i].data.paymentType_2.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_2'));
            }

            if (_documents[i].data.paymentType_3.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_3'));
            }

            if (_documents[i].data.paymentType_4_A.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_A'));
            }

            if (_documents[i].data.paymentType_4_B_1_1.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_1'));
            }

            if (_documents[i].data.paymentType_4_B_1_2.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_2'));
            }
            if (_documents[i].data.paymentType_4_B_1_3.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_3'));
            }
            if (_documents[i].data.paymentType_4_B_1_4.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_4'));
            }
            if (_documents[i].data.paymentType_4_B_2_1.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_1'));
            }
            if (_documents[i].data.paymentType_4_B_2_2.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_2'));
            }
            if (_documents[i].data.paymentType_4_B_2_3.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_3'));
            }
            if (_documents[i].data.paymentType_4_B_2_4.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_4'));
            }
            if (_documents[i].data.paymentType_4_B_2_5.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_5'));
            }
            if (_documents[i].data.paymentType_5.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_5'));
            }
            if (_documents[i].data.paymentType_6.vatTotal) {
              csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_6'));
            }
          }
        }
      } else {
        if (_documents[i].data.paymentType_1.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_1'));
        }

        if (_documents[i].data.paymentType_2.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_2'));
        }

        if (_documents[i].data.paymentType_3.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_3'));
        }

        if (_documents[i].data.paymentType_4_A.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_A'));
        }

        if (_documents[i].data.paymentType_4_B_1_1.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_1'));
        }

        if (_documents[i].data.paymentType_4_B_1_2.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_2'));
        }
        if (_documents[i].data.paymentType_4_B_1_3.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_3'));
        }
        if (_documents[i].data.paymentType_4_B_1_4.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_1_4'));
        }
        if (_documents[i].data.paymentType_4_B_2_1.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_1'));
        }
        if (_documents[i].data.paymentType_4_B_2_2.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_2'));
        }
        if (_documents[i].data.paymentType_4_B_2_3.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_3'));
        }
        if (_documents[i].data.paymentType_4_B_2_4.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_4'));
        }
        if (_documents[i].data.paymentType_4_B_2_5.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_4_B_2_5'));
        }
        if (_documents[i].data.paymentType_5.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_5'));
        }
        if (_documents[i].data.paymentType_6.vatTotal) {
          csvData.push(getRowPaymentTypeUnstructure(_documents[i], 'paymentType_6'));
        }
      }
    }

    let result = csvData.map((data, index) => ({ ...data, r1: index + 1 }));

    let stringToText =
      'ลำดับที่|เลขประจำตัวผู้เสียภาษีอากร|สาขาที่|คำนำหน้าชื่อ|ชื่อ|ชื่ออาคาร|ห้องเลขที่|ชั้นที่|หมู่บ้าน|เลขที่|หมู่ที่|ตรอก/ซอย|แยก|ถนน|ตำบล/แขวง|อำเภอ/เขต|จังหวัด|รหัสไปรษณีย์|วันเดือนปีที่จ่าย|ประเภทเงินได้|อัตราภาษี|จำนวนเงินได้ที่จ่าย|จำนวนเงินภาษีที่หัก|เงื่อนไขการหักภาษี' +
      '\n';

    result.forEach(
      (row) =>
        (stringToText =
          stringToText +
          `${row.r1}|${row.r2}|${row.r3}|${row.r4}|${row.r5}|${row.r6}|${row.r7}|${row.r8}|${row.r9}|${row.r10}|${row.r11}|${row.r12}|${row.r13}|${row.r14}|${row.r15}|${row.r16}|${row.r17}|${row.r18}|${row.r19}|${row.r20}|${row.r21}|${row.r22}|${row.r23}|${row.r24}` +
          '\n')
    );

    const time = formatDateTime(new Date().toISOString());

    const element = document.createElement('a');
    const file = new Blob([stringToText], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = time + '-PND-53.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  //CommandBars Items
  const [documentType] = useState({
    key: 'ALL',
    text: 'เอกสารทุกประเภท',
  });

  const [isClickEmail, setIsClickEmail] = useState(false);
  const [isClickCopyEmail, setIsClickCopyEmail] = useState(false);
  const [fileIdEmail, setFileIdEmail] = useState('');
  const [fileNameEmail, setFileNameEmail] = useState('');
  const [fileDisplayName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [emailList, setEmailList] = useState([]);
  const [copyEmailList, setCopyEmailList] = useState([])
  const [copyEmail, setCopyEmail] = useState('');
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [errorMessageCopyEmail, setErrorMessageCopyEmail] = useState('');

  const [isClickSms, setIsClickSms] = useState(false);
  const [fileIdSms, setFileIdSms] = useState('');
  const [fileNameSms, setFileNameSms] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [showSmsSuccess, setShowSmsSuccess] = useState(false);
  const [showSmsError, setShowSmsError] = useState(false);
  const [errorMessageSms, setErrorMessageSms] = useState('');

  //Display Summary
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const [totalGrand, setTotalGrand] = useState(0);

  const getMonth = (goBack) => {
    let monthNames = [
      t('pnd53.months01'),
      t('pnd53.months02'),
      t('pnd53.months03'),
      t('pnd53.months04'),
      t('pnd53.months05'),
      t('pnd53.months06'),
      t('pnd53.months07'),
      t('pnd53.months08'),
      t('pnd53.months09'),
      t('pnd53.months10'),
      t('pnd53.months11'),
      t('pnd53.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem('pnd53-queryMonth-key') && {
      key: sessionStorage.getItem('pnd53-queryMonth-key'),
      text: sessionStorage.getItem('pnd53-queryMonth-text'),
    }) || { key: getMonth(0).year + '' + getMonth(0).key, text: t('pnd53.thisMonth') }
  );

  const [date, setDate] = useState(sessionStorage.getItem('pnd53-date') ? new Date(sessionStorage.getItem('pnd53-date')) : new Date());

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('pnd53-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('pnd53-to') || getTo(getMonth(0).month, getMonth(0).year));

  const [dateType] = useState({
    key: 'document',
    text: 'วันที่บนเอกสาร',
  });

  const handleDownloadCSV = () => {
    setDownloadStatus('Downloading');
    getCSVData();
  };

  function getDateText(monthObject) {
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('pnd53.thisMonth');

    let monthNames = [
      t('pnd53.months01'),
      t('pnd53.months02'),
      t('pnd53.months03'),
      t('pnd53.months04'),
      t('pnd53.months05'),
      t('pnd53.months06'),
      t('pnd53.months07'),
      t('pnd53.months08'),
      t('pnd53.months09'),
      t('pnd53.months10'),
      t('pnd53.months11'),
      t('pnd53.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('pnd53.months01'),
                    t('pnd53.months02'),
                    t('pnd53.months03'),
                    t('pnd53.months04'),
                    t('pnd53.months05'),
                    t('pnd53.months06'),
                    t('pnd53.months07'),
                    t('pnd53.months08'),
                    t('pnd53.months09'),
                    t('pnd53.months10'),
                    t('pnd53.months11'),
                    t('pnd53.months12'),
                  ];

                  setDate(_date);

                  sessionStorage.setItem('pnd53-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  sessionStorage.setItem('pnd53-queryMonth-key', adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'));
                  sessionStorage.setItem('pnd53-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem('pnd53-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem('pnd53-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setDocuments([]);
                  setItems([]);
                  setGetNewDocuments(true);
                  setPreviousSearch('');
                  setSearch('');

                  setTotalDocuments(0);
                  setTotalVat(0);
                  setTotalGrand(0);

                  setCurrentPage(1);
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
            </div>
          );
        },
      },
    },
    {
      key: 'txt',
      text: t('pnd53.downloadTxt'),
      iconProps: { iconName: 'TextDocument' },
      disabled: !loadDataComplete || items.length <= 0,
      onClick: () => {
        downloadTXT();
      },
    },
    {
      key: 'csv',
      text: t('pnd53.downloadCsv'),
      iconProps: { iconName: 'ExcelDocument' },
      disabled: !loadDataComplete || items.length <= 0,
      onClick: () => {
        handleDownloadCSV();
      },
    },
    {
      key: 'refresh',
      text: t('pnd53.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        setCurrentPage(1);
      },
    },
  ];

  const UploadPDF = (fileName, file, email) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        let formData = new FormData();

        formData.append('file', file);
        formData.append('fileName', fileName);
        formData.append('to', email);
        formData.append('message', '');

        await axios
          .post(API_URL + '/sents', formData, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'Content-Type': 'multipart/form-data',
            },
            timeout: 60000,
          })
          .then(
            (response) => {
              console.log(true, response);
            },
            (error) => {
              console.log('Error sending document response: ', error);
            }
          );
      })
      .catch((error) => {
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const sendEmail = (fileName, fileId, email, copyEmail) => {
    if (email !== '' && email !== null && email !== undefined) {
      if ((isClickCopyEmail && copyEmail !== '' && copyEmail !== null && copyEmail !== undefined) || !isClickCopyEmail) {
        setIsClickEmail(false);

        setIsClickCopyEmail(false);
        setErrorMessageCopyEmail('');

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
              .post(
                API_URL + '/emails/send',
                {
                  fileId: fileId,
                  email: email,
                  copyEmail: copyEmail,
                },
                {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'Content-Type': 'application/json',
                  },
                }
              )
              .then(
                (response) => {
                  console.log(response);
                  setIsClickEmail(false);
                  setShowEmailSuccess(true);
                  window.scrollTo(0, 0);
                },
                (error) => {
                  console.log(error);
                  setIsClickEmail(false);
                  setShowEmailError(true);
                  window.scrollTo(0, 0);
                }
              );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });

        let file = '';

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            axios
              .get(API_URL + '/etax/files/' + fileId, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
                responseType: 'blob',
              })
              .then(
                (response) => {
                  console.log(response);

                  file = response.data;

                  let allEmails = email.split(';');

                  allEmails.forEach((email) => {
                    console.log(email, true);

                    instance
                      .acquireTokenSilent({
                        ...silentRequest,
                        account: account,
                      })
                      .then(async (tokenResponse) => {
                        await axios
                          .get(API_URL + '/sents/check?email=' + email, {
                            headers: {
                              Authorization: 'Bearer ' + tokenResponse.accessToken,
                            },
                          })
                          .then(
                            (response) => {
                              console.log(response);
                              if (response.data.isExist) {
                                UploadPDF(fileName, file, email);
                              }
                            },
                            (error) => {
                              console.log(error);
                            }
                          );
                      });
                  });
                },
                (error) => {
                  console.log(error);
                }
              );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });
      } else {
        setErrorMessageCopyEmail('กรุณากรอกอีเมลที่ถูกต้อง');
      }
    }
  };

  const sendSms = (fileId, mobile) => {
    const re = /^0[1-9]{1}[0-9]{8}/;

    if (mobile !== '' && mobile !== null && mobile !== undefined && re.test(String(mobile))) {
      setIsClickSms(false);
      setErrorMessageSms('');

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .post(
              API_URL + '/etax/sms/',
              {
                fileId: fileId,
                mobile: mobile,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'application/json',
                },
              }
            )
            .then(
              (response) => {
                console.log(response);
                setIsClickSms(false);
                setShowSmsSuccess(true);
                window.scrollTo(0, 0);
              },
              (error) => {
                console.log(error);
                setIsClickSms(false);
                setShowSmsError(true);
                window.scrollTo(0, 0);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    } else {
      setErrorMessageSms('กรุณากรอกเบอร์มือถือที่ถูกต้อง');
    }
  };

  async function getPNGorZip() {
    setTimeout(async () => {
      try {
        const zip = new JSZip();

        console.log('download...');
        const pdfElements = printRef.current.getElementsByClassName('react-pdf__Page__canvas');

        if (pdfElements) {
          if (pdfElements.length > 1) {
            const promises = [];

            async function getCanvasBlobToPNG(elements, index) {
              const canvas = await html2canvas(elements[index]);

              return new Promise(function (resolve, reject) {
                canvas.toBlob(function (blob) {
                  resolve(zip.file(`${index + 1}.png`, blob));
                });
              });
            }

            for (let i = 0; i < pdfElements.length; i++) {
              promises.push(getCanvasBlobToPNG(pdfElements, i));
            }

            Promise.all(promises).then(async () => {
              const blob = await zip.generateAsync({
                type: 'blob',
              });

              const link = document.createElement('a');
              link.setAttribute('href', window.URL.createObjectURL(blob));
              link.setAttribute('download', fileName + '.zip');
              link.style.display = 'none';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setUrl('');
            });
          } else {
            const canvas = await html2canvas(pdfElements[0]);

            const data = canvas.toDataURL('image/png');

            const link = document.createElement('a');

            if (typeof link.download === 'string') {
              link.href = data;
              link.download = fileName + '.png';

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setUrl('');
            } else {
              window.open(data);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000);
  }

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');

    if (getNewDocuments) {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          // Do something with the tokenResponse
          console.log('Token response: ', tokenResponse);

          const [pdf_response] = await Promise.all([
            axios
              .get(API_URL + '/etax/documents/' + documentType.key + '/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              })
              .catch((error) => console.log(error)),
          ]);

          let whtList = pdf_response.data.filter((doc) => doc.type === 'WITHHOLDING-TAX');

          let pnd53List = whtList.filter((doc) => {
            if (doc.data.formType_7) return true;
            if (doc.data.referExpenseNote?.customer?.taxNumberType === 'TXID') return true;
            return false;
          });

          console.log('pnd53List', pnd53List);

          if (pnd53List && pnd53List.length > 0) {
            if (primarySort === 'documentDate') {
              if (isSortedDescendingDocumentDate) {
                pnd53List.sort(function (a, b) {
                  let _documentDateA;
                  if (a.data.paymentType_1.dateBE) {
                    _documentDateA = a.data.paymentType_1.dateBE;
                  } else if (a.data.paymentType_2.dateBE) {
                    _documentDateA = a.data.paymentType_2.dateBE;
                  } else if (a.data.paymentType_3.dateBE) {
                    _documentDateA = a.data.paymentType_3.dateBE;
                  } else if (a.data.paymentType_4_A.dateBE) {
                    _documentDateA = a.data.paymentType_4_A.dateBE;
                  } else if (a.data.paymentType_4_B_1_1.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_1_1.dateBE;
                  } else if (a.data.paymentType_4_B_1_2.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_1_2.dateBE;
                  } else if (a.data.paymentType_4_B_1_3.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_1_3.dateBE;
                  } else if (a.data.paymentType_4_B_1_4.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_1_4.dateBE;
                  } else if (a.data.paymentType_4_B_2_1.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_2_1.dateBE;
                  } else if (a.data.paymentType_4_B_2_2.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_2_2.dateBE;
                  } else if (a.data.paymentType_4_B_2_3.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_2_3.dateBE;
                  } else if (a.data.paymentType_4_B_2_4.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_2_4.dateBE;
                  } else if (a.data.paymentType_4_B_2_5.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_2_5.dateBE;
                  } else if (a.data.paymentType_5.dateBE) {
                    _documentDateA = a.data.paymentType_5.dateBE;
                  } else if (a.data.paymentType_6.dateBE) {
                    _documentDateA = a.data.paymentType_6.dateBE;
                  }

                  let _documentDateB;
                  if (b.data.paymentType_1.dateBE) {
                    _documentDateB = b.data.paymentType_1.dateBE;
                  } else if (b.data.paymentType_2.dateBE) {
                    _documentDateB = b.data.paymentType_2.dateBE;
                  } else if (b.data.paymentType_3.dateBE) {
                    _documentDateB = b.data.paymentType_3.dateBE;
                  } else if (b.data.paymentType_4_A.dateBE) {
                    _documentDateB = b.data.paymentType_4_A.dateBE;
                  } else if (b.data.paymentType_4_B_1_1.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_1_1.dateBE;
                  } else if (b.data.paymentType_4_B_1_2.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_1_2.dateBE;
                  } else if (b.data.paymentType_4_B_1_3.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_1_3.dateBE;
                  } else if (b.data.paymentType_4_B_1_4.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_1_4.dateBE;
                  } else if (b.data.paymentType_4_B_2_1.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_2_1.dateBE;
                  } else if (b.data.paymentType_4_B_2_2.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_2_2.dateBE;
                  } else if (b.data.paymentType_4_B_2_3.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_2_3.dateBE;
                  } else if (b.data.paymentType_4_B_2_4.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_2_4.dateBE;
                  } else if (b.data.paymentType_4_B_2_5.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_2_5.dateBE;
                  } else if (b.data.paymentType_5.dateBE) {
                    _documentDateB = b.data.paymentType_5.dateBE;
                  } else if (b.data.paymentType_6.dateBE) {
                    _documentDateB = b.data.paymentType_6.dateBE;
                  }

                  let _dateSplitA = _documentDateA.split('/');
                  let _dateA = new Date(_dateSplitA[1] + '/' + _dateSplitA[0] + '/' + _dateSplitA[2]);

                  let _dateSplitB = _documentDateB.split('/');
                  let _dateB = new Date(_dateSplitB[1] + '/' + _dateSplitB[0] + '/' + _dateSplitB[2]);

                  return _dateB - _dateA;
                });
              } else {
                pnd53List.sort(function (a, b) {
                  let _documentDateA;
                  if (a.data.paymentType_1.dateBE) {
                    _documentDateA = a.data.paymentType_1.dateBE;
                  } else if (a.data.paymentType_2.dateBE) {
                    _documentDateA = a.data.paymentType_2.dateBE;
                  } else if (a.data.paymentType_3.dateBE) {
                    _documentDateA = a.data.paymentType_3.dateBE;
                  } else if (a.data.paymentType_4_A.dateBE) {
                    _documentDateA = a.data.paymentType_4_A.dateBE;
                  } else if (a.data.paymentType_4_B_1_1.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_1_1.dateBE;
                  } else if (a.data.paymentType_4_B_1_2.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_1_2.dateBE;
                  } else if (a.data.paymentType_4_B_1_3.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_1_3.dateBE;
                  } else if (a.data.paymentType_4_B_1_4.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_1_4.dateBE;
                  } else if (a.data.paymentType_4_B_2_1.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_2_1.dateBE;
                  } else if (a.data.paymentType_4_B_2_2.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_2_2.dateBE;
                  } else if (a.data.paymentType_4_B_2_3.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_2_3.dateBE;
                  } else if (a.data.paymentType_4_B_2_4.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_2_4.dateBE;
                  } else if (a.data.paymentType_4_B_2_5.dateBE) {
                    _documentDateA = a.data.paymentType_4_B_2_5.dateBE;
                  } else if (a.data.paymentType_5.dateBE) {
                    _documentDateA = a.data.paymentType_5.dateBE;
                  } else if (a.data.paymentType_6.dateBE) {
                    _documentDateA = a.data.paymentType_6.dateBE;
                  }

                  let _documentDateB;
                  if (b.data.paymentType_1.dateBE) {
                    _documentDateB = b.data.paymentType_1.dateBE;
                  } else if (b.data.paymentType_2.dateBE) {
                    _documentDateB = b.data.paymentType_2.dateBE;
                  } else if (b.data.paymentType_3.dateBE) {
                    _documentDateB = b.data.paymentType_3.dateBE;
                  } else if (b.data.paymentType_4_A.dateBE) {
                    _documentDateB = b.data.paymentType_4_A.dateBE;
                  } else if (b.data.paymentType_4_B_1_1.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_1_1.dateBE;
                  } else if (b.data.paymentType_4_B_1_2.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_1_2.dateBE;
                  } else if (b.data.paymentType_4_B_1_3.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_1_3.dateBE;
                  } else if (b.data.paymentType_4_B_1_4.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_1_4.dateBE;
                  } else if (b.data.paymentType_4_B_2_1.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_2_1.dateBE;
                  } else if (b.data.paymentType_4_B_2_2.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_2_2.dateBE;
                  } else if (b.data.paymentType_4_B_2_3.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_2_3.dateBE;
                  } else if (b.data.paymentType_4_B_2_4.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_2_4.dateBE;
                  } else if (b.data.paymentType_4_B_2_5.dateBE) {
                    _documentDateB = b.data.paymentType_4_B_2_5.dateBE;
                  } else if (b.data.paymentType_5.dateBE) {
                    _documentDateB = b.data.paymentType_5.dateBE;
                  } else if (b.data.paymentType_6.dateBE) {
                    _documentDateB = b.data.paymentType_6.dateBE;
                  }

                  let _dateSplitA = _documentDateA.split('/');
                  let _dateA = new Date(_dateSplitA[1] + '/' + _dateSplitA[0] + '/' + _dateSplitA[2]);

                  let _dateSplitB = _documentDateB.split('/');
                  let _dateB = new Date(_dateSplitB[1] + '/' + _dateSplitB[0] + '/' + _dateSplitB[2]);

                  return _dateA - _dateB;
                });
              }
            }
          }

          if (currentPage > Math.ceil(pnd53List.length / pageSize)) {
            setCurrentPage(1);
          }

          setTotalDocuments(pnd53List.length);

          //set page
          setPageData(paginate(pnd53List.length, currentPage, pageSize, 10));

          setDocuments(pnd53List);

          const _docs = [];

          if (pnd53List.length > 0) {
            for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, pnd53List.length); i++) {
              let _vatTotal = 0;
              let _grandTotal = 0;

              if (pnd53List[i].data.vatTotal) {
                _vatTotal = Number(String(pnd53List[i].data.vatTotal).replaceAll(',', ''));
              }

              if (pnd53List[i].data.amountTotal) {
                _grandTotal = Number(String(pnd53List[i].data.amountTotal).replaceAll(',', ''));
              }

              let _documentDate;
              if (pnd53List[i].data.paymentType_1.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_1.dateBE;
              } else if (pnd53List[i].data.paymentType_2.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_2.dateBE;
              } else if (pnd53List[i].data.paymentType_3.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_3.dateBE;
              } else if (pnd53List[i].data.paymentType_4_A.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_4_A.dateBE;
              } else if (pnd53List[i].data.paymentType_4_B_1_1.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_4_B_1_1.dateBE;
              } else if (pnd53List[i].data.paymentType_4_B_1_2.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_4_B_1_2.dateBE;
              } else if (pnd53List[i].data.paymentType_4_B_1_3.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_4_B_1_3.dateBE;
              } else if (pnd53List[i].data.paymentType_4_B_1_4.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_4_B_1_4.dateBE;
              } else if (pnd53List[i].data.paymentType_4_B_2_1.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_4_B_2_1.dateBE;
              } else if (pnd53List[i].data.paymentType_4_B_2_2.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_4_B_2_2.dateBE;
              } else if (pnd53List[i].data.paymentType_4_B_2_3.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_4_B_2_3.dateBE;
              } else if (pnd53List[i].data.paymentType_4_B_2_4.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_4_B_2_4.dateBE;
              } else if (pnd53List[i].data.paymentType_4_B_2_5.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_4_B_2_5.dateBE;
              } else if (pnd53List[i].data.paymentType_5.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_5.dateBE;
              } else if (pnd53List[i].data.paymentType_6.dateBE) {
                _documentDate = pnd53List[i].data.paymentType_6.dateBE;
              }

              _docs.push({
                key: pnd53List[i].id,
                row: i + 1,
                documentDate: _documentDate,
                taxId: pnd53List[i].data.customer.taxId,
                name: pnd53List[i].data.customer.name,
                grandTotal: _grandTotal,
                vatTotal: _vatTotal,
                type: pnd53List[i].type,
                fileExtension: pnd53List[i].file.extension,
                number: pnd53List[i].data.number,
                signature: pnd53List[i].signatures[0].certificateName,
                digitalSign: pnd53List[i].digitalSign,
                createdTimeValue: pnd53List[i].createdTime,
              });
            }

            let _totalVat = 0;
            let _totalGrand = 0;

            for (let i = 0; i < pnd53List.length; i++) {
              _totalVat = _totalVat + Number(String(pnd53List[i].data.vatTotal).replaceAll(',', ''));
              _totalGrand = _totalGrand + Number(String(pnd53List[i].data.amountTotal).replaceAll(',', ''));
            }

            setTotalVat(_totalVat);
            setTotalGrand(_totalGrand);
          }

          console.log('Docs data', _docs);
          setItems(_docs);

          setLoadDataComplete(true);
          setGetNewDocuments(false);
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          // instance.acquireTokenRedirect(silentRequest);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    if (previousSearch) {
      if (filterDocuments.length > 0) {
        const _docs = [];

        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, filterDocuments.length); i++) {
          let _vatTotal = 0;
          let _grandTotal = 0;

          if (filterDocuments[i].data.vatTotal) {
            _vatTotal = Number(String(filterDocuments[i].data.vatTotal).replaceAll(',', ''));
          }

          if (filterDocuments[i].data.amountTotal) {
            _grandTotal = Number(String(filterDocuments[i].data.amountTotal).replaceAll(',', ''));
          }

          let _documentDate;
          if (filterDocuments[i].data.paymentType_1.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_1.dateBE;
          } else if (filterDocuments[i].data.paymentType_2.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_2.dateBE;
          } else if (filterDocuments[i].data.paymentType_3.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_3.dateBE;
          } else if (filterDocuments[i].data.paymentType_4_A.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_4_A.dateBE;
          } else if (filterDocuments[i].data.paymentType_4_B_1_1.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_4_B_1_1.dateBE;
          } else if (filterDocuments[i].data.paymentType_4_B_1_2.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_4_B_1_2.dateBE;
          } else if (filterDocuments[i].data.paymentType_4_B_1_3.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_4_B_1_3.dateBE;
          } else if (filterDocuments[i].data.paymentType_4_B_1_4.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_4_B_1_4.dateBE;
          } else if (filterDocuments[i].data.paymentType_4_B_2_1.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_4_B_2_1.dateBE;
          } else if (filterDocuments[i].data.paymentType_4_B_2_2.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_4_B_2_2.dateBE;
          } else if (filterDocuments[i].data.paymentType_4_B_2_3.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_4_B_2_3.dateBE;
          } else if (filterDocuments[i].data.paymentType_4_B_2_4.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_4_B_2_4.dateBE;
          } else if (filterDocuments[i].data.paymentType_4_B_2_5.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_4_B_2_5.dateBE;
          } else if (filterDocuments[i].data.paymentType_5.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_5.dateBE;
          } else if (filterDocuments[i].data.paymentType_6.dateBE) {
            _documentDate = filterDocuments[i].data.paymentType_6.dateBE;
          }

          _docs.push({
            key: filterDocuments[i].id,
            row: i + 1,
            documentDate: _documentDate,
            taxId: filterDocuments[i].data.customer.taxId,
            name: filterDocuments[i].data.customer.name,
            grandTotal: _grandTotal,
            vatTotal: _vatTotal,
            type: filterDocuments[i].type,
            fileExtension: filterDocuments[i].file.extension,
            number: filterDocuments[i].data.number,
            signature: filterDocuments[i].signatures[0].certificateName,
            digitalSign: filterDocuments[i].digitalSign,
            createdTimeValue: filterDocuments[i].createdTime,
          });
        }

        setItems(_docs);
      }
    } else {
      if (documents.length > 0) {
        const _docs = [];

        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
          let _vatTotal = 0;
          let _grandTotal = 0;

          if (documents[i].data.vatTotal) {
            _vatTotal = Number(String(documents[i].data.vatTotal).replaceAll(',', ''));
          }

          if (documents[i].data.amountTotal) {
            _grandTotal = Number(String(documents[i].data.amountTotal).replaceAll(',', ''));
          }

          let _documentDate;
          if (documents[i].data.paymentType_1.dateBE) {
            _documentDate = documents[i].data.paymentType_1.dateBE;
          } else if (documents[i].data.paymentType_2.dateBE) {
            _documentDate = documents[i].data.paymentType_2.dateBE;
          } else if (documents[i].data.paymentType_3.dateBE) {
            _documentDate = documents[i].data.paymentType_3.dateBE;
          } else if (documents[i].data.paymentType_4_A.dateBE) {
            _documentDate = documents[i].data.paymentType_4_A.dateBE;
          } else if (documents[i].data.paymentType_4_B_1_1.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_1_1.dateBE;
          } else if (documents[i].data.paymentType_4_B_1_2.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_1_2.dateBE;
          } else if (documents[i].data.paymentType_4_B_1_3.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_1_3.dateBE;
          } else if (documents[i].data.paymentType_4_B_1_4.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_1_4.dateBE;
          } else if (documents[i].data.paymentType_4_B_2_1.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_2_1.dateBE;
          } else if (documents[i].data.paymentType_4_B_2_2.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_2_2.dateBE;
          } else if (documents[i].data.paymentType_4_B_2_3.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_2_3.dateBE;
          } else if (documents[i].data.paymentType_4_B_2_4.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_2_4.dateBE;
          } else if (documents[i].data.paymentType_4_B_2_5.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_2_5.dateBE;
          } else if (documents[i].data.paymentType_5.dateBE) {
            _documentDate = documents[i].data.paymentType_5.dateBE;
          } else if (documents[i].data.paymentType_6.dateBE) {
            _documentDate = documents[i].data.paymentType_6.dateBE;
          }

          _docs.push({
            key: documents[i].id,
            row: i + 1,
            documentDate: _documentDate,
            taxId: documents[i].data.customer.taxId,
            name: documents[i].data.customer.name,
            grandTotal: _grandTotal,
            vatTotal: _vatTotal,
            type: documents[i].type,
            fileExtension: documents[i].file.extension,
            number: documents[i].data.number,
            signature: documents[i].signatures[0].certificateName,
            digitalSign: documents[i].digitalSign,
            createdTimeValue: documents[i].createdTime,
          });
        }
        console.log('Docs data', _docs);

        setItems(_docs);
      }
      sessionStorage.setItem('pnd53-currentPage', currentPage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const download = (item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/files/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              console.log(response.headers);

              const time = formatDateTime(item.createdTimeValue);

              let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g, '') + '.' + item.fileExtension;

              console.log('file name: ', fileName);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName); //or any other extension
              document.body.appendChild(link);
              link.click();
              link.remove();
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const downloadPNG = async (item) => {
    console.log('download PNG');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/files/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              console.log(response.headers);

              const time = formatDateTime(item.createdTimeValue);

              let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g, '');

              setFileName(fileName);

              console.log('file name: ', fileName);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              setUrl(url);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    if (url) {
      getPNGorZip();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const openFile_pdf = (item) => {
    history.push({
      pathname: '/etax/documents/pdf/view',
      state: {
        item: item,
        backUrl: '/rdprep/PND53',
      },
    });
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const columns = [
    {
      key: 'column1',
      name: t('pnd53.row'),
      fieldName: 'row',
      minWidth: 35,
      maxWidth: 35,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.row}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('pnd53.documentDate'),
      fieldName: 'documentDate',
      minWidth: 80,
      maxWidth: 80,
      isSorted: primarySort === 'documentDate' ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log('Sort documentDate!');
        setPrimarySort('documentDate');
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        sessionStorage.setItem('pnd53-primarySort', 'documentDate');
        sessionStorage.setItem('pnd53-sort-documentDate', !isSortedDescendingDocumentDate === true ? 'true' : 'false');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);
        setPreviousSearch('');
        setSearch('');

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        setCurrentPage(1);
      },
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.documentDate}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('pnd53.taxId'),
      fieldName: 'taxId',
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.taxId}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('pnd53.name'),
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 400,
      isRowHeader: true,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        return <span>{item.name}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('pnd53.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      data: 'number',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: t('pnd53.vatTotal'),
      fieldName: 'vatTotal',
      minWidth: 70,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      data: 'number',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.vatTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: t('pnd53.file'),
      fieldName: 'file',
      minWidth: 100,
      maxWidth: 100,
      onColumnClick: '',

      onRender: (item) => (
        <Stack horizontal>
          <IconButton
            onClick={(event) => {
              openFile_pdf(item);
            }}
            iconProps={{
              ...getFileTypeIconProps({
                extension: 'pdf',
                size: 32,
                imageFileType: 'svg',
              }),
            }}
          />
          <IconButton
            onClick={() => {
              downloadPNG(item);
            }}
            iconProps={{
              ...getFileTypeIconProps({
                size: 32,
                imageFileType: 'svg',
              }),
            }}
          />
        </Stack>
      ),
    },
    {
      key: 'column8',
      name: '',
      fieldName: 'command',
      minWidth: 90,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        return (
          <DefaultButton
            text={t('pnd53.select')}
            menuProps={{
              items: [
                {
                  key: 'open',
                  text: t('pnd53.openDocument'),
                  iconProps: { iconName: 'OpenFile' },
                  onClick: () => {
                    openFile_pdf(item);
                  },
                },
                {
                  key: 'download',
                  text: t('pnd53.download'),
                  iconProps: { iconName: 'DownloadDocument' },
                  onClick: () => {
                    download(item);
                  },
                },
                {
                  key: 'divider_1',
                  itemType: ContextualMenuItemType.Divider,
                },
                {
                  key: 'email',
                  text: t('pnd53.email'),
                  iconProps: { iconName: 'MailForward' },
                  onClick: () => {
                    setIsClickEmail(true);
                    setFileNameEmail(item.name);
                    setCustomerEmail(item.customerEmail);
                    setEmailList(item.customerEmail ? [item.customerEmail] : []);
                    setCopyEmailList(item.companyEmail ? [item.companyEmail] : [])
                    setCopyEmail(item.companyEmail);
                    setFileIdEmail(item.key);
                  },
                },
                {
                  key: 'sms',
                  text: 'SMS',
                  iconProps: { iconName: 'Message' },
                  onClick: () => {
                    setIsClickSms(true);
                    setFileNameSms(item.name);
                    setCustomerPhone(item.customerPhone);
                    setFileIdSms(item.key);
                  },
                },
              ],
              shouldFocusOnMount: true,
              directionalHintFixed: true,
            }}
            // Optional callback to customize menu rendering
            menuAs={(props) => <ContextualMenu {...props} />}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (search !== '') {
      let _filterDocument =
        documents.filter(
          (document) =>
            String(document.data.vatTotal).replaceAll(',', '').includes(search) ||
            String(document.data.amountTotal).replaceAll(',', '').includes(search) ||
            document.data.customer.taxId.includes(search) ||
            document.data.customer.name.includes(search) ||
            document.data.number.includes(search)
        ) || [];

      const _docs = [];

      if (_filterDocument.length) {
        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, _filterDocument.length); i++) {
          let _vatTotal = 0;
          let _grandTotal = 0;

          if (_filterDocument[i].data.vatTotal) {
            _vatTotal = Number(String(_filterDocument[i].data.vatTotal).replaceAll(',', ''));
          }

          if (_filterDocument[i].data.amountTotal) {
            _grandTotal = Number(String(_filterDocument[i].data.amountTotal).replaceAll(',', ''));
          }

          let _documentDate;
          if (_filterDocument[i].data.paymentType_1.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_1.dateBE;
          } else if (_filterDocument[i].data.paymentType_2.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_2.dateBE;
          } else if (_filterDocument[i].data.paymentType_3.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_3.dateBE;
          } else if (_filterDocument[i].data.paymentType_4_A.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_4_A.dateBE;
          } else if (_filterDocument[i].data.paymentType_4_B_1_1.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_4_B_1_1.dateBE;
          } else if (_filterDocument[i].data.paymentType_4_B_1_2.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_4_B_1_2.dateBE;
          } else if (_filterDocument[i].data.paymentType_4_B_1_3.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_4_B_1_3.dateBE;
          } else if (_filterDocument[i].data.paymentType_4_B_1_4.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_4_B_1_4.dateBE;
          } else if (_filterDocument[i].data.paymentType_4_B_2_1.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_4_B_2_1.dateBE;
          } else if (_filterDocument[i].data.paymentType_4_B_2_2.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_4_B_2_2.dateBE;
          } else if (_filterDocument[i].data.paymentType_4_B_2_3.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_4_B_2_3.dateBE;
          } else if (_filterDocument[i].data.paymentType_4_B_2_4.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_4_B_2_4.dateBE;
          } else if (_filterDocument[i].data.paymentType_4_B_2_5.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_4_B_2_5.dateBE;
          } else if (_filterDocument[i].data.paymentType_5.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_5.dateBE;
          } else if (_filterDocument[i].data.paymentType_6.dateBE) {
            _documentDate = _filterDocument[i].data.paymentType_6.dateBE;
          }

          _docs.push({
            key: _filterDocument[i].id,
            row: i + 1,
            documentDate: _documentDate,
            taxId: _filterDocument[i].data.customer.taxId,
            name: _filterDocument[i].data.customer.name,
            grandTotal: _grandTotal,
            vatTotal: _vatTotal,
            type: _filterDocument[i].type,
            fileExtension: _filterDocument[i].file.extension,
            number: _filterDocument[i].data.number,
            signature: _filterDocument[i].signatures[0].certificateName,
            digitalSign: _filterDocument[i].digitalSign,
            createdTimeValue: _filterDocument[i].createdTime,
          });
        }
      }

      setItems(_docs);
    } else {
      if (documents.length > 0) {
        const _docs = [];

        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
          let _vatTotal = 0;
          let _grandTotal = 0;

          if (documents[i].data.vatTotal) {
            _vatTotal = Number(String(documents[i].data.vatTotal).replaceAll(',', ''));
          }

          if (documents[i].data.amountTotal) {
            _grandTotal = Number(String(documents[i].data.amountTotal).replaceAll(',', ''));
          }

          let _documentDate;
          if (documents[i].data.paymentType_1.dateBE) {
            _documentDate = documents[i].data.paymentType_1.dateBE;
          } else if (documents[i].data.paymentType_2.dateBE) {
            _documentDate = documents[i].data.paymentType_2.dateBE;
          } else if (documents[i].data.paymentType_3.dateBE) {
            _documentDate = documents[i].data.paymentType_3.dateBE;
          } else if (documents[i].data.paymentType_4_A.dateBE) {
            _documentDate = documents[i].data.paymentType_4_A.dateBE;
          } else if (documents[i].data.paymentType_4_B_1_1.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_1_1.dateBE;
          } else if (documents[i].data.paymentType_4_B_1_2.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_1_2.dateBE;
          } else if (documents[i].data.paymentType_4_B_1_3.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_1_3.dateBE;
          } else if (documents[i].data.paymentType_4_B_1_4.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_1_4.dateBE;
          } else if (documents[i].data.paymentType_4_B_2_1.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_2_1.dateBE;
          } else if (documents[i].data.paymentType_4_B_2_2.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_2_2.dateBE;
          } else if (documents[i].data.paymentType_4_B_2_3.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_2_3.dateBE;
          } else if (documents[i].data.paymentType_4_B_2_4.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_2_4.dateBE;
          } else if (documents[i].data.paymentType_4_B_2_5.dateBE) {
            _documentDate = documents[i].data.paymentType_4_B_2_5.dateBE;
          } else if (documents[i].data.paymentType_5.dateBE) {
            _documentDate = documents[i].data.paymentType_5.dateBE;
          } else if (documents[i].data.paymentType_6.dateBE) {
            _documentDate = documents[i].data.paymentType_6.dateBE;
          }

          _docs.push({
            key: documents[i].id,
            row: i + 1,
            documentDate: _documentDate,
            taxId: documents[i].data.customer.taxId,
            name: documents[i].data.customer.name,
            grandTotal: _grandTotal,
            vatTotal: _vatTotal,
            type: documents[i].type,
            fileExtension: documents[i].file.extension,
            number: documents[i].data.number,
            signature: documents[i].signatures[0].certificateName,
            digitalSign: documents[i].digitalSign,
            createdTimeValue: documents[i].createdTime,
          });
        }
        console.log('Docs data', _docs);

        setItems(_docs);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const isCompactMode = false;

  function TextFieldEmailComponent(props) {
    return (
      <TextFieldEmail 
        style={{ width: 240}}
        selectedItems={[...emailList.map(_email => (
          { key: _email, text: _email }
        ))]}
        onItemsRemoved={(removeItems, removeItemIndex) => {
          const currentSelectedItemsCopy = [...emailList];
          currentSelectedItemsCopy.splice(removeItemIndex, 1);
          // this.setState({ currentSelectedItems: [...currentSelectedItemsCopy] });
          setEmailList([...currentSelectedItemsCopy])

          let multiEmail = '';

          currentSelectedItemsCopy.forEach((email, index) => {
            multiEmail = multiEmail + email;

            if (currentSelectedItemsCopy.length - 1 !== index) {
              multiEmail = multiEmail + ';';
            }
          });
          setCustomerEmail(multiEmail);
        }}
        onBlur={(e) => {
          if (checkEmail(e.target.value) && !emailList.includes(e.target.value)) {
              const currentSelectedItemsCopy = [...emailList, e.target.value];

              setEmailList(currentSelectedItemsCopy)

              let multiEmail = '';

              currentSelectedItemsCopy.forEach((email, index) => {
                multiEmail = multiEmail + email;

                if (currentSelectedItemsCopy.length - 1 !== index) {
                  multiEmail = multiEmail + ';';
                }
              });
              setCustomerEmail(multiEmail);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
              if (checkEmail(e.target.value) && !emailList.includes(e.target.value)) {
                  const currentSelectedItemsCopy = [...emailList, e.target.value];

                  setEmailList(currentSelectedItemsCopy)

                  let multiEmail = '';

                  currentSelectedItemsCopy.forEach((email, index) => {
                    multiEmail = multiEmail + email;

                    if (currentSelectedItemsCopy.length - 1 !== index) {
                      multiEmail = multiEmail + ';';
                    }
                  });
                  setCustomerEmail(multiEmail);

              }
          }
        }}
      />
    );
  }

  function TextFieldCopyEmailComponent(props) {
    return (
      <TextFieldEmail 
        style={{ width: 240}}
        selectedItems={[...copyEmailList.map(_email => (
          { key: _email, text: _email }
        ))]}
        onItemsRemoved={(removeItems, removeItemIndex) => {
          const currentSelectedItemsCopy = [...copyEmailList];
          currentSelectedItemsCopy.splice(removeItemIndex, 1);
          // this.setState({ currentSelectedItems: [...currentSelectedItemsCopy] });
          setCopyEmailList([...currentSelectedItemsCopy])

          let multiEmail = '';

          currentSelectedItemsCopy.forEach((email, index) => {
            multiEmail = multiEmail + email;

            if (currentSelectedItemsCopy.length - 1 !== index) {
              multiEmail = multiEmail + ';';
            }
          });
          setCopyEmail(multiEmail);
        }}
        onBlur={(e) => {
          if (checkEmail(e.target.value) && !copyEmailList.includes(e.target.value)) {
              const currentSelectedItemsCopy = [...copyEmailList, e.target.value];

              setCopyEmailList(currentSelectedItemsCopy)

              let multiEmail = '';

              currentSelectedItemsCopy.forEach((email, index) => {
                multiEmail = multiEmail + email;

                if (currentSelectedItemsCopy.length - 1 !== index) {
                  multiEmail = multiEmail + ';';
                }
              });
              setCopyEmail(multiEmail);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
              if (checkEmail(e.target.value) && !copyEmailList.includes(e.target.value)) {
                  const currentSelectedItemsCopy = [...copyEmailList, e.target.value];

                  setCopyEmailList(currentSelectedItemsCopy)

                  let multiEmail = '';

                  currentSelectedItemsCopy.forEach((email, index) => {
                    multiEmail = multiEmail + email;

                    if (currentSelectedItemsCopy.length - 1 !== index) {
                      multiEmail = multiEmail + ';';
                    }
                  });
                  setCopyEmail(multiEmail);

              }
          }
        }}
      />
    );
  }

  return (
    <>
      <div style={homeStyles}>
        {showEmailSuccess && (
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => setShowEmailSuccess(false)}
            dismissButtonAriaLabel="Close"
            style={{
              fontSize: 14,
            }}
          >
            {t('pnd53.sendEmailSuccess')}
          </MessageBar>
        )}
        {showEmailError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setShowEmailError(false)}
            dismissButtonAriaLabel="Close"
            style={{
              fontSize: 14,
            }}
          >
            {t('pnd53.sendEmailFail')}
          </MessageBar>
        )}

        {showSmsSuccess && (
          <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={false}
            onDismiss={() => setShowSmsSuccess(false)}
            dismissButtonAriaLabel="Close"
            style={{
              fontSize: 14,
            }}
          >
            {t('pnd53.sendSmsSuccess')}
          </MessageBar>
        )}

        {showSmsError && (
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={false}
            onDismiss={() => setShowSmsError(false)}
            dismissButtonAriaLabel="Close"
            style={{
              fontSize: 14,
            }}
          >
            {t('pnd53.sendSmsFail')}
          </MessageBar>
        )}

        {(showEmailSuccess || showEmailError || showSmsSuccess || showSmsError) && <br />}
        <Stack>
          <Stack vertical horizontalAlign="center">
            <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('pnd53.header')}</h2>
            <h4 style={{ marginLeft: '0px', marginTop: '0px' }}>{t('pnd53.subHeader')}</h4>
          </Stack>

          <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
            <DocumentCard aria-label={t('pnd53.totalDocuments')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Documentation' }} title={t('pnd53.totalDocuments')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('pnd53.totalDocuments')} styles={cardStyles.header} />
                <Text styles={cardStyles.amount}>
                  <CountUp end={totalDocuments} separator="," duration={1} /> {t('pnd53.documentUnit')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>

            <DocumentCard aria-label={t('pnd53.totalGrand')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Money' }} title={t('pnd53.totalGrand')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('pnd53.totalGrand')} styles={cardStyles.header} />
                <Text styles={cardStyles.amount}>
                  <CountUp end={totalGrand} separator="," decimals={2} decimal="." duration={1} /> {t('pnd53.thb')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>

            <DocumentCard aria-label={t('pnd53.totalVat')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
              <DocumentCardLogo {...{ logoIcon: 'Bank' }} title={t('pnd53.totalVat')} />
              <DocumentCardDetails>
                <DocumentCardTitle title={t('pnd53.totalVat')} styles={cardStyles.header} />
                <Text styles={cardStyles.amount}>
                  <CountUp end={totalVat} separator="," decimals={2} decimal="." duration={1} /> {t('pnd53.thb')}
                </Text>
              </DocumentCardDetails>
            </DocumentCard>
          </Stack>
        </Stack>

        {totalProcessingDocuments > 0 && (
          <div>
            <br />
            <Spinner label={t('pnd53.signProcessing')} size={SpinnerSize.large} />
          </div>
        )}

        {downloading && (
          <div>
            <br />
            <Spinner label={t('pnd53.loading')} size={SpinnerSize.large} />
          </div>
        )}

        <br />
        <Stack horizontal horizontalAlign="center">
          <Stack
            vertical
            style={{
              width: '98%',
              minWidth: '800px',
              maxWidth: '1350px',
            }}
          >
            <Stack 
              horizontal 
              styles={{
                root: {
                  backgroundColor: 'white',
                },
              }} 
              horizontalAlign="space-between"
            >
              <CommandBar
                items={commandBarItems}
                styles={{
                  root: {
                    paddingLeft: 8,
                    width: 800,
                  },
                }}
              />
              {loadDataComplete && (
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                      paddingRight: 8,
                    },
                  }}
                  tokens={{ childrenGap: '10px' }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        marginLeft: 6,
                        width: 200,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete}
                    placeholder={t('pnd53.searchPlaceholder')}
                    onChange={(e) => setSearch(e ? e.target.value.replace(/\s/g, '') : '')}
                    onClear={() => {
                      setSearch('');
                    }}
                    value={search}
                  />
                </Stack>
              )}
            </Stack>
            {items.length ? (
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
              />
            ) : (
              <ShimmeredDetailsList
                items={[{}]}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderRow={() => {
                  return (
                    <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: '#FFFFFF', height: 55 } }}>
                      <Text>{t('documents.noItemFound')}</Text>
                    </Stack>
                  );
                }}
              />
            )}
          </Stack>
        </Stack>
        <br />
        <center>
          {!(documents.length === 0 || documents.length < pageSize) && loadDataComplete && (
            <Pagination
              selectedPageIndex={currentPage - 1}
              pageCount={pageData.totalPages}
              onPageChange={onPageChange}
              format
              firstPageIconProps={{
                iconName: 'DoubleChevronLeft',
              }}
              previousPageIconProps={{
                iconName: 'ChevronLeft',
              }}
              nextPageIconProps={{ iconName: 'ChevronRight' }}
              lastPageIconProps={{
                iconName: 'DoubleChevronRight',
              }}
            />
          )}
          <br />
          <br />
        </center>

        <CSVLink style={{ display: 'none' }} ref={csvLink} filename={CSVFileName} data={CSV} headers={headerCSV} enclosingCharacter="">
          Download csv
        </CSVLink>

        <Dialog
          hidden={!isClickEmail}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: t('pnd53.sendFileByEmail'),
            subText: fileNameEmail,
          }}
        >
          <Stack vertical>
            <div>
              <label>{t('pnd53.sendToEmail')}</label>
            </div>
            <TextFieldEmailComponent />
            <br />
            <Checkbox
              label={t('pnd53.ccToEmail')}
              checked={isClickCopyEmail}
              onChange={(e) => {
                console.log('copy email checkbox: ', e.target.value);
                setIsClickCopyEmail((prev) => !prev);
              }}
            />
            {isClickCopyEmail && (
              <div style={{ marginTop: '8px' }}>
                {/* <TextField
                  value={copyEmail}
                  onChange={(e) => {
                    setCopyEmail(e.target.value);
                    setErrorMessageCopyEmail('');
                  }}
                  errorMessage={errorMessageCopyEmail}
                /> */}
                <TextFieldCopyEmailComponent />
              </div>
            )}
            <br />
            <br />
          </Stack>
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                if (isClickCopyEmail) {
                  sendEmail(fileDisplayName, fileIdEmail, customerEmail, copyEmail);
                } else {
                  sendEmail(fileDisplayName, fileIdEmail, customerEmail, '');
                }
              }}
              text={t('pnd53.sendEmail')}
            />
            <DefaultButton onClick={() => setIsClickEmail(false)} text={t('pnd53.cancel')} />
          </DialogFooter>
        </Dialog>

        <Dialog
          hidden={!isClickSms}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: t('pnd53.sendFileBySms'),
            subText: fileNameSms,
          }}
        >
          <TextField
            label={t('pnd53.mobileNumer')}
            value={customerPhone}
            required
            onChange={(e) => {
              setCustomerPhone(e.target.value);
              setErrorMessageSms('');
            }}
            errorMessage={errorMessageSms}
            description={t('pnd53.exampleNumber')}
          />
          <br />
          <br />
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                sendSms(fileIdSms, customerPhone);
              }}
              text={t('pnd53.sendSms')}
            />
            <DefaultButton onClick={() => setIsClickSms(false)} text={t('pnd53.cancel')} />
          </DialogFooter>
        </Dialog>
      </div>
      {url ? (
        <div ref={printRef} style={{ position: 'absolute', zIndex: -1 }}>
          <PdfAllPages pdf={url} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PND53;
